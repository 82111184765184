import React from 'react';
import { SummaryOfChange } from '../views';

export default {
  initialStep: 'summaryOfChange',
  internalSteps: {
    summaryOfChange: {
      component: (props) => (
        <SummaryOfChange {...props} displayDescriptionOfChange={false} />
      ),
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: () => null,
};
