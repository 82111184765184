import gql from 'graphql-tag';

export const CREATE_ASSOCIATED_VALIDATION_RECORD = gql`
  mutation addValidationRecordToVerificationAndValidationReportVersion(
    $recordVersionIds: [ID!]!
    $versionId: ID!
    $parentVersionId: ID!
  ) {
    addValidationRecordToVerificationAndValidationReportVersion(
      recordVersionIds: $recordVersionIds
      versionId: $versionId
      parentVersionId: $parentVersionId
    ) {
      verificationAndValidationReportVersion {
        id
        currentStatus {
          id
          name
        }
        title
        versionIdentifier
        validationRecords {
          id
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ASSOCIATED_VERIFICATION_RECORD = gql`
  mutation addVerificationRecordToVerificationAndValidationReportVersion(
    $recordVersionIds: [ID!]!
    $versionId: ID!
    $parentVersionId: ID!
  ) {
    addVerificationRecordToVerificationAndValidationReportVersion(
      recordVersionIds: $recordVersionIds
      versionId: $versionId
      parentVersionId: $parentVersionId
    ) {
      verificationAndValidationReportVersion {
        id
        currentStatus {
          id
          name
        }
        title
        versionIdentifier
        verificationRecords {
          id
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
    }
  }
`;
