import { createStyles, makeStyles } from '@material-ui/core';

export const iconStyles = {
  icon: {
    marginLeft: 5,
    marginRight: 5,
    '&:hover': {
      cursor: 'pointer',
    },
    color: '#6E7583',
  },
  disabledMouse: {
    color: '#b3b3b3',
    marginLeft: 5,
    marginRight: 5,
    pointerEvent: 'auto',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

const actionStyles = {
  disabledIcon: {
    marginLeft: 5,
    marginRight: 5,
    color: '#6e7583',
  },
};

export const useStyles = makeStyles(() =>
  createStyles({
    ...actionStyles,
    ...iconStyles,
  }),
);
