function previousVersions(versions) {
  return versions.filter(
    (r) =>
      r.releasedAt !== null ||
      r.retiredAt !== null ||
      // We use startsWith instead of an === because some workflows
      // use "cancel" and some use "canceled"
      r.currentStatusName.startsWith('cancel'),
  );
}

export default previousVersions;
