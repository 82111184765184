import React from 'react';

import { LockOpen } from '@material-ui/icons';

import SmallCircle from './SmallCircle';
import ConnectBar from './ConnectBar';

export function Unlocked({ name }) {
  return (<>
    <ConnectBar color={name} visible />
    <SmallCircle
      content={<LockOpen style={{ fontSize: '180%', color: 'white' }} />}
      color={name}
      style={{
        backgroundColor: 'currentcolor',
        marginTop: -3,
        width: 30,
        height: 30,
      }}
    />
  </>)
}
