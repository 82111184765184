import { find as _find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, createContext } from 'react';
import { setDisplayName } from 'recompose';

import { Redirect } from '@reach/router';

import { getAuthToken } from 'utils';

import withRenewAuthToken from './withRenewAuthToken';
import flowRight from 'lodash/flowRight';

const AuthContext = createContext();

const TOKEN_EXP_BUFFER = 30 * 1000;

const VISITOR_ROUTES = ['/login', '/sign-out', '/forgot-password', '/forgot-username', '/reset-password'];

// FIXME: This will break if any visitor route is added that includes pattern matching.
function isVisitorRoute({ pathname }) {
  return !!_find(VISITOR_ROUTES, (path) => path === pathname);
}

class AuthProvider extends Component {
  static propTypes = {
    renewAuthToken: PropTypes.func.isRequired,
    renewAuthTokenLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    authToken: undefined,
  };

  constructor(props) {
    super(props);

    // This is a trick to help prevent unnecessary re-renders. All values
    // passed into a context provider should be inside the parent's state.
    this.state = {
      startRenewAuthTokenService: this.startRenewAuthTokenService,
    };
  }

  componentDidMount() {
    if (!this.isAuthed()) {
      const { pathname, search } = window.location;
      if (pathname !== '/login') {
        this.setState({ requestedPath: `${pathname}${search}` });
      } else {
        this.setState({ requestedPath: '/' });
      }
    }

    // When initial render completes, refresh the token and kick off the renewal service.
    return this.startRenewAuthTokenService();
  }

  componentDidUpdate(prevProps) {
    if (this.props.authToken && prevProps.renewAuthTokenLoading && !this.props.renewAuthTokenLoading) {
      // the token was just refreshed. save it and start a new token service.
      window.memoryDB.setItem('token', JSON.stringify(this.props.authToken));
      this.startRenewAuthTokenService();
    }
  }

  componentWillUnmount() {
    // clear timeout for token refresh
    clearTimeout(this.renewAuthTokenService);
  }

  isAuthed = () => {
    const [authToken] = getAuthToken();
    const authRequired = !isVisitorRoute(window.location);
    return authToken || !authRequired;
  };

  getRenewTokenTimeout = (expiry) => {
    // The token is fetched from the stored token instead of props becase
    // props.authToken.payload data will only be available after the
    // mutation is called. Also, this may be initiated from outside of
    // this component via a context consumer.
    // eslint-disable-next-line no-unused-vars
    if (expiry) {
      const EXP_AT_MS = expiry * 1000;
      return EXP_AT_MS - TOKEN_EXP_BUFFER - Date.now();
    }
  };

  startRenewAuthTokenService = () => {
    const [token, payload] = getAuthToken();
    if (token) {
      const msUntilRenew = this.getRenewTokenTimeout(payload.exp);
      this.renewAuthTokenService = setTimeout(this.props.renewAuthToken, msUntilRenew);
    }
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        <>
          {this.props.children}
          {!this.isAuthed() && <Redirect noThrow to="/login" />}
        </>
      </AuthContext.Provider>
    );
  }
}

export { AuthContext };

export default flowRight([setDisplayName('AuthProvider'), withRenewAuthToken])(AuthProvider);
