import qs from 'qs';
import get from 'lodash/get';

import { BuilderView, TableView, VersionsView, ComingSoon, EffectiveView, RecordsView} from 'components';
import { LinksGraph } from 'compositions';
import { Views } from 'workspaces';

function getLayoutComponent(name: string) {
  switch (name) {
    case Views.Set.id:
      return BuilderView;
    case Views.Builder.id:
      return BuilderView;
    case Views.Effective.id:
      return EffectiveView;
    case Views.Versions.id:
      return VersionsView;
    case Views.Table.id:
      return TableView;
    case Views.LinksGraph.id:
      return LinksGraph;
    case Views.StepTrace.id:
      return LinksGraph;
    case Views.Records.id:
      return RecordsView;
    default:
      return ComingSoon;
  }
}

function getActiveWorkspaceView(location) {
  const params = qs.parse(get(location, 'search', '').slice(1));
  return get(params, 'view');
}

export { getActiveWorkspaceView, getLayoutComponent };
