import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    content: {
      width: 80,
      display: 'flex',
    },
    pass: {
      color: '#0B915C',
    },
    fail: {
      color: '#F65955',
    },
  }),
);
