import React from 'react';
import flowRight from 'lodash/flowRight';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import { IconButton, withStyles, createStyles } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';

import LongCardPaper from './LongCardPaper';

const styles = (theme) =>
  createStyles({
    iconButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0.5),
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 0.7,
      },
    },
    mediumIcon: {
      width: 25,
    },
  });

function AddButton({ label, onClick, classes, disabled, i18n }) {
  return (
    <IconButton
      aria-label={i18n._(t`${label}`)}
      classes={{ root: classes.iconButton }}
      disabled={disabled}
      disableRipple
      onClick={onClick}
    >
      <AddCircle
        classes={{ root: classes.mediumIcon }}
        color="inherit"
        data-testid="create-new-member-icon"
        fontSize="inherit"
        titleAccess={i18n._(t`${label}`)}
      />
    </IconButton>
  );
}

function ControlBar(props: {
  label: string;
  onClickPlus: (e: any) => void;
  classes;
  i18n;
  children: JSX.Element[];
  disabled: boolean;
  isShowAddButton: boolean;
}) {
  const { label = '', onClickPlus, classes, disabled = false, i18n, isShowAddButton = true } = props;

  return (
    <>
      <LongCardPaper>
        {isShowAddButton && (
          <AddButton onClick={onClickPlus} label={label} classes={classes} disabled={disabled} i18n={i18n} />
        )}
        {props.children}
      </LongCardPaper>
    </>
  );
}

export default flowRight(
  withI18n(),
  withStyles(styles),
)(ControlBar);
