import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import Book from '@material-ui/icons/Book';

import { DocumentToolbar } from '../mixins/Document';
import slugs from '../../displaySlugs';
import { docMetadata } from '../mixins/Document';
import { docStylePayloadFields } from '../mixins/DocumentBased/gql';

import { QueryBasedPayloadFields } from '../mixins/DocumentBased/QueryBasedPayloadFields';

import RecordPage from './RecordPage';

const versionQuery = gql`
  query supplierQuestionnaireVersion($id: ID!) {
    supplierQuestionnaireVersion(id: $id) {
      ${docStylePayloadFields}
    }
  }
`;

const itemType = 'supplierQuestionnaire';

const PayloadFields = QueryBasedPayloadFields(versionQuery, itemType);

export default {
  categoryLabel: t`Supplier Questionnaire`,
  displaySlug: slugs[itemType],
  label: t`Supplier Questionnaire`,
  listViewQuery: gql`
    query supplierQuestionnaireListViewQuery {
      supplierQuestionnaires {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          body
          versionIdentifier
          currentStatusName
        }
      }
    }
  `,
  itemType,
  icon: Book,
  ...docMetadata,
  PayloadFields,
  EffectiveViewOptions: DocumentToolbar,
  RecordPage,
};
