import { makeStyles } from '@material-ui/styles';

const styles = (theme) => ({
  modalInside: {
    padding: theme.spacing(3),
    marginTop: '-55%',
    width: '360px',
  },
  permissionBack: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  modalBack: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  permissionsContent: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    textAlign: 'center',
  },
  modalContent: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    textAlign: 'center',
  },
  modalSubtitle: {
    color: 'rgba(0,0,0,0.6)',
  },
  closeIcon: {
    float: 'right',
    '&:hover': { cursor: 'pointer' },
  },
  infoModal: {
    top: '33% !important', // unfortunately MUI injects css directly into the style attr
    width: 300,
    left: '50% !important', // unfortunately MUI injects css directly into the style attr
    marginLeft: -150,
  },
  errorIcon: {
    fontSize: '30px',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    marginBottom: theme.spacing(1),
  },
  hoverPointer: { '&:hover': { cursor: 'pointer' } },
  disabled: { '&:hover': { cursor: 'not-allowed' }, color: 'rgba(0, 0, 0, 0.33)' },
  timelineContainer: {
    paddingTop: theme.spacing(4),
    overflowX: 'scroll',
  },
  timelineHeader: {
    position: 'fixed',
  },
});

export const useStyles = makeStyles(styles);
export default styles;
