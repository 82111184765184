import gql from 'graphql-tag';

export const COPY_USERS_TRAINING_PLAN = gql`
  mutation CopyUsersTrainingPlan($trainingPlanId: ID!, $userIds: [ID!]!) {
    copyUsersTrainingPlanVersions(trainingPlanId: $trainingPlanId, userIds: $userIds) {
      trainingPlanVersion {
        id
      }
    }
  }
`;
