import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      marginBottom: theme.spacing(1.5),
    },
    text: {
      minWidth: theme.spacing(19),
    },
  }),
);
