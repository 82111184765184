import React from 'react';

import { t } from '@lingui/macro';
import get from 'lodash/get';

import { Rule } from 'assets/icons';
import slugs from 'displaySlugs';
import Views from 'workspaces/views';
import MakeLink from 'components/MakeLink';

import { ItemTypeDefinition } from '../index';
import { LIST_VALIDATION_RECORDS } from './gql';
import {
  validationRecordsQuery_validationRecords as ValidationRecord,
  validationRecordsQuery_validationRecords_currentVersion_deviationVersions as DeviationVersion,
} from './__generated__/validationRecordsQuery';

const itemType = 'validationRecord';

const listViewColumns = [
  {
    headerName: 'ID',
    field: 'customIdentifier',
    type: 'id',
  },
  {
    headerName: 'Title',
    field: 'currentVersion.title',
    type: 'regular_text',
  },
  {
    headerName: 'Version',
    field: 'currentVersion',
    type: 'version',
  },
  {
    headerName: 'Status',
    type: 'status',
    field: 'currentVersion.currentStatus.name',
  },
  {
    headerName: 'Test Result',
    type: 'boolean_result',
    field: 'currentVersion.isFulfillAllAcceptanceCriteria',
  },
  {
    headerName: 'Deviations',
    type: 'custom',
    field: 'currentVersion.deviationVersions',
    enableRowGroup: true,
    keyCreator: (row) => {
      return row.value.map((version) => version.item.customIdentifier).join(', ');
    },
    render: (row) => {
      const isColumnGroupedBy = Boolean(
        row.columnApi.columnModel.rowGroupColumns.find((column) => column.colId === 'currentVersion.deviationVersions'),
      );
      if (isColumnGroupedBy) {
        return row.value;
      }
      const validationRecord: ValidationRecord = row.data;
      return (
        <div style={{ display: 'inline-flex' }}>
          <span>
            {validationRecord.currentVersion.deviationVersions.map((devVersion: DeviationVersion, index) => (
              <>
                {index > 0 && ', '}
                <MakeLink newTab={true} item={devVersion.item}>
                  {get(devVersion, 'item.customIdentifier')}
                </MakeLink>
              </>
            ))}
          </span>
        </div>
      );
    },
  },
  {
    headerName: 'Originates From',
    type: 'regular_text',
    field: 'currentVersion.deviceValidationVersion.item.customIdentifier',
  },
  {
    headerName: 'Origination Version',
    type: 'version',
    field: 'currentVersion.deviceValidationVersion',
  },
  {
    headerName: 'Protocol Version Record ID',
    type: 'regular_text',
    field: 'currentVersion.protocolVersionRecordNumber',
  },
  {
    headerName: 'Initiation Date',
    type: 'date',
    field: 'currentVersion.createdAt',
  },
  {
    headerName: 'Owner',
    type: 'user',
    field: 'currentVersion.owner',
  },
  {
    headerName: 'Approver',
    type: 'user',
    field: 'currentVersion.approver',
  },
];

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Validation Record`,
  categoryLabel: t`Validation Records`,
  icon: Rule,
  listViewQuery: LIST_VALIDATION_RECORDS,
  listViewColumns,
  listWorkspace: [Views.Table, Views.LinksGraph],
  recordWorkspace: [Views.Builder, Views.Effective, Views.Versions],
} as ItemTypeDefinition;
