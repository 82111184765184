import { PageType } from 'setup/routes';
import Views, { View } from './views';
import { ItemTypeIndex } from '../itemTypes';

const Workspaces = {
  SingleRecord: [Views.Builder, Views.Effective, Views.Versions],
  RecordList: [Views.Table, Views.LinksGraph],
};

export function workspaceViewsForItemType(itemTypeName: string, pageType: PageType) {
  const itemType = ItemTypeIndex[itemTypeName];
  if (!itemType) return null;

  switch (pageType) {
    case PageType.List:
      return itemType.listWorkspace || Workspaces.RecordList;
    case PageType.SingleRecord:
      return itemType.recordWorkspace || Workspaces.SingleRecord;
  }
}

export function workspaceViewsFromRouteName(routeName: string) {
  if (routeName === undefined) return null;
  const [itemTypeName, pageType] = routeName.split(':');
  return workspaceViewsForItemType(itemTypeName, pageType as PageType);
}

export { View, Views };
export default Workspaces;
