import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { DateTime } from 'luxon';

import BaseDialog from 'components/BaseDialog';
import Button from 'components/Button';
import Spacer from 'components/Spacer';
import Buttons from 'components/core/Buttons';
import ExpandCarrot from 'components/core/ExpandCarrot';
import MakeLink from 'components/MakeLink';

import { useStyles } from './styles';
import { useTaskToggle } from './hooks';
import GetStarted from './GetStarted';

interface Props {
  task;
}

function PrecomplianceTaskSection(props: Props) {
  const { task } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [showCompleted, setShowCompleted] = useState(false);

  const { complete, uncomplete } = useTaskToggle(props.task.id);

  return (
    <div>
      <div className={classes.taskTitle}>
        <MakeLink item={task.task}>
          <Typography
            className={classes.taskTitleText}
            variant="subtitle1"
            color={task.completedAt ? 'primary' : 'inherit'}
          >
            {task.completedAt ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
            <span>{task.title}</span>
          </Typography>
        </MakeLink>
        <ExpandCarrot expanded={expanded} setExpanded={setExpanded} />
      </div>
      <Spacer factor={1} />
      {expanded && (
        <div className={classes.contentSection}>
          <Typography variant="body2">{task.content}</Typography>
          <Spacer factor={2} />
          {task.completedAt && (
            <>
              <Typography variant="subtitle2" color="primary" className={classes.completedBy}>
                Marked as done on {DateTime.fromISO(props.task.completedAt).toFormat('dd/MM/yyyy')} by{' '}
                {task.completedBy.username}
              </Typography>
              <Spacer factor={0.5} />
            </>
          )}
          <Buttons className={classes.buttons}>
            <GetStarted task={task} />
            {task.completedAt ? (
              <Tooltip title="Reopen">
                <Button
                  color="primary"
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    uncomplete()
                      .then(() => setLoading(false))
                      .catch((err) => {
                        throw new Error(`Error uncompleting precompliance task: ${err}`);
                      });
                  }}
                  variant="contained"
                >
                  Completed
                </Button>
              </Tooltip>
            ) : (
              <Button
                color="primary"
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  complete()
                    .then(() => setLoading(false))
                    .then(() => setShowCompleted(task.successMessage !== null))
                    .catch((err) => {
                      throw new Error(`Error completing precompliance task: ${err}`);
                    });
                }}
                variant="contained"
              >
                Mark as Done
              </Button>
            )}
          </Buttons>
        </div>
      )}
      {showCompleted && (
        <BaseDialog
          Icon={TrendingUpIcon}
          onClose={() => setShowCompleted(false)}
          open
          textAlign="left"
          title={task.successHeader || 'Keep up the good work!'}
        >
          <Typography variant="body1">{task.successMessage}</Typography>
        </BaseDialog>
      )}
    </div>
  );
}

export default PrecomplianceTaskSection;
