import React from 'react';
import { ChangeSummary } from 'compositions/TimelineGroup/steps/views';

export default {
  initialStep: 'changeSummary',
  internalSteps: {
    changeSummary: {
      component: (props) => <ChangeSummary buttonText="Done" smallCircle {...props} />,
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ changeSummary }) => ({ ...changeSummary }),
};
