export const RetirementStatus = {
  RetirementInitiated: 'retirement_initiated',
  ApprovedRetirement: 'retirement_approved',
  RetirementRejected: 'retirement_rejected',
  Retired: 'retired',
};

export const Order = {
  [RetirementStatus.RetirementInitiated]: 0,
  [RetirementStatus.ApprovedRetirement]: 1,
  [RetirementStatus.RetirementRejected]: 2,
  [RetirementStatus.Retired]: 3,
};
