import React from 'react';
import { Trans } from '@lingui/macro';

import { Button, Fab, Modal, Paper, Typography, withStyles } from '@material-ui/core';

import Spacer from 'components/Spacer';

import styles from './sharedStyles';

const permissionHeading = <Trans>Permission Required</Trans>;
const permissionBody = <Trans>You don't have permission to perform this action.</Trans>;
const releaseHeading = <Trans>Release not permitted</Trans>;
const releaseBody = <Trans>This item can only be released upon close of its associated Change Order.</Trans>;
const transitioningLockedHeading = <Trans>Item Status Locked</Trans>;
const transitioningLockedBody = (
  <Trans>
    Item status updates require an upgraded license type. Contact your administrator to upgrade your license.
  </Trans>
);

function PermissionFailModal({ classes, closeModal, failType, open }) {
  return (
    <Modal
      open={open}
      onClose={closeModal}
      style={{
        top: '33%',
        width: 300,
        left: '50%',
        marginLeft: -150,
      }}
    >
      <Paper className={classes.permissionsContent}>
        <Fab className={classes.errorIcon} size="large" variant="round" disabled>
          !
        </Fab>
        <Typography variant="h3">
          {failType === 'permissions'
            ? permissionHeading
            : failType === 'release'
            ? releaseHeading
            : transitioningLockedHeading}
        </Typography>
        <Spacer factor={2} />
        <Typography variant="body1">
          {failType === 'permissions' ? permissionBody : failType === 'release' ? releaseBody : transitioningLockedBody}
        </Typography>
        <Spacer factor={2} />
        <Button color="primary" className={classes.permissionBack} variant="outlined" onClick={closeModal}>
          {failType === 'itemTransitioning' ? <Trans>Dismiss</Trans> : <Trans>Back</Trans>}
        </Button>
      </Paper>
    </Modal>
  );
}

PermissionFailModal.defaultProps = {
  failType: 'permissions',
};

export default withStyles(styles)(PermissionFailModal);
