import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Query, useQuery } from 'react-apollo';

const query = gql`
  query AllJobRoles {
    jobRoles {
      id
      formattedRoleName
      roleName
      roleType
      status
      customIdentifier
      createdAt
      creator {
        id
        fullName
      }
      usersWithRole {
        id
        fullName
        jobRoles {
          id
          formattedRoleName
        }
      }
    }
  }
`;

const withJobRoles = (Component) => (componentProps) => (
  <Query query={query}>
    {({ data, error }) => {
      if (!data) return null;
      if (error) throw new Error(`Error loading job roles: ${error}`);
      return <Component {...componentProps} jobRoles={get(data, 'jobRoles')} />;
    }}
  </Query>
);

export function useJobRoles() {
  return useQuery(query);
}

export default withJobRoles;
