import AccountTree from 'assets/icons/AccountTree';
import BubbleChart from '@material-ui/icons/BubbleChart';
import Error from '@material-ui/icons/Error';
import FolderShared from '@material-ui/icons/FolderShared';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import ReportIcon from '@material-ui/icons/Report';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Inventory from 'assets/icons/Inventory';
import Factory from 'assets/icons/Factory';
import Warehouse from 'assets/icons/Warehouse';
import Medication from 'assets/icons/Medication';

import { t } from '@lingui/macro';

import ItemTypes from 'itemTypes';
import itemTypeToCategory from './itemTypeToCategory';

const categories = [
  {
    icon: AccountTree,
    name: t`Project Management`,
    slug: 'project-management',
    children: [itemTypeToCategory(ItemTypes.DDPlan), itemTypeToCategory(ItemTypes.DDReport)],
  },
  {
    icon: LibraryBooks,
    name: t`Design Inputs`,
    slug: 'design-inputs',
    children: [
      itemTypeToCategory(ItemTypes.SystemArchitecture),
      itemTypeToCategory(ItemTypes.UserNeed),
      itemTypeToCategory(ItemTypes.UserNeedsSet),
      itemTypeToCategory(ItemTypes.Requirement),
      itemTypeToCategory(ItemTypes.RequirementsSet),
      itemTypeToCategory(ItemTypes.TraceMatrix),
    ],
  },
  {
    icon: Error,
    name: t`Risk Management File`,
    slug: 'risk-management',
    isDashboard: true,
    excludeFromCategoryIndex: false,
    children: [
      itemTypeToCategory(ItemTypes.RiskAssessmentDocument),
      itemTypeToCategory(ItemTypes.DeviceSafetyCharacteristicsReport),
      {
        traceOnly: true,
        icon: ItemTypes.DeviceSafetyCharacteristicsReport.icon,
        slug: 'dsc-report',
        name: t`Device Safety Characteristics`,
        itemType: 'deviceSafetyCharacteristic',
        parentRouteName: 'deviceSafetyCharacteristicsReport:list',
      },
      itemTypeToCategory(ItemTypes.RiskNote),
      itemTypeToCategory(ItemTypes.Risk),
      itemTypeToCategory(ItemTypes.RiskControl),
      itemTypeToCategory(ItemTypes.RiskAnalysis),
      itemTypeToCategory(ItemTypes.RiskManagementPlan),
      {
        icon: ReportIcon,
        name: t`Harms Library`,
        slug: 'harms',
      },
      {
        icon: ReportProblemIcon,
        name: t`Hazards Library`,
        slug: 'hazards',
      },
      itemTypeToCategory(ItemTypes.FailureModesAndEffectsAnalysis),
      itemTypeToCategory(ItemTypes.RiskManagementReport),
    ],
  },
  {
    icon: Error,
    name: t`Software File`,
    slug: 'software',
    isDashboard: false,
    excludeFromCategoryIndex: false,
    children: [
      itemTypeToCategory(ItemTypes.SoftwareDevelopmentPlan),
      itemTypeToCategory(ItemTypes.SoftwareSafetyClassification),
    ],
  },
  {
    icon: Error,
    name: t`Cybersecurity`,
    slug: 'cybersecurity',
    isDashboard: false,
    excludeFromCategoryIndex: false,
    children: [
      itemTypeToCategory(ItemTypes.CybersecurityPlan),
      itemTypeToCategory(ItemTypes.CybersecuritySystemCharacterization),
      itemTypeToCategory(ItemTypes.CyberattackAnalysis),
      itemTypeToCategory(ItemTypes.CommonWeaknessAssessment),
      itemTypeToCategory(ItemTypes.SoftwareBillOfMaterial),
      itemTypeToCategory(ItemTypes.CybersecurityReport),
    ],
  },
  {
    icon: FolderShared,
    name: t`Usability Engineering File`,
    slug: 'usability-engineering',
    isDashboard: false,
    excludeFromCategoryIndex: false,
    children: [itemTypeToCategory(ItemTypes.DeviceUsageDescription)],
  },
  {
    icon: LibraryBooks,
    name: t`Regulatory`,
    slug: 'regulatory',
    children: [
      itemTypeToCategory(ItemTypes.RegulatoryStrategyPlan),
      itemTypeToCategory(ItemTypes.IndicationsForUse),
      itemTypeToCategory(ItemTypes.DeviceDescription),
      itemTypeToCategory(ItemTypes.StandardDocument),
      itemTypeToCategory(ItemTypes.Labeling),
      itemTypeToCategory(ItemTypes.SoftwareSafetyClassification),
    ],
  },
  {
    icon: BubbleChart,
    name: t`Testing`,
    slug: 'testing',
    children: [
      itemTypeToCategory(ItemTypes.TestProtocolNote),
      itemTypeToCategory(ItemTypes.VerificationAndValidationPlan),
      itemTypeToCategory(ItemTypes.DeviceVerification),
      itemTypeToCategory(ItemTypes.DeviceValidation),
      itemTypeToCategory(ItemTypes.VerificationAndValidationReport),
      itemTypeToCategory(ItemTypes.VerificationRecord),
      itemTypeToCategory(ItemTypes.ValidationRecord),
      itemTypeToCategory(ItemTypes.Deviation),
    ],
  },
  {
    icon: Inventory,
    name: t`Design Outputs`,
    slug: 'design-outputs',
    children: [
      itemTypeToCategory(ItemTypes.DesignOutput),
      itemTypeToCategory(ItemTypes.InProcessInspection),
      itemTypeToCategory(ItemTypes.IncomingQualityInspection),
      itemTypeToCategory(ItemTypes.FinalDeviceInspection),
    ],
  },
  {
    icon: Factory,
    name: t`Device Master Record`,
    slug: 'device-master-records-product',
    excludeFromCategoryIndex: false,
    children: [itemTypeToCategory(ItemTypes.DeviceMasterRecord)],
  },
  {
    icon: Warehouse,
    name: t`Device History Record`,
    slug: 'device-history-records-product',
    excludeFromCategoryIndex: false,
    children: [
      itemTypeToCategory(ItemTypes.IncomingQualityInspectionRecord),
      itemTypeToCategory(ItemTypes.InProcessInspectionRecord),
      itemTypeToCategory(ItemTypes.FinalDeviceInspectionRecord),
    ],
  },
  {
    icon: Medication,
    name: t`Drug Master File`,
    slug: 'drug-master-files-product',
    excludeFromCategoryIndex: false,
    children: [
      itemTypeToCategory(ItemTypes.QualityByDesignPlan),
      itemTypeToCategory(ItemTypes.TargetProductProfile),
      itemTypeToCategory(ItemTypes.PrimaryModeOfAction),
    ],
  },
];

const designHistoryFile = {
  id: 'product',
  label: t`Product`,
  shortLabel: t`Product`,
  icon: VerifiedUser,
  categories,
  colorKey: 'palette.appWindows.designHistoryFile',
};
export default designHistoryFile;
