import React from 'react';

import PropTypes from 'prop-types';
import { setDisplayName } from 'recompose';
import flowRight from 'lodash/flowRight';

import { Grid, withStyles } from '@material-ui/core';

import Header from 'components/Header';
import { AppWindowControl, Sidebar, SlimNav } from 'compositions';
import { withEnabledCategories } from 'compositions/WithCategories';
import { withTreeDrawerContext } from 'contexts/treeDrawerContext';
import { withNavigation } from 'contexts/navigationContext';
import { withSidebarWorkspaces } from 'contexts/workspacesContext';
import { withItemTypeMetadata } from 'utils/withItemTypeMetadata';

import { styles } from './styles';

function AuthorizedLayout({ children, classes, allItemTypeMetadata, activeWorkspaceView }) {
  const url = window.location.href;
  const isTableView = url.endsWith('?view=Table') || url.endsWith('/employees') || url.endsWith('/approved-supplier');
  return (
    <div className={classes.root}>
      <AppWindowControl>
        {({ appWindow, setAppWindow }) => {
          return (
            <>
              <SlimNav
                allItemTypeMetadata={allItemTypeMetadata}
                activeAppWindowId={appWindow.id}
                setAppWindow={setAppWindow}
              />
              <Sidebar
                allItemTypeMetadata={allItemTypeMetadata}
                categoryTree={appWindow.categories}
                appWindowShortLabel={appWindow.shortLabel}
                AppWindowIcon={appWindow.icon}
              />
            </>
          );
        }}
      </AppWindowControl>
      <Header />
      <div className={classes.contentContainer}>
        <main className={isTableView ? classes.content : classes.contentGutters}>{children}</main>
      </div>
    </div>
  );
}

const AuthorizedLayoutWithEnabledCategories = flowRight([
  setDisplayName('AuthorizedLayoutWithEnabledCategories'),
  withTreeDrawerContext,
  withEnabledCategories,
  withNavigation,
  withSidebarWorkspaces,
  withItemTypeMetadata,
])(AuthorizedLayout);

function Layout({ children, classes, variant }) {
  if (variant === 'unauthorized') {
    return (
      <div className={classes.fullPageBackground}>
        <Grid container alignItems="center" justify="center" classes={{ container: classes.unauthorizedRoot }}>
          <Grid item xs={10}>
            {children}
          </Grid>
        </Grid>
      </div>
    );
  }

  return <AuthorizedLayoutWithEnabledCategories classes={classes}>{children}</AuthorizedLayoutWithEnabledCategories>;
}

Layout.propTypes = {
  variant: PropTypes.oneOf(['unauthorized']),
};

Layout.defaultProps = {
  variant: undefined,
};

export default flowRight([withStyles(styles, { withTheme: true })])(Layout);
