import React, { useState } from 'react';
import { useMutation } from 'react-apollo';

import AddCircle from '@material-ui/icons/AddCircle';

import MakeLink from 'components/MakeLink';
import Table from 'components/core/Table';
import { IconButton, Spacer, WorkflowIcon, VersionAndStatusDot } from 'components';

import AddDeviation from './AddDeviation';
import { CREATE_DEVIATION_AND_LINK } from './gql';
import { VerificationRecordVersionQuery_verificationRecordVersion } from '../__generated__/VerificationRecordVersionQuery';

interface DeviationsTableProps {
  currentVersion: VerificationRecordVersionQuery_verificationRecordVersion;
}

const order = ['to_do', 'void', 'failed', 'archived', 'checked', 'completed'];

const DeviationsTable = (props: DeviationsTableProps) => {
  const { currentVersion } = props;

  const [createReferencedItems] = useMutation(CREATE_DEVIATION_AND_LINK, {
    refetchQueries: ['VerificationRecordVersionQuery'],
  });

  const { locked } = currentVersion;

  const [showPopup, setShowPopup] = useState(false);

  const handleSubmitAddDeviation = async () => {
    try {
      await createReferencedItems({
        variables: {
          verificationRecordVersionId: currentVersion.id,
        },
      });
      return true;
    } catch (error) {
      throw new Error(`Error creating deviation: ${error}`);
    }
  };

  return (
    <div>
      <Table
        emptyDataSourceMessage={'No deviations to display'}
        data={currentVersion.deviationVersions}
        columns={[
          {
            title: 'Deviation ID',
            render: (row) => row.deviationId,
          },
          {
            title: 'ID',
            render: (row) => (
              <MakeLink item={row.item} newTab>
                {row.item.customIdentifier}
              </MakeLink>
            ),
          },
          {
            title: 'Title',
            field: 'item.currentVersion.title',
          },
          {
            title: 'Version',
            align: 'center',
            render: (refItem) => (
              <div style={{ display: 'inline-block' }}>
                <VersionAndStatusDot
                  style={{ fontSize: 13 }}
                  version={refItem.item.currentVersion.versionIdentifier}
                  status={refItem.item.currentVersion.currentStatus.name}
                />
              </div>
            ),
          },
          {
            title: 'Status',
            render: (refItem) => (
              <WorkflowIcon
                type={refItem.item.currentVersion.currentStatus.name}
                completed
                size="small"
                displayTooltip
              />
            ),
            customSort: (a: any, b: any) => {
              const aPosition = order.indexOf(a.currentStatus.name);
              const bPosition = order.indexOf(b.currentStatus.name);
              return aPosition - bPosition;
            },
            align: 'center',
          },
        ]}
      />
      <Spacer factor={1} />
      {!locked && (
        <IconButton Icon={AddCircle} onClick={() => setShowPopup((x) => !x)} size="large" tooltip="Add deviation" />
      )}
      {showPopup && (
        <AddDeviation recordIdent={currentVersion.item.customIdentifier} addDeviation={handleSubmitAddDeviation} />
      )}
    </div>
  );
};

export { DeviationsTable };
