import React from 'react';
import { WorkflowType } from 'workflows/workflowType';
import GenericWorkflow from 'workflows/GenericWorkflow';
import { TimelineGroup } from 'compositions';

export interface WorkflowProps {
  key: string;
  workflow: string;
  status: string;
  itemId: string;
  itemType: string;
  refetchQueries: string[];
  itemData;
}

const Workflow: React.FC<WorkflowProps> = (props: WorkflowProps) => {
  switch (props.workflow) {
    case WorkflowType.Generic:
      return (
        <GenericWorkflow
          key={props.status}
          itemType={props.itemType}
          itemId={props.itemId}
          queriesToRefetch={props.refetchQueries}
          itemData={props.itemData}
        />
      );
    // otherwise default to deprecated timeline component
    default:
      return (
        <TimelineGroup
          key={props.status}
          itemType={props.itemType}
          itemId={props.itemId}
          queriesToRefetch={props.refetchQueries}
          itemData={props.itemData}
        />
      );
  }
};
export default Workflow;
