import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import get from 'lodash/get';

import { HorizontalBody, VerticalBody } from 'components/LongCardBodyVariants';

class LongCardBody extends Component {
  render() {
    const {
      versionId,
      primaryBodyText,
      secondaryBodyText,
      queryToRefetch,
      locked,
      variant,
      rightColumnComponent,
    } = this.props;

    switch (variant) {
      case 'vertical':
        return (
          <>
            <VerticalBody
              versionId={versionId}
              primaryBodyText={primaryBodyText}
              initialValue={get(secondaryBodyText, 'attrValue')}
              attrName={get(secondaryBodyText, 'attrName')}
              queryToRefetch={queryToRefetch}
              locked={locked}
              Label={
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  <Trans>Response:</Trans>
                </Typography>
              }
            />
          </>
        );
      case 'horizontal':
        return (
          <HorizontalBody
            versionId={versionId}
            primaryBodyText={primaryBodyText}
            secondaryBodyText={secondaryBodyText}
            queryToRefetch={queryToRefetch}
            locked={locked}
            rightColumnComponent={rightColumnComponent}
          />
        );
      default:
        return null;
    }
  }
}
export default LongCardBody;
