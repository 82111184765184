import { listViewRouteName, singleRecordRouteName } from 'setup/routes/routeNames';
import { MessageDescriptor } from '@lingui/core';
import { ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function itemTypeToCategory({
  categoryLabel,
  displaySlug,
  icon,
  itemType,
  titleEditable,
}: {
  categoryLabel: MessageDescriptor | string;
  displaySlug?: string;
  icon: ComponentType<SvgIconProps>;
  itemType: string;
  titleEditable?: boolean;
}) {
  if (!displaySlug) {
    throw new Error(`Cannot create category for ${itemType}.  display_slug is null.`);
  }
  return {
    icon,
    name: categoryLabel,
    slug: displaySlug,
    parentRouteName: listViewRouteName(itemType),
    routeName: singleRecordRouteName(itemType),
    itemType,
    titleEditable,
  };
}
