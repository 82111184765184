import { t } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';

export const options: MessageDescriptor[] = [
  t`Patient`,
  t`Doctor`,
  t`Nurse`,
  t`Attending`,
  t`Scrub Nurse`,
  t`Caregiver`,
];

export const placeholder: MessageDescriptor = t`Select type(s)`;
