import React from 'react';

import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import School from '@material-ui/icons/School';
import Portrait from '@material-ui/icons/Portrait';
import BarChart from '@material-ui/icons/BarChart';

import { Trans } from '@lingui/macro';
import { useQuery } from 'react-apollo';

import Page from 'components/core/Page';
import SectionLabel from 'components/core/SectionLabel';
import { Spacer } from 'components';

import RecordsList from './RecordList';
import StatusCounts from './StatusCounts';

import { useStyles } from './styles';

import { TRAINING_DASHBOARD_QUERY } from './gql';
import { dashboard as DashboardData } from './__generated__/dashboard';

export interface TrainingModuleDashboardProps {
  path: string;
}

const TrainingDashboard: React.FC<TrainingModuleDashboardProps> = () => {
  const classes = useStyles();

  const { data, loading, error } = useQuery<DashboardData>(TRAINING_DASHBOARD_QUERY);

  if (error) throw new Error(error.message);

  if (loading)
    return (
      <Box style={{ textAlign: 'center', padding: '25px' }}>
        <CircularProgress />
      </Box>
    );

  return data ? (
    <div style={{ marginTop: -35 }}>
      <Page>
        <Grid item xs={12}>
          <div className={classes.header}>
            <School fontSize={'large'} className={classes.icon} />
            <Typography
              variant="h2"
              style={{ fontWeight: 400, lineHeight: '32px', fontStyle: 'normal', color: '#6E7583' }}
            >
              <Trans>Training Management Home</Trans>
            </Typography>
          </div>
          <Spacer factor={8} />
          <SectionLabel icon={BarChart}>
            <Typography
              variant="h3"
              component="span"
              style={{ fontSize: '18px', fontWeight: 400, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
            >
              Training Record Breakdown by Status
            </Typography>
          </SectionLabel>
          <StatusCounts data={data.trainingDashboard} />
        </Grid>
        <Grid item xs={12}>
          <SectionLabel icon={Portrait}>
            <Typography
              variant="h3"
              component="span"
              style={{ fontSize: '18px', fontWeight: 400, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
            >
              Incomplete Training Records
            </Typography>
          </SectionLabel>
          <RecordsList data={data.trainingRecordSearch} />
        </Grid>
      </Page>
    </div>
  ) : null;
};

export default TrainingDashboard;
