import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';

import {
  LabelingVersion as VersionQuery,
  LabelingVersionVariables as VersionProps,
} from './__generated__/LabelingVersion';
import {
  CreateLabelingAttachment as Create,
  CreateLabelingAttachmentVariables as CreateVars,
} from './__generated__/CreateLabelingAttachment';
import {
  UpdateLabelingAttachment as Update,
  UpdateLabelingAttachmentVariables as UpdateVars,
} from './__generated__/UpdateLabelingAttachment';
import {
  DestroyLabelingAttachment as Destroy,
  DestroyLabelingAttachmentVariables as DestroyVars,
} from './__generated__/DestroyLabelingAttachment';

export const createAttachment = gql`
  mutation CreateLabelingAttachment($attachmentURL: String!, $versionId: ID!) {
    createLabelingAttachment(attachment: $attachmentURL, versionId: $versionId) {
      labelingAttachment {
        id
        url
        versionId
      }
    }
  }
`;

export function useCreateAttachment() {
  return useMutation<Create, CreateVars>(createAttachment, {
    refetchQueries: ['LabelingVersion'],
  });
}

export const updateAttachment = gql`
  mutation UpdateLabelingAttachment(
    $attachmentId: ID!
    $attachmentURL: String
    $previewURL: String
    $description: String
  ) {
    updateLabelingAttachment(
      id: $attachmentId
      attachment: $attachmentURL
      preview: $previewURL
      description: $description
    ) {
      labelingAttachment {
        id
        url
        previewUrl
        description
        versionId
      }
    }
  }
`;

export function useUpdateAttachment() {
  return useMutation<Update, UpdateVars>(updateAttachment);
}

export const query = gql`
  query LabelingVersion($id: ID!) {
    labelingVersion(id: $id) {
      id
      summary
      body
      locked
      inputMethod
      labelingAttachments {
        id
        url
        previewUrl
        description
      }
      reasonForChange
      descriptionOfChange
      pdfDataUpload
      displayAsApplicationVersion
      item {
        id
        itemNumber
        customIdentifier
      }
      attachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      rawReferenceAttachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
    }
  }
`;

export function useVersion(id?: string) {
  const variables = id ? { id } : undefined;
  return useQuery<VersionQuery, VersionProps>(query, {
    skip: !id,
    variables,
  });
}

export const destroyAttachmentMutation = gql`
  mutation DestroyLabelingAttachment($id: ID!) {
    destroyLabelingAttachment(id: $id) {
      labelingAttachment {
        id
      }
    }
  }
`;

export function useDestroyAttachment() {
  return useMutation<Destroy, DestroyVars>(destroyAttachmentMutation, {
    refetchQueries: ['LabelingVersion'],
  });
}
