import React from 'react';
import { Popper } from '@material-ui/core';

export const menuOptions = {
  none: 0,
  user: 1,
  people: 2,
  more: 3,
  tags: 4,
};

export function createMenuClickHandler(menuName, activeMenuKey = 'activeMenu') {
  return function(event) {
    const activeMenu = this.state[activeMenuKey];
    this.setState({
      activeMenu: activeMenu === menuName ? menuOptions.none : menuName,
      popperAnchorEl: event.currentTarget,
    });
  };
}

export function createMenuPopper(menuName) {
  return function({ anchorEl, activeMenu, children, ...rest }) {
    return (
      <Popper
        anchorEl={anchorEl}
        open={activeMenu === menuName}
        id={activeMenu === menuName ? `${menuName}-popper` : null}
        disablePortal
        {...rest}
      >
        {children}
      </Popper>
    );
  };
}
