import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      backgroundColor: theme.palette.common.white,
    },
    inactive: {
      backgroundColor: '#dfdfdf',
    },
    tab: {
      alignItems: 'center',
      borderRadius: '20px 20px 0 0',
      display: 'flex',
      height: `${theme.spacing(5)}px`,
      justifyContent: 'center',
      width: `${theme.spacing(24)}px`,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    text: {
      fontWeight: 'bold',
    },
  }),
);
