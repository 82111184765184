import { WorkflowDefinition, WorkflowTransition } from '../types';
import Completed from './Completed';
import ApproveOrReject from './ApproveOrReject';
import ResolveFailure from './ResolveFailure';
import CompletedNoAssessment from './CompletedNoAssessment';
import TrainingRecordVoid from './TrainingRecordVoid';

const AttachmentDependentWorkflowSteps: WorkflowDefinition = {
  completed: Completed as WorkflowTransition,
  completedNoAssessment: CompletedNoAssessment as WorkflowTransition,
  approveOrReject: ApproveOrReject as WorkflowTransition,
  resolveFailure: ResolveFailure as WorkflowTransition,
  trainingRecordVoided: TrainingRecordVoid as WorkflowTransition,
};

export default AttachmentDependentWorkflowSteps;
