import React, { useState } from 'react';
import { useMutation } from 'react-apollo';

import WorkflowModal from 'components/WorkflowModal';
import { useStyles as useTimelineStyles } from 'compositions/TimelineGroup/styles';
import { transitionMutation as transMut } from 'utils/gql/timelineQueries';
import WorkflowModalContainer from 'components/WorkflowModalContainer';
import useItemType from 'pages/generic/useItemType';

import CurriculumTimeline from './CurriculumTimeline';
import { TransitionPayload, ServerAction } from './statuses';
import { useCurriculumWorkflowData } from './gql';
import Wizard, { TransitionWizard } from './Wizard';

const InitialWizardState = { currentWizard: null, clearError: false };
const useWizard = () => useState<{ currentWizard: TransitionWizard | null; clearError: boolean }>(InitialWizardState);

function CurriculumWorkflow(props: { itemId: string }) {
  const [{ currentWizard, clearError }, setWizard] = useWizard();
  const closeWizard = () => setWizard(InitialWizardState);

  const curriculum = useItemType('curriculum');

  const { data, loading, error, refetch } = useCurriculumWorkflowData(props.itemId);
  const classes: any = useTimelineStyles();
  const [createStatusTransition, { error: transErr }] = useMutation(transMut);

  if (loading || !data) return null;
  if (error) throw error;

  const { currentVersion, customIdentifier } = data.item;

  const createTransition = async (data: TransitionPayload) => {
    await setWizard({ currentWizard, clearError: false });
    if (data.action === ServerAction.StatusTransition) {
      if (!currentVersion) throw new Error(`Cant transition w/o current version (item: ${props.itemId})`);
      await createStatusTransition({
        variables: {
          input: {
            versionId: currentVersion.id,
            ...data.payload,
          },
        },
      });
    }

    if (!transErr) {
      closeWizard();
      await refetch();
    }
  };

  if (!currentVersion) throw new Error(`Null currentVersion provided for Curriculum ${props.itemId}`);
  return (
    <div className={classes.timelineBtnContainer}>
      <CurriculumTimeline
        currentVersion={currentVersion}
        startWizard={(t: TransitionWizard) => setWizard({ currentWizard: t, clearError: true })}
      />
      <WorkflowModal open={currentWizard !== null} onClose={closeWizard}>
        <WorkflowModalContainer onClose={closeWizard} showBackButton={false} onBack={closeWizard}>
          <Wizard
            error={clearError ? undefined : transErr}
            itemTypeMetadata={curriculum}
            itemCustomIdentifier={customIdentifier}
            createTransition={createTransition}
            currentVersion={currentVersion}
            type={currentWizard}
          />
        </WorkflowModalContainer>
      </WorkflowModal>
    </div>
  );
}

export default CurriculumWorkflow;
