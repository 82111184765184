import gql from 'graphql-tag';

export const CREATE_REFERENCED_ITEM = gql`
  mutation addVerificationRecordsReferencedItems($verificationRecordVersionId: ID!, $itemIds: [ID!]!) {
    addReferencedItemsToVerificationRecordVersion(
      verificationRecordVersionId: $verificationRecordVersionId
      itemIds: $itemIds
    ) {
      verificationRecordVersion {
        id
        deviationVersions {
          id
          item {
            id
            currentVersion {
              id
              currentStatus {
                id
                name
              }
              title
              versionIdentifier
            }
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
    }
  }
`;

export const CREATE_DEVIATION_AND_LINK = gql`
  mutation linkNewDeviationToVerificationRecord($verificationRecordVersionId: ID!) {
    linkNewDeviationToVerificationRecord(verificationRecordVersionId: $verificationRecordVersionId) {
      verificationRecordVersion {
        id
        deviationVersions {
          id
          item {
            id
            currentVersion {
              id
              currentStatus {
                id
                name
              }
              title
              versionIdentifier
            }
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
    }
  }
`;
