import React, { useContext, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { Description, SwapHoriz } from '@material-ui/icons';
import EmojiPeople from 'assets/icons/EmojiPeople';

import get from 'lodash/get';
import Page from 'components/core/Page';
import Panel from 'components/core/Panel';
import SectionLabel from 'components/core/SectionLabel';
import { LockedContext, withLockedContext } from 'contexts/lockedContext';

import { PayloadFieldsProps } from 'itemTypes';

import { useTrainingRecordVersion } from './gql';
import TrainingAssessment from './TrainingAssessment';
import TrainingContext from './TrainingContext';
import TrainingMaterials from './TrainingMaterials';

import { useStyles } from './TrainingMaterials/styles';

import { TrainingRecordCategoryTitle } from '../index';

function PayloadFields(props: PayloadFieldsProps) {
  const { data, loading, error } = useTrainingRecordVersion(props.versionId!);
  const classes = useStyles();

  const { setLocked } = useContext(LockedContext);

  useEffect(() => {
    setLocked(get(data, 'trainingRecordVersion.locked'));
  }, [data]); // eslint-disable-line

  if (error) {
    throw new Error(`Error loading TrainingRecordVersion ${props.versionId}: ${error.message}`);
  }

  if (loading || !data) return null;

  const { trainingRecordVersion } = data;
  const { source, category } = trainingRecordVersion;

  return (
    <Page>
      <Grid item xs={12}>
        <SectionLabel icon={EmojiPeople}>
          Introduction to Training Record
          <SectionLabel.HelperText>
            You have been assigned a training. Please complete the following steps.
          </SectionLabel.HelperText>
        </SectionLabel>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <div className={classes.fullHeight}>
              <Panel>
                <Panel.Heading icon={Description}>Training Record Type</Panel.Heading>
                <Typography variant="caption">{TrainingRecordCategoryTitle[category]}</Typography>
              </Panel>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className={classes.fullHeight}>
              <Panel>
                <Panel.Heading icon={SwapHoriz}>Reason for Training Record</Panel.Heading>
                <Typography variant="caption">{trainingRecordVersion.reasonForTraining}</Typography>
              </Panel>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <TrainingContext dueDate={trainingRecordVersion.dueDate} source={trainingRecordVersion.source} />
      </Grid>
      {source.__typename === 'CourseVersion' && (
        <>
          <Grid item xs={12}>
            <TrainingMaterials courseVersion={source} versionId={trainingRecordVersion.id} />
          </Grid>
          <Grid item xs={12}>
            <TrainingAssessment
              assessmentGrader={source.assessmentGrader}
              assessmentUrl={get(source.attachment, 'file.url')}
              trainingFormat={source.trainingFormat}
              uploadedRecord={get(trainingRecordVersion, 'uploadedRecord')}
              versionId={trainingRecordVersion.id}
            />
          </Grid>
        </>
      )}
    </Page>
  );
}

export default withLockedContext(PayloadFields);
