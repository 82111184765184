import React, { useState } from 'react';
import get from 'lodash/get';

import CommentsControl from './CommentsControl';
import TracingControl from './TracingControl';
import TagsControl from './TagsControl';
import BottomRowContainer from './BottomRowContainer';

interface Props {
  itemRecord: any;
  queryToRefetch: string;
  versionId: any;
  disabled?: boolean;
  parentSetItemSlug?: string;
  renderDeleteControl?: () => JSX.Element;
}

function BottomRowControls({
  itemRecord,
  queryToRefetch,
  versionId,
  parentSetItemSlug,
  disabled,
  renderDeleteControl,
}: Props) {
  const [showComments, setShowComments] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showTracing, setShowTracing] = useState(false);

  return (
    <BottomRowContainer>
      <TagsControl
        onClick={() => setShowTags(!showTags)}
        onClose={() => setShowTags(false)}
        itemId={itemRecord.id}
        tags={itemRecord.tags}
        showPopup={showTags}
        queryToRefetch={queryToRefetch}
        disabled={disabled}
      />

      <TracingControl
        onClick={() => setShowTracing(!showTracing)}
        onClose={() => setShowTracing(false)}
        versionId={versionId}
        itemId={itemRecord.id}
        showPopup={showTracing}
        parentSetItemSlug={parentSetItemSlug}
        disabled={disabled}
      />

      <CommentsControl
        onClick={() => setShowComments(!showComments)}
        onClose={() => setShowComments(false)}
        commentCount={get(itemRecord, 'comments.length', 0)}
        showPopup={showComments}
        itemId={itemRecord.id}
        queryToRefetch={queryToRefetch}
        disabled={disabled}
      />

      {renderDeleteControl && renderDeleteControl()}
    </BottomRowContainer>
  );
}

export { CommentsControl, TagsControl, TracingControl, BottomRowContainer };
export default BottomRowControls;
