import { BulkItemSearch, BulkItemSearch_search, BulkItemSearch_search_results } from './__generated__/BulkItemSearch';
import uniqBy from 'lodash/uniqBy';

import { TraceListItem } from 'components/TracesListPopover';
import { BULK_ITEM_TRACES } from './gql';

export function bulkItemTracesRequest(ids: number[]) {
  return {
    query: BULK_ITEM_TRACES,
    variables: { ids },
    dataMapper: (data: any) => {
      return data.search.length > 0
        ? mapSearchResultsToLinks(data).map((item) => ({
            customIdentifier: item.customIdentifier,
            title: item.title,
          }))
        : [];
    },
  };
}

export function mapSearchResultsToLinks(result?: BulkItemSearch): TraceListItem[] {
  if (!result || !result.search) return [];
  return uniqBy(
    result.search.map(mapSearchResultToItms).reduce((previous, current) => previous.concat(current), []),
    'customIdentifier',
  );
}

function mapSearchResultToItms(result: BulkItemSearch_search): TraceListItem[] {
  return result.results
    .map(mergeItemsUpstreamAndDownstreamItems)
    .reduce((previous, current) => previous.concat(current), []);
}

function mergeItemsUpstreamAndDownstreamItems(item: BulkItemSearch_search_results): TraceListItem[] {
  return item.upstreamItems
    .concat(item.downstreamItems)
    .map((item) => ({ title: item.title || '', customIdentifier: item.customIdentifier }));
}
