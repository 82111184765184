import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import Dvr from '@material-ui/icons/Dvr';

import { buildRecordQuery } from 'utils/gql/itemRecordQuery';

import slugs from '../../displaySlugs';

import PayloadFields from './PayloadFields';

const itemTypeName = 'designRequirementSpecification';
const versionFields = `
  descriptionOfItem
`;

const query = gql`
  query designRequirementSpecificationQuery($itemId: ID!) {
    ${buildRecordQuery({
      itemType: itemTypeName,
      versionFields,
      userCreatable: true,
    })}
  }
`;

export default {
  itemType: 'designRequirementSpecification',
  icon: Dvr,
  displaySlug: slugs[itemTypeName],
  label: t`Design Requirement Specification`,
  categoryLabel: t`DRSs`,
  query,
  PayloadFields,
  versionFields,
  listViewQuery: gql`
    query designRequirementSpecificationsQuery {
      designRequirementSpecifications {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        createdAt
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        currentVersion {
          id
          createdAt
          title
          descriptionOfItem
          versionIdentifier
          currentStatusName
        }
      }
    }
  `,
  listViewColumns: [
    {
      field: 'customIdentifier',
      headerName: 'ID',
      type: 'id',
    },
    {
      field: 'currentVersion.title',
      headerName: 'Title',
      type: 'regular_text',
    },
    {
      field: 'currentVersion.descriptionOfItem',
      headerName: 'Description',
      type: 'long_text',
    },
    {
      field: 'currentVersion',
      headerName: 'Version',
      type: 'version',
    },
    {
      field: 'currentVersion.currentStatusName',
      headerName: 'Status',
      type: 'status',
    },
    {
      field: 'currentVersion.createdAt',
      headerName: 'Initiation Date',
      type: 'date',
    },
    {
      field: 'owner',
      headerName: 'Owner',
      type: 'user',
    },
    {
      field: 'approver',
      headerName: 'Approver',
      type: 'user',
    },
  ],
};
