import { CancelReason } from 'compositions/TimelineGroup/steps/views';
import { CourseWorkflowStatuses } from 'workflows/statuses';

export default {
  initialStep: 'cancel',
  internalSteps: {
    cancel: {
      component: CancelReason,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: ({ cancel }) => ({
    toStatus: CourseWorkflowStatuses.Canceled.id,
    ...cancel.signature,
    reasonForTransition: cancel.reason,
  }),
};
