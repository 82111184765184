import React from 'react';
import { WorkflowTransition } from 'compositions/TimelineGroup/steps/types';
import { GenericWorkflowStatuses } from 'workflows/statuses';
import { VoidReason } from 'compositions/TimelineGroup/steps/views';

export default {
  initialStep: 'voidApprovedDraft',
  internalSteps: {
    voidApprovedDraft: {
      component: (props) => {
        return <VoidReason relevantRole={props.currentVersion.assignedApproverJobRole.formattedRoleName} {...props} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: ({ voidApprovedDraft }) => ({
    toStatus: GenericWorkflowStatuses.VoidApprovedDraft.id,
    ...voidApprovedDraft.signature,
    reasonForTransition: voidApprovedDraft.reason,
  }),
} as WorkflowTransition;
