import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import isEmpty from 'lodash/isEmpty';

import ErrorIcon from '@material-ui/icons/Error';
import SettingsSelect from 'components/SettingsSelect';
import Days from 'components/SettingsSelect/Days';
import SettingsEditButton from '../SettingsEditButton';
import SectionTitle from 'components/SettingsSection/SectionTitle';

import { useCurrentCompany } from 'compositions/WithCurrentCompany';
import { UPDATE_CLIENT_CONFIG } from '../../gql';
import { useStyles } from './styles';
import { EditProps } from '../../types';

interface Props extends EditProps {}

const RecordConfiguration: FC<Props> = ({ name, editing, onEdit }) => {
  const classes = useStyles();
  const [updateData, setUpdateData] = useState({});
  const { data } = useCurrentCompany();
  const [updateClientConfig] = useMutation(UPDATE_CLIENT_CONFIG, {
    refetchQueries: ['withCurrentCompany_currentCompanyQuery'],
  });

  if (!data) return null;

  const onChange = (name: string, value: number) => setUpdateData((prevState) => ({ ...prevState, [name]: value }));

  const getSettingValue = (key: string) => data.currentCompany.config.trainingManagement[key];
  const items = data.currentCompany.config.trainingManagement.items;

  const handleSave = async () => {
    if (isEmpty(updateData)) {
      onEdit(null);
      return;
    }

    await updateClientConfig({
      variables: {
        input: {
          trainingManagement: updateData,
        },
      },
    })
      .then(() => {
        setUpdateData({});
        onEdit(null);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  return (
    <div className={classes.recordConfiguration}>
      <SectionTitle
        icon={<ErrorIcon />}
        title="Record Due date configuration"
        editButton={
          <SettingsEditButton
            isActive={editing}
            onClick={() => onEdit(name)}
            onActionButtonClick={handleSave}
            onCancel={() => onEdit(null)}
          />
        }
      />
      <Days />

      {items.length > 0 &&
        items.map((item) => (
          <SettingsSelect
            key={`${item.key}-${editing}`}
            item={item}
            value={getSettingValue(item.key)}
            onChange={onChange}
            disabled={!editing}
          />
        ))}
    </div>
  );
};

export default RecordConfiguration;
