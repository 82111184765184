import React, { useState, useContext } from 'react';
import YesOrNo from '../components/YesOrNo';
import { TextArea } from 'components';
import { useMutation } from 'react-apollo';
import { SnackContext } from 'contexts/other/snackContext';
import gql from 'graphql-tag';

const updateItemMutation = gql`
  mutation updateItem($id: ID!, $referenceId: String!) {
    updateItem(id: $id, referenceId: $referenceId) {
      item {
        id
        referenceId
      }
    }
  }
`;

const SUCCESS = 'Reference id updated.';

interface UpdateRefIdProps {
  itemId: string;
  onClose: () => void;
  initialValue: string | null;
  refetchQueries?: any;
}
function UpdateReferenceId(props: UpdateRefIdProps) {
  const [mutate, { error }] = useMutation(updateItemMutation, {
    onCompleted: props.onClose,
  });
  const [newRefId, setNewRefId] = useState(props.initialValue || '');

  const { showSnack } = useContext(SnackContext);

  if (error) throw new Error(`Failed to update reference id: ${error.message}`);

  return (
    <YesOrNo
      heading={'Change Reference ID'}
      yesLabel="Change"
      noLabel="Cancel"
      closeOnNo
      handleComplete={() => {
        mutate({
          variables: {
            id: props.itemId,
            referenceId: newRefId,
          },
          refetchQueries: props.refetchQueries,
        })
          .then(() => {
            showSnack(SUCCESS);
          })
          .catch((e) => {
            showSnack(`Failed to update reference id for item ${props.itemId}: ${e.message}`);
          });
      }}
      handleClose={props.onClose}
      prompt={<TextArea id="reference-id-input" value={newRefId} onTextChange={(txt) => setNewRefId(txt)} />}
    />
  );
}

export default UpdateReferenceId;
