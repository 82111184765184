import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    traceRow: {
      borderBottom: `6px solid #dfdfdf`,
      padding: theme.spacing(1),
      display: 'flex',
      ...theme.mainLayoutGutters,
      margin: '0 auto',
    },
  });
