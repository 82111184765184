import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import Trace from 'assets/icons/Trace';

const styles = (theme) => ({
  typography: {
    ...theme.typography.body2,
    width: '100%',
    margin: 0,
    background: theme.palette.common.input.background,
    border: `2px solid ${theme.palette.common.input.outline}`,
    borderRadius: 4,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    fontSize: 'inherit',
    color: 'inherit',
    lineHeight: 'inherit',
  },
  icon: {
    borderRadius: '50%',
  },
});

class TraceField extends React.Component {
  static defaultProps = {
    traceIds: [],
  };

  render() {
    const { classes, traceIds, placeholderText } = this.props;
    return (
      <Typography
        variant="body2"
        component="div" // div cannot be descendant of p
        className={classes.typography}
      >
        {traceIds.length === 0 && placeholderText}
        <Trace className={classes.icon} />
      </Typography>
    );
  }
}

export default withStyles(styles)(TraceField);
