import capitalize from 'lodash/capitalize';

export function normalize(str: string) {
  return str
    .split('_')
    .map((r) => capitalize(r))
    .join(' ');
}

export function denormalize(str: string) {
  return str
    .split(' ')
    .map((r) => r.toLowerCase())
    .join('_');
}
