import { WithCurrentCompanyProps } from '../../compositions/WithCurrentCompany';
import { updateCompanyVariables } from './__generated__/updateCompany';

export const getCompanyUrl = () => window.location.origin;

export const createUpdateData = (data: WithCurrentCompanyProps['currentCompany']): updateCompanyVariables => ({
  input: {
    id: data.id,
    name: data.name,
    streetAddress: data.streetAddress,
    city: data.city,
    stateOrProvince: data.stateOrProvince,
    zipCode: data.zipCode,
    phoneNumber: data.phoneNumber,
    emailAddress: data.emailAddress,
    timeZone: data.timeZone,
  },
});
