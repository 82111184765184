import gql from 'graphql-tag';

export const VERSION_TRACE_QUERY = gql`
  query FindVersionTraceQuery($traceVersionId: ID!) {
    versionTrace(traceVersionId: $traceVersionId) {
      id
      upstreamVersion {
        id
        item {
          id
          customIdentifier
        }
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
      }
      downstreamVersion {
        id
        item {
          id
          customIdentifier
        }
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
      }
      owner {
        id
        item {
          id
          customIdentifier
        }
        title
        currentStatus {
          id
          name
        }
      }
    }
  }
`;
