import React from 'react';
import { MenuList } from '@material-ui/core';

import styles from './styles';
import { withStyles } from '@material-ui/styles';

const MenuContainer = ({ classes, children }) => (
  <MenuList classes={{ root: classes.menuContainer }}>{children}</MenuList>
);

export default withStyles(styles)(MenuContainer);
