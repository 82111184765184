import React from 'react';

import { FormControl, MenuItem, Select as MuiSelect, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  select: {
    ...theme.typography.body2,
    paddingLeft: '10px',
    background: theme.palette.common.input.background,
    border: `2px solid ${theme.palette.common.input.outline}`,
    borderRadius: 3,
    minWidth: 125,
  },
  selectItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

function SetSelect(props) {
  const options = props.options.map((opt) => {
    return (
      <MenuItem value={opt.id} key={opt.id}>
        <div className={props.classes.selectItemContainer}>
          {opt.item.customIdentifier}: {opt.title}
        </div>
      </MenuItem>
    );
  });
  return (
    <FormControl>
      <MuiSelect
        disableUnderline
        disabled={props.disabled || false}
        classes={{ root: props.classes.select }}
        labelId={props.labelId}
        value={props.value}
        onChange={props.onChange}
      >
        {options}
      </MuiSelect>
    </FormControl>
  );
}

export default withStyles(styles, { withTheme: true })(SetSelect);
