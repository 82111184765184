import React, { ReactNode } from 'react';

import { useStyles } from './styles';

interface Props {
  children: ReactNode;
}

function IconButtonRow(props: Props) {
  const classes = useStyles();
  return <div className={classes.row}>{props.children}</div>;
}

export default IconButtonRow;
