import React from 'react';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';

export function ArrowWithSize(fontSize = 24) {
  return () => <ArrowRightAlt style={{ fontSize }} />;
}

function prevChar(char) {
  return String.fromCharCode(char.charCodeAt(0) - 1);
}

function hasNumber(myString) {
  return /\d/.test(myString);
}

function versionSequence(currentVersion, currentStatus, fontSize) {
  const Arrow = ArrowWithSize(fontSize);

  if ((currentVersion === 'q1' || currentVersion === 'v1' || currentVersion === 'vA') && currentStatus !== 'released') {
    return (
      <>
        _ <Arrow /> {currentVersion.substr(1)}
      </>
    );
  } else if (currentStatus === 'released') {
    return <>{currentVersion.substr(1)}</>;
  } else if (currentVersion === 'v1' && currentStatus === 'released') {
    return (
      <>
        {currentVersion.substr(1) - 1} <Arrow /> {parseInt(currentVersion.substr(1))}
      </>
    );
  } else if (currentVersion === 'vA' && currentStatus === 'released') {
    return (
      <>
        {prevChar(currentVersion.substr(1))} <Arrow /> {currentVersion.substr(1)}
      </>
    );
  } else if (currentVersion !== 'v1' && hasNumber(currentVersion)) {
    return (
      <>
        {currentVersion.substr(1) - 1} <Arrow /> {parseInt(currentVersion.substr(1))}
      </>
    );
  } else if (!hasNumber(currentVersion)) {
    return (
      <>
        {prevChar(currentVersion.substr(1))} <Arrow /> {currentVersion.substr(1)}
      </>
    );
  }
}

export function versionSequenceAsString(currentVersion, currentStatus) {
  if ((currentVersion === 'q1' || currentVersion === 'v1' || currentVersion === 'vA') && currentStatus !== 'released') {
    return `_ → ${currentVersion.substr(1)}`;
  } else if (currentStatus === 'released') {
    return `${currentVersion.substr(1)}`;
  } else if (currentVersion === 'v1' && currentStatus === 'released') {
    return `${currentVersion.substr(1) - 1} → ${parseInt(currentVersion.substr(1))}`;
  } else if (currentVersion === 'vA' && currentStatus === 'released') {
    return `${prevChar(currentVersion.substr(1))} → ${currentVersion.substr(1)}`;
  } else if (currentVersion !== 'v1' && hasNumber(currentVersion)) {
    return `${currentVersion.substr(1) - 1} → ${parseInt(currentVersion.substr(1))}`;
  } else if (!hasNumber(currentVersion)) {
    return `${prevChar(currentVersion.substr(1))} → ${currentVersion.substr(1)}`;
  }
}

export default versionSequence;
