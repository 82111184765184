import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    editButton: {
      marginLeft: theme.spacing(1.5),

      '& .MuiButtonBase-root': {
        color: theme.palette.subHeading,

        '&:hover': {
          color: '#33BCB4',
        },
      },
    },
  }),
);
