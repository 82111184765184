import {
  useAddLinkItem,
  useDeleteItemLink,
  useUpdateVersion,
  useAddRisksToRiskAnalysis,
  useAddNewRiskToRiskAnalysis,
  useUnlinkRiskFromRiskAnalysis,
  useChangeRiskVersion,
  useCreateNewVersion,
  useAddRiskControlVersion,
  useDeleteRiskControlVersion,
} from '../gql';

// one a bit messy move here is that these are different
// from whatever default array-returning variables-taking useMutation functions
// but the idea here is to check that ugly api cleanup options that fit in simple cases

type MutationParams = object;

export function useAddLink(params: MutationParams = {}) {
  const [addLinkMutation] = useAddLinkItem(params);
  const addLink = (upId: string, downId: string) => {
    addLinkMutation({
      variables: {
        input: {
          rootItemId: upId,
          linkedItemIds: [downId],
          direction: 'downstream',
        },
      },
    }).catch(onError('addLinkMutation'));
  };
  return addLink;
}

export function useAddRiskCtrlVer(params: MutationParams = {}) {
  const [addRiskControlVersionMutation] = useAddRiskControlVersion(params);
  const addRiskControlVersion = (riskVerId: string, riskControlVerIds: string) => {
    addRiskControlVersionMutation({
      variables: {
        riskVersionId: riskVerId,
        riskControlVersionIds: [riskControlVerIds],
        trioType: 'RiskMitigation',
      },
    }).catch(onError('useAddRiskCtrlVer'));
  };
  return addRiskControlVersion;
}

export function useDeleteLink(params: MutationParams = {}) {
  const [deleteLinkMutation] = useDeleteItemLink(params);
  const deleteLink = (upId: string, downId: string) => {
    deleteLinkMutation({
      variables: {
        upstreamId: upId,
        downstreamId: downId,
      },
    }).catch(onError('deleteLinkRiskMutation'));
  };
  return deleteLink;
}

export function useDeleteRiskCtrlVer(params: MutationParams = {}) {
  const [deleteRiskControlVersionMutation] = useDeleteRiskControlVersion(params);
  const deleteRiskControlVersion = (riskVerId: string, riskControlVerId: string) => {
    deleteRiskControlVersionMutation({
      variables: {
        riskVersionId: riskVerId,
        riskControlVersionId: riskControlVerId,
      },
    }).catch(onError('useDeleteRiskCtrlVer'));
  };
  return deleteRiskControlVersion;
}

export function useUpdateRiskVersion(versionId: number, params: MutationParams = {}) {
  const [save] = useUpdateVersion(params);

  const fieldSave = (field: string) => (value: any) => {
    save({
      variables: {
        id: versionId,
        [field]: value,
      },
      refetchQueries: ['RiskAnalysisVersionQuery'],
    }).catch(onError('updateRiskVersion'));
  };
  return fieldSave;
}

export function useLinkExistingRisks(params: MutationParams = {}) {
  const [save] = useAddRisksToRiskAnalysis(params);

  const addRisks = (riskAnalysisVersionId: string, linkedRiskVersionIds: string[]) => {
    save({
      variables: {
        riskAnalysisVersionId,
        linkedRiskVersionIds,
      },
    }).catch(onError('addRisksToRiskAnalysis'));
  };
  return addRisks;
}

export function useLinkNewRisk(params: MutationParams = {}) {
  const [save] = useAddNewRiskToRiskAnalysis(params);

  const addRisk = (riskAnalysisVersionId: string) => {
    save({
      variables: {
        riskAnalysisVersionId,
      },
    }).catch(onError('addNewRiskToRiskAnalysis'));
  };
  return addRisk;
}

export function useChangeRisk(params: MutationParams = {}) {
  const [save] = useChangeRiskVersion(params);

  const changeRisk = (riskAnalysisVersionId: string, oldRiskVersionId: string, newRiskVersionId: string) => {
    save({
      variables: {
        riskAnalysisVersionId,
        oldRiskVersionId,
        newRiskVersionId,
      },
    }).catch(onError('changeRiskVersionInRiskAnalysis'));
  };
  return changeRisk;
}

export function useCreateVersion(params: MutationParams = {}) {
  const [save] = useCreateNewVersion(params);

  const newVersion = (itemId: string) => {
    save({
      variables: {
        input: {
          itemId,
        },
      },
    }).catch(onError('createNewVersion'));
  };
  return newVersion;
}

export function useUnlinkRisk(params: MutationParams = {}) {
  const [save] = useUnlinkRiskFromRiskAnalysis(params);

  const mutation = (riskAnalysisVersionId: string, riskVersionId: string) => {
    return save({
      variables: {
        riskAnalysisVersionId,
        riskVersionId,
      },
      refetchQueries: ['RiskAnalysisVersionQuery'],
    }).catch(onError('useUnlinkRiskFromRiskAnalysis'));
  };
  return mutation;
}

// helpers

export function targetValue(fun) {
  return (e) => fun(e.target.value);
}

function onError(place: string) {
  return (e: any) => {
    throw new Error(`${place}: ${e.message}`);
  };
}
