import React, { useContext, useState, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { Filter1 } from '@material-ui/icons';

import { useMutation } from 'react-apollo';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { LockedContext } from 'contexts/lockedContext';
import { ContentSection, LabelText, NoContentDisclaimer, Spacer } from 'components';

import AddStep from './AddStep';
import SetupStep from './SetupStep';

import { ADD_STEP, REMOVE_STEP, UPDATE_STEP } from './gql';

import { useStyles } from './styles';

export interface TestSetupStep {
  id: string;
  position: number;
  text?: string;
  item: {
    customIdentifier: string;
    currentVersion: {
      title: string;
    };
  } | null;
}

interface Props {
  activeWorkspaceView: string;
  steps: TestSetupStep[];
  versionId: number;
}

function TestSetup(props: Props) {
  const { steps: propsSteps } = props;
  const { locked } = useContext(LockedContext);
  const classes = useStyles();

  const [selectedStep, setSelectedStep] = useState<string>();
  const [hoverStep, setHoverStep] = useState<string>();

  const [steps, setSteps] = useState(props.steps);

  const [addedStep, setAddedStep] = useState<string>();

  useEffect(() => {
    setSteps(propsSteps);
  }, [propsSteps, setSteps]);

  const [addStep] = useMutation(ADD_STEP, {
    refetchQueries: ['DeviceVerificationVersionQuery'],
    onCompleted: (res) => {
      setAddedStep(res.addTestSetupStep.step.id);
    },
  });

  const [removeStep] = useMutation(REMOVE_STEP, {
    refetchQueries: ['DeviceVerificationVersionQuery'],
  });

  const [updateStep] = useMutation(UPDATE_STEP, {
    refetchQueries: ['DeviceVerificationVersionQuery'],
  });

  const handleOnDragEnd = (result) => {
    setSelectedStep(undefined);
    const { destination, draggableId, source } = result;
    const elementNotMoved = destination.droppableId === source.droppableId && destination.index === source.index;
    if (!destination || elementNotMoved) return;

    const copy = [...steps];
    const reorderedElement = steps[source.index];
    copy.splice(source.index, 1);
    copy.splice(destination.index, 0, reorderedElement);
    copy.forEach((step, index) => {
      step.position = index;
    });
    setSteps(copy);

    updateStep({ variables: { position: destination.index, id: draggableId } }).catch((e) => {
      throw new Error(`Error occurred while updating setup step: ${e}`);
    });
  };

  const highlightStep = (id): boolean => {
    if (selectedStep) {
      return selectedStep === id;
    } else if (hoverStep) {
      return hoverStep === id;
    } else {
      return false;
    }
  };

  return (
    <ContentSection LabelText={<LabelText labelText="Test Setup Steps" Icon={Filter1} />}>
      <Spacer factor={1} />
      {steps.length > 0 && (
        <Typography variant="caption">
          <span className={classes.headerNumber}>#</span>
          <span>Test Step</span>
        </Typography>
      )}
      <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={(start) => setSelectedStep(start.draggableId)}>
        <Droppable droppableId="members">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {steps.map((step, idx) => {
                return (
                  <Draggable isDragDisabled={locked} key={step.id} draggableId={step.id} index={idx}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div onMouseEnter={() => setHoverStep(step.id)} onMouseLeave={() => setHoverStep(undefined)}>
                          <SetupStep
                            activeWorkspaceView={props.activeWorkspaceView}
                            text={step.text || ''}
                            item={step.item}
                            index={step.position + 1}
                            onTextUpdate={(updatedText) =>
                              updateStep({
                                variables: { text: updatedText, id: step.id },
                              })
                            }
                            onItemUpdate={(itemId) =>
                              updateStep({
                                variables: { itemId: itemId, id: step.id },
                              })
                            }
                            focusInput={step.id === addedStep}
                            highlight={!locked && highlightStep(step.id)}
                            onRemove={() => removeStep({ variables: { id: step.id } })}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Spacer factor={1} />
      {!locked && (
        <AddStep
          onAddClick={() => addStep({ variables: { parentVersionId: props.versionId, text: '' } })}
          onItemSelected={(itemId) => addStep({ variables: { itemId, parentVersionId: props.versionId } })}
        />
      )}
      {props.steps.length === 0 && <NoContentDisclaimer variant="add" />}
    </ContentSection>
  );
}

export default TestSetup;
