import React, { Component } from 'react';
import flowRight from 'lodash/flowRight';
import { Trans } from '@lingui/macro';
import LinearScale from '@material-ui/icons/LinearScale';

import { AlertDialogWithUser, WorkflowIcon, ContentSection } from 'components';
import WorkflowMenu, { VoidMenuItem, CancelMenuItem } from 'components/WorkflowMenu';

import ConnectBar from './ConnectBar';
import { MiscellaneousRecordWorkflowStatuses, indexById } from 'workflows/statuses';
import PermissionFailModal from './PermissionFailModal';
import WithCurrentUser from '../../WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import withMetadataFromServer from '../../WithMetadataFromServer';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

const { Cancel, Checked, Created, InProgress, Void } = MiscellaneousRecordWorkflowStatuses;

const MiscellaneousRecordWorkflowStatusesById = indexById(MiscellaneousRecordWorkflowStatuses);

class MiscellaneousRecordWorkflow extends Component {
  constructor(props) {
    super(props);
    this.permissions = props.permissions;
  }

  state = {
    modal: {
      open: false,
      type: '',
    },
    deniedModalWithUser: {
      open: false,
      user: null,
      role: null,
    },
  };

  handleInactiveUserDeniedModal = (inactiveUsers) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: inactiveUsers[0].user,
        role: inactiveUsers[0].role,
      },
    });
  };

  handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: usersWithoutActiveRole[0].user,
        role: usersWithoutActiveRole[0].role,
      },
    });
  };

  workflowControls = () => {
    const { currentCompanyAllowsTransitioning, currentUser, currentVersion, handleInitTransition } = this.props;
    const currentWorkflowStatus = MiscellaneousRecordWorkflowStatusesById[currentVersion.currentStatusName];

    const { signingApprover } = currentVersion;
    const isVoidable = () =>
      signingApprover && signingApprover.id === currentUser.id && currentWorkflowStatus.isEqualTo(Checked);

    if (!currentCompanyAllowsTransitioning) return;

    return (
      <>
        <WorkflowMenu>
          <CancelMenuItem
            disabled={
              !(
                this.permissions.canOwn &&
                (currentWorkflowStatus.isEqualTo(Created) || currentWorkflowStatus.isEqualTo(InProgress))
              )
            }
            onClick={() => handleInitTransition('recordCanceled')}
          />

          <VoidMenuItem disabled={!isVoidable()} onClick={() => handleInitTransition('recordVoided')} />
        </WorkflowMenu>
      </>
    );
  };

  render() {
    const { currentCompanyAllowsTransitioning, currentVersion, handleInitTransition } = this.props;
    const { approver, assignedApproverJobRole, assignedOwnerJobRole, currentStatusName, owner } = currentVersion;
    const status = currentStatusName;
    const currentWorkflowStatus = MiscellaneousRecordWorkflowStatusesById[status];

    const inactiveUsers = getInactiveUsers(owner, approver);
    const usersWithoutActiveRole = getUsersWithoutActiveRole(
      owner,
      approver,
      assignedOwnerJobRole,
      assignedApproverJobRole,
    );

    return (
      <ContentSection LabelText={<Trans>Workflow</Trans>} Icon={<LinearScale />} Controls={this.workflowControls()}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <WorkflowIcon
            type="created"
            active={currentWorkflowStatus.isEqualTo(Created)}
            completed={currentWorkflowStatus.isAfter(Created)}
            label="Created"
            visible
          />

          <ConnectBar color="inProgress" disabled={!currentCompanyAllowsTransitioning} visible />

          <WorkflowIcon
            type="inProgress"
            active={currentWorkflowStatus.isEqualTo(InProgress)}
            completed={currentWorkflowStatus.isAfter(InProgress)}
            disabledEffect={!currentCompanyAllowsTransitioning}
            label="In Progress"
            size="large"
            handleClick={() => {
              if (!currentCompanyAllowsTransitioning)
                return this.setState({
                  modal: { open: true, type: 'itemTransitioning' },
                });

              if (inactiveUsers.length) return this.handleInactiveUserDeniedModal(inactiveUsers);
              if (usersWithoutActiveRole.length)
                return this.handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);

              if (currentWorkflowStatus.isBefore(InProgress) && currentCompanyAllowsTransitioning)
                return handleInitTransition('recordInProgress');
            }}
            visible
          />

          <ConnectBar color="checked" disabled={!currentCompanyAllowsTransitioning} visible />

          <WorkflowIcon
            type="checked"
            active={currentWorkflowStatus.isEqualTo(Checked)}
            completed={currentWorkflowStatus.isAfter(Checked)}
            disabledEffect={!currentCompanyAllowsTransitioning}
            label="Checked"
            handleClick={() => {
              if (!currentCompanyAllowsTransitioning)
                return this.setState({
                  modal: { open: true, type: 'itemTransitioning' },
                });

              if (currentWorkflowStatus.isEqualTo(InProgress) && !this.permissions.canApprove)
                return this.setState({
                  modal: { open: true, type: 'permissions' },
                });

              if (currentWorkflowStatus.isEqualTo(InProgress) && this.permissions.canApprove) {
                return handleInitTransition('recordChecked');
              }
            }}
            visible
            displayEffectiveDate
          />

          <ConnectBar color="rejected" visible={currentWorkflowStatus.isEqualTo(Cancel)} disabled />

          <WorkflowIcon type="rejected" label="Canceled" visible={currentWorkflowStatus.isEqualTo(Cancel)} completed />

          <ConnectBar color="obsolete" visible={currentWorkflowStatus.isEqualTo(Void)} />

          <WorkflowIcon type="obsolete" label="Voided" visible={currentWorkflowStatus.isEqualTo(Void)} completed />

          <PermissionFailModal
            open={this.state.modal.open}
            failType={this.state.modal.type}
            closeModal={() => this.setState({ modal: { open: false } })}
          />

          <AlertDialogWithUser
            open={this.state.deniedModalWithUser.open}
            titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
            onClose={() => this.setState({ deniedModalWithUser: { open: false, user: null, role: null } })}
            subtitle={
              inactiveUsers.length
                ? 'The following user has been deactivated.'
                : "The following user's role has been removed."
            }
            user={this.state.deniedModalWithUser.user}
            role={this.state.deniedModalWithUser.role === 'owner' ? assignedOwnerJobRole : assignedApproverJobRole}
            body={`Please use the People Menu to reassign the ${
              this.state.deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
            } role to a new user.`}
          />
        </div>
      </ContentSection>
    );
  }
}

export default flowRight([WithCurrentUser, withCurrentCompany, withMetadataFromServer()])(MiscellaneousRecordWorkflow);
