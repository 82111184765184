import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_theme) =>
  createStyles({
    // exapmle styles to be applied from the outside
    /*
    tableContainer: {
      padding: '42px 23px',
      paddingTop: 0,
      '& table': {
        paddingTop: 42,
      }
    },
    */
    container: {
      position: 'relative',
      '& .MuiChip-root': {
        height: '15px',
        width: '100%',
      },
    },
    group: {
      position: 'absolute',
      top: -50,
      padding: '0 3px',
    },
  }),
);
