import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      borderRadius: '50%',
      cursor: 'pointer',
      border: `1px solid ${theme.palette.primary.main}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: 12,
    },
    large: {
      width: theme.spacing(21.875),
      height: theme.spacing(21.875),
    },
  }),
);
