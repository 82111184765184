import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    input: {
      color: theme.palette.blueDark,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1.5, 2),
    },
    disabled: {
      border: 'none',
      color: theme.palette.secondary.main,
      ...theme.typography.h6,
    },
  }),
);
