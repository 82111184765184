import React, { useContext } from 'react';

import { createStyles, makeStyles, Typography, Chip, Tooltip } from '@material-ui/core';
import { ReportProblem, DirectionsRun, FlashOn, Report } from '@material-ui/icons';

import { TraceMatrixCardContext } from 'contexts/traceMatrixCardContext';

interface Props {
  version: any;
}

export const useStyles = makeStyles((theme) =>
  createStyles({
    riskCardTextField: {
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      overflow: 'hidden',
      display: 'inline-block',
    },
    riskCardSection: {
      width: '100%',
      '& .MuiChip-root': {
        marginLeft: '5px',
        color: 'rgba(0, 0, 0, 0.6)',
        height: '20px',
        maxWidth: 'calc(100% - 20px)',
        '& > span': {
          padding: `0 ${theme.spacing(1)}px`,
        },
      },
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
      },
      '& .MuiCardActions-root .MuiTypography-root': {
        marginBottom: 0,
      },
    },
    iconAndChip: {
      display: 'flex',
      '& > .MuiChip-root': {
        maxWidth: 'calc(100% - 20px)',
        cursor: 'pointer !important',
      },
    },
  }),
);

function RiskVersionCard(props: Props) {
  const {
    version: { hazard, hazardousSituation, sequenceOfEvents, harm },
  } = props;

  const { description } = useContext(TraceMatrixCardContext);
  const classes = useStyles();

  return (
    <section className={classes.riskCardSection}>
      {description.showing && (
        <>
          <Typography variant={'body2'}>
            <ReportProblem fontSize={'small'} />
            {hazard && (
              <Tooltip title={hazard.name}>
                <Chip label={hazard.name} />
              </Tooltip>
            )}
          </Typography>
          <Tooltip title={hazardousSituation}>
            <Typography variant={'body2'}>
              <span className={classes.riskCardTextField}>
                <DirectionsRun fontSize={'small'} />
                {hazardousSituation}
              </span>
            </Typography>
          </Tooltip>
          <Tooltip title={sequenceOfEvents}>
            <Typography variant={'body2'}>
              <span className={classes.riskCardTextField}>
                <FlashOn fontSize={'small'} /> {sequenceOfEvents}
              </span>
            </Typography>
          </Tooltip>
          <Typography variant={'body2'}>
            <Report fontSize={'small'} />
            {harm && (
              <Tooltip title={harm.name}>
                <Chip label={harm.name} />
              </Tooltip>
            )}
          </Typography>
        </>
      )}
    </section>
  );
}

export default RiskVersionCard;
