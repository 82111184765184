import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    statusTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > div': {
        marginRight: '10px',
        marginBottom: '10px',
      },
    },
    statusCount: {
      textAlign: 'center',
      marginBottom: '30px',
    },
  }),
);
