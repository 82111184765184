import React from 'react';

import { ChangeSummary, YesOrNo } from 'compositions/TimelineGroup/steps/views';

import InitiateRetirement from 'compositions/TimelineGroup/steps/views/InitiateRetirement';

import { WorkflowTransition } from 'compositions/TimelineGroup/steps/types';
import { CourseWorkflowStatuses } from 'workflows/statuses';

export default {
  initialStep: 'initRetire',
  internalSteps: {
    initRetire: {
      component: (props) => {
        return (
          <YesOrNo
            disableOnSubmit
            prompt={`Do you want to start the retirement of version ${props.currentVersion.versionIdentifier} of ${
              props.itemCustomIdentifier
            }?`}
            {...props}
          />
        );
      },
      getNextStep: (initRetirement) => (initRetirement ? 'changeSummary' : 'complete'),
    },
    changeSummary: {
      component: (props) => <ChangeSummary buttonText="Next" {...props} />,
      getNextStep: (_) => 'signature',
    },
    signature: {
      component: InitiateRetirement,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: ({ initRetire, changeSummary, signature }) => {
    if (initRetire) {
      return {
        ...signature.signature,
        ...changeSummary,
        reasonForTransition: signature.reason,
        toStatus: CourseWorkflowStatuses.RetirementInitiated.id,
      };
    } else {
      return null;
    }
  },
} as WorkflowTransition;
