import { createStyles, makeStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    timelineBtnContainer: {
      whiteSpace: 'nowrap',
      overflowY: 'hidden',
      transform: 'translateZ(0)' /* hack to get chrome to behave */,
    },
    transitionModal: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      width: theme.spacing(50),
    },
  });

export const useStyles = makeStyles(styles);
export default styles;
