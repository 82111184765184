import React from 'react';

import { Spacer } from 'components';

import { navigate } from '@reach/router';
import { Typography, Button, withStyles } from '@material-ui/core';
import CompareArrows from '@material-ui/icons/CompareArrows';

import { Trans } from '@lingui/macro';

import styles from './styles';

function AssociatedChangeOrder({ classes, coTitle, coKey, coId, coJustificationText, disableEdit, handleComplete }) {
  const hasChangeOrder = coId && !coJustificationText;

  const ChangeOrderDetails = <Typography variant="body1">{`${coKey}: ${coTitle}`}</Typography>;
  const JustificationDetails = (
    <Typography variant="body2" component="div">
      <Trans>
        No change order is associated with this change because:
        <Spacer factor={1} />
        <div className={classes.textInput}>{coJustificationText}</div>
      </Trans>
    </Typography>
  );
  return (
    <>
      <Typography variant="h3">
        <Trans>Associated Change Order</Trans>
      </Typography>
      <Spacer factor={1} />
      {hasChangeOrder ? ChangeOrderDetails : JustificationDetails}

      <Spacer factor={2} />

      {disableEdit && hasChangeOrder && (
        <Button
          color="primary"
          fullWidth
          variant="outlined"
          style={{ minHeight: 40 }}
          onClick={() => navigate(`/category/change-order/${coId}`)}
        >
          <span className={classes.buttonIconText}>
            <CompareArrows className={classes.buttonIcon} />
            <Trans>View</Trans> {coKey}
          </span>
        </Button>
      )}

      {!disableEdit && (
        <div style={{ display: 'flex' }}>
          <Button
            id="btn-edit"
            style={{ flex: 1, marginRight: '5px' }}
            onClick={() => handleComplete({ edit: true })}
            color="primary"
            variant="outlined"
          >
            <Trans>Edit</Trans>
          </Button>
          {hasChangeOrder && (
            <Button
              id="btn-view"
              style={{ flex: 1, marginLeft: '5px' }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate(`/category/change-order/${coId}`)}
            >
              <Trans>View</Trans>
            </Button>
          )}
        </div>
      )}
    </>
  );
}

export default withStyles(styles)(AssociatedChangeOrder);
