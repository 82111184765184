import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    days: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1),
    },
    icon: {
      color: theme.palette.secondary.main,
    },
    daysLabel: {
      color: theme.palette.secondary.main,
    },
    selectItem: {
      display: 'flex',
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    },
    selectItemLabelArea: {
      marginRight: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    selectItemLabel: {
      color: theme.palette.blueDark,
    },
    selectItemSubLabel: {
      color: theme.palette.secondary.main,
    },
    input: {
      marginRight: theme.spacing(1.5),

      '& .MuiOutlinedInput-input': {
        width: 50,
        padding: theme.spacing(1.5),
      },
    },
    select: {
      background: theme.palette.common.input.background,

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1.5px solid #DFDFDF',
      },
    },
  }),
);
