import React, { useContext } from 'react';
import FilterList from '@material-ui/icons/FilterList';
import LinksGraphToggle from '../LinksGraphToggle';
import ActionPopover from '../ActionPopover';
import { FilterContext } from 'contexts/LinksGraph/filterContext';

export interface FilterState {
  includeDraft: boolean;
  includeRetired: boolean;
}

function FilterAction() {
  const { includeDraft, includeRetired } = useContext(FilterContext);

  return (
    <ActionPopover Icon={FilterList} cardDescription="Filter Root">
      <LinksGraphToggle checked={includeDraft.showing} label="Show Draft" onChange={includeDraft.toggle} />
      <LinksGraphToggle checked={includeRetired.showing} label="Show Retired" onChange={includeRetired.toggle} />
    </ActionPopover>
  );
}

export default FilterAction;
