import React from 'react';

import { Checkbox } from '@material-ui/core';
import { Select, OutlinedInput, Typography, MenuItem } from '@material-ui/core';
import { Edit, Link, Cancel } from '@material-ui/icons';
import { navigate } from '@reach/router';

import clsx from 'clsx';

import { Spacer, VersionAndStatusDot } from 'components';
import { itemLink } from 'components/MakeLink';

import Table from 'components/core/Table';
import IconButton from 'components/IconButton';
import Input from 'components/core/form/Input';

import { useStyles } from './styles';
import { useRemoveSubgroupVersion, useUpdateDeviceMasterRecordSubgroup } from './gql';

export default function Subgroup({ group, subgroup, openSearch, selection, toggleSelection, usedNames, locked }) {
  const classes = useStyles();

  const chosenOption = group.headerOptions.find((x) => x.name === subgroup.name);
  let isUserText = !chosenOption;
  let selectedText = chosenOption ? chosenOption.name : '';
  if (subgroup.name == null) {
    isUserText = false;
    selectedText = null;
  }

  const [updateDeviceMasterRecordSubgroup] = useUpdateDeviceMasterRecordSubgroup();
  const [removeSubgroupVersion] = useRemoveSubgroupVersion();
  const updateHeader = (e) => {
    const text = e.target.value;
    return updateHeaderUserText(text);
  };
  const updateHeaderUserText = (text) =>
    updateDeviceMasterRecordSubgroup({
      variables: {
        id: subgroup.id,
        name: text,
      },
    }).catch((err) => {
      throw new Error(`Error returned by updateHeaderUserText: ${err.message}`);
    });

  const isSelected = selection[subgroup.id];

  const tableData = subgroup.subgroupVersions

  return (
    <div className={classes.subgroup}>
      <div className={classes.sectionHeaderRow}>
        <Checkbox
          checked={isSelected}
          onChange={() => toggleSelection(subgroup.id)}
          disabled={locked}
          style={locked ? { backgroundColor: 'transparent' } : {}}
          classes={{ disabled: classes.notAllowed }}
        />
        <div className={classes.inputContainer}>
        {!isUserText && (
          <Select
            value={selectedText}
            onChange={updateHeader}
            disabled={locked}
            input={<OutlinedInput labelWidth={0} name="itemType" required={true} className={classes.itemNameInput} />}
            className={clsx(classes.selectMenu, classes.subgroupHeader, locked && classes.notAllowed)}
            displayEmpty
            fullWidth
            required={true}
          >
              <MenuItem value={null as any} disabled>
                <Typography variant="body2">Choose Section Header</Typography>
              </MenuItem>

              {group.headerOptions
                .filter((x) => !usedNames.includes(x.name))
                .map((option) => (
                  <MenuItem value={option.name} key={option.name} className={classes.menuOption}>
                    <Typography variant="body2">
                      <span className={classes.itemTypeText}>{option.name}</span>
                    </Typography>
                  </MenuItem>
                ))}

              <MenuItem value={''} key={''} className={classes.menuOption}>
                <Typography variant="body2">
                  <span className={classes.itemTypeText}>Add Custom Section</span>
                  <Edit fontSize="small" />
                </Typography>
              </MenuItem>
            </Select>
          )}
          {isUserText && (
            <div className={classes.inputContainer}>
              <Input
                debounced
                defaultValue={subgroup.name}
                onChange={(e) => updateHeaderUserText(e.target.value)}
                inputProps={{ maxLength: 42 }}
                disabled={locked}
              />
              {locked ? null : (
                <span className={classes.inputButtonContainer}>
                  <IconButton onClick={() => updateHeaderUserText(null)} Icon={Cancel} size='large' />
                </span>
              )}
            </div>
          )}
          </div>
      </div>

      <Spacer factor={2} />
      {tableData.length === 0 ? null :
      <div
        className={classes.buttonFill}
      >
      <Table
        data={tableData}
        onRowClick={(_e, row) => {
          const link = itemLink(row.version.item);
          link && navigate(link);
        }}
        actions={
          !locked
            ? [
                {
                  icon: () => <Cancel />,
                  onClick: (_e, subgroupVersion) => {
                    removeSubgroupVersion({
                      variables: { id: subgroupVersion.id },
                    }).catch((err) => {
                      throw new Error(`Error returned by removeSubgroupVersion: ${err.message}`);
                    });
                  },
                },
              ]
            : undefined
        }
        columns={[
          {
            title: 'ID',
            field: 'version.item.customIdentifier',
          },
          {
            title: 'Title',
            field: 'version.title',
          },
          {
            title: 'Version',
            field: 'version.item.currentVersion.versionIdentifier',
            render: (row) => {
              return (
                <VersionAndStatusDot
                  version={row.version.item.currentVersion.versionIdentifier}
                  status={row.version.item.currentVersion.currentStatusName}
                />
              );
            },
          },
        ]}
      />
      </div>
    }

      <Spacer factor={2} />
      {locked ? null : (
        <IconButton onClick={(e) => openSearch(e.currentTarget, subgroup)} Icon={Link} includeBackground
          size={tableData.length === 0 ? 'large' : 'medium'}
        />
      )}
    </div>
  );
}
