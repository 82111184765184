import React, { useState } from 'react';

import semver from 'semver';

import { Button, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { Delete, OfflinePin } from '@material-ui/icons';

import { Mutation } from 'react-apollo';
import { camelCase, get } from 'lodash';

import { WorkflowIcon, ExpandedTextArea } from 'components';
import ChipItem, { LinkType, StatusVersionType } from 'components/ChipItem';
import MakeLink from 'components/MakeLink';
import IconButton from 'components/IconButton';
import { ItemSearch } from 'compositions';
import slugs from 'displaySlugs';

import { destroyLinkedChange, queryNameToRefetch, useAddTaskToImpactedItem, useRemoveTaskToImpactedItem } from '../gql';
import { useStyles } from '../styles';
import VersionSequenceWithStatus from './VersionColumn';

interface Props {
  row: any;
  itemRecord: any;
  version: any;
}

const ImpactedItemTableRow = (props: Props) => {
  const classes = useStyles();
  const { row, itemRecord, version } = props;
  const [searchModalAnchor, setSearchModalAnchor] = useState<EventTarget | null>(null);
  const searchModalOpen = Boolean(searchModalAnchor);

  const [addTaskReferenceToImpactedItem] = useAddTaskToImpactedItem();
  const [removeTaskReferenceToImpactedItem] = useRemoveTaskToImpactedItem();

  const itemVersions: any[] = get(row, 'version.item.versions');

  const addAssociatedTasksToItem = (selectedRecords) => {
    addTaskReferenceToImpactedItem({
      variables: {
        versionId: row.currentVersionId,
        referencedTaskIds: selectedRecords,
      },
    }).catch((err) => {
      throw new Error(`Error adding associated task to item: ${err}`);
    });
  };

  return (
    <TableRow key={row.customIdentifier} id={`linked-rev-${row.id}`} className={classes.tableRow}>
      <TableCell scope="row" align="left" className={classes.tableCell} style={{ whiteSpace: 'nowrap' }}>
        <Tooltip disableFocusListener={false} title={`${row.customIdentifier}:${row.title}`} key={row.customIdentifier}>
          <span>
            <MakeLink
              newTab={true}
              item={{
                id: row.id,
                itemType: {
                  displaySlug: slugs[row.itemType],
                  userCreatable: row.userCreatable,
                },
              }}
            >
              {row.customIdentifier}
            </MakeLink>
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        {row.title}
      </TableCell>
      <TableCell align="center" className={classes.tableCell}>
        <VersionSequenceWithStatus versions={itemVersions} status={row.currentStatusName} />
      </TableCell>
      <TableCell align="center" className={classes.tableCell}>
        <WorkflowIcon type={camelCase(row.currentStatusName)} completed size="small" />
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <ExpandedTextArea
          key={`reason-for-change-${row.id}`}
          locked={row.version.locked}
          id={`reason-for-change-${row.id}`}
          initialValue={row.reasonForChange}
          versionId={row.currentVersionId}
          attrName="reason_for_change"
          rows={2}
          placeholder="Enter reason"
          className={classes.textBox}
          refetchQueries={[queryNameToRefetch]}
        />
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <ExpandedTextArea
          key={`description-of-change-${row.id}`}
          locked={row.version.locked}
          id={`description-of-change-${row.id}`}
          initialValue={row.descriptionOfChange}
          versionId={row.currentVersionId}
          attrName="description_of_change"
          rows={2}
          placeholder="Enter description"
          className={classes.textBox}
          refetchQueries={[queryNameToRefetch]}
        />
      </TableCell>
      {semver.lt('9.1.0', version.displayAsApplicationVersion) && (
        <TableCell align="left" className={classes.tableCell}>
          <IconButton
            disabled={version.locked}
            Icon={OfflinePin}
            onClick={(evt) => {
              setSearchModalAnchor(evt.target);
            }}
            size="medium"
          />
          <ItemSearch
            open={searchModalOpen}
            anchorEl={searchModalAnchor}
            closeSelf={() => setSearchModalAnchor(null)}
            onSubmit={(selectedRecords) =>
              new Promise((resolve) => {
                addAssociatedTasksToItem(selectedRecords);
                resolve(true);
              })
            }
            itemTypes={['task']}
            draftOnly={false}
            excludeVoidAndCancel
            supportsChangeOrder={false}
            usesCurrentVersion={true}
            defaultSearchTerm={''}
          />
          <div style={{ display: 'inline-block' }}>
            {row.taskReferencedVersions.length ? (
              row.taskReferencedVersions.map((task) => (
                <ChipItem
                  key={task.id}
                  statusVersionDot={StatusVersionType.status}
                  linkType={LinkType.internalNewTab}
                  option={{
                    id: task.id,
                    customIdentifier: task.item.customIdentifier,
                    currentVersion: {
                      versionIdentifier: task.item.currentVersion.versionIdentifier,
                      title: task.item.currentVersion.title,
                      currentStatus: { name: task.item.currentVersion.currentStatus.name },
                    },
                    itemType: {
                      displaySlug: task.item.itemType.displaySlug,
                    },
                  }}
                  getTagProps={{
                    style: { marginLeft: '3px' },
                    onDelete: () =>
                      removeTaskReferenceToImpactedItem({
                        variables: { versionId: row.currentVersionId, taskId: task.id },
                      }),
                  }}
                  disable={version.locked}
                />
              ))
            ) : (
              <Typography style={{ marginLeft: '3px' }} variant="body2">
                No tasks associated
              </Typography>
            )}
          </div>
        </TableCell>
      )}
      <Mutation mutation={destroyLinkedChange} refetchQueries={['timelineQuery', queryNameToRefetch]}>
        {(mutate, { error }) => {
          if (error) throw new Error(`Destroying linked change failed: ${error}`);

          const payload = {
            variables: {
              input: {
                versionId: row.currentVersionId,
                changeItemId: itemRecord ? itemRecord.id : null,
              },
            },
          };

          return (
            <>
              <TableCell align="center" className={classes.tableCell}>
                {row.currentStatusName === 'draft' && (
                  <Button
                    className={classes.deleteIcon}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      mutate(payload);
                    }}
                  >
                    <Delete id="delete-impacted-item-btn" />
                  </Button>
                )}
              </TableCell>
            </>
          );
        }}
      </Mutation>
    </TableRow>
  );
};

export default ImpactedItemTableRow;
