import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { ReactComponent as CheckListOutlinedIcon } from './check-list-outlined.svg';

const CheckListOutlined = (props) => {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <CheckListOutlinedIcon {...svgProps} />} />;
};

export default CheckListOutlined;
