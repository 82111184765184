import React from 'react';

import { YesOrNo } from 'components';
import { TraceMatrixWorkflowStatuses } from 'workflows/statuses';

import { RejectReason } from '../views';

const VoidPrompt = ({ signatureType }) =>
  `Are you sure you want to Void your ${signatureType} signature of this Item? This action cannot be undone`;

const SignatureStatement = ({ id }) => `As the Approver of ${id}, I Void my signature.`;

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => {
        const prompt = <VoidPrompt signatureType="Owner Approval" />;
        return <YesOrNo heading="Void" prompt={prompt} closeOnNo {...props} />;
      },
      getNextStep: (cancel) => (cancel ? 'voidReason' : undefined),
    },
    voidReason: {
      component: (props) => {
        const heading = <SignatureStatement id={props.itemCustomIdentifier} />;
        return <RejectReason {...props} title="Void" heading={heading} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: (props) => {
    return !props.confirmTransition
      ? null
      : {
          ...props.voidReason.signature,
          reasonForSignature: props.voidReason.reason,
          toStatus: TraceMatrixWorkflowStatuses.Void.id,
        };
  },
};
