import React from 'react';

import { Trans } from '@lingui/macro';
import flowRight from 'lodash/flowRight';

import { Chip, Grid, Typography } from '@material-ui/core';

/*
Temporarily commented out per QAD-2381

import { ClickAwayListener, IconButton, Paper, Popover, Tooltip, WorkflowIcon } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import Brush from '@material-ui/icons/Brush';
import ControlPointDuplicate from '@material-ui/icons/ControlPointDuplicate';
import Delete from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import Link from '@material-ui/icons/Link';

import ItemSearch from 'compositions/ItemSearch';
*/

import { ContentSection, Spacer } from 'components';
import withCurrentUser from 'compositions/WithCurrentUser';

import AssignedCourses from './AssignedCourses';

import useStyles from './styles';

import {
  //useAddCurriculumToTrainingPlan,
  //useRemoveCurriculumFromTrainingPlan,
  useAddCourseToTrainingPlan,
  useRemoveCourseFromTrainingPlan,
  useVersion,
} from './gql';
import ChipItem, { LinkType } from 'components/ChipItem';

function PayloadFields({ layoutClasses, versionId, currentUser }: { layoutClasses?; versionId?; currentUser }) {
  const classes: any = useStyles();

  // Temporarily commented this and others out per QAD-2381
  //const [blankCurriculumRow, setBlankCurriculumRow] = useState(false);

  //const [curriculumSearchAnchorEl, setCurriculumSearchAnchorEl] = useState();
  //const [associatedCoursesAnchorEl, setAssociatedCoursesAnchorEl] = useState();

  //const [selectedCurriculumIds, setSelectedCurriculumIds] = useState(new Set());

  //const [addCurriculumToTrainingPlan] = useAddCurriculumToTrainingPlan();
  //const [removeCurriculumFromTrainingPlan] = useRemoveCurriculumFromTrainingPlan();
  const [addCourseToTrainingPlan] = useAddCourseToTrainingPlan();
  const [removeCourseFromTrainingPlan] = useRemoveCourseFromTrainingPlan();

  const { data, loading, error, refetch } = useVersion(versionId);
  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Training Plan Version ${versionId}: ${error.message}`);

  // const handleAssociatedCoursesClick = (event) => {
  //   event.nativeEvent.stopImmediatePropagation();
  //   setAssociatedCoursesAnchorEl(event.currentTarget);
  // };

  // const handleAssociatedCoursesClose = () => setAssociatedCoursesAnchorEl(null);

  const versionData = data.trainingPlanVersion;

  const isTrainingManager = currentUser.jobRoles.some((role) => role.roleName === 'training_manager');
  // const permissionsText = 'You do not have the proper role or permissions to perform this action';

  return (
    <>
      <Grid item xs={8} className={layoutClasses.grid}>
        <ContentSection
          fillHeight={false}
          Label={
            <Typography variant="h3">
              <Trans>User Information</Trans>
            </Typography>
          }
        >
          <Spacer factor={3} />
          <Typography variant="body2" component="div" className={classes.employeeSection}>
            <span className={layoutClasses.boldHeaderText}>
              <Trans>Associated User</Trans>
            </span>
            <ChipItem
              option={{
                id: versionData.employee.id,
                currentVersion: {
                  title: 'task.item.currentVersion.title',
                },
                itemType: {
                  displaySlug: 'employees',
                },
              }}
              customContent={versionData.employee.user.fullName}
              getTagProps={{ className: classes.chip }}
              linkType={LinkType.internalNewTab}
            />
          </Typography>
          <Typography variant="body2" component="div" className={classes.jobRoleSection}>
            <span className={layoutClasses.boldHeaderText}>
              <Trans>Job Role(s)</Trans>
            </span>
            <div
              style={{
                width: '85%',
                verticalAlign: 'top',
                display: 'inline-block',
              }}
            >
              {versionData.employee.user.userJobRoles.map((role, idx) => (
                <Chip key={idx} label={role.jobRole.formattedRoleName} className={classes.chip} />
              ))}
            </div>
          </Typography>
        </ContentSection>
        {/* Comment out for now per QAD-2381
      <Spacer factor={5} />
      <Grid item xs={12} className={layoutClasses.grid}>
        <ContentSection
          BigHeading={<Trans>Assigned Curricula</Trans>}
          BigHeadingIcon={<Brush />}
          flexDirection={'row'}
          padding={false}
          fillHeight={false}
        >
          {!versionData.locked && (
            <IconButton
              aria-label="delete-curriculum-version-button"
              className={classes.titleSecondaryActionButton}
              disableRipple
              disabled={loading}
              onClick={async () => {
                if (!selectedCurriculumIds.size) return;
                await Promise.all(
                  versionData.curriculumVersions
                    .filter((curriculumVersion) => selectedCurriculumIds.has(curriculumVersion.id))
                    .map((curriculumVersion) =>
                      removeCurriculumFromTrainingPlan({
                        variables: {
                          trainingPlanVersionId: versionData.id,
                          curriculumVersionId: curriculumVersion.id,
                        },
                      }),
                    ),
                );
                setSelectedCurriculumIds(new Set());
                await refetch();
              }}
            >
              {Boolean(selectedCurriculumIds.size) && <Delete className={classes.titleSecondaryActionIcon} />}
            </IconButton>
          )}
        </ContentSection>

        <ContentSection
          LabelText={
            <>
              <ControlPointDuplicate className={layoutClasses.icon} />
              <Trans>Curricula</Trans>
            </>
          }
          fillHeight={false}
        >
          <Spacer factor={1} />
          {versionData.curriculumVersions.length !== 0 && (
            <>
              <Typography
                variant="subtitle2"
                className={classes.associatedItemColumn}
                style={{ display: 'inline-block', width: '64%', marginLeft: 28 }}
              >
                <Trans>Item ID</Trans>
              </Typography>
              <Typography variant="subtitle2" className={classes.associatedItemColumn} style={{ width: '36%' }}>
                <Trans>Status</Trans>
              </Typography>
              <Spacer factor={1} />
            </>
          )}
          {versionData.curriculumVersions.map((curriculumVersion) => (
            <Grid key={curriculumVersion.id} container>
              <Tooltip title={isTrainingManager ? '' : permissionsText} className={classes.tooltip}>
                <span>
                  <Checkbox
                    disabled={versionData.locked || !isTrainingManager}
                    className={classes.tableRowCheckbox}
                    color="primary"
                    onClick={() => {
                      const newSelectedCurriculum = new Set(selectedCurriculumIds);
                      if (newSelectedCurriculum.has(curriculumVersion.id)) {
                        newSelectedCurriculum.delete(curriculumVersion.id);
                      } else {
                        newSelectedCurriculum.add(curriculumVersion.id);
                      }
                      setSelectedCurriculumIds(newSelectedCurriculum);
                    }}
                  />
                </span>
              </Tooltip>
              <Paper variant="outlined" className={classes.associatedItemContainer}>
                <Typography
                  variant="body2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/category/curricula/${curriculumVersion.item.id}`)}
                >{`${curriculumVersion.item.customIdentifier}: ${curriculumVersion.title}`}</Typography>
                <IconButton
                  classes={{ root: classes.infoButton }}
                  size="small"
                  disabled={versionData.locked}
                  onClick={handleAssociatedCoursesClick}
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
                <ClickAwayListener onClickAway={handleAssociatedCoursesClose}>
                  <Popover
                    open={Boolean(associatedCoursesAnchorEl)}
                    anchorEl={associatedCoursesAnchorEl}
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handleAssociatedCoursesClose}
                  >
                    <Typography variant="h3">
                      <Trans>Associated Courses</Trans>
                    </Typography>
                    <Spacer factor={2} />
                    {versionData.curriculumVersions.map((curriculumVersion) =>
                      curriculumVersion.courseVersions.map((courseVersion) => {
                        return (
                          <Typography
                            variant="body2"
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/category/courses/${courseVersion.item.id}`)}
                          >{`${courseVersion.item.customIdentifier}: ${courseVersion.title}`}</Typography>
                        );
                      }),
                    )}
                  </Popover>
                </ClickAwayListener>
              </Paper>
              <Grid item xs style={{ marginLeft: 70 }}>
                <Spacer factor={1} />
                <WorkflowIcon type={camelCase(curriculumVersion.currentStatus.name)} completed size="tiny" />
              </Grid>
            </Grid>
          ))}

          {blankCurriculumRow && (
            <Grid container>
              <Paper variant="outlined" className={classes.associatedItemContainer} style={{ marginLeft: 27 }}>
                <IconButton
                  classes={{ root: classes.linkButton }}
                  size="small"
                  disabled={versionData.locked || !isTrainingManager}
                  onClick={(e) => setCurriculumSearchAnchorEl(e.target)}
                >
                  <Link fontSize="small" />
                </IconButton>
                <ItemSearch
                  draftOnly={false}
                  supportsChangeOrder={false}
                  itemTypes={['curriculum']}
                  open={Boolean(curriculumSearchAnchorEl)}
                  closeSelf={() => setCurriculumSearchAnchorEl(null)}
                  onSubmit={(curriculumVersionIds) => {
                    setBlankCurriculumRow(false);
                    return Promise.all(
                      curriculumVersionIds.map((curriculumVersionId) =>
                        addCurriculumToTrainingPlan({
                          variables: {
                            trainingPlanVersionId: versionData.id,
                            curriculumVersionId,
                          },
                        }),
                      ),
                    )
                      .then(() => refetch())
                      .then(() => Promise.resolve(true))
                      .catch((e) => {
                        throw new Error(`Error occurred while adding Curriculum to Training Plan: ${e.message}`);
                      });
                  }}
                  anchorEl={curriculumSearchAnchorEl}
                />
              </Paper>
            </Grid>
          )}

          {!blankCurriculumRow && !versionData.locked && (
            <Tooltip title={isTrainingManager ? '' : permissionsText} className={classes.tooltip}>
              <span>
                <IconButton
                  aria-label="add-curriculum-button"
                  className={classes.addRowButton}
                  disableRipple
                  onClick={() => setBlankCurriculumRow(true)}
                  disabled={!isTrainingManager}
                >
                  <AddCircle className={classes.addRowIcon} color="inherit" fontSize="inherit" />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </ContentSection>
      </Grid>
      */}

        <Spacer factor={3} />
        <AssignedCourses
          hasManagerialPermissions={isTrainingManager}
          onAdd={(courseIds) => {
            addCourseToTrainingPlan({
              variables: {
                trainingPlanVersionId: versionData.id,
                courseIds: courseIds,
              },
            })
              .then(() => refetch())
              .then(() => Promise.resolve(true))
              .catch((e) => {
                throw new Error(`Error occurred while adding Course to Training Plan: ${e.message}`);
              });
          }}
          onDelete={(course) => {
            removeCourseFromTrainingPlan({
              variables: {
                trainingPlanVersionId: versionData.id,
                courseId: course.id,
              },
            })
              .then(() => refetch())
              .catch((e) => {
                throw new Error(`Failed to remove course: ${e.message}`);
              });
          }}
          trainingPlanVersion={versionData}
        />
      </Grid>
    </>
  );
}

export default flowRight([withCurrentUser])(PayloadFields);
