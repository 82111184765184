import { get } from 'lodash';
import React, { useState } from 'react';
import { Query } from 'react-apollo';

import { Trans } from '@lingui/macro';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AttachFile from '@material-ui/icons/AttachFile';
import Notes from '@material-ui/icons/Notes';

import {
  BooleanRadioBtnField,
  ContentSection,
  DocumentUploader,
  PDFViewer,
  Spacer,
  TextAreaField,
} from 'components';
import attachmentQuery from 'utils/gql/attachmentQuery';

const withAttachmentData = (WrappedComponent) => (componentProps) => (
  <Query
    query={attachmentQuery}
    skip={!get(componentProps, 'versionData.id')}
    variables={{
      versionId: get(componentProps, 'versionData.id'),
    }}
  >
    {({ data, loading, refetch }) => {
      refetch();
      const attachment = get(
        data,
        'version.virtualAttributes[0].attachments[0]',
      );
      return (
        <WrappedComponent
          {...componentProps}
          filename={get(attachment, 'filename')}
          url={get(attachment, 'url')}
          attachmentId={get(attachment, 'id')}
          refetchAttachments={refetch}
          loading={loading}
        />
      );
    }}
  </Query>
);

const PayloadFields = ({ layoutClasses, versionData, ...props }) => {
  const [loadingAttachment, setLoadingAttachment] = useState(false);

  return (
    <>
      <Grid item xs={8} className={layoutClasses.gridGutterRight}>
        <ContentSection fillHeight={false}>
          <Spacer factor={1} />
          <div className={layoutClasses.multiTextAreaFields}>
            <span style={{ width: '20%' }}>
              <Typography variant="body2" className={layoutClasses.heading}>
                <Trans>Standard #</Trans>
              </Typography>
              <Spacer factor={1} />
              <TextAreaField
                id="standard-number-text-area-field"
                locked={versionData.locked}
                initialValue={versionData.standardNumber}
                versionId={versionData.id}
                rows={1}
                refetchQueries={['standardDocumentQuery']}
                attrName={'standard_number'}
              />
            </span>
            <span style={{ width: '50%' }}>
              <Typography variant="body2" className={layoutClasses.heading}>
                <Trans>Name</Trans>
              </Typography>
              <Spacer factor={1} />
              <TextAreaField
                id="standard-name-text-area-field"
                locked={versionData.locked}
                initialValue={versionData.standardName}
                versionId={versionData.id}
                rows={1}
                refetchQueries={['standardDocumentQuery']}
                attrName={'standard_name'}
              />
            </span>
            <span style={{ width: '20%' }}>
              <Typography variant="body2" className={layoutClasses.heading}>
                <Trans>Edition</Trans>
              </Typography>
              <Spacer factor={1} />
              <TextAreaField
                id="edition-text-area-field"
                locked={versionData.locked}
                initialValue={versionData.edition}
                versionId={versionData.id}
                rows={1}
                refetchQueries={['standardDocumentQuery']}
                attrName={'edition'}
              />
            </span>
          </div>
        </ContentSection>

        <Spacer factor={3} />

        <ContentSection
          fillHeight={false}
          LabelText={
            <>
              <Notes className={layoutClasses.icon} />
              <Trans>Standard Applicability</Trans>
            </>
          }
        >
          <Spacer factor={1} />
          <BooleanRadioBtnField
            id="standard-applicability-selector"
            label={<Trans>Does the standard apply?</Trans>}
            attrName={'does_standard_apply'}
            locked={versionData.locked}
            options={[
              {
                label: <Trans>Yes</Trans>,
                value: 'true',
              },
              {
                label: <Trans>No</Trans>,
                value: 'false',
              },
            ]}
            refetchQueries={['standardDocumentQuery']}
            versionId={versionData.id}
            selectedValue={versionData.doesStandardApply}
          />
          <Spacer factor={1} />
          <TextAreaField
            locked={versionData.locked}
            id="standard-applicability-justification"
            initialValue={versionData.justificationIfDoesNotApply}
            versionId={versionData.id}
            attrName="justification_if_does_not_apply"
            rows={5}
            placeholder="Enter justification"
            refetchQueries={['standardDocumentQuery']}
          />
        </ContentSection>
      </Grid>

      <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
        <ContentSection
          LabelText={
            <>
              <AttachFile className={layoutClasses.icon} />
              <Trans>Standard Document</Trans>
            </>
          }
          fillHeight={false}
        >
          <Spacer factor={1} />
          <DocumentUploader
            attrName={'attachment'}
            attachmentId={props.attachmentId}
            filename={props.filename}
            locked={versionData.locked}
            onCompleted={props.refetchAttachments}
            beforeUpload={() => setLoadingAttachment(true)}
            beforeDelete={() => setLoadingAttachment(false)}
            versionId={versionData.id}
          />
        </ContentSection>
      </Grid>

      <Grid
        item
        xs={12}
        className={`${layoutClasses.grid} ${layoutClasses.pdfView}`}
      >
        <PDFViewer loading={loadingAttachment} url={props.url} />
      </Grid>
    </>
  );
};

export default withAttachmentData(PayloadFields);
