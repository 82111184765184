import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import { Spacer } from 'components';

import styles from './styles';

function ChangeOrderClosureConfirmation({ classes, handleComplete }) {
  return (
    <>
      <div className={classes.heading}>
        <Typography variant="h3">Close Confirmation</Typography>
        <Spacer factor={1} />
        <Typography variant="body2">Approve CO closure?</Typography>
      </div>

      <Spacer factor={3} />

      <div style={{ display: 'flex' }}>
        <Button
          id="co-close-no"
          style={{ flex: 1, marginRight: '5px' }}
          onClick={() => handleComplete({ approve: false })}
          color="primary"
          variant="outlined"
        >
          Reject
        </Button>
        <Button
          id="co-close-yes"
          style={{ flex: 1, marginLeft: '5px' }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleComplete({ approve: true })}
        >
          Approve
        </Button>
      </div>
    </>
  );
}
export default withStyles(styles)(ChangeOrderClosureConfirmation);
