import React from 'react';
import { getLayoutComponent } from 'utils/pageUtils';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { Views } from 'workspaces';

import BuilderView from 'compositions/FormBasedBuilderView';
import EffectiveView from 'compositions/FormBasedEffectiveView';
import PayloadFields from './PayloadFields';
import { DocumentToolbar } from 'itemTypes/mixins/Document';

function RecordPage(props) {
  switch (props.activeWorkspaceView.id) {
    case Views.Builder.id:
      return (
        <BuilderView
          itemId={props.itemId}
          itemTypeName={'inProcessInspection'}
          listViewQueryToRefetch={'inProcessInspectionsQuery'}
          PayloadFields={PayloadFields}
          categorySlug={'in-process-inspections'}
        />
      );
    case Views.Effective.id:
      return (
        <EffectiveView
          itemId={props.itemId}
          itemTypeName={'inProcessInspection'}
          listViewQueryToRefetch={'inProcessInspectionsQuery'}
          PayloadFields={PayloadFields}
          EffectiveViewOptions={DocumentToolbar}
          categorySlug={'in-process-inspections'}
          formBasedRecordItemTypeName={'inProcessInspectionRecord'}
        />
      );
    default:
      const LayoutComponent = getLayoutComponent(props.activeWorkspaceView.id);
      return (
        <LayoutComponent
          {...props}
          itemType={'inProcessInspection'}
          displayHistoryInEffectiveView
          PayloadFields={PayloadFields}
        />
      );
  }
}

export default withWorkspaceViews()(RecordPage);
