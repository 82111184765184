import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { Query, useQuery } from 'react-apollo';
import {
  withCurrentCompany_currentCompanyQuery,
  withCurrentCompany_currentCompanyQuery_currentCompany as Company,
  withCurrentCompany_currentCompanyQueryVariables,
} from './__generated__/withCurrentCompany_currentCompanyQuery';

const CURRENT_COMPANY_QUERY = gql`
  query withCurrentCompany_currentCompanyQuery($subdomain: String) {
    currentCompany(subdomain: $subdomain) {
      id
      name
      subdomain
      validatedSystem
      disabledCategories
      enabledCategories
      allowTransitioning
      allowCustomPermissions
      requirePasswordChangeAfter
      licenseType
      licenseTier
      logoUrl
      remainingGeneralUserSeats
      remainingViewOnlyUserSeats
      remainingTrainAndViewUserSeats
      partnerWhitelistUserCount
      timeZone
      availableTimeZones
      streetAddress
      city
      stateOrProvince
      zipCode
      phoneNumber
      emailAddress
      moduleMenuItems {
        id
        state
      }
      deployments {
        currentVersion {
          version
          deployedAt
        }
        previousVersion {
          version
        }
      }
      config {
        trainingManagement {
          independentStudyCertificationText
          defaultTrainingRecordVersionDaysDue
          newDocumentVersionDaysDue
          newTrainingPlanVersionDaysDue
          uprevItemVersionDaysDue
          uprevTrainingPlanVersionDaysDue
          items {
            key
            label
            subLabel
          }
        }
        supplierManagement {
          expirationHighRisk
          expirationLowRisk
          expirationMediumRisk
        }
        generalUserSeatCountLimit
        viewOnlyUserSeatCountLimit
        trainAndViewUserSeatCountLimit
      }
    }
  }
`;

export interface WithCurrentCompanyProps {
  currentCompany: Company;
  currentCompanyValidatedSystem: boolean;
  currentCompanyProfessionalLicense: string;
  currentCompanyAllowsTransitioning: boolean;
}

const withCurrentCompany = (WrappedComponent) => (componentProps) => {
  // In this context, we are only concerned with the company name subdomain.
  const [subdomain] = get(window, 'location.hostname').split('.');

  return (
    <Query query={CURRENT_COMPANY_QUERY} variables={{ subdomain }}>
      {({ data, error, loading }) => {
        if (error) throw new Error(`Error retrieving company data: ${error}`);
        if (loading) return null;

        const currentCompany = get(data, 'currentCompany');
        const currentCompanyValidatedSystem = get(currentCompany, 'validatedSystem', false);
        const currentCompanyLicense = get(currentCompany, 'licenseType');
        const allowTransitioning = get(currentCompany, 'allowTransitioning', false);
        const allowCustomPermissions = get(currentCompany, 'allowCustomPermissions', false);
        const hasProfessionalLicense =
          currentCompanyLicense === 'professional' || currentCompanyLicense === 'professional_plus';

        return (
          <WrappedComponent
            {...componentProps}
            currentCompany={currentCompany}
            currentCompanyValidatedSystem={currentCompanyValidatedSystem}
            currentCompanyProfessionalLicense={hasProfessionalLicense}
            currentCompanyAllowsTransitioning={allowTransitioning}
            currentCompanyAllowsCustomPermissions={allowCustomPermissions}
          />
        );
      }}
    </Query>
  );
};

function WithCurrentCompany({ children, ...rest }) {
  return children(rest);
}

function useCurrentCompany() {
  return useQuery<withCurrentCompany_currentCompanyQuery, withCurrentCompany_currentCompanyQueryVariables>(
    CURRENT_COMPANY_QUERY,
  );
}

export { withCurrentCompany, useCurrentCompany, CURRENT_COMPANY_QUERY };
export default withCurrentCompany(WithCurrentCompany);
