import gql from 'graphql-tag';

export const PREPARE_CHANGE_ORDER_VERSION_FOR_TRAINING = gql`
  mutation PrepareChangeOrderVersionForTraining($id: ID!) {
    prepareChangeOrderVersionForTraining(id: $id) {
      willCreate {
        user {
          id
          fullName
        }
        trainingItemVersion {
          id
          item {
            id
            customIdentifier
            itemType {
              id
              userCreatable
            }
          }
        }
      }
      willNullify {
        id
        title
        item {
          id
          customIdentifier
          itemType {
            id
            userCreatable
          }
        }
      }
    }
  }
`;
