import React from 'react';

import { get } from 'lodash';

import { TriStateToggle } from 'components';

import { useUpdateRiskVersion } from '../hooks';
import { useStyles } from './styles';

interface Props {
  riskVersion: any;
  locked: boolean;
  which: 'final' | 'residual'; // naming is hard
}

export default function RiskAcceptable({ riskVersion, which, locked }: Props) {
  const updateField = useUpdateRiskVersion(riskVersion.id);
  const classes = useStyles();

  const attrName = `${which}RiskAcceptability`;
  const value = get(riskVersion, attrName, null);
  const update = useNull(updateField(attrName));

  return (
    <span className={classes.container}>
      <span className={classes.left}>No</span>
      <TriStateToggle toggled={value} onChange={update} disabled={locked} />
      <span className={classes.right}>Yes</span>
    </span>
  );
}

// useX is somewhat confusing with hooks, but I got no better idea yet
// also this move has a FP name but not much of that is used anyway
function useNull(fun) {
  return (x) => {
    if (x == null) return fun(null);
    return fun(x);
  };
}
