import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import { VersionAndStatusDot } from 'components';
import { useQuery } from 'react-apollo';

import SelectOption, { SingleSelectOptionProps } from './SelectOption';
import { DocumentNode } from 'graphql';

import { useStyles } from './styles';
import get from 'lodash/get';
// import { isUndefined } from 'lodash';

type showOptionVersionStatus = Pick<SingleSelectOptionProps, 'showOptionVersionStatus'>;

interface Props extends showOptionVersionStatus {
  disabled?: boolean;
  onChange: (id: string) => void;
  placeholder?: string;
  value?: any;
  options: {
    query: DocumentNode;
    optionsPath: string;
    filter?: (options: any[]) => any[];
  };
}

function SingleItemSelect(props: Props) {
  const classes = useStyles();

  const {
    options,
    disabled = false,
    onChange,
    placeholder = 'Select Associated',
    value,
    showOptionVersionStatus = false,
  } = props;
  const { data, error } = useQuery(options.query);

  if (error) {
    throw new Error(`Error loading users: ${error.message}`);
  }
  const selectOptions = data ? data[options.optionsPath] : [];
  const filteredOptions = options.filter !== undefined ? options.filter(selectOptions) : selectOptions;

  return (
    <Autocomplete
      closeIcon={<CancelIcon />}
      className={classes.autocomplete}
      disabled={disabled}
      size="small"
      options={filteredOptions}
      forcePopupIcon={false}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {value ? (
                  <VersionAndStatusDot version={value.versionIdentifier} status={value.currentStatus.name} />
                ) : (
                  ''
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          fullWidth
          variant="outlined"
          margin="none"
          placeholder={placeholder}
        />
      )}
      renderOption={(option) => <SelectOption showOptionVersionStatus={showOptionVersionStatus} item={option} />}
      getOptionLabel={(option) =>
        get(option, 'item.customIdentifier', '').length && option.title.length
          ? `${get(option, 'item.customIdentifier')}: ${option.title}`
          : ''
      }
      onChange={(_, selections) => onChange(selections)}
      value={value}
    />
  );
}

export default SingleItemSelect;
