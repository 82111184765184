export interface SortedItemTypes {
  productName: string;
  itemTypes: string[];
}

export const SORTED_ITEM_TYPES_BY_PRODUCTS: SortedItemTypes[] = [
  {
    productName: 'project_management',
    itemTypes: ['design_and_development_plan', 'design_and_development_report'],
  },
  {
    productName: 'design_inputs',
    itemTypes: [
      'system_architecture',
      'user_need',
      'user_needs_set',
      'requirement',
      'requirements_set',
      'trace_matrix',
    ],
  },
  {
    productName: 'risk_management_file',
    itemTypes: [
      'risk_assessment_document',
      'device_safety_characteristics_report',
      'risk_note',
      'risk',
      'risk_control',
      'risk_analysis',
      'risk_trace_matrices',
      'risk_management_plan',
      'failure_modes_and_effects_analysis',
      'risk_control_effectiveness_tests',
      'risk_control_implementation_tests',
      'risk_management_report',
    ],
  },
  {
    productName: 'software_file',
    itemTypes: ['software_safety_classification', 'software_development_plan'],
  },
  {
    productName: 'cybersecurity',
    itemTypes: [
      'cybersecurity_plan',
      'cybersecurity_system_characterization',
      'cyberattack_analysis',
      'common_weakness_assessment',
      'software_bill_of_material',
      'cybersecurity_report',
    ],
  },
  {
    productName: 'usability_engineering_file',
    itemTypes: ['device_usage_description', 'use_environment_specification', 'user_group_specification'],
  },
  {
    productName: 'regulatory',
    itemTypes: [
      'regulatory_strategy_plan',
      'indications_for_use',
      'patient_population_profile',
      'device_description',
      'standard_document',
      'labeling',
      'software_safety_classification',
    ],
  },
  {
    productName: 'testing',
    itemTypes: [
      'test_protocol_note',
      'verification_and_validation_plan',
      'device_verification',
      'device_validation',
      'verification_and_validation_report',
      'verification_record',
      'validation_record',
      'deviation',
    ],
  },
  {
    productName: 'design_outputs',
    itemTypes: ['design_output', 'in_process_inspection', 'incoming_quality_inspection', 'final_device_inspection'],
  },
  {
    productName: 'device_master_record',
    itemTypes: ['device_master_record'],
  },

  {
    productName: 'device_history_record',
    itemTypes: ['incoming_quality_inspection_record', 'in_process_inspection_record', 'final_device_inspection_record'],
  },
  {
    productName: 'drug_master_file',
    itemTypes: ['quality_by_design_plan', 'target_product_profile', 'primary_mode_of_action'],
  },
];
