import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Location, Router } from '@reach/router';

import { I18nProvider } from '@lingui/react';

import enusCatalog from 'locale/en_us/messages.js';

import AuthProvider from './AuthProvider';
import ReduxProvider from './ReduxProvider';
import ThemeProvider from './ThemeProvider';
import apolloClient from './apolloClient';
import initMemoryDB from './memoryDB';
import getAuthToken from 'utils/getAuthToken';
import setupAgGridTable from './setupAgGridTable';
import RouteChangeWorker from './RouteChangeWorker';

import { ForgotPassword, ForgotUsername, Login, ResetPassword, SetPassword, SignOut } from 'pages';
import AppRouter from './AppRouter';

import { SnackContextProvider } from 'contexts/other/snackContext';

export { initMemoryDB, setupAgGridTable };

function Providers({ children }) {
  return (
    <I18nProvider language="en_us" catalogs={{ en_us: enusCatalog }}>
      <ReduxProvider>
        <SnackContextProvider>
          <ThemeProvider>{children}</ThemeProvider>
        </SnackContextProvider>
      </ReduxProvider>
    </I18nProvider>
  );
}

function AppLoader(props) {
  // a way to force  a full rerender after sign in/ sign out
  const [refreshCount, setRefreshCount] = React.useState(0);
  const [authToken] = getAuthToken();
  return props.children({
    authenticated: Boolean(authToken),
    reloadApp: () => setRefreshCount(refreshCount + 1),
    count: refreshCount,
  });
}

function App() {
  return (
    <AppLoader>
      {({ authenticated, reloadApp }) => {
        if (!authenticated) {
          return (
            <ApolloProvider client={apolloClient}>
              <Providers>
                <AuthProvider default>
                  <Router>
                    <Login path="/login" reloadApp={reloadApp} />
                    <SignOut path="/sign-out" reloadApp={reloadApp} />
                    <ForgotPassword path="/forgot-password" reloadApp={reloadApp} />
                    <ForgotUsername path="/forgot-username" reloadApp={reloadApp} />
                    <ResetPassword path="/reset-password" reloadApp={reloadApp} />
                    <SetPassword path="/set-password" reloadApp={reloadApp} />
                  </Router>
                </AuthProvider>
              </Providers>
            </ApolloProvider>
          );
        }

        // Some context providers are rendered outside of the `Providers` component because it
        // is not compatable with tests. Integration testing with GraphQL requires
        // rendering a `MockedProvider` and mocked responses.
        // https://www.apollographql.com/docs/guides/testing-react-components.html
        return (
          <ApolloProvider client={apolloClient}>
            <Providers>
              <AuthProvider default>
                <AppRouter reloadApp={reloadApp} />
                <Location>{({ location }) => <RouteChangeWorker location={location} />}</Location>
              </AuthProvider>
            </Providers>
          </ApolloProvider>
        );
      }}
    </AppLoader>
  );
}

export { AuthProvider, Providers, AppLoader };
export default App;
