import React from 'react';

import { t } from '@lingui/macro';

import DomainIcon from '@material-ui/icons/Domain';
import { Typography } from '@material-ui/core';

import gql from 'graphql-tag';
import Views from 'workspaces/views';

import slugs from '../../displaySlugs';

import { riskStringToParams } from 'pages/Supplier/Id/PayloadFields/RenderTab';
import ColorBox from 'components/RiskLevelSelect/ColorBox';

import { VersionAndStatusDot } from 'components';
import { regularTextHeaderStyle } from 'components/TableView/Fields/RegularText';
import { versionHeaderStyle } from 'components/TableView/Fields/Version';
import { dateHeaderStyle } from 'components/TableView/Fields/Date';
import { IDCell, idHeaderStyle } from 'components/TableView/Fields/ID';
import MakeLink from 'components/MakeLink';

const itemType = 'supplier';

const listViewQuery = gql`
  query suppliers {
    suppliers {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        versionIdentifier
        categoryText
        suppliedScope
        qualificationText
        riskLevel
        approvalExpiresAt
        currentApprovalAt
        currentStatus {
          id
          name
        }
      }
    }
  }
`;

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
    headerStyle: idHeaderStyle,
    render(row) {
      if (!row.data) return null;
      return (
        <div style={{ width: 72 }}>
          <MakeLink item={row}>
            <IDCell data={row.customIdentifier} />
          </MakeLink>
        </div>
      );
    },
  },
  {
    field: 'currentVersion.title',
    headerName: 'Company Name',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.categoryText',
    headerName: 'Category',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.suppliedScope',
    headerName: 'Scope',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'qualification',
    headerName: 'Qualification',
    type: 'custom',
    headerStyle: versionHeaderStyle,
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        versionIdentifier: row.value.currentVersion.versionIdentifier,
        currentStatusName: row.value.currentVersion.currentStatus.name,
      });
    },
    render(row) {
      if (row.value === undefined) return null;
      let versionIdentifier;
      let currentStatusName;
      const isColumnGroupedBy = Boolean(
        row.columnApi.columnModel.rowGroupColumns.find((column) => column.colId === 'qualification'),
      );
      if (isColumnGroupedBy) {
        versionIdentifier = JSON.parse(row.value).versionIdentifier;
        currentStatusName = JSON.parse(row.value).currentStatusName;
      } else {
        versionIdentifier = row.data.currentVersion.versionIdentifier.replace(/v/i, 'q');
        currentStatusName = row.data.currentVersion.currentStatus.name;
      }
      return (
        <div style={{ width: 64, display: 'flex', justifyContent: 'center' }}>
          <VersionAndStatusDot version={versionIdentifier} status={currentStatusName} />
        </div>
      );
    },
  },
  {
    field: 'currentVersion.riskLevel',
    headerName: 'Risk Level',
    type: 'custom',
    headerStyle: regularTextHeaderStyle,
    enableRowGroup: true,
    width: '180px',
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        riskLevel: row.value.currentVersion.riskLevel,
      });
    },
    render(row) {
      if (row.value === undefined) return null;
      let riskLevel;
      const isColumnGroupedBy = Boolean(
        row.columnApi.columnModel.rowGroupColumns.find((column) => column.colId === 'currentVersion.riskLevel'),
      );
      if (isColumnGroupedBy) {
        riskLevel = JSON.parse(row.value).riskLevel;
      } else {
        riskLevel = row.data.currentVersion.riskLevel;
      }
      return (
        <div style={{ display: 'flex' }}>
          {riskLevel && (
            <>
              <ColorBox color={riskStringToParams(riskLevel).color} />
              <Typography variant="body2">{riskStringToParams(riskLevel).label}</Typography>
            </>
          )}
        </div>
      );
    },
  },
  {
    field: 'currentVersion.qualificationText',
    headerName: 'Qualification Method',
    type: 'regular_text',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Request Date',
    type: 'date',
  },
  {
    field: 'currentVersion.currentApprovalAt',
    headerName: 'Current Approval Date',
    type: 'date',
    headerStyle: dateHeaderStyle,
    width: '160px',
  },
  {
    field: 'currentVersion.approvalExpiresAt',
    headerName: 'Date Requalification Required',
    type: 'date',
    headerStyle: dateHeaderStyle,
    width: '190px',
  },
];

export default {
  categoryLabel: t`Suppliers`,
  displaySlug: slugs[itemType],
  label: t`Suppliers`,
  itemType,
  icon: DomainIcon,
  listViewQuery,
  listViewColumns,
  recordWorkspace: [Views.Supplier, Views.Monitoring],
  listWorkspace: [Views.Table, Views.LinksGraph],
};
