import React, { useState, useEffect } from 'react';

import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';

import get from 'lodash/get';
import xor from 'lodash/xor';
import flowRight from 'lodash/flowRight';

import { Loading, RecordsAsCategoryTree } from 'components';
import { CATEGORY_INDEX } from 'categories';
import itemTypes from 'item_types.json';

import styles from './styles';
import { useItemSearch } from 'utils/gql/itemSearch';
import { withItemTypeMetadata } from 'utils/withItemTypeMetadata';

interface ItemTypeMetadata {
  name: string;
  itemsCount: number;
  workflow: {
    id: string;
    name: string;
  };
}

interface ItemSearchResult {
  itemType: string;
  results: string[];
}

interface Props {
  anchorEl;
  closeSelf: () => void;
  classes;
  open: boolean;
  itemTypes?: string[];
  onSubmit: (recordIds: string[]) => Promise<boolean>;
  errorText?: string;
  draftOnly: boolean;
  excludeVoidAndCancel: boolean;
  supportsChangeOrder: boolean;
  excludeMembersAlreadyInSet: boolean;
  defaultSearchTerm: string;
  usesCurrentVersion: boolean;
  versionLink;
  enableVersionSupport: boolean;
  multiple?: boolean;
  customResults;
  allItemTypeMetadata: Record<string, ItemTypeMetadata>;
  loadingItemTypes?: boolean;
  excludedItemTypes?: string[];
}

function ItemSearch(props: Props) {
  const {
    classes,
    enableVersionSupport = false,
    multiple = true,
    customResults = null,
    allItemTypeMetadata,
    loadingItemTypes,
  } = props;
  const [searchTerm, setSearchTerm] = useState<string | null>(props.defaultSearchTerm);
  const [selectedItemTypes, setSelectedItemTypes] = useState<string[] | undefined>(props.itemTypes);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSelectedCategory, setLastSelectedCategory] = useState(null);

  const handleSearchCompleted = () => {
    setIsSearching(false);
  };

  const { data, error, loading } = useItemSearch(
    searchTerm,
    props.draftOnly,
    props.excludeVoidAndCancel,
    props.supportsChangeOrder,
    props.excludeMembersAlreadyInSet,
    selectedItemTypes,
    handleSearchCompleted,
  );
  const [itemSearchResults, setItemSearchResults] = useState<ItemSearchResult[]>([]);
  const workflowNamesForChangeOrder = ['generic', 'set_generic', 'trace_matrix', 'course_generic_with_members'];
  useEffect(() => {
    if (!props.itemTypes || props.itemTypes.length === 0) {
      const stubs = Object.values(allItemTypeMetadata)
        .filter((it) => it.itemsCount > 0)
        .filter((it) => !props.excludedItemTypes || !props.excludedItemTypes.includes(it.name))
        .filter((it) => {
          if (props.supportsChangeOrder) {
            return it.workflow && workflowNamesForChangeOrder.some((name) => it.workflow.name.includes(name));
          }
          return true;
        })
        .map((it) => ({
          itemType: it.name,
          results: [''],
        }));
      setItemSearchResults(stubs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItemTypeMetadata, props.itemTypes, props.excludedItemTypes]);

  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);

  const [itemCurrentVersion, setItemCurrentVersion] = useState<{ [id: string]: string }>({});

  if (error) throw new Error(`Item search failed: ${error}`);

  let results = customResults || itemSearchResults;
  if (props.itemTypes) {
    results = get(data, 'itemSearch');
  }

  const onKeyPress = (e) => {
    const term = e.target.value;
    if (term && e.key === 'Enter') {
      setSelectedRecords([]);
      setSearchTerm(term);
      setSelectedItemTypes([]);
    }
  };

  const resetDefaults = () => {
    setSearchTerm(props.defaultSearchTerm);
    setSelectedRecords([]);
    setItemCurrentVersion({});
    setSelectedItemTypes(props.itemTypes);
    setLastSelectedCategory(null);
    setIsSearching(false);
  };

  let resultMap = {}; // itemtypename => [itemtype]
  let whitelist = {}; // itemtypename => bool
  if (results && results.length > 0) {
    results.forEach((r) => (resultMap[r.itemType] = r.results));
    Object.keys(resultMap).forEach((t) => {
      const displaySlug = itemTypes[t].display_slug;
      if (!displaySlug) throw new Error(`displaySlug not defined for ${t}`);
      whitelist[displaySlug] = true;
      const parentSlug = get(CATEGORY_INDEX, [displaySlug, 'parentSlug']);
      if (parentSlug) whitelist[parentSlug] = true;
    });
  }
  const handleRecordClick = (record, _) => {
    const versionRecords = xor(selectedRecords, [record.currentVersionId]);
    const itemRecords = xor(selectedRecords, [record.id]);
    if (enableVersionSupport) {
      multiple ? setSelectedRecords(versionRecords) : setSelectedRecords([record.currentVersionId]);
    } else {
      props.usesCurrentVersion
        ? multiple
          ? setSelectedRecords(versionRecords)
          : setSelectedRecords([record.currentVersionId])
        : multiple
        ? setSelectedRecords(itemRecords)
        : setSelectedRecords([record.id]);
    }
  };

  const handleCategoryClick = (itemType) => {
    if (itemType === lastSelectedCategory) {
      return;
    }
    if (!props.itemTypes) {
      setIsSearching(true);
    }

    setSelectedItemTypes([itemType]);
    setSearchTerm('');
    setLastSelectedCategory(itemType);
  };

  useEffect(() => {
    if (data) {
      setItemSearchResults((prevResults) =>
        prevResults.map((item) => {
          const itemInData = data.itemSearch.find((searchItem) => searchItem.itemType === item.itemType);
          if (itemInData) {
            return { ...item, results: itemInData.results };
          }
          return item;
        }),
      );
    }
  }, [data]);

  const handleAddClick = async () => {
    const onSubmitValues = selectedRecords.map((val) => (itemCurrentVersion[val] ? itemCurrentVersion[val] : val));
    const success = await props.onSubmit(onSubmitValues);

    if (success) {
      props.closeSelf();
      resetDefaults();
    }
  };

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      marginThreshold={200}
      onClose={() => {
        resetDefaults();
        props.closeSelf();
      }}
      classes={{ paper: classes.addItemContainer }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <>
        <Typography variant="overline" component="p" className={classes.header}>
          Search item
        </Typography>
        <I18n>
          {({ i18n }) => (
            <TextField
              autoComplete="off"
              id="draft-items-search-input"
              placeholder={i18n._(t`Search by item ID or title`)}
              fullWidth
              variant="outlined"
              margin="dense"
              className={classes.searchContainer}
              onKeyPress={(e) => onKeyPress(e)}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search classes={{ root: classes.searchIcon }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </I18n>
        <div className={classes.results}>
          {((!loadingItemTypes && results && results.length === 0 && selectedItemTypes) ||
            (customResults && customResults[0].results.length === 0)) && (
            <Typography variant="body2" className={classes.noResults}>
              <Trans>No results found.</Trans>
            </Typography>
          )}

          {loading && !props.customResults && <Loading size={30} style={{ height: 70 }} />}
          <RecordsAsCategoryTree
            displayHeader={false}
            categoryRecordsMap={resultMap}
            selectedRecords={selectedRecords}
            categoryWhitelist={whitelist}
            recordIsSelectedProperty={props.usesCurrentVersion ? 'currentVersionId' : 'id'}
            style={{ paddingLeft: 30 }}
            setItemCurrentVersion={setItemCurrentVersion}
            onRecordClick={handleRecordClick}
            enableVersionSupport={enableVersionSupport}
            onCategoryClick={handleCategoryClick}
            isLoading={isSearching}
          />
        </div>
      </>
      <hr className={classes.splitter} />
      {props.errorText && <Typography className={classes.error}>{props.errorText}</Typography>}
      <div className={classes.addBtnContainer}>
        <Button
          id="add-impacted-item-btn"
          fullWidth
          color="primary"
          variant="contained"
          type="button"
          className={classes.addBtn}
          onClick={handleAddClick}
          disabled={selectedRecords.length < 1}
        >
          <Trans>Add</Trans>
        </Button>
      </div>
    </Popover>
  );
}

ItemSearch.defaultProps = {
  defaultSearchTerm: '',
  usesCurrentVersion: true,
  versionLink: null,
  excludeVoidAndCancel: false,
  excludeMembersAlreadyInSet: false,
} as Partial<Props>;

export default flowRight([withItemTypeMetadata, withStyles(styles)])(ItemSearch);
