import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    column80: {
      width: 80,
    },
    table: {
      '& th': {
        lineHeight: 1.5,
      },
    },
    itemTitle: {
      color: theme.palette.secondary.main,
    },
  }),
);
