import React from 'react';
import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';
import flowRight from 'lodash/flowRight';
import { IconButton, withStyles, Badge } from '@material-ui/core';

import Forum from '@material-ui/icons/Forum';

import MemberComments from '../MemberComments';
import styles from './styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    border: '2px solid white',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '.5rem',
    height: 22,
    minWidth: 22,
    borderRadius: 11,
  },
}))(Badge);

class MemberCommentsControl extends React.Component {
  constructor(props) {
    super(props);
    this.buttonRef = undefined;
  }
  render() {
    const { classes, commentCount, i18n, onClick, onClose, itemId, showPopup, queryToRefetch, disabled } = this.props;
    return (
      <>
        <IconButton
          data-testid="comments-control"
          aria-label={i18n._(t`Open Comments`)}
          buttonRef={(n) => (this.buttonRef = n)}
          disabled={disabled}
          disableRipple
          className={classes.iconButton}
          onClick={onClick}
        >
          <StyledBadge badgeContent={commentCount}>
            <Forum classes={{ root: classes.smallIcon }} />
          </StyledBadge>
        </IconButton>

        <MemberComments
          open={showPopup}
          anchorEl={this.buttonRef}
          onClose={onClose}
          itemId={itemId}
          queryToRefetch={queryToRefetch}
        />
      </>
    );
  }
}

export default flowRight([withStyles(styles), withI18n()])(MemberCommentsControl);
