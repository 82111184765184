import React, { useState } from 'react';

import { Paper, Typography } from '@material-ui/core';
import { Notes } from '@material-ui/icons';

import MenuBook from 'assets/icons/MenuBook';
import { Spacer, TextArea } from 'components';
import { AssociatedItems, AssociatedItemsVersions, ReferenceAttachments } from 'components/TableTrio';
import {
  useAddReferencedAttachment,
  useAddReferencedItem,
  useAddReferencedVersion,
  useRemoveReferencedAttachment,
  useRemoveReferencedItem,
  useRemoveReferencedVersion,
  useUpdateReferencedAttachment,
  useUpdateReferencedVersion,
} from 'components/TableTrio/gql';
import Panel from 'components/core/Panel';
import Signature from 'compositions/TimelineGroup/steps/views/Signature';

interface StepProps {
  error;
  handleComplete: (param: any) => void;
  handleClose: () => void;
  unsavedValue: any;
  currentVersion: any;
  currentUser: any;
  itemCustomIdentifier: string;
  itemTypeMetadata: any;
}

interface Props {
  stepProps: StepProps;
  title: string;
  subText: string;
  signatureDialog?: string;
  reasonField: {
    fieldLabel: string;
    fieldName: string;
  };
}

const SupplierRevocationRejection = (props: Props) => {
  const { error, handleComplete, currentVersion, itemTypeMetadata } = props.stepProps;
  const [reason, setReason] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const createAttachment = useAddReferencedAttachment();
  const updateAttachment = useUpdateReferencedAttachment();
  const destroyAttachment = useRemoveReferencedAttachment();

  const addAssociatedItem = useAddReferencedItem();
  const removeAssociatedItem = useRemoveReferencedItem();

  const addAssociatedItemVersion = useAddReferencedVersion();
  const removeAssociatedItemVersion = useRemoveReferencedVersion();
  const updateAssociatedItemVersion = useUpdateReferencedVersion();
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h3">{props.title}</Typography>
      </div>

      <div style={{ width: '592px' }}>
        <Typography variant={'h5'} style={{ padding: '32px 0 16px 0' }}>
          {props.subText}
        </Typography>
        <Paper elevation={0}>
          <Panel.Heading icon={Notes}>{props.reasonField.fieldLabel}</Panel.Heading>
          <TextArea
            required
            label={props.reasonField.fieldLabel}
            placeholder="Insert reason here*"
            variant="outlined"
            margin="normal"
            fullWidth
            multiline
            rows="2"
            InputLabelProps={{
              shrink: true,
            }}
            onTextChange={(reason) => setReason(reason)}
          />
        </Paper>
        <Typography variant={'subtitle1'} style={{ padding: '30px 0 0' }}>
          <MenuBook fontSize={'small'} /> <span style={{ verticalAlign: 'middle' }}>Reference Materials</span>
        </Typography>
        <div
          style={{
            overflowY: 'scroll',
            maxHeight: '400px',
            margin: '16px 0px',
          }}
        >
          <AssociatedItems
            showNotice={false}
            versionId={currentVersion.id}
            locked={false}
            associatedItems={currentVersion.revokedReferencedItems}
            handlers={{
              remove: (id: string) => {
                return removeAssociatedItem({
                  refetchQueries: ['timelineQuery'],
                  variables: {
                    versionId: currentVersion.id,
                    referencedItemId: id,
                  },
                });
              },
              addAssociatedItem: (id: string) => {
                return addAssociatedItem({
                  refetchQueries: ['timelineQuery'],
                  variables: {
                    versionId: currentVersion.id,
                    referencedItemIds: id,
                    trioType: 'revocation',
                  },
                });
              },
            }}
          />
          <Spacer factor={2} />
          <AssociatedItemsVersions
            versionId={currentVersion.id}
            locked={false}
            associatedVersions={currentVersion.revokedReferencedVersions}
            handlers={{
              removeAssociatedItemVersion: (id: string) => {
                return removeAssociatedItemVersion({
                  refetchQueries: ['timelineQuery'],
                  variables: {
                    versionId: currentVersion.id,
                    referencedVersionId: id,
                  },
                });
              },
              updateAssociatedItemVersion: (next, old) => {
                return updateAssociatedItemVersion({
                  refetchQueries: ['timelineQuery'],
                  variables: {
                    nextVersionId: next,
                    oldVersionId: old,
                    versionId: currentVersion.id,
                  },
                });
              },
              addAssociatedItemVersion: (versionId: string) => {
                return addAssociatedItemVersion({
                  refetchQueries: ['timelineQuery'],
                  variables: {
                    versionId: currentVersion.id,
                    referencedVersionIds: versionId,
                    trioType: 'revocation',
                  },
                });
              },
            }}
          />
          <Spacer factor={2} />
          <ReferenceAttachments
            handlers={{
              create: (versionId, attachment) => {
                return createAttachment({
                  refetchQueries: ['timelineQuery'],
                  variables: { versionId, attachment, trioType: 'revocation' },
                });
              },
              update: (attachmentId, attachment) => {
                return updateAttachment({
                  refetchQueries: ['timelineQuery'],
                  variables: {
                    id: attachmentId,
                    attachment: attachment.url,
                    description: attachment.description,
                  },
                });
              },
              destroy: (attachmentId) => {
                return destroyAttachment({
                  refetchQueries: ['timelineQuery'],
                  variables: { id: attachmentId },
                });
              },
            }}
            locked={false}
            referencedAttachments={currentVersion.revokedReferencedAttachments}
            versionId={currentVersion.id}
          />
        </div>
      </div>
      {props.signatureDialog && <Typography variant="body2">{props.signatureDialog}</Typography>}
      <div style={{ marginTop: 60 }}>
        <Signature
          itemType={itemTypeMetadata.name}
          error={error}
          disabled={!reason || !username || !password}
          handleSubmit={() => {
            return handleComplete({
              username,
              password,
              [props.reasonField.fieldName]: reason,
            });
          }}
          onUsernameChange={(e) => setUsername(e.target.value)}
          onPasswordChange={(e) => setPassword(e.target.value)}
        />
      </div>
    </>
  );
};

export default SupplierRevocationRejection;
