import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    addBtn: {
      ...theme.typography.button,
      color: theme.palette.primary.contrastText,
    },
    button: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
    addBtnContainer: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    addItemContainer: {
      width: 320,
    },
    rowIcon: {
      width: 12,
      height: 'auto',
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.25),
    },
    checkedRow: {
      color: theme.palette.primary.main,
    },
    disabledRow: {
      pointerEvents: 'none',
      cursor: 'not-allowed',
      opacity: 0.6,
    },
    fabButton: {
      backgroundColor: theme.palette.blueDark,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        opacity: 0.7,
      },
    },
    header: {
      ...theme.typography.overline2,
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
    noResults: {
      paddingBottom: theme.spacing(1),
    },
    productName: {
      ...theme.typography.overline2,
      paddingBottom: theme.spacing(1),
    },
    results: {
      overflowY: 'scroll',
      height: 'auto',
      maxHeight: 240,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    searchContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    cssOutlinedInput: {
      ...theme.typography.body2,
      height: 36,
      marginBottom: theme.spacing(1),
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    cssFocused: {},
    notchedOutline: {
      border: `2px solid ${theme.palette.common.input.outline} !important`,
    },
    searchIcon: {
      fontSize: 20,
    },
    searchResult: {
      marginBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    selectedRow: {
      backgroundColor: 'rgba(48, 188, 179, 0.1)',
    },
    smallIcon: {
      width: 16,
      height: 16,
      minHeight: 16,
      boxShadow: 'none',
      marginRight: theme.spacing(0.75),
    },
    splitter: {
      marginTop: 0,
      color: theme.palette.common.input.outline,
      borderStyle: 'solid',
    },
    tooltip: {
      ...theme.typography.body2,
    },
  });
