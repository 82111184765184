import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import get from 'lodash/get';

import { VersionAndStatusDot } from 'components';
import QuotedSection from 'components/core/QuotedSection';
import linkToItem from 'utils/linkToItem';

import { useStyles } from './styles';


function SupplierAssociatedDocsReleased(props: any) {
  const classes = useStyles();

  const questionnaireRecords= get(props, 'currentVersion.supplierQuestionnaireRecordVersions')
  const auditRecords=get(props, 'currentVersion.supplierAuditReportVersions')

  const notReleasedQuestionnaireRecords = questionnaireRecords.filter((item: any) => item.currentStatus.name !== 'released');
  const notReleasedAuditRecords = auditRecords.filter((item: any) => item.currentStatus.name !== 'released');

  const unreleasedDocsQuantity = notReleasedQuestionnaireRecords.length + notReleasedAuditRecords.length;
  useEffect(() => {
    if (unreleasedDocsQuantity===0) {
      props.handleComplete(true, { forgetThisStep: true });
    }
  }, [props, props.handleComplete, unreleasedDocsQuantity]);

  if (unreleasedDocsQuantity===0) return <div />;

  return (
      <>
        <div className={classes.heading}>
          <Typography variant="h3">Not Allowed</Typography>
        </div>
        <Typography component="p" variant="h5" align="left">
          {`This Supplier is associated with the following items and cannot be approved until they are released.`}
        </Typography>
        <QuotedSection className={classes.container}>
          <ul className={classes.list}>
            {notReleasedQuestionnaireRecords.map((record) => (
              <Link to={linkToItem('supplier-questionnaire-record', record.item.id)}>
                <li className={classes.listEntry}>
                  <Typography variant="h6" component="p" className={classes.text}>
                    {record.item.customIdentifier}: {record.title}
                  </Typography>
                  <VersionAndStatusDot version={record.versionIdentifier} status={record.currentStatus.name} />
                </li>
              </Link>
            ))}
            {notReleasedAuditRecords.map((record) => (
              <Link to={linkToItem('supplier-audit-report', record.item.id)}>
                <li className={classes.listEntry}>
                  <Typography variant="h6" component="p" className={classes.text}>
                    {record.item.customIdentifier}: {record.title}
                  </Typography>
                  <VersionAndStatusDot version={record.versionIdentifier} status={record.currentStatus.name} />
                </li>
              </Link>
            ))}
          </ul>
        </QuotedSection>
    </>
  );
}

export default SupplierAssociatedDocsReleased;
