import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    toggleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(0.5),
    },
    toggleText: {
      margin: `3px ${theme.spacing(1.5)}px 0`,
    },
  }),
);
