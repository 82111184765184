import React from 'react';
import flowRight from 'lodash/flowRight';
import { Trans } from '@lingui/macro';
import LinearScale from '@material-ui/icons/LinearScale';

import { ContentSection, WorkflowIcon } from 'components';
import WorkflowMenu, { CancelMenuItem } from 'components/WorkflowMenu';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import ConnectBar from 'compositions/TimelineGroup/components/ConnectBar';
import PermissionFailModal from 'compositions/TimelineGroup/components/PermissionFailModal';
import { AlertDialog } from 'components';

import { RetirementStatus } from '../RetirementStatus';
import { Typography } from '@material-ui/core';

class RetirementTimeline extends React.Component {
  state = {
    permissionFailModal: false,
    deniedModal: {
      open: false,
      heading: null,
      body: null,
    },
    releaseNotPermittedModal: false,
  };

  workflowMenu = () => {
    return (
      <WorkflowMenu>
        <CancelMenuItem
          disabled={
            this.props.currentVersion.currentStatusName !== RetirementStatus.RetirementInitiated ||
            !this.props.permissions.canOwn
          }
          onClick={() => this.props.handleInitTransition('cancelRetirement')}
        >
          Cancel Retirement
        </CancelMenuItem>
      </WorkflowMenu>
    );
  };

  openModal = (modalName) => {
    this.setState({ [modalName]: true });
  };

  closeModal = () => {
    this.setState({
      permissionFailModal: false,
      deniedModal: {
        open: false,
        heading: null,
        body: null,
      },
      releaseNotPermittedModal: false,
    });
  };

  render() {
    const { currentVersion, permissions, handleInitTransition, currentCompanyAllowsTransitioning } = this.props;

    const { permissionFailModal, releaseNotPermittedModal } = this.state;

    const hasChangeOrder =
      Boolean(currentVersion.linkedChangeCustomIdentifier) && !Boolean(currentVersion.linkedChangeJustificationText);

    return (
      <ContentSection scrollX LabelText={<Trans>Workflow</Trans>} Icon={<LinearScale />} Controls={this.workflowMenu()}>
        <WorkflowIcon
          type="ownerApproval"
          active={currentVersion.currentStatusName === RetirementStatus.RetirementInitiated}
          completed={currentVersion.currentStatusName !== RetirementStatus.RetirementInitiated}
          version={currentVersion.versionIdentifier}
          label="Retirement Initiated"
          size="large"
          visible
        />

        <ConnectBar color="approvedDraft" visible={hasChangeOrder} />

        <WorkflowIcon
          type="approvedDraft"
          active={currentVersion.currentStatusName === RetirementStatus.ApprovedRetirement}
          completed={currentVersion.currentStatusName !== RetirementStatus.RetirementInitiated}
          version={currentVersion.versionIdentifier}
          label="Approved Retirement"
          size="large"
          disabled={!permissions.canApprove}
          handleClick={() => {
            if (!permissions.canApprove) return this.openModal('permissionFailModal');

            if (currentVersion.currentStatusName === RetirementStatus.RetirementInitiated)
              return handleInitTransition('retireOrReject');
          }}
          visible={hasChangeOrder}
        />

        <ConnectBar color="obsolete" visible />

        <WorkflowIcon
          type="obsolete"
          version={currentVersion.versionIdentifier}
          completed={currentVersion.currentStatusName === RetirementStatus.Retired}
          label="Retired"
          size="large"
          handleClick={() => {
            if (!permissions.canApprove) {
              return this.openModal('permissionFailModal');
            } else {
              return handleInitTransition('retireOrRejectWithoutCO');
            }
          }}
          visible
        />

        <ConnectBar
          color="rejected"
          disabled={!currentCompanyAllowsTransitioning}
          visible={currentVersion.currentStatusName === RetirementStatus.RetirementRejected}
        />

        <WorkflowIcon
          type="rejected"
          active
          version={currentVersion.versionIdentifier}
          label="Retirement Rejected"
          size="large"
          disabledEffect={!currentCompanyAllowsTransitioning}
          handleClick={() => {
            if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');

            return handleInitTransition('resolveRejection');
          }}
          visible={currentVersion.currentStatusName === RetirementStatus.RetirementRejected}
        />

        <PermissionFailModal
          open={permissionFailModal || releaseNotPermittedModal}
          failType={permissionFailModal ? 'permissions' : 'release'}
          closeModal={this.closeModal}
        />

        <AlertDialog
          open={this.state.deniedModal.open}
          onClose={() => this.closeModal()}
          titleText={this.state.deniedModal.heading}
        >
          <Typography variant="body2">{this.state.deniedModal.body}</Typography>
        </AlertDialog>
      </ContentSection>
    );
  }
}

export default flowRight(withCurrentCompany)(RetirementTimeline);
