import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import { useComments } from 'api/useComments';
import useMentionableUsers from 'api/useMentionableUsers';
import { Loading } from 'components';

import Message from './Message';
import Input from './Input';
import styles from './styles';

interface CommentsProps {
  classes;
  commentInputRef: (el) => any;
  itemId: string;
  i18n;
  theme;
}

function Comments(props: CommentsProps) {
  const { itemId } = props;
  const {
    query: { data, loading, error },
    mutation: [createComment],
  } = useComments(itemId);
  const { users } = useMentionableUsers();

  const [draftComment, setDraftComment] = useState('');

  if (error) throw new Error(`${error}`);
  if (loading || !data || !data.item) return null;

  const { classes, commentInputRef } = props;

  if (loading) return <Loading />;

  const comments = data.item.comments.slice(0).reverse();

  return (
    <>
      <div
        className={classes.chatMessageContainer}
        data-testid="commentsContainer"
      >
        {comments.map((msg) => (
          <Message {...msg} classes={classes} key={msg.id} />
        ))}
      </div>

      <Input
        users={users}
        value={draftComment}
        inputRef={(ref) => commentInputRef(ref)}
        onChange={(e) => setDraftComment(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            draftComment.trim() &&
              createComment({
                variables: {
                  input: {
                    body: draftComment,
                    itemId,
                  },
                },
                refetchQueries: ['GetComments'],
              }).then(() => setDraftComment(''));
          }
        }}
      />
    </>
  );
}

export default withStyles(styles)(Comments);
