import React, { FC, useState } from 'react';

import { Edit, HighlightOff, OfflinePin, ThumbDown, ThumbUp } from '@material-ui/icons';

import OptionsDropdown from 'components/OptionsDropdown';
import ConfirmationDialog from 'components/ConfirmationDialog';
import AppRegistration from 'assets/icons/AppRegistration';

interface Props {
  editorRef: React.MutableRefObject<any>;
  trackChangesToggle: string;
  suggestionsCount: number;
  setTrackChangesToggle: React.Dispatch<React.SetStateAction<string>>;
  setTrackChangesAction: React.Dispatch<React.SetStateAction<ActionTypes | null>>;
  disabled?: boolean;
}

export enum ActionTypes {
  ACCEPT_SELECTED_SUGGESTIONS = 'acceptSelectedSuggestions',
  ACCEPT_ALL_SUGGESTIONS = 'acceptAllSuggestions',
  DISCARD_SELECTED_SUGGESTIONS = 'discardSelectedSuggestions',
  DISCARD_ALL_SUGGESTIONS = 'discardAllSuggestions',
}

const actionTitles = {
  [ActionTypes.ACCEPT_SELECTED_SUGGESTIONS]: 'Accept selected updates',
  [ActionTypes.ACCEPT_ALL_SUGGESTIONS]: 'Accept all selected updates',
  [ActionTypes.DISCARD_SELECTED_SUGGESTIONS]: 'Discard selected updates',
  [ActionTypes.DISCARD_ALL_SUGGESTIONS]: 'Discard all selected updates',
};

const TrackChangesToolbar: FC<Props> = (props) => {
  const { editorRef, trackChangesToggle, suggestionsCount, setTrackChangesToggle, disabled } = props;
  const [action, setAction] = useState<ActionTypes | null>(null);
  const [dialog, setDialog] = useState(false);

  const handleTrackChangesToggle = (event: any) => {
    // Opens confirmation dialog if any actions selected otherwise it will toggle the track changes mode
    if (Object.values(ActionTypes).includes(event.target.value)) {
      setAction(event.target.value);
      setDialog(true);
    } else {
      setTrackChangesToggle(event.target.value);
    }
  };

  const onActionConfirm = () => {
    // Executes suggestion action (ex. accept or discard suggestions)
    if (editorRef.current) {
      try {
        editorRef.current.execute(action);
        setDialog(false);
      } catch (error) {
        throw new Error('Cannot execute track changes action');
      }
    }
  };

  return (
    <>
      <OptionsDropdown
        value={trackChangesToggle}
        onChange={handleTrackChangesToggle}
        optionsTitle="Update Mode"
        options={[
          {
            value: 'suggesting',
            Icon: AppRegistration,
            text: 'Suggesting',
            secondaryText: 'Updates must be accepted',
          },
          {
            value: 'editing',
            Icon: Edit,
            text: 'Editing',
            secondaryText: 'Update the item directly',
          },
        ]}
        actionsTitle="Bulk Update Review"
        actions={[
          {
            value: ActionTypes.ACCEPT_SELECTED_SUGGESTIONS,
            Icon: ThumbUp,
            text: actionTitles[ActionTypes.ACCEPT_SELECTED_SUGGESTIONS],
          },
          {
            value: ActionTypes.ACCEPT_ALL_SUGGESTIONS,
            Icon: OfflinePin,
            text: actionTitles[ActionTypes.ACCEPT_ALL_SUGGESTIONS],
          },
          {
            value: ActionTypes.DISCARD_SELECTED_SUGGESTIONS,
            Icon: ThumbDown,
            text: actionTitles[ActionTypes.DISCARD_SELECTED_SUGGESTIONS],
          },
          {
            value: ActionTypes.DISCARD_ALL_SUGGESTIONS,
            Icon: HighlightOff,
            text: actionTitles[ActionTypes.DISCARD_ALL_SUGGESTIONS],
          },
        ]}
        badgeContent={suggestionsCount}
        disabled={disabled}
      />

      <ConfirmationDialog
        open={dialog}
        title="Confirm Selection"
        onClose={() => setDialog(false)}
        onCancel={() => setDialog(false)}
        onConfirm={onActionConfirm}
      >
        {`This action will ${action && actionTitles[action]}.`}
        <br />
        {`Are you sure you want to continue?`}
      </ConfirmationDialog>
    </>
  );
};

export default TrackChangesToolbar;
