import React from 'react';

import { GenericWithMembersWorkflowStatuses } from 'workflows/statuses';
import { YesOrNo } from 'components';

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => (
        <YesOrNo
          disableOnSubmit
          prompt={`Do you want to disable editing of ${props.itemCustomIdentifier}?`}
          closeOnNo
          yesLabel="Lock"
          noLabel="Cancel"
          {...props}
        />
      ),
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ confirmTransition }) => {
    return confirmTransition
      ? {
          toStatus: GenericWithMembersWorkflowStatuses.UnderReview.id,
        }
      : null;
  },
};
