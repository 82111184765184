import React, { useState } from 'react';

import { navigate } from '@reach/router';

import { IconButton, Paper, Tooltip, Typography } from '@material-ui/core';

import linkToItem from 'utils/linkToItem';

import ItemSearch from 'compositions/ItemSearch';

import { WorkflowIcon } from 'components';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InfoIcon from '@material-ui/icons/Info';
import LinkIcon from '@material-ui/icons/Link';

import Panel from 'components/core/Panel';
import { DeleteIcon, VersionCell, VersionToItemTable } from 'components/VersionTables';

import useStyles from './styles';
import useSharedStyles from '../styles';

function AssignedCourses(props) {
  const { hasManagerialPermissions, trainingPlanVersion } = props;

  const [blankCourseRow, setBlankCourseRow] = useState(false);
  const [courseSearchAnchorEl, setCourseSearchAnchorEl] = useState();

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const rowActions = trainingPlanVersion.locked
    ? undefined
    : [
        {
          icon: () => <DeleteIcon />,
          onClick: (e, data) => {
            e.preventDefault();
            props.onDelete(data);
          },
        },
      ];

  const widthClass = trainingPlanVersion.locked
    ? classes.noticeContainerWidthLocked
    : classes.noticeContainerWidthUnlocked;
  const noticeContainerClass = `${classes.noticeContainer} ${widthClass}`;

  return (
    <Panel>
      <div className={classes.headingContainer}>
        <div>
          <Panel.Heading icon={AssignmentIcon}>Assigned Courses</Panel.Heading>
        </div>
        <div className={noticeContainerClass}>
          <Typography variant="subtitle1" className={classes.noticeHeading}>
            <InfoIcon fontSize="small" />
            Reference Information
          </Typography>
          <Typography variant="subtitle2" className={classes.noticeMessage}>
            Title, Version, and Status displayed are for reference only and are subject to change as each item is
            updated.
          </Typography>
        </div>
      </div>
      <>
        {trainingPlanVersion.courses.length > 0 ? (
          <VersionToItemTable
            data={trainingPlanVersion.courses}
            actions={rowActions}
            columns={[
              {
                field: 'customIdentifier',
                title: 'ID',
              },
              {
                field: 'currentVersion.title',
                title: 'Title',
              },
              {
                title: 'Version',
                align: 'center',
                render: (course) => (
                  <VersionCell
                    version={course.currentVersion.versionIdentifier}
                    status={course.currentVersion.currentStatus.name}
                  />
                ),
              },
              {
                title: 'Status',
                render: (course) => (
                  <WorkflowIcon type={course.currentVersion.currentStatus.name} completed size="small" displayTooltip />
                ),
              },
            ]}
            onRowClick={(_e, course) => navigate(linkToItem('courses', course.id))}
          />
        ) : (
          <Typography variant="caption">No associated courses.</Typography>
        )}
      </>
      {blankCourseRow && (
        <div>
          <Paper variant="outlined" className={sharedClasses.associatedItemContainer}>
            <IconButton
              classes={{ root: sharedClasses.linkButton }}
              size="small"
              disabled={trainingPlanVersion.locked || !hasManagerialPermissions}
              onClick={(e) => setCourseSearchAnchorEl(e.target)}
            >
              <LinkIcon fontSize="small" />
            </IconButton>
            <ItemSearch
              anchorEl={courseSearchAnchorEl}
              closeSelf={() => setCourseSearchAnchorEl(null)}
              draftOnly={false}
              itemTypes={['course']}
              open={Boolean(courseSearchAnchorEl)}
              onSubmit={(courseIds) => {
                setBlankCourseRow(false);
                return props.onAdd(courseIds);
              }}
              supportsChangeOrder={false}
              usesCurrentVersion={false}
              versionLink={trainingPlanVersion}
            />
          </Paper>
        </div>
      )}
      {!blankCourseRow && !trainingPlanVersion.locked && (
        <div>
          <Tooltip
            title={
              hasManagerialPermissions ? '' : 'You do not have the proper role or permissions to perform this action'
            }
          >
            <span>
              <IconButton
                aria-label="add-course-button"
                disableRipple
                onClick={() => setBlankCourseRow(true)}
                disabled={!hasManagerialPermissions}
              >
                <AddCircleIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      )}
    </Panel>
  );
}

export default AssignedCourses;
