import React from 'react';
import clsx from 'clsx';
import { IconButton, Tooltip } from '@material-ui/core';

import { NavigationItemConfig } from 'compositions/Sidebar/NavigationConfig/navigationItems';

import { useStyles } from './styles';

interface NavigationItemProps extends NavigationItemConfig {
  onClick: (name: string) => void;
  selected: boolean;
}

const NavigationItem = (props: NavigationItemProps) => {
  const { Icon, title, onClick, selected, name } = props;
  const classes = useStyles();

  return (
    <Tooltip title={title} placement="right">
      <div className={clsx(classes.disciplinesItem, selected && classes.selected)}>
        <IconButton
          className={classes.disciplinesItemButton}
          aria-label={`${title} Discipline`}
          color="inherit"
          onClick={() => onClick(name)}
        >
          <Icon className={clsx(classes.disciplinesIcon, selected && classes.selectedIcon)} />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default NavigationItem;
