import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    trueStatusSwitcher: {
      backgroundColor: theme.palette.primary.main,
    },
    falseStatusSwitcher: {
      backgroundColor: '#8f8f8f',
    },
    nullStatusSwitcher: {
      backgroundColor: theme.palette.splitter.background,
    },
  }),
);
