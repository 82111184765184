export default (theme) => ({
  newItemButton: {
    borderRadius: '50%',
    textTransform: 'none',
    ...theme.buttonMixins.justIcon,
    padding: 0,
  },
  icon: {
    '&:hover': { color: theme.palette.blueDark },
  },
});
