import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';

import slugs from '../../displaySlugs';
import { docMetadata } from '../mixins/Document';
import TableColumns from './TableColumns';

const itemType = 'supplierAuditPlan';

export default {
  categoryLabel: t`Supplier Audit Plan`,
  displaySlug: slugs[itemType],
  label: t`Supplier Audit Plan`,
  listViewQuery: gql`
    query supplierAuditPlanListViewQuery {
      supplierAuditPlans {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          body
          versionIdentifier
          currentStatusName
          supplierVersion {
            id
            title
            item {
              id
              customIdentifier
            }
          }
        }
      }
    }
  `,
  itemType,
  icon: PlaylistAddCheck,
  ...docMetadata,
  listViewColumns: TableColumns,
};
