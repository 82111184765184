import React from 'react';
import Build from '@material-ui/icons/Build';
import { navigate } from '@reach/router';

import { Trans } from '@lingui/macro';
import { Modal, Paper, Typography, withStyles } from '@material-ui/core';

import { Spacer } from 'components';
import { Views } from 'workspaces';

const styles = (theme) => ({
  linkColor: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: theme.palette.primary.main,
  },
  modal: {
    width: 600,
    left: '20%',
    margin: '0 auto',
  },
  modalInside: {
    padding: theme.spacing(2),
  },
});

const defaultMessage = (
  <Trans>This item does not have a released version. View the item in its unreleased state in the Builder View.</Trans>
);

interface NoReleaseModalProps {
  classes;
  open: boolean;
  modalTopPosition?: string;
  viewName?: string;
  message?: string | JSX.Element;
  showLink?: boolean;
  navTo?: string;
  viewToNavTo?: string;
}

function NoReleaseModal({
  classes,
  open,
  modalTopPosition = '33%',
  viewName = 'Effective View',
  message = defaultMessage,
  showLink = true,
  navTo,
  viewToNavTo = 'Builder',
}: NoReleaseModalProps) {
  return (
    <Modal open={open} hideBackdrop className={classes.modal} style={{ top: modalTopPosition, zIndex: 0 }}>
      <Paper className={classes.modalInside}>
        <Typography variant="h2">
          {viewName} <Trans>Unavailable</Trans>
        </Typography>

        <Spacer factor={2} />

        <Typography variant="body1">{message}</Typography>

        <Spacer factor={2} />
        {showLink && (
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Typography
              className={classes.linkColor}
              onClick={() => navigate(navTo || document.location.pathname + '?view=' + Views.Builder.id)}
            >
              <Build fontSize={'inherit'} /> {viewToNavTo} View
            </Typography>
          </div>
        )}
      </Paper>
    </Modal>
  );
}

export default withStyles(styles)(NoReleaseModal);
