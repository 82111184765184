import React, { FC } from 'react';
import { Badge, MenuItem, PopoverOrigin, Select, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import { useStyles } from './style';

export interface Option {
  value: string;
  Icon: any;
  text: string;
  secondaryText?: string;
  disabled?: boolean;
}

interface Props {
  value: string;
  onChange: any;
  optionsTitle: string;
  options: Option[];
  actionsTitle?: string;
  actions?: Option[];
  classes?: any;
  badgeContent?: React.ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disabled?: boolean;
}

const OptionsDropdown: FC<Props> = (props) => {
  const {
    value: selected,
    onChange,
    optionsTitle,
    options,
    badgeContent,
    actionsTitle,
    actions,
    anchorOrigin,
    transformOrigin,
    disabled,
  } = props;
  const classes = useStyles();
  const renderItems = (options?: Option[], isGrouped?: boolean) =>
    options &&
    options.map(({ value, Icon, text, secondaryText, disabled }) => (
      <MenuItem
        key={value}
        value={value}
        data-testid="menuitemshsh"
        disabled={disabled}
        className={clsx({ [classes.menuItem]: true, [classes.menuItemHoverable]: isGrouped })}
      >
        <Badge badgeContent={badgeContent} color="primary" classes={{ badge: classes.badge }}>
          <Icon className={classes.menuIcon} />
        </Badge>
        <div className={classes.menuItemTextArea}>
          <Typography variant="body2" className={classes.menuItemText}>
            {text}
          </Typography>
          {secondaryText && (
            <Typography variant="overline" className={classes.menuItemSecondaryText}>
              {secondaryText}
            </Typography>
          )}
        </div>

        {value === selected && <Check className={clsx('checkmark-icon', classes.checkmark)} />}
      </MenuItem>
    ));

  return (
    <div className={classes.root}>
      <Select
        value={selected}
        onChange={onChange}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin,
          transformOrigin,
          PopoverClasses: { paper: classes.paper },
        }}
        disableUnderline
        className={classes.select}
        disabled={disabled}
      >
        <MenuItem value="" disabled className={classes.menuTitle}>
          <Typography variant="h6">{optionsTitle}</Typography>
        </MenuItem>

        {renderItems(options)}

        {actionsTitle && (
          <MenuItem value="" disabled className={classes.menuTitle} style={{ paddingTop: 16 }}>
            <Typography variant="h6">{actionsTitle}</Typography>
          </MenuItem>
        )}

        {renderItems(actions, true)}
      </Select>
    </div>
  );
};

OptionsDropdown.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

export default OptionsDropdown;
