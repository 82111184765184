import React from 'react';

import { Grid } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';
import WebAsset from '@material-ui/icons/WebAsset';

import SectionLabel from 'components/core/SectionLabel';
import Panel from 'components/core/Panel';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import { TaskQuery } from '../__generated__/TaskQuery';

interface Props {
  layoutClasses: any;
  data: TaskQuery;
}

function TaskOverview(props: Props) {
  const { data, layoutClasses } = props;

  const locked = data.taskVersion.locked;

  return (
    <Grid item xs={12} className={layoutClasses.grid}>
      <SectionLabel icon={WebAsset}>Task Overview</SectionLabel>
      <Panel>
        <Panel.Heading icon={Sort}>Description</Panel.Heading>
        <div>
          <RichTextEditorContentSection
            key={locked ? 0 : 1}
            locked={locked}
            initialValue={data.taskVersion.description}
            versionId={data.taskVersion.id}
            attrName={'description'}
            refetchQueries={['TaskQuery', 'timelineQuery']}
            optionSet="large"
          />
        </div>
      </Panel>
    </Grid>
  );
}

export default TaskOverview;
