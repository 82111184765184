import React, { useState } from 'react';

import { useMutation } from 'react-apollo';

import Input from 'components/core/form/Input';
import BaseDialog from 'components/BaseDialog';
import Button from 'components/Button';

import { CREATE_HAZARD } from './gql';

import formatApolloError from 'utils/formatApolloError';

function AddHazardDialog(props) {
  const [currentHazardName, setCurrentHazardName] = useState(null);
  const [error, setError] = useState(null);
  const [createHazard] = useMutation(CREATE_HAZARD);

  function handleCancel() {
    setCurrentHazardName(null);
    setError(null);

    props.onCancel();
  }

  function handleAdd() {
    createHazard({ variables: { name: currentHazardName } })
      .then((result) => {
        setError(null);
        setCurrentHazardName(null);
        props.onAdd(result.data.createHazard.hazard);
      })
      .catch((e) => {
        setError(formatApolloError(e));
      });
  }

  return (
    <BaseDialog
      actions={
        <>
          <Button fullWidth color="primary" onClick={handleCancel} variant="outlined">
            Cancel
          </Button>
          <Button
            fullWidth
            color="primary"
            disabled={!currentHazardName || !currentHazardName.length}
            onClick={handleAdd}
            variant="contained"
          >
            Submit
          </Button>
        </>
      }
      error={error}
      onClose={handleCancel}
      open={props.open}
      title="Create New Hazard"
    >
      <Input
        multiline
        placeholder="Hazard Name"
        name="hazard"
        onChange={(e) => {
          setCurrentHazardName(e.target.value);
        }}
        value={currentHazardName}
      />
    </BaseDialog>
  );
}

export default AddHazardDialog;
