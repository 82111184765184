import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { isUndefined } from 'lodash';

import { useStyles } from './styles';
import TriStateToggle, { TriStateToggleProps } from '../index';

type ColorizedTriStateToggleProps = Pick<TriStateToggleProps, 'disabled' | 'toggled'> & {
  onChange: (value: boolean | null) => void;
};

// @ts-ignore
const getValue = (val: boolean | undefined): boolean | null => (isUndefined(val) ? null : val);

const ColorizedTriStateToggle = (props: ColorizedTriStateToggleProps) => {
  const { toggled } = props;

  const classes = useStyles();
  const [value, setValue] = useState<boolean | null>(toggled);

  const handleChange = (value: boolean | undefined) => {
    props.onChange(getValue(value))
    setValue(getValue(value));
  }

  return (
    <div>
      <Typography display={'block'} variant="body2" className={classes.toggleText} style={{ marginTop: 4 }}>
        Did actual results from this Test fulfill all Acceptance Criteria?
        <span style={{ marginLeft: 8, marginTop: 4 }}>
          <span className={classes.negativeLabel}>Fail</span>
          <TriStateToggle
            disabled={props.disabled}
            toggled={value}
            onChange={handleChange}
          />
          <span className={classes.positiveLabel}>Pass</span>
        </span>
      </Typography>
    </div>
  );
};

export default ColorizedTriStateToggle;
