import gql from 'graphql-tag';
import { docStylePayloadFields } from '../../itemTypes/mixins/DocumentBased/gql';

const versionQuery = gql`
  query deviceValidationVersion($id: ID!) {
    deviceValidationVersion(id: $id) {
      ${docStylePayloadFields}
    }
  }
`;

const CREATE_VALIDATION_RECORD = gql`
  mutation createValidationRecord($versionId: ID!, $productId: ID!, $title: String!, $host: String) {
    createValidationRecord(versionId: $versionId, productId: $productId, title: $title, host: $host) {
      item {
        id
        customIdentifier
      }
    }
  }
`;

export { versionQuery, CREATE_VALIDATION_RECORD };
