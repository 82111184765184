import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      width: '100%',
      backgroundColor: 'white',
      boxShadow: '0 2px 5px 0 #eeeeee',
    },
    tabs: {
      display: 'flex',
    },
  }),
);
