import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    collapsed: {
      maxHeight: 122,
      overflow: 'clip',
    },
    expanded: {
      height: 'auto',
    },
    icon: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.5),
    },
    paper: {
      padding: theme.spacing(2),
    },
  }),
);
