import React, { ReactNode, ComponentType } from 'react';
import ActionTooltip from 'compositions/LinksGraph/ActionTooltip';
import { Popover, Paper, FormGroup, Typography, SvgIconProps } from '@material-ui/core';
import { useStyles } from './styles';
import { Spacer } from 'components';

interface Props {
  children: ReactNode;
  Icon: ComponentType<SvgIconProps>;
  cardDescription: string;
}

function ActionPopover(props: Props) {
  const classes = useStyles();
  const [anchor, setAnchor] = React.useState<null | Element>(null);
  return (
    <>
      <ActionTooltip text={props.cardDescription}>
        <props.Icon
          className={classes.icon}
          onClick={(e) => {
            setAnchor(e.currentTarget);
          }}
        />
      </ActionTooltip>
      <Popover
        open={anchor ? true : false}
        anchorEl={anchor}
        className={classes.tooltip}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => {
          setAnchor(null);
        }}
      >
        <Paper className={classes.filterContainer}>
          <FormGroup>
            <Typography variant="overline">{props.cardDescription}</Typography>
            <Spacer factor={2} />
            {props.children}
            <Spacer factor={1} />
          </FormGroup>
        </Paper>
      </Popover>
    </>
  );
}

export default ActionPopover;
