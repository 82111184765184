import gql from 'graphql-tag';

export const TRAINING_PLANS_FOR_COURSE_QUERY = gql`
    query TrainingPlansForCourse($id: ID!) {
        course(id: $id) {
            trainingPlanVersions {
                id
                item {
                    id
                    customIdentifier
                    itemType {
                        id
                        displaySlug
                    }
                }
                currentStatus {
                    id
                    name
                }
                title
                versionIdentifier
            }
        }
    }
`;
