import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import { Link } from '@reach/router';
import * as Yup from 'yup';
import posed from 'react-pose';
import { compose, setDisplayName } from 'recompose';
import { useMutation } from 'react-apollo';

import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Email from '@material-ui/icons/Email';

import LOGO from 'assets/img/nemedio_vert.svg';
import { createRecoverUsernameEmail } from './gql';
import { Logo, Spacer } from 'components';
import styles from './styles';

const ContainerWithEntry = posed.div({
  hidden: {
    y: -50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      duration: 300,
    },
  },
});

interface Props {
  classes: any;
  logo: string;
}

function ForgotUsernameForm(props: Props) {
  const { classes, logo = LOGO } = props;
  const [mounted, setMounted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted]);

  const onSuccess = () => {
    setSuccessMessage('Please check your email for username information');
    setIsButtonDisabled(true);
  };

  const [sendRecoverUsernameEmail, { loading }] = useMutation(createRecoverUsernameEmail, {
    onCompleted: onSuccess,
    onError: onSuccess,
  });

  const onSubmit = (values) => {
    sendRecoverUsernameEmail({
      variables: {
        input: {
          emailAddress: values.email,
        },
      },
    }).catch((err) => {
      throw new Error(`Recover username email did not send: ${err}`);
    });
  };

  const validateEmail = (inputText) => {
    //eslint-disable-next-line
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return mailFormat.test(inputText) ? true : false;
  };

  const RecoverUsernameSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required('Email is required'),
  });

  return (
    <ContainerWithEntry pose={mounted ? 'visible' : 'hidden'}>
      <Card className={classes.formCard}>
        <Logo src={logo} classes={classes} />
        <Grid container item xs={10}>
          <Tooltip title={'Back to login'} classes={{ tooltip: classes.tooltip }} placement="right">
            <Link to="/login" className={classes.backArrow}>
              <ArrowBack />
            </Link>
          </Tooltip>
        </Grid>

        <Formik initialValues={{ email: '' }} onSubmit={onSubmit} validationSchema={RecoverUsernameSchema}>
          {({ isValid, values }) => (
            <form className={classes.form}>
              <CardContent>
                <Typography variant="h2">Recover Your Username</Typography>
                <Spacer factor={2} />
                <Typography paragraph variant="body2">
                  You can recover your nemedio username using your email address associated with your account. If your
                  account is found in our system, an email will be sent with your username information.
                </Typography>
                <Typography paragraph variant="body2">
                  You may need to check your spam folder or unblock support@nemedio.com.
                </Typography>
                <Spacer factor={2} />
                <Field name="email" validate={validateEmail(values)}>
                  {({ field, form: { errors } }) => (
                    <FormControl fullWidth>
                      <Input
                        {...field}
                        className={classes.inputField}
                        endAdornment={
                          <InputAdornment position="end" className={classes.inputAdornment}>
                            <Email />
                          </InputAdornment>
                        }
                        fullWidth
                        id="email"
                        type="text"
                        placeholder="Email Address"
                      />
                      <FormHelperText className={classes.success}>
                        {loading ? 'Submitting...' : successMessage}
                      </FormHelperText>
                      <FormHelperText className={classes.error}>{errors.email}</FormHelperText>
                    </FormControl>
                  )}
                </Field>
              </CardContent>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      className={classes.forgotPasswordButton}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={!isValid || isButtonDisabled || loading}
                      onClick={() => onSubmit(values)}
                    >
                      Recover Username
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          )}
        </Formik>
      </Card>
    </ContainerWithEntry>
  );
}

export default compose(
  setDisplayName('ForgotUsernameForm'),
  withStyles(styles),
)(ForgotUsernameForm);
