import React, { FC, useMemo, useState } from 'react';
import flowRight from 'lodash/flowRight';

import { Box, Divider, Typography } from '@material-ui/core';
import { Settings } from '@material-ui/icons';

import BasicInformation from './Settings/BasicInformation';
import Section from 'components/SettingsSection';
import SettingsCollapse from 'components/SettingsCollapse';
import BaseDialog from 'components/BaseDialog';
import SupplierManagement from './Settings/SupplierManagement';
import AssesmentConfirmation from './Settings/Training/AssesmentConfirmation';
import RecordConfiguration from './Settings/Training/RecordConfiguration';
import RiskCodeConfiguration from './Settings/RiskManagement/RiskCodeConfiguration';
import RPCConfiguration from './Settings/RiskManagement/RPCConfiguration';

import { useCurrentCompany } from 'compositions/WithCurrentCompany';
import withJobRoles from 'compositions/WithJobRoles';
import { useStyles } from './styles';
import { CollapseItem } from './types';

interface Props {
  path: string;
  jobRoles: any;
}

const GeneralSettings: FC<Props> = () => {
  const classes = useStyles();
  const { data } = useCurrentCompany();
  const [collapseMenu, setCollapseMenu] = useState({
    generalSettings: true,
    trainingSettings: true,
    supplierSettings: true,
    riskSettings: true,
  });
  const [editingComponent, setEditingComponent] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const collapseItems = useMemo<CollapseItem[]>(
    () => [
      {
        key: 'generalSettings',
        title: 'General Settings',
        icon: Settings,
        sections: [
          {
            key: 'basic_information',
            content: BasicInformation,
          },
        ],
      },
      {
        key: 'trainingSettings',
        title: 'Training Settings',
        icon: Settings,
        sections: [
          {
            key: 'assesment_confirmation',
            content: AssesmentConfirmation,
          },
          {
            key: 'record_configuration',
            content: RecordConfiguration,
          },
        ],
      },
      {
        key: 'supplierSettings',
        title: 'Supplier Management Settings',
        icon: Settings,
        sections: [
          {
            key: 'supplier_management',
            content: SupplierManagement,
          },
        ],
      },
      {
        key: 'riskSettings',
        title: 'Risk Management Settings',
        icon: Settings,
        withDivider: true,
        sections: [
          {
            key: 'risk_code_configuration',
            content: RiskCodeConfiguration,
          },
          {
            key: 'risk_priority_code_configuration',
            content: RPCConfiguration,
          },
        ],
      },
    ],
    [],
  );

  const handleCollapseMenu = (key: string) => {
    setCollapseMenu({ ...collapseMenu, [key]: !collapseMenu[key] });
  };

  const handleEdit = (name: string | null) => {
    if (editingComponent && name && editingComponent !== name) {
      const isEditingComponentTimezoneAndNameLogo = editingComponent.includes('timezone') && name === 'company_logo';
      const isEditingComponentLogoAndNameTimezone = editingComponent === 'company_logo' && name.includes('timezone');
      if (isEditingComponentTimezoneAndNameLogo || isEditingComponentLogoAndNameTimezone) {
        setEditingComponent(name);
      } else {
        setModalOpen(true);
      }
    } else {
      setEditingComponent(name);
    }
  };

  if (!data) return null;

  return (
    <div>
      <Typography variant="h1" className={classes.pageTitle}>
        Settings & Configuration
      </Typography>

      <BaseDialog onClose={() => setModalOpen(false)} open={modalOpen} textAlign="left" title={'Warning'}>
        <Typography variant="body1">
          You are currently editing a section of this page. Please save or cancel these changes before editing other
          application settings.
        </Typography>
      </BaseDialog>

      {collapseItems.map(({ key: collapseKey, title, icon: Icon, sections, withDivider }) => (
        <div key={collapseKey} className={classes.collapseItem}>
          <SettingsCollapse
            open={collapseMenu[collapseKey]}
            icon={Icon}
            title={title}
            onCollapseButtonClick={() => handleCollapseMenu(collapseKey)}
          >
            {withDivider ? (
              <Section>
                {sections.map(({ key, content: Content }, index) => (
                  <>
                    <Content key={key} name={key} editing={editingComponent === key} onEdit={handleEdit} />
                    {index !== sections.length - 1 && (
                      <Box my={4}>
                        <Divider />
                      </Box>
                    )}
                  </>
                ))}
              </Section>
            ) : (
              sections.map(({ key, content: Content }) => (
                <div key={key} className={classes.section}>
                  <Section>
                    <Content
                      name={key}
                      collapsed={collapseMenu[collapseKey]}
                      editingComponent={editingComponent}
                      editing={editingComponent === key}
                      onEdit={handleEdit}
                    />
                  </Section>
                </div>
              ))
            )}
          </SettingsCollapse>
        </div>
      ))}
    </div>
  );
};

export default flowRight([withJobRoles])(GeneralSettings);
