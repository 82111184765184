import React from 'react';

import { Spacer } from 'components';

import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FlagIcon from '@material-ui/icons/Flag';

import { VerificationDeviationsTable } from './VerificationDeviationsTable';
import { ValidationDeviationsTable } from './ValidationDeviationsTable';
import { useStyles } from './styles';
// import { VerificationAndValidationReportVersionQuery_verificationAndValidationReportVersion } from '../__generated__/VerificationAndValidationReportVersionQuery';
interface DeviationsTabProps {
  currentVersion;
}

const DeviationsTab = (props: DeviationsTabProps) => {
  const classes = useStyles();
  const { currentVersion } = props;
  const { associatedVvPlan } = currentVersion;

  const deviationsFromValidationRecords = currentVersion.validationRecords.length
    ? currentVersion.validationRecords.map((record) => record.deviationVersions).flat(2)
    : [];
  const deviationsFromVerificationRecords = currentVersion.verificationRecords.length
    ? currentVersion.verificationRecords.map((record) => record.deviationVersions).flat(2)
    : [];
  const deviationsCount = deviationsFromValidationRecords.length + deviationsFromVerificationRecords.length;

  return (
    <>
      <Typography variant="h3">
        <AccountCircleIcon />
        <span className={classes.addSpaceOnLeft}>Summary of Deviations</span>
      </Typography>
      <Spacer factor={0.5} />
      <Typography variant={'body2'} hidden={associatedVvPlan}>
        <FlagIcon className={classes.resizeIcon} />
        <span className={classes.addSpaceOnLeft}>
          No V&V Plan selected. Please select a V&V Plan to view results on this page.
        </span>
      </Typography>
      <Spacer factor={3} />
      <Typography variant={'body2'} hidden={!associatedVvPlan}>
        <span>These tests were executed with a total of {deviationsCount} deviations.</span>
      </Typography>
      <Spacer factor={3} />
      <div hidden={!associatedVvPlan}>
        <ValidationDeviationsTable validationDeviations={deviationsFromValidationRecords} />
      </div>
      <Spacer factor={3} />
      <div hidden={!associatedVvPlan}>
        <VerificationDeviationsTable verificationDeviations={deviationsFromVerificationRecords} />
      </div>
    </>
  );
};

export { DeviationsTab };
