import React from 'react';
import { VoidReason } from '../views';
import { CourseWorkflowStatuses } from 'workflows/statuses';
import { WorkflowTransition } from '../types';

export default {
  initialStep: 'voidOwnerApproval',
  internalSteps: {
    voidOwnerApproval: {
      component: (props) => {
        return <VoidReason relevantRole={props.currentVersion.assignedApproverJobRole.formattedRoleName} {...props} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: (props) => ({
    toStatus: CourseWorkflowStatuses.Void.id,
    ...props.voidOwnerApproval.signature,
    reasonForTransition: props.voidOwnerApproval.reason,
  }),
} as WorkflowTransition;
