import clamp from 'lodash/clamp';

interface Selection {
  object;
  trace;
  root;
}

class CurrentPosition {
  static MAX_DISTANCE = 5;

  trueUpstream: number;
  numUpstream: number;

  trueDownstream: number;
  numDownstream: number;

  rootIsOnScreen: boolean;

  selection: Partial<Selection>;

  constructor(offset: number, selection: Partial<Selection>) {
    this.trueUpstream = 2 + offset;
    this.numUpstream = clamp(this.trueUpstream, 0, CurrentPosition.MAX_DISTANCE);

    this.trueDownstream = 2 - offset;
    this.numDownstream = clamp(this.trueDownstream, 0, CurrentPosition.MAX_DISTANCE);

    this.rootIsOnScreen =
      this.numUpstream < CurrentPosition.MAX_DISTANCE && this.numDownstream < CurrentPosition.MAX_DISTANCE;

    this.selection = selection;
  }
}

export default CurrentPosition;
