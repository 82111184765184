import React from 'react';

import { get } from 'lodash';

import RiskLevelSelect from 'components/RiskLevelSelect';

import { useUpdateRiskVersion, targetValue } from './hooks';
import { useOptionsContext } from './OptionsContext';

interface Props {
  riskVersion: any;
  mitigated: boolean;
  which: '' | '1' | '2';
  locked: boolean;
}

export default function RiskProbability({ riskVersion, mitigated, which, locked }: Props) {
  const updateField = useUpdateRiskVersion(riskVersion.id);
  const { probabilityLevels } = useOptionsContext();

  const prefix = mitigated ? '' : 'un';

  const attrName = `${prefix}mitigatedProbability${which}`;
  const updateAttrName = `${attrName}Id`;
  const content = get(riskVersion, `${attrName}.id`, null);
  const options = probabilityLevels;
  const placeholder = `P${which}`;

  return (
    <RiskLevelSelect
      key={content}
      placeholder={placeholder}
      defaultValue={content}
      options={options}
      onChange={targetValue(updateField(updateAttrName))}
      includeLabelWhenSelected={false}
      disabled={locked}
    />
  );
}
