import { Component } from 'react';
import { compose } from 'recompose';
import { get } from 'lodash';

import WithCurrentUser from 'compositions/WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';

class Intercom extends Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV === 'production') {
      const { currentUser, currentCompany } = this.props;

      const APP_ID = 'kldw5dxl';
      const currentUserFirstName = get(currentUser, 'firstName');
      const currentUserLastName = get(currentUser, 'lastName');
      const currentUserEmail = get(currentUser.profile, 'emailAddress');
      const currentUserId = get(currentUser, 'id');
      const currentUserAvatar = get(currentUser.profile, 'photoUrl');
      const validatedSystem = get(currentCompany, 'validatedSystem');

      const isTrainingManager = get(currentUser, 'jobRoles').some((jobRole) => jobRole.roleName === 'training_manager');
      const isSupplierManager = get(currentUser, 'jobRoles').some((jobRole) => jobRole.roleName === 'supplier_manager');
      const isRiskManager = get(currentUser, 'jobRoles').some((jobRole) => jobRole.roleName === 'risk_management_lead');
      const isSystemAdmin = get(currentUser, 'jobRoles').some((jobRole) => jobRole.roleName === 'admin');
      const isQualityAffairsLead = get(currentUser, 'jobRoles').some(
        (jobRole) => jobRole.roleName === 'quality_affairs_lead',
      );

      if (!window.Intercom) {
        (function(w, d, id, s, x) {
          function i() {
            i.c(arguments);
          }
          i.q = [];
          i.c = function(args) {
            i.q.push(args);
          };
          w.Intercom = i;
          s = d.createElement('script');
          s.async = 1;
          s.src = 'https://widget.intercom.io/widget/' + id;
          d.head.appendChild(s);
        })(window, document, APP_ID);
      }

      window.Intercom('boot', {
        app_id: APP_ID,
        name: currentUserFirstName + ' ' + currentUserLastName,
        first_name: currentUserFirstName,
        last_name: currentUserLastName,
        email: currentUserEmail,
        prefix: currentCompany.subdomain,
        user_id: `${currentCompany.subdomain}.${currentUserId}`,
        company: currentCompany,
        avatar: {
          type: 'avatar',
          image_url: currentUserAvatar,
        },
        validated_system: validatedSystem,
        training_manager: isTrainingManager,
        supplier_manager: isSupplierManager,
        risk_manager: isRiskManager,
        system_administrator: isSystemAdmin,
        qa_lead: isQualityAffairsLead,
      });
    }
  }

  render() {
    return false;
  }
}

export default compose(
  WithCurrentUser,
  withCurrentCompany,
)(Intercom);
