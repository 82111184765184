import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Version } from './version-icon.svg';

const VersionIcon = (props) => {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <Version {...svgProps} />} />
};

export default VersionIcon;
