import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';

import { FormControl, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import Check from '@material-ui/icons/Check';
import AddCircle from '@material-ui/icons/AddCircle';

import { Spacer } from 'components';
import BaseDialog from 'components/BaseDialog';

import { ReasonForChangeAndEsig, UserAvatar } from 'components';
import WithAllUsers from 'compositions/WithAllUsers';
import { displayUserRoles } from 'components/SelectUserField/util';
import { UPDATE_USER_JOB_ROLE } from './gql';

interface Props {
  open: boolean;
  onClose: () => void;
  jobRole: any;
  usersWithRole: { id: string; fullName: string }[];
  usersUpdated: (users: any) => void;
  allUsers: any;
}

function AddUsersToRole(props: Props) {
  const [usersToAdd, setUsersToAdd] = useState(null);
  const [reasonForRoleChange, setReasonForRoleChange] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onUpdateCompleted = () => {
    props.onClose();
    props.usersUpdated(usersToAdd);
    setReasonForRoleChange('');
    setUsername('');
    setPassword('');
  };

  const [updateUserJobRole, { loading, error }] = useMutation(UPDATE_USER_JOB_ROLE, {
    refetchQueries: ['AllJobRoles'],
    onCompleted: onUpdateCompleted,
  });
  if (error) throw new Error(`Error updating user job roles: ${error}`);

  const userOptions = props.allUsers.filter(
    (user) => user.status === 'active' && props.usersWithRole.every((userWithRole) => userWithRole.id !== user.id),
  );

  const renderOption = (option: any, { selected }) => {
    return (
      <>
        <UserAvatar userId={option.id} diameter={24} />
        <div style={{ width: '100%', marginLeft: 8 }}>
          <Typography variant="body2" style={{ fontWeight: 600 }}>
            {option.fullName}
            {selected ? <Check style={{ float: 'right' }} color="primary" /> : null}
          </Typography>
          <Typography variant="caption">{displayUserRoles(option.jobRoles)}</Typography>
        </div>
      </>
    );
  };

  const onSelectChange = (_, values) => {
    setUsersToAdd(values);
  };

  const formCanSubmit = () => {
    return usersToAdd && reasonForRoleChange.length && username.length && password.length;
  };

  const handleSubmit = () => {
    updateUserJobRole({
      variables: {
        userIds: (usersToAdd || []).map((user) => get(user, 'id')),
        jobRoleId: props.jobRole.id,
        reasonForRoleChange: reasonForRoleChange,
        username: username,
        password: password,
      },
    }).catch((err) => {
      throw new Error(`Error while updating users' job roles: ${err}`);
    });
  };

  return (
    <BaseDialog open={props.open} onClose={props.onClose} title={`Add Users to Role`} Icon={AddCircle} size="xlarge">
      <form>
        <FormControl required fullWidth>
          <Spacer factor={2} />
          <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left' }}>
            {`The following users will be assigned the Role of ${get(props.jobRole, 'formattedRoleName')}:`}
          </Typography>
          <Spacer factor={2} />
          <Autocomplete
            multiple
            openOnFocus
            options={userOptions}
            getOptionLabel={(option: any) => option.fullName}
            disableCloseOnSelect
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Users to Add" />}
            renderOption={renderOption}
            onChange={onSelectChange}
          />
          <Spacer factor={4} />
          <ReasonForChangeAndEsig
            textboxHeading={`Reason for Role Change`}
            textboxId={`reason-for-role-change-input`}
            onTextChange={(e) => setReasonForRoleChange(e.target.value)}
            usernameInputId={`add-user-to-role-username-input`}
            onUsernameChange={(e) => setUsername(e.target.value)}
            passwordInputId={`add-user-to-role-password-input`}
            onPasswordChange={(e) => setPassword(e.target.value)}
            submitDisabled={!formCanSubmit() || loading}
            onSubmitClick={handleSubmit}
            submitLoading={loading}
          />
        </FormControl>
      </form>
    </BaseDialog>
  );
}

export default flowRight([WithAllUsers])(AddUsersToRole);
