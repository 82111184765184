import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(1),
      display: 'inline-block',
    },
    box: {
      padding: theme.spacing(2),
    },
    paper: {
      borderWidth: '2px',
      height: '100%',
    },
  }),
);
