import React from 'react';

import { Grid } from '@material-ui/core';
import Business from '@material-ui/icons/Business';

import { useMutation } from 'react-apollo';

import { withLockedContext } from 'contexts/lockedContext';
import { SingleItemSelect } from 'components';
import SectionLabel from 'components/core/SectionLabel';
import Panel from 'components/core/Panel';

import { SET_SUPPLIER_ID, SUPPLIER_OPTIONS_QUERY } from '../gql';

interface Props {
  currentVersion: any;
  supplierPassedAudit: boolean | null;
}

function AssociatedSupplierCard(props: Props) {
  const [setSupplierId] = useMutation(SET_SUPPLIER_ID, {
    refetchQueries: ['SupplierAuditPlanVersionQuery'],
  });

  const updateSupplierAuditPlan = (supplierItem) => {
    setSupplierId({
      variables: {
        id: props.currentVersion.id,
        supplierId: supplierItem ? supplierItem.currentVersion.id : null,
      },
    }).catch((err) => {
      throw new Error(`Error refetching Associated supplier audit plan version: ${err}`);
    });
  };

  return (
    <Grid item xs={5} alignContent="flex-start" style={{ alignSelf: 'start' }}>
      <Panel>
        <SectionLabel icon={Business}>Associated Supplier</SectionLabel>
        <SingleItemSelect
          showOptionVersionStatus={true}
          value={props.currentVersion.supplierVersion}
          disabled={false}
          onChange={updateSupplierAuditPlan}
          placeholder="Select Associated Supplier"
          options={{
            query: SUPPLIER_OPTIONS_QUERY,
            optionsPath: 'suppliers',
            filter: (options: any[]) =>
              options.filter(
                (option: any) =>
                  option.currentVersion.plannedQualificationAudit &&
                  option.currentVersion.currentStatusName !== 'qualification_revoked',
              ),
          }}
        />
      </Panel>
    </Grid>
  );
}

export default withLockedContext(AssociatedSupplierCard);
