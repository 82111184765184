import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    switchViewPanelText: {
      ...theme.typography.subtitle1,
      width: '100%',
      marginRight: '16px',
    },
    divider: {
      marginBottom: theme.spacing(3),
    },
    inlineToggle: {
      display: 'inline',

      '& > input': {
        margin: `0 ${theme.spacing(1)}px`,
      },
      '& > span': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      '& > p': {
        fontSize: '9px',
        display: 'inline-block',
        marginLeft: '5px',
        lineHeight: '15px',
        verticalAlign: 'text-top',
      },
    },
    box: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  }),
);

export const useRiskControlsAutoCompleteStyles = makeStyles((theme) => ({
  tag: {
    ...theme.typography.body2,
    // @ts-ignore
    background: theme.palette.common.tags.background,
  },
  noOptions: {
    ...theme.typography.body2,
    padding: '0',
    fontWeight: 'bold',
  },
}));
