import React, { FC } from 'react';
import { WorkflowIcon } from 'components';
import { Box, Typography, createStyles, makeStyles } from '@material-ui/core';

interface Props {
  beforeVerb?: string;
  afterVerb?: string;
  status: string;
  titleizedName: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      display: 'inline-block',
      fontSize: 11,
      color: theme.palette.secondary.main,
    },
    statusIconTiny: {
      width: 15,
      height: 15,
    },
  }),
);

const VerbAndStatus: FC<Props> = ({ beforeVerb, afterVerb, status, titleizedName }) => {
  const classes = useStyles();

  return (
    <>
      {beforeVerb && (
        <Box mx={0.5} display="inline-block">
          <Typography variant="body2" className={classes.text}>
            {beforeVerb}
          </Typography>
        </Box>
      )}
      <Box display="inline-block">
        <WorkflowIcon type={status} completed size="tiny" />
      </Box>
      <Box mr={0.5} display="inline-block" />
      <Typography variant="body2" className={classes.text} style={{ textTransform: 'uppercase' }}>
        {titleizedName}
      </Typography>
      {afterVerb && (
        <Box mx={0.5} display="inline-block">
          <Typography variant="body2" className={classes.text}>
            {afterVerb}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default VerbAndStatus;
