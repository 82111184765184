import React from 'react';
import { capitalize } from 'lodash';

import { AssociatedSetModal, AssociatedRiskAnalysis } from '../views';

export default {
  initialStep: 'AssociatedSet',
  internalSteps: {
    AssociatedSet: {
      component: (props) => {
        if (!props.currentVersion.setMember) return null;
        if (
          props.currentVersion.__typename === 'RiskVersion' ||
          props.currentVersion.__typename === 'RiskControlVersion'
        ) {
          return (
            <AssociatedRiskAnalysis
              {...props}
              riskAnalysisTitle={capitalize(props.currentVersion.setMember.setItemVersion.title)}
              riskAnalysisId={props.currentVersion.setMember.setItemVersion.item.id}
              riskAnalysisKey={props.currentVersion.setMember.setItemVersion.item.customIdentifier}
              riskAnalysisVersion={props.currentVersion.setMember.setItemVersion.versionIdentifier}
            />
          );
        }
        return (
          <AssociatedSetModal
            parentSetName={props.itemTypeMetadata.parentSetItemType.name}
            parentSetVersion={props.currentVersion.setMember.setItemVersion.versionIdentifier}
            parentSetStatus={capitalize(props.currentVersion.setMember.setItemVersion.currentStatusName)}
            parentSetLocked={props.currentVersion.locked}
            parentSetDisplaySlug={props.itemTypeMetadata.parentSetItemType.displaySlug}
            requirementsSetMemberId={props.currentVersion.setMember.id}
            {...props}
          />
        );
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: () => null,
};
