import React from 'react';

import { Grid, Typography, withStyles } from '@material-ui/core';

import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import { useQuery } from 'react-apollo';

import { FactCheck } from 'assets/icons';
import Breadcrumbs from 'components/Breadcrumbs';
import NoReleaseModal from 'components/NoReleaseModal';
import Spacer from 'components/Spacer';
import { Blur } from 'components';

import ItemsTable from './ItemsTable';
import styles from './styles';
import { CustomColumn } from '../core/Table/types';

interface RecordViewProps {
  categorySlug;
  classes;
  itemId;
  itemType: string;
  RecordViewOptions: {
    query: any;
    recordsProperty: string;
    recordViewColumns: CustomColumn<any>[];
  };
}

function RecordsView(props: RecordViewProps) {
  const { classes, categorySlug, itemId, itemType, RecordViewOptions } = props;
  const { data, loading, error } = useQuery(RecordViewOptions.query, {
    fetchPolicy: 'network-only',
    variables: {
      itemId,
      itemType: snakeCase(itemType),
    },
  });

  if (loading) return null;
  if (error) throw new Error(`Record View query failed: ${error.message}`);

  const itemRecord = data['item'];
  const versions: any[] = get(data, 'item.versions', []);
  const { currentRelease } = itemRecord;

  const itemTitle = get(currentRelease, 'title', '');

  const isRecordsExists = (itemVersions) => {
    let res = false;
    for (let i = 0; i < itemVersions.length; i++) {
      const recordsByProperty = itemVersions[i][RecordViewOptions.recordsProperty];
      if (recordsByProperty && recordsByProperty.length > 0) {
        res = true;
        break;
      }
    }
    return res;
  };

  return (
    <Grid container data-testid="item" spacing={3} className={classes.container}>
      <Grid item xs={12} className={classes.grid}>
        <Breadcrumbs categorySlug={categorySlug} itemTitle={`${itemRecord.customIdentifier}: ${itemTitle}`} />
        <Grid container item xs={12} justify="space-between">
          <div className={classes.titleContainer}>
            <FactCheck />
            <Typography variant="h2" classes={{ root: classes.typography }}>
              Records View
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Blur blur={!isRecordsExists(versions)} style={{ width: '100%' }}>
        <Grid item xs={12} className={classes.grid}>
          {versions.length &&
            versions.map((version) => {
              return (
                <React.Fragment key={version.id}>
                  <ItemsTable
                    versionIdentifier={version.versionIdentifier}
                    items={get(version, props.RecordViewOptions.recordsProperty)}
                    columns={props.RecordViewOptions.recordViewColumns}
                  />
                  <Spacer factor={5} />
                </React.Fragment>
              );
            })}
        </Grid>
      </Blur>
      <NoReleaseModal
        message={'This item does not have generated Records. View the item in the Builder View'}
        open={!isRecordsExists(versions)}
        viewName="Records View"
      />
    </Grid>
  );
}

export default withStyles(styles)(RecordsView);
