import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { buildConditionalClasses } from 'utils';

const styles = () => ({
  blur: {
    filter: 'blur(4px)',
    opacity: 0.7,
  },
});

function Blur({ blur, children, classes, container, ...rest }) {
  if (blur) {
    return (
      <Grid
        id="blur"
        container={container}
        spacing={container ? 3 : undefined}
        className={buildConditionalClasses([[blur, classes.blur]])}
        {...rest}
      >
        {children}
      </Grid>
    );
  }

  return <>{children}</>;
}

Blur.defaultProps = {
  container: true,
};

export default withStyles(styles)(Blur);
