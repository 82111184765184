import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      textTransform: 'initial',
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    select: {
      '& .MuiSelect-root': {
        display: 'flex',
        alignItems: 'center',
      },

      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.main,
      },

      '& .MuiTypography-root': {
        color: theme.palette.secondary.main,
      },

      '& .MuiTypography-overline': {
        display: 'none',
      },

      '& .checkmark-icon': {
        display: 'none',
      },
    },
    paper: {
      border: '1px solid #F2F2F2',
      boxShadow: '0px 2px 20px 1px rgba(182, 182, 182, 0.25)',
    },
    menuTitle: {
      padding: theme.spacing(1, 2),
      color: theme.palette.blueDark,

      '&.Mui-disabled': {
        opacity: 1,
      },
    },
    menuItem: {
      padding: theme.spacing(1, 2),

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '&.Mui-selected': {
        backgroundColor: 'transparent',

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },

      '& .MuiBadge-root': {
        alignSelf: 'baseline',
      },

      '& .MuiBadge-badge': {
        display: 'none',
      },
    },
    menuItemHoverable: {
      '&:hover': {
        backgroundColor: 'rgba(51, 188, 180, 0.2)',
      },
    },
    menuIcon: {
      fontSize: 20,
      color: theme.palette.subHeading,
    },
    menuItemTextArea: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(1),
      marginRight: 'auto',
    },
    menuItemText: {
      display: 'inline',
    },
    menuItemSecondaryText: {
      textTransform: 'initial',
      color: theme.palette.subHeading,
    },
    checkmark: {
      fontSize: 20,
      color: '#329D96',
    },
    badge: {
      fontSize: '7px',
      fontWeight: theme.typography.fontWeightBold,
      height: 11,
      width: 10,
      minWidth: 'auto',
    },
  }),
);
