import React from 'react';

import BugReport from '@material-ui/icons/BugReport';

import { t } from '@lingui/macro';

import { RegularTextCell } from 'components/TableView/Fields/RegularText';
import VersionAndStatusDot from 'components/VersionAndStatusDot';
import slugs from 'displaySlugs';
import Views from 'workspaces/views';

import { ItemTypeDefinition } from '../index';
import { LIST_DEVIATIONS } from './gql';

const itemType = 'deviation';

const listViewColumns = [
  {
    headerName: 'ID',
    field: 'customIdentifier',
    type: 'id',
  },
  {
    headerName: 'Title',
    field: 'currentVersion.title',
    type: 'regular_text',
  },
  {
    headerName: 'Version',
    field: 'currentVersion',
    type: 'version',
  },
  {
    headerName: 'Status',
    type: 'status',
    field: 'currentVersion.currentStatus.name',
  },
  {
    headerName: 'Origination Record',
    type: 'custom',
    field: 'currentVersion.parentRecord',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        customIdentifier: row.value.currentVersion.parentRecord.item.customIdentifier,
        versionIdentifier: row.value.currentVersion.parentRecord.versionIdentifier,
        currentStatusName: row.value.currentVersion.parentRecord.currentStatus.name,
      });
    },
    render: (row) => {
      let customIdentifier;
      let versionIdentifier;
      let currentStatusName;

      const isColumnGroupedBy = Boolean(
        row.columnApi.columnModel.rowGroupColumns.find((column) => column.colId === 'currentVersion.parentRecord'),
      );
      if (isColumnGroupedBy) {
        customIdentifier = JSON.parse(row.value).customIdentifier;
        versionIdentifier = JSON.parse(row.value).versionIdentifier;
        currentStatusName = JSON.parse(row.value).currentStatusName;
      } else {
        customIdentifier = row.value.currentVersion.parentRecord.item.customIdentifier;
        versionIdentifier = row.value.currentVersion.parentRecord.versionIdentifier;
        currentStatusName = row.value.currentVersion.parentRecord.currentStatus.name;
      }

      return (
        <div style={{ display: 'inline-flex' }}>
          <span>
            <RegularTextCell data={customIdentifier} />
            <VersionAndStatusDot style={{ fontSize: 13 }} version={versionIdentifier} status={currentStatusName} />
          </span>
        </div>
      );
    },
  },
  {
    headerName: 'Deviation ID',
    field: 'currentVersion.deviationId',
    type: 'regular_text',
  },
  {
    headerName: "Origination Record's Associated Protocol",
    type: 'custom',
    field: 'currentVersion.parentRecordProtocol',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        parentRecordProtocolIdentifier:
          row.value.currentVersion.parentRecord.item.itemType.name === 'validation_record'
            ? row.value.currentVersion.parentRecordValidationProtocol.item.customIdentifier
            : row.value.currentVersion.parentRecordVerificationProtocol.item.customIdentifier,
        parentRecordVersionIdentifier: row.value.currentVersion.parentRecord.versionIdentifier,
        parentRecordCurrentStatusName: row.value.currentVersion.currentStatus.name,
      });
    },
    render: (row) => {
      let parentRecordProtocolIdentifier;
      let parentRecordVersionIdentifier;
      let parentRecordCurrentStatusName;

      const isColumnGroupedBy = Boolean(
        row.columnApi.columnModel.rowGroupColumns.find(
          (column) => column.colId === 'currentVersion.parentRecordProtocol',
        ),
      );
      if (isColumnGroupedBy) {
        parentRecordProtocolIdentifier = JSON.parse(row.value).parentRecordProtocolIdentifier;
        parentRecordVersionIdentifier = JSON.parse(row.value).parentRecordVersionIdentifier;
        parentRecordCurrentStatusName = JSON.parse(row.value).parentRecordCurrentStatusName;
      } else {
        parentRecordProtocolIdentifier =
          row.value.currentVersion.parentRecord.item.itemType.name === 'validation_record'
            ? row.value.currentVersion.parentRecordValidationProtocol.item.customIdentifier
            : row.value.currentVersion.parentRecordVerificationProtocol.item.customIdentifier;
        parentRecordVersionIdentifier = row.value.currentVersion.parentRecord.versionIdentifier;
        parentRecordCurrentStatusName = row.value.currentVersion.parentRecord.currentStatus.name;
      }

      return (
        <div style={{ display: 'inline-flex' }}>
          <span>
            {parentRecordProtocolIdentifier}
            <VersionAndStatusDot
              style={{ fontSize: 13 }}
              version={parentRecordVersionIdentifier}
              status={parentRecordCurrentStatusName}
            />
          </span>
        </div>
      );
    },
  },
  {
    headerName: 'Initiation Date',
    type: 'date',
    field: 'currentVersion.createdAt',
  },
  {
    headerName: 'Owner',
    type: 'user',
    field: 'currentVersion.owner',
  },
  {
    headerName: 'Approver',
    type: 'user',
    field: 'currentVersion.approver',
  },
];

const versionFields = `
  body
  descriptionOfChange
  pdfDataUpload
  virtualAttributes(attrType: "attachmentAttr") {
    attachments {
      filename
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Deviation`,
  categoryLabel: t`Deviations`,
  icon: BugReport,
  listViewQuery: LIST_DEVIATIONS,
  versionFields,
  listViewColumns,
  // ListPage,
  listWorkspace: [Views.Table, Views.LinksGraph],
  recordWorkspace: [Views.Builder, Views.Effective, Views.Versions],
} as ItemTypeDefinition;
