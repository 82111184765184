import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { useMutation } from 'react-apollo';

import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import UploadFileIcon from 'assets/icons/FileUpload';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Sort from '@material-ui/icons/Sort';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';

import { ContentSection, PDFViewer, Spacer, TextAreaField, InfoList, DirectDocumentUploader } from 'components';
import fileNameFromURL from 'utils/fileNameFromURL';

import { DESTROY_REFERENCED_DOCUMENT, CREATE_REFERENCED_DOCUMENT } from '../FormRecord/gql';

const PayloadFields = ({ layoutClasses, versionData }) => {
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [createAttachment] = useMutation(CREATE_REFERENCED_DOCUMENT, {
    refetchQueries: ['inProcessInspectionRecordQuery'],
  });
  const [destroyAttachment] = useMutation(DESTROY_REFERENCED_DOCUMENT, {
    refetchQueries: ['inProcessInspectionRecordQuery'],
  });
  const createExecutedAttachment = async (attachmentUrl: string) => {
    const payload = {
      variables: {
        attachmentURL: attachmentUrl,
        versionId: versionData.id,
        isExecutedAttachment: true,
      },
    };
    try {
      await createAttachment(payload);
    } catch (e) {
      throw new Error(`Error creating attachment (versionId: ${payload.variables.versionId}): ${e.message}`);
    }
  };
  const deleteAttachment = async (attachmentId) => {
    setLoadingAttachment(true);
    try {
      await destroyAttachment({ variables: { id: attachmentId } });
    } catch (e) {
      throw new Error(`Failed to delete attachment: ${e.message}`);
    } finally {
      setLoadingAttachment(false);
    }
  };

  const { formCustomIdentifier, formTitle, formVersion, formReleasedAt } = versionData;
  const signedFormAttachment = get(versionData, 'executedRecordAttachment');
  const attachmentId = get(signedFormAttachment, 'id');
  const url = get(signedFormAttachment, 'url');
  const filename = url && fileNameFromURL(url);
  const creator = get(signedFormAttachment, 'creator');
  const createdAt = get(signedFormAttachment, 'createdAt');
  const unexecutedRecordAttachment = get(versionData, 'unexecutedRecordAttachment');

  return (
    <>
      <Grid container item xs={8} className={layoutClasses.grid}>
        <Grid item xs={5}>
          <InfoList
            headerText={'Associated Form'}
            Icon={PlaylistAddCheck}
            data={[
              { title: formCustomIdentifier, value: formTitle },
              { title: 'Effective Date', value: DateTime.fromISO(formReleasedAt).toLocaleString(DateTime.DATE_FULL) },
              { title: 'Version', value: formVersion },
            ]}
          />
        </Grid>
        <Grid item xs={7} className={layoutClasses.gridGutterLeft}>
          <ContentSection
            LabelText={
              <>
                <DescriptionIcon className={layoutClasses.icon} />
                <Trans>Unexecuted Record Attachment</Trans>
              </>
            }
            fillHeight={false}
          >
            <Spacer factor={1} />
            <div>
              <DirectDocumentUploader
                attrName={'original_form'}
                attachmentId={unexecutedRecordAttachment && unexecutedRecordAttachment.id}
                filename={unexecutedRecordAttachment && fileNameFromURL(unexecutedRecordAttachment.url)}
                fileUrl={unexecutedRecordAttachment && unexecutedRecordAttachment.url}
                locked
                readOnly
                versionId={versionData.id}
              />
            </div>
          </ContentSection>
          <ContentSection
            LabelText={
              <>
                <UploadFileIcon className={layoutClasses.icon} />
                <Trans>Executed Record Attachment</Trans>
              </>
            }
            fillHeight={false}
          >
            <Spacer factor={1} />
            <div>
              <DirectDocumentUploader
                attrName={'attachment'}
                acceptedFileTypes={'.pdf'}
                attachmentId={attachmentId}
                filename={filename}
                fileUrl={url}
                locked={versionData.locked}
                createAttachment={createExecutedAttachment}
                deleteAttachment={deleteAttachment}
                beforeUpload={() => setLoadingAttachment(true)}
                beforeDelete={() => setLoadingAttachment(false)}
                versionId={versionData.id}
              />
            </div>
            <Spacer factor={2} />
            {attachmentId && (
              <>
                <Typography
                  variant="body2"
                  className={layoutClasses.boldHeaderText}
                  style={{ textTransform: 'uppercase' }}
                >
                  <Trans>Upload Information</Trans>{' '}
                  <span className={layoutClasses.labelSecondaryText}>
                    {DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_FULL)} <Trans>by</Trans>{' '}
                    {creator.fullName}
                  </span>
                </Typography>
              </>
            )}
          </ContentSection>
        </Grid>
      </Grid>
      <Grid container item xs={8} className={layoutClasses.grid}>
        <Grid item xs={12}>
          <ContentSection
            LabelText={
              <>
                <Sort className={layoutClasses.icon} />
                <Trans>Execution Notes</Trans>
              </>
            }
            fillHeight={false}
          >
            <Spacer factor={1} />
            <TextAreaField
              locked={versionData.locked}
              initialValue={versionData.notes}
              versionId={versionData.id}
              attrName={'notes'}
              rows={5}
              placeholder="Enter description"
              refetchQueries={['inProcessInspectionRecordQuery']}
            />
          </ContentSection>
        </Grid>
      </Grid>
      <Grid container item xs={8} className={layoutClasses.grid}>
        <Grid item xs={12} className={`${layoutClasses.pdfView}`}>
          <PDFViewer loading={loadingAttachment} url={url} />
        </Grid>
      </Grid>
    </>
  );
};

export default PayloadFields;
