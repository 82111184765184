import React, { useState } from 'react';
import { Notes, UnfoldLess, UnfoldMore } from '@material-ui/icons';

import { Spacer, TextAreaField, LabelText } from 'components';
import { useStyles } from './styles';

interface Props {
  attrName: string;
  id: string;
  initialValue: string;
  labelText: string;
  locked: boolean;
  versionId: string;
  refetchQueries?: string[];
  onTextChange?: any;
  placeholder?: string;
  startExpanded?: boolean;
  useInEffectiveView?: boolean;
  rows?: number;
}

function CollapsableTextArea(props: Props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [focused, setFocused] = useState(false);

  const adornment = !props.useInEffectiveView ? (
    <>
      {!expanded && <UnfoldLess className={classes.icon} onClick={() => setExpanded(true)} />}
      {expanded && <UnfoldMore className={classes.icon} onClick={() => setExpanded(false)} />}
    </>
  ) : (
    undefined
  );

  return (
    <div>
      <LabelText labelText={props.labelText} Icon={Notes} Adornment={adornment} />
      <Spacer factor={1} />
      <TextAreaField
        useInEffectiveView={props.useInEffectiveView}
        onTextChange={props.onTextChange}
        locked={props.locked}
        id={props.id}
        initialValue={props.initialValue}
        versionId={props.versionId}
        attrName={props.attrName}
        className={expanded || focused ? classes.expanded : classes.collapsed}
        placeholder={props.placeholder || 'Enter description'}
        multiline
        refetchQueries={props.refetchQueries}
        height="large"
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        rows={props.rows || 1}
      />
    </div>
  );
}
export default CollapsableTextArea;
