import React, { useEffect, useContext } from 'react';

import { Grid, Typography, TextField } from '@material-ui/core';
import { BugReportOutlined, BugReport, PlaylistAddCheck, BubbleChart } from '@material-ui/icons';

import { DateTime } from 'luxon';

import { ContentSection, Spacer } from 'components';
import MakeLink from 'components/MakeLink';
import SectionLabel from 'components/core/SectionLabel';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { withLockedContext, LockedContext } from 'contexts/lockedContext';
import { PayloadFieldsProps } from 'itemTypes';

import { InputMethod } from '__generated__/globalTypes';
import GuidedEditorTabs from './GuidedEditorTabs';
import { useUpdateDeviationVersion, useVersion } from './gql';

import { useStyles } from './styles';
import { get } from 'lodash';

interface Props {
  inputMethod: InputMethod;
  activeWorkspaceView: any;
}

function PayloadFields(props: Props & PayloadFieldsProps) {
  const { inputMethod, activeWorkspaceView } = props;
  const classes = useStyles();
  const { data, loading, error, refetch } = useVersion(props.versionId || '');
  const { setLocked } = useContext(LockedContext);

  const [updateDeviationVersion] = useUpdateDeviationVersion();

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching DEV: ${err}`);
    });
  }, [inputMethod, refetch]);

  useEffect(() => {
    if (data) setLocked(data.deviationVersion!.locked); //FIXME: Object is possibly 'null'
  }, [data, setLocked]);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Deviation version ${props.versionId}\n${error}`);

  const currentVersion = data.deviationVersion;

  return (
    <div style={{ width: '100%' }}>
      <SectionLabel icon={BugReport}>Deviation Identification</SectionLabel>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={5} className={classes.protocol}>
          <ContentSection
            Label={<SectionLabel icon={PlaylistAddCheck}>Associated Test Record</SectionLabel>}
            fillHeight={true}
          >
            <div style={{ padding: 4 }}>
              <Typography variant="subtitle1">
                <MakeLink item={currentVersion!.parentRecord!.item}>
                  {'Record ID'}:{' '}
                  <span style={{ fontWeight: 'normal' }}>{currentVersion!.parentRecord!.item.customIdentifier}</span>
                </MakeLink>
              </Typography>
              <Typography variant="subtitle1">
                {'Record Number'}:{' '}
                <span style={{ fontWeight: 'normal' }}>{currentVersion!.parentRecordDeviationsCount}</span>
              </Typography>
              <Typography variant="subtitle1">
                {'Effective Date'}:{' '}
                <span style={{ fontWeight: 'normal' }}>
                  {DateTime.fromISO(currentVersion!.parentRecord!.createdAt).toFormat('LLLL dd, yyyy')}
                </span>
              </Typography>
              <Typography variant="subtitle1">
                {'Version'}:{' '}
                <span style={{ fontWeight: 'normal' }}>{currentVersion!.parentRecord!.versionIdentifier}</span>
              </Typography>
            </div>
            <Spacer factor={1} />
          </ContentSection>
        </Grid>
        <Grid item xs={7}>
          <ContentSection
            fillHeight={true}
            Label={<SectionLabel icon={PlaylistAddCheck}>Deviation Information</SectionLabel>}
          >
            <div style={{ padding: 4 }}>
              <Typography variant="subtitle1">
                {'Deviation Number'}: <span style={{ fontWeight: 'normal' }}>{currentVersion!.deviationId}</span>
              </Typography>
            </div>
            <Spacer factor={2} />

            <Grid item>
              <Typography variant="overline">
                <BubbleChart />
                Associated Test Step
                <TextField
                  className={classes.textFieldStep}
                  fullWidth
                  defaultValue={get(currentVersion, 'associatedTestStep')}
                  variant="outlined"
                  margin="dense"
                  disabled={currentVersion!.locked}
                  placeholder="Enter Test Step number"
                  onChange={(ev) =>
                    updateDeviationVersion({
                      variables: {
                        id: get(currentVersion, 'id'),
                        associatedTestStep: ev.target.value,
                      },
                    })
                  }
                />
              </Typography>
            </Grid>
            <Spacer factor={1} />
          </ContentSection>
        </Grid>
      </Grid>
      <Spacer factor={3} />
      <SectionLabel icon={BugReportOutlined}>Deviation Report</SectionLabel>
      <GuidedEditorTabs
        viewType={activeWorkspaceView.id}
        currentVersion={currentVersion}
        layoutClasses={props.layoutClasses}
        locked={currentVersion!.locked} //FIXME: Object is possibly 'null'
        onUpdate={refetch}
        data={data} //FIXME: Object is possibly 'null'
      />
    </div>
  );
}

export default withWorkspaceViews()(withLockedContext(PayloadFields));
