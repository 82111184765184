import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import Description from '@material-ui/icons/Description';
import PayloadFields from './PayloadFields';
import RecordPage from './RecordPage';
import slugs from '../../displaySlugs';
import PrintViewMenu from 'components/PrintViewMenu';
import Views from 'workspaces/views';
import { defaultRecordViewColumnsWithNotes } from 'components/RecordsView/ItemsTable/defaultColumns';

const itemType = 'form';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query formsQuery {
    forms {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        versionIdentifier
        currentStatusName
      }
    }
  }
`;

const recordsQuery = gql`
  query FormRecordsVersionQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      currentVersion {
        id
        title
        id
        currentStatus {
          id
          name
        }
      }
      versions {
        ... on FormVersion {
          formRecordVersions {
            createdAt
            id
            notes
            item {
              itemType {
                id
                userCreatable
                displaySlug
              }
              id
              customIdentifier
            }
            owner {
              id
              fullName
            }
            approver {
              id
              fullName
            }
            title
            currentStatus {
              name
              id
            }
          }
        }
        id
        releasedAt
        locked
        title
        approver {
          id
          firstName
        }
        owner {
          id
          firstName
        }
        currentStatus {
          name
          id
        }
        versionIdentifier
      }
      currentRelease {
        id
        title
      }
      currentUser {
        id
        readOnly
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Form`,
  categoryLabel: t`Forms`,
  icon: Description,
  RecordViewOptions: {
    query: recordsQuery,
    recordsProperty: 'formRecordVersions',
    recordViewColumns: defaultRecordViewColumnsWithNotes,
  },
  recordWorkspace: [Views.Builder, Views.Effective, Views.Versions, Views.Records],
  listViewColumns,
  listViewQuery,
  PayloadFields,
  RecordPage,
  EffectiveViewOptions: PrintViewMenu,
};
