import 'sanitize.css/sanitize.css';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import { getSentryDsn } from 'utils';
import App, { initMemoryDB, setupAgGridTable } from 'setup';

// Initialize memoryDB on the window
initMemoryDB();

setupAgGridTable();

Sentry.init({
  dsn: getSentryDsn(),
});

ReactDOM.render(<App />, document.getElementById('root'));
