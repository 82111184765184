import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from '../styles';

interface Props {
  icon?: React.ReactNode;
  text?: string;
  onAction?: () => void;
}

const NotificationCTA: FC<Props> = ({ icon, text, onAction, children }) => {
  const classes = useStyles();

  return (
    <div onClick={onAction} className={classes.ctaRoot}>
      <div className={classes.ctaIcon}>{icon}</div>
      {text && (
        <Typography variant="h6" className={classes.ctaText}>
          {text}
        </Typography>
      )}
      {children}
    </div>
  );
};

export default NotificationCTA;
