import React, { useState } from 'react';
import { useMutation } from 'react-apollo';

import LinkOff from '@material-ui/icons/LinkOff';

import ConfirmationDialog from 'components/ConfirmationDialog';
import IconButton from 'components/IconButton';

import { REMOVE_VALIDATION_RECORD, REMOVE_VERIFICATION_RECORD } from './gql';

interface Props {
  record;
  itemType: string;
  currentVersion;
}

const RemoveRecord = (props: Props) => {
  const { record, itemType, currentVersion } = props;
  const [showUnlinkConfirmation, setShowUnlinkConfirmation] = useState(false);

  const [removeValRecord, { error: valRecordError }] = useMutation(REMOVE_VALIDATION_RECORD, {
    onCompleted: () => setShowUnlinkConfirmation(false),
    variables: {
      vvrVersionId: currentVersion.id,
      valRecordVersionId: record.id,
    },
    refetchQueries: ['VerificationAndValidationReportVersionQuery'],
  });
  const [removeVerRecord, { error: verRecordError }] = useMutation(REMOVE_VERIFICATION_RECORD, {
    onCompleted: () => setShowUnlinkConfirmation(false),
    variables: {
      vvrVersionId: currentVersion.id,
      verRecordVersionId: record.id,
    },
    refetchQueries: ['VerificationAndValidationReportVersionQuery'],
  });

  if (valRecordError) throw new Error(`Error removing validation record: ${valRecordError}`);
  if (verRecordError) throw new Error(`Error removing validation record: ${verRecordError}`);

  const removeRecordMutation = itemType === 'validation_record' ? removeValRecord : removeVerRecord;

  return (
    <>
      {!currentVersion.locked && (
        <IconButton
          tooltip="Remove Record"
          Icon={LinkOff}
          onClick={() => setShowUnlinkConfirmation(true)}
          size="large"
        />
      )}
      <ConfirmationDialog
        onCancel={() => setShowUnlinkConfirmation(false)}
        onClose={() => setShowUnlinkConfirmation(false)}
        onConfirm={removeRecordMutation}
        open={showUnlinkConfirmation}
        title="Unlink Confirmation"
      >
        Are you sure you want to unlink this item?
      </ConfirmationDialog>
    </>
  );
};

export default RemoveRecord;
