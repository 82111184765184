import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { ClickAwayListener, Dialog as MuiDialog, Popper, Paper } from '@material-ui/core';

import { Dialog } from 'components';
import ChangeOwnerApprover from 'components/ItemControlMenus/PeopleMenu/ChangeOwnerApprover';
import Controls from 'components/ItemControlButtons';
import { PeopleMenu, TagsMenu, ReferenceIdMenuItem, MenuContainer } from 'components/ItemControlMenus';
import UpdateRefId from 'compositions/UpdateReferenceId';
import CopyTrainingPlanMenuItem from './CopyTrainingPlanMenuItem';
import CopyTrainingPlanMenu from './CopyTrainingPlanMenu';

enum Menu {
  People,
  Tags,
  Ellipses,
}

enum DialogType {
  ReferenceId,
  CopyTrainingPlan,
}

type State = {
  menu: Menu | null;
  anchor: Element | null;
};

const InitialState = { menu: null, anchor: null };

function ItemControls(props: {
  itemId: string;
  referenceId: string | null;
  approver: { id: string; title: string | null; fullName: string } | null;
  owner: { id: string; title: string | null; fullName: string } | null;
  creator: { id: string; title: string | null; fullName: string };
  createdAt: string;
  tags: string[] | null;
  permissibleApprovers;
  permissibleOwners;
  version;
  versionId: string;
  locked: boolean;
  refetchQueries;
}) {
  const [{ menu, anchor }, setMenu] = useState<State>(InitialState);
  const [dialog, setDialog] = useState<DialogType | null>(null);
  const [changeOwnerVisible, setChangeOwnerVisible] = useState(false);
  const [changeApproverVisible, setChangeApproverVisible] = useState(false);

  const handleEscPress = (e) => {
    if (e.key === 'Esc' || e.key === 'Escape') setMenu(InitialState);
  };
  useEffect(() => {
    window.addEventListener('keydown', handleEscPress);
    return () => {
      window.removeEventListener('keydown', handleEscPress);
    };
  });

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          if (!dialog) {
            setMenu(InitialState);
          }
        }}
      >
        <div>
          <Controls
            handleMoreClick={(e) => {
              setMenu({ menu: Menu.Ellipses, anchor: e.currentTarget });
            }}
            handleTagsClick={(e) => {
              setMenu({ menu: Menu.Tags, anchor: e.currentTarget });
            }}
            handlePeopleClick={(e) => {
              setMenu({ menu: Menu.People, anchor: e.currentTarget });
            }}
          />

          <Popper anchorEl={anchor} open={menu === Menu.Ellipses} disablePortal placement="bottom-end">
            <Paper>
              <MenuContainer>
                <ReferenceIdMenuItem
                  referenceId={props.referenceId}
                  onClick={() => setDialog(DialogType.ReferenceId)}
                />
                <CopyTrainingPlanMenuItem onClick={() => setDialog(DialogType.CopyTrainingPlan)} />
              </MenuContainer>
              <Dialog
                open={dialog === DialogType.ReferenceId}
                onClose={() => {
                  setMenu(InitialState);
                  setDialog(null);
                }}
              >
                <UpdateRefId
                  itemId={props.itemId}
                  initialValue={props.referenceId}
                  onClose={() => {
                    setMenu(InitialState);
                    setDialog(null);
                  }}
                />
              </Dialog>
              <CopyTrainingPlanMenu
                open={dialog === DialogType.CopyTrainingPlan}
                onClose={() => {
                  setMenu(InitialState);
                  setDialog(null);
                }}
                trainingPlanId={props.versionId}
              />
            </Paper>
          </Popper>

          <Popper anchorEl={anchor} open={menu === Menu.People} disablePortal style={{ zIndex: 9999 }}>
            <PeopleMenu
              approver={props.approver}
              owner={props.owner}
              locked={props.locked}
              creator={props.creator}
              createdAt={props.createdAt}
              permissibleApprovers={props.permissibleApprovers}
              permissibleOwners={props.permissibleOwners}
              versionId={props.versionId}
              version={props.version}
              refetchQueries={['TrainingPlanBuilderViewQuery']}
              onChangeOwnerClicked={() => setChangeOwnerVisible(true)}
              onChangeApproverClicked={() => setChangeApproverVisible(true)}
              changeOwnerApproverVisible={changeOwnerVisible || changeApproverVisible}
            />
          </Popper>

          <Popper anchorEl={anchor} open={menu === Menu.Tags} disablePortal>
            <TagsMenu
              itemId={props.itemId}
              tags={props.tags}
              refetchQueries={['TrainingPlanBuilderViewQuery', 'tagsQuery']}
            />
          </Popper>
        </div>
      </ClickAwayListener>
      <MuiDialog open={changeOwnerVisible} keepMounted maxWidth={false}>
        <ChangeOwnerApprover
          onClose={() => setChangeOwnerVisible(false)}
          permissionType="own_record"
          permissibleDefaultUsers={props.permissibleOwners}
          currentRole={props.version.assignedOwnerJobRole}
          currentOwnerRole={props.version.assignedOwnerJobRole}
          currentApproverRole={props.version.assignedApproverJobRole}
          currentOwner={props.owner}
          currentApprover={props.approver}
          currentAssignedUser={get(props.owner, 'fullName', '')}
          versionId={props.version.id}
          refetchQueries={props.refetchQueries}
          jobRoleSelectLocked
        />
      </MuiDialog>
      <MuiDialog open={changeApproverVisible} keepMounted maxWidth={false}>
        <ChangeOwnerApprover
          onClose={() => setChangeApproverVisible(false)}
          permissionType="approve_record"
          permissibleDefaultUsers={props.permissibleApprovers}
          currentRole={props.version.assignedApproverJobRole}
          currentOwnerRole={props.version.assignedOwnerJobRole}
          currentApproverRole={props.version.assignedApproverJobRole}
          currentOwner={props.owner}
          currentApprover={props.approver}
          currentAssignedUser={get(props.approver, 'fullName', '')}
          versionId={props.version.id}
          refetchQueries={props.refetchQueries}
          jobRoleSelectLocked
        />
      </MuiDialog>
    </>
  );
}

export default ItemControls;
