import React from 'react';

import { navigate } from '@reach/router';
import get from 'lodash/get';

import { Spacer } from 'components';

import { Button, Typography, withStyles } from '@material-ui/core';
import CompareArrows from '@material-ui/icons/CompareArrows';

import { Trans } from '@lingui/macro';

import styles from './styles';

function SummaryOfChange({ classes, currentVersion, displayChangeInfo, displayDescriptionOfChange = true }) {
  const coId = get(currentVersion, 'linkedChangeId');
  const coCustomId = get(currentVersion, 'linkedChangeCustomIdentifier');

  const ChangeOrder = (
    <Button
      color="primary"
      fullWidth
      variant="outlined"
      style={{ minHeight: 40 }}
      onClick={() => navigate(`/category/change-order/${coId}`)}
    >
      <span className={classes.buttonIconText}>
        <CompareArrows className={classes.buttonIcon} />
        <Trans>View</Trans> {coCustomId}
      </span>
    </Button>
  );

  const Justification = (
    <>
      <Typography variant="body2" component="div">
        <Trans>No change order is associated with this change because:</Trans>
        <Spacer factor={1} />
        <div className={classes.textInput}>{currentVersion.linkedChangeJustificationText}</div>
      </Typography>
    </>
  );

  return (
    <>
      <div className={classes.heading}>
        <Typography variant="h3">
          <Trans>Summary of Change</Trans>
        </Typography>
      </div>

      <Spacer factor={3} />

      <Typography variant="overline">
        <Trans>Reason for Change</Trans>
      </Typography>
      <Spacer factor={1} />
      <Typography variant="body2" className={classes.textInput}>
        {currentVersion.reasonForChange || 'N/A'}
      </Typography>

      <Spacer factor={3} />

      {displayDescriptionOfChange && (
        <>
          <Typography variant="overline">
            <Trans>Description of Change</Trans>
          </Typography>
          <Spacer factor={1} />
          <Typography variant="body2" className={classes.textInput}>
            {currentVersion.descriptionOfChange || 'N/A'}
          </Typography>

          <Spacer factor={3} />
        </>
      )}

      {coId && displayChangeInfo && (
        <>
          <Typography variant="overline">
            <Trans>Associated Change Order</Trans>
          </Typography>
          <Spacer factor={1} />
          {currentVersion.linkedChangeJustificationText ? Justification : ChangeOrder}
        </>
      )}
    </>
  );
}

export default withStyles(styles)(SummaryOfChange);
