import GenericWorkflowSteps from 'workflows/GenericWorkflow/steps';
import DeviationWorkflowSteps from 'workflows/DeviationWorkflow/steps';
import SetGenericWorkflowSteps from './steps/SetGeneric';
import GenericWithMembersWorkflowSteps from './steps/GenericWithMembers';
import CourseWorkflowSteps from './steps/Course';
import ChangeOrderWorkflowSteps from './steps/ChangeOrder';
import SetItemMemberWorkflowSteps from './steps/SetItemMember';
import RecordWorkflowSteps from './steps/Record';
import MiscellaneousRecordWorkflowSteps from './steps/MiscellaneousRecord';
import TraceMatrixWorkflowSteps from './steps/TraceMatrix';
import TrainingRecordWorkflowSteps from './steps/TrainingRecord';
import RiskNoteWorkflowSteps from './steps/RiskNote';
import SupplierWorkflowSteps from './steps/Supplier';
import GenericRecordWorkflowSteps from './steps/GenericRecord';

import GenericWorkflow from 'workflows/GenericWorkflow';
import DeviationWorkflow from 'workflows/DeviationWorkflow';
import SetGenericWorkflow from './components/SetGenericWorkflow';
import GenericWithMembersWorkflow from './components/GenericWithMembersWorkflow';
import CourseWorkflow from './components/CourseWorkflow';
import ChangeOrderWorkflow from './components/ChangeOrderWorkflow';
import SetItemMemberWorkflow from './components/SetItemMemberWorkflow';
import RecordWorkflow from './components/RecordWorkflow';
import MiscellaneousRecordWorkflow from './components/MiscellaneousRecordWorkflow';
import TraceMatrixWorkflow from './components/TraceMatrixWorkflow';
import TrainingRecordWorkflow from './components/TrainingRecordWorkflow';
import TaskWorkflow from './components/TaskWorkflow';
import RiskNoteWorkflow from './components/RiskNoteWorkflow';
import SupplierWorkflow from './components/SupplierWorkflow';
import GenericRecordWorkflow from './components/GenericRecordWorkflow';

import { WorkflowType } from 'workflows/workflowType';

export default {
  [WorkflowType.Generic]: {
    workflow: GenericWorkflowSteps,
    WorkflowComponent: GenericWorkflow,
  },
  [WorkflowType.Task]: {
    WorkflowComponent: TaskWorkflow,
  },
  [WorkflowType.SetGeneric]: {
    workflow: SetGenericWorkflowSteps,
    WorkflowComponent: SetGenericWorkflow,
  },
  [WorkflowType.Deviation]: {
    workflow: DeviationWorkflowSteps,
    WorkflowComponent: DeviationWorkflow,
  },
  [WorkflowType.Course]: {
    workflow: CourseWorkflowSteps,
    WorkflowComponent: CourseWorkflow,
  },
  [WorkflowType.SetItemMember]: {
    workflow: SetItemMemberWorkflowSteps,
    WorkflowComponent: SetItemMemberWorkflow,
  },
  [WorkflowType.ChangeOrder]: {
    workflow: ChangeOrderWorkflowSteps,
    WorkflowComponent: ChangeOrderWorkflow,
  },
  [WorkflowType.Record]: {
    workflow: RecordWorkflowSteps,
    WorkflowComponent: RecordWorkflow,
  },
  [WorkflowType.MiscellaneousRecord]: {
    workflow: MiscellaneousRecordWorkflowSteps,
    WorkflowComponent: MiscellaneousRecordWorkflow,
  },
  [WorkflowType.TraceMatrix]: {
    WorkflowComponent: TraceMatrixWorkflow,
    workflow: TraceMatrixWorkflowSteps,
  },
  [WorkflowType.TrainingRecord]: {
    WorkflowComponent: TrainingRecordWorkflow,
    workflow: TrainingRecordWorkflowSteps,
  },
  [WorkflowType.GenericWithMembers]: {
    workflow: GenericWithMembersWorkflowSteps,
    WorkflowComponent: GenericWithMembersWorkflow,
  },
  [WorkflowType.RiskNote]: {
    workflow: RiskNoteWorkflowSteps,
    WorkflowComponent: RiskNoteWorkflow,
  },
  [WorkflowType.Supplier]: {
    workflow: SupplierWorkflowSteps,
    WorkflowComponent: SupplierWorkflow,
  },
  [WorkflowType.GenericRecord]: {
    workflow: GenericRecordWorkflowSteps,
    WorkflowComponent: GenericRecordWorkflow,
  },
};
