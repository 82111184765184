import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      '& > *': {
        verticalAlign: 'middle',
      },
    },
    left: {
      paddingRight: 10,
    },
    right: {
      paddingLeft: 10,
    },
  }),
);
