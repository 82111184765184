import gql from 'graphql-tag';

export const ITEM_VERSION_FRAGMENT = gql`
  fragment versions on Item {
    versions {
      id
      versionIdentifier
      item {
        id
        customIdentifier
        itemType {
          id
          name
          displaySlug
          discipline
          product
        }
      }
      currentStatus {
        name
      }
      title
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      creator {
        id
        fullName
      }
      createdAt
      releasedAt
    }
  }
`;

export const ITEM_VERSION_QUERY = gql`
  ${ITEM_VERSION_FRAGMENT}

  query ItemVersionQuery {
    allItems {
      id
      itemType {
        id
        name
        displaySlug
        discipline
        product
      }
      customIdentifier
      ...versions
    }
  }
`;
