import React, { useState } from 'react';

import clsx from 'clsx';
import { isUndefined } from 'lodash';

import { TextareaAutosize, Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  textReason: {
    ...theme.typography.body2,
    width: '100%',
    margin: 0,
    background: theme.palette.common.input.background,
    border: `2px solid ${theme.palette.common.input.outline}`,
    borderRadius: 4,
    padding: `${theme.spacing(0.5)}px`,
  },
  shortHeight: {
    minHeight: 28,
  },
  largeHeight: {
    minHeight: 122,
  },
  defaultHeight: {
    fontSize: 'inherit',
    color: 'inherit',
    lineHeight: 'inherit',
  },
  fixedHeight: {
    resize: 'none',
  },
  textBoxLook: {
    background: 'transparent',
    border: '2px solid transparent',
    cursor: 'pointer',
    resize: 'none',
    color: theme.palette.subtitleGrey,
  },
  empty: {
    height: '30px !important',
  },
});

const inputClassSize = (classes, sizeName) => {
  switch (sizeName) {
    case 'small':
      return classes.shortHeight;
    case 'large':
      return classes.largeHeight;
    default:
      return classes.defaultHeight;
  }
};

const TextArea = (props) => {
  const {
    classes,
    autoComplete,
    autoFocus,
    disabled,
    initialValue,
    id,
    label,
    onChange,
    onTextChange,
    placeholder,
    maxLength,
    rows,
    inputClasses,
    obfuscate = false,
    value,
    multiline = false,
    height,
    readOnly = false,
    onFocus,
    onBlur,
    useInEffectiveView = false,
    fixedHeight = true,
    otherClasses,
    onClick,
    isEditing = true,
    onKeyDown,
  } = props;
  const [spanText, setSpanText] = useState(initialValue);

  const handleChange = (e) => {
    onChange ? onChange(e) : onTextChange && onTextChange(e.target.value);
    setSpanText(e.target.value);
  };

  return (
    <Typography
      variant="body2"
      component="div" // div cannot be descendant of p
      style={{ width: '100%', display: 'flex' }}
    >
      {isEditing ? (
        <TextareaAutosize
          onClick={onClick ? (e) => onClick(e) : undefined}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          type={obfuscate ? 'password' : 'text'}
          disabled={disabled}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          id={id || 'change-justify'}
          multiline={multiline || (rows && rows > 1)}
          fullWidth
          rows={rows}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          variant="filled"
          className={clsx(
            !isUndefined(props.className) && props.className,
            (useInEffectiveView || disabled) && classes.textBoxLook,
            useInEffectiveView && classes.textBoxLook,
            useInEffectiveView && !initialValue && classes.empty,
            fixedHeight && classes.fixedHeight,
            classes.textReason,
          )}
          inputProps={{
            className: { root: classes.input },
          }}
          classes={{
            input: clsx(inputClasses, inputClassSize(classes, height), props.className),
            ...otherClasses,
          }}
          label={label}
          defaultValue={initialValue}
          placeholder={!useInEffectiveView ? placeholder : ''}
          value={value}
        />
      ) : (
        <span style={{ minHeight: 36 }}>{spanText || (!useInEffectiveView ? placeholder : '')}</span>
      )}
    </Typography>
  );
};

export default withStyles(styles)(TextArea);
