import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    menuOption: {
      ...theme.typography.body2,
      borderTop: `2px solid ${theme.palette.common.input.outline}`,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '&:hover': {
        background: theme.palette.common.input.hover,
      },
    },
    menuDropdown: {
      height: 'auto',
      maxHeight: 300,
    },
    root: {
      color: theme.palette.blueDark,
      '&:focus': {
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled': {
        opacity: 0.7,
        cursor: 'not-allowed',
      },
    },
    readOnly: {
      border: 'none',
    },
    dropdownIcon: {
      '&.Mui-disabled': {
        display: 'none',
      },
    },
  }),
);
