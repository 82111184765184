import React from 'react';

import { Button, Fab, Modal, Paper, Typography } from '@material-ui/core';

import Spacer from 'components/Spacer';

import { useStyles } from './sharedStyles';

function FailModal({ closeModal, titleText, subtitleText, buttonText, open }) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={closeModal}
      style={{
        top: '33%',
        width: 300,
        left: '50%',
        marginLeft: -150,
      }}
    >
      <Paper className={classes.permissionsContent}>
        <Fab className={classes.errorIcon} size="large" variant="round" disabled>
          !
        </Fab>
        <Typography variant="h3">{titleText}</Typography>
        <Spacer factor={2} />
        <Typography variant="body1">{subtitleText}</Typography>
        <Spacer factor={2} />
        <Button color="primary" className={classes.permissionBack} variant="outlined" onClick={closeModal}>
          {buttonText}
        </Button>
      </Paper>
    </Modal>
  );
}

export default FailModal;
