import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './manage_accounts.svg';

function ManageAccounts(props) {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <Icon {...svgProps} />} />;
}

export default ManageAccounts;
