import React from 'react';

import { WorkflowTransition } from 'compositions/TimelineGroup/steps/types';
import { GenericWorkflowStatuses } from 'workflows/statuses';
import { VoidReason } from 'compositions/TimelineGroup/steps/views';

export default {
  initialStep: 'voidOwnerApproval',
  internalSteps: {
    voidOwnerApproval: {
      component: (props) => {
        return <VoidReason relevantRole={props.currentVersion.assignedOwnerJobRole.formattedRoleName} {...props} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: ({ voidOwnerApproval }) => ({
    toStatus: GenericWorkflowStatuses.VoidOwnerApproval.id,
    ...voidOwnerApproval.signature,
    reasonForTransition: voidOwnerApproval.reason,
  }),
} as WorkflowTransition;
