import curry from 'lodash/curry';
import get from 'lodash/get';

import { Views, workspaceViewsFromRouteName } from 'workspaces';

export const workspaceViewsStoreSelector = (state) => state.workspaceViews;

export const workspaceViewSelector = curry((state, args) => {
  const routeName = get(args, 'routeName');
  const viewKey = get(workspaceViewsStoreSelector(state), routeName);
  const defaultView = routeName
    ? get(workspaceViewsFromRouteName(routeName), 0)
    : null;
  return get(Views, viewKey, defaultView);
});
