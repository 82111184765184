import React, { FC } from 'react';

import { Box, Collapse } from '@material-ui/core';
import CollapseButton from './CollapseButton';

interface Props {
  open: boolean;
  title: string;
  icon: any;
  onCollapseButtonClick: () => void;
}

const SettingsCollapse: FC<Props> = ({ open, icon: Icon, title, children, onCollapseButtonClick }) => {
  return (
    <>
      <CollapseButton open={open} icon={Icon} title={title} onClick={onCollapseButtonClick} />
      <Box mb={2.5} />
      <Collapse in={open}>{children}</Collapse>
    </>
  );
};

export default SettingsCollapse;
