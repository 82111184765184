import React from 'react';
import { Grid, Paper, withStyles } from '@material-ui/core';

const styles = () => ({
  controlBarContainer: {
    boxShadow: 'none',
    height: 36,
    display: 'flex',
    alignItems: 'center',
  },
  grid: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
});

function LongCardPaper(props) {
  const { classes } = props;

  return (
    <Grid item xs={12} className={classes.grid}>
      <Paper className={classes.controlBarContainer}>{props.children}</Paper>
    </Grid>
  );
}

export default withStyles(styles)(LongCardPaper);
