import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

const removeREQFromSet = gql`
  mutation RemoveRequirementsSetMember($requirementsSetMemberId: ID!) {
    removeRequirementsSetMember(requirementsSetMemberId: $requirementsSetMemberId) {
      member {
        id
        version {
          id
          title
          setMember {
            id
          }
        }
        setItemVersion {
          id
          currentStatusName
          versionIdentifier
        }
      }
    }
  }
`;

const removeUNDFromSet = gql`
  mutation RemoveUserNeedsSetMember($userNeedsSetMemberId: ID!) {
    removeUserNeedsSetMember(userNeedsSetMemberId: $userNeedsSetMemberId) {
      member {
        id
        version {
          id
          title
          setMember {
            id
          }
        }
        setItemVersion {
          id
          currentStatusName
          versionIdentifier
        }
      }
    }
  }
`;

function useRemoveMemberFromSetMutation(parentSetName, memberId) {
  const req = useMutation(removeREQFromSet, {
    variables: { requirementsSetMemberId: memberId },
    refetchQueries: ['RequirementsSetVersionQuery', 'timelineQuery', 'requirementQuery'],
    fetchPolicy: 'no-cache',
  });

  const und = useMutation(removeUNDFromSet, {
    variables: { userNeedsSetMemberId: memberId },
    refetchQueries: ['UserNeedsSetVersionQuery', 'timelineQuery', 'userNeedQuery'],
    fetchPolicy: 'no-cache',
  });

  switch (parentSetName) {
    case 'requirements_set':
      return req;
    case 'user_needs_set':
      return und;
    default:
      throw new Error(`No item type defined for ${parentSetName}`);
  }
}

export default useRemoveMemberFromSetMutation;
