import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    notificationListItem: {
      padding: theme.spacing(1, 0.5, 1.5, 1),
      outline: '1px solid #EEE',

      '&:hover': {
        outline: `2px solid ${theme.palette.common.tags.background}`,

        '& $radio': {
          color: theme.palette.subHeading,

          '&:hover': {
            color: theme.palette.primary.main,
          },
        },

        '& .MuiRadio-colorPrimary.Mui-checked': {
          color: theme.palette.primary.main,
        },

        '& $radioChecked': {
          '& .MuiSvgIcon-root:first-child': {
            color: theme.palette.subHeading,
          },

          '&:hover': {
            '& .MuiSvgIcon-root:first-child': {
              color: theme.palette.primary.main,
            },
          },
        },

        '& $headlineIcon, & $headline': {
          color: theme.palette.blueDark,
        },
      },
    },
    notificationListItemMainContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      paddingLeft: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
    headlineContainer: {
      display: 'flex',
    },
    headlineIcon: {
      fontSize: 18,
      color: theme.palette.secondary.main,
    },
    headline: {
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(0.25),
    },
    itemTitle: {
      display: 'inline',
      fontSize: 11,
      fontWeight: 700,
    },
    itemIdLink: {
      color: 'inherit',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    addToMyTasks: {
      '& .MuiButton-root': {
        height: 'auto',
      },
    },
    ctaRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    ctaIcon: {
      marginRight: theme.spacing(0.25),
      fontSize: 12,

      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.main,
        fontSize: 12,
        width: 12,
        height: 12,
      },
    },
    chipItem: {
      '& .MuiChip-root': {
        height: 14,

        '& .MuiTypography-root': {
          color: theme.palette.secondary.main,
          fontSize: 8,
          fontWeight: theme.typography.fontWeightBold,
        },

        '& .MuiChip-deleteIcon': {
          width: 12,
          height: 12,
        },
      },
    },
    ctaText: {
      fontSize: 11,
      color: theme.palette.secondary.main,
    },
    date: {
      fontSize: 8,
      letterSpacing: 1,
      color: theme.palette.blueDark,
    },
    radio: {
      color: theme.palette.common.white,
    },
    radioChecked: {
      '& .MuiSvgIcon-root:first-child': {
        color: theme.palette.common.white,
      },
    },
  }),
);
