import React, { CSSProperties } from 'react';

import camelCase from 'lodash/camelCase';

import WorkflowIcon from 'components/WorkflowIcon';

import { useStyles } from './styles';

export function StatusCell({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <WorkflowIcon type={camelCase(data)} completed size="small" displayTooltip />
    </div>
  );
}

export const statusHeaderStyle = { textAlign: 'center' } as CSSProperties;
