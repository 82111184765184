import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Typography, Input, withStyles } from '@material-ui/core';
import { compose, setDisplayName } from 'recompose';

import UpdateTitleMutation from '../utils/gql/updateTitleMutation';
import withActiveCategory from 'compositions/WithCategories/withActiveCategory';
import { DEBOUNCE_MILLISECONDS } from '../constants';

const styles = () => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  titleInput: {
    fontSize: 'inherit',
    width: '80%',
  },
  typography: {
    width: '100%',
  },
});

interface ActiveCategory {
  icon: any;
  name: { id: string | number };
}

interface ItemTitleClasses {
  titleContainer: string;
  titleInput: string;
  typography: string;
}

interface ItemTitleProps {
  activeCategory: ActiveCategory;
  classes: ItemTitleClasses;
  displayTitle: boolean;
  editable: boolean;
  itemTitle: any;
  refetchQueries: string[];
  versionId: string;
  titlePrefix: string;
  icon?: JSX.Element;
}

function ItemTitle({
  activeCategory,
  classes,
  displayTitle,
  editable,
  itemTitle,
  refetchQueries,
  versionId,
  titlePrefix,
  icon,
}: ItemTitleProps) {
  const Icon = icon || get(activeCategory, 'icon');
  const itemTypeName = get(activeCategory, 'name.id');
  const titleEditable = editable && get(activeCategory, 'titleEditable', true);

  return (
    <Mutation mutation={UpdateTitleMutation}>
      {(mutate) => {
        const changeHandler = debounce((e) => {
          const stringValue = e.target.value.length ? e.target.value : itemTypeName;
          mutate({
            variables: {
              input: {
                versionId,
                attrName: 'title',
                stringValue,
              },
            },
            refetchQueries: refetchQueries,
          });
        }, DEBOUNCE_MILLISECONDS);

        return (
          <div className={classes.titleContainer}>
            <Typography variant="h2" classes={{ root: classes.typography }}>
              {Icon && <Icon viewBox="0 0 24 24" />} {titlePrefix}
              {titleEditable ? (
                <>
                  {displayTitle && ': '}
                  <Input
                    className={classes.titleInput}
                    defaultValue={itemTitle}
                    disableUnderline
                    multiline
                    onChange={(e) => {
                      e.persist();
                      changeHandler(e);
                    }}
                  />
                </>
              ) : displayTitle ? (
                `: ${itemTitle}`
              ) : null}
            </Typography>
          </div>
        );
      }}
    </Mutation>
  );
}

ItemTitle.defaultProps = {
  editable: true,
  refetchQueries: [],
  displayTitle: true,
};

export default compose(
  setDisplayName('ItemTitle'),
  withActiveCategory,
  withStyles(styles),
)(ItemTitle);
