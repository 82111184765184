import React from 'react';
import flowRight from 'lodash/flowRight';
import LinearScale from '@material-ui/icons/LinearScale';
import { Typography, withStyles } from '@material-ui/core';

import { Trans } from '@lingui/macro';

import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';
import styles from 'compositions/TimelineGroup/components/sharedStyles';

import { AlertDialogWithUser, ContentSection, WorkflowIcon } from 'components';
import WorkflowMenu, { VoidMenuItem, CancelMenuItem } from 'components/WorkflowMenu';
import ConnectBar from './ConnectBar';
import { TraceMatrixWorkflowStatuses, indexById } from 'workflows/statuses';
import { AlertDialog } from 'components';
import PreviousReleases from './PreviousReleases';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

import WithCurrentUser from '../../WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import withMetadataFromServer from '../../WithMetadataFromServer';
import rfcContent from 'workflows/GenericWorkflow/rfcContent';

const {
  ApprovedDraft,
  Canceled,
  Draft,
  NotCreated,
  OwnerApproval,
  Rejected,
  UnderReview,
} = TraceMatrixWorkflowStatuses;

const TraceMatrixWorkflowStatusesById = indexById(TraceMatrixWorkflowStatuses);

class TraceMatrixWorkflow extends React.Component {
  constructor(props) {
    super(props);

    const { permissions } = props;
    this.canOwn = permissions.canOwn;
    this.canApprove = permissions.canApprove;
  }

  state = {
    deniedModal: {
      open: false,
    },
    deniedModalWithUser: {
      open: false,
      user: null,
      role: null,
    },
  };

  isCancelable = (status) => this.canOwn && (status.isEqualTo(UnderReview) || status.isEqualTo(Draft));

  isVoidable = (status) =>
    (this.canApprove && status.isEqualTo(ApprovedDraft)) || (this.canOwn && status.isEqualTo(OwnerApproval));

  closeModal = () => {
    this.setState({
      deniedModal: { open: false },
    });
  };

  workflowControls = () => {
    const { currentCompanyAllowsTransitioning, currentVersion, handleInitTransition } = this.props;

    const currentWorkflowStatus = TraceMatrixWorkflowStatusesById[currentVersion.currentStatusName];

    if (!currentCompanyAllowsTransitioning) return;

    return (
      <>
        <WorkflowMenu>
          <CancelMenuItem
            disabled={!this.isCancelable(currentWorkflowStatus)}
            onClick={() => {
              if (!currentVersion.mayTransitionToCanceled) {
                return this.setState({
                  deniedModal: {
                    open: true,
                    heading: <Trans>Change Order Error</Trans>,
                    body: (
                      <Trans>
                        This version can only be released upon removal of links to the associated pending Change Order.
                      </Trans>
                    ),
                  },
                });
              }
              handleInitTransition('cancel');
            }}
          />

          <VoidMenuItem
            disabled={!this.isVoidable(currentWorkflowStatus)}
            onClick={() => handleInitTransition('voidOwnerApproval')}
          />
        </WorkflowMenu>
      </>
    );
  };

  renderTracesLockedModal = (toStatus) => {
    return this.setState({
      deniedModal: {
        open: true,
        heading: 'Transition Error',
        body: `This version cannot be transitioned to ${toStatus} if any of the selected sets or traced versions are not in a ‘released’ status.`,
      },
    });
  };

  renderTransitionLockedModal = () => {
    return this.setState({
      deniedModal: {
        open: true,
        heading: 'Item Status Locked',
        body:
          'Item status updates require an upgraded license type. Contact your administrator to upgrade your license.',
      },
    });
  };

  renderPermissionRequiredModal = () => {
    return this.setState({
      deniedModal: {
        open: true,
        heading: 'Permission Required',
        body: "You don't have permission to perform this action.",
      },
    });
  };

  handleSmallBlueCircleClick = (currentVersion) => {
    const status = currentVersion.currentStatusName;
    const currentWorkflowStatus = TraceMatrixWorkflowStatusesById[status];

    if (currentWorkflowStatus.isEqualTo(NotCreated) || currentWorkflowStatus.isEqualTo(Canceled)) {
      return this.props.handleInitTransition('createNewVersion');
    }

    if (currentWorkflowStatus.isEqualTo(Draft) || (currentWorkflowStatus.isEqualTo(UnderReview) && this.canOwn)) {
      return this.props.handleInitTransition('reasonForChange');
    }

    return this.props.handleInitTransition('summaryOfChange');
  };

  handleInactiveUserDeniedModal = (inactiveUsers) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: inactiveUsers[0].user,
        role: inactiveUsers[0].role,
      },
    });
  };

  handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: usersWithoutActiveRole[0].user,
        role: usersWithoutActiveRole[0].role,
      },
    });
  };

  render() {
    const { classes, currentCompanyAllowsTransitioning, handleInitTransition } = this.props;
    const currentVersion = this.props.currentVersion.releasedAt
      ? { currentStatusName: 'not_created' }
      : this.props.currentVersion;

    const { linkedChangeCustomIdentifier, linkedChangeJustificationText, locked, versionIdentifier } = currentVersion;

    const currentWorkflowStatus = TraceMatrixWorkflowStatusesById[currentVersion.currentStatusName];

    const hasChangeOrder = Boolean(linkedChangeCustomIdentifier) && !Boolean(linkedChangeJustificationText);

    const inactiveUsers = getInactiveUsers(currentVersion.owner, currentVersion.approver);
    const usersWithoutActiveRole = getUsersWithoutActiveRole(
      currentVersion.owner,
      currentVersion.approver,
      currentVersion.assignedOwnerJobRole,
      currentVersion.assignedApproverJobRole,
    );

    return (
      <ContentSection
        LabelText="Workflow"
        Icon={<LinearScale />}
        Controls={this.workflowControls()}
        classes={{ label: classes.timelineHeader }}
      >
        <div className={classes.timelineContainer}>
          <PreviousReleases
            generateSmallCircleContent={rfcContent}
            releasedVersions={this.props.releasedVersions}
            onClick={(v) =>
              this.setState({
                previousVersionModal: true,
                versionForModal: v,
              })
            }
          />

          <SmallCircle
            content={rfcContent(currentVersion)}
            color="draft"
            handleClick={() => this.handleSmallBlueCircleClick(currentVersion)}
          />

          {currentWorkflowStatus.isAfter(NotCreated) && !currentWorkflowStatus.isEqualTo(Canceled) && (
            <>
              <ConnectBar color="draft" short visible />

              <WorkflowIcon
                type="draft"
                active={currentWorkflowStatus.isEqualTo(Draft)}
                completed={currentWorkflowStatus.isAfter(Draft)}
                version={versionIdentifier}
                label="Draft"
                visible
              />

              <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

              <SmallCircle
                content={hasChangeOrder ? 'CO' : linkedChangeJustificationText ? 'JST' : '?'}
                color="underReview"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) return this.renderTransitionLockedModal();
                  if (!hasChangeOrder && !locked && !linkedChangeJustificationText)
                    return handleInitTransition('ownerApprovalWithoutSig');

                  return handleInitTransition('associatedChange');
                }}
                visible
              />

              <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

              <WorkflowIcon
                type="underReview"
                active={currentWorkflowStatus.isEqualTo(UnderReview)}
                completed={currentWorkflowStatus.isAfter(UnderReview)}
                version={versionIdentifier}
                disabledEffect={!currentCompanyAllowsTransitioning}
                label="Under Review"
                size="large"
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) {
                    this.renderTransitionLockedModal();
                  }

                  if (inactiveUsers.length) return this.handleInactiveUserDeniedModal(inactiveUsers);
                  if (usersWithoutActiveRole.length)
                    return this.handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);

                  if (!currentVersion.mayTransitionToUnderReview) {
                    return this.renderTracesLockedModal('Under Review');
                  }

                  return handleInitTransition('underReview');
                }}
                visible
              />

              <ConnectBar color="ownerApproval" disabled={!currentCompanyAllowsTransitioning} visible />

              <WorkflowIcon
                type="ownerApproval"
                active={currentWorkflowStatus.isEqualTo(OwnerApproval)}
                completed={currentWorkflowStatus.isAfter(OwnerApproval)}
                version={versionIdentifier}
                disabled={!this.canOwn}
                disabledEffect={!currentCompanyAllowsTransitioning}
                label="Owner Approval"
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) {
                    this.renderTransitionLockedModal();
                  }

                  if (!currentVersion.mayTransitionToOwnerApproval) {
                    return this.renderTracesLockedModal('Owner Approval');
                  }

                  if (currentWorkflowStatus.isEqualTo(UnderReview)) {
                    if (this.canOwn) {
                      return handleInitTransition('ownerApproval');
                    } else {
                      this.renderPermissionRequiredModal();
                    }
                  }

                  return null;
                }}
                visible
              />

              <ConnectBar
                color="rejected"
                disabled={!currentCompanyAllowsTransitioning}
                visible={currentWorkflowStatus.isEqualTo(Rejected)}
              />

              <WorkflowIcon
                type="rejected"
                active
                version={versionIdentifier}
                disabledEffect={!currentCompanyAllowsTransitioning}
                label="Rejected"
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) {
                    this.renderTransitionLockedModal();
                  }

                  return handleInitTransition('resolveRejection');
                }}
                visible={currentWorkflowStatus.isEqualTo(Rejected)}
              />

              <ConnectBar
                color="approvedDraft"
                disabled={
                  currentWorkflowStatus.isBefore(OwnerApproval) ||
                  !currentCompanyAllowsTransitioning ||
                  !this.canApprove
                }
                visible={
                  currentWorkflowStatus.isEqualTo(ApprovedDraft) ||
                  (!currentWorkflowStatus.isEqualTo(Rejected) &&
                    (hasChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval)))
                }
              />

              <WorkflowIcon
                type="approvedDraft"
                active={currentWorkflowStatus.isEqualTo(ApprovedDraft)}
                completed={currentWorkflowStatus.isAfter(ApprovedDraft)}
                disabled={currentWorkflowStatus.isBefore(OwnerApproval) || !this.canApprove}
                version={versionIdentifier}
                label="Approved Draft"
                size="large"
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) {
                    return this.renderTransitionLockedModal();
                  }

                  if (!currentVersion.mayTransitionToApprovedDraft) {
                    return this.renderTracesLockedModal('Approved Draft');
                  }

                  if (!this.canApprove) {
                    return this.renderPermissionRequiredModal();
                  }

                  if (currentWorkflowStatus.isEqualTo(OwnerApproval)) {
                    return handleInitTransition('approveOrReject');
                  }

                  return null;
                }}
                visible={
                  currentWorkflowStatus.isEqualTo(ApprovedDraft) ||
                  (!currentWorkflowStatus.isEqualTo(Rejected) &&
                    (hasChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval)))
                }
              />

              <ConnectBar
                color="released"
                disabled={!currentCompanyAllowsTransitioning || !this.canApprove}
                visible={!currentWorkflowStatus.isEqualTo(Rejected)}
              />

              <WorkflowIcon
                type="released"
                version={versionIdentifier}
                label="Release"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) {
                    return this.renderTransitionLockedModal();
                  }

                  if (!currentVersion.mayTransitionToReleased) {
                    return this.renderTracesLockedModal('Releasee');
                  }

                  if (currentWorkflowStatus.isEqualTo(ApprovedDraft)) {
                    return this.setState({
                      deniedModal: {
                        open: true,
                        heading: 'Release not permitted',
                        body: 'This item can only be released upon close of its associated Change Order.',
                      },
                    });
                  }

                  if (currentWorkflowStatus.isEqualTo(OwnerApproval) && !hasChangeOrder) {
                    if (!this.canApprove) {
                      return this.renderPermissionRequiredModal();
                    } else {
                      return handleInitTransition('releaseOrReject');
                    }
                  }
                }}
                disabled={!this.canApprove}
                visible={!currentWorkflowStatus.isEqualTo(Rejected)}
                completed={currentWorkflowStatus.isAfter(ApprovedDraft)}
              />

              <ConnectBar color="rejected" visible={currentWorkflowStatus.isEqualTo(Canceled)} disabled />

              <WorkflowIcon
                type="rejected"
                label="Canceled"
                visible={currentWorkflowStatus.isEqualTo(Canceled)}
                version={versionIdentifier}
                completed
              />

              <AlertDialog
                open={this.state.deniedModal.open}
                onClose={() => this.closeModal()}
                titleText={this.state.deniedModal.heading}
              >
                <Typography variant="body2">{this.state.deniedModal.body}</Typography>
              </AlertDialog>

              <AlertDialogWithUser
                open={this.state.deniedModalWithUser.open}
                titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
                onClose={() => this.setState({ deniedModalWithUser: { open: false, user: null, role: null } })}
                subtitle={
                  inactiveUsers.length
                    ? 'The following user has been deactivated.'
                    : "The following user's role has been removed."
                }
                user={this.state.deniedModalWithUser.user}
                role={
                  this.state.deniedModalWithUser.role === 'owner'
                    ? currentVersion.assignedOwnerJobRole
                    : currentVersion.assignedApproverJobRole
                }
                body={`Please use the People Menu to reassign the ${
                  this.state.deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
                } role to a new user.`}
              />
            </>
          )}
        </div>
      </ContentSection>
    );
  }
}

export default flowRight(
  WithCurrentUser,
  withCurrentCompany,
  withMetadataFromServer(),
  withStyles(styles),
)(TraceMatrixWorkflow);
