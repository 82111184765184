import { WorkflowDefinition, WorkflowTransition } from '../types';

import SummaryOfChange from '../TraceMatrix/SummaryOfChange';
import CreateNewVersion from './CreateNewVersion';
import ReadyForPrequalification from './ReadyForPrequalification';
import PrequalificationComplete from './PrequalificationComplete';
import ReadyForQualification from './ReadyForQualification';
import QualificationComplete from './QualificationComplete';
import SupplierApprovedOrRejected from './SupplierApprovedOrRejected';
import QualificationRejected from './QualificationRejected';
import QualificationRevoked from './QualificationRevoked';
import Archived from './Archived';

const supplierWorkflowSteps: WorkflowDefinition = {
  createNewVersion: CreateNewVersion as WorkflowTransition,
  summaryOfChange: SummaryOfChange as WorkflowTransition,
  readyForPrequalification: ReadyForPrequalification as WorkflowTransition,
  prequalificationComplete: PrequalificationComplete as WorkflowTransition,
  readyForQualification: ReadyForQualification as WorkflowTransition,
  qualificationComplete: QualificationComplete as WorkflowTransition,
  supplierApproved: SupplierApprovedOrRejected as WorkflowTransition,
  qualificationRejected: QualificationRejected as WorkflowTransition,
  qualificationRevoked: QualificationRevoked as WorkflowTransition,
  archived: Archived as WorkflowTransition,
};

export default supplierWorkflowSteps;
