import React from 'react';

import { Trans, t } from '@lingui/macro';
import { setupI18n } from '@lingui/core';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ReportProblem from '@material-ui/icons/ReportProblem';
import Sort from '@material-ui/icons/Sort';
import TrendingUp from '@material-ui/icons/TrendingUp';

import { ContentSection, SelectField, Spacer, TextAreaField } from 'components';

const i18n = setupI18n();

const riskTypeOptions = [i18n._(t`Low`), i18n._(t`Medium`), i18n._(t`High`)];

const styles = (theme) => ({
  label: {
    paddingBottom: theme.spacing(0.5),
  },
  multiSection: {
    minHeight: 141,
  },
});

const PayloadFields = ({ classes, layoutClasses, versionData, i18n }) => {
  return (
    <>
      <Grid item xs={8} className={layoutClasses.gridGutterRight}>
        <ContentSection
          LabelText={
            <>
              <Sort className={layoutClasses.icon} />
              <Typography variant="button">
                <Trans>URS Description</Trans>
              </Typography>
            </>
          }
        >
          <Spacer factor={1} />
          <TextAreaField
            refetchQueries={['userRequirementSpecificationQuery']}
            locked={versionData.locked}
            id="user-req-spec-text-field"
            initialValue={versionData.userReqSpecDescription}
            versionId={versionData.id}
            attrName={'user_req_spec_description'}
            rows={8}
            placeholder={i18n._(t`Enter description`)}
            className={layoutClasses.textField}
          />
        </ContentSection>
      </Grid>

      <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
        <ContentSection
          BigHeading={<Trans>Risk Level</Trans>}
          BigHeadingIcon={<ReportProblem />}
          Icon={<TrendingUp fontSize="small" />}
          LabelText={
            <Typography variant="button">
              <Trans>Risk Level</Trans>
            </Typography>
          }
          PaperClasses={classes.multiSection}
        >
          <Spacer factor={1} />
          <SelectField
            attrName="risk_level"
            options={riskTypeOptions}
            locked={versionData.locked}
            value={versionData.riskLevel}
            placeholder={i18n._(t`Select Risk Level`)}
            versionId={versionData.id}
            refetchQueries={['userRequirementSpecificationQuery']}
          />

          <Spacer factor={2} />

          <Typography variant="button" component="p" className={classes.label}>
            <Sort className={layoutClasses.icon} />
            <Trans>Justification</Trans>
          </Typography>

          <TextAreaField
            id="justification-text-field"
            placeholder={i18n._(t`Enter justification`)}
            locked={versionData.locked}
            initialValue={versionData.justificationForRiskLevel}
            attrName="justification_for_risk_level"
            versionId={versionData.id}
            rows={2}
            refetchQueries={['userRequirementSpecificationQuery']}
          />
        </ContentSection>
      </Grid>
    </>
  );
};

export default withStyles(styles)(PayloadFields);
