import React from 'react';

class RouteChangeWorker extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname)
      window.scroll(0, 0);
  }

  render() {
    return null;
  }
}

export default RouteChangeWorker;
