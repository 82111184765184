import React from 'react';
import { Query } from 'react-apollo';
import get from 'lodash/get';

import { Loading, BuilderView, EffectiveView, VersionsView, RecordsView, ComingSoon } from 'components';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { buildFullItemRecordQuery } from '../../utils/gql/itemRecordQuery';
import useItemType from './useItemType';
import { Views } from 'workspaces';
import { LinksGraph } from 'compositions';

function SingletonPage(props: { itemType: string; categorySlug: string; activeWorkspaceView: { id: string } }) {
  const itemType = useItemType(props.itemType);

  const renderView = (record?: any) => {
    if (!itemType) return <Loading />;

    switch (props.activeWorkspaceView.id) {
      case (Views.Set.id, Views.Builder.id):
        return (
          <BuilderView
            categorySlug={props.categorySlug}
            itemType={props.itemType}
            itemRecord={record}
            itemId={get(record, 'id')}
            PayloadFields={itemType.PayloadFields}
          />
        );
      case Views.Effective.id:
        return (
          <EffectiveView
            categorySlug={props.categorySlug}
            itemType={props.itemType}
            itemRecord={record}
            itemId={get(record, 'id')}
            PayloadFields={itemType.PayloadFields}
            EffectiveViewOptions={itemType.EffectiveViewOptions}
          />
        );
      case Views.Versions.id:
        return (
          <VersionsView
            categorySlug={props.categorySlug}
            itemType={props.itemType}
            itemRecord={record}
            itemId={get(record, 'id')}
            PayloadFields={itemType.PayloadFields}
            VersionsViewOptions={itemType.EffectiveViewOptions}
          />
        );
      case (Views.LinksGraph.id, Views.StepTrace.id):
        return (
          <LinksGraph
            categorySlug={props.categorySlug}
            itemType={props.itemType}
            queryVariables={itemType.linksGraphViewQueryVariables}
          />
        );
      case (Views.Records.id):
        return (
          <RecordsView
            RecordViewOptions={itemType.RecordViewOptions}
            itemId={get(record, 'id')}
            categorySlug={props.categorySlug}
            itemType={props.itemType}
          />
        );
      default:
        return <ComingSoon />;
    }
  };

  if (itemType) {
    const { query: itemQuery, versionFields, userCreatable } = itemType;

    var query =
      itemQuery ||
      (versionFields &&
        buildFullItemRecordQuery({
          itemType: props.itemType,
          versionFields,
          userCreatable,
        }));
  }

  if (query) {

    return (
      <Query errorPolicy="all" query={query} fetchPolicy={'network-only'} skip={!query}>
        {({ data, loading, error }) => {
          if (error) throw error;
          if (loading || !itemType) return <Loading />;

          return renderView(get(data, props.itemType));
        }}
      </Query>
    );
  } else {
    return renderView();
  }
}

export default withWorkspaceViews()(SingletonPage);
