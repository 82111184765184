import get from 'lodash/get';

export function matchPermissionGrantForVersion(user, version, grantType) {
  const userId = get(user, 'id');
  const grantUserId = get(version, `${grantType}.id`);

  if (!userId || !grantUserId) {
    return false;
  } else {
    return userId === grantUserId;
  }
}

export function stepCustomWidth(step) {
  const STEPS_DICT = {
    qualificationRevoked: 'min-content',
    supplierApproved: 'min-content',
  };

  return STEPS_DICT[step];
}
