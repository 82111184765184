import React, { useEffect } from 'react';
import get from 'lodash/get';

import { ChangeOrderWorkflowStatuses } from 'workflows/statuses';
import { ChangeOrderError } from 'workflows/releaseErrors';

import { CertifiedSignature, ChangeOrderClosureConfirmation, RejectReason, YesOrNo } from '../views';

function getFinalResult({ changeOrderClosureConfirmation, changeOrderClosureSignature, rejectReason }) {
  if (changeOrderClosureConfirmation.approve) {
    return {
      ...changeOrderClosureSignature,
      toStatus: ChangeOrderWorkflowStatuses.Closed.id,
    };
  } else {
    return {
      ...rejectReason.signature,
      reasonForRejection: rejectReason.reason,
      toStatus: ChangeOrderWorkflowStatuses.Rejected.id,
    };
  }
}

function ChangeOrderRejectReason(props) {
  const heading = `As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I reject ${
    props.itemCustomIdentifier
  }.`;
  return <RejectReason {...props} heading={heading} />;
}

function ChangeOrderClosureSignature(props) {
  const heading = 'CO Closed E-Signature';
  const dialog = `As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I approve the closure of ${
    props.itemCustomIdentifier
  }.`;

  return (
    <CertifiedSignature
      {...props}
      dialog={dialog}
      heading={heading}
      errorDisplay={props.error ? ChangeOrderError : undefined}
    />
  );
}

function ModalTrecsNotChecked(props) {
  const completeTrecsPercent = get(props, 'currentVersion.impactedEmployeesTrainingProgress');
  const impactedEmployees = get(props, 'currentVersion.impactedEmployees');

  const noModal = completeTrecsPercent === 100 || impactedEmployees.length === 0;
  useEffect(() => {
    if (noModal) {
      props.handleComplete(true, { forgetThisStep: true });
    }
  }, [props, props.handleComplete, noModal]);
  if (noModal) return <div />;

  return (
    <YesOrNo
      disableOnSubmit
      heading="Training Incomplete"
      prompt={`Training associated with this change order has been completed by ${completeTrecsPercent}% of employees assigned. Are you sure you want to close this change order?`}
      yesLabel="Confirm"
      noLabel="Cancel"
      {...props}
      handleComplete={(yes) => {
        if (yes) {
          props.handleComplete(yes);
        } else {
          props.handleClose();
        }
      }}
    />
  );
}

export default {
  initialStep: 'modalIfTrecsNotChecked',
  internalSteps: {
    modalIfTrecsNotChecked: {
      component: ModalTrecsNotChecked,
      getNextStep: (proceed) => (proceed ? 'changeOrderClosureConfirmation' : 'complete'),
    },
    changeOrderClosureConfirmation: {
      component: ChangeOrderClosureConfirmation,
      getNextStep: ({ approve }) => (approve ? 'changeOrderClosureSignature' : 'rejectReason'),
    },
    rejectReason: {
      component: ChangeOrderRejectReason,
      getNextStep: (_) => 'complete',
    },
    changeOrderClosureSignature: {
      component: ChangeOrderClosureSignature,
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult,
};
