import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    commentInputContainer: {
      position: 'relative',
    },
    commentsPopover: {
      width: 320,
      height: 340,
      background: theme.palette.common.white,
    },
    inputField: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    commentInputWrapper: {
      maxHeight: 200,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      ...theme.typography.body2,
      background: theme.palette.common.input.outline,
      borderRadius: 4,
      paddingTop: theme.spacing(1.5),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(1),
      '& textarea': {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        top: `3px !important`,
      },
      '& ul': {
        width: 202,
        paddingTop: `8px !important`, // react-mention lib override
        paddingBottom: `8px !important`, // react-mention lib override
        borderRadius: 4,
        boxShadow: `0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 4px 5px 0 rgba(0, 0, 0, 0.14)`,
      },
    },
    commentInputFocus: {
      boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`,
    },
    commentInputIcon: {
      width: 18,
      cursor: 'pointer',
      color: theme.palette.blueDark,
      position: 'absolute',
      top: 9,
      right: 8,
    },
    noCommentMsg: {
      display: 'flex',
      flexDirection: 'column',
      width: '32%',
      textAlign: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '35%',
    },
    chatMessage: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1.5),
    },
    chatTimestamp: {
      marginLeft: 'auto',
    },
    chatMessageContentWrapper: {
      display: 'flex',
    },
    avatarContainer: {
      display: 'flex',
      flex: 1,
      paddingLeft: theme.spacing(1.5),
      marginTop: theme.spacing(0.5),
    },
    chatMessageContainer: {
      backgroundColor: theme.palette.background.contextDrawerGrey,
      flex: 1,
      overflowX: 'hidden',
      overflowY: 'scroll',
      padding: theme.spacing(1),
      transform: 'rotate(180deg) scaleX(-1)',
    },
    chatMessageContent: {
      flex: 9,
      paddingTop: theme.spacing(2.5),
      padding: theme.spacing(1.5),
      borderRadius: theme.spacing(1),
    },
    chatMessageUser: {
      fontWeight: theme.typography.fontWeightBold,
      position: 'absolute',
      marginTop: theme.spacing(1) * -2,
    },
  });
