import React from 'react';
import { Query } from 'react-apollo';
import get from 'lodash/get';

import { Loading, BuilderView, EffectiveView, VersionsView, ComingSoon, RecordsView } from 'components';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { buildFullItemRecordQuery } from '../../utils/gql/itemRecordQuery';
import useItemType from './useItemType';
import { Views } from 'workspaces';
import { LinksGraph } from 'compositions';

function RecordPage(props: {
  itemId: string;
  itemType: string;
  categorySlug: string;
  activeWorkspaceView: { id: string };
}) {
  const itemType = useItemType(props.itemType);

  const renderView = (record?: any) => {
    if (!itemType) return <Loading />;

    switch (props.activeWorkspaceView.id) {
      case Views.Builder.id:
      case Views.Set.id:
        return (
          <BuilderView
            categorySlug={props.categorySlug}
            itemType={props.itemType}
            itemRecord={record}
            itemId={props.itemId}
            PayloadFields={itemType.PayloadFields}
            hideNotes={itemType.hideNotes}
          />
        );
      case Views.Effective.id:
        return (
          <EffectiveView
            categorySlug={props.categorySlug}
            itemType={props.itemType}
            itemRecord={record}
            itemId={props.itemId}
            PayloadFields={itemType.PayloadFields}
            createNewRecordOptions={itemType.createNewRecordOptions}
            recordItemType={itemType.recordItemType}
            EffectiveViewOptions={itemType.EffectiveViewOptions}
            displayHistoryInEffectiveView={itemType.displayHistoryInEffectiveView}
          />
        );
      case Views.Versions.id:
        return (
          <VersionsView
            categorySlug={props.categorySlug}
            itemType={props.itemType}
            itemRecord={record}
            itemId={props.itemId}
            PayloadFields={itemType.PayloadFields}
            VersionsViewOptions={itemType.EffectiveViewOptions}
          />
        );
      case Views.LinksGraph.id:
      case Views.StepTrace.id:
        return (
          <LinksGraph
            categorySlug={props.categorySlug}
            itemType={props.itemType}
            queryVariables={itemType.linksGraphViewQueryVariables}
          />
        );
      case Views.Records.id:
        return (
          <RecordsView
            RecordViewOptions={itemType.RecordViewOptions}
            categorySlug={props.categorySlug}
            itemType={props.itemType}
            itemId={props.itemId}
          />
        );

      default:
        return <ComingSoon />;
    }
  };

  if (itemType) {
    const { query: itemQuery, versionFields, userCreatable } = itemType;

    var query =
      itemQuery ||
      (versionFields &&
        buildFullItemRecordQuery({
          itemType: props.itemType,
          versionFields,
          userCreatable,
        }));
  }

  if (query) {
    return (
      <Query errorPolicy="all" query={query} variables={{ itemId: props.itemId }} fetchPolicy={'network-only'}>
        {(result) => {
          const { data, loading, error } = result;

          if (error) throw error;
          if (loading || !itemType) return <Loading />;

          return renderView(get(data, props.itemType));
        }}
      </Query>
    );
  } else {
    return renderView();
  }
}

export default withWorkspaceViews()(RecordPage);
