import React from 'react';
import { Paper, Typography, withStyles } from '@material-ui/core';
import { buildConditionalClasses } from 'utils';

const styles = (theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  paper: {
    flex: 7,
  },
  fullHeight: {
    height: '100%',
  },
});

function ContentSection({
  BigHeading,
  BigHeadingIcon,
  BigHeadingSecondaryIcon = false,
  fillHeight,
  PaperClasses,
  Icon,
  LabelText,
  Label = null,
  padding = true,
  scrollX = false,
  ...props
}) {
  return (
    <div
      className={buildConditionalClasses([[fillHeight, props.classes.fullHeight]])}
      style={{
        display: 'flex',
        flexDirection: props.flexDirection || 'column',
        overflowX: scrollX ? 'scroll' : 'auto',
        overflow: 'visible',
      }}
    >
      {(BigHeading || BigHeadingIcon) && (
        <Typography variant="h3" style={{ flex: 2, marginBottom: 8 }}>
          {BigHeadingIcon} {BigHeading}
        </Typography>
      )}
      <Paper
        className={buildConditionalClasses([
          [padding, props.classes.padding],
          [true, PaperClasses],
          [fillHeight, props.classes.paper],
        ])}
        elevation={props.elevation || 0}
        style={props.style}
      >
        {Label ? (
          Label
        ) : (
          <Typography component="div" variant="button">
            {Icon} {LabelText}
          </Typography>
        )}
        <div>{props.Controls}</div>

        {props.children}
      </Paper>
    </div>
  );
}

ContentSection.defaultProps = {
  fillHeight: true,
};

export default withStyles(styles)(ContentSection);
