import gql from 'graphql-tag';

export const tagsQuery = gql`
  query tagsQuery($search: String) {
    tags(search: $search) {
      name
    }
  }
`;

export const tagSearch = gql`
  query tagSearch($tags: [String!]) {
    tagSearch(tags: $tags) {
      itemType
      results {
        id
        customIdentifier
        title
        userCreatable
        currentVersionId
        status
      }
    }
  }
`;

export const tagsMutation = gql`
  mutation UpdateTags($tags: [String!]!, $itemId: ID!) {
    updateTags(itemId: $itemId, tags: $tags) {
      tags
    }
  }
`;

export const BULK_ITEM_TAG_ADD = gql`
  mutation BulkItemTagAdd($tags: [String!]!, $items: [ID!]!) {
    bulkAddTags(tags: $tags, items: $items) {
      items {
        id
        tags
      }
    }
  }
`;

export const BULK_ITEM_TAG_REMOVE = gql`
  mutation BulkItemTagRemove($tags: [String!]!, $items: [ID!]!) {
    bulkRemoveTags(tags: $tags, items: $items) {
      items {
        id
        tags
      }
    }
  }
`;
