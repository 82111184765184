export default (theme) => ({
  calendarContainer: {
    display: 'inline',
    position: 'absolute',
    marginTop: theme.spacing(1) * -1.1,
  },
  calendarIcon: {
    width: '12px',
  },
  tooltipText: {
    ...theme.typography.body2,
    padding: theme.spacing(0.25),
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
  },
  tooltipContainer: {
    marginTop: theme.spacing(0.5),
  },
});
