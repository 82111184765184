import React, { useMemo } from 'react';
import { Chip } from '@material-ui/core';
import _ from 'lodash';

import { useStyles } from './styles';

export { addColumnGroups } from './addColumnGroups';

// if columns have widths this can provide groupings

export interface Column {
  width: number;
  startGroup?: string;
  stopGroup?: string;
}

interface Props {
  columns: Column[];
}

export function ColumnGroups({ columns }: Props) {
  const headerGroups = useMemo(() => calcHeaderGroups(columns), [columns]);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {headerGroups.map((x, i) => (
        <div
          key={i}
          className={classes.group}
          style={{
            left: x.dx,
            width: x.width,
          }}
        >
          <Chip size="small" label={x.text} />
        </div>
      ))}
    </div>
  );
}

interface ColumnGroup {
  text: string;
  dx: number;
  width: number;
}

function calcHeaderGroups(columns: Column[]) {
  const headerGroups: ColumnGroup[] = [];

  let currentColumnDx = 0;
  columns.forEach((x: any) => {
    if (x.startGroup)
      headerGroups.push({
        text: x.startGroup,
        dx: currentColumnDx,
        width: 0,
      });

    currentColumnDx += x.width;

    if (x.stopGroup) {
      const group = _.last(headerGroups);
      if (group) group.width = currentColumnDx - group.dx;
    }
  });

  return headerGroups;
}
