import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
    line: {
      border: `solid 2px ${theme.palette.primary.main}`,
      borderRadius: 2,
      marginRight: theme.spacing(1),
    },
  }),
);
