import React from 'react';

import { Grid, withStyles } from '@material-ui/core';

import get from 'lodash/get';
import compact from 'lodash/compact';
import snakeCase from 'lodash/snakeCase';
import { useQuery } from 'react-apollo';

import { Blur, Breadcrumbs, ItemTitle, NoReleaseModal } from 'components';
import ContextDrawer from 'compositions/ContextDrawer';
import EffectiveViewData from 'compositions/EffectiveViewData';
import styles from 'compositions/EffectiveViewData/styles';
import CreateFormRecord, { CreateFormRecordMutationVariables } from 'compositions/CreateFormRecord';
import { PayloadFieldsComponent } from 'itemTypes';

import query from './query';
import { Views } from 'workspaces';
import { BaseMutationOptions, RefetchQueriesFunction } from '@apollo/react-common/lib/types/types';
import { PureQueryOptions } from 'apollo-client';

interface EffectiveViewProps {
  categorySlug: string;
  classes;
  itemId: string;
  itemRecord?;
  itemType: string;
  displayHistoryInEffectiveView?: boolean;
  PayloadFields: PayloadFieldsComponent;
  recordItemType?: string;
  EffectiveViewOptions?: (props: {
    pdfExportHeading: string;
    pdfExportSubheading: string;
    versionId: string;
  }) => JSX.Element | any;
  createNewRecordOptions?: {
    createNewRecordMutation: (options?: BaseMutationOptions<any, CreateFormRecordMutationVariables>) => Promise<any>;
    refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
  };
}

function EffectiveView({
  categorySlug,
  classes,
  itemId,
  itemRecord: itemRecordFromProps,
  itemType,
  displayHistoryInEffectiveView = true,
  PayloadFields,
  recordItemType,
  EffectiveViewOptions,
  createNewRecordOptions,
}: EffectiveViewProps) {
  const { data, loading, error } = useQuery(query, {
    fetchPolicy: 'network-only',
    skip: itemRecordFromProps, // if we are passed data, no need to query
    variables: {
      itemId,
      itemType: snakeCase(itemType),
    },
  });

  if (loading) return null;
  if (error) throw new Error(`Effective View query failed: ${error.message}`);

  const itemRecord = itemRecordFromProps || data['item'];
  const { currentRelease, currentVersion, currentUser } = itemRecord;
  const itemTitle = get(currentRelease, 'title', '');
  const hasCurrentRelease = Boolean(currentRelease);

  const isRetired = itemType !== 'changeOrder' && currentVersion.currentStatus.name === 'retired';
  const retiredMessage =
    'This item has been retired. Select Versions View from the view menu for the' +
    'retired and/or previous versions of this item.';

  const navToViewLink =
    itemId && !isRetired
      ? `/category/${categorySlug}/${itemId}?view=${Views.Builder.id}`
      : itemId && isRetired
      ? `/category/${categorySlug}/${itemId}?view=${Views.Versions.id}`
      : !itemId && !isRetired
      ? `/category/${categorySlug}?view=${Views.Builder.id}`
      : `/category/${categorySlug}?view=${Views.Versions.id}`;

  const textForExecutionButton = (itemType) => {
    if (itemType === 'deviceValidation' || itemType === 'deviceVerification') {
      return 'Execute Protocol';
    } else {
      return 'Use this form';
    }
  };

  return (
    <Grid container data-testid="item" spacing={3} className={classes.container}>
      <Grid item xs={12} className={classes.grid}>
        <Breadcrumbs categorySlug={categorySlug} itemTitle={`${itemRecord.customIdentifier}: ${itemTitle}`} />
        <Grid container item xs={8} justify="space-between">
          <ItemTitle
            itemTitle={itemTitle}
            titlePrefix={`${itemRecord.customIdentifier}`}
            versionId={get(currentRelease, 'id', '')}
            editable={false}
          />
          <div>
            {EffectiveViewOptions && (
              <Blur blur={!hasCurrentRelease} style={{ width: '100%' }}>
                <EffectiveViewOptions
                  pdfExportHeading={compact([
                    itemRecord.customIdentifier,
                    get(itemRecord.currentRelease, 'title'),
                  ]).join(': ')}
                  pdfExportSubheading="Effective View"
                  versionId={get(itemRecord.currentRelease, 'id')}
                />
              </Blur>
            )}
          </div>
          {currentRelease && createNewRecordOptions && (
            <CreateFormRecord
              createNewRecordMutation={createNewRecordOptions && createNewRecordOptions.createNewRecordMutation}
              formIdentifier={itemRecord.customIdentifier}
              formVersionId={currentRelease.id}
              refetchQueries={
                createNewRecordOptions && createNewRecordOptions.refetchQueries
                  ? createNewRecordOptions.refetchQueries
                  : ['formsQuery']
              }
              currentUser={currentUser}
              textForExecutionButton={textForExecutionButton(itemType)}
              recordItemType={recordItemType}
            />
          )}
        </Grid>
      </Grid>

      <EffectiveViewData
        itemType={itemType}
        itemId={itemId}
        blur={!hasCurrentRelease || isRetired || (isRetired && !currentRelease)}
        displayHistoryTable={displayHistoryInEffectiveView}
      />

      {PayloadFields && hasCurrentRelease && !isRetired && (
        <PayloadFields
          key={get(currentRelease, 'id')}
          layoutClasses={classes}
          versionData={currentRelease || currentVersion}
          versionId={get(currentRelease, 'id')}
          displayOnly
          itemRecord={itemRecord}
        />
      )}

      {hasCurrentRelease && <ContextDrawer itemId={itemId || itemRecord.id} itemType={itemType} />}

      <NoReleaseModal
        open={!hasCurrentRelease || isRetired}
        message={isRetired ? retiredMessage : undefined}
        viewToNavTo={isRetired ? 'Versions' : undefined}
        navTo={navToViewLink}
      />
    </Grid>
  );
}

export default withStyles(styles)(EffectiveView);
