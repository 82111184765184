import React from 'react';

import { useVersion } from './gql';
import FormBasedPayloadFields from 'compositions/FormBasedPayloadFields';

const PayloadFields = (props: { versionId: string }) => {
  const { data, loading, error, refetch } = useVersion(props.versionId);
  if (!data || loading) return null;
  if (error) throw new Error(`Error loading Incoming Quality Inspection Version ${props.versionId}\n${error}`);

  return (
    <FormBasedPayloadFields
      versionData={data.incomingQualityInspectionVersion}
      itemTypeName={'incomingQualityInspection'}
      associatedRecordCustomIdPrefix={'IQI-R'}
      onDocumentUploadComplete={refetch}
      refetchQueries={['IncomingQualityInspectionVersion']}
    />
  );
};

export default PayloadFields;
