import { ComponentType } from 'react';

import Build from '@material-ui/icons/Build';
import Domain from '@material-ui/icons/Domain';
import Visibility from '@material-ui/icons/Visibility';
import GridOn from '@material-ui/icons/GridOn';
import Launch from '@material-ui/icons/Launch';
import List from '@material-ui/icons/List';
import Link from '@material-ui/icons/Link';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import Repeat from '@material-ui/icons/Repeat';
import Update from '@material-ui/icons/Update';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import VerticalSplit from '@material-ui/icons/VerticalSplit';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import { t } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';

import LibraryAddCheck from 'assets/icons/LibraryAddCheck';
import Records from 'assets/icons/RecordsIcon';
import SwapHoriz from "@material-ui/icons/SwapHoriz";

class View {
  constructor(public id: string, public label: MessageDescriptor, public icon: ComponentType<SvgIconProps>) {}
}

const Views = {
  Audit: new View('Audit', t`Audit`, VerifiedUser),
  Builder: new View('Builder', t`Builder`, Build),
  Task: new View('Builder', t`Task`, LibraryAddCheck),
  Supplier: new View('Supplier', t`Supplier`, Domain),
  Monitoring: new View('Monitoring', t`Monitoring`, Visibility),
  ChangeOrder: new View('Builder', t`Change Order`, SwapHoriz),
  Export: new View('Export', t`Export`, Launch),
  Table: new View('Table', t`Table`, GridOn),
  Versions: new View('Versions', t`Versions`, Update),
  Effective: new View('Effective', t`Effective`, PlaylistAddCheck),
  LinksGraph: new View('LinksGraph', t`Link`, Link),
  StepTrace: new View('Step Trace', t`Step Trace`, Repeat),
  ReleaseSetBuilder: new View('ReleaseSetBuilder', t`Release Sets`, VerticalSplit),
  Set: new View('Set', t`Set`, List),
  Records: new View('Records', t`Records`, Records),
};

export { View };
export default Views;
