import React, { useState } from 'react';

import flowRight from 'lodash/flowRight';

import { withStyles } from '@material-ui/core';
import KeyboardReturn from '@material-ui/icons/KeyboardReturn';

import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';

import { MentionsInput, Mention } from 'react-mentions';

import styles from './styles';
import useMentionableUsers from 'api/useMentionableUsers';
import { MentionSuggestion } from 'components';

interface CommentInputProps {
  value: string;
  i18n;
  classes;
  onSubmit: (e) => void;
  onChange: (e) => void;
  onKeyDown: (e) => void;
}

function CommentInput(props: CommentInputProps) {
  const [onInputFocus, setInputFocus] = useState(false);
  const { users } = useMentionableUsers();

  const toggleInputFocus = () => setInputFocus(!onInputFocus);

  const { value, i18n, classes, onSubmit, onChange, onKeyDown } = props;

  const inputFocusClass = onInputFocus ? classes.commentInputFocus : '';

  return (
    <div className={classes.commentInputContainer}>
      <MentionsInput
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={i18n._(t`Leave your comment`)}
        className={`${classes.commentInputWrapper} ${inputFocusClass}`}
        value={value}
        onFocus={toggleInputFocus}
        onBlur={toggleInputFocus}
      >
        <Mention
          markup="@[__display__:__id__]"
          trigger="@"
          placeholder={i18n._(t`Type your comment`)}
          data={users}
          renderSuggestion={(suggestion, _search, highlightedDisplay) => (
            <MentionSuggestion userId={suggestion.id}>
              {highlightedDisplay}
            </MentionSuggestion>
          )}
        />
      </MentionsInput>
      <KeyboardReturn className={classes.commentInputIcon} onClick={onSubmit} />
    </div>
  );
}

export default flowRight([withStyles(styles), withI18n()])(CommentInput);
