import React, { ReactNode, useState } from 'react';

interface State {
  expanded: boolean;
  setExpanded: (state: boolean) => void;
}

export const TreeDrawerContext = React.createContext<State>({
  expanded: false,
  setExpanded: (_) => {},
});

export function TreeDrawerContextProvider(props: { children: ReactNode }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <TreeDrawerContext.Provider
      value={{
        expanded: expanded,
        setExpanded: setExpanded,
      }}
    >
      {props.children}
    </TreeDrawerContext.Provider>
  );
}

export const withTreeDrawerContext = (Component) => (props) => (
  <TreeDrawerContextProvider>
    <Component {...props} />
  </TreeDrawerContextProvider>
);
