export default (theme) => ({
  bottomMenu: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  border: {
    height: 2,
    backgroundColor: theme.palette.grey[100],
    width: '100%',
  },
  userMenuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: theme.mixins.sidebar.expandedWidth + theme.spacing(15),
  },
  signOutText: {
    float: 'right',
    paddingRight: theme.spacing(4),
  },
  signOutIcon: {
    transform: 'rotate(180deg)',
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.25),
  },
});
