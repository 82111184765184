import React, { useContext, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { LowPriority, AttachFile, Sort } from '@material-ui/icons';
import { InputRange, ConfirmationDialog } from 'components';
import { LockedContext } from 'contexts/lockedContext';
import { useStyles } from './styles';
import { updateCourseVersionVariables } from '../__generated__/updateCourseVersion';
import Justification from './Justification';
import Panel from 'components/core/Panel';
import AttachmentControl from 'components/AttachmentsSection/AttachmentControl';
import get from 'lodash/get';
import { useMutation } from 'react-apollo';
import { CREATE_SOURCE_FORM_ATTACHMENT, DESTROY_SOURCE_FORM_ATTACHMENT, UPDATE_ASSESSMENT_MATERIAL } from './gql';
import Input from 'components/core/form/Input';
import AssessmentMaterialNotes from './AssessmentMaterialNotes';
import AssessmentGrader from './AssessmentGrader';
import { TrainingFormat } from '__generated__/globalTypes';
import SectionLabel from 'components/core/SectionLabel';

interface Props {
  assessmentGrader: any;
  assessmentMaterialNotes: string;
  attachment: any;
  currentUser: any;
  effectivenessMeasurementRequired?: boolean;
  justification: string;
  save: (variables: updateCourseVersionVariables) => Promise<any>;
  trainingFormat: TrainingFormat;
  versionId: string;
}

const switchValue = {
  0: false,
  1: null,
  2: true,
};

function getSwitchKey(val) {
  return Object.keys(switchValue).find((key) => switchValue[key] === val);
}

function EffectivenessMeasurement(props: Props) {
  const { locked } = useContext(LockedContext);

  const [createSourceFormAttachment] = useMutation(CREATE_SOURCE_FORM_ATTACHMENT);
  const [destroySourceFormAttachment] = useMutation(DESTROY_SOURCE_FORM_ATTACHMENT);
  const [updateAssessmentMaterial] = useMutation(UPDATE_ASSESSMENT_MATERIAL);

  const [selectedEffectivenessMeasurement, setSelectedEffectivenessMeasurement] = useState();

  const effectivenessMeasurementChangeConfirmed = () => {
    // set to undefined first so we don't block the UI/show the modal until the request completes
    const required = selectedEffectivenessMeasurement;
    setSelectedEffectivenessMeasurement(undefined);
    updateEffectivenessMeasurement(required);
  };

  const classes = useStyles();

  const handleSourceFormAttachmentChange = async (_id, url) =>
    createSourceFormAttachment({
      variables: {
        versionId: props.versionId,
        fileURL: url,
      },
      refetchQueries: ['CourseVersionQuery'],
    });

  const handleSourceFormAttachmentDeletion = () =>
    destroySourceFormAttachment({
      variables: {
        id: get(props.attachment, 'id'),
      },
      refetchQueries: ['CourseVersionQuery'],
    });

  const handleAssessmentMaterialUpdate = (updates) =>
    updateAssessmentMaterial({
      variables: {
        id: get(props.attachment, 'id'),
        ...updates,
      },
      refetchQueries: ['CourseVersionQuery'],
    });

  const updateEffectivenessMeasurement = (required) => {
    props
      .save({
        id: props.versionId,
        effectivenessMeasurementRequired: required,
        justification: null,
        assessmentMaterialNotes: null,
        assessmentGraderId: null,
      })
      .then(() => {
        props.attachment && handleSourceFormAttachmentDeletion();
      })
      .catch((e) => {
        throw new Error(`Error occurred while updating the Course: ${e.message}`);
      });
  };

  const inputClassForValue = (value?: boolean) => {
    switch (value) {
      case false:
        return classes.falseStatusSwitcher;
      case true:
        return classes.trueStatusSwitcher;
      default:
        return classes.nullStatusSwitcher;
    }
  };

  const effectivenessMeasurementRequiredChanged = (e) => {
    const value = switchValue[e.target.value];

    if (props.effectivenessMeasurementRequired === null) {
      updateEffectivenessMeasurement(value);
    } else {
      setSelectedEffectivenessMeasurement(value);
    }
  };

  return (
    <>
      <div style={{ display: 'inline-flex' }}>
        <SectionLabel icon={LowPriority}>Effectiveness Measurement</SectionLabel>
        <Typography variant="body2" className={classes.toggleText} style={{ marginTop: 4 }}>
          Is Effectiveness Measurement Required?
          <span style={{ marginLeft: 8, marginTop: 4 }}>
            No{' '}
            <InputRange
              min="0"
              max="2"
              disabled={locked}
              value={getSwitchKey(props.effectivenessMeasurementRequired)}
              className={inputClassForValue(props.effectivenessMeasurementRequired)}
              onChange={effectivenessMeasurementRequiredChanged}
            />{' '}
            Yes
          </span>
        </Typography>
      </div>
      {props.effectivenessMeasurementRequired === false && (
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Justification
              justification={props.justification}
              onChange={(e) =>
                props.save({
                  id: props.versionId,
                  justification: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      )}
      {props.effectivenessMeasurementRequired && (
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Panel>
              <Panel.Heading icon={AttachFile}>Assessment Material</Panel.Heading>
              <Grid container item spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="overline">
                    <AttachFile fontSize="small" />
                    File Name
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="overline">
                    <AttachFile fontSize="small" />
                    Editable Document
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="overline">
                    <Sort fontSize="small" />
                    Description
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <AttachmentControl
                    versionId={props.versionId}
                    locked={locked}
                    attachment={get(props.attachment, 'file')}
                    createAttachment={handleSourceFormAttachmentChange}
                    destroyAttachment={handleSourceFormAttachmentDeletion}
                    singleAttachment
                  />
                </Grid>
                <Grid item xs={3}>
                  <AttachmentControl
                    attachment={get(props.attachment, 'editableDocument')}
                    createAttachment={(_id, url) => handleAssessmentMaterialUpdate({ editableDocument: url })}
                    destroyAttachment={() => handleAssessmentMaterialUpdate({ editableDocument: null })}
                    downloadable
                    locked={locked || !props.attachment}
                    singleAttachment
                    versionId={props.versionId}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Input
                    debounced
                    disabled={!props.attachment}
                    defaultValue={get(props.attachment, 'description')}
                    onChange={(e) => handleAssessmentMaterialUpdate({ description: e.target.value })}
                  />
                </Grid>
                <Grid item xs={1}>
                  {/* can't preview since S3 headers set to force a download */}
                  {/*data.courseVersion.attachment && (
                      <a href={data.courseVersion.attachment.file.url} target="_blank">
                        Preview
                      </a>
                    )*/}
                </Grid>
              </Grid>
            </Panel>
          </Grid>

          <Grid item xs={6}>
            <AssessmentMaterialNotes
              notes={props.assessmentMaterialNotes}
              onChange={(e) =>
                props.save({
                  id: props.versionId,
                  assessmentMaterialNotes: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <AssessmentGrader
              disabled={false}
              selected={props.assessmentGrader}
              currentUser={props.currentUser.profile}
              onChange={(id) => {
                props
                  .save({
                    id: props.versionId,
                    assessmentGraderId: id,
                  })
                  .catch((e) => {
                    throw new Error(`Error occurred while updating assessment grader: ${e.message}`);
                  });
              }}
            />
          </Grid>
        </Grid>
      )}
      <ConfirmationDialog
        onCancel={() => setSelectedEffectivenessMeasurement(undefined)}
        onClose={() => setSelectedEffectivenessMeasurement(undefined)}
        onConfirm={effectivenessMeasurementChangeConfirmed}
        open={selectedEffectivenessMeasurement !== undefined}
        title="Confirm Change"
      >
        Are you sure? This will remove all content and linked items in the section.
      </ConfirmationDialog>
    </>
  );
}

export default EffectivenessMeasurement;
