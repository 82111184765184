import React from 'react';

import { Assignment, Filter1, Notes, School, CalendarToday } from '@material-ui/icons';
import { Typography, Chip, Grid } from '@material-ui/core';

import { navigate } from '@reach/router';
import { DateTime } from 'luxon';

import Panel from 'components/core/Panel';
import SectionLabel from 'components/core/SectionLabel';
import Table from 'components/core/Table';
import { VersionCell } from 'components/VersionTables';

import linkToItem from 'utils/linkToItem';

import { useStyles } from './styles';

interface Props {
  dueDate: any;
  source: any;
}

function TrainingContext(props: Props) {
  const { dueDate, source } = props;

  const classes = useStyles();

  return (
    <>
      <SectionLabel icon={Filter1}>
        Training Context
        <SectionLabel.HelperText>
          These are the items in the system that have created your training.
        </SectionLabel.HelperText>
      </SectionLabel>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <div className={classes.fullHeight}>
            <Panel>
              <Panel.Heading icon={School}>Associated Training Item</Panel.Heading>
              <a
                href={linkToItem(source.item.itemType.displaySlug, source.item.id)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Chip
                  size="small"
                  label={`${source.item.customIdentifier}: ${source.title}`}
                  className={classes.course}
                />
              </a>
              <Panel.Heading icon={CalendarToday}>Due Date</Panel.Heading>
              <Typography variant="caption">
                {dueDate ? DateTime.fromISO(dueDate).toFormat('MMMM dd, yyyy') : ''}
              </Typography>
            </Panel>
          </div>
        </Grid>
        {source.__typename === 'CourseVersion' && (
          <>
            <Grid item xs={7}>
              <div className={classes.fullHeight}>
                <Panel>
                  <Panel.Heading icon={Notes}>Learning Objectives</Panel.Heading>
                  <Typography variant="caption">{source.learningObjective}</Typography>
                </Panel>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.fullHeight}>
                <Panel>
                  <Panel.Heading icon={Assignment}>Associated Quality Documents</Panel.Heading>
                  <Table
                    data={source.qualityDocumentVersions}
                    onRowClick={(_e, version) =>
                      navigate(
                        linkToItem(
                          version.item.itemType.displaySlug,
                          version.item.itemType.userCreatable && version.item.id,
                        ) + '?view=Effective',
                      )
                    }
                    columns={[
                      {
                        title: 'ID',
                        field: 'item.customIdentifier',
                      },
                      {
                        title: 'Title',
                        field: 'title',
                      },
                      {
                        title: 'Version',
                        render: (version) => (
                          <VersionCell version={version.versionIdentifier} status={version.currentStatus.name} />
                        ),
                      },
                    ]}
                  />
                </Panel>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default TrainingContext;
