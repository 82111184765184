import React, { FC, useState } from 'react';

import LinearScale from '@material-ui/icons/LinearScale';

import flowRight from 'lodash/flowRight';

import { AlertDialogWithUser, ContentSection, WorkflowIcon } from 'components';
import WorkflowMenu, { CancelMenuItem, VoidMenuItem } from 'components/WorkflowMenu';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import { ChangeOrderWorkflowStatuses, indexById } from 'workflows/statuses';

import ConnectBar from './ConnectBar';
import PermissionFailModal from './PermissionFailModal';

import WithCurrentUser from '../../WithCurrentUser';
import withMetadataFromServer from '../../WithMetadataFromServer';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

const { Cancel, Draft, UnderReview, ReadyForClose, ReadyForTraining, Closed, Rejected } = ChangeOrderWorkflowStatuses;

const ChangeOrderWorkflowStatusesById = indexById(ChangeOrderWorkflowStatuses);

interface Props {
  currentUser: any;
  currentCompany: any;
  currentCompanyValidatedSystem: any;
  currentCompanyProfessionalLicense: any;
  currentCompanyAllowsTransitioning: any;
  currentVersion: any;
  handleInitTransition: any;
  permissions: any;
}

interface ModalState {
  open: boolean;
  type: string;
}

interface DeniedModalState {
  open: boolean;
  user: object | any;
  role: string | any;
}

const ChangeOrderWorkflow: FC<Props> = (props) => {
  const { currentCompanyAllowsTransitioning, currentVersion, handleInitTransition, permissions } = props;
  const canOwn = permissions.canOwn;
  const canApprove = permissions.canApprove;

  const [modal, setModal] = useState<ModalState>({ open: false, type: '' });
  const [deniedModalWithUser, setDeniedModalWithUser] = useState<DeniedModalState>({
    open: false,
    user: null,
    role: null,
  });

  const inactiveUsers = getInactiveUsers(currentVersion.owner, currentVersion.approver);
  const usersWithoutActiveRole = getUsersWithoutActiveRole(
    currentVersion.owner,
    currentVersion.approver,
    currentVersion.assignedOwnerJobRole,
    currentVersion.assignedApproverJobRole,
  );

  const handleInactiveUserDeniedModal = (inactiveUsers) => {
    setDeniedModalWithUser({
      open: true,
      user: inactiveUsers[0].user,
      role: inactiveUsers[0].role,
    });
  };

  const handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    setDeniedModalWithUser({
      open: true,
      user: usersWithoutActiveRole[0].user,
      role: usersWithoutActiveRole[0].role,
    });
  };

  const workflowControls = () => {
    const { currentCompanyAllowsTransitioning, currentUser, currentVersion, handleInitTransition } = props;
    const currentWorkflowStatus = ChangeOrderWorkflowStatusesById[currentVersion.currentStatusName];

    if (!currentCompanyAllowsTransitioning) return;

    const isCancelable = (status) =>
      canOwn &&
      (status.isEqualTo(Draft) ||
        status.isEqualTo(UnderReview) ||
        status.isEqualTo(ReadyForClose) ||
        status.isEqualTo(Rejected));

    const { signingOwner } = currentVersion;
    const isVoidable = () =>
      signingOwner && signingOwner.id === currentUser.id && currentWorkflowStatus.isEqualTo(ReadyForClose);

    return (
      <>
        <WorkflowMenu>
          <CancelMenuItem
            disabled={!isCancelable(currentWorkflowStatus)}
            onClick={() => handleInitTransition('changeOrderCanceled')}
          />

          <VoidMenuItem disabled={!isVoidable()} onClick={() => handleInitTransition('changeOrderVoided')} />
        </WorkflowMenu>
      </>
    );
  };

  const { currentStatusName, versionIdentifier } = currentVersion;
  const currentWorkflowStatus = ChangeOrderWorkflowStatusesById[currentStatusName];
  const showTrainingStatus = currentVersion.hasImpactedEmployees;

  return (
    <ContentSection LabelText={'Workflow'} Icon={<LinearScale />} Controls={workflowControls()}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <WorkflowIcon
          size="medium"
          type="draft"
          active={currentWorkflowStatus.isEqualTo(Draft)}
          completed={currentWorkflowStatus.isAfter(Draft)}
          label="Draft"
          visible
        />

        <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} visible />

        <WorkflowIcon
          type="underReview"
          active={currentWorkflowStatus.isEqualTo(UnderReview)}
          completed={currentWorkflowStatus.isAfter(UnderReview)}
          disabledEffect={!currentCompanyAllowsTransitioning}
          label="Under Review"
          size="large"
          handleClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            if (inactiveUsers.length) return handleInactiveUserDeniedModal(inactiveUsers);
            if (usersWithoutActiveRole.length) return handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);

            if (currentWorkflowStatus.isBefore(UnderReview) && currentCompanyAllowsTransitioning)
              return handleInitTransition('underReview');
          }}
          visible
        />

        <ConnectBar color="ownerApproval" disabled={!currentCompanyAllowsTransitioning} visible />

        <WorkflowIcon
          size="medium"
          type="ownerApproval"
          active={currentWorkflowStatus.isEqualTo(ReadyForClose)}
          completed={currentWorkflowStatus.isAfter(ReadyForClose)}
          disabledEffect={!currentCompanyAllowsTransitioning}
          label="Ready for Closure"
          handleClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            if (currentWorkflowStatus.isEqualTo(UnderReview) && !canOwn) {
              return setModal({ open: true, type: 'permissions' });
            }
            if (currentWorkflowStatus.isEqualTo(UnderReview) && canOwn) {
              return handleInitTransition('changeOrderClosure');
            }
          }}
          visible
        />

        <ConnectBar
          color="readyForTraining"
          disabled={!currentCompanyAllowsTransitioning}
          visible={showTrainingStatus}
        />

        <WorkflowIcon
          size={'medium'}
          type="readyForTraining"
          active={currentWorkflowStatus.isEqualTo(ReadyForTraining)}
          completed={currentWorkflowStatus.isAfter(ReadyForTraining)}
          disabledEffect={!currentCompanyAllowsTransitioning}
          label="Ready For Training"
          handleClick={() => {
            const isRightStatus = currentWorkflowStatus.isEqualTo(ReadyForClose);

            if (isRightStatus && !canApprove) return setModal({ open: true, type: 'permissions' });

            if (isRightStatus && canApprove) {
              return handleInitTransition('changeOrderTraining');
            }
          }}
          visible={showTrainingStatus}
        />

        <ConnectBar
          color="rejected"
          disabled={!currentCompanyAllowsTransitioning}
          visible={currentWorkflowStatus.isEqualTo(Rejected)}
        />

        <WorkflowIcon
          size={'medium'}
          type="rejected"
          active
          version={versionIdentifier}
          disabledEffect={!currentCompanyAllowsTransitioning}
          label="Rejected"
          handleClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            return handleInitTransition('resolveRejection');
          }}
          visible={currentWorkflowStatus.isEqualTo(Rejected)}
        />

        <ConnectBar
          color="released"
          disabled={!currentCompanyAllowsTransitioning}
          visible={!currentWorkflowStatus.isEqualTo(Rejected)}
        />

        <WorkflowIcon
          size={'medium'}
          type="closed"
          completed={currentWorkflowStatus.isEqualTo(Closed)}
          disabledEffect={!currentCompanyAllowsTransitioning}
          label="Closed"
          handleClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            const isRightStatus =
              currentWorkflowStatus.isEqualTo(ReadyForTraining) || currentWorkflowStatus.isEqualTo(ReadyForClose);

            if (isRightStatus && !canApprove) return setModal({ open: true, type: 'permissions' });

            if (isRightStatus && canApprove) {
              return handleInitTransition('changeOrderClosureApproveOrReject');
            }
          }}
          visible={!currentWorkflowStatus.isEqualTo(Rejected)}
        />

        <ConnectBar color="rejected" visible={currentWorkflowStatus.isEqualTo(Cancel)} disabled />

        <WorkflowIcon
          size={'medium'}
          type="rejected"
          label="Canceled"
          visible={currentWorkflowStatus.isEqualTo(Cancel)}
          completed
        />

        <PermissionFailModal
          open={modal.open}
          failType={modal.type}
          closeModal={() => setModal({ ...modal, open: false })}
        />
        <AlertDialogWithUser
          open={deniedModalWithUser.open}
          titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
          onClose={() => setDeniedModalWithUser({ open: false, user: null, role: null })}
          subtitle={
            inactiveUsers.length
              ? 'The following user has been deactivated.'
              : "The following user's role has been removed."
          }
          user={deniedModalWithUser.user}
          role={
            deniedModalWithUser.role === 'owner'
              ? currentVersion.assignedOwnerJobRole
              : currentVersion.assignedApproverJobRole
          }
          body={`Please use the People Menu to reassign the ${
            deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
          } role to a new user.`}
        />
      </div>
    </ContentSection>
  );
};

export default flowRight(
  WithCurrentUser,
  withCurrentCompany,
  withMetadataFromServer(),
)(ChangeOrderWorkflow);
