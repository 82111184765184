import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as DesignServices } from './design-services.svg';

function DesignServicesIcon(props) {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <DesignServices {...svgProps} />} />;
}

export default DesignServicesIcon;
