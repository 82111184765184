export enum ViewTypes {
  ALL_ITEMS = 'allItems',
  ITEM_VIEW = 'itemView',
  ITEM_ID = 'itemId',
  STATUS = 'status',
  EFFECTIVE = 'effective',
  DRAFT = 'draft',
  IN_PROGRESS = 'inProgress',
  PRODUCT_CATEGORY = 'productCategory',
}

export enum StatusTypes {
  APPROVED_DRAFT = 'approved_draft',
  APPROVE_TO_PROCEED = 'approve_to_proceed',
  CHECKED = 'checked',
  CLOSED = 'closed',
  COMPLETED = 'completed',
  CREATED = 'created',
  DRAFT = 'draft',
  IDENTIFY = 'identify',
  INVESTIGATE = 'investigate',
  IN_PROGRESS = 'in_progress',
  OWNER_APPROVAL = 'owner_approval',
  PREPARED = 'prepared',
  PREQUALIFICATION_COMPLETE = 'prequalification_complete',
  READY_FOR_CLOSURE = 'ready_for_closure',
  READY_FOR_REVIEW = 'ready_for_review',
  READY_FOR_PREQUALIFICATION = 'ready_for_prequalification',
  READY_FOR_TRAINING = 'ready_for_training',
  READY_FOR_QUALIFICATION = 'ready_for_qualification',
  RELEASED = 'released',
  RELEASED_FOR_CO_TRAINING = 'released_for_co_training',
  SEMI_LOCKED = 'semi_locked',
  SUPPLIER_APPROVED = 'supplier_approved',
  SUPPLIER_REQUESTED = 'supplier_requested',
  TO_DO = 'to_do',
  UNLOCKED = 'unlocked',
  UNDER_REVIEW = 'under_review',
}

export const statusesForEffective = [
  StatusTypes.RELEASED,
  StatusTypes.CLOSED,
  StatusTypes.SUPPLIER_APPROVED,
  StatusTypes.CHECKED,
];

export const statusesForDraft = [
  StatusTypes.DRAFT,
  StatusTypes.IDENTIFY,
  StatusTypes.UNLOCKED,
  StatusTypes.SUPPLIER_REQUESTED,
  StatusTypes.CREATED,
  StatusTypes.TO_DO,
];

export const statusesForInProgress = [
  StatusTypes.DRAFT,
  StatusTypes.UNDER_REVIEW,
  StatusTypes.APPROVED_DRAFT,
  StatusTypes.IDENTIFY,
  StatusTypes.INVESTIGATE,
  StatusTypes.APPROVE_TO_PROCEED,
  StatusTypes.UNLOCKED,
  StatusTypes.SEMI_LOCKED,
  StatusTypes.SUPPLIER_REQUESTED,
  StatusTypes.READY_FOR_PREQUALIFICATION,
  StatusTypes.PREQUALIFICATION_COMPLETE,
  StatusTypes.READY_FOR_QUALIFICATION,
  StatusTypes.READY_FOR_REVIEW,
  StatusTypes.OWNER_APPROVAL,
  StatusTypes.CREATED,
  StatusTypes.IN_PROGRESS,
  StatusTypes.READY_FOR_CLOSURE,
  StatusTypes.READY_FOR_TRAINING,
  StatusTypes.RELEASED_FOR_CO_TRAINING,
  StatusTypes.PREPARED,
  StatusTypes.TO_DO,
  StatusTypes.COMPLETED,
];
