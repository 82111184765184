import React from 'react';
import startCase from 'lodash/startCase';

import { TableCell, TableBody, TableHead, TableRow, Table } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import MakeLink from 'components/MakeLink';
import Panel from 'components/core/Panel';
import StatusDot from 'components/StatusDot';
import VersionAndStatusDot from 'components/VersionAndStatusDot';
import { BooleanResultCell } from 'components/TableView/Fields/BooleanResult';

import AddRecord from '../AddRecord';
import RemoveRecord from '../RemoveRecord';
import { useStyles } from '../styles';

interface ValidationProtocolsProps {
  associatedValidationRecords;
  currentVersion;
  validationProtocols;
}

const ValidationProtocolsTable = (props: ValidationProtocolsProps) => {
  const { associatedValidationRecords, currentVersion, validationProtocols } = props;
  const classes = useStyles();

  const compareRecords = function(validationProtocol) {
    const matchingRecords: any[] = [];
    validationProtocol.validationRecords.forEach((recFromProtocol) => {
      associatedValidationRecords.forEach((associatedRecord) => {
        if (recFromProtocol.id === associatedRecord.id) {
          matchingRecords.push(recFromProtocol);
        }
      });
    });

    return matchingRecords;
  };

  return (
    <Panel>
      <Panel.Heading icon={AccountCircle}>Validation Protocols from V&V Plan</Panel.Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>ID</TableCell>
            <TableCell className={classes.tableHeader}>Current Title</TableCell>
            <TableCell className={classes.tableHeader}>
              Current
              <br />
              Version
            </TableCell>
            <TableCell align="center" className={classes.tableHeader}>
              {''}
            </TableCell>
            <TableCell className={`${classes.tableHeader} ${classes.noWrap}`}>
              Associated
              <br />
              Record
            </TableCell>
            <TableCell className={classes.tableHeader}>
              Origination
              <br />
              Protocol Version #
            </TableCell>
            <TableCell className={classes.tableHeader}>Record Status</TableCell>
            <TableCell className={classes.tableHeader}>Test Result</TableCell>
            <TableCell className={`${classes.tableHeader} ${classes.noWrap}`}>Deviations</TableCell>
            <TableCell className={classes.tableHeader}>{''}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {validationProtocols.map((valProtocol) => {
            return (
              <>
                <TableRow key={valProtocol.item.customIdentifier} id={valProtocol.id} className={classes.tableRow}>
                  <TableCell scope="row" className={classes.tableCell} rowSpan={compareRecords(valProtocol).length + 1}>
                    <MakeLink item={valProtocol.item} newTab>
                      {valProtocol.item.customIdentifier}
                    </MakeLink>
                  </TableCell>
                  <TableCell
                    scope="row"
                    size="medium"
                    className={classes.tableCell}
                    rowSpan={compareRecords(valProtocol).length + 1}
                  >
                    {valProtocol.item.currentVersion.title}
                  </TableCell>
                  <TableCell
                    scope="row"
                    size="small"
                    className={classes.tableCell}
                    rowSpan={compareRecords(valProtocol).length + 1}
                  >
                    <VersionAndStatusDot
                      style={{ fontSize: 13 }}
                      version={valProtocol.item.currentVersion.versionIdentifier}
                      status={valProtocol.item.currentVersion.currentStatus.name}
                    />
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                    size="small"
                    className={classes.tableCell}
                    rowSpan={compareRecords(valProtocol).length + 1}
                  >
                    <AddRecord
                      currentVersion={currentVersion}
                      itemType="validation_record"
                      protocol={valProtocol}
                      records={valProtocol.validationRecords}
                    />
                  </TableCell>
                </TableRow>
                {associatedValidationRecords.map((valRecord) => {
                  if (valRecord.deviceValidationVersion.id !== valProtocol.item.currentVersion.id) return null;
                  return (
                    <>
                      <TableRow className={classes.tableRow} key={valRecord.item.customIdentifier}>
                        <TableCell className={`${classes.tableCell} ${classes.recordCell}`}>
                          <MakeLink item={valRecord.item} newTab>
                            {valRecord.item.customIdentifier}
                          </MakeLink>
                        </TableCell>
                        <TableCell size="small" className={classes.tableCell}>
                          {valRecord.versionIdentifier}
                        </TableCell>
                        <TableCell size="small" className={classes.tableCell}>
                          <span>
                            <StatusDot
                              status={valRecord.currentStatus.name}
                              style={{ marginRight: 10, display: 'inline-block' }}
                            />
                            {startCase(valRecord.currentStatus.name)}
                          </span>
                        </TableCell>
                        <TableCell size="small" className={classes.tableCell}>
                          <BooleanResultCell data={valRecord.isFulfillAllAcceptanceCriteria} />
                        </TableCell>
                        <TableCell size="small" className={classes.tableCell}>
                          {valRecord.deviationVersions.length
                            ? valRecord.deviationVersions.map((deviation) => deviation.item.customIdentifier).join(', ')
                            : 'n/a'}
                        </TableCell>
                        <TableCell size="small" className={classes.tableCell}>
                          <RemoveRecord
                            record={valRecord}
                            itemType="validation_record"
                            currentVersion={currentVersion}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </>
            );
          })}
        </TableBody>
      </Table>
    </Panel>
  );
};

export { ValidationProtocolsTable };
