import React, { useContext } from 'react';

import { Notes } from '@material-ui/icons';

import { useMutation } from 'react-apollo';

import { ToggleRichTextEditor, Spacer, LabelText, CollapsableTextArea } from 'components';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import { LockedContext } from '../../../../../contexts/lockedContext';
import { UPDATE_VERIFICATION_AND_VALIDATION_REPORT_VERSION } from '../../gql';

interface ReportTabProps {
  versionId: string;
  fields: {
    purpose: string | null;
    scope: string | null;
    intendedUse: string | null;
    systemDescription: string | null;
    summaryOfResults: string | null;
    conclusion: string | null;
    reportSummary: string | null;
  };
  richTextToggled: boolean;
}

const ReportTab = (props: ReportTabProps) => {
  const { versionId, fields, richTextToggled } = props;

  const [updateReport] = useMutation(UPDATE_VERIFICATION_AND_VALIDATION_REPORT_VERSION, {
    refetchQueries: ['VerificationAndValidationReportVersionQuery'],
  });

  const onConfirm = () => {
    const variables = { id: versionId, reportToggle: !richTextToggled };
    updateReport({ variables }).catch((error) => {
      throw new Error(`Failed to toggle input method: ${error.message}`);
    });
  };

  const { locked } = useContext(LockedContext);

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        {!locked && <ToggleRichTextEditor richTextEditorVisible={props.richTextToggled} onConfirm={onConfirm} />}
      </div>
      <Spacer factor={1} />
      {props.richTextToggled ? (
        <>
          <LabelText labelText="Report" Icon={Notes} />
          <Spacer factor={1} />
          <RichTextEditorContentSection
            locked={locked}
            initialValue={fields.reportSummary || ''}
            fixedToolbarOffset={10}
            versionId={versionId}
            attrName="report_summary"
            refetchQueries={[]}
            optionSet="large"
          />
        </>
      ) : (
        <>
          <CollapsableTextArea
            attrName="scope"
            id="device-verification-scope-text-field"
            initialValue={fields.scope || ''}
            labelText="Scope"
            locked={locked}
            refetchQueries={['VerificationAndValidationReportVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="purpose"
            id="device-verification-purpose-text-field"
            initialValue={fields.purpose || ''}
            labelText="Purpose"
            locked={locked}
            refetchQueries={['VerificationAndValidationReportVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="intended_use"
            id="device-verification-intended-use-text-field"
            initialValue={fields.intendedUse || ''}
            labelText="Intended use"
            locked={locked}
            refetchQueries={['VerificationAndValidationReportVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="system_description"
            id="device-verification-system-description-text-field"
            initialValue={fields.systemDescription || ''}
            labelText="System Description"
            locked={locked}
            refetchQueries={['VerificationAndValidationReportVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="summary_of_results"
            id="device-verification-summary-of-results-text-field"
            initialValue={fields.summaryOfResults || ''}
            labelText="Summary of results"
            locked={locked}
            refetchQueries={['VerificationAndValidationReportVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="conclusion"
            id="device-verification-conclusion-text-field"
            initialValue={fields.conclusion || ''}
            labelText="Conclusion"
            locked={locked}
            refetchQueries={['VerificationAndValidationReportVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
        </>
      )}
    </>
  );
};

export { ReportTab };
