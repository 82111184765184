import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';

const mention = /(@\[[^@]*:\d+\])/g;
const exactMention = /^@\[[^@]*:\d+\]$/g;
const displayName = /^@\[([^@]*):\d+\]$/g;

function TextWithMentions(props: { content: string; theme }) {
  return (
    <>
      {props.content.split(mention).map((part, ix) =>
        part.match(exactMention) ? (
          <span
            key={`${btoa(part)}-${ix}`}
            style={{
              fontWeight: props.theme.typography.fontWeightBold,
              display: 'inline',
            }}
          >
            {part.replace(displayName, '@$1')}
          </span>
        ) : (
          <Fragment key={`${btoa(part)}-${ix}`}>{part}</Fragment>
        ),
      )}
    </>
  );
}

export default withStyles({}, { withTheme: true })(TextWithMentions);
