import gql from 'graphql-tag';

export const RISK_PRIORITY_CODES = gql`
  query riskPriorityCodes {
    riskPriorityCodes {
      id
      name
      fullName
      color
      updatedAt
      createdAt
    }
  }
`;

export const UPDATE_RISK_PRIORITY_CODES = gql`
  mutation updateRiskPriorityCodes($input: UpdateRiskPriorityCodeInput!) {
    updateRiskPriorityCode(input: $input) {
      riskPriorityCodes {
        id
        name
        fullName
        color
        updatedAt
        createdAt
      }
    }
  }
`;

export const RISK_PRIORITY_CODE_FRAGMENT = gql`
  fragment riskMatrixEntryFragment on RiskMatrixEntry {
    id
    harmSeverityLevel {
      id
      level
      name
      color
    }
    hazardousSituationProbabilityLevel {
      id
      name
      level
      color
    }
    riskPriorityCode {
      id
      name
      fullName
      color
      updatedAt
      createdAt
    }
  }
`;

export const RISK_MATRIX_ENTRIES = gql`
  query riskMatrixEntries {
    riskMatrixEntries {
      ...riskMatrixEntryFragment
    }
  }
  ${RISK_PRIORITY_CODE_FRAGMENT}
`;

export const UPDATE_RISK_MATRIX_ENTRIES = gql`
  mutation updateRiskMatrixEntries($input: UpdateRiskMatrixEntryInput!) {
    updateRiskMatrixEntry(input: $input) {
      riskMatrixEntries {
        ...riskMatrixEntryFragment
      }
    }
  }
  ${RISK_PRIORITY_CODE_FRAGMENT}
`;
