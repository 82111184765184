import React from 'react';
import WorkflowIcon from 'components/WorkflowIcon';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import RecordEdit from 'assets/icons/RecordEdit';

function StatusChange({ from, to }) {
  return (
    <>
      <WorkflowIcon type={from} size="small" />
      <ArrowRightAlt style={{ fontSize: 32 }} />
      <WorkflowIcon type={to} size="small" />
    </>
  );
}

function ItemHistoryIcon({ typeOfChange, fromStatus, toStatus }) {
  switch (typeOfChange) {
    case 'status_change':
      return <StatusChange from={fromStatus} to={toStatus} />;
    case 'record_edit':
      return <RecordEdit style={{ borderRadius: '50%', width: 30, height: 30 }} />;
    default:
      return null;
  }
}

export default ItemHistoryIcon;
