import React from 'react';

import TableTrio from 'components/TableTrio';
import {
  useAddReferencedAttachment,
  useAddReferencedItem,
  useAddReferencedVersion,
  useRemoveReferencedAttachment,
  useRemoveReferencedItem,
  useRemoveReferencedVersion,
  useUpdateReferencedAttachment,
  useUpdateReferencedVersion
} from 'components/TableTrio/gql';


function ReferencesTab(props) {
  const { currentVersion, locked } = props;
  const createAttachment = useAddReferencedAttachment();
  const updateAttachment = useUpdateReferencedAttachment();
  const destroyAttachment = useRemoveReferencedAttachment();

  const addAssociatedItem = useAddReferencedItem();
  const removeAssociatedItem = useRemoveReferencedItem();

  const addAssociatedItemVersion = useAddReferencedVersion();
  const removeAssociatedItemVersion = useRemoveReferencedVersion();
  const updateAssociatedItemVersion = useUpdateReferencedVersion();

  const refetchQueries = ['SupplierAuditReportVersionQuery'];

  return (
    <TableTrio
      currentVersion={currentVersion}
      locked={locked}
      attachmentsTableProps={{
        handlers: {
          create: (versionId, attachment) => {
            return createAttachment({
              refetchQueries,
              variables: { versionId, attachment }
            });
          },
          update: (attachmentId, attachment) => {
            return updateAttachment({
              refetchQueries,
              variables: {
                id: attachmentId,
                attachment: attachment.url,
                description: attachment.description
              }
            });
          },
          destroy: attachmentId => {
            return destroyAttachment({
              refetchQueries,
              variables: { id: attachmentId }
            });
          }
        }
      }}
      itemsTableProps={{
        handlers: {
          remove: (id: string) => {
            return removeAssociatedItem({
              refetchQueries,
              variables: {
                versionId: currentVersion.id,
                referencedItemId: id
              }
            });
          },
          addAssociatedItem: (id: string) => {
            return addAssociatedItem({
              refetchQueries,
              variables: {
                versionId: currentVersion.id,
                referencedItemIds: id
              }
            });
          }
        }
      }}
      versionsTableProps={{
        handlers: {
          removeAssociatedItemVersion: (id: string) => {
            return removeAssociatedItemVersion({
              refetchQueries,
              variables: {
                versionId: currentVersion.id,
                referencedVersionId: id
              }
            });
          },
          updateAssociatedItemVersion: (next, old) => {
            return updateAssociatedItemVersion({
              refetchQueries,
              variables: {
                nextVersionId: next,
                oldVersionId: old,
                versionId: currentVersion.id,
              }
            });
          },
          addAssociatedItemVersion: (versionId: string) => {
            return addAssociatedItemVersion({
              refetchQueries,
              variables: {
                versionId: currentVersion.id,
                referencedVersionIds: versionId
              }
            });
          }
        }
      }}
    />
  );
}

export default ReferencesTab;
