import React, { useState } from 'react';
import { Button, Modal, Paper, Fab, Typography, withStyles } from '@material-ui/core';
import flowRight from 'lodash/flowRight';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import LinearScale from '@material-ui/icons/LinearScale';
import { Trans } from '@lingui/macro';

import { Spacer, ContentSection } from 'components';
import { SetItemMemberWorkflowStatuses, indexById } from 'workflows/statuses';
import { SummaryOfChange } from '../steps/views';
import WorkflowIcon from 'components/WorkflowIcon';
import withMetadataFromServer from '../../WithMetadataFromServer';
import PreviousReleases from './PreviousReleases';
import SmallCircle from './SmallCircle';
import ConnectBar from './ConnectBar';
import styles from './sharedStyles';

const { NotCreated, Unlocked, SemiLocked, Locked, Released, Obsolete } = SetItemMemberWorkflowStatuses;

const SetItemMemberWorkflowStatusesById = indexById(SetItemMemberWorkflowStatuses);

function rfcContent({ reasonForChange }) {
  return reasonForChange ? 'RFC' : <Add />;
}

function SetItemMemberWorkflow(props) {
  const [workflowState, setWorkflowState] = useState({
    showPermissionsModal: false,
    showPreviousVersionModal: false,
    versionForModal: null,
  });
  const { currentVersion, releasedVersions, classes } = props;
  const { currentStatusName, versionIdentifier } = currentVersion;
  const currentWorkflowStep = SetItemMemberWorkflowStatusesById[currentStatusName];

  if (!currentWorkflowStep) throw new Error(`Invalid status for SetItemMember Workflow: ${currentStatusName}`);

  const getTextValueForSmallCircle = (currentVersion) => {
    if (currentVersion.__typename === 'RiskVersion' || currentVersion.__typename === 'RiskControlVersion') {
      if (currentVersion.setMember) return 'RAN';
      else return '?';
    }
    if (currentVersion.setMember) return 'SET';
    else return '?';
  };
  return (
    <ContentSection
      LabelText={<Trans>Workflow</Trans>}
      Icon={<LinearScale />}
      classes={{ label: classes.timelineHeader }}
    >
      <div className={classes.timelineContainer}>
        <PreviousReleases
          releasedVersions={releasedVersions}
          generateSmallCircleContent={rfcContent}
          onClick={(v) => {
            setWorkflowState({
              showPermissionsModal: false,
              showPreviousVersionModal: true,
              versionForModal: v,
            });
          }}
        />

        <SmallCircle
          content={
            currentWorkflowStep.isEqualTo(Obsolete) || currentWorkflowStep.isEqualTo(Released) ? (
              <Add />
            ) : (
              rfcContent(currentVersion)
            )
          }
          color="draft"
          id={currentWorkflowStep.isEqualTo(NotCreated) && 'create-new-rev'}
          handleClick={() => {
            if (currentWorkflowStep.isEqualTo(Unlocked)) {
              props.handleInitTransition('reasonForChange');
            } else if (
              currentWorkflowStep.isEqualTo(NotCreated) ||
              currentWorkflowStep.isEqualTo(Obsolete) ||
              currentWorkflowStep.isEqualTo(Released)
            ) {
              props.handleInitTransition('createNewVersion');
            } else {
              props.handleInitTransition('summaryOfChange');
            }
          }}
        />

        {currentWorkflowStep.isAfter(NotCreated) &&
          !currentWorkflowStep.isEqualTo(Obsolete) &&
          !currentWorkflowStep.isEqualTo(Released) && (
            <>
              <ConnectBar color="draft" short visible />

              <WorkflowIcon
                type="unlocked"
                active={currentWorkflowStep.isEqualTo(Unlocked)}
                completed={currentWorkflowStep.isAfter(Unlocked)}
                version={versionIdentifier}
                label="Unlocked"
                size="large"
                visible
              />

              <ConnectBar color="underReview" short disabled={currentWorkflowStep.isBefore(SemiLocked)} visible />
              <SmallCircle
                content={getTextValueForSmallCircle(currentVersion)}
                handleClick={() => {
                  if (props.currentVersion.setMember) {
                    props.handleInitTransition('associatedSet');
                  } else {
                    props.handleInitTransition('addToSet');
                  }
                }}
                color="underReview"
                visible
              />

              <ConnectBar color="underReview" disabled={currentWorkflowStep.isBefore(SemiLocked)} short visible />

              <WorkflowIcon
                type="semiLocked"
                active={currentWorkflowStep.isEqualTo(SemiLocked)}
                completed={currentWorkflowStep.isAfter(SemiLocked)}
                version={versionIdentifier}
                label="Semi-locked"
                size="large"
                disabledEffect={currentWorkflowStep.isBefore(SemiLocked)}
                handleClick={() => {
                  if (currentWorkflowStep.isBefore(SemiLocked))
                    setWorkflowState({
                      showPermissionsModal: true,
                      showPreviousVersionModal: false,
                      versionForModal: null,
                    });
                }}
                visible
              />

              <ConnectBar color="ownerApproval" disabled={currentWorkflowStep.isBefore(SemiLocked)} visible />

              <WorkflowIcon
                type="locked"
                active={currentWorkflowStep.isEqualTo(Locked)}
                completed={currentWorkflowStep.isAfter(Locked)}
                version={versionIdentifier}
                label="Locked"
                size="large"
                disabledEffect={currentWorkflowStep.isBefore(Locked)}
                handleClick={() => {
                  if (currentWorkflowStep.isBefore(Locked))
                    setWorkflowState({
                      showPermissionsModal: true,
                      showPreviousVersionModal: false,
                      versionForModal: null,
                    });
                }}
                visible
              />

              <ConnectBar color="released" disabled={currentWorkflowStep.isBefore(SemiLocked)} visible />

              <WorkflowIcon
                type="released"
                active={currentWorkflowStep.isEqualTo(Released)}
                completed={currentWorkflowStep.isAfter(Released)}
                version={versionIdentifier}
                label="Released"
                size="large"
                disabledEffect={currentWorkflowStep.isBefore(Released)}
                visible
                handleClick={() => {
                  if (currentWorkflowStep.isBefore(Released))
                    setWorkflowState({
                      showPermissionsModal: true,
                      showPreviousVersionModal: false,
                      versionForModal: null,
                    });
                }}
              />
            </>
          )}

        {releasedVersions && (
          <Modal
            open={workflowState.showPreviousVersionModal}
            onClose={() =>
              setWorkflowState({
                showPermissionsModal: false,
                showPreviousVersionModal: false,
                versionForModal: null,
              })
            }
            className={classes.infoModal}
          >
            <Paper className={classes.modalInside}>
              <Close
                className={classes.closeIcon}
                viewBox="-4 4 24 24"
                onClick={() =>
                  setWorkflowState({
                    showPermissionsModal: false,
                    showPreviousVersionModal: false,
                    versionForModal: null,
                  })
                }
                color="action"
              />
              <SummaryOfChange currentVersion={workflowState.versionForModal} displayChangeInfo={false} />
            </Paper>
          </Modal>
        )}

        <Modal
          open={workflowState.showPermissionsModal}
          onClose={() =>
            setWorkflowState({
              showPermissionsModal: false,
              showPreviousVersionModal: false,
              versionForModal: null,
            })
          }
          className={classes.infoModal}
        >
          <Paper className={classes.permissionsContent}>
            <Fab className={classes.errorIcon} size="large" variant="round" disabled>
              !
            </Fab>
            <Typography variant="h3">
              <Trans>Status Change Not Permitted</Trans>
            </Typography>

            <Spacer factor={2} />
            <Typography variant="body1" className={classes.permissionSubtitle}>
              <Trans>This item can only be transitioned through its associated set</Trans>
            </Typography>
            <Spacer factor={2} />
            <Button
              color="primary"
              className={classes.permissionBack}
              variant="outlined"
              onClick={() =>
                setWorkflowState({
                  showPermissionsModal: false,
                  showPreviousVersionModal: false,
                  versionForModal: null,
                })
              }
            >
              <Trans>Dismiss</Trans>
            </Button>
          </Paper>
        </Modal>
      </div>
    </ContentSection>
  );
}

export default flowRight([withStyles(styles), withMetadataFromServer()])(SetItemMemberWorkflow);
