import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    autocomplete: {
      marginTop: 10,
      width: '90%',
    },
    supplierLink: {
      '&:hover': {
        textDecoration: 'underline',
      },
      cursor: 'pointer',
    },
    option: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  }),
);
