import React, { useContext } from 'react';

import SortIcon from '@material-ui/icons/Sort';

import { LockedContext } from 'contexts/lockedContext';

import Input from 'components/core/form/Input';
import Panel from 'components/core/Panel';

interface Props {
  notes: string;
  onChange: (e) => void;
}

function AssessmentMaterialNotes(props: Props) {
  const { locked } = useContext(LockedContext);

  return (
    <Panel>
      <Panel.Heading icon={SortIcon}>Assessment Material Notes</Panel.Heading>
      <Input
        debounced
        defaultValue={props.notes}
        disabled={locked}
        onChange={props.onChange}
        rows={4}
        multiline
        placeholder="Enter assessment material notes"
      />
    </Panel>
  );
}

export default AssessmentMaterialNotes;
