import React, { useState } from 'react';

import Modal from 'components/WorkflowModal';
import ModalContainer from 'components/WorkflowModalContainer';
import YesOrNo from 'components/YesOrNo';

interface Props {
  recordIdent: string;
  addDeviation: () => Promise<any>;
}

export default function AddDeviation(props: Props) {
  const [showModal, setShowModal] = useState(true);
  const { recordIdent, addDeviation } = props;

  const head = `Would you like to create a deviation and add it to ${recordIdent}?`;
  const onClose = () => setShowModal(false);

  return (
    <>
      <Modal
        open={showModal}
        onClose={onClose}
        style={{
          top: '50%',
          transform: 'translate(0, -25%)',
        }}
      >
        <ModalContainer showBackButton={false} onBack={onClose} onClose={onClose}>
          <YesOrNo
            disableOnSubmit={true}
            handleComplete={() => addDeviation().then(() => setShowModal(false))}
            handleClose={() => setShowModal(false)}
            heading={head}
            noLabel="cancel"
            yesLabel="yes"
          />
        </ModalContainer>
      </Modal>
    </>
  );
}
