import React from 'react';

import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { useStyles } from './styles';

// ref is a function passed from react-autosuggest
const SearchInput = React.forwardRef((props, ref) => {
  const styles = useStyles();
  const { disabled, onClear, ...textFieldProps } = props;

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={onClear} disabled={disabled}>
        {props.value ? (
          <CancelIcon fontSize="small" className={styles.cancelIcon} />
        ) : (
          <ArrowDropDown fontSize="small" />
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <TextField
      {...textFieldProps}
      disabled={disabled}
      InputProps={{
        classes: { root: styles.cssOutlinedInput, focused: styles.cssFocused, notchedOutline: styles.notchedOutline },
        inputRef: (node) => ref(node),
        className: styles.textField,
        endAdornment: endAdornment,
      }}
    />
  );
});

export default SearchInput;
