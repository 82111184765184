import React from 'react';
import { YesOrNo } from 'compositions/TimelineGroup/steps/views';
import { buildActionMessage } from './genericWorkflowTransitionsUtil';
import { GenericWorkflowStatuses } from 'workflows/statuses';

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => (
        <YesOrNo
          key={props.error ? btoa(props.error) : 'yes-or-no'}
          disableYes={props.error}
          disableOnSubmit
          prompt={buildActionMessage('disable editing for', props.currentVersion, props.itemCustomIdentifier)}
          closeOnNo
          yesLabel="Lock"
          noLabel="Cancel"
          {...props}
        />
      ),
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ confirmTransition }) => {
    return confirmTransition
      ? {
          toStatus: GenericWorkflowStatuses.UnderReview.id,
        }
      : null;
  },
};
