import PermDataSetting from '@material-ui/icons/PermDataSetting';

import gql from 'graphql-tag';
import { t } from '@lingui/macro';

import { docMetadata } from '../mixins/Document';
import { docStylePayloadFields } from '../mixins/DocumentBased/gql';
import { QueryBasedPayloadFields } from '../mixins/DocumentBased/QueryBasedPayloadFields';
import slugs from '../../displaySlugs';

const versionQuery = gql`
  query failureModesAndEffectsAnalysisVersion($id: ID!) {
    failureModesAndEffectsAnalysisVersion(id: $id) {
      ${docStylePayloadFields}
    }
  }
`;

const itemType = 'failureModesAndEffectsAnalysis';

const PayloadFields = QueryBasedPayloadFields(versionQuery, itemType);

export default {
  categoryLabel: t`FMEA`,
  displaySlug: slugs[itemType],
  label: t`FMEA`,
  listViewQuery: gql`
    query failureModesAndEffectsAnalysesQuery {
      failureModesAndEffectsAnalyses {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          versionIdentifier
          currentStatusName
        }
      }
    }
  `,
  itemType,
  icon: PermDataSetting,
  ...docMetadata,
  PayloadFields,
};
