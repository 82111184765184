import React, { useEffect, useState } from 'react';

import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { EventNote, ArrowDropDown, Person } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { Loading, UserAvatar } from 'components';
import Panel from 'components/core/Panel';
import { WithAllUsers } from 'compositions';
import { displayUserRoles } from 'components/SelectUserField/util';

import { useUpdateTask, useVersion } from '../gql';
import { TaskQuery_taskVersion } from '../__generated__/TaskQuery';

import { useStyles } from './styles';

interface Props {
  taskVersion: TaskQuery_taskVersion;
  layoutClasses: any;
  allUsers: any;
}

const renderOption = (option) => {
  return (
    <>
      <UserAvatar userId={option.id} diameter={24} />
      <div style={{ marginLeft: 8 }}>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {option.fullName}
        </Typography>
        <Typography variant="body2">{displayUserRoles(option.jobRoles)}</Typography>
      </div>
    </>
  );
};

function AssignedPersonAndDueDateSection(props: Props) {
  const { taskVersion, layoutClasses, allUsers } = props;
  const versionId = taskVersion.id;
  const { data, loading, error } = useVersion(versionId!);
  const [update] = useUpdateTask();
  const classes = useStyles();

  const [userValue, setUserValue] = useState<{
    id: string;
    fullName: string;
    status?: string;
    jobRoles?: [any];
  } | null>(null);

  useEffect(() => {
    if (data) {
      setUserValue(data.taskVersion.assignedPerson);
    }
  }, [data]);

  if (error) throw new Error(`Error loading Task ${versionId}\n${error}`);
  if (loading || !data) return <Loading />;

  const onDateChange = (e: any) => {
    update({
      variables: {
        id: taskVersion.id,
        dueDate: e.target.value,
      },
    }).catch((e) => {
      throw new Error(e);
    });
  };

  const onUserChange = (_, selectedOption) => {
    update({
      variables: {
        id: taskVersion.id,
        assignedPersonId: selectedOption ? selectedOption.id : null,
      },
    }).catch((e) => {
      throw new Error(e);
    });
  };

  const locked = data.taskVersion.locked;

  return (
    <Grid container xs={8} className={layoutClasses.gridGutterRight}>
      <Panel className={classes.panel}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={6}>
            <Panel.Heading icon={Person}>Assigned to</Panel.Heading>
            <div>
              <Autocomplete
                options={allUsers}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    classes={{
                      root: classes.autocompleteInputRoot,
                    }}
                    fullWidth
                    variant="outlined"
                    margin="none"
                    placeholder="Search Users"
                  />
                )}
                value={userValue}
                onChange={onUserChange}
                getOptionLabel={(option: any) => option.fullName}
                renderOption={renderOption}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Panel.Heading icon={EventNote}>Due Date</Panel.Heading>
            <div>
              <TextField
                variant="outlined"
                id="dueDate"
                type="date"
                className={classes.textField}
                defaultValue={taskVersion.dueDate}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.datePickerInputAdornment}>
                      <ArrowDropDown fontSize="small" />
                    </InputAdornment>
                  ),
                  classes: {
                    input: classes.input,
                    root: classes.cssOutlinedInput,
                    notchedOutline: classes.notchedOutline,
                  },
                  className: classes.textField,
                }}
                onChange={onDateChange}
                disabled={locked}
              />
            </div>
          </Grid>
        </Grid>
      </Panel>
    </Grid>
  );
}

export default WithAllUsers(AssignedPersonAndDueDateSection);
