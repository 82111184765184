import React from 'react';

import { useStyles } from './styles';

const defaultColor = '#c7c7c7';
const defaultWidth = 1;

function Edge({ color, width }) {
  const classes = useStyles(),
    borderBottom = `${width || defaultWidth}px solid ${color || defaultColor}`;

  return (
    <div className={classes.container}>
      <div className={classes.edge} style={{ borderBottom }} />
      <div className={classes.edge} />
    </div>
  );
}

export default Edge;
