import gql from 'graphql-tag';

export const riskControlEffectivenessTestsQuery = gql`
  query RiskControlEffectivenessTestsQuery {
    riskControlEffectivenessTests {
      riskControlEffectivenessTest {
        ... on DeviceValidationVersion {
          id
          title
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
        ... on DeviceVerificationVersion {
          id
          title
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
      riskAnalysisVersion {
        id
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      riskControlVersion {
        id
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
    }
  }
`;
