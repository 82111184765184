import React, { useMemo, useState } from 'react';
import { useLazyQuery } from 'react-apollo';

import { Box, Chip, Grid, Typography } from '@material-ui/core';
import {
  BrokenImage,
  DirectionsRun,
  FindReplace,
  FlashOn,
  Grain,
  Healing,
  Report,
  ReportProblem,
} from '@material-ui/icons';

import { get, isUndefined } from 'lodash';

import { EnhancedAutocomplete, TextAreaField, TriStateToggle } from 'components';
import RiskLevelSelect from 'components/RiskLevelSelect';
import { useDebounce } from 'hooks/useDebounce';
import ChipSelectOptionRender from 'pages/RiskAnalysis/Id/PayloadFields/controls/ChipSelectOptionRender';
import ChipSelect from 'pages/RiskAnalysis/Id/PayloadFields/controls/helpers/ChipSelect';
import VersionTracesConfirmationModal from 'pages/RiskAnalysis/Id/PayloadFields/controls/modals/VersionTracesConfirmationModal';
import { VERSION_TRACE_QUERY } from 'pages/RiskAnalysis/Id/PayloadFields/controls/modals/gql';

import RiskFieldSection from '../RiskFieldSection';
import RpcInput from '../RpcComponent';
import MitigatedField from '../MitigatedField';
import { levelsDictToOptions } from '../utils';

import { useAddRiskControlConnection, useDeleteRiskControlConnection, useChangeRiskControlVersion } from '../gql';
import { RiskVersionQuery_riskVersion } from '../__generated__/RiskVersionQuery';

import { useStyles } from './styles';

interface Props {
  riskVersion: RiskVersionQuery_riskVersion;
  onFieldSave: (field: string) => (value: any) => void;
  dictionaries: any;
  callbacks: any;
  viewType: string;
}

const RiskControl = (props: Props) => {
  const classes = useStyles();
  const { riskVersion, onFieldSave, dictionaries, callbacks, viewType } = props;
  const { probabilityLevels, severityLevels, harms, hazards } = dictionaries;
  const { onHazardsChange, onHarmChange } = callbacks;
  const { locked } = riskVersion;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const probabilityOptions = useMemo(() => levelsDictToOptions(probabilityLevels, 'Select Probability'), [
    probabilityLevels,
  ]);
  const severityOptions = useMemo(() => levelsDictToOptions(severityLevels, 'Select Severity'), [severityLevels]);
  const riskControlsDict = get(dictionaries, 'riskControls', []);

  const linkedRiskControls = get(riskVersion, 'item.riskControlMeasures');
  const linkedRiskControlIds = linkedRiskControls.map((rc) => rc.id);
  const filteredRiskControlsDict = riskControlsDict.filter((option) => !linkedRiskControlIds.includes(option.id));

  const [connectRiskControls] = useAddRiskControlConnection();
  const [deleteRiskControlConnection] = useDeleteRiskControlConnection();
  const [changeRiskControlVersion] = useChangeRiskControlVersion();
  const connectRiskControl = (id: string) => {
    connectRiskControls({
      variables: {
        riskVersionId: riskVersion.id,
        riskControlVersionIds: [id],
        trioType: 'RiskMitigation',
      },
    }).catch(onError);
  };

  const deleteRiskControl = (id: string) => {
    deleteRiskControlConnection({
      variables: {
        riskControlVersionId: id,
        riskVersionId: riskVersion.id,
      },
    }).catch(onError);
  };

  function findRiskControlByVersionId(data, versionId) {
    for (let item of data) {
      const foundVersion = item.currentVersion.item.versions.find((version) => version.id === versionId);
      if (foundVersion) return item.chosenRiskControlVersionId;
    }
    return null;
  }

  const changeRiskControl = (id: string) => {
    changeRiskControlVersion({
      variables: {
        riskVersionId: riskVersion.id,
        newRiskControlVersionId: id,
        oldRiskControlVersionId: findRiskControlByVersionId(linkedRiskControls, id),
        trioType: 'RiskMitigation',
      },
    }).catch(onError);
  };

  const onError = (e) => (message: string) => {
    throw new Error(`${message}${e.message}`);
  };

  const onChange = (field: string) => (event) => {
    const value = event.target.value;
    onFieldSave(field)(value);
  };

  const onChangeDebounced = useDebounce((field: string) => (value: string) => {
    onFieldSave(field)(value);
  });

  const [selectedRiskControl, setSelectedRiskControl] = useState(null);
  const [selectedToggledId, setSelectedToggledId] = useState(null);
  const [versionTraces, setVersionTraces] = useState([]);
  const [actionType, setActionType] = useState<'remove-rcm' | 'toggle-rcm' | null>(null);

  const [getVersionTrace] = useLazyQuery(VERSION_TRACE_QUERY, {
    //@ts-ignore
    fetchPolicy: 'network-only',
    onError: onError,
    onCompleted: function(data) {
      if (data && data.versionTrace && data.versionTrace.length > 0) {
        setShowConfirmationModal(true);
        //@ts-ignore
        setVersionTraces(data.versionTrace);
      } else if (!data || data.versionTrace.length === 0) {
        if (actionType === 'remove-rcm') {
          //@ts-ignore
          deleteRiskControl(selectedRiskControl.chosenRiskControlVersionId);
          setSelectedRiskControl(null);
          setActionType(null);
        } else if (actionType === 'toggle-rcm') {
          //@ts-ignore
          changeRiskControl(selectedToggledId);
          setSelectedToggledId(null);
          setActionType(null);
        }
      }
    },
  });

  const handleOnRemove = async (riskControl) => {
    setSelectedRiskControl(riskControl);
    setActionType('remove-rcm');
    getVersionTrace({ variables: { traceVersionId: riskControl.chosenRiskControlVersionId } });
  };

  const handleOnToggle = async (id) => {
    setSelectedToggledId(id);
    setActionType('toggle-rcm');
    getVersionTrace({ variables: { traceVersionId: findRiskControlByVersionId(linkedRiskControls, id) } });
  };

  const harmAsUnmitigatedToggle = () => {
    return (
      <div className={classes.inlineToggle}>
        <Typography variant="body2">SAME AS UNMITIGATED?</Typography>
        <TriStateToggle
          toggled={get(riskVersion, 'mitigatedHarmAsUnmitigated')}
          onChange={(val) => {
            onFieldSave('mitigatedHarmAsUnmitigated')(isUndefined(val) ? null : val);
          }}
          disabled={locked}
        />
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
        }}
      >
        <section
          style={{
            width: '50%',
            marginRight: '50px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <Chip size={'small'} label="Unmitigated Risk Analysis" className={classes.switchViewPanelText} />
          </div>
          <Grid container style={{ height: '100%', marginTop: '8px' }} alignContent={'flex-start'}>
            <Grid item xs={8} style={{ borderRight: '2px solid #EBECED' }}>
              <Box className={classes.box}>
                <EnhancedAutocomplete
                  onChange={onHazardsChange}
                  loading={false}
                  options={{
                    label: 'Hazard',
                    Icon: ReportProblem,
                    placeholder: 'Add Hazard',
                  }}
                  suggestions={hazards.map((h) => h.name)}
                  selected={get(riskVersion, 'hazard.name')}
                  disabled={locked}
                  chipTooltip
                />
              </Box>
            </Grid>
            <Grid item xs={4} style={{ borderRight: '2px solid #EBECED' }}>
              <Box className={classes.box}>
                <RiskFieldSection.Heading Icon={Grain}>P1</RiskFieldSection.Heading>
                <RiskLevelSelect
                  placeholder={'Select Probability'}
                  includeLabelWhenSelected
                  defaultValue={get(riskVersion, 'unmitigatedProbability1.id', null)}
                  options={probabilityOptions}
                  onChange={onChange('unmitigatedProbability1Id')}
                  disabled={locked}
                  viewType={viewType}
                />
              </Box>
            </Grid>
            <Grid item xs={8} style={{ borderRight: '2px solid #EBECED' }}>
              <Box className={classes.box}>
                <RiskFieldSection.Heading Icon={DirectionsRun}>Sequence of Events</RiskFieldSection.Heading>
                <TextAreaField
                  onTextChange={onChangeDebounced('sequenceOfEvents')}
                  locked={locked}
                  id="sequenceOfEvents"
                  initialValue={riskVersion.sequenceOfEvents}
                  versionId={riskVersion.id}
                  attrName="sequence_of_events"
                  rows={4}
                  maxLength={850}
                  placeholder="Describe sequence of events"
                />
              </Box>
            </Grid>
            <Grid item xs={4} style={{ borderRight: '2px solid #EBECED' }}>
              <Box className={classes.box}>
                <RiskFieldSection.Heading Icon={Grain}>P2</RiskFieldSection.Heading>
                <RiskLevelSelect
                  placeholder={'Select Probability'}
                  includeLabelWhenSelected
                  defaultValue={get(riskVersion, 'unmitigatedProbability2.id', null)}
                  options={probabilityOptions}
                  onChange={onChange('unmitigatedProbability2Id')}
                  disabled={locked}
                  viewType={viewType}
                />
              </Box>
            </Grid>
            <Grid item xs={8} style={{ borderRight: '2px solid #EBECED' }}>
              <Box className={classes.box}>
                <RiskFieldSection.Heading Icon={FlashOn}>Hazardous situation</RiskFieldSection.Heading>
                <TextAreaField
                  onTextChange={onChangeDebounced('hazardousSituation')}
                  locked={locked}
                  id="hazardousSituation"
                  initialValue={riskVersion.hazardousSituation}
                  versionId={riskVersion.id}
                  attrName="hazardous_situation"
                  maxLength={850}
                  rows={4}
                  placeholder="Describe hazardous situation"
                />
              </Box>
            </Grid>
            <Grid item xs={4} style={{ borderRight: '2px solid #EBECED' }}>
              <Box className={classes.box}>
                <RiskFieldSection.Heading Icon={Grain}>Probability</RiskFieldSection.Heading>
                <RiskLevelSelect
                  placeholder={'Select Probability'}
                  includeLabelWhenSelected
                  defaultValue={get(riskVersion, 'unmitigatedProbability.id', null)}
                  options={probabilityOptions}
                  onChange={onChange('unmitigatedProbabilityId')}
                  disabled={locked}
                  viewType={viewType}
                />
              </Box>
            </Grid>
            <Grid item xs={8} style={{ borderRight: '2px solid #EBECED', paddingTop: '60px' }}>
              <Box className={classes.box}>
                <EnhancedAutocomplete
                  onChange={onHarmChange('harm')}
                  loading={false}
                  options={{
                    label: 'Harm',
                    Icon: Report,
                    placeholder: 'Add Harm',
                  }}
                  suggestions={harms.map((h) => h.name)}
                  // @ts-ignore
                  selected={get(riskVersion, 'harm.name')}
                  disabled={locked}
                  chipTooltip
                />
              </Box>
            </Grid>
            <Grid item xs={4} style={{ borderRight: '2px solid #EBECED', paddingTop: '60px' }}>
              <Box className={classes.box}>
                <RiskFieldSection.Heading Icon={BrokenImage} style={{ marginTop: 'auto' }}>
                  Severity
                </RiskFieldSection.Heading>
                <RiskLevelSelect
                  placeholder={'Select Severity'}
                  includeLabelWhenSelected
                  defaultValue={get(riskVersion, 'unmitigatedSeverity.id', null)}
                  options={severityOptions}
                  onChange={onChange('unmitigatedSeverityId')}
                  disabled={locked}
                  viewType={viewType}
                />
              </Box>
            </Grid>
          </Grid>
          <div style={{ borderRight: '2px solid #EBECED', borderTop: '2px solid #EBECED' }}>
            <Box className={classes.box}>
              <RiskFieldSection.Heading Icon={FindReplace}>Unmitigated RPC</RiskFieldSection.Heading>
              <RpcInput
                placeholder={'RPC cannot be determined'}
                color={get(riskVersion, 'unmitigatedRiskPriorityCode.color')}
                inputValue={get(riskVersion, 'unmitigatedRiskPriorityCode.name')}
              />
            </Box>
          </div>
        </section>
        <section
          style={{
            width: 'calc(64% - 50px)',
          }}
        >
          <div>
            <Chip size={'small'} label="Mitigated Risk Analysis" className={classes.switchViewPanelText} />
          </div>
          <Grid container style={{ marginTop: '8px' }}>
            <Grid item xs={5} style={{ borderRight: '2px solid #EBECED', borderLeft: '2px solid #EBECED' }}>
              <Box className={classes.box}>
                <RiskFieldSection.Heading Icon={Healing} style={{ marginTop: 'auto' }}>
                  Risk Control Measures
                </RiskFieldSection.Heading>
                <MitigatedField>
                  <ChipSelect
                    placeholder={''}
                    disabled={locked}
                    renderOption={(option) => <ChipSelectOptionRender option={option} />}
                    content={linkedRiskControls}
                    options={filteredRiskControlsDict}
                    onAdd={(riskControl) => {
                      connectRiskControl(riskControl.currentVersion.id);
                    }}
                    onRemove={locked ? undefined : handleOnRemove}
                    onOpen={() => {
                      // refreshing options on open may make sense
                    }}
                    onToggle={locked ? undefined : handleOnToggle}
                  />
                </MitigatedField>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className={classes.box}>
                    <RiskFieldSection.Heading Icon={Grain}>P1</RiskFieldSection.Heading>
                    <MitigatedField>
                      <RiskLevelSelect
                        placeholder={'Select Probability'}
                        defaultValue={get(riskVersion, 'mitigatedProbability1.id', null)}
                        options={probabilityOptions}
                        onChange={onChange('mitigatedProbability1Id')}
                        disabled={locked}
                        viewType={viewType}
                      />
                    </MitigatedField>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.box}>
                    <RiskFieldSection.Heading Icon={Grain}>P2</RiskFieldSection.Heading>
                    <MitigatedField>
                      <RiskLevelSelect
                        placeholder={'Select Probability'}
                        defaultValue={get(riskVersion, 'mitigatedProbability2.id', null)}
                        options={probabilityOptions}
                        onChange={onChange('mitigatedProbability2Id')}
                        disabled={locked}
                        viewType={viewType}
                      />
                    </MitigatedField>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.box}>
                    <RiskFieldSection.Heading Icon={Grain}>Probability</RiskFieldSection.Heading>
                    <MitigatedField>
                      <RiskLevelSelect
                        placeholder={'Select Probability'}
                        defaultValue={get(riskVersion, 'mitigatedProbability.id', null)}
                        options={probabilityOptions}
                        onChange={onChange('mitigatedProbabilityId')}
                        disabled={locked}
                        viewType={viewType}
                      />
                    </MitigatedField>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.box}>
                    <RiskFieldSection.Heading Icon={Report} style={{ marginTop: 'auto', marginBottom: '11px' }}>
                      Mitigated Harm
                      {harmAsUnmitigatedToggle()}
                    </RiskFieldSection.Heading>
                    <MitigatedField>
                      <EnhancedAutocomplete
                        onChange={onHarmChange('mitigatedHarm')}
                        loading={false}
                        options={{
                          placeholder: 'Type to add harm',
                        }}
                        suggestions={harms.map((h) => h.name)}
                        selected={get(
                          riskVersion,
                          get(riskVersion, 'mitigatedHarmAsUnmitigated', false) ? 'harm.name' : 'mitigatedHarm.name',
                        )}
                        disabled={locked || !!get(riskVersion, 'mitigatedHarmAsUnmitigated')}
                        chipTooltip
                      />
                    </MitigatedField>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.box}>
                    <RiskFieldSection.Heading Icon={BrokenImage} style={{ marginTop: 'auto' }}>
                      Severity
                    </RiskFieldSection.Heading>
                    <MitigatedField>
                      <RiskLevelSelect
                        placeholder={'Select Severity'}
                        defaultValue={get(riskVersion, 'mitigatedSeverity.id', null)}
                        options={severityOptions}
                        onChange={onChange('mitigatedSeverityId')}
                        disabled={locked}
                        viewType={viewType}
                      />
                    </MitigatedField>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ borderTop: '2px solid #EBECED', borderLeft: '2px solid #EBECED' }}>
            <Box className={classes.box}>
              <RiskFieldSection.Heading Icon={FindReplace}>Mitigated RPC</RiskFieldSection.Heading>
              <RpcInput
                placeholder={'RPC cannot be determined'}
                color={get(riskVersion, 'mitigatedRiskPriorityCode.color')}
                inputValue={get(riskVersion, 'mitigatedRiskPriorityCode.fullName')}
              />
            </Box>
          </div>
        </section>
        {showConfirmationModal && (
          <VersionTracesConfirmationModal
            isOpen={showConfirmationModal}
            actionType={actionType}
            traces={versionTraces}
            riskIdentifier={riskVersion.item.customIdentifier}
            //@ts-ignore
            currentVersionId={selectedRiskControl ? selectedRiskControl.chosenRiskControlVersionId : selectedToggledId}
            onCancel={() => setShowConfirmationModal(false)}
            onConfirm={() => {
              setShowConfirmationModal(false);
              if (actionType === 'remove-rcm') {
                //@ts-ignore
                deleteRiskControl(selectedRiskControl.chosenRiskControlVersionId);
                setSelectedRiskControl(null);
                setVersionTraces([]);
                setActionType(null);
              } else if (actionType === 'toggle-rcm') {
                //@ts-ignore
                changeRiskControl(selectedToggledId);
                setSelectedToggledId(null);
                setVersionTraces([]);
                setActionType(null);
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default RiskControl;
