import { useState } from 'react';

// no concurrency handled now (users-wise and in the same browser too)
// so cases when data got changed during editing are not handled in any way

interface Props<T> {
  data: T
  update: (change: Partial<T>) => Promise<any>
}

enum FormState {
  Viewing = 1,
  Editing,
  Saving,
}

export interface Form<T> {
  data: T
  editedData: T
  change: Partial<T>

  state: FormState
  canEdit: boolean

  startEditing: () => any
  edit: (attr: string, value: any) => any
  save: () => Promise<any>
  reset: () => any
  cancelEditing: () => any
}

export function useForm<T extends {id:any}>(props: Props<T>): Form<T> {
  const initialState = FormState.Viewing

  const [state, setState] = useState(initialState)
  const [change, setChange] = useState({})

  const { data } = props
  const editedData = { ...data, ...change }

  const canEdit = state === FormState.Editing

  const startEditing = () => setState(FormState.Editing)

  const edit = (attr: string, value: any) => setChange(obj => ({ ...obj, [attr]: value }))
  const reset = () => setChange({})
  const save = () => {
    setState(FormState.Saving)
    const payload = { ...change, id: data.id }
    const result = props.update(payload as any)
    result.then(() => { reset(); setState(initialState) }).catch(e => {
      throw new Error(`useForm: update failed with ${e.message}`);
    });
    return result
  }
  const cancelEditing = () => {
    reset(); setState(initialState)
  }

  return {
    data,
    change,
    editedData,

    state,
    canEdit,

    startEditing,
    edit,
    save,
    reset,
    cancelEditing,
  }
}
