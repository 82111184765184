import React, { FC } from 'react';

import { Box, Typography } from '@material-ui/core';

import NotificationPreferences from './NotificationPreferences';

import { useStyles } from './styles';

interface Props {
  onCreate?: any;
  path: string;
}

const UserSettings: FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box mb={5}>
        <Typography variant="h2" className={classes.pageTitle}>
          Account Settings
        </Typography>
      </Box>
      <Box ml={2}>
        <NotificationPreferences />
      </Box>
    </div>
  );
};

export default UserSettings;
