import React from 'react';

import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import GetApp from '@material-ui/icons/GetApp';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Modal } from '@material-ui/core';

import { Trans } from '@lingui/macro';
import truncate from 'lodash/truncate';

import { getAuthToken, getBaseApiUrl } from 'utils';
import AttachmentClient from 'utils/AttachmentClient';

import FileUploadSnackbar from './FileUploadSnackbar';
import YesOrNo from '../YesOrNo';
import styles from './styles';

interface DocumentUploaderProps {
  classes;
  acceptedFileTypes?: string;
  attrName: string;
  attachmentId?: string;
  filename?: string | null;
  locked: boolean;
  readOnly?: boolean;
  onCompleted?: () => void;
  displayOnly?: boolean;
  beforeUpload?: () => void;
  beforeDelete?: () => void;
  versionId: string;
  url?: string;
  uploadMutation?: ({ variables: { versionId, attachmentURL } }) => Promise<any>;
  deleteMutation?: ({ variables: { id } }) => Promise<any>;
}

class DocumentUploader extends React.Component<DocumentUploaderProps, any> {
  constructor(props: DocumentUploaderProps) {
    super(props);

    const base = new URL(getBaseApiUrl() || window.location.origin);
    base.searchParams.append('id', props.versionId);

    this.state = {
      displaySnackbar: false,
      fileText: '',
      fileUpload: undefined,
      filename: undefined,
      modalOpen: false,
      s3uploadUrl: null,
      s3params: null,
    };
  }

  hideSnackbar = () => {
    this.setState({ displaySnackbar: false });
  };

  handleFileChange = async (event) => {
    event.preventDefault();

    let file = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('version_id', this.props.versionId);
      formData.append('field_name', this.props.attrName);

      this.uploadFile(formData);
    }

    this.setState({
      fileUpload: file,
      fileText: file.name,
    });
  };

  uploadFile = (formData) => {
    this.props.beforeUpload && this.props.beforeUpload();
    const [authToken] = getAuthToken();
    let headers = new Headers();
    headers.append('Authorization', authToken || '');

    fetch(AttachmentClient.endpoint, {
      credentials: 'same-origin',
      headers: headers,
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Attachment upload failed');
        }
        this.setState({ displaySnackbar: true, deleteAction: false }, this.props.onCompleted);
      })
      .catch((error) => {
        throw new Error(`Upload failed: ${error.message}`);
      });
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  deleteFile = () => {
    if (!this.props.attachmentId) return;
    this.props.beforeDelete && this.props.beforeDelete();
    const [authToken] = getAuthToken();

    new AttachmentClient(authToken)
      .delete(this.props.versionId, this.props.attachmentId)
      .then(() => {
        this.setState(
          {
            displaySnackbar: true,
            deleteAction: true,
            modalOpen: false,
          },
          this.props.onCompleted,
        );
      })
      .catch((error) => {
        throw new Error(`Deletion of attachment failed: ${error.message}`);
      });
  };

  urlWithToken = (url: URL) => {
    url.searchParams.set('token', getAuthToken()[0] || '');
    return url.toString();
  };

  handleFileDownload = () => {
    window.open(this.props.url);
  };
  render() {
    const { classes, locked, filename, displayOnly = false, readOnly = false, acceptedFileTypes = '.pdf' } = this.props;

    return (
      <>
        <form onChange={this.handleFileChange}>
          <div className={classes.inputWrapper as string}>
            <div style={{ flex: 1 }} className={(locked || filename) && classes.disabledInput}>
              {(filename === undefined || filename === null) && (
                <input
                  id={`${this.props.attrName}-input`}
                  accept={acceptedFileTypes}
                  type="file"
                  hidden
                  disabled={locked || Boolean(filename)}
                />
              )}
              <label htmlFor={`${this.props.attrName}-input`}>
                <Button
                  data-testid="upload-btn"
                  component="span"
                  className={classes.uploaderBtn as string}
                  fullWidth
                  disabled={Boolean(locked || filename)}
                >
                  {filename ? <span>{truncate(filename, { length: 25 })}</span> : <Trans>Attach File</Trans>}
                </Button>
              </label>
            </div>
            <div
              style={{
                display: filename ? 'flex' : 'none',
              }}
              className={classes.buttonGroup as string}
            >
              {this.props.url && (
                <div style={{ display: 'inline' }} onClick={this.handleFileDownload}>
                  <GetApp className={classes.pdfActionButton} data-testid="download-pdf-btn" />
                </div>
              )}

              {!displayOnly && !locked && !readOnly && (
                <div style={{ display: 'inline' }} onClick={this.handleModalOpen}>
                  <Delete className={classes.pdfActionButton} data-testid="delete-attachment-btn" />
                </div>
              )}
              <Modal open={this.state.modalOpen} onClose={this.handleModalClose}>
                <Card classes={{ root: classes.modalCard }}>
                  <CardContent classes={{ root: classes.cardContent }}>
                    <Close
                      className={classes.closeIcon}
                      viewBox="-4 4 24 24"
                      onClick={this.handleModalClose}
                      color="action"
                    />
                    <YesOrNo
                      heading={'Delete Attachment?'}
                      yesLabel="Delete"
                      noLabel="Cancel"
                      handleComplete={this.deleteFile}
                      closeOnNo
                      handleClose={this.handleModalClose}
                      headingWithoutPromptText={true}
                    />
                  </CardContent>
                </Card>
              </Modal>
            </div>
          </div>
        </form>

        <FileUploadSnackbar
          onClose={this.hideSnackbar}
          open={this.state.displaySnackbar}
          variant={this.state.deleteAction ? 'deleteSuccess' : 'success'}
          variantActions={{ error: this.uploadFile }}
          message
        />
      </>
    );
  }
}

export default withStyles(styles)(DocumentUploader);
