import React, { useEffect, useState } from 'react';

import { OfflinePin, WebAsset } from '@material-ui/icons';
import { Grid, Typography } from '@material-ui/core';

import { dropRight } from 'lodash';

import LibraryAddCheck from 'assets/icons/LibraryAddCheck';
import IconButton from 'components/IconButton';
import SectionLabel from 'components/core/SectionLabel';
import Panel from 'components/core/Panel';

import LinkedTasksTable from './LinkedTasksTable/index';

import { useDestroyTaskLink, useLinkTask } from '../gql';

import { TaskQuery, TaskQuery_tasks, TaskQuery_taskVersion as TaskVersion } from '../__generated__/TaskQuery';
import { TasksRelationship } from '__generated__/globalTypes';

import { useStyles } from './styles';

interface Option {
  value: any;
  label: string;
}

// NOTE: controversial move doing this on the front-end
const getTaskOptions = (taskVersion: TaskVersion, tasks: TaskQuery_tasks[] | null): Option[] => {
  if (!tasks || tasks.length === 0) return [];
  return tasks
    .filter((task) => task.id !== taskVersion.id)
    .filter((task) => !task.currentVersion.locked)
    .filter((task) => {
      if (taskVersion.taskVersionLinkedTasks == null) return true;
      return !taskVersion.taskVersionLinkedTasks.map((linkedTask) => linkedTask.id).includes(task.id);
    })
    .map((task) => ({
      value: task.currentVersion.id,
      label: `${task.customIdentifier}:${task.currentVersion.title}`,
    }));
};

interface AssociatedTasksProps {
  layoutClasses: any;
  data: TaskQuery;
}

const AssociatedTasks = (props: AssociatedTasksProps) => {
  const classes = useStyles();
  const {
    data: { taskVersion },
    layoutClasses,
  } = props;

  const tasks = props.data.tasks ? props.data.tasks : [];

  const [addLinkTask] = useLinkTask();
  const [destroyTaskLink] = useDestroyTaskLink();

  const [linkedTasks, setLinkedTasks] = useState<any[]>([]);

  useEffect(() => {
    setLinkedTasks(taskVersion.taskVersionLinkedTasks || []);
  }, [taskVersion]);

  const linkTask = (linkedTaskId: string, relationship: TasksRelationship) => {
    addLinkTask({
      variables: {
        taskId: taskVersion.id,
        linkedTaskId,
        relationship,
      },
    }).catch((e) => {
      throw new Error(`Error occurred while linking Task: ${e}`);
    });
  };

  const onRelationshipChange = (row) => (e) => {
    const next = { ...row, relationship: e.target.value };
    const nextTasks = linkedTasks.map((task) => (task.id === row.id ? next : task));
    setLinkedTasks(nextTasks);
  };

  const unlinkTask = (linkedTaskId: string) => {
    if (linkedTaskId.length === 0) {
      setLinkedTasks(dropRight(linkedTasks));
      return;
    }
    destroyTaskLink({
      variables: {
        taskId: taskVersion.id,
        linkedTaskId,
      },
    }).catch((e) => {
      throw new Error(`Error occurred while removing Task link: ${e}`);
    });
  };

  const isEmptyLineExist = () => {
    return linkedTasks.find((task) => !Boolean(task.id));
  };

  const addEmptyRow = () => {
    setLinkedTasks([
      ...linkedTasks,
      {
        id: null,
        linkedTask: {
          id: '',
          assignedPerson: null,
          currentStatus: null,
        },
        relationship: '',
      },
    ]);
  };

  const { locked } = taskVersion;

  return (
    <Grid item xs={12} className={layoutClasses.grid}>
      <SectionLabel icon={LibraryAddCheck}>Associated Tasks</SectionLabel>
      <Panel>
        <Panel.Heading icon={WebAsset}>Linked Tasks</Panel.Heading>
        <div>
          {linkedTasks && linkedTasks.length > 0 ? (
            <LinkedTasksTable
              tasksOptions={getTaskOptions(taskVersion, tasks)}
              unlinkTask={unlinkTask}
              data={linkedTasks}
              linkTask={linkTask}
              locked={locked}
              onRelationshipChange={onRelationshipChange}
            />
          ) : (
            <Typography variant="caption">No tasks linked.</Typography>
          )}
          <div className={classes.linkTaskIcon}>
            {locked || (
              <IconButton
                disabled={isEmptyLineExist()}
                Icon={OfflinePin}
                onClick={() => {
                  addEmptyRow();
                }}
                size="medium"
              />
            )}
          </div>
        </div>
      </Panel>
    </Grid>
  );
};

export default AssociatedTasks;
