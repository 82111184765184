import React, { useState } from 'react';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import startCase from 'lodash/startCase';

import Panel from 'components/core/Panel';
import Button from 'components/Button';
import WorkflowIcon from 'components/WorkflowIcon';

import { useStyles } from '../styles';
import { PreviousVersionsListProps } from '..';

type ItemTypeLabels = Pick<PreviousVersionsListProps, 'labels'>;

interface PreviousVersionItemProps extends ItemTypeLabels {
  isInitial: boolean;
  version: any;
  Component: any;
}

const PreviousVersionItem = (props: PreviousVersionItemProps) => {
  const { Component, version, labels, isInitial } = props;
  const {
    statusLabel = 'Outcome: ',
    dateLabel = 'Date: ',
    versionTypeLabel = 'Version',
    versionTypeSecondLabel = 'Version',
  } = labels;
  const classes = useStyles();

  const [expanded, setExpanded] = useState<boolean>(false);
  const status = get(version, 'currentStatus.name');

  return (
    <div className={classes.versionsItemContainer}>
      <Panel className={classes.versionsItemPanel}>
        <div className={classes.versionsItemInfo}>
          <Typography variant="h4">
            {isInitial ? `Initial ${versionTypeLabel}` : `${version.versionIdentifier} ${versionTypeSecondLabel}`}
          </Typography>
          <Typography variant="subtitle1" className={classes.label}>
            {dateLabel}:&ensp;
            <Typography variant="body2">
              {DateTime.fromISO(get(version, 'currentStatus.createdAt')).toFormat('MMM. dd, yyyy')}
            </Typography>
          </Typography>
        </div>
        <Typography variant="subtitle1" className={classes.label}>
          {statusLabel}:&ensp;<Typography variant="body2">{startCase(status)}</Typography>
        </Typography>
        <WorkflowIcon type={camelCase(status)} completed size="small" displayTooltip />
        <Button className={classes.expandButton} type="button" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
        </Button>
      </Panel>
      {expanded && <Component version={version} />}
    </div>
  );
};

export default PreviousVersionItem;
