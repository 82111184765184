import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { DescriptionOutlined, FindReplace, Error as ErrorIcon } from '@material-ui/icons';

import { get, isUndefined } from 'lodash';

import { TriStateToggle, TextAreaField } from 'components';
import { useDebounce } from 'hooks/useDebounce';

import RpcInput from '../RpcComponent';
import RiskFieldSection from '../RiskFieldSection';

import { useStyles } from './styles';

import { RiskVersionQuery_riskVersion } from '../__generated__/RiskVersionQuery';

interface Props {
  riskVersion: RiskVersionQuery_riskVersion;
  onFieldSave: (field: string) => (value: any) => void;
}

const OverallRiskEvaluation = (props: Props) => {
  const { riskVersion, onFieldSave } = props;
  const classes = useStyles();
  const { locked } = riskVersion;

  const onRiskAccessibilityChange = (value: any) =>
    onFieldSave('residualRiskAcceptability')(isUndefined(value) ? null : value);

  const onChangeDebounced = useDebounce(onFieldSave('residualRiskJustification'));

  return (
    <>
      <Grid container spacing={4}>
        <Grid item>
          <section className={classes.rpcSection}>
            <RiskFieldSection.Heading Icon={FindReplace}>Unmitigated RPC</RiskFieldSection.Heading>
            <RpcInput
              placeholder={'RPC cannot be determined'}
              color={get(riskVersion, 'unmitigatedRiskPriorityCode.color')}
              inputValue={get(riskVersion, 'unmitigatedRiskPriorityCode.name')}
            />
          </section>
          <section className={classes.rpcSection}>
            <RiskFieldSection.Heading Icon={FindReplace}>Mitigated RPC</RiskFieldSection.Heading>
            <RpcInput
              placeholder={'RPC cannot be determined'}
              color={get(riskVersion, 'mitigatedRiskPriorityCode.color')}
              inputValue={get(riskVersion, 'mitigatedRiskPriorityCode.name')}
            />
          </section>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={12} xs={12}>
          <RiskFieldSection.Heading Icon={ErrorIcon}>Residual Risk</RiskFieldSection.Heading>
          <div className={classes.toggleContainer}>
            <Typography variant={'body2'}>
              Considering the medical benefits, is residual risk acceptable?
              <div className={classes.inlineToggle}>
                <span>No</span>
                <TriStateToggle
                  toggled={riskVersion.residualRiskAcceptability}
                  onChange={onRiskAccessibilityChange}
                  disabled={locked}
                />
                <span>Yes</span>
              </div>
            </Typography>
          </div>
          <RiskFieldSection.Heading Icon={DescriptionOutlined}>Residual Risk Justification</RiskFieldSection.Heading>
          <TextAreaField
            onTextChange={onChangeDebounced}
            locked={locked}
            id="residualRiskJustification"
            maxLength={850}
            initialValue={riskVersion.residualRiskJustification}
            versionId={riskVersion.id}
            attrName="residual_risk_justification"
            rows={4}
            placeholder="Add justification"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OverallRiskEvaluation;
