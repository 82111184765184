import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';

import { Spacer } from 'components';

const styles = (theme) =>
  createStyles({
    root: {
      width: theme.spacing(45),
      padding: theme.spacing(3),
    },
    backArrow: {
      float: 'left',
      '&:hover': { cursor: 'pointer' },
    },
    closeIcon: {
      float: 'right',
      '&:hover': { cursor: 'pointer' },
    },
  });

function WorkflowModalContainer(props: {
  classes;
  children;
  showBackButton: boolean;
  onClose: () => void;
  onBack: () => void;
}) {
  const { classes } = props;
  return (
    <Paper className={classes.root} elevation={1}>
      <div>
        {props.showBackButton && (
          <ArrowBack
            className={classes.backArrow}
            viewBox="4 4 24 24"
            onClick={props.onClose}
            color="action"
          />
        )}
        <Close
          className={classes.closeIcon}
          viewBox="-4 4 24 24"
          onClick={props.onBack}
          color="action"
        />
      </div>
      <Spacer factor={2} />
      {props.children}
    </Paper>
  );
}

export default withStyles(styles)(WorkflowModalContainer);
