import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { queryNameToRefetch } from '../../gql';

const UPDATE_IMPACT_ASSOCIATED_COURSE = gql`
  mutation UpdateTrainingImpactAssociatedCourse(
    $id: ID!
    $requiresCourseTraining: Boolean
    $justificationForNoTraining: String
  ) {
    updateTrainingImpactAssociatedCourse(
      id: $id
      requiresCourseTraining: $requiresCourseTraining
      justificationForNoTraining: $justificationForNoTraining
    ) {
      trainingImpactAssociatedCourse {
        id
        requiresCourseTraining
        justificationForNoTraining
      }
    }
  }
`;

export function useUpdateImpactAssociatedCourseMutation() {
  return useMutation(UPDATE_IMPACT_ASSOCIATED_COURSE, {
    refetchQueries: [queryNameToRefetch, 'timelineQuery'],
  });
}

const UPDATE_IMPACT_ASSESSMENT = gql`
  mutation UpdateTrainingImpactAssessment($id: ID!, $userIds: [ID!], $requiresReadTraining: Boolean) {
    updateTrainingImpactAssessment(id: $id, userIds: $userIds, requiresReadTraining: $requiresReadTraining) {
      trainingImpactAssessment {
        id
      }
    }
  }
`;

export function useUpdateImpactAssessmentMutation() {
  return useMutation(UPDATE_IMPACT_ASSESSMENT, {
    refetchQueries: [queryNameToRefetch, 'timelineQuery'],
  });
}
