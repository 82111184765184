import React, { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { AssignmentInd } from '@material-ui/icons';

import ManageAccounts from 'assets/icons/ManageAccounts';
import { UserCreatedSnackbar } from 'components';

import LicenseInformation from './LicenseInformation';
import { useStyles } from './styles';
import UserList from './UserList';
import CreateNewUserModal from './CreateNewUserModal';

interface Props {
  onCreate?: any;
  path: string;
}

function UserManagement(props: Props) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [newUser, setNewUser] = useState(null);

  const onCreate = (data) => {
    const newUser = data.addUser.user;

    retrieveUserData(newUser);
    props.onCreate && props.onCreate(newUser);
  };

  const retrieveUserData = (user) => {
    setNewUser(user);
  };

  const handleModalClose = (evt) => {
    evt.preventDefault();
    setModalOpen(false);
  };

  const showSnackbar = () => {
    setSnackbarOpen(true);
    setModalOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setNewUser(null);
  };

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.titleContainer} item container>
        <Grid item>
          <ManageAccounts fontSize="large" viewBox="0 0 24 24" />
        </Grid>
        <Grid item>
          <Typography variant="h1" classes={{ root: classes.typography }}>
            User Management
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <LicenseInformation />
      </Grid>
      <Grid item container alignItems="center" style={{ marginTop: 35 }}>
        <Grid item>
          <AssignmentInd viewBox="0 0 24 24" />
        </Grid>
        <Grid item>
          <Typography variant="h4" classes={{ root: classes.typography }} style={{ fontWeight: 500 }}>
            User List
          </Typography>
        </Grid>
        <Grid>
          <Button
            style={{ marginLeft: '25px', padding: '0 30px' }}
            color="primary"
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Add New User
          </Button>
          {modalOpen && (
            <CreateNewUserModal
              isOpen={modalOpen}
              onClose={handleModalClose}
              showSnackbar={showSnackbar}
              onCreate={onCreate}
            />
          )}
          {newUser && (
            <UserCreatedSnackbar open={snackbarOpen} handleSnackbarClose={handleSnackbarClose} newUser={newUser} />
          )}
        </Grid>
      </Grid>
      <Grid item container>
        <UserList />
      </Grid>
    </Grid>
  );
}

export default UserManagement;
