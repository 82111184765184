import React from 'react';
import { useMutation } from 'react-apollo';
import { CircularProgress, MenuItem, Select, Typography } from '@material-ui/core';
import { useStyles } from './style';

interface Folder {
  id: string;
  indentationLevel: number;
  sortPosition: number;
  folderName: string;
  __typename: string;
}

interface SelectFolderProps {
  folders: Folder[] | null;
  selectedFolderName: string;
  level: string;
  setMemberId: string;
  updateMemberMutation: any;
  refetchQueries: [string];
  locked: boolean;
}

const SelectFolder: React.FC<SelectFolderProps> = ({
  folders,
  selectedFolderName,
  setMemberId,
  level,
  updateMemberMutation,
  refetchQueries,
  locked,
}) => {
  const classes = useStyles();

  const [mutateMember, { loading: memberLoading }] = useMutation(updateMemberMutation, {
    refetchQueries,
  });

  const handleMemberChange = (setMemberId: string, folder: Folder | undefined, folderLevel: string) => {
    const variables = {
      setMemberId,
      sortPosition: 0,
      indentationLevel: 0,
      [`${folderLevel}`]: folder ? folder.id : null,
    };
    mutateMember({
      variables: variables,
    }).catch((error) => {
      throw error;
    });
  };

  if (!folders) return null;

  const nonEmptyFolders = folders.filter((folder: Folder) => folder.folderName && folder.folderName.trim() !== '');
  const selectedFolder = folders.find((folder) => folder.folderName === selectedFolderName);
  const selectedFolderId = selectedFolder ? selectedFolder.id : '';

  if (memberLoading) return <CircularProgress />;

  return (
    <div className={classes.selectContainer}>
      <Select
        value={selectedFolderId}
        onChange={(e) => handleMemberChange(setMemberId, folders.find((folder) => folder.id === e.target.value), level)}
        disableUnderline={true}
        disabled={locked}
        className={classes.customSelect}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          classes: {
            paper: classes.menuPaper,
          },
        }}
      >
        {' '}
        <MenuItem value="">None</MenuItem>
        {nonEmptyFolders.map((folder: any) => (
          <MenuItem key={folder.id} value={folder.id}>
            <Typography variant="body2">{folder.folderName}</Typography>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectFolder;
