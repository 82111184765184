import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { Modal, Paper } from '@material-ui/core';
import { Add, Close, LinearScale } from '@material-ui/icons';
import camelCase from 'lodash/camelCase';
import flowRight from 'lodash/flowRight';

import { WorkflowIcon, ContentSection } from 'components';
import WorkflowMenu, { VoidMenuItem, CancelMenuItem } from 'components/WorkflowMenu';
import ConnectBar from 'compositions/TimelineGroup/components/ConnectBar';
import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';
import { SummaryOfChange } from 'compositions/TimelineGroup/steps/views';
import { useStyles as useTimelineStyles } from 'compositions/TimelineGroup/components/sharedStyles';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import WithCurrentUser from 'compositions/WithCurrentUser';

import { TransitionWizard } from '../Wizard';
import CurrentWorkflow from './CurrentWorkflow';
import { ChangeInfo } from './util';

interface Props {
  currentCompanyAllowsTransitioning: boolean;
  currentUser;
  currentVersion: any;
  releasedVersions: any[];
  startWizard: (t: TransitionWizard) => void;
}

function TrainingPlanTimeline(props: Props) {
  const [previousReleaseModal, setPreviousReleaseModal] = useState<ChangeInfo | null>(null);
  const classes: any = useTimelineStyles();

  const { currentStatus } = props.currentVersion;
  const { currentCompanyAllowsTransitioning } = props;

  const notInProgressStatuses = ['released', 'canceled', 'inactive'];

  const WorkflowControls = () => {
    if (!currentCompanyAllowsTransitioning) return;

    return (
      <>
        <WorkflowMenu>
          <CancelMenuItem
            disabled={!props.currentVersion.mayTransitionToCanceled}
            onClick={() => props.startWizard(TransitionWizard.Cancel)}
          />

          <VoidMenuItem
            disabled={!props.currentVersion.mayTransitionToVoid}
            onClick={() => props.startWizard(TransitionWizard.VoidPrepared)}
          />
        </WorkflowMenu>
      </>
    );
  };

  return (
    <ContentSection scrollX LabelText={<Trans>Workflow</Trans>} Icon={<LinearScale />} Controls={WorkflowControls()}>
      <div className={classes.timelineContainer}>
        {props.releasedVersions.map((v) => (
          <span key={v.id}>
            <SmallCircle
              content={'RFC'}
              color="obsolete"
              handleClick={() =>
                setPreviousReleaseModal({
                  reasonForChange: v.reasonForChange,
                  descriptionOfChange: v.descriptionOfChange,
                })
              }
            />

            <ConnectBar color={camelCase(v.currentStatus.name)} short visible />

            <WorkflowIcon
              type={camelCase(v.currentStatus.name)}
              completed
              version={v.versionIdentifier}
              effectiveDate={v.releasedAt}
              label={v.currentStatus.name}
              size="large"
              visible
            />

            {v.currentStatus.name !== 'inactive' && (
              <ConnectBar color={camelCase(v.currentStatus.name)} short visible />
            )}
          </span>
        ))}

        {(currentStatus.name === 'released' || currentStatus.name === 'canceled') && (
          <SmallCircle
            content={<Add />}
            color="draft"
            handleClick={() => props.startWizard(TransitionWizard.CreateVersion)}
          />
        )}

        {!notInProgressStatuses.includes(currentStatus.name) && (
          <CurrentWorkflow
            currentCompanyAllowsTransitioning={currentCompanyAllowsTransitioning}
            currentVersion={props.currentVersion}
            startWizard={props.startWizard}
          />
        )}

        {previousReleaseModal && (
          <Modal
            open={Boolean(previousReleaseModal)}
            onClose={() => setPreviousReleaseModal(null)}
            className={classes.infoModal}
          >
            <Paper className={classes.modalInside}>
              <Close
                className={classes.closeIcon}
                viewBox="-4 4 24 24"
                onClick={() => setPreviousReleaseModal(null)}
                color="action"
              />
              <SummaryOfChange currentVersion={previousReleaseModal} displayChangeInfo />
            </Paper>
          </Modal>
        )}
      </div>
    </ContentSection>
  );
}

export default flowRight([WithCurrentUser, withCurrentCompany])(TrainingPlanTimeline);
