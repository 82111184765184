import React, { CSSProperties, useContext, useState } from 'react';

import { DescriptionOutlined, Info as InfoIcon, Link } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

import clsx from 'clsx';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import isNull from 'lodash/isNull';

import { DeleteIcon } from 'components/VersionTables';
import { regularTextHeaderStyle } from 'components/TableView/Fields/RegularText';
import { useStyles as useTableStyles } from 'components/core/Table/styles';
import { IDCell, idHeaderStyle } from 'components/TableView/Fields/ID';
import MakeLink from 'components/MakeLink';
import Table from 'components/core/Table';

import { LockedContext } from 'contexts/lockedContext';
import { useToggleablePopover } from 'hooks/useToggleablePopover';
import Validation from 'pages/DesignValidationProtocol';
import Verification from 'itemTypes/DeviceVerification';
import { addColumnGroups } from 'pages/RiskAnalysis/Id/PayloadFields/ColumnGroups';

import { ConfirmationDialog, IconButton, LabelText, VersionAndStatusDot, WorkflowIcon } from 'components';
import { ItemSearch } from 'compositions';

import { useStyles } from './styles';
import { useAddJustification, useRemoveJustification } from './hooks';
import { JustificationType, Protocol } from './util';
import JustificationForTesting from './JustificationForTesting';

interface Props {
  justifications: Protocol[];
  type: JustificationType;
  versionId: number;
  locked: boolean;
}

const width = {
  id: 117,
  textColumn: 180,
  smallColumn: 70,
  acceptabilityColumn: 210,
  hazardChip: 220,
  manyChips: 200,
  versionColumn: 125,
};

function withWidth(width: number) {
  return {
    width,
    cellStyle: {
      width,
      minWidth: width,
      maxWidth: width,
    },
  };
}

const getColumns = (classes, locked) => {
  const iconProps = {
    fontSize: 'small' as any,
    classes: { root: classes.icon },
  };

  return [
    {
      title: (
        <span className={classes.header}>
          <span>ID</span>
        </span>
      ),
      field: 'item.customIdentifier',
      ...withWidth(width.id),
      headerStyle: idHeaderStyle as any,
      cellStyle: { borderRight: 'solid 2px #ebeced' },
      render(row) {
        return (
          <div className={classes.idCell}>
            <MakeLink item={row.item} newTab>
              <IDCell data={row.item.customIdentifier} />
            </MakeLink>
          </div>
        );
      },
    },
    {
      title: (
        <span className={classes.header}>
          <span>Title</span>
        </span>
      ),
      cellStyle: { borderRight: 'none' },
      field: 'status',
      ...withWidth(width.versionColumn),
      headerStyle: { textAlign: 'left', paddingLeft: '8px' } as CSSProperties,
      render(row) {
        return <div style={{ display: 'flex', alignItems: 'center', padding: 8 }}>{row.item.currentVersion.title}</div>;
      },
    },
    {
      title: (
        <span className={classes.header}>
          <span>Version</span>
        </span>
      ),
      cellStyle: { borderRight: 'none' },
      field: 'versionIdentifier',
      ...withWidth(width.versionColumn),
      headerStyle: regularTextHeaderStyle as any,
      render(row) {
        return (
          <div className={classes.idCell}>
            <div style={{ display: 'flex', alignItems: 'center', padding: 8 }}>
              <VersionAndStatusDot
                version={row.item.currentVersion.versionIdentifier}
                status={row.item.currentVersion.currentStatus.name}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span className={classes.header}>
          <span>Status</span>
        </span>
      ),
      cellStyle: { borderRight: 'none' },
      field: 'status',
      ...withWidth(width.versionColumn),
      headerStyle: regularTextHeaderStyle as any,
      render(row) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', padding: 8 }}>
            <WorkflowIcon type={row.item.currentVersion.currentStatus.name} completed size="small" displayTooltip />
          </div>
        );
      },
    },
    {
      title: (
        <span className={classes.header}>
          <DescriptionOutlined {...iconProps} />
          <span>Justification for Testing</span>
        </span>
      ),
      field: 'residualRiskJustification',
      ...withWidth(width.textColumn),
      cellStyle: {
        padding: '3px 8px 0',
      },
      headerStyle: { paddingLeft: '8px' } as CSSProperties,
      render(row) {
        return (
          <JustificationForTesting
            key={row.item.id}
            justificationItem={row}
            className={classes.textField}
            locked={row.item.currentVersion.locked || locked}
          />
        );
      },
    },
  ];
};

export default function JustificationsTabs(props: Props) {
  const { locked } = useContext(LockedContext);
  const [toggled, anchor, toggle] = useToggleablePopover();
  const [showUnlinkConfirmation, setShowUnlinkConfirmation] = useState<string | null>(null);

  const classes = useStyles();
  const tableClasses = useTableStyles();

  const createJustification = useAddJustification(props.versionId);
  const [protocolToInputFocus, setProtocolToInputFocus] = useState<string | null>(null);

  const tabTitle = `Associated ${upperFirst(props.type.replace('-protocol', ''))} Protocols`;

  const [selectedDuplicates, setSelectedDuplicates] = useState<string[]>([]);

  const itemAlreadySelected = (id) => props.justifications.map((justification) => justification.item.id).includes(id);

  const customIdentifierForItemId = (id) => {
    const found = props.justifications.find((justification) => justification.item.id === id);
    return found && found.item.customIdentifier;
  };
  const removeJustification = useRemoveJustification();
  const validAssociatedItemTypes =
    props.type === 'verification-protocol' ? ['device_verification'] : ['device_validation'];

  const columns = getColumns(tableClasses, props.locked);

  return (
    <>
      <LabelText
        labelText={tabTitle}
        Icon={props.type === 'verification-protocol' ? Verification.icon : Validation.icon}
      />

      <div className={classes.justificationContainer}>
        <div className={classes.noticeContainer}>
          <section className={classes.noticeSection}>
            <Typography variant="subtitle1" className={classes.noticeHeading}>
              <InfoIcon fontSize="small" />
              <span>Reference Information</span>
            </Typography>
            <Typography variant="subtitle2" className={classes.noticeMessage}>
              <span>
                Title, Version, and Status displayed are for reference only and are subject to change as each item is
                updated.
              </span>
            </Typography>
          </section>
        </div>
        {props.justifications.length > 0 ? (
          <>
            <div className={clsx(classes.tableContainer, tableClasses.table)}>
              <Table
                doNotMessWithContainer={true}
                sortable
                components={{
                  Header: addColumnGroups(columns),
                }}
                actions={
                  !locked
                    ? [
                        {
                          icon: () => <DeleteIcon />,
                          onClick: (_e: any, associatedItem: any) => {
                            setShowUnlinkConfirmation(associatedItem.id);
                          },
                        },
                      ]
                    : undefined
                }
                columns={columns}
                data={props.justifications.sort((a, b) =>
                  ('' + get(a, 'item.customIdentifier')).localeCompare(get(b, 'item.customIdentifier')),
                )}
              />
            </div>
            {selectedDuplicates.length > 0 && (
              <Typography component="p" variant="caption" color="error" className={classes.itemAddErrorText}>
                Selected items are duplicates: {selectedDuplicates.map(customIdentifierForItemId).join(', ')}
              </Typography>
            )}
          </>
        ) : (
          <Typography className={classes.emptyDisclaimer} variant="caption">
            Not Applicable, no protocols have been added.
          </Typography>
        )}
      </div>
      {!locked && (
        <IconButton tooltip="Add Protocol" Icon={Link} onClick={(e) => toggle(e.currentTarget)} includeBackground />
      )}
      {toggled && (
        <ItemSearch
          draftOnly={false}
          supportsChangeOrder={false}
          defaultSearchTerm=""
          itemTypes={validAssociatedItemTypes}
          open={toggled}
          closeSelf={() => {
            toggle(null);
          }}
          onSubmit={(selectedItems) => {
            return new Promise<boolean>((resolve, reject) => {
              const nonDuplicateItems: string[] = [];
              const duplicateItems: string[] = [];
              selectedItems.forEach((item) => {
                if (itemAlreadySelected(item)) {
                  duplicateItems.push(item);
                } else {
                  nonDuplicateItems.push(item);
                }
              });
              setSelectedDuplicates(duplicateItems);
              Promise.all(nonDuplicateItems.map(createJustification))
                .then((id) => {
                  if (id && !Boolean(protocolToInputFocus)) {
                    setProtocolToInputFocus(id[0]);
                  }
                  resolve(true);
                })
                .catch((error) => {
                  reject(`Error creating protocol justification: ${error}`);
                });
              toggle(null);
              setSelectedDuplicates([]);
            });
          }}
          anchorEl={anchor}
          usesCurrentVersion={false}
        />
      )}
      <ConfirmationDialog
        onCancel={() => setShowUnlinkConfirmation(null)}
        onClose={() => setShowUnlinkConfirmation(null)}
        onConfirm={() => {
          removeJustification(showUnlinkConfirmation)
            .then(() => {
              setShowUnlinkConfirmation(null);
            })
            .catch((err) => {
              throw new Error(`Error removing justification: ${err}`);
            });
        }}
        open={!isNull(showUnlinkConfirmation)}
        title="Unlink Confirmation"
      >
        Are you sure you want to unlink this item? Any text in the “Justification for Testing” field will be lost.
      </ConfirmationDialog>
    </>
  );
}
