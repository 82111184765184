import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    collapseButton: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    collapseButtonIcon: {
      color: theme.palette.secondary.main,
    },
    collapseButtonText: {
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(1),
      fontSize: '21px',
      fontWeight: 400,
      lineHeight: '32px',
    },
    collapseButtonExpandIcon: {
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(1),
    },
  }),
);
