import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    panel: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    panelActions: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    panelContent: {
      width: '100%',
    },
  });
