import React, { useContext, useState } from 'react';

import { compose, setDisplayName } from 'recompose';
import clsx from 'clsx';

import { Badge, Drawer, IconButton, withStyles } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Notifications from '@material-ui/icons/Notifications';
import Settings from '@material-ui/icons/Settings';

import LOGO from 'assets/img/logo.svg';
import { CheckListOutlined } from 'assets/icons';
import { withCurrentCompany, WithCurrentCompanyProps } from 'compositions/WithCurrentCompany';
import { TreeDrawerContext } from 'contexts/treeDrawerContext';
import SystemValidated from 'compositions/Sidebar/SystemValidated';
import CentralSnack from 'components/CentralSnack';
import { CurrentUserAvatar } from 'components/UserAvatar';
import { Logo } from 'components';
import { NewItem, NotificationsMenu, SearchMenu, SettingsMenu, UserMenu, TasksMenu } from 'compositions';
import { NavigationContext } from 'contexts/navigationContext';
import { SidebarWorkspacesContext, WorkspaceType } from 'contexts/workspacesContext';
import { NAVIGATION_ITEMS } from 'compositions/Sidebar/NavigationConfig';

import NavigationItem from './NavigationItem';
import { useStyles } from './NavigationItem/styles';

import styles from './styles';
import WithCurrentUser from '../WithCurrentUser';

interface SlimNavProps extends WithCurrentCompanyProps {
  classes: any; // from withStyles HOC
  theme: any; // from withStyles HOC
  activeAppWindowId: string;
  setAppWindow: any;
  currentUser: any;
  currentUserRefetch: any;
}

const SlimNav = (props: SlimNavProps) => {
  const { classes, currentCompanyValidatedSystem, currentUser, currentUserRefetch } = props;

  const navItemClasses = useStyles();

  const { expanded, setExpanded } = useContext(TreeDrawerContext);
  const { selectedNavigationItem, setNavigationItem } = useContext(NavigationContext);
  const { setWorkspaceType } = useContext(SidebarWorkspacesContext);

  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [tasksOpen, setTasksOpen] = useState<boolean>(false);
  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [userOpen, setUserOpen] = useState<boolean>(false);

  const onDisciplineItemClick = (name) => {
    setSearchOpen(false);
    setExpanded(true);
    setNavigationItem(name);
    setWorkspaceType(WorkspaceType.DISCIPLINE);
  };

  const closeAll = () => {
    setSearchOpen(false);
    setTasksOpen(false);
    setNotificationsOpen(false);
    setSettingsOpen(false);
    setUserOpen(false);
  };

  const buildDrawerComponent = (children, open: boolean, disableGutter?: boolean) => {
    const { classes } = props;
    return (
      <Drawer
        classes={{ paper: `${classes.drawerPaper} ${disableGutter ? classes.disableGutter : ''}` }}
        elevation={1}
        open={open}
        onClose={closeAll}
      >
        <span className={classes.offset} />
        {children}
      </Drawer>
    );
  };

  const logoClass = clsx(expanded && classes.logoHided, classes.logoContainer);

  return (
    <div id="slimnav" className={classes.slimNav}>
      <div className={logoClass}>
        <IconButton
          classes={{ root: classes.logoButton }}
          aria-haspopup="true"
          aria-label={'Close All'}
          color="inherit"
          onClick={closeAll}
          id="home-button"
        >
          <Logo src={LOGO} classes={classes} />
        </IconButton>
        <SystemValidated simpleView={true} currentCompanyValidatedSystem={currentCompanyValidatedSystem} />
      </div>
      <section id={'disciplines'} className={classes.disciplinesPanel}>
        {NAVIGATION_ITEMS.map((discipline) => (
          <NavigationItem
            {...discipline}
            key={discipline.name}
            selected={selectedNavigationItem.name === discipline.name}
            onClick={onDisciplineItemClick}
          />
        ))}
      </section>
      <div className={classes.slimNavSection}>
        <div className={classes.slimNavItem}>
          <NewItem color={'#fff'} classes={{ newItemButton: classes.newItemButton }} />
        </div>
        <div className={classes.slimNavItem}>
          <IconButton
            className={navItemClasses.disciplinesIcon}
            aria-haspopup="true"
            aria-label={'Open Search'}
            color="inherit"
            onClick={() => {
              setTasksOpen(false);
              setNotificationsOpen(false);
              setSettingsOpen(false);
              setUserOpen(false);
              setSearchOpen(!searchOpen);
            }}
            id="search-button"
          >
            <Search id="search-icon" />
          </IconButton>
          {buildDrawerComponent(<SearchMenu closeSelf={closeAll} />, searchOpen)}
        </div>
        <div className={classes.slimNavItem}>
          <Badge
            badgeContent={currentUser.assignedTasksCount}
            color="default"
            overlap="circle"
            classes={{ badge: classes.badge }}
          >
            <IconButton
              className={navItemClasses.disciplinesIcon}
              aria-haspopup="true"
              aria-label={'My Tasks'}
              color="inherit"
              id="my-tasks-button"
              onClick={() => {
                setSearchOpen(false);
                setSettingsOpen(false);
                setUserOpen(false);
                setTasksOpen(!tasksOpen);
                setNotificationsOpen(false);
                currentUserRefetch();
              }}
            >
              <CheckListOutlined />
            </IconButton>
          </Badge>
          {buildDrawerComponent(<TasksMenu onClose={() => setTasksOpen(false)} />, tasksOpen, true)}
        </div>
        <div className={classes.slimNavItem}>
          <Badge
            badgeContent={currentUser.incompleteNotificationsCount}
            color="default"
            overlap="circle"
            classes={{ badge: classes.badge }}
          >
            <IconButton
              className={navItemClasses.disciplinesIcon}
              aria-haspopup="true"
              aria-label={'Open Notifications'}
              color="inherit"
              id="notifications-button"
              onClick={() => {
                setSearchOpen(false);
                setSettingsOpen(false);
                setUserOpen(false);
                setTasksOpen(false);
                setNotificationsOpen(!notificationsOpen);
                currentUserRefetch();
              }}
            >
              <Notifications />
            </IconButton>
          </Badge>
          {buildDrawerComponent(<NotificationsMenu closeSelf={closeAll} />, notificationsOpen)}
        </div>

        <div className={classes.slimNavItem}>
          <IconButton
            className={navItemClasses.disciplinesIcon}
            aria-haspopup="true"
            aria-label={'Open Settings'}
            color="inherit"
            id="settings-button"
            onClick={() => {
              setNotificationsOpen(false);
              setSearchOpen(false);
              setUserOpen(false);
              setSettingsOpen(!settingsOpen);
            }}
          >
            <Settings />
          </IconButton>
          {buildDrawerComponent(<SettingsMenu closeSelf={closeAll} />, settingsOpen)}
        </div>
        <div className={classes.slimNavItem}>
          <CurrentUserAvatar
            onClick={() => {
              setNotificationsOpen(false);
              setSettingsOpen(false);
              setSearchOpen(false);
              setUserOpen(!userOpen);
            }}
            aria-label={'Open User Profile'}
            id="profile-button"
            invertColor
          />
          {buildDrawerComponent(<UserMenu />, userOpen)}
        </div>
        <CentralSnack />
      </div>
    </div>
  );
};

export default compose(
  withCurrentCompany,
  setDisplayName('SlimNav'),
  WithCurrentUser,
  // @ts-ignore
  withStyles(styles, { withTheme: true }),
)(SlimNav);
