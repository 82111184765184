import PropTypes from 'prop-types';
import React from 'react';

function Logo({ children, classes, src }) {
  return (
    <div className={classes.logo}>
      <img src={src} alt="logo" className={classes.logoImg} id="logo" />
      {children}
    </div>
  );
}

Logo.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    logoImg: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  }).isRequired,
};

Logo.defaultProps = {
  children: undefined,
};

export default Logo;
