import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { SupplierQuestionnaireRecordVersion } from './__generated__/SupplierQuestionnaireRecordVersion';

export const GET_SUPPLIER_QUESTIONNAIRE_RECORD = gql`
  query SupplierQuestionnaireRecordVersion($id: ID!) {
    supplierQuestionnaireRecordVersion(id: $id) {
      id
      locked
      inputMethod
      body
      reasonForChange
      descriptionOfChange
      pdfDataUpload
      unexecutedRecordAttachment {
        id
        url
        creator {
          id
          fullName
        }
        createdAt
      }
      executedRecordAttachment {
        id
        url
        creator {
          id
          fullName
        }
        createdAt
      }
      supplierVersion {
        id
        title
        releasedAt
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export const UPDATE_VERIFICATION_AND_VALIDATION_PLAN_VERSION = gql`
  mutation UpdateSupplierQuestionnaireRecordVersion(
    $id: ID!
    $inputMethod: String
    $body: String
    $reasonForChange: String
    $descriptionOfChange: String
    $associatedSupplierId: String
  ) {
    updateSupplierQuestionnaireRecordVersion(
      id: $id
      inputMethod: $inputMethod
      body: $body
      reasonForChange: $reasonForChange
      descriptionOfChange: $descriptionOfChange
      associatedSupplierId: $associatedSupplierId
    ) {
      supplierQuestionnaireRecordVersion {
        id
      }
    }
  }
`;

export const ADD_ASSOCIATED_SUPPLIER = gql`
  mutation AddAssociatedSupplier($id: ID!, $associatedSupplierId: String) {
    updateSupplierQuestionnaireRecordVersion(id: $id, associatedSupplierId: $associatedSupplierId) {
      supplierQuestionnaireRecordVersion {
        id
      }
    }
  }
`;

export const SUPPLIER_OPTIONS_QUERY = gql`
  query SuppliersOptionsQuery {
    suppliers {
      id
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        versionIdentifier
        currentStatusName
        plannedQualificationQuestionnaire
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<SupplierQuestionnaireRecordVersion>(GET_SUPPLIER_QUESTIONNAIRE_RECORD, {
    variables: { id },
  });
}
