import React from 'react';

import { Spacer } from 'components';

import { navigate } from '@reach/router';
import { Typography, Button, withStyles } from '@material-ui/core';

import { Trans } from '@lingui/macro';

import styles from './styles';

function AssociatedRiskAnalysis({
  classes,
  riskAnalysisKey,
  riskAnalysisTitle,
  riskAnalysisVersion,
  riskAnalysisId,
  handleComplete,
}) {
  const riskAnalysisDetails = (
    <Typography variant="body1">{`${riskAnalysisKey} ${riskAnalysisVersion}: ${riskAnalysisTitle}`}</Typography>
  );
  return (
    <>
      <Typography variant="h3">
        <Trans>Associated Risk Analysis</Trans>
      </Typography>
      <Spacer factor={1} />
      {riskAnalysisDetails}

      <Spacer factor={2} />

      <div style={{ display: 'flex' }}>
        <Button
          id="btn-edit"
          style={{ flex: 1, marginRight: '5px' }}
          onClick={() => handleComplete()}
          color="primary"
          variant="outlined"
        >
          <Trans>Dismiss</Trans>
        </Button>

        <Button
          id="btn-view"
          style={{ flex: 1, marginLeft: '5px' }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate(`/category/risk-analyses/${riskAnalysisId}`)}
        >
          <Trans>View RAN</Trans>
        </Button>
      </div>
    </>
  );
}

export default withStyles(styles)(AssociatedRiskAnalysis);
