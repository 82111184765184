import React from 'react';
import { RecordWorkflowStatuses } from 'workflows/statuses';
import { YesOrNo } from '../views';

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => (
        <YesOrNo
          heading={'Lock for Review'}
          prompt={'Confirm attachment of signed document?'}
          closeOnNo
          yesLabel="Lock"
          noLabel="Cancel"
          {...props}
        />
      ),
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ confirmTransition }) => {
    return confirmTransition
      ? {
          toStatus: RecordWorkflowStatuses.InProgress.id,
        }
      : null;
  },
};
