import React from 'react';

import { AccountCircle } from '@material-ui/icons';

import Panel from 'components/core/Panel';
import Table from 'components/core/Table';
import VersionAndStatusDot from 'components/VersionAndStatusDot';

interface ValidationDeviationsTableProps {
  validationDeviations;
}

const ValidationDeviationsTable = (props: ValidationDeviationsTableProps) => {
  const { validationDeviations } = props;

  return (
    <Panel>
      <Panel.Heading icon={AccountCircle}>Validation Deviations</Panel.Heading>
      <Table
        data={validationDeviations}
        sortable
        columns={[
          {
            title: 'Associated Protocol',
            field: 'parentRecordValidationProtocol.item.customIdentifier',
            render: (row) => (
              <div style={{ display: 'inline-flex' }}>
                <span style={{ marginRight: 8 }}>{row.parentRecordValidationProtocol.item.customIdentifier}</span>
                <VersionAndStatusDot
                  style={{ fontSize: 13 }}
                  version={row.parentRecordValidationProtocol.versionIdentifier}
                  status={row.parentRecordValidationProtocol.currentStatus.name}
                />
              </div>
            ),
          },
          {
            title: 'Origination Record',
            field: 'parentRecord.item.customIdentifier',
            render: (row) => (
              <div style={{ display: 'inline-flex' }}>
                <span style={{ marginRight: 8 }}>{row.parentRecord.item.customIdentifier}</span>
                <VersionAndStatusDot
                  style={{ fontSize: 13 }}
                  version={row.parentRecord.versionIdentifier}
                  status={row.parentRecord.currentStatus.name}
                />
              </div>
            ),
          },
          {
            title: 'Deviation ID',
            field: 'deviationId',
          },
          {
            title: 'ID',
            field: 'item.customIdentifier',
          },
          {
            title: 'Title',
            field: 'item.currentVersion.title',
          },
          {
            title: 'Version',
            render: (row) => (
              <VersionAndStatusDot
                style={{ fontSize: 13 }}
                version={row.versionIdentifier}
                status={row.currentStatus.name}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};

export { ValidationDeviationsTable };
