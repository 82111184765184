import { useState } from 'react';
import get from 'lodash/get';

import { FULL_CATEGORIES } from 'categories';
import withActiveCategory from 'compositions/WithCategories/withActiveCategory';

function AppWindowControl({ activeCategory, children }) {
  const [selectedAppWindowId, setAppWindow] = useState('product');
  const appWindowId = get(activeCategory, 'appWindow', selectedAppWindowId);
  const appWindow = get(FULL_CATEGORIES, appWindowId);
  return children({ appWindow, setAppWindow });
}

export default withActiveCategory(AppWindowControl);
