import React, { FC } from 'react';
import clsx from 'clsx';

import { TextField, OutlinedTextFieldProps } from '@material-ui/core';

import { useStyles } from './styles';

interface Props extends Pick<OutlinedTextFieldProps, Exclude<keyof OutlinedTextFieldProps, 'variant'>> {
  disabled?: boolean;
  defaultValue?: string;
}

const SettingsInput: FC<Props> = ({ defaultValue, disabled, ...props }) => {
  const classes = useStyles();

  return (
    <TextField
      defaultValue={defaultValue}
      disabled={disabled}
      InputProps={{
        classes: {
          input: classes.input,
          disabled: classes.disabled,
          notchedOutline: clsx({ [classes.disabled]: disabled }),
        },
      }}
      variant={'outlined'}
      {...props}
    />
  );
};

export default SettingsInput;
