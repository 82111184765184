import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClick: () => void;
  icon: any;
  title: string;
}

const CollapseButton: FC<Props> = ({ open, icon: Icon, title, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.collapseButton} onClick={onClick}>
      <div className={classes.collapseButtonIcon}>{<Icon />}</div>
      <Typography variant="h2" className={classes.collapseButtonText}>
        {title}
      </Typography>
      {open ? (
        <ExpandLess className={classes.collapseButtonExpandIcon} />
      ) : (
        <ExpandMore className={classes.collapseButtonExpandIcon} />
      )}
    </div>
  );
};

export default CollapseButton;
