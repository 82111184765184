import React from 'react';
import { Step } from '../util';
import { useMutation } from 'react-apollo';
import { REMOVE_MEMBERS } from './gql';
import { ConfirmationDialog } from 'components';

interface Props {
  onCancel: () => void;
  onClose: () => void;
  onRemove: (removedSteps: Step[]) => void;
  stepsToRemove: Step[];
}

function TrashConfirmation(props: Props) {
  const [removeTestSteps] = useMutation(REMOVE_MEMBERS, {
    refetchQueries: ['DeviceVerificationVersionQuery'],
  });

  return (
    <ConfirmationDialog
      onCancel={props.onCancel}
      onClose={props.onClose}
      onConfirm={() => {
        removeTestSteps({ variables: { ids: props.stepsToRemove.map((step) => step.memberId) } })
          .then(() => {
            props.onRemove(props.stepsToRemove);
            props.onClose();
          })
          .catch((e) => {
            throw new Error(`Error removing test steps: ${e}`);
          });
      }}
      open
      title="Confirm Trash Action"
    >
      Are you sure you want to remove {`<${props.stepsToRemove.map((step) => step.stepId)}>`} and mark as trashed?
      Making this change will remove any connections attached to{' '}
      {`<${props.stepsToRemove.map((step) => `${step.stepId}`)}>`}.
    </ConfirmationDialog>
  );
}

export default TrashConfirmation;
