import { get } from 'lodash';
import React, { useState } from 'react';
import { Query } from 'react-apollo';

import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import AttachFile from '@material-ui/icons/AttachFile';
import Notes from '@material-ui/icons/Notes';

import {
  ContentSection,
  DocumentUploader,
  PDFViewer,
  Spacer,
  TextAreaField,
} from 'components';
import attachmentQuery from 'utils/gql/attachmentQuery';

const withAttachmentData = (WrappedComponent) => (componentProps) => (
  <Query
    query={attachmentQuery}
    skip={!get(componentProps, 'versionData.id')}
    variables={{
      versionId: get(componentProps, 'versionData.id'),
    }}
  >
    {({ data, loading, refetch }) => {
      refetch();
      const attachment = get(
        data,
        'version.virtualAttributes[0].attachments[0]',
      );
      return (
        <WrappedComponent
          {...componentProps}
          filename={get(attachment, 'filename')}
          url={get(attachment, 'url')}
          attachmentId={get(attachment, 'id')}
          refetchAttachments={refetch}
          loading={loading}
        />
      );
    }}
  </Query>
);

const PayloadFields = ({ layoutClasses, versionData, ...props }) => {
  const [loadingAttachment, setLoadingAttachment] = useState(false);

  return (
    <>
      <Grid item xs={8} className={layoutClasses.gridGutterRight}>
        <ContentSection
          fillHeight={false}
          noSpacer
          LabelText={
            <>
              <Notes className={layoutClasses.icon} />
              <Trans>Architecture Description</Trans>
            </>
          }
        >
          <Spacer factor={1} />
          <TextAreaField
            locked={versionData.locked}
            id="architecture-description-text-field"
            initialValue={versionData.architectureDescription}
            versionId={versionData.id}
            attrName="architecture_description"
            rows={5}
            placeholder="Enter description"
            refetchQueries={['systemArchitectureQuery']}
          />
        </ContentSection>
      </Grid>

      <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
        <ContentSection
          LabelText={
            <>
              <AttachFile className={layoutClasses.icon} />
              <Trans>Document</Trans>
            </>
          }
          fillHeight={false}
        >
          <Spacer factor={1} />
          <DocumentUploader
            attrName={'attachment'}
            attachmentId={props.attachmentId}
            filename={props.filename}
            locked={versionData.locked}
            onCompleted={props.refetchAttachments}
            beforeUpload={() => setLoadingAttachment(true)}
            beforeDelete={() => setLoadingAttachment(false)}
            versionId={versionData.id}
          />
        </ContentSection>
      </Grid>

      <Grid
        item
        xs={12}
        className={`${layoutClasses.grid} ${layoutClasses.pdfView}`}
      >
        <PDFViewer loading={loadingAttachment} url={props.url} />
      </Grid>
    </>
  );
};

export default withAttachmentData(PayloadFields);
