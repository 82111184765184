import React from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import IconButton from 'components/IconButton';

interface Props {
  expanded: boolean;
  setExpanded: (newState: boolean) => void;
  size?: 'small' | 'medium';
}

function ExpandCarrot(props: Props) {
  return (
    <IconButton
      Icon={props.expanded ? ExpandLessIcon : ExpandMoreIcon}
      size={props.size || 'small'}
      onClick={() => props.setExpanded(!props.expanded)}
    />
  );
}

export default ExpandCarrot;
