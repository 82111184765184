import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';

import { flowRight } from 'lodash';
import { setDisplayName } from 'recompose';
import { Trans } from '@lingui/macro';

import withAuditTrail from './withAuditTrail';
import AuditTrailItem from './AuditTrailItem';

import useStyles from './styles';

import { GetAuditTrail_item_auditTrail } from './__generated__/GetAuditTrail';

export interface AuditTrailTransition extends GetAuditTrail_item_auditTrail {}

export interface AuditTrailProps {
  auditTrail: AuditTrailTransition[];
  currentCompanyProfessionalLicense: boolean;
  customIdentifier: string;
}

function AuditTrail(props: AuditTrailProps) {
  const classes = useStyles();
  const { customIdentifier, auditTrail = [], currentCompanyProfessionalLicense } = props;

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography variant="subtitle1" className={classes.headerText}>
          {customIdentifier} <Trans>Audit Trail</Trans>
        </Typography>
      </div>
      <div className={classes.container} data-testid="auditTrailContainer">
        {currentCompanyProfessionalLicense ? (
          auditTrail.map((history: AuditTrailTransition, i) => <AuditTrailItem history={history} key={i} />)
        ) : (
          <Grid container alignContent="center" className={classes.messageContainer} item spacing={0}>
            <Grid container justify="center">
              <Grid item className={classes.messageItem} xs={12}>
                <Typography variant="body2">
                  <Trans>
                    To activate audit trails for compliance, please contact your company administrator to upgrade your
                    license.
                  </Trans>
                </Typography>
              </Grid>
              <Grid item className={classes.messageItem} xs={12}>
                <Typography variant="subtitle1" className={classes.messageMain}>
                  <Trans>21 CFR Part 11 compliant audit trails not active.</Trans>
                </Typography>
              </Grid>
              <Grid item className={classes.messageItem} xs={12}>
                <Info color="primary" fontSize="large" className={classes.infoIcon} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}

export default flowRight([setDisplayName('AuditTrail'), withAuditTrail])(AuditTrail);
