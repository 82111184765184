import React from 'react';

import * as Sentry from '@sentry/browser';
import AccessRestricted from 'pages/AccessRestricted';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      return /access denied$/i.test(this.state.error.message) ? <AccessRestricted /> : null;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
