import get from 'lodash/get';
import React from 'react';
import { Trans } from '@lingui/macro';
import { useQuery } from 'react-apollo';

import { Grid } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';

import DocumentBuilder from 'compositions/DocumentBuilder';
import { ContentSection, DocumentStyleUploader, PDFViewer, Spacer, TextAreaField } from 'components';
import { InputMethod } from '__generated__/globalTypes';

const PayloadFields = ({ layoutClasses, query, versionId, itemType, ...props }) => {
  const versionName = `${itemType}Version`;
  const versionQuery = `${itemType}Query`;

  const { data, loading, error, refetch } = useQuery(query, {
    variables: { id: versionId },
    fetchPolicy: 'network-only',
  });

  if (loading || !data) return null;

  if (error) throw new Error(`Error loading ${itemType} Version ${versionId}: ${error.message}`);
  const versionData = data[versionName];
  if (versionData.inputMethod === InputMethod.BODY)
    return (
      <Grid container>
        {versionData.hasOwnProperty('summary') && (
          <>
            <Grid item xs={12}>
              <ContentSection
                fillHeight={false}
                LabelText={
                  <>
                    <Sort className={layoutClasses.icon} />
                    <Trans>Summary</Trans>
                  </>
                }
              >
                <Spacer factor={1} />
                <TextAreaField
                  locked={versionData.locked}
                  initialValue={versionData.summary}
                  versionId={versionId}
                  attrName={'summary'}
                  rows={4}
                  refetchQueries={[versionQuery]}
                />
              </ContentSection>
            </Grid>
            <Spacer factor={1} />
          </>
        )}
        <Grid item xs={12}>
          <DocumentBuilder
            itemType={props.itemType}
            refetchQueries={[versionName, 'timelineQuery']}
            richTextOptionSet="large"
            layoutClasses={layoutClasses}
            versionData={versionData}
          />
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid container xs={8} justify="flex-end" alignItems="center" direction="row" className={layoutClasses.grid}>
        <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
          <DocumentStyleUploader versionData={versionData} onUpdate={() => refetch()} />
        </Grid>
      </Grid>

      <Grid container justify="flex-end" alignItems="center" direction="row" className={layoutClasses.grid}>
        {versionData.attachment && (
          <Grid item xs={12} className={`${layoutClasses.grid} ${layoutClasses.pdfView}`}>
            <PDFViewer url={get(versionData, 'attachment.url')} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PayloadFields;
