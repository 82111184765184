import ResolveFailure from '../views/ResolveFailure';
import { AttachmentDependentWorkflowStatuses } from 'workflows/statuses';

export default {
  initialStep: 'resolve',
  internalSteps: {
    resolve: {
      component: ResolveFailure,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: () => ({
    toStatus: AttachmentDependentWorkflowStatuses.ToDo.id,
  }),
};
