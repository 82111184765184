import { t } from '@lingui/macro';
import Group from '@material-ui/icons/Group';

import slugs from '../../displaySlugs';
import gql from 'graphql-tag';

import { docStylePayloadFields } from '../mixins/DocumentBased/gql';

import { QueryBasedPayloadFields } from '../mixins/DocumentBased/QueryBasedPayloadFields';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const versionQuery = gql`
  query riskManagementPlanVersion($id: ID!) {
    riskManagementPlanVersion(id: $id) {
      ${docStylePayloadFields}
    }
  }
`;
const itemType = 'riskManagementPlan';
const PayloadFields = QueryBasedPayloadFields(versionQuery, itemType);

export default {
  categoryLabel: t`Risk Management Plan`,
  displaySlug: slugs[itemType],
  label: t`Risk Management Plan`,
  itemType,
  icon: Group,
  listViewQuery: gql`
    query riskManagementPlansListViewQuery {
      riskManagementPlans {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
      }
    }
  `,
  listViewColumns,
  PayloadFields,
};
