import React from 'react';

import { Card, Typography } from '@material-ui/core';
import { Assignment, Info as InfoIcon, Link as LinkIcon } from '@material-ui/icons';
import get from 'lodash/get';

import clsx from 'clsx';

import Table from 'components/core/Table';
import { itemLink } from 'components/MakeLink';
import { DeleteIcon, VersionCell } from 'components/VersionTables';
import { IconButton, WorkflowIcon } from 'components';
import Spacer from 'components/Spacer';
import LabelText from 'components/LabelText';
import { ItemSearch } from 'compositions';
import { useToggleablePopover } from 'hooks/useToggleablePopover';

import useStyles from './styles';

export interface AssociatedItemsProps {
  versionId: string;
  locked: boolean;
  associatedItems: any;
  showNotice?: boolean;
  handlers: {
    remove: (id: string) => Promise<any>;
    addAssociatedItem: (id: any) => Promise<any>;
  };
}

const AssociatedItems = (props: AssociatedItemsProps) => {
  const { associatedItems = [], locked, showNotice = true } = props;
  const classes = useStyles();

  const [searchOpen, searchAnchor, toggleSearch] = useToggleablePopover();

  const noticeContainerClass = clsx([
    classes.noticeContainer,
    locked && classes.noticeContainerWidthLocked,
    !locked && classes.noticeContainerWidthUnlocked,
  ]);
  return (
    <div className={classes.container}>
      <LabelText labelText="Associated Items" Icon={Assignment} />
      <Spacer factor={4} />
      {showNotice && (
        <div className={noticeContainerClass}>
          <section className={classes.noticeSection}>
            <Typography variant="subtitle1" className={classes.noticeHeading}>
              <InfoIcon fontSize="small" />
              <span>Reference Information</span>
            </Typography>
            <Typography variant="subtitle2" className={classes.noticeMessage}>
              <span>
                Title, Version, and Status displayed are for reference only and are subject to change as each item is
                updated.
              </span>
            </Typography>
          </section>
        </div>
      )}
      <div className={classes.tableContainer}>
        {associatedItems.length > 0 ? (
          <Table
            data={associatedItems}
            actions={
              !locked
                ? [
                    {
                      icon: () => <DeleteIcon />,
                      onClick: (_e: any, associatedItem: any) => {
                        props.handlers.remove(associatedItem.id).catch((err: { message: any }) => {
                          throw new Error(`Error removing associated item: ${err.message}`);
                        });
                      },
                    },
                  ]
                : undefined
            }
            columns={[
              {
                title: 'ID',
                field: 'id',
                cellStyle: { borderRight: '2px solid #ebeced' },
                render: (item: any) => (
                  <a href={itemLink(item)} target="_blank" rel="noopener noreferrer">
                    {item.customIdentifier}
                  </a>
                ),
              },
              {
                title: 'Current Title',
                cellStyle: { paddingLeft: '6px' },
                render: (item: any) => {
                  return (
                    <Typography variant="body2">
                      {get(item, 'workingVersion.title', item.currentVersion.title)}
                    </Typography>
                  );
                },
              },
              {
                title: 'Current Version',
                align: 'center',
                render: (item: any) => {
                  return (
                    <VersionCell
                      version={get(item, 'workingVersion.versionIdentifier', item.currentVersion.versionIdentifier)}
                      status={get(item, 'workingVersion.currentStatus.name', item.currentVersion.currentStatus.name)}
                    />
                  );
                },
              },
              {
                title: 'Current Status',
                align: 'center',
                render: (item: any) => (
                  <WorkflowIcon
                    type={get(item, 'workingVersion.currentStatus.name', item.currentVersion.currentStatus.name)}
                    completed
                    size="small"
                    displayTooltip
                  />
                ),
              },
            ]}
          />
        ) : (
          <Card className={classes.noItemsPanel} variant={'outlined'}>
            <Typography variant="caption">No items associated.</Typography>
          </Card>
        )}
      </div>
      <div>
        {!locked && (
          <IconButton
            disabled={locked}
            onClick={(e) => toggleSearch(e.currentTarget)}
            Icon={LinkIcon}
            includeBackground
          />
        )}
        <ItemSearch
          usesCurrentVersion={false}
          draftOnly={false}
          supportsChangeOrder={false}
          defaultSearchTerm={null}
          open={searchOpen}
          closeSelf={() => toggleSearch(null)}
          onSubmit={(associatedItemVersionIds) =>
            props.handlers
              .addAssociatedItem(associatedItemVersionIds)
              .then(() => true)
              .catch(() => false)
          }
          anchorEl={searchAnchor}
        />
      </div>
    </div>
  );
};

export default AssociatedItems;
