import React, { useState, useEffect } from 'react';
import { InputAdornment, TextField, Typography, Modal, Paper, Tooltip, withStyles } from '@material-ui/core';
import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import startCase from 'lodash/startCase';
import xor from 'lodash/xor';
import flowRight from 'lodash/flowRight';
import isEmpty from 'lodash/isEmpty';

import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';

import { RecordsAsCategoryTree, Loading, Spacer } from 'components';

import { useSearchResultMap } from 'hooks/items/useSearch';
import { withItemTypeMetadata } from 'utils/withItemTypeMetadata';

import ItemLinkButton from '../ItemLinkButton';
import styles from './styles';

function AdvancedLinkModal({
  classes,
  i18n,
  open,
  onClose,
  direction,
  baseRecordId,
  baseRecordCustomId,
  baseRecordTitle,
  theme,
  suggestedItems,
  excludedIds,
  allItemTypeMetadata,
}) {
  const [selectedItemType, setSelectedItemType] = useState(null);

  const [filterQueryParam, setFilterQueryParam] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [selectedItemTypes, setSelectedItemTypes] = useState([]);
  const [skipSearch, setSkipSearch] = useState(true);
  const [lastSelectedCategory, setLastSelectedCategory] = useState(null);
  const [itemSearchResults, setItemSearchResults] = useState({});

  const resetDefaults = () => {
    setSearchTerm(null);
    setFilterQueryParam(null);
    setSelectedRecords([]);
    setLastSelectedCategory(null);
    setSkipSearch(true);
  };
  const resetDefaultsAndClose = (e) => {
    setSelectedItemType(null);
    resetDefaults();
    onClose(e);
    setLastSelectedCategory(null);
    setSkipSearch(true);
  };
  const toggleRecordSelection = (record) => {
    const newRecords = xor(selectedRecords, [record.id]);
    setSelectedRecords(newRecords);
  };
  const handleCategoryClick = (itemType) => {
    if (itemType === lastSelectedCategory) {
      return;
    }
    setSelectedItemTypes([itemType]);
    setLastSelectedCategory(itemType);
    setSkipSearch(false);
  };

  const { resultMap, loading } = useSearchResultMap({
    itemTypes: selectedItemTypes,
    query: filterQueryParam,
    linksGraphRootId: baseRecordId,
    excludedIds: excludedIds,
    skipSearch: skipSearch,
  });
  const filterEnabled = selectedItemType !== null;

  const onKeyPress = (e) => {
    const term = e.target.value;
    if (term && e.key === 'Enter') {
      setFilterQueryParam(term.trim());
    }
  };

  const handleEmptyInput = (e) => {
    const term = e.target.value;
    if (!term.trim().length) resetDefaults();
    if (term) setSearchTerm(term);
  };

  useEffect(() => {
    if (!loading && resultMap && !isEmpty(resultMap)) {
      setItemSearchResults((prevItemSearchResults) => ({ ...prevItemSearchResults, ...resultMap }));
    } else if (!isEmpty(allItemTypeMetadata) && isEmpty(itemSearchResults)) {
      const stubs = Object.values(allItemTypeMetadata)
        .filter((it) => it.itemsCount > 0)
        .reduce((acc, it) => {
          acc[it.name] = [''];
          return acc;
        }, {});

      setItemSearchResults(stubs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, allItemTypeMetadata]);

  return (
    <Modal open={Boolean(open)} onClose={resetDefaultsAndClose} className={classes.root}>
      <div className={classes.modalContentContainer}>
        <Paper style={{ width: 600 }}>
          <div className={classes.container}>
            <Close className={classes.closeIcon} onClick={resetDefaultsAndClose} />
          </div>
          <div className={classes.headerContainer}>
            <Typography variant="body2" component="div" className={classes.suggestedItems}>
              Create Relationship
            </Typography>
            <div className={classes.rightHeader}>
              <Typography variant="body2" component="div" className={classes.fullItemList} onClick={() => {}}>
                Full Item List
              </Typography>
              <Typography
                variant="body2"
                component="div"
                className={classes.suggested}
                onClick={(e) => {
                  e.preventDefault();
                  if (suggestedItems && suggestedItems.onClick) {
                    suggestedItems.onClick();
                  }
                }}
              >
                Suggested
              </Typography>
            </div>
          </div>
          <div className={classes.results}>
            {loading && <Loading size={30} style={{ height: 70 }} />}
            <RecordsAsCategoryTree
              selectedRecords={selectedRecords}
              onRecordClick={toggleRecordSelection}
              onCreateItem={toggleRecordSelection}
              onNavigate={(itemType) => {
                setSelectedItemType(itemType);
                if (itemType === null) resetDefaults();
              }}
              selectedItemType={selectedItemType}
              categoryRecordsMap={itemSearchResults}
              createItemButtonText={i18n._(t`Create & Add Link`)}
              displayHeader={false}
              displayAll={false}
              showFullCategoryTree={false}
              onCategoryClick={handleCategoryClick}
              isLoading={loading}
            />
          </div>
          <hr className={classes.splitter} />
          {selectedItemType && (
            <Typography variant="body2" component="span" style={{ fontSize: 9, paddingLeft: 16 }}>
              <Trans>{`Search in "${startCase(selectedItemType)}"`}</Trans>
            </Typography>
          )}
          <Tooltip
            title={filterEnabled ? '' : 'Choose specific itemType to search'}
            aria-label={filterEnabled ? '' : 'Choose specific itemType to search'}
            placement="top"
          >
            <TextField
              disabled={!filterEnabled}
              autoComplete="off"
              id="link-input"
              fullWidth
              variant="outlined"
              margin="normal"
              value={searchTerm || ''}
              onChange={handleEmptyInput}
              className={classes.linkTextField}
              onKeyPress={onKeyPress}
              InputProps={{
                'aria-label': i18n._(t`Search`),
                classes: {
                  notchedOutline: classes.notchedOutline,
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
                startAdornment: (
                  <InputAdornment style={{ paddingTop: 2 }} position="start">
                    <Search
                      style={{
                        fontSize: 20,
                        marginLeft: theme.spacing(1),
                        color: theme.palette.blueDark,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
          <ItemLinkButton
            disabled={selectedRecords.length === 0}
            linkMutationArgs={{
              input: {
                rootItemId: baseRecordId,
                linkedItemIds: selectedRecords,
                direction: direction,
              },
            }}
            onMutationCompleted={resetDefaultsAndClose}
          />
          <Spacer factor={2} />
        </Paper>
      </div>
    </Modal>
  );
}

export default flowRight([withItemTypeMetadata, withStyles(styles, { withTheme: true }), withI18n()])(
  AdvancedLinkModal,
);
