import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import Business from '@material-ui/icons/Business';

import { useQuery } from 'react-apollo';
import { get } from 'lodash';

import AgGridTable from 'components/AgGridTable';
import { Breadcrumbs, Loading, MakeLink, VersionAndStatusDot } from 'components';
import { ShortTextCell } from 'components/TableView/Fields/ShortText';
import { isColumnGroupedBy } from 'utils/agGridHelpers';

import { useStyles } from './styles';
import { riskTraceMatricexQuery } from './gql';

const columns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.requirementsSetVersion.item.customIdentifier',
    headerName: 'Associated Set #1',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return get(row.value.currentVersion.requirementsSetVersion, 'item.customIdentifier', '-');
    },
    render: (row) => {
      if (!row.data.currentVersion.requirementsSetVersion) return null;
      if (isColumnGroupedBy(row, 'currentVersion.requirementsSetVersion.item.customIdentifier')) {
        return row.value;
      }
      let associatedSetVersion1 = row.data.currentVersion.requirementsSetVersion;
      return (
        <MakeLink newTab={true} item={associatedSetVersion1.item}>
          <ShortTextCell data={get(associatedSetVersion1, 'item.customIdentifier')} />
        </MakeLink>
      );
    },
  },
  {
    field: 'currentVersion.requirementsSetVersion',
    headerName: 'Associated Set #1 Version',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      if (!row.value.currentVersion.requirementsSetVersion) return '';
      return JSON.stringify({
        versionIdentifier: row.value.currentVersion.requirementsSetVersion.versionIdentifier,
        currentStatusName: row.value.currentVersion.requirementsSetVersion.currentStatus.name,
      });
    },
    render: (row) => {
      if (!row.data.currentVersion.requirementsSetVersion) return null;
      let versionIdentifier;
      let currentStatusName;
      if (isColumnGroupedBy(row, 'currentVersion.requirementsSetVersion')) {
        versionIdentifier = JSON.parse(row.value).versionIdentifier;
        currentStatusName = JSON.parse(row.value).currentStatusName;
      } else {
        versionIdentifier = row.data.currentVersion.requirementsSetVersion.versionIdentifier;
        currentStatusName = row.value.currentVersion.requirementsSetVersion.currentStatus.name;
      }
      return <VersionAndStatusDot version={versionIdentifier} status={currentStatusName} />;
    },
  },
  {
    field: 'currentVersion.riskAnalysisVersion.item.customIdentifier',
    headerName: 'Associated Set #2',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return get(row.value.currentVersion.riskAnalysisVersion, 'item.customIdentifier', '-');
    },
    render: (row) => {
      if (!row.data.currentVersion.riskAnalysisVersion) return null;
      if (isColumnGroupedBy(row, 'currentVersion.riskAnalysisVersion.item.customIdentifier')) {
        return row.value;
      }
      let associatedSetVersion2 = row.data.currentVersion.riskAnalysisVersion;
      return (
        <MakeLink newTab={true} item={associatedSetVersion2.item}>
          <ShortTextCell data={get(associatedSetVersion2, 'item.customIdentifier')} />
        </MakeLink>
      );
    },
  },
  {
    field: 'currentVersion.riskAnalysisVersion',
    headerName: 'Associated Set #2 Version',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      if (!row.value.currentVersion.riskAnalysisVersion) return '';
      return JSON.stringify({
        versionIdentifier: row.value.currentVersion.riskAnalysisVersion.versionIdentifier,
        currentStatusName: row.value.currentVersion.riskAnalysisVersion.currentStatus.name,
      });
    },
    render: (row) => {
      if (!row.data.currentVersion.riskAnalysisVersion) return null;
      let versionIdentifier;
      let currentStatusName;
      if (isColumnGroupedBy(row, 'currentVersion.riskAnalysisVersion')) {
        versionIdentifier = JSON.parse(row.value).versionIdentifier;
        currentStatusName = JSON.parse(row.value).currentStatusName;
      } else {
        versionIdentifier = row.data.currentVersion.riskAnalysisVersion.versionIdentifier;
        currentStatusName = row.value.currentVersion.riskAnalysisVersion.currentStatus.name;
      }
      return <VersionAndStatusDot version={versionIdentifier} status={currentStatusName} />;
    },
  },

  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

function RiskTraceMatrices(_) {
  const classes = useStyles();

  const { data, loading, error } = useQuery(riskTraceMatricexQuery);

  if (loading) return <Loading />;
  if (error) throw new Error(`Graphql Error: ${error}`);

  return (
    <Grid container xs={12} className={classes.container}>
      <Breadcrumbs categorySlug="risk-trace-matrices" />
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <AgGridTable
          columns={columns}
          data={data.traceMatrices}
          TableTitle={() => (
            <div className={classes.titleContainer}>
              <Business viewBox="0 0 24 24" />
              <Typography variant="h2" classes={{ root: classes.typography }}>
                Risk Trace Matrices
              </Typography>
            </div>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default RiskTraceMatrices;
