import React from 'react';
import Typography from '@material-ui/core/Typography';
import { DateTime } from 'luxon';

import MakeLink from 'components/MakeLink';
import { useStyles } from '../styles';

const AssociatedTestProtocol = ({ form }) => {
  const effectiveDate = form.releasedAt ? DateTime.fromISO(form.releasedAt).toLocaleString(DateTime.DATE_FULL) : 'TBD';
  const classes = useStyles();

  return (
    <div>
      <div className={classes.associatedRowContainer}>
        <Typography variant="subtitle1">
          <MakeLink item={form.item}  newTab={true}>
            {form.item.customIdentifier}: {form.title}
          </MakeLink>
        </Typography>
      </div>

      <div className={classes.associatedRowContainer}>
        <Typography variant="subtitle1">Effective Date:&nbsp;</Typography>
        <Typography variant="body2">
          {effectiveDate}
        </Typography>
      </div>

      <div className={classes.associatedRowContainer}>
        <Typography variant="subtitle1">Version:&nbsp;</Typography>
        <Typography variant="body2">{form.versionIdentifier}</Typography>
      </div>
    </div>
  );
};

export { AssociatedTestProtocol };
