import React from 'react';

import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import last from 'lodash/last';

import WorkflowIcon from 'components/WorkflowIcon';
import ConnectBar from '../ConnectBar';

interface Props {
  releasedVersions: any[];
}

function Releases(props: Props) {
  const currentStatus = (version) => get(last(version.statuses), 'name');
  return props.releasedVersions.map((version, index) => (
    <>
      {index !== 0 && <ConnectBar color={camelCase(currentStatus(version))} visible />}
      <WorkflowIcon
        type={currentStatus(version)}
        completed
        version={version.versionIdentifier}
        effectiveDate={version.releasedAt || version.retiredAt}
        label={currentStatus(version) === 'effective' ? 'Released' : capitalize(currentStatus(version))}
        size="large"
        visible
      />
    </>
  ));
}

export default Releases;
