import { ColumnApi, ColumnState, GridApi, IFilter } from 'ag-grid-community';

export interface GridRef {
  api: GridApi;
  columnApi: ColumnApi;
}

type Instance = IFilter | null | undefined;

export const getFilterInstance = (gridRef: GridRef, key: string) => gridRef.api.getFilterInstance(key);
export const setModel = (instance: Instance, values: string[]) => instance && instance.setModel({ values });
export const onFilterChanged = (gridRef: GridRef) => gridRef.api.onFilterChanged();
export const resetFilters = (gridRef: GridRef) => gridRef.api.setFilterModel(null);
export const applyColumnState = (gridRef: GridRef, state: ColumnState[]) =>
  gridRef.columnApi.applyColumnState({ state, defaultState: { sort: null } });
export const resetColumnState = (gridRef: GridRef) => gridRef.columnApi.resetColumnState();
