import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(3),
    },
    pageTitle: {
      color: theme.palette.secondary.main,
    },
  }),
);
