import React from 'react';
import flowRight from 'lodash/flowRight';

import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { AccountCircle, Edit, Error, Explore, Flag, Notes, Save } from '@material-ui/icons';
import PendingActions from 'assets/icons/PendingAcitons';

import { LabelText, Spacer, TextArea } from 'components';
import RiskLevelSelect from 'components/RiskLevelSelect';
import Input from 'components/core/form/Input';
import * as Form from 'components/Form';
import { useDebounce } from 'hooks';
import TableTrio from 'components/TableTrio';
import { useStyles } from './styles';
import {
  useAddReferencedAttachment,
  useAddReferencedItem,
  useAddReferencedVersion,
  useRemoveReferencedAttachment,
  useRemoveReferencedItem,
  useRemoveReferencedVersion,
  useUpdateReferencedAttachment,
  useUpdateReferencedVersion,
} from 'components/TableTrio/gql';
import AuditReports from './AuditReports';
import QuestionnaireRecords from './QuestionnaireRecords';
import withCurrentUser from 'compositions/WithCurrentUser';
import useCurrentUserPermission from 'workflows/useCurrentUserPermission';
import { SupplierWorkflowStatuses, indexById } from 'workflows/statuses';

const RISK_LEVEL_OPTIONS = [
  {
    color: '',
    label: 'Select Risk Level',
    value: null,
  },
  {
    value: 'Low',
    label: 'Low',
    color: '#00FF57',
  },
  {
    value: 'Medium',
    label: 'Medium',
    color: '#FFF84E',
  },
  {
    value: 'High',
    label: 'High',
    color: '#FF9E45',
  },
];

const ATextArea = ({ attr, placeholder, locked = false, version, debouncedSave, partLocked, ...rest }) => {
  const value = version[attr];
  const update = (text) => debouncedSave({ id: version.id, [attr]: text });

  return (
    <TextArea
      initialValue={value}
      placeholder={placeholder}
      variant="outlined"
      margin="normal"
      fullWidth
      multiline
      rows="4"
      onTextChange={update}
      disabled={partLocked(attr)}
      {...rest}
    />
  );
};

const AnInput = ({ attr, placeholder, locked = false, version, debouncedSave, partLocked, ...rest }) => {
  const value = version[attr];
  const update = (e) => debouncedSave({ id: version.id, [attr]: e.target.value });

  return (
    <Input
      debounced
      defaultValue={value}
      disabled={partLocked(attr)}
      onChange={update}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export function riskStringToParams(str) {
  const found = RISK_LEVEL_OPTIONS.find((x) => x.value === str);
  if (found) return found;
  return { value: str, label: str, color: '#888888' };
}

function RenderTab({
  name,
  version,
  save,
  form,
  currentUser,
}: {
  name: string;
  version: any;
  save: any;
  form: any;
  currentUser: any;
}) {
  const { currentStatus } = version;
  const classes = useStyles();
  const debouncedSave = useDebounce(save);

  const SupplierWorkflowStatusesById = indexById(SupplierWorkflowStatuses);
  const currentWorkflowStatus = SupplierWorkflowStatusesById[currentStatus.name];
  const { ReadyForPrequalification, ReadyForQualification, QualificationComplete } = SupplierWorkflowStatuses;
  const canOwn = useCurrentUserPermission('supplier', 'own_record') && currentUser.id === version.owner.id;
  const statusesOwnerCanEdit =
    currentWorkflowStatus.isEqualTo(ReadyForPrequalification) || currentWorkflowStatus.isEqualTo(ReadyForQualification);

  const addReferencedAttachment = useAddReferencedAttachment();
  const updateReferencedAttachment = useUpdateReferencedAttachment();
  const removeReferencedAttachment = useRemoveReferencedAttachment();
  const addReferencedItem = useAddReferencedItem();
  const removeReferencedItem = useRemoveReferencedItem();
  const addReferencedVersion = useAddReferencedVersion();
  const removeReferencedVersion = useRemoveReferencedVersion();
  const updateAssociatedItemVersion = useUpdateReferencedVersion();

  const partLocked = (name) => !version.partsAccessToEdit.includes(name);

  if (name === 'information') {
    const partly_locked = partLocked('tabInformation');

    return (
      <div className={classes.firstTab}>
        <Spacer factor={2} />
        <LabelText Icon={AccountCircle} labelText="CONTACT INFORMATION" />
        <Spacer factor={1} />

        {partly_locked || (
          <div className={classes.cornerButton}>
            {!form.canEdit ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Edit />}
                onClick={form.startEditing}
                disabled={partLocked('tabInformation')}
              >
                Update
              </Button>
            ) : (
              <Button variant="contained" color="primary" startIcon={<Save />} onClick={form.save}>
                Save
              </Button>
            )}
          </div>
        )}

        <Grid container xs={12} spacing={2}>
          <Grid item xs={2} spacing={2}>
            {/* layouting is not perfect you know */}
            <Spacer factor={1} />
            <Typography variant="subtitle1">Full Name</Typography>
            <Spacer factor={3.5} />
            <Typography variant="subtitle1">Role</Typography>
            <Spacer factor={3.5} />
            <Typography variant="subtitle1">Email</Typography>
            <Spacer factor={3.5} />
            <Typography variant="subtitle1">Phone</Typography>
          </Grid>
          <Grid item xs={10} spacing={2}>
            <Form.Input
              attr="name"
              placeholder="Contact First and Last Name"
              form={form}
              toText={partly_locked || !form.canEdit}
            />
            <Spacer factor={1} />
            <Form.Input attr="role" placeholder="Role" form={form} toText={partly_locked || !form.canEdit} />
            <Spacer factor={1} />
            <Form.Input attr="email" placeholder="Email" form={form} toText={partly_locked || !form.canEdit} />
            <Spacer factor={1} />
            <Form.Input attr="phone" placeholder="###-###-####" form={form} toText={partly_locked || !form.canEdit} />
          </Grid>
        </Grid>

        <Spacer factor={4} />
        <LabelText Icon={Explore} labelText="SUPPLIER WEBSITE" />
        <Spacer factor={1} />
        <Form.Input attr="website" placeholder="https://" form={form} toText={partly_locked || !form.canEdit} />

        <Spacer factor={4} />
        <LabelText Icon={Notes} labelText="CONTACT NOTES" />
        <Spacer factor={1} />
        <Form.TextArea
          attr="contactNotes"
          placeholder="Describe the best method of contacting supplier."
          form={form}
          toText={partly_locked || !form.canEdit}
        />

        <Spacer factor={4} />
        <LabelText Icon={Notes} labelText="ERP NOTES" />
        <Spacer factor={1} />
        <Form.TextArea attr="erpNotes" placeholder="" form={form} toText={partly_locked || !form.canEdit} />
      </div>
    );
  }

  const ACheckbox = ({ label, attr, locked = false, ...rest }) => {
    const value = version[attr];
    const toggle = () => save({ id: version.id, [attr]: !value });

    return (
      <FormGroup>
        <FormControlLabel
          control={<Checkbox color="primary" checked={value} onChange={toggle} disabled={partLocked(attr)} {...rest} />}
          label={label}
        />
      </FormGroup>
    );
  };

  if (name === 'scope') {
    return (
      <div>
        <Spacer factor={2} />
        <Typography variant="h4">Supplier Scope</Typography>
        <Spacer factor={2} />
        <LabelText Icon={PendingActions} labelText="CATEGORY OF WHAT IS SUPPLIED" />
        <div className={classes.checkList}>
          <ACheckbox size="small" label="Part" attr="suppliedPart" />
          <ACheckbox size="small" label="Service" attr="suppliedService" />
          <div className={classes.floatingTextContainer}>
            {version.suppliedOther ? (
              <>
                <ACheckbox size="small" label="" attr="suppliedOther" />
                <AnInput
                  attr="suppliedOtherText"
                  placeholder="Please describe category"
                  debouncedSave={debouncedSave}
                  version={version}
                  partLocked={partLocked}
                />
              </>
            ) : (
              <ACheckbox size="small" label="Other" attr="suppliedOther" />
            )}
          </div>
        </div>

        <LabelText Icon={Notes} labelText="SCOPE OF WHAT IS SUPPLIED" />
        <Spacer factor={1} />
        <ATextArea
          attr="suppliedScope"
          placeholder="Enter summary of parts of services that this supplier will provide"
          debouncedSave={debouncedSave}
          version={version}
          partLocked={partLocked}
        />

        <Spacer factor={4} />
        <Typography variant="h4">Supplier Risk Assessment</Typography>
        <Spacer factor={2} />
        <LabelText Icon={Error} labelText="RISK LEVEL OF SUPPLIER'S SCOPE" />
        <Spacer factor={1} />
        <div className={classes.riskLevelSelect}>
          <RiskLevelSelect
            options={RISK_LEVEL_OPTIONS}
            placeholder="Select Risk Level"
            onChange={(e) => save({ id: version.id, riskLevel: e.target.value })}
            defaultValue={version.riskLevel}
            disabled={partLocked('riskLevel')}
          />
        </div>
        <Spacer factor={2} />
        <LabelText Icon={Notes} labelText="JUSTIFICATION FOR RISK LEVEL" />
        <Spacer factor={1} />
        <ATextArea
          attr="riskLevelJustification"
          placeholder="Enter description"
          debouncedSave={debouncedSave}
          version={version}
          partLocked={partLocked}
        />
        <Spacer factor={4} />
        <Typography variant="h4">Supplier Qualification</Typography>
        <Spacer factor={2} />
        <LabelText Icon={PendingActions} labelText="PLANNED QUALIFICATION METHOD" />
        <div className={classes.checkList}>
          <ACheckbox label="Supplier Questionnaire Assessment" attr="plannedQualificationQuestionnaire" />
          <ACheckbox label="Supplier Audit" attr="plannedQualificationAudit" />
          <ACheckbox label="Other" attr="plannedQualificationOther" />
        </div>
        {version.plannedQualificationOther && (
          <>
            <LabelText Icon={Notes} labelText="OTHER EVALUATION METHOD" />
            <Spacer factor={1} />
            <ATextArea
              attr="plannedQualificationOtherText"
              placeholder="Enter description"
              debouncedSave={debouncedSave}
              version={version}
              partLocked={partLocked}
            />
          </>
        )}
      </div>
    );
  }

  version.associatedItemVersions = version.trioReferencedVersions;
  version.associatedItems = version.trioReferencedItems;
  version.referencedAttachments = version.trioReferencedAttachments;
  const refetchQueries = ['SupplierVersionQuery'];

  // NOTE: the whole section need an uptade after granular lockedness is merged
  if (name === 'activities') {
    return (
      <>
        <Spacer factor={2} />
        <Typography variant="h4">Qualification Activities</Typography>
        <Spacer factor={2} />
        <LabelText Icon={Notes} labelText="QUALIFICATION NOTES" />
        <Spacer factor={1} />
        <ATextArea
          attr="qualificationNotes"
          placeholder="Enter notes related to Qualification Activities"
          debouncedSave={debouncedSave}
          version={version}
          partLocked={partLocked}
        />
        <Spacer factor={4} />
        {version.plannedQualificationQuestionnaire ||
        version.plannedQualificationAudit ||
        version.plannedQualificationOther ? (
          <>
            <Typography variant="h4">Questionnaire Assessment Activities</Typography>
            <Spacer factor={2} />
            {version.plannedQualificationQuestionnaire && (
              <>
                <LabelText Icon={Notes} labelText="SUPPLIER QUESTIONNAIRE RECORDS" />
                <Spacer factor={2} />
                <QuestionnaireRecords associatedVersions={version.supplierQuestionnaireRecordVersions} />
                <Spacer factor={4} />
              </>
            )}
            {version.plannedQualificationAudit && (
              <>
                <Typography variant="h4">Supplier Audit Activities</Typography>
                <Spacer factor={2} />
                <LabelText Icon={Notes} labelText="SUPPLIER AUDIT REPORTS" />
                <Spacer factor={2} />
                <AuditReports associatedVersions={version.supplierAuditReportVersions} />
                <Spacer factor={4} />
              </>
            )}
            {version.plannedQualificationOther && (
              <>
                <Typography variant="h4">Other Qualification Activities</Typography>
                <Spacer factor={2} />
                <>
                  <TableTrio
                    currentVersion={version}
                    locked={(statusesOwnerCanEdit && !canOwn) || currentWorkflowStatus.isEqualTo(QualificationComplete)}
                    itemsTableProps={{
                      handlers: {
                        remove: (id) =>
                          removeReferencedItem({
                            variables: { versionId: version.id, referencedItemId: id },
                            refetchQueries,
                          }),
                        addAssociatedItem: (ids) =>
                          addReferencedItem({
                            variables: {
                              versionId: version.id,
                              referencedItemIds: ids,
                            },
                            refetchQueries,
                          }),
                      },
                    }}
                    versionsTableProps={{
                      handlers: {
                        removeAssociatedItemVersion: (id) =>
                          removeReferencedVersion({
                            variables: {
                              versionId: version.id,
                              referencedVersionId: id,
                            },
                            refetchQueries,
                          }),
                        addAssociatedItemVersion: (ids) =>
                          addReferencedVersion({
                            variables: {
                              versionId: version.id,
                              referencedVersionIds: ids,
                            },
                            refetchQueries,
                          }),
                        updateAssociatedItemVersion: (next, old) =>
                          updateAssociatedItemVersion({
                            refetchQueries,
                            variables: {
                              nextVersionId: next,
                              oldVersionId: old,
                              versionId: version.id,
                            },
                          }),
                      },
                    }}
                    attachmentsTableProps={{
                      handlers: {
                        update: (id, fields) =>
                          updateReferencedAttachment({
                            variables: { id, ...fields },
                            refetchQueries,
                          }),
                        destroy: (id) => removeReferencedAttachment({ variables: { id }, refetchQueries }),
                        create: (id, url) =>
                          addReferencedAttachment({
                            variables: { versionId: id, attachment: url },
                            refetchQueries,
                          }),
                      },
                    }}
                  />
                </>
              </>
            )}
          </>
        ) : (
          <>
            <Typography variant={'body2'}>
              <Flag fontSize={'small'} />
              You have not specified any Qualification Activities yet. Please specify Qualification Activities in the
              Supplier Information tab first.{' '}
            </Typography>
          </>
        )}
      </>
    );
  }

  return <span>nothing</span>;
}

export default flowRight([withCurrentUser])(RenderTab);
