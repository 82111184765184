import React from 'react';

import { Avatar } from '@material-ui/core';

import { useStyles } from './styles';

type Size = 'small' | 'large';

interface ImageProps {
  size?: Size;
  url: string;
}

export function ImageProfile(props: ImageProps) {
  const classes = useStyles();
  return <Avatar src={props.url} className={`${classes.container} ${classes[props.size || 'small']}`} />;
}

interface InitialsProps {
  size?: Size;
  firstName: string;
  lastName: string;
}

export function InitialsProfile(props: InitialsProps) {
  const classes = useStyles();
  return (
    <Avatar className={`${classes.container} ${classes[props.size || 'small']}`}>
      {props.firstName.charAt(0)}
      {props.lastName.charAt(0)}
    </Avatar>
  );
}
