import React, { useState } from 'react';

import { Clear, Link, LocalOffer } from '@material-ui/icons';
import IconButton from 'components/IconButton';
import IconButtonRow from 'components/IconButtonRow';
import { Popover } from 'components';
import { Tags } from 'compositions';
import { useQuery } from 'react-apollo';
import { useToggleablePopover } from 'hooks/useToggleablePopover';
import TracesListPopover from 'components/TracesListPopover';
import { mapSearchResultsToLinks } from './util';
import { BULK_ITEM_TRACES } from './gql';
import { Step } from '../util';
import uniq from 'lodash/uniq';
import { useUpdateBulkTags } from './hooks';
import RemoveConfirmation from '../RemoveConfirmation';

interface Props {
  selectedSteps: Step[];
  onRemove: (steps: number[]) => void;
}

function BulkActions(props: Props) {
  const [labelToggled, labelAnchor, toggleLabel] = useToggleablePopover();
  const [linkToggled, linkAnchor, toggleLink] = useToggleablePopover();

  const bulkTags = uniq(props.selectedSteps.flatMap((step) => step.tags));
  const updateBulkTags = useUpdateBulkTags(bulkTags, props.selectedSteps.map((step) => step.itemId));
  const [removeConfirmation, setRemoveConfirmation] = useState(false);

  const { data } = useQuery(BULK_ITEM_TRACES, { variables: { ids: props.selectedSteps } });

  return (
    <div>
      <IconButtonRow>
        <IconButton
          size="large"
          includeBackground
          tooltip="Bulk Link Items"
          onClick={(e) => toggleLink(e.currentTarget)}
          Icon={Link}
        />
        <IconButton
          size="large"
          includeBackground
          tooltip="Bulk Label Steps"
          onClick={(e) => toggleLabel(e.currentTarget)}
          Icon={LocalOffer}
        />
        <IconButton
          size="large"
          includeBackground
          tooltip="Bulk Remove Steps"
          onClick={() => props.selectedSteps.length > 0 && setRemoveConfirmation(true)}
          Icon={Clear}
        />
      </IconButtonRow>
      {removeConfirmation && (
        <RemoveConfirmation
          onCancel={() => setRemoveConfirmation(false)}
          onClose={() => setRemoveConfirmation(false)}
          onRemove={() => props.onRemove(props.selectedSteps.map((step) => step.versionId))}
          stepsToRemove={props.selectedSteps}
        />
      )}
      {labelToggled && labelAnchor && (
        <Popover title="Tags" anchorEl={labelAnchor} open onClose={() => toggleLabel(null)}>
          <Tags
            tags={bulkTags}
            onSelected={(tags) =>
              updateBulkTags(tags).catch((e) => {
                throw new Error(`Error updating bulk tags ${e}`);
              })
            }
          />
        </Popover>
      )}
      {linkToggled && linkAnchor !== null && (
        <TracesListPopover
          anchorEl={linkAnchor}
          open
          onClose={() => toggleLink(null)}
          parentSetItemSlug=""
          traces={mapSearchResultsToLinks(data)}
        />
      )}
    </div>
  );
}

export default BulkActions;
