import { useMutation, useQuery } from 'react-apollo';

import {
  RISK_MATRIX_ENTRIES,
  RISK_PRIORITY_CODES,
  UPDATE_RISK_MATRIX_ENTRIES,
  UPDATE_RISK_PRIORITY_CODES,
} from './gql';
import {
  updateRiskPriorityCodesVariables,
  updateRiskPriorityCodes_updateRiskPriorityCode,
} from './__generated__/updateRiskPriorityCodes';
import {
  updateRiskMatrixEntriesVariables,
  updateRiskMatrixEntries_updateRiskMatrixEntry,
} from './__generated__/updateRiskMatrixEntries';
import { riskPriorityCodes } from './__generated__/riskPriorityCodes';
import { riskMatrixEntries } from './__generated__/riskMatrixEntries';

export const createRpcUpdateData = (updateData: { [id: string]: any }) =>
  Object.entries(updateData).map(([key, value]) => ({ id: key, name: value.name, fullName: value.fullName }));

export const useQueryPriorityCodes = () => useQuery<riskPriorityCodes>(RISK_PRIORITY_CODES);

export const useUpdatePriorityCode = () =>
  useMutation<updateRiskPriorityCodes_updateRiskPriorityCode, updateRiskPriorityCodesVariables>(
    UPDATE_RISK_PRIORITY_CODES,
  );

export const createRmeUpdateData = (updateData: { [id: string]: any }) =>
  Object.entries(updateData).map(([key, value]) => ({ id: key, ...value }));

export const useQueryRiskMatrixEntries = () => useQuery<riskMatrixEntries>(RISK_MATRIX_ENTRIES);

export const useUpdateRiskMatrixEntry = () =>
  useMutation<updateRiskMatrixEntries_updateRiskMatrixEntry, updateRiskMatrixEntriesVariables>(
    UPDATE_RISK_MATRIX_ENTRIES,
  );
