import React, { useState } from 'react';
import { Query } from 'react-apollo';

import camelCase from 'lodash/camelCase';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import xor from 'lodash/xor';
import union from 'lodash/union';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Done from '@material-ui/icons/Done';
import Search from '@material-ui/icons/Search';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';

import { Spacer } from 'components';
import { ItemTypeIndex } from 'itemTypes';
import { CATEGORY_INDEX } from 'categories';
import withCurrentAppWindow from 'compositions/WithCurrentAppWindow';
import withProducts from 'compositions/WithProducts';
import { searchQuery } from 'utils/gql/itemSearch';
import styles from './styles';
import { buildConditionalClasses } from 'utils';

function CategoryHeading({ icon, name, classes }) {
  const Icon = icon;
  return (
    <div style={{ marginBottom: 4 }}>
      <Icon className={classes.rowIcon} />
      <Typography variant="body2" style={{ display: 'inline' }}>
        {name.id}
      </Typography>
    </div>
  );
}

function MemberItemSearchImpl(props) {
  const {
    anchorEl,
    appWindow,
    closeSelf,
    classes,
    open,
    products,
    submitButtonText,
    asyncOnSubmit,
    existingMemberIds,
    memberItemType,
  } = props;
  const [searchTerm, setSearchTerm] = useState(null);
  const [selected, setSelected] = useState<string[]>([]);
  const resetDefaults = () => {
    setSearchTerm(null);
    setSelected([]);
  };
  const toggleRecordSelection = (recordId: string) => {
    const newRecords = xor(selected, [recordId]);

    setSelected(newRecords);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      marginThreshold={210}
      onClose={() => {
        resetDefaults();
        closeSelf();
      }}
      classes={{ paper: classes.addItemContainer }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Typography className={classes.header}>Search item</Typography>
      <Query
        query={searchQuery}
        fetchPolicy="network-only"
        variables={{
          q: searchTerm,
          itemTypes: memberItemType,
          excludeMembersAlreadyInSet: true,
        }}
      >
        {({ data, error }) => {
          if (error) throw new Error(`Member item search failed: ${error}`);

          const noResults = get(data, 'itemSearch') && get(data, 'itemSearch').length === 0 && (
            <Typography variant="body2" className={classes.noResults}>
              No results found.
            </Typography>
          );

          const onKeyPress = (e) => {
            const term = e.target.value;
            if (term && e.key === 'Enter') {
              setSearchTerm(term);
            }
          };

          const handleEmptyInput = (e) => {
            const term = e.target.value;
            if (!term.length) {
              resetDefaults();
            }
          };

          const resultMap = {};
          get(data, 'itemSearch', []).forEach((r) => {
            resultMap[r.itemType] = get(r, 'results', []);
          });

          const whitelist = {};
          Object.keys(resultMap).forEach((t) => {
            const displaySlug = get(ItemTypeIndex, [camelCase(t), 'displaySlug']);

            whitelist[displaySlug] = true;

            const parentSlug = get(CATEGORY_INDEX, [displaySlug, 'parentSlug']);
            if (parentSlug) whitelist[parentSlug] = true;
          });

          return (
            <>
              <TextField
                autoComplete="off"
                id="unlocked-member-items-search-input"
                placeholder="Search by item ID or title"
                fullWidth
                variant="outlined"
                margin="dense"
                className={classes.searchContainer}
                onKeyPress={(e) => onKeyPress(e)}
                onChange={(e) => handleEmptyInput(e)}
                InputProps={{
                  'aria-label': 'Member Search',
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search classes={{ root: classes.searchIcon }} />
                    </InputAdornment>
                  ),
                }}
              />
              <div className={classes.results}>
                <Typography className={classes.productName}>
                  {products[0].name}
                  {/* FIXME: needs to account for multiple products */}
                </Typography>
                {noResults}
                {appWindow.categories.map((cat, ix) => {
                  if (!get(whitelist, cat.slug)) return null;
                  return (
                    <div key={ix}>
                      {get(cat, 'children', []).map((child) => {
                        if (!get(whitelist, child.slug)) return null;
                        const Icon = child.icon;

                        return (
                          <div key={child.name.id}>
                            <CategoryHeading classes={classes} {...child} />
                            {get(resultMap, snakeCase(child.itemType), []).map((record) => (
                              <Typography
                                variant="body2"
                                component="div"
                                test-element-type="search-result"
                                className={`${buildConditionalClasses([
                                  [true, classes.searchResult],
                                  [selected.includes(record.currentVersionId), classes.selectedRow],
                                  [existingMemberIds.includes(record.currentVersionId), classes.disabledRow],
                                ])}`}
                                key={record.currentVersionId}
                                onClick={() => toggleRecordSelection(record.currentVersionId)}
                              >
                                {union(existingMemberIds, selected).includes(record.currentVersionId) ? (
                                  <Done
                                    classes={{
                                      root: `${classes.rowIcon} ${classes.checkedRow}`,
                                    }}
                                  />
                                ) : (
                                  <Icon className={classes.rowIcon} />
                                )}
                                {`${record.customIdentifier}: ${record.title}`}
                              </Typography>
                            ))}
                          </div>
                        );
                      })}
                      <Spacer factor={0.5} />
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      </Query>
      <hr className={classes.splitter} />
      <div className={classes.addBtnContainer}>
        <Button
          id="add-member-btn"
          fullWidth
          color="primary"
          variant="contained"
          type="button"
          className={classes.addBtn}
          onClick={async () => {
            await asyncOnSubmit(selected);
            resetDefaults();
            closeSelf();
          }}
          disabled={selected.length === 0}
        >
          {submitButtonText}
        </Button>
      </div>
    </Popover>
  );
}

const MemberItemSearch = withStyles(styles)(MemberItemSearchImpl);

function AddMember({
  classes,
  onClick,
  itemSetsIcon,
  create,
  closeSelf,
  existingMemberIds,
  memberItemType,
  title,
  ...itemSearchProps
}) {
  return (
    <>
      <Button onClick={onClick} variant="outlined" startIcon={<PlaylistAdd />} className={classes.button}>
        {title || 'Add Member'}
      </Button>
      <MemberItemSearch
        {...itemSearchProps}
        asyncOnSubmit={async (selected) => create(selected)}
        classes={classes}
        closeSelf={closeSelf}
        submitButtonText={'Add'}
        existingMemberIds={existingMemberIds}
        memberItemType={memberItemType}
      />
    </>
  );
}

export { MemberItemSearch };

export default flowRight([withStyles(styles), withCurrentAppWindow, withProducts])(AddMember);
