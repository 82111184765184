import React, { FC, useRef } from 'react';

import { Popper, Typography } from '@material-ui/core';

import SettingsDropdown from 'components/SettingsDropdown';
import EditButton from 'components/EditButton';
import { useStyles } from './styles';

interface Props {
  timeZone: string;
  editTimeZone: boolean;
  availableTimeZones: string[];
  onChange: (timeZone: string) => void;
  setEditTimeZone: () => void;
}

const TimeZone: FC<Props> = ({
  timeZone: selectedTimeZone,
  editTimeZone,
  availableTimeZones,
  onChange,
  setEditTimeZone,
}) => {
  const classes = useStyles();
  const ref = useRef<null | HTMLElement>(null);

  return (
    <div>
      <div className={classes.labelContainer}>
        <Typography innerRef={ref} variant="h5" className={classes.label}>
          Time Zone
        </Typography>
        <EditButton onClick={setEditTimeZone} />
      </div>

      <Popper open={editTimeZone} anchorEl={ref.current} placement="bottom-start">
        <SettingsDropdown
          inputText="Select Time Zone"
          selected={selectedTimeZone}
          items={availableTimeZones}
          onChange={onChange}
        />
      </Popper>

      {!editTimeZone && <Typography variant="body2">{selectedTimeZone}</Typography>}
    </div>
  );
};

export default TimeZone;
