import React from 'react';

import DashboardPanel from '../DashboardPanel';
import { useStyles } from './styles';

interface Data {
  tolerable: number;
  tolerableName: string;
  asLowAsReasonablyPracticable: number;
  alarpName: string;
  intolerable: number;
  intolerableName: string;
  cannotBeDetermined: number;
}

const RisksByMitigatedRPC: React.FC<{ data: Data }> = (props) => {
  const classes = useStyles();
  const { data } = props;

  const items = [
    { key: 'tolerable', title: data.tolerableName, count: data.tolerable, color: '#AEFF88' },
    {
      key: 'asLowAsReasonablyPracticable',
      title: data.alarpName,
      count: data.asLowAsReasonablyPracticable,
      color: '#FFF57E',
    },
    { key: 'intolerable', title: data.intolerableName, count: data.intolerable, color: '#FF8D8D' },
    { key: 'cannotBeDetermined', title: 'Cannot be determined', count: data.cannotBeDetermined },
  ];

  return (
    <div className={classes.cardWrapper}>
      {items.map((item) => (
        <DashboardPanel {...item} />
      ))}
    </div>
  );
};

export default RisksByMitigatedRPC;
