import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { compose, setDisplayName } from 'recompose';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { withI18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';

import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Lock from '@material-ui/icons/Lock';

import LOGO from 'assets/img/nemedio_vert.svg';
import { Logo, Spacer } from 'components';
import { buildConditionalClasses } from 'utils';
import ExpiredResetPasswordToken from './ExpiredResetPasswordToken';
import styles from './styles';

const ContainerWithEntry = posed.div({
  hidden: {
    y: -50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      duration: 300,
    },
  },
});

const mutation = gql`
  mutation updatePasswordFromToken($input: UpdatePasswordFromTokenInput!) {
    updatePasswordFromToken(input: $input) {
      success
      errors
    }
  }
`;

const ResetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required(<Trans>Password is required</Trans>)
    .min(8, <Trans>Password is too short - minimum length is 8 characters</Trans>)
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9@~`!@#$%^&*()_=+\]\\';:"?>.<,-{|}]+)/,
      <Trans>Incorrect format.</Trans>,
    ),
  passwordConfirmation: Yup.string()
    .required('')
    .oneOf([Yup.ref('password'), null], <Trans>Password does not match</Trans>),
});

class ResetPasswordForm extends Component {
  static propTypes = {
    heading: PropTypes.string,
    classes: PropTypes.shape({
      error: PropTypes.string.isRequired,
      formCard: PropTypes.string.isRequired,
      form: PropTypes.string.isRequired,
      inputAdornment: PropTypes.string.isRequired,
      inputField: PropTypes.string.isRequired,
      resetPasswordButton: PropTypes.string.isRequired,
    }).isRequired,
    i18n: PropTypes.shape({
      _: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    mounted: false,
    hasSaved: false,
    isButtonDisabled: false,
    expiredPasswordTokenOpen: false,
    successMessage: '',
    errors: [],
  };

  componentDidMount() {
    // this toggles the pose after mounting, causing the card to be nicely animated into place.
    if (!this.state.mounted) {
      this.setState({ mounted: true });
    }
  }

  getPasswordTokenFromURL = () => {
    const variables = {};

    // parses and returns the parameters
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
      variables[key] = value;
    });

    return variables;
  };

  render() {
    const { classes, i18n, logo, heading } = this.props;
    const { mounted, isButtonDisabled, expiredPasswordTokenOpen, successMessage, hasSaved } = this.state;

    const serverError = this.state.errors.map((error) => {
      return (
        <>
          {error
            .replace('Validation failed:', '')
            .split(',')
            .join('\r\n')}
        </>
      );
    });

    return (
      <>
        <ContainerWithEntry pose={mounted ? 'visible' : 'hidden'}>
          <Card className={classes.formCard}>
            <Logo src={logo} classes={classes} />
            <Grid container item xs={10}>
              <Tooltip title={i18n._(t`Back to login`)} classes={{ tooltip: classes.tooltip }} placement="right">
                <Link to="/login" className={classes.backArrow}>
                  <ArrowBack />
                </Link>
              </Tooltip>
            </Grid>

            <Formik
              initialValues={{ password: '', passwordConfirmation: '' }}
              validationSchema={ResetPasswordValidationSchema}
            >
              {({ isValid, values }) => (
                <Mutation
                  mutation={mutation}
                  variables={{
                    input: {
                      password: values.password,
                      passwordConfirmation: values.passwordConfirmation,
                      token: this.getPasswordTokenFromURL()['token'],
                    },
                  }}
                  onCompleted={(data) => {
                    if (
                      data.updatePasswordFromToken.errors &&
                      data.updatePasswordFromToken.errors.includes('Password reset token expired')
                    ) {
                      this.setState({
                        hasSaved: false,
                        errors: data.updatePasswordFromToken.errors,
                        expiredPasswordTokenOpen: true,
                      });
                    } else if (!data.updatePasswordFromToken.errors) {
                      this.setState({
                        hasSaved: true,
                        isButtonDisabled: true,
                        successMessage: <Trans>Your password has been updated.</Trans>,
                        errors: [],
                      });
                    } else {
                      this.setState({
                        hasSaved: false,
                        errors: data.updatePasswordFromToken.errors,
                      });
                    }
                  }}
                >
                  {(updatePasswordFromToken, { loading, error }) => {
                    if (error) throw new Error(`Error updating password from token: ${error}`);

                    return (
                      <Form className={classes.form}>
                        <CardContent>
                          <Typography variant="h2">{heading}</Typography>
                          <Spacer factor={2} />
                          <Typography paragraph variant="body2">
                            <Trans>
                              Enter your new password. Password should have at least 8 alphanumeric characters, with at
                              least one number and one letter, and must differ from your current password.
                            </Trans>
                          </Typography>
                          <Spacer factor={1} />
                          <Field name="password">
                            {({ field, form: { errors, touched } }) => {
                              const displayError = errors.password && touched.password;
                              return (
                                <FormControl error={displayError} fullWidth>
                                  <Input
                                    {...field}
                                    className={classes.inputField}
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        className={buildConditionalClasses([
                                          [!displayError || !errors.password, classes.inputAdornment],
                                          [touched.password && !errors.password, classes.success],
                                          [errors.password || displayError, classes.error],
                                          [displayError, classes.error],
                                        ])}
                                      >
                                        <Lock />
                                      </InputAdornment>
                                    }
                                    fullWidth
                                    id="password"
                                    type="password"
                                    placeholder={i18n._(t`Enter new password`)}
                                  />
                                  <FormHelperText className={buildConditionalClasses([[true, classes.error]])}>
                                    {errors.password}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                          <Spacer factor={2} />
                          <Field name="passwordConfirmation">
                            {({ field, form: { errors, touched } }) => {
                              const displayError =
                                errors.password && errors.passwordConfirmation && touched.passwordConfirmation;
                              return (
                                <FormControl error={displayError} fullWidth>
                                  <Input
                                    {...field}
                                    className={classes.inputField}
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        className={buildConditionalClasses([
                                          [
                                            !displayError || !errors.confirmationPassword || !errors.password,
                                            classes.inputAdornment,
                                          ],
                                          [
                                            touched.passwordConfirmation &&
                                              !errors.passwordConfirmation &&
                                              !errors.password,
                                            classes.success,
                                          ],
                                          [errors.passwordConfirmation || errors.password, classes.error],
                                          [displayError, classes.error],
                                        ])}
                                      >
                                        <Lock />
                                      </InputAdornment>
                                    }
                                    fullWidth
                                    id="passwordConfirmation"
                                    type="password"
                                    placeholder={i18n._(t`Confirm new password`)}
                                  />
                                  <FormHelperText
                                    className={buildConditionalClasses([
                                      [true, classes.error],
                                      [successMessage, classes.success],
                                    ])}
                                  >
                                    {errors.passwordConfirmation ||
                                      (loading
                                        ? 'Updating...'
                                        : hasSaved
                                        ? successMessage
                                        : serverError || 'An error occurred.')}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </CardContent>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Button
                                className={classes.resetPasswordButton}
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                disabled={!isValid || isButtonDisabled}
                                onClick={updatePasswordFromToken}
                              >
                                <Trans>Change Password</Trans>
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Form>
                    );
                  }}
                </Mutation>
              )}
            </Formik>
          </Card>
        </ContainerWithEntry>
        <ExpiredResetPasswordToken
          open={expiredPasswordTokenOpen}
          onClose={() => {
            this.setState({
              expiredPasswordTokenOpen: false,
            });
          }}
        />
      </>
    );
  }
}

ResetPasswordForm.defaultProps = {
  logo: LOGO,
  heading: 'Reset Your Password',
};

export default compose(
  setDisplayName('ResetPasswordForm'),
  withI18n(),
  withStyles(styles),
)(ResetPasswordForm);
