import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    ...theme.mixins.gridLayout,
    nextRow: {
      paddingLeft: `0 !important`,
      paddingTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
    },
    tab: {
      padding: theme.spacing(1),
      justifyContent: 'left',
      '& span': {
        fontWeight: '600 !important',
        fontSize: '11px !important',
        lineHeight: '14px !important',
      },
    }
  }));
