import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const GET_PATIENT_POPULATION_PROFILE_VERSION = gql`
  query PatientPopulationProfileVersionQuery($id: ID!) {
    patientPopulationProfileVersion(id: $id) {
      id
      title
      displayAsApplicationVersion
      item {
        id
        itemNumber
        customIdentifier
      }
      attachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      rawReferenceAttachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      body
      inputMethod
      locked
      displayAsApplicationVersion
      patientProfileVersions {
        id
        item {
          id
          customIdentifier
          tags
          itemNumber
          itemType {
            id
            name
          }
          comments {
            id
          }
        }
        locked
        title
        description
        member {
          id
          position
        }
      }
    }
  }
`;

const CREATE_PATIENT_PROFILE = gql`
  mutation AddPatientProfileToPatientPopulationProfileVersion($patientPopulationProfileId: ID!, $title: String!) {
    addPatientProfileToPatientPopulationProfileVersion(
      patientPopulationProfileId: $patientPopulationProfileId
      title: $title
    ) {
      item {
        id
      }
    }
  }
`;

const REMOVE_MEMBERS = gql`
  mutation removePatientProfile($ids: [ID!]!) {
    removeMembers(ids: $ids) {
      members {
        id
      }
    }
  }
`;

function useVersion(id: string) {
  return useQuery(GET_PATIENT_POPULATION_PROFILE_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

export { useVersion, REMOVE_MEMBERS, CREATE_PATIENT_PROFILE, GET_PATIENT_POPULATION_PROFILE_VERSION };
