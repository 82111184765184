import React from 'react';

import { Typography } from '@material-ui/core';
import SettingsInput from '../SettingsInput';

import { RiskDashboard_riskMatrixEntries_hazardousSituationProbabilityLevel as Probability } from 'pages/RiskManagementDashboard/__generated__/RiskDashboard';

import { useStyles } from './styles';

interface Props {
  probability: Probability & { matrixId: string };
  editMode?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ProbabilityUnit: React.FC<Props> = ({ probability, editMode, onChange }) => {
  const classes = useStyles();
  const { id, name, color } = probability;

  return (
    <div className={classes.probabilityUnit}>
      <div className={classes.probabilityName}>
        {editMode ? (
          <SettingsInput name={id} defaultValue={name} fullWidth onChange={onChange} />
        ) : (
          <Typography variant="h6" className={classes.matrixSideHeading}>
            {name}
          </Typography>
        )}
      </div>

      <div
        className={classes.probabilityBox}
        style={{
          backgroundColor: color,
        }}
      />
    </div>
  );
};

export default ProbabilityUnit;
