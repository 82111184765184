import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      marginBottom: '8px',
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },
    icon: {
      marginRight: '4px',
    },
  });
});
