import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    iconBackground: {
      background: theme.palette.blueDark,
      borderRadius: '50%',
      color: 'white',
      padding: 2,
    },
    themeColor: {
      color: theme.palette.primary.main,
    },
    icon: {
      cursor: 'pointer',
    },
    root: {
      padding: 0,
      color: theme.palette.secondary.main,
      '&:hover': { backgroundColor: 'none', color: theme.palette.blueDark },
    },
    small: {
      width: 16,
      height: 16,
    },
    medium: {
      width: 20,
      height: 20,
    },
    large: {
      width: 24,
      height: 24,
    },
    'x-large': {
      width: 48,
      height: 48,
    },
  }),
);
