import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    tooltip: {
      ...theme.typography.body2,
      zIndex: theme.zIndex.tooltip,
    },
  });

export default styles;
