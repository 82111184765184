import React from 'react';

import { Typography, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core';

import { Trans } from '@lingui/macro';

import { DateTime } from 'luxon';

interface SoftwareInformationProps {
  currentVersion: string;
  currentVersionDeployedAt: string;
  previousVersion?: string;
  classes: any;
}

const styles = (theme) =>
  createStyles({
    h7: {
      ...theme.typography.h7,
    },
    section: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px 0 0`,
    },
    versionNumber: {
      fontWeight: theme.typography.fontWeightBold,
    },
    versionReleasedAt: {
      fontSize: '8px',
      textTransform: 'uppercase',
    },
  });

const SoftwareInformation = (props: SoftwareInformationProps) => {
  return (
    <div>
      <Typography className={props.classes.h7}>
        <Trans>Software Information</Trans>
      </Typography>

      <div className={props.classes.section}>
        <div>
          <Typography display="inline">
            <Trans>Current Version</Trans>
          </Typography>{' '}
          <Typography display="inline" className={props.classes.versionNumber}>
            {props.currentVersion}
          </Typography>{' '}
          <Typography
            display="inline"
            className={props.classes.versionReleasedAt}
          >
            (<Trans>Updated</Trans>:{' '}
            {DateTime.fromISO(props.currentVersionDeployedAt).toFormat(
              'M/d/yyyy',
            )}
            )
          </Typography>
        </div>

        {props.previousVersion && (
          <div>
            <Typography display="inline">
              <Trans>Previous Version</Trans>:
            </Typography>{' '}
            <Typography
              display="inline"
              className={props.classes.versionNumber}
            >
              {props.previousVersion}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(SoftwareInformation);
