import React, { FC } from 'react';

import { useStyles } from './styles';
import { Paper } from '@material-ui/core';

interface Props {
  children: React.ReactNode;
}

const SettingsSection: FC<Props> = ({ children }) => {
  const classes = useStyles();

  return <Paper className={classes.section}>{children}</Paper>;
};

export default SettingsSection;
