import React, { useState } from 'react';

import { Tooltip, Typography, IconButton } from '@material-ui/core';
import { AddCircle, Link, Notes } from '@material-ui/icons';

import { useStyles } from './styles';
import { ItemSearch } from 'compositions';

interface Props {
  onAddClick: () => void;
  onItemSelected: (itemId: number) => void;
}

function AddStep(props: Props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles();
  const [linkAnchor, setLinkAnchor] = useState<Element | null>(null);

  return (
    <Typography component="div" variant="body2" className={classes.container}>
      <IconButton className={classes.icon} onClick={() => setMenuOpen((prev) => !prev)}>
        <AddCircle />
      </IconButton>
      {menuOpen && (
        <div>
          <Tooltip title="Text Link">
            <IconButton
              className={classes.icon}
              onClick={() => {
                setMenuOpen(false);
                props.onAddClick();
              }}
            >
              <Notes />
            </IconButton>
          </Tooltip>
          <Tooltip title="Link Existing">
            <IconButton
              className={classes.icon}
              onClick={(e) => {
                setLinkAnchor(e.currentTarget);
              }}
            >
              <Link />
            </IconButton>
          </Tooltip>
          {linkAnchor && (
            <ItemSearch
              defaultSearchTerm={null}
              anchorEl={linkAnchor}
              closeSelf={() => {
                setLinkAnchor(null);
                setMenuOpen(false);
              }}
              onSubmit={(selectedItems) => {
                props.onItemSelected(parseInt(selectedItems[0]));
                return Promise.resolve(true);
              }}
              open
              usesCurrentVersion={false}
            />
          )}
        </div>
      )}
    </Typography>
  );
}

export default AddStep;
