import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    imageContainer: {
      marginRight: theme.spacing(1),
    },
    infoContainer: {
      '& *': {
        display: 'block',
      },
    },
  }),
);
