import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      margin: 'inherit',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      color: theme.palette.secondary.main,
    },
    typography: {
      width: '100%',
      marginLeft: theme.spacing(),
      color: theme.palette.secondary.main,
    },
  }),
);
