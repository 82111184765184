import React, { useState } from 'react';

import semver from 'semver';

import Sort from '@material-ui/icons/Sort';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import AddCircle from '@material-ui/icons/AddCircle';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { get } from 'lodash';
import clsx from 'clsx';

import ItemSearch from 'compositions/ItemSearch';
import { TextAreaField } from 'components';
import Panel from 'components/core/Panel';
import Spacer from 'components/Spacer';
import IconButton from 'components/IconButton';

import { queryNameToRefetch, useAddImpactedItems } from '../gql';
import { TabProps } from '../PayloadFields';
import { useStyles } from '../styles';
import ImpactedItemTableRow from './ImpactedItemTableRow';

interface Props extends TabProps {
  itemRecord: any;
}

const ImpactAnalysisTab = (props: Props) => {
  const classes = useStyles();
  const { layoutClasses, version, itemRecord } = props;

  const [addImpactedItems, { error }] = useAddImpactedItems();

  const impactedItems = get(itemRecord, 'impactedItems', []);
  const impactedItemsErrorText = error ? error.graphQLErrors.map((e) => e.message).join('\n') : undefined;

  const [popper, setPopper] = useState<any>({ anchorEl: null, showError: false });
  const popperOpen = Boolean(popper.anchorEl);

  return (
    <>
      <Grid item xs={12} className={layoutClasses.gridGutterRight}>
        <Panel.Heading icon={Sort}>Impact Assessment</Panel.Heading>
        <div>
          <TextAreaField
            locked={version.locked}
            id="change-order-description"
            initialValue={version.impactAssessment}
            versionId={version.id}
            attrName="impact_assessment"
            rows={5}
            placeholder="Enter description"
            multiline
            refetchQueries={[queryNameToRefetch]}
          />
        </div>
        <Spacer factor={2} />
      </Grid>
      <Grid item xs={12} className={layoutClasses.gridGutterRight}>
        <Panel.Heading icon={LibraryBooks}>Impacted Items</Panel.Heading>
        <div style={{ overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.tableHeader}>
                  ID
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{ minWidth: 150 }}>
                  Title
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  Version Change
                </TableCell>
                <TableCell
                  align="center"
                  className={clsx([classes.tableHeader, classes.wrapped])}
                  style={{ minWidth: 120 }}
                >
                  Current Status
                </TableCell>
                <TableCell align="left" className={classes.tableHeader}>
                  Reason for Change
                </TableCell>
                <TableCell align="left" className={classes.tableHeader}>
                  Description of Change
                </TableCell>
                {semver.lt('9.1.0', version.displayAsApplicationVersion) && (
                  <TableCell align="left" className={classes.tableHeader}>
                    Associated Tasks
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {impactedItems.map((row) => {
                return <ImpactedItemTableRow key={row.id} row={row} itemRecord={itemRecord} version={version} />;
              })}
            </TableBody>
          </Table>
        </div>
        <Spacer factor={1} />
        {!itemRecord.currentVersion.locked && (
          <div className={layoutClasses.addRowButton}>
            <IconButton
              Icon={AddCircle}
              onClick={(evt) =>
                setPopper({
                  anchorEl: evt.target,
                  showError: false,
                })
              }
              size="large"
            />
          </div>
        )}
        <ItemSearch
          defaultSearchTerm={null}
          excludedItemTypes={['change_order']}
          supportsChangeOrder
          draftOnly
          errorText={popper.showError ? impactedItemsErrorText : undefined}
          open={popperOpen}
          anchorEl={popper.anchorEl}
          closeSelf={() => setPopper({ showError: false, anchorEl: null })}
          onSubmit={(selectedRecords) =>
            addImpactedItems({
              variables: {
                versionIds: selectedRecords,
                changeOrderId: itemRecord.id,
              },
              refetchQueries: [queryNameToRefetch],
            })
              .then(() => true)
              .catch((_) => {
                setPopper({
                  anchorEl: popper.anchorEl,
                  showError: true,
                });
                return false;
              })
          }
        />
      </Grid>
    </>
  );
};

export default ImpactAnalysisTab;
