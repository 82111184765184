import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';

const query = gql`
  fragment error on TransitionError {
    message
    data {
      key
      value
    }
  }

  fragment transitionFields on TrainingPlanVersion {
    mayTransitionToPrepared
    mayNotTransitionToPreparedBecause {
      ...error
    }
    mayTransitionToUnderReview
    mayTransitionToVoid
    mayNotTransitionToUnderReviewBecause {
      ...error
    }
    mayTransitionToRejected
    mayNotTransitionToRejectedBecause {
      ...error
    }
    mayTransitionToCanceled
    mayNotTransitionToCanceledBecause {
      ...error
    }
  }

  query TrainingPlanWorkflowQuery($itemId: ID!) {
    item(itemType: "training_plan", id: $itemId) {
      id
      customIdentifier
      currentVersion {
        id
        locked
        versionIdentifier
        currentStatus {
          id
          name
        }
        permissibleOwners {
          id
          fullName
          title
        }
        ...transitionFields
      }
      workingVersion {
        id
        locked
        versionIdentifier
        currentStatus {
          id
          createdAt
          name
          previousTransition {
            id
            esignature {
              id
              reasonForRejection
            }
          }
        }
        owner {
          id
          fullName
          title
          status
        }
        approver {
          id
          fullName
          title
          status
        }
        assignedOwnerJobRole {
          id
          formattedRoleName
          usersWithRole {
            id
            fullName
          }
        }
        assignedApproverJobRole {
          id
          formattedRoleName
          usersWithRole {
            id
            fullName
          }
        }
        permissibleOwners {
          id
          fullName
          title
        }
        signingRejector {
          id
          fullName
          title
        }
        ...transitionFields
        ... on TrainingPlanVersion {
          courses {
            id
            customIdentifier
            currentRelease {
              id
            }
            currentVersion {
              id
              currentStatus {
                id
                name
              }
            }
          }
          descriptionOfChange
          reasonForChange
        }
      }
      versions {
        id
        versionIdentifier
        currentStatus {
          id
          name
        }
        releasedAt
        ... on TrainingPlanVersion {
          descriptionOfChange
          reasonForChange
        }
      }
    }
  }
`;

export const useTrainingPlanWorkflowData = (itemId: string) =>
  useQuery(query, {
    fetchPolicy: 'network-only',
    variables: { itemId },
  });

const updateChangeFields = gql`
  mutation UpdateTrainingPlanVersion($versionId: ID!, $reasonForChange: String, $descriptionOfChange: String) {
    updateTrainingPlanVersion(
      id: $versionId
      reasonForChange: $reasonForChange
      descriptionOfChange: $descriptionOfChange
    ) {
      trainingPlanVersion {
        id
        reasonForChange
        descriptionOfChange
      }
    }
  }
`;

export const useUpdateChangeFields = () => useMutation(updateChangeFields);
