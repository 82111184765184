import React from 'react';
import { useMutation } from 'react-apollo';
import flowRight from 'lodash/flowRight';

import { AddCircleOutlined } from '@material-ui/icons';

import WithCurrentUser from 'compositions/WithCurrentUser';
import Modal from 'components/WorkflowModal';
import ModalContainer from 'components/WorkflowModalContainer';
import YesOrNo from 'components/YesOrNo';
import { CREATE_TRAINING_PLAN } from './gql';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  employeeId: any;
  currentUser: any;
}

function CreateTrainingPlanModal(props: Props) {
  const { isOpen, onClose, employeeId, currentUser } = props;

  const [createTrainingPlan, { error }] = useMutation(CREATE_TRAINING_PLAN, {
    refetchQueries: ['UserListQuery'],
    onCompleted: onClose,
  });
  if (error) throw new Error(`Error creating training plan: ${error}`);

  const head = `Create New Training Plan`;
  const body = `This action will create a new training plan for this user.`;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        style={{
          top: '50%',
          transform: 'translate(0, -25%)',
        }}
      >
        <ModalContainer showBackButton={false} onBack={onClose} onClose={onClose}>
          <YesOrNo
            disableOnSubmit
            handleComplete={async () => {
              try {
                await createTrainingPlan({
                  variables: {
                    employeeId: employeeId,
                    creatorId: currentUser.id,
                  },
                });
              } catch (error) {
                throw new Error(`Error creating training plan: ${error}`);
              }
            }}
            handleClose={onClose}
            closeOnNo
            Icon={AddCircleOutlined}
            heading={head}
            prompt={body}
            noLabel="Cancel"
            yesLabel="Create"
          />
        </ModalContainer>
      </Modal>
    </>
  );
}

export default flowRight([WithCurrentUser])(CreateTrainingPlanModal);
