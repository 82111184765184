import { AttachmentDependentWorkflowStatuses } from 'workflows/statuses';
import CompletedNoAssessment from '../views/CompletedNoAssessment';

export default {
  initialStep: 'confirm',
  internalSteps: {
    confirm: {
      component: CompletedNoAssessment,
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ confirm }) => {
    return {
      toStatus: AttachmentDependentWorkflowStatuses.Completed.id,
      ...confirm,
    };
  },
};
