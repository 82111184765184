import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    typography: {
      width: '100%',
      marginLeft: theme.spacing(),
    },
    boldHeaderText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    container: {
      padding: `0 ${theme.spacing(5)}px`,
      paddingTop: theme.spacing(5),
    },
    collaboratorRow: {
      display: 'flex',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    grid: {
      paddingLeft: `0 !important`,
      paddingRight: `0 !important`,
    },
    gridGutterRight: {
      paddingLeft: `0 !important`,
      paddingRight: theme.spacing(2),
    },
    gridGutterLeft: {
      paddingRight: `0 !important`,
      paddingLeft: theme.spacing(2),
    },
    pdfView: {
      width: '500px', // fixed width needed here to allow scaling
      height: '800px', // fixed height needed here to avoid page jumps when changing page
      margin: '0 auto',
      borderRadius: 4,
      paddingTop: theme.spacing(2),
    },
    icon: {
      width: '16px',
      height: '16px',
      marginRight: theme.spacing(0.5),
    },
    linkColor: {
      '&:hover': {
        cursor: 'pointer',
      },
      color: theme.palette.primary.main,
    },
    bold: {
      fontWeight: 600,
    },
    blur: {
      filter: 'blur(4px)',
      opacity: 0.7,
      background: '#dfdfdf',
    },
    modal: {
      width: 600,
      top: '33%',
      left: '50%',
      marginLeft: -300,
    },
    modalInside: {
      padding: theme.spacing(3),
      position: 'absolute',
      top: '33%',
      left: '50%',
      marginLeft: -170,
    },
    closeIcon: {
      float: 'right',
      '&:hover': { cursor: 'pointer' },
    },
    tableCell: {
      ...theme.typography.body2,
      padding: theme.spacing(1),
    },
    tableHeader: {
      whiteSpace: 'nowrap',
    },
    tableRow: {
      border: `2px solid ${theme.palette.common.input.outline} !important`,
    },
    attachmentInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    versionCreatedInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    role: {
      textTransform: 'uppercase',
    },
  });
