import React from 'react';
import { Mutation } from 'react-apollo';
import { Typography, Button, withStyles } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { addLinkMutation } from '../queries';

const styles = (theme) => ({
  buttonSection: {
    padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
  },
  error: {
    color: theme.palette.common.error.main,
  },
});

const ItemLinkButton = ({ classes, disabled, linkMutationArgs, onMutationCompleted }) => (
  <Mutation mutation={addLinkMutation} refetchQueries={['LinkQuery']} onCompleted={onMutationCompleted}>
    {(mutate, { error }) => {
      if (error) console.log(error);

      const count = linkMutationArgs.input.linkedItemIds.length;
      const payload = {
        variables: {
          ...linkMutationArgs,
        },
      };

      return (
        <>
          <div className={classes.buttonSection}>
            <Button
              disabled={disabled}
              fullWidth
              id="select-link-button"
              variant="contained"
              color="primary"
              onClick={() => mutate(payload)}
            >
              Submit {!disabled && `(${count})`}
            </Button>

            {error && !disabled && (
              <Typography variant="body2" className={classes.error}>
                <Trans>An unexpected error ocurred. Link was not created.</Trans>
              </Typography>
            )}
          </div>
        </>
      );
    }}
  </Mutation>
);

export default withStyles(styles)(ItemLinkButton);
