import get from 'lodash/get';

export const sortIds = (value1: string, value2: string) => {
  return value1.localeCompare(value2, undefined, { numeric: true });
};

export const getVersionIdentifierAndStatus = (data) => {
  return [
    get(data, 'currentVersion.versionIdentifier', data),
    data.currentVersion.currentStatusName || data.currentVersion.currentStatus.name,
  ].join('-');
};

export const sortVersions = (value1: any, value2: any) => {
  if (!value1 || !value2) return null;
  let field1 = value1;
  let field2 = value2;
  if (value1 instanceof Object && value2 instanceof Object) {
    field1 = getVersionIdentifierAndStatus(value1);
    field2 = getVersionIdentifierAndStatus(value2);
  }
  if (field1 === field2) return 0;
  return field1 > field2 ? 1 : -1;
};

export const isColumnGroupedBy = (row, fieldName) =>
  Boolean(row.columnApi.columnModel.rowGroupColumns.find((column) => column.colId === fieldName));
