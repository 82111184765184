import React from 'react';

import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import { VersionAndStatusDot } from 'components';

interface Version {
  status?: string;
  identifier?: string;
}

export enum State {
  JustReleaseVersion,
  JustDraftVersion,
  ReleasedToDraft,
}

interface Props {
  from: Version;
  to: Version;
}

function VersionSequenceWithDot(props: Props) {
  const { to, from } = props;
  switch (determineSequenceState(to, from)) {
    case State.JustReleaseVersion:
      return <VersionAndStatusDot version={to.identifier!} status={to.status!} />;

    case State.ReleasedToDraft:
      return (
        <>
          <VersionAndStatusDot version={from.identifier!} status={from.status!} />
          <ArrowRightAlt style={{ fontSize: 24 }} />
          <VersionAndStatusDot version={to.identifier!} status={to.status!} />
        </>
      );

    case State.JustDraftVersion:
      return <VersionAndStatusDot version={to.identifier!} status={to.status!} />;
  }
}

function determineSequenceState(to: Version, from: Version): State {
  if (to.identifier && from.identifier) {
    if (to.identifier === from.identifier) {
      return State.JustReleaseVersion;
    } else {
      return State.ReleasedToDraft;
    }
  } else if (to.identifier) {
    return State.JustDraftVersion;
  } else {
    throw new Error('no released or draft version on item');
  }
}

export default VersionSequenceWithDot;
