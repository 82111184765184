import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import slugs from '../../displaySlugs';

import { listFragment, docMetadata } from '../mixins/Document';
const itemType = 'nqmsSrt';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`nQMS System Requirement Table`,
  categoryLabel: t`nQMS SRTs`,
  icon: LibraryBooks,
  listViewQuery: gql`
    query nqmsSrtsQuery {
      documents(itemType: "nqms_srt") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
