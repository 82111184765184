import React from 'react';
import { useMutation } from 'react-apollo';
import isEmpty from 'lodash/isEmpty';

import Warning from '@material-ui/icons/Warning';

import AlertDialog from 'components/AlertDialog';
import BaseDialog from 'components/BaseDialog';
import Button from 'components/Button';
import { YesOrNo } from 'components';
import { ARCHIVE_JOB_ROLE } from './gql';

interface Props {
  open: boolean;
  onClose: () => void;
  jobRole: any;
  usersWithRole: { id: string; fullName: string }[];
}

function ArchiveJobRole(props: Props) {
  const [archiveJobRole, { loading, error }] = useMutation(ARCHIVE_JOB_ROLE, {
    refetchQueries: ['AllJobRoles'],
    onCompleted: props.onClose,
  });
  if (error) throw new Error(`Error archiving job role: ${error}`);

  const handleSubmit = () => {
    archiveJobRole({ variables: { jobRoleId: props.jobRole.id } }).catch((e) => {
      throw new Error(`Error occurred while archiving job role: ${e.message}`);
    });
  };

  const userListWithoutSystemUser = props.usersWithRole.filter((user) => user.id !== '1');

  return (
    <>
      {!isEmpty(userListWithoutSystemUser) && (
        <>
          <AlertDialog
            open={props.open}
            titleText="Not Allowed"
            subtitle="Please remove all users before archiving this role."
            onClose={props.onClose}
          >
            <Button variant="contained" color="primary" fullWidth onClick={props.onClose}>
              Confirm
            </Button>
          </AlertDialog>
        </>
      )}

      {isEmpty(userListWithoutSystemUser) && (
        <>
          <BaseDialog
            open={props.open}
            title="Confirm Role Archiving"
            subtitle="This action cannot be undone."
            onClose={props.onClose}
            Icon={Warning}
          >
            <YesOrNo
              yesLabel="Confirm"
              noLabel="Cancel"
              disableOnSubmit
              closeOnNo
              handleComplete={handleSubmit}
              handleClose={props.onClose}
              loading={loading}
            />
          </BaseDialog>
        </>
      )}
    </>
  );
}

export default ArchiveJobRole;
