import React, { useContext, useEffect } from 'react';

import { Grid } from '@material-ui/core';

import { withLockedContext, LockedContext } from 'contexts/lockedContext';
import DeviceSafetyCharacteristicCard from './DeviceSafetyCharacteristicCard';

const PayloadFields = ({ versionData }) => {
  const { setLocked } = useContext(LockedContext);

  useEffect(() => {
    setLocked(versionData.locked);
  }, [versionData, setLocked]);

  return (
    <Grid item xs={8}>
      {versionData.deviceSafetyCharacteristicVersions.map((dsc) => {
        return <DeviceSafetyCharacteristicCard dsc={dsc} />;
      })}
    </Grid>
  );
};

export default withLockedContext(PayloadFields);
