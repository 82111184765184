import React from 'react';
import { Typography } from '@material-ui/core';

function Collaborator({ fullName, title }) {
  return (
    <>
      <Typography variant="body2" style={{ fontWeight: 600 }}>
        {fullName}
      </Typography>
      <Typography variant="body2" style={{ fontSize: '0.562rem' }}>
        {title}
      </Typography>
    </>
  );
}

export default Collaborator;
