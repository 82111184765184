import { useState } from 'react';
import { useCurrentUser } from 'compositions/WithCurrentUser';
import { useGenericOptionsDropdown } from 'compositions/GenericOptionsDropdown/useGenericOptionsDropdown';
import { taskFunctionsMap } from '../Task/viewFunctions';
import { formFunctionsMap } from 'itemTypes/FormRecord/viewFunctions';
import { GridRef } from 'utils/viewOptionUtils';

type Options = {
  viewOption: string;
};

type ViewOptionReturnType = [(event: any, gridRef: GridRef) => void, Options];

const functionsMap = new Map([
  ['task', taskFunctionsMap],
  ['formRecord', formFunctionsMap],
  ['inProcessInspectionRecord', formFunctionsMap],
  ['incomingQualityInspectionRecord', formFunctionsMap],
  ['finalDeviceInspectionRecord', formFunctionsMap],
]);

export const useViewOption = (itemType: string): ViewOptionReturnType => {
  const { defaultOption } = useGenericOptionsDropdown(itemType);
  const [viewOption, setViewOption] = useState<string>(defaultOption!);
  const { data: currentUserData } = useCurrentUser();

  const onChange = (event: any, gridRef: GridRef) => {
    const itemTypeFunctionMap = functionsMap.get(itemType);
    if (itemTypeFunctionMap) {
      const itemTypeFunction = itemTypeFunctionMap.get(event.target.value);
      if (itemTypeFunction) {
        itemTypeFunction(gridRef, currentUserData);
        setViewOption(event.target.value);
      }
    }
  };

  return [onChange, { viewOption }];
};
