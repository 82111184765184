import { Option } from 'components/OptionsDropdown';
import { taskOptions } from 'pages/Task/viewOptions';
import { formOptions } from 'itemTypes/FormRecord/viewOptions';

const optionsMap = new Map<string, Option[]>([
  ['task', taskOptions],
  ['formRecord', formOptions],
  ['inProcessInspectionRecord', formOptions],
  ['incomingQualityInspectionRecord', formOptions],
  ['finalDeviceInspectionRecord', formOptions],
]);

const defaultOption = new Map<string, string>([
  ['task', 'allTasks'],
  ['formRecord', 'allRecords'],
  ['inProcessInspectionRecord', 'allRecords'],
  ['incomingQualityInspectionRecord', 'allRecords'],
  ['finalDeviceInspectionRecord', 'allRecords'],
]);

type ReturnType = {
  title: string;
  options: Option[] | undefined;
  defaultOption: string | undefined;
};

export const useGenericOptionsDropdown = (itemType: string): ReturnType => {
  return { title: 'Views', defaultOption: defaultOption.get(itemType), options: optionsMap.get(itemType) };
};
