import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    textReason: {
      ...theme.typography.body2,
      width: '100%',
      margin: 0,
      background: theme.palette.common.input.background,
      border: `2px solid ${theme.palette.common.input.outline}`,
      borderRadius: 4,
      padding: theme.spacing(1),
      '& .action': {
        display: 'none',
      },
      '&:hover': {
        '& .action': {
          display: 'inherit',
        },
      },
    },
    input: {
      fontSize: 'inherit',
      color: 'inherit',
      lineHeight: 'inherit',
      padding: 0,
      height: theme.spacing(2),
    },
  }),
);
