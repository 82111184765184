import React, { FC, useEffect, useRef, useState } from 'react';

import LinearScale from '@material-ui/icons/LinearScale';
import { MenuItem } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import flowRight from 'lodash/flowRight';

import { AlertDialogWithUser, ContentSection } from 'components';
import WorkflowMenu from 'components/WorkflowMenu';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import { indexById, SupplierWorkflowStatuses } from 'workflows/statuses';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

import ConnectBar from './ConnectBar';
import PermissionFailModal from './PermissionFailModal';
import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';
import WithCurrentUser from '../../WithCurrentUser';
import withMetadataFromServer from '../../WithMetadataFromServer';
import { StatusHistory } from './StatusHistory';
import { StatusSegment } from './StatusSegment';

const {
  ReadyForPrequalification,
  PrequalificationComplete,
  ReadyForQualification,
  QualificationComplete,
  SupplierApproved,
  QualificationRejected,
  QualificationRevoked,
} = SupplierWorkflowStatuses;

const StatusById = indexById(SupplierWorkflowStatuses);

interface Props {
  currentUser: any;
  currentCompany: any;
  currentCompanyValidatedSystem: any;
  currentCompanyProfessionalLicense: any;
  currentCompanyAllowsTransitioning: any;
  currentVersion: any;
  handleInitTransition: any;
  permissions: any;
  remoteItemTypeMetadata: any;
}

interface DeniedModalState {
  open: boolean;
  user: object | any;
  role: string | any;
}

const PATHS = [
  [
    'supplier_requested',
    'ready_for_prequalification',
    'prequalification_complete',
    'ready_for_qualification',
    'qualification_complete',
    'supplier_approved',
  ],
];

export const REASONS_FOR_SIGNATURE = {
  prequalification_complete:
    'My signature indicates that the Supplier information including category, associated risk, and planned qualification activities have been identified. I verify that any high level risks have been communicated to management.',
  supplier_approved:
    'My signature indicates that I have reviewed this Supplier and all associated qualification activities and approve the supplier.',
  qualification_complete:
    'My signature indicates that all planned qualification activities have been completed and associated to this Supplier.',
  qualification_rejected:
    'My signature indicates that I have checked this Supplier and all associated qualification activities and have determined this supplier cannot be approved.',
};

const SupplierWorkflow: FC<Props> = (props) => {
  const {
    permissions: { canOwn, canApprove },
    remoteItemTypeMetadata,
  } = props;

  const [modal, setModal] = useState({ open: false, type: '' });
  const [deniedModalWithUser, setDeniedModalWithUser] = useState<DeniedModalState>({
    open: false,
    user: null,
    role: null,
  });

  const scrollRef = useRef(null as any);
  useEffect(() => {
    scrollRef.current && (scrollRef.current as any).scrollBy(999999999, 0);
  }, []);

  const workflowControls = () => {
    const { currentCompanyAllowsTransitioning, currentVersion, handleInitTransition } = props;
    if (!currentCompanyAllowsTransitioning) return;

    const status = StatusById[currentVersion.currentStatusName];

    const canRevoke = canOwn && status.isEqualTo(SupplierApproved);
    const canArchive = canOwn && (status.isEqualTo(QualificationRejected) || status.isEqualTo(QualificationRevoked));

    const doRevoke = () => handleInitTransition('qualificationRevoked');
    const doArchive = () => handleInitTransition('archived');

    return (
      <>
        <WorkflowMenu>
          <MenuItem disabled={!canRevoke} onClick={doRevoke}>
            Revoke
          </MenuItem>
          <MenuItem disabled={!canArchive} onClick={doArchive}>
            Archive
          </MenuItem>
        </WorkflowMenu>
      </>
    );
  };

  const { currentCompanyAllowsTransitioning, currentVersion, handleInitTransition } = props;
  const { currentStatusName } = currentVersion;
  const currentWorkflowStatus = StatusById[currentStatusName];
  const showPlus =
    currentWorkflowStatus.isEqualTo(QualificationRevoked) || currentWorkflowStatus.isEqualTo(QualificationRejected);

  const currentPath = PATHS.find((x) => !!x.find((name) => name === currentStatusName));
  const currentPathPoint = currentPath && currentPath.indexOf(currentStatusName);
  const showStatus = (name) => {
    if (!currentPath || currentPathPoint == null) return false;
    const itsIndex = currentPath.indexOf(name);
    if (itsIndex === -1) return false;
    return itsIndex > currentPathPoint;
  };

  const inactiveUsers = getInactiveUsers(currentVersion.owner, currentVersion.approver);
  const usersWithoutActiveRole = getUsersWithoutActiveRole(
    currentVersion.owner,
    currentVersion.approver,
    currentVersion.assignedOwnerJobRole,
    currentVersion.assignedApproverJobRole,
  );

  const handleInactiveUserDeniedModal = (inactiveUsers) => {
    setDeniedModalWithUser({
      open: true,
      user: inactiveUsers[0].user,
      role: inactiveUsers[0].role,
    });
  };

  const handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    setDeniedModalWithUser({
      open: true,
      user: usersWithoutActiveRole[0].user,
      role: usersWithoutActiveRole[0].role,
    });
  };

  const FutureSegment = ({ name, onClick }) => {
    return (
      <StatusSegment
        statusName={name}
        show={showStatus(name)}
        active={false}
        completed={false}
        disabled={!currentCompanyAllowsTransitioning}
        onClick={onClick}
        wideLabel={true}
        version={currentVersion.versionIdentifier}
      />
    );
  };

  return (
    <ContentSection LabelText={'Workflow'} Icon={<LinearScale />} Controls={workflowControls()}>
      <div style={{ overflowX: 'scroll', paddingTop: 30 }} ref={scrollRef}>
        <StatusHistory
          isWorkflowSignatureShown={remoteItemTypeMetadata.isWorkflowSignatureShown}
          version={currentVersion}
          statuses={currentVersion.item.statusHistory}
          disabled={!currentCompanyAllowsTransitioning}
        />

        {showPlus && (
          <>
            <ConnectBar color="draft" short visible />
            <SmallCircle content={<Add />} color="draft" handleClick={() => handleInitTransition('createNewVersion')} />
          </>
        )}

        <PermissionFailModal
          open={modal.open}
          failType={modal.type}
          closeModal={() => setModal({ ...modal, open: false })}
        />

        <FutureSegment
          name="ready_for_prequalification"
          onClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            if (inactiveUsers.length) return handleInactiveUserDeniedModal(inactiveUsers);
            if (usersWithoutActiveRole.length) return handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);

            if (currentWorkflowStatus.isBefore(ReadyForPrequalification) && currentCompanyAllowsTransitioning)
              return handleInitTransition('readyForPrequalification');
          }}
        />

        <FutureSegment
          name="prequalification_complete"
          onClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            if (!canOwn) return setModal({ open: true, type: 'permissions' });

            if (currentWorkflowStatus.isBefore(PrequalificationComplete) && currentCompanyAllowsTransitioning)
              return handleInitTransition('prequalificationComplete');
          }}
        />

        <FutureSegment
          name="ready_for_qualification"
          onClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            if (currentWorkflowStatus.isBefore(ReadyForQualification) && currentCompanyAllowsTransitioning)
              return handleInitTransition('readyForQualification');
          }}
        />

        <FutureSegment
          name="qualification_complete"
          onClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            if (!canOwn) return setModal({ open: true, type: 'permissions' });

            if (currentWorkflowStatus.isBefore(QualificationComplete) && currentCompanyAllowsTransitioning)
              return handleInitTransition('qualificationComplete');
          }}
        />

        <FutureSegment
          name="supplier_approved"
          onClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            if (!canApprove) return setModal({ open: true, type: 'permissions' });

            if (currentWorkflowStatus.isBefore(SupplierApproved) && currentCompanyAllowsTransitioning)
              return handleInitTransition('supplierApproved');
          }}
        />

        <AlertDialogWithUser
          open={deniedModalWithUser.open}
          titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
          onClose={() => setDeniedModalWithUser({ open: false, user: null, role: null })}
          subtitle={
            inactiveUsers.length
              ? 'The following user has been deactivated.'
              : "The following user's role has been removed."
          }
          user={deniedModalWithUser.user}
          role={
            deniedModalWithUser.role === 'owner'
              ? currentVersion.assignedOwnerJobRole
              : currentVersion.assignedApproverJobRole
          }
          body={`Please use the People Menu to reassign the ${
            deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
          } role to a new user.`}
        />
      </div>
    </ContentSection>
  );
};

export default flowRight(
  WithCurrentUser,
  withCurrentCompany,
  withMetadataFromServer(),
)(SupplierWorkflow);
