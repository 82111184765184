import React from 'react';

import { Link as LinkIcon } from '@material-ui/icons';
import { Card, Typography } from '@material-ui/core';

import VersionIcon from 'assets/icons/Version';
import Table from 'components/core/Table';
import { DeleteIcon, VersionCell } from 'components/VersionTables';
import LabelText from 'components/LabelText';
import Spacer from 'components/Spacer';
import { itemLink } from 'components/MakeLink';
import IconButton from 'components/IconButton';
import VersionSwitch from 'components/VersionSwitch';
import { useToggleablePopover } from 'hooks/useToggleablePopover';
import { ItemSearch } from 'compositions';

import useStyles from './styles';

export interface AssociatedVersionsProps {
  versionId: string;
  locked: boolean;
  associatedVersions: any[];
  handlers: {
    removeAssociatedItemVersion: (id: string) => Promise<any>;
    updateAssociatedItemVersion: (nextId: string, oldId: string) => Promise<any>;
    addAssociatedItemVersion: (id: string) => Promise<any>;
  };
}

const AssociatedItemsVersions = (props: AssociatedVersionsProps) => {
  const { associatedVersions = [], handlers, locked } = props;
  const [searchOpen, searchAnchor, toggleSearch] = useToggleablePopover();

  const changeAttachedVersion = (nextId: string, prevId: string): void => {
    handlers.updateAssociatedItemVersion(nextId, prevId).catch((err) => {
      throw new Error(`Error updating associated item version: ${err.message}`);
    });
  };

  const classes = useStyles();

  return (
    <div>
      <LabelText labelText="Associated Items Versions" Icon={VersionIcon} />
      <Spacer factor={2} />
      <div className={classes.tableContainer}>
        {associatedVersions.length > 0 ? (
          <Table
            data={associatedVersions.sort((a, b) => a.item.customIdentifier.localeCompare(b.item.customIdentifier))}
            actions={
              !locked
                ? [
                    {
                      icon: () => <DeleteIcon />,
                      onClick: (_e, associatedItem) => {
                        handlers.removeAssociatedItemVersion(associatedItem.id).catch((err) => {
                          throw new Error(`Error removing associated item version: ${err.message}`);
                        });
                      },
                    },
                  ]
                : undefined
            }
            columns={[
              {
                title: 'ID',
                field: 'id',
                render: (version) => (
                  <a href={itemLink(version.item)} target="_blank" rel="noopener noreferrer">
                    {version.item.customIdentifier}
                  </a>
                ),
              },
              {
                title: 'Title',
                field: 'title',
              },
              {
                title: 'Version',
                align: 'center',
                render: (version) => (
                  <div className={classes.versionCell}>
                    {locked ? (
                      <VersionCell version={version.versionIdentifier} status={version.currentStatus.name} />
                    ) : (
                      <VersionSwitch
                        disabled={locked}
                        currentRelease={version.item.currentRelease}
                        workingVersion={version.item.workingVersion}
                        value={version.id}
                        onToggle={(value) => {
                          if (version.id === value) return;
                          changeAttachedVersion(value, version.id);
                        }}
                        withAddButton={false}
                      />
                    )}
                  </div>
                ),
              },
            ]}
          />
        ) : (
          <Card className={classes.noItemsPanel} variant={'outlined'}>
            <Typography variant="caption">No versions associated.</Typography>
          </Card>
        )}
      </div>
      {!locked && (
        <IconButton
          disabled={locked}
          onClick={(e) => toggleSearch(e.currentTarget)}
          Icon={LinkIcon}
          includeBackground
        />
      )}
      <ItemSearch
        draftOnly={false}
        supportsChangeOrder={false}
        defaultSearchTerm={null}
        open={searchOpen}
        closeSelf={() => toggleSearch(null)}
        onSubmit={(associatedItemVersionIds) =>
          Promise.all(
            associatedItemVersionIds.map((associatedItemVersionId) =>
              handlers.addAssociatedItemVersion(associatedItemVersionId),
            ),
          )
            .then(() => true)
            .catch(() => false)
        }
        anchorEl={searchAnchor}
      />
    </div>
  );
};

export default AssociatedItemsVersions;
