import React from 'react';

import { Button, withStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import flowRight from 'lodash/flowRight';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

const styles = (theme) => ({
  controlButton: {
    ...theme.buttonMixins.justIcon,
    margin: 0,
  },
});

const EllipsesMenuButton = ({ classes, i18n, i18nHash, onClick, ...props }) => (
  <Button
    {...props}
    aria-haspopup="true"
    aria-label={i18n._(t`Ellipses Menu`)}
    classes={{ root: classes.controlButton }}
    onClick={onClick}
  >
    <MoreVert titleAccess={i18n._(t`More Operations Menu`)} color="action" />
  </Button>
);

export default flowRight([withI18n(), withStyles(styles)])(EllipsesMenuButton);
