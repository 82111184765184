import React from 'react';

import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import { withStyles } from '@material-ui/core';

import styles from './styles';

import Edge from '../Edge';
import Graph from '../Graph';

class Trace extends React.Component {
  recursivelyDrawRows = (
    root,
    numHops,
    direction,
    visibleColumns, // numUpstream
    parent,
    graph,
    initial = true,
  ) => {
    if (numHops < 0) return;
    if (!root) return;

    const children = root[direction];
    const uniqueChildren = uniqBy(children, 'id');
    const selected = this.vertexSelected(root);
    const selectedEdgeStyles = { width: 3, color: this.props.theme.palette.primary.main };
    const selection = this.props.currentPosition.selection;
    const upstreamTrace = graph.findEdge(root.object.id, parent.object.id);
    const downstreamTrace = graph.findEdge(parent.object.id, root.object.id);
    
    const descendantRecordItems = [
      'deviation',
      'validation_record',
      'verification_record',
      'device_validation',
      'device_verification',
      'supplier_questionnaire',
      'supplier_questionnaire_record',
    ];
    
    const upstreamEdgeStyle =
      selected && get(upstreamTrace, 'id') === get(selection.trace, 'id') ? selectedEdgeStyles : {};
    const downstreamEdgeStyle =
      selected && get(downstreamTrace, 'id') === get(selection.trace, 'id') ? selectedEdgeStyles : {};

    const isCardBelongsToRecordStyle = (itemType) => Boolean(descendantRecordItems.includes(itemType));
  
    const isRecordStyle = () => {
      return (isCardBelongsToRecordStyle(get(root, 'object.itemType')));
    };

    if (initial) {
      return (
        <div
          style={{
            display: 'flex',
            flex: visibleColumns,
            flexDirection: 'column',
          }}
        >
          {uniqueChildren.map((vertex) => {
            return this.recursivelyDrawRows(
              graph.getVertex(vertex.object.id),
              numHops - 1,
              direction,
              visibleColumns,
              root,
              graph,
              false,
            );
          })}
        </div>
      );
    }

    if (Math.abs(numHops) >= visibleColumns) {
      return uniqueChildren.map((vertex) =>
        this.recursivelyDrawRows(
          graph.getVertex(vertex.object.id),
          numHops - 1,
          direction,
          visibleColumns,
          root,
          graph,
          false,
        ),
      );
    }

    return (
      <div style={{ display: 'flex', flex: 1 }} key={`d-${root.id}`}>
        {direction === 'downstream' && (
          <>
            {isRecordStyle()? <Edge  width="3" { ...downstreamEdgeStyle} /> : <Edge { ...downstreamEdgeStyle}/>}
            {this.props.drawCard(
              root,
              parent,
              selected && get(downstreamTrace, 'id') === get(selection.trace, 'id'),
              'downstream',
            )}
          </>
        )}
        {numHops > 0 && (
          <div style={{ flex: numHops, display: 'flex', flexDirection: 'column' }}>
            {uniqueChildren.map((vertex) => {
              return this.recursivelyDrawRows(
                graph.getVertex(vertex.object.id),
                numHops - 1,
                direction,
                visibleColumns,
                root,
                graph,
                false,
              );
            })}
          </div>
        )}
        {direction === 'upstream' && (
          <>
            {this.props.drawCard(root, parent, selected && upstreamTrace.id === get(selection.trace, 'id'), 'upstream')}
            {isRecordStyle()? <Edge  width="3" { ...upstreamEdgeStyle} /> : <Edge { ...upstreamEdgeStyle}/>}
          </>
        )}
      </div>
    );
  };

  vertexSelected = (vertex) => {
    const selection = this.props.currentPosition.selection;
    const result = get(selection.root, 'id') === this.props.root.id && get(selection.object, 'id') === vertex.object.id;

    return result;
  };

  render() {
    const graph = this.props.graph;
    const vertex = graph.getVertex(this.props.root.id) || Graph.createVertex(this.props.root);
    const currentPosition = this.props.currentPosition;
    const selected = this.vertexSelected(vertex);

    return (
      <div className={this.props.classes.traceRow}>
        {this.recursivelyDrawRows(
          vertex,
          currentPosition.trueUpstream,
          'upstream',
          currentPosition.numUpstream,
          vertex,
          graph,
        )}

        {currentPosition.rootIsOnScreen && this.props.drawCard(vertex, null, selected)}
        {this.recursivelyDrawRows(
          vertex,
          currentPosition.trueDownstream,
          'downstream',
          currentPosition.numDownstream,
          vertex,
          graph,
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Trace);
