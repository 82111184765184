import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

interface Variables {
  userIds: number[];
}

const query = gql`
  query FilteredSearch($userIds: [ID!]!) {
    trainingRecordSearch(userIds: $userIds) {
      customIdentifier
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      currentVersion {
        id
        completionDate
        dueDate
        currentStatus {
          id
          name
        }
        category
        source {
          id
          currentStatus {
            id
            name
          }
          item {
            customIdentifier
            id
            itemType {
              id
              displaySlug
              userCreatable
              name
            }
          }
          versionIdentifier
          ... on CourseVersion {
            qualityDocumentVersions {
              id
              item {
                customIdentifier
                id
                itemType {
                  id
                  displaySlug
                  userCreatable
                }
              }
            }
          }
        }
        user {
          id
          firstName
          lastName
          jobRoles {
            formattedRoleName
            id
          }
        }
      }
    }
  }
`;

export const useTrainingRecordSearch = (variables: Variables) =>
  useQuery(query, {
    variables,
    fetchPolicy: 'no-cache',
  });
