import gql from 'graphql-tag';
import get from 'lodash/get';
import { GenericWorkflowStatuses, indexById } from 'workflows/statuses';

const StatusesById = indexById(GenericWorkflowStatuses);

export function isPastDraftStatus(currentStatus: string): boolean {
  const status = StatusesById[currentStatus];
  return status && !status.isAfter(GenericWorkflowStatuses.Draft);
}

export const defaultFolderAttrs = {
  folderName: 'Untitled Folder',
  sortPosition: 0,
  indentationLevel: 0,
};

export const folderFragment = gql`
  fragment FolderFragment on Folder {
    id
    indentationLevel
    sortPosition
    folderName
  }
`;

/* 
  Typescript type inference is not smart enough so we need to look at the
  underylying apollo typename.
*/
export function isFolder(member) {
  return get(member, '__typename') === 'Folder';
}
