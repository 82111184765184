import gql from 'graphql-tag';

export const suppliersQuery = gql`
  query SupplierListQuery {
    suppliers(status: "supplier_approved") {
      id
      customIdentifier
      itemType {
        id
        displaySlug
        userCreatable
      }
      currentVersion {
        id
        title
        suppliedPart
        suppliedService
        suppliedOther
        suppliedOtherText
        suppliedScope
        riskLevel
        approvalExpiresAt
        categoryText
        currentStatus {
          id
          name
          createdAt
        }
      }
      createdAt
    }
  }
`;
