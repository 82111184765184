import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { AllUsers } from './__generated__/AllUsers';

export const usersQuery = gql`
  query AllUsers {
    users {
      id
      fullName
    }
  }
`;

function useMentionableUsers() {
  const { data, loading, error } = useQuery<AllUsers>(usersQuery);
  if (error) throw new Error(`${error}`);
  const users = data
    ? data.users.map((u) => ({
        display: u.fullName,
        id: u.id,
      }))
    : [];
  return { users, loading };
}

export default useMentionableUsers;
