import { WorkflowDefinition, WorkflowTransition } from 'compositions/TimelineGroup/steps/types';

import CreateNewVersion from './CreateNewVersion';
import RecordOfOrigin from './RecordOfOrigin';
import Investigate from './Investigate';
import ApproveToProceed from './ApproveToProceed';
import Void from './Void';
import CloseOrReject from './CloseOrReject';
import SummaryOfChange from './SummaryOfChange';
import ResolveRejection from './ResolveRejection';
import Cancel from './Cancel';

const deviationWorkflowSteps: WorkflowDefinition = {
  createNewVersion: CreateNewVersion as WorkflowTransition,
  recordOfOrigin: RecordOfOrigin as WorkflowTransition,
  investigate: Investigate as WorkflowTransition,
  approveToProceed: ApproveToProceed as WorkflowTransition,
  void: Void as WorkflowTransition,
  closeOrReject: CloseOrReject as WorkflowTransition,
  resolveRejection: ResolveRejection as WorkflowTransition,
  summaryOfChange: SummaryOfChange as WorkflowTransition,
  cancel: Cancel as WorkflowTransition,
};

export default deviationWorkflowSteps;
