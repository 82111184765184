import React, { ReactNode } from 'react';

import { useStyles } from './styles';

interface Props {
  children: ReactNode;
}

function Adornment(props: Props) {
  const classes = useStyles();
  return <span className={classes.container}>{props.children}</span>;
}

export default Adornment;
