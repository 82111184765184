export default (theme) => ({
  addCircleIcon: {
    color: theme.palette.secondary.main,
    fontSize: 20,
  },
  tag: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.tags.background,
    borderRadius: 16,
    alignItems: 'center',
    display: 'inline-flex',
    marginBottom: 6,
    marginRight: 6,
    padding: '1px 6px',
  },
  removeIcon: {
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    color: theme.palette.common.tags.background,
    cursor: 'pointer',
    fontSize: 14,
    marginLeft: 8,
  },
  input: {
    width: '100%',
  },
  searchInput: {
    ...theme.typography.h3, // sizes pulled in from theme so they can be applied to input directly

    marginBottom: theme.spacing(1),
    '&::after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  suggestionsContainer: {
    margin: 0,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    width: '240px',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    'list-style-type': 'none',
  },
  suggestion: {
    ...theme.typography.body2,
    cursor: 'pointer',
    padding: '8px 14px',
  },
  suggestionHighlighted: {
    'background-color': theme.palette.common.tags.highlight,
  },
});
