import React from 'react';
import { useMutation } from 'react-apollo';

import { Button, Grid, createStyles, makeStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { withLockedContext } from 'contexts/lockedContext';
import AgGridTable from 'components/AgGridTable';
import { Loading } from 'components';

import { CREATE_USER_GROUP, REMOVE_MEMBERS, useVersion } from './gql';
import RemoveControl from './Controls/RemoveControl';
import BottomRowControls from 'components/LongCardControls/BottomRowControls';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      '&:active': {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
      },
    },
  }),
);

const userGroupSpecificationVersionQuery = 'UserGroupSpecificationVersionQuery';

const RenderActions = ({ row }) => {
  const [removeUserGroups] = useMutation(REMOVE_MEMBERS, {
    refetchQueries: [userGroupSpecificationVersionQuery],
  });

  return (
    <BottomRowControls
      versionId={row.data.id}
      itemRecord={row.data.item}
      queryToRefetch={userGroupSpecificationVersionQuery}
      disabled={row.data.locked}
      parentSetItemSlug={'pencil'}
      renderDeleteControl={() => (
        <RemoveControl
          removePrompts={{
            head: 'Remove User Group',
            body: `Are you sure to remove item ${row.data.item.customIdentifier}?`,
          }}
          onRemove={() => removeUserGroups({ variables: { ids: [row.data.member.id] } })}
          disabled={row.data.locked}
        />
      )}
    />
  );
};

const columns = [
  {
    type: 'regular_text',
    headerName: 'ID',
    field: 'item.customIdentifier',
  },
  {
    type: 'editable_text',
    headerName: 'Title',
    field: 'title',
    editAttr: 'title',
    currentVersionPath: '',
    blockRowLink: true,
    refetchQuery: userGroupSpecificationVersionQuery,
  },
  {
    headerName: 'Description',
    field: 'description',
    type: 'editable_text',
    editAttr: 'description',
    currentVersionPath: '',
    blockRowLink: true,
    refetchQuery: userGroupSpecificationVersionQuery,
  },
  {
    headerName: 'Actions',
    field: 'item',
    type: 'custom',
    blockRowLink: true,
    render: (row) => <RenderActions row={row} />,
  },
];

function PayloadFields({ versionId }) {
  const classes = useStyles();
  const { data, loading } = useVersion(versionId);
  const [createUserGroup] = useMutation(CREATE_USER_GROUP, {
    refetchQueries: [userGroupSpecificationVersionQuery],
  });

  const handleCreateNewEnvironmentButtonClick = () => {
    createUserGroup({
      variables: {
        userGroupSpecificationId: versionId,
        title: 'Default Title',
      },
    }).catch((err) => {
      throw new Error(`Error creating use environment: ${err.message}`);
    });
  };

  if (loading) return <Loading />;

  return (
    <Grid item xs={12} style={{ padding: '10px 0' }}>
      <AgGridTable
        data={data.userGroupSpecificationVersion.userGroupVersions}
        columns={columns}
        TableActionButton={() => (
          <Button
            onClick={handleCreateNewEnvironmentButtonClick}
            variant="outlined"
            startIcon={<AddCircle />}
            className={classes.button}
            disabled={data.userGroupSpecificationVersion.locked}
          >
            Create New User Group
          </Button>
        )}
      />
    </Grid>
  );
}

export default withWorkspaceViews()(withLockedContext(PayloadFields));
