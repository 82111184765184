export const docStylePayloadFields = `
  id
  body
  inputMethod
  locked
  displayAsApplicationVersion
  attachment {
    id
    url
    filename
    createdAt
    creator {
      id
      fullName
    }
  }
  rawReferenceAttachment {
    id
    url
    filename
    createdAt
    creator {
      id
      fullName
    }
  }
`;
