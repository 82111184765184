import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme: any) => ({
  headingContainer: {
    display: 'flex',
    marginBottom: 17,
    position: 'relative',
  },
  noticeContainer: {
    position: 'relative',
  },
  noticeSection: {
    position: 'absolute',
    bottom: '100%',
    right: 0,
    width: '100%',
    borderBottom: '1px solid #33BCB4',
    marginBottom: theme.spacing(1),
  },
  noticeContainerWidthUnlocked: {
    width: 'calc((100% - 60px) * 3 / 4 + 60px)',
    marginLeft: 'calc((100% - 60px) * 1 / 4);',
  },
  noticeContainerWidthLocked: {
    width: 'calc((100% - 0px) * 3 / 4 + 0px)',
    marginLeft: 'calc((100%) * 1 / 4);',
  },
  noticeHeading: {
    // @ts-ignore
    color: theme.palette.primary.main,
  },
  noticeMessage: {
    paddingLeft: 20,
  },
  noItemsPanel: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  tableContainer: {
    marginBottom: theme.spacing(1),
  },
  container: {
    width: '100%',
  },
}));
