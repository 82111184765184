import React from 'react';
import Autosuggest from 'react-autosuggest';
import { Query, withApollo } from 'react-apollo';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';

import { Paper, Button, InputAdornment, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import Search from '@material-ui/icons/Search';

import { Spacer } from 'components';
import styles from '../styles';
import { moveOrAddToSetMutationFor, optionsQueryFor } from './gql';

const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  return options.filter((opt) => opt.currentVersion.title.toLowerCase().includes(inputValue));
};

const getSuggestionValue = (suggestion) => suggestion.currentVersion.title;

const renderSuggestion = (suggestion) => {
  return <MenuItem component="div">{`${suggestion.customIdentifier}: ${suggestion.currentVersion.title}`}</MenuItem>;
};

const renderInputComponent = ({ classes, inputRef = () => {}, ref, ...rest }) => (
  <TextField
    id="select-co-input"
    fullWidth
    variant="outlined"
    margin="dense"
    InputProps={{
      inputRef: (node) => {
        ref(node);
        inputRef(node);
      },
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    }}
    {...rest}
  />
);

class SelectSetItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      suggestions: [],
      selectedSetItemId: null,
    };
  }

  onChange = (_, { newValue }) => {
    this.setState({ value: newValue, hasTypedSinceLastSelection: true });
  };

  onSuggestionSelected = (_, { suggestion }) => {
    this.setState({
      selectedSetItemId: suggestion.currentVersion.id,
      hasTypedSinceLastSelection: false,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const opts = this.props.options;
    this.setState({
      suggestions: getSuggestions(value, opts),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  shouldRenderSuggestions = () => {
    return true;
  };
  handleSubmit = () => {
    this.setState({ submitInProgress: true });
    this.props.client
      .mutate({
        mutation: moveOrAddToSetMutationFor(this.props.itemTypeMetadata.name),
        variables: {
          setItemVersionId: this.state.selectedSetItemId,
          setMemberVersionId: this.props.currentVersion.id,
        },
        refetchQueries: [
          'UserNeedsSetVersionQuery',
          'RequirementsSetVersionQuery',
          'timelineQuery',
          'requirementQuery',
          'userNeedQuery',
        ],
      })
      .then(() => {
        this.setState({ submitInProgress: false });
        this.props.handleComplete();
      });
  };

  render() {
    const { classes, unsavedValue } = this.props;
    const { suggestions, value } = this.state;
    let displayValue;
    if (value !== null && value !== undefined) {
      displayValue = value;
    } else if (unsavedValue) {
      displayValue = unsavedValue.displayValue;
    } else {
      displayValue = '';
    }

    const setItemId = this.state.selectedSetItemId || get(unsavedValue, 'setItemId', null);

    return (
      <div>
        <div className={classes.heading}>
          <Typography variant="h3">Add to Parent Set</Typography>

          <Spacer factor={1} />
          <Typography variant="body2">Select a Parent Set to add:</Typography>
        </div>

        <Spacer factor={2} />
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          inputProps={{
            value: displayValue,
            classes,
            onChange: this.onChange,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
        <div className={classes.spacer} />
        <div className={classes.spacer} />
        <Button
          id="select-co-button"
          fullWidth
          variant="contained"
          color="primary"
          disabled={this.state.hasTypedSinceLastSelection || setItemId === null || this.state.submitInProgress}
          onClick={this.handleSubmit}
        >
          Next
        </Button>
      </div>
    );
  }
}

const withOptions = (Component) => (props) => {
  const parentItemType = props.itemTypeMetadata.parentSetItemType.name;
  const { query, key } = optionsQueryFor(parentItemType);
  return (
    <Query query={query} fetchPolicy={'network-only'} variables={{ status: 'draft' }}>
      {({ data, error, loading }) => {
        if (loading) return null;
        if (error) throw new Error(`Sets query failed: ${error}`);

        const options = data[key];
        return <Component {...props} options={options} />;
      }}
    </Query>
  );
};

export default flowRight([withStyles(styles), withOptions, withApollo])(SelectSetItem);
