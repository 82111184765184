export default (theme) => ({
  arrowIcon: {
    width: '16px',
    color: theme.palette.text.secondary,
    position: 'relative',
    bottom: '1px',
    left: '1px',
  },
  headerName: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    padding: '0',

    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  headerCell: {
    padding: '5px',
    textAlign: 'left',
    paddingLeft: '1.5em',
  },
  tableContainer: {
    overflowX: 'auto',
  },
  tableHeaderRow: {
    height: '60px',
  },
  tableBodyRow: {
    cursor: 'pointer',
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0.07)',
    },
  },
});
