import gql from 'graphql-tag';

export const PROFILE_QUERY = gql`
  query ProfileQuery($id: ID!) {
    profile(id: $id) {
      id
      firstName
      lastName
      emailAddress
      phoneNumber
      secondaryPhoneNumber
      photoUrl
      title
      user {
        id
        customIdentifier
        fullName
        username
        status
        jobRoles {
          id
          formattedRoleName
        }
        employee {
          id
          customEmployeeId
          startDate
          type
          department
          manager {
            id
            fullName
          }
          trainingRecordVersions {
            id
            title
            dueDate
            currentStatus {
              id
              name
            }
            source {
              id
              title
              item {
                id
                itemType {
                  id
                  displaySlug
                  userCreatable
                }
                customIdentifier
              }
            }
            item {
              id
              itemType {
                id
                displaySlug
                userCreatable
              }
              customIdentifier
            }
            versionIdentifier
          }
        }
      }
    }
  }
`;
