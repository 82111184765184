import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    gridGutterRight: {
      paddingLeft: `0 !important`,
      paddingRight: theme.spacing(1.5),
    },
    grid: {
      paddingLeft: theme.spacing(2),
      paddingRight: `0 !important`,
    },
    container: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    toggleText: {
      marginLeft: 0,
    },
    trueStatusSwitcher: {
      backgroundColor: theme.palette.primary.main,
    },
    falseStatusSwitcher: {
      backgroundColor: '#8f8f8f',
    },
    nullStatusSwitcher: {
      backgroundColor: theme.palette.splitter.background,
    },
    deviationsIcon: {
      width: '16px',
      height: '16px',
      borderRadius: '100%',
      color: '#ffffff',
      backgroundColor: '#6E7583',
      fontSize: '9px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.spacing(0.375),
    },
    associatedRowContainer: {
      display: 'flex',
      margin: `${theme.spacing(0.5)} 0 0 ${theme.spacing(1.5)}`,
      alignItems: 'baseline',
    },
    protocol: {
      '& h6.MuiTypography-root': {
        marginBottom: theme.spacing(1),
        marginLeft: 36,
      },
    },
    fullHeight: {
      height: '100%'
    }
  }),
);
