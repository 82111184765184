import React from 'react';

import { Link, Paper } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import PictureInPicture from '@material-ui/icons/PictureInPicture';

import { Spacer } from 'components';
import Diagram from 'assets/img/precompliance_diagram.png';
import { useStyles } from './styles';
import SectionLabel from 'components/core/SectionLabel';

function PrecomplianceDiagramWidget() {
  const classes = useStyles();

  const helpUrl = 'https://nemedio.zendesk.com/hc/en-us/articles/360031683571';
  const infoUrl = 'https://nemedio.zendesk.com/hc/en-us/articles/4402288013076';

  return (
    <>
      <SectionLabel icon={PictureInPicture}>
        Precompliance Item Diagram
        <span className={classes.actions}>
          <Link href={helpUrl} target="_blank" rel="noopener">
            <HelpOutline className={classes.icon} />
          </Link>
          <Link href={infoUrl} target="_blank" rel="noopener">
            <InfoOutlined className={classes.icon} />
          </Link>
        </span>
      </SectionLabel>
      <Spacer factor={1} />
      <Paper elevation={1} className={classes.imageContainer}>
        <img className={classes.image} src={Diagram} alt="precompliance-item-diagram" />
      </Paper>
    </>
  );
}

export default PrecomplianceDiagramWidget;
