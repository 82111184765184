import React from 'react';

import { MenuItem } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

function CancelMenuItem(props) {
  return (
    <MenuItem disabled={props.disabled} onClick={props.onClick}>
      <DeleteForeverIcon />
      {props.children ? props.children : 'Cancel'}
    </MenuItem>
  );
}

export default CancelMenuItem;
