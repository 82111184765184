import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '16px',
      height: 'inherit',
    },
  }),
);
