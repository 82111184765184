import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import ViewList from '@material-ui/icons/ViewList';

import slugs from '../../displaySlugs';
import Views from 'workspaces/views';

import PayloadFields from './PayloadFields';

const itemType = 'qualitySystemValidationProtocol';

const versionFields = `
  protocolObjective
  prerequisiteNotes
  testSetupNotes
  qualitySystemValidationTestStepsCollectionId
  qualitySystemValidationTestSteps {
    id
    customIdentifier
    tags
    comments {
      id
    }
    itemType {
      id
      name
    }
    currentVersion {
      id
      title
      acceptanceCriteria
      referenceTestId
      screenshotRequired
      stepText
    }
    upstreamItems {
      customIdentifier
      currentVersion {
        virtualAttributes(attrName: "title") {
          stringValue
        }
      }
    }
  }
`;

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query qualitySystemValidationProtocolsQuery {
    qualitySystemValidationProtocols {
      id
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        versionIdentifier
        currentStatusName
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  icon: ViewList,
  itemType,
  label: t`Quality System Validation Protocol`,
  categoryLabel: t`Validation Protocols`,
  versionFields,
  PayloadFields,
  listViewQuery,
  listViewColumns,
  recordWorkspace: [Views.Set, Views.Effective, Views.Versions],
};
