import React from 'react';

import { LowPriority, People } from '@material-ui/icons';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import snakeCase from 'lodash/snakeCase';

import { TrainingFormat, TrainingType } from '__generated__/globalTypes';
import { SelectField, SelectUserField } from 'components';
import Panel from 'components/core/Panel';

import { updateVersion } from '../gql';
import { updateCourseVersionVariables } from '../__generated__/updateCourseVersion';
import { useStyles } from './styles';

interface Props {
  courseVersion;
  currentUser?;
  displayOnly: boolean;
  refetch?: () => void;
  save?: (variables: updateCourseVersionVariables) => Promise<any>;
  versionId: string;
}

function TrainingInfo(props: Props) {
  const isIndependentStudy = props.courseVersion.trainingFormat === TrainingFormat.INDEPENDENT_STUDY;
  const classes = useStyles();

  const formatValue = (value: string): any => snakeCase(value).toUpperCase();

  return (
    <div className={classes.fullHeight}>
      <Panel>
        <Panel.Heading icon={LowPriority}>Training Type</Panel.Heading>
        <SelectField
          attrName="training_type"
          options={Object.keys(TrainingType).map(capitalize)}
          refetchQueries={['CourseVersionQuery']}
          versionId={props.versionId}
          locked={props.courseVersion.locked || props.displayOnly}
          value={capitalize(props.courseVersion.trainingType)}
          mutation={updateVersion}
          placeholder="Select Type"
          onChange={(e) => {
            props.save &&
              props
                .save({
                  id: props.versionId,
                  trainingType: formatValue(e.target.value),
                })
                .catch((e) => {
                  throw new Error(`Error occurred while updating Course field: ${e}`);
                });
          }}
        />
        <Panel.Heading icon={LowPriority}>Training Format</Panel.Heading>
        <SelectField
          attrName={'training_format'}
          options={Object.keys(TrainingFormat).map(capitalize)}
          refetchQueries={['CourseVersionQuery']}
          versionId={props.versionId}
          locked={props.courseVersion.locked || props.displayOnly}
          value={capitalize(props.courseVersion.trainingFormat)}
          placeholder="Select format"
          mutation={updateVersion}
          onChange={async (e) => {
            try {
              if (e.target.value === TrainingFormat.INDEPENDENT_STUDY) {
                props.save &&
                  (await props.save({
                    id: props.versionId,
                    trainingFormat: formatValue(e.target.value),
                    instructorId: null,
                  }));
                return props.refetch && props.refetch();
              } else {
                props.save &&
                  (await props.save({
                    id: props.versionId,
                    trainingFormat: formatValue(e.target.value),
                  }));
              }
            } catch (e) {
              throw new Error(`Error occurred while updating Course field: ${e}`);
            }
          }}
        />
        <Panel.Heading icon={People}>Instructor</Panel.Heading>
        <SelectUserField
          inEditingMode={!(props.courseVersion.locked || props.displayOnly)}
          disabled={isIndependentStudy || props.displayOnly}
          placeholder={isIndependentStudy ? 'N/A' : 'Select instructor'}
          currentSelectedUser={!isIndependentStudy ? props.courseVersion.instructor : null}
          userProfile={get(props.currentUser, 'profile')}
          onSelectedUserInputData={(val) => {
            props.save &&
              props
                .save({
                  id: props.versionId,
                  instructorId: val,
                })
                .catch((e) => {
                  throw new Error(`Error occurred while updating Course field: ${e}`);
                });
          }}
        />
      </Panel>
    </div>
  );
}
export default TrainingInfo;
