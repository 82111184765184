import gql from 'graphql-tag';

export const USERS_QUERY = gql`
  query UserListQuery {
    users {
      id
      customIdentifier
      customEmployeeId
      department
      emailAddress
      firstName
      lastName
      fullName
      tier
      jobRoles {
        customIdentifier
        formattedRoleName
        roleName
      }
      manager {
        firstName
        lastName
      }
      phoneNumber
      secondaryPhoneNumber
      status
      title
      username
      tier
      employee {
        id
        trainingRecordVersions {
          id
          title
          item {
            id
            customIdentifier
          }
        }
        effectiveTrainingPlanVersion {
          id
          title
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUser(
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $username: String!
    $title: String
    $userType: Int!
  ) {
    addUser(
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      username: $username
      title: $title
      userType: $userType
    ) {
      user {
        id
        fullName
        username
        employee {
          id
        }
      }
    }
  }
`;

export const CREATE_ACCOUNT_CREATED_EMAIL = gql`
  mutation CreateAccountCreatedEmail($input: CreateAccountCreatedEmailInput!) {
    createAccountCreatedEmail(input: $input) {
      success
    }
  }
`;

export const CREATE_TRAINING_PLAN = gql`
  mutation CreateTrainingPlan($employeeId: ID!, $creatorId: ID!) {
    createTrainingPlan(employeeId: $employeeId, creatorId: $creatorId) {
      trainingPlanVersion {
        id
        item {
          id
        }
      }
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation DeactivateUser($userId: ID!, $reasonForChange: String!, $username: String!, $password: String!) {
    deactivateUser(userId: $userId, reasonForChange: $reasonForChange, username: $username, password: $password) {
      user {
        id
      }
    }
  }
`;

export const CREATE_USER_DEACTIVATED_EMAIL = gql`
  mutation CreateUserDeactivatedEmail($input: CreateUserDeactivatedEmailInput!) {
    createUserDeactivatedEmail(input: $input) {
      success
    }
  }
`;
