import React, { useContext } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PeopleIcon from '@material-ui/icons/People';

import { LockedContext } from 'contexts/lockedContext';

import Panel from 'components/core/Panel';
import SelectUserField from 'components/SelectUserField';

import { useStyles } from './styles';

interface Props {
  disabled: boolean;
  currentUser: any;
  selected: any;
  onChange: (id: string) => void;
}

function AssessmentGrader(props: Props) {
  const { locked } = useContext(LockedContext);
  const classes = useStyles();

  return (
    <Panel>
      <Panel.Heading icon={PeopleIcon}>
        Assessment Grader
        <Tooltip title="This is the person who will be responsible for signing off on training records associated with this course.">
          <InfoOutlinedIcon fontSize="small" className={classes.tooltip} />
        </Tooltip>
      </Panel.Heading>
      <SelectUserField
        inEditingMode={!locked}
        disabled={props.disabled}
        placeholder="Select assessment grader"
        currentSelectedUser={props.selected}
        userProfile={props.currentUser}
        onSelectedUserInputData={props.onChange}
      />
    </Panel>
  );
}

export default AssessmentGrader;
