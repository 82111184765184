import React from 'react';
import get from 'lodash/get';

import { longTextHeaderStyle, LongTextCell } from './LongText';
import { regularTextHeaderStyle, RegularTextCell } from './RegularText';
import { idHeaderStyle, IDCell } from './ID';
import { statusHeaderStyle, StatusCell } from './Status';
import { avatarHeaderStyle, AvatarCell } from './Avatar';
import { dateHeaderStyle, DateCell } from './Date';
import { versionHeaderStyle, VersionCell } from './Version';
import { booleanHeaderStyle, BooleanCell } from './Boolean';
import { multiOptionHeaderStyle, MultioptionCell } from './Multioption';
import { arrayHeaderStyle, ArrayCell } from './Array';
import { shortTextHeaderStyle, ShortTextCell } from './ShortText';
import { boolResultCellHeaderStyle, BooleanResultCell } from './BooleanResult';

type FieldTypes =
  | 'id'
  | 'regular_text'
  | 'long_text'
  | 'status'
  | 'user'
  | 'date'
  | 'version'
  | 'boolean'
  | 'multi_options'
  | 'array'
  | 'short_text'
  | 'boolean_result'
  | 'custom';

export function FieldCell({ row, col }) {
  const { Cell } = getComponentsForType(col.type);
  return <Cell col={col} data={get(row, col.key, col.defaultValue || row)} />;
}

export function headerStyle(col) {
  if (col.type === 'custom') return col.headerStyle;

  const { headerStyle } = getComponentsForType(col.type);
  return headerStyle;
}

function getComponentsForType(type: FieldTypes): any {
  switch (type) {
    case 'id':
      return { headerStyle: idHeaderStyle, Cell: IDCell };
    case 'regular_text':
      return { headerStyle: regularTextHeaderStyle, Cell: RegularTextCell };
    case 'long_text':
      return { headerStyle: longTextHeaderStyle, Cell: LongTextCell };
    case 'status':
      return { headerStyle: statusHeaderStyle, Cell: StatusCell };
    case 'user':
      return { headerStyle: avatarHeaderStyle, Cell: AvatarCell };
    case 'date':
      return { headerStyle: dateHeaderStyle, Cell: DateCell };
    case 'version':
      return { headerStyle: versionHeaderStyle, Cell: VersionCell };
    case 'boolean':
      return { headerStyle: booleanHeaderStyle, Cell: BooleanCell };
    case 'multi_options':
      return { headerStyle: multiOptionHeaderStyle, Cell: MultioptionCell };
    case 'array':
      return { headerStyle: arrayHeaderStyle, Cell: ArrayCell };
    case 'short_text':
      return { headerStyle: shortTextHeaderStyle, Cell: ShortTextCell };
    case 'boolean_result':
      return { headerStyle: boolResultCellHeaderStyle, Cell: BooleanResultCell };
    case 'custom':
      return { headerStyle: null, Cell: null };
    default:
      throw new Error(`Invalid type given: ${type}`);
  }
}
