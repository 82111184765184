import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

import gql from 'graphql-tag';
import { t } from '@lingui/macro';

import slugs from '../../displaySlugs';
import { docMetadata, DocumentToolbar } from '../mixins/Document';
import TableColumns from './TableColumns';

const itemType = 'supplierAuditReport';

export default {
  categoryLabel: t`Supplier Audit Report`,
  displaySlug: slugs[itemType],
  label: t`Supplier Audit Report`,
  listViewQuery: gql`
    query supplierAuditReportListViewQuery {
      supplierAuditReports {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          body
          versionIdentifier
          currentStatusName
          supplierAuditPlanVersion {
            id
            title
            item {
              id
              customIdentifier
            }
            supplierVersion {
              id
              title
              item {
                id
                customIdentifier
              }
            }
          }
        }
      }
    }
  `,
  itemType,
  icon: AssignmentTurnedIn,
  ...docMetadata,
  EffectiveViewOptions: DocumentToolbar,
  listViewColumns: TableColumns,
};
