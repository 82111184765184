import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { CurriculumWorkflowQuery, CurriculumWorkflowQueryVariables } from './__generated__/CurriculumWorkflowQuery';

const query = gql`
  query CurriculumWorkflowQuery($itemId: ID!) {
    item(itemType: "curriculum", id: $itemId) {
      id
      customIdentifier
      workingVersion {
        id
        currentStatusName
        versionIdentifier
      }
      currentVersion {
        id
        currentStatusName
        versionIdentifier
        owner {
          id
        }
        approver {
          id
        }
        assignedOwnerJobRole {
          id
          formattedRoleName
        }
        assignedApproverJobRole {
          id
          formattedRoleName
        }
      }
      versions {
        id
        versionIdentifier
        currentStatusName
        releasedAt
      }
    }
  }
`;

export const useCurriculumWorkflowData = (itemId: string) =>
  useQuery<CurriculumWorkflowQuery, CurriculumWorkflowQueryVariables>(query, {
    variables: { itemId },
  });
