import React, { useState } from 'react';
import capitalize from 'lodash/capitalize';

import {
  CancelReason,
  CertifiedSignature,
  ChangeSummary,
  RejectReason,
  ResolveRejection,
  SummaryOfChange,
  VoidReason,
  YesOrNo,
} from 'compositions/TimelineGroup/steps/views';
import { TransitionPayload, ServerAction, Status } from '../statuses';
import formatApolloError from 'utils/formatApolloError';
import { useCurrentUser } from 'compositions/WithCurrentUser';

import AlertDialog from 'components/AlertDialog';
import UnreleasedCoursesDialog from './UnreleasedCoursesDialog';
import { Typography } from '@material-ui/core';

export enum TransitionWizard {
  Cancel,
  DraftToUnderReview,
  UnderReviewToPrepared,
  VoidPrepared,
  ReleaseOrReject,
  ResolveRejection,
  RFCDOC,
  SummaryOfChange,
  CreateVersion,
}

function Wizard(props: {
  type: TransitionWizard | null;
  createTransition: (data: TransitionPayload) => void;
  error?: Error;
  itemTypeMetadata: any;
  itemCustomIdentifier: string;
  versionIdentifier: string;
  reasonForChange: string;
  descriptionOfChange: string;
  currentVersion;
}) {
  const [transitionToPrepared, setTransitionToPrepared] = useState(false);
  const [releaseVersion, setReleaseVersion] = useState(false);
  const [rejectVersion, setRejectVersion] = useState(false);

  const { data, loading, error } = useCurrentUser();
  if (error) throw error;
  if (loading || !data || !data.currentUser) return null;

  switch (props.type) {
    case TransitionWizard.RFCDOC:
      return (
        <ChangeSummary
          handleComplete={({ reasonForChange, descriptionOfChange }) => {
            props.createTransition({
              action: ServerAction.UpdateVersion,
              payload: {
                reasonForChange,
                descriptionOfChange,
              },
            });
          }}
          currentVersion={{
            reasonForChange: props.reasonForChange,
            descriptionOfChange: props.descriptionOfChange,
          }}
          buttonText="Save"
        />
      );
    case TransitionWizard.SummaryOfChange:
      return <SummaryOfChange currentVersion={props.currentVersion} displayChangeInfo={false} />;
    case TransitionWizard.Cancel:
      return (
        <CancelReason
          error={props.error}
          currentVersion={props.currentVersion}
          itemCustomIdentifier={props.itemCustomIdentifier}
          itemTypeMetadata={{
            name: 'training_plan',
          }}
          handleComplete={(data: { signature }) => {
            props.createTransition({
              action: ServerAction.StatusTransition,
              payload: {
                toStatus: Status.Canceled,
                username: data.signature.username,
                password: data.signature.password,
                reasonForSignature: data.signature.reasonForSignature,
              },
            });
          }}
        />
      );

    case TransitionWizard.DraftToUnderReview:
      return (
        <YesOrNo
          closeOnNo
          disableYes={Boolean(props.error)}
          error={props.error && props.error.message}
          handleClose={() => props.createTransition({ action: null })}
          handleComplete={() => {
            props.createTransition({
              action: ServerAction.StatusTransition,
              payload: {
                toStatus: Status.UnderReview,
              },
            });
          }}
          noLabel="Cancel"
          prompt={`Do you want to disable editing for version ${props.currentVersion.versionIdentifier} of ${
            props.itemCustomIdentifier
          }?`}
          yesLabel="Lock"
        />
      );

    case TransitionWizard.UnderReviewToPrepared:
      if (props.currentVersion.mayTransitionToPrepared) {
        const prepareDialog = `As the ${props.currentVersion.assignedOwnerJobRole.formattedRoleName}, I approve ${
          props.itemCustomIdentifier
        }.`;
        return (
          <>
            {!transitionToPrepared && (
              <YesOrNo
                disableOnSubmit
                closeOnNo
                heading={props.itemCustomIdentifier}
                prompt={`Do you want to take ownership of version ${props.versionIdentifier} of ${
                  props.itemCustomIdentifier
                } or unlock and send to Draft status?`}
                yesLabel="Take Ownership"
                noLabel="Unlock"
                error={props.error && props.error.message}
                handleComplete={() => setTransitionToPrepared(true)}
                handleClose={() => {
                  props.createTransition({
                    action: ServerAction.StatusTransition,
                    payload: {
                      toStatus: Status.Draft,
                    },
                  });
                }}
              />
            )}
            {transitionToPrepared && (
              <CertifiedSignature
                itemTypeMetadata={{
                  name: 'training_plan',
                }}
                dialog={prepareDialog}
                error={props.error}
                errorDisplay={formatApolloError(props.error)}
                heading="Prepare Training Plan"
                handleComplete={(data: { username: string; password: string; reasonForSignature: string }) => {
                  props.createTransition({
                    action: ServerAction.StatusTransition,
                    payload: {
                      toStatus: Status.Prepared,
                      username: data.username,
                      password: data.password,
                      reasonForSignature: data.reasonForSignature,
                    },
                  });
                }}
              />
            )}
          </>
        );
      }

      const errors = props.currentVersion.mayNotTransitionToPreparedBecause,
        courses = errors.flatMap((error) =>
          error.data.map((d) => {
            const course = JSON.parse(d.value);
            return {
              id: course.id,
              customIdentifier: course.custom_identifier,
              versionIdentifier: course.current_version.version_identifier,
              currentStatus: course.current_version.current_status.name,
            };
          }),
        );

      return courses.length > 0 ? (
        <UnreleasedCoursesDialog courses={courses} onClose={() => props.createTransition({ action: null })} />
      ) : (
        <AlertDialog onClose={() => props.createTransition({ action: null })} open>
          <Typography variant="body2">{capitalize(errors.map((error) => error.message).join(', ') + '.')}</Typography>
        </AlertDialog>
      );
    case TransitionWizard.VoidPrepared:
      return (
        <VoidReason
          relevantRole={props.currentVersion.assignedOwnerJobRole.formattedRoleName}
          error={props.error}
          itemTypeMetadata={{
            name: 'training_plan',
          }}
          handleComplete={(data: { signature }) => {
            props.createTransition({
              action: ServerAction.StatusTransition,
              payload: {
                toStatus: Status.Void,
                username: data.signature.username,
                password: data.signature.password,
                reasonForSignature: data.signature.reasonForSignature,
              },
            });
          }}
        />
      );
    case TransitionWizard.ReleaseOrReject:
      const releaseDialog = `As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I approve ${
        props.itemCustomIdentifier
      } for release.`;
      return (
        <>
          {!(releaseVersion || rejectVersion) && (
            <YesOrNo
              disableOnSubmit
              closeOnNo
              heading={props.itemCustomIdentifier}
              prompt={`Do you want to release version ${props.versionIdentifier} of ${props.itemCustomIdentifier}?`}
              yesLabel="Release"
              noLabel="Reject"
              error={props.error && props.error.message}
              handleComplete={() => setReleaseVersion(true)}
              handleClose={() => setRejectVersion(true)}
            />
          )}
          {releaseVersion && (
            <CertifiedSignature
              itemTypeMetadata={{
                name: 'training_plan',
              }}
              dialog={releaseDialog}
              error={props.error}
              errorDisplay={formatApolloError(props.error)}
              heading="Release Training Plan"
              handleComplete={(data: { username: string; password: string; reasonForSignature: string }) => {
                props.createTransition({
                  action: ServerAction.StatusTransition,
                  payload: {
                    toStatus: Status.Released,
                    username: data.username,
                    password: data.password,
                    reasonForSignature: data.reasonForSignature,
                  },
                });
              }}
            />
          )}
          {rejectVersion && (
            <RejectReason
              itemTypeMetadata={{
                name: 'training_plan',
              }}
              heading={`As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I reject ${
                props.itemCustomIdentifier
              }.`}
              title="Rejection"
              handleComplete={(data: { reason: string; signature }) => {
                props.createTransition({
                  action: ServerAction.StatusTransition,
                  payload: {
                    toStatus: Status.Rejected,
                    username: data.signature.username,
                    password: data.signature.password,
                    reasonForSignature: `As the responsible manager, I reject ${props.itemCustomIdentifier}. ${
                      data.reason
                    }`,
                    reasonForRejection: data.reason,
                  },
                });
              }}
            />
          )}
        </>
      );
    case TransitionWizard.ResolveRejection:
      return (
        <ResolveRejection
          itemTypeMetadata={{
            name: 'training_plan',
          }}
          itemCustomIdentifier={props.itemCustomIdentifier}
          currentVersion={props.currentVersion}
          handleComplete={() =>
            props.createTransition({
              action: ServerAction.StatusTransition,
              payload: {
                toStatus: Status.Draft,
              },
            })
          }
        />
      );
    case TransitionWizard.CreateVersion:
      return (
        <YesOrNo
          disableOnSubmit
          heading={props.itemCustomIdentifier}
          prompt={`Do you want to create a new version of ${props.itemCustomIdentifier}?`}
          closeOnNo
          yesLabel="Yes"
          noLabel="No"
          error={props.error && props.error.message}
          handleComplete={() => {
            props.createTransition({ action: ServerAction.CreateVersion });
          }}
          handleClose={() => props.createTransition({ action: null })}
        />
      );
    default:
      return null;
  }
}

export default Wizard;
