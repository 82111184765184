import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    content: {
      whiteSpace: 'pre-line',
    },
    helpDeskDisclaimer: {
      display: 'flex',
      alignItems: 'center',
      '& > span': {
        marginLeft: 4,
      },
    },
  }),
);
