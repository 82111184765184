import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { Trans } from '@lingui/macro';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import { DateTime } from 'luxon';

import { ContentSection, Spacer, WorkflowIcon } from 'components';

interface StatusSectionProps {
  classes;
  versionIdentifier: string;
  currentStatusName: string;
  releasedAt: Date;
  itemType: string;
  onChangeInfoClick?: (e) => void;
  onTeamMembersClick?: (e) => void;
}
function StatusSection({
  classes,
  currentStatusName,
  onChangeInfoClick,
  onTeamMembersClick,
  releasedAt,
  versionIdentifier,
  itemType,
}: StatusSectionProps) {
  const coAndRecordItemTypes = [
    'changeOrder',
    'finalDeviceInspectionRecord',
    'formRecord',
    'incomingQualityInspectionRecord',
    'inProcessInspectionRecord',
    'trainingRecord',
  ];

  return (
    <Grid item xs={2} className={classes.gridGutterRight}>
      <ContentSection
        LabelText={
          <>
            <MoreHoriz className={classes.icon} />
            <Trans>Status</Trans>
          </>
        }
      >
        <Spacer factor={1} />
        <div style={{ display: 'flex' }}>
          <div style={{ paddingRight: '12px' }}>
            <WorkflowIcon type={camelCase(currentStatusName)} completed size={'large'} />
          </div>
          <div style={{ flex: 1 }}>
            <Typography variant="body2" className={classes.bold}>
              {`${versionIdentifier} ${startCase(currentStatusName)}`}
            </Typography>

            <Typography variant="body2">Effective: {DateTime.fromISO(releasedAt).toFormat('MMM. d yyyy')}</Typography>

            {!coAndRecordItemTypes.includes(itemType) && onChangeInfoClick && (
              <Typography variant="body2" className={classes.linkColor} onClick={onChangeInfoClick}>
                <Trans>View Change Information</Trans>
              </Typography>
            )}

            <Typography variant="body2" className={classes.linkColor} onClick={onTeamMembersClick}>
              <Trans>View Team Members</Trans>
            </Typography>
          </div>
        </div>
      </ContentSection>
    </Grid>
  );
}

export default StatusSection;
