import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './factory.svg';

function Factory(props) {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <Icon {...svgProps} />} />;
}

export default Factory;
