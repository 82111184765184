import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  switcher: {
    '-webkit-appearance': 'none',
    cursor: 'pointer',
    display: 'inline-block',
    width: 30,
    height: 12,
    borderRadius: 30,
    outline: 0,
    marginTop: theme.spacing(0.5),
    '&::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      backgroundColor: 'white',
      width: 10,
      height: 10,
      borderRadius: '50%',
      transition: '.3s ease-in-out',
    },
    '&::-webkit-slider-runnable-track': {
      width: 28,
      height: 10,
      // background: theme.palette.primary.main,
      borderRadius: 30,
      paddingRight: theme.spacing(0.15),
      paddingLeft: theme.spacing(0.15),
    },
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed',
    },
  },
});

function InputRange({ disabled = false, value, onChange, min, max, classes, className = '' }) {
  return (
    <input
      className={`${className} ${classes.switcher}`}
      type="range"
      min={min}
      max={max}
      step="1"
      value={value}
      disabled={disabled}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onChange={onChange}
    />
  );
}

export default withStyles(styles)(InputRange);
