import gql from 'graphql-tag';

const itemTypesQuery = gql`
  query ItemTypeTraces {
    itemTypes {
      discipline
      product
      id
      name
      traceableRiskUpstreamTypes
      traceableRiskDownstreamTypes
      traceableDesignUpstreamTypes
      traceableDesignDownstreamTypes
      displaySlug
      workflow {
        id
        name
      }
      itemsCount
    }
  }
`;

export { itemTypesQuery };
