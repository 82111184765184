import React from 'react';

import { Dialog, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import { get } from 'lodash';
import { DateTime } from 'luxon';
import snakeCase from 'lodash/snakeCase';

import Signature from 'assets/icons/Signature';
import UserAvatar from 'components/UserAvatar';
import { REASONS_FOR_SIGNATURE } from 'compositions/TimelineGroup/components/SupplierWorkflow';

import { useStyles } from './styles';

interface StatusSignatureDialogProps {
  Icon?: any;
  open: boolean;
  version: any;
  status: any;
  onClose: () => void;
}

const StatusSignatureDialog = (props: StatusSignatureDialogProps) => {
  const { open, onClose, status, version } = props;
  const classes = useStyles();

  const userId = get(version, 'currentStatus.user.id', '');
  const userName = get(version, 'currentStatus.user.fullName', '');
  const userTitle = get(version, 'currentStatus.user.title', '');
  const date = get(version, 'currentStatus.createdAt', '');
  const reasonForSignature = get(REASONS_FOR_SIGNATURE, `${snakeCase(status)}`, null);

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: `${classes.dialog}` }} keepMounted closeAfterTransition>
      <div className={classes.closeContainer}>
        <button className={classes.close}>
          <Close onClick={props.onClose} />
        </button>
      </div>
      <div className={classes.header}>
        <Signature fontSize="small" />
        <Typography variant={'overline'}>VERSION SIGNATURE</Typography>
      </div>
      <section className={classes.contentContainer}>
        <div className={classes.status}>
          {props.Icon}
          <Typography variant={'subtitle1'}>{status}</Typography>
        </div>
        <div className={classes.user}>
          <UserAvatar userId={userId} diameter={30} />
          <div className={classes.userInfo}>
            <Typography variant="body2">{userName}</Typography>
            <Typography variant="subtitle2">{userTitle}</Typography>
          </div>
        </div>
        <div className={classes.date} >
          <Typography variant="body2">{DateTime.fromISO(date).toFormat('MMM. d, yyyy')}</Typography>
        </div>
        <div className={classes.time}>
          <Typography variant="body2">{ DateTime.fromISO(date).toFormat('hh:mm:ss a (ZZZZ)')}</Typography>
        </div>
      </section>
      {reasonForSignature && <section className={classes.reasonForSignature} >
        <Typography variant="subtitle1">Reason For Signature:</Typography>
        <Typography variant="body2">{reasonForSignature}</Typography>
      </section>}
    </Dialog>
  );
};

export default StatusSignatureDialog;
