import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { AccountCircle, FileCopy, School } from '@material-ui/icons';

import _ from 'lodash';

import { LicenseTier } from '__generated__/globalTypes';
import SectionLabel from 'components/core/SectionLabel';
import Panel from 'components/core/Panel';
import LicenseLockedSection from 'components/LicenseLockedSection';
import Spacer from 'components/Spacer';

import ImpactedEmployees from './ImpactedEmployees';
import ItemsWithCourse from './ItemsWithCourse';
import NoCourseItems from './NoCourseItems';
import {
  ChangeOrderBuilderViewQuery_changeOrder_currentVersion_impactedEmployees as ImpactedEmployeesType,
  ChangeOrderBuilderViewQuery_changeOrder_currentVersion_trainingImpactAssessments as TrainingImpactAssessmentsType,
} from '../../__generated__/ChangeOrderBuilderViewQuery';

export interface ImpactedItem extends TrainingImpactAssessmentsType {}

export interface ImpactedEmployee extends ImpactedEmployeesType {}

interface Props {
  assessments: ImpactedItem[];
  impactedEmployees: ImpactedEmployee[];
  impactedEmployeesTrainingProgress: number | null;
  displayOnly: boolean;
}

function TrainingImpact(props: Props) {
  const [expanded, setExpanded] = useState(true);
  const withCourse = (assessment) => assessment.trainingImpactAssociatedCourses.length;

  return (
    <>
      <SectionLabel icon={FileCopy}>
        Training Impact
        <SectionLabel.Collapse expanded={expanded} setExpanded={setExpanded} />
      </SectionLabel>
      {expanded && (
        <LicenseLockedSection requiredTier={LicenseTier.CORE_QS}>
          <Panel>
            <Panel.Heading icon={School}>Associated Training Items</Panel.Heading>
            <ItemsWithCourse
              displayOnly={props.displayOnly}
              courses={props.assessments.flatMap((assessment: ImpactedItem) => {
                return assessment.trainingImpactAssociatedCourses.map((course, index) => ({
                  ...course,
                  parent: assessment,
                  firstItem: index === 0,
                }));
              })}
            />
            <Spacer factor={1} />
            <NoCourseItems displayOnly={props.displayOnly} rows={props.assessments.filter((x) => !withCourse(x))} />
          </Panel>
          <Spacer factor={4.125} />
          <Panel>
            <Panel.Heading icon={AccountCircle}>Impacted Users</Panel.Heading>
            <>
              <Typography variant="subtitle1" display="inline">
                Training Summary:
              </Typography>
              <Typography variant="body2" component="span">
                {' '}
                {_.isNumber(props.impactedEmployeesTrainingProgress)
                  ? `${props.impactedEmployeesTrainingProgress}% of employees have completed associated training.`
                  : 'n/a'}
              </Typography>
            </>

            {props.impactedEmployees.length !== 0 && <ImpactedEmployees rows={props.impactedEmployees} />}
          </Panel>
        </LicenseLockedSection>
      )}
    </>
  );
}

export default TrainingImpact;
