import React from 'react';

import { MenuItem, Select } from '@material-ui/core';

export const REQUIREMENTS_OPTION = 'Requirements';
export const USER_NEEDS_OPTION = 'UserNeeds';
export const DEVICE_VERIFICATION_PROTOCOLS_OPTION = 'DeviceVerficationProtocols';
export const DEVICE_VALIDATION_PROTOCOLS_OPTION = 'DeviceValidationProtocols';
export const RISK_OPTION = 'Risk';
export const RISK_CONTROL_OPTION = 'RiskControl';

function RootSelect(props) {
  return (
    <Select onChange={props.onChange} value={props.value} disableUnderline>
      {props.isRiskTrace && (
        <MenuItem value={RISK_OPTION} key={RISK_OPTION}>
          Risk
        </MenuItem>
      )}
      {props.isRiskTrace && (
        <MenuItem value={RISK_CONTROL_OPTION} key={RISK_CONTROL_OPTION}>
          Risk Control
        </MenuItem>
      )}
      <MenuItem value={REQUIREMENTS_OPTION} key={REQUIREMENTS_OPTION}>
        Requirements
      </MenuItem>
      {!props.isRiskTrace && (
        <MenuItem value={USER_NEEDS_OPTION} key={USER_NEEDS_OPTION}>
          User Needs
        </MenuItem>
      )}
      <MenuItem value={DEVICE_VERIFICATION_PROTOCOLS_OPTION} key={DEVICE_VERIFICATION_PROTOCOLS_OPTION}>
        Device Verification Protocols
      </MenuItem>
      <MenuItem value={DEVICE_VALIDATION_PROTOCOLS_OPTION} key={DEVICE_VALIDATION_PROTOCOLS_OPTION}>
        Device Validation Protocols
      </MenuItem>
    </Select>
  );
}

export default RootSelect;
