import gql from 'graphql-tag';

export const removeTaskReferenceFromItemVersionMutation = gql`
  mutation RemoveTaskReferenceFromItemVersion($versionId: ID!, $taskId: ID!) {
    removeTaskReferencedVersion(versionId: $versionId, taskId: $taskId) {
      version {
        id
      }
    }
  }
`;

export const addTaskReferenceToItemVersionMutation = gql`
  mutation AddTaskReferenceToItemVersion($versionId: ID!, $referencedTaskIds: [ID!]!) {
    addTaskReferencedVersion(versionId: $versionId, referencedTaskIds: $referencedTaskIds) {
      version {
        id
      }
    }
  }
`;

export const esignatureRequiredMutation = gql`
  mutation updateEsignatureRequiredCompletedMutation($input: UpdateEsignatureRequiredCompletedInput!) {
    updateEsignatureRequiredCompleted(input: $input) {
      clientMutationId
      esignatureRequired {
        id
      }
    }
  }
`;

export const mentionMutation = gql`
  mutation updateMentionCompletedMutation($input: UpdateMentionCompletedInput!) {
    updateMentionCompleted(input: $input) {
      clientMutationId
      mention {
        id
      }
    }
  }
`;

export const taskMutation = gql`
  mutation updateTaskCompletedMutation($input: UpdateTaskCompletedInput!) {
    updateTaskCompleted(input: $input) {
      clientMutationId
      task {
        id
      }
    }
  }
`;

export const trainingMutation = gql`
  mutation updateTrainingCompletedMutation($input: UpdateTrainingCompletedInput!) {
    updateTrainingCompleted(input: $input) {
      clientMutationId
      training {
        id
      }
    }
  }
`;
