import React from 'react';
import get from 'lodash/get';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { Avatar, withStyles, Tooltip, Typography } from '@material-ui/core';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { compose } from 'recompose';

import WithCurrentUser from 'compositions/WithCurrentUser';

const query = gql`
  query userQuery($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      profile {
        id
        photoUrl
      }
    }
  }
`;

const styles = (theme) => ({
  container: {
    borderRadius: '50%',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: theme.palette.primary.main,
  },
  inverted: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
  },
  invertedText: {
    color: theme.palette.primary.contrastText,
  },
  hideTooltip: {
    display: 'none',
  },
  tooltip: {
    ...theme.typography.body2,
  },
});

function UserAvatar({
  id,
  userId,
  classes,
  diameter = 30,
  fontVariant = 'body2',
  fontSize = null,
  invertColor,
  onClick,
  displayTooltip = false,
  i18n,
}) {
  const computedFontSize = fontSize !== null ? fontSize : `${(diameter / 30) * 0.875}rem`;

  if (!userId) return null;

  return (
    <Query query={query} skip={userId === undefined || userId === '0'} variables={{ userId }}>
      {({ data, loading, error }) => {
        if (error) {
          throw new Error(`Error fetching avatar data: ${error}`);
        }
        if (loading) return null;

        const userData = get(data, 'user');
        const avatarImg = get(data, 'user.profile.photoUrl');
        const fullName = `${get(userData, 'firstName')} ${get(userData, 'lastName')}`;
        const firstInitial = get(userData, 'firstName.0');
        const lastInitial = get(userData, 'lastName.0');

        const avatar = () => {
          if (!avatarImg) return null;

          return (
            <Avatar
              alt={i18n._(t`User Avatar`)}
              style={{ height: diameter, minWidth: diameter, maxWidth: diameter }}
              src={avatarImg}
            />
          );
        };

        const initials = () => {
          if (!firstInitial && !lastInitial) return null;

          return (
            <Typography
              variant={fontVariant}
              style={{ fontSize: computedFontSize }}
              className={invertColor ? classes.invertedText : classes.text}
            >
              {`${firstInitial}${lastInitial}`}
            </Typography>
          );
        };

        return (
          <Tooltip
            title={fullName}
            classes={{
              tooltip: `${!displayTooltip && classes.hideTooltip} ${classes.tooltip}`,
            }}
          >
            <div
              id={id}
              className={`${classes.container} ${invertColor ? classes.inverted : ''}`}
              style={{ height: diameter, minWidth: diameter }}
              onClick={onClick}
            >
              {avatar() || initials()}
            </div>
          </Tooltip>
        );
      }}
    </Query>
  );
}

const UserAvatarWithStyles = compose(
  withI18n(),
  withStyles(styles),
)(UserAvatar);

const CurrentUserAvatarNoStyle = ({ currentUser: { id }, ...props }) => {
  return <UserAvatarWithStyles {...props} userId={id} />;
};

export const CurrentUserAvatar = WithCurrentUser(CurrentUserAvatarNoStyle);
export default UserAvatarWithStyles;
