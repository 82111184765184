export class Step {
  itemId: number;
  memberId: number;
  tags: string[];
  versionId: number;
  description: string;
  title: string;
  acceptanceCriteria: string;

  stepId: string;
  globalId: string;
  raw: any;

  constructor(data: any, itemNumber: number) {
    this.itemId = data.item.id;
    this.memberId = data.member.id;
    this.tags = data.item.tags;
    this.versionId = data.id;
    this.description = data.stepDescription;
    this.title = data.title;
    this.acceptanceCriteria = data.acceptanceCriteria;

    this.stepId = `VERTS-${itemNumber}.${data.member.position + 1}`;
    this.globalId = data.item.itemNumber;
    this.raw = data;
  }
}
