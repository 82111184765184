import React from 'react';

import { GenericWithMembersWorkflowStatuses } from 'workflows/statuses';

import { RejectReason } from '../views';

const SignatureStatement = ({ id }) => `As the Approver of ${id}, I Void my signature.`;

export default {
  initialStep: 'voidReason',
  internalSteps: {
    voidReason: {
      component: (props) => {
        const heading = <SignatureStatement id={props.itemCustomIdentifier} />;
        return <RejectReason {...props} title="Void" heading={heading} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: (props) => {
    return !props.voidReason
      ? null
      : {
          ...props.voidReason.signature,
          reasonForSignature: props.voidReason.reason,
          toStatus: GenericWithMembersWorkflowStatuses.Void.id,
        };
  },
};
