import gql from 'graphql-tag';

import { useQuery } from 'react-apollo';
import { TRIO_FIELDS } from 'components/TableTrio/gql';

import { docStylePayloadFields } from 'itemTypes/mixins/DocumentBased/gql';

const versionQuery = gql`
  query CybersecurityReportVersionQuery($id: ID!) {
    cybersecurityReportVersion(id: $id) {      
      ${docStylePayloadFields}
      ${TRIO_FIELDS}
      cybersecurityPlanVersion {
        id
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export const CYBERSECUIRTY_PLAN_OPTIONS_QUERY = gql`
  query CybersecurityPlanOptionsQuery {
    cybersecurityPlans {
      id
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        inputMethod
        body
        versionIdentifier
        currentStatusName
      }
    }
  }
`;

export const SET_CYBERSECURITY_PLAN_ID = gql`
  mutation SetCybersecurityPlanId($id: ID!, $cybersecurityPlanId: ID) {
    updateCybersecurityReport(id: $id, cybersecurityPlanVersionId: $cybersecurityPlanId) {
      cybersecurityReport {
        id
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery(versionQuery, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}
