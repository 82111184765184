import React from 'react';
import Link from '@material-ui/icons/Link';
import { IconButton, withStyles } from '@material-ui/core';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import flowRight from 'lodash/flowRight';

import styles from './styles';
import MemberTracing from '../MemberTracing';

class TracingControl extends React.Component {
  buttonRef = undefined;

  render() {
    const { classes, onClick, onClose, i18n, showPopup, versionId, parentSetItemSlug, itemId, disabled } = this.props;
    return (
      <>
        <IconButton
          aria-label={i18n._(t`Open Tracing`)}
          disableRipple
          buttonRef={(n) => (this.buttonRef = n)}
          className={classes.iconButton}
          onClick={onClick}
          disabled={disabled}
        >
          <Link classes={{ root: classes.smallIcon }} />
        </IconButton>

        <MemberTracing
          open={showPopup}
          anchorEl={this.buttonRef}
          onClose={onClose}
          itemId={itemId}
          parentSetItemSlug={parentSetItemSlug}
          versionId={versionId}
        />
      </>
    );
  }
}

export default flowRight([withStyles(styles), withI18n()])(TracingControl);
