import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_: any) =>
  createStyles({
    toggleText: {
      marginLeft: 0,
    },
    negativeLabel: {
      color: '#F65955',
      marginRight: 3,
      textTransform: 'uppercase'
    },
    positiveLabel: {
      color: '#0B915C',
      marginLeft: 3,
      textTransform: 'uppercase'
    },
  }),
);
