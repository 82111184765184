import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import { CourseVersionQuery, CourseVersionQueryVariables } from './__generated__/CourseVersionQuery';

const versionQuery = gql`
  query CourseVersionQuery($id: ID!) {
    courseVersion(id: $id) {
      id
      title
      item {
        id
        customIdentifier
      }
      assessmentGrader {
        id
        fullName
      }
      assessmentMaterialNotes
      attachment {
        id
        description
        editableDocument {
          url
          name
        }
        file {
          url
          name
        }
        filename
        url
      }
      description
      effectivenessMeasurementRequired
      hasQualityDocuments
      instructor {
        id
        fullName
      }
      justification
      learningObjective
      locked
      qualityDocumentVersions {
        id
        title
        currentStatusName
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      trainingFormat
      trainingMaterialAttachments {
        id
        description
        file {
          url
        }
        editableDocument {
          url
        }
      }
      trainingMaterialLinks
      trainingType
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<CourseVersionQuery, CourseVersionQueryVariables>(versionQuery, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

export const updateVersion = gql`
  mutation updateCourseVersion(
    $id: ID!
    $description: String
    $effectivenessMeasurementRequired: Boolean
    $hasQualityDocuments: Boolean
    $instructorId: ID
    $justification: String
    $learningObjective: String
    $trainingFormat: TrainingFormat
    $trainingMaterialLinks: [String!]
    $trainingType: TrainingType
    $assessmentMaterialNotes: String
    $assessmentGraderId: ID
  ) {
    updateCourseVersion(
      id: $id
      description: $description
      effectivenessMeasurementRequired: $effectivenessMeasurementRequired
      hasQualityDocuments: $hasQualityDocuments
      instructorId: $instructorId
      justification: $justification
      learningObjective: $learningObjective
      trainingFormat: $trainingFormat
      trainingMaterialLinks: $trainingMaterialLinks
      trainingType: $trainingType
      assessmentMaterialNotes: $assessmentMaterialNotes
      assessmentGraderId: $assessmentGraderId
    ) {
      courseVersion {
        id
        description
        justification
        learningObjective
        assessmentMaterialNotes
        instructor {
          id
          fullName
        }
        assessmentGrader {
          id
          fullName
        }
        qualityDocumentVersions {
          id
        }
        trainingFormat
        trainingType
        trainingMaterialAttachments {
          id
          file {
            url
          }
          description
        }
      }
    }
  }
`;

export function useUpdateVersion() {
  return useMutation(updateVersion, {
    refetchQueries: ['CourseVersionQuery'],
  });
}
