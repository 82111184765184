export interface SortedItemTypes {
  disciplineName: string;
  itemTypes: string[];
}

export const SORTED_ITEM_TYPES_BY_DISCIPLINES: SortedItemTypes[] = [
  {
    disciplineName: 'document_control',
    itemTypes: [
      'change_order',
      'standard_operating_procedure',
      'work_instruction',
      'template',
      'form',
      'form_record',
      'miscellaneous_document',
      'miscellaneous_record',
    ],
  },
  {
    disciplineName: 'quality_management',
    itemTypes: ['review', 'capa', 'complaint'],
  },
  {
    disciplineName: 'supplier_management',
    itemTypes: [
      'supplier',
      'supplier_questionnaire',
      'supplier_questionnaire_record',
      'supplier_audit_plan',
      'supplier_audit_report',
      'quality_agreement',
    ],
  },
  {
    disciplineName: 'training',
    itemTypes: ['job_description', 'course', 'training_plan', 'training_record'],
  },
];
