import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    '&:hover': { cursor: 'pointer' },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    width: 640,
  },
  disabledText: {
    display: 'flex',
    height: 40,
  },
  dropdownIcon: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(-0.5),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  itemTypeText: {
    marginLeft: theme.spacing(0.5),
  },
  menuOption: {
    ...theme.typography.body2,
    borderTop: `2px solid ${theme.palette.common.input.outline}`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      background: theme.palette.common.input.hover,
    },
  },
  selectMenu: {
    ...theme.typography.body2,
    width: '100%',
    height: 40,
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: 'center',
  },
  separator: {
    color: theme.palette.subHeading,
    margin: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  textFieldInput: {
    padding: theme.spacing(1),
    border: '2px solid #ebeced',
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.common.input.background,
  },
}));
