import gql from 'graphql-tag';

const genericWithMembersWorkflowBody = `
  descriptionOfChange
  linkedChangeCustomIdentifier
  linkedChangeId
  linkedChangeJustificationText
  linkedChangeTitle
  mayTransitionToCanceled
  mayTransitionToDraft
  mayTransitionToUnderReview
  mayTransitionToOwnerApproval
  mayTransitionToApprovedDraft
  mayTransitionToReleased
  mayTransitionToRejected
  mayTransitionToRetirementInitiated
  mayTransitionToRetired
  mayTransitionToObsolete
  mayTransitionToVoid
  reasonForChange
`;

const query = gql`
  query BuilderViewQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      createdAt
      referenceId
      tags
      itemType {
        id
        name
      }
      currentUser {
        id
        username
        readOnly
      }
      workingVersion {
        id
      }
      versions {
        id
        releasedAt
        retiredAt
        versionIdentifier
        releasedAt
        currentStatusName
        statuses {
          id
          name
        }
        ... on GenericWorkflowInterface {
          reasonForChange
          descriptionOfChange
          linkedChangeId
          linkedChangeCustomIdentifier
          linkedChangeJustificationText
          linkedChangeTitle
        }
        ... on GenericWithMembersWorkflowInterface {
          ${genericWithMembersWorkflowBody}
        }
        ... on DocumentVersion {
          body
          pdfDataUpload
          inputMethod
          attachment {
            id
            filename
          }
        }
        ... on TrainingRecordVersion {
          id
          source {
            id
            ... on CourseVersion {
              effectivenessMeasurementRequired
              attachment {
                id
              }
            }
          }
          employee {
            id
            user {
              id
            }
          }
        }
      }
      currentRelease {
        id
        mayTransitionToObsolete
      }
      currentVersion {
        unresolvedSuggestions
        owner {
          id
          fullName
          title
          status
        }
        approver {
          id
          fullName
          title
          status
        }
        ... on SupplierVersion {
          partsAccessToEdit
        }
        ... on TaskVersion {
          locked
        }
        ... on RiskVersion {
          setMember {
            id
            setItemVersion {
              id
              item {
                id
                customIdentifier
              }
            }
          }
        }
        item {
          id
          itemType {
            id
            name
            workflow {
              id
              name
            }
          }
        }
        mayTransitionToCanceled
        mayTransitionToRetired
        assignedOwnerJobRole {
          id
          formattedRoleName
          usersWithRole {
            id
            fullName
          }
        }
        assignedApproverJobRole {
          id
          formattedRoleName
          usersWithRole {
            id
            fullName
          }
        }
        createdAt
        creator {
          id
          fullName
          title
        }
        signingApprover {
          id
          fullName
          title
          jobRoles {
            id
            formattedRoleName
          }
        }
        signingOwner {
          id
          fullName
          title
          jobRoles {
            id
            formattedRoleName
          }
        }
        signingRejector {
          id
          fullName
          title
          jobRoles {
            id
            formattedRoleName
          }
        }
        id
        locked
        permissibleApprovers {
          id
          fullName
          title
          jobRoles {
            id
            formattedRoleName
          }
        }
        permissibleOwners {
          id
          fullName
          title
          jobRoles {
            id
            formattedRoleName
          }
        }
        title
        versionIdentifier
        currentStatusName
        currentStatus {
          id
          createdAt
          previousTransition {
            id
            esignature {
              id
              reasonForRejection
            }
          }
        }
        changeOrderVersions {
          id
          item {
            id
            customIdentifier
          }
        }
        ... on VerificationAndValidationReportVersion {
          id
          mayTransitionToOwnerApproval
          item {
            id
            itemType {
              id
              name
            }
          }
          associatedVvPlan {
            version {
              id
              title
              versionIdentifier
              currentStatus {
                id
                name
              }
              item {
                id
                customIdentifier
                currentVersion {
                  id
                  versionIdentifier
                }
              }
              validationProtocols {
                id
                item {
                  id
                  customIdentifier
                  itemType {
                    id
                    displaySlug
                    userCreatable
                  }
                  currentVersion {
                    id
                    title
                    versionIdentifier
                    currentStatus {
                      id
                      name
                    }
                  }
                }
              }
              verificationProtocols {
                id
                item {
                  id
                  customIdentifier
                  itemType {
                    id
                    displaySlug
                  }
                  currentVersion {
                    id
                    title
                    versionIdentifier
                    currentStatus {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          validationRecords {
            id
            versionIdentifier
            isFulfillAllAcceptanceCriteria
            currentStatus {
              id
              name
            }
            deviceValidationVersion {
              id
            }
            deviationVersions {
              id
              deviationId
              versionIdentifier
              currentStatus {
                id
                name
              }
              item {
                id
                customIdentifier
                currentVersion {
                  id
                  title
                }
              }
              parentRecord {
                id
                versionIdentifier
                currentStatus {
                  id
                  name
                }
                item {
                  id
                  customIdentifier
                }
              }
              parentRecordValidationProtocol {
                id
                versionIdentifier
                currentStatus {
                  id
                  name
                }
                item {
                  id
                  customIdentifier
                }
              }
            }
            item {
              id
              customIdentifier
              itemType {
                id
                displaySlug
                userCreatable
              }
            }
          }
          verificationRecords {
            id
            versionIdentifier
            isFulfillAllAcceptanceCriteria
            currentStatus {
              id
              name
            }
            deviceVerificationVersion {
              id
            }
            deviationVersions {
              id
              deviationId
              versionIdentifier
              currentStatus {
                id
                name
              }
              item {
                id
                customIdentifier
                currentVersion {
                  id
                  title
                }
              }
              parentRecord {
                id
                versionIdentifier
                currentStatus {
                  id
                  name
                }
                item {
                  id
                  customIdentifier
                }
              }
              parentRecordVerificationProtocol {
                id
                versionIdentifier
                currentStatus {
                  id
                  name
                }
                item {
                  id
                  customIdentifier
                }
              }
            }
            item {
              id
              customIdentifier
              itemType {
                id
                displaySlug
                userCreatable
              }
            }
          }
        }
        ... on DeviceMasterRecordVersion {
          groups {
            id
            name
            info
            optional
            applicable
            subgroups {
              id
              name
              subgroupVersions {
                id
                version {
                  id
                  title
                  item {
                    id
                    customIdentifier
                    itemType {
                      id
                      displaySlug
                      userCreatable
                    }
                    currentVersion {
                      id
                      versionIdentifier
                      currentStatusName
                    }
                  }
                }
              }
            }
            headerOptions {
              name
            }
          }
        }
        ... on GenericWorkflowInterface {
          linkedChangeId
          reasonForChange
          descriptionOfChange
          linkedChangeJustificationText
          linkedChangeTitle
          linkedChangeCustomIdentifier
          lastOwnerApproval {
            id
            createdAt
            user {
              id
              firstName
              lastName
            }
          }
        }
        ... on GenericWithMembersWorkflowInterface {
          ${genericWithMembersWorkflowBody}
        }
        ... on DesignAndDevelopmentPlanVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on IndicationsForUseVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on DesignAndDevelopmentReportVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on DeviceDescriptionVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on QualityManualVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on CommonWeaknessAssessmentVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on CyberattackAnalysisVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on OrganizationalChartVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on JobDescriptionVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on CybersecurityPlanVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on CybersecurityReportVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on CybersecuritySystemCharacterizationVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on SoftwareBillOfMaterialVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on SoftwareDevelopmentPlanVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on SoftwareSafetyClassificationVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on RiskManagementPlanVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on RegulatoryStrategyPlanVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on DeviceMasterRecordVersion {
          description
          body
          inputMethod
        }
        ... on RiskManagementReportVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on ReviewVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on QualityPolicyVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on DeviceValidationVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on DeviceVerificationVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on DocumentVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on FailureModesAndEffectsAnalysisVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on ComplaintVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on LabelingVersion {
          body
          summary
          pdfDataUpload
          inputMethod
        }
        ... on SupplierQuestionnaireVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on SupplierAuditPlanVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on SupplierAuditReportVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on QualityAgreementVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on DeviceUsageDescriptionVersion {
          inputMethod
        }
        ... on PartVersion {
          inputMethod
        }
        ... on QualityByDesignPlanVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on PrimaryModeOfActionVersion {
          body
          pdfDataUpload
          inputMethod
        }
        ... on TaskVersion {
          description
          dueDate
          assignedPerson {
            id
            fullName
          }
        }
        ... on TrainingRecordVersion {
          source {
            id
            ... on CourseVersion {
              attachment {
                id
              }
            }
          }
          employee {
            id
            user {
              id
            }
          }
        }
      }
      itemType {
        id
        workflow {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_DOCUMENT_VERSION_INPUT_METHOD = gql`
  mutation Toggle($versionId: ID!, $inputMethod: InputMethod!) {
    updateDocumentVersionInputMethod(versionId: $versionId, inputMethod: $inputMethod) {
      inputMethod
    }
  }
`;

export { query, UPDATE_DOCUMENT_VERSION_INPUT_METHOD };
