import React, { useContext, useEffect, useState } from 'react';

import { CardTravel, Filter1, FormatAlignJustify, ListAlt } from '@material-ui/icons';
import { Grid, createStyles, makeStyles } from '@material-ui/core';

import { get } from 'lodash';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { DocumentBuilder } from 'compositions';
import { LockedContext, withLockedContext } from 'contexts/lockedContext';
import { PDFData, Tab, TabContainer, TabIcon } from 'components';

import ProtocolStepMethods from './Tabs/ProtocolStepMethods';
import TestOverview from './Tabs/TestOverview';
import Prerequisites from './Tabs/Prerequisites';
import TestResources from './Tabs/TestResources';
import TestSetup from './Tabs/TestSetup';
import { useVersion } from './gql';

import { InputMethod } from '__generated__/globalTypes';

const useStyles = makeStyles((theme) =>
  createStyles({
    ...theme.mixins.gridLayout,
  }),
);

function PayloadFields({ versionId, ...props }) {
  const { data, loading, error, refetch } = useVersion(versionId);
  const { setLocked } = useContext(LockedContext);
  const layoutClasses = useStyles();
  const inputMethod = get(data, 'deviceVerificationVersion.inputMethod');

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching VER: ${err}`);
    });
  }, [inputMethod, refetch]);

  useEffect(() => {
    if (data) setLocked(data.deviceVerificationVersion.locked);
  }, [data, setLocked]);

  const testOverview = 'Test Overview';
  const testResources = 'Test Resources';
  const testSetup = 'Test Setup';
  const prerequisites = 'Prerequisites';
  const protocolSteps = 'Protocol Steps';

  const [currentTab, setCurrentTab] = useState(testOverview);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Design Verification version ${props.versionId}\n${error}`);

  const versionData = data.deviceVerificationVersion;

  if (inputMethod === 'BODY')
    return (
      <DocumentBuilder
        itemType="deviceVerification"
        refetchQueries={['DeviceVerificationVersionQuery', 'timelineQuery']}
        richTextOptionSet="large"
        layoutClasses={props.layoutClasses}
        versionData={versionData}
      />
    );

  const tabs = {};

  tabs[testOverview] = (
    <TestOverview
      devicesTested={versionData.devicesTested}
      purpose={versionData.purpose}
      scope={versionData.scope}
      testBackground={versionData.testBackground}
      testSubjects={versionData.testSubjects}
      versionId={versionData.id}
      richTextToggled={versionData.testOverviewToggle}
      testOverviewSummary={versionData.testOverviewSummary}
      rolesAndResponsibilities={versionData.rolesAndResponsibilities}
    />
  );
  tabs[testResources] = (
    <TestResources
      materialsAndEquipment={versionData.materialsAndEquipment}
      references={versionData.references}
      attachments={versionData.referencedAttachments}
      versionId={versionData.id}
      richTextToggled={versionData.testResourcesToggle}
      testResourcesSummary={versionData.testResourcesSummary}
    />
  );
  tabs[testSetup] = (
    <TestSetup
      activeWorkspaceView={props.activeWorkspaceView.id}
      steps={versionData.testSetupSteps}
      versionId={versionData.id}
    />
  );
  tabs[prerequisites] = (
    <Prerequisites
      prerequisiteApplicable={versionData.prerequisiteApplicable}
      prerequisiteJustification={versionData.prerequisiteJustification}
      prerequisiteSteps={versionData.prerequisiteSteps}
      versionId={versionData.id}
    />
  );
  tabs[protocolSteps] = <ProtocolStepMethods itemNumber={versionData.item.itemNumber} versionData={versionData} />;

  return (
    <Grid item xs={8}>
      {versionData.inputMethod !== InputMethod.UPLOAD ? (
        <>
          <TabContainer
            layoutClasses={layoutClasses}
            tabs={
              <>
                <Tab
                  icon={<TabIcon Icon={FormatAlignJustify} />}
                  active={testOverview === currentTab}
                  onClick={() => setCurrentTab(testOverview)}
                  text={testOverview}
                />
                <Tab
                  icon={<TabIcon Icon={CardTravel} />}
                  active={testResources === currentTab}
                  onClick={() => setCurrentTab(testResources)}
                  text={testResources}
                />
                <Tab
                  icon={<TabIcon Icon={Filter1} />}
                  active={testSetup === currentTab}
                  onClick={() => setCurrentTab(testSetup)}
                  text={testSetup}
                />
                <Tab
                  icon={<TabIcon Icon={Filter1} />}
                  active={prerequisites === currentTab}
                  onClick={() => setCurrentTab(prerequisites)}
                  text={prerequisites}
                />
                <Tab
                  icon={<TabIcon Icon={ListAlt} />}
                  active={protocolSteps === currentTab}
                  onClick={() => setCurrentTab(protocolSteps)}
                  text={protocolSteps}
                />
              </>
            }
          >
            {tabs[currentTab]}
          </TabContainer>
        </>
      ) : (
        <PDFData versionData={versionData} layoutClasses={layoutClasses} refetch={refetch} />
      )}
    </Grid>
  );
}

export default withWorkspaceViews()(withLockedContext(PayloadFields));
