import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    utilitiesDashboard: {
      padding: '0 80px 30px 30px;',
    },
    header: {
      color: '#1f1f1f',
      marginTop: '50px',
      fontWeight: 100,
      fontSize: '32px',
      lineHeight: '40px',
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    headerTitle: {
      width: '100%',
    },
    icon: {
      color: '#4b49a2',
      marginRight: '20px',
      verticalAlign: 'bottom',
    },
    riskManagementIcon: {
      color: '#FF6A61',
      marginRight: '20px',
      verticalAlign: 'bottom',
    },
  }),
);
