import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const GET_SUPPLIER = gql`
  query SupplierQuery($id: ID!) {
    supplier(id: $id) {
      id
      customIdentifier
      currentVersion {
        id
        title
        approvalExpiresAt
        riskLevel
        qualityAgreementVersions {
          id
          versionIdentifier
          title
          createdAt
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
          owners {
            id
          }
          approvers {
            id
          }
        }
        currentStatus {
          id
          name
          createdAt
          previousTransition {
            id
            esignature {
              id
              reasonForSignature
              reasonForRevocation
              user {
                id
                fullName
                jobRoles {
                  id
                  formattedRoleName
                }
              }
            }
          }
        }
      }
      itemType {
        id
        displaySlug
      }
    }
  }
`;

export function useSupplier(id: string) {
  return useQuery<any, any>(GET_SUPPLIER, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}
