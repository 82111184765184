import React from 'react';
import flowRight from 'lodash/flowRight';
import { Trans } from '@lingui/macro';
import LinearScale from '@material-ui/icons/LinearScale';

import { Typography, withStyles } from '@material-ui/core';

import WorkflowIcon from 'components/WorkflowIcon';
import WorkflowMenu, { CancelMenuItem, VoidMenuItem } from 'components/WorkflowMenu';
import { ContentSection } from 'components';
import WithCurrentUser from 'compositions/WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import withMetadataFromServer from 'compositions/WithMetadataFromServer';
import { DeviationWorkflowStatuses, indexById } from 'workflows/statuses';

import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';
import ConnectBar from 'compositions/TimelineGroup/components/ConnectBar';
import PermissionFailModal from 'compositions/TimelineGroup/components/PermissionFailModal';
import { AlertDialog, AlertDialogWithUser } from 'components';
import styles from 'compositions/TimelineGroup/components/sharedStyles';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

const {
  Identify,
  Investigate,
  ApproveToProceed,
  Rejected,
  ClosedDeviation,
  CanceledDeviation,
} = DeviationWorkflowStatuses;

const DeviationWorkflowStatusesById = indexById(DeviationWorkflowStatuses);

class DeviationTimeline extends React.Component {
  state = {
    permissionFailModal: false,
    deniedModal: {
      open: false,
      heading: null,
      body: null,
    },
    deniedModalWithUser: {
      open: false,
      user: null,
      role: null,
    },
    releaseNotPermittedModal: false,
    itemTransitioningLockedModal: false,
  };

  constructor(props) {
    super(props);
    this.permissions = props.permissions;
  }

  openModal = (modalName) => {
    this.setState({ [modalName]: true });
  };

  closeModal = () => {
    this.setState({
      permissionFailModal: false,
      deniedModal: {
        open: false,
        heading: null,
        body: null,
      },
      releaseNotPermittedModal: false,
      itemTransitioningLockedModal: false,
    });
  };

  handleSmallBlueCircleClick = () => {
    return this.props.handleInitTransition('recordOfOrigin');
  };

  handleVoidClick = () => {
    const status = this.props.currentVersion.currentStatusName;
    const currentWorkflowStatus = DeviationWorkflowStatusesById[status];

    if (currentWorkflowStatus.isEqualTo(ApproveToProceed)) {
      this.props.handleInitTransition('void');
    }
  };

  handleResolveRejection = () => {
    return this.props.handleInitTransition('resolveRejection');
  };

  handleInactiveUserDeniedModal = (inactiveUsers) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: inactiveUsers[0].user,
        role: inactiveUsers[0].role,
      },
    });
  };

  handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: usersWithoutActiveRole[0].user,
        role: usersWithoutActiveRole[0].role,
      },
    });
  };

  workflowMenu = () => {
    const { currentCompanyAllowsTransitioning, currentUser, currentVersion } = this.props;
    const currentWorkflowStatus = DeviationWorkflowStatusesById[currentVersion.currentStatusName];
    const { canOwn } = this.permissions;

    if (!currentCompanyAllowsTransitioning) return;

    const isCancelable = (status) => canOwn && (status.isEqualTo(Identify) || status.isEqualTo(Investigate));

    const { signingOwner } = currentVersion;

    const isVoidable = (status) =>
      signingOwner && signingOwner.id === currentUser.id && currentWorkflowStatus.isEqualTo(ApproveToProceed);

    return (
      <WorkflowMenu>
        <CancelMenuItem
          disabled={!isCancelable(currentWorkflowStatus)}
          onClick={() => {
            this.props.handleInitTransition('cancel');
          }}
        />
        <VoidMenuItem disabled={!isVoidable(currentWorkflowStatus)} onClick={this.handleVoidClick} />
      </WorkflowMenu>
    );
  };

  render() {
    const { classes, currentCompanyAllowsTransitioning, currentVersion, handleInitTransition } = this.props;

    const { permissionFailModal, releaseNotPermittedModal, itemTransitioningLockedModal } = this.state;

    const { approver, assignedApproverJobRole, assignedOwnerJobRole, currentStatusName, owner } = currentVersion;
    const status = currentStatusName;

    const currentWorkflowStatus = DeviationWorkflowStatusesById[status];
    if (!currentWorkflowStatus) throw new Error(`Invalid status for Deviation Workflow: ${status}`);

    const inactiveUsers = getInactiveUsers(owner, approver);
    const usersWithoutActiveRole = getUsersWithoutActiveRole(
      owner,
      approver,
      assignedOwnerJobRole,
      assignedApproverJobRole,
    );

    return (
      <ContentSection
        scrollX
        LabelText={<Trans>Workflow</Trans>}
        Icon={<LinearScale />}
        Controls={this.workflowMenu()}
        classes={{ label: classes.timelineHeader }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SmallCircle id="frm-small" content={'RO'} color="created" handleClick={this.handleSmallBlueCircleClick} />

          <ConnectBar color="draft" short={!currentWorkflowStatus.isEqualTo(Rejected)} visible />

          <WorkflowIcon
            type="draft"
            active={currentWorkflowStatus.isEqualTo(Identify)}
            completed={currentWorkflowStatus.isAfter(Identify)}
            label="Identify"
            size="large"
            visible
          />

          <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} incomplete visible />

          <WorkflowIcon
            type="underReview"
            active={currentWorkflowStatus.isEqualTo(Investigate)}
            completed={currentWorkflowStatus.isAfter(Investigate)}
            label="Investigate"
            size="large"
            disabledEffect={!currentCompanyAllowsTransitioning}
            handleClick={() => {
              if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');
              if (inactiveUsers.length) return this.handleInactiveUserDeniedModal(inactiveUsers);
              if (usersWithoutActiveRole.length)
                return this.handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);
              if (currentWorkflowStatus.isBefore(Investigate) && currentCompanyAllowsTransitioning)
                return handleInitTransition('investigate');
            }}
            visible
          />

          <ConnectBar
            color="ownerApproval"
            disabled={!currentCompanyAllowsTransitioning || !this.permissions.canOwn}
            incomplete
            visible
          />

          <WorkflowIcon
            type="ownerApproval"
            active={currentWorkflowStatus.isEqualTo(ApproveToProceed)}
            completed={currentWorkflowStatus.isAfter(ApproveToProceed)}
            label="Approve To Proceed"
            size="large"
            disabledEffect={!currentCompanyAllowsTransitioning}
            disabled={!this.permissions.canOwn}
            handleClick={() => {
              if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');

              if (currentWorkflowStatus.isEqualTo(Investigate) && this.permissions.canOwn)
                return handleInitTransition('approveToProceed');

              if (currentWorkflowStatus.isEqualTo(Investigate) && !this.permissions.canOwn)
                return this.openModal('permissionFailModal');

              return null;
            }}
            visible
          />

          <ConnectBar color="rejected" visible={currentWorkflowStatus.isEqualTo(Rejected)} disabled />
          <WorkflowIcon
            type="rejected"
            label="Rejected"
            visible={currentWorkflowStatus.isEqualTo(Rejected)}
            completed
            handleClick={this.handleResolveRejection}
          />

          <ConnectBar color="rejected" visible={currentWorkflowStatus.isEqualTo(CanceledDeviation)} disabled />
          <WorkflowIcon
            type="rejected"
            label="Canceled"
            visible={currentWorkflowStatus.isEqualTo(CanceledDeviation)}
            completed
          />

          <ConnectBar
            color="released"
            disabled={!currentCompanyAllowsTransitioning || !this.permissions.canApprove}
            visible={!currentWorkflowStatus.isEqualTo(Rejected) && !currentWorkflowStatus.isEqualTo(CanceledDeviation)}
          />

          <WorkflowIcon
            type="released"
            label="Closed"
            size="large"
            visible={!currentWorkflowStatus.isEqualTo(Rejected) && !currentWorkflowStatus.isEqualTo(CanceledDeviation)}
            disabledEffect={!currentCompanyAllowsTransitioning}
            completed={currentWorkflowStatus.isEqualTo(ClosedDeviation)}
            handleClick={() => {
              if (currentWorkflowStatus.isEqualTo(ApproveToProceed)) {
                if (!this.permissions.canApprove) {
                  return this.openModal('permissionFailModal');
                } else {
                  return handleInitTransition('closeOrReject');
                }
              }

              if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');
            }}
            disabled={!this.permissions.canApprove}
          />

          <PermissionFailModal
            open={permissionFailModal || releaseNotPermittedModal || itemTransitioningLockedModal}
            failType={permissionFailModal ? 'permissions' : releaseNotPermittedModal ? 'release' : 'itemTransitioning'}
            closeModal={this.closeModal}
          />

          <AlertDialog
            open={this.state.deniedModal.open}
            onClose={() => this.closeModal()}
            titleText={this.state.deniedModal.heading}
          >
            <Typography variant="body2">{this.state.deniedModal.body}</Typography>
          </AlertDialog>

          <AlertDialogWithUser
            open={this.state.deniedModalWithUser.open}
            titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
            onClose={() => this.setState({ deniedModalWithUser: { open: false, user: null, role: null } })}
            subtitle={
              inactiveUsers.length
                ? 'The following user has been deactivated.'
                : "The following user's role has been removed."
            }
            user={this.state.deniedModalWithUser.user}
            role={this.state.deniedModalWithUser.role === 'owner' ? assignedOwnerJobRole : assignedApproverJobRole}
            body={`Please use the People Menu to reassign the ${
              this.state.deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
            } role to a new user.`}
          />
        </div>
      </ContentSection>
    );
  }
}

export default flowRight([WithCurrentUser, withStyles(styles), withMetadataFromServer(), withCurrentCompany])(
  DeviationTimeline,
);
