import React, { Dispatch } from 'react';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import camelCase from 'lodash/camelCase';
import { navigate } from '@reach/router';

import { WorkflowIcon } from 'components';
import { versionSequence } from 'utils';

import { Action } from './useCourses';

export interface CourseData {
  id: string;
  title: string | null;
  item: { customIdentifier: string; id: string };
  versionIdentifier: string;
  currentStatusName: string;
  effectivenessMeasurementRequired: boolean | null;
  attachment: {
    filename: string;
  } | null;
}

export default function CourseTableRow(props: {
  locked: boolean;
  data: CourseData;
  classes;
  selected: boolean;
  dispatch: Dispatch<Action>;
}) {
  const select = () => props.dispatch({ type: 'SelectCourse', val: props.data.id });
  const deselect = () => props.dispatch({ type: 'DeselectCourse', val: props.data.id });

  const boolToStr = (value) => {
    if (value === null) {
      return 'N/A';
    } else if (value) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  return (
    <TableRow
      key={props.data.id}
      className={props.classes.tableRow}
      style={{ cursor: props.locked ? undefined : 'pointer' }}
      onClick={() => {
        if (!props.locked) navigate(`/category/courses/${props.data.item.id}`); // eslint-disable-line @typescript-eslint/no-floating-promises
        return null;
      }}
    >
      {!props.locked && (
        <TableCell scope="row" align="left" className={props.classes.tableCell} style={{ whiteSpace: 'nowrap' }}>
          <Checkbox
            checked={props.selected}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
            onChange={(e) => (e.target.checked ? select() : deselect())}
          />
        </TableCell>
      )}
      <TableCell scope="row" align="left" className={props.classes.tableCell} style={{ whiteSpace: 'nowrap' }}>
        {props.data.item.customIdentifier}
      </TableCell>
      <TableCell align="left" className={props.classes.tableCell}>
        {props.data.title}
      </TableCell>
      <TableCell align="center" className={props.classes.tableCell}>
        {versionSequence(props.data.versionIdentifier, props.data.currentStatusName)}
      </TableCell>
      <TableCell align="center" className={props.classes.tableCell}>
        <WorkflowIcon type={camelCase(props.data.currentStatusName)} completed size="small" />
      </TableCell>
      <TableCell align="left" className={props.classes.tableCell}>
        {boolToStr(props.data.effectivenessMeasurementRequired)}
      </TableCell>
    </TableRow>
  );
}
