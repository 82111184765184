import React from 'react';

import { Layout } from 'components';

// core components
import { ForgotPasswordForm } from 'compositions';

function ForgotPassword(props) {
  return (
    <Layout variant="unauthorized">
      <ForgotPasswordForm />
    </Layout>
  );
}

export default ForgotPassword;
