import gql from 'graphql-tag';

export const TOGGLE_TEST_RESOURCES = gql`
  mutation ToggleTestResourcesSummary($id: ID!, $toggle: Boolean!) {
    updateDeviceVerification(id: $id, toggleTestResources: $toggle) {
      deviceVerification {
        id
      }
    }
  }
`;

export const CREATE_REFERENCED_DOCUMENT = gql`
  mutation createReferencedAttachment($versionId: ID!, $attachmentURL: String!) {
    addReferencedAttachmentToDeviceVerificationVersion(versionId: $versionId, attachment: $attachmentURL) {
      deviceVerificationAttachment {
        id
        url
      }
    }
  }
`;

export const UPDATE_REFERENCED_DOCUMENT = gql`
  mutation updateReferencedAttachment($attachmentId: ID!, $attachmentURL: String, $description: String) {
    updateReferencedAttachmentFromDeviceVerificationVersion(
      id: $attachmentId
      attachment: $attachmentURL
      description: $description
    ) {
      deviceVerificationAttachment {
        id
        url
        description
      }
    }
  }
`;

export const DESTROY_REFERENCED_DOCUMENT = gql`
  mutation destroyReferencedAttachment($id: ID!) {
    removeReferencedAttachmentFromDeviceVerificationVersion(id: $id) {
      deviceVerificationAttachment {
        id
      }
    }
  }
`;
