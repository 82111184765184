import React, { useState } from 'react';

import { useMutation } from 'react-apollo';

import { VersionSwitch } from 'components';
import AddVersionModal from 'components/AddVersionModal';
import { createVersion as createVersionMutation } from 'utils/gql/timelineQueries';

interface Props {
  setItemChild: {
    id: string;
    currentVersion: {
      id: string;
      item: {
        id: string;
        currentRelease: any;
        workingVersion: any;
        customIdentifier: string;
      };
    };
  };
  refetchQueries: [string];
  switchVersionMutation: any;
  disabled?: boolean;
}

function VersionToggleField({ setItemChild, refetchQueries, switchVersionMutation, disabled = false }: Props) {
  const [addVersionModalOpen, setAddVersionModalOpen] = useState(false);
  const [switchRev] = useMutation(switchVersionMutation, { refetchQueries });
  const [addVersion] = useMutation(createVersionMutation, { refetchQueries });

  return (
    <>
      <VersionSwitch
        currentRelease={setItemChild.currentVersion.item.currentRelease}
        workingVersion={setItemChild.currentVersion.item.workingVersion}
        value={setItemChild.currentVersion.id}
        disabled={disabled}
        onToggle={(value) => {
          switchRev({
            variables: {
              setMemberId: setItemChild.id,
              memberVersionId: value,
            },
          }).catch((e) => {
            throw new Error(
              `Error ocurred while switching version toggle for ${
                setItemChild.currentVersion.item.customIdentifier
              } version:${setItemChild.currentVersion.id} ${e}`,
            );
          });
        }}
        onAddClick={() => {
          setAddVersionModalOpen(true);
        }}
      />
      <AddVersionModal
        open={addVersionModalOpen}
        onClose={() => {
          setAddVersionModalOpen(false);
        }}
        customIdentifier={setItemChild.currentVersion.item.customIdentifier}
        handleComplete={async () => {
          await addVersion({
            variables: {
              input: {
                itemId: setItemChild.currentVersion.item.id,
              },
            },
          });
          setAddVersionModalOpen(false);
        }}
      />
    </>
  );
}

export default VersionToggleField;
