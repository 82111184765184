import { useState } from 'react';

export function useToggledSteps(): [number[], (steps: number[]) => void] {
  const [toggledSteps, setToggledSteps] = useState<number[]>([]);

  const toggle = (steps: number[]) => {
    const copy = [...toggledSteps];

    steps.forEach((step) => {
      if (toggledSteps.includes(step)) {
        copy.splice(copy.indexOf(step), 1);
      } else {
        copy.push(step);
      }
    });
    setToggledSteps(copy);
  };

  return [toggledSteps, toggle];
}
