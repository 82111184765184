import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { DeviceVerificationVersionQuery } from './__generated__/DeviceVerificationVersionQuery';

const GET_DEVICE_VERIFICATION_VERSION = gql`
  query DeviceVerificationVersionQuery($id: ID!) {
    deviceVerificationVersion(id: $id) {
      id
      title
      displayAsApplicationVersion
      item {
        id
        itemNumber
        customIdentifier
      }
      attachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      rawReferenceAttachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      body
      purpose
      inputMethod
      locked
      displayAsApplicationVersion
      devicesTested
      materialsAndEquipment
      references
      rolesAndResponsibilities
      scope
      testBackground
      pdfDataUpload
      prerequisiteApplicable
      prerequisiteJustification
      prerequisiteSteps
      referencedAttachments {
        id
        url
        description
      }
      testSetupSteps {
        id
        position
        text
        item {
          itemType {
            id
            displaySlug
            userCreatable
          }
          id
          customIdentifier
          currentVersion {
            id
            title
          }
        }
      }
      testOverviewToggle
      testResourcesSummary
      testResourcesToggle
      testOverviewSummary
      deviceVerificationTestStepVersions {
        id
        item {
          id
          customIdentifier
          tags
          itemNumber
          itemType {
            id
            name
          }
        }
        title
        stepDescription
        acceptanceCriteria
        member {
          id
          position
        }
      }
    }
  }
`;

const CREATE_VERIFICATION_RECORD = gql`
  mutation createVerificationRecord($versionId: ID!, $productId: ID!, $title: String!, $host: String) {
    createVerificationRecord(versionId: $versionId, productId: $productId, title: $title, host: $host) {
      item {
        id
        customIdentifier
      }
    }
  }
`;

function useVersion(id: string) {
  return useQuery<DeviceVerificationVersionQuery>(GET_DEVICE_VERIFICATION_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

export { useVersion, CREATE_VERIFICATION_RECORD };
