import React, { useState } from 'react';
import DeviationInformationTab from './DeviationInformationTab';
import AdditionalMaterialsTab from './AdditionalMaterialsTab';
import { Tab, TabIcon, TabContainer } from 'components';
import { BugReport, InsertLink } from '@material-ui/icons';

interface Props {
  currentVersion: any;
  layoutClasses: { [className: string]: string };
  locked: boolean;
  onUpdate: any;
  data: any;
  viewType: string
}

export default function GuidedEditorTabs(props: Props) {
  const { currentVersion } = props;
  const deviationInformationTab = 'Deviation Information';
  const additionalMaterialsTab = 'References';

  const [currentTab, setCurrentTab] = useState(deviationInformationTab);

  const tabs = {};

  tabs[deviationInformationTab] = (
    <DeviationInformationTab
      fields={{
        identification: currentVersion.identification,
        investigation: currentVersion.investigation,
        conclusion: currentVersion.conclusion,
        correctiveOrPreventiveActions: currentVersion.correctiveOrPreventiveActions,
        other: currentVersion.other,
        deviationSummary: currentVersion.deviationSummary,
        deviationInfoToggle: currentVersion.deviationInfoToggle,
      }}
      locked={props.locked}
      versionId={currentVersion.id}
    />
  );

  tabs[additionalMaterialsTab] = (
    <AdditionalMaterialsTab
      viewType={props.viewType}
      currentVersion={currentVersion}
    />
  );

  return (
    <TabContainer
      layoutClasses={props.layoutClasses}
      tabs={
        <>
          <Tab
            icon={<TabIcon Icon={BugReport} />}
            active={deviationInformationTab === currentTab}
            onClick={() => setCurrentTab(deviationInformationTab)}
            text={deviationInformationTab}
          />
          <Tab
            icon={<TabIcon Icon={InsertLink} />}
            active={additionalMaterialsTab === currentTab}
            onClick={() => setCurrentTab(additionalMaterialsTab)}
            text={additionalMaterialsTab}
          />
        </>
      }
    >
      {tabs[currentTab]}
    </TabContainer>
  );
}
