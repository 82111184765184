import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';

import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import BuildIcon from '@material-ui/icons/Build';
import Error from '@material-ui/icons/Error';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';

import { Grid, MenuItem, Select, Typography, withStyles } from '@material-ui/core';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { ConfirmationDialog, VersionSwitch } from 'components';
import SectionLabel from 'components/core/SectionLabel';
import Panel from 'components/core/Panel';
import DesignServices from 'assets/icons/DesignServices';

import { graphFromTraces } from './Graph/util/graphFromTraces';

import Graph from './Graph';
import UserNeedsSetSelect from './UserNeedsSetSelect';
import RequirementsSetSelect from './RequirementsSetSelect';
import RiskAnalysisSelect from './RiskAnalysisSelect';
import { normalize, denormalize } from './utils';

import styles from './styles';

import { GET_SETS, UPDATE_TRACE_MATRIX_VERSION, CONVERT_LINKS_TO_VERSION_TRACES } from './gql';

function selectableSetVersion(version) {
  const name = version.currentStatus.name;
  // FIXME: filter server-side
  return name !== 'void' && name !== 'obsolete';
}

function PayloadFields(props) {
  const [updateTraceMatrixVersion] = useMutation(UPDATE_TRACE_MATRIX_VERSION);
  const [convertLinksToVersionTraces] = useMutation(CONVERT_LINKS_TO_VERSION_TRACES);
  const { data, loading, error } = useQuery(GET_SETS);

  const { classes, layoutClasses } = props;
  const currentVersion = props.versionData;
  const [showChangeConfirmation, setShowChangeConfirmation] = useState(false);
  const [newTraceType, setNewTraceType] = useState(null);

  const [showSwitchVersionConfirmation, setShowSwitchVersionConfirmation] = useState(false);
  const [versionSwitchType, setVersionSwitchType] = useState(null);
  const [newVersionToSet, setNewVersionToSet] = useState(null);

  const [requirementsSetVersionId, setRequirementsSetVersionId] = useState(
    get(currentVersion.requirementsSetVersion, 'id'),
  );
  const [userNeedsSetVersionId, setUserNeedsSetVersionId] = useState(get(currentVersion.userNeedsSetVersion, 'id'));
  const [riskAnalysisVersionId, setRiskAnalysisVersionId] = useState(get(currentVersion.riskAnalysisVersion, 'id'));

  const [graph, setGraph] = useState(null);

  useEffect(() => {
    // When a set changes we discard traces associated with it.
    // The effect cannot depend on currentVersion.traces because if it has no traces with set v1 and we add one, it will still be [].
    // Then when we change to set v2 currentVersion.traces will still be [] but graph will contain the previously added trace.
    // => so logic of updating was transfered to componentDidUpdate method of Graph object
    setGraph(graphFromTraces(currentVersion.traces));
  }, [currentVersion.traces]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) throw new Error(`Failed to load sets: ${error.message}`);
  if (loading) return null;

  const currentTraceMatrixId = currentVersion.id;

  const requirementsSetVersions = data.requirementsSets
    .flatMap((item) => item.versions)
    .filter((version) => selectableSetVersion(version));

  const userNeedsSetVersions = data.userNeedsSets
    .flatMap((item) => item.versions)
    .filter((version) => selectableSetVersion(version));

  const riskAnalysisVersions = data.riskAnalyses
    .flatMap((item) => item.versions)
    .filter((version) => selectableSetVersion(version));

  const traceType = get(currentVersion, 'traceType');

  const isDesignTraceType = traceType === 'design_trace_matrix';
  const isRiskTraceType = traceType === 'risk_trace_matrix';

  const handleToggleVersionSwitchConfirmation = (type, newVersion) => {
    setVersionSwitchType(type);
    setNewVersionToSet(newVersion);
    setShowSwitchVersionConfirmation(true);
  };

  const handleConfirmVersionSwitch = () => {
    if (newVersionToSet !== null) {
      switch (versionSwitchType) {
        case 'userNeedsSetVersion':
          setUserNeedsSetVersionId(newVersionToSet);
          updateTraceMatrixVersion({
            variables: {
              id: currentVersion.id,
              userNeedsSetVersionId: newVersionToSet,
            },
          })
            .then(() => {
              return convertLinksToVersionTraces({
                variables: {
                  traceMatrixVersionId: currentTraceMatrixId,
                },
                refetchQueries: ['traceMatrixQuery', 'timelineQuery'],
              });
            })
            .then(() => {
              setGraph(graphFromTraces(currentVersion.traces));
            })
            .catch((error) => {
              throw new Error(`Failed to updated User Need Set Version: ${error.message}`);
            });
          break;
        case 'requirementsSetVersion':
          setRequirementsSetVersionId(newVersionToSet);
          updateTraceMatrixVersion({
            variables: {
              id: currentVersion.id,
              requirementsSetVersionId: newVersionToSet,
            },
          })
            .then(() => {
              return convertLinksToVersionTraces({
                variables: {
                  traceMatrixVersionId: currentTraceMatrixId,
                },
                refetchQueries: ['traceMatrixQuery', 'timelineQuery'],
              });
            })
            .then(() => {
              setGraph(graphFromTraces(currentVersion.traces));
            })
            .catch((error) => {
              throw new Error(`Failed to updated User Need Set Version: ${error.message}`);
            });
          break;
        case 'riskAnalysisVersion':
          setRiskAnalysisVersionId(newVersionToSet);
          updateTraceMatrixVersion({
            variables: {
              id: currentVersion.id,
              riskAnalysisVersionId: newVersionToSet,
            },
          })
            .then(() => {
              return convertLinksToVersionTraces({
                variables: {
                  traceMatrixVersionId: currentTraceMatrixId,
                },
                refetchQueries: ['traceMatrixQuery', 'timelineQuery'],
              });
            })
            .then(() => {
              setGraph(graphFromTraces(currentVersion.traces));
            })
            .catch((error) => {
              throw new Error(`Failed to updated User Need Set Version: ${error.message}`);
            });
          break;
        default:
          break;
      }
      setNewVersionToSet(null);
    }
    setShowSwitchVersionConfirmation(false);
    setVersionSwitchType(null);
  };

  const handleCancelVersionSwitch = () => {
    setVersionSwitchType(null);
    setShowSwitchVersionConfirmation(false);
  };

  const setTraceType = (value) => {
    setNewTraceType(value);
    setShowChangeConfirmation(true);
  };

  const handleTraceAdded = (trace) => {
    const newGraph = graphFromTraces(graph.edges);
    newGraph.addEdge(trace);
    setGraph(newGraph);
  };

  const handleTraceUpdated = (traces) => {
    const newGraph = graphFromTraces(graph.edges);
    traces.forEach((trace) => newGraph.addEdge(trace));

    const uniqueTraces = uniqBy(Object.values(newGraph.graph), 'object.item.id');
    newGraph.graph = uniqueTraces.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});

    setGraph(newGraph);
  };

  const handleTraceDeleted = (trace) => {
    const newGraph = graphFromTraces(graph.edges.filter((edge) => edge.id !== trace.id));
    setGraph(newGraph);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Panel>
          <SectionLabel icon={BuildIcon}>Configure Trace Matrix</SectionLabel>
          <Grid container>
            <Grid xs={3} container>
              <Grid xs={12}>
                <div className={classes.parentContainer}>
                  <Typography variant="button" style={{ paddingLeft: '25px' }}>
                    <AccountBoxOutlinedIcon className={layoutClasses.icon} />
                    Trace Matrix Type
                  </Typography>
                </div>
              </Grid>
              <Grid xs={12}>
                <div className={classes.parentContainer}>
                  <Select
                    disabled={props.displayOnly}
                    disableUnderline
                    style={{ paddingLeft: '25px' }}
                    labelId="trace-matrix-type-label"
                    id="trace-matrix-type"
                    value={normalize(traceType)}
                    onChange={(event) => {
                      setShowChangeConfirmation(true);
                      setTraceType(denormalize(event.target.value));
                      setRequirementsSetVersionId(null);
                      setUserNeedsSetVersionId(null);
                      setRiskAnalysisVersionId(null);
                    }}
                  >
                    <MenuItem value="Design Trace Matrix">Design Trace Matrix</MenuItem>
                    <MenuItem value="Risk Trace Matrix">Risk Trace Matrix</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>
            <Grid xs={3} container>
              <Grid xs={12}>
                <div className={classes.parentContainer}>
                  {isDesignTraceType ? (
                    <Typography variant="button">
                      <AccountBoxOutlinedIcon className={layoutClasses.icon} />
                      User Needs Set
                    </Typography>
                  ) : (
                    <Typography variant="button">
                      <LibraryBooks className={layoutClasses.icon} />
                      Requirements Set
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid xs={12}>
                <div className={classes.parentContainer}>
                  {isDesignTraceType ? (
                    <Typography variant="button">
                      <DesignServices className={layoutClasses.icon} />
                      Requirements Set
                    </Typography>
                  ) : (
                    <Typography variant="button">
                      <Error className={layoutClasses.icon} />
                      Risk Analysis
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid xs={4} container>
              <Grid xs={12}>
                <div className={classes.parentContainer}>
                  {isDesignTraceType ? (
                    <UserNeedsSetSelect
                      className={classes.setSection}
                      isRiskTraceType={isRiskTraceType}
                      value={userNeedsSetVersionId}
                      disabled={props.displayOnly}
                      options={uniqBy(userNeedsSetVersions, 'item.id')}
                      graph={graph}
                      onChange={(newId) => {
                        setUserNeedsSetVersionId(newId);
                        updateTraceMatrixVersion({
                          variables: {
                            id: currentVersion.id,
                            userNeedsSetVersionId: newId,
                          },
                        })
                          .then(() => {
                            return convertLinksToVersionTraces({
                              variables: {
                                traceMatrixVersionId: currentTraceMatrixId,
                              },
                              refetchQueries: ['traceMatrixQuery', 'timelineQuery'],
                            });
                          })
                          .then(() => {
                            setGraph(graphFromTraces(currentVersion.traces));
                          })
                          .catch((error) => {
                            throw new Error(
                              `Failed to update User Need Set Version and convert links: ${error.message}`,
                            );
                          });
                      }}
                    />
                  ) : (
                    <RequirementsSetSelect
                      disabled={props.displayOnly}
                      value={requirementsSetVersionId}
                      isRiskTraceType={isRiskTraceType}
                      options={uniqBy(requirementsSetVersions, 'item.id')}
                      graph={graph}
                      onChange={(newId) => {
                        setRequirementsSetVersionId(newId);
                        updateTraceMatrixVersion({
                          variables: {
                            id: currentVersion.id,
                            requirementsSetVersionId: newId,
                          },
                        })
                          .then(() => {
                            return convertLinksToVersionTraces({
                              variables: {
                                traceMatrixVersionId: currentTraceMatrixId,
                              },
                              refetchQueries: ['traceMatrixQuery', 'timelineQuery'],
                            });
                          })
                          .then(() => {
                            setGraph(graphFromTraces(currentVersion.traces));
                          })
                          .catch((error) => {
                            throw new Error(`Failed to updated Requirements Set Version: ${error.message}`);
                          });
                      }}
                    />
                  )}
                </div>
              </Grid>
              <Grid xs={12}>
                <div className={classes.parentContainer}>
                  {isDesignTraceType ? (
                    <RequirementsSetSelect
                      disabled={props.displayOnly}
                      value={requirementsSetVersionId}
                      isRiskTraceType={isRiskTraceType}
                      options={uniqBy(requirementsSetVersions, 'item.id')}
                      graph={graph}
                      onChange={(newId) => {
                        setRequirementsSetVersionId(newId);
                        updateTraceMatrixVersion({
                          variables: {
                            id: currentVersion.id,
                            requirementsSetVersionId: newId,
                          },
                        })
                          .then(() => {
                            return convertLinksToVersionTraces({
                              variables: {
                                traceMatrixVersionId: currentTraceMatrixId,
                              },
                              refetchQueries: ['traceMatrixQuery', 'timelineQuery'],
                            });
                          })
                          .then(() => {
                            setGraph(graphFromTraces(currentVersion.traces));
                          })
                          .catch((error) => {
                            throw new Error(`Failed to updated Requirements Set Version: ${error.message}`);
                          });
                      }}
                    />
                  ) : (
                    <RiskAnalysisSelect
                      className={classes.setSection}
                      value={riskAnalysisVersionId}
                      isRiskTraceType={isRiskTraceType}
                      disabled={props.displayOnly}
                      options={uniqBy(riskAnalysisVersions, 'item.id')}
                      graph={graph}
                      onChange={(newId) => {
                        setRiskAnalysisVersionId(newId);
                        updateTraceMatrixVersion({
                          variables: {
                            id: currentVersion.id,
                            riskAnalysisVersionId: newId,
                          },
                        })
                          .then(() => {
                            return convertLinksToVersionTraces({
                              variables: {
                                traceMatrixVersionId: currentTraceMatrixId,
                              },
                              refetchQueries: ['traceMatrixQuery', 'timelineQuery'],
                            });
                          })
                          .then(() => {
                            setGraph(graphFromTraces(currentVersion.traces));
                          })
                          .catch((error) => {
                            throw new Error(`Failed to updated Risk Analysis Version: ${error.message}`);
                          });
                      }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid xs={2} container>
              <Grid xs={12}>
                <div className={classes.parentContainer}>
                  {isDesignTraceType
                    ? currentVersion.userNeedsSetVersion && (
                        <VersionSwitch
                          workingVersion={currentVersion.userNeedsSetVersion.item.workingVersion}
                          currentRelease={currentVersion.userNeedsSetVersion.item.currentRelease}
                          value={currentVersion.userNeedsSetVersion.id}
                          disabled={props.displayOnly}
                          withAddButton={false}
                          onToggle={(newVersion) =>
                            handleToggleVersionSwitchConfirmation('userNeedsSetVersion', newVersion)
                          }
                        />
                      )
                    : currentVersion.requirementsSetVersion && (
                        <VersionSwitch
                          workingVersion={currentVersion.requirementsSetVersion.item.workingVersion}
                          currentRelease={currentVersion.requirementsSetVersion.item.currentRelease}
                          value={currentVersion.requirementsSetVersion.id}
                          disabled={props.displayOnly}
                          withAddButton={false}
                          onToggle={(newVersion) =>
                            handleToggleVersionSwitchConfirmation('requirementsSetVersion', newVersion)
                          }
                        />
                      )}
                </div>
              </Grid>
              <Grid xs={12}>
                <div className={classes.parentContainer}>
                  {isDesignTraceType
                    ? currentVersion.requirementsSetVersion && (
                        <VersionSwitch
                          workingVersion={currentVersion.requirementsSetVersion.item.workingVersion}
                          currentRelease={currentVersion.requirementsSetVersion.item.currentRelease}
                          value={currentVersion.requirementsSetVersion.id}
                          disabled={props.displayOnly}
                          withAddButton={false}
                          onToggle={(newVersion) =>
                            handleToggleVersionSwitchConfirmation('requirementsSetVersion', newVersion)
                          }
                        />
                      )
                    : currentVersion.riskAnalysisVersion && (
                        <VersionSwitch
                          workingVersion={currentVersion.riskAnalysisVersion.item.workingVersion}
                          currentRelease={currentVersion.riskAnalysisVersion.item.currentRelease}
                          value={currentVersion.riskAnalysisVersion.id}
                          disabled={props.displayOnly}
                          withAddButton={false}
                          onToggle={(newVersion) =>
                            handleToggleVersionSwitchConfirmation('riskAnalysisVersion', newVersion)
                          }
                        />
                      )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Panel>
      </Grid>
      <Graph
        onTraceAdd={handleTraceAdded}
        onTraceDelete={handleTraceDeleted}
        onTraceUpdate={handleTraceUpdated}
        disableTracing={props.displayOnly}
        userNeedsSetVersionId={userNeedsSetVersionId}
        requirementsSetVersionId={requirementsSetVersionId}
        riskAnalysisVersionId={riskAnalysisVersionId}
        currentVersion={currentVersion}
        graph={graph}
        layoutClasses={layoutClasses}
        isRiskTrace={isRiskTraceType}
      />
      {showChangeConfirmation && (
        <ConfirmationDialog
          size="large"
          textAlign="left"
          onClose={() => {
            setShowChangeConfirmation(false);
            setNewTraceType(null);
          }}
          onCancel={() => {
            setShowChangeConfirmation(false);
            setNewTraceType(null);
          }}
          onConfirm={() => {
            setShowChangeConfirmation(false);
            updateTraceMatrixVersion({
              variables: {
                id: currentVersion.id,
                traceType: newTraceType,
              },
              refetchQueries: ['traceMatrixQuery'],
            })
              .then(() => {
                setGraph(graphFromTraces(currentVersion.traces));
                if (isDesignTraceType) {
                  setUserNeedsSetVersionId(null);
                  setRequirementsSetVersionId(null);
                } else {
                  setRiskAnalysisVersionId(null);
                }
              })
              .catch((error) => {
                throw new Error(`Failed to update Trace Type: ${error.message}`);
              });
            setNewTraceType(null);
          }}
          open={true}
          title="Set Trace Type Change Impact on Trace Matrix"
        >
          <Typography variant="body2">
            Are you sure you wish to proceed? It will destroy all traces in the current trace matrix.
          </Typography>
        </ConfirmationDialog>
      )}
      {showSwitchVersionConfirmation && (
        <ConfirmationDialog
          size="large"
          textAlign="left"
          onClose={handleCancelVersionSwitch}
          onCancel={handleCancelVersionSwitch}
          onConfirm={handleConfirmVersionSwitch}
          open={true}
          title="Set Version Change Impact on Trace Matrix"
        >
          <Typography variant="body2">
            Are you sure you wish to proceed? It will delete all traces linked to the current version of chosen set.
          </Typography>
        </ConfirmationDialog>
      )}
    </Grid>
  );
}

export default flowRight(
  withStyles(styles, { withTheme: true }),
  withWorkspaceViews(),
)(PayloadFields);
