import React from 'react';

import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import sortedUniqBy from 'lodash/sortedUniqBy';

import Row from 'components/MatrixGraph/Row';
import TraceVertex from '../TraceVertex';

import Card from '../Card';

function renderTraces(renderItems, props) {
  const uniqueItems = uniqBy(renderItems, 'id');

  return uniqueItems.map((item) => {
    const version = findTracedVersion(props.graph, item);
    version.item = Object.assign({}, item);
    if (version.item.currentRelease) version.item.currentRelease.item = Object.assign({}, version.item);
    if (version.item.workingVersion) version.item.workingVersion.item = Object.assign({}, version.item);
    return renderVersion(version, props);
  });
}

function renderVersion(version, props) {
  const drawCard = (vertex, parent, selected, direction) => {
    const traces = parent
      ? direction === 'downstream'
        ? otherTraces(vertex, parent, 'upstream')
        : otherTraces(vertex, parent, 'downstream')
      : undefined;

    const upstreamTrace = () => props.graph.findEdge(vertex.object.id, parent.object.id);
    const downstreamTrace = () => props.graph.findEdge(parent.object.id, vertex.object.id);

    const handleUpstreamTraceClicked = (trace) => {
      if (!trace) return;
      props.onTraceChange(trace);
      props.onVersionClick(trace.upstreamVersion, version);
    };

    const handleDownstreamTraceClicked = (trace) => {
      if (!trace) return;
      props.onTraceChange(trace);
      props.onVersionClick(trace.downstreamVersion, version);
    };

    const handleVersionClicked = (v) => {
      props.onTraceChange(null);
      props.onVersionClick(v, version);
    };

    const handleClick =
      parent && direction
        ? () =>
            direction === 'upstream'
              ? handleUpstreamTraceClicked(upstreamTrace())
              : handleDownstreamTraceClicked(downstreamTrace())
        : (v) => handleVersionClicked(v.object);

    return (
      <TraceVertex
        disabled={props.disabled}
        onClick={(v) => handleClick(v)}
        onAddRightEdge={props.onAddDownstreamTrace}
        onAddLeftEdge={props.onAddUpstreamTrace}
        selected={selected}
        vertex={vertex}
        isRiskTrace={props.isRiskTrace}
      >
        <Card
          disabled={props.disabled}
          graph={props.graph}
          version={vertex.object}
          selected={selected}
          onUpdate={props.onUpdateTrace}
          otherTraces={traces}
        />
      </TraceVertex>
    );
  };
  return <Row {...props} root={version} key={version.id} drawCard={drawCard} />;
}

function itemsWithExistingTraces(traces, typeName) {
  const foundTraces = reduce(
    traces,
    (itemTraces, trace) => {
      if (trace.upstreamVersion.__typename === typeName) {
        itemTraces.push(trace.upstreamVersion.item);
      }
      if (trace.downstreamVersion.__typename === typeName) {
        itemTraces.push(trace.downstreamVersion.item);
      }

      return itemTraces;
    },
    [],
  );
  return sortedUniqBy(uniqBy(foundTraces, (trace) => trace.id), (trace) => trace.id);
}

function findTracedVersion(graph, item) {
  const version =
    ['currentVersion', 'currentRelease', 'workingVersion'].find((prop) => item[prop] && graph.hasEdge(item[prop].id)) ||
    'currentVersion';

  return Object.assign({}, item[version]);
}

const versionToConnection = (version) => {
  return {
    customIdentifier: version.item.customIdentifier,
    id: version.item.id,
    itemType: {
      displaySlug: version.item.itemType.displaySlug,
      name: version.item.itemType.name,
    },
    title: version.title,
  };
};

const otherTraces = (root, parent, direction) =>
  reduce(
    root[direction],
    (traces, { object }) => {
      if (object.item.id !== parent.object.item.id && object.id !== parent.id) {
        traces.push(versionToConnection(object));
      }

      return uniqBy(traces, (trace) => trace.id);
    },
    [],
  );

function removeRetired(item) {
  return item.currentVersion.currentStatus.name !== 'retired';
}

function itemsWithUpstreamRiskControlTraces(traces, typeName) {
  const foundTraces = reduce(
    traces,
    (itemTraces, trace) => {
      if (
        trace.downstreamVersion.__typename === typeName &&
        trace.upstreamVersion.__typename === 'RiskControlVersion'
      ) {
        itemTraces.push(trace.downstreamVersion.item);
      }
      return itemTraces;
    },
    [],
  );
  return sortedUniqBy(uniqBy(foundTraces, (trace) => trace.id), (trace) => trace.id);
}

function itemsWithUpstreamDesignTraceMatrixTraces(traces, typeName) {
  const foundTraces = reduce(
    traces,
    (itemTraces, trace) => {
      if (
        trace.downstreamVersion.__typename === typeName &&
        (trace.upstreamVersion.__typename === 'RequirementVersion' ||
          trace.upstreamVersion.__typename === 'UserNeedVersion' ||
          trace.upstreamVersion.__typename === 'RiskControlVersion')
      ) {
        itemTraces.push(trace.downstreamVersion.item);
      }
      return itemTraces;
    },
    [],
  );
  return sortedUniqBy(uniqBy(foundTraces, (trace) => trace.id), (trace) => trace.id);
}

export {
  itemsWithExistingTraces,
  renderTraces,
  removeRetired,
  otherTraces,
  renderVersion,
  itemsWithUpstreamRiskControlTraces,
  itemsWithUpstreamDesignTraceMatrixTraces,
};
