import gql from 'graphql-tag';

export const effectiveViewQuery = gql`
  query FormBasedEffectiveViewQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      currentUser {
        id
        readOnly
      }
      currentRelease {
        id
        locked
        title
      }
    }
  }
`;
