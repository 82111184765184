import Autocomplete, { RenderInputParams } from '@material-ui/lab/Autocomplete';
import { Chip, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import { isUndefined } from 'lodash';

interface RpcInputProps {
  inputValue: string;
  placeholder: string;
  color: string;
}
const RpcInput = (props: RpcInputProps) => {
  const classes = useStyles();
  const { inputValue, placeholder, color } = props;
  return (
    <Autocomplete
      classes={classes}
      style={{ width: '195px' }}
      disabled
      closeIcon={null}
      popupIcon={null}
      disableClearable={true}
      disablePortal={true}
      size={'small'}
      options={[]}
      value={isUndefined(inputValue) ? [] : [inputValue]}
      autoHighlight={true}
      multiple
      renderInput={(params: RenderInputParams) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            rows={1}
            placeholder={!isUndefined(inputValue) ? '' : placeholder}
          />
        );
      }}
      renderTags={(tagValue, _getTagProps) => {
        return tagValue.map((option) => (
          <Chip
            key={option}
            style={{ backgroundColor: color }}
            size={'small'}
            classes={{ root: classes.chipRoot, label: classes.chipLabel }}
            label={<Typography variant={'body2'}>{option}</Typography>}
          />
        ));
      }}
    />
  );
};

export default RpcInput;
