import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_theme: any) =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& > div': {
        marginLeft: '5px',
      },
      '&:hover': {
        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '1px',
          background: '#000',
        },
      },
    },
    chipRoot: {
      paddingLeft: '4px',
      '& .MuiChip-deleteIcon': {
        opacity: 0,
      },
      '&:hover': {
        '& .MuiChip-deleteIcon': {
          opacity: 1,
        },
      },
      '&.Mui-disabled': {
        opacity: '1 !important',
      },
    },
  }),
);
