import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

import { UserAvatar } from '../index';

const styles = (theme) => ({
  userInfoContainer: {
    minWidth: '20%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  userTextContainer: {
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  icon: {
    position: 'absolute',
    top: -5,
    left: 3,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  nameText: {
    color: theme.palette.blueDark,
    textAlign: 'left',
  },
  titleText: {
    color: '#A4A8B1',
  },
});

function UserListItem({ name, title, userId, classes, icon = undefined }) {
  return (
    <div className={classes.userInfoContainer}>
      <UserAvatar userId={userId} />
      <div className={classes.userTextContainer}>
        <Typography variant="body2" className={classes.nameText}>
          {name}
        </Typography>
        <Typography variant="subtitle2" className={classes.titleText}>
          {title}
        </Typography>
      </div>
      {icon && <div className={classes.icon}>{icon}</div>}
    </div>
  );
}

export default withStyles(styles)(UserListItem);
