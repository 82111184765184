import React from 'react';

import Paper from '@material-ui/core/Paper';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { useQuery } from 'react-apollo';

import MakeLink from 'components/MakeLink';
import SectionLabel from 'components/core/SectionLabel';

import { PRECOMPLIANCE_TASK_CATEGORIES } from './gql';
import PrecomplianceCategorySection from './PrecomplianceCategorySection';
import { useStyles } from './styles';

function PrecomplianceTaskList() {
  const { data, loading, error } = useQuery(PRECOMPLIANCE_TASK_CATEGORIES);
  const classes = useStyles();

  if (loading) return <div />;
  if (error) throw new Error(`Error loading pre compliance task categories: ${error}`);

  return (
    <Paper elevation={1}>
      <div className={classes.content}>
        <SectionLabel icon={VerifiedUserIcon}>Precompliance Activity Checklist</SectionLabel>
        <MakeLink className={classes.cornerLink} href="/category/task" params={{ view: 'Table' }}>
          View All Tasks
        </MakeLink>
        <div className={classes.categoriesSection}>
          {data.precomplianceTaskCategories.map((category, index) => (
            <PrecomplianceCategorySection index={index} key={`category-${index}`} category={category} />
          ))}
        </div>
      </div>
    </Paper>
  );
}

export default PrecomplianceTaskList;
