import React from 'react';

import { Grid, Typography, createStyles, withStyles } from '@material-ui/core';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import flowRight from 'lodash/flowRight';

import { Spacer } from 'components';
import ContactSupport from 'components/ContactSupport';

import ComingSoonBackground from '../assets/img/Computer.svg';

const styles = (theme) =>
  createStyles({
    container: {
      padding: `0 ${theme.spacing(2)}px`,
      width: '100%',
    },
    bgImage: {
      maxWidth: '50%',
      position: 'fixed',
      bottom: 0,
      right: 0,
    },
    emailLink: {
      color: theme.palette.primary.main,
      lineHeight: '36px',
      fontWeight: 400,
      '&:hover': {
        opacity: 0.8,
      },
    },
    textBody: {
      position: 'absolute',
      top: '125px',
      width: '50%',
      textAlign: 'left',
    },
  });

function FeatureUnavailablePage({ classes, i18n }) {
  return (
    <Grid container classes={{ container: classes.container }} item xs={12}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <div className={classes.container}>
            <img alt={i18n._(t`Coming Soon Background`)} src={ComingSoonBackground} className={classes.bgImage} />
            <div className={classes.textBody}>
              <Typography variant="h1">Builder View Unavailable</Typography>
              <Spacer factor={2} />
              <Typography variant="body1">
                Your assigned license does not
                <br />
                permit the editing of items in the
                <br />
                builder view. Please contact your
                <br />
                company's system administrator or
                <br />
                <ContactSupport /> to upgrade.
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default flowRight([withStyles(styles), withI18n()])(FeatureUnavailablePage);
