import React, { useEffect } from 'react';

import get from 'lodash/get';

import { ChangeOrderError } from 'workflows/releaseErrors';
import { ChangeOrderWorkflowStatuses } from 'workflows/statuses';

import { CertifiedSignature, YesOrNo } from '../views';

import ImpactedItemsDialog from '../../components/NotAllowedImpactedItemsDialog';

// ideally I would have this on the back-end but then bigger refactoring could be looked into
// otherwise the difference is not that big, so just moving it here
const APPROVED_STATUSES = ['approved_draft', 'retirement_approved'];
function unapprovedImpactedItems(trainingImpactAssessments) {
  return trainingImpactAssessments
    .filter((item) => !APPROVED_STATUSES.includes(item.impactedVersion.currentStatus.name))
    .map((impactedItem) => {
      const { impactedVersion } = impactedItem;
      const { item } = impactedVersion;
      return {
        id: item.id,
        versionIdentifier: item.currentVersion.versionIdentifier,
        displaySlug: item.itemType.displaySlug,
        currentStatus: impactedVersion.currentStatus.name,
        customIdentifier: item.customIdentifier,
      };
    });
}

const TakeOwnershipConfirm = (props) => (
  <YesOrNo
    disableOnSubmit
    prompt={`Do you want to take ownership of ${props.itemCustomIdentifier} or unlock and send to Draft status?`}
    yesLabel="Take Ownership"
    noLabel="Unlock"
    {...props}
  />
);

const ReadyForClosureSignature = (props) => {
  const heading = 'Approval Declaration';
  const dialog = `As the ${props.currentVersion.assignedOwnerJobRole.formattedRoleName}, I certify ${
    props.itemCustomIdentifier
  } is ready for closure.`;

  return (
    <CertifiedSignature
      {...props}
      dialog={dialog}
      heading={heading}
      errorDisplay={props.error ? ChangeOrderError : undefined}
    />
  );
};

const CheckImpactedItems = (props) => {
  const unapproved = unapprovedImpactedItems(get(props, 'currentVersion.trainingImpactAssessments'));
  const noModal = !unapproved.length;

  useEffect(() => {
    if (noModal) {
      props.handleComplete(true, { forgetThisStep: true });
    }
  }, [props, props.handleComplete, noModal]);

  if (noModal) return <div />;

  return (
    <ImpactedItemsDialog items={unapproved}>
      This <strong>Change Order</strong> cannot be transitioned because the following impacted items do not have an
      Approved Draft status:
    </ImpactedItemsDialog>
  );
};

export default {
  initialStep: 'takeOwnership',
  internalSteps: {
    takeOwnership: {
      component: TakeOwnershipConfirm,
      getNextStep: (takeOwnership) => (takeOwnership ? 'checkImpactedItems' : 'complete'),
    },
    checkImpactedItems: {
      component: CheckImpactedItems,
      getNextStep: (_) => 'changeOrderReadyForClosure',
    },
    changeOrderReadyForClosure: {
      component: ReadyForClosureSignature,
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ takeOwnership, changeOrderReadyForClosure }) => {
    if (takeOwnership) {
      return {
        ...changeOrderReadyForClosure,
        toStatus: ChangeOrderWorkflowStatuses.ReadyForClose.id,
      };
    } else {
      return {
        toStatus: ChangeOrderWorkflowStatuses.Draft.id,
      };
    }
  },
};
