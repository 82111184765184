import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import SwapHoriz from '@material-ui/icons/SwapHoriz';

import slugs from '../../displaySlugs';
import Views from 'workspaces/views';
import PrintViewMenu from 'components/PrintViewMenu';

import RecordPage from './RecordPage';
import PayloadFields from './PayloadFields';

const itemType = 'changeOrder';
const listViewQuery = gql`
  query changeOrdersQuery {
    changeOrders {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        id
        currentStatusName
        title
        releasedAt
        descriptionOfChange
        riskLevel
        createdAt
        signingOwner {
          id
          firstName
          lastName
        }
        signingApprover {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.descriptionOfChange',
    headerName: 'Description',
    type: 'long_text',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.releasedAt',
    headerName: 'Effective Date',
    type: 'date',
  },
  {
    field: 'currentVersion.riskLevel',
    headerName: 'Risk Level',
    type: 'short_text',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

export default {
  displaySlug: slugs[itemType],
  itemType,
  icon: SwapHoriz,
  label: t`Change Order`,
  categoryLabel: t`Change Orders`,
  recordWorkspace: [Views.ChangeOrder],
  listWorkspace: [Views.Table, Views.LinksGraph],
  listViewColumns,
  listViewQuery,
  PayloadFields,
  RecordPage,
  EffectiveViewOptions: PrintViewMenu,
};
