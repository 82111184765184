import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName } from 'recompose';

import { withStyles } from '@material-ui/core';

import { buildConditionalClasses } from 'utils';

const styles = (theme) => ({
  cardPopout: {
    background: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    margin: `0 ${theme.spacing(3)}px -${theme.spacing(1)}px`,
    padding: theme.spacing(2),
    position: 'relative',
    textAlign: 'center',
    top: theme.spacing(1) * -5,
    height: '134px',
  },
});

function CardPopout({ classes, className, ...rest }) {
  return (
    <div
      className={buildConditionalClasses([
        [className, className],
        [true, classes.cardPopout],
      ])}
      {...rest}
    />
  );
}

CardPopout.propTypes = {
  classes: PropTypes.shape({
    cardPopout: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default compose(
  setDisplayName('CardPopout'),
  withStyles(styles),
)(CardPopout);
