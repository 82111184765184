import React, { ReactNode } from 'react';

import { Grid } from '@material-ui/core';

interface Props {
  children: ReactNode;
}

function Page(props: Props) {
  return (
    <Grid container spacing={5} style={{ width: '100%', margin: 'inherit' }}>
      {props.children}
    </Grid>
  );
}

export default Page;
