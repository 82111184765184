import React from 'react';

import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

export function ArrayCell({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Typography variant="body2">
        {data.length
          ? data
              .map((val) => val[Object.keys(val)[0]])
              .join(', ')
              .replace(/^,/, '')
          : 'n/a'}
      </Typography>
    </div>
  );
}

export const arrayHeaderStyle = { textAlign: 'left' };
