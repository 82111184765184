import React from 'react';
import { Trans } from '@lingui/macro';

import { ChangeSummary } from 'compositions/TimelineGroup/steps/views';
import { CertifiedSignature, RejectReason, YesOrNo } from 'compositions/TimelineGroup/steps/views';
import { RiskNoteWorkflowStatuses } from 'workflows/statuses';
import { buildActionMessage, buildReleaseMessage } from '../shared/workflowTransitionsUtil';

export default {
  initialStep: 'releaseOrReject',
  internalSteps: {
    releaseOrReject: {
      component: (props) => (
        <YesOrNo
          prompt={buildActionMessage('release', props.currentVersion, props.itemCustomIdentifier)}
          yesLabel="Release"
          noLabel="Reject"
          {...props}
        />
      ),
      getNextStep: (release) => (release ? 'changeSummary' : 'rejectReason'),
    },
    rejectReason: {
      component: (props) => {
        const heading = `As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I reject ${
          props.itemCustomIdentifier
        }.`;
        return <RejectReason {...props} heading={heading} />;
      },
      getNextStep: (_) => 'complete',
    },
    changeSummary: {
      component: (props) => <ChangeSummary buttonText="Done" smallCircle {...props} />,
      getNextStep: () => 'releaseSignature',
    },
    releaseSignature: {
      component: (props) => {
        const heading = <Trans>Release</Trans>;
        const dialog = buildReleaseMessage(props);
        return <CertifiedSignature dialog={dialog} heading={heading} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ releaseOrReject, releaseSignature, rejectReason, changeSummary }) => {
    if (releaseOrReject) {
      return {
        toStatus: RiskNoteWorkflowStatuses.Released.id,
        ...releaseSignature,
        ...changeSummary,
      };
    } else {
      return {
        toStatus: RiskNoteWorkflowStatuses.Rejected.id,
        ...rejectReason.signature,
        reasonForRejection: rejectReason.reason,
      };
    }
  },
};
