import gql from 'graphql-tag';

export default gql`
  mutation createItemAndMember($input: CreateItemAndMemberInput!) {
    createItemAndMember(input: $input) {
      item {
        id
        currentVersion {
          id
        }
      }
    }
  }
`;
