import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

import { navigate } from '@reach/router';

import { Loading } from 'components';

import { Button, LinearProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';

pdfjs.GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.min.js';

const styles = (theme) => ({
  documentContainer: {
    background: theme.palette.common.pdf.background,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    maxWidth: '100%',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    overflowY: 'scroll',
  },
  navBtn: {
    borderRadius: 4,
    color: theme.palette.common.input.outline,
  },
  navigationBar: {
    ...theme.typography.body2,
    color: theme.palette.common.input.outline,
    background: theme.palette.common.pdf.navBar,
    textAlign: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  navigationBarRadius: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  noPreview: {
    paddingTop: '5%',
    textAlign: 'center',
  },
  pageContainer: {
    maxWidth: '100%',
  },
});

class PDFViewer extends Component {
  static propTypes = {
    documentClass: PropTypes.string,
    classes: PropTypes.shape({
      documentContainer: PropTypes.string,
      navigationBar: PropTypes.string,
      navBtn: PropTypes.string,
      pageContainer: PropTypes.string,
    }).isRequired,
  };

  state = {
    numPages: null,
    pageNumber: 1,
    scale: 1.0,
    zoomPercentage: 50,
  };

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  changePage = (offset) =>
    this.setState((prevState) => ({
      pageNumber: prevState.pageNumber + offset,
    }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  zoom = () => {
    this.setState({
      scale: this.state.scale + 0.2,
      zoomPercentage: this.state.zoomPercentage + 20,
    });
  };

  unzoom = () => {
    this.setState({
      scale: this.state.scale - 0.2,
      zoomPercentage: this.state.zoomPercentage - 20,
    });
  };

  render() {
    const { classes, url, loading } = this.props;
    const { pageNumber, numPages, scale, zoomPercentage } = this.state;

    if (!url && !loading) return null;
    return (
      <div styles={{ marginTop: 20 }}>
        {loading && !url && (
          <LinearProgress
            variant="indeterminate"
            style={{
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
            }}
          />
        )}
        <div className={`${classes.navigationBar} ${!loading && classes.navigationBarRadius}`}>
          <span>
            <Button
              type="button"
              disabled={pageNumber <= 1}
              onClick={this.previousPage}
              className={classes.navBtn}
              disableRipple
            >
              <ExpandLess />
            </Button>
            <Button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={this.nextPage}
              className={classes.navBtn}
              disableRipple
            >
              <ExpandMore />
            </Button>
            Page {pageNumber || (numPages ? 1 : '--')}/{numPages || '--'}
          </span>
          <span>
            <Button type="button" disabled={scale < 0.9} onClick={this.unzoom} className={classes.navBtn} disableRipple>
              <ZoomOut />
            </Button>
            <Button type="button" disabled={scale > 1.4} onClick={this.zoom} className={classes.navBtn} disableRipple>
              <ZoomIn />
            </Button>
            Zoom {zoomPercentage}%
          </span>
          {/* Only used by PDFExportDialog. This option can be removed when we have true fullscreen mode per QAD-963 */
          this.props.disableFullscreen !== true && (
            <span>
              <Button
                type="button"
                disabled={!url}
                onClick={() => navigate(url)}
                className={classes.navBtn}
                disableRipple
              >
                <OpenInBrowser />
              </Button>
            </span>
          )}
        </div>
        <Document
          file={url}
          noData={null}
          onLoadSuccess={this.onDocumentLoad}
          loading={<Loading />}
          className={classes.documentContainer + ' ' + (this.props.documentClass || '')}
        >
          <Page className={classes.pageContainer} pageNumber={pageNumber} scale={scale} height={700} />
        </Document>
      </div>
    );
  }
}

export default withStyles(styles)(PDFViewer);
