import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Loading from 'components/Loading';

const usersQuery = gql`
  query userListQuery($includeSystemUser: Boolean) {
    users(includeSystemUser: $includeSystemUser) {
      id
      fullName
      emailAddress
      username
      status
      jobRoles {
        id
        formattedRoleName
      }
      employee {
        id
        department
      }
      profile {
        id
        photoUrl
      }
    }
  }
`;

const withAllUsers = (Component, includeSystemUser = false) => {
  return (props) => (
    <Query errorPolicy="all" query={usersQuery} variables={{ includeSystemUser }} fetchPolicy={'no-cache'}>
      {({ data, loading }) => {
        if (loading) return <Loading />;
        if (data === undefined) return null;

        const formattedUsers =
          data.users &&
          data.users.map((user) => ({
            id: user.id,
            status: user.status,
            fullName: user.fullName,
            emailAddress: user.emailAddress,
            username: user.username,
            jobRoles: user.jobRoles,
            photoUrl: user.profile && user.profile.photoUrl,
          }));

        return <Component {...props} allUsers={formattedUsers} />;
      }}
    </Query>
  );
};

export default withAllUsers;
