import gql from 'graphql-tag';

export const TASKS_QUERY = gql`
  query tasksQuery {
    tasks(
      onlyAssignedToCurrentUser: true
      excludeInDoneStatus: true
      excludeInBacklogStatus: true
      excludeInBlockedStatus: true
      excludeInWontDoStatus: true
    ) {
      id
      customIdentifier
      currentVersion {
        id
        title
        dueDate
        currentStatus {
          id
          name
        }
      }
    }
  }
`;
