import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    items: {
      height: '100%',
      margin: 0,
      padding: 0,
    },
    noTraceMsgContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    noTraceMsg: {
      width: '38%',
      margin: 0,
    },
    tracedItemsList: {
      listStyle: 'none',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    traceItemIdentifier: {
      fontWeight: 600,
    },
  }),
);
