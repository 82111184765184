import { get, find, isEmpty } from 'lodash';
import memoizeOne from 'memoize-one';
import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Location, Match, navigate } from '@reach/router';

import { workspaceViewSelector } from 'redux/workspaceViews/selectors';
import { setWorkspaceViewForRoute } from 'redux/workspaceViews/actions';

import WithRoutes from 'setup/WithRoutes';

/**
 * This HOC hydrates views into category related props like activeCategory (if present).
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
const withWorkspaceViewsForCategories = (WrappedComponent) => (componentProps) => {
  function routeForCategory({ slug }) {
    const { protectedRoutes } = componentProps;
    return (
      find(protectedRoutes, ({ categorySlug, path }) => categorySlug === slug && path.endsWith(categorySlug)) || {}
    );
  }

  function categoryWithViews(category) {
    const { RouteComponent, ...routeData } = routeForCategory(category);
    return { ...category, ...routeData };
  }

  // These values may not exist depending on if the `withCategories` HOC is applied
  // in the correct order. This HOC must handle both cases correctly.
  const { activeCategory, getCategoryHierarchy } = componentProps;
  const hasCategoryData = !isEmpty(activeCategory) && getCategoryHierarchy;

  function getCategoryHierarchyWithWorkspaceViews(...args) {
    const categoryHierarchy = getCategoryHierarchy(...args);
    return categoryHierarchy.map((category) => categoryWithViews(category));
  }

  const categoriesProps = hasCategoryData && {
    activeCategory: categoryWithViews(activeCategory),
    getCategoryHierarchy: getCategoryHierarchyWithWorkspaceViews,
  };

  // Overwrite those values in the component props.
  return <WrappedComponent {...componentProps} {...categoriesProps} />;
};

function getQueryParams({ location } = {}) {
  // The search value includes the '?'. It must be removed before parsing.
  return qs.parse(get(location, 'search', '').slice(1));
}

class WithActiveWorkspaceViewForRoute extends Component {
  state = { changeViewTo: undefined };

  componentDidMount() {
    const activeWorkspaceViewName = get(this.getActiveWorkspaceView(this.props), 'id');
    const viewQueryParam = get(getQueryParams(this.props), 'view');
    if (
      this.props.controlRoute &&
      this.props.location &&
      activeWorkspaceViewName &&
      (activeWorkspaceViewName !== this.props.lastWorkspaceViewForRoute.id ||
        activeWorkspaceViewName !== viewQueryParam)
    ) {
      this.props.setWorkspaceViewForRoute(activeWorkspaceViewName);
      const queryParams = qs.stringify({
        ...getQueryParams(this.props),
        view: activeWorkspaceViewName,
      });
      /* eslint-disable @typescript-eslint/no-floating-promises */
      navigate(`${this.props.location.pathname}?${queryParams}`, {
        replace: true,
      });
      /* eslint-enable @typescript-eslint/no-floating-promises */
    }
  }

  componentDidUpdate() {
    const activeWorkspaceViewName = get(this.getActiveWorkspaceView(this.props), 'id');
    const viewQueryParam = get(getQueryParams(this.props), 'view');
    if (this.props.controlRoute && this.props.location && (activeWorkspaceViewName && !viewQueryParam)) {
      const queryParams = qs.stringify({
        ...getQueryParams(this.props),
        view: activeWorkspaceViewName,
      });
      navigate(`${this.props.location.pathname}?${queryParams}`); // eslint-disable-line @typescript-eslint/no-floating-promises
    }
  }

  getWorkspaceViewFromQueryParams = () => {
    const viewQueryParam = get(getQueryParams(this.props), 'view');

    return find(this.props.workspaceViews, ({ id }) => id === viewQueryParam);
  };

  getActiveWorkspaceView = memoizeOne(({ lastWorkspaceViewForRoute }) => {
    const activeWorkspaceView = this.getWorkspaceViewFromQueryParams() || lastWorkspaceViewForRoute;

    return activeWorkspaceView;
  });

  setWorkspaceViewForRoute = (id) => {
    this.props.setWorkspaceViewForRoute(id);
    if (this.props.controlRoute) {
      const queryParams = qs.stringify({
        ...getQueryParams(this.props),
        view: id,
      });
      navigate(`${this.props.location.pathname}?${queryParams}`); // eslint-disable-line @typescript-eslint/no-floating-promises
    }
  };

  render() {
    const { Child, controlRoute, ...componentProps } = this.props;

    return (
      <Child
        {...componentProps}
        activeWorkspaceView={this.getActiveWorkspaceView(this.props)}
        setWorkspaceViewForRoute={this.setWorkspaceViewForRoute}
      />
    );
  }
}

const withActiveWorkspaceViewForRoute = ({ controlRoute }) =>
  /**
   * This HOC selects and manages the activeWorkspaceView value and the view query param.
   * @param {React.Component} WrappedComponent
   * @returns {React.Component}
   */
  (WrappedComponent) => (componentProps) => {
    return <WithActiveWorkspaceViewForRoute {...componentProps} controlRoute={controlRoute} Child={WrappedComponent} />;
  };

function mapStateToProps(state, { routeName }) {
  return {
    lastWorkspaceViewForRoute: workspaceViewSelector(state, { routeName }),
    getWorkspaceView: workspaceViewSelector(state),
  };
}

function mapDispatchToProps(dispatch, { routeName }) {
  return {
    setWorkspaceViewForRoute: (workspaceViewName) => {
      dispatch(setWorkspaceViewForRoute({ routeName, workspaceViewName }));
    },
  };
}

function getProtectedRouteForCategoryMatch(protectedRoutes, { categorySlug, uri, itemId }) {
  if (!categorySlug || !uri) {
    return {};
  }

  const protectedRoute = find(protectedRoutes, ({ categorySlug: routeCategorySlug, path }) => {
    if (routeCategorySlug === categorySlug) {
      if (!itemId && path.endsWith(routeCategorySlug) && uri.endsWith(routeCategorySlug)) {
        return true;
      }
      if (itemId && path.includes(':itemId') && path.includes(routeCategorySlug) && uri.includes(routeCategorySlug)) {
        return true;
      }
    }
    return false;
  });

  return protectedRoute || find(protectedRoutes, ({ routeName }) => routeName === 'comingSoon');
}

/**
 * This HOC applies the props from the Routes definition file for use elsewhere.
 * Purposefully omitted from being passed down is the RouteComponent prop.
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
const withCategoryRouteProps = (WrappedComponent) => (componentProps) => {
  return (
    <WithRoutes>
      {({ protectedRoutes }) => (
        <Location>
          {({ location }) => (
            <Match path="/category/:categorySlug/*">
              {({ match }) => {
                const route = getProtectedRouteForCategoryMatch(
                  protectedRoutes,
                  match ? { ...match, itemId: match['*'] } : {},
                );
                if (!route) return;
                const { RouteComponent, workspaceViews = [], ...routeProps } = route;

                const workspaceViewsWithPaths = workspaceViews.map((wv) => {
                  const queryParams = qs.stringify({
                    ...getQueryParams(document),
                    view: wv.id,
                  });

                  return {
                    ...wv,
                    path: `${document.location.pathname}?${queryParams}`,
                  };
                });

                return (
                  <WrappedComponent
                    location={location}
                    workspaceViews={workspaceViewsWithPaths}
                    {...componentProps}
                    {...routeProps}
                  />
                );
              }}
            </Match>
          )}
        </Location>
      )}
    </WithRoutes>
  );
};

const withWorkspaceViews = ({ controlRoute = false } = {}) =>
  compose(
    withCategoryRouteProps,
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    withActiveWorkspaceViewForRoute({ controlRoute }),
    withWorkspaceViewsForCategories,
  );

function WithWorkspaceViews({ children, ...rest }) {
  return children({ ...rest });
}

export { withWorkspaceViews };
export default withWorkspaceViews()(WithWorkspaceViews);
