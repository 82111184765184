import React, { useState } from 'react';
import { compose } from 'recompose';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import { Grid, Typography, withStyles } from '@material-ui/core';

import { InputRange } from 'components';

const styles = (theme) => ({
  headingText: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  trueStatusSwitcher: {
    backgroundColor: theme.palette.primary.main,
  },
  falseStatusSwitcher: {
    backgroundColor: '#8f8f8f',
  },
  nullStatusSwitcher: {
    backgroundColor: theme.palette.splitter.background,
  },
});

const mutation = gql`
  mutation updateSwitchValue($input: UpdateVirtualAttributeInput!) {
    updateVirtualAttribute(input: $input) {
      virtualAttribute {
        attrName
        stringValue
      }
    }
  }
`;

const switchValue = {
  '0': 'no',
  '1': null,
  '2': 'yes',
};

function getSwitchKey(val) {
  return Object.keys(switchValue).find((key) => switchValue[key] === val);
}

function ScreenshotRequired({ classes, locked, queryToRefetch, versionId, screenshotRequiredValue }) {
  const [value, setValue] = useState(screenshotRequiredValue);
  const [mutate] = useMutation(mutation);

  const value2class = {
    no: classes.falseStatusSwitcher,
    [null]: classes.nullStatusSwitcher,
    yes: classes.trueStatusSwitcher,
  };
  return (
    <Grid item xs={2} className={classes.container}>
      <Typography variant="body2" component="span" className={classes.headingText}>
        <Trans>
          Screenshot
          <br />
          Required?
        </Trans>
      </Typography>
      {/* replace with TriStateToggle */}
      <InputRange
        min="0"
        max="2"
        refetchQueries={[queryToRefetch]} // FIXME This component doesn't accept a refetchQueries prop
        disabled={locked}
        value={getSwitchKey(value)}
        className={value2class[value]}
        onChange={(event) => {
          const val = switchValue[event.target.value];
          setValue(val);
          mutate({
            variables: {
              input: {
                attrName: 'screenshot_required',
                stringValue: val,
                versionId,
              },
            },
            refetchQueries: queryToRefetch,
          }).catch((e) => {
            throw new Error(`Error ocurred while toggling Screenshot Required (version: ${versionId}) (${e}`);
          });
        }}
      />
    </Grid>
  );
}

ScreenshotRequired.propTypes = {};

ScreenshotRequired.defaultProps = {};

export default compose(
  withI18n(),
  withStyles(styles),
)(ScreenshotRequired);
