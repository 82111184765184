import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';

import { Loading } from 'components';

import { GET_REQUIREMENTS_SET_VERSION } from '../gql';
import { renderVersion, renderTraces, itemsWithUpstreamRiskControlTraces } from './util/TraceUtils';
import { SnackContext } from 'contexts/other/snackContext';

const typeName = 'RequirementVersion';

function RequirementsSetTrace(props) {
  const { showSnack } = useContext(SnackContext);

  const { data, error, loading } = useQuery(GET_REQUIREMENTS_SET_VERSION, {
    variables: { id: props.id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (props.isRiskTrace) {
        const renderItems = itemsWithUpstreamRiskControlTraces(props.graph.edges, typeName);
        if (renderItems.length === 0) {
          showSnack('No requirements set traces found.');
        }
      } else {
        if (data && data.set && data.set.memberVersions && data.set.memberVersions.length === 0) {
          showSnack('No requirements set traces found.');
        }
      }
    },
  });

  if (error) throw new Error(`Failed to load requirements set ${props.id}: ${error.message}`);
  if (loading) return <Loading />;

  if (props.isRiskTrace) {
    const renderItems = itemsWithUpstreamRiskControlTraces(props.graph.edges, typeName);
    return renderTraces(renderItems, props);
  } else {
    return <>{data.set.memberVersions.map((version) => renderVersion(version, props))}</>;
  }
}

export default RequirementsSetTrace;
