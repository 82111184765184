export enum PageType {
  List = 'list',
  SingleRecord = 'record',
}

export function singleRecordRouteName(itemTypeName: string) {
  return `${itemTypeName}:${PageType.SingleRecord}`;
}

export function listViewRouteName(itemTypeName: string) {
  return `${itemTypeName}:${PageType.List}`;
}
