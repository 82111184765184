import React, { FC, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { Box, Checkbox, Typography } from '@material-ui/core';

import SectionTitle from './SectionTitle';
import ChannelTags from './ChannelTags';
import SettingsIcon from '@material-ui/icons/Settings';

import { useStyles } from './styles';
import WithCurrentUser from 'compositions/WithCurrentUser';
import { currentUser_currentUser_notificationPreferences } from 'compositions/__generated__/currentUser';
import { useUpdateNotificationPreference } from './gql';
import { NotificationChannel, NotificationChannelEnum, NotificationPreferencesProps } from '../types';
import { channels, preferences } from '../contants';

const NotificationPreferences: FC<NotificationPreferencesProps> = ({ currentUser }) => {
  const classes = useStyles();
  const [updatePreference] = useUpdateNotificationPreference();
  const [isActive, setIsActive] = useState({
    assigned_task: false,
    assigned_training: false,
    mention: false,
    esignature_required: false,
  });
  const [updatePreferences, setUpdatePreferences] = useState({});

  const handleSave = async (notifPreference: currentUser_currentUser_notificationPreferences) => {
    try {
      if (!isEmpty(updatePreferences[notifPreference.preferenceType])) {
        await updatePreference({
          variables: {
            input: { id: notifPreference.id, updatePreferences: updatePreferences[notifPreference.preferenceType] },
          },
        });
      }
    } catch (error) {
      throw new Error(`Error occured while updating notification preferences ${error}`);
    }
  };

  const handleChange = (
    notifPreference: currentUser_currentUser_notificationPreferences,
    notifKey: string,
    notifChannel: NotificationChannelEnum,
    checked: boolean,
  ) => {
    setUpdatePreferences((prevState) => {
      let notification = prevState[notifPreference.preferenceType];
      let subNotification = notification ? notification[notifKey] : undefined;

      return {
        ...prevState,
        [notifPreference.preferenceType]: {
          ...prevState[notifPreference.preferenceType],
          [notifKey]: subNotification
            ? {
                ...subNotification,
                [notifChannel]: checked,
              }
            : {
                [notifChannel]: checked,
              },
        },
      };
    });
  };

  return (
    <div>
      <Box display="flex" alignItems="center">
        <SettingsIcon className={classes.titleIcon} />
        <Typography variant="h2" className={classes.title}>
          My Notification Preferences
        </Typography>
      </Box>
      <Box>
        {currentUser.notificationPreferences.map((notifPreference) => (
          <div key={notifPreference.id} className={classes.section}>
            <SectionTitle
              icon={preferences[notifPreference.preferenceType].headline.icon}
              title={preferences[notifPreference.preferenceType].headline.title}
              isActive={isActive[notifPreference.preferenceType]}
              onClick={() => setIsActive((prevState) => ({ ...prevState, [notifPreference.preferenceType]: true }))}
              onActionButtonClick={async () => {
                await handleSave(notifPreference);
                setIsActive((prevState) => ({ ...prevState, [notifPreference.preferenceType]: false }));
              }}
            />
            <Box width="60%" mt={1.5}>
              <ChannelTags />
              {Object.entries<NotificationChannel>(notifPreference.preferences).map(([key, value]) => (
                <Box key={`${notifPreference.preferenceType}-${key}`} display="flex" alignItems="center" mt={1}>
                  <Box display="flex" alignItems="center" ml={1} mr={3}>
                    {channels.map((channel) => (
                      <Checkbox
                        key={channel.key}
                        defaultChecked={value[channel.key]}
                        onChange={(_, checked) => handleChange(notifPreference, key, channel.key, checked)}
                        disabled={!isActive[notifPreference.preferenceType]}
                        className={classes.checkbox}
                      />
                    ))}
                  </Box>
                  <Typography variant="subtitle1" className={classes.preferenceLabel}>
                    {preferences[notifPreference.preferenceType].preferenceTitles[key]}
                  </Typography>
                </Box>
              ))}
            </Box>
          </div>
        ))}
      </Box>
    </div>
  );
};

export default WithCurrentUser(NotificationPreferences);
