import React from 'react';

import { Typography, Box, Grid } from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';

import ContactSupport from 'components/ContactSupport';
import Page from 'components/core/Page';

import { useStyles } from './styles';

export interface AccessRestrictedPageProps {
  path: string;
}

const AccessRestrictedPage: React.FC<AccessRestrictedPageProps> = () => {
  const classes = useStyles();
  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.restrictedAccess}>
            <Box>
              <Lock className={classes.icon} />
              <Typography className={classes.heading} variant="h1">
                Access Restricted
              </Typography>
              <Typography className={classes.subHeading} variant="body1">
                You do not have appropriate permissions to access this item
              </Typography>
              <Typography className={classes.text} variant="body2">
                Please contact your system administrator or <ContactSupport /> if you think you have received this
                message in error
              </Typography>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
};

export default AccessRestrictedPage;
