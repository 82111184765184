import React from 'react';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import { plural } from 'pluralize';
import { useQuery, useMutation } from 'react-apollo';
import { Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Flag from '@material-ui/icons/Flag';
import Check from '@material-ui/icons/Check';

import NoReleaseModal from '../NoReleaseModal';
import { Breadcrumbs, Blur, ContentSection, ItemTitle, Spacer, ErrorBoundary, Loading } from 'components';
import { ContextDrawer, ItemControls } from 'compositions';
import { FeatureUnavailable } from 'pages';
import { PayloadFieldsComponent } from 'itemTypes';
import styles from './styles';
import { query, UPDATE_DOCUMENT_VERSION_INPUT_METHOD } from './gql';
import { getAuthToken } from 'utils';

import AttachmentClient from 'utils/AttachmentClient';
import Workflow from './Workflow';

function noCurrentVersion(itemRecord) {
  // If an Item is canceled, voided, or about to be retired, it has no "working version"
  // but it has been "created" and we want to display the workflow diagram
  // on the builder page so we have this check 🤯
  // The fix is to make BuilderView Item Type and/or Workflow specific
  if (
    itemRecord.currentVersion.currentStatusName === 'cancel' ||
    itemRecord.currentVersion.currentStatusName === 'canceled' ||
    itemRecord.currentVersion.currentStatusName === 'void' ||
    itemRecord.currentVersion.currentStatusName === 'void_approve_to_proceed' ||
    itemRecord.currentVersion.currentStatusName === 'retirement_initiated' ||
    itemRecord.currentVersion.currentStatusName === 'retirement_approved' ||
    itemRecord.currentVersion.currentStatusName === 'supplier_approved' ||
    itemRecord.currentVersion.currentStatusName === 'qualification_revoked' ||
    itemRecord.currentVersion.currentStatusName === 'qualification_rejected'
  )
    return false;

  return !get(itemRecord, 'workingVersion.id');
}

export function showPDFExport(version, type) {
  enum ITEM_TYPE {
    'changeOrder',
    'deviation',
    'finalDeviceInspectionRecord',
    'formRecord',
    'incomingQualityInspectionRecord',
    'inProcessInspectionRecord',
    'riskAnalysis',
    'riskControl',
    'risk',
    'supplierQuestionnaireRecord',
    'traceMatrix',
    'validationRecord',
    'verificationAndValidationPlan',
    'verificationAndValidationReport',
    'verificationRecord',
    'requirementsSet',
    'userNeedsSet',
    'useEnvironmentSpecification',
    'userGroupSpecification',
    'patientPopulationProfile',
  }

  const isValid = (type: string): boolean => {
    return type in ITEM_TYPE;
  };

  return 'body' in version || isValid(type); // Check itemType for export availability
}

export function deleteAttachment(versionId, attachmentId) {
  const [token] = getAuthToken();
  new AttachmentClient(token).delete(versionId, attachmentId).catch((error) => {
    throw new Error(`Deletion of attachment failed: ${error.message}`);
  });
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface BuilderViewProps {
  classes;
  itemRecord?;
  itemId: string;
  itemType: string;
  PayloadFields: PayloadFieldsComponent;
  categorySlug: string;
  hideNotes?: boolean;
}
function BuilderView({
  categorySlug,
  classes,
  itemRecord: itemRecordFromProps,
  itemId,
  itemType,
  PayloadFields,
  hideNotes,
}: BuilderViewProps) {
  const showNotes = !hideNotes;
  const doLocalQuery = !Boolean(itemRecordFromProps); // we can be passed data, or do our own query

  const [updateDocumentVersionInputMethod] = useMutation(UPDATE_DOCUMENT_VERSION_INPUT_METHOD);

  const matchesPrint = useMediaQuery('print');
  const { data, loading, error } = useQuery(query, {
    skip: !doLocalQuery,
    variables: {
      itemId,
      itemType: snakeCase(itemType),
    },
  });

  if (matchesPrint) return null;
  if (loading) return <Loading />;
  if (error) throw error;
  const itemRecord = itemRecordFromProps || data['item'];
  const readOnly = get(itemRecord, 'currentUser.readOnly', true);
  if (readOnly) return <FeatureUnavailable />;

  const { currentVersion } = itemRecord;
  const itemTitle = get(itemRecord, 'currentVersion.title', '');
  const titlePrefix = `${get(itemRecord, 'customIdentifier', '')}`;

  const queryNameToRefetch = doLocalQuery ? 'BuilderViewQuery' : `${itemType}Query`;
  /*
    If we've been passed data, pass version data along to PayloadFields.
    otherwise, just pass the version id and let PayloadFields do its own
    query.
  */
  const versionProp = doLocalQuery
    ? { versionId: itemRecord.currentVersion.id }
    : { versionData: itemRecord.currentVersion };

  /*
    version 3 of Material UI has a defect which forces scroll to be disabled if
    any modal is active.  V4 includes a "disableScrollLock" prop for this:
    https://material-ui.com/api/modal/#props
  */
  if (noCurrentVersion(itemRecord)) window.scroll(0, 0);

  const versions = get(itemRecord, 'versions', []).filter((r) => r.releasedAt !== null);
  const retiredWithNoReleases = currentVersion.currentStatusName === 'retired' && versions.length === 0;

  const builderViewDefaultMessage = (
    <Trans>
      This item has no in-progress version. Select Effective View from the view menu for the current effective view of
      this item.
    </Trans>
  );

  const retiredNoReleaseMessage = <Trans>This item has been retired and does not have any previous releases.</Trans>;

  const titleLocked = get(currentVersion, 'locked');

  const itemTypesWithUneditableTitles = ['risk'];
  // FIXME: this was a quickfix in the interest of time. Typically, item types that need custom usage of the ItemTitle component should
  // be broken out into having its own BuilderView

  return (
    <Grid container data-testid="item" spacing={3} className={classes.container}>
      <Grid item xs={12} className={classes.grid}>
        <Breadcrumbs categorySlug={categorySlug} itemTitle={`${titlePrefix}: ${itemTitle}`} />
        <Grid container item xs={8} justify="space-between">
          <Blur blur={noCurrentVersion(itemRecord)} container={false}>
            <ItemTitle
              editable={!titleLocked && !itemTypesWithUneditableTitles.includes(itemType)}
              itemTitle={itemTitle}
              titlePrefix={titlePrefix}
              versionId={get(currentVersion, 'id')}
              refetchQueries={[queryNameToRefetch]}
              displayTitle={!itemTypesWithUneditableTitles.includes(itemType)}
            />
          </Blur>

          <ItemControls
            showPDFExport={showPDFExport(itemRecord.currentVersion, itemType)}
            pdfExportHeader={`${itemRecord.customIdentifier}: ${itemRecord.currentVersion.title}`}
            peopleProps={{
              approver: currentVersion.approver,
              creator: currentVersion.creator,
              createdAt: currentVersion.createdAt,
              owner: currentVersion.owner,
              permissibleApprovers: currentVersion.permissibleApprovers,
              permissibleOwners: currentVersion.permissibleOwners,
              locked: currentVersion.locked,
              ownerLocked: currentVersion.__typename === 'TrainingRecordVersion',
              versionId: currentVersion.id,
              version: currentVersion,
              workflow: get(currentVersion, 'item.itemType.workflow.name', null),
              setItemCustomIdentifier: get(currentVersion, 'setMember.setItemVersion.item.customIdentifier', null),
              refetchQueries: [queryNameToRefetch],
            }}
            tagsProps={{
              itemId: itemRecord.id,
              tags: itemRecord.tags,
              refetchQueries: [queryNameToRefetch, `${plural(itemType)}Query`, 'tagsQuery'],
            }}
            ellipsesProps={{
              referenceId: itemRecord.referenceId,
              versionId: itemRecord.currentVersion.id,
              itemId: itemRecord.id,
              itemType: itemType,
              inputMethod: itemRecord.currentVersion.inputMethod,
              attachment: get(itemRecord, 'currentVersion.attachment.filename'),
              locked: currentVersion.locked,
              refetchQueries: [queryNameToRefetch],
              onInputMethodChange: (inputMethod) => {
                updateDocumentVersionInputMethod({
                  variables: {
                    versionId: itemRecord.currentVersion.id,
                    inputMethod: inputMethod,
                  },
                  refetchQueries: [queryNameToRefetch],
                }).catch((err) => {
                  throw new Error(`Error updating input method: ${err.message}`);
                });
              },
              onPDFDelete: () => {
                const attachmentId = get(itemRecord, 'currentVersion.attachment.id');

                if (attachmentId) deleteAttachment(itemRecord.currentVersion.id, attachmentId);
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container item xs={8} className={classes.grid}>
        <Grid item xs={showNotes ? 8 : 12} className={classes.gridGutterRight}>
          <ErrorBoundary>
            <Workflow
              status={itemRecord.currentVersion.currentStatusName}
              key={itemRecord.currentVersion.currentStatusName}
              workflow={get(itemRecord, 'itemType.workflow.name')}
              itemId={itemRecord.id}
              refetchQueries={[
                queryNameToRefetch,
                `${itemType}Query`,
                `${capitalizeFirstLetter(itemType)}VersionQuery`,
              ]}
              itemType={itemType}
              itemData={itemRecord}
            />
          </ErrorBoundary>
        </Grid>

        {showNotes && (
          <Grid item xs={4} className={classes.gridGutterLeft}>
            <ContentSection LabelText={<Trans>Item Notes</Trans>} Icon={<Flag className={classes.icon} />}>
              <Spacer factor={1} />
              <div className={classes.itemNotes}>
                <Spacer factor={3} />
                <Typography variant="body2" className={classes.iconTextStack}>
                  <Check className={classes.icon} />
                  <Trans>No new updates</Trans>
                </Typography>
              </div>
            </ContentSection>
          </Grid>
        )}
      </Grid>

      {PayloadFields && (
        <Blur blur={noCurrentVersion(itemRecord)}>
          <I18n>
            {({ i18n }) => (
              <PayloadFields
                layoutClasses={classes}
                i18n={i18n}
                itemRecord={itemRecord}
                itemType={itemType}
                {...versionProp}
                displayOnly={get(itemRecord, 'currentVersion.locked')}
              />
            )}
          </I18n>
        </Blur>
      )}

      <NoReleaseModal
        open={noCurrentVersion(itemRecord)}
        modalTopPosition="50%"
        viewName="Builder View"
        message={!retiredWithNoReleases ? builderViewDefaultMessage : retiredNoReleaseMessage}
        showLink={false}
      />
      <ContextDrawer itemId={itemId || itemRecord.id} itemType={itemType} />
    </Grid>
  );
}

export default withStyles(styles)(BuilderView);
