import React from 'react';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';

import { Grid } from '@material-ui/core';

// For determining the category whitelist. Needed for the QuickTracePopover
import displaySlugs from 'displaySlugs';
import { CATEGORY_INDEX } from 'categories';

import { withItemTypeMetadata } from 'utils/withItemTypeMetadata';
import { ConfirmationDialog } from 'components';

import withAddVersionTrace from './withAddVersionTrace';
import withDeleteVersionTrace from './withDeleteVersionTrace';
import withUpdateVersionTrace from './withUpdateVersionTrace';

import CurrentPosition from 'components/MatrixGraph/CurrentPosition';

import Header from './Header';
import { QuickSearchPopover } from 'components';
import { TraceMatrixCardContextProvider } from 'contexts/traceMatrixCardContext';

// I think this can be added to Header if we always want select field for all traces ever
import RootSelect, {
  REQUIREMENTS_OPTION,
  DEVICE_VERIFICATION_PROTOCOLS_OPTION,
  DEVICE_VALIDATION_PROTOCOLS_OPTION,
  RISK_OPTION,
  RISK_CONTROL_OPTION,
} from './RootSelect';

import RequirementsSetTrace from './RequirementsSetTrace';
import UserNeedsSetTrace from './UserNeedsSetTrace';
import DeviceValidationProtocolsTrace from './DeviceValidationProtocolsTrace';
import DeviceVerificationProtocolsTrace from './DeviceVerificationProtocolsTrace';
import RiskTrace from './RiskTrace';
import RiskControlTrace from './RiskControlTrace';

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPosition: null,
      offset: 0,
      quickTraceAnchor: {},
      showDeleteModal: false,
      selectedTrace: null,
      excludedIds: [],
      selectedTraceRoot: null,
      selectedTraceRootType: props.isRiskTrace ? RISK_OPTION : REQUIREMENTS_OPTION,
      selectedVersion: null,
      isRiskTrace: props.isRiskTrace,
      userNeedsSetVersionId: props.userNeedsSetVersionId,
      requirementsSetVersionId: props.requirementsSetVersionId,
      riskAnalysisVersionId: props.riskAnalysisVersionId,
      excludedCurrentStatuses: [
        'canceled',
        'retired',
        'void',
        'obsolete',
        'retirement_initiated',
        'retirement_rejected',
      ],
    };
  }
  componentDidUpdate(prevProps) {
    const { userNeedsSetVersionId, requirementsSetVersionId, riskAnalysisVersionId, isRiskTrace } = this.props;

    if (
      prevProps.userNeedsSetVersionId !== userNeedsSetVersionId ||
      prevProps.requirementsSetVersionId !== requirementsSetVersionId ||
      prevProps.riskAnalysisVersionId !== riskAnalysisVersionId ||
      prevProps.isRiskTrace !== isRiskTrace
    ) {
      let newState = {
        userNeedsSetVersionId,
        requirementsSetVersionId,
        riskAnalysisVersionId,
        isRiskTrace,
        selectedTraceRootType: isRiskTrace ? RISK_OPTION : REQUIREMENTS_OPTION,
      };
      this.setState(newState);
    }
  }

  findLinkedTracesIds = (version) => {
    const id = version.item.id;
    const traces = this.props.graph.graph;
    let excludedIds = [];

    let trace;
    for (let traceId in traces) {
      if (traces[traceId].object.item.id === id) {
        trace = traces[traceId];
        break;
      }
    }

    if (trace) {
      const upstreamTraces = trace.upstream || [];
      const downstreamTraces = trace.downstream || [];
      const allRelevantTraces = upstreamTraces.concat(downstreamTraces);

      excludedIds = allRelevantTraces.map((t) => t.object.item.id);
    }

    this.setState({ excludedIds: excludedIds });
  };

  itemTypeExistsInAllowedTraceTypes = (itemType) => {
    const itemDisplaySlugSuperSet = [
      'requirement',
      'user_need',
      'device_validation',
      'device_verification',
      'risk_control',
      'risk',
    ];

    return itemDisplaySlugSuperSet.some((t) => t === itemType);
  };

  categoryWhitelist = (itemTypes) => {
    const whitelist = {};
    const filteredItemList = itemTypes.filter((itemType) => {
      return this.itemTypeExistsInAllowedTraceTypes(itemType);
    });

    filteredItemList.forEach((t) => {
      const displaySlug = displaySlugs[camelCase(t)];
      if (displaySlug) {
        whitelist[displaySlug] = true;
        const parentSlug = get(CATEGORY_INDEX, [displaySlug, 'parentSlug']);
        if (parentSlug) whitelist[parentSlug] = true;
      }
    });
    return whitelist;
  };

  createTraces = (traces) => {
    Promise.all(
      traces.map((trace) =>
        this.props.addVersionTrace({
          traceMatrixVersionId: this.props.currentVersion.id,
          upstreamVersionId: trace.upstreamVersionId,
          downstreamVersionId: trace.downstreamVersionId,
        }),
      ),
    )
      .then((results) =>
        results.forEach((result) => {
          this.props.onTraceAdd(result.data.addTraceToTraceMatrixVersion.versionTrace);
        }),
      )
      .catch((error) => {
        throw new Error(`Failed to create a trace: ${error.message}`);
      });
  };

  handleTraceChanged = (trace) => {
    this.setState({ selectedTrace: trace });
  };

  handleVersionClicked = (version, traceRoot) => {
    this.setState({ selectedVersion: version, selectedTraceRoot: traceRoot });
  };

  handleDeleteTraceClicked = (e) => {
    this.setState({ showDeleteModal: true });
  };

  handleConfirmDeleteTraceClicked = (e) => {
    this.setState({ showDeleteModal: false });

    this.props
      .deleteVersionTrace({ id: this.state.selectedTrace.id })
      .then((result) => this.props.onTraceDelete(this.state.selectedTrace))
      .catch((error) => {
        throw new Error(`Failed to delete trace: ${error.message}`);
      });
  };

  handleCancelDeleteTraceClicked = (e) => {
    this.setState({ showDeleteModal: false });
  };

  handleUpdateTraceClicked = (oldVersionId, newVersionId) => {
    this.props
      .updateVersionTrace({
        traceMatrixVersionId: this.props.currentVersion.id,
        oldTracedVersionId: oldVersionId,
        newTracedVersionId: newVersionId,
      })
      .then((result) => this.props.onTraceUpdate(result.data.updateTraceMatrixVersionVersionTrace.versionTraces))
      .catch((error) => {
        throw new Error(`Failed to update traces: ${error.message}`);
      });
  };

  handleAddDownstreamTraceClicked = (e, version) => {
    this.findLinkedTracesIds(version);
    const handler = (selected) =>
      this.createTraces(selected.map((id) => ({ upstreamVersionId: version.id, downstreamVersionId: id })));
    let traceableDownstreamTypes;
    if (this.state.isRiskTrace === true) {
      traceableDownstreamTypes =
        get(this.props.allItemTypeMetadata[version.item.itemType.name], 'traceableRiskDownstreamTypes', []) || [];
    } else {
      traceableDownstreamTypes =
        get(this.props.allItemTypeMetadata[version.item.itemType.name], 'traceableDesignDownstreamTypes', []) || [];
    }
    this.setState({
      addTraceDirection: 'downstream',
      handler: handler,
      categoryWhitelist: this.categoryWhitelist(traceableDownstreamTypes),
      quickTraceAnchor: { anchorEl: e.currentTarget },
      selectedVersion: version,
    });
  };

  handleAddUpstreamTraceClicked = (e, version) => {
    this.findLinkedTracesIds(version);

    const handler = (ids) =>
      this.createTraces(ids.map((id) => ({ upstreamVersionId: id, downstreamVersionId: version.id })));
    let traceableUpstreamTypes;
    if (this.state.isRiskTrace === true) {
      traceableUpstreamTypes =
        get(this.props.allItemTypeMetadata[version.item.itemType.name], 'traceableRiskUpstreamTypes', []) || [];
    } else {
      traceableUpstreamTypes =
        get(this.props.allItemTypeMetadata[version.item.itemType.name], 'traceableDesignUpstreamTypes', []) || [];
    }
    this.setState({
      addTraceDirection: 'upstream',
      handler: handler,
      categoryWhitelist: this.categoryWhitelist(traceableUpstreamTypes),
      quickTraceAnchor: { anchorEl: e.currentTarget },
      selectedVersion: version,
    });
  };

  handleTraceRootTypeSelected = (e) => {
    this.setState({ selectedTraceRootType: e.target.value });
  };

  renderGraphForTraceRoot = (currentPosition) => {
    const props = {
      graph: this.props.graph,
      disabled: !!this.props.disableTracing,
      currentPosition: currentPosition,
      isRiskTrace: this.state.isRiskTrace,
      onAddUpstreamTrace: this.handleAddUpstreamTraceClicked,
      onAddDownstreamTrace: this.handleAddDownstreamTraceClicked,
      onUpdateTrace: this.handleUpdateTraceClicked,
      onTraceChange: this.handleTraceChanged,
      onVersionClick: this.handleVersionClicked,
    };
    if (this.state.isRiskTrace) {
      switch (this.state.selectedTraceRootType) {
        case DEVICE_VALIDATION_PROTOCOLS_OPTION:
          return <DeviceValidationProtocolsTrace {...props} onlyItemsInGraph={true} />;
        case DEVICE_VERIFICATION_PROTOCOLS_OPTION:
          return <DeviceVerificationProtocolsTrace {...props} onlyItemsInGraph={true} />;
        case REQUIREMENTS_OPTION:
          return (
            this.props.requirementsSetVersionId && (
              <RequirementsSetTrace {...props} id={this.props.requirementsSetVersionId} />
            )
          );
        case RISK_OPTION:
          return this.props.riskAnalysisVersionId ? (
            <RiskTrace {...props} id={this.props.riskAnalysisVersionId} />
          ) : null;
        case RISK_CONTROL_OPTION:
          return this.props.riskAnalysisVersionId ? (
            <RiskControlTrace {...props} id={this.props.riskAnalysisVersionId} />
          ) : null;
        default:
          return null;
      }
    } else {
      switch (this.state.selectedTraceRootType) {
        case DEVICE_VALIDATION_PROTOCOLS_OPTION:
          return <DeviceValidationProtocolsTrace {...props} onlyItemsInGraph={true} />;
        case DEVICE_VERIFICATION_PROTOCOLS_OPTION:
          return <DeviceVerificationProtocolsTrace {...props} onlyItemsInGraph={true} />;
        case REQUIREMENTS_OPTION:
          return (
            this.props.requirementsSetVersionId && (
              <RequirementsSetTrace {...props} id={this.props.requirementsSetVersionId} />
            )
          );
        default:
          return (
            this.props.userNeedsSetVersionId && <UserNeedsSetTrace {...props} id={this.props.userNeedsSetVersionId} />
          );
      }
    }
  };

  render() {
    const currentPosition = new CurrentPosition(this.state.offset, {
      root: this.state.selectedTraceRoot,
      trace: this.state.selectedTrace,
      object: this.state.selectedVersion,
    });

    const resetTraceState = {
      addTraceDirection: null,
      categoryWhitelist: null,
    };

    const traceQuickTraceAnchorOpen = Boolean(this.state.quickTraceAnchor.anchorEl);
    return (
      <>
        <TraceMatrixCardContextProvider>
          <Grid
            className={this.props.layoutClasses.gridGutterRight}
            item
            xs={8}
            style={{ position: 'sticky', top: '60px', zIndex: 2, marginTop: '10px' }}
          >
            <Header
              currentPosition={currentPosition}
              onDeleteTraceClick={
                !this.props.disableTracing && this.state.selectedTrace ? this.handleDeleteTraceClicked : undefined
              }
              onNavigateUpstreamClick={() => this.setState((state) => ({ offset: state.offset + 1 }))}
              onNavigateDownstreamClick={() => this.setState((state) => ({ offset: state.offset - 1 }))}
              root={
                !currentPosition.rootIsOnScreen ? (
                  undefined
                ) : (
                  <RootSelect
                    value={this.state.selectedTraceRootType}
                    onChange={this.handleTraceRootTypeSelected}
                    isRiskTrace={this.state.isRiskTrace}
                  />
                )
              }
            />
          </Grid>
          <Grid className={this.props.layoutClasses.gridGutterRight} item xs={8} style={{ maxWidth: 1280 }}>
            {this.renderGraphForTraceRoot(currentPosition)}
          </Grid>

          {this.state.selectedTrace !== null && (
            <ConfirmationDialog
              onCancel={this.handleCancelDeleteTraceClicked}
              onClose={this.handleCancelDeleteTraceClicked}
              onConfirm={this.handleConfirmDeleteTraceClicked}
              open={this.state.showDeleteModal}
              title="Confirm Removal"
            >
              <p>
                Are you sure you want to remove the trace between{' '}
                {get(this.state.selectedTrace, 'upstreamVersion.item.customIdentifier')} and{' '}
                {get(this.state.selectedTrace, 'downstreamVersion.item.customIdentifier')}
              </p>
            </ConfirmationDialog>
          )}

          {this.state.selectedVersion !== null && (
            <QuickSearchPopover
              closeSelf={() => {
                this.setState({
                  quickTraceAnchor: { anchorEl: null },
                  ...resetTraceState,
                });
              }}
              open={traceQuickTraceAnchorOpen}
              onAdvancedSearchClick={() =>
                this.setState({
                  advancedSearchModalOpen: true,
                  quickTraceAnchor: { anchorEl: null },
                })
              }
              onSelected={(selected) => {
                this.state.handler(
                  selected.map((selection) => {
                    return selection.currentVersionId;
                  }),
                );
              }}
              direction={this.state.addTraceDirection}
              categoryWhitelist={this.state.categoryWhitelist}
              anchorElement={this.state.quickTraceAnchor.anchorEl}
              selectedItemId={this.state.selectedVersion.item.id}
              traceMatrixId={this.props.currentVersion.id}
              isRiskTrace={this.state.isRiskTrace}
              baseItemType={this.state.selectedVersion.item.itemType.name}
              showFullItewlist={false}
              excludedIds={this.state.excludedIds}
              baseRecordId={this.state.selectedVersion.item.id}
              excludedCurrentStatuses={this.state.excludedCurrentStatuses}
            />
          )}
        </TraceMatrixCardContextProvider>
      </>
    );
  }
}

export default flowRight(
  withAddVersionTrace,
  withDeleteVersionTrace,
  withUpdateVersionTrace,
  withItemTypeMetadata,
)(Container);
