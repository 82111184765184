import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import slugs from '../../displaySlugs';

import { listFragment, docMetadata } from '../mixins/Document';
const itemType = 'corporateQualityPlan';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Corporate Quality Plan`,
  categoryLabel: t`Corporate Quality Plans`,
  icon: LibraryBooks,
  listViewQuery: gql`
    query corporateQualityPlansQuery {
      documents(itemType: "corporate_quality_plan") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
