import React from 'react';

import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { AuditTrailTransition } from '../../index';
import useStyles from '../../styles';

type StatusTransitionProps = Pick<
  AuditTrailTransition,
  'versionIdentifier' | 'statusTransition' | 'recordOrigin' | 'linkedChangeCustomIdentifier'
>;

export default function StatusTransition(props: StatusTransitionProps) {
  const classes = useStyles();
  const { statusTransition, recordOrigin, linkedChangeCustomIdentifier, versionIdentifier } = props;

  const fromStatus = get(statusTransition, 'fromStatus');
  const toStatus = get(statusTransition, 'toStatus');
  const reasonForSignature = get(statusTransition, 'reasonForSignature');
  const parentSetTransition = get(statusTransition, 'parentSetTransition');
  const reasonForRevocation = get(statusTransition, 'reasonForRevocation');
  const reasonForSupplierRejection = get(statusTransition, 'reasonForSupplierRejection');
  const recordOriginText = recordOrigin && 'with the following Reason for Origin: ' + recordOrigin;

  return (
    <>
      {versionIdentifier} Transition from {fromStatus} to {toStatus} {recordOriginText}
      {parentSetTransition && (
        <span>
          via {get(parentSetTransition, 'customIdentifier')} {get(parentSetTransition, 'versionIdentifier')} Transition
          from {get(parentSetTransition, 'fromStatus')} to {get(parentSetTransition, 'toStatus')}
        </span>
      )}
      {reasonForSignature && (
        <div className={classes.reasonForSignature}>
          <Trans>Reason for signature:</Trans> {reasonForSignature}
        </div>
      )}
      {reasonForSupplierRejection && (
        <div className={classes.reasonForSignature}>
          <Trans>Reason for Rejection:</Trans> {reasonForSupplierRejection}
        </div>
      )}
      {reasonForRevocation && (
        <div className={classes.reasonForSignature}>
          <Trans>Reason for Revocation:</Trans> {reasonForRevocation}
        </div>
      )}
      {linkedChangeCustomIdentifier && (
        <span>via {linkedChangeCustomIdentifier} Transition from Ready for Closure to Ready for Training</span>
      )}
      {fromStatus === 'Released For Co Training' && toStatus === 'Approved Draft' && (
        <span>
          via {linkedChangeCustomIdentifier} Transition from Ready for Training to Ready for Closure via the void action
        </span>
      )}
    </>
  );
}
