import React from 'react';

import { Grid } from '@material-ui/core';
import flowRight from 'lodash/flowRight';
import { useQuery } from 'react-apollo';

import { Breadcrumbs } from 'components';
import Page from 'components/core/Page';
import withCurrentUser from 'compositions/WithCurrentUser';

import EmployeeProfile from './EmployeeProfile';
import EmployeeInformation from './EmployeeInformation';
import { PROFILE_QUERY } from './gql';
import Training from './Training';

function IdPage({ i18n, currentUser, ...props }) {
  const { data, loading, error } = useQuery(PROFILE_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: props.id },
  });

  if (loading) return null;
  if (error) throw new Error(`Error loading employee record: ${error}`);

  const profile = data.profile;
  const isTrainingManager = currentUser.jobRoles.some((role) => role.roleName === 'training_manager');

  return (
    <Page>
      <Grid item xs={12}>
        <Breadcrumbs categorySlug="employees" itemTitle={`@${profile.user.username}`} />
      </Grid>
      <Grid item xs={3}>
        <EmployeeProfile profile={profile} editable={isTrainingManager} />
      </Grid>
      <Grid item xs={7}>
        <EmployeeInformation profile={profile} editable={isTrainingManager} />
      </Grid>
      <Grid item xs={12}>
        <Training trainingRecordVersions={profile.user.employee.trainingRecordVersions} />
      </Grid>
    </Page>
  );
}

export default flowRight([withCurrentUser])(IdPage);
