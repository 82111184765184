import React, { useState } from 'react';
import { LineStyle } from '@material-ui/icons';

import { ConfirmationDialog, IconButton } from 'components';

interface Props {
  richTextEditorVisible: boolean;
  onConfirm: () => void;
}

function ToggleRichTextEditor(props: Props) {
  const warningPrefix = 'By changing editor style, you will lose any added content in this';
  const [showConfirm, setShowConfirm] = useState(false);
  const close = () => setShowConfirm(false);

  return (
    <>
      <IconButton
        tooltip={props.richTextEditorVisible ? 'Switch to Guided Editor' : 'Switch to Open Text Editor'}
        Icon={LineStyle}
        onClick={() => setShowConfirm(true)}
      />
      <ConfirmationDialog
        onCancel={close}
        title="Confirm Change"
        open={showConfirm}
        onConfirm={() => {
          close();
          props.onConfirm();
        }}
        onClose={close}
      >
        {props.richTextEditorVisible ? `${warningPrefix} item` : `${warningPrefix} tab`}.
      </ConfirmationDialog>
    </>
  );
}

export default ToggleRichTextEditor;
