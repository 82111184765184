import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { Error, WarningOutlined, People } from '@material-ui/icons';

import AlertDialog from '../AlertDialog';
import { UserListItem } from 'components';

interface Props {
  error?: string;
  onClose: () => void;
  open: boolean;
  titleText?: string;
  subtitle?: string;
  user: {
    id: string;
    fullName: string;
  };
  body?: string;
  role: {
    id: string;
    formattedRoleName: string;
  };
}

function AlertDialogWithUsers({ onClose, user, role, body, ...rest }: Props) {
  return (
    <AlertDialog onClose={onClose} icon={People} secondaryIcon={Error} {...rest}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2">{body}</Typography>
        </Grid>

        <div style={{ border: '1px solid #ecedee', width: '100%', margin: '8px' }}>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              component="div"
              style={{
                display: 'flex',
                alignItems: 'center',
                background: '#F7F7F7',
                color: '#6E7583',
                fontSize: '13px',
                fontWeight: 600,
                lineHeight: '20px',
                textTransform: 'capitalize',
                paddingLeft: '12px',
              }}
            >
              <WarningOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
              <span style={{ padding: '8px' }}>deactivated</span>
            </Typography>
            <div style={{ backgroundColor: '#fcfcfc', padding: '4px 8px 4px 8px' }}>
              {user && role && <UserListItem userId={user.id} name={user.fullName} title={role.formattedRoleName} />}
            </div>
          </Grid>
        </div>

        <Grid item xs={12}>
          <Button fullWidth disableElevation variant="contained" color="primary" onClick={onClose}>
            OK
          </Button>
        </Grid>
      </Grid>
    </AlertDialog>
  );
}

export default AlertDialogWithUsers;
