import React from 'react';

import { Grid } from '@material-ui/core';

import TableTrio from 'components/TableTrio';
import {
  useAddReferencedAttachment,
  useAddReferencedItem,
  useAddReferencedVersion,
  useRemoveReferencedAttachment,
  useRemoveReferencedItem,
  useRemoveReferencedVersion,
  useUpdateReferencedAttachment,
  useUpdateReferencedVersion,
} from 'components/TableTrio/gql';

const queryNameToRefetch = 'TaskQuery';

interface Props {
  version: any;
}

const TableTrioSection = (props: Props) => {
  const { version } = props;

  const createAttachment = useAddReferencedAttachment();
  const updateAttachment = useUpdateReferencedAttachment();
  const destroyAttachment = useRemoveReferencedAttachment();

  const addAssociatedItem = useAddReferencedItem();
  const removeAssociatedItem = useRemoveReferencedItem();

  const addAssociatedItemVersion = useAddReferencedVersion();
  const removeAssociatedItemVersion = useRemoveReferencedVersion();
  const updateAssociatedItemVersion = useUpdateReferencedVersion();

  return (
    <Grid xs={12} style={{ padding: '20px 0' }}>
      <TableTrio
        currentVersion={version}
        locked={version.locked}
        attachmentsTableProps={{
          handlers: {
            create: (versionId, attachment) => {
              return createAttachment({
                refetchQueries: [queryNameToRefetch],
                variables: { versionId, attachment },
              });
            },
            update: (attachmentId, attachment) => {
              return updateAttachment({
                refetchQueries: [queryNameToRefetch],
                variables: {
                  id: attachmentId,
                  attachment: attachment.url,
                  description: attachment.description,
                },
              });
            },
            destroy: (attachmentId) => {
              return destroyAttachment({
                refetchQueries: [queryNameToRefetch],
                variables: { id: attachmentId },
              });
            },
          },
        }}
        itemsTableProps={{
          handlers: {
            remove: (id: string) => {
              return removeAssociatedItem({
                refetchQueries: [queryNameToRefetch],
                variables: {
                  versionId: version.id,
                  referencedItemId: id,
                },
              });
            },
            addAssociatedItem: (id: string) => {
              return addAssociatedItem({
                refetchQueries: [queryNameToRefetch],
                variables: {
                  versionId: version.id,
                  referencedItemIds: id,
                },
              });
            },
          },
        }}
        versionsTableProps={{
          handlers: {
            removeAssociatedItemVersion: (id: string) => {
              return removeAssociatedItemVersion({
                refetchQueries: [queryNameToRefetch],
                variables: {
                  versionId: version.id,
                  referencedVersionId: id,
                },
              });
            },
            updateAssociatedItemVersion: (next, old) => {
              return updateAssociatedItemVersion({
                refetchQueries: [queryNameToRefetch],
                variables: {
                  nextVersionId: next,
                  oldVersionId: old,
                  versionId: version.id,
                },
              });
            },
            addAssociatedItemVersion: (versionId: string) => {
              return addAssociatedItemVersion({
                refetchQueries: [queryNameToRefetch],
                variables: {
                  versionId: version.id,
                  referencedVersionIds: versionId,
                },
              });
            },
          },
        }}
      />
    </Grid>
  );
};

export default TableTrioSection;
