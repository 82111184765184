import gql from 'graphql-tag';

const CREATE_FORM_RECORD = gql`
  mutation createFormRecord($input: CreateFormRecordInput!) {
    createFormRecord(input: $input) {
      item {
        id
        customIdentifier
      }
    }
  }
`;

export { CREATE_FORM_RECORD };
