import React from 'react';

import { Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import get from 'lodash/get';
import { useQuery } from 'react-apollo';

import { USER_MULTI_SELECT_QUERY } from './gql';
import { useStyles } from './styles';
import UserOption from './UserOption';
import { UserMultiSelect_users } from './__generated__/UserMultiSelect';

interface Props {
  disabled?: boolean;
  onChange: (selections: UserMultiSelect_users[]) => void;
  placeholder?: string;
  valueIds?: string[];
}

function UserMultiSelect(props: Props) {
  const { data, loading, error } = useQuery(USER_MULTI_SELECT_QUERY);
  const classes = useStyles();

  if (error) {
    throw new Error(`Error loading users: ${error.message}`);
  }

  const options = get(data, 'users', []).filter((user) => user.status !== 'inactive') as UserMultiSelect_users[];
  const { disabled = false, onChange, placeholder = 'Select employee(s)', valueIds } = props;
  const value = valueIds ? options.filter((x) => valueIds.includes(x.id)) : undefined;

  return (
    <Autocomplete
      multiple
      className={classes.chipsContainer}
      classes={{ inputRoot: classes.autocompleteField }}
      disabled={disabled}
      size="small"
      options={options}
      disableClearable
      filterSelectedOptions
      renderTags={(value, getTagProps) =>
        loading ? (
          <div>Loading</div>
        ) : (
          value.map((option, index) => (
            <Chip label={option.fullName} size="small" {...getTagProps({ index })} className={classes.userChips} />
          ))
        )
      }
      renderInput={(params) => (
        <TextField {...params} fullWidth variant="outlined" margin="none" placeholder={placeholder} />
      )}
      renderOption={(option) => <UserOption user={option} />}
      getOptionLabel={(option) => option.fullName}
      onChange={(_, selections) => onChange(selections)}
      value={value}
    />
  );
}

export default UserMultiSelect;
