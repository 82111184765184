import React, { useEffect, useRef, useState } from 'react';

import AgGridTable from 'components/AgGridTable';
import { MakeLink, VersionAndStatusDot } from 'components';
import VersionToggleField from 'components/VersionToggleField';
import { isColumnGroupedBy } from 'utils/agGridHelpers';
import SelectFolder from 'components/SelectFolder';
import RequirementLongCard from './RequirementLongCard';
import { workingVersionSelected } from 'components/VersionSwitch';
import { Views } from 'workspaces';
import { options } from '../Requirement/picklist';
import { updateMember, switchVersion as switchRequirementsVersion } from './gql';
import { GenericWorkflowStatuses } from 'workflows/statuses';
import displaySlugs from 'displaySlugs';
import {
  changeSortPositionsBy,
  filterFolders,
  SetChild,
  sortGroupedBy,
  sortSetItems,
  requirementTypesKeyCreator,
  undefinedKey,
  requirementToSetChild,
  combineItemGroups,
} from './util';
import { cloneDeep, get, isEqual } from 'lodash';

interface TableContainerProps {
  data: any;
  DisplayVariantSelect: any;
  activeWorkspaceView: any;
  handleColumnRowGroupChanged: any;
  isCustomGrouping: boolean;
  isGroupedByType: boolean;
  isGroupedByFolders: boolean;
  isGroupedByTypeAndFolders: boolean;
  handleUpdateOrderingAndGrouping: any;
}

const rowDrag = function(params) {
  return !params.node.group;
};

const formatData = (data) => {
  let lastIndexAllItems = 1;
  let lastIndexRequirementTypes = 1;
  let lastIndexRequirementTypesAndFolders = 1;
  let lastIndexFolders = 1;

  const filteredData = data
    .filter((child) => child.__typename !== 'Folder')
    .map((member) => requirementToSetChild(member));

  const finalData = filteredData.map((item) => {
    const newItem = cloneDeep(item);
    if (newItem.sortPositionsJson.allItems) {
      if (newItem.sortPositionsJson.allItems > lastIndexAllItems) {
        lastIndexAllItems = newItem.sortPositionsJson.allItems;
      }
    } else {
      newItem.sortPositionsJson.allItems = lastIndexAllItems;
      lastIndexAllItems++;
    }

    if (newItem.sortPositionsJson.requirementTypes) {
      if (newItem.sortPositionsJson.requirementTypes > lastIndexRequirementTypes) {
        lastIndexRequirementTypes = newItem.sortPositionsJson.requirementTypes;
      }
    } else {
      newItem.sortPositionsJson.requirementTypes = lastIndexRequirementTypes;
      lastIndexRequirementTypes++;
    }

    if (newItem.sortPositionsJson.requirementTypesAndFolders) {
      if (newItem.sortPositionsJson.requirementTypesAndFolders > lastIndexRequirementTypesAndFolders) {
        lastIndexRequirementTypesAndFolders = newItem.sortPositionsJson.requirementTypesAndFolders;
      }
    } else {
      newItem.sortPositionsJson.requirementTypesAndFolders = lastIndexRequirementTypesAndFolders;
      lastIndexRequirementTypesAndFolders++;
    }

    if (newItem.sortPositionsJson.folders) {
      if (newItem.sortPositionsJson.folders > lastIndexFolders) {
        lastIndexFolders = newItem.sortPositionsJson.folders;
      }
    } else {
      newItem.sortPositionsJson.folders = lastIndexFolders;
      lastIndexFolders++;
    }

    return newItem;
  });

  return finalData;
};

const listViewColumns = (
  activeWorkspaceView,
  data,
  isCustomGrouping,
  isGroupedByType,
  isGroupedByTypeAndFolders,
  isGroupedByFolders,
  getFoldersByIndentationLevel,
) => [
  {
    field: 'currentVersion.item.customIdentifier',
    headerName: 'ID',
    type: 'custom',
    blockRowLink: true,
    rowDrag: (params) => rowDrag(params) && !isCustomGrouping,
    render: (row) => {
      return (
        <MakeLink item={row.data.currentVersion.item} newTab={true}>
          {row.data.currentVersion.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    blockRowLink: true,
    type: 'custom',
    render: (row) => {
      if (activeWorkspaceView.id === Views.Effective.id || activeWorkspaceView.id === Views.Versions.id) {
        const isWorkingVersionSelected = workingVersionSelected(
          row.data.currentVersion.item.currentRelease,
          row.data.currentVersion.item.workingVersion,
          row.data.currentVersion.id,
        );

        return (
          <VersionAndStatusDot
            version={
              isWorkingVersionSelected
                ? row.data.currentVersion.item.workingVersion.versionIdentifier
                : row.data.currentVersion.item.currentRelease.versionIdentifier
            }
            status={
              isWorkingVersionSelected
                ? row.data.currentVersion.item.workingVersion.currentStatusName
                : row.data.currentVersion.item.currentRelease.currentStatusName
            }
          />
        );
      }

      return (
        <VersionToggleField
          setItemChild={row.data}
          refetchQueries={['RequirementsSetVersionQuery']}
          switchVersionMutation={switchRequirementsVersion}
          disabled={
            data.requirementsSetVersion.locked === true ||
            (row.data.currentVersion.locked &&
              row.data.currentVersion.currentStatusName !== GenericWorkflowStatuses.Released.id)
          }
        />
      );
    },
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    blockRowLink: true,
    type: 'editable_text',
    editAttr: 'title',
    refetchQuery: 'RequirementsSetVersionQuery',
    currentVersionPath: 'currentVersion',
  },
  {
    field: 'currentVersion.requirementDescription',
    headerName: 'Description',
    blockRowLink: true,
    type: 'editable_text',
    editAttr: 'requirement_description',
    refetchQuery: 'RequirementsSetVersionQuery',
    currentVersionPath: 'currentVersion',
    width: '400px',
  },
  {
    field: 'currentVersion.requirementTypes',
    headerName: 'Types',
    blockRowLink: true,
    type: 'multi_options',
    editAttr: 'requirement_types',
    refetchQuery: 'RequirementsSetVersionQuery',
    currentVersionPath: 'currentVersion',
    options: options,
    keyCreator: (params) => {
      return requirementTypesKeyCreator(params.data.currentVersion.requirementTypes);
    },
    ...(!isCustomGrouping && {
      rowGroup: isGroupedByType || isGroupedByTypeAndFolders,
      hide: isGroupedByType || isGroupedByTypeAndFolders,
    }),
  },
  {
    field: 'folderOneSelect',
    headerName: 'Folder Level One',
    type: 'custom',
    width: '125px',
    enableRowGroup: true,
    ...(!isCustomGrouping && {
      rowGroup: isGroupedByFolders || isGroupedByTypeAndFolders,
      hide: isGroupedByFolders || isGroupedByTypeAndFolders,
    }),
    cellStyle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
    },
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.folderOne ? row.value.folderOne : '(undefined)';
    },
    render: (row) => {
      if (isColumnGroupedBy(row, 'folderOneSelect')) {
        return row.value;
      }
      return (
        <SelectFolder
          folders={getFoldersByIndentationLevel(1)}
          selectedFolderName={row.value.folderOne}
          level="folderOne"
          setMemberId={row.value.id}
          updateMemberMutation={updateMember}
          refetchQueries={['RequirementsSetVersionQuery']}
          locked={get(row.value, 'currentVersion.locked')}
        />
      );
    },
  },
  {
    field: 'folderTwoSelect',
    headerName: 'Folder Level Two',
    type: 'custom',
    width: '125px',
    enableRowGroup: true,
    cellStyle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
    },
    ...(!isCustomGrouping && {
      rowGroup: isGroupedByFolders || isGroupedByTypeAndFolders,
      hide: isGroupedByFolders || isGroupedByTypeAndFolders,
    }),
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.folderTwo ? row.value.folderTwo : '(undefined)';
    },
    render: (row) => {
      if (isColumnGroupedBy(row, 'folderTwoSelect')) {
        return row.value;
      }
      return (
        <SelectFolder
          folders={getFoldersByIndentationLevel(2)}
          selectedFolderName={row.value.folderTwo}
          level="folderTwo"
          setMemberId={row.value.id}
          updateMemberMutation={updateMember}
          refetchQueries={['RequirementsSetVersionQuery']}
          locked={get(row.value, 'currentVersion.locked')}
        />
      );
    },
  },
  {
    field: 'folderThreeSelect',
    headerName: 'Folder Level Three',
    type: 'custom',
    width: '125px',
    enableRowGroup: true,
    cellStyle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
    },
    ...(!isCustomGrouping && {
      hide: isGroupedByFolders || isGroupedByTypeAndFolders,
      rowGroup: isGroupedByFolders || isGroupedByTypeAndFolders,
    }),
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.folderThree ? row.value.folderThree : '(undefined)';
    },
    render: (row) => {
      if (isColumnGroupedBy(row, 'folderThreeSelect')) {
        return row.value;
      }
      return (
        <SelectFolder
          folders={getFoldersByIndentationLevel(3)}
          selectedFolderName={row.value.folderThree}
          level="folderThree"
          setMemberId={row.value.id}
          updateMemberMutation={updateMember}
          refetchQueries={['RequirementsSetVersionQuery']}
          locked={get(row.value, 'currentVersion.locked')}
        />
      );
    },
  },
  {
    field: 'currentVersion.taskVersionLinkedTasks',
    headerName: 'Actions',
    blockRowLink: true,
    type: 'custom',
    width: '150px',
    valueGetter: (row) => {
      return row.data;
    },
    render: (row) => {
      const requirementItem = row.value;
      return (
        <RequirementLongCard
          memberId={requirementItem.id}
          versionToggleEnabled={false}
          versionId={requirementItem.currentVersion.id}
          versionIdentifier={requirementItem.currentVersion.versionIdentifier}
          currentStatusName={requirementItem.currentVersion.currentStatusName}
          title={requirementItem.title}
          requirementDescription={requirementItem.currentVersion.requirementDescription}
          requirementTypes={requirementItem.currentVersion.requirementTypes}
          locked={requirementItem.currentVersion.locked}
          workingVersion={requirementItem.currentVersion.item.workingVersion}
          currentRelease={requirementItem.currentVersion.item.currentRelease}
          itemId={requirementItem.currentVersion.item.id}
          itemTags={requirementItem.currentVersion.item.tags}
          parentSetItemSlug={displaySlugs['requirement']}
          customIdentifier={requirementItem.currentVersion.item.customIdentifier}
          displayOnly={false}
          commentCount={requirementItem.currentVersion.item.comments.length}
        />
      );
    },
  },
];

const TableContainer: React.FC<TableContainerProps> = ({
  data,
  handleColumnRowGroupChanged,
  DisplayVariantSelect,
  activeWorkspaceView,
  isCustomGrouping,
  isGroupedByType,
  isGroupedByFolders,
  isGroupedByTypeAndFolders,
  handleUpdateOrderingAndGrouping,
}) => {
  const gridRef = useRef<any>();
  const [isCombinedGroups, setIsCombinedGroups] = useState(true);
  const folders = filterFolders(data);

  const getFoldersByIndentationLevel = (level: number) => {
    return folders
      ? folders
          .filter((folder) => folder.indentationLevel === level)
          .sort((a, b) => a.folderName.localeCompare(b.folderName))
      : null;
  };

  const [setMembers, setSetMembers] = useState<SetChild[]>([]);

  const handleData = () => {
    let sortedMembers: SetChild[] = [];

    if (isCombinedGroups && (isCustomGrouping || isGroupedByType || isGroupedByTypeAndFolders)) {
      sortedMembers = combineItemGroups(data.requirementsSetVersion.setItemChildren, 'requirementTypes');
    } else {
      sortedMembers = sortSetItems(
        formatData(data.requirementsSetVersion.setItemChildren),
        isCustomGrouping,
        isGroupedByType,
        isGroupedByFolders,
        isGroupedByTypeAndFolders,
      );
    }
    setSetMembers(sortedMembers);
    if (gridRef.current) {
      gridRef.current.api.setRowData(sortedMembers);
    }
  };

  useEffect(() => {
    handleData();
  }, [
    data.requirementsSetVersion.setItemChildren.length,
    JSON.stringify(data.requirementsSetVersion.setItemChildren.map(({ version }) => version)),
    isCustomGrouping,
    isGroupedByType,
    isGroupedByFolders,
    isGroupedByTypeAndFolders,
    isCombinedGroups,
  ]);

  const handleGridReady = () => {
    handleData();
  };

  const handleRowDragEnd = () => {
    handleUpdateOrderingAndGrouping(setMembers);
  };

  const handleRowDragMove = (event) => {
    const movingNode = event.node;
    const overNode = event.overNode;

    const movingData = movingNode.data;
    if (isGroupedByType && !overNode.group) {
      const movingNodeGroup = requirementTypesKeyCreator(movingNode.data.currentVersion.requirementTypes);
      const overNodeGroup = requirementTypesKeyCreator(overNode.data.currentVersion.requirementTypes);
      if (movingNodeGroup !== overNodeGroup) {
        movingData.currentVersion.requirementTypes = overNode.data.currentVersion.requirementTypes;
        gridRef.current.api.applyTransaction({
          update: [movingData],
        });
      }
    }

    if (isGroupedByFolders && !overNode.group) {
      const overNodeGroup = `${overNode.data.folderOne || undefinedKey}, ${overNode.data.folderTwo ||
        undefinedKey}, ${overNode.data.folderThree || undefinedKey}`;
      const movingNodeGroup = `${movingNode.data.folderOne || undefinedKey}, ${movingNode.data.folderTwo ||
        undefinedKey}, ${movingNode.data.folderThree || undefinedKey}`;
      if (overNodeGroup !== movingNodeGroup) {
        const movingData = movingNode.data;
        movingData.folderOne = overNode.data.folderOne;
        movingData.folderTwo = overNode.data.folderTwo;
        movingData.folderThree = overNode.data.folderThree;
        gridRef.current.api.applyTransaction({
          update: [movingData],
        });
      }
    }

    if (isGroupedByTypeAndFolders && !overNode.group) {
      const overNodeGroup = `${overNode.data.folderOne || undefinedKey}, ${overNode.data.folderTwo ||
        undefinedKey}, ${overNode.data.folderThree || undefinedKey} - ${requirementTypesKeyCreator(
        overNode.data.currentVersion.requirementTypes,
      )}`;
      const movingNodeGroup = `${movingNode.data.folderOne || undefinedKey}, ${movingNode.data.folderTwo ||
        undefinedKey}, ${movingNode.data.folderThree || undefinedKey} - ${requirementTypesKeyCreator(
        movingNode.data.currentVersion.requirementTypes,
      )}`;
      if (overNodeGroup !== movingNodeGroup) {
        const movingData = movingNode.data;
        movingData.folderOne = overNode.data.folderOne;
        movingData.folderTwo = overNode.data.folderTwo;
        movingData.folderThree = overNode.data.folderThree;
        movingData.currentVersion.requirementTypes = overNode.data.currentVersion.requirementTypes;
        gridRef.current.api.applyTransaction({
          update: [movingData],
        });
      }
    }

    const rowNeedsToMove = movingNode && overNode && movingNode.data.id !== overNode.data.id && !overNode.group;
    if (rowNeedsToMove) {
      let reorderedSetMembers;
      let key;
      if (isGroupedByType) {
        key = 'requirementTypes';
      }
      if (isGroupedByFolders) {
        key = 'folders';
      }
      if (isGroupedByTypeAndFolders) {
        key = 'requirementTypeAndFolders';
      }
      if (!isGroupedByType && !isGroupedByFolders && !isGroupedByTypeAndFolders) {
        key = 'allItems';
      }
      reorderedSetMembers = sortGroupedBy(changeSortPositionsBy(setMembers, movingNode, overNode, key), key);
      if (!isEqual(setMembers, reorderedSetMembers)) {
        gridRef.current.api.setRowData(reorderedSetMembers);
        setSetMembers(reorderedSetMembers);
      }
    }
    gridRef.current.api.clearFocusedCell();
  };

  const onViewOptionChange = (checked: boolean) => {
    setIsCombinedGroups(!checked);
  };

  return (
    <AgGridTable
      columns={listViewColumns(
        activeWorkspaceView,
        data,
        isCustomGrouping,
        isGroupedByType,
        isGroupedByTypeAndFolders,
        isGroupedByFolders,
        getFoldersByIndentationLevel,
      )}
      data={setMembers}
      handleColumnRowGroupChanged={handleColumnRowGroupChanged}
      DisplayVariantSelect={DisplayVariantSelect}
      handleRowDragMove={handleRowDragMove}
      gridRef={gridRef}
      handleGridReady={handleGridReady}
      handleRowDragEnd={handleRowDragEnd}
      viewOptionChangeCallback={onViewOptionChange}
    />
  );
};

export default TableContainer;
