import React, { createContext, FC, ReactNode, useState } from 'react';

export enum WorkspaceType {
  DISCIPLINE = 'discipline',
  ALPHABETICAL = 'alphabetical',
  FAVORITES = 'favorites',
}

export type WorkspacesContext = {
  workspace: WorkspaceType | string;
  setWorkspaceType: (type: WorkspaceType) => void;
};

export const SidebarWorkspacesContext = createContext<WorkspacesContext>({
  workspace: 'discipline',
  setWorkspaceType: () => {},
});

export const SidebarWorkspacesProvider: FC<ReactNode> = ({ children }) => {
  const [selected, setSelected] = useState<WorkspaceType>(WorkspaceType.DISCIPLINE);

  return (
    <SidebarWorkspacesContext.Provider value={{ workspace: selected, setWorkspaceType: setSelected }}>
      {children}
    </SidebarWorkspacesContext.Provider>
  );
};

export const withSidebarWorkspaces = (Component) => (props) => (
  <SidebarWorkspacesProvider>
    <Component {...props} />
  </SidebarWorkspacesProvider>
);
