import React from 'react';
import { ChangeOrderWorkflowStatuses } from 'workflows/statuses';
import { YesOrNo } from '../views';

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => (
        <YesOrNo
          prompt={`Do you want to disable editing of ${
            props.itemCustomIdentifier
          }?`}
          closeOnNo
          yesLabel="Lock"
          noLabel="Cancel"
          {...props}
        />
      ),
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ confirmTransition }) => {
    return confirmTransition
      ? {
          toStatus: ChangeOrderWorkflowStatuses.UnderReview.id,
        }
      : null;
  },
};
