import gql from 'graphql-tag';

export const CREATE_HAZARD = gql`
  mutation CreateHazard($name: String!) {
    createHazard(name: $name) {
      hazard {
        id
        name
      }
    }
  }
`;

export const LIST_HAZARDS = gql`
  query ListHazards {
    hazards {
      id
      name
    }
  }
`;
