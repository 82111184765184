import React from 'react';
import { DEBOUNCE_MILLISECONDS } from '../../constants';
import debounce from 'lodash/debounce';
import { useFormikContext } from 'formik';

export default function AutoSave() {
  const { values, submitForm, dirty } = useFormikContext();
  const debouncedSubmit = React.useCallback(
    debounce(submitForm, DEBOUNCE_MILLISECONDS),
    [],
  );

  React.useEffect(() => {
    if (dirty) {
      const result = debouncedSubmit();
      if (result) {
        result.catch((e) => {
          throw new Error(
            `Error while saving version ${JSON.stringify(values)}\n${e}`,
          );
        });
      }
    }
  }, [values, dirty, debouncedSubmit]);

  return null;
}
