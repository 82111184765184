import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    dialog: {
      '& .MuiPaper-root': {
        '& > div': {
          padding: theme.spacing(2),
        },
      },
    },
    root: {},
    content: {
      height: 300,
      overflowY: 'auto',
      marginBottom: theme.spacing(4),
    },
    roleName: {
      fontWeight: theme.typography.fontWeightExtraBold,
    },
    itemsContainer: {
      marginTop: theme.spacing(2),
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    userIcon: {
      fontSize: 16,
      color: theme.palette.secondary.main,
    },
    newUser: {
      textTransform: 'uppercase',
      color: theme.palette.blueDark,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(3),
    },
    controlLabel: {
      marginRight: 0,
      marginLeft: 0,
    },
    checkbox: {
      padding: 0,
    },
    checkboxRoot: {
      fontSize: 16,
    },
    item: {
      display: 'flex',

      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    itemName: {
      textAlign: 'start',
      marginRight: 'auto',
      color: theme.palette.blueDark,
      width: '70%',
    },
    select: {
      width: '30%',
      textAlign: 'left',
    },
    esignature: {
      margin: theme.spacing(-2),
      padding: theme.spacing(2, 2, 0, 2),
      boxShadow: '0px -1px 3px 0px rgba(126, 161, 159, 0.28)',
    },
  }),
);
