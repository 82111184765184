import React from 'react';
import flowRight from 'lodash/flowRight';
import LinearScale from '@material-ui/icons/LinearScale';
import { Trans } from '@lingui/macro';

import { withStyles } from '@material-ui/core';

import WorkflowMenu, { VoidMenuItem } from 'components/WorkflowMenu';
import ConnectBar from '../ConnectBar';
import { indexById, AttachmentDependentWorkflowStatuses } from 'workflows/statuses';
import withMetadataFromServer from '../../../WithMetadataFromServer';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import WithCurrentUser from '../../../WithCurrentUser';

import PermissionFailModal from '../PermissionFailModal';
import styles from '../sharedStyles';
import { ContentSection, WorkflowIcon } from 'components';

import RecordOrigin from './RecordOrigin';

const { Archived, ToDo, Completed, Checked, Failed, Cancel } = AttachmentDependentWorkflowStatuses;

const StatusesById = indexById(AttachmentDependentWorkflowStatuses);

class TrainingRecordWorkflow extends React.Component {
  state = {
    previousVersionModal: false,
    permissionFailModal: false,
    releaseNotPermittedModal: false,
    itemTransitioningLockedModal: false,
    previousVersion: null,
    justificationOfNoChangeOrder: null,
  };

  openModal = (modalName) => {
    this.setState({ [modalName]: true });
  };

  closeModal = () => {
    this.setState({
      permissionFailModal: false,
      releaseNotPermittedModal: false,
      itemTransitioningLockedModal: false,
      previousVersionModal: false,
    });
  };

  workflowControls = () => {
    const { currentCompanyAllowsTransitioning, currentVersion, handleInitTransition } = this.props;
    const currentWorkflowStatus = StatusesById[currentVersion.currentStatusName];
    const currentUserIsEmployee = this.props.currentVersion.employee.user.id === this.props.currentUser.id;

    if (!currentCompanyAllowsTransitioning) return;

    return (
      <>
        <WorkflowMenu>
          <VoidMenuItem
            disabled={!(currentWorkflowStatus.isEqualTo(Completed) && currentUserIsEmployee)}
            onClick={() => handleInitTransition('trainingRecordVoided')}
          />
        </WorkflowMenu>
      </>
    );
  };

  render() {
    const { currentCompanyAllowsTransitioning, releasedVersions, handleInitTransition } = this.props;
    const { permissionFailModal, releaseNotPermittedModal, itemTransitioningLockedModal } = this.state;

    const currentVersion =
      this.props.currentVersion.currentStatusName === 'not_created'
        ? releasedVersions[releasedVersions.length - 1]
        : this.props.currentVersion;

    const { currentStatusName } = currentVersion;

    const currentUserIsEmployee = this.props.currentVersion.employee.user.id === this.props.currentUser.id;

    const status = currentStatusName;
    const currentWorkflowStatus = StatusesById[status];
    if (!currentWorkflowStatus) throw new Error(`Invalid status for Attachment Dependent Workflow: ${status}`);

    return (
      <ContentSection LabelText={<Trans>Workflow</Trans>} Icon={<LinearScale />} Controls={this.workflowControls()}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RecordOrigin recordOrigin={currentVersion.recordOrigin} createdAt={currentVersion.createdAt} />
          <ConnectBar color="draft" disabled={!currentCompanyAllowsTransitioning} visible short />
          <WorkflowIcon
            type="draft"
            active={currentWorkflowStatus.isEqualTo(ToDo)}
            completed={currentWorkflowStatus.isAfter(ToDo)}
            label="To Do"
            size="large"
            visible
          />

          {!currentWorkflowStatus.isEqualTo(Cancel) && !currentWorkflowStatus.isEqualTo(Archived) && (
            <>
              <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} visible />

              <WorkflowIcon
                type="completed"
                active={currentWorkflowStatus.isEqualTo(Completed)}
                completed={currentWorkflowStatus.isAfter(Completed)}
                label="Completed"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');

                  if (!currentUserIsEmployee) return this.openModal('permissionFailModal');

                  if (currentWorkflowStatus.isBefore(Completed)) {
                    const handler = currentVersion.source.effectivenessMeasurementRequired
                      ? 'completed'
                      : 'completedNoAssessment';
                    return handleInitTransition(handler);
                  }
                }}
                visible
              />

              <ConnectBar
                color="released"
                disabled={!currentCompanyAllowsTransitioning || !this.props.permissions.canApprove}
                visible
              />

              <WorkflowIcon
                type="released"
                active={currentWorkflowStatus.isEqualTo(Checked)}
                completed={currentWorkflowStatus.isEqualTo(Checked)}
                label="Checked"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (currentWorkflowStatus.isEqualTo(Completed)) {
                    if (!this.props.permissions.canApprove) {
                      return this.openModal('permissionFailModal');
                    } else {
                      return handleInitTransition('approveOrReject');
                    }
                  }

                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');
                }}
                disabled={!this.props.permissions.canApprove}
                visible
              />

              <ConnectBar
                color="failed"
                disabled={!currentUserIsEmployee || !currentCompanyAllowsTransitioning}
                visible={currentWorkflowStatus.isEqualTo(Failed)}
              />

              <WorkflowIcon
                type="failed"
                active
                label="Failed"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');

                  if (!currentUserIsEmployee) return this.openModal('permissionFailModal');

                  return handleInitTransition('resolveFailure');
                }}
                visible={currentWorkflowStatus.isEqualTo(Failed)}
              />
            </>
          )}

          <ConnectBar color="archived" visible={currentWorkflowStatus.isEqualTo(Archived)} />

          <WorkflowIcon
            type="archived"
            label="Archived"
            visible={currentWorkflowStatus.isEqualTo(Archived)}
            completed
          />

          <ConnectBar color="canceled" visible={currentWorkflowStatus.isEqualTo(Cancel)} />

          <WorkflowIcon type="canceled" label="Cancel" visible={currentWorkflowStatus.isEqualTo(Cancel)} completed />

          <PermissionFailModal
            open={permissionFailModal || releaseNotPermittedModal || itemTransitioningLockedModal}
            failType={permissionFailModal ? 'permissions' : releaseNotPermittedModal ? 'release' : 'itemTransitioning'}
            closeModal={this.closeModal}
          />
        </div>
      </ContentSection>
    );
  }
}

export default flowRight(
  withStyles(styles),
  withMetadataFromServer(),
  WithCurrentUser,
  withCurrentCompany,
)(TrainingRecordWorkflow);
