import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';
import startCase from 'lodash/startCase';
import { DateTime } from 'luxon';
import { Typography, withStyles } from '@material-ui/core';

import Spacer from 'components/Spacer';
import Loading from 'components/Loading';
import UserAvatar from 'components/UserAvatar';

import styles from './styles';
import ItemHistoryIcon from './ItemHistoryIcon';
import withMetadataFromServer from '../../WithMetadataFromServer';

const query = gql`
  query History($itemId: ID!) {
    item(id: $itemId) {
      id
      history {
        affectedFieldName
        affectedFieldLabel
        typeOfChange
        fromStatus
        toStatus
        occurredAt
        diff {
          before
          after
          changes {
            action
            content
          }
        }
        changeNotes
        userId
      }
    }
  }
`;

function hasSameCalendarDate(baseISO, compareISO) {
  const baseDate = DateTime.fromISO(baseISO);
  const compareDate = DateTime.fromISO(compareISO);

  return baseDate.hasSame(compareDate, 'day');
}

const diffTypeToClass = {
  '+': 'diffAdded',
  '-': 'diffRemoved',
  '=': null,
};

function renderSimpleFieldDiff(change, classes) {
  return (
    <div>
      <Typography variant="body2" className={classes.recordEditTitle}>
        {`${change.affectedFieldLabel} was edited: `}
      </Typography>

      {change.diff.before !== '' && (
        <>
          <Typography
            variant="body2"
            className={classes.diffRemoved}
            style={{
              display: 'inline',
            }}
            component="span"
          >
            {change.diff.before}
          </Typography>
          <div style={{ height: 4, clear: 'both' }} />
        </>
      )}

      <Typography
        variant="body2"
        className={classes.diffAdded}
        style={{
          display: 'inline',
        }}
        component="span"
      >
        {change.diff.after}
      </Typography>
    </div>
  );
}

function renderLongTextDiff(change, classes) {
  /*
    We have chosen to simply not display changes that are solely whitespace
    instead of updating the diff algorithm, we just escape them while rendering
  */
  return (
    <div>
      <Typography variant="body2" className={classes.recordEditTitle}>
        {`${change.affectedFieldLabel} was edited: `}
      </Typography>
      {change.diff.changes.map((diff) => {
        const displayClass = get(diffTypeToClass, diff.action);

        const words = diff.content.split(' ');
        if (diff.action === '=' && words.length > 10) {
          return (
            <>
              <Typography variant="body2" style={{ display: 'inline' }}>
                {words.slice(0, 10).join(' ')}
              </Typography>
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                ...
              </Typography>
              <Typography variant="body2" style={{ display: 'inline', marginRight: 4 }}>
                {words.slice(words.length - 10, words.length - 1).join(' ')}
              </Typography>

              {words[words.length - 1] === '\n' && <br />}
            </>
          );
        }

        const lines = diff.content.split('\n');
        return lines.map((line, ix) => (
          <>
            {line !== '' && line !== ' ' && (
              <Typography
                key={ix}
                variant="body2"
                className={get(classes, displayClass)}
                style={{
                  display: 'inline',
                  marginRight: 4,
                }}
                component="span"
              >
                {line}
              </Typography>
            )}
            {ix < lines.length - 1 && <br />}
          </>
        ));
      })}
    </div>
  );
}

function Diff({ change, fieldType, classes }) {
  if (!change.diff) return null;
  if (fieldType === 'text_field') {
    if (change.diff.changes.length < 1) return null;
    return renderLongTextDiff(change, classes);
  }
  if (change.affectedFieldLabel.endsWith('member')) {
    return null;
  }
  return renderSimpleFieldDiff(change, classes);
}

interface HistoryProps {
  classes: any;
  itemId: string;
  remoteItemTypeMetadata: any;
}

function History({ classes, itemId, remoteItemTypeMetadata }: HistoryProps) {
  const fieldDict = remoteItemTypeMetadata.fields.reduce((acc, val) => {
    acc[val.fieldName] = val.fieldType;
    return acc;
  }, {});

  return (
    /* Once dynamic API disappears and we only use static API,
        we can turn cacheing back on */
    <Query query={query} variables={{ itemId }} fetchPolicy="network-only">
      {({ data, loading, error }) => {
        if (loading) return <Loading />;
        if (error) throw new Error(`Error loading history: ${error}`);

        const history = get(data, 'item.history', []);
        return (
          <div className={classes.container}>
            {history.map((change, ix) => {
              const fieldType = get(fieldDict, change.affectedFieldName) || 'string_field';

              /*
                For text_field changes, we chose not to render whitespace-only changes,
                thus if the change entry only consists of whitespace changes, we must render null
              */
              let hasVisibleChanges = false;
              if (fieldType === 'text_field') {
                for (let ch of change.diff.changes) {
                  if (ch.action !== '=' && /[^\n\s]/.test(ch.content)) {
                    hasVisibleChanges = true;
                    break;
                  }
                }
              } else {
                hasVisibleChanges = true;
              }
              if (!hasVisibleChanges) return null;

              const prevElement = get(history, ix - 1, null);
              const displayHeader =
                prevElement === null || !hasSameCalendarDate(change.occurredAt, prevElement.occurredAt);

              const sameUpdate =
                prevElement &&
                change.occurredAt === prevElement.occurredAt &&
                (change.typeOfChange === 'record_edit' || change.typeOfChange === 'status_change');

              const occurredAt = DateTime.fromISO(change.occurredAt);

              // just add diff if it was part of the same transaction (multiple fields)
              if (sameUpdate && !change.toStatus && !change.fromStatus) {
                return (
                  <div key={ix}>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          visibility: 'hidden', // placeholder
                          width: 50,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <UserAvatar userId={change.userId} />
                      </div>

                      <div style={{ flex: 1 }}>
                        <Diff change={change} fieldType={fieldType} classes={classes} />
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div key={ix}>
                  <Spacer factor={1} />

                  {displayHeader && (
                    <Typography component="div" className={classes.dateHeader} variant="overline">
                      {occurredAt.toLocaleString(DateTime.DATE_FULL)}
                    </Typography>
                  )}

                  <Typography component="div" className={classes.timeRow}>
                    {occurredAt.toLocaleString(DateTime.TIME_SIMPLE)}
                  </Typography>

                  <div className={classes.centeredRow}>
                    <ItemHistoryIcon {...change} />
                  </div>

                  <div className={classes.centeredRow}>
                    <Typography component="div" variant="overline" className={classes.title}>
                      {startCase(change.typeOfChange)}
                    </Typography>
                  </div>

                  <Spacer factor={1} />
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        width: 50,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <UserAvatar userId={change.userId} />
                    </div>

                    {change.changeNotes && !change.affectedFieldLabel.endsWith('ordering') && (
                      <Typography component="div" variant="body2" style={{ flex: '1 1 100%' }}>
                        {change.changeNotes}
                      </Typography>
                    )}

                    {!change.toStatus && !change.fromStatus && (
                      <div style={{ flex: 1 }}>
                        <Diff change={change} fieldType={fieldType} classes={classes} />
                      </div>
                    )}
                  </div>
                  <Spacer factor={1} />
                </div>
              );
            })}
          </div>
        );
      }}
    </Query>
  );
}

export default flowRight([withStyles(styles), withMetadataFromServer()])(History);
