import React, { useContext } from 'react';

import { Assignment, InfoOutlined, LibraryBooks, Link as LinkIcon } from '@material-ui/icons';

import { WorkflowIcon } from 'components';
import SectionLabel from 'components/core/SectionLabel';
import { Tooltip, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useToggleablePopover } from 'hooks/useToggleablePopover';
import { ADD_QUALITY_DOCUMENT, REMOVE_QUALITY_DOCUMENT } from './gql';
import { ItemSearch } from 'compositions';
import { useMutation } from 'react-apollo';
import IconButton from 'components/IconButton';
import Panel from 'components/core/Panel';
import Table from 'components/core/Table';
import linkToItem from 'utils/linkToItem';
import { LockedContext } from 'contexts/lockedContext';

import { DeleteIcon, VersionCell } from 'components/VersionTables';

interface Props {
  data: any;
  versionId: string;
}

function AssociatedItems(props: Props) {
  const { data } = props;
  const classes = useStyles();
  const [searchOpen, searchAnchor, toggleSearch] = useToggleablePopover();
  const { locked } = useContext(LockedContext);

  const [removeQualityDocument] = useMutation(REMOVE_QUALITY_DOCUMENT, { refetchQueries: ['CourseVersionQuery'] });
  const [addQualityDocument] = useMutation(ADD_QUALITY_DOCUMENT, { refetchQueries: ['CourseVersionQuery'] });

  const itemLink = (item) => linkToItem(item.itemType.displaySlug, item.id);

  return (
    <div>
      <SectionLabel icon={LibraryBooks}>
        Items Associated with Course
        <Tooltip title="These should be the items in the system the course will train on." className={classes.info}>
          <InfoOutlined />
        </Tooltip>
      </SectionLabel>
      <Panel>
        <Panel.Heading icon={Assignment}>Associated Items</Panel.Heading>
        <div className={classes.tableContainer}>
          {data.courseVersion.qualityDocumentVersions.length > 0 ? (
            <Table
              data={data.courseVersion.qualityDocumentVersions}
              actions={
                !locked
                  ? [
                      {
                        icon: () => <DeleteIcon />,
                        onClick: (_e, qualityDocument) => {
                          removeQualityDocument({
                            variables: {
                              courseVersionId: data.courseVersion.id,
                              qualityDocumentVersionId: qualityDocument.id,
                            },
                          }).catch((err) => {
                            throw new Error(`Error removing quality document: ${err.message}`);
                          });
                        },
                      },
                    ]
                  : undefined
              }
              columns={[
                {
                  title: 'ID',
                  field: 'id',
                  render: (version) => (
                    <a href={itemLink(version.item)} target="_blank" rel="noopener noreferrer">
                      {version.item.customIdentifier}
                    </a>
                  ),
                },
                {
                  title: 'Title',
                  field: 'title',
                },
                {
                  title: 'Version',
                  align: 'center',
                  render: (version) => (
                    <VersionCell version={version.versionIdentifier} status={version.currentStatus.name} />
                  ),
                },
                {
                  title: 'Status',
                  render: (version: any) => (
                    <WorkflowIcon type={version.currentStatus.name} completed size="small" displayTooltip />
                  ),
                },
              ]}
            />
          ) : (
            <Typography variant="caption">No items associated.</Typography>
          )}
        </div>
        <div>
          {!locked && <IconButton onClick={(e) => toggleSearch(e.currentTarget)} Icon={LinkIcon} includeBackground />}
          <ItemSearch
            draftOnly={false}
            supportsChangeOrder={false}
            defaultSearchTerm={null}
            excludedItemTypes={['course']}
            open={searchOpen}
            closeSelf={() => toggleSearch(null)}
            onSubmit={(qualityDocumentVersionIds) =>
              Promise.all(
                qualityDocumentVersionIds.map((qualityDocumentVersionId) =>
                  addQualityDocument({
                    variables: {
                      courseVersionId: props.versionId,
                      qualityDocumentVersionId,
                    },
                    refetchQueries: ['CourseVersionQuery'],
                  }),
                ),
              )
                .then(() => true)
                .catch(() => false)
            }
            anchorEl={searchAnchor}
            versionLink={data.courseVersion}
          />
        </div>
      </Panel>
    </div>
  );
}

export default AssociatedItems;
