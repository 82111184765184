import React, { useContext } from 'react';
import { SnackContext } from 'contexts/other/snackContext';
import BaseSnackbar from 'components/BaseSnackbar';

// it does not add custom action to snack bar at this point
//
export default function CentralSnack() {
  const { snackMessage, snackVisible, hideSnack } = useContext(SnackContext);

  return <BaseSnackbar open={snackVisible} message={snackMessage} onClose={hideSnack} />;
}
