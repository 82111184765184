import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    titleContainer: {
      position: 'relative',
      width: '100%',
    },
    editContainer: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    avatarImageWrapper: {
      width: '175px',
      height: '175px',
      alignSelf: 'center',
    },
    avatarImage: {
      width: '100%',
      height: 'auto',
    },
    profileSection: {
      display: 'flex',
    },
  }),
);
