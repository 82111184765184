import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import {
  MiscellaneousRecordVersionQuery,
  MiscellaneousRecordVersionQueryVariables,
} from './__generated__/MiscellaneousRecordVersionQuery';

const versionQuery = gql`
  query MiscellaneousRecordVersionQuery($id: ID!) {
    miscellaneousRecordVersion(id: $id) {
      id
      locked
      displayAsApplicationVersion
      reasonForChange
      descriptionOfChange
      attachment {
        id
        url
      }
      rawReferenceAttachment {
        id
        url
      }
      creator {
        id
        fullName
        title
      }
      signingOwner {
        id
        firstName
        lastName
      }
      signingApprover {
        id
        firstName
        lastName
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<MiscellaneousRecordVersionQuery, MiscellaneousRecordVersionQueryVariables>(versionQuery, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

const createMiscellaneousRecordAttachment = gql`
  mutation createMiscellaneousRecordAttachment($attachmentURL: String!, $versionId: ID!) {
    createMiscellaneousRecordAttachment(attachment: $attachmentURL, versionId: $versionId) {
      miscellaneousRecordAttachment {
        id
        url
        miscellaneousRecordVersionId
        creator {
          id
          fullName
        }
      }
    }
  }
`;

export function useCreateMiscellaneousRecordAttachment() {
  return useMutation(createMiscellaneousRecordAttachment);
}

const destroyMiscellaneousRecordAttachment = gql`
  mutation DestroyMiscellaneousRecordAttachment($id: ID!) {
    destroyMiscellaneousRecordAttachment(id: $id) {
      attachment {
        id
      }
    }
  }
`;

export function useDestroyMiscellaneousRecordAttachment() {
  return useMutation(destroyMiscellaneousRecordAttachment);
}

const createRawReferenceAttachment = gql`
  mutation createRawReferenceAttachment($attachmentURL: String!, $versionId: ID!) {
    createRawReferenceAttachment(attachment: $attachmentURL, versionId: $versionId) {
      rawReferenceAttachment {
        id
        url
        versionId
        creator {
          id
          fullName
        }
      }
    }
  }
`;

export function useCreateRawReferenceAttachment() {
  return useMutation(createRawReferenceAttachment);
}

const destroyRawReferenceAttachment = gql`
  mutation DestroyRawReferenceAttachment($id: ID!) {
    destroyRawReferenceAttachment(id: $id) {
      attachment {
        id
      }
    }
  }
`;

export function useDestroyRawReferenceAttachment() {
  return useMutation(destroyRawReferenceAttachment);
}
