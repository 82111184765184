import gql from 'graphql-tag';

export const commentsQuery = gql`
  query CommentsQuery($offset: Int, $limit: Int) {
    comments(offset: $offset, limit: $limit) {
      id
      createdAt
      body
      item {
        id
        customIdentifier
        itemType {
          id
          name
          displaySlug
          userCreatable
        }
        currentVersion {
          id
          virtualAttributes(attrName: "title") {
            stringValue
          }
        }
      }
      creator {
        id
        profile {
          id
          firstName
          lastName
          photoUrl
        }
      }
    }
  }
`;
