import React, { useContext } from 'react';
import ListAlt from '@material-ui/icons/ListAlt';
import { CardContext } from 'contexts/LinksGraph/cardContext';
import LinksGraphToggle from '../LinksGraphToggle';
import ActionPopover from '../ActionPopover';

function CustomizeCardAction() {
  const { otherConnections, description } = useContext(CardContext);

  return (
    <ActionPopover Icon={ListAlt} cardDescription="Customize Card">
      <LinksGraphToggle
        checked={description.showing}
        label="Show Description"
        onChange={() => {
          description.toggle();
        }}
      />
      <LinksGraphToggle
        checked={otherConnections.showing}
        label="Show Other Connections"
        onChange={() => {
          otherConnections.toggle();
        }}
      />
    </ActionPopover>
  );
}

export default CustomizeCardAction;
