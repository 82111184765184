import gql from 'graphql-tag';

export default gql`
  query attachmentQuery($versionId: ID!) {
    version(id: $versionId) {
      id
      virtualAttributes(attrType: "attachmentAttr") {
        attachments {
          id
          url
          filename
          createdAt
          creator {
            id
            fullName
          }
        }
      }
    }
  }
`;
