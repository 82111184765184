import React from 'react';

import { Typography } from '@material-ui/core';

import Input from '../Input';

interface Props {
  editing: boolean;
  value: string;
  onChange: (text) => void;
  type?: string;
}

function EditableText(props: Props) {
  return props.editing ? (
    <Input debounced defaultValue={props.value} onChange={props.onChange} type={props.type} />
  ) : (
    <Typography variant="body2">{props.value}</Typography>
  );
}

export default EditableText;
