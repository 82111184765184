import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    taskMenuWrapper: {
      width: '100%',
      paddingLeft: theme.spacing(0.75),
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      height: 56,
      width: theme.mixins.sidebar.expandedWidth + theme.spacing(6),
      padding: theme.spacing(0, 0, 0.5, 3),
      zIndex: 3,
      borderBottom: '2px solid transparent',
      background:
        'linear-gradient(180deg, #FCFCFC 66.16%, rgba(252, 252, 252, 0.83) 86.38%, rgba(252, 252, 252, 0.62) 97.9%, rgba(252, 252, 252, 0.12) 115.18%)',
      backdropFilter: 'blur(2px)',

      '& .MuiButtonBase-root': {
        color: theme.palette.blueDark,
      },
    },
    menuHeader: {
      marginRight: theme.spacing(1),
      color: theme.palette.blueDark,
    },
  }),
);
