import RecordPage from 'pages/generic/RecordPage';
import SingletonPage from 'pages/generic/SingletonPage';
import ListPage from 'pages/generic/ListPage';
import { workspaceViewsForItemType } from 'workspaces';

import { PageType, singleRecordRouteName, listViewRouteName } from './routeNames';

export function singletonRoute(itemType) {
  const name = itemType.itemType;
  const workspaceViews = workspaceViewsForItemType(name, PageType.SingleRecord);
  return {
    RouteComponent: itemType.RecordPage || SingletonPage,
    categorySlug: itemType.displaySlug,
    path: `/category/${itemType.displaySlug}`,
    routeName: singleRecordRouteName(name),
    workspaceViews,
    itemType: name,
  };
}

export function recordRoute(itemType) {
  const name = itemType.itemType;
  const workspaceViews = workspaceViewsForItemType(name, PageType.SingleRecord);
  return {
    RouteComponent: itemType.RecordPage || RecordPage,
    categorySlug: itemType.displaySlug,
    path: `/category/${itemType.displaySlug}/:itemId`,
    routeName: singleRecordRouteName(name),
    workspaceViews,
    itemType: name,
    RecordViewOptions: itemType.RecordViewOptions,
  };
}

export function listRoute(itemType) {
  const name = itemType.itemType;
  const workspaceViews = workspaceViewsForItemType(name, PageType.List);
  return {
    RouteComponent: itemType.ListPage || ListPage,
    categorySlug: itemType.displaySlug,
    path: `/category/${itemType.displaySlug}`,
    routeName: listViewRouteName(name),
    workspaceViews,
    itemType: name,
  };
}
