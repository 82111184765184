import React, { ReactNode, useState } from 'react';
import { Showable, defaultShowable } from './LinksGraph/showable';

interface State {
  otherConnections: Showable;
  description: Showable;
}

export const TraceMatrixCardContext = React.createContext<State>({
  otherConnections: defaultShowable,
  description: defaultShowable,
});

export function TraceMatrixCardContextProvider(props: { children: ReactNode }) {
  const [showDescription, setShowDescription] = useState(true);
  const [showOtherConnections, setShowOtherConnections] = useState(false);

  return (
    <TraceMatrixCardContext.Provider
      value={{
        otherConnections: {
          showing: showOtherConnections,
          toggle: () => setShowOtherConnections((prev) => !prev),
        },
        description: {
          showing: showDescription,
          toggle: () => setShowDescription((prev) => !prev),
        },
      }}
    >
      {props.children}
    </TraceMatrixCardContext.Provider>
  );
}
