import React, { useState } from 'react';

import { Query } from 'react-apollo';

import RateReview from '@material-ui/icons/RateReview';
import { Trans } from '@lingui/macro';
import { Typography, Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Spacer, Loading } from 'components';

import LabelText from 'components/LabelText';

import CommentContainer from './CommentContainer';
import { commentsQuery } from './gql';
import { useStyles } from './styles';

function ScrollableComments() {
  const numberOfRecordsToLoadPerRequest = 10;
  const [hasMore, setHasMore] = useState(true);

  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.container}>
      <LabelText labelText="💬 Comments" />
      <Spacer factor={2} />
      <Query
        query={commentsQuery}
        variables={{ offset: 0, limit: numberOfRecordsToLoadPerRequest }}
        fetchPolicy={'network-only'}
      >
        {({ data, fetchMore, loading, error }) => {
          const comments = get(data, 'comments', []);
          const hasComments = comments.length > 0;
          const commentsGroupedByDay = groupBy(comments, (comment) =>
            DateTime.fromISO(comment.createdAt).startOf('day'),
          );
          const next = () => {
            fetchMore({
              variables: { offset: comments.length },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (fetchMoreResult.comments.length === 0) {
                  setHasMore(false);
                  return prev;
                } else {
                  return Object.assign({}, prev, {
                    comments: [...prev.comments, ...fetchMoreResult.comments],
                  });
                }
              },
            });
          };

          // initial load
          if (!hasComments && loading) return <Loading size={30} style={{ height: 70 }} />;
          if (error) throw new Error(`Error loading Scrollable Comments on dashboard: ${error}`);

          if (hasComments) {
            return (
              <InfiniteScroll
                height={736}
                dataLength={comments.length}
                next={next}
                hasMore={hasMore}
                loader={<Loading size={30} style={{ height: '100px' }} />}
              >
                {Object.entries(commentsGroupedByDay).map(([day, comments]) => (
                  <div className={classes.commentsContainer} key={day}>
                    <Typography variant="caption" className={classes.timestamp}>
                      {DateTime.fromISO(day).toLocaleString()}
                    </Typography>
                    {comments.map((comment) => (
                      <CommentContainer comment={comment} />
                    ))}
                    <Divider />
                  </div>
                ))}
              </InfiniteScroll>
            );
          } else {
            return (
              <Typography className={classes.centerChildren}>
                <RateReview />
                <Trans>No comments in yet!</Trans>
              </Typography>
            );
          }
        }}
      </Query>
    </Paper>
  );
}

export default ScrollableComments;
