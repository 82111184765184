import React, { useEffect, useContext } from 'react';

import { Grid } from '@material-ui/core';

import { PayloadFieldsProps } from 'itemTypes';
import { DocumentBuilder } from 'compositions';
import { DocumentStyleUploader, PDFViewer } from 'components';
import { withLockedContext, LockedContext } from 'contexts/lockedContext';
import GuidedEditorTabs from './GuidedEditorTabs';
import AssociatedSupplierCard from './AssociatedSupplierCard/index';
import { useVersion } from './gql';
import { InputMethod } from '__generated__/globalTypes';

interface Props {
  inputMethod: InputMethod;
  itemType: string;
}

function PayloadFields(props: Props & PayloadFieldsProps) {
  const { inputMethod, itemType, layoutClasses } = props;
  const { data, loading, error, refetch } = useVersion(props.versionId || '');
  const { setLocked } = useContext(LockedContext);

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching VVP: ${err}`);
    });
  }, [inputMethod, refetch]);

  useEffect(() => {
    if (data) setLocked(data.supplierAuditPlanVersion.locked);
  }, [data, setLocked]);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Supplier Audit Plan version ${props.versionId}\n${error}`);

  const currentVersion = data.supplierAuditPlanVersion;

  const renderInputMethod = () => {
    switch (inputMethod) {
      case InputMethod.BODY:
        return (
          <>
            <AssociatedSupplierCard currentVersion={currentVersion} />
            <DocumentBuilder
              itemType={props.itemType}
              refetchQueries={['SupplierAuditPlanVersionQuery']}
              richTextOptionSet="large"
              layoutClasses={props.layoutClasses}
              versionData={currentVersion}
            />
          </>
        );
      case InputMethod.UPLOAD:
        return <Grid item xs={12} spacing={2} container justify="flex-start" alignItems="flex-start">
          <AssociatedSupplierCard currentVersion={currentVersion} />
          <Grid item xs={7} className={layoutClasses.gridGutterLeft} style={{ marginLeft: 'auto' }}>
            <DocumentStyleUploader versionData={currentVersion} onUpdate={() => refetch()} displayUploadInfo />
          </Grid>
          {currentVersion.attachment &&
            <Grid item xs={12}>
              <PDFViewer url={currentVersion.attachment.url} />
            </Grid>
          }
        </Grid>
      default:
        return (
          <>
            <AssociatedSupplierCard currentVersion={currentVersion} />
            <GuidedEditorTabs
              itemType={itemType}
              currentVersion={currentVersion}
              layoutClasses={props.layoutClasses}
              locked={currentVersion.locked}
              onUpdate={refetch}
            />
          </>
        );
    }
  };
  return <>
    {renderInputMethod()}
  </>;
}

export default withLockedContext(PayloadFields);
