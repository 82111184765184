import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { TRIO_FIELDS } from 'components/TableTrio/gql';

const FIELDS = {
  name: 'String',
  role: 'String',
  email: 'String',
  phone: 'String',
  website: 'String',
  contactNotes: 'String',
  erpNotes: 'String',
  qualificationNotes: 'String',
  suppliedPart: 'Boolean',
  suppliedService: 'Boolean',
  suppliedOther: 'Boolean',
  suppliedOtherText: 'String',
  suppliedScope: 'String',
  riskLevel: 'String', // may go after enum type at some point (currently this is the same way CO does it)
  riskLevelJustification: 'String',
  plannedQualificationQuestionnaire: 'Boolean',
  plannedQualificationAudit: 'Boolean',
  plannedQualificationOther: 'Boolean',
  plannedQualificationOtherText: 'String',
  reasonForSupplierRejection: 'String',
  reasonForRevocation: 'String',
};

// not making into a function at this point to not make a bunch of decisions and definetly expanding the scope
const FIELDS_BODY = Object.keys(FIELDS).join('\n');
const FIELDS_MUTATION_HEADER = Object.entries(FIELDS)
  .map(([name, whatType]) => `$${name}: ${whatType}`)
  .join('\n');
const FIELDS_MUTATION_SECOND_HEADER = Object.entries(FIELDS)
  .map(([name, _whatType]) => `${name}: $${name}`)
  .join('\n');

const supplierVersionFragment = gql`
  fragment SupplierVersionFragment on SupplierVersion {
    id
    locked
    approver {
      id
      fullName
    }
    owner {
      id
      fullName
    }
    currentStatus {
      id
      name
      createdAt
    }
    title
    versionIdentifier
    partsAccessToEdit
    ${FIELDS_BODY}
    ${TRIO_FIELDS}
    supplierAuditReportVersions {
      id
      versionIdentifier
      title
      createdAt
      supplierPassedAudit
      currentStatus {
        id
        name
      }
      item {
        id
        customIdentifier
        itemType {
          id
          displaySlug
          userCreatable
        }
      }
      owners {
        id
      }
      approvers {
        id
      }
      supplierAuditPlanVersion {
        id
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
    }
    supplierQuestionnaireRecordVersions {
      id
      versionIdentifier
      title
      createdAt
      currentStatus {
        id
        name
      }
      item {
        id
        customIdentifier
        itemType {
          id
          displaySlug
          userCreatable
        }
      }
      owners {
        id
      }
      approvers {
        id
      }
    }
  }
`;

export const GET_SUPPLIER_VERSION = gql`
  query SupplierVersionQuery($id: ID!) {
    supplierVersion(id: $id) {
      id
      locked
      currentStatus {
        id
        name
      }
      title
      versionIdentifier
      partsAccessToEdit
      approver {
        id
        fullName
      }
      owner {
        id
        fullName
      }
      item {
        id
        versions {
          ...SupplierVersionFragment
        }
      }
      ${FIELDS_BODY}
      ${TRIO_FIELDS}
      supplierAuditReportVersions {
        id
        versionIdentifier
        title
        createdAt
        supplierPassedAudit
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
        owners {
          id
        }
        approvers {
          id
        }
        supplierAuditPlanVersion {
          id
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
      supplierQuestionnaireRecordVersions {
        id
        versionIdentifier
        title
        createdAt
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
        owners {
          id
        }
        approvers {
          id
        }
      }
    }
  }
  ${supplierVersionFragment}
`;

export const UPDATE_SUPPLIER_VERSION = gql`
    mutation UpdateSupplierVersion(
        $id: ID!
        ${FIELDS_MUTATION_HEADER}
    ) {
        updateSupplierVersion(
            id: $id
            ${FIELDS_MUTATION_SECOND_HEADER}
        ) {
            supplierVersion {
                id
                ${FIELDS_BODY}
            }
        }
    }
`;

export function useVersion(id: string) {
  return useQuery<any, any>(GET_SUPPLIER_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

export function useUpdateVersion() {
  const [mutation] = useMutation(UPDATE_SUPPLIER_VERSION, {
    refetchQueries: ['SupplierVersionQuery', 'timelineQuery'],
  });
  return (params) => {
    if (!params.variables) params = { variables: params };
    return mutation(params);
  };
}
