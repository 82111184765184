import React from 'react';

import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import Grid from '@material-ui/core/Grid';
import { PlaylistAddCheck } from '@material-ui/icons';

import { ApolloQueryResult } from 'apollo-client';
import { useMutation } from 'react-apollo';

import { DateTime } from 'luxon';

import UploadFileIcon from 'assets/icons/FileUpload';
import { DirectDocumentUploader, PDFViewer, InfoList, Spacer } from 'components';
import Panel from 'components/core/Panel';
import fileNameFromURL from 'utils/fileNameFromURL';

import { DESTROY_REFERENCED_DOCUMENT, CREATE_REFERENCED_DOCUMENT } from './gql';
import { useStyles } from './styles';
import {
  SupplierQuestionnaireRecordVersion,
  SupplierQuestionnaireRecordVersion_supplierQuestionnaireRecordVersion,
} from '../__generated__/SupplierQuestionnaireRecordVersion';
import { Typography } from '@material-ui/core';
interface RecordAttachmentProps {
  currentVersion: SupplierQuestionnaireRecordVersion_supplierQuestionnaireRecordVersion;
  layoutClasses: { [className: string]: string };
  refetch: () => Promise<ApolloQueryResult<SupplierQuestionnaireRecordVersion>>;
}

const QuestionnaireInformation = (props: RecordAttachmentProps) => {
  const {
    currentVersion: { id, locked, unexecutedRecordAttachment, executedRecordAttachment, supplierVersion },
    refetch,
  } = props;

  const classes = useStyles();
  const isSupplierSelected = !!supplierVersion;
  
  const [createAttachment] = useMutation(CREATE_REFERENCED_DOCUMENT);
  const [destroyAttachment] = useMutation(DESTROY_REFERENCED_DOCUMENT);

  const deleteAttachment = async (attachmentId: string) => {
    await destroyAttachment({ variables: { id: attachmentId } });
  };

  const createExecutedAttachment = async (attachmentUrl: string) => {
    const payload = {
      variables: {
        attachmentURL: attachmentUrl,
        versionId: id,
        isExecutedAttachment: true,
      },
    };
    try {
      await createAttachment(payload);
    } catch (e) {
      throw new Error(`Error creating attachment (versionId: ${payload.variables.versionId}): ${e.message}`);
    }
  };

  return (
    <>
      <Grid container item className={classes.grid}>
        <Grid item xs={5}>
            {isSupplierSelected && (
              <InfoList
                headerText={'Associated Supplier'}
                Icon={PlaylistAddCheck}
                data={[
                  { title: supplierVersion!.item.customIdentifier, value: supplierVersion!.title },
                  {
                    title: 'Effective Date',
                    value: !!supplierVersion!.releasedAt
                      ? DateTime.fromISO(supplierVersion!.releasedAt).toLocaleString(DateTime.DATE_FULL)
                      : 'NOT RELEASED',
                  },
                  { title: 'Version', value: supplierVersion!.versionIdentifier },
                ]}
              />
            )}
            {!isSupplierSelected && (
              <InfoList
                headerText={'No Supplier Selected'}
                Icon={PlaylistAddCheck}
                data={[]}
              />
            )}
        </Grid>
        <Grid item xs={7} className={classes.gridGutterLeft}>
            <Panel className={classes.fullHeight}>
              <Panel.Heading icon={DescriptionIcon}>Supplier Questionnaire Form</Panel.Heading>
              <div>
              <DirectDocumentUploader
                attrName={'original_form'}
                attachmentId={unexecutedRecordAttachment && unexecutedRecordAttachment.id}
                filename={unexecutedRecordAttachment && fileNameFromURL(unexecutedRecordAttachment.url)}
                fileUrl={unexecutedRecordAttachment && unexecutedRecordAttachment.url}
                locked
                readOnly
                versionId={id}
              />
              {unexecutedRecordAttachment && (
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <Typography variant="body2" style={{ textTransform: 'uppercase', fontSize: 12, fontWeight: 600 }}>Upload information:</Typography>
                  <Typography variant="body2" style={{ marginLeft: '10px', fontSize: 12 }}>{DateTime.fromISO(unexecutedRecordAttachment.createdAt, { zone: "EST" }).toFormat('MMMM d, yyyy HH:mm a')} by {unexecutedRecordAttachment.creator.fullName}</Typography>
                </div>
              )}
              </div>
              <Spacer factor={1} />
              <Panel.Heading icon={UploadFileIcon}>Completed Questionnaire Form</Panel.Heading>
              <div>
              <DirectDocumentUploader
                attrName={'attachment'}
                attachmentId={executedRecordAttachment && executedRecordAttachment.id}
                filename={executedRecordAttachment && fileNameFromURL(executedRecordAttachment.url)}
                fileUrl={executedRecordAttachment && executedRecordAttachment.url}
                locked={locked}
                createAttachment={createExecutedAttachment}
                deleteAttachment={deleteAttachment}
                onCompleted={refetch}
                versionId={id}
              />
              {executedRecordAttachment && (
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <Typography variant="body2" style={{ textTransform: 'uppercase', fontSize: 12, fontWeight: 600 }}>Upload information:</Typography>
                  <Typography variant="body2" style={{ marginLeft: '10px', fontSize: 12 }}>{DateTime.fromISO(executedRecordAttachment.createdAt, { zone: "EST" }).toFormat('MMMM d, yyyy HH:mm a')} by {executedRecordAttachment.creator.fullName}</Typography>
                </div>
              )}
                <Spacer factor={2} />
              </div>
            </Panel>
        </Grid>
      </Grid>
      <Grid container item className={classes.grid}>
        <Grid item xs={12} className={`${classes.pdfView}`}>
          {props.currentVersion && props.currentVersion.executedRecordAttachment && (
            <PDFViewer url={props.currentVersion.executedRecordAttachment.url} />
          )}        
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionnaireInformation;
