import gql from 'graphql-tag';

export default gql`
  mutation updateTitle($input: UpdateVirtualAttributeInput!) {
    updateVirtualAttribute(input: $input) {
      virtualAttribute {
        attrName
        stringValue
      }
    }
  }
`;
