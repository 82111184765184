import React from 'react';
import flowRight from 'lodash/flowRight';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useQuery } from 'react-apollo';
import withCurrentUser from 'compositions/WithCurrentUser';
import SectionLabel from 'components/core/SectionLabel';
import AgGridOverlay from 'components/AgGridTable/Overlay';

import { currentUsersTrainingRecordsQuery } from './gql';
import { useStyles } from './styles';
import AgGridTable from 'components/AgGridTable';
import { History } from '@material-ui/icons';

const columns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
    suppressMenu: true,
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
    suppressMenu: true,
    flex: 1,
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
    suppressMenu: true,
  },
  {
    field: 'currentVersion.dueDate',
    headerName: 'Due Date',
    type: 'date',
    suppressMenu: true,
  },
];

function CurrentUsersTrainingRecordsList(props) {
  const { data, loading, error } = useQuery(currentUsersTrainingRecordsQuery, {
    variables: { userIds: [props.currentUser.id] },
  });
  const classes = useStyles();

  if (loading) return <div />;
  if (error) throw new Error(`Error loading pre compliance task categories: ${error}`);

  return (
    <Paper elevation={1}>
      <div className={classes.content}>
        <SectionLabel icon={History}>
          <Typography
            variant="h3"
            component="span"
            style={{ fontSize: '18px', fontWeight: 400, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
          >
            My Assigned Training
          </Typography>
        </SectionLabel>
        <AgGridTable
          columns={columns}
          data={data.trainingRecordSearch}
          suppressSideBar={true}
          suppressViewOptions={true}
          noRowsOverlayComponent={AgGridOverlay}
          noRowsOverlayComponentParams={{ text: 'All assigned trainings are complete' }}
        />
      </div>
    </Paper>
  );
}

export default flowRight([withCurrentUser])(CurrentUsersTrainingRecordsList);
