import React from 'react';

import { StatusSegment } from './StatusSegment';

interface StatusHistoryProps {
  version?: any;
  statuses: any[];
  disabled: boolean;
  replace?: any[];
  isWorkflowSignatureShown?: boolean;
  workflowType?: string;
}

export function StatusHistory(props: StatusHistoryProps) {
  const { statuses, disabled, replace = [], version, isWorkflowSignatureShown, workflowType } = props;
  const lastIndex = statuses.length - 1;
  let prevStatus = null as any;

  return (
    <>
      {statuses.map((status, index) => {
        const replacement = replace.find((x) =>
          x.match({
            current: status.name,
            previous: prevStatus && prevStatus.name,
          }),
        );

        prevStatus = status;

        if (replacement) return replacement.render();

        return (
          <StatusSegment
            version={{ ...version, isWorkflowSignatureShown }}
            workflowType={workflowType}
            key={index}
            statusName={status.name}
            showBar={index > 0}
            active={index === lastIndex}
            completed
            disabled={disabled}
          />
        );
      })}
    </>
  );
}
