import React, { useState } from 'react';
import { useMutation } from 'react-apollo';

import {
  Button as MuiButton,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { Close, Contacts, Settings } from '@material-ui/icons';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import flowRight from 'lodash/flowRight';
import startCase from 'lodash/startCase';

import Button from 'components/Button';
import WithAllUsers from 'compositions/WithAllUsers';
import FailModal from 'compositions/TimelineGroup/components/FailModal';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';

import { ADD_USER, CREATE_ACCOUNT_CREATED_EMAIL } from './gql';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  allUsers: any;
  showSnackbar: () => void;
  onCreate: (value: any) => void;
  currentCompany: any;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      padding: theme.spacing(2),
    },
    title: {
      marginTop: theme.spacing(3),
      textAlign: 'center',
    },
    closeIcon: {
      float: 'right',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    formControl: {
      '& .MuiInputLabel-outlined': {
        fontSize: 12,
        transform: 'translate(14px, 13px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
    formHelperTextSticky: {
      position: 'absolute',
      bottom: theme.spacing(-3),
      left: 0,
    },
  }),
);

function CreateNewUserModal(props: Props) {
  const classes = useStyles();
  const { isOpen, onClose, allUsers, showSnackbar, onCreate } = props;

  const [isFailModalOpen, setIsFailModalOpen] = useState(false);

  const onUserCreationCompleted = (data) => {
    showSnackbar();
    createAccountCreatedEmail({
      variables: {
        input: {
          username: data.addUser.user.username,
        },
      },
    }).catch((err) => {
      throw new Error(`Error sending Account Created email: ${err}`);
    });
  };
  const [addUser, { loading, error }] = useMutation(ADD_USER, {
    update(_cache, { data }) {
      onCreate(data);
    },
    onCompleted: (data) => {
      onUserCreationCompleted(data);
    },
    refetchQueries: ['UserListQuery', 'ProfileQuery', 'withCurrentCompany_currentCompanyQuery'],
  });
  const [createAccountCreatedEmail] = useMutation(CREATE_ACCOUNT_CREATED_EMAIL);

  if (error) throw new Error(`Error creating new user: ${error}`);

  const userTypeOptions = [
    { value: 0, label: 'General' },
    { value: 2, label: 'Train & View' },
    { value: 1, label: 'View' },
  ];
  const allExistingEmailAddresses = allUsers.flatMap(({ emailAddress }) => emailAddress);
  const allExistingUsernames = allUsers.flatMap(({ username }) => username);

  const duplicateCheck = (list, value) => {
    return value && list.includes(value.toLowerCase()) ? false : true;
  };

  const validateEmail = (inputText) => {
    //eslint-disable-next-line
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return mailFormat.test(inputText) ? true : false;
  };

  const LoginValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .required('Email is required')
      .test('Unique', 'This email address is already associated with an account', (value) => {
        return duplicateCheck(allExistingEmailAddresses, value);
      })
      .test('Invalid', 'Please enter a valid email address', (value) => {
        return validateEmail(value);
      }),
    username: Yup.string()
      .required('Username is required')
      .test('Unique', 'This username is already associated with an account', (value) => {
        return duplicateCheck(allExistingUsernames, value);
      }),
    userType: Yup.string().required('User Type is required'),
  });

  const handleSubmit = (values) => {
    const seatsRemaining =
      (values.userType === 0 && props.currentCompany.remainingGeneralUserSeats > 0) ||
      (values.userType === 1 && props.currentCompany.remainingViewOnlyUserSeats > 0);

    if (!seatsRemaining) {
      setIsFailModalOpen(true);
    } else {
      addUser({
        variables: {
          firstName: startCase(values.firstName),
          lastName: startCase(values.lastName),
          emailAddress: values.email.toLowerCase(),
          title: startCase(values.title),
          username: values.username.toLowerCase(),
          userType: values.userType,
        },
      }).catch((err) => {
        throw new Error(`Error while creating new user: ${err}`);
      });
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Paper classes={{ root: classes.container }}>
          <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={onClose} color="action" />
          <Typography variant="h3" className={classes.title}>
            Create New User
          </Typography>
          <Grid container style={{ marginTop: 40 }} alignItems="center">
            <Grid item>
              <Contacts fontSize="small" color="secondary" />
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
                Basic information
              </Typography>
            </Grid>
          </Grid>
          <Formik
            initialValues={{ firstName: '', lastName: '', email: '', username: '', userType: '' }}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={LoginValidationSchema}
          >
            {({ isValid }) => (
              <Form translate="yes">
                <Field name="firstName">
                  {({ field, form: { errors, touched } }) => (
                    <FormControl error={errors.firstName && touched.firstName} fullWidth>
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        placeholder="First Name*"
                        error={errors.firstName && touched.firstName}
                      />
                      <FormHelperText>{touched.firstName && errors.firstName}</FormHelperText>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field, form: { errors, touched } }) => (
                    <FormControl error={errors.lastName && touched.lastName} fullWidth>
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        placeholder="Last Name*"
                        error={errors.lastName && touched.lastName}
                      />
                      <FormHelperText>{touched.lastName && errors.lastName}</FormHelperText>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form: { errors, touched } }) => (
                    <FormControl error={errors.email && touched.email} fullWidth>
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        placeholder="Email*"
                        error={errors.email && touched.email}
                      />
                      <FormHelperText>{touched.email && errors.email}</FormHelperText>
                    </FormControl>
                  )}
                </Field>
                <Field name="title">
                  {({ field, form: { errors } }) => (
                    <FormControl error={errors.title} fullWidth>
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        placeholder="Title"
                        error={errors.title}
                      />
                      <FormHelperText>{errors.title}</FormHelperText>
                    </FormControl>
                  )}
                </Field>
                <Grid container style={{ marginTop: 25 }} alignItems="center">
                  <Grid item>
                    <Settings fontSize="small" color="secondary" />
                  </Grid>
                  <Grid item style={{ marginLeft: 10 }}>
                    <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
                      Account Information
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item style={{ flexGrow: 1 }}>
                    <Field name="username">
                      {({ field, form: { errors, touched } }) => (
                        <FormControl
                          error={errors.username && touched.username}
                          fullWidth
                          style={{ position: 'relative' }}
                        >
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            placeholder="Username*"
                            error={errors.username && touched.username}
                            style={{ marginTop: 4 }}
                          />
                          <FormHelperText className={classes.formHelperTextSticky}>
                            {touched.username && errors.username}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                  <Grid item style={{ marginLeft: 10 }}>
                    <Field name="userType">
                      {({ field, form: { errors, touched } }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          error={errors.userType && touched.userType}
                          style={{ position: 'relative' }}
                        >
                          <InputLabel id="select-label">User Type*</InputLabel>
                          <Select
                            {...field}
                            id="select"
                            error={errors.userType && touched.userType}
                            labelId="select-label"
                            variant="outlined"
                            margin="dense"
                            style={{ width: 150 }}
                            label="User Type*"
                            required
                          >
                            <MenuItem key="none" value="">
                              <em>User Type*</em>
                            </MenuItem>
                            {userTypeOptions.map((option) => {
                              return (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText className={classes.formHelperTextSticky}>
                            {touched.userType && errors.userType}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container justify="center" style={{ marginTop: 36 }}>
                  <Grid item>
                    <MuiButton
                      color="primary"
                      variant="outlined"
                      style={{ textTransform: 'uppercase', width: 140 }}
                      onClick={onClose}
                      type="button"
                    >
                      Cancel
                    </MuiButton>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!isValid || loading}
                      style={{ textTransform: 'uppercase', marginLeft: 8, width: 140 }}
                      type="submit"
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Modal>
      <FailModal
        open={isFailModalOpen}
        closeModal={() => setIsFailModalOpen(false)}
        titleText="No more Licenses"
        subtitleText="This user cannot be created as there are no more available licenses. Please contact support@nemedio.com to purchase additional licenses."
        buttonText="OK"
      />
    </>
  );
}

export default flowRight([WithAllUsers, withCurrentCompany])(CreateNewUserModal);
