import React, { useContext } from 'react';
import { useOptions } from './gql';

const defaultValue: any = {
  probabilityLevels: [],
  severityLevels: [],
  harms: [],
  hazards: [],
};

export const OptionsContext = React.createContext(defaultValue);

export const OptionsProvider = ({ children }) => {
  const { data } = useOptions();
  const value = data || defaultValue;

  makeOptions(value.probabilityLevels);
  makeOptions(value.severityLevels);

  return <OptionsContext.Provider value={value}>{children}</OptionsContext.Provider>;
};

// NOTE: it unexpectedly was executing multiple times
// maybe cached stuff or something else
// anyway this was not the expected behavior internally

function makeOptions(xs) {
  if (!(xs[0] && xs[0].id == null)) xs.unshift({ id: null, name: '' });
  xs.forEach(toOption);
}

function toOption(x) {
  if (!x) return;
  if (x.value != null) return;
  x.value = x.id;
  x.label = x.name;
  return x;
}

export const useOptionsContext = () => useContext(OptionsContext);
