import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import { Mutation } from 'react-apollo';

import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  clearTextWrapper: {
    width: 220,
  },
  clearText: {
    color: theme.palette.primary.main,
    minWidth: 0,
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(19),
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.7,
    },
  },
  formControlLabel: {
    display: 'flex',
    flex: 1,
    maxWidth: 150,
  },
  patch: {
    borderRadius: theme.shape.borderRadius,
    height: 20,
    width: 20,
  },
  radioRoot: {
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  root: {
    width: '100%',
  },
});

const mutation = gql`
  mutation updateRadioValue($input: UpdateVirtualAttributeInput!) {
    updateVirtualAttribute(input: $input) {
      virtualAttribute {
        attrName
        stringValue
      }
    }
  }
`;

class RadioGroupField extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      formControlLabel: PropTypes.string.isRequired,
      patch: PropTypes.string.isRequired,
      radioRoot: PropTypes.string.isRequired,
      root: PropTypes.string.isRequired,
    }).isRequired,
    attrName: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    refetchQueries: PropTypes.array,
    versionId: PropTypes.string.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    refetchQueries: undefined,
    value: '',
    locked: true,
  };

  render() {
    const { classes, attrName, locked, options, refetchQueries, versionId, value, ...rest } = this.props;

    return (
      <Mutation
        onError={(error) => {
          console.log(error);
        }}
        mutation={mutation}
      >
        {(mutate) => (
          <FormControl component="fieldset" className={classes.root} disabled={locked}>
            <div className={classes.clearTextWrapper}>
              <Button
                classes={{ text: classes.clearText }}
                color="primary"
                variant="text"
                onClick={(event) => {
                  mutate({
                    variables: {
                      input: {
                        attrName,
                        stringValue: '',
                        versionId,
                      },
                    },
                    refetchQueries,
                  });
                }}
              >
                <Trans>Clear</Trans>
              </Button>
            </div>
            <RadioGroup
              name="radio-group-field-selector"
              onChange={(event) => {
                mutate({
                  variables: {
                    input: {
                      attrName,
                      stringValue: event.target.value,
                      versionId,
                    },
                  },
                  refetchQueries,
                });
              }}
              value={value || ''}
              {...rest}
            >
              {options.map(({ value, label, patchColor, ...rest }) => (
                <FormControlLabel
                  key={value}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio className={classes.radioRoot} disableRipple />}
                  label={
                    <Grid container item justify="space-between">
                      {label}
                      {patchColor && (
                        <div
                          className={classes.patch}
                          style={{
                            backgroundColor: patchColor,
                          }}
                        />
                      )}
                    </Grid>
                  }
                  value={value}
                  {...rest}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(RadioGroupField);
