import React, { useState } from 'react';

import { compose } from 'recompose';
import { DateTime } from 'luxon';
import { Mutation } from 'react-apollo';

import { Button, FormHelperText, TextField, Typography } from '@material-ui/core';

import { SoftwareInformation, Spacer } from 'components';
import WithCurrentUser from 'compositions/WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';

import FormLabel from './FormLabel';
import { useStyles } from './styles';
import { UPDATE_PASSWORD_MUTATION } from './gql';

interface UserMenuProps {
  classes: any;
  currentUser: any;
  currentCompany: any;
}

const UserMenu = (props: UserMenuProps) => {
  const classes = useStyles();
  const { currentUser, currentCompany } = props;

  const [hasSaved, setHasSaved] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]>([]);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const expiry = DateTime.fromISO(currentUser.passwordLastUpdatedAt).plus({
    days: currentCompany.requirePasswordChangeAfter,
  });
  const resetInputs = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  return (
    <div className={classes.userMenuWrapper}>
      <SoftwareInformation
        currentVersion={currentCompany.deployments.currentVersion.version}
        currentVersionDeployedAt={currentCompany.deployments.currentVersion.deployedAt}
        previousVersion={
          currentCompany.deployments.previousVersion ? currentCompany.deployments.previousVersion.version : null
        }
      />
      <Mutation
        mutation={UPDATE_PASSWORD_MUTATION}
        onError={() => {
          resetInputs();
          setHasSaved(false);
        }}
        onCompleted={(data) => {
          if (!data.updatePassword.errors) setHasSaved(true);
          else {
            resetInputs();
            setErrors(data.updatePassword.errors);
          }
        }}
      >
        {(mutate, { loading, error }) => {
          if (error) console.log(error);

          const serverError = errors.map((error: any) => {
            return (
              <>
                {error
                  .replace('Validation failed:', '')
                  .split(',')
                  .join('\r\n')}
              </>
            );
          });

          return (
            <>
              <div className={classes.topMenu}>
                <div className={classes.border} />
                <div className={classes.section}>
                  <Typography className={classes.h7}>Security Settings</Typography>
                </div>
                <div className={classes.section}>
                  <Typography className={classes.bold}>Password</Typography>
                </div>

                <div className={classes.passwordSettingsContainer}>
                  <div className={classes.section}>
                    <div className={classes.formRow}>
                      <FormLabel text={'Change Password'} />
                      <div className={classes.formRight}>
                        <Typography className={`${classes.caption}`} component="div" style={{ flex: 1 }}>
                          Expires {expiry.toFormat('MM/d')}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <Spacer factor={2} />

                  <div className={classes.section}>
                    <FormLabel text={'Current Password'} />
                    <TextField
                      value={currentPassword}
                      classes={{
                        root: classes.passwordTextFieldRoot,
                      }}
                      id="current-password"
                      type="password"
                      variant={'outlined'}
                      InputProps={{
                        autoComplete: 'off',
                        onChange: (e) => {
                          setCurrentPassword(e.currentTarget.value);
                        },
                        classes: {
                          input: classes.passwordTextFieldInput,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.section}>
                    <FormLabel text={'New Password'} />
                    <TextField
                      value={newPassword}
                      classes={{
                        root: classes.passwordTextFieldRoot,
                      }}
                      id="new-password"
                      type="text"
                      variant={'outlined'}
                      InputProps={{
                        autoComplete: 'off',
                        onChange: (e) => {
                          setNewPassword(e.currentTarget.value);
                        },
                        classes: {
                          input: classes.passwordTextFieldInput,
                        },
                      }}
                    />
                  </div>

                  <div className={classes.section}>
                    <FormLabel text={'Confirm Password'} />
                    <TextField
                      value={confirmPassword}
                      classes={{
                        root: classes.passwordTextFieldRoot,
                      }}
                      id="confirm-password"
                      type="text"
                      variant={'outlined'}
                      InputProps={{
                        autoComplete: 'off',
                        onChange: (e) => {
                          setConfirmPassword(e.currentTarget.value);
                        },
                        classes: {
                          input: classes.passwordTextFieldInput,
                        },
                      }}
                    />
                    {errors ? (
                      <FormHelperText className={classes.passwordValidation} component="span">
                        <Spacer factor={1} />
                        {serverError}
                      </FormHelperText>
                    ) : null}
                  </div>

                  <Spacer factor={2} />

                  <div className={classes.section}>
                    <Button
                      id="password-submit"
                      disabled={loading}
                      variant="outlined"
                      color="primary"
                      classes={{ root: classes.saveButton }}
                      fullWidth
                      onClick={() => {
                        const password = newPassword;
                        const passwordConfirmation = confirmPassword;

                        setErrors([]);
                        setHasSaved(false);
                        resetInputs();

                        mutate({
                          variables: {
                            input: {
                              currentPassword,
                              password,
                              passwordConfirmation,
                            },
                          },
                        });
                      }}
                    >
                      Save
                    </Button>
                    <Spacer factor={1} />
                    <Typography variant="body2" className={classes.specifications}>
                      *Password should have at least 8 alpha numeric characters which must include at least one number
                      and one letter
                    </Typography>
                  </div>
                  <div className={classes.section}>
                    {error && (
                      <Typography variant="body2" className={classes.errorText} id="error-message">
                        An error occurred. Please verify that your password is valid or contact support.
                      </Typography>
                    )}
                    {hasSaved && !error && (
                      <Typography variant="body2" id="success-message" color="primary">
                        Password has been updated
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Mutation>
    </div>
  );
};

export default compose(
  WithCurrentUser,
  withCurrentCompany,
)(UserMenu);
