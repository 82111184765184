import gql from 'graphql-tag';

export const LIST_COURSES = gql`
  query coursesQuery {
    courses {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      currentVersion {
        id
        approver {
          id
          firstName
        }
        createdAt
        currentStatus {
          id
          name
        }
        description
        learningObjective
        owner {
          id
          firstName
        }
        qualityDocumentVersions {
          id
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
        title
        versionIdentifier
        trainingType
        trainingFormat
      }
    }
  }
`;
