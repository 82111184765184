import React from 'react';

import { Typography } from '@material-ui/core';

import { ImageProfile, InitialsProfile } from 'components/UserProfileImage';

import { UserMultiSelect_users } from '../__generated__/UserMultiSelect';
import { useStyles } from './styles';

interface Props {
  user: UserMultiSelect_users;
}

function UserOption(props: Props) {
  const { user } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        {user.photoUrl ? (
          <ImageProfile url={user.photoUrl} />
        ) : (
          <InitialsProfile firstName={user.firstName} lastName={user.lastName} />
        )}
      </div>
      <div className={classes.infoContainer}>
        <Typography variant="body2">{user.fullName}</Typography>
        <Typography variant="subtitle2">
          {(user.jobRoles || []).map((role) => role.formattedRoleName).join(', ')}
        </Typography>
      </div>
    </div>
  );
}

export default UserOption;
