import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    idHeader: {
      marginLeft: 50,
      marginRight: 82,
    },
    justificationContainer: { margin: `20px 0` },
    emptyDisclaimer: {
      marginLeft: 24,
    },
    itemAddErrorText: {
      paddingTop: 8,
    },
    icon: {
      display: 'inline-block !important',
    },
    header: {
      '& > svg': {
        marginRight: 6,
      },
      '& > span': {
        verticalAlign: 'middle',
      },
    },
    idCell: {
      display: 'flex',
    },
    tableContainer: {
      '& > .MuiPaper-root': {
        boxShadow: 'none',
      },
      paddingTop: 0,
      '& table': {
        paddingTop: 42,
      },
      '& td(:first-child)': {
        borderRight: '1px solid #dfdfdf',
      },
      '& .MuiTableCell-root': {
        position: 'relative',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    noticeContainer: {
      position: 'relative',
      width: 'calc(100% - 260px)',
      marginLeft: '260px',
    },
    noticeSection: {
      position: 'absolute',
      bottom: 'calc(100% - 5px)',
      right: 0,
      width: '100%',
      borderBottom: '1px solid #33BCB4',
      marginBottom: theme.spacing(1),
    },
    noticeHeading: {
      // @ts-ignore
      color: theme.palette.primary.main,
    },
    noticeMessage: {
      paddingLeft: 20,
    },
  }),
);
