import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import get from 'lodash/get';

import { VersionAndStatusDot } from 'components';
import QuotedSection from 'components/core/QuotedSection';
import linkToItem from 'utils/linkToItem';

import { useStyles } from './styles';


function VVReportReleased(props: any) {
  const classes = useStyles();
  const isVVR = props.itemTypeMetadata.displaySlug === 'v-v-report';

  useEffect(() => {
    if (!isVVR || props.currentVersion.mayTransitionToOwnerApproval) {
      props.handleComplete(true, { forgetThisStep: true });
    }
  }, [props, props.handleComplete, isVVR]);

  if (!isVVR) return <div />;

  const associatedVvPlan= get(props, 'currentVersion.associatedVvPlan')
  const validationRecords= get(props, 'currentVersion.validationRecords')
  const verificationRecords=get(props, 'currentVersion.verificationRecords')

  const mayTransitionToOwnerApproval = get(props, 'currentVersion.mayTransitionToOwnerApproval');
  if (mayTransitionToOwnerApproval) return <div />;
  const notReleasedAssociatedVvPlan = [associatedVvPlan].filter((item: any) => item.version.currentStatus.name !== 'released')[0];
  const notReleasedValidationRecords = validationRecords.filter((item: any) => item.currentStatus.name !== 'checked');
  const notReleasedVerificationRecords = verificationRecords.filter((item: any) => item.currentStatus.name !== 'checked');
  
  return (
    <>
      <div className={classes.heading}>
        <Typography variant="h3">Not Allowed</Typography>
      </div>
      <Typography component="p" variant="h5" align="left">
        This V&V Report is associated with the following items and cannot be closed until they are released.
      </Typography>
      <QuotedSection className={classes.container}>
        <ul className={classes.list}>
          {notReleasedAssociatedVvPlan && <Link to={linkToItem('v-v-plan', notReleasedAssociatedVvPlan.version.item.id)}>
            <li className={classes.listEntry}>
              <Typography variant="h6" component="p" className={classes.text}>
                {notReleasedAssociatedVvPlan.version.item.customIdentifier}: {notReleasedAssociatedVvPlan.version.title}
              </Typography>
              <VersionAndStatusDot
                version={notReleasedAssociatedVvPlan.version.versionIdentifier}
                status={notReleasedAssociatedVvPlan.version.currentStatus.name}
              />
            </li>
          </Link>}
          {notReleasedValidationRecords.map((valRecord) => (
            <Link to={linkToItem('validation-record', valRecord.item.id)}>
              <li className={classes.listEntry}>
                <Typography variant="h6" component="p" className={classes.text}>
                  {valRecord.item.customIdentifier}: {valRecord.title}
                </Typography>
                <VersionAndStatusDot version={valRecord.versionIdentifier} status={valRecord.currentStatus.name} />
              </li>
            </Link>
          ))}
          {notReleasedVerificationRecords.map((verRecord) => (
            <Link to={linkToItem('verification-record', verRecord.item.id)}>
              <li className={classes.listEntry}>
                <Typography variant="h6" component="p" className={classes.text}>
                  {verRecord.item.customIdentifier}: {verRecord.title}
                </Typography>
                <VersionAndStatusDot version={verRecord.versionIdentifier} status={verRecord.currentStatus.name} />
              </li>
            </Link>
          ))}
          {validationRecords.map((valRecord) =>
            valRecord.deviationVersions
              .filter((deviationVersion) => deviationVersion.currentStatus.name !== 'closed_deviation')
              .map((deviation) => (
              <Link to={linkToItem('deviation', deviation.item.id)}>
                <li className={classes.listEntry}>
                  <Typography variant="h6" component="p" className={classes.text}>
                    {deviation.item.customIdentifier}: {deviation.title}
                  </Typography>
                  <VersionAndStatusDot version={deviation.versionIdentifier} status={deviation.currentStatus.name} />
                </li>
              </Link>
            )),
          )}
          {verificationRecords.map((verRecord) =>
            verRecord.deviationVersions
              .filter((deviationVersion) => deviationVersion.currentStatus.name !== 'closed_deviation')
              .map((deviation) => (
              <Link to={linkToItem('deviation', deviation.item.id)}>
                <li className={classes.listEntry}>
                  <Typography variant="h6" component="p" className={classes.text}>
                    {deviation.item.customIdentifier}: {deviation.title}
                  </Typography>
                  <VersionAndStatusDot version={deviation.versionIdentifier} status={deviation.currentStatus.name} />
                </li>
              </Link>
            )),
          )}
        </ul>
      </QuotedSection>
    </>
  );
}

export default VVReportReleased;
