import React from 'react';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { navigate } from '@reach/router';

import { YesOrNo } from '../views';
import useRemoveMemberFromSetMutation from 'compositions/useRemoveMemberFromSetMutation';

function AssociatedSetModal({
  requirementsSetMemberId,
  parentSetName,
  parentSetVersion,
  parentSetStatus,
  parentSetLocked,
  parentSetDisplaySlug,
  currentVersion,
  ...props
}) {
  const [removeMemberFromSetMutation, { error }] = useRemoveMemberFromSetMutation(
    parentSetName,
    requirementsSetMemberId,
  );
  if (error) throw new Error(`Failed to remove member from ${parentSetName} in workflow: ${error}`);

  return (
    <YesOrNo
      disableOnSubmit
      disableNo={parentSetLocked}
      heading="Associated Set"
      prompt={`${currentVersion.setMember.setItemVersion.item.customIdentifier}: ${
        currentVersion.setMember.setItemVersion.title
      } ${parentSetVersion} ${parentSetStatus}`}
      yesLabel={i18n._(t`View Set`)}
      noLabel={i18n._(t`Remove From Set`)}
      handleComplete={async (value) => {
        if (value) {
          await navigate(
            `/category/${parentSetDisplaySlug}${
              currentVersion.setMember.setItemVersion.item.userCreatable
                ? `/${currentVersion.setMember.setItemVersion.item.id}`
                : ''
            }`,
          );
        } else {
          await removeMemberFromSetMutation();
          props.handleClose();
        }
      }}
    />
  );
}

export default AssociatedSetModal;
