import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';

const P = ({ children }) => (
  <Typography variant="body2" gutterBottom>
    {children}
  </Typography>
);
const NA = () => <P>N/A</P>;

const useStyles = makeStyles((theme) => ({
  list: {
    ...theme.typography.body2,
    listStyleType: 'none',
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    marginLeft: 0,
    paddingLeft: 8,
    '&> li': {
      padding: '2px 0',
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function TraceList({ traces }) {
  const classes = useStyles();

  return (
    <ul className={classes.list}>
      {traces.map((trace) => (
        <li key={`${trace[0].id}-${trace[1].id}`}>
          <span className={classes.bold}>
            {trace[0].item.customIdentifier} {trace[0].versionIdentifier}
          </span>{' '}
          is no longer traced to{' '}
          <span className={classes.bold}>
            {trace[1].item.customIdentifier} {trace[1].versionIdentifier}
          </span>
        </li>
      ))}
    </ul>
  );
}

function VersionList({ versions }) {
  const classes = useStyles();

  return (
    <ul className={classes.list}>
      {versions.map((v) => (
        <li key={v.id}>
          <span className={classes.bold}>{v.item.customIdentifier}</span>
        </li>
      ))}
    </ul>
  );
}

function ItemLinksList({ links }) {
  const classes = useStyles();

  const getLinkData = (link) => {
    const isVersion = link.__typename && link.__typename.endsWith('Version');
    const identifier = isVersion ? link.item.customIdentifier : link.customIdentifier;
    const version = isVersion ? link.item.currentVersion.versionIdentifier : link.currentVersion.versionIdentifier;

    return { identifier, version, isVersion };
  };

  const renderLink = (link) => {
    const [up, down] = link.map(getLinkData);

    return (
      <li key={`${up.identifier}-${down.identifier}`}>
        <span className={classes.bold}>
          {up.identifier} {up.version}
        </span>
        {' <> '}
        <span className={classes.bold}>
          {down.identifier} {down.version}
        </span>
      </li>
    );
  };

  return <ul className={classes.list}>{links.map((link) => renderLink(link))}</ul>;
}

function SetDiff({ diff, itemType }) {
  const removed = !diff.versionsRemoved.length ? <NA /> : <VersionList versions={diff.versionsRemoved} />,
    added = !diff.versionsAdded.length ? <NA /> : <VersionList versions={diff.versionsAdded} />,
    traces = !diff.tracesRemoved.length ? <NA /> : <TraceList traces={diff.tracesRemoved} />,
    links = !diff.linksConverted.length ? <NA /> : <ItemLinksList links={diff.linksConverted} />,
    classes = useStyles();
  return (
    <div>
      <div>
        <P>
          The following <span className={classes.bold}>{itemType}</span> no longer exist in the selected set:
        </P>
        {removed}
      </div>
      <div>
        <P>All traces to these items will be removed by the system:</P>
        {traces}
      </div>
      <div>
        <P>
          The following <span className={classes.bold}>{itemType}</span> are new in this set and have no traces
          associated with them:
        </P>
        {added}
      </div>
      <div>
        <P>The following Links will be imported into this Trace Matrix as traces:</P>
        {links}
      </div>
    </div>
  );
}

export default SetDiff;
