import { WorkflowDefinition, WorkflowTransition } from 'compositions/TimelineGroup/steps/types';

import AssociatedChange from '../shared/AssociatedChange';
import CreateNewVersion from './CreateNewVersion';
import CancelRetirement from './CancelRetirement';
import InitiateRetirement from './InitiateRetirement';
import ReasonForChange from './ReasonForChange';
import ReleaseOrReject from './ReleaseOrReject';
import SummaryOfChange from './SummaryOfChange';
import ResolveRejection from './ResolveRejection';
import Cancel from './Cancel';
import RetireOrRejectWithoutCO from './RetireOrRejectWithoutCO';
import RetireOrReject from './RetireOrReject';

const riskNoteWorkflowSteps: WorkflowDefinition = {
  initiateRetirement: InitiateRetirement as WorkflowTransition,
  cancelRetirement: CancelRetirement as WorkflowTransition,
  createNewVersion: CreateNewVersion as WorkflowTransition,
  reasonForChange: ReasonForChange as WorkflowTransition,
  releaseOrReject: ReleaseOrReject as WorkflowTransition,
  resolveRejection: ResolveRejection as WorkflowTransition,
  summaryOfChange: SummaryOfChange as WorkflowTransition,
  cancel: Cancel as WorkflowTransition,
  retireOrRejectWithoutCO: RetireOrRejectWithoutCO as WorkflowTransition,
  retireOrReject: RetireOrReject as WorkflowTransition,
  associatedChange: AssociatedChange as WorkflowTransition,
};

export default riskNoteWorkflowSteps;
