import { t } from '@lingui/macro';
import Portrait from '@material-ui/icons/Portrait';

import { ItemTypeDefinition } from 'itemTypes';
import slugs from '../../displaySlugs';
import RecordPage from './RecordPage';
import { DocumentToolbar } from '../mixins/Document';
import PayloadFields from './PayloadFields';
import { listViewQuery } from './gql';
import Views from 'workspaces/views';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

export default {
  itemType: 'trainingPlan',
  displaySlug: slugs['trainingPlan'],
  label: t`Training Plan`,
  categoryLabel: t`Training Plans`,
  icon: Portrait,
  RecordPage,
  listViewColumns,
  listViewQuery,
  listWorkspace: [Views.Table, Views.LinksGraph],
  recordWorkspace: [Views.Builder, Views.Effective, Views.Versions],
  EffectiveViewOptions: DocumentToolbar,
  PayloadFields,
} as ItemTypeDefinition;
