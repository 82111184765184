import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextAreaAndButton } from 'components';
import { Trans } from '@lingui/macro';

import styles from './styles';
import { Spacer } from 'components';

class Justify extends Component {
  constructor(props) {
    super(props);
    this.state = { linkedChangeJustificationText: null };
  }

  getCurrentValue = (key) => {
    if (this.state[key] !== null && this.state[key] !== undefined) return this.state[key];

    if (this.props.unsavedValue !== null && this.props.unsavedValue !== undefined) return this.props.unsavedValue;

    return this.props.currentVersion[key];
  };

  render() {
    const { allowNull, classes, handleComplete } = this.props;
    // const justifyText = this.getCurrentValue('linkedChangeJustificationText');
    const { linkedChangeJustificationText } = this.state;
    return (
      <div>
        <div className={classes.heading}>
          <Typography variant="h3">
            <Trans>Justification for no Change Order</Trans>
          </Typography>

          <Spacer factor={1} />

          <Typography variant="body2">
            <Trans>I certify that this change does not require a Change Order.</Trans>
          </Typography>
        </div>

        <Spacer factor={4} />

        <TextAreaAndButton
          buttonId="justify-next"
          buttonDisabled={
            allowNull
              ? false
              : linkedChangeJustificationText === '' || !this.getCurrentValue('linkedChangeJustificationText')
          }
          onTextChange={(evt) => this.setState({ linkedChangeJustificationText: evt.target.value })}
          onButtonClick={() => handleComplete(this.getCurrentValue('linkedChangeJustificationText'))}
          buttonText={'Next'}
          label={<Trans>Impact Assessment</Trans>}
          rows={4}
          initialValue={this.getCurrentValue('linkedChangeJustificationText')}
        />
      </div>
    );
  }
}
export default withStyles(styles)(Justify);
