import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Spacer } from 'components';

const styles = (theme) => ({
  container: {
    width: 'auto',
  },
  border: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(0.5),
  },
  subheader: {
    minWidth: 210,
    position: 'relative',
  },
});

function MenuHeading({ text, subheader, classes }) {
  return (
    <div className={classes.container}>
      <Typography component="p" variant="button">
        {text}
      </Typography>
      {subheader && (
        <Typography variant="subtitle2" className={classes.subheader}>
          {subheader}
        </Typography>
      )}
      <Spacer factor={1} />
    </div>
  );
}

export default withStyles(styles)(MenuHeading);
