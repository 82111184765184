import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    ...theme.mixins.gridLayout,
    icon: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.5),
      color: theme.palette.blueDark,
    },
    iconTextStack: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    itemNotes: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
