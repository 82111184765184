import gql from "graphql-tag";

const addLinkMutation = gql`
  mutation CreateLink($input: CreateItemLinkInput!) {
    createItemLink(input: $input) {
      itemLinks {
        id
      }
      versionTraces {
        id
      }
    }
  }
`;

const linksQuery = gql`
  query LinksQuery(
    $itemType: String!
    $onlyReleased: Boolean
    $parentItemType: String
    $parentItemTypeCollectionName: String
    $includeDraft: Boolean
    $includeRetired: Boolean
  ) {
    itemSummaries(
      itemType: $itemType
      onlyReleased: $onlyReleased
      parentItemType: $parentItemType
      includeDraft: $includeDraft
      includeRetired: $includeRetired
      parentItemTypeCollectionName: $parentItemTypeCollectionName
    ) {
      ...ItemSummaryFields
      upstreamLinks {
        ...ItemSummaryFields
        downstreamLinks {
          ...OtherConnectionfields
        }
        upstreamLinks {
          ...ItemSummaryFields
          downstreamLinks {
            ...OtherConnectionfields
          }
          upstreamLinks {
            ...ItemSummaryFields
            downstreamLinks {
              ...OtherConnectionfields
            }
            upstreamLinks {
              ...ItemSummaryFields
              downstreamLinks {
                ...OtherConnectionfields
              }
              upstreamLinks {
                ...ItemSummaryFields
                downstreamLinks {
                  ...OtherConnectionfields
                }
                upstreamLinks {
                  ...ItemSummaryFields
                  downstreamLinks {
                    ...OtherConnectionfields
                  }
                  upstreamLinks {
                    ...ItemSummaryFields
                    downstreamLinks {
                      ...OtherConnectionfields
                    }
                    upstreamLinks {
                      ...ItemSummaryFields
                      downstreamLinks {
                        ...OtherConnectionfields
                      }
                      upstreamLinks {
                        ...ItemSummaryFields
                        downstreamLinks {
                          ...OtherConnectionfields
                        }
                        upstreamLinks {
                          ...ItemSummaryFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      downstreamLinks {
        ...ItemSummaryFields
        upstreamLinks {
          ...OtherConnectionfields
        }
        downstreamLinks {
          ...ItemSummaryFields
          upstreamLinks {
            ...OtherConnectionfields
          }
          downstreamLinks {
            ...ItemSummaryFields
            upstreamLinks {
              ...OtherConnectionfields
            }
            downstreamLinks {
              ...ItemSummaryFields
              upstreamLinks {
                ...OtherConnectionfields
              }
              downstreamLinks {
                ...ItemSummaryFields
              }
            }
          }
        }
      }
    }
  }
  fragment ItemSummaryFields on ItemSummary {
    id
    itemType
    customIdentifier
    currentStatus
    currentVersion
    mayRemoveLinks
    mayNotRemoveLinksBecause
    referenceId
    releaseStatus
    releaseVersion
    stringifiedMostImportantData
    title
    harm {
      id
      name
    }
    hazard {
      id
      name
    }
    sequenceOfEvents
    hazardousSituation
  }
  fragment OtherConnectionfields on ItemSummary {
    id
    itemType
    customIdentifier
    title
  }
`;

const deleteLink = gql`
  mutation DeleteLink($upstreamId: ID!, $downstreamId: ID!) {
    deleteItemLink(
      input: { upstreamItemId: $upstreamId, downstreamItemId: $downstreamId }
    ) {
      errors
      success
      versionTraceRemoved
    }
  }
`;

export { addLinkMutation, deleteLink, linksQuery };
