import React, { FC, useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';

import { Box } from '@material-ui/core';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';

import { IconButton } from 'components';
import ChipItem, { LinkType, StatusVersionType } from 'components/ChipItem';
import NotificationCTA from './GenericNotificationListItem/components/NotificationCTA';
import ItemSearch from '../../ItemSearch';
import NewItem from '../../NewItem';

import { useUpdateTask } from 'pages/Task/Id/gql';
import { useStyles } from './GenericNotificationListItem/styles';
import { currentUser_currentUser } from '../../__generated__/currentUser';
import {
  addTaskReferenceToItemVersionMutation,
  removeTaskReferenceFromItemVersionMutation,
} from './GenericNotificationListItem/gql';
import { createItem_createItem_item } from '../../NewItem/ItemTypeSelector/__generated__/createItem';
import { userNotificationsQuery_user_esignatureRequiredNotifications_precedingStatus_version_taskReferencedVersions } from '../__generated__/userNotificationsQuery';

type TaskReferencedVersions =
  | userNotificationsQuery_user_esignatureRequiredNotifications_precedingStatus_version_taskReferencedVersions
  | userNotificationsQuery_user_esignatureRequiredNotifications_precedingStatus_version_taskReferencedVersions;

interface Props {
  currentUser: currentUser_currentUser;
  versionId: string | null;
  taskReferencedVersions: TaskReferencedVersions[];
}

const TaskAssociator: FC<Props> = ({ currentUser, versionId, taskReferencedVersions }) => {
  const classes = useStyles();
  const [searchModalAnchor, setSearchModalAnchor] = useState<EventTarget | null>(null);
  const searchModalOpen = Boolean(searchModalAnchor);
  const [updateTask] = useUpdateTask();
  const [addTaskReferenceToItemVersion] = useMutation(addTaskReferenceToItemVersionMutation, {
    refetchQueries: ['userNotificationsQuery'],
  });
  const [removeTaskReferenceFromItemVersion] = useMutation(removeTaskReferenceFromItemVersionMutation, {
    refetchQueries: ['userNotificationsQuery'],
  });

  const handleAddTaskReferenceToItemVersion = async (referencedTaskIds: string[]) => {
    await addTaskReferenceToItemVersion({
      variables: {
        versionId: versionId,
        referencedTaskIds,
      },
    }).catch((err) => {
      throw new Error(`Error adding associated task to item: ${err}`);
    });
  };

  const handleRemoveTaskReferenceFromItemVersion = async (taskId: string) => {
    await removeTaskReferenceFromItemVersion({
      variables: {
        versionId: versionId,
        taskId,
      },
    }).catch((err) => {
      throw new Error(`Error adding associated task to item: ${err}`);
    });
  };

  const onTaskCreate = async (task: createItem_createItem_item) => {
    if (task.currentVersion) {
      await updateTask({
        variables: {
          id: task.currentVersion.id,
          assignedPersonId: currentUser.id,
        },
      }).catch((err) => {
        throw new Error(`Error updating associated task: ${err}`);
      });
    }

    if (task.currentVersion) {
      await handleAddTaskReferenceToItemVersion([task.currentVersion.id]);
    }
  };

  const Icon = useMemo(
    () => <IconButton Icon={OfflinePinIcon} onClick={(event) => setSearchModalAnchor(event.target)} size="small" />,
    [],
  );

  return (
    <Box display="flex" className={classes.addToMyTasks}>
      {taskReferencedVersions.length ? (
        <>
          <ItemSearch
            open={searchModalOpen}
            anchorEl={searchModalAnchor}
            closeSelf={() => setSearchModalAnchor(null)}
            onSubmit={(selectedTaskIds: string[]) =>
              new Promise(async (resolve) => {
                await handleAddTaskReferenceToItemVersion(selectedTaskIds);
                resolve(true);
              })
            }
            itemTypes={['task']}
            draftOnly={false}
            excludeVoidAndCancel
            supportsChangeOrder={false}
            usesCurrentVersion={true}
            defaultSearchTerm={searchModalOpen ? '' : null}
          />
          <NotificationCTA icon={Icon}>
            {taskReferencedVersions.map((task) => (
              <Box key={task.id} display="flex" mr={0.5} className={classes.chipItem}>
                <ChipItem
                  statusVersionDot={StatusVersionType.status}
                  linkType={LinkType.internalNewTab}
                  option={{
                    id: task.item.id,
                    customIdentifier: task.item.customIdentifier,
                    currentVersion: {
                      versionIdentifier: task.versionIdentifier,
                      title: task.title || '',
                      currentStatus: { name: task.currentStatus.name },
                    },
                    itemType: {
                      displaySlug: task.item.itemType.displaySlug,
                    },
                  }}
                  getTagProps={{
                    onDelete: () => handleRemoveTaskReferenceFromItemVersion(task.id),
                  }}
                  disable={task.locked}
                />
              </Box>
            ))}
          </NotificationCTA>
        </>
      ) : (
        <NewItem hideAddIcon defaultSelectedItemType="task" onCreate={onTaskCreate}>
          <NotificationCTA icon={<OfflinePinIcon />} text="Add to My Tasks" />
        </NewItem>
      )}
    </Box>
  );
};

export default TaskAssociator;
