import { LibraryBooks } from '@material-ui/icons';

interface Page {
  itemType: string;
  displaySlug: string;
  categoryLabel: {
    id: string;
  };
  icon: any;
}

const pages: { [key: string]: Page[] } = {
  risk_management_file: [
    {
      itemType: 'risk_trace_matrices',
      displaySlug: 'risk-trace-matrices',
      icon: LibraryBooks,
      categoryLabel: { id: 'Risk Trace Matrices' },
    },
    {
      itemType: 'risk_control_effectiveness_tests',
      displaySlug: 'risk-control-effectiveness-tests',
      icon: LibraryBooks,
      categoryLabel: { id: 'Risk Control Effectiveness Tests' },
    },
    {
      itemType: 'risk_control_implementation_tests',
      displaySlug: 'risk-control-implementation-tests',
      icon: LibraryBooks,
      categoryLabel: { id: 'Risk Control Implementation Tests' },
    },
  ],
};

const pagesListToInsert = (productName) => {
  const pagesToInsert: Page[] = [];

  if (pages[productName]) pagesToInsert.push(...pages[productName]);

  return pagesToInsert;
};

export default pagesListToInsert;
