import React, { FC, useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { Warning, People } from '@material-ui/icons';

import Select from 'components/core/form/Select';
import { VersionAndStatusDot } from 'components';

import WithCurrentUser from 'compositions/WithCurrentUser';
import BaseDialog from 'components/BaseDialog';
import ESignatureWithButton from 'components/ReasonForChangeAndEsig/ESignatureWithButton';
import ReasonForChangeTextArea from 'components/ReasonForChangeAndEsig/ReasonForChangeTextArea';

import { useStyles } from './styles';
import { userListQuery_users } from 'compositions/__generated__/userListQuery';
import { currentUser_currentUser } from 'compositions/__generated__/currentUser';
import { AllJobRoles_jobRoles } from '../../../compositions/__generated__/AllJobRoles';
import { UPDATE_PERMISSION_GRANT } from './gql';

interface Props {
  open: boolean;
  confirmRoleRemovalOpen: (e) => void;
  onClose: () => void;
  selectedUser: any;
  currentUser: currentUser_currentUser;
  allUsers: userListQuery_users[];
  usersWithRole: { id: string; fullName: string } | null;
  jobRole: AllJobRoles_jobRoles;
  activePermissionGrants: any;
  setJobRole: (e) => void;
  setSelectedUser: (e) => void;
}

interface Item {
  key: number;
  id: string;
  label: string;
  value?: string;
}

const AssignNewPermissions: FC<Props> = ({
  open,
  onClose,
  selectedUser,
  currentUser,
  jobRole,
  activePermissionGrants,
  confirmRoleRemovalOpen,
  setJobRole,
  setSelectedUser,
}) => {
  const classes = useStyles();
  const [scrollTarget, setScrollTarget] = useState<HTMLDivElement | undefined>(undefined);
  const [reasonForPermissionChange, setReasonForPermissionChange] = useState('');
  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newlyAssignedPermissionGrants, setNewlyAssignedPermissionGrants] = useState<any>([]);

  const unaffectedStatuses = [
    'approved_draft',
    'approved_retirement',
    'canceled',
    'closed',
    'obsolete',
    'retired',
    'released',
  ];

  const filteredActivePermissionGrants = activePermissionGrants.filter(
    (grant, index, self) =>
      !unaffectedStatuses.includes(grant.version.currentStatus.name) &&
      index === self.findLastIndex((g) => grant.version.item.id === g.version.item.id) &&
      (grant.permissionType === 'approve_record'
        ? grant.version.assignedApproverJobRole.roleName === jobRole.roleName
        : grant.version.assignedOwnerJobRole.roleName === jobRole.roleName),
  );

  const generateSelectOption = (user: { id: string; fullName: string }) => ({ value: user.id, label: user.fullName });
  const userOptions = useMemo(
    () =>
      jobRole.usersWithRole
        .filter((user) => user.id !== '1' && user.id !== get(selectedUser, 'id'))
        .map(generateSelectOption),
    [jobRole.usersWithRole, selectedUser],
  );

  const formCanSubmit = () =>
    !checkboxSelected
      ? filteredActivePermissionGrants.every((item) => !!item.value)
      : newlyAssignedPermissionGrants.length && reasonForPermissionChange.length && username.length && password.length;

  const handleChange = (
    item: Item,
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string;
    }>,
  ) => {
    // event target value is the assignee user ID
    // item.id can be useful
    const nextGrants = [...filteredActivePermissionGrants];
    const foundItem = nextGrants.find(({ id }) => id === item.id);
    if (foundItem) {
      foundItem.value = event.target.value;

      const newGrants = [
        ...newlyAssignedPermissionGrants,
        {
          grantId: !checkboxSelected ? foundItem.id : newlyAssignedPermissionGrants[0],
          userId: event.target.value,
          versionId: foundItem.version.id,
          jobRoleId: jobRole.id,
          permissionType: foundItem.permissionType,
        },
      ];
      setNewlyAssignedPermissionGrants(newGrants);
    }
  };

  const handleCheckboxChange = (_, _checked: boolean) => {
    setCheckboxSelected(!checkboxSelected);
  };

  const handleSubmit = () => {
    !checkboxSelected
      ? newlyAssignedPermissionGrants.forEach((grant) => {
          updatePermissionGrant({
            variables: {
              userId: grant.userId,
              versionId: grant.versionId,
              jobRoleId: grant.jobRoleId,
              permissionType: grant.permissionType,
              reasonForPermissionChange: reasonForPermissionChange,
              username: username,
              password: password,
              userPermissionType: 'admin',
            },
          }).catch((e) => {
            throw new Error(`Error occurred while updating permission grants: ${e}`);
          });
        })
      : filteredActivePermissionGrants.forEach((grant) => {
          updatePermissionGrant({
            variables: {
              userId: newlyAssignedPermissionGrants[0].userId,
              versionId: grant.version.id,
              jobRoleId: jobRole.id,
              permissionType: grant.permissionType,
              reasonForPermissionChange: reasonForPermissionChange,
              username: username,
              password: password,
            },
          }).catch((e) => {
            throw new Error(`Error occurred while updating permission grants: ${e}`);
          });
        });
  };

  const handleClose = () => {
    onClose();
    setReasonForPermissionChange('');
    setUsername('');
    setPassword('');
    setNewlyAssignedPermissionGrants([]);
    setCheckboxSelected(false);
  };

  const [updatePermissionGrant, { loading, error }] = useMutation(UPDATE_PERMISSION_GRANT, {
    onCompleted: () => {
      handleClose();
      setJobRole(jobRole);
      setSelectedUser(selectedUser);
      confirmRoleRemovalOpen(true);
    },
    refetchQueries: ['AllJobRoles'],
  });
  if (error) throw new Error(`Error updating permission grants: ${error}`);

  return (
    <BaseDialog
      open={open}
      title="Assign New Permissions"
      onClose={handleClose}
      Icon={Warning}
      size="xlarge"
      className={classes.dialog}
      scrollTarget={scrollTarget}
    >
      <div className={classes.root}>
        <div
          ref={(node) => {
            if (node) {
              setScrollTarget(node);
            }
          }}
          className={classes.content}
        >
          <Typography variant="h6">
            {`${selectedUser && selectedUser.fullName} `}
            <Typography component="span" variant="h5">
              is the owner or approver of the items below. You must assign a new user to take over their
              responsibilities before you can remove the role
            </Typography>
            <Typography component="span" variant="h6" className={classes.roleName}>
              {` ${jobRole.formattedRoleName} `}
            </Typography>
            <Typography component="span" variant="h5">
              from
            </Typography>
            {` ${selectedUser && selectedUser.fullName}.`}
          </Typography>

          <div className={classes.itemsContainer}>
            <div className={classes.checkboxContainer}>
              <People className={classes.userIcon} />
              <Typography variant="button" className={classes.newUser}>
                new user
              </Typography>
              <FormControlLabel
                id="assign-to-all"
                control={
                  <Checkbox color="primary" className={classes.checkbox} classes={{ root: classes.checkboxRoot }} />
                }
                label={<Typography variant="caption">Assign to all</Typography>}
                onChange={handleCheckboxChange}
                className={classes.controlLabel}
              />
            </div>
            {filteredActivePermissionGrants.map((grant, index) => (
              <div key={index} className={classes.item}>
                <Typography variant="h6" className={classes.itemName}>
                  {grant.version.item.customIdentifier}{' '}
                  <VersionAndStatusDot
                    style={{ display: 'inline-flex', alignItems: 'baseline' }}
                    version={grant.version.versionIdentifier}
                    status={grant.version.currentStatus.name}
                  />{' '}
                  : {grant.version.title}
                </Typography>
                <div className={classes.select}>
                  <Select
                    options={userOptions.filter((user) =>
                      grant.permissionType === 'own_record'
                        ? user.value !== grant.version.approver.id
                        : user.value !== grant.version.owner.id,
                    )}
                    onChange={(event) => handleChange(grant, event)}
                    disabled={checkboxSelected && index !== 0}
                    defaultValue=""
                    placeholder={grant.permissionType === 'own_record' ? 'Assign new owner' : 'Assign new approver'}
                  />
                </div>
              </div>
            ))}
            <ReasonForChangeTextArea
              textboxHeading={`Reason For Permission Changes`}
              textboxId={`reason-for-permission-change-text`}
              onTextChange={(e) => setReasonForPermissionChange(e.target.value)}
            />
          </div>
        </div>

        <div className={classes.esignature}>
          <ESignatureWithButton
            currentUser={currentUser}
            usernameInputId={`assign-new-permissions-username-input`}
            onUsernameChange={(e) => setUsername(e.target.value)}
            passwordInputId={`assign-new-permissions-password-input`}
            onPasswordChange={(e) => setPassword(e.target.value)}
            submitDisabled={!formCanSubmit() || loading}
            onSubmitClick={handleSubmit}
            submitLoading={loading}
          />
        </div>
      </div>
    </BaseDialog>
  );
};

export default flowRight([WithCurrentUser])(AssignNewPermissions);
