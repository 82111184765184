import React from 'react';

import { SupplierWorkflowStatuses } from 'workflows/statuses';
import { CertifiedSignature, YesOrNo } from 'compositions/TimelineGroup/steps/views';

const TO_STATUS = SupplierWorkflowStatuses.QualificationComplete.id;

export function buildApproveMessage(props) {
  return `As the ${
    props.currentVersion.assignedOwnerJobRole.formattedRoleName
  }, I approve the completion of qualification for ${props.itemCustomIdentifier}.`;
}

export default {
  initialStep: 'takeOwnership',
  internalSteps: {
    takeOwnership: {
      component: (props) => (
        <YesOrNo disableOnSubmit prompt={buildApproveMessage(props)} yesLabel="Approve" noLabel="Cancel" {...props} />
      ),
      getNextStep: (takeOwnership) => (takeOwnership ? 'signature' : 'complete'),
    },
    signature: {
      component: (props) => {
        const heading = 'Qualification Complete';
        const dialog = `My signature indicates that all planned qualification activities have been completed and associated to this Supplier.`;

        return <CertifiedSignature heading={heading} dialog={dialog} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ signature }) => {
    return signature
      ? {
          toStatus: TO_STATUS,
          ...signature,
        }
      : null;
  },
};
