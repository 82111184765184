import React, { useState } from 'react';

import { Trans } from '@lingui/macro';

import { Popover, Typography, withStyles } from '@material-ui/core';
import RateReview from '@material-ui/icons/RateReview';

import { buildConditionalClasses } from 'utils';
import { useComments } from 'api/useComments';
import styles from './styles';
import CommentInput from './CommentInput';
import Comment from './Comment';

function MemberComments({
  classes,
  anchorEl,
  onClose,
  open,
  itemId,
  queryToRefetch,
}) {
  const [draftComment, setDraftComment] = useState('');
  const {
    query: { data, error },
    mutation: [createComment],
  } = useComments(itemId, !open);

  if (error) throw new Error(`${error}`);
  if (!data || !data.item) return null;

  const comments = data.item.comments.slice(0).reverse();

  const submitComment = () => {
    if (draftComment.trim()) {
      createComment(payload)
        .then(() => {
          setDraftComment('');
        })
        .catch((err) => {
          throw new Error(
            `Error while creating comment for item: ${itemId}\n${err}`,
          );
        });
    }
  };

  let refetchQueries = ['GetComments'];
  if (queryToRefetch) refetchQueries.push(queryToRefetch);
  const payload = {
    variables: {
      input: {
        body: draftComment,
        itemId: itemId,
      },
    },
    refetchQueries,
  };
  return (
    <Popover
      data-testid="comments-popover"
      classes={{ paper: classes.commentsPopover }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.commentInputContainer}>
        <Typography
          className={classes.inputField}
          variant="body2"
          component="span"
        >
          <CommentInput
            value={draftComment}
            onSubmit={submitComment}
            onChange={(e) => setDraftComment(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                submitComment();
              }
            }}
          />
        </Typography>
      </div>
      <div
        className={buildConditionalClasses([
          [true, classes.commentsContainer],
          [!(comments && comments.length), classes.noCommentContainer],
        ])}
      >
        {comments.length ? (
          comments.map((comment) => (
            <Comment {...comment} classes={classes} key={comment.id} />
          ))
        ) : (
          <Typography component="span" className={classes.noCommentMsg}>
            <RateReview />
            <Trans>No comments in for this item yet!</Trans>
          </Typography>
        )}
      </div>
    </Popover>
  );
}

export default withStyles(styles)(MemberComments);
