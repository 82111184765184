import gql from 'graphql-tag';

export const supplierManagementDashboardQuery = gql`
  query SupplierManagementDashboardQuery {
    supplierManagementDashboard {
      approved
      requested
      needsRequalification
      upcomingRequalifications {
        id
        customIdentifier
        itemType {
          id
          displaySlug
          userCreatable
        }
        currentVersion {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
          approvalExpiresAt
          categoryText
          riskLevel
        }
      }
    }
  }
`;
