import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    container: {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateX(100%)',
      opacity: 0,
      transition: 'opacity .2s ease-in-out',
      'div:has(&):hover > &': {
        opacity: 1,
      },
      '& .MuiIconButton-root': {
        padding: 0,
        height: '78px',
        width: '16px',
        position: 'absolute',
        left: 0,
      },
      '& .MuiSvgIcon-root': {
        width: '16px',
      },
    },
    hided: {
      opacity: 1,
    },
    buttonIconOpened: {
      color: theme.palette.primary.main,
      opacity: 0,
      transition: 'opacity .2s ease-in-out',

      'div:has(&):hover > &': {
        opacity: 1,
      },
    },
    buttonIconHided: {
      color: theme.palette.primary.contrastText,
    },
  }),
);
