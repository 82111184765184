import gql from 'graphql-tag';

export const TRAINING_DASHBOARD_QUERY = gql`
  query dashboard {
    trainingDashboard {
      checked
      completed
      failed
      toDo
    }
    trainingRecordSearch(statusNames: ["to_do", "completed", "failed"]) {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      currentVersion {
        category
        source {
          item {
            itemType {
              id
              displaySlug
              userCreatable
            }
            id
            customIdentifier
          }
          id
          versionIdentifier
        }
        id
        createdAt
        title
        versionIdentifier
        currentStatus {
          id
          name
          titleizedName
        }
        dueDate
      }
    }
  }
`;
