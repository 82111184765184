import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';

import { Grid } from '@material-ui/core';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

import { Loading, TableView } from 'components';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { itemLink } from 'components/MakeLink';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import CreateTrainingPlanModal from './CreateTrainingPlanModal';
import CannotDeactivateUserModal from './CannotDeactivateUserModal';
import ConfirmUserDeactivationModal from './ConfirmUserDeactivationModal';
import { USERS_QUERY } from './gql';
import { useStyles } from './styles';
import { sortIds } from 'utils/agGridHelpers';

interface Props {
  currentCompany: any;
}

function UserList(props: Props) {
  const classes = useStyles();
  const { currentCompany } = props;
  const [createTrainingPlanModalOpen, setCreateTrainingPlanModalOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [cannotDeactivateUserModalOpen, setCannotDeactivateUserModalOpen] = useState(false);
  const [userDeactivationModalOpen, setUserDeactivationModalOpen] = useState(false);
  const [userValue, setUserValue] = useState({
    id: null,
    fullName: '',
    jobRoles: {},
    trainingRecords: [],
    trainingPlan: null,
  });

  const { data, loading, error } = useQuery(USERS_QUERY);
  if (loading) return <Loading />;
  if (error) throw new Error(`Error loading employees: ${error.message}`);

  const handleModalClose = () => {
    setCreateTrainingPlanModalOpen(false);
    setCannotDeactivateUserModalOpen(false);
    setUserDeactivationModalOpen(false);
    setEmployeeId(null);
    setUserValue({
      id: null,
      fullName: '',
      jobRoles: {},
      trainingRecords: [],
      trainingPlan: null,
    });
  };

  const onlyGeneralRoleAssigned = (jobRoles) => {
    if (jobRoles.length === 1 && jobRoles[0].roleName === 'general') {
      return true;
    }
  };

  const onDeactivateUserClick = (row) => {
    setUserValue({
      id: row.id,
      fullName: row.fullName,
      jobRoles: row.jobRoles,
      trainingPlan: row.employee.effectiveTrainingPlanVersion,
      trainingRecords: row.employee.trainingRecordVersions,
    });

    if (onlyGeneralRoleAssigned(row.jobRoles) || !row.jobRoles.length) {
      setUserDeactivationModalOpen(true);
    } else {
      setCannotDeactivateUserModalOpen(true);
    }
  };

  const listViewColumns = [
    {
      field: 'customIdentifier',
      headerName: 'ID',
      type: 'short_text',
      enableRowGroup: false,
      comparator: (value1, value2) => sortIds(value1, value2),
      width: 96,
    },
    {
      field: 'id',
      headerName: 'Nemedio ID',
      type: 'custom',
      comparator: (value1, value2) => sortIds(value1, value2),
      render: function(row) {
        return row.data.employee.id;
      },
      hide: true,
    },
    { field: 'firstName', headerName: 'First Name', type: 'regular_text' },
    { field: 'lastName', headerName: 'Last Name', type: 'regular_text' },
    {
      field: null,
      headerName: 'Avatar',
      type: 'user',
      keyCreator: (row) => row.data.fullName,
      comparator: (value1, value2) => {
        if (value1.fullName === value2.fullName) return 0;
        return value1.fullName > value2.fullName ? 1 : -1;
      },
      valueGetter: (row) => {
        return row.data;
      },
    },
    { field: 'username', headerName: 'Username', type: 'regular_text' },
    { field: 'emailAddress', headerName: 'E-mail', type: 'regular_text' },
    { field: 'tier', headerName: 'Type', type: 'regular_text' },
    {
      field: 'jobRoles',
      headerName: 'Active Roles',
      type: 'custom',
      enableRowGroup: true,
      valueGetter: (row) => {
        if (!row.data) return '';
        const roles = row.data.jobRoles
          .filter((role) => role.roleName !== 'general')
          .map((role) => role.formattedRoleName);
        return roles.join(', ');
      },
      render: function(row) {
        return row.value;
      },
    },
    { field: 'status', headerName: 'Status', type: 'short_text' },
    {
      field: 'employee.effectiveTrainingPlanVersion.item.customIdentifier',
      headerName: 'TPLN-#',
      type: 'custom',
      suppressNavigable: true,
      keyCreator: (row) => {
        return get(row, 'data.employee.effectiveTrainingPlanVersion.item.customIdentifier', '');
      },
      comparator: (data1, data2) => {
        const value1 = get(data1, 'employee.effectiveTrainingPlanVersion.item.customIdentifier', 'TPL');
        const value2 = get(data2, 'employee.effectiveTrainingPlanVersion.item.customIdentifier', 'TPL');
        return sortIds(value1, value2);
      },
      valueGetter: (row) => {
        return row.data;
      },
      render: function(row) {
        const trainingPlanVersion = row.value.employee.effectiveTrainingPlanVersion;
        const tplEnabledOnLicenseTier =
          currentCompany.licenseTier === 'CORE_QS' || currentCompany.licenseTier === 'CORE_QS_AND_DESIGN_QS';
        if (row.value.tier === 'read_only') {
          return 'N/A - View Only License';
        } else if (trainingPlanVersion && tplEnabledOnLicenseTier) {
          const trainingPlanItem = trainingPlanVersion.item;
          return <a href={itemLink(trainingPlanItem)}>{trainingPlanItem.customIdentifier}</a>;
        } else {
          return tplEnabledOnLicenseTier && currentCompany.validatedSystem ? (
            <>
              <Button
                color="primary"
                variant="contained"
                disabled={row.data.status === 'inactive'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCreateTrainingPlanModalOpen(true);
                  setEmployeeId(row.value.employee.id);
                }}
              >
                Create
              </Button>
            </>
          ) : (
            'N/A'
          );
        }
      },
    },
    {
      field: 'status',
      headerName: 'Deactivate',
      type: 'custom',
      enableRowGroup: true,
      suppressNavigable: true,
      render: function(row) {
        return (
          <>
            <IconButton
              tooltip={row.data ? `Deactivate ${row.data.fullName}'s account` : ''}
              Icon={RemoveCircle}
              disabled={(row.value || row.data.status) === 'inactive'}
              onClick={() => row.data && onDeactivateUserClick(row.data)}
            />
          </>
        );
      },
    },
  ];

  return (
    <Grid container className={classes.tableContainer} wrap="nowrap">
      {createTrainingPlanModalOpen && (
        <CreateTrainingPlanModal
          isOpen={createTrainingPlanModalOpen}
          onClose={handleModalClose}
          employeeId={employeeId}
        />
      )}
      {cannotDeactivateUserModalOpen && (
        <CannotDeactivateUserModal
          isOpen={cannotDeactivateUserModalOpen}
          onClose={handleModalClose}
          jobRoles={userValue.jobRoles}
          userFullName={userValue.fullName}
        />
      )}
      {userDeactivationModalOpen && (
        <ConfirmUserDeactivationModal
          isOpen={userDeactivationModalOpen}
          onClose={handleModalClose}
          userId={userValue.id}
          userFullName={userValue.fullName}
          trainingPlan={userValue.trainingPlan}
          trainingRecords={userValue.trainingRecords}
        />
      )}
      <TableView
        data={data.users}
        loading={loading}
        categorySlug={'employees'}
        listViewColumns={listViewColumns}
        showNewItemBtn={false}
        showItemTypeInfo={false}
      />
    </Grid>
  );
}

export default flowRight(withCurrentCompany)(UserList);
