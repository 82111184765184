import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  addRowButton: {
    justifyContent: 'flex-start',
    padding: 0,
    paddingTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.7,
    },
  },
  addRowIcon: {
    width: 25,
  },
  associatedItemColumn: {
    marginTop: theme.spacing(-3),
  },
  associatedItemContainer: {
    border: `2px solid ${theme.palette.common.input.outline}`,
    borderRadius: 3,
    width: 570,
    padding: theme.spacing(1),
    display: 'flex',
    margin: `${theme.spacing(0.5)}px 0`,
  },
  chip: {
    height: 20,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  employeeSection: {
    display: 'block',
    width: '40%',
    float: 'left',
  },
  infoButton: {
    color: theme.palette.secondary.main,
    padding: 1,
    marginLeft: 'auto',
    order: 2,
    '&:hover': {
      opacity: 0.7,
    },
    '&:disabled': {
      background: theme.palette.action.disabledBackground,
    },
  },
  jobRoleSection: {
    width: '60%',
    float: 'right',
  },
  linkButton: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.secondary.main,
    padding: 1,
    marginLeft: 'auto',
    order: 2,
    '&:hover': {
      opacity: 0.7,
    },
    '&:disabled': {
      background: theme.palette.action.disabledBackground,
    },
  },
  paper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    width: 322,
  },
  popover: {
    pointerEvents: 'none',
  },
  tableRowCheckbox: {
    padding: '12px 12px 12px 0',
    '& svg': {
      width: 15,
      height: 15,
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
  titleSecondaryActionButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    marginTop: theme.spacing(-1.5),
    width: 20,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  tooltip: {
    ...theme.typography.body2,
  },
}));
