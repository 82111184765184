import React from 'react';
import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import ListIcon from '@material-ui/icons/List';

import { listFragment, docMetadata } from 'itemTypes/mixins/Document';
import { QueryBasedPayloadFields } from 'itemTypes/mixins/DocumentBased/QueryBasedPayloadFields';

import slugs from '../../displaySlugs';
import { versionQuery, CREATE_VALIDATION_RECORD } from './gql';
import Views from 'workspaces/views';
import { defaultRecordViewColumns } from 'components/RecordsView/ItemsTable/defaultColumns';
import { CustomColumn } from 'components/core/Table/types';
import DeviationLinks from 'components/RecordsView/ItemsTable/DeviationLinks';

const itemType = 'deviceValidation';

const PayloadFields = QueryBasedPayloadFields(versionQuery, itemType);

const recordsQuery = gql`
  query DeviceValidationVersionRecordsQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      currentVersion {
        id
        title
        id
        currentStatus {
          id
          name
        }
      }
      versions {
        ... on DeviceValidationVersion {
          validationRecordVersions {
            createdAt
            deviationVersions {
              id
              title
              item {
                id
                customIdentifier
                itemType {
                  displaySlug
                  id
                  name
                  userCreatable
                }
              }
            }
            isFulfillAllAcceptanceCriteria
            item {
              itemType {
                id
                userCreatable
                displaySlug
              }
              id
              customIdentifier
            }
            owner {
              id
              fullName
            }
            approver {
              id
              fullName
            }
            id
            title
            currentStatus {
              name
              id
            }
          }
        }
        id
        releasedAt
        locked
        title
        approver {
          id
          firstName
        }
        owner {
          id
          firstName
        }
        currentStatus {
          name
          id
        }
        versionIdentifier
      }
      currentRelease {
        id
        title
      }
      currentUser {
        id
        readOnly
      }
    }
  }
`;

export const recordViewColumns: CustomColumn<any>[] = [
  ...defaultRecordViewColumns,
  {
    title: 'TEST RESULT',
    align: 'center',
    field: 'isFulfillAllAcceptanceCriteria',
    cellType: 'boolean',
  },
  {
    title: 'DEVIATIONS',
    field: 'deviationVersions',
    align: 'center',
    cellType: 'custom',
    editComponent: (props) => <DeviationLinks {...props} />,
  },
];

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Validation Protocol`,
  categoryLabel: t`Validation Protocols`,
  recordItemType: 'validationRecord',
  createNewRecordOptions: {
    createNewRecordMutation: CREATE_VALIDATION_RECORD,
    refetchQueries: [],
  },
  icon: ListIcon,
  listWorkspace: [Views.Table, Views.LinksGraph],
  recordWorkspace: [Views.Builder, Views.Effective, Views.Versions, Views.Records],
  RecordViewOptions: {
    query: recordsQuery,
    recordsProperty: 'validationRecordVersions',
    recordViewColumns,
  },
  listViewQuery: gql`
    query deviceValidationsQuery {
      documents(itemType: "device_validation") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
  PayloadFields,
};
