import gql from 'graphql-tag';

const versionFragment = gql`
  fragment traceMatrixVersionFragment on VersionInterface {
    ... on RiskVersion {
      harm {
        id
        name
      }
      hazard {
        name
        id
      }
      sequenceOfEvents
      hazardousSituation
      linkedRiskControlVersions {
        id
        item {
          id
          customIdentifier
          currentVersion {
            id
            versionIdentifier
          }
        }
      }
    }
    id
    currentStatus {
      id
      name
    }
    item {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
      currentRelease {
        id
        stringified
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            name
            displaySlug
          }
        }
        title
        versionIdentifier
      }
      currentVersion {
        id
        stringified
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            name
            displaySlug
          }
        }
        title
        versionIdentifier
      }
      workingVersion {
        id
        stringified
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            name
            displaySlug
          }
        }
        title
        versionIdentifier
      }
      upstreamItemsKept {
        id
        customIdentifier
        currentVersion {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
        itemType {
          displaySlug
          id
        }
      }
      downstreamItemsKept {
        id
        customIdentifier
        currentVersion {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
        itemType {
          displaySlug
          id
        }
      }
    }
    title
    versionIdentifier
  }
`;

const itemVersionFragment = gql`
  fragment traceMatrixItemVersionFragment on VersionInterface {
    id
    currentStatus {
      id
      name
    }
    item {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
    }
    title
    versionIdentifier
  }
`;

const riskVersionFragment = gql`
  fragment traceMatrixRiskVersionFragment on VersionInterface {
    id
    currentStatus {
      id
      name
    }
    item {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
    }
    title
    versionIdentifier
  }
`;

const riskControlVersionFragment = gql`
  fragment traceMatrixRiskControlVersionFragment on VersionInterface {
    id
    currentStatus {
      id
      name
    }
    item {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
    }
    title
    versionIdentifier
  }
`;

const deviceValidationProtocolsVersionFragment = gql`
  fragment traceMatrixDeviceValidationProtocolsVersionFragment on VersionInterface {
    id
    currentStatus {
      id
      name
    }
    item {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
    }
    title
    versionIdentifier
  }
`;

const deviceVerificationProtocolsVersionFragment = gql`
  fragment traceMatrixDeviceVerificationProtocolsVersionFragment on VersionInterface {
    id
    currentStatus {
      id
      name
    }
    item {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
    }
    title
    versionIdentifier
  }
`;

const ADD_TRACE_MATRIX_VERSION_TRACE = gql`
  mutation Add($traceMatrixVersionId: ID!, $upstreamVersionId: ID!, $downstreamVersionId: ID!) {
    addTraceToTraceMatrixVersion(
      traceMatrixVersionId: $traceMatrixVersionId
      upstreamVersionId: $upstreamVersionId
      downstreamVersionId: $downstreamVersionId
    ) {
      versionTrace {
        id
        downstreamVersion {
          ...traceMatrixVersionFragment
        }
        upstreamVersion {
          ...traceMatrixVersionFragment
        }
      }
    }
  }
  ${versionFragment}
`;

const DELETE_VERSION_TRACE = gql`
  mutation DeleteVersionTrace($id: ID!) {
    deleteVersionTrace(id: $id) {
      versionTrace {
        id
      }
    }
  }
`;

const GET_DEVICE_VALIDATION_PROTOCOLS = gql`
  query traceMatrixDeviceValidationsQuery {
    deviceValidations {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
      currentVersion {
        ...traceMatrixDeviceValidationProtocolsVersionFragment
      }
      currentRelease {
        ...traceMatrixDeviceValidationProtocolsVersionFragment
      }
      workingVersion {
        ...traceMatrixDeviceValidationProtocolsVersionFragment
      }
    }
  }
  ${deviceValidationProtocolsVersionFragment}
`;

const GET_DEVICE_VERIFICATION_PROTOCOLS = gql`
  query traceMatrixDeviceVerificationsQuery {
    deviceVerifications {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
      currentVersion {
        ...traceMatrixDeviceVerificationProtocolsVersionFragment
      }
      currentRelease {
        ...traceMatrixDeviceVerificationProtocolsVersionFragment
      }
      workingVersion {
        ...traceMatrixDeviceVerificationProtocolsVersionFragment
      }
    }
  }
  ${deviceVerificationProtocolsVersionFragment}
`;

const GET_RISK_ITEMS = gql`
  query riskItems {
    items(itemType: "risk") {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
      currentVersion {
        ...traceMatrixRiskVersionFragment
      }
      currentRelease {
        ...traceMatrixRiskVersionFragment
      }
      workingVersion {
        ...traceMatrixRiskVersionFragment
      }
    }
  }
  ${riskVersionFragment}
`;

const GET_RISK_CONTROL_ITEMS = gql`
  query riskControlItems {
    items(itemType: "risk_control") {
      id
      customIdentifier
      itemType {
        id
        name
        displaySlug
      }
      currentVersion {
        ...traceMatrixRiskVersionFragment
      }
      currentRelease {
        ...traceMatrixRiskVersionFragment
      }
      workingVersion {
        ...traceMatrixRiskVersionFragment
      }
    }
  }
  ${riskControlVersionFragment}
`;

const GET_REQUIREMENTS_SET_VERSION = gql`
  query requirementsSetVersion($id: ID!) {
    set: requirementsSetVersion(id: $id) {
      memberVersions {
        ...traceMatrixVersionFragment
      }
    }
  }
  ${versionFragment}
`;

// We only need versions in sets where currentStatus != void or obsolete
const GET_SETS = gql`
  query traceMatrixSets {
    userNeedsSets {
      id
      customIdentifier
      versions {
        id
        item {
          id
          customIdentifier
          currentRelease {
            id
            versionIdentifier
            title
            currentStatus {
              name
              id
            }
          }
          versions {
            id
          }
          workingVersion {
            id
            title
            versionIdentifier
            currentStatus {
              name
              id
            }
          }
        }
        versionIdentifier
        currentStatus {
          id
          name
        }
        title
      }
    }
    requirementsSets {
      id
      customIdentifier
      versions {
        id
        item {
          id
          customIdentifier
          currentRelease {
            id
            versionIdentifier
            title
            currentStatus {
              name
              id
            }
          }
          workingVersion {
            id
            title
            versionIdentifier
            currentStatus {
              name
              id
            }
          }
          versions {
            id
          }
        }
        versionIdentifier
        currentStatus {
          id
          name
        }
        title
      }
    }
    riskAnalyses {
      id
      customIdentifier
      versions {
        id
        item {
          id
          customIdentifier
          currentRelease {
            id
            versionIdentifier
            title
            currentStatus {
              name
              id
            }
          }
          workingVersion {
            id
            title
            versionIdentifier
            currentStatus {
              name
              id
            }
          }
          versions {
            id
          }
        }
        versionIdentifier
        currentStatus {
          id
          name
        }
        title
      }
    }
  }
`;

const GET_UN_SETS = gql`
  query userNeedSets {
    userNeedsSets {
      id
      customIdentifier
      versions {
        id
        item {
          id
          customIdentifier
        }
        versionIdentifier
        currentStatus {
          id
          name
        }
      }
    }
  }
`;

const GET_REQ_SETS = gql`
  query requirementSets {
    requirementsSet {
      id
      customIdentifier
      versions {
        id
        item {
          id
          customIdentifier
        }
        versionIdentifier
        currentStatus {
          id
          name
        }
      }
    }
  }
`;

const GET_USER_NEEDS_SET_VERSION = gql`
  query userNeedsSetVersion($id: ID!) {
    set: userNeedsSetVersion(id: $id) {
      memberVersions {
        ...traceMatrixVersionFragment
      }
    }
  }
  ${versionFragment}
`;

const GET_RISK_ANALYSIS_VERSION = gql`
  query riskAnalysisVersion($id: ID!) {
    set: riskAnalysisVersion(id: $id) {
      memberVersions(showOnlyRisk: false) {
        ...traceMatrixVersionFragment
      }
    }
  }
  ${versionFragment}
`;

const UPDATE_TRACE_MATRIX_VERSION = gql`
  mutation UpdateTraceMatrixVersion(
    $id: ID!
    $title: String
    $reasonForChange: String
    $descriptionOfChange: String
    $requirementsSetVersionId: ID
    $userNeedsSetVersionId: ID
    $riskAnalysisVersionId: ID
    $traceType: String
  ) {
    updateTraceMatrixVersion(
      id: $id
      descriptionOfChange: $descriptionOfChange
      reasonForChange: $reasonForChange
      requirementsSetVersionId: $requirementsSetVersionId
      title: $title
      userNeedsSetVersionId: $userNeedsSetVersionId
      riskAnalysisVersionId: $riskAnalysisVersionId
      traceType: $traceType
    ) {
      traceMatrixVersion {
        id
      }
    }
  }
`;

const CONVERT_LINKS_TO_VERSION_TRACES = gql`
  mutation ConvertLinksToVersionTraces($traceMatrixVersionId: ID!) {
    convertLinksToVersionTraces(traceMatrixVersionId: $traceMatrixVersionId) {
      traceMatrixSearch {
        itemType
        results {
          id
          currentVersionId
          customIdentifier
        }
      }
    }
  }
`;

const UPDATE_VERSION_TRACE = gql`
  mutation UpdateVersionTrace($traceMatrixVersionId: ID!, $oldTracedVersionId: ID!, $newTracedVersionId: ID!) {
    updateTraceMatrixVersionVersionTrace(
      traceMatrixVersionId: $traceMatrixVersionId
      oldTracedVersionId: $oldTracedVersionId
      newTracedVersionId: $newTracedVersionId
    ) {
      versionTraces {
        id
        downstreamVersion {
          currentStatus {
            id
            name
          }
          id
          item {
            id
            customIdentifier
            itemType {
              id
              name
            }
            currentRelease {
              ...traceMatrixItemVersionFragment
            }
            workingVersion {
              ...traceMatrixItemVersionFragment
            }
          }
          stringified
          title
          versionIdentifier
        }
        upstreamVersion {
          currentStatus {
            id
            name
          }
          id
          item {
            id
            customIdentifier
            itemType {
              id
              name
            }
            currentRelease {
              ...traceMatrixItemVersionFragment
            }
            workingVersion {
              ...traceMatrixItemVersionFragment
            }
          }
          stringified
          title
          versionIdentifier
        }
      }
    }
  }
  ${itemVersionFragment}
`;

export {
  ADD_TRACE_MATRIX_VERSION_TRACE,
  DELETE_VERSION_TRACE,
  GET_REQUIREMENTS_SET_VERSION,
  GET_RISK_ITEMS,
  GET_RISK_CONTROL_ITEMS,
  GET_SETS,
  GET_USER_NEEDS_SET_VERSION,
  GET_RISK_ANALYSIS_VERSION,
  UPDATE_VERSION_TRACE,
  UPDATE_TRACE_MATRIX_VERSION,
  CONVERT_LINKS_TO_VERSION_TRACES,
  GET_DEVICE_VERIFICATION_PROTOCOLS,
  GET_DEVICE_VALIDATION_PROTOCOLS,
  GET_UN_SETS,
  GET_REQ_SETS,
};
