import React, { useCallback, useContext, useEffect } from 'react';

import { flowRight, get, debounce } from 'lodash';

import { Grid } from '@material-ui/core';

import { withLockedContext, LockedContext } from 'contexts/lockedContext';

import { ContentSection, PDFViewer, Spacer, CollapsableTextArea } from 'components';

import withCurrentUser from 'compositions/WithCurrentUser';
import { DEBOUNCE_MILLISECONDS } from '../../../../constants';

import AssociatedItems from './AssociatedItems';
import TrainingMaterials from './TrainingMaterials';
import EffectivenessMeasurement from './EffectivenessMeasurement';
import TrainingInfo from './TrainingInfo';

import { useVersion, useUpdateVersion } from './gql';

function PayloadFields({ layoutClasses, versionId, displayOnly, currentUser, ...props }) {
  const { data, loading, error, refetch } = useVersion(versionId);
  const { setLocked } = useContext(LockedContext);

  useEffect(() => {
    if (data) setLocked(data.courseVersion.locked);
  }, [data, setLocked]);

  const [save] = useUpdateVersion();

  const debouncedSave = useCallback(debounce((args) => save(args), DEBOUNCE_MILLISECONDS), []);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading course version ${versionId}\n${error}`);

  return (
    <>
      <Grid item xs={5} className={layoutClasses.gridGutterRight}>
        <ContentSection fillHeight={false}>
          <Spacer factor={1} />
          <CollapsableTextArea
            locked={data.courseVersion.locked}
            labelText="Description"
            id="course-description"
            initialValue={data.courseVersion.description}
            placeholder="Enter description"
            startExpanded
            onTextChange={(text) =>
              debouncedSave({
                variables: {
                  id: versionId,
                  description: text,
                },
              })
            }
            attrName={''}
            versionId={'0'}
          />
        </ContentSection>
        <ContentSection fillHeight={false}>
          <Spacer factor={1} />
          <CollapsableTextArea
            locked={data.courseVersion.locked}
            labelText="Learning Objectives"
            id="course-learning-objectives"
            initialValue={data.courseVersion.learningObjective}
            placeholder="Enter learning objective"
            startExpanded
            onTextChange={(text) =>
              debouncedSave({
                variables: {
                  id: versionId,
                  learningObjective: text,
                },
              })
            }
            attrName={''}
            versionId={'0'}
          />
        </ContentSection>
      </Grid>
      <Grid item xs={3} className={layoutClasses.gridGutterLeft}>
        <TrainingInfo
          versionId={versionId}
          courseVersion={data.courseVersion}
          displayOnly={displayOnly}
          refetch={refetch}
          save={(variables) => save({ variables })}
          currentUser={currentUser}
        />
      </Grid>
      <Grid item xs={8}>
        <AssociatedItems data={data} versionId={versionId} />
      </Grid>
      <Grid item xs={8}>
        <TrainingMaterials
          locked={data.courseVersion.locked}
          trainingMaterialLinks={data.courseVersion.trainingMaterialLinks}
          trainingMaterialAttachments={data.courseVersion.trainingMaterialAttachments}
          courseVersionId={data.courseVersion.id}
          save={(variables) => save({ variables })}
        />
      </Grid>
      <Grid item xs={8}>
        <EffectivenessMeasurement
          assessmentGrader={data.courseVersion.assessmentGrader}
          assessmentMaterialNotes={data.courseVersion.assessmentMaterialNotes}
          attachment={data.courseVersion.attachment}
          currentUser={currentUser}
          effectivenessMeasurementRequired={data.courseVersion.effectivenessMeasurementRequired}
          justification={data.courseVersion.justification}
          trainingFormat={data.courseVersion.trainingFormat}
          save={(variables) => save({ variables })}
          versionId={data.courseVersion.id}
        />
      </Grid>

      <Grid container>
        {data.courseVersion.attachment && (
          <Grid item xs={8} className={layoutClasses.pdfView}>
            <PDFViewer url={get(data.courseVersion, 'attachment.url')} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default flowRight([withCurrentUser, withLockedContext])(PayloadFields);
