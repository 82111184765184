import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    content: {
      width: 64,
      textAlign: 'center',
    },
  }),
);
