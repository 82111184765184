import { WorkflowDefinition, WorkflowTransition } from '../types';

import AddToSet from './AddToSet';
import AssociatedSet from './AssociatedSet';
import SummaryOfChange from './SummaryOfChange';
import ReasonForChange from './ReasonForChange';
import CreateNewVersion from './CreateNewVersion';

const setItemMemberWorkflowSteps: WorkflowDefinition = {
  reasonForChange: ReasonForChange as WorkflowTransition,
  summaryOfChange: SummaryOfChange as WorkflowTransition,
  addToSet: AddToSet as WorkflowTransition,
  associatedSet: AssociatedSet as WorkflowTransition,
  createNewVersion: CreateNewVersion as WorkflowTransition,
};

export default setItemMemberWorkflowSteps;
