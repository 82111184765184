import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    taskTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    taskTitleText: {
      display: 'flex',
      alignItems: 'center',

      '& > span': {
        marginLeft: theme.spacing(2),
      },
    },
    contentSection: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(5),
    },
    completedBy: {
      alignSelf: 'flex-end',
    },
    buttons: {
      alignSelf: 'flex-end',
    },
  }),
);
