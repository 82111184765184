import { makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  icon: {
    color: theme.palette.blueDark,
    width: 17,
    marginRight: theme.spacing(-3),
  },
  menuContainer: {
    width: 248,
    padding: 0,
  },
  menuItem: {
    padding: 0,
  },
  menuItemHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  },
  menuItemText: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    whiteSpace: 'normal',
    height: 'auto',
    minHeight: 24,
    '&:hover:not(.Mui-disabled)': {
      background: 'rgba(48, 188, 179, 0.1)',
    },
    '&.Mui-disabled': {
      opacity: 0.7,
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      backgroundColor: 'transparent',
    },
  },
  refIdText: {
    color: theme.palette.titleBlack,
    whiteSpace: 'normal',
    lineHeight: 1.27,
    letterSpacing: 1.1,
    paddingBottom: theme.spacing(0.5),
    paddingtop: theme.spacing(0.5),
  },
});

export const useStyles = makeStyles(styles as any);
export default styles;
