import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';

import { Loading } from 'components';

import { GET_DEVICE_VERIFICATION_PROTOCOLS } from '../gql';
import {
  renderTraces,
  removeRetired,
  itemsWithExistingTraces,
  itemsWithUpstreamDesignTraceMatrixTraces,
} from 'itemTypes/TraceMatrix/Graph/util/TraceUtils';
import { SnackContext } from 'contexts/other/snackContext';

const typeName = 'DeviceVerificationVersion';

function DeviceVerificationProtocolsTrace(props) {
  const { showSnack } = useContext(SnackContext);
  const onlyItemsInGraph = !!props.onlyItemsInGraph;

  const { data, error, loading } = useQuery(GET_DEVICE_VERIFICATION_PROTOCOLS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let renderItems;

      if (props.isRiskTrace) {
        renderItems = itemsWithUpstreamDesignTraceMatrixTraces(props.graph.edges, typeName);
      } else {
        renderItems = onlyItemsInGraph
          ? itemsWithExistingTraces(props.graph.edges, typeName)
          : data.deviceVerifications.filter(removeRetired);
      }

      if (renderItems.length === 0) {
        showSnack('No device verification protocol traces found.');
      }
    },
  });

  if (error) throw new Error(`Failed to load device verification protocol ${props.id}: ${error.message}`);
  if (loading) return <Loading />;

  let renderItems;

  if (props.isRiskTrace) {
    renderItems = itemsWithUpstreamDesignTraceMatrixTraces(props.graph.edges, typeName);
  } else {
    renderItems = onlyItemsInGraph
      ? itemsWithExistingTraces(props.graph.edges, typeName)
      : data.deviceVerifications.filter(removeRetired);
  }

  return renderTraces(renderItems, props);
}

export default DeviceVerificationProtocolsTrace;
