import React, { useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import GetApp from '@material-ui/icons/GetApp';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Modal } from '@material-ui/core';

import { Trans } from '@lingui/macro';
import truncate from 'lodash/truncate';

import FileUploadSnackbar from '../DocumentUploader/FileUploadSnackbar';
import YesOrNo from '../YesOrNo';
import styles from './styles';

import fileNameFromURL from '../../utils/fileNameFromURL';
import WithUploadParams, { WithUploadParamsProps } from '../WithUploadParams';

interface DirectDocumentUploaderProps extends WithUploadParamsProps {
  classes;
  acceptedFileTypes?: string;
  attrName: string;
  attachmentId?: string;
  locked: boolean;
  readOnly?: boolean;
  onCompleted?: () => void;
  displayOnly?: boolean;
  beforeUpload?: () => void;
  beforeDelete?: () => void;
  versionId: string;
  fileUrl?: string;
  url: string;
  params: { name: string; value: string }[];
  createAttachment?: (location: string) => Promise<any>;
  deleteAttachment?: (attachmentId: string) => Promise<any>;
}

const DirectDocumentUploader = (props: DirectDocumentUploaderProps) => {
  const {
    attrName,
    classes,
    locked,
    versionId,
    acceptedFileTypes,
    fileUrl,
    url,
    createAttachment,
    deleteAttachment,
    attachmentId,
    beforeDelete,
    onCompleted,
    displayOnly,
    readOnly,
    params,
  } = props;

  const [file, setFile] = useState({ payload: '', name: fileUrl ? fileNameFromURL(fileUrl) : null });
  const [displaySnackbar, setDisplaySnackbar] = useState<boolean>(false);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleFileChange = async (event) => {
    event.preventDefault();

    let file = event.target.files[0];

    if (file && formRef.current) {
      const formData = new FormData(formRef.current);
      await directFileUpload(formData);
      setFile({ payload: file, name: file.name });
    }
  };

  const directFileUpload = async (formData) => {
    const file = formData.get('file') as File;
    const sanitizedFilename = file.name.replace(/\s/g, '_');
    formData.set('file', new File([file], sanitizedFilename, { type: file.type }));
    const resp = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!resp.ok) throw new Error(`Upload failed: ${resp.statusText}`);

    if (!resp.headers.get('Location')) throw new Error('Upload failed: response contained no Location header');

    const location = resp.headers.get('Location');
    const attachmentURL = decodeURIComponent(location!);

    try {
      if (createAttachment) {
        await createAttachment(attachmentURL);
        onCompleted && onCompleted();
      }
    } catch (err) {
      throw new Error(`Error creating attachment (versionId: ${versionId}): ${err.message}`);
    }
  };

  const deleteFile = async () => {
    if (!attachmentId) return;
    beforeDelete && beforeDelete();

    try {
      if (deleteAttachment) {
        await deleteAttachment(attachmentId);
        setDisplaySnackbar(true);
        setDeleteAction(true);
        setIsModalOpen(false);
        setFile({ payload: '', name: null });
        onCompleted && onCompleted();
      }
    } catch (e) {
      throw new Error(`Deletion of attachment failed: ${e.message}`);
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const hideSnackbar = () => {
    setDisplaySnackbar(false);
  };

  const handleFileDownload = () => {
    window.open(fileUrl);
  };

  return (
    <>
      <form onChange={handleFileChange} ref={formRef}>
        <div className={classes.inputWrapper as string}>
          <div style={{ flex: 1 }} className={(locked || file.name) && classes.disabledInput}>
            {params.map(({ name, value }) => (
              <input type="hidden" name={name} value={value || ''} key={name} />
            ))}
            {(file.name === undefined || file.name === null) && (
              <input
                id={`${attrName}-input`}
                accept={acceptedFileTypes}
                name={'file'}
                type="file"
                style={{ display: 'none' }}
                disabled={locked || Boolean(file.name)}
              />
            )}
            <label htmlFor={`${attrName}-input`}>
              <Button
                data-testid="upload-btn"
                component="span"
                className={classes.uploaderBtn as string}
                fullWidth
                disabled={Boolean(locked || file.name)}
              >
                {file.name ? <span>{truncate(file.name, { length: 25 })}</span> : <Trans>Attach File</Trans>}
              </Button>
            </label>
          </div>
          <div
            style={{
              display: file.name ? 'flex' : 'none',
            }}
            className={classes.buttonGroup as string}
          >
            {fileUrl && (
              <div style={{ display: 'inline' }} onClick={handleFileDownload}>
                <GetApp className={classes.pdfActionButton} data-testid="download-pdf-btn" />
              </div>
            )}

            {!displayOnly && !locked && !readOnly && (
              <div style={{ display: 'inline' }} onClick={handleModalOpen}>
                <Delete className={classes.pdfActionButton} data-testid="delete-attachment-btn" />
              </div>
            )}
            <Modal open={isModalOpen} onClose={handleModalClose}>
              <Card classes={{ root: classes.modalCard }}>
                <CardContent classes={{ root: classes.cardContent }}>
                  <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={handleModalClose} color="action" />
                  <YesOrNo
                    heading={'Delete Attachment?'}
                    yesLabel="Delete"
                    noLabel="Cancel"
                    handleComplete={deleteFile}
                    closeOnNo
                    handleClose={handleModalClose}
                    headingWithoutPromptText={true}
                  />
                </CardContent>
              </Card>
            </Modal>
          </div>
        </div>
      </form>

      <FileUploadSnackbar
        onClose={hideSnackbar}
        open={displaySnackbar}
        variant={deleteAction ? 'deleteSuccess' : 'success'}
        variantActions={{ error: directFileUpload }}
        message
      />
    </>
  );
};

export default withStyles(styles)(WithUploadParams(DirectDocumentUploader));
