import React from 'react';

import { useMutation } from 'react-apollo';
import semver from 'semver';

import { AttachFile } from '@material-ui/icons';
import { Paper } from '@material-ui/core';

import { LabelText, Spacer } from 'components';
import AttachmentControl from 'components/AttachmentsSection/AttachmentControl';
import {
  CREATE_DOCUMENT_ATTACHMENT,
  DESTROY_DOCUMENT_ATTACHMENT,
  CREATE_RAW_REF_ATTACHMENT,
  DESTROY_RAW_REF_ATTACHMENT,
} from './gql';
import { useStyles } from './styles';
import UploadInfo from './UploadInfo';

interface Props {
  versionData: any;
  onUpdate: () => void;
  displayUploadInfo?: boolean;
}

export default function DocumentStyleUploader(props: Props) {
  const { versionData, displayUploadInfo } = props;
  const classes = useStyles();

  const [createDocumentAttachment] = useMutation(CREATE_DOCUMENT_ATTACHMENT);
  const [destroyDocumentAttachment] = useMutation(DESTROY_DOCUMENT_ATTACHMENT);
  const [createRawReferenceAttachment] = useMutation(CREATE_RAW_REF_ATTACHMENT);
  const [destroyRawReferenceAttachment] = useMutation(DESTROY_RAW_REF_ATTACHMENT);
  const handleDocumentAttachmentChange = (versionId, attachmentURL) =>
    createDocumentAttachment({
      variables: {
        versionId,
        attachmentURL,
      },
    }).then(() => props.onUpdate());

  const handleDocumentAttachmentDeletion = () =>
    destroyDocumentAttachment({
      variables: { id: versionData.attachment.id },
    }).then(() => props.onUpdate());

  const handleRawRefAttachmentChange = (versionId, attachmentURL) =>
    createRawReferenceAttachment({
      variables: {
        versionId,
        attachmentURL,
      },
    }).then(() => props.onUpdate());

  const handleRawRefAttachmentDeletion = () =>
    destroyRawReferenceAttachment({
      variables: { id: versionData.rawReferenceAttachment.id },
    }).then(() => props.onUpdate());

  return (
    <Paper className={classes.container}>
      <LabelText Icon={AttachFile} labelText="PDF Document" />
      <AttachmentControl
        accept={['.pdf']}
        versionId={versionData.id}
        locked={versionData.locked}
        attachment={versionData.attachment}
        createAttachment={handleDocumentAttachmentChange}
        destroyAttachment={handleDocumentAttachmentDeletion}
        singleAttachment
        downloadable
      />
      {displayUploadInfo && <UploadInfo attachment={versionData.attachment} />}
      {semver.gte(versionData.displayAsApplicationVersion, '4.3.0') && (
        <>
          <Spacer factor={4} />
          <LabelText Icon={AttachFile} labelText="Editable Document" />
          <AttachmentControl
            accept={['*']}
            versionId={versionData.id}
            locked={versionData.locked}
            attachment={versionData.rawReferenceAttachment}
            createAttachment={handleRawRefAttachmentChange}
            destroyAttachment={handleRawRefAttachmentDeletion}
            singleAttachment
            downloadable
          />
          {displayUploadInfo && <UploadInfo attachment={versionData.rawReferenceAttachment} />}
        </>
      )}
    </Paper>
  );
}
