import React from 'react';

import { useMutation } from 'react-apollo';
import { ApolloQueryResult } from 'apollo-client';

import AttachmentsSection from 'components/AttachmentsSection';

import { CREATE_REFERENCED_DOCUMENT, DESTROY_REFERENCED_DOCUMENT, UPDATE_REFERENCED_DOCUMENT } from './gql';
import { VerificationRecordVersionQuery_verificationRecordVersion } from '../__generated__/VerificationRecordVersionQuery';

interface AdditionalInformationProps {
  onUpdate: (variables?: Record<string, any>) => Promise<ApolloQueryResult<any>>;
  currentVersion: VerificationRecordVersionQuery_verificationRecordVersion;
}

const AdditionalInformation = (props: AdditionalInformationProps) => {
  const { onUpdate, currentVersion } = props;

  const { locked } = currentVersion;

  const [createAttachment] = useMutation(CREATE_REFERENCED_DOCUMENT);
  const [updateAttachment] = useMutation(UPDATE_REFERENCED_DOCUMENT);
  const [destroyAttachment] = useMutation(DESTROY_REFERENCED_DOCUMENT);

  const handleCreateAttachment = async (versionId, attachmentURL) => {
    try {
      await createAttachment({ variables: { versionId, attachmentURL } });
      return await onUpdate();
    } catch (error) {
      throw new Error(`Adding referenced document failed: ${error.message}`);
    }
  };

  const handleUpdateAttachment = async (id, attachment) => {
    try {
      await updateAttachment({
        variables: {
          attachmentId: id,
          attachmentURL: attachment.url,
          description: attachment.description,
        },
      });
      return await onUpdate();
    } catch (error) {
      throw new Error(`Updating referenced document failed: ${error.message}`);
    }
  };

  const handleDestroyAttachment = async (id) => {
    try {
      await destroyAttachment({ variables: { id } });
      return await onUpdate();
    } catch (error) {
      throw new Error(`Deletion of referenced document failed: ${error.message}`);
    }
  };

  return (
    <div>
      <AttachmentsSection
        attachments={currentVersion.referencedAttachments}
        displayHeader={false}
        enablePreview={false}
        locked={locked}
        versionId={currentVersion.id}
        createAttachment={handleCreateAttachment}
        updateAttachment={handleUpdateAttachment}
        destroyAttachment={handleDestroyAttachment}
      />
    </div>
  );
};

export { AdditionalInformation };
