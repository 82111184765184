import React, { useState } from 'react';
import { EllipsesMenuButton } from 'components';
import {
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { getAuthToken, getBaseApiUrl } from 'utils';
import OpenInNew from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    controlButton: {
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.main,
        '&:hover': { color: theme.palette.blueDark },
      },
      padding: 0,
    },
  }),
);

function ExportMenuButton(props) {
  const classes = useStyles();
  const [showEllipsesMenu, setShowEllipsesMenu] = useState(false);

  let buttonRef;
  const downloadExport = (itemType) => {
    // window.location.origin is needed becasue getBaseApiUrl() returns '' in dev
    let exportUrl = new URL(getBaseApiUrl() || window.location.origin);
    exportUrl.pathname = 'exports/csv.csv';
    exportUrl.searchParams.set('token', getAuthToken()[0] || '');
    exportUrl.searchParams.set('item_type', itemType);

    window.open(exportUrl.toString(), '_blank');
  };

  return (
    <>
      <EllipsesMenuButton
        buttonRef={(e) => (buttonRef = e)}
        onClick={() => {
          setShowEllipsesMenu((state) => !state);
        }}
        classes={{ controlButton: classes.controlButton }}
      />

      <Popper
        anchorEl={() => buttonRef}
        open={showEllipsesMenu}
        placement="bottom-end"
        disablePortal
        style={{ zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={() => setShowEllipsesMenu(false)}>
          <Paper style={{ boxShadow: '0px 2px 20px 1px rgba(182, 182, 182, 0.25)' }}>
            <MenuList>
              <MenuItem onClick={() => downloadExport(props.itemType)}>
                <ListItemIcon>
                  <OpenInNew />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                  <Typography variant="body2">
                    <Trans>Export Data</Trans>
                  </Typography>
                </ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default ExportMenuButton;
