import React from 'react';

import Select, { SelectProps } from 'components/core/form/Select';

import ColorBox from './ColorBox';

function renderValue(options, includeLabelWhenSelected: boolean = true, placeholder: string | undefined = undefined) {
  const mapping = options.reduce((obj, opt) => {
    obj[opt.value] = opt;
    return obj;
  }, {});

  return (value) => {
    const selected = mapping[value];

    if (placeholder && selected && selected.value === null) {
      return <div>{placeholder}</div>;
    }
    return !selected ? value : <Option color={selected.color}>{includeLabelWhenSelected && selected.label}</Option>;
  };
}

function Option(props) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ColorBox color={props.color} /> {props.children}
    </div>
  );
}

interface Props {
  [key: string]: any;
  includeLabelWhenSelected?: boolean;
  placeholder?: string;
  options: { value: any; color: string; label: string }[];
  viewType?: string;
}

function RiskLevelSelect(props: Props & SelectProps) {
  const { options, includeLabelWhenSelected, placeholder, viewType, ...rest } = props,
    formattedOptions = options.map((opt) => ({
      value: opt.value,
      label: <Option color={opt.color}>{opt.label}</Option>,
    }));

  return (
    <Select
      {...rest}
      options={formattedOptions}
      renderValue={renderValue(options, includeLabelWhenSelected, placeholder)}
      viewType={viewType}
    />
  );
}

export default RiskLevelSelect;
