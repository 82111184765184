import React from 'react';
import { Query } from 'react-apollo';
import get from 'lodash/get';

import { itemTypesQuery } from './gql/itemTypesQuery';

export const withItemTypeMetadata = (Component) => (componentProps) => {
  return (
    <Query query={itemTypesQuery} fetchPolicy="network-only">
      {({ data, loading, error }) => {
        // eslint-disable-next-line no-console
        if (error) console.log(error);
        let itemTypeMap = {};
        get(data, 'itemTypes', []).forEach((t) => {
          itemTypeMap[t.name] = t;
        });
        return <Component allItemTypeMetadata={itemTypeMap} loadingItemTypes={loading} {...componentProps} />;
      }}
    </Query>
  );
};
