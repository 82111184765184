import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
// import { navigate } from '@reach/router';
import { compose } from 'recompose';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import LinkOff from '@material-ui/icons/LinkOff';
import {
  IconButton,
  // Modal,
  Paper,
  withStyles,
} from '@material-ui/core';

import { removeMember } from '../gql';
// import LongCardHeader from 'components/LongCardHeader';
// import TopRowControls from 'components/LongCardControls/TopRowControls';
import {
  CommentsControl,
  TracingControl,
  TagsControl,
  BottomRowContainer,
} from 'components/LongCardControls/BottomRowControls';
// import VersionSwitch from 'components/VersionSwitch';

import {
  // VersionAndStatusDot,
  RemoveFromSetModal,
} from 'components';
import { isEmpty } from 'lodash';
// import AddVersionModal from 'components/AddVersionModal';
// import { createVersion } from 'utils/gql/timelineQueries';

// import { switchVersion, removeMember } from '../gql';
// import updateTitleMut from 'utils/gql/updateTitleMutation';
// import CardBody from './CardBody';
// import LongCardLoading from 'components/LongCardLoading';

interface REQLongCardProps {
  classes: any;
  i18n: any;
  theme;
  title: string;
  versionId: string;
  versionIdentifier: string;
  currentStatusName: string;
  versionToggleEnabled: boolean;
  memberId: string;
  locked: boolean;
  itemId: string;
  itemTags: string[];
  parentSetItemSlug: string;
  customIdentifier: string;
  requirementDescription: string;
  requirementTypes: string[];
  displayOnly?: boolean;
  commentCount: number;
  workingVersion?: {
    id: string;
    versionIdentifier: string;
    currentStatusName: string;
    currentStatus: { name: string };
  };
  currentRelease?: {
    id: string;
    versionIdentifier: string;
    currentStatusName: string;
    currentStatus: { name: string };
  };
}

const styles = (theme) => ({
  iconButton: {
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.blueDark,
    },
  },
  smallIcon: {
    width: 20,
  },
  topRowIcons: {
    marginLeft: 'auto',
    float: 'right',
  },
  iconCarrot: {
    color: theme.palette.blueDark,
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
});

function RequirementLongCard({
  classes,
  i18n,
  // workingVersion,
  // currentRelease,
  // versionToggleEnabled,
  // theme,
  title,
  versionId,
  versionIdentifier,
  // currentStatusName,
  memberId,
  locked,
  // requirementDescription,
  // requirementTypes,
  itemId,
  itemTags,
  parentSetItemSlug,
  customIdentifier,
  displayOnly,
  commentCount,
}: REQLongCardProps) {
  const [showComments, setShowComments] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showTracing, setShowTracing] = useState(false);

  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
  // const [addVersionModalOpen, setAddVersionModalOpen] = useState(false);

  // const [versionSwitchVal, setVersionSwitchVal] = useState(versionId);
  // const [switchRev, { loading: updateLoading }] = useMutation(switchVersion);

  // const [updateTitle, { error: updateTitleErr }] = useMutation(updateTitleMut);
  const [removeFromSet, { error: errorRemove, called }] = useMutation(removeMember, {
    variables: { requirementsSetMemberId: memberId },
    refetchQueries: ['RequirementsSetVersionQuery'],
  });

  useEffect(() => {
    if (called && isEmpty(errorRemove) && showRemoveMemberModal) {
      setShowRemoveMemberModal(false);
    }
  }, [called, errorRemove]);

  // if (errorRemove) throw new Error(`Removing from Set failed: ${errorRemove}`);
  // if (updateTitleErr) throw new Error(`Title update failed: ${updateTitleErr}`);

  // const [addVersion, { loading: addLoading }] = useMutation(createVersion, {
  //   onCompleted: (data) => {
  //     const newVersionId = data.createVersion.version.id;
  //     switchRev({
  //       variables: {
  //         requirementsSetMemberId: memberId,
  //         memberVersionId: newVersionId,
  //       },
  //     })
  //       .then(({ errors }) => {
  //         if (!errors) setVersionSwitchVal(newVersionId);
  //       })
  //       .catch((e) => {
  //         throw new Error(`Error ocurred while switching version toggle for REQ:${itemId} version:${versionId} ${e}`);
  //       });
  //   },
  // });

  // if (updateLoading || addLoading) return <LongCardLoading />;

  return (
    <Paper
      elevation={0}
      // style={{
      //   width: '100%',
      //   marginBottom: theme.spacing(1),
      //   display: 'flex',
      //   flexDirection: 'column',
      // }}
    >
      {/*<LongCardHeader*/}
      {/*  onTitleChange={*/}
      {/*    locked*/}
      {/*      ? undefined*/}
      {/*      : (newValue) =>*/}
      {/*          updateTitle({*/}
      {/*            variables: {*/}
      {/*              input: {*/}
      {/*                versionId,*/}
      {/*                attrName: 'title',*/}
      {/*                stringValue: newValue,*/}
      {/*              },*/}
      {/*            },*/}
      {/*          })*/}
      {/*  }*/}
      {/*  title={title}*/}
      {/*  titleEditable={!locked}*/}
      {/*  customIdentifier={customIdentifier}*/}
      {/*  handleTitlePrefix={() => navigate(`/category/requirements/${itemId}`)}*/}
      {/*  hideCheckbox={displayOnly}*/}
      {/*  Controls={*/}
      {/*    <TopRowControls*/}
      {/*      expandCarrot={{*/}
      {/*        visible: false,*/}
      {/*      }}*/}
      {/*      removeFromSetIcon={{*/}
      {/*        onClick: () => setShowRemoveMemberModal(!showRemoveMemberModal),*/}
      {/*        visible: true,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  }*/}
      {/*/>*/}

      {/*<RemoveFromSetModal*/}
      {/*  open={showRemoveMemberModal}*/}
      {/*  onClose={() => setShowRemoveMemberModal(false)}*/}
      {/*  remove={removeFromSet}*/}
      {/*  heading={`Unlink ${customIdentifier}: ${title} from Requirements Set?`}*/}
      {/*  prompt={`Making this change will remove any pending traces attached to ${customIdentifier} - ${versionIdentifier} in the Trace Matrix`}*/}
      {/*/>*/}

      {/*<CardBody*/}
      {/*  locked={locked}*/}
      {/*  requirementDescription={requirementDescription}*/}
      {/*  requirementTypes={requirementTypes}*/}
      {/*  versionId={versionId}*/}
      {/*/>*/}

      <BottomRowContainer>
        {/*{versionToggleEnabled ? (*/}
        {/*  <VersionSwitch*/}
        {/*    currentRelease={currentRelease}*/}
        {/*    workingVersion={workingVersion}*/}
        {/*    value={versionSwitchVal}*/}
        {/*    onToggle={(value) => {*/}
        {/*      setVersionSwitchVal(value);*/}
        {/*      switchRev({*/}
        {/*        variables: {*/}
        {/*          requirementsSetMemberId: memberId,*/}
        {/*          memberVersionId: value,*/}
        {/*        },*/}
        {/*      }).catch((e) => {*/}
        {/*        throw new Error(*/}
        {/*          `Error ocurred while switching version toggle for REQ:${itemId} version:${versionId} ${e}`,*/}
        {/*        );*/}
        {/*      });*/}
        {/*    }}*/}
        {/*    onAddClick={() => {*/}
        {/*      setAddVersionModalOpen(true);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <div style={{ display: 'flex', alignItems: 'center', padding: 8 }}>*/}
        {/*    <VersionAndStatusDot version={versionIdentifier} status={currentStatusName} />*/}
        {/*  </div>*/}
        {/*)}*/}
        {!displayOnly && (
          <>
            <TagsControl
              onClick={() => setShowTags(!showTags)}
              onClose={() => setShowTags(false)}
              itemId={itemId}
              tags={itemTags}
              showPopup={showTags}
              queryToRefetch="RequirementsSetVersionQuery"
              disabled={locked}
            />

            <TracingControl
              onClick={() => setShowTracing(!showTracing)}
              onClose={() => setShowTracing(false)}
              versionId={versionId}
              itemId={itemId}
              showPopup={showTracing}
              parentSetItemSlug={parentSetItemSlug}
              disabled={locked}
            />

            <CommentsControl
              onClick={() => setShowComments(!showComments)}
              onClose={() => setShowComments(false)}
              showPopup={showComments}
              itemId={itemId}
              commentCount={commentCount}
              queryToRefetch="RequirementsSetVersionQuery"
              disabled={locked}
            />

            <IconButton
              aria-label={i18n._(t`Remove Item From Set`)}
              disableRipple
              className={classes.iconButton}
              style={{ paddingRight: 16 }}
              onClick={() => {
                setShowRemoveMemberModal(!showRemoveMemberModal);
              }}
              disabled={locked}
            >
              <LinkOff classes={{ root: classes.smallIcon }} />
            </IconButton>
          </>
        )}
      </BottomRowContainer>
      <RemoveFromSetModal
        open={showRemoveMemberModal}
        onClose={() => {
          setShowRemoveMemberModal(false);
        }}
        remove={removeFromSet}
        heading={`Unlink ${customIdentifier}: ${title} from Requirements Set?`}
        prompt={`Making this change will remove any pending traces attached to ${customIdentifier} - ${versionIdentifier} in the Trace Matrix`}
      />

      {/*<AddVersionModal*/}
      {/*  open={addVersionModalOpen}*/}
      {/*  onClose={() => {*/}
      {/*    setAddVersionModalOpen(false);*/}
      {/*  }}*/}
      {/*  customIdentifier={customIdentifier}*/}
      {/*  handleComplete={async () => {*/}
      {/*    await addVersion({*/}
      {/*      variables: {*/}
      {/*        input: {*/}
      {/*          itemId,*/}
      {/*        },*/}
      {/*      },*/}
      {/*    });*/}
      {/*    setAddVersionModalOpen(false);*/}
      {/*  }}*/}
      {/*/>*/}
    </Paper>
  );
}

export default compose(
  // @ts-ignore
  withStyles(styles),
  withI18n(),
)(RequirementLongCard);
