import React, { useMemo, useState } from 'react';

import { Chip, Grid, Typography, Tooltip, IconButton } from '@material-ui/core';
import {
  BrokenImage,
  DescriptionOutlined,
  DirectionsRun,
  FindReplace,
  FlashOn,
  Grain,
  GridOn,
  Report,
  ReportProblem,
} from '@material-ui/icons';

import { get, isUndefined } from 'lodash';
import Arrow from 'react-xarrows';

import AccountTree from 'assets/icons/AccountTree';
import { TextAreaField, TriStateToggle, EnhancedAutocomplete } from 'components';
import ConfirmationDialog from 'components/ConfirmationDialog';
import RiskLevelSelect from 'components/RiskLevelSelect';
import { useDebounce } from 'hooks/useDebounce';

import RiskFieldSection from '../RiskFieldSection';
import RiskDiagramRow from '../RowDiagramRow';
import RpcInput from '../RpcComponent';

import { useStyles } from './styles';

import { RiskVersionQuery_riskVersion } from '../__generated__/RiskVersionQuery';
import { levelsDictToOptions } from '../utils';

enum ViewType {
  CARD,
  DIAGRAM,
}

interface Props {
  riskVersion: RiskVersionQuery_riskVersion;
  onFieldSave: (field: string) => (value: any) => void;
  dictionaries: any;
  callbacks: any;
  viewType?: string;
}

const RiskAnalysisAndEvaluation = (props: Props) => {
  const classes = useStyles();

  const { riskVersion, onFieldSave, dictionaries, callbacks, viewType } = props;
  const { probabilityLevels, severityLevels, harms, hazards } = dictionaries;
  const { onHazardsChange, onHarmChange } = callbacks;
  const { locked } = riskVersion;

  const probabilityOptions = useMemo(() => levelsDictToOptions(probabilityLevels, 'Select Probability'), [
    probabilityLevels,
  ]);
  const severityOptions = useMemo(() => levelsDictToOptions(severityLevels, 'Select Severity'), [severityLevels]);

  const [view, setView] = useState<ViewType>(ViewType.DIAGRAM);
  const [dialog, setDialog] = useState<{ accept: boolean | null; open: boolean }>({ accept: null, open: false });

  const onRiskAccessibilityChange = (value) => {
    if (!isUndefined(value)) {
      if (riskVersion.finalRiskAcceptability === null) {
        onFieldSave('finalRiskAcceptability')(value);
      } else {
        setDialog({ accept: value, open: true });
      }
      return;
    }
    onFieldSave('finalRiskAcceptability')(null);
  };

  const onRiskAccessibilityConfirm = (value: boolean) => {
    onFieldSave('finalRiskAcceptability')(value);
    setDialog({ accept: null, open: false });
  };

  const onChange = (field: string) => (event) => {
    const value = event.target.value;
    onFieldSave(field)(value);
  };

  const onChangeDebounced = useDebounce((field: string) => (value: string) => {
    onFieldSave(field)(value);
  });

  const switchViewButton = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={4} />
        <Grid item xs={8} className={classes.switchViewPanel}>
          {view === ViewType.CARD && (
            <Chip size={'small'} label="Unmitigated" className={classes.switchViewPanelText} />
          )}
          <Tooltip title={view === ViewType.CARD ? 'Switch to Diagram view' : 'Switch to Card View'}>
            <IconButton
              className={classes.switchButton}
              aria-label="switch-view"
              onClick={() => setView(view === ViewType.CARD ? ViewType.DIAGRAM : ViewType.CARD)}
            >
              {view === ViewType.CARD ? <AccountTree /> : <GridOn />}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const inlineToggle = () => {
    return (
      <div className={classes.inlineToggle}>
        <span>No</span>
        <TriStateToggle
          toggled={riskVersion.finalRiskAcceptability}
          onChange={onRiskAccessibilityChange}
          disabled={locked}
        />
        <span>Yes</span>
      </div>
    );
  };

  const hazardsControl = () => (
    <>
      <EnhancedAutocomplete
        onChange={onHazardsChange}
        loading={false}
        options={{
          label: 'Hazard',
          Icon: ReportProblem,
          placeholder: 'Add Hazard',
        }}
        suggestions={hazards.map((h) => h.name)}
        selected={get(riskVersion, 'hazard.name')}
        disabled={locked}
        chipTooltip
      />
    </>
  );

  const pOneControl = () => (
    <>
      <RiskFieldSection.Heading Icon={Grain}>P1</RiskFieldSection.Heading>
      <RiskLevelSelect
        placeholder={'Select Probability'}
        defaultValue={get(riskVersion, 'unmitigatedProbability1.id', null)}
        options={probabilityOptions}
        onChange={onChange('unmitigatedProbability1Id')}
        disabled={locked}
        viewType={viewType}
      />
    </>
  );
  const pTwoControl = () => (
    <>
      <RiskFieldSection.Heading Icon={Grain}>P2</RiskFieldSection.Heading>
      <RiskLevelSelect
        placeholder={'Select Probability'}
        defaultValue={get(riskVersion, 'unmitigatedProbability2.id', null)}
        options={probabilityOptions}
        onChange={onChange('unmitigatedProbability2Id')}
        disabled={locked}
        viewType={viewType}
      />
    </>
  );
  const sequenceOfEventsControl = () => (
    <>
      {' '}
      <RiskFieldSection.Heading Icon={DirectionsRun}>Sequence of Events</RiskFieldSection.Heading>
      <TextAreaField
        onTextChange={onChangeDebounced('sequenceOfEvents')}
        locked={locked}
        id="sequenceOfEvents"
        initialValue={riskVersion.sequenceOfEvents}
        versionId={riskVersion.id}
        attrName="sequence_of_events"
        maxLength={850}
        rows={4}
        placeholder="Describe sequence of events"
      />
    </>
  );

  const hazardousSituationControl = () => (
    <>
      <RiskFieldSection.Heading Icon={FlashOn}>Hazardous situation</RiskFieldSection.Heading>
      <TextAreaField
        onTextChange={onChangeDebounced('hazardousSituation')}
        locked={locked}
        id="hazardousSituation"
        initialValue={riskVersion.hazardousSituation}
        maxLength={850}
        versionId={riskVersion.id}
        attrName="hazardous_situation"
        rows={4}
        placeholder="Describe hazardous situation"
      />
    </>
  );

  const probabilityControl = () => (
    <>
      <RiskFieldSection.Heading Icon={Grain}>Probability</RiskFieldSection.Heading>
      <RiskLevelSelect
        placeholder={'Select Probability'}
        defaultValue={get(riskVersion, 'unmitigatedProbability.id', null)}
        options={probabilityOptions}
        onChange={onChange('unmitigatedProbabilityId')}
        disabled={locked}
        viewType={viewType}
      />
    </>
  );

  const severityControl = () => (
    <>
      <RiskFieldSection.Heading Icon={BrokenImage} style={{ marginTop: 'auto' }}>
        Severity
      </RiskFieldSection.Heading>
      <RiskLevelSelect
        placeholder={'Select Severity'}
        defaultValue={get(riskVersion, 'unmitigatedSeverity.id', null)}
        options={severityOptions}
        onChange={onChange('unmitigatedSeverityId')}
        disabled={locked}
        viewType={viewType}
      />
    </>
  );

  const harmControl = () => (
    <EnhancedAutocomplete
      onChange={onHarmChange('harm')}
      loading={false}
      options={{
        label: 'Harm',
        Icon: Report,
        placeholder: 'Add Harm',
      }}
      suggestions={harms.map((h) => h.name)}
      // @ts-ignore
      selected={get(riskVersion, 'harm.name')}
      disabled={locked}
      chipTooltip
    />
  );
  const unmitigatedControl = () => (
    <div>
      <RiskFieldSection.Heading Icon={FindReplace}>Unmitigated RPC</RiskFieldSection.Heading>
      <RpcInput
        placeholder={'RPC cannot be determined'}
        color={get(riskVersion, 'unmitigatedRiskPriorityCode.color')}
        inputValue={get(riskVersion, 'unmitigatedRiskPriorityCode.name')}
      />
      <div className={classes.divider} />

      <Typography component={'div'} variant={'body2'}>
        Considering the medical benefits, is risk acceptable?
        <br />
        {inlineToggle()}
      </Typography>

      <div className={classes.divider} />
      <RiskFieldSection.Heading Icon={DescriptionOutlined}>Risk Justification</RiskFieldSection.Heading>
      <TextAreaField
        onTextChange={onChangeDebounced('justification')}
        locked={locked}
        id="justification"
        initialValue={riskVersion.justification}
        versionId={riskVersion.id}
        maxLength={850}
        attrName="justification"
        rows={4}
        placeholder="Add justification"
      />
    </div>
  );

  const diagramView = () => (
    <>
      <RiskDiagramRow>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <RiskFieldSection id="box1">{hazardsControl()}</RiskFieldSection>
        </Grid>
        <Grid item xs={4} />
      </RiskDiagramRow>
      <RiskDiagramRow>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <RiskFieldSection id="box2">{pOneControl()}</RiskFieldSection>
        </Grid>
        <Grid item xs={4}>
          <RiskFieldSection id="box3">{sequenceOfEventsControl()}</RiskFieldSection>
        </Grid>
        <Grid item xs={3}>
          <RiskFieldSection id="box4">
            <Typography variant={'body2'}>Circumstances Affecting Severity</Typography>
          </RiskFieldSection>
        </Grid>
        <Grid item xs={1} />
      </RiskDiagramRow>
      <RiskDiagramRow>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <RiskFieldSection id="box5">{hazardousSituationControl()}</RiskFieldSection>
        </Grid>
        <Grid item xs={4} />
      </RiskDiagramRow>
      <RiskDiagramRow>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <RiskFieldSection id="box6">{pTwoControl()}</RiskFieldSection>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={3}>
          <RiskFieldSection id="box7">
            <Typography variant={'body2'}>Circumstances Affecting Severity</Typography>
          </RiskFieldSection>
        </Grid>
        <Grid item xs={1} />
      </RiskDiagramRow>
      <RiskDiagramRow
        border={{
          style: 'dashed',
          width: '2px',
          color: '#ccc',
        }}
        label={'RISK'}
      >
        <Grid item xs={1} />
        <Grid item xs={3}>
          <RiskFieldSection id="box8">{probabilityControl()}</RiskFieldSection>
        </Grid>
        <Grid item xs={4}>
          <RiskFieldSection id="box9">{harmControl()}</RiskFieldSection>
        </Grid>
        <Grid item xs={3}>
          <RiskFieldSection id="box10">{severityControl()}</RiskFieldSection>
        </Grid>
        <Grid item xs={1} />
      </RiskDiagramRow>
      <RiskDiagramRow>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <RiskFieldSection id="box11">{unmitigatedControl()}</RiskFieldSection>
        </Grid>
        <Grid item xs={4} />
      </RiskDiagramRow>
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'left'}
        endAnchor={'top'}
        start={'box1'}
        end={'box2'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'bottom'}
        endAnchor={'top'}
        start={'box1'}
        end={'box3'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'right'}
        endAnchor={'top'}
        start={'box1'}
        end={'box4'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'bottom'}
        endAnchor={{
          position: 'left',
          offset: {
            x: 0,
            y: -20,
          },
        }}
        start={'box2'}
        end={'box5'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'bottom'}
        endAnchor={'top'}
        start={'box3'}
        end={'box5'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'bottom'}
        endAnchor={{
          position: 'right',
          offset: {
            x: 0,
            y: -20,
          },
        }}
        start={'box4'}
        end={'box5'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={{
          position: 'left',
          offset: {
            x: 0,
            y: 20,
          },
        }}
        endAnchor={'top'}
        start={'box5'}
        end={'box6'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={{
          position: 'right',
          offset: {
            x: 0,
            y: 20,
          },
        }}
        endAnchor={'top'}
        start={'box5'}
        end={'box7'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'bottom'}
        endAnchor={'top'}
        start={'box6'}
        end={'box8'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'bottom'}
        endAnchor={'top'}
        start={'box5'}
        end={'box9'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'bottom'}
        endAnchor={'top'}
        start={'box7'}
        end={'box10'}
      />
      <Arrow
        strokeWidth={2}
        color={'#a7abb3'}
        path={'grid'}
        showXarrow
        startAnchor={'bottom'}
        endAnchor={'top'}
        start={'box9'}
        end={'box11'}
      />
    </>
  );
  const cardView = () => (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <RiskFieldSection>
          {hazardsControl()}
          <div className={classes.divider} />
          {sequenceOfEventsControl()}
          <div className={classes.divider} />
          {hazardousSituationControl()}
          <div className={classes.divider} />
          {harmControl()}
        </RiskFieldSection>
      </Grid>
      <Grid item xs={4}>
        <RiskFieldSection style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {pOneControl()}
          <div className={classes.divider} />
          {pTwoControl()}
          <div className={classes.divider} />
          {probabilityControl()}
          <div className={classes.divider} />
          {severityControl()}
        </RiskFieldSection>
      </Grid>
      <Grid item xs={4}>
        <RiskFieldSection>{unmitigatedControl()}</RiskFieldSection>
      </Grid>
    </Grid>
  );

  return (
    <>
      {switchViewButton()}
      {view === ViewType.CARD ? cardView() : diagramView()}
      <ConfirmationDialog
        open={dialog.open}
        title="Confirm Selection"
        onClose={() => setDialog({ accept: null, open: false })}
        onCancel={() => setDialog({ accept: null, open: false })}
        onConfirm={() => onRiskAccessibilityConfirm(dialog.accept as boolean)}
      >
        {dialog.accept
          ? 'You previously indicated that this risk was unacceptable. Are you sure the risk is acceptable without' +
            ' additional risk control? Any additional content added for risk control, identifying new risks, and overall ' +
            'risk evaluation will be removed.'
          : 'You previously indicated that this risk was acceptable. Are you sure the risk is unnacceptable? Your ' +
            'initial justification will be removed and you may complete additional risk control actions.'}
      </ConfirmationDialog>
    </>
  );
};

export default RiskAnalysisAndEvaluation;
