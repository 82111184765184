import React from 'react';
import { DateTime } from 'luxon';
import { withI18n } from '@lingui/react';
import flowRight from 'lodash/flowRight';

import { Divider, IconButton, Paper, Typography, withStyles } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import WarningOutlined from '@material-ui/icons/WarningOutlined';

import Spacer from 'components/Spacer';
import UserListItem from 'components/UserListItem';

const style = (theme) => ({
  root: {
    padding: theme.spacing(2),
    zIndex: '9999',
    boxShadow: '0px 2px 20px 1px rgba(182, 182, 182, 0.25)',
  },
  icon: {
    backgroundColor: 'transparent',
    width: 10,
    height: 10,
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  },
  item: {
    paddingBottom: theme.spacing(1),
  },
  heading: {
    color: theme.palette.blueDark,
  },
  jobRoleText: {
    color: theme.palette.secondary.main,
  },
  permissionTypeHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subHeading: {
    color: theme.palette.subHeading,
  },
  inactiveUserBlock: {
    display: 'flex',
    alignItems: 'center',
    background: '#F7F7F7',
    color: '#6E7583',
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '20px',
    textTransform: 'capitalize',
    paddingLeft: '12px',
  },
});

function renderUserListItem(user) {
  return (
    <UserListItem
      key={user.id}
      GridProps={{ item: true, xs: 12 }}
      name={user.fullName}
      title={user.title}
      userId={user.id}
    />
  );
}

function PeopleMenu({
  classes,
  i18n,
  approver,
  creator,
  createdAt,
  owner,
  ownerLocked,
  version,
  locked,
  onChangeOwnerClicked,
  onChangeApproverClicked,
  changeOwnerApproverVisible,
  workflow = null,
  setItemCustomIdentifier,
}) {
  const versionCreationDate = DateTime.fromISO(createdAt).toFormat('MMM. d yyyy');
  const versionCreationTime = DateTime.fromISO(createdAt).toFormat('hh:mm:ss a (ZZZZ)');

  const hasMemberWorkflow = workflow === 'set_item_member';

  return (
    <>
      <Paper
        classes={{ root: classes.root }}
        style={{ display: changeOwnerApproverVisible ? 'none' : 'grid' }}
        data-testid="people-menu"
      >
        <Typography variant="h4" className={classes.heading}>
          Version Creator
        </Typography>
        <Spacer factor={1} />
        <Typography variant="subtitle2" className={classes.subHeading}>
          {`${versionCreationDate} at ${versionCreationTime}`}
        </Typography>
        <Spacer factor={1} />
        <UserListItem
          key={creator.id}
          GridProps={{ item: true, xs: 12 }}
          name={creator.fullName}
          title={creator.title}
          userId={creator.id}
        />
        {owner && (
          <>
            <Spacer factor={2} />
            <Divider />
            <Spacer factor={2} />
            <div className={classes.ownerContainer}>
              <span className={classes.permissionTypeHeader}>
                <Typography variant="h4" className={classes.heading}>
                  Owner
                </Typography>
                <IconButton
                  color="primary"
                  disableRipple
                  disableFocusRipple
                  className={classes.icon}
                  onClick={onChangeOwnerClicked}
                  disabled={locked || hasMemberWorkflow || ownerLocked}
                >
                  <Edit style={{ width: 20 }} />
                </IconButton>
              </span>
              <Spacer factor={1} />
              {hasMemberWorkflow ? (
                <Typography variant="body2" className={classes.heading}>
                  {setItemCustomIdentifier
                    ? `N/A - Managed by ${setItemCustomIdentifier}`
                    : 'N/A - An associated Set has not been assigned'}
                </Typography>
              ) : (
                <>
                  <Typography variant="subtitle1" className={classes.heading}>
                    Role
                  </Typography>
                  <Typography variant="caption" className={classes.jobRoleText}>
                    {version.assignedOwnerJobRole.formattedRoleName}
                  </Typography>
                  <Spacer factor={2} />
                  <Typography variant="subtitle1" className={classes.heading}>
                    User
                  </Typography>
                  {owner.status === 'inactive' ? (
                    <div style={{ border: '1px solid #ecedee', width: '100%', margin: '0px' }}>
                      <Typography variant="body2" component="div" className={classes.inactiveUserBlock}>
                        <WarningOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
                        <span style={{ padding: '4px' }}>deactivated</span>
                      </Typography>
                      <div style={{ backgroundColor: '#fcfcfc', padding: '4px 8px 4px 4px' }}>
                        {renderUserListItem(owner)}
                      </div>
                    </div>
                  ) : (
                    <>
                      <Spacer factor={1} />
                      {renderUserListItem(owner)}
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
        {approver && (
          <>
            <Spacer factor={2} />
            <Divider />
            <Spacer factor={2} />
            <div className={classes.approverContainer}>
              <span className={classes.permissionTypeHeader}>
                <Typography variant="h4" className={classes.heading}>
                  Approver
                </Typography>
                <IconButton
                  color="primary"
                  disableRipple
                  disableFocusRipple
                  className={classes.icon}
                  onClick={onChangeApproverClicked}
                  disabled={locked || hasMemberWorkflow}
                >
                  <Edit style={{ width: 20 }} />
                </IconButton>
              </span>
              <Spacer factor={1} />
              {hasMemberWorkflow ? (
                <Typography variant="body2" className={classes.heading}>
                  {setItemCustomIdentifier
                    ? `N/A - Managed by ${setItemCustomIdentifier}`
                    : 'N/A - An associated Set has not been assigned'}
                </Typography>
              ) : (
                <>
                  <Typography variant="subtitle1" className={classes.heading}>
                    Role
                  </Typography>
                  <Typography variant="caption" className={classes.jobRoleText}>
                    {version.assignedApproverJobRole.formattedRoleName}
                  </Typography>
                  <Spacer factor={2} />
                  <Typography variant="subtitle1" className={classes.heading}>
                    User
                  </Typography>
                  {approver.status === 'inactive' ? (
                    <div style={{ border: '1px solid #ecedee', width: '100%', margin: '0px' }}>
                      <Typography variant="body2" component="div" className={classes.inactiveUserBlock}>
                        <WarningOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
                        <span style={{ padding: '4px' }}>deactivated</span>
                      </Typography>
                      <div style={{ backgroundColor: '#fcfcfc', padding: '4px 8px 4px 4px' }}>
                        {renderUserListItem(approver)}
                      </div>
                    </div>
                  ) : (
                    <>
                      <Spacer factor={1} />
                      {renderUserListItem(approver)}
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </Paper>
    </>
  );
}

export default flowRight([withI18n(), withStyles(style)])(PeopleMenu);
