import React, { MouseEventHandler, useContext } from 'react';

import { useStyles } from 'components/ItemControlMenus';
import { MenuItem, Typography, ListItemText, ListItemIcon } from '@material-ui/core';
import NodeAdd from '@material-ui/icons/NoteAdd';
import { Trans } from '@lingui/macro';
import { LockedContext } from 'contexts/lockedContext';

function CopyTrainingPlanMenuItem(props: { onClick: MouseEventHandler }) {
  const menuItemClasses = useStyles();
  const { locked } = useContext(LockedContext);
  return (
    <>
      <MenuItem disabled classes={{ root: menuItemClasses.menuItemHeader }}>
        <Typography variant="overline" classes={{ root: menuItemClasses.refIdText }}>
          <Trans>Copy Item</Trans>
        </Typography>
      </MenuItem>

      <MenuItem
        classes={{ root: menuItemClasses.menuItemText }}
        onClick={(e) => !locked && props.onClick(e)}
        disabled={locked}
      >
        <ListItemIcon classes={{ root: menuItemClasses.icon }}>
          <NodeAdd classes={{ root: menuItemClasses.icon }} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ variant: 'body2' }} classes={{ root: menuItemClasses.menuItem }}>
          <Trans>Copy Training Plan</Trans>
        </ListItemText>
      </MenuItem>
    </>
  );
}

export default CopyTrainingPlanMenuItem;
