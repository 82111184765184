import React from 'react';

import Typography from '@material-ui/core/Typography';

import { DateTime } from 'luxon';

import MakeLink from 'components/MakeLink';

import { ValidationRecordVersionQuery_validationRecordVersion_deviceValidationVersion } from '../__generated__/ValidationRecordVersionQuery';
import { useStyles } from '../styles';

interface AssociatedTestProtocolProps {
  deviceValidationVersion: ValidationRecordVersionQuery_validationRecordVersion_deviceValidationVersion;
}

const AssociatedTestProtocol = (props: AssociatedTestProtocolProps) => {
  const { deviceValidationVersion } = props;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.associatedRowContainer}>
        <Typography variant="subtitle1">
          <MakeLink item={deviceValidationVersion.item} newTab={true}>
            {deviceValidationVersion.item.customIdentifier}: {deviceValidationVersion.title}
          </MakeLink>
        </Typography>
      </div>
      <div className={classes.associatedRowContainer}>
        <Typography variant="subtitle1">Effective Date:&nbsp;</Typography>
        <Typography variant="body2">
          {DateTime.fromISO(deviceValidationVersion.releasedAt).toFormat('LLLL dd, yyyy')}
        </Typography>
      </div>
      <div className={classes.associatedRowContainer}>
        <Typography variant="subtitle1">Version:&nbsp;</Typography>
        <Typography variant="body2">{deviceValidationVersion.versionIdentifier}</Typography>
      </div>
    </div>
  );
};

export { AssociatedTestProtocol };
