import PropTypes from 'prop-types';
import React from 'react';

import { withStyles } from '@material-ui/core';

import { Link } from '@reach/router';

import { buildConditionalClasses } from 'utils';

import styles from './styles';

function NavLink({ classes, forceDisplayType, ...props }) {
  return (
    <Link
      {...props}
      getProps={({ isCurrent, isPartiallyCurrent, ...props }) => {
        let active;
        if (forceDisplayType === 'current') {
          active = true;
        } else if (forceDisplayType === 'not_current') {
          active = false;
        } else {
          active = isCurrent || isPartiallyCurrent;
        }
        return {
          className: buildConditionalClasses([
            [!active, classes.navLink],
            [active, classes.navLinkActive],
          ]),
        };
      }}
    />
  );
}

NavLink.propTypes = {
  classes: PropTypes.shape({
    navLink: PropTypes.string.isRequired,
    navLinkActive: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(NavLink);
