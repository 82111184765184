import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_theme) =>
  createStyles({
    agGridCell: {
      fontSize: '13px',
    },
    filterColumnsBarContainer: {
      display: 'flex',
      marginBottom: '10px',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    tableTitle: {
      flex: 1,
    },
  }),
);
