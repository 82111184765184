import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 16,
      textAlign: 'center',
    },
  }),
);
