import React from 'react';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { SelectSetItem, YesOrNo } from '../views';
import AssociateRisk from '../views/AssociateRisk';

const SetAssignedConfirm = ({ itemCustomIdentifier, currentVersion, ...props }) => (
  <YesOrNo
    disableOnSubmit
    heading={i18n._(t`No set assigned`)}
    prompt={`${i18n._(t`Assign`)} ${itemCustomIdentifier} ${currentVersion.versionIdentifier} ${i18n._(t`to set?`)}`}
    yesLabel={i18n._(t`ADD`)}
    noLabel={i18n._(t`DISMISS`)}
    closeOnNo
    {...props}
  />
);

export default {
  initialStep: 'setAssigned',
  internalSteps: {
    setAssigned: {
      component: (props) =>
        props.currentVersion.__typename === 'RiskControlVersion' ? (
          <AssociateRisk {...props} />
        ) : (
          SetAssignedConfirm({ ...props })
        ),
      getNextStep: () => 'addToSet',
    },
    addToSet: {
      component: SelectSetItem,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: () => null,
};
