export enum Status {
  Draft = 'draft',
  Prepared = 'prepared',
  UnderReview = 'under_review',
  Rejected = 'rejected',
  Released = 'released',
  Inactive = 'inactive',
  Canceled = 'canceled',
  Void = 'void',
}

export const Order = {
  [Status.Draft]: 0,
  [Status.UnderReview]: 1,
  [Status.Prepared]: 2,
  [Status.Rejected]: 3,
  [Status.Released]: 4,
  [Status.Inactive]: 5,
  [Status.Canceled]: 6,
  [Status.Void]: 7,
};

export enum ServerAction {
  StatusTransition,
  CreateVersion,
  UpdateVersion,
}

export type TransitionPayload =
  | {
      action: ServerAction.UpdateVersion;
      payload: {
        reasonForChange: string;
        descriptionOfChange: string;
      };
    }
  | {
      action: ServerAction.StatusTransition;
      payload: {
        toStatus: Status.Prepared | Status.UnderReview | Status.Released | Status.Draft | Status.Canceled | Status.Void;
        username: string;
        password: string;
        reasonForSignature: string;
      };
    }
  | {
      action: ServerAction.StatusTransition;
      payload: {
        toStatus: Status.Draft | Status.UnderReview;
      };
    }
  | {
      action: ServerAction.StatusTransition;
      payload: {
        toStatus: Status.Draft;
      };
    }
  | {
      action: ServerAction.StatusTransition;
      payload: {
        toStatus: Status.Rejected;
        username: string;
        password: string;
        reasonForSignature: string;
        reasonForRejection: string;
      };
    }
  | {
      action: ServerAction.CreateVersion | null;
    };
