import { currentUser } from 'compositions/__generated__/currentUser';
import {
  GridRef,
  applyColumnState,
  getFilterInstance,
  onFilterChanged,
  resetFilters,
  setModel,
} from 'utils/viewOptionUtils';
import { TaskWorkflowStatuses } from 'workflows/statuses';

/**
 *
 * @param gridRef
 * @param values
 *
 * @description Sets status filter model with given parameters
 *
 */
const setStatusModel = (gridRef: GridRef, values: string[]) => {
  const status = getFilterInstance(gridRef, 'currentVersion.currentStatusName');
  setModel(status, values);
};

/**
 * Handles in complete tasks option filtering
 */
const myInCompleteTasks = (gridRef: GridRef, currentUserData: currentUser | undefined) => {
  if (currentUserData && currentUserData.currentUser) {
    resetFilters(gridRef);
    setStatusModel(gridRef, [
      TaskWorkflowStatuses.Backlog.id,
      TaskWorkflowStatuses.ToDo.id,
      TaskWorkflowStatuses.InProgress.id,
    ]);
    const assignedPerson = getFilterInstance(gridRef, 'currentVersion.assignedPerson');
    setModel(assignedPerson, [currentUserData.currentUser.fullName]);
    onFilterChanged(gridRef);
    applyColumnState(gridRef, [{ colId: 'currentVersion.dueDate', sort: 'asc' }]);
  }
};

/**
 * Handles completed tasks option filtering
 */
const completedTasks = (gridRef: GridRef) => {
  resetFilters(gridRef);
  setStatusModel(gridRef, [TaskWorkflowStatuses.Done.id]);
  onFilterChanged(gridRef);
  applyColumnState(gridRef, [{ colId: 'currentVersion.releasedAt', sort: 'asc' }]);
};

/**
 * Handles blocked tasks option filtering
 */
const blockedTasks = (gridRef: GridRef) => {
  resetFilters(gridRef);
  setStatusModel(gridRef, [
    TaskWorkflowStatuses.BlockedAfterBacklog.id,
    TaskWorkflowStatuses.BlockedAfterToDo.id,
    TaskWorkflowStatuses.BlockedAfterInProgress.id,
    TaskWorkflowStatuses.BlockedAfterDone.id,
  ]);
  onFilterChanged(gridRef);
  applyColumnState(gridRef, [{ colId: 'customIdentifier', sort: 'asc' }]);
};

/**
 * Handles in progress tasks option filtering
 */
const inProgressTasks = (gridRef: GridRef) => {
  resetFilters(gridRef);
  setStatusModel(gridRef, [TaskWorkflowStatuses.InProgress.id]);
  onFilterChanged(gridRef);
  applyColumnState(gridRef, [{ colId: 'currentVersion.dueDate', sort: 'asc' }]);
};

/**
 * Handles all show tasks option filtering
 */
const allTasks = (gridRef: GridRef) => {
  resetFilters(gridRef);
  applyColumnState(gridRef, [{ colId: 'customIdentifier', sort: 'asc' }]);
};

const taskFunctionsMap = new Map([
  ['myIncompleteTasks', myInCompleteTasks],
  ['completedTasks', completedTasks],
  ['blockedTasks', blockedTasks],
  ['inProgressTasks', inProgressTasks],
  ['allTasks', allTasks],
]);

export { taskFunctionsMap };
