import React from 'react';

import { CertifiedSignature, RejectReason, YesOrNo } from 'compositions/TimelineGroup/steps/views';
import {
  buildActionMessage,
  buildReleaseMessage,
} from 'workflows/GenericWorkflow/steps/genericWorkflowTransitionsUtil';
import { CourseWorkflowStatuses } from 'workflows/statuses';

export default {
  initialStep: 'approveOrReject',
  internalSteps: {
    approveOrReject: {
      component: (props) => (
        <YesOrNo
          prompt={buildActionMessage('approve', props.currentVersion, props.itemCustomIdentifier)}
          yesLabel="Approve"
          noLabel="Reject"
          {...props}
        />
      ),
      getNextStep: (release) => (release ? 'approveSignature' : 'rejectReason'),
    },
    rejectReason: {
      component: (props) => {
        const heading = `As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I reject ${
          props.itemCustomIdentifier
        }.`;
        return <RejectReason heading={heading} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
    approveSignature: {
      component: (props) => {
        const heading = 'Approval';
        const dialog = buildReleaseMessage(props);
        return <CertifiedSignature dialog={dialog} heading={heading} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ approveOrReject, rejectReason, approveSignature }) => {
    if (approveOrReject) {
      return {
        toStatus: CourseWorkflowStatuses.ApprovedDraft.id,
        ...approveSignature,
      };
    } else {
      return {
        toStatus: CourseWorkflowStatuses.Rejected.id,
        reasonForRejection: rejectReason.reason,
        ...rejectReason.signature,
      };
    }
  },
};
