import React, { useState } from 'react';

import { ClickAwayListener, Modal, MenuList, Paper, Popper, withStyles } from '@material-ui/core';

import { EllipsesMenuButton, PDFExportDialog, PrintViewMenuItem } from 'components';

const styles = (theme) => ({
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  pdfModal: {
    zIndex: theme.zIndex.tooltip + 1,
  },
  menuContainer: {
    width: 248,
    padding: 0,
  },
});

const PrintViewMenu = (props) => {
  const [showPDFExport, setShowPDFExport] = useState(false);
  const [showEllipsesMenu, setShowEllipsesMenu] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);

  const handlePrintViewClickAway = (event) => {
    const menu = document.getElementById('print-view-menu');

    if (menu && menu.contains(event.target)) return;

    setShowEllipsesMenu(false);
  };

  let buttonRef;

  let visibility = hideMenu ? 'hidden' : 'visible';
  return (
    <>
      <ClickAwayListener onClickAway={handlePrintViewClickAway}>
        <>
          <EllipsesMenuButton
            buttonRef={(e) => (buttonRef = e)}
            onClick={() => {
              if (hideMenu) {
                setHideMenu(false);
              } else {
                setShowEllipsesMenu((state) => !state);
              }
            }}
          />

          <Popper
            id="print-view-menu"
            anchorEl={() => buttonRef}
            className={props.classes.popper}
            open={showEllipsesMenu}
            placement="bottom-end"
            disablePortal
          >
            <div style={{ visibility, boxShadow: '0px 2px 20px 1px rgba(182, 182, 182, 0.25)' }}>
              <Paper>
                <MenuList className={props.classes.menuContainer}>
                  <PrintViewMenuItem
                    defaultGenerateOption={{
                      text: 'Generate PDF',
                      handler: () => {
                        setShowPDFExport(true);
                        setHideMenu(true);
                      },
                    }}
                    customGenerateOptions={[]}
                  />
                </MenuList>
              </Paper>
            </div>
          </Popper>
        </>
      </ClickAwayListener>

      <Modal className={props.classes.pdfModal} open={showPDFExport}>
        <PDFExportDialog
          heading={props.pdfExportHeading}
          subheading={props.pdfExportSubheading}
          onClose={() => setShowPDFExport(false)}
          versionId={props.versionId}
          resetExportOptionsValues={() => {}}
        />
      </Modal>
    </>
  );
};

export default withStyles(styles)(PrintViewMenu);
