import React from 'react';
import { Trans } from '@lingui/macro';

import { ChangeOrderWorkflowStatuses } from 'workflows/statuses';
import YesOrNo from 'components/YesOrNo';

import { RejectReason } from '../views';

import { Button } from '@material-ui/core';

function NotReadyDialog(props) {
  return (
    <>
      <div>{props.children}</div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button color="primary" variant="contained" onClick={props.onConfirm}>
          <Trans>OK</Trans>
        </Button>
      </div>
    </>
  );
}

function HasDependenciesDialog(props) {
  return (
    <NotReadyDialog onConfirm={props.onConfirm}>
      <p>
        <Trans>
          In order to Cancel this Change Order, this Change Order must remain in Draft or Under Review status and all
          items in the Linked and Impacted Items sections must be removed. If any signatures have been applied to
          Impacted Items, the items must be reverted to Draft status by Voiding the signature or Rejecting the item from
          their item page to remove them from this Change Order. The item can then be linked to another CO, if
          applicable, and re-approved by owner/approver.
        </Trans>
      </p>
    </NotReadyDialog>
  );
}

function WrongStatusDialog(props) {
  return (
    <NotReadyDialog onConfirm={props.onConfirm}>
      <p>
        <Trans>
          In order to Cancel this Change Order, this Change Order must be in Draft or Under Review status and all items
          in the Linked and Impacted Items sections must be removed. If any signatures have been applied to Impacted
          Items, the items must be reverted to Draft status by Voiding the signature or Rejecting the item from their
          item page to remove them from this Change Order. The item can then be linked to another CO, if applicable, and
          re-approved by owner/approver.
        </Trans>
      </p>
    </NotReadyDialog>
  );
}

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => {
        const version = props.currentVersion;

        if (['rejected', 'ready_for_closure'].includes(version.currentStatusName))
          return <WrongStatusDialog onConfirm={props.handleClose} />;

        if (version.supportingItems.length > 0 || version.item.impactedItems.length > 0) {
          return <HasDependenciesDialog onConfirm={props.handleClose} />;
        }

        const prompt = <Trans>Are you sure you want to Cancel this Change Order? This action cannot be undone.</Trans>;
        return <YesOrNo heading={'Cancel'} prompt={prompt} closeOnNo yesLabel="Ok" noLabel="No" {...props} />;
      },
      getNextStep: (cancel) => (cancel ? 'cancelReason' : undefined),
    },
    cancelReason: {
      component: (props) => {
        const heading = `As the ${
          props.currentVersion.assignedOwnerJobRole.formattedRoleName
        }, I confirm I want to cancel ${props.itemCustomIdentifier}.`;
        return <RejectReason heading={heading} title={'Cancel'} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: (props) => {
    return !props.confirmTransition
      ? null
      : {
          ...props.cancelReason.signature,
          reasonForTransition: props.cancelReason.reason,
          toStatus: ChangeOrderWorkflowStatuses.Cancel.id,
        };
  },
};
