import React from 'react';

import CoreInput from 'components/core/form/Input';
import { Form } from '../useForm';
import { useStyles } from './styles';

interface Props {
  attr: string
  placeholder?: string
  form: Form<any> // can be brought from context if needed too
  toText?: boolean
}

export function Input<T extends Props>(props: T) {
  let { attr, placeholder, form, toText, ...rest } = props

  const classes = useStyles();

  const disabled = !form.canEdit
  const value = form.editedData[attr]
  const update = e => form.edit(attr, e.target.value)

  const passClasses = toText ? {
    root: classes.textLook
  } : null
  if (toText) placeholder = undefined
  if (passClasses) rest = { ...rest, classes: passClasses }

  // if done older autosaving-way - debounce={true}
  return <CoreInput
    defaultValue={value}
    disabled={disabled}
    onChange={update}
    placeholder={placeholder}
    {...rest}
  />
}
