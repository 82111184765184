import React from 'react';
import flowRight from 'lodash/flowRight';
import { Trans } from '@lingui/macro';
import LinearScale from '@material-ui/icons/LinearScale';

import Close from '@material-ui/icons/Close';
import { Modal, Paper, Typography, withStyles } from '@material-ui/core';

import WorkflowIcon from 'components/WorkflowIcon';
import WorkflowMenu, { CancelMenuItem, RetireMenuItem } from 'components/WorkflowMenu';
import { ContentSection } from 'components';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import withMetadataFromServer from 'compositions/WithMetadataFromServer';
import { RiskNoteWorkflowStatuses, indexById } from 'workflows/statuses';

import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';
import { SummaryOfChange } from 'compositions/TimelineGroup/steps/views';
import ConnectBar from 'compositions/TimelineGroup/components/ConnectBar';
import PreviousReleases from 'compositions/TimelineGroup/components/PreviousReleases';
import PermissionFailModal from 'compositions/TimelineGroup/components/PermissionFailModal';
import { AlertDialog, AlertDialogWithUser } from 'components';
import styles from 'compositions/TimelineGroup/components/sharedStyles';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

import rfcContent from './rfcContent';

const { NotCreated, Draft, Rejected, Retired } = RiskNoteWorkflowStatuses;

const RiskNoteWorkflowStatusesById = indexById(RiskNoteWorkflowStatuses);

class RiskNoteTimeline extends React.Component {
  state = {
    previousVersionModal: false,
    permissionFailModal: false,
    deniedModal: {
      open: false,
      heading: null,
      body: null,
    },
    deniedModalWithUser: {
      open: false,
      user: null,
      role: null,
    },
    releaseNotPermittedModal: false,
    itemTransitioningLockedModal: false,
    previousVersion: null,
    justificationOfNoChangeOrder: null,
  };

  constructor(props) {
    super(props);
    this.permissions = props.permissions;
  }

  openModal = (modalName) => {
    this.setState({ [modalName]: true });
  };

  closeModal = () => {
    this.setState({
      permissionFailModal: false,
      deniedModal: {
        open: false,
        heading: null,
        body: null,
      },
      releaseNotPermittedModal: false,
      itemTransitioningLockedModal: false,
      previousVersionModal: false,
    });
  };

  handleSmallBlueCircleClick = () => {
    const status = this.props.currentVersion.currentStatusName;
    const currentWorkflowStatus = RiskNoteWorkflowStatusesById[status];

    if (currentWorkflowStatus.isEqualTo(NotCreated)) {
      return this.props.handleInitTransition('createNewVersion');
    } else return this.props.handleInitTransition('summaryOfChange');
  };

  handleNullifyRenderTransitionDeniedModal = (desiredStateName) => {
    this.setState({
      deniedModal: {
        open: true,
        heading: 'Pending Trace Matrix',
        body: `Remove this version from any traces on the pending Trace Matrix before transitioning to ${desiredStateName}`,
      },
    });
  };

  handleReleaseRenderTransitionDeniedModal = () => {
    this.setState({
      deniedModal: {
        open: true,
        heading: 'Pending Trace Matrix',
        body:
          'Remove the previous version from any traces on the pending Trace Matrix before releasing this new version',
      },
    });
  };

  handleInactiveUserDeniedModal = (inactiveUsers) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: inactiveUsers[0].user,
        role: inactiveUsers[0].role,
      },
    });
  };

  handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: usersWithoutActiveRole[0].user,
        role: usersWithoutActiveRole[0].role,
      },
    });
  };

  workflowMenu = () => {
    const { currentCompanyAllowsTransitioning, currentVersion } = this.props;
    const currentWorkflowStatus = RiskNoteWorkflowStatusesById[currentVersion.currentStatusName];

    if (!currentCompanyAllowsTransitioning) return;

    const isCancelable = (status) => status.isEqualTo(Draft);

    return (
      <WorkflowMenu>
        <CancelMenuItem
          disabled={!isCancelable(currentWorkflowStatus)}
          onClick={() => {
            if (!currentVersion.mayTransitionToCanceled) {
              return this.handleNullifyRenderTransitionDeniedModal('Canceled');
            }
            this.props.handleInitTransition('cancel');
          }}
        />
        <RetireMenuItem
          disabled={false}
          onClick={() => {
            if (!currentVersion.mayTransitionToRetired) {
              return this.handleNullifyRenderTransitionDeniedModal('Retired');
            }
            this.props.handleInitTransition('initiateRetirement');
          }}
        />
      </WorkflowMenu>
    );
  };

  render() {
    const {
      classes,
      currentCompanyAllowsTransitioning,
      currentRelease,
      currentVersion,
      previousVersions,
      handleInitTransition,
    } = this.props;

    const {
      previousVersionModal,
      permissionFailModal,
      releaseNotPermittedModal,
      itemTransitioningLockedModal,
    } = this.state;

    const {
      approver,
      assignedApproverJobRole,
      assignedOwnerJobRole,
      currentStatusName: status,
      owner,
      versionIdentifier,
    } = currentVersion;

    const currentWorkflowStatus = RiskNoteWorkflowStatusesById[status];
    if (!currentWorkflowStatus) throw new Error(`Invalid status for RiskNote Workflow: ${status}`);

    // const hasChangeOrder = Boolean(linkedChangeCustomIdentifier) && !Boolean(linkedChangeJustificationText);

    const inactiveUsers = getInactiveUsers(owner, approver);
    const usersWithoutActiveRole = getUsersWithoutActiveRole(
      owner,
      approver,
      assignedOwnerJobRole,
      assignedApproverJobRole,
    );

    return (
      <ContentSection
        scrollX
        LabelText={<Trans>Workflow</Trans>}
        Icon={<LinearScale />}
        Controls={this.workflowMenu()}
        classes={{ label: classes.timelineHeader }}
      >
        <div className={classes.timelineContainer}>
          <PreviousReleases
            generateSmallCircleContent={rfcContent}
            releasedVersions={this.props.previousVersions}
            onClick={(v) =>
              this.setState({
                previousVersionModal: true,
                versionForModal: v,
              })
            }
          />

          {!currentWorkflowStatus.isEqualTo(Retired) && (
            <SmallCircle
              content={rfcContent(currentVersion)}
              color="draft"
              handleClick={this.handleSmallBlueCircleClick}
              id={currentWorkflowStatus.isEqualTo(NotCreated) && 'create-new-rev'}
            />
          )}

          {currentWorkflowStatus.isAfter(NotCreated) && !currentWorkflowStatus.isEqualTo(Retired) && (
            <>
              <ConnectBar color="draft" short visible />

              <WorkflowIcon
                type="draft"
                active={currentWorkflowStatus.isEqualTo(Draft)}
                completed={currentWorkflowStatus.isAfter(Draft)}
                version={versionIdentifier}
                label="Draft"
                size="large"
                visible
              />

              <ConnectBar
                color="rejected"
                disabled={!currentCompanyAllowsTransitioning}
                visible={currentWorkflowStatus.isEqualTo(Rejected)}
              />

              <WorkflowIcon
                type="rejected"
                active
                version={versionIdentifier}
                label="Rejected"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');

                  return handleInitTransition('resolveRejection');
                }}
                visible={currentWorkflowStatus.isEqualTo(Rejected)}
              />

              <ConnectBar
                color="released"
                disabled={!currentCompanyAllowsTransitioning || !this.permissions.canApprove}
                visible={!currentWorkflowStatus.isEqualTo(Rejected)}
              />

              <WorkflowIcon
                type="released"
                version={versionIdentifier}
                label="Release"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');
                  if (inactiveUsers.length) return this.handleInactiveUserDeniedModal(inactiveUsers);
                  if (usersWithoutActiveRole.length)
                    return this.handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);

                  if (currentWorkflowStatus.isEqualTo(Draft)) {
                    if (!this.permissions.canApprove) {
                      return this.openModal('permissionFailModal');
                    } else if (currentRelease && !currentRelease.mayTransitionToObsolete) {
                      this.handleReleaseRenderTransitionDeniedModal();
                    } else {
                      return handleInitTransition('releaseOrReject');
                    }
                  }
                }}
                disabled={!this.permissions.canApprove}
                visible={!currentWorkflowStatus.isEqualTo(Rejected)}
              />
            </>
          )}

          {previousVersions && (
            <Modal open={previousVersionModal} onClose={this.closeModal} className={classes.infoModal}>
              <Paper className={classes.modalInside}>
                <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={this.closeModal} color="action" />
                <SummaryOfChange currentVersion={this.state.versionForModal} displayChangeInfo={true} />
              </Paper>
            </Modal>
          )}

          <PermissionFailModal
            open={permissionFailModal || releaseNotPermittedModal || itemTransitioningLockedModal}
            failType={permissionFailModal ? 'permissions' : releaseNotPermittedModal ? 'release' : 'itemTransitioning'}
            closeModal={this.closeModal}
          />

          <AlertDialog
            open={this.state.deniedModal.open}
            onClose={() => this.closeModal()}
            titleText={this.state.deniedModal.heading}
          >
            <Typography variant="body2">{this.state.deniedModal.body}</Typography>
          </AlertDialog>

          <AlertDialogWithUser
            open={this.state.deniedModalWithUser.open}
            titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
            onClose={() => this.setState({ deniedModalWithUser: { open: false, user: null, role: null } })}
            subtitle={
              inactiveUsers.length
                ? 'The following user has been deactivated.'
                : "The following user's role has been removed."
            }
            user={this.state.deniedModalWithUser.user}
            role={this.state.deniedModalWithUser.role === 'owner' ? assignedOwnerJobRole : assignedApproverJobRole}
            body={`Please use the People Menu to reassign the ${
              this.state.deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
            } role to a new user.`}
          />
        </div>
      </ContentSection>
    );
  }
}

export default flowRight([withStyles(styles), withMetadataFromServer(), withCurrentCompany])(RiskNoteTimeline);
