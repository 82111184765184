import React, { useEffect, useRef, useState } from 'react';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { Trans } from '@lingui/macro';
import { useMutation } from 'react-apollo';
import clsx from 'clsx';

import { ContentSection, ConfirmationDialog } from 'components';
import ItemSearch from 'compositions/ItemSearch';
import VersionAndStatusDot from 'components/VersionAndStatusDot';

import { useStyles } from './styles';

import { ADD_ASSOCIATED_VVPLAN_TO_VVREPORT, REMOVE_ASSOCIATED_VVPLAN_TO_VVREPORT } from '../gql';
import { VerificationAndValidationReportVersionQuery_verificationAndValidationReportVersion_associatedVvPlan_version as VVPlan } from '../__generated__/VerificationAndValidationReportVersionQuery';
import { VerificationAndValidationReportVersionQuery_verificationAndValidationReportVersion } from '../__generated__/VerificationAndValidationReportVersionQuery';
import { defaultAssociatedVVPState } from '../PayloadFields';

interface AssociatedValidationPlanSelectProps {
  currentVersion: VerificationAndValidationReportVersionQuery_verificationAndValidationReportVersion;
  vvReportId: string;
  vvPlan: VVPlan | null;
  associatedVVP: any;
  setAssociatedVVP: any;
  getVVPData: any;
}

const AssociatedValidationPlanSelect = (props: AssociatedValidationPlanSelectProps) => {
  const { currentVersion, vvReportId, vvPlan, associatedVVP, setAssociatedVVP, getVVPData } = props;

  const classes = useStyles();

  const { locked } = currentVersion;

  const [hasErrors, setErrors] = useState<boolean>(false);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const [addAssociatedVVPlanToVVReport, { error: addError }] = useMutation(ADD_ASSOCIATED_VVPLAN_TO_VVREPORT, {
    refetchQueries: ['timelineQuery', 'BuilderViewQuery', 'VerificationAndValidationReportVersionQuery'],
  });
  const [removeAssociatedVVPlanToVVReport] = useMutation(REMOVE_ASSOCIATED_VVPLAN_TO_VVREPORT, {
    refetchQueries: ['VerificationAndValidationReportVersionQuery'],
  });

  const addButtonRef = useRef<HTMLButtonElement>(null);

  const addErrorText = addError ? addError.graphQLErrors.map((e) => e.message).join('\n') : undefined;
  const searchItemTypes = ['verification_and_validation_plan'];

  const handleAddAssociatedPlan = () => {
    setErrors(false);
    setSearchModalOpen(true);
  };

  const handleRemoveAssociatedPlan = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleCloseSearchModal = () => {
    setSearchModalOpen(false);
  };

  const handleConfirmDialog = () => {
    removeAssociatedVVPlanToVVReport({
      variables: {
        parentVersionId: vvReportId,
      },
    }).catch(() => {
      setErrors(true);
      return false;
    });
    setAssociatedVVP(defaultAssociatedVVPState);
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleSearchSubmit = (selectedRecords) => {
    const vvPlanId = selectedRecords[0];
    return addAssociatedVVPlanToVVReport({
      variables: {
        linkedItemId: vvPlanId,
        parentVersionId: vvReportId,
      },
    })
      .then(() => {
        getVVPData({ variables: { id: vvPlanId } });
        return true;
      })
      .catch(() => {
        setErrors(true);
        return false;
      });
  };

  useEffect(() => {
    if (vvPlan) {
      getVVPData({ variables: { id: vvPlan } });
    }
  }, [getVVPData, vvPlan]);

  return (
    <ContentSection
      LabelText={
        <>
          <Sort />
          <Trans>Associated Validation Plan</Trans>
        </>
      }
    >
      <TextField
        disabled={locked}
        variant="outlined"
        size="small"
        placeholder="Select Associated VVP"
        value={associatedVVP.name || ''}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment className={classes.endAdornment} position={'end'} disablePointerEvents={locked}>
              {associatedVVP.id ? (
                <>
                  <VersionAndStatusDot version={associatedVVP.version} status={associatedVVP.status} style={{marginRight: '8px'}}/>
                  {!locked && <IconButton onClick={handleRemoveAssociatedPlan} ref={addButtonRef} className={clsx(locked && classes.lockedButton)}>
                    <CancelIcon/>
                  </IconButton>}
                </>
              ) : (
                <IconButton onClick={handleAddAssociatedPlan} ref={addButtonRef} className={clsx(locked && classes.lockedButton)}>
                  <AddCircleIcon/>
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        className={classes.textField}
      />
      <ItemSearch
        errorText={hasErrors ? addErrorText : undefined}
        open={searchModalOpen}
        anchorEl={addButtonRef.current}
        closeSelf={handleCloseSearchModal}
        onSubmit={handleSearchSubmit}
        itemTypes={searchItemTypes}
        defaultSearchTerm=""
        draftOnly={false}
        excludeVoidAndCancel
        supportsChangeOrder={true}
        usesCurrentVersion={true}
        enableVersionSupport={true}
        multiple={false}
      />
      {isConfirmDialogOpen && (
        <ConfirmationDialog
          onCancel={handleConfirmDialogClose}
          title="Confirm Change"
          open={isConfirmDialogOpen}
          onConfirm={handleConfirmDialog}
          onClose={handleConfirmDialogClose}
        >
          By removing the Validation Plan and version you will lose all related data on Tabs 2 and 3.
        </ConfirmationDialog>
      )}
    </ContentSection>
  );
};

export { AssociatedValidationPlanSelect };
