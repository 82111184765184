import React from 'react';
import { Typography } from '@material-ui/core';

export default [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.supplierVersion.item.customIdentifier',
    headerName: 'Associated Supplier',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (row.data === undefined) return null;
      const supplierVersion = row.data.currentVersion.supplierVersion;
      return supplierVersion === null ? '-' : `${supplierVersion.item.customIdentifier}:${supplierVersion.title}`;
    },
    render: (row) => {
      if (row.value === undefined) return null;
      return (
        <Typography variant={'body2'} style={{ width: 100 }}>
          {row.value}
        </Typography>
      );
    },
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];
