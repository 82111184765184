/**
 * Builds an string of CSS classnames based on conditions.
 * This utility will help prevent ternaries that return empty
 * string values in the classname prop.
 *
 * @param {Array.<Array>} conditions An array of 2 element arrays (tuples).
 *        The first element in each child array must be true or false.
 *        The second element in each child array is a CSS class name.
 * @returns {string} a CSS class name string
 */
function buildConditionalClasses(conditions) {
  return conditions
    .reduce((acc, [condition, className]) => {
      if (condition) {
        return acc.concat(className);
      }
      return acc;
    }, [])
    .join(' ');
}

export default buildConditionalClasses;
