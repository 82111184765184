import React from 'react';

import BaseDialog from 'components/BaseDialog';
import { VersionAndStatusDot } from 'components';

import { useStyles } from './styles';
import QuotedSection from 'components/core/QuotedSection';
import { Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import linkToItem from 'utils/linkToItem';

interface Props {
    onClose: () => void;
    trainingPlans: any[];
    courseId: string;
}

function ActiveTrainingPlansDialog(props: Props) {
    const classes = useStyles();
    return (
        <BaseDialog open title="Not Allowed" onClose={props.onClose} size="large">
            <Typography component="p" variant="h5" align="left">
                {props.courseId} is part of the training plans for the following employees. Please release a new version
                of their training plan that does not include this course before retiring.
            </Typography>
            <QuotedSection className={classes.container}>
                <ul className={classes.list}>
                    {props.trainingPlans.map((trainingPlan) => (
                        <Link to={linkToItem('training-plans', trainingPlan.item.id)}>
                            <li className={classes.listEntry}>
                                <Typography variant="h6" component="p" className={classes.trainingPlanText}>
                                    {trainingPlan.item.customIdentifier}: {trainingPlan.title}
                                </Typography>
                                <VersionAndStatusDot
                                    version={trainingPlan.versionIdentifier}
                                    status={trainingPlan.currentStatus.name}
                                />
                            </li>
                        </Link>
                    ))}
                </ul>
            </QuotedSection>
        </BaseDialog>
    );
}

export default ActiveTrainingPlansDialog;
