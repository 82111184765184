import React from 'react';

import { Typography, withStyles } from '@material-ui/core';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { isOnlyReadTrace } from '../ReadOnlyTracesDictionary';
import ActionBar from './ActionBar';

const styles = (theme) => ({
  buttonToolbar: {
    display: 'flex',
    flexDirection: 'row-reverse',
    backgroundColor: theme.palette.background.default,
  },
  rowHeader: {
    backgroundColor: '#dfdfdf',
    flex: 1,
    textAlign: 'center',
    padding: theme.spacing(0.5),
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  navigateIcon: {
    '&:hover': { cursor: 'pointer' },
  },
  headerContent: {
    ...theme.mainLayoutGutters,
    width: '100%',
  },
  columnHeadings: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#dfdfdf',
    alignItems: 'center',
  },
});

const RowHeader = withStyles(styles)(function({ classes, children }) {
  return (
    <Typography className={classes.rowHeader} component="div" variant="body2">
      {children}
    </Typography>
  );
});

class Header extends React.Component {
  render() {
    const { currentPosition, classes } = this.props;

    let upstreamName, downstreamName;
    if (currentPosition && currentPosition.selection && currentPosition.selection.trace) {
      upstreamName = currentPosition.selection.trace.upstreamVersion.item.itemType.name;
      downstreamName = currentPosition.selection.trace.downstreamVersion.item.itemType.name;
    }

    const isReadOnlyTrace = isOnlyReadTrace(upstreamName, downstreamName);
    const tooltipText = isReadOnlyTrace
      ? 'Add/Remove trace is not available from the Trace Matrix. Edit the Risk item directly to make these changes'
      : 'Remove Trace';
    return (
      <div className={classes.headerContent}>
        <ActionBar
          isReadOnlyTrace={isReadOnlyTrace}
          onDeleteTraceClick={this.props.onDeleteTraceClick}
          tooltipText={tooltipText}
        />
        <div className={classes.columnHeadings}>
          <ChevronLeft className={classes.navigateIcon} onClick={this.props.onNavigateUpstreamClick} />
          {[...Array(currentPosition.numUpstream).keys()].map((i) => (
            <RowHeader key={`u-${i}`}>
              Upstream {currentPosition.numUpstream - i + (currentPosition.trueUpstream - currentPosition.numUpstream)}
            </RowHeader>
          ))}

          {this.props.root && <RowHeader key="root">{this.props.root}</RowHeader>}

          {[...Array(currentPosition.numDownstream).keys()].map((i) => (
            <RowHeader key={`d-${i}`}>
              Downstream {i + 1 + (currentPosition.trueDownstream - currentPosition.numDownstream)}
            </RowHeader>
          ))}
          <ChevronRight className={classes.navigateIcon} onClick={this.props.onNavigateDownstreamClick} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Header);
