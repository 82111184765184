import React from 'react';

import { Trans } from '@lingui/macro';
import clsx from 'clsx';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Info from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';

import { useStyles } from './styles';

interface SystemValidatedProps {
  currentCompanyValidatedSystem: boolean;
  simpleView?: boolean;
}

const SystemValidated = (props: SystemValidatedProps) => {
  const classes = useStyles();
  const { currentCompanyValidatedSystem, simpleView = false } = props;

  if (currentCompanyValidatedSystem) {
    return null;
  }

  const Icon = simpleView ? Info : InfoOutlinedIcon;
  const chipClass = clsx(classes.chip, simpleView && classes.chipSimple);

  return (
    <Grid container item xs={12} justify="center">
      <Chip
        className={chipClass}
        data-testid="system-not-validated-chip"
        clickable={false}
        label={
          !simpleView ? (
            <Typography variant="body2">
              <Trans>System not validated</Trans>
            </Typography>
          ) : (
            ''
          )
        }
        deleteIcon={
          <Tooltip
            placement={'right'}
            title={
              'Your company’s software instance has not yet been validated. Any documentation created in it does not currently comply with current federal and international regulations. Please contact Nemedio to upgrade your license, and validate your system to ensure your software is fully compliant.'
            }
          >
            <div className={classes.chipDeleteIconContainer}>
              <Icon
                className={simpleView ? classes.chipDeleteIconSimple : classes.chipDeleteIcon}
                color="inherit"
                fontSize="small"
              />
            </div>
          </Tooltip>
        }
        // the chip uses the presence of "onDelete" to show/hide the deleteIcon
        onDelete={() => {}}
      />
    </Grid>
  );
};

export default SystemValidated;
