import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import LibraryBook from '@material-ui/icons/LibraryBooks';

import slugs from '../../displaySlugs';

import { listFragment, docMetadata } from '../mixins/Document';
const itemType = 'qualityPlan';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Quality Plan`,
  categoryLabel: t`Quality Plans`,
  icon: LibraryBook,
  listViewQuery: gql`
    query qualityPlansQuery {
      documents(itemType: "quality_plan") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
