import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import TagsInput from 'react-tagsinput';
import { withApollo } from 'react-apollo';

import { Paper, withStyles, Input, Typography } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

import 'react-tagsinput/react-tagsinput.css';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';

import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import { navigate } from '@reach/router';

import { CATEGORY_INDEX } from 'categories';
import { ItemTypeIndex } from 'itemTypes';

import RecordsAsCategoryTree from 'components/RecordsAsCategoryTree';
import styles from './styles';
import { tagSearch, tagsQuery } from 'utils/gql/tagQueries';

class TagSearchTab extends Component {
  state = {
    searchResults: null,
    suggestions: [],
    tags: [],
  };

  autosuggest = (value) => {
    this.props.client
      .query({
        query: tagsQuery,
        variables: { search: value },
        fetchPolicy: 'network-only',
      })
      .then((response) => get(response, 'data.tags', []).map((tag) => tag.name))
      .then((suggestions) => this.setState({ suggestions }));
  };

  renderAutosuggestInput = ({ addTag, ...props }) => {
    const { classes } = this.props;

    const autoSuggestTheme = {
      container: classes.suggestionsContainer,
      suggestionsContainerOpen: classes.suggestionsContainerOpen,
      suggestionsList: classes.suggestionsList,
      suggestion: classes.suggestion,
      suggestionHighlighted: classes.suggestionHighlighted,
    };

    const handleOnChange = (e, { method }) => {
      if (method === 'enter' || method === 'click') {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    return (
      <Autosuggest
        ref={this.props.ref}
        suggestions={this.state.suggestions}
        shouldRenderSuggestions={(value) => value && value.trim().length > 0}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={(suggestion) => suggestion}
        highlightFirstSuggestion={true}
        // it would be nice to leave this on but causes a known bug
        // https://github.com/moroshko/react-autosuggest/issues/613
        focusInputOnSuggestionClick={false}
        inputProps={{
          ...props,
          onChange: handleOnChange,
          className: classes.input,
        }}
        onSuggestionSelected={(_event, { suggestion }) => addTag(suggestion)}
        onSuggestionsFetchRequested={({ value }) => this.autosuggest(value)}
        onSuggestionsClearRequested={() => this.setState({ suggestions: [] })}
        theme={autoSuggestTheme}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
        renderInputComponent={(inputWrapperProps) => (
          <Input
            {...inputWrapperProps}
            className={classes.searchInput}
            id="item-search-input"
            autoComplete="off"
            fullWidth
            inputProps={{
              'aria-label': i18n._(t`Search by tag`),
            }}
            classes={{ underline: classes.inputUnderline }}
            placeholder={i18n._(t`Search by tag`)}
            type="search"
          />
        )}
      />
    );
  };

  renderTag = (props) => {
    const {
      tag,
      key,
      disabled,
      onRemove,
      removeIconClassName,
      getTagDisplayValue,
      ...other
    } = props;

    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        {!disabled && (
          <Clear
            className={removeIconClassName}
            onClick={() => onRemove(key)}
          />
        )}
      </span>
    );
  };

  renderLayout = (tagComponents, inputComponent) => {
    return (
      <>
        {inputComponent}
        {tagComponents}
      </>
    );
  };

  handleTagsInputChange = (tags) => {
    this.setState({ tags });
    this.props.client
      .query({
        query: tagSearch,
        variables: { tags },
        fetchPolicy: 'network-only',
      })
      .then((response) => get(response, 'data.tagSearch', []))
      .then((searchResults) => this.setState({ searchResults }));
  };

  render() {
    const { classes, closeSelf } = this.props;

    const noResults =
      get(this.state, 'searchResults', []) &&
      (this.state.searchResults.length === 0 && (
        <Typography variant="body2" className={this.props.classes.noResults}>
          <Trans>No results found.</Trans>
        </Typography>
      ));

    const resultMap = {};
    const searchResults = get(this.state, 'searchResults', []);
    searchResults &&
      searchResults.forEach((r) => {
        resultMap[r.itemType] = get(r, 'results', []);
      });

    const whitelist = {};
    Object.keys(resultMap) &&
      Object.keys(resultMap).forEach((t) => {
        const displaySlug = get(ItemTypeIndex, [camelCase(t), 'displaySlug']);

        whitelist[displaySlug] = true;

        const parentSlug = get(CATEGORY_INDEX, [displaySlug, 'parentSlug']);
        if (parentSlug) whitelist[parentSlug] = true;
      });

    return (
      <div>
        <TagsInput
          disable
          value={this.state.tags}
          onChange={this.handleTagsInputChange}
          renderInput={this.renderAutosuggestInput}
          renderTag={this.renderTag}
          tagProps={{
            className: this.props.classes.tag,
            removeIconClassName: this.props.classes.removeIcon,
          }}
          inputProps={{
            placeholder: i18n._(t`Add Tag`),
          }}
          className={''} // clear default styling
          focusedClassName={null}
          renderLayout={this.renderLayout}
        />
        <div className={classes.results}>
          {noResults}
          <RecordsAsCategoryTree
            allowNavigation={false}
            categoryRecordsMap={resultMap}
            categoryWhitelist={whitelist}
            style={{ paddingLeft: 40 }}
            onRecordClick={(record, cat) => {
              const itemLink = `/category/${cat.slug}${
                record.userCreatable ? `/${record.id}` : ''
              }/?view=CardBuilder`;

              navigate(itemLink) && closeSelf();
            }}
          />
        </div>
      </div>
    );
  }
}
export default flowRight([withI18n(), withStyles(styles), withApollo])(
  TagSearchTab,
);
