import React from 'react';

import CreateSupplierVersion from 'compositions/TimelineGroup/steps/views/CreateSupplierVersion';

export default {
  initialStep: 'newVersion',
  internalSteps: {
    newVersion: {
      component: (props) => {
        return <CreateSupplierVersion {...props} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: () => ({
    gqlMethod: 'createVersion',
  }),
};
