import React, { useState } from 'react';

import { CommentsControl, TracingControl, TagsControl } from 'components/LongCardControls/BottomRowControls';
import UnlinkControl from './UnlinkControl';

interface OtherProps {
  itemId: string;
  versionId: string;
  commentCount: number;
  itemTags: string[] | null;
  className?: string;
  riskIdent: string;
  riskIdentVersion: string;
  unlinkRisk: () => Promise<any>;
  locked: boolean;
}

export default function OtherControls({
  itemId,
  versionId,
  commentCount,
  itemTags,
  className,
  riskIdent,
  riskIdentVersion,
  unlinkRisk,
  locked,
}: OtherProps) {
  const parentSetItemSlug = 'risks';

  const queryToRefetch = 'RiskAnalysisVersionQuery'; // may be looked into updating only one row

  const [showComments, setShowComments] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showTracing, setShowTracing] = useState(false);

  return (
    <div className={className}>
      <div className="OtherControls-popup" style={{ visibility: 'visible' }}>
        <TracingControl
          onClick={() => setShowTracing(!showTracing)}
          onClose={() => setShowTracing(false)}
          versionId={versionId}
          itemId={itemId}
          showPopup={showTracing}
          parentSetItemSlug={parentSetItemSlug}
          disabled={locked}
        />

        <TagsControl
          onClick={() => setShowTags(!showTags)}
          onClose={() => setShowTags(false)}
          itemId={itemId}
          tags={itemTags}
          showPopup={showTags}
          queryToRefetch={queryToRefetch}
          disabled={locked}
        />

        <CommentsControl
          onClick={() => setShowComments(!showComments)}
          onClose={() => setShowComments(false)}
          showPopup={showComments}
          itemId={itemId}
          commentCount={commentCount}
          queryToRefetch={queryToRefetch}
          disabled={locked}
        />

        <UnlinkControl
          riskIdent={riskIdent}
          riskIdentVersion={riskIdentVersion}
          unlinkRisk={unlinkRisk}
          disabled={locked}
        />
      </div>
    </div>
  );
}
