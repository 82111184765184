import React from 'react';

import camelCase from 'lodash/camelCase';
import get from 'lodash/get';

import FilterList from '@material-ui/icons/FilterList';

import { ItemTypeIndex } from 'itemTypes';

function Item(props) {
  const Icon = get(ItemTypeIndex, [camelCase(props.type), 'icon'], FilterList);
  return <Icon fontSize={props.fontSize} style={props.style} />;
}

export default Item;
