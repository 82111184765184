import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
    rpcSection: {
      display: 'inline-block',
      marginRight: '65px',
      marginTop: '25px',
    },
    toggleContainer: {
      marginBottom: '22px',
    },
    inlineToggle: {
      display: 'inline',
      '& > span': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
  }),
);
