import React, { FC } from 'react';

import { Box, Button, SvgIconTypeMap, Typography } from '@material-ui/core';
import { Edit, Save } from '@material-ui/icons';

import { useStyles } from './styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

interface Props {
  isActive: boolean;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  title: string;
  onClick: () => void;
  onActionButtonClick?: () => void;
}

const SectionTitle: FC<Props> = ({ isActive, icon: Icon, title, onClick, onActionButtonClick }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Icon className={classes.sectionTitleIcon} />
      <Typography variant="h3" className={classes.sectionTitle}>
        {title}
      </Typography>
      {isActive ? (
        <Button variant="contained" color="primary" startIcon={<Save />} onClick={onActionButtonClick}>
          Save
        </Button>
      ) : (
        <Button variant="outlined" color="primary" startIcon={<Edit />} onClick={onClick}>
          Update
        </Button>
      )}
    </Box>
  );
};

export default SectionTitle;
