import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';

import { Typography } from '@material-ui/core';
import Error from '@material-ui/icons/Error';

import { ChangeOrderWorkflowStatuses } from 'workflows/statuses';
import { ChangeOrderError } from 'workflows/releaseErrors';

import { CertifiedSignature, YesOrNo } from '../views';
import { useStyles } from './styles';
import { PREPARE_CHANGE_ORDER_VERSION_FOR_TRAINING } from './gql';

export default {
  initialStep: 'checkAllowedness',
  internalSteps: {
    checkAllowedness: {
      component: NotAllowedModal,
      getNextStep: () => 'confirmChanges',
    },
    confirmChanges: {
      component: ConfirmChangesModal,
      getNextStep: () => 'changeOrderReadyToTrainingSignature',
    },
    changeOrderReadyToTrainingSignature: {
      component: ChangeOrderReadyToTrainingSignature,
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult,
};

function getFinalResult({ changeOrderReadyToTrainingSignature }) {
  return {
    ...changeOrderReadyToTrainingSignature,
    toStatus: ChangeOrderWorkflowStatuses.ReadyForTraining.id,
  };
}

function ChangeOrderReadyToTrainingSignature(props) {
  const heading = 'CO Ready To Training E-Signature';
  const dialog = `As the ${
    props.currentVersion.assignedApproverJobRole.formattedRoleName
  }, I approve the transition of ${props.itemCustomIdentifier}.`;

  return (
    <CertifiedSignature
      {...props}
      dialog={dialog}
      heading={heading}
      errorDisplay={props.error ? ChangeOrderError : undefined}
    />
  );
}

function NotAllowedModal(props) {
  const classes = useStyles();
  const version = props.currentVersion;

  useEffect(() => {
    if (version.mayTransitionToTraining) {
      props.handleComplete(true);
    }
  }, [props, version, props.handleComplete, version.mayTransitionToTraining]);

  if (version.mayTransitionToTraining) {
    return (
      <>
        <div className={classes.heading}>
          <Typography variant="h3">Processing...</Typography>
        </div>
      </>
    );
  }

  const course_versions = [] as any;
  version.mayNotTransitionToTrainingBecause.forEach((err) => {
    err.data.forEach(({ key, value }) => {
      if (key === 'course_version') course_versions.push(JSON.parse(value));
    });
  });

  return (
    <>
      <div className={classes.heading}>
        <Error className={classes.icon} color="primary" />
        <Typography variant="h3">Not Allowed</Typography>
      </div>
      <div className={classes.body}>
        {version.mayNotTransitionToTrainingBecause.map((issue, i) => (
          <p key={i} className={classes.message}>
            {issue.message}
          </p>
        ))}
        {course_versions.length === 0 ? null : (
          <ul>
            {course_versions.map((x, i) => (
              <li key={i}>
                {x.item.custom_identifier} | {x.title} | {x.current_status.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

function ConfirmChangesModal(props) {
  const [prepareChangeOrderVersionForTraining] = useMutation(PREPARE_CHANGE_ORDER_VERSION_FOR_TRAINING);

  const [data, setData] = useState({ loading: true, willCreate: [] as any[], willNullify: [] as any[] });
  const [error, setError] = useState(null as any);

  const id = props.currentVersion.id;
  const classes = useStyles();

  useEffect(() => {
    prepareChangeOrderVersionForTraining({
      variables: {
        id: id,
      },
    })
      .then((x) => {
        setData({ ...x.data.prepareChangeOrderVersionForTraining, loading: false });
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [prepareChangeOrderVersionForTraining, id]);

  return (
    <YesOrNo
      disableOnSubmit
      heading="Confirm Action"
      noLabel="Cancel"
      yesLabel="Confirm"
      prompt={
        <>
          {data.loading && !error ? <span>loading...</span> : null}
          Changing this status will result in the following Training Records being created:
          <ul className={classes.trainingRecordList}>
            {data.willCreate.map((x) => (
              <li className={classes.bold}>
                TRN-R-TBD, {x.user.fullName}, {x.trainingItemVersion.item.customIdentifier}
              </li>
            ))}
          </ul>
          {data.willNullify.length > 0 && (
            <>
              <div style={{ height: 10, visibility: 'hidden' }}>-</div>
              And the following Training Records being nullified:
              <ul className={classes.trainingRecordList}>
                {data.willNullify.map((x) => (
                  <li className={classes.bold}>
                    {x.item.customIdentifier}, {x.title}
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      }
      {...props}
      error={error}
      closeOnNo
      handleComplete={() => props.handleComplete()}
    />
  );
}
