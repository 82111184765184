import React from 'react';

import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';

import { Trans } from '@lingui/macro';
import { DateTime } from 'luxon';
import { useMutation } from 'react-apollo';

import { ContentSection, DirectDocumentUploader, Spacer } from 'components';
import UploadFileIcon from 'assets/icons/FileUpload';
import fileNameFromURL from 'utils/fileNameFromURL';

import { DESTROY_REFERENCED_DOCUMENT, CREATE_REFERENCED_DOCUMENT } from '../AdditionalInformation/gql';
import {
  VerificationRecordVersionQuery,
  VerificationRecordVersionQuery_verificationRecordVersion,
} from '../__generated__/VerificationRecordVersionQuery';
import { ApolloQueryResult } from 'apollo-client';

interface RecordAttachmentSectionProps {
  currentVersion: VerificationRecordVersionQuery_verificationRecordVersion;
  layoutClasses: { [className: string]: string };
  refetch: () => Promise<ApolloQueryResult<VerificationRecordVersionQuery>>;
}

const RecordAttachmentSection = (props: RecordAttachmentSectionProps) => {
  const {
    currentVersion: { id, locked, executedTestProtocolAttachment, unexecutedTestProtocolAttachment },
    layoutClasses,
    refetch,
  } = props;

  const [createAttachment] = useMutation(CREATE_REFERENCED_DOCUMENT);
  const [destroyAttachment] = useMutation(DESTROY_REFERENCED_DOCUMENT);

  const deleteAttachment = async (attachmentId: string) => {
    await destroyAttachment({ variables: { id: attachmentId } });
  };

  const createExecutedAttachment = async (attachmentUrl: string) => {
    const payload = {
      variables: {
        attachmentURL: attachmentUrl,
        versionId: id,
        isExecutedTestProtocolAttachment: true,
      },
    };
    try {
      await createAttachment(payload);
    } catch (e) {
      throw new Error(`Error creating attachment (versionId: ${payload.variables.versionId}): ${e.message}`);
    }
  };

  return (
    <>
      <ContentSection
        LabelText={
          <>
            <DescriptionIcon className={layoutClasses.icon} />
            <Trans>Unexecuted Record Attachment</Trans>
          </>
        }
        fillHeight={false}
      >
        <Spacer factor={1} />
        <div>
          <DirectDocumentUploader
            attrName={'original_form'}
            attachmentId={unexecutedTestProtocolAttachment && unexecutedTestProtocolAttachment.id}
            filename={unexecutedTestProtocolAttachment && fileNameFromURL(unexecutedTestProtocolAttachment.url)}
            fileUrl={unexecutedTestProtocolAttachment && unexecutedTestProtocolAttachment.url}
            locked
            readOnly
            versionId={id}
          />
        </div>
      </ContentSection>
      <ContentSection
        LabelText={
          <>
            <UploadFileIcon className={layoutClasses.icon} />
            <Trans>Executed Record Attachment</Trans>
          </>
        }
        fillHeight={false}
      >
        <Spacer factor={1} />
        <div>
          <DirectDocumentUploader
            attrName={'attachment'}
            attachmentId={executedTestProtocolAttachment && executedTestProtocolAttachment.id}
            filename={executedTestProtocolAttachment && fileNameFromURL(executedTestProtocolAttachment.url)}
            fileUrl={executedTestProtocolAttachment && executedTestProtocolAttachment.url}
            locked={locked}
            createAttachment={createExecutedAttachment}
            deleteAttachment={deleteAttachment}
            onCompleted={refetch}
            versionId={id}
          />
        </div>
        <Spacer factor={2} />
        {executedTestProtocolAttachment && executedTestProtocolAttachment.id && (
          <>
            <Typography variant="body2" className={layoutClasses.boldHeaderText} style={{ textTransform: 'uppercase' }}>
              <Trans>Upload Information</Trans>{' '}
              <span className={layoutClasses.labelSecondaryText}>
                {DateTime.fromISO(executedTestProtocolAttachment.createdAt).toLocaleString(DateTime.DATETIME_FULL)}{' '}
                <Trans>by</Trans> {executedTestProtocolAttachment.creator.fullName}
              </span>
            </Typography>
          </>
        )}
      </ContentSection>
    </>
  );
};

export { RecordAttachmentSection };
