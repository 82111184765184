import { WorkflowDefinition, WorkflowTransition } from '../types';
import RecordOfOrigin from './RecordOfOrigin';
import RecordInProgress from './RecordInProgress';
import RecordChecked from './RecordChecked';
import RecordCancel from './RecordCancel';
import RecordVoid from './RecordVoid';

const RecordWorkflowSteps: WorkflowDefinition = {
  recordOfOrigin: RecordOfOrigin as WorkflowTransition,
  recordInProgress: RecordInProgress as WorkflowTransition,
  recordChecked: RecordChecked as WorkflowTransition,
  recordCanceled: RecordCancel as WorkflowTransition,
  recordVoided: RecordVoid as WorkflowTransition,
};

export default RecordWorkflowSteps;
