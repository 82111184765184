import React from 'react';
import { Link } from '@reach/router';
import isEmpty from 'lodash/isEmpty';
import { Chip, Tooltip, Typography } from '@material-ui/core';
import { Item } from 'assets/icons';
import { useStyles } from './styles';

export interface OtherConnection {
  customIdentifier: string;
  id: number;
  itemType: {
    displaySlug: string;
    name: string;
  };
  title: string;
}

interface Props {
  otherConnections: OtherConnection[];
}

function OtherConnections(props: Props) {
  const classes = useStyles();

  const mapConnection = (otherConnection) => {
    let chipLabel = (
      <Tooltip title={`${otherConnection.customIdentifier}: ${otherConnection.title}`}>
        <Link to={`/category/${otherConnection.itemType.displaySlug}/${otherConnection.id}`}>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} className={classes.hoverUnderline}>
            <Item style={{ fontSize: '16px', fill: '#3c4858' }} type={otherConnection.itemType.name} />
            <Typography variant="body2" className={classes.pillText}>
              {otherConnection.customIdentifier}
            </Typography>
          </div>
        </Link>
      </Tooltip>
    );

    return (
      <Chip
        key={`otherTraceChip-${otherConnection.customIdentifier}`}
        size="small"
        style={{ height: 17, margin: 2 }}
        label={chipLabel}
      />
    );
  };

  return !isEmpty(props.otherConnections) ? (
    <div>
      <Typography variant="body2">Other Connections</Typography>
      <div>{props.otherConnections.map(mapConnection)}</div>
    </div>
  ) : (
    <></>
  );
}

export default OtherConnections;
