import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

function NoRiskSelected(props: any) {   
  const classes = useStyles();
  const isRiskSelected = props.currentVersion.riskLevel !== null;

  useEffect(() => {
    if (isRiskSelected) {
      props.handleComplete(true, { forgetThisStep: true });
    }
  }, [isRiskSelected]);

  if (isRiskSelected) return <div />;

  return (
    <>
      <div className={classes.heading}>
        <Typography variant="h3">Not Allowed</Typography>
      </div>
      <Typography component="p" variant="h5" align="left">
        This supplier cannot advance to Ready For Prequalification Status until a Risk Level has been selected.
      </Typography>
    </>
  );
}

export default NoRiskSelected;
