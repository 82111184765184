import React from 'react';
import { Grid, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import LowPriority from '@material-ui/icons/LowPriority';
import { Trans } from '@lingui/macro';
import { DateTime } from 'luxon';
import get from 'lodash/get';

import { ContentSection, Spacer } from 'components';

const datefmt = 'MMM. d yyyy';

function HistoryTable({ classes, versions }) {
  return (
    <Grid item xs={8} className={classes.grid}>
      <ContentSection
        LabelText={
          <>
            <LowPriority className={classes.icon} />
            <Trans>Version History</Trans>
          </>
        }
      >
        <Spacer factor={1} />
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.tableCell}>Ver #</TableCell>
              <TableCell style={{ width: '50%' }} className={classes.tableCell}>
                Description of Change
              </TableCell>
              <TableCell className={classes.tableCell}>Affected Documents</TableCell>
              <TableCell className={classes.tableCell}>Approver</TableCell>
              <TableCell className={classes.tableCell}>Approval Date</TableCell>
              <TableCell className={classes.tableCell}>Effective Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versions.map((row) => {
              const approvalUserName = get(row.approval, 'user.fullName');
              const approvalCreatedAt = DateTime.fromISO(get(row.approval, 'createdAt')).toFormat(datefmt);

              const retirementUserName = get(row.retirement, 'user.fullName');
              const retirementCreatedAt = DateTime.fromISO(get(row.retirement, 'createdAt')).toFormat(datefmt);

              const releasedAt = DateTime.fromISO(row.releasedAt).toFormat(datefmt);
              const retiredAt = DateTime.fromISO(row.retiredAt).toFormat(datefmt);

              return (
                <TableRow key={row.id} id={`previous-rev-${row.id}`} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>{row.versionIdentifier}</TableCell>

                  <TableCell style={{ width: '50%' }} className={classes.tableCell}>
                    {row.descriptionOfChange}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {row.linkedChangeJustificationText || !row.linkedChangeCustomIdentifier ? (
                      <Trans>N/A - no affected documents</Trans>
                    ) : (
                      <>
                        <Trans>View impacted items in </Trans>
                        {` ${row.linkedChangeCustomIdentifier}`}
                      </>
                    )}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {row.approval ? approvalUserName : retirementUserName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.approval ? approvalCreatedAt : retirementCreatedAt}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{row.releasedAt ? releasedAt : retiredAt}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </ContentSection>
    </Grid>
  );
}

export default HistoryTable;
