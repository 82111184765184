import gql from 'graphql-tag';
import { t } from '@lingui/macro';

import { RuleFolder } from 'assets/icons';
import slugs from '../../displaySlugs';

const itemType = 'verificationAndValidationReport';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Verification and Validation Report`,
  categoryLabel: t`V & V Reports`,
  icon: RuleFolder,
  listViewQuery: gql`
    query verificationAndValidationReportsListViewQuery {
      verificationAndValidationReports {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
      }
    }
  `,
  listViewColumns,
};
