import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    titleIcon: {
      color: theme.palette.secondary.main,
    },
    title: {
      fontSize: 21,
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(1),
    },
    section: {
      borderRadius: 4,
      background: theme.palette.common.white,
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    sectionTitleIcon: {
      color: theme.palette.secondary.main,
    },
    sectionTitle: {
      flex: 1,
      fontSize: 18,
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(1),
      fontWeight: theme.typography.fontWeightBold,
    },
    channelTagIcon: {
      fontSize: 20,
      color: theme.palette.secondary.main,
    },
    channelTagLabel: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(0.25),
    },
    preferenceLabel: {
      color: theme.palette.secondary.main,
      textTransform: 'capitalize',
    },
    checkbox: {
      padding: 0,
      '&:first-child': {
        marginRight: theme.spacing(6),
      },
    },
  }),
);
