import React from 'react';
import camelCase from 'lodash/camelCase';
import flowRight from 'lodash/flowRight';
import { Router } from '@reach/router';

import Layout from 'components/Layout';
import AccessRestricted from 'pages/AccessRestricted';

import {
  PrecomplianceDashboard,
  ForgotPassword,
  ForgotUsername,
  Login,
  ResetPassword,
  SetPassword,
  SignOut,
} from 'pages';

import ListPage from 'pages/generic/ListPage';
import RecordPage from 'pages/generic/RecordPage';

import { useAllItemTypes } from 'hooks/useAllItemTypes';
import { useCurrentCompany } from 'compositions/WithCurrentCompany';
import { ItemTypeIndex } from 'itemTypes';
import withCurrentUser from 'compositions/WithCurrentUser';

import { workspaceViewsForItemType, View } from 'workspaces';
import { PageType } from './routes';
import TrainingDashboard from '../pages/TrainingDashboard';
import UtilitiesHome from '../pages/UtilitiesHome';
import RiskManagementDashboard from '../pages/RiskManagementDashboard';
import SupplierManagementDashboard from '../pages/SupplierManagementDashboard';
import MainDashboard from 'pages/MainDashboard';
import Harms from 'pages/Harms';
import CourseId from 'pages/Course/Id';
import CybersecurityReportId from 'pages/CybersecurityReport/Id';
import DeviationId from 'pages/Deviation/Id';
import DeviceMasterRecordId from 'pages/DeviceMasterRecord/Id';
import Employee from 'pages/Employee';
import EmployeeId from 'pages/Employee/Id';
import Hazards from 'pages/Hazards';
import ItemVersionIndex from 'pages/ItemVersionIndex';
import MyTrainingPlan from 'compositions/MyTrainingPlan';
import RiskId from 'pages/Risk/Id';
import SupplierId from 'pages/Supplier/Id';
import TrainingRecordId from 'pages/TrainingRecord/Id';
import TrainingReport from 'pages/TrainingReport';
import ValidationRecordId from 'pages/ValidationRecord/Id';
import VerificationAndValidationPlanId from 'pages/VerificationAndValidationPlan/Id';
import VerificationAndValidationReportId from 'pages/VerificationAndValidationReport/Id';
import VerificationRecordId from 'pages/VerificationRecord/Id';
import SupplierAuditPlanId from 'pages/SupplierAuditPlan/Id';
import SupplierAuditReportId from 'pages/SupplierAuditReport/Id';
import QualityAgreementId from 'pages/QualityAgreement/Id';
import ApprovedSupplier from 'pages/ApprovedSupplier';
import RiskTraceMatrices from 'pages/RiskTraceMatrices';
import RiskControlEffectivenessTests from 'pages/RiskControlEffectivenessTests';
import RiskControlImplementationTests from 'pages/RiskControlImplementationTests';
import GeneralSettings from 'pages/GeneralSettings';
import RoleManagement from 'pages/RoleManagement';
import UserManagement from 'pages/UserManagement';
import UserSettings from '../pages/UserSettings';
import SupplierQuestionnaireRecordId from 'pages/SupplierQuestionnaireRecord/Id';
import TaskId from 'pages/Task/Id';

export type ItemTypeRoute = (props: {
  categorySlug: string;
  path?: string;
  workspaceViews: View[] | null;
  itemType: string;
}) => any;

function AppRouter({ reloadApp, currentUser }) {
  const ItemTypesResult = useAllItemTypes();
  const CompanyResult = useCurrentCompany();

  if (ItemTypesResult.loading || !ItemTypesResult.data) return null;
  if (CompanyResult.loading || !CompanyResult.data) return null;

  const company = CompanyResult.data.currentCompany;
  const enabled = new Set(company.enabledCategories);
  const disabled = new Set(company.disabledCategories);

  const currentUserIsAdmin = currentUser.jobRoles.some((role) => role.roleName === 'admin');

  return (
    <Router>
      <Login path="/login" reloadApp={reloadApp} />
      <SignOut path="/sign-out" reloadApp={reloadApp} />
      <ForgotPassword path="/forgot-password" reloadApp={reloadApp} />
      <ForgotUsername path="/forgot-username" reloadApp={reloadApp} />
      <ResetPassword path="/reset-password" reloadApp={reloadApp} />
      <SetPassword path="/set-password" reloadApp={reloadApp} />

      <Layout default>
        <MainDashboard path="/" />
        <RiskManagementDashboard path="/category/risk-management" />
        <TrainingDashboard path="/category/training-dashboard" />
        <SupplierManagementDashboard path="/category/supplier-management" />
        <AccessRestricted path="/category/:categorySlug" />
        <CourseId path="/category/courses/:itemId" />
        <CybersecurityReportId path="/category/cybersecurity-report/:itemId" />
        <DeviationId path="/category/deviation/:itemId" />
        <DeviceMasterRecordId path="/category/device-master-record/:itemId" />
        <EmployeeId path="/category/employees/:id" />
        <Harms path="/category/harms" />
        <Hazards path="/category/hazards" />
        <ItemVersionIndex path="/category/item-version-index" />
        <RiskId path="/category/risks/:itemId" />
        <SupplierId path="/category/supplier/:itemId" />
        <TaskId path="/category/task/:itemId" />
        <TrainingRecordId path="/category/training-records/:itemId" />
        <TrainingReport path="/category/training-report" />
        <ValidationRecordId path="/category/validation-record/:itemId" />
        <VerificationAndValidationPlanId path="/category/v-v-plan/:itemId" />
        <VerificationAndValidationReportId path="/category/v-v-report/:itemId" />
        <VerificationRecordId path="/category/verification-record/:itemId" />
        <UtilitiesHome path="/category/utilities-home" />
        <SupplierAuditPlanId path="/category/supplier-audit-plan/:itemId" />
        <SupplierAuditReportId path="/category/supplier-audit-report/:itemId" />
        <QualityAgreementId path="/category/quality-agreement/:itemId" />
        <ApprovedSupplier path="/category/approved-supplier" />
        <RiskTraceMatrices path="/category/risk-trace-matrices" />
        <RiskControlEffectivenessTests path="/category/risk-control-effectiveness-tests" />
        <RiskControlImplementationTests path="/category/risk-control-implementation-tests" />
        <SupplierQuestionnaireRecordId path="/category/supplier-questionnaire-record/:itemId" />

        {ItemTypesResult.data.itemTypes
          .filter(({ displaySlug }) => displaySlug && !disabled.has(displaySlug) && enabled.has(displaySlug))
          .flatMap((itemType) => {
            const camelizedName = camelCase(itemType.name);
            const localItemType = ItemTypeIndex[camelizedName];
            if (!localItemType) return [];

            const ListRoute = localItemType.ListPage || ListPage;
            const RecordRoute = localItemType.RecordPage || RecordPage;

            if (itemType.userCreatable) {
              return [
                <ListRoute
                  key={`${camelizedName}:list`}
                  categorySlug={itemType.displaySlug}
                  path={`/category/${itemType.displaySlug}`}
                  workspaceViews={workspaceViewsForItemType(camelizedName, PageType.List)}
                  itemType={camelizedName}
                />,
                <RecordRoute
                  key={`${camelizedName}:record`}
                  categorySlug={itemType.displaySlug}
                  path={`/category/${itemType.displaySlug}/:itemId`}
                  workspaceViews={workspaceViewsForItemType(camelizedName, PageType.SingleRecord)}
                  itemType={camelizedName}
                />,
              ];
            } else {
              return [
                <RecordRoute
                  key={`${camelizedName}:record`}
                  categorySlug={itemType.displaySlug}
                  path={`/category/${itemType.displaySlug}`}
                  workspaceViews={workspaceViewsForItemType(camelizedName, PageType.SingleRecord)}
                  itemType={camelizedName}
                />,
              ];
            }
          })}

        {enabled.has('my-training-plan') && <MyTrainingPlan path="/category/my-training-plan" />}
        {enabled.has('harms') && <Harms path="/category/harms" />}
        {enabled.has('hazards') && <Hazards path="/category/hazards" />}
        {enabled.has('employees') && <Employee path={'/category/employees'} />}
        {enabled.has('risk-management') && <RiskManagementDashboard path="/category/risk-management" />}
        {enabled.has('supplier-management') && <SupplierManagementDashboard path="/category/supplier-management" />}
        {enabled.has('training-dashboard') && <TrainingDashboard path="/category/training-dashboard" />}
        {enabled.has('precompliance-dashboard') && <PrecomplianceDashboard path="/category/precompliance-dashboard" />}
        {enabled.has('user-settings') && <UserSettings path="/category/user-settings" />}
        {currentUserIsAdmin ? (
          <GeneralSettings path={'/category/general-settings'} />
        ) : (
          <AccessRestricted path={'/category/:categorySlug'} />
        )}
        {currentUserIsAdmin ? (
          <UserManagement path={'/category/user-management'} />
        ) : (
          <AccessRestricted path={'/category/:categorySlug'} />
        )}
        {currentUserIsAdmin ? (
          <RoleManagement path={'/category/role-management'} />
        ) : (
          <AccessRestricted path={'/category/:categorySlug'} />
        )}
      </Layout>
    </Router>
  );
}

export default flowRight([withCurrentUser])(AppRouter);
