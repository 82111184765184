import React, { useState } from 'react';
import clsx from 'clsx';

import { IconButton, Tooltip } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { useStyles } from './styles';

interface CollapseButtonProps {
  open: boolean;
  onClick: () => void;
}

const CollapseButton = (props: CollapseButtonProps) => {
  const classes = useStyles();

  const { onClick, open } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleClick = () => {
    onClick();
    handleTooltipClose();
  };
  return (
    <Tooltip title={`${open ? 'Collapse' : 'Expand'} Drawer`} open={tooltipOpen} onClose={handleTooltipClose}>
      <div
        className={clsx(!open && classes.hided, classes.container)}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
      >
        <svg
          width="16"
          height="78"
          viewBox="0 0 16 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid="DrawerButton"
          stroke={open ? 'rgba(0, 0, 0, 0.12)' : '#4db1ab'}
        >
          <path
            fill={open ? 'white' : '#4DB1AB'}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.8761 38.6237C16 40.5162 16 42.816 16 45.6473V60.4584C16 65.1932 16 67.5606 14.1128 69.796C12.2257 72.0313 9.16473 72.552 3.04287 73.5935C1.20903 73.9055 0.00320506 74.7625 6.37939e-06 76.7991C1.99847e-05 71.9417 0 29.6473 0 29.6473C4.75457e-05 29.6473 9.50913e-05 29.6473 0.000142637 29.6473C0.000146645 17.4885 0.000149494 3.15927 0.000141913 0.452863C0.00334037 2.48914 1.209 3.34612 3.04264 3.65811C9.16476 4.69979 12.2258 5.22064 14.1129 7.45594C16 9.69124 16 12.0587 16 16.7935V31.6001C16 34.4314 16 36.7313 15.8761 38.6237ZM0.000141913 0.452863C0.000140293 -0.12548 0.000138196 -0.173063 0.000135533 0.444712C0.000135522 0.447431 0.000137648 0.450148 0.000141913 0.452863ZM0 76.8073C0 76.8046 2.1152e-06 76.8018 6.37939e-06 76.7991C4.75934e-06 77.3775 2.66303e-06 77.4251 0 76.8073Z"
          />
        </svg>
        <IconButton className={open ? classes.buttonIconOpened : classes.buttonIconHided} onClick={handleClick}>
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
    </Tooltip>
  );
};
export default CollapseButton;
