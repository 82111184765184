import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Views } from 'workspaces';
import BuilderView from 'components/BuilderView';
import get from 'lodash/get';

import PayloadFields from './PayloadFields';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import EffectiveView from 'components/EffectiveView';
import VersionsView from 'components/VersionsView';
import { DocumentToolbar } from 'itemTypes/mixins/Document';

interface Props extends RouteComponentProps {
  itemId: string;
  activeWorkspaceView: { id: string };
}

const itemType = 'qualityAgreement';
const categorySlug = 'quality_agreement';

function Page(props: Props) {
  switch (props.activeWorkspaceView.id) {
    case Views.Effective.id:
      return (
        <EffectiveView
          categorySlug={categorySlug}
          itemType={itemType}
          itemId={props.itemId}
          EffectiveViewOptions={DocumentToolbar}
          PayloadFields={(props) => (
            <PayloadFields {...props} inputMethod={get(props.itemRecord, 'currentRelease.inputMethod')} />
          )}
        />
      );
    case Views.Versions.id:
      return (
        <VersionsView
          categorySlug={categorySlug}
          itemType={itemType}
          itemId={props.itemId}
          PayloadFields={(props) => <PayloadFields {...props} inputMethod={props.versionData.inputMethod} />}
        />
      );
    default:
      return (
        <BuilderView
          categorySlug={categorySlug}
          itemType={itemType}
          itemId={props.itemId}
          PayloadFields={(props) => (
            <PayloadFields {...props} inputMethod={get(props.itemRecord, 'currentVersion.inputMethod')} />
          )}
        />
      );
  }
}

export default withWorkspaceViews()(Page);
