import React from 'react';

import { CertifiedSignature, RejectReason, YesOrNo } from '../views';
import { AttachmentDependentWorkflowStatuses } from 'workflows/statuses';

export default {
  initialStep: 'approveOrReject',
  internalSteps: {
    approveOrReject: {
      component: (props) => (
        <YesOrNo
          heading="Check Training"
          prompt="Has the user completed the training?"
          yesLabel="Pass"
          noLabel="Fail"
          {...props}
        />
      ),
      getNextStep: (release) => (release ? 'approveSignature' : 'rejectReason'),
    },
    rejectReason: {
      component: (props) => {
        return (
          <RejectReason
            heading="As Training Manager I certify I have checked this Training Record for completion of course materials and have determined that the trainee failed to complete their training."
            title="Fail"
            {...props}
          />
        );
      },
      getNextStep: (_) => 'complete',
    },
    approveSignature: {
      component: (props) => {
        return (
          <CertifiedSignature
            dialog="As the Training Manager I certify I have checked this Training Record for completion of course materials and have determined that the trainee completed their training."
            heading="Pass"
            {...props}
          />
        );
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ approveOrReject, rejectReason, approveSignature }) => {
    if (approveOrReject) {
      return {
        toStatus: AttachmentDependentWorkflowStatuses.Checked.id,
        ...approveSignature,
      };
    } else {
      return {
        toStatus: AttachmentDependentWorkflowStatuses.Failed.id,
        reasonForRejection: rejectReason.reason,
        ...rejectReason.signature,
      };
    }
  },
};
