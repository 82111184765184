import gql from 'graphql-tag';

export const UPDATE_DEVICE_SAFETY_CHARACTERISTIC = gql`
  mutation UPDATE_DEVICE_SAFETY_CHARCTERISTIC($id: ID!, $response: String) {
    updateDeviceSafetyCharacteristic(id: $id, response: $response) {
      deviceSafetyCharacteristic {
        id
        response
      }
    }
  }
`;
