import React, { FC } from 'react';

import { CalendarToday } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

const Days: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.days}>
      <CalendarToday className={classes.icon} />
      <Typography variant="body2" className={classes.daysLabel}>
        Days
      </Typography>
    </div>
  );
};

export default Days;
