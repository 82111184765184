import React from 'react';

import { Loading } from 'components';
import { PayloadFieldsProps } from 'itemTypes';

import { useVersion } from './gql';
import AssignedPersonAndDueDateSection from './AssignedPersonAndDueDateSection';
import TaskOverview from './TaskOverview';
import AssociatedTasks from './AssociatedTasks';
import TableTrioSection from './TableTrioSection';

function PayloadFields(props: PayloadFieldsProps) {
  const { versionId, layoutClasses } = props;

  const { data, loading, error } = useVersion(versionId!);

  if (error) throw new Error(`Error loading Task ${versionId}\n${error}`);
  if (loading || !data) return <Loading />;
  return (
    <>
      <AssignedPersonAndDueDateSection taskVersion={data.taskVersion} layoutClasses={layoutClasses} />
      <TaskOverview data={data} layoutClasses={layoutClasses} />
      <TableTrioSection version={data.taskVersion} />
      <AssociatedTasks data={data} layoutClasses={layoutClasses} />
    </>
  );
}

export default PayloadFields;
