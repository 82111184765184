import React, { useState } from 'react';
import FormatAlignJustify from '@material-ui/icons/FormatAlignJustify';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import { Tab, TabIcon, TabContainer } from 'components';
import PlanTab from './PlanTab';
import JustificationsTabs from './JustificationsTab';
import ReferencesTab from './ReferencesTab';
import { Item } from 'assets/icons';

interface Props {
  currentVersion: any;
  layoutClasses: { [className: string]: string };
  locked: boolean;
  onUpdate: any;
  viewType: string;
}

export default function GuidedEditorTabs(props: Props) {
  const { currentVersion } = props;
  const planTab = 'Plan';
  const referencesTab = 'References';
  const verificationJustifications = 'Verification';
  const validationJustifications = 'Validation';

  const [currentTab, setCurrentTab] = useState(planTab);

  const tabs = {};
  tabs[planTab] = (
    <PlanTab
      viewType={props.viewType}
      fields={{
        acceptanceCriteria: currentVersion.acceptanceCriteria,
        approach: currentVersion.approach,
        deviationManagement: currentVersion.deviationManagement,
        purpose: currentVersion.purpose,
        planSummary: currentVersion.planSummary,
        planToggle: currentVersion.planToggle,
        responsibilities: currentVersion.responsibilities,
        scope: currentVersion.scope,
      }}
      locked={props.locked}
      versionId={currentVersion.id}
    />
  );
  tabs[referencesTab] = (
    <ReferencesTab viewType={props.viewType} currentVersion={currentVersion} onUpdate={props.onUpdate} />
  );
  tabs[verificationJustifications] = (
    <JustificationsTabs
      key={'verification-protocol'}
      justifications={currentVersion.verificationProtocols}
      type="verification-protocol"
      versionId={currentVersion.id}
      locked={currentVersion.locked}
    />
  );
  tabs[validationJustifications] = (
    <JustificationsTabs
      key={'validation-protocol'}
      justifications={currentVersion.validationProtocols}
      type="validation-protocol"
      versionId={currentVersion.id}
      locked={currentVersion.locked}
    />
  );

  return (
    <TabContainer
      layoutClasses={props.layoutClasses}
      tabs={
        <>
          <Tab
            icon={<TabIcon Icon={FormatAlignJustify} />}
            active={planTab === currentTab}
            onClick={() => setCurrentTab(planTab)}
            text={planTab}
          />
          <Tab
            icon={<TabIcon Icon={PlaylistAddCheck} />}
            active={referencesTab === currentTab}
            onClick={() => setCurrentTab(referencesTab)}
            text={referencesTab}
          />
          <Tab
            icon={<Item type="device_verification" fontSize="inherit" />}
            active={verificationJustifications === currentTab}
            onClick={() => setCurrentTab(verificationJustifications)}
            text={verificationJustifications}
          />
          <Tab
            icon={<Item type="device_validation" fontSize="inherit" />}
            active={validationJustifications === currentTab}
            onClick={() => setCurrentTab(validationJustifications)}
            text={validationJustifications}
          />
        </>
      }
    >
      {tabs[currentTab]}
    </TabContainer>
  );
}
