import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    cssFocused: {},
    notchedOutline: {
      border: `2px solid ${theme.palette.common.input.outline} !important`,
    },
    textField: {
      paddingRight: 0,
    },
    cancelIcon: {
      color: 'inherit',
    },
  }),
);
