import React from 'react';
import { t } from '@lingui/macro';
import Error from '@material-ui/icons/Error';
import slugs from '../../displaySlugs';
import gql from 'graphql-tag';
import PayloadFields from './Id/PayloadFields';
import { isColumnGroupedBy } from 'utils/agGridHelpers';

const itemType = 'riskAnalysis';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    headerName: 'Risks',
    type: 'custom',
    field: 'risks',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      const risksCount = row.value.currentVersion.memberVersions.filter(
        (version) => version.item.itemType.name === 'risk',
      ).length;
      return risksCount;
    },
    render: (row) => {
      if (isColumnGroupedBy(row, 'risks')) {
        return row.value;
      }
      const risksCount = row.value.currentVersion.memberVersions.filter(
        (version) => version.item.itemType.name === 'risk',
      ).length;
      return <div>{risksCount}</div>;
    },
  },
  {
    headerName: 'Risk Controls',
    type: 'custom',
    field: 'riskControls',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      const riskControlsCount = row.value.currentVersion.memberVersions.filter(
        (version) => version.item.itemType.name === 'risk_control',
      ).length;
      return riskControlsCount;
    },
    render: (row) => {
      if (isColumnGroupedBy(row, 'riskControls')) {
        return row.value;
      }
      const riskControlsCount = row.value.currentVersion.memberVersions.filter(
        (version) => version.item.itemType.name === 'risk_control',
      ).length;
      return <div>{riskControlsCount}</div>;
    },
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Risk Analysis`,
  categoryLabel: t`Risk Analyses`,
  icon: Error,
  listViewQuery: gql`
    query riskAnalysesQuery {
      riskAnalyses {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          title
          createdAt
          versionIdentifier
          currentStatusName
          currentStatus {
            id
            name
          }
          memberVersions(showOnlyRisk: false) {
            __typename
            ... on RiskVersion {
              id
              title
              item {
                id
                customIdentifier
                itemType {
                  id
                  name
                }
              }
              versionIdentifier
            }
            ... on RiskControlVersion {
              id
              title
              versionIdentifier
              item {
                id
                customIdentifier
                itemType {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  listViewColumns,
  PayloadFields,
  disableNoRecordsPanel: true,
  hideNotes: true,
};
