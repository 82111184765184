import { createStyles } from '@material-ui/core';

export default createStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  closeIcon: {
    float: 'right',
    '&:hover': { cursor: 'pointer' },
  },
  disabledInput: {
    pointerEvents: 'none',
  },
  pdfActionButton: {
    opacity: 0.6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modalCard: {
    top: '30%',
    left: '50%',
    position: 'fixed',
    width: 328,
  },
  inputWrapper: {
    display: 'flex',
    position: 'relative',
  },
  buttonGroup: {
    alignItems: 'center',
    position: 'absolute',
    height: 36,
    right: 10,
  },
  uploaderBtn: {
    ...theme.typography.body2,
    justifyContent: 'left',
    textTransform: 'initial',
    background: theme.palette.common.input.background,
    border: `2px solid ${theme.palette.common.input.outline}`,
    padding: theme.spacing(1),
  },
}));
