export default (theme) => ({
  '@global': {
    '@media print': {
      '#slimnav': {
        display: 'none',
      },
    },
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
    left: `${theme.spacing(5)}px !important`, // material-ui overrides this with higher specificity for some reason
  },
  popperContent: {
    padding: theme.spacing(1),
    width: theme.spacing(37),
  },
  popperRow: {
    ...theme.typography.h7,
    fontWeight: 400,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginButtom: theme.spacing(0.5),
  },
  popperHover: {
    '&:hover': {
      backgroundColor: '#f4f4f4',
      cursor: 'pointer',
    },
  },
  activeAppWindow: {
    backgroundColor: 'rgba(0,0,0,0.08)',
  },
  avatar: {
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    position: 'absolute',
    width: 30,
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 24,
    paddingTop: 24,
    width: theme.mixins.sidebar.expandedWidth + theme.spacing(15),
    zIndex: 1200,
  },
  disableGutter: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  logo: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: theme.spacing(7),
  },
  logoButton: {
    padding: '0 0 10px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logoImg: {
    height: '36px',
    width: '36px',
  },
  offset: {
    width: theme.spacing(7),
  },
  slimNav: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
    height: '100vh',
    justifyContent: 'space-between',
    paddingBottom: '24px',
    paddingTop: '19px',
    position: 'fixed',
    width: theme.spacing(7),
    zIndex: 1400,
  },
  slimNavItem: {
    position: 'relative',
    fontSize: '36px',
    height: theme.spacing(7),
  },
  newItemButton: {
    '& .MuiSvgIcon-root': {
      color: '#fff',

      opacity: 0.5,
      '&:hover': {
        opacity: 1,
      },
    },
  },
  slimNavSearchIcon: {
    fontSize: '36px',
    height: theme.spacing(7),
    marginTop: '10px',
  },
  slimNavSection: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
    justifyContent: 'flex-start',
  },
  disciplinesPanel: {
    width: '100%',
    height: '100%',
  },
  disciplinesItem: {
    width: '100%',
  },
  logoContainer: {
    padding: 0,
    paddingBottom: theme.spacing(3),
    opacity: 1,
    transition: 'opacity .3s ease-in-out',
  },
  logoHided: {
    opacity: 0,
    '&::after': {
      content: "' '",
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
    },
  },
  badge: {
    color: theme.palette.blueDark,
    background: theme.palette.common.input.outline,
    fontSize: '10px',
    fontWeight: theme.typography.fontWeightBold,
    height: 15,
    width: 15,
    minWidth: 'auto',
    whiteSpace: 'nowrap',
  },
});
