import React from 'react';
import { getLayoutComponent } from 'utils/pageUtils';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { Views } from 'workspaces';

import BuilderView from 'compositions/FormBasedBuilderView';
import EffectiveView from 'compositions/FormBasedEffectiveView';
import { DocumentToolbar } from 'itemTypes/mixins/Document';
import PayloadFields from './PayloadFields';

function RecordPage(props) {
  switch (props.activeWorkspaceView.id) {
    case Views.Builder.id:
      return (
        <BuilderView
          itemId={props.itemId}
          itemTypeName={'finalDeviceInspection'}
          listViewQueryToRefetch={'finalDeviceInspectionsQuery'}
          PayloadFields={PayloadFields}
          categorySlug={'final-device-inspections'}
        />
      );
    case Views.Effective.id:
      return (
        <EffectiveView
          itemId={props.itemId}
          itemTypeName={'finalDeviceInspection'}
          listViewQueryToRefetch={'finalDeviceInspectionsQuery'}
          PayloadFields={PayloadFields}
          EffectiveViewOptions={DocumentToolbar}
          categorySlug={'final-device-inspections'}
          formBasedRecordItemTypeName={'finalDeviceInspectionRecord'}
        />
      );
    default:
      const LayoutComponent = getLayoutComponent(props.activeWorkspaceView.id);
      return (
        <LayoutComponent
          {...props}
          itemType={'finalDeviceInspection'}
          displayHistoryInEffectiveView
          PayloadFields={PayloadFields}
        />
      );
  }
}

export default withWorkspaceViews()(RecordPage);
