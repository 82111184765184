import React, { ComponentType, ReactNode } from 'react';

import { Dialog, Typography, SvgIconProps, useScrollTrigger, Slide, Fade } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import { Spacer } from 'components';
import Buttons from 'components/core/Buttons';

import { useStyles } from './styles';

export type DialogSize = 'large' | 'default' | 'xlarge';
export type TextAlign = 'left' | 'center';

interface Props {
  actions?: ReactNode;
  children: ReactNode;
  error?: string;
  Icon?: ComponentType<SvgIconProps>;
  title: string;
  subtitle?: string;
  onClose: () => void;
  open: boolean;
  size?: DialogSize;
  textAlign?: TextAlign;
  className?: string;
  scrollTarget?: HTMLDivElement;
}

function BaseDialog(props: Props) {
  const classes = useStyles();
  const scrollTrigger = useScrollTrigger({ target: props.scrollTarget, disableHysteresis: true, threshold: 10 });
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      classes={{ paper: `${classes.dialog} ${props.size}` }}
      keepMounted
      closeAfterTransition
      className={props.className}
    >
      <div className={classes.content}>
        {scrollTrigger ? (
          <ShrunkDialogHeader {...props} />
        ) : (
          <>
            <div className={classes.closeContainer}>
              <button className={classes.close}>
                <Close onClick={props.onClose} />
              </button>
            </div>
            <DialogHeader {...props} />
          </>
        )}

        <div className={`${classes.body} ${props.textAlign}`}>{props.children}</div>
        {props.actions && <Buttons className={classes.actions}>{props.actions}</Buttons>}
        {props.error && (
          <Typography variant="body2" color="error" component="div">
            {props.error}
          </Typography>
        )}
      </div>
    </Dialog>
  );
}

interface HeaderProps {
  Icon?: ComponentType<SvgIconProps>;
  SecondaryIcon?: ComponentType<SvgIconProps>;
  title: string;
  subtitle?: string;
  onClose?: () => void;
}

export function DialogHeader(props: HeaderProps) {
  const { Icon, SecondaryIcon, subtitle, title } = props;
  const classes = useStyles();

  return (
    <div className={classes.heading}>
      <span style={{ marginBottom: `${SecondaryIcon ? '-32px' : '0'}` }}>
        {Icon && <Icon className={classes.icon} color="primary" />}
        {SecondaryIcon && <SecondaryIcon className={classes.secondaryIcon} color="primary" />}
      </span>
      <Typography variant="h3">{title}</Typography>
      {subtitle && (
        <>
          <Spacer factor={2} />
          <Typography variant="body2">{subtitle}</Typography>
        </>
      )}
    </div>
  );
}

export function ShrunkDialogHeader(props: HeaderProps) {
  const { Icon, SecondaryIcon, subtitle, title, onClose } = props;
  const classes = useStyles();

  return (
    <Fade in={true}>
      <div className={classes.shrunkHeader}>
        {Icon && <Icon className={classes.icon} color="primary" />}
        {SecondaryIcon && <SecondaryIcon className={classes.secondaryIcon} color="primary" />}
        <Typography variant="h3" className={classes.shrunkHeadeTitle}>
          {title}
        </Typography>
        {subtitle && (
          <>
            <Spacer factor={2} />
            <Typography variant="body2">{subtitle}</Typography>
          </>
        )}
        <Slide in={true} direction="right" timeout={400}>
          <button className={classes.close}>
            <Close onClick={onClose} />
          </button>
        </Slide>
      </div>
    </Fade>
  );
}

export default BaseDialog;
