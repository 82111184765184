import gql from 'graphql-tag';

export const COMPLETE_PRECOMPLIANCE_TASK = gql`
  mutation CompletePrecomplianceTask($id: ID!) {
    completePrecomplianceTask(id: $id) {
      precomplianceTask {
        id
      }
    }
  }
`;

export const UNCOMPLETE_PRECOMPLIANCE_TASK = gql`
  mutation UncompletePrecomplianceTask($id: ID!) {
    uncompletePrecomplianceTask(id: $id) {
      precomplianceTask {
        id
      }
    }
  }
`;

export const INITIATE_PRECOMPLIANCE_TASK = gql`
  mutation InitiatePrecomplianceTask($id: ID!) {
    initiatePrecomplianceTask(id: $id) {
      precomplianceTask {
        id
      }
    }
  }
`;
