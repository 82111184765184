import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import { Button, Modal, Tooltip, withStyles } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';

import ItemTypeSelector from './ItemTypeSelector';

import { ItemCreatedSnackbar } from 'components';
import WithCurrentUser from 'compositions/WithCurrentUser';

import styles from './styles';

class NewItem extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      newItemButton: PropTypes.string.isRequired,
    }).isRequired,
    i18n: PropTypes.shape({
      _: PropTypes.func.isRequired,
    }).isRequired,
    styleOverride: PropTypes.object,
    onCreate: PropTypes.func,
    refetchQueries: PropTypes.any, // string | array | nothing
    dontCenter: PropTypes.bool,
    hideAddIcon: PropTypes.bool,
  };

  static defaultProps = {
    showButton: true,
    color: '#b5e4e1',
    fontSize: '36px',
    styleOverride: {},
  };

  state = {
    modalOpen: false,
    selectedItemType: undefined,
    snackbarOpen: false,
    itemId: null,
    customIdentifier: null,
  };

  showSnackbar = () => this.setState({ snackbarOpen: true, modalOpen: false });

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false, customIdentifier: null });
  };

  handleModalClose = (evt) => {
    evt.preventDefault();

    this.setState({
      modalOpen: false,
    });
  };

  retrieveItemData = (item) => {
    this.setState({
      itemId: item.id,
      customIdentifier: item.customIdentifier,
      selectedItemType: item.itemType.name,
    });
  };

  render() {
    const { classes, currentUser, i18n, children, createItemButtonText } = this.props;
    const { customIdentifier, itemId, selectedItemType } = this.state;

    const styleOverride = {
      color: this.props.color,
      fontSize: this.props.fontSize,
      ...this.props.styleOverride,
    };

    const createItemModalId = 'create-item-modal';
    const onCreate = (data) => {
      const newItem = data.createItem.item;

      this.retrieveItemData(newItem);
      this.props.onCreate && this.props.onCreate(newItem);
    };

    const blacklistedItemTypesSet = [
      'formRecord',
      'finalDeviceInspectionRecord',
      'incomingQualityInspectionRecord',
      'inProcessInspectionRecord',
      'trainingPlan',
      'trainingRecord',
      'validationRecord',
      'verificationRecord',
      'deviation',
      'supplierQuestionnaireRecord',
    ];

    if (blacklistedItemTypesSet.includes(this.props.defaultSelectedItemType)) {
      return null;
    }

    return (
      <>
        <Tooltip title={currentUser.readOnly ? i18n._(t`Upgraded license required to use this feature`) : ''}>
          <div style={this.props.dontCenter ? {} : { display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              aria-haspopup="true"
              aria-label={i18n._(t`Create New Item`)}
              aria-owns={createItemModalId}
              classes={{ root: classes.newItemButton }}
              disabled={currentUser.readOnly}
              style={styleOverride}
              onClick={this.handleModalOpen}
              variant="text"
              id="create-item-button"
              component={currentUser.readOnly ? 'div' : undefined}
            >
              {!this.props.hideAddIcon && (
                <AddCircle
                  className={classes.icon}
                  fontSize="inherit"
                  color="inherit"
                  data-testid="create-new-item-icon"
                  titleAccess={i18n._(t`Create New Item`)}
                />
              )}
              {children}
            </Button>
          </div>
        </Tooltip>
        <Modal id={createItemModalId} open={this.state.modalOpen} onClose={this.handleModalClose}>
          <ItemTypeSelector
            showSnackbar={this.showSnackbar}
            createItemButtonText={createItemButtonText}
            onCreate={onCreate}
            handleModalClose={this.handleModalClose}
            defaultItemType={this.props.defaultSelectedItemType}
            refetchQueries={this.props.refetchQueries}
          />
        </Modal>
        {customIdentifier && itemId && (
          <ItemCreatedSnackbar
            open={this.state.snackbarOpen}
            handleSnackbarClose={this.handleSnackbarClose}
            customIdentifier={customIdentifier}
            itemId={itemId}
            selectedItemType={selectedItemType}
          />
        )}
      </>
    );
  }
}

export default compose(
  WithCurrentUser,
  withI18n(),
  withStyles(styles),
)(NewItem);
