import gql from 'graphql-tag';

const query = gql`
  query VersionsViewQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      createdAt
      tags
      versions {
        releasedAt
        id
        locked
        title
        currentStatusName
        versionIdentifier
        owner {
          id
          fullName
          title
        }
        approver {
          id
          fullName
          title
        }
      }
      currentVersion {
        id
        releasedAt
        currentStatus {
          id
          name
        }
        ... on CybersecurityReportVersion {
          body
          pdfDataUpload
          inputMethod
        }
      }
    }
  }
`;
export default query;
