import React, { ReactNode } from 'react';

import Error from '@material-ui/icons/Error';

import { DialogHeader } from 'components/BaseDialog';
import { VersionAndStatusDot } from 'components';

import { useStyles } from './styles'; // ideally having stuff extracted form that dialog
import QuotedSection from 'components/core/QuotedSection';
import { Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import linkToItem from 'utils/linkToItem';

export interface Item {
  customIdentifier: string;
  currentStatus: string;
  id: string;
  versionIdentifier: string;
  [key: string]: any;
}

interface Props {
  children: ReactNode;
  items: Item[];
}

// not really dialog now, only contents of it so it is usable in the modal-steps-sequence
function ImpactedItemsDialog(props: Props) {
  const classes = useStyles();
  const { children, items } = props;
  const Icon = Error;
  const title = 'Not Allowed';

  return (
    <>
      <DialogHeader Icon={Icon} title={title} />
      <Typography component="p" variant="h5">
        {children}
      </Typography>
      <QuotedSection className={classes.container}>
        <ul className={classes.list}>
          {items.map((item) => {
            return (
              <li className={classes.listItem} key={item.id}>
                <Link to={linkToItem(item.displaySlug, item.id)}>
                  <Typography variant="h6" component="span" className={classes.itemId}>
                    {item.customIdentifier}
                  </Typography>
                </Link>
                <VersionAndStatusDot version={item.versionIdentifier} status={item.currentStatus} />
              </li>
            );
          })}
        </ul>
      </QuotedSection>
    </>
  );
}

export default ImpactedItemsDialog;
