import React from 'react';
import { Trans } from '@lingui/macro';
import get from 'lodash/get';

import {
  CertifiedSignature,
  ChangeSummary,
  ChangeOrderRequired,
  Justify,
  SelectChangeOrder,
  YesOrNo,
} from 'compositions/TimelineGroup/steps/views';
import { SetGenericWorkflowStatuses } from 'workflows/statuses';

export default {
  initialStep: 'takeOwnership',
  internalSteps: {
    takeOwnership: {
      component: (props) => (
        <YesOrNo
          disableOnSubmit
          prompt={`Do you want to take ownership of version ${props.currentVersion.versionIdentifier}
          of ${props.itemCustomIdentifier} or unlock and send to Draft status?`}
          yesLabel="Take Ownership"
          noLabel="Unlock"
          {...props}
        />
      ),
      getNextStep: (takeOwnership) => (takeOwnership ? 'changeSummary' : 'complete'),
    },
    changeSummary: {
      component: (props) => <ChangeSummary buttonText="Next" {...props} />,
      getNextStep: (_) => 'coRequired',
    },
    coRequired: {
      component: ChangeOrderRequired,
      getNextStep: (coRequired) => (coRequired ? 'selectCO' : 'justify'),
    },
    selectCO: {
      component: SelectChangeOrder,
      getNextStep: (_) => 'signature',
    },
    justify: {
      component: Justify,
      getNextStep: (_) => 'signature',
    },
    signature: {
      component: (props) => {
        const heading = <Trans>Ownership Declaration</Trans>;
        const dialog = `As the ${props.currentVersion.assignedOwnerJobRole.formattedRoleName}, I take ownership of ${
          props.itemCustomIdentifier
        }.`;

        return <CertifiedSignature heading={heading} dialog={dialog} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ takeOwnership, changeSummary, justify, selectCO, signature }) => {
    if (takeOwnership) {
      return {
        changeItemId: get(selectCO, 'changeOrderId'),
        justificationText: justify,
        ...changeSummary,
        toStatus: SetGenericWorkflowStatuses.OwnerApproval.id,
        ...signature,
      };
    } else {
      return {
        toStatus: SetGenericWorkflowStatuses.Draft.id,
      };
    }
  },
};
