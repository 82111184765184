import React from 'react';

import { Typography } from '@material-ui/core';

import { stripHTML } from 'utils';

import { useStyles } from './styles';

export function LongTextCell({ col, data }) {
  const classes = useStyles();
  const strippedData = stripHTML(data);

  function convertStrToPhoneNum(str) {
    return str.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  return (
    <div className={classes.content}>
      <Typography variant="body2">
        {col.key === 'phoneNumber' ? convertStrToPhoneNum(strippedData) : strippedData}
      </Typography>
    </div>
  );
}

export const longTextHeaderStyle = { textAlign: 'left' };
