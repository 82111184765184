import React from 'react';
import { SummaryOfChange } from 'compositions/TimelineGroup/steps/views';

export default {
  initialStep: 'summaryOfChange',
  internalSteps: {
    summaryOfChange: {
      component: (props) => <SummaryOfChange {...props} />,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: () => null,
};
