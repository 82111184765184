import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const GET_VERIFICATION_AND_VALIDATION_REPORT_VERSION = gql`
  query VerificationAndValidationReportVersionQuery($id: ID!) {
    verificationAndValidationReportVersion(id: $id) {
      trioReferencedItems {
        id
        customIdentifier
        workingVersion {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
        currentVersion {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
        itemType {
          id
          displaySlug
          userCreatable
        }
      }
      trioReferencedVersions {
        id
        versionIdentifier
        title
        currentStatus {
          id
          name
        }
        item {
          currentRelease {
            id
            versionIdentifier
            title
            currentStatus {
              name
              id
            }
          }
          workingVersion {
            id
            title
            versionIdentifier
            currentStatus {
              name
              id
            }
          }
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      trioReferencedAttachments {
        id
        versionId
        description
        url
      }     
      id
      title
      displayAsApplicationVersion
      locked
      reportSummary
      reportToggle
      purpose
      scope
      intendedUse
      systemDescription
      summaryOfResults
      conclusion
      mayTransitionToOwnerApproval
      associatedVvPlan {
        version {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            currentVersion {
              id
              versionIdentifier
              currentStatus {
                id
                name
              }
            }
          }
          validationProtocols {
            id
            item {
              id
              customIdentifier
              itemType {
                id
                displaySlug
                userCreatable
              }
              currentVersion {
                id
                title
                versionIdentifier
                currentStatus {
                  id
                  name
                }
              }
            }
          }
          verificationProtocols {
            id
            item {
              id
              customIdentifier
              itemType {
                id
                displaySlug
              }
              currentVersion {
                id
                title
                versionIdentifier
                currentStatus {
                  id
                  name
                }
              }
            }
          }
        }
      }
      validationRecords {
        id
        versionIdentifier
        isFulfillAllAcceptanceCriteria
        currentStatus {
          id
          name
        }
        deviceValidationVersion {
          id
        }
        deviationVersions {
          id
          deviationId
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            currentVersion {
              id
              title
            }
          }
          parentRecord {
            id
            versionIdentifier
            currentStatus {
              id
              name
            }
            item {
              id
              customIdentifier
            }
          }
          parentRecordValidationProtocol {
            id
            versionIdentifier
            currentStatus {
              id
              name
            }
            item {
              id
              customIdentifier
            }
          }
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      verificationRecords {
        id
        versionIdentifier
        isFulfillAllAcceptanceCriteria
        currentStatus {
          id
          name
        }
        deviceVerificationVersion {
          id
        }
        deviationVersions {
          id
          deviationId
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            currentVersion {
              id
              title
            }
          }
          parentRecord {
            id
            versionIdentifier
            currentStatus {
              id
              name
            }
            item {
              id
              customIdentifier
            }
          }
          parentRecordVerificationProtocol {
            id
            versionIdentifier
            currentStatus {
              id
              name
            }
            item {
              id
              customIdentifier
            }
          }
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
    }
  }
`;

export const UPDATE_VERIFICATION_AND_VALIDATION_REPORT_VERSION = gql`
  mutation UpdateVerificationAndValidationReportVersion(
    $id: ID!
    $reportSummary: String
    $reportToggle: Boolean
    $purpose: String
    $scope: String
    $intendedUse: String
    $systemDescription: String
    $summaryOfResults: String
    $conclusion: String
  ) {
    updateVerificationAndValidationReportVersion(
      id: $id
      reportSummary: $reportSummary
      reportToggle: $reportToggle
      purpose: $purpose
      scope: $scope
      intendedUse: $intendedUse
      systemDescription: $systemDescription
      summaryOfResults: $summaryOfResults
      conclusion: $conclusion
    ) {
      verificationAndValidationReportVersion {
        id
      }
    }
  }
`;

export const ADD_ASSOCIATED_VVPLAN_TO_VVREPORT = gql`
  mutation AddAssociatedVvPlan($linkedItemId: ID!, $parentVersionId: ID!) {
    addAssociatedVvPlan(linkedItemId: $linkedItemId, parentVersionId: $parentVersionId) {
      success
    }
  }
`;

export const REMOVE_ASSOCIATED_VVPLAN_TO_VVREPORT = gql`
  mutation RemoveAssociatedVvPlan($parentVersionId: ID!) {
    removeAssociatedVvPlan(id: $parentVersionId) {
      success
    }
  }
`;

export const useVersion = (id: string) => {
  return useQuery<any>(GET_VERIFICATION_AND_VALIDATION_REPORT_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
};
