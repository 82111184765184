export default (theme) => ({
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  card: {
    flex: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkRow: {
    borderBottom: `6px solid #dfdfdf`,
    padding: theme.spacing(1),
    display: 'flex',
  },
  rowHeader: {
    backgroundColor: '#eeeeee',
    flex: 1,
    textAlign: 'center',
    padding: theme.spacing(0.5),
    fontWeight: 600,
  },
  leftGutter: {
    paddingLeft: theme.spacing(5),
  },
  footer: {
    borderRadius: theme.spacing(1),
    position: 'absolute',
    bottom: 0,
    backgroundColor: '#fff',
    height: 47,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: `calc(100vw - ${theme.mixins.sidebar.expandedWidth}px)`,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1280,
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: 1080,
    },
  },
  navigateIcon: {
    '&:hover': { cursor: 'pointer' },
  },
  filterContainer: {
    padding: theme.spacing(1),
  },
  switchButton: {
    justifyContent: 'start',
  },
  deleteLinkModalContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    left: theme.mixins.sidebar.expandedWidth,
    right: 0,
    top: '25%',
    bottom: 0,
    outline: 'none',
  },
  deleteLinkModalPaper: {
    width: theme.spacing(45),
    padding: theme.spacing(3),
  },
  deleteLinkModalIcon: {
    float: 'right',
    '&:hover': { cursor: 'pointer' },
  },
  fixedHeader: {
    left: 0,
    right: 0,
    top: 72, // appBar height is 72
    display: 'flex',
    justifyContent: 'center',
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.default,
  },
  headerContent: {
    ...theme.mainLayoutGutters,
    width: '100%',
  },
  columnHeadings: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#eeeeee',
    borderRadius: '8px',
    padding: '5px 10px',
  },
  newItemButton: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,

      opacity: 0.5,
      '&:hover': {
        opacity: 1,
      },
    },
  },
});
