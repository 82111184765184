import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  trainingRecordVersionQuery,
  trainingRecordVersionQueryVariables,
} from './__generated__/trainingRecordVersionQuery';

const query = gql`
  query trainingRecordVersionQuery($id: ID!) {
    trainingRecordVersion(id: $id) {
      id
      locked
      dueDate
      notes
      category
      source {
        id
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
          }
        }
        title

        ... on CourseVersion {
          learningObjective
          trainingFormat
          trainingType
          instructor {
            id
            fullName
          }
          attachment {
            id
            file {
              url
              name
            }
            editableDocument {
              url
              name
            }
          }
          qualityDocumentVersions {
            id
            title
            currentStatusName
            versionIdentifier
            currentStatus {
              id
              name
            }
            item {
              id
              customIdentifier
              itemType {
                id
                displaySlug
                userCreatable
              }
            }
          }
          trainingMaterialAttachments {
            id
            description
            file {
              url
            }
            editableDocument {
              url
            }
          }
          trainingMaterialLinks
          assessmentGrader {
            id
            fullName
          }
        }
      }
      uploadedRecord {
        id
        url
        filename
      }
      recordOrigin
      reasonForTraining
    }
  }
`;

export function useTrainingRecordVersion(id: string) {
  return useQuery<trainingRecordVersionQuery, trainingRecordVersionQueryVariables>(query, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}
