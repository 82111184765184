export default (theme) => {
  return {
    heading: {
      clear: 'both',
      paddingLeft: '1px',
      paddingRight: '1px',
    },
    sectionHeading: {
      ...theme.typography.overline2,
    },
    textReason: {
      width: '100%',
      margin: 0,
    },
    spacer: {
      height: theme.spacing(1),
      clear: 'both',
    },
    splitter: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
      color: theme.palette.splitter.background,
    },
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    signatureText: {
      paddingLeft: 4,
    },
    checkboxContainer: {
      alignItems: 'flex-start',
    },
    checkbox: {
      paddingTop: 0,
    },
    buttonDraft: {
      minHeight: 40,
      backgroundColor: theme.palette.common.draft.main,
      '&:hover': {
        backgroundColor: theme.palette.common.draft.main,
      },
      display: 'flex',
    },
    buttonIcon: {
      flex: 2,
      marginRight: theme.spacing(1),
    },
    buttonIconText: {
      flex: 6,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
    },
    padLeftUnit: {
      paddingLeft: theme.spacing(1),
    },
    padRightUnit: {
      paddingRight: theme.spacing(1),
    },
    currentUserName: {
      position: 'absolute',
      top: theme.spacing(0.6),
      color: '#00000059',
    },
    signatureBar: {
      transform: 'rotate(90deg) scaleX(-1)',
      width: '190px',
      position: 'absolute',
      marginLeft: theme.spacing(1) * -13,
      marginTop: theme.spacing(1) * -21.5,
      borderRadius: '5px',
    },
    sigError: {
      paddingLeft: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
    textInput: {
      maxHeight: 80,
      overflow: 'auto',
      background: theme.palette.common.input.background,
      padding: theme.spacing(1),
      borderRadius: '4px',
    },
    input: {
      ...theme.typography.body2,
    },
    link: {
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'none',
      },
    },
    textBoxCaption: {
      paddingTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: theme.spacing(1) * -1.5,
      marginLeft: theme.spacing(1) * -1.5,
    },
    boldText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    attachmentInput: {
      ...theme.typography.body2,
      minHeight: 36,
      width: '100%',
      margin: 0,
      background: theme.palette.common.input.background,
      border: `2px solid ${theme.palette.common.input.outline}`,
      borderRadius: 4,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
};
