import React from 'react';

import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';

import { Grid, Button } from '@material-ui/core';
import { Group, LocalOffer } from '@material-ui/icons';

import { Error } from '@material-ui/icons';
import { EllipsesMenuButton } from 'components';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: any) => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  buttonContainer: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlButton: {
    ...theme.buttonMixins.justIcon,
    margin: 0,
  },
  inactiveUserCase: {
    position: 'relative',
    display: 'inline-block',
  },
  exclamationMark: {
    color: '#FF8D8D',
    position: 'absolute',
    top: '-14px',
    right: '-6px',
    width: '1em',
    height: '1em',
  },
}));

function ItemControlButtons(props: {
  handleMoreClick: React.MouseEventHandler;
  handlePeopleClick: React.MouseEventHandler;
  handleTagsClick: React.MouseEventHandler;
  hasInactiveUser?: Boolean;
}) {
  const classes = useStyles();
  return (
    <I18n>
      {({ i18n }) => (
        <Grid className={classes.cardContent}>
          <div className={classes.buttonContainer} />
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              aria-haspopup="true"
              aria-label={i18n._(t`People Menu`)}
              classes={{ root: classes.controlButton }}
              onClick={props.handlePeopleClick}
              data-testid="people-menu-button"
            >
              {props.hasInactiveUser ? (
                <div className={classes.inactiveUserCase}>
                  <Group titleAccess={i18n._(t`People Menu`)} color="action" />{' '}
                  <div className={classes.exclamationMark}>
                    <Error />
                  </div>
                </div>
              ) : (
                <Group titleAccess={i18n._(t`People Menu`)} color="action" />
              )}
            </Button>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              aria-haspopup="true"
              aria-label={i18n._(t`Tags Menu`)}
              classes={{ root: classes.controlButton }}
              onClick={props.handleTagsClick}
            >
              <LocalOffer titleAccess={i18n._(t`Tags Menu`)} color="action" />
            </Button>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EllipsesMenuButton onClick={props.handleMoreClick} />
          </div>
        </Grid>
      )}
    </I18n>
  );
}

export default ItemControlButtons;
