import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import DesignServices from 'assets/icons/DesignServices';

import { ItemTypeDefinition } from '../index';
import PayloadFields from './PayloadFields';
import slugs from '../../displaySlugs';

const itemType = 'requirementsSet';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.setItemsCount',
    headerName: 'Members',
    type: 'short_text',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query requirementsSetsQuery {
    requirementsSets {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        setItemsCount
        id
        createdAt
        title
        versionIdentifier
        currentStatusName
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Requirements Set`,
  categoryLabel: t`Requirements Set`,
  icon: DesignServices,
  PayloadFields,
  listViewQuery,
  listViewColumns,
  hideNotes: true,
} as ItemTypeDefinition;
