import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    inlineToggle: {
      display: 'inline-flex',
      alignItems: 'center',
      '& > p': {
        display: 'inline',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      '& .MuiTooltip-root': {
        display: 'inline-flex',
      },
    },
    newRiskToggle: {
      borderRight: '1px solid rgba(235, 236, 237, 1)',
      borderLeft: '1px solid rgba(235, 236, 237, 1)',
      textAlign: 'center',
    },
  }),
);
