import React from 'react';

import { compose } from 'recompose';
import { i18n } from '@lingui/core';
import { Tooltip, withStyles } from '@material-ui/core';

import styles from './styles';

interface Props {
  classes: any;
  children: React.ReactElement;
  readOnly: boolean;
  text: string;
}

const READ_ONLY_TEXT = 'Upgraded license required to use this feature';

function ActionTooltip(props: Props) {
  const { classes, children, readOnly, text } = props;
  return (
    <Tooltip title={readOnly ? READ_ONLY_TEXT : i18n._(text)} classes={{ tooltip: classes.tooltip }}>
      {children}
    </Tooltip>
  );
}

export default compose(withStyles(styles, { withTheme: true }))(ActionTooltip);
