import React from 'react';
import { SupplierWorkflowStatuses } from 'workflows/statuses';
import { YesOrNo } from 'components';

const TO_STATUS = SupplierWorkflowStatuses.ReadyForPrequalification.id;

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => (
        <YesOrNo
          prompt={`Do you want to disable editing for Scope and Risk tab of ${props.itemCustomIdentifier}?`}
          closeOnNo
          yesLabel="Lock"
          noLabel="Cancel"
          {...props}
        />
      ),
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ confirmTransition }) => {
    return confirmTransition ? { toStatus: TO_STATUS } : null;
  },
};
