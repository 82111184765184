import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      padding: 0,
      paddingRight: theme.spacing(1.5),
      fontSize: theme.spacing(2.25),
      color: 'inherit',
    },
  }),
);
