import React from 'react';

import { DateTime } from 'luxon';
import { Trans } from '@lingui/macro';

import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
    attachment: {
        createdAt: string;
        creator: {
            fullName: string;
        }
    };
}

export default function UploadInfo(props: Props) {
    const classes = useStyles();

    const { attachment } = props;

    return !attachment ? null : (
        <Typography
            variant="body2"
            className={classes.uploadInfoLabel}
            style={{ textTransform: 'uppercase' }}
        >
            <span>Upload Information</span>{' '}
            <span>
                {DateTime.fromISO(attachment.createdAt).toLocaleString(DateTime.DATETIME_FULL)} <Trans>by</Trans>{' '}
                {attachment.creator.fullName}
            </span>
        </Typography>
    );
}
