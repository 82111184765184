import React, { useState } from 'react';
import { Fab, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';

import Archive from '@material-ui/icons/Archive';
import Assignment from '@material-ui/icons/Assignment';
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit';
import FindInPage from '@material-ui/icons/FindInPage';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import NotInterested from '@material-ui/icons/NotInterested';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import School from '@material-ui/icons/School';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import DeleteForever from '@material-ui/icons/DeleteForever';
import LockOpenFilled from 'assets/icons/LockOpenFilled';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import AutoRenew from '@material-ui/icons/Autorenew';
import DoneAll from '@material-ui/icons/DoneAll';
import PanTool from '@material-ui/icons/PanTool';
import BubbleChart from '@material-ui/icons/BubbleChart';
import History from '@material-ui/icons/History';

import { camelCase, get, isUndefined, startCase } from 'lodash';

import EffectiveDate from '../EffectiveDate';

import { buildConditionalClasses } from '../../utils';
import styles from './styles';
import StatusSignatureDialog from '../StatusSignatureDialog';

const SQUARE_STATUSES = ['archived'];

const icons = {
  active: ThumbUp,
  approvedDraft: Check,
  approvedRetirement: Check,
  approveToProceed: Assignment,
  archived: Archive,
  backlog: PlaylistAdd,
  canceled: DeleteForever,
  canceledDeviation: DeleteForever,
  checked: ThumbUp,
  cancel: DeleteForeverIcon,
  completed: Check,
  created: Edit,
  closed: ThumbUp,
  closedDeviation: ThumbUp,
  draft: Edit,
  effective: ThumbUp,
  failed: ThumbDown,
  identify: Edit,
  inactive: Clear,
  investigate: FindInPage,
  inProgress: FindInPage,
  locked: Lock,
  ownerApproval: Assignment,
  obsolete: NotInterested,
  planning: FindInPage,
  prepared: Assignment,
  readyForClosure: Assignment,
  readyForTraining: School,
  releasedForCoTraining: School,
  released: ThumbUp,
  rejected: Clear,
  retired: NotInterested,
  retirementInitiated: Assignment,
  retirementApproved: Check,
  retirementCanceled: DeleteForeverIcon,
  retirementRejected: Clear,
  semiLocked: LockOpenFilled,
  taskBacklog: PlaylistAdd,
  taskToDo: PlaylistAddCheck,
  taskInProgress: AutoRenew,
  taskDone: DoneAll,
  taskBlocked: PanTool,
  blockedAfterBacklog: PanTool,
  blockedAfterInProgress: PanTool,
  blockedAfterToDo: PanTool,
  blockedAfterDone: PanTool,
  taskWontDo: Archive,
  wontDoAfterBacklog: Archive,
  wontDoAfterInProgress: Archive,
  wontDoAfterToDo: Archive,
  wontDoAfterDone: Archive,
  toDo: Edit,
  done: DoneAll,
  underReview: FindInPage,
  readyForReview: FindInPage,
  unlocked: LockOpen,
  voidApprovedDraft: RemoveCircleIcon,
  voidOwnerApproval: RemoveCircleIcon,
  void: RemoveCircleIcon,
  supplierRequested: Edit,
  readyForPrequalification: FindInPage,
  prequalificationComplete: Assignment,
  readyForQualification: BubbleChart,
  qualificationComplete: Check,
  supplierApproved: ThumbUp,
  qualificationRejected: ThumbDown,
  qualificationRevoked: History,
};

const specificWorkflowStatuses = {
  GenericRecordWorkflow: {
    rejected: ThumbDown,
  },
};

export const themeColors = {
  active: 'released',
  approvedDraft: 'approvedDraft',
  approvedRetirement: 'approvedDraft',
  approveToProceed: 'ownerApproval',
  archived: 'archived',
  backlog: 'draft',
  cancel: 'canceled',
  canceledDeviation: 'canceled',
  checked: 'released',
  closed: 'released',
  closedDeviation: 'released',
  completed: 'underReview',
  created: 'draft',
  canceled: 'canceled',
  draft: 'draft',
  effective: 'released',
  failed: 'rejected',
  identify: 'draft',
  inactive: 'obsolete',
  investigate: 'underReview',
  inProgress: 'underReview',
  locked: 'ownerApproval',
  obsolete: 'obsolete',
  retired: 'obsolete',
  retirementInitiated: 'ownerApproval',
  retirementApproved: 'approvedDraft',
  retirementCanceled: 'canceled',
  retirementRejected: 'rejected',
  ownerApproval: 'ownerApproval',
  planning: 'draft',
  prepared: 'ownerApproval',
  readyForClosure: 'ownerApproval',
  readyForTraining: 'readyForTraining',
  releasedForCoTraining: 'releasedForCoTraining',
  released: 'released',
  rejected: 'rejected',
  semiLocked: 'underReview',
  taskBacklog: 'draft',
  taskToDo: 'underReview',
  taskInProgress: 'ownerApproval',
  taskDone: 'released',
  done: 'released',
  taskBlocked: 'rejected',
  taskWontDo: 'taskWontDo',
  wontDoAfterBacklog: 'canceled',
  blockedAfterBacklog: 'rejected',
  wontDoAfterToDo: 'canceled',
  blockedAfterToDo: 'rejected',
  wontDoAfterInProgress: 'canceled',
  blockedAfterInProgress: 'rejected',
  wontDoAfterDone: 'canceled',
  blockedAfterDone: 'rejected',
  toDo: 'draft',
  underReview: 'underReview',
  readyForReview: 'underReview',
  unlocked: 'draft',
  voidApprovedDraft: 'obsolete',
  voidOwnerApproval: 'obsolete',
  void: 'obsolete',
  supplierRequested: 'draft',
  readyForPrequalification: 'underReview',
  prequalificationComplete: 'ownerApproval',
  readyForQualification: 'underReview',
  qualificationComplete: 'ownerApproval',
  supplierApproved: 'released',
  qualificationRejected: 'rejected',
  qualificationRevoked: 'qualificationRevoked',
};

interface WorkflowIconProps {
  active?: boolean;
  classes: any;
  completed?: boolean;
  disabled?: boolean;
  disabledEffect?: boolean;
  displayTooltip?: boolean;
  effectiveDate?: Date;
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
  label?: string;
  showLabel?: boolean;
  theme: Theme; // materialui theme
  type: string;
  version?: any;
  size: 'tiny' | 'small' | 'medium' | 'large';
  visible?: boolean;
  wideLabel?: boolean;
  workflowType?: string;
}

function WorkflowIcon(props: WorkflowIconProps) {
  const {
    active = false,
    classes,
    completed = false,
    disabled = false,
    disabledEffect = false,
    effectiveDate,
    handleClick,
    label,
    showLabel = true,
    theme,
    type,
    version,
    size,
    visible = true,
    displayTooltip = false,
    wideLabel = false,
    workflowType,
  } = props;
  const [signDialogOpen, setSignDialogOpen] = useState(false);
  if (!visible) return null;

  const displayType = camelCase(type);
  if (!displayType) return null;
  const Icon = get(specificWorkflowStatuses, `${workflowType}.${displayType}`, icons[displayType]);
  const themeColorKey = themeColors[displayType];
  if (!Icon || !themeColorKey) {
    throw new Error(`Invalid status passed to WorkflowIcon: ${displayType}`);
  }

  const versionIdentifier = !isUndefined(version)
    ? typeof version === 'string'
      ? version
      : version.versionIdentifier
    : '';

  const baseColor = theme.palette.common[themeColorKey];
  const displayColor = disabled ? baseColor.light : baseColor.main;
  const contrastColor = theme.palette.primary.contrastText;

  const statusName = startCase(type);

  const openSignatureDialog = () => {
    setSignDialogOpen(true);
  };

  return (
    <div
      className={buildConditionalClasses([
        [true, classes.iconContainer],
        [wideLabel, classes.fixedHeightIconContainer],
      ])}
    >
      <Tooltip
        title={statusName}
        classes={{
          tooltip: `${!displayTooltip && classes.hidden} ${classes.tooltip}`,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Fab
            className={buildConditionalClasses([
              [true, classes.largeBtn],
              [active && !disabledEffect, classes.active],
              [!active && !completed, classes.empty],
              [disabledEffect, classes.disabled],
              [size === 'small', classes.sizeSmall],
              [size === 'tiny', classes.sizeTiny],
              [SQUARE_STATUSES.includes(type), classes.squareIcon],
            ])}
            style={{
              border: `2px solid ${displayColor}`,
              backgroundColor: displayColor,
              color: completed && !disabledEffect ? contrastColor : displayColor,
              // @ts-ignore
              '&:hover': {
                // @ts-ignore
                backgroundColor: displayColor,
              },
              height: 64,
              width: 64,
            }}
            disabled={disabled}
            variant="round"
            size={get({ tiny: 'small' }, size, size)}
            id={`workflow-${type}`}
            onClick={(e) => {
              if (handleClick && !disabled) {
                handleClick(e);
              } else if (version && version.isWorkflowSignatureShown) {
                openSignatureDialog();
              }
            }}
            disableRipple
            disableTouchRipple
          >
            <Icon
              className={buildConditionalClasses([
                [size !== 'tiny' && size !== 'small', classes.statusIcon],
                [size === 'tiny', classes.statusIconTiny],
                [size === 'small', classes.statusIconSmall],
              ])}
            />
          </Fab>
        </div>
      </Tooltip>
      <Typography
        variant="button"
        style={{ color: displayColor }}
        className={buildConditionalClasses([
          [true, classes.textOverride],
          [!showLabel, classes.hidden],
          [wideLabel, classes.wideLabel],
        ])}
      >
        <span>
          {versionIdentifier && <div>{versionIdentifier}</div>}
          <div>{label}</div>
          <div className={classes.calendarContainer}>
            {effectiveDate ? (
              <EffectiveDate timestamps={[{ releasedAt: effectiveDate }]} />
            ) : (
              type === 'released' && versionIdentifier && <EffectiveDate />
            )}
          </div>
        </span>
      </Typography>

      <StatusSignatureDialog
        version={version}
        Icon={
          <Fab
            className={buildConditionalClasses([
              [true, classes.largeBtn],
              [true, classes.filled],
              [true, classes.sizeSmall],
            ])}
            style={{
              border: `2px solid ${displayColor}`,
              backgroundColor: displayColor,
              color: completed && !disabledEffect ? contrastColor : displayColor,
              // @ts-ignore
              '&:hover': {
                // @ts-ignore
                backgroundColor: displayColor,
              },
              height: 64,
              width: 64,
            }}
            disabled={disabled}
            variant="round"
            size={'small'}
            id={`workflow-${type}`}
            disableRipple
            disableTouchRipple
          >
            <Icon
              className={buildConditionalClasses([
                [size !== 'tiny' && size !== 'small', classes.statusIcon],
                [true, classes.statusIconSmall],
              ])}
            />
          </Fab>
        }
        onClose={() => setSignDialogOpen(false)}
        status={statusName}
        open={signDialogOpen}
      />
    </div>
  );
}

export { icons };
export default withStyles(styles, { withTheme: true })(WorkflowIcon);
