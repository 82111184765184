function getInactiveUsers(owner, approver) {
  if (approver && approver.status === 'inactive') {
    return [{ user: { id: approver.id, fullName: approver.fullName }, role: 'approver' }];
  } else if (owner && owner.status === 'inactive') {
    return [{ user: { id: owner.id, fullName: owner.fullName }, role: 'owner' }];
  }

  return [];
}

export default getInactiveUsers;
