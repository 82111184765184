import React from 'react';
import snakeCase from 'lodash/snakeCase';
import { Trans } from '@lingui/macro';

import { Grid, withStyles } from '@material-ui/core';

import AgGridTable from 'components/AgGridTable';
import { Breadcrumbs, ExportMenuButton, ItemTitle, Loading } from 'components';
import NewItem from 'compositions/NewItem';
import styles from './styles';

const exportable = (itemType) => {
  const exportableItemTypes = ['userNeed', 'requirement', 'riskControl'];

  return exportableItemTypes.includes(itemType);
};

function TableView({
  gridRef,
  classes,
  data,
  itemType,
  loading,
  listViewColumns,
  DisplayVariantSelect,
  handleColumnRowGroupChanged,
  categoryLabel,
  categorySlug,
  path,
  showNewItemBtn = true,
  showItemTypeInfo = true,
  groupDefaultExpanded,
  DisciplineToggle,
  leafField,
}) {
  if (loading) return <Loading />;
  const titleText = <Trans id={categoryLabel} />;

  return (
    <Grid container className={classes.container}>
      {showItemTypeInfo && (
        <div className={classes.pageTitleInfoContainer}>
          <Grid item xs={12} className={classes.slimRow}>
            <Breadcrumbs categorySlug={categorySlug} />
          </Grid>
        </div>
      )}
      <Grid item className={classes.tableContainer}>
        <div className={classes.agGridTableContainer}>
          <AgGridTable
            gridRef={gridRef}
            columns={listViewColumns}
            data={data}
            path={path}
            categorySlug={categorySlug}
            DisplayVariantSelect={DisplayVariantSelect}
            handleColumnRowGroupChanged={handleColumnRowGroupChanged}
            groupDefaultExpanded={groupDefaultExpanded}
            DisciplineToggle={DisciplineToggle}
            leafField={leafField}
            TableTitle={() => (
              <ItemTitle
                titleEditable={false}
                displayTitle={false}
                editable={false}
                titlePrefix={titleText}
                classes={{ typography: classes.itemTitle }}
              />
            )}
            {...showNewItemBtn && {
              NewItemButton: () => <NewItem color="#6e7583" fontSize="24px" defaultSelectedItemType={itemType} />,
            }}
            {...exportable(itemType) && {
              ExportMenuButton: () => <ExportMenuButton itemType={snakeCase(itemType)} />,
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(TableView);
