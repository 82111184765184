const styles = (theme) => ({
  button: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    marginLeft: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '&:active': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },
  },
});

export default styles;
