import React from 'react';
import get from 'lodash/get';
import { getLayoutComponent } from 'utils/pageUtils';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { Views } from 'workspaces';

import BuilderView from 'components/BuilderView';
import EffectiveView from './EffectiveView';

import VersionsView from 'components/VersionsView';
import PayloadFields from './PayloadFields';
import useItemType from 'pages/generic/useItemType';
import { DocumentToolbar } from 'itemTypes/mixins/Document';

const ITEM_TYPE = 'supplierQuestionnaire';
const SLUG = 'supplier-questionnaire'; // not perfect, you know
const REFETCH = 'supplierQuestionnaireListViewQuery';
const RECORD_ITEM_TYPE = 'supplierQuestionnaireRecord';

function RecordPage(props) {
  const itemType = useItemType(ITEM_TYPE);

  switch (props.activeWorkspaceView.id) {
    case Views.Builder.id:
      return (
        <BuilderView itemId={props.itemId} itemType={ITEM_TYPE} PayloadFields={PayloadFields} categorySlug={SLUG} />
      );
    case Views.Effective.id:
      return (
        <EffectiveView
          itemId={props.itemId}
          itemTypeName={ITEM_TYPE}
          listViewQueryToRefetch={REFETCH}
          PayloadFields={PayloadFields}
          categorySlug={SLUG}
          EffectiveViewOptions={DocumentToolbar}
          formBasedRecordItemTypeName={RECORD_ITEM_TYPE}
        />
      );
    case Views.Versions.id:
      return (
        <VersionsView
          categorySlug={SLUG}
          itemType={ITEM_TYPE}
          itemId={props.itemId}
          PayloadFields={PayloadFields}
          VersionsViewOptions={get(itemType, 'EffectiveViewOptions')}
        />
      );
    default:
      const LayoutComponent = getLayoutComponent(props.activeWorkspaceView.id);
      return (
        // itemType here - not totally tested
        <LayoutComponent {...props} itemType={itemType} displayHistoryInEffectiveView PayloadFields={PayloadFields} />
      );
  }
}

export default withWorkspaceViews()(RecordPage);
