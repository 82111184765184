import React from 'react';

import { Grid } from '@material-ui/core';
import Business from '@material-ui/icons/Business';

import { useMutation } from 'react-apollo';

import { SingleItemSelect } from 'components';
import Panel from 'components/core/Panel';
import SectionLabel from 'components/core/SectionLabel';
import { withLockedContext } from 'contexts/lockedContext';

import { CYBERSECUIRTY_PLAN_OPTIONS_QUERY, SET_CYBERSECURITY_PLAN_ID } from '../gql';

interface Props {
  currentVersion: any;
}

function AssociatedCybersecurityPlanCard(props: Props) {
  const [setCybersecurityPlanId] = useMutation(SET_CYBERSECURITY_PLAN_ID, {
    refetchQueries: ['CybersecurityReportVersionQuery'],
  });

  const updateCybersecurityReport = (cybersecurityPlan) => {
    setCybersecurityPlanId({
      variables: {
        id: props.currentVersion.id,
        cybersecurityPlanId: cybersecurityPlan ? cybersecurityPlan.currentVersion.id : null,
      },
    }).catch((err) => {
      throw new Error(`Error refetching Associated cybersecurity report version: ${err}`);
    });
  };

  return (
    <Grid item xs={5}>
      <Panel>
        <SectionLabel icon={Business}>Associated Cybersecurity Plan</SectionLabel>
        <SingleItemSelect
          value={props.currentVersion.cybersecurityPlanVersion}
          disabled={false}
          onChange={updateCybersecurityReport}
          placeholder="Select Cybersecurity Plan"
          options={{
            query: CYBERSECUIRTY_PLAN_OPTIONS_QUERY,
            optionsPath: 'cybersecurityPlans',
          }}
        />
      </Panel>
    </Grid>
  );
}

export default withLockedContext(AssociatedCybersecurityPlanCard);
