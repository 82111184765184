import gql from 'graphql-tag';

export const ARCHIVE_JOB_ROLE = gql`
  mutation ArchiveJobRole($jobRoleId: ID!) {
    archiveJobRole(jobRoleId: $jobRoleId) {
      jobRole {
        id
      }
    }
  }
`;
