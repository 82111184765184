import React, { useState } from 'react';
import { useMutation } from 'react-apollo';

import { LinearProgress, Typography } from '@material-ui/core';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

import { ReasonForChangeAndEsig, Spacer } from 'components';
import BaseDialog from 'components/BaseDialog';
import { DEACTIVATE_USER, CREATE_USER_DEACTIVATED_EMAIL } from './gql';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userId: string | null;
  userFullName: string;
  trainingPlan: any;
  trainingRecords: any;
}

function ConfirmUserDeactivationModal(props: Props) {
  const classes = useStyles();
  const { isOpen, onClose, userId, userFullName, trainingPlan, trainingRecords } = props;

  const [reasonForDeactivationText, setReasonForDeactivationText] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onUserDeactivationCompleted = () => {
    onClose();
    setReasonForDeactivationText('');
    setUsername('');
    setPassword('');
  };

  const [deactivateUser, { loading, error }] = useMutation(DEACTIVATE_USER, {
    refetchQueries: ['UserListQuery', 'withCurrentCompany_currentCompanyQuery'],
    onCompleted: async () => {
      await createUserDeactivatedEmail({
        variables: {
          input: {
            userId: userId,
          },
        },
      });
      onUserDeactivationCompleted();
    },
  });
  if (error) throw new Error(`Error deactivating user: ${error}`);

  const [createUserDeactivatedEmail] = useMutation(CREATE_USER_DEACTIVATED_EMAIL);

  const handleSubmit = () => {
    deactivateUser({
      variables: {
        userId: userId,
        reasonForChange: reasonForDeactivationText,
        username: username,
        password: password,
      },
    }).catch((err) => {
      throw new Error(`Error while deactivating user: ${err}`);
    });
  };

  const formCanSubmit = () => reasonForDeactivationText.length && username.length && password.length;

  return (
    <BaseDialog
      open={isOpen}
      title="Confirm User Deactivation"
      Icon={RemoveCircle}
      size="xlarge"
      onClose={onClose}
      textAlign="left"
    >
      <Typography variant="body2">
        <div>
          <Spacer factor={2} />
          <span style={{ fontWeight: 600 }}>{userFullName}</span> will be deactivated.
          {trainingPlan && (
            <span>
              <Spacer factor={2} />
              Their Training Plan{' '}
              <span style={{ fontWeight: 600 }}>
                {trainingPlan.item.customIdentifier}: {trainingPlan.title}
              </span>{' '}
              will be archived.
            </span>
          )}
        </div>
        {trainingRecords.length !== 0 && (
          <>
            <Spacer factor={2} />
            The following open training records will be nullified:
            <Spacer factor={2} />
            {trainingRecords.map((trainingRecord) => {
              return (
                <div style={{ marginLeft: '-10px' }}>
                  <div className={classes.itemList}>
                    <LinearProgress className={classes.verticalBar} color="primary" variant="determinate" value={100} />
                    <span style={{ fontWeight: 600 }}>
                      {trainingRecord.item.customIdentifier}: {trainingRecord.title}
                    </span>
                  </div>
                </div>
              );
            })}
            <Spacer factor={2} />
          </>
        )}
      </Typography>
      <Spacer factor={4} />
      <ReasonForChangeAndEsig
        textboxHeading={`Reason for Deactivation`}
        textboxId={`reason-for-deactivation-input`}
        onTextChange={(e) => setReasonForDeactivationText(e.target.value)}
        usernameInputId={`confirm-deactiviation-username-input`}
        onUsernameChange={(e) => setUsername(e.target.value)}
        passwordInputId={`confirm-deactivation-password-input`}
        onPasswordChange={(e) => setPassword(e.target.value)}
        submitDisabled={!formCanSubmit || loading}
        onSubmitClick={handleSubmit}
        submitLoading={loading}
      />
    </BaseDialog>
  );
}

export default ConfirmUserDeactivationModal;
