import gql from 'graphql-tag';

import { useQuery } from 'react-apollo';
import { TRIO_FIELDS } from 'components/TableTrio/gql';

import { docStylePayloadFields } from '../../../itemTypes/mixins/DocumentBased/gql';

const versionQuery = gql`
  query SupplierAuditReportVersionQuery($id: ID!) {
    supplierAuditReportVersion(id: $id) {
      supplierPassedAudit,
      ${docStylePayloadFields}
      ${TRIO_FIELDS}
      supplierAuditPlanVersion {
        id
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
        }
        supplierVersion {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            customIdentifier
            id
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
    }
  }
`;

export const SUPPLIER_AUDIT_PLAN_OPTIONS_QUERY = gql`
  query SupplierAuditPlanOptionsQuery {
    supplierAuditPlans {
      id
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        inputMethod
        body
        versionIdentifier
        currentStatusName
      }
    }
  }
`;

export const TOGGLE_SUPPLIER_PASSED_AUDIT = gql`
  mutation ToggleSupplierPassedAudit($id: ID!, $toggle: Boolean) {
    updateSupplierAuditReport(id: $id, supplierPassedAudit: $toggle) {
      supplierAuditReport {
        id
      }
    }
  }
`;

export const SET_SUPPLIER_AUDIT_PLAN_ID = gql`
  mutation SetSupplierAuditPlanId($id: ID!, $supplierAuditPlanId: ID) {
    updateSupplierAuditReport(id: $id, supplierAuditPlanVersionId: $supplierAuditPlanId) {
      supplierAuditReport {
        id
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery(versionQuery, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}
