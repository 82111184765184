import React from 'react';

import { TextArea as TextAreaComponent } from 'components';
import { Form } from '../useForm';
import { useStyles } from './styles';

interface Props {
  attr: string
  placeholder?: string
  form: Form<any>
  toText?: boolean
}

export function TextArea<T extends Props>(props: T) {
  let { attr, placeholder, form, toText, ...rest } = props

  const classes = useStyles()

  const disabled = !form.canEdit
  const value = form.editedData[attr]
  const update = value => form.edit(attr, value)

  const passClasses = toText ? {
    root: classes.textLook
  } : null
  if (toText) placeholder = undefined
  if (passClasses) rest = { ...rest, otherClasses: passClasses }

  return <TextAreaComponent
    initialValue={value}
    placeholder={placeholder}
    variant="outlined"
    margin="normal"
    fullWidth
    multiline
    rows="4"
    onTextChange={update}
    disabled={disabled}
    {...rest}
  />
}
