import gql from 'graphql-tag';

export const MEMBER_TRACING_QUERY = gql`
  query MemberTracingQuery($itemId: ID!) {
    item(id: $itemId) {
      downstreamItems {
        customIdentifier
        currentVersion {
          virtualAttributes(attrName: "title") {
            stringValue
          }
        }
      }
      upstreamItems {
        customIdentifier
        currentVersion {
          virtualAttributes(attrName: "title") {
            stringValue
          }
        }
      }
    }
  }
`;
