import React from 'react';

import { Typography } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';

import { DocumentBuilder } from 'compositions';

interface Props {
  layoutClasses: { [className: string]: string };
  currentVersion: any;
  locked: boolean;
  versionId: string;
  itemType: string;
}

export default function PlanTab(props: Props) {
  const { currentVersion, layoutClasses, itemType } = props;

  return (
    <>
      <Typography variant='subtitle1'>
        <Menu />
        Audit Plan
      </Typography>
      <DocumentBuilder
        Heading={undefined}
        itemType={itemType}
        refetchQueries={['SupplierAuditPlanVersionQuery']}
        richTextOptionSet="large"
        layoutClasses={layoutClasses}
        versionData={currentVersion}
      />
    </>
  );
}
