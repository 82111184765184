import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      width: 24,
      flexDirection: 'column',
    },
    edge: {
      flex: 1,
    },
  }),
);
