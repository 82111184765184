import React from 'react';

import { clone } from 'lodash';

import { CardActions } from '@material-ui/core';

import { VersionAndStatusDot, VersionSwitch } from 'components';

import { GraphCard } from 'components';

function Version(props) {
  const { otherTraces, version } = props;

  // VersionSwitch requires currentStatusName which is deprecated.
  // We cannot remove it from other places that use VersionSwitch without causing unknown failures
  // so for now we just assign this property to each version
  let currentRelease;
  if (props.currentRelease) {
    currentRelease = clone(props.currentRelease);
    currentRelease.currentStatusName = currentRelease.currentStatus.name;
  }

  let workingVersion;
  if (props.workingVersion) {
    workingVersion = clone(props.workingVersion);
    workingVersion.currentStatusName = workingVersion.currentStatus.name;
  }

  return (
    <GraphCard
      item={{
        customIdentifier: version.item.customIdentifier,
        id: version.item.id,
        itemType: version.item.itemType.name,
        title: version.title,
      }}
      otherConnections={otherTraces}
      selected={props.selected}
      summary={version.stringified}
    >
      <CardActions style={{ justifyContent: 'flex-end' }}>
        {!props.disabled && workingVersion ? (
          <VersionSwitch
            style={{ fontSize: 13 }}
            value={version.id}
            currentRelease={currentRelease}
            workingVersion={workingVersion}
            onToggle={props.onUpdate}
          />
        ) : (
          <VersionAndStatusDot
            style={{ fontSize: 13 }}
            version={version.versionIdentifier}
            status={version.currentStatus.name}
          />
        )}
      </CardActions>
    </GraphCard>
  );
}

export default Version;
