import React, { useContext, useEffect } from 'react';

import { Domain } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

import { useMutation } from 'react-apollo';

import { SingleItemSelect } from 'components';
import SectionLabel from 'components/core/SectionLabel';
import Panel from 'components/core/Panel';
import { LockedContext, withLockedContext } from 'contexts/lockedContext';
import { PayloadFieldsProps } from 'itemTypes';

import { InputMethod } from '__generated__/globalTypes';
import QuestionnaireInformation from './QuestionnaireInformation';

import { ADD_ASSOCIATED_SUPPLIER, SUPPLIER_OPTIONS_QUERY, useVersion } from './gql';
import { SupplierQuestionnaireRecordVersion_supplierQuestionnaireRecordVersion as SupplierQuestionnaireRecordVersion } from './__generated__/SupplierQuestionnaireRecordVersion';

interface Props {
  inputMethod: InputMethod;
}

function PayloadFields(props: Props & PayloadFieldsProps) {
  const { inputMethod, layoutClasses } = props;
  const { data, loading, error, refetch } = useVersion(props.versionId || '');
  const { setLocked, locked } = useContext(LockedContext);

  const [addAssociatedSupplier] = useMutation(ADD_ASSOCIATED_SUPPLIER, {
    refetchQueries: ['SupplierQuestionnaireRecordVersion'],
  });

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching Supplier: ${err}`);
    });
  }, [inputMethod, refetch]);

  useEffect(() => {
    if (data) setLocked(data.supplierQuestionnaireRecordVersion!.locked); //FIXME: Object is possibly 'null'
  }, [data, setLocked]);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Questionnaire Record ${props.versionId}\n${error}`);

  const currentVersion = data.supplierQuestionnaireRecordVersion;

  const updateAssociatedSupplier = (supplierItem) => {
    addAssociatedSupplier({
      variables: {
        associatedSupplierId: supplierItem ? supplierItem.currentVersion.id : null,
        id: currentVersion!.id,
      },
    }).catch((err) => {
      throw new Error(`Error refetching Associated supplier audit plan version: ${err}`);
    });
  };

  return (
    <>
      <Grid container spacing={2} item>
        <Grid item xs={5}>
          <Panel>
            <SectionLabel icon={Domain}>Associated Supplier</SectionLabel>
            <div>
              <SingleItemSelect
                value={currentVersion!.supplierVersion}
                disabled={locked}
                onChange={updateAssociatedSupplier}
                placeholder="Select Associated Supplier"
                options={{
                  query: SUPPLIER_OPTIONS_QUERY,
                  optionsPath: 'suppliers',
                  filter: (options: any[]) =>
                    options.filter(
                      (option: any) =>
                        option.currentVersion.plannedQualificationQuestionnaire &&
                        option.currentVersion.currentStatusName !== 'qualification_revoked',
                    ),
                }}
              />
            </div>
          </Panel>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <SectionLabel icon={Domain}>Questionnaire Information</SectionLabel>
        </Grid>
        <QuestionnaireInformation
          refetch={refetch}
          layoutClasses={layoutClasses}
          currentVersion={currentVersion as SupplierQuestionnaireRecordVersion}
        />
      </Grid>
    </>
  );
}

export default withLockedContext(PayloadFields);
