import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    label: {
      fontSize: '13px',
      fontWeight: theme.typography.fontWeightBold,
      marginRight: '11px',
    },
    setSection: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '15px',
    },
    parentContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      padding: '5px 0',
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  });
