import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import { Button, Modal, Paper, Tooltip, createStyles, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { ItemCreatedSnackbar, YesOrNo } from 'components';
import { getBaseApiUrl } from 'utils';

const styles = createStyles((theme) => ({
  closeIcon: {
    float: 'right',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modalContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 322,
    padding: theme.spacing(2),
  },
}));

const mutateRecord = gql`
  mutation createFormBasedRecord($versionId: ID!, $productId: ID!, $title: String!, $host: String!) {
    createFormBasedRecord(versionId: $versionId, productId: $productId, title: $title, host: $host) {
      item {
        id
        customIdentifier
      }
    }
  }
`;

function CreateFormBasedRecordModal({ classes, open, onClose, handleComplete, formIdentifier }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper classes={{ root: classes.modalContainer }}>
        <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={onClose} color="action" />
        <YesOrNo
          heading={`Would you like to assign ${formIdentifier} to a new incoming quality inspection record and export it for completion?`}
          yesLabel="Create"
          noLabel="Cancel"
          closeOnNo
          handleComplete={handleComplete}
          handleClose={onClose}
          disableOnSubmit
        />
      </Paper>
    </Modal>
  );
}

interface CreateFormBasedRecordProps {
  versionId: string;
  refetchQueries;
  classes;
  formIdentifier: string;
  itemTypeName: string;
  currentUser: {
    id: string;
    readOnly: boolean;
  };
}
function CreateFormBasedRecord(props: CreateFormBasedRecordProps) {
  const [showCreateRecordModal, setShowCreateRecordModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [customIdentifier, setCustomIdentifier] = useState('');
  const [showItemCreatedSnackbar, setShowItemCreatedSnackbar] = useState(false);
  const [createRecord] = useMutation(mutateRecord);

  const onCreateRecord = (data) => {
    const { id, customIdentifier } = data.createFormBasedRecord.item;
    setItemId(id);
    setCustomIdentifier(customIdentifier);
  };

  const base = new URL('exports.pdf', getBaseApiUrl() || window.location.origin);
  base.searchParams.append('id', props.versionId);
  base.searchParams.append('source', window.location.toString());
  base.searchParams.append('disposition', 'attachment');

  return (
    <>
      <Tooltip title={props.currentUser.readOnly ? 'Upgraded license required to use this feature' : ''}>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowCreateRecordModal(!showCreateRecordModal)}
            disabled={props.currentUser.readOnly}
          >
            Use This Form
          </Button>
        </div>
      </Tooltip>
      <CreateFormBasedRecordModal
        classes={props.classes}
        open={showCreateRecordModal}
        onClose={() => setShowCreateRecordModal(false)}
        handleComplete={() => {
          createRecord({
            variables: {
              versionId: props.versionId,
              productId: 1, //FIXME: this needs to accommodate additional products in the future
              title: 'Default Title',
              host: base,
            },
            refetchQueries: props.refetchQueries,
            awaitRefetchQueries: true,
            update: (_cache, { data }) => {
              onCreateRecord(data);
              setShowItemCreatedSnackbar(true);
            },
          }).catch((e) => {
            throw new Error(`Error ocurred while creating record item: ${e}`);
          });
          setShowCreateRecordModal(false);
          setItemId(null);
          setCustomIdentifier('');
        }}
        formIdentifier={props.formIdentifier}
      />
      <ItemCreatedSnackbar
        open={showItemCreatedSnackbar}
        handleSnackbarClose={() => setShowItemCreatedSnackbar(false)}
        customIdentifier={customIdentifier}
        itemId={itemId}
        selectedItemType={props.itemTypeName}
      />
    </>
  );
}

export default withStyles(styles)(CreateFormBasedRecord);
