import React, { useState } from 'react';

import { Link, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import BaseDialog from 'components/BaseDialog';
import Button from 'components/Button';
import Spacer from 'components/Spacer';

import { useTaskToggle } from '../hooks';

import { useStyles } from './styles';

interface Props {
  task;
}

function GetStarted(props: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { initiate } = useTaskToggle(props.task.id);

  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
        Get Started
      </Button>
      <BaseDialog
        open={open}
        title={props.task.additionalTitle || props.task.title}
        onClose={() => setOpen(false)}
        size="xlarge"
        textAlign="left"
      >
        <div>
          <Typography variant="body1" className={classes.content}>
            {props.task.additionalContent}
          </Typography>
          <Spacer factor={3} />
          {props.task.helpUrl && (
            <Link
              href={props.task.helpUrl}
              target="_blank"
              rel="noopener"
              className={classes.helpDeskDisclaimer}
              color="primary"
              variant="subtitle2"
            >
              <HelpOutlineIcon fontSize="small" />
              <span>Read the full Help Desk Guide</span>
            </Link>
          )}
          <Spacer factor={2} />
          <Link href={props.task.reference} target="_blank" rel="noopener">
            <Button variant="contained" color="primary" fullWidth onClick={initiate}>
              Let's Go!
            </Button>
          </Link>
        </div>
      </BaseDialog>
    </>
  );
}

export default GetStarted;
