import React, { FC } from 'react';

import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { channels } from '../contants';

const ChannelTags: FC = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      {channels.map(({ key, icon: Icon, label }) => (
        <Box key={key} display="flex" alignItems="center" flexDirection="column" mr={3}>
          <Icon className={classes.channelTagIcon} />
          <Typography variant="subtitle1" className={classes.channelTagLabel}>
            {label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ChannelTags;
