import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useQuery } from 'react-apollo';

import MakeLink from 'components/MakeLink';
import SectionLabel from 'components/core/SectionLabel';
import LibraryAddCheck from 'assets/icons/LibraryAddCheck';
import AgGridOverlay from 'components/AgGridTable/Overlay';

import { currentUsersTaskQuery } from './gql';
import { useStyles } from './styles';
import AgGridTable from 'components/AgGridTable';

const columns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
    suppressMenu: true,
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
    suppressMenu: true,
    flex: 1,
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
    suppressMenu: true,
  },
  {
    field: 'currentVersion.dueDate',
    headerName: 'Due Date',
    type: 'date',
    suppressMenu: true,
  },
];

function CurrentUsersTaskList() {
  const { data, loading, error } = useQuery(currentUsersTaskQuery);
  const classes = useStyles();

  if (loading) return <div />;
  if (error) throw new Error(`Error loading pre compliance task categories: ${error}`);

  return (
    <Paper elevation={1}>
      <div className={classes.content}>
        <SectionLabel icon={LibraryAddCheck}>
          <Typography
            variant="h3"
            component="span"
            style={{ fontSize: '18px', fontWeight: 400, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
          >
            My Tasks
          </Typography>
        </SectionLabel>
        <MakeLink className={classes.cornerLink} href="/category/task" params={{ view: 'Table' }}>
          View All Tasks
        </MakeLink>
        <AgGridTable
          columns={columns}
          data={data.tasks}
          suppressSideBar={true}
          suppressViewOptions={true}
          noRowsOverlayComponent={AgGridOverlay}
          noRowsOverlayComponentParams={{ text: 'All assigned tasks are complete' }}
        />
      </div>
    </Paper>
  );
}

export default CurrentUsersTaskList;
