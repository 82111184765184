import React from 'react';
import { VerticalAlignBottom } from '@material-ui/icons';

import { useStyles } from './styles';
import fileNameFromURL from 'utils/fileNameFromURL';

function AttachmentDownload({ url }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <span className={classes.fileName}>{fileNameFromURL(url)}</span>
      <div>
        <VerticalAlignBottom style={{ cursor: 'pointer' }} onClick={() => window.open(url)} />
      </div>
    </div>
  );
}

export default AttachmentDownload;
