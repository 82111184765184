import React from 'react';

import { Layout } from 'components';

import { ForgotUsernameForm } from 'compositions';

export interface ForgotUsernameProps {
  path: string;
  reloadApp: () => void;
}

const ForgotUsername: React.FC<ForgotUsernameProps> = () => {
  return (
    <Layout variant="unauthorized">
      <ForgotUsernameForm />
    </Layout>
  );
};

export default ForgotUsername;
