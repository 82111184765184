import React from 'react';
import { camelCase } from 'lodash';

import { ItemTypeIndex } from 'itemTypes';

import { BaseSnackbar } from 'components';

import { navigate } from '@reach/router';

function ItemCreatedSnackbar({ handleSnackbarClose, open, customIdentifier, itemId, selectedItemType }) {
  return (
    <BaseSnackbar
      actionText="View Item"
      actionFunction={() => {
        const itemUrl = `/category/${ItemTypeIndex[camelCase(selectedItemType)].displaySlug}/${itemId}`;

        navigate(itemUrl).catch((err) => {
          throw err;
        });
      }}
      message={`Item ${customIdentifier} was created.`}
      onClose={handleSnackbarClose}
      open={open}
    />
  );
}

export default ItemCreatedSnackbar;
