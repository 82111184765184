import React, { ComponentType, CSSProperties, ReactNode } from 'react';

import { Box, SvgIconProps, Typography, Paper } from '@material-ui/core';

import { useStyles } from './styles';

export interface RiskFieldSectionProps {
  id?: string;
  children: ReactNode | ReactNode[];
  style?: CSSProperties | undefined;
}

export interface RiskFieldHeadingProps {
  Icon: ComponentType<SvgIconProps>;
  children: ReactNode;
  style?: CSSProperties | undefined;
}

const RiskFieldHeading = (props: RiskFieldHeadingProps) => {
  const classes = useStyles();
  const { Icon, children } = props;

  return (
    <Typography variant={'subtitle1'} className={classes.heading} style={props.style}>
      <Icon /> {children}
    </Typography>
  );
};

const RiskFieldSection = (props: RiskFieldSectionProps) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Paper className={classes.paper} variant={'outlined'} id={props.id}>
      <Box className={classes.box} style={props.style}>
        {children}
      </Box>
    </Paper>
  );
};

RiskFieldSection.Heading = RiskFieldHeading;

export default RiskFieldSection;
