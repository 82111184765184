import { AllItemTypes } from 'utils/gql/__generated__/AllItemTypes';
import { useQuery } from 'react-apollo';

import { allItemTypesQuery } from '../utils/gql/metadataQueries';

export function useAllItemTypes() {
  const { data, loading, error } = useQuery<AllItemTypes>(allItemTypesQuery);
  return {
    data,
    loading,
    error,
    findTypeByName: (name: string) => {
      const found = data && data.itemTypes.find((itemType) => itemType.name === name);
      if (!found) throw new Error(`${name} doesn't exist`);
      return found;
    },
  };
}
