import React, { MouseEventHandler } from 'react';
import { Tooltip, SvgIconProps, IconButton as MuiIconButton } from '@material-ui/core';
import { ComponentType } from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

interface Props {
  includeBackground?: boolean;
  themeColor?: boolean;
  tooltip?: string;
  Icon: ComponentType<SvgIconProps>;
  onClick: MouseEventHandler<HTMLButtonElement>;
  size?: 'small' | 'medium' | 'large' | 'x-large';
  disabled?: boolean;
}

function IconButton(props: Props) {
  const classes = useStyles();
  const sizeClass = () => classes[props.size || 'medium'];
  return (
    <Tooltip title={props.tooltip ? props.tooltip : ''}>
      <MuiIconButton disabled={props.disabled} classes={{ root: classes.root }} onClick={props.onClick}>
        <props.Icon
          className={clsx(
            props.includeBackground && classes.iconBackground,
            clsx((props.themeColor && !props.disabled) && classes.themeColor),
            classes.icon,
            sizeClass(),
          )}
        />
      </MuiIconButton>
    </Tooltip>
  );
}

export default IconButton;
