import { t } from '@lingui/macro';
import gql from 'graphql-tag';

import Group from '@material-ui/icons/Group';

import { buildRecordQuery } from 'utils/gql/itemRecordQuery';
import slugs from '../../displaySlugs';
import Views from 'workspaces/views';
import PayloadFields from './PayloadFields';

const itemType = 'deviceSafetyCharacteristicsReport';

const versionFields = `
  deviceSafetyCharacteristicVersions {
    id
    title
    question
    response
    helpText
    versionIdentifier
    currentStatus {
      name
    }
    item {
      id
      tags
      comments {
        id
      }
      customIdentifier
      itemType {
        id
        name
      }
    }
  }
`;

const query = gql`
  query deviceSafetyCharacteristicsReportQuery($itemId: ID!)  {
    ${buildRecordQuery({ itemType: 'deviceSafetyCharacteristicsReport', versionFields, userCreatable: true })}
  }
`;

const listViewQuery = gql`
  query deviceSafetyCharacteristicsReportsListViewQuery {
    deviceSafetyCharacteristicsReports {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
      }
    }
  }
`;

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

export default {
  categoryLabel: t`DSC Reports`,
  displaySlug: slugs[itemType],
  label: t`DSC Report`,
  itemType,
  icon: Group,
  listViewQuery,
  listViewColumns,
  PayloadFields,
  versionFields,
  query,
  listWorkspace: [Views.Table, Views.LinksGraph],
  recordWorkspace: [Views.Builder, Views.Effective, Views.Versions],
  linksGraphViewQueryVariables: {
    itemType: 'deviceSafetyCharacteristic',
    parentItemType: itemType,
    parentItemTypeCollectionName: 'deviceSafetyCharacteristics',
  },
  disableNoRecordsPanel: true,
};
