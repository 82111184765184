import React, { useContext } from 'react';

import { Tooltip } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';

import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import { MTableCell } from 'material-table';

import { LockedContext } from 'contexts/lockedContext';
import { useDebounce } from 'hooks/useDebounce';

import { TextArea, TriStateToggle } from 'components';
import MakeLink from 'components/MakeLink';
import { VersionCell } from 'components/VersionTables';

import ImpactedCourseTable from './ImpactedCourseTable';
import { useStyles } from '../styles';
import { useUpdateImpactAssessmentMutation, useUpdateImpactAssociatedCourseMutation } from '../gql';

import { ChangeOrderBuilderViewQuery_changeOrder_currentVersion_trainingImpactAssessments_trainingImpactAssociatedCourses as AssociatedCourse } from '../../../__generated__/ChangeOrderBuilderViewQuery';

interface Props {
  displayOnly: boolean;
  courses: AssociatedCourse[];
}

function ItemsWithCourse(props: Props) {
  const { displayOnly } = props;
  const classes = useStyles();
  const { locked } = useContext(LockedContext);
  const [updateImpactCourse] = useUpdateImpactAssociatedCourseMutation();
  const [updateImpactAssessment] = useUpdateImpactAssessmentMutation();

  const isAdditionalRow = (props) =>
    ['ID', 'Read and Understand'].includes(props.columnDef.title) &&
    !props.rowData.firstItem &&
    !props.rowData.directlyAddedCourse;

  const isFirstRow = (props) =>
    ['ID', 'Read and Understand'].includes(props.columnDef.title) &&
    props.rowData.firstItem &&
    !props.rowData.directlyAddedCourse;

  const onImpactCourseChanged = (changes) => {
    updateImpactCourse({
      variables: {
        ...changes,
      },
    }).catch((e) => {
      throw new (Error as any)(`Error occurred while updating Impact Course: ${e.message}`);
    });
  };

  const updateDebounced = useDebounce(onImpactCourseChanged);

  return (
    <>
      <ImpactedCourseTable
        sortable
        data={props.courses}
        localization={{
          body: {
            emptyDataSourceMessage: <Typography variant="body2">N/A</Typography>,
          },
        }}
        components={{
          Cell: (props) => {
            let cellProps = props;
            if (isAdditionalRow(props)) return null;
            if (isFirstRow(props))
              cellProps = {
                ...props,
                rowSpan: props.rowData.parent.trainingImpactAssociatedCourses.length,
              };

            return <MTableCell {...cellProps} />;
          },
        }}
        columns={[
          {
            title: 'ID',
            field: 'parent.impactedVersion.item.customIdentifier',
            width: '85px',
            render: (row) => {
              const customIdentifier = row.directlyAddedCourse
                ? row.courseVersion.item.customIdentifier
                : row.parent.impactedVersion.item.customIdentifier;
              return !row.firstItem ? null : (
                <Tooltip
                  title={`${customIdentifier}:${
                    row.directlyAddedCourse ? row.courseVersion.title : row.parent.impactedVersion.title
                  }`}
                >
                  <span>
                    <MakeLink
                      params={{ displayOnly }}
                      item={row.directlyAddedCourse ? row.courseVersion.item : row.parent.impactedVersion.item}
                    >
                      {customIdentifier}
                    </MakeLink>
                  </span>
                </Tooltip>
              );
            },
          },
          {
            title: 'Read and Understand',
            align: 'center',
            width: '150px',
            render: (row) => {
              if (row.directlyAddedCourse) return 'n/a';
              return row.firstItem && !row.directlyAddedCourse ? (
                <>
                  <span className={classes.leftSpan}>No</span>
                  <TriStateToggle
                    disabled={displayOnly}
                    toggled={row.parent.requiresReadTraining}
                    onChange={(value) => {
                      updateImpactAssessment({
                        variables: {
                          id: row.parent.id,
                          requiresReadTraining: isUndefined(value) ? null : value,
                        },
                      }).catch((e) => {
                        // @ts-ignore
                        throw new Error(`Category change failed: ${e.message}`);
                      });
                    }}
                  />
                  <span className={classes.rightSpan}>Yes</span>
                </>
              ) : null;
            },
          },
          {
            title: 'Associated Course',
            cellStyle: {
              paddingLeft: 8,
              borderLeft: 'solid 2px #ebeced',
            },
            headerStyle: {
              paddingLeft: 8,
            },
            field: 'courseVersion.item.customIdentifier',
            render: (row) => {
              return row.directlyAddedCourse ? (
                'n/a'
              ) : (
                <Tooltip
                  title={`${get(row.courseVersion.item, 'customIdentifier')}:${get(row.courseVersion, 'title')}`}
                >
                  <span>
                    <MakeLink params={{ displayOnly }} item={row.courseVersion.item}>
                      {get(row.courseVersion.item, 'customIdentifier')}
                    </MakeLink>
                  </span>
                </Tooltip>
              );
            },
          },
          {
            title: 'Course Version',
            align: 'center',
            render: (row) => {
              return VersionCell({
                version: row.courseVersion.versionIdentifier,
                status: row.courseVersion.currentStatus.name,
              });
            },
          },
          {
            title: 'Course Training Required',
            align: 'center',
            render: (row) => (
              <>
                <span className={classes.leftSpan}>No</span>
                <TriStateToggle
                  toggled={row.requiresCourseTraining}
                  disabled={locked || displayOnly}
                  onChange={(value) =>
                    onImpactCourseChanged({
                      id: row.id,
                      requiresCourseTraining: value === undefined ? null : value,
                      justificationForNoTraining: null,
                    })
                  }
                />
                <span className={classes.rightSpan}>Yes</span>
              </>
            ),
          },
          {
            title: 'Justification for No Training',
            render: (row) => (
              <>
                {row.requiresCourseTraining === false ? (
                  <TextArea
                    rows={1}
                    disabled={locked || displayOnly}
                    initialValue={row.justificationForNoTraining}
                    onTextChange={(value) =>
                      updateDebounced({
                        id: row.id,
                        justificationForNoTraining: value,
                      })
                    }
                  />
                ) : (
                  'n/a'
                )}
              </>
            ),
          },
          {
            align: 'center',
            width: '50px',
            render: (row) => {
              const isShowTooltip =
                !row.directlyAddedCourse && row.requiresCourseTraining && row.parent.requiresReadTraining;
              return isShowTooltip ? (
                <Tooltip
                  title={
                    'Are you sure? This will trigger the creation of Training Records for both the Impacted Item and the Course, which may result in duplicated training information.'
                  }
                >
                  <Error color={'error'} />
                </Tooltip>
              ) : null;
            },
          },
        ]}
      />
    </>
  );
}

export default ItemsWithCourse;
