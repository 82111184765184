import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { get } from 'lodash';
import { DateTime } from 'luxon';

import StatusTransition from './StatusTransition';
import ParentSetChange from './ParentSetChange';
import { AuditTrailTransition } from '../index';

import useStyles from '../styles';

interface AuditTrailItemProps {
  history: AuditTrailTransition;
}

export default function AuditTrailItem(props: AuditTrailItemProps) {
  const classes = useStyles();

  const { history } = props;
  const versionIdentifier = get(history, 'versionIdentifier');
  const userFullName = get(history, 'userFullName');
  const timestamp = get(history, 'timestamp');
  const statusTransition = get(history, 'statusTransition');
  const parentSetChange = get(history, 'parentSetChange');
  const recordOrigin = get(history, 'recordOrigin');
  const linkedChangeCustomIdentifier = get(history, 'linkedChangeCustomIdentifier');

  return (
    <Grid container spacing={6}>
      <Grid container item xs={12} spacing={0}>
        <Grid item xs={12} className={classes.timestamp}>
          <Typography variant="caption">
            {DateTime.fromISO(timestamp).toFormat('MMM. dd, yyyy - hh:mm:ss a (ZZZZ)')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.userText}>
            {userFullName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            {statusTransition && (
              <StatusTransition
                versionIdentifier={versionIdentifier}
                statusTransition={statusTransition}
                recordOrigin={recordOrigin}
                linkedChangeCustomIdentifier={linkedChangeCustomIdentifier}
              />
            )}
            {parentSetChange && (
              <ParentSetChange versionIdentifier={versionIdentifier} parentSetChange={parentSetChange} />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
