import React from 'react';

import { Spacer } from 'components';

import { navigate } from '@reach/router';
import { Typography, Button, withStyles } from '@material-ui/core';

import styles from './styles';

function AssociateRisk({ classes, handleComplete }) {
  return (
    <>
      <Typography variant="h3">No Associated Risk Analysis</Typography>
      <Spacer factor={1} />
      <Typography variant="body1"> Associate this Risk Control with a Risk to add it to a Risk Analysis</Typography>

      <Spacer factor={2} />

      <div style={{ display: 'flex' }}>
        <Button
          id="btn-edit"
          style={{ flex: 1, marginRight: '5px' }}
          onClick={() => handleComplete()}
          color="primary"
          variant="outlined"
        >
          Dismiss
        </Button>

        <Button
          id="btn-view"
          style={{ flex: 1, marginLeft: '5px' }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate(`/category/risks`)}
        >
          View risks
        </Button>
      </div>
    </>
  );
}

export default withStyles(styles)(AssociateRisk);
