import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

export const TRIO_FIELDS = `
  trioReferencedItems {
    id
    customIdentifier
    workingVersion {
      id
      title
      versionIdentifier
      currentStatus {
        id
        name
      }
    }
    currentVersion {
      id
      title
      versionIdentifier
      currentStatus {
        id
        name
      }
    }
    itemType {
      id
      displaySlug
      userCreatable
    }
  }
  trioReferencedVersions {
    id
    versionIdentifier
    title
    currentStatus {
      id
      name
    }
    item {
      currentRelease {
        id
        versionIdentifier
        title
        currentStatus {
          name
          id
        }
      }
      workingVersion {
        id
        title
        versionIdentifier
        currentStatus {
          name
          id
        }
      }
      id
      customIdentifier
      itemType {
        id
        displaySlug
        userCreatable
      }
    }
  }
  trioReferencedAttachments {
    id
    versionId
    description
    url
  }
`;

const ADD_REFERENCED_ATTACHMENT = gql`
  mutation AddReferencedAttachment($versionId: ID!, $attachment: String!, $description: String, $trioType: String) {
    addReferencedAttachment(
      versionId: $versionId
      attachment: $attachment
      description: $description
      trioType: $trioType
    ) {
      result {
        id
      }
    }
  }
`;

export function useAddReferencedAttachment() {
  const [mutation] = useMutation(ADD_REFERENCED_ATTACHMENT);
  return mutation;
}

const UPDATE_REFERENCED_ATTACHMENT = gql`
  mutation UpdateReferencedAttachment($id: ID!, $attachment: String, $description: String) {
    updateReferencedAttachment(id: $id, attachment: $attachment, description: $description) {
      result {
        id
      }
    }
  }
`;

export function useUpdateReferencedAttachment() {
  const [mutation] = useMutation(UPDATE_REFERENCED_ATTACHMENT);
  return mutation;
}

const REMOVE_REFERENCED_ATTACHMENT = gql`
  mutation RemoveReferencedAttachment($id: ID!) {
    removeReferencedAttachment(id: $id) {
      result {
        id
      }
    }
  }
`;

export function useRemoveReferencedAttachment() {
  const [mutation] = useMutation(REMOVE_REFERENCED_ATTACHMENT);
  return mutation;
}

const ADD_REFERENCED_ITEM = gql`
  mutation AddReferencedItem($versionId: ID!, $referencedItemIds: [ID!]!, $trioType: String) {
    addReferencedItem(versionId: $versionId, referencedItemIds: $referencedItemIds, trioType: $trioType) {
      version {
        id
      }
    }
  }
`;

export function useAddReferencedItem() {
  const [mutation] = useMutation(ADD_REFERENCED_ITEM);
  return mutation;
}

const REMOVE_REFERENCED_ITEM = gql`
  mutation RemoveReferencedItem($versionId: ID!, $referencedItemId: ID!) {
    removeReferencedItem(versionId: $versionId, referencedItemId: $referencedItemId) {
      version {
        id
      }
    }
  }
`;

export function useRemoveReferencedItem() {
  const [mutation] = useMutation(REMOVE_REFERENCED_ITEM);
  return mutation;
}

const ADD_REFERENCED_VERSION = gql`
  mutation AddReferencedVersion($versionId: ID!, $referencedVersionIds: [ID!]!, $trioType: String) {
    addReferencedVersion(versionId: $versionId, referencedVersionIds: $referencedVersionIds, trioType: $trioType) {
      version {
        id
      }
    }
  }
`;

export function useAddReferencedVersion() {
  const [mutation] = useMutation(ADD_REFERENCED_VERSION);
  return mutation;
}

const UPDATE_REFERENCED_VERSION = gql`
  mutation UpdateReferencedVersion($nextVersionId: ID!, $oldVersionId: ID!, $versionId: ID!) {
    updateReferencedVersion(nextVersionId: $nextVersionId, oldVersionId: $oldVersionId, versionId: $versionId) {
      version {
        id
      }
    }
  }
`;

export function useUpdateReferencedVersion() {
  const [mutation] = useMutation(UPDATE_REFERENCED_VERSION);
  return mutation;
}

const REMOVE_REFERENCED_VERSION = gql`
  mutation RemoveReferencedVersion($versionId: ID!, $referencedVersionId: ID!) {
    removeReferencedVersion(versionId: $versionId, referencedVersionId: $referencedVersionId) {
      version {
        id
      }
    }
  }
`;

export function useRemoveReferencedVersion() {
  const [mutation] = useMutation(REMOVE_REFERENCED_VERSION);
  return mutation;
}
