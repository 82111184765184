import { useQuery, useMutation } from 'react-apollo';

import { commentsQuery, mutation } from 'api/gql/comments';
import {
  GetComments,
  GetCommentsVariables,
} from 'api/gql/__generated__/GetComments';
import { CreateComment } from 'api/gql/__generated__/CreateComment';

export const useComments = (itemId: string, skip: boolean = false) => ({
  query: useQuery<GetComments, GetCommentsVariables>(commentsQuery, {
    variables: { itemId },
    skip: !itemId || skip,
  }),
  mutation: useMutation<CreateComment>(mutation),
});
