import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(3),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    cardLabel: {
      textTransform: 'none',
      display: 'flex',
      '& span': {
        width: '100%',
      },
    },
    helperText: {
      whiteSpace: 'pre-line',
      marginRight: theme.spacing(6),
    },
    responseContainer: {
      marginTop: theme.spacing(1),
    },
    controls: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);
