import React from 'react';
import { Formik, Field } from 'formik';
import { Grid } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';
import { I18n } from '@lingui/react';
import { t, Trans } from '@lingui/macro';

import { ContentSection, Spacer, TextArea } from 'components';

import AutoSave from './AutoSave';
import Courses, { useCourses } from './Courses';
import { useVersion, useUpdateVersion, useRemoveCourse, useAddCourse } from './gql';
import useStyles from './styles';

function PayloadFields(props: { versionId: string }) {
  const classes: any = useStyles();

  const { data, loading, error, refetch } = useVersion(props.versionId);
  const [save] = useUpdateVersion();
  const [removeCourse] = useRemoveCourse();
  const [addCourse] = useAddCourse();
  const [courseState, dispatchCourses] = useCourses();

  const handleUnlinkCourses = () => {
    const coursesToDelete = Array.from(courseState.selectedCourses);
    Promise.all(
      coursesToDelete.map((courseVersionId) =>
        removeCourse({
          variables: {
            courseVersionId,
            curriculumVersionId: props.versionId,
          },
        }),
      ),
    )
      .then(() => refetch())
      .then(() => {
        dispatchCourses({ type: 'Clear' });
      })
      .catch(() => {
        dispatchCourses({ type: 'SetError' });
        // TODO: rethrow && make sure caught by error boundary!
      });
  };

  const handleAddCourses = (courseIds: string[]) =>
    Promise.all(
      courseIds.map((courseVersionId) =>
        addCourse({
          variables: {
            courseVersionId,
            curriculumVersionId: props.versionId,
          },
        }),
      ),
    )
      .then(() => refetch())
      .then(() => {
        dispatchCourses({ type: 'Clear' });
        return true;
      })
      .catch((err) => {
        dispatchCourses({ type: 'SetError' });
        throw err;
      });

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading version ${props.versionId}: ${error.message}`);

  return (
    <>
      <Formik
        initialValues={{
          id: props.versionId,
          description: data.curriculumVersion.description,
        }}
        onSubmit={(variables) => {
          save({ variables }).catch((e) => {
            throw new Error(`Error saving curriclum version ${props.versionId}: ${e.message}`);
          });
        }}
      >
        <>
          <Grid item xs={8} className={classes.gridGutterRight}>
            <ContentSection
              LabelText={
                <>
                  <Sort className={classes.icon} />
                  <Trans>Description</Trans>
                </>
              }
            >
              <Spacer factor={1} />
              <Field name="description">
                {({ field }) => (
                  <I18n>
                    {({ i18n }) => (
                      <TextArea
                        disabled={data.curriculumVersion.locked}
                        multiline
                        placeholder={i18n._(t`Enter description`)}
                        className={classes.textField}
                        rows={8}
                        onChange={field.onChange}
                        id={field.name}
                        value={field.value}
                      />
                    )}
                  </I18n>
                )}
              </Field>
            </ContentSection>
          </Grid>
          <AutoSave />
        </>
      </Formik>

      <Courses
        dispatch={dispatchCourses}
        locked={data.curriculumVersion.locked}
        courses={data.curriculumVersion.courseVersions}
        onUnlinkCourses={handleUnlinkCourses}
        onAddCourses={handleAddCourses}
        {...courseState}
      />
    </>
  );
}

export default PayloadFields;
