import React from 'react';

import { Grid } from '@material-ui/core';
import TrendingUp from '@material-ui/icons/TrendingUp';
import Sort from '@material-ui/icons/Sort';

import { SelectField, TextAreaField } from 'components';
import Panel from 'components/core/Panel';

import { TabProps } from '../PayloadFields';
import { queryNameToRefetch } from '../gql';

interface Props extends TabProps {}

const RiskAssessmentTab = (props: Props) => {
  const { layoutClasses, version } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} className={layoutClasses.gridGutterRight}>
        <Panel.Heading icon={TrendingUp}>Risk Level</Panel.Heading>
        <SelectField
          attrName="risk_level"
          options={['Low', 'Medium', 'High']}
          locked={version.locked}
          value={version.riskLevel}
          placeholder="Select Risk Level"
          versionId={version.id}
          refetchQueries={[queryNameToRefetch]}
        />
      </Grid>
      <Grid item xs={12} className={layoutClasses.gridGutterRight}>
        <Panel.Heading icon={Sort}>Justification for Risk Level</Panel.Heading>
        <TextAreaField
          locked={version.locked}
          id="justification-for-risk-level"
          initialValue={version.justificationForRiskLevel}
          versionId={version.id}
          attrName="justification_for_risk_level"
          rows={5}
          placeholder="Enter justification"
          refetchQueries={[queryNameToRefetch]}
        />
      </Grid>
    </Grid>
  );
};

export default RiskAssessmentTab;
