import gql from 'graphql-tag';

export const LIST_DEVIATIONS = gql`
  query deviationVersionQuery {
    deviations {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      currentVersion {
        id
        deviationId
        createdAt
        title
        body
        versionIdentifier
        currentStatus {
          id
          name
        }
        parentRecord {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            itemType {
              id
              name
            }
          }
        }
        parentRecordValidationProtocol {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
          }
        }
        parentRecordVerificationProtocol {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
          }
        }
        owner {
          id
          firstName
        }
        approver {
          id
          firstName
        }
      }
    }
  }
`;
