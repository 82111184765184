import React, { FC } from 'react';

import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  icon: any;
  title: string;
  editButton?: React.ReactNode;
}

const SectionTitle: FC<Props> = ({ icon, title, editButton }) => {
  const classes = useStyles();

  return (
    <div className={classes.sectionTitleArea}>
      <div className={classes.icon}>{icon}</div>
      <Typography variant="h3" className={classes.sectionTitle}>
        {title}
      </Typography>
      {editButton}
    </div>
  );
};

export default SectionTitle;
