import React, { ComponentType } from 'react';

import { Typography } from '@material-ui/core';

import { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';

interface Props {
  children: React.ReactNode;
  icon?: ComponentType<SvgIconProps>;
}

function Heading(props: Props) {
  const classes = useStyles();

  const Icon = props.icon;

  return (
    <div className={classes.container}>
      <Typography variant="button">
        {Icon && (
          <span className={classes.icon}>
            <Icon fontSize="small" />
          </span>
        )}
        {props.children}
      </Typography>
    </div>
  );
}

export default Heading;
