import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    cardWrapper: {
      display: 'flex',
      '& .cardContainer': {
        border: '1px solid #EEE',
        '&:not(:last-child)': {
          marginRight: theme.spacing(2),
        },
      },
      '& .card': {
        boxShadow: 'none',
      },
    },
  }),
);
