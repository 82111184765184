import React from 'react';
import { ReasonForChange } from '../views';

export default {
  initialStep: 'changeSummary',
  internalSteps: {
    changeSummary: {
      component: (props) => (
        <ReasonForChange buttonText="Done" smallCircle {...props} />
      ),
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: ({ changeSummary }) => ({ ...changeSummary }),
};
