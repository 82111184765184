import React, { useState } from 'react';
import get from 'lodash/get';
import { useQuery } from 'react-apollo';
import _ from 'lodash';

import { Button, FormControl, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import Check from '@material-ui/icons/Check';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

import { Spacer, UserAvatar } from 'components';
import BaseDialog from 'components/BaseDialog';
import { displayUserRoles } from 'components/SelectUserField/util';

import ConfirmUserRoleRemoval from '../ConfirmUserRoleRemoval';
import ItemsRequireTransitioningModal from '../ItemsRequireTransitioningModal';
import AssignNewPermissions from '../AssignNewPermissions';
import { GET_USER_ACTIVE_PERMISSION_GRANTS } from './gql';

interface Props {
  open: boolean;
  onClose: () => void;
  jobRole: any;
}

function RemoveUserFromRole(props: Props) {
  const { open, onClose, jobRole } = props;
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedJobRole, setSelectedJobRole] = useState(null);
  const [permissionGrants, setPermissionGrants] = useState<any[]>([]);
  const [confirmRoleRemovalOpen, setConfirmRoleRemovalOpen] = useState(false);
  const [cannotRemoveRoleModalOpen, setCannotRemoveRoleModalOpen] = useState(false);
  const [assignNewPermissionsOpen, setAssignNewPermissionsOpen] = useState(false);
  const userSelectedId = get(selectedUser, 'id');

  const { error, loading } = useQuery(GET_USER_ACTIVE_PERMISSION_GRANTS, {
    skip: !userSelectedId,
    variables: { userId: userSelectedId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const permissionGrants = get(data, 'activePermissionGrants', []);
      const uniqueGrants = _.uniqWith(
        _.reverse(_.clone(permissionGrants)),
        (arrVal: any, othVal: any) => arrVal.version.item.id === othVal.version.item.id,
      );
      const updatedFilteredGrants =
        uniqueGrants.length &&
        uniqueGrants.filter(
          (grant: any) =>
            !unaffectedStatuses.includes(grant.version.currentStatus.name) &&
            (grant.permissionType === 'approve_record'
              ? grant.version.assignedApproverJobRole.roleName === jobRole.roleName
              : grant.version.assignedOwnerJobRole.roleName === jobRole.roleName),
        );
      setPermissionGrants(updatedFilteredGrants || []);
    },
  });

  if (error) throw new Error(`Error loading user active permissions: ${error}`);

  const unaffectedStatuses = [
    'approved_draft',
    'approved_retirement',
    'canceled',
    'closed',
    'obsolete',
    'retired',
    'released',
  ];

  const onModalClose = () => {
    onClose();
    setSelectedUser(null);
  };

  const renderOption = (option: any, { selected }) => {
    if (!jobRole) return null;
    return (
      <>
        <UserAvatar userId={option.id} diameter={24} />
        <div style={{ width: '100%', marginLeft: 8 }}>
          <Typography variant="body2" style={{ fontWeight: 600 }}>
            {option.fullName}
            {selected ? <Check style={{ float: 'right' }} color="primary" /> : null}
          </Typography>
          <Typography variant="caption">{displayUserRoles(option.jobRoles)}</Typography>
        </div>
      </>
    );
  };

  const onSelectChange = (_, values) => {
    setSelectedUser(values);
  };

  const ownerApprovalStatuses = [
    'owner_approval',
    'approve_to_proceed',
    'qualification_complete',
    'ready_for_closure',
    'prepared',
    'completed',
  ];

  const permissionGrantsInOwnerApproval = permissionGrants.filter((grant: any) =>
    ownerApprovalStatuses.includes(grant.version.currentStatus.name),
  );

  const permissionGrantsInRetirementInitiated = permissionGrants.filter((grant: any) =>
    'retirement_initiated'.includes(grant.version.currentStatus.name),
  );

  const handleNextClick = () => {
    setSelectedJobRole(jobRole);
    if (permissionGrantsInOwnerApproval.length || permissionGrantsInRetirementInitiated.length) {
      setCannotRemoveRoleModalOpen(true);
    } else if (!permissionGrants.length) {
      setConfirmRoleRemovalOpen(true);
    } else {
      setAssignNewPermissionsOpen(true);
    }
    onClose();
  };

  const onConfirmRoleRemovalClose = () => {
    setSelectedUser(null);
    setConfirmRoleRemovalOpen(false);
    setCannotRemoveRoleModalOpen(false);
    setSelectedJobRole(null);
    setPermissionGrants([]);
  };

  const onAssignNewPermissionsClose = () => {
    setAssignNewPermissionsOpen(false);
    setSelectedJobRole(null);
    setPermissionGrants([]);
  };

  return (
    <>
      {!confirmRoleRemovalOpen && (
        <BaseDialog
          open={!confirmRoleRemovalOpen ? open : false}
          onClose={() => onModalClose()}
          title={`Remove User from Role`}
          Icon={RemoveCircle}
          size="xlarge"
          actions={
            <Button
              onClick={handleNextClick}
              color="primary"
              variant="contained"
              fullWidth
              disabled={loading || !userSelectedId}
            >
              Next
            </Button>
          }
        >
          <form>
            <FormControl required fullWidth>
              <Spacer factor={2} />
              <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left' }}>
                {`The following user will be removed from the Role of ${get(jobRole, 'formattedRoleName')}:`}
              </Typography>
              <Spacer factor={2} />
              <Autocomplete
                openOnFocus
                options={get(jobRole, 'usersWithRole', []).filter((user) => user.id !== '1')}
                getOptionLabel={(option: any) => option.fullName}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder="Select User to Remove" />
                )}
                value={selectedUser}
                renderOption={renderOption}
                onChange={onSelectChange}
                disabled={loading}
              />
            </FormControl>
          </form>
        </BaseDialog>
      )}
      {confirmRoleRemovalOpen && (
        <ConfirmUserRoleRemoval
          open={confirmRoleRemovalOpen}
          onClose={onConfirmRoleRemovalClose}
          selectedUser={selectedUser}
          jobRole={selectedJobRole}
        />
      )}
      <ItemsRequireTransitioningModal
        open={cannotRemoveRoleModalOpen}
        onClose={onConfirmRoleRemovalClose}
        selectedUser={selectedUser}
        jobRole={selectedJobRole}
        type={permissionGrantsInOwnerApproval.length ? 'Owner Approval' : 'Retirement Initiated'}
        affectedGrants={
          permissionGrantsInOwnerApproval.length
            ? permissionGrantsInOwnerApproval
            : permissionGrantsInRetirementInitiated
        }
      />
      {selectedJobRole && permissionGrants.length && (
        <AssignNewPermissions
          open={assignNewPermissionsOpen}
          confirmRoleRemovalOpen={setConfirmRoleRemovalOpen}
          onClose={onAssignNewPermissionsClose}
          selectedUser={selectedUser}
          activePermissionGrants={permissionGrants}
          jobRole={selectedJobRole}
          setJobRole={setSelectedJobRole}
          setSelectedUser={setSelectedUser}
        />
      )}
    </>
  );
}

export default RemoveUserFromRole;
