import React, { useState } from 'react';

import clsx from 'clsx';

import { TextAreaField } from 'components';

import { useStyles } from './styles';

const ExpandedTextArea = ({ fixedHeight = false, ...rest }) => {
  const classes = useStyles();

  const [isEditing, setIsEditing] = useState(false);

  const handleDoubleClick = () => {
    if (!rest.locked) {
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div
      onDoubleClick={handleDoubleClick}
      className={clsx(classes.textAreaContainer, { [classes.textAreaContainerDisplay]: !isEditing })}
    >
      <TextAreaField
        className={clsx(classes.expanded, rest.className, fixedHeight && classes.fixedHeight)}
        fixedHeight={fixedHeight}
        disabled={!isEditing}
        onBlur={handleBlur}
        isEditing={isEditing}
        autoFocus
        {...rest}
      />
    </div>
  );
};
export default ExpandedTextArea;
