import React from 'react';
import { Trans } from '@lingui/macro';

import { Grid } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import { Views } from 'workspaces';

const PayloadFields = ({
  activeWorkspaceView,
  layoutClasses,
  versionData: currentVersion,
}) => (
  <Grid xs={12}>
    <RichTextEditorContentSection
      Heading={
        <>
          <Sort className={layoutClasses.icon} />
          <Trans>Notes</Trans>
        </>
      }
      initialValue={currentVersion.notes}
      versionId={currentVersion.id}
      attrName="notes"
      locked={currentVersion.locked}
      refetchQueries={['riskNoteQuery']}
      showComments={activeWorkspaceView.id === Views.Builder.id}
    />
  </Grid>
);

export default withWorkspaceViews()(PayloadFields);
