import React from 'react';

import { MenuItem } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

function RetireMenuItem(props) {
  return (
    <MenuItem disabled={props.disabled} onClick={props.onClick}>
      <CancelIcon />
      {props.children ? props.children : 'Retire'}
    </MenuItem>
  );
}

export default RetireMenuItem;
