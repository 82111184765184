import gql from 'graphql-tag';

export const UPDATE_COMPANY_QUERY = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      errors
      company {
        id
        name
        subdomain
        validatedSystem
        disabledCategories
        enabledCategories
        allowTransitioning
        allowCustomPermissions
        requirePasswordChangeAfter
        licenseType
        licenseTier
        logoUrl
        remainingGeneralUserSeats
        remainingViewOnlyUserSeats
        remainingTrainAndViewUserSeats
        partnerWhitelistUserCount
        timeZone
        availableTimeZones
        streetAddress
        city
        stateOrProvince
        zipCode
        phoneNumber
        emailAddress
        moduleMenuItems {
          id
          state
        }
        deployments {
          currentVersion {
            version
            deployedAt
          }
          previousVersion {
            version
          }
        }
        config {
          trainingManagement {
            independentStudyCertificationText
            defaultTrainingRecordVersionDaysDue
            newDocumentVersionDaysDue
            newTrainingPlanVersionDaysDue
            uprevItemVersionDaysDue
            uprevTrainingPlanVersionDaysDue
          }
          supplierManagement {
            expirationHighRisk
            expirationLowRisk
            expirationMediumRisk
          }
          generalUserSeatCountLimit
          viewOnlyUserSeatCountLimit
          trainAndViewUserSeatCountLimit
        }
      }
    }
  }
`;

export const UPDATE_CLIENT_CONFIG = gql`
  mutation updateClientConfig($input: UpdateClientConfigInput!) {
    updateClientConfig(input: $input) {
      clientConfig {
        trainingManagement {
          independentStudyCertificationText
          defaultTrainingRecordVersionDaysDue
          newDocumentVersionDaysDue
          newTrainingPlanVersionDaysDue
          uprevItemVersionDaysDue
          uprevTrainingPlanVersionDaysDue
        }
        supplierManagement {
          expirationHighRisk
          expirationLowRisk
          expirationMediumRisk
        }
      }
    }
  }
`;
