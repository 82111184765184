import React from 'react';

import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface DashboardPanelProps {
  title: string;
  count: number;
  color?: string;
}

const DashboardPanel = (props: DashboardPanelProps) => {
  const classes = useStyles();
  const { title, count, color } = props;

  return (
    <div className={`${classes.cardContainer} cardContainer`}>
      <div className="cardColor" style={{ height: 15, backgroundColor: color || 'white' }} />
      <div className={`${classes.card} card`}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.count}>
          {count}
        </Typography>
      </div>
    </div>
  );
};

export default DashboardPanel;
