import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';

import get from 'lodash/get';
import flowRight from 'lodash/flowRight';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CKEditorInspector from '@ckeditor/ckeditor5-inspector'; //this is used for debugging CKEditor

import WithCurrentUser from 'compositions/WithCurrentUser';
import { WithAllUsers } from 'compositions';

import { getAuthToken } from 'utils';
import AttachmentClient from 'utils/AttachmentClient';
import CommentsAdapter from './comments-adapter';
import mutation from 'utils/gql/updateTextArea';
import './ck-editor.css';

const toolbarLayouts = {
  large: [
    'undo',
    'redo',
    '|',
    'heading',
    '|',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'subscript',
    'superscript',
    '|',
    'alignment:left',
    'alignment:center',
    'alignment:right',
    'alignment:justify',
    '|',
    'bulletedList',
    'numberedList',
    'todoList',
    '|',
    'link',
    '|',
    'insertTable',
    '|',
    'pageBreak',
    '|',
    'imageUpload',
    'comment',
    'commentsArchive',
  ],
  small: [
    'undo',
    'redo',
    '|',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'subscript',
    'superscript',
    '|',
    'bulletedList',
    'numberedList',
    'todoList',
    '|',
    'link',
    '|',
    'insertTable',
    'pageBreak',
    'imageUpload',
    'comment',
    'commentsArchive',
  ],
};

const imageToolbarConfig = {
  toolbar: [
    'toggleImageCaption',
    'imageStyle:block',
    'imageStyle:alignLeft',
    'imageStyle:alignCenter',
    'imageStyle:alignRight',
    'resizeImage',
  ],
  styles: ['full', 'alignLeft', 'alignCenter', 'alignRight'],
};

const headingToolbarConfig = {
  options: [
    {
      model: 'paragraph',
      title: 'Paragraph',
      class: 'ck-heading_paragraph',
    },
    {
      model: 'heading1',
      view: 'h1',
      title: 'Heading 1',
      class: 'ck-heading_heading1',
    },
    {
      model: 'heading2',
      view: 'h2',
      title: 'Heading 2',
      class: 'ck-heading_heading2',
    },
    {
      model: 'heading3',
      view: 'h3',
      title: 'Heading 3',
      class: 'ck-heading_heading3',
    },
    {
      model: 'heading4',
      view: 'h4',
      title: 'Heading 4',
      class: 'ck-heading_heading4',
    },
    {
      model: 'heading5',
      view: 'h5',
      title: 'Heading 5',
      class: 'ck-heading_heading5',
    },
    {
      model: 'heading6',
      view: 'h6',
      title: 'Heading 6',
      class: 'ck-heading_heading6',
    },
  ],
};

class RichTextEditorField extends Component {
  static propTypes = {
    attrName: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    refetchQueries: PropTypes.array,
    versionId: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
  };

  static defaultProps = {
    refetchQueries: undefined,
    intialValue: '',
  };

  constructor(props) {
    super(props);

    this.editor = null;
    this.suggestionTypes = ['suggestion-insertion', 'suggestion-deletion', 'suggestion-replace'];
  }

  toggleCommentButton = () => {
    if (!this.editor) return;
    const cmd = this.editor.commands.get('addCommentThread');
    if (!cmd) {
      throw new Error("Cannot toggle comment button: command 'addCommentThread' not found");
    }

    // CKEditor "feature ID" for enabling/disabling the toolbar's comment button
    if (this.props.showComments) {
      cmd.clearForceDisabled('nemedio:comments');
    } else {
      cmd.forceDisabled('nemedio:comments');
    }
    const mode = this.props.displayMode || 'narrowSidebar';
    this.editor.plugins.get('AnnotationsUIs').switchTo(mode);

    // Toggles track changes mode
    const trackChangesMode = this.props.trackChangesMode === 'suggesting' ? true : false;
    this.editor.commands.get('trackChanges').value = trackChangesMode;
  };

  render() {
    const { attrName, currentUser, locked, refetchQueries, versionId, allUsers } = this.props;

    this.toggleCommentButton();

    // ====================
    // We *do not* set CKEditor's data property. Doing so can cause the cursor
    // position to change in the middle of typing due to the re-render that can occur
    // when the onChange handler's mutation refetchQueries are ran.
    //
    // The refetchQueries can result in this.props.initialValue changing. When this changes and
    // we assign to CKEditor's data property, it will re-render too, changing the cursor position.
    //
    // To avoid this we set CKEditor's initial value in the onInit hook.
    // Now when then refetchQueries run and this component re-renders CKEditor does not.
    // ====================
    return (
      <Mutation mutation={mutation}>
        {(mutate) => (
          <CKEditor
            editor={ClassicEditor}
            disabled={locked}
            onReady={(editor) => {
              // CKEditorInspector.attach(editor); // this is used for debugging CKEditor
              this.editor = editor;
              this.props.editorRef.current = editor;
              editor.setData(this.props.initialValue || '');
              const mode = this.props.displayMode || 'narrowSidebar';
              this.editor.plugins.get('AnnotationsUIs').switchTo(mode);

              // Annotation listeners are defined here
              const annotations = this.editor.plugins.get('Annotations');
              const collection = annotations.collection;
              collection.on('add', (event, data) => {
                if (data.type === 'comment') {
                  this.props.setCommentsCount((prevCount) => prevCount + 1);
                }
                if (this.suggestionTypes.includes(data.type)) {
                  this.props.setSuggestionsCount((prevCount) => prevCount + 1);
                }
              });
              collection.on('remove', (event, data) => {
                if (data.type === 'comment') {
                  this.props.setCommentsCount((prevCount) => prevCount - 1);
                }
                if (this.suggestionTypes.includes(data.type)) {
                  this.props.setSuggestionsCount((prevCount) => prevCount - 1);
                }
              });

              const trackChangesMode = this.props.trackChangesMode === 'suggesting' ? true : false;
              this.editor.commands.get('trackChanges').value = trackChangesMode;
            }}
            config={{
              heading: headingToolbarConfig,
              toolbar: locked
                ? null
                : {
                    viewportTopOffset: this.props.fixedToolbarOffset,
                    items: get(toolbarLayouts, this.props.optionSet, 'small'),
                  },
              sidebar: { container: this.props.commentsContainer },
              licenseKey: process.env.REACT_APP_COMMENTS_LICENSE_KEY,
              comments: {
                attrName,
                versionId,
                allUsers: allUsers,
                currentUser: {
                  id: currentUser.id,
                  name: `${currentUser.firstName} ${currentUser.lastName}`,
                  avatar: currentUser.profile.photoUrl,
                },
              },
              trackChanges: {
                disableComments: false,
              },
              shouldNotGroupWhenFull: false,
              attachmentUpload: {
                url: AttachmentClient.endpoint,
                headers: { Authorization: `Bearer ${getAuthToken()[0]}` },
                params: {
                  field: attrName,
                  version: versionId,
                },
              },
              autosave: {
                save(editor) {
                  if (locked) return;
                  return mutate({
                    variables: {
                      input: {
                        attrName,
                        textValue: editor.getData(),
                        versionId,
                      },
                    },
                    refetchQueries,
                  });
                },
              },
              table: {
                contentToolbar: [
                  'toggleTableCaption',
                  'tableRow',
                  'tableColumn',
                  'mergeTableCells',
                  'tableProperties',
                  'tableCellProperties',
                ],
              },
              image: imageToolbarConfig,
              extraPlugins: [CommentsAdapter],
            }}
          />
        )}
      </Mutation>
    );
  }
}

RichTextEditorField.defaultProps = {
  optionSet: 'small',
};

export default flowRight([WithCurrentUser, WithAllUsers])(RichTextEditorField);
