import { createStyles, makeStyles } from '@material-ui/core';

const borderStyle = 'solid 2px #ebeced';

export const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      '& table': {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0,
        '& a[href]': {
          textDecoration: 'inherit',
          fontStyle: 'inherit',
          color: 'inherit',

          '&:hover': {
            textDecoration: 'underline',
          },
        },
        '& th': {
          ...theme.typography.subtitle1,
          padding: 0,
          border: 'none',
          paddingBottom: theme.spacing(0.25),
          backgroundColor: 'inherit',
          '&:first-child': {
            paddingLeft: theme.spacing(2.25),
          },
          '&:last-child': {
            paddingRight: theme.spacing(2.25),
          },
        },
        '& tbody > tr': {
          boxSizing: 'border-box',
          height: theme.spacing(6.5),
          border: 'solid 2px #ebeced',
          '& td .button': {
            visibility: 'hidden',
          },
          '&:hover': {
            // We have to override Material UI's default and since we're using
            // MaterialTable on top of it this seems easiest
            backgroundColor: 'unset !important',
            '& td': {
              borderColor: theme.palette.primary.main,
            },
            '& .button': {
              visibility: 'visible',
            },
          },
          '&:not(:hover):not(:first-child)': {
            '& td': {
              paddingTop: 2,
              borderTop: 'none',
            },
          },
          '&:first-child': {
            '& td:first-child': {
              borderTopLeftRadius: 4,
            },
            '& td:last-child': {
              borderTopRightRadius: 4,
            },
          },
          '&:last-child': {
            '& td:first-child': {
              borderBottomLeftRadius: 4,
            },
            '& td:last-child': {
              borderBottomRightRadius: 4,
            },
          },
        },
        '& td': {
          padding: 0,
          borderTop: borderStyle,
          borderBottom: borderStyle,
          '&:first-child': {
            paddingLeft: theme.spacing(2.25),
            borderLeft: borderStyle,
          },
          '&:last-child': {
            paddingRight: theme.spacing(2.25),
            borderRight: borderStyle,
          },
        },
      },
    },
  }),
);
