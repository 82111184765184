import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import topWatermark from 'assets/img/watermark_main_page_top.png';
import bottomWatermark from 'assets/img/watermark_main_page_bottom.png';
import { INoRowsOverlayParams } from 'ag-grid-community/dist/lib/rendering/overlays/noRowsOverlayComponent';

interface Props extends INoRowsOverlayParams {
  text: string;
}

const AgGridOverlay: FC<Props> = ({ text }) => {
  return (
    <Box
      position="absolute"
      width="100%"
      height="calc(100% - 56px)"
      left={0}
      top={56}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img
        src={topWatermark}
        style={{ position: 'absolute', top: 0, right: 72 }}
        width={326}
        alt="aggrid-watermark-top"
      />
      <img
        src={bottomWatermark}
        style={{ position: 'absolute', bottom: 0, left: 72 }}
        width={326}
        alt="aggrid-watermark-bottom"
      />
      <Typography variant="button" style={{ textTransform: 'none' }}>
        {text}
      </Typography>
    </Box>
  );
};

export default AgGridOverlay;
