import RestoreIcon from '@material-ui/icons/Restore';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import GestureIcon from '@material-ui/icons/Gesture';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LibraryAddCheckOutline from 'assets/icons/LibraryAddCheckOutline';

import { NotificationChannelEnum, Preference } from './types';

const generatePreferenceTitleByType = (type: string) => ({
  new_assignment: `When A new ${type} has been assigned to me`,
  one_week_before: `1 week before a ${type} is due`,
  three_days_before: `3 days before a ${type} is due`,
  two_days_before: `2 days before a ${type} is due`,
  one_day_before: `1 day before a ${type} is due`,
  overdue: `When a ${type} is overdue`,
});

export const preferences: Preference = {
  assigned_task: {
    headline: { icon: LibraryAddCheckOutline, title: 'My Assigned Tasks' },
    preferenceTitles: generatePreferenceTitleByType('task'),
  },
  assigned_training: {
    headline: {
      icon: RestoreIcon,
      title: 'My Assigned Trainings',
    },
    preferenceTitles: generatePreferenceTitleByType('training'),
  },
  mention: {
    headline: { icon: AlternateEmailIcon, title: 'Mentions' },
    preferenceTitles: {
      new_mention: "When I've been mentioned",
    },
  },
  esignature_required: {
    headline: { icon: GestureIcon, title: 'My Signature Required' },
    preferenceTitles: {
      required_signature: 'When my signature is required ',
    },
  },
};

export const channels = [
  { key: NotificationChannelEnum.in_app, icon: NotificationsIcon, label: 'In app' },
  { key: NotificationChannelEnum.email, icon: MailOutlineIcon, label: 'Via email' },
];
