export default (theme) => ({
  modalContainer: {
    width: '320px',
    height: '280px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    alignItems: 'center',
    textAlign: 'center',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    backgroundColor: theme.palette.primary.contrastText,
    paddingTop: theme.spacing(3.5),
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  errorIcon: {
    fontSize: '30px',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
  },
});
