import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Fab, Button, Modal, Typography, withStyles } from '@material-ui/core';

import { Trans } from '@lingui/macro';

import { Spacer } from 'components';

import styles from './styles';

class ItemPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleModalOpen = () => {
    this.setState({ open: true });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, fullName, type } = this.props;

    function permissionHeader() {
      if (type === 'release') {
        return <Trans>Release not permitted</Trans>;
      } else if (type === 'owner' || 'approver' || 'generic') {
        return <Trans>Permission Required</Trans>;
      }
    }

    function permissionText() {
      if (type === 'owner') {
        return (
          <Trans>
            You do not have permission to take ownership of this item. Please contact {fullName} to have this item
            approved.
          </Trans>
        );
      } else if (type === 'approver') {
        return (
          <Trans>
            You do not have permission to approve this item. Please contact
            {fullName} to have this item approved.
          </Trans>
        );
      } else if (type === 'generic') {
        return <Trans>You do not have permission to perform this action.</Trans>;
      } else if (type === 'release') {
        return <Trans>This item can only be released upon close of its associated Change Order.</Trans>;
      }
    }

    return (
      <>
        <Button onClick={this.handleModalOpen}>Open Modal</Button>
        <Modal aria-labelledby="item-approval-modal" open={this.state.open} onClose={this.handleModalClose}>
          <div className={classes.modalContainer}>
            <Fab className={classes.errorIcon} disabled={true} size="large" variant="round">
              !
            </Fab>
            <Spacer factor={2} />
            <Typography variant="h3">{permissionHeader()}</Typography>
            <Spacer factor={2} />
            <Typography variant="body2">{permissionText()}</Typography>
            <Spacer factor={2} />
            <Button variant="contained" color="primary" fullWidth onClick={this.handleModalClose}>
              <Trans>BACK</Trans>
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

ItemPermission.propTypes = {
  classes: PropTypes.shape({
    modalContainer: PropTypes.string.isRequired,
    errorIcon: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(ItemPermission);
