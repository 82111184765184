export default (theme) => ({
  backArrow: {
    color: theme.palette.action.active,
    paddingLeft: theme.spacing(2),
  },
  error: {
    color: theme.palette.common.error.main,
  },
  forgotPasswordButton: {
    ...theme.typography.button,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
    height: '43px',
  },
  formCard: {
    position: 'relative',
    overflow: 'visible',
    margin: 'auto',
    maxWidth: theme.spacing(52),
    borderRadius: '10px',
  },
  form: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  inputAdornment: {
    color: theme.palette.secondary.main,
  },
  inputField: {
    '&:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoImg: {
    width: '40%',
  },
  success: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    ...theme.typography.body2,
  },
});
