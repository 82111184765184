import React from 'react';

import { get } from 'lodash';

import RiskLevelSelect from 'components/RiskLevelSelect';

import { useUpdateRiskVersion, targetValue } from './hooks';
import { useOptionsContext } from './OptionsContext';

interface Props {
  riskVersion: any;
  mitigated: boolean;
  locked: boolean;
}

export default function RiskSeverity({ riskVersion, mitigated, locked }: Props) {
  const updateField = useUpdateRiskVersion(riskVersion.id);
  const { severityLevels } = useOptionsContext();

  const prefix = mitigated ? '' : 'un';

  const attrName = `${prefix}mitigatedSeverity`;
  const updateAttrName = `${attrName}Id`;
  const content = get(riskVersion, `${attrName}.id`, null);
  const options = severityLevels;
  const placeholder = `S`;

  return (
    <RiskLevelSelect
      key={content}
      placeholder={placeholder}
      defaultValue={content}
      options={options}
      onChange={targetValue(updateField(updateAttrName))}
      includeLabelWhenSelected={false}
      disabled={locked}
    />
  );
}
