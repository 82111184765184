import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';

import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import styles from './styles';

const variantIcon = {
  error: Cancel,
  success: CheckCircle,
  deleteSuccess: CheckCircle,
};

const variantMessage = {
  error: t`Upload failed`,
  success: t`Upload succeeded`,
  deleteSuccess: t`File deleted`,
};

function getVariantAction(variant, { onClose, variantActions = {} }) {
  if (variant === 'error') {
    return [
      <Button
        key="try-again-button"
        size="small"
        color="primary"
        onClick={() => {
          onClose();
          if (variantActions.error) {
            // This timeout gives time for the snackbar to close. Without it,
            // retrying feels broken (even though it is working) because no
            // observable changes take place.
            setTimeout(variantActions.error, 300);
          }
        }}
      >
        <Trans>TRY AGAIN</Trans>
      </Button>,
    ];
  }

  return undefined;
}

function FileUploadSnackbar({
  classes,
  i18n,
  onClose,
  open,
  variant,
  variantActions,
}) {
  const Icon = variantIcon[variant];
  const message = variantMessage[variant];

  return (
    <Snackbar
      ContentProps={{
        'aria-describedby': 'file-upload-status-message',
      }}
      action={getVariantAction(variant, { onClose, variantActions })}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      aria-label={i18n._(t`File Upload Status`)}
      autoHideDuration={4000}
      message={
        <>
          <Icon className={classes.messageIcon} />
          <span className={classes.messageText} id="file-upload-status-message">
            {i18n._(message)}
          </span>
        </>
      }
      onClose={onClose}
      open={open}
    />
  );
}

FileUploadSnackbar.propTypes = {
  classes: PropTypes.shape({
    messageIcon: PropTypes.string.isRequired,
    messageText: PropTypes.string.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['error', 'success', 'deleteSuccess']).isRequired,
  variantActions: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
};

FileUploadSnackbar.defaultProps = {
  variantActions: {},
};

export default compose(
  withI18n(),
  withStyles(styles),
)(FileUploadSnackbar);
