import React from 'react';

import AttachmentDownload from 'components/AttachmentDownload';
import Panel from 'components/core/Panel';
import SectionLabel from 'components/core/SectionLabel';
import { TrainingFormat } from '__generated__/globalTypes';

import { Sort, AttachFile, Filter2 } from '@material-ui/icons';
import { Typography, Link, Grid } from '@material-ui/core';

import { useStyles } from './styles';
import TrainingInfo from 'pages/Course/Id/PayloadFields/TrainingInfo';

interface Props {
  versionId: string;
  courseVersion;
}

function TrainingMaterials(props: Props) {
  const classes = useStyles();

  const trainingFormatHelperText = () => {
    switch (props.courseVersion.trainingFormat) {
      case TrainingFormat.INDEPENDENT_STUDY:
        return (
          <>
            This training format is <b>Independent Study</b> which means that you may complete this training
            independently. Please review the materials below to complete your training.
          </>
        );
      case TrainingFormat.INSTRUCTOR_LED:
        return (
          <>
            This training format is <b>Instructor Led</b> which means that your instructor will contact you to schedule
            your training. The materials below will be covered during your training.
          </>
        );
      case TrainingFormat.ON_THE_JOB:
        return (
          <>
            This training format is <b>On the Job</b> which means that your instructor will contact you to schedule your
            training. The materials below will be covered during your training.
          </>
        );
    }
  };

  return (
    <>
      <SectionLabel icon={Filter2}>
        Training Materials
        <SectionLabel.HelperText>{trainingFormatHelperText()}</SectionLabel.HelperText>
      </SectionLabel>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <div className={classes.fullHeight}>
            <Panel>
              <Panel.Heading icon={AttachFile}>Training Materials</Panel.Heading>
              <ul className={classes.list}>
                {props.courseVersion.trainingMaterialLinks.map((link) => (
                  <Typography variant="caption" component="li">
                    <Link href={link} target="_blank" rel="noopener" variant="caption">
                      {link}
                    </Link>
                  </Typography>
                ))}
              </ul>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th align="left" className={classes.header}>
                      <AttachFile fontSize="small" />
                      File Name
                    </th>
                    <th align="left" className={classes.header}>
                      <Sort fontSize="small" />
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.courseVersion.trainingMaterialAttachments.map(
                    (attachment) =>
                      attachment.file && (
                        <tr>
                          <td>
                            <AttachmentDownload url={attachment.file.url} />
                          </td>
                          <td>
                            <Typography variant="caption">{attachment.description}</Typography>
                          </td>
                        </tr>
                      ),
                  )}
                </tbody>
              </table>
            </Panel>
          </div>
        </Grid>
        <Grid item xs={4}>
          <TrainingInfo courseVersion={props.courseVersion} displayOnly versionId={props.versionId} />
        </Grid>
      </Grid>
    </>
  );
}

export default TrainingMaterials;
