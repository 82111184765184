import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';

import mutation from 'utils/gql/updateBooleanValue';

const styles = (theme) => ({
  formControlLabel: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    maxWidth: 150,
  },
  labelText: {
    marginRight: theme.spacing(2),
  },
  radioRoot: {
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  root: {
    width: '100%',
  },
});

class BooleanRadioBtnField extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      formControlLabel: PropTypes.string.isRequired,
      radioRoot: PropTypes.string.isRequired,
      root: PropTypes.string.isRequired,
    }).isRequired,
    attrName: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    refetchQueries: PropTypes.array,
    versionId: PropTypes.string.isRequired,
    selectedValue: PropTypes.bool,
  };

  static defaultProps = {
    refetchQueries: undefined,
    value: null,
    locked: true,
  };

  render() {
    const {
      classes,
      attrName,
      label,
      locked,
      options,
      refetchQueries,
      versionId,
      selectedValue,
      value,
      ...rest
    } = this.props;

    return (
      <Mutation mutation={mutation}>
        {(mutate) => (
          <FormControl
            component="fieldset"
            className={classes.root}
            disabled={locked}
          >
            <RadioGroup
              name="boolean-radio-group-field-selector"
              onChange={(event) => {
                mutate({
                  variables: {
                    input: {
                      attrName,
                      booleanValue: JSON.parse(event.target.value),
                      versionId,
                    },
                  },
                  refetchQueries,
                });
              }}
              value={value}
              {...rest}
            >
              <Typography variant="body2" component="div">
                <span className={classes.labelText}>{label}</span>
                {options.map(({ value, label, ...rest }) => (
                  <FormControlLabel
                    key={value}
                    classes={{ label: classes.formControlLabel }}
                    control={<Radio className={classes.radioRoot} />}
                    label={label}
                    value={value}
                    checked={selectedValue === JSON.parse(value)}
                    {...rest}
                  />
                ))}
              </Typography>
            </RadioGroup>
          </FormControl>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(BooleanRadioBtnField);
