import React from 'react';
import { compose } from 'recompose';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { IconButton, withStyles } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import LinkOff from '@material-ui/icons/LinkOff';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import get from 'lodash/get';

const styles = (theme) => ({
  iconButton: {
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.blueDark,
    },
  },
  smallIcon: {
    width: 20,
  },
  topRowIcons: {
    marginLeft: 'auto',
    float: 'right',
  },
  iconCarrot: {
    color: theme.palette.blueDark,
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
});

function TopRowControls(props) {
  const { classes, i18n, onClickPlus, loading, expandCarrot, removeFromSetIcon, disabled } = props;

  const RemoveIcon = get(props, 'removeFromSetIcon.icon', LinkOff);

  return (
    <span className={classes.topRowIcons}>
      {onClickPlus && (
        <IconButton
          aria-label={i18n._(t`Create New Item`)}
          className={classes.iconButton}
          disableRipple
          onClick={onClickPlus}
          disabled={loading}
        >
          <AddCircle
            classes={{ root: classes.smallIcon }}
            color="inherit"
            data-testid="create-new-item-icon"
            fontSize="inherit"
            titleAccess={i18n._(t`Create New Item`)}
          />
        </IconButton>
      )}
      {expandCarrot.visible && (
        <IconButton
          aria-label={i18n._(t`Show Children`)}
          className={`${classes.iconButton} ${classes.iconCarrot} ${expandCarrot.expanded ? '' : classes.rotate}`}
          disableRipple
          onClick={expandCarrot.onClick}
        >
          <KeyboardArrowUp fontSize="inherit" />
        </IconButton>
      )}
      {removeFromSetIcon.visible && (
        <IconButton
          aria-label={i18n._(t`Remove Item From Set`)}
          disableRipple
          className={classes.iconButton}
          style={{ paddingRight: 16 }}
          onClick={removeFromSetIcon.onClick}
          disabled={disabled}
        >
          <RemoveIcon classes={{ root: classes.smallIcon }} />
        </IconButton>
      )}
    </span>
  );
}

export default compose(
  withStyles(styles),
  withI18n(),
)(TopRowControls);
