import React from 'react';

import { Typography, Grid } from '@material-ui/core';
import Business from '@material-ui/icons/Business';
import Build from '@material-ui/icons/Build';

import { Trans } from '@lingui/macro';
import snakeCase from 'lodash/snakeCase';

import { NewItem } from 'compositions';
import Page from 'components/core/Page';
import Button from 'components/Button';
import { Loading, Spacer } from 'components';
import SectionLabel from 'components/core/SectionLabel';

import BreakdownByStatus from './BreakdownByStatus';
import UpcomingRequalifications from './UpcomingRequalifications';
import { useStyles } from './styles';
import { useQuery } from 'react-apollo';
import { supplierManagementDashboardQuery } from './gql';

export interface TrainingModuleDashboardProps {
  path: string;
}

const TrainingDashboard: React.FC<TrainingModuleDashboardProps> = () => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(supplierManagementDashboardQuery);

  if (loading) return <Loading />;
  if (error) throw new Error(`Graphql Error: ${error}`);

  return (
    <div style={{ marginTop: -35 }}>
      <Page>
        <Grid item xs={12}>
          <div className={classes.header}>
            <Business fontSize={'large'} className={classes.icon} />
            <Typography variant="h2" className={classes.headerTitle}>
              <Trans>Supplier Management</Trans>
            </Typography>
          </div>
          <Spacer factor={1} />
          <div className={classes.supplierActionsLabel}>
            <SectionLabel variant="body1" icon={Build}>
              <Typography
                variant="h3"
                component="span"
                style={{ fontSize: '18px', fontWeight: 400, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
              >
                Supplier Actions
              </Typography>
            </SectionLabel>
          </div>
          <Spacer factor={1} />
          <div style={{ display: 'flex' }}>
            <NewItem
              styleOverride={{ minWidth: 270, width: 270, marginRight: 15 }}
              defaultSelectedItemType={snakeCase('supplier')}
              children={
                <Button variant="outlined" color="primary" className={classes.button}>
                  Request New Supplier
                </Button>
              }
              hideAddIcon
            />
            <a href="/category/approved-supplier" target="_blank" rel="noopener noreferrer">
              <Button variant="contained" color="primary" className={classes.button}>
                View approved supplier list
              </Button>
            </a>
          </div>
          <Spacer factor={1} />
          <BreakdownByStatus
            statusesCount={{
              approved: data.supplierManagementDashboard.approved,
              requested: data.supplierManagementDashboard.requested,
              needsRequalification: data.supplierManagementDashboard.needsRequalification,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <UpcomingRequalifications
            upcomingRequalifications={data.supplierManagementDashboard.upcomingRequalifications}
          />
        </Grid>
      </Page>
    </div>
  );
};

export default TrainingDashboard;
