import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import get from 'lodash/get';

import { Grid } from '@material-ui/core';

import { LongCard } from 'compositions';
import updateTitleMutation from '../../utils/gql/updateTitleMutation';
import { itemTypeFields } from '../../utils/gql/metadataQueries';
import mutation from 'utils/gql/createItemAndMember';

const metadataQuery = gql`
  query TPPandTPPC {
    itemTypes(
      names: [
        "target_product_profile_characteristic"
        "target_product_profile_category"
      ]
    ) {
      ...itemTypeFields
    }
  }
  ${itemTypeFields}
`;

function hasEditableTitleField(itemType) {
  if (!itemType) return false;
  let titleIsUserEditable = false;
  get(itemType, 'fields', []).forEach((field) => {
    if (field.fieldName === 'title' && field.userEditable === true)
      titleIsUserEditable = true;
  });
  return titleIsUserEditable;
}

const PayloadFields = ({ versionData }) => {
  return (
    <Query query={metadataQuery}>
      {({ data }) => {
        const itemTypeMetadata = get(data, 'itemTypes', []).reduce(
          (acc, itemType) => {
            if (itemType.name) acc[itemType.name] = itemType;
            return acc;
          },
          {},
        );

        return (
          <>
            {get(versionData, 'targetProductProfileCategories', []).map(
              (tppCategory) => {
                const collectionId = get(
                  tppCategory,
                  'currentVersion.targetProductProfileCharacteristicsCollectionId',
                );
                const tppCharacteristics = get(
                  tppCategory,
                  'currentVersion.targetProductProfileCharacteristics',
                  [],
                );
                return (
                  <Mutation mutation={updateTitleMutation}>
                    {(mutateTitle) => (
                      <Mutation mutation={mutation} awaitRefetchQueries>
                        {(
                          createItemMutation,
                          { loading: createItemMutationLoading },
                        ) => {
                          const handleTitleChange = (versionId) => (
                            newValue,
                          ) => {
                            mutateTitle({
                              variables: {
                                input: {
                                  versionId,
                                  attrName: 'title',
                                  stringValue: newValue,
                                },
                              },
                            });
                          };

                          return (
                            <Grid
                              item
                              xs={12}
                              style={{ padding: 0 }}
                              key={`${tppCategory.id}-container`}
                            >
                              <LongCard
                                key={tppCategory.id}
                                versionId={tppCategory.currentVersion.id}
                                title={tppCategory.currentVersion.title}
                                customIdentifier={tppCategory.customIdentifier}
                                helpTextEntries={[
                                  {
                                    label: 'Target Guidelines',
                                    text:
                                      tppCategory.currentVersion
                                        .targetGuidelines,
                                  },
                                  {
                                    label: 'Annotation Guidelines',
                                    text:
                                      tppCategory.currentVersion
                                        .annotationGuidelines,
                                  },
                                ]}
                                locked={true}
                                onClickPlus={() =>
                                  createItemMutation({
                                    variables: {
                                      input: {
                                        productId: 1,
                                        parentCollectionId: collectionId,
                                        itemType:
                                          'target_product_profile_characteristic',
                                        title: 'Default Title',
                                      },
                                    },
                                    refetchQueries: [
                                      'targetProductProfileQuery',
                                    ],
                                  })
                                }
                                controlsLoading={createItemMutationLoading}
                                showBody={false}
                                variant="vertical"
                                queryToRefetch="targetProductProfileQuery"
                              >
                                <Grid
                                  item
                                  xs={11}
                                  style={{ padding: 0, marginLeft: 'auto' }}
                                >
                                  {tppCharacteristics.map((tppc) => {
                                    return (
                                      <LongCard
                                        key={tppc.currentVersion.id}
                                        versionId={tppc.currentVersion.id}
                                        title={tppc.currentVersion.title}
                                        customIdentifier={tppc.customIdentifier}
                                        primaryBodyText={{
                                          attrName: 'target',
                                          attrValue: tppc.currentVersion.target,
                                        }}
                                        secondaryBodyText={{
                                          attrName: 'annotation',
                                          attrValue:
                                            tppc.currentVersion.annotation,
                                        }}
                                        onTitleChange={
                                          hasEditableTitleField(
                                            itemTypeMetadata.target_product_profile_characteristic,
                                          )
                                            ? handleTitleChange(
                                                tppc.currentVersion.id,
                                              )
                                            : undefined
                                        }
                                        locked={versionData.locked}
                                        queryToRefetch="targetProductProfileQuery"
                                        variant="horizontal"
                                        itemRecord={tppc}
                                        parentSetItemSlug="target-product-profiles"
                                      />
                                    );
                                  })}
                                </Grid>
                              </LongCard>
                            </Grid>
                          );
                        }}
                      </Mutation>
                    )}
                  </Mutation>
                );
              },
            )}
          </>
        );
      }}
    </Query>
  );
};
export default PayloadFields;
