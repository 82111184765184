import { Assignment } from '@material-ui/icons';
import Plagiarism from 'assets/icons/Plagiarism';
import Recommend from 'assets/icons/Recommend';
import Pending from 'assets/icons/Pending';

export const formOptions = [
  {
    value: 'byForm',
    Icon: Plagiarism,
    text: 'By Form',
  },
  {
    value: 'completedForms',
    Icon: Recommend,
    text: 'Completed Forms',
  },
  {
    value: 'incompleteForms',
    Icon: Pending,
    text: 'Incomplete Forms',
  },
  {
    value: 'allRecords',
    Icon: Assignment,
    text: 'All Records',
  },
];
