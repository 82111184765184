import React from 'react';
import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import { get } from 'lodash';

import DesignServices from 'assets/icons/DesignServices';

import { MakeLink } from 'components';
import slugs from '../../displaySlugs';
import PayloadFields from './PayloadFields';

const itemType = 'requirement';

const versionFields = `
  requirementTypes
  requirementDescription
  setMember {
    id
    setItemVersion {
      id
      item {
        id
        customIdentifier
      }
    }
  }
`;

const listViewColumns = [
  {
    field: 'currentVersion.setMember.setItemVersion',
    headerName: 'Associated Set',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        requirementCurrentVersion: {
          setMember: {
            setItemVersion: {
              item: {
                id: get(row.data.currentVersion.setMember, 'setItemVersion.item.id', null),
                customIdentifier: get(row.data.currentVersion.setMember, 'setItemVersion.item.customIdentifier', null),
                itemType: {
                  displaySlug: get(row.data.currentVersion.setMember, 'setItemVersion.item.itemType.displaySlug', null),
                  userCreatable: get(
                    row.data.currentVersion.setMember,
                    'setItemVersion.item.itemType.userCreatable',
                    null,
                  ),
                },
              },
            },
          },
        },
      });
    },
    render: function(row) {
      if (row.value === undefined) return null;
      let requirementCurrentVersion;
      const isColumnGroupedBy = Boolean(
        row.columnApi.columnModel.rowGroupColumns.find(
          (column) => column.colId === 'currentVersion.setMember.setItemVersion',
        ),
      );
      if (isColumnGroupedBy) {
        requirementCurrentVersion = JSON.parse(row.value).requirementCurrentVersion;
      } else {
        requirementCurrentVersion = row.data.currentVersion;
      }
      return requirementCurrentVersion.setMember === null ? (
        '-'
      ) : (
        <MakeLink
          item={{
            id: requirementCurrentVersion.setMember.setItemVersion.item.id,
            itemType: {
              displaySlug: requirementCurrentVersion.setMember.setItemVersion.item.itemType.displaySlug,
              userCreatable: requirementCurrentVersion.setMember.setItemVersion.item.itemType.userCreatable,
            },
          }}
          newTab={true}
        >
          {requirementCurrentVersion.setMember.setItemVersion.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.requirementDescription',
    headerName: 'Description',
    type: 'long_text',
  },
  {
    field: 'currentVersion.requirementTypes',
    headerName: 'Type',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (row.data === undefined) return null;
      const requirementTypes = row.data.currentVersion.requirementTypes;
      return requirementTypes ? requirementTypes.join(', ') : '';
    },
    render: (row) => {
      if (row.value === undefined) return null;
      return row.value;
    },
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query requirementsQuery {
    requirements {
      id
      customIdentifier
      itemType {
        id
        displaySlug
        userCreatable
      }
      createdAt
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      tags
      currentVersion {
        id
        createdAt
        title
        requirementDescription
        requirementTypes
        currentStatusName
        versionIdentifier
        setMember {
          id
          setItemVersion {
            id
            item {
              id
              customIdentifier
              itemType {
                id
                displaySlug
                userCreatable
              }
            }
          }
        }
        signingOwner {
          id
          firstName
          lastName
        }
        signingApprover {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  displayHistoryInEffectiveView: false,
  itemType,
  label: t`Requirement`,
  categoryLabel: t`Requirements`,
  icon: DesignServices,
  PayloadFields,
  versionFields,
  listViewColumns,
  listViewQuery,
};
