import React from 'react';
import flowRight from 'lodash/flowRight';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextArea } from 'components';
import { Trans } from '@lingui/macro';

import styles from './styles';
import { Spacer } from 'components';

import Signature from './Signature';
import withCurrentUser from '../../../WithCurrentUser';

class BackToPlanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
      username: null,
      password: null,
    };
  }

  render() {
    const { classes, error, dialog, handleComplete, itemTypeMetadata, errorDisplay } = this.props;
    const { reason, username, password } = this.state;

    return (
      <>
        <div className={classes.heading}>
          <Typography variant="h3">
            <Trans>Back to Planning</Trans>
          </Typography>
        </div>

        <Spacer factor={2} />

        <Typography variant="body2">
          Are you sure you want to move this Curriculum to Planning? This action shall allow editing.
        </Typography>

        <Spacer factor={2} />

        <TextArea
          required
          label="Reason For Transitioning to Planning"
          placeholder="Insert reason here*"
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows="2"
          InputLabelProps={{
            shrink: true,
          }}
          onTextChange={(reason) => this.setState({ reason })}
        />
        <Typography variant="caption" className={classes.textBoxCaption}>
          <Trans>*Required</Trans>
        </Typography>

        <Spacer factor={5} />

        <Signature
          itemType={itemTypeMetadata.name}
          error={error}
          errorDisplay={errorDisplay}
          disabled={!reason || !username || !password}
          handleSubmit={
            reason
              ? () =>
                  handleComplete({
                    username,
                    password,
                    reasonForSignature: `${dialog}: ${reason}`,
                  })
              : undefined
          }
          onUsernameChange={(e) => this.setState({ username: e.target.value })}
          onPasswordChange={(e) => this.setState({ password: e.target.value })}
        />
      </>
    );
  }
}

export default flowRight([withStyles(styles), withCurrentUser])(BackToPlanning);
