import React from 'react';

import { Typography } from '@material-ui/core';

import get from 'lodash/get';
import { Column } from 'material-table';

import DataTable from 'components/DataTable';
import { DateCell, dateHeaderStyle } from 'components/TableView/Fields/Date';
import { StatusCell, statusHeaderStyle } from 'components/TableView/Fields/Status';
import { RegularTextCell, regularTextHeaderStyle } from 'components/TableView/Fields/RegularText';
import { ShortTextCell } from 'components/TableView/Fields/ShortText';
import MakeLink from 'components/MakeLink';

import { TrainingCategory } from '__generated__/globalTypes';
import { dashboard_trainingRecordSearch as TrainingRecord } from '../__generated__/dashboard';

const RECORD_TABLE_COLUMNS: Column<TrainingRecord>[] = [
  {
    field: 'customIdentifier',
    title: 'ID',
    sorting: false,
    headerStyle: regularTextHeaderStyle,
    render: function(row) {
      return (
        <MakeLink item={row}>
          <ShortTextCell data={get(row, 'customIdentifier')} />
        </MakeLink>
      );
    },
  },
  {
    field: 'currentVersion.title',
    title: 'Title',
    sorting: true,
    headerStyle: regularTextHeaderStyle,
    render: function(row) {
      return <RegularTextCell data={get(row, 'currentVersion.title')} />;
    },
  },
  {
    field: 'currentVersion.currentStatus.name',
    title: 'Status',
    sorting: true,
    headerStyle: statusHeaderStyle,
    render: function(row) {
      return <StatusCell data={get(row, 'currentVersion.currentStatus.name')} />;
    },
    customSort: (a: any, b: any) => {
      const order = ['to_do', 'completed', 'failed'];
      const aPosition = order.indexOf(a.currentVersion.currentStatus.name);
      const bPosition = order.indexOf(b.currentVersion.currentStatus.name);
      const result = aPosition - bPosition;
      return result;
    },
  },
  {
    field: 'currentVersion.createdAt',
    title: 'Initiation Date',
    sorting: true,
    type: 'date',
    headerStyle: dateHeaderStyle,
    render: function(row) {
      return <DateCell data={get(row, 'currentVersion.createdAt')} />;
    },
  },
  {
    field: 'currentVersion.dueDate',
    title: 'Due Date',
    sorting: true,
    type: 'date',
    headerStyle: dateHeaderStyle,
    render: function(row) {
      return <DateCell data={get(row, 'currentVersion.dueDate')} />;
    },
  },
  {
    field: 'currentVersion.source.item.customIdentifier',
    title: 'Associated Course',
    sorting: false,
    headerStyle: regularTextHeaderStyle,
    render: function(row) {
      const category: TrainingCategory = get(row, 'currentVersion.category');
      return category === TrainingCategory.COURSE ? (
        <MakeLink item={get(row, 'currentVersion.source.item')} params={{ view: 'Effective' }}>
          <ShortTextCell data={get(row, 'currentVersion.source.item.customIdentifier')} />
        </MakeLink>
      ) : (
        <ShortTextCell data={'N/A'} />
      );
    },
  },
  {
    field: 'currentVersion.source.item.customIdentifier',
    title: 'Read & Understand Item',
    sorting: false,
    headerStyle: regularTextHeaderStyle,
    render: function(row) {
      const category: TrainingCategory = get(row, 'currentVersion.category');
      return category === TrainingCategory.READ_AND_UNDERSTAND ? (
        <MakeLink item={get(row, 'currentVersion.source.item')}>
          <ShortTextCell data={get(row, 'currentVersion.source.item.customIdentifier')} />
        </MakeLink>
      ) : (
        <ShortTextCell data={'N/A'} />
      );
    },
  },
];

const RecordsList: React.FC<{ data: TrainingRecord[] }> = ({ data }) => {
  if (data.length === 0) {
    return <Typography variant="caption">No associated training records.</Typography>;
  }

  return <DataTable sortable columns={RECORD_TABLE_COLUMNS} data={data} />;
};

export default RecordsList;
