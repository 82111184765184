import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { docStylePayloadFields } from 'itemTypes/mixins/DocumentBased/gql';

const deviceMasterRecordVersion = gql`
  query DeviceMasterRecordVersionQuery($id: ID!) {
    deviceMasterRecordVersion(id: $id) {
      ${docStylePayloadFields}
      description
      unresolvedSuggestions
      groups {
        id
        name
        info
        optional
        applicable
        subgroups {
          id
          name
          subgroupVersions {
            id
            version {
              id
              title
              item {
                id
                customIdentifier
                itemType {
                  id
                  displaySlug
                  userCreatable
                }
                currentVersion {
                  id
                  versionIdentifier
                  currentStatusName
                }
              }
            }
          }
        }
        headerOptions {
          name
        }
      }
    }
  }
`;

export function useDeviceMasterRecordVersion(id: string) {
  return useQuery<any, any>(deviceMasterRecordVersion, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

const addSection = gql`
  mutation AddDeviceMasterRecordSubgroup($groupId: ID!) {
    addDeviceMasterRecordSubgroup(groupId: $groupId) {
      subgroup {
        id
      }
    }
  }
`;

export const useAddSection = () => useMutation(addSection, { refetchQueries: ['DeviceMasterRecordVersionQuery'] });

const updateDeviceMasterRecordGroup = gql`
  mutation UpdateDeviceMasterRecordGroup($id: ID!, $applicable: Boolean) {
    updateDeviceMasterRecordGroup(id: $id, applicable: $applicable) {
      group {
        id
      }
    }
  }
`;

export const useUpdateDeviceMasterRecordGroup = () =>
  useMutation(updateDeviceMasterRecordGroup, { refetchQueries: ['DeviceMasterRecordVersionQuery'] });

const addDeviceMasterRecordSubgroupVersions = gql`
  mutation AddDeviceMasterRecordSubgroupVersions($id: ID!, $versionIds: [ID!]!) {
    addDeviceMasterRecordSubgroupVersions(id: $id, versionIds: $versionIds) {
      subgroup {
        id
      }
    }
  }
`;

export const useAddDeviceMasterRecordSubgroupVersions = () =>
  useMutation(addDeviceMasterRecordSubgroupVersions, { refetchQueries: ['DeviceMasterRecordVersionQuery'] });

const removeDeviceMasterRecordSubgroups = gql`
  mutation RemoveDeviceMasterRecordSubgroups($ids: [ID!]!) {
    removeDeviceMasterRecordSubgroups(ids: $ids) {
      subgroups {
        id
      }
    }
  }
`;

export const useRemoveDeviceMasterRecordSubgroups = () =>
  useMutation(removeDeviceMasterRecordSubgroups, { refetchQueries: ['DeviceMasterRecordVersionQuery'] });
