import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      margin: 'inherit',
      // padding: `${theme.spacing(5)}px ${theme.spacing(5)}px 0 ${theme.spacing(5)}px`,
    },
    modalContainer: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 322,
      padding: theme.spacing(2),
    },
    closeIcon: {
      float: 'right',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    titleContainer: {
      alignItems: 'center',
    },
    typography: {
      width: '100%',
      marginLeft: theme.spacing(),
    },
    tableContainer: {
      width: '100%',
    },
    licenseInformation: {
      background: 'white',
      marginTop: 36,
      padding: theme.spacing(2),
    },
    toolbar: {
      backgroundColor: theme.palette.background.default,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(2),
      minHeight: 'inherit',
      '& .MuiTextField-root': {
        width: 30,
        minWidth: 0,
        transition: ' all .5s',
        '& .MuiInputBase-adornedStart': {
          cursor: 'pointer',
        },
        '& .MuiInputAdornment-positionStart': {
          pointerEvents: 'none',
        },
        '& .MuiInputAdornment-positionEnd': {
          visibility: 'hidden',
          '& .Mui-disabled': {
            visibility: 'hidden',
          },
        },
        '&:focus-within': {
          width: 250,
          '& .MuiInputAdornment-positionEnd': {
            '&::placeholder-shown': {
              visibility: 'hidden',
            },
            visibility: 'visible',
          },
        },
      },
      '& .MuiInput-underline:hover:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
    },
    verticalBar: {
      transform: 'rotate(90deg) scaleX(-1)',
      width: '5%',
      marginTop: theme.spacing(1),
      borderRadius: '5px',
    },
    itemList: {
      display: 'flex',
    }
  }),
);
