import { get } from 'lodash';
import React from 'react';

import { Match } from '@reach/router';

import { FULL_CATEGORIES_INDEX } from 'categories';

/**
 * This HOC adds activeCategory as a prop to the component it wraps.
 * @param {React.Component} WrappedComponent A React component requiring this data
 * @returns {React.Component}
 */
const withActiveCategory = (WrappedComponent) => (componentProps) => {
  return (
    <Match path="/category/:categorySlug/*">
      {({ match }) => {
        const categorySlug = get(match, 'categorySlug');
        const activeCategory = FULL_CATEGORIES_INDEX[categorySlug];
        return <WrappedComponent {...componentProps} activeCategory={activeCategory} />;
      }}
    </Match>
  );
};

export default withActiveCategory;
