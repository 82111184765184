export default (theme) => ({
  searchHeading: {
    fontWeight: 600,
  },
  searchMenuWrapper: {
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(1.5),
    width: '100%',
  },
  searchTab: {
    fontSize: '0.625rem',
    lineHeight: 1.4,
    letterSpacing: '1px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.6)',
    textTransform: 'uppercase',
  },
  searchTabPanel: {
    '&[hidden]': {
      display: 'none',
    },
  },
  searchTabsWrapper: {
    backgroundColor: '#FFF',
    boxShadow: 'none',
    paddingBottom: theme.spacing(2),
  },
});
