import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Trans } from '@lingui/macro';

import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, withStyles } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { navigate } from '@reach/router';

import styles from './styles';

class TableDisplay extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      arrowIcon: PropTypes.string.isRequired,
      headerCell: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      tableContainer: PropTypes.string.isRequired,
      tableHeaderRow: PropTypes.string.isRequired,
      tableBodyRow: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keydownEventHandler);
  }

  handleRowNavigation = (itemId) => (event) => {
    if (event.code === 'Enter' || event.key === 'Enter' || event.keyCode === 13) {
      const {
        activeCategory: { slug: categorySlug },
      } = this.props;
      navigate(`/category/${categorySlug}/${itemId}`); // eslint-disable-line @typescript-eslint/no-floating-promises
    }
  };

  handleFocusOnRow = (itemId) => () => {
    this.keydownEventHandler = this.handleRowNavigation(itemId);
    window.addEventListener('keydown', this.keydownEventHandler);
  };

  handleBlurOnRow = () => {
    window.removeEventListener('keydown', this.keydownEventHandler);
  };

  render() {
    const {
      activeCategory: { slug: categorySlug },
      tableData,
      classes,
      columnToSort,
      handleSort,
      tableColumns,
      sortDirection,
    } = this.props;

    return (
      <Paper className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              {tableColumns.map(({ key, label }) => (
                <TableCell
                  className={classes.headerCell}
                  key={`tableHeaderCell-${key}`}
                  onClick={() => handleSort(key)}
                >
                  <Tooltip title="Sort" enterDelay={300}>
                    <span role="columnheader" className={classes.headerName}>
                      <Trans id={label} />
                    </span>
                  </Tooltip>
                  {columnToSort === key &&
                    (sortDirection === 'asc' ? (
                      <ArrowUpward className={classes.arrowIcon} />
                    ) : (
                      <ArrowDownward className={classes.arrowIcon} />
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item) => (
              <TableRow
                classes={{ root: classes.tableBodyRow }}
                hover
                href={`/category/${categorySlug}/${item.id}`}
                key={`tableRow-${item.id}`}
                onClick={() => navigate(`/category/${categorySlug}/${item.id}`)}
                onFocus={this.handleFocusOnRow(item.id)}
                onBlur={this.handleBlurOnRow}
                role="link"
                tabIndex="1"
              >
                {tableColumns.map(({ key }) => (
                  <TableCell key={`tableCell-${key}`} data-testid={`tableCell-${key}`}>
                    {item[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(TableDisplay);
