import React, { useRef, useState } from 'react';

import { withStyles } from '@material-ui/core';

import flowRight from 'lodash/flowRight';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { ContentSection, Spacer } from 'components';
import RichTextEditor, { OptionsToolbar, TrackChangesToolbar } from 'components/RichTextEditor';
import { ActionTypes } from 'components/RichTextEditor/TrackChangesToolbar';

const RichTextEditorContentSection = ({
  attrName,
  Heading,
  showComments,
  initialValue,
  locked,
  optionSet,
  refetchQueries,
  versionId,
  theme,
}) => {
  const editorRef = useRef<any>();
  const [commentsVisible, setCommentsVisible] = useState(showComments !== false);
  const [commentsDisplayVariant, setCommentsDisplayVariant] = useState('narrowSidebar');
  const [commentsCount, setCommentsCount] = useState<number>(0);

  const [trackChangesToggle, setTrackChangesToggle] = useState('suggesting');
  const [suggestionsCount, setSuggestionsCount] = useState<number>(0);
  const [trackChangesAction, setTrackChangesAction] = useState<ActionTypes | null>(null);

  return (
    <ContentSection
      LabelText={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>{Heading}</span>
          <div style={{ display: 'flex' }}>
            <OptionsToolbar
              showComments={commentsVisible}
              onToggleComments={(visible) => setCommentsVisible(visible)}
              commentsDisplayVariant={commentsDisplayVariant}
              setCommentsDisplayVariant={setCommentsDisplayVariant}
              commentsCount={commentsCount}
            />
            <TrackChangesToolbar
              editorRef={editorRef}
              trackChangesToggle={trackChangesToggle}
              suggestionsCount={suggestionsCount}
              setTrackChangesToggle={setTrackChangesToggle}
              setTrackChangesAction={setTrackChangesAction}
              disabled={locked}
            />
          </div>
        </div>
      }
    >
      <Spacer factor={1} />

      <RichTextEditor
        editorRef={editorRef}
        locked={locked}
        initialValue={initialValue}
        fixedToolbarOffset={theme.mixins.header.height}
        versionId={versionId}
        attrName={attrName}
        refetchQueries={refetchQueries}
        optionSet={optionSet}
        commentsDisplayVariant={commentsDisplayVariant}
        showComments={commentsVisible}
        setCommentsCount={setCommentsCount}
        trackChangesToggle={trackChangesToggle}
        trackChangesAction={trackChangesAction}
        setSuggestionsCount={setSuggestionsCount}
      />
    </ContentSection>
  );
};

export default flowRight([withStyles(null as any, { withTheme: true }), withWorkspaceViews()])(
  RichTextEditorContentSection,
);
