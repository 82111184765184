import React from 'react';

import { WorkflowTransition } from 'compositions/TimelineGroup/steps/types';
import { DeviationWorkflowStatuses } from 'workflows/statuses';
import { VoidReason } from 'compositions/TimelineGroup/steps/views';

export default {
  initialStep: 'voidApproveToProceed',
  internalSteps: {
    voidApproveToProceed: {
      component: (props) => {
        return <VoidReason relevantRole={props.currentVersion.assignedOwnerJobRole.formattedRoleName} {...props} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: ({ voidApproveToProceed }) => ({
    toStatus: DeviationWorkflowStatuses.VoidApproveToProceed.id,
    ...voidApproveToProceed.signature,
    reasonForTransition: voidApproveToProceed.reason,
  }),
} as WorkflowTransition;
