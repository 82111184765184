import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    input: {
      padding: '10.5px 15px',
      position: 'relative',
      '&::-webkit-calendar-picker-indicator': {
        background: 'none',
      },
    },
    datePickerInputAdornment: {
      position: 'absolute',
      top: '50%',
      translate: 'transformY(-50%)',
      right: 12.5,
      pointerEvents: 'none',
    },
    notchedOutline: {
      border: `2px solid ${theme.palette.common.input.outline} !important`,
    },
    textField: {
      width: '100%',
      paddingRight: 0,
    },
    panel: {
      width: '100%',
    },
    autocompleteInputRoot: {
      '& .MuiInputBase-root': {
        height: 36,
        borderRadius: 4,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      },
      '& .MuiInputBase-input': {
        position: 'relative',
        bottom: 3,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        // @ts-ignore
        border: `2px solid ${theme.palette.common.input.outline}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        // @ts-ignore
        border: `2px solid ${theme.palette.common.input.outline}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }),
);
