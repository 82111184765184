export default (theme) => ({
  '@global': {
    '@media print': {
      '#context-drawer': {
        display: 'none',
      },
    },
  },
  drawerPaper: {
    borderLeft: `2px solid ${theme.palette.grey[200]}`,
    borderTop: `2px solid ${theme.palette.grey[200]}`,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '75vh',
    overflow: 'hidden',
    top: 'auto',
    width: 320,
  },
  headerContainer: {
    backgroundColor: theme.palette.background.contextDrawerHeader,
    // boxShadow: `2px 2px 2px 0 ${theme.palette.secondary.main}`,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  headerText: {
    fontWeight: 600,
  },
  optionsTab: {
    ...theme.buttonMixins.justIcon,
    borderRadius: '50%',
    clear: 'both',
    color: theme.palette.action.active,
  },
  inactive: {
    opacity: 0.6,
  },
  active: {
    opactiy: 1,
  },
  tabContainer: {
    backgroundColor: theme.palette.grey[300],
    boxShadow: `${theme.palette.secondary.main} 2px 7px 16px 2px`,
    borderRadius: '20px 0px 0px 20px',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
    paddingTop: 10,
    position: 'fixed',
    top: '25vh',
    opacity: 0.6,
    // intended to show badges but below high index material elements
    zIndex: theme.zIndex.mobileStepper - 1,
    '&:hover': {
      opacity: 1.0,
    },
  },
});
