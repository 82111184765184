import React from 'react';

import { RouteComponentProps } from '@reach/router';

import BuilderView from 'components/BuilderView';
import EffectiveView from 'components/EffectiveView';
import VersionsView from 'components/VersionsView';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';

import { Views } from 'workspaces';

import PayloadFields from './PayloadFields';

interface Props extends RouteComponentProps {
  itemId: string;
  activeWorkspaceView: { id: string };
}

function Page(props: Props) {
  switch (props.activeWorkspaceView.id) {
    case Views.Effective.id:
      return <EffectiveView categorySlug="risk" itemType="risk" itemId={props.itemId} PayloadFields={PayloadFields} />;
    case Views.Versions.id:
      return <VersionsView categorySlug="risk" itemType="risk" itemId={props.itemId} PayloadFields={PayloadFields} />;
    default:
      return <BuilderView categorySlug="risk" itemType="risk" itemId={props.itemId} PayloadFields={PayloadFields} />;
  }
}

export default withWorkspaceViews()(Page);
