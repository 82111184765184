import gql from 'graphql-tag';

const query = gql`
  query EffectiveViewQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      currentVersion {
        id
        currentStatus {
          id
          name
        }
        ... on CybersecurityReportVersion {
          body
          pdfDataUpload
          inputMethod
        }
      }
      versions {
        id
        releasedAt
        locked
        title
        currentStatusName
        versionIdentifier
      }
      currentRelease {
        id
        locked
        title
      }
      currentUser {
        id
        readOnly
      }
    }
  }
`;

export default query;
