import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  versionToItemTable: {
    '& th:nth-of-type(2)': {
      paddingLeft: 18,
    },
    '& td:nth-of-type(2)': {
      paddingLeft: 18,
      borderLeft: '2px solid #ebeced',
    },
  },
}));
