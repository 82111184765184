import React, { useState } from 'react';

import { useMutation } from 'react-apollo';

import Input from 'components/core/form/Input';
import BaseDialog from 'components/BaseDialog';
import Button from 'components/Button';

import { CREATE_HARM } from './gql';

import formatApolloError from 'utils/formatApolloError';

function AddHarmDialog(props) {
  const [currentHarmName, setCurrentHarmName] = useState('');
  const [error, setError] = useState(null);
  const [createHarm] = useMutation(CREATE_HARM);

  function handleCancel() {
    setCurrentHarmName('');
    setError(null);

    props.onCancel();
  }

  function handleAdd() {
    createHarm({ variables: { name: currentHarmName } })
      .then((result) => {
        setError(null);
        setCurrentHarmName('');
        props.onAdd(result.data.createHarm.harm);
      })
      .catch((e) => {
        setError(formatApolloError(e));
      });
  }

  return (
    <BaseDialog
      actions={
        <>
          <Button fullWidth color="primary" onClick={handleCancel} variant="outlined">
            Cancel
          </Button>
          <Button
            fullWidth
            color="primary"
            disabled={!currentHarmName || !currentHarmName.length}
            onClick={handleAdd}
            variant="contained"
          >
            Submit
          </Button>
        </>
      }
      error={error}
      onClose={handleCancel}
      open={props.open}
      title="Create New Harm"
    >
      <Input
        placeholder="Harm Name"
        name="harm"
        onChange={(e) => {
          setCurrentHarmName(e.target.value);
        }}
        value={currentHarmName}
      />
    </BaseDialog>
  );
}

export default AddHarmDialog;
