import React from 'react';

import Error from '@material-ui/icons/Error';

import BaseDialog from 'components/BaseDialog';
import { VersionAndStatusDot } from 'components';

import { useStyles } from './styles';
import QuotedSection from 'components/core/QuotedSection';
import { Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import linkToItem from 'utils/linkToItem';

interface Course {
  customIdentifier: string;
  currentStatus: string;
  id: string;
  versionIdentifier: string;
}

interface Props {
  courses: Course[];
  onClose: () => void;
}

function UnreleasedCoursesDialog(props: Props) {
  const classes = useStyles();

  return (
    <BaseDialog open title="Not Allowed" onClose={props.onClose} Icon={Error} size="large">
      <Typography component="p" variant="h5">
        This <strong>Training Plan</strong> cannot be transitioned because the following course
        {props.courses.length > 1 ? 's' : ''} do not have an Effective version:
      </Typography>
      <QuotedSection className={classes.container}>
        <ul className={classes.list}>
          {props.courses.map((course) => (
            <li className={classes.listItem} key={course.id}>
              <Link to={linkToItem('courses', course.id)}>
                <Typography variant="h6" component="span" className={classes.courseId}>
                  {course.customIdentifier}
                </Typography>
              </Link>
              <VersionAndStatusDot version={course.versionIdentifier} status={course.currentStatus} />
            </li>
          ))}
        </ul>
      </QuotedSection>
    </BaseDialog>
  );
}

export default UnreleasedCoursesDialog;
