import React from 'react';
import get from 'lodash/get';
import { Trans } from '@lingui/macro';

export function buildReleaseMessage(props) {
  return `As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I approve ${
    props.itemCustomIdentifier
  } for release.`;
}

export function buildActionMessage(action, currentVersion, itemCustomIdentifier) {
  return (
    <Trans>
      Do you want to {action} version {get(currentVersion, 'versionIdentifier')} of {itemCustomIdentifier}?
    </Trans>
  );
}
