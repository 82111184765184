import React, { useContext, useState } from 'react';

import { ClickAwayListener, Input, Typography } from '@material-ui/core';
import { Clear, Link } from '@material-ui/icons';

import clsx from 'clsx';

import { LockedContext } from 'contexts/lockedContext';
import IconButton from 'components/IconButton';
import { ItemSearch } from 'compositions';
import MakeLink from 'components/MakeLink';
import { useDebounce } from 'hooks/useDebounce';
import { useToggleablePopover } from 'hooks/useToggleablePopover';
import Views from 'workspaces/views';

import { useStyles } from './styles';

interface Props {
  focusInput?: boolean;
  index: number;
  onTextUpdate: (updatedText: string) => void;
  onItemUpdate: (updatedItemId: string) => Promise<any>;
  onRemove: () => void;
  highlight: boolean;
  text: string;
  item?;
  activeWorkspaceView: string;
}

function SetupStep(props: Props) {
  const classes = useStyles();
  const { locked } = useContext(LockedContext);
  const debouncedOnChange = useDebounce(props.onTextUpdate);
  const [toggled, anchor, setAnchor] = useToggleablePopover();

  const [editingText, setEditingText] = useState(props.focusInput || false);

  const renderStepByView = (viewId: string) =>
    viewId === Views.Effective.id ? (
      <MakeLink item={props.item} newTab>
        {props.item.customIdentifier}: {props.item.currentVersion.title}
      </MakeLink>
    ) : (
      <>
        {viewId === Views.Effective.id && (
          <IconButton includeBackground Icon={Link} onClick={(e) => !locked && setAnchor(e.currentTarget)} />
        )}
        <span className={classes.itemTitle}>
          <MakeLink item={props.item} newTab>
            {props.item.customIdentifier}: {props.item.currentVersion.title}
          </MakeLink>
        </span>
        {toggled && (
          <ItemSearch
            defaultSearchTerm={null}
            anchorEl={anchor}
            closeSelf={() => {
              setAnchor(null);
            }}
            onSubmit={(selectedItems) =>
              props
                .onItemUpdate(selectedItems[0])
                .then(() => true)
                .catch(() => false)
            }
            open
            usesCurrentVersion={false}
          />
        )}
      </>
    );

  return (
    <Typography
      component="div"
      variant="body2"
      className={clsx(classes.container, props.highlight && classes.selectedBorder)}
    >
      <div className={classes.index}>{props.index}</div>
      <div className={classes.content}>
        {props.item ? (
          renderStepByView(props.activeWorkspaceView)
        ) : editingText ? (
          <ClickAwayListener onClickAway={() => setEditingText(false)}>
            <Input
              autoFocus
              style={{ width: '80%' }}
              disableUnderline
              defaultValue={props.text}
              onChange={(e) => debouncedOnChange(e.target.value)}
              disabled={locked}
            />
          </ClickAwayListener>
        ) : (
          <div className={classes.text} onClick={() => !locked && setEditingText(true)}>
            {props.text || 'Please add description of test setup step'}
          </div>
        )}
      </div>
      {props.highlight && (
        <div className={classes.deleteIcon}>
          <IconButton includeBackground tooltip="Remove Setup Step" onClick={() => props.onRemove()} Icon={Clear} />
        </div>
      )}
    </Typography>
  );
}

export default SetupStep;
