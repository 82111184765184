import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      height: theme.spacing(60.5),
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    categoriesSection: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
      overflowY: 'scroll',
      flex: 1,
    },
    title: {
      '& > span': {
        marginLeft: theme.spacing(1.5),
      },
    },
    cornerLink: {
      position: 'absolute',
      right: 20,
      top: 17,
      color: '#33BCB4',
      fontSize: '13px',
      fontWeight: 600,
    },
  }),
);
