import React, { Component } from 'react';
import flowRight from 'lodash/flowRight';

import { Paper, createStyles, withStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { Spacer } from 'components';
import { produce } from 'immer';

import withCurrentUser from 'compositions/WithCurrentUser';
import { WorkflowTransition, WorkflowWizardView } from 'compositions/TimelineGroup/steps/types';

const styles = (theme) =>
  createStyles({
    root: {
      width: theme.spacing(45),
      padding: theme.spacing(3),
    },
    backArrow: {
      float: 'left',
      '&:hover': { cursor: 'pointer' },
    },
    closeIcon: {
      float: 'right',
      '&:hover': { cursor: 'pointer' },
    },
  });

interface TransitionWizardProps {
  steps: WorkflowTransition;
  error?;
  classes;
  currentUser;
  itemTypeMetadata?;
  onCompleteTransition: (nextItemData: any) => Promise<any>;
  onAbandonTransition: () => void;
  currentVersion;
  customIdentifier: string;
}

class TransitionWizard extends Component<TransitionWizardProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      prevSteps: [],
      step: null,
      unsavedChanges: {},
    };
  }

  currentStep = () => this.state.step || this.props.steps.initialStep;

  handleStepComplete = async (data) => {
    const currentStep = this.currentStep();
    const { steps } = this.props;
    const { getNextStep }: WorkflowWizardView = steps.internalSteps[currentStep];
    const nextStep = getNextStep(data);

    const unsavedChanges = produce(this.state.unsavedChanges, (draft) => {
      draft[currentStep] = data;
    });

    if (nextStep === 'complete') {
      const payload = steps.getFinalResult(unsavedChanges);
      await this.props.onCompleteTransition(payload);
      return;
    }

    let history = this.state.prevSteps;
    history.push(currentStep);
    this.setState({
      unsavedChanges,
      step: nextStep,
      prevSteps: history,
    });
  };

  handleBack = () => {
    const currentStep = this.currentStep();
    const unsavedChanges = produce(this.state.unsavedChanges, (draft) => {
      draft[currentStep] = null;
    });
    let prevSteps = this.state.prevSteps;
    const step = prevSteps.pop();
    this.setState({ unsavedChanges, step, prevSteps });
  };

  render() {
    const {
      classes,
      error,
      onAbandonTransition,
      steps,
      currentVersion,
      currentUser,
      customIdentifier,
      itemTypeMetadata,
    } = this.props;
    const { unsavedChanges } = this.state;
    const currentStep = this.currentStep();
    const StepComponent = steps.internalSteps[currentStep].component;

    return (
      <Paper className={classes.root} elevation={1}>
        <div>
          {currentStep !== steps.initialStep && (
            <ArrowBack className={classes.backArrow} viewBox="4 4 24 24" onClick={this.handleBack} color="action" />
          )}
          <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={onAbandonTransition} color="action" />
        </div>
        <Spacer factor={2} />
        <StepComponent
          error={error}
          handleComplete={this.handleStepComplete}
          handleClose={onAbandonTransition}
          unsavedValue={unsavedChanges[currentStep]}
          currentVersion={currentVersion}
          currentUser={currentUser}
          itemCustomIdentifier={customIdentifier}
          itemTypeMetadata={itemTypeMetadata}
        />
      </Paper>
    );
  }
}

export default flowRight([withStyles(styles), withCurrentUser])(TransitionWizard);
