import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  headingContainer: {
    display: 'flex',
    marginBottom: 17,
    position: 'relative',
  },
  noticeContainer: {
    transform: 'translateY(12px)',

    right: '0',
    bottom: '2px',
    position: 'absolute',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },

  noticeContainerWidthUnlocked: {
    // this means:
    // - take 60px out (at the right of rows buttons take that space)
    // - take 3/4 of the remaining (skip the size of one of 4 equal columns)
    // - return that 60px back (that right space)
    width: 'calc((100% - 60px) * 3 / 4 + 60px)',
  },
  noticeContainerWidthLocked: {
    width: 'calc((100% - 0px) * 3 / 4 + 0px)', // no right action button
  },
  noticeHeading: {
    color: theme.palette.primary.main,
  },
  noticeMessage: {
    paddingLeft: 20,
  },
}));
