import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  '@global': {
    '#link-popper': {
      zIndex: 9999,
    },
  },
  advancedSearchLink: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  buttonSection: {
    padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cssOutlinedInput: {
    ...theme.typography.body2,
    height: 36,
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  cssFocused: {},
  notchedOutline: {
    border: `2px solid ${theme.palette.common.input.outline} !important`,
  },
  linkModalRoot: {
    width: theme.spacing(60),
    minHeight: theme.spacing(14),
  },
  results: {
    // overflowY: 'scroll',
    maxHeight: theme.spacing(20),
    padding: theme.spacing(2),
    // paddingTop: 0,
  },
  padding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  searchResult: {
    paddingLeft: theme.spacing(5.75),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedRow: {
    backgroundColor: 'rgba(48, 188, 179, 0.1)',
  },
  splitter: {
    marginTop: 0,
    marginBottom: theme.spacing(3.5),
    color: theme.palette.splitter.background,
    borderStyle: 'solid',
    borderWidth: 0.5,
  },
  header: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    fontWeight: 'bold',
  },
  margin: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  noResults: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  search: {
    fontSize: 20,
    marginLeft: theme.spacing(1),
    color: theme.palette.blueDark,
  },

  suggestedItems: {
    color: '#3C4858',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'capitalize',
    fontWeight: 600,
    marginLeft: '16px',
  },
  fullItemList: {
    color: '#33BCB4',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    cursor: 'pointer',
    padding: '4px 8px',
    fontWeight: 600,
  },
  suggested: {
    color: '#33BCB4',
    fontSize: '12px',
    letterSpacing: '0.05em',
    lineHeight: '14px',
    textTransform: 'uppercase',
    // cursor: "pointer",
    padding: '4px 8px',
    backgroundColor: 'rgba(48, 188, 179, 0.2)',
    fontWeight: 600,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
  },
  rightHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginRight: '16px',
  },
  containerSuggestedItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(1),
    marginRight: '0px',
  },
  icon: {
    height: 13,
    width: 11.86,
    left: '12.5%',
    right: '12.5%',
    top: '12.5%',
    bottom: '12.5%',
    color: '#6E7583',
    marginRight: '2px',
  },
  typography: {
    height: 20,
    marginLeft: '4px',
    letterSpacing: '0.02em',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  arrowIcon: {
    left: '37.49%',
    right: '35.07%',
    top: '75.01%',
    bottom: '-21.57%',
    height: 13,
    color: '#6E7583',
    transform: 'matrix(1, 0, 0, -1, 0, 0)',
  },
}));
