import gql from 'graphql-tag';

export const TOGGLE_PREREQUISITES = gql`
  mutation TogglePrerequisites($id: ID!, $toggle: Boolean) {
    updateDeviceVerification(id: $id, togglePrerequisites: $toggle) {
      deviceVerification {
        id
      }
    }
  }
`;
