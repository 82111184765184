import { t } from '@lingui/macro';
import ListAlt from '@material-ui/icons/ListAlt';

import { docMetadata } from '../mixins/Document';
import slugs from '../../displaySlugs';

const itemType = 'biologicalEvaluationPlan';

export default {
  displaySlug: slugs[itemType],
  itemType,
  icon: ListAlt,
  label: t`Biological Evaluation Plan`,
  categoryLabel: t`Biological Evaluation Plan`,
  ...docMetadata,
};
