import React from 'react';
import { startCase, camelCase } from 'lodash';

import WorkflowIcon from 'components/WorkflowIcon';
import ConnectBar from './ConnectBar';

const STATUS_NAMES = [
  'supplier_requested',
  'ready_for_prequalification',
  'prequalification_complete',
  'ready_for_qualification',
  'qualification_complete',
  'supplier_approved',
  'qualification_rejected',
  'qualification_revoked',
  'archived',
  'draft',
  'ready_for_review',
  'owner_approval',
  'released',
  'rejected',
];

const MAPPING = STATUS_NAMES.reduce((acc, status) => {
  return { ...acc, [status]: { label: startCase(status), type: camelCase(status) } };
}, {});

interface StatusSegmentProps {
  version?: any;
  statusName: string;
  show?: boolean;
  showBar?: boolean;
  disabled?: boolean;
  active?: boolean;
  completed?: boolean;
  wideLabel?: boolean;
  onClick?: any;
  workflowType?: string;
}

export function StatusSegment(props: StatusSegmentProps) {
  const {
    version,
    statusName,
    show = true,
    showBar = true,
    disabled = false,
    active = false,
    completed = false,
    onClick = undefined,
    wideLabel = false,
    workflowType,
  } = props;
  const found = MAPPING[statusName];

  if (found) {
    return (
      <>
        <ConnectBar color={found.type} disabled={disabled} visible={show && showBar} />

        <WorkflowIcon
          version={version}
          size="medium"
          type={found.type}
          active={active}
          completed={completed}
          label={found.label}
          visible={show}
          disabledEffect={disabled}
          handleClick={onClick}
          wideLabel={wideLabel}
          workflowType={workflowType}
        />
      </>
    );
  }

  return <pre>no status match: ${statusName}</pre>;
}
