import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { TRIO_FIELDS } from 'components/TableTrio/gql';

import { VerificationAndValidationPlanVersionQuery } from './__generated__/VerificationAndValidationPlanVersionQuery';

export const GET_VERIFICATION_AND_VALIDATION_PLAN_VERSION = gql`
  query VerificationAndValidationPlanVersionQuery($id: ID!) {
    verificationAndValidationPlanVersion(id: $id) {
      id
      title
      displayAsApplicationVersion
      versionIdentifier
      item {
        id
        customIdentifier
      }
      currentStatus {
        id
        name
      }
      acceptanceCriteria
      approach
      deviationManagement
      locked
      planSummary
      planToggle
      purpose
      referencedAttachments {
        id
        url
        description
      }
      referencedItems {
        id
        item {
          id
          currentVersion {
            id
            currentStatus {
              name
            }
            title
            versionIdentifier
          }
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      responsibilities
      scope
      summary
      title
      verificationProtocols {
        id
        text
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
          currentVersion {
            id
            title
            versionIdentifier
            currentStatus {
              id
              name
            }
          }
        }
        verificationRecords {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            userCreatable
            itemType {
              id
              displaySlug
            }
            currentVersion {
              id
            }
            currentRelease {
              id
              versionIdentifier
              currentStatusName
              currentStatus {
                id
                name
              }
            }
            workingVersion {
              id
              versionIdentifier
              currentStatusName
              currentStatus {
                id
                name
              }
            }
          }
        }
      }
      validationProtocols {
        id
        text
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
          currentVersion {
            id
            title
            versionIdentifier
            currentStatus {
              id
              name
            }
          }
        }
        validationRecords {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            userCreatable
            itemType {
              id
              displaySlug
            }
            currentVersion {
              id
            }
            currentRelease {
              id
              versionIdentifier
              currentStatusName
              currentStatus {
                id
                name
              }
            }
            workingVersion {
              id
              versionIdentifier
              currentStatusName
              currentStatus {
                id
                name
              }
            }
          }
        }
      }
      ${TRIO_FIELDS}
    }
  }
`;

export const UPDATE_VERIFICATION_AND_VALIDATION_PLAN_VERSION = gql`
  mutation UpdateVerificationAndValidationPlanVersion(
    $id: ID!
    $acceptanceCriteria: String
    $approach: String
    $deviationManagement: String
    $planSummary: String
    $planToggle: Boolean
    $purpose: String
    $responsibilities: String
    $scope: String
  ) {
    updateVerificationAndValidationPlanVersion(
      id: $id
      acceptanceCriteria: $acceptanceCriteria
      approach: $approach
      deviationManagement: $deviationManagement
      planToggle: $planToggle
      planSummary: $planSummary
      purpose: $purpose
      responsibilities: $responsibilities
      scope: $scope
    ) {
      verificationAndValidationPlanVersion {
        id
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<VerificationAndValidationPlanVersionQuery>(GET_VERIFICATION_AND_VALIDATION_PLAN_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}
