import {
  GridRef,
  applyColumnState,
  getFilterInstance,
  onFilterChanged,
  resetFilters,
  resetColumnState,
  setModel,
} from 'utils/viewOptionUtils';
import { RecordWorkflowStatuses } from 'workflows/statuses';

/**
 * Handles resetting filters and column states
 */

const reset = (gridRef: GridRef) => {
  resetFilters(gridRef);
  resetColumnState(gridRef);
};

/**
 *
 * @param gridRef
 * @param values
 *
 * @description Sets status filter model with given parameters
 *
 */
const setStatusModel = (gridRef: GridRef, values: string[]) => {
  const status = getFilterInstance(gridRef, 'currentVersion.currentStatus.name');
  setModel(status, values);
};

/**
 * Handles completed forms option filtering
 */
const byForm = (gridRef: GridRef) => {
  reset(gridRef);
  onFilterChanged(gridRef);
  applyColumnState(gridRef, [{ colId: 'currentVersion.formCustomIdentifier', hide: false, rowGroup: true }]);
  applyColumnState(gridRef, [{ colId: 'currentVersion.formVersion', hide: false, rowGroup: true }]);
};

/**
 * Handles completed forms option filtering
 */
const completedForms = (gridRef: GridRef) => {
  reset(gridRef);
  setStatusModel(gridRef, [RecordWorkflowStatuses.Checked.id]);
  onFilterChanged(gridRef);
  applyColumnState(gridRef, [{ colId: 'currentVersion.releasedAt', sort: 'asc' }]);
};

/**
 * Handles incomplete forms option filtering
 */
const incompleteForms = (gridRef: GridRef) => {
  reset(gridRef);
  setStatusModel(gridRef, [RecordWorkflowStatuses.InProgress.id, RecordWorkflowStatuses.Created.id]);
  onFilterChanged(gridRef);
  applyColumnState(gridRef, [{ colId: 'customIdentifier', sort: 'asc' }]);
};

/**
 * Handles showing all form records option filtering
 */
const allRecords = (gridRef: GridRef, _currentUser: any) => {
  reset(gridRef);
  applyColumnState(gridRef, [{ colId: 'customIdentifier', sort: 'asc' }]);
};

const formFunctionsMap = new Map([
  ['byForm', byForm],
  ['completedForms', completedForms],
  ['incompleteForms', incompleteForms],
  ['allRecords', allRecords],
]);

export { formFunctionsMap };
