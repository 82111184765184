import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    icon: {
      color: theme.palette.blueDark,
    },
    notAllowed: {
      cursor: 'not-allowed !important' as any,
      pointerEvents: 'auto !important' as any,
      '& *': {
        cursor: 'not-allowed !important' as any,
        pointerEvents: 'auto !important' as any,
      },
    },
    inputContainer: {
      display: 'inline-block',
      position: 'relative',
      width: 450,
      minHeight: '100%',
      '& .MuiInputBase-root': {
        height: 42,
      },
    },
    subgroupHeader: {
      '& p': {
        lineHeight: 3,
      },
    },
    buttonFill: {
      '& .MuiIconButton-root svg': {
        fill: '#3c4858',
      }
    },
    inputButtonContainer: {
      '& .MuiIconButton-root svg': {
        fill: '#3c4858',
      },
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 10,
    },
    subgroup: {
      outline: 'solid 1px #dfdfdf',
      borderRadius: '6px',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },

    sectionHeaderRow: {
      display: 'flex',
      marginLeft: -11,
    },

    itemNameInput: {
      ...theme.typography.h3,
      '&:after': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    selectMenu: {
      ...theme.typography.body2,
      '& .MuiSelect-root': {
        padding: 0,
        paddingLeft: 10,
      },
    },
    itemTypeText: {
      marginLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
    },
    menuOption: {
      ...theme.typography.body2,
      borderTop: `2px solid ${theme.palette.common.input.outline}`,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '&:hover': {
        background: theme.palette.common.input.hover,
      },
    },
  }),
);
