import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './corporate-fare.svg';

function CorporateFare(props) {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <Icon {...svgProps} />} />;
}

export default CorporateFare;
