import gql from 'graphql-tag';

export const DEACTIVATE_USER_JOB_ROLE = gql`
  mutation DeactviateUserJobRole(
    $userId: ID!
    $jobRoleId: ID!
    $reasonForChange: String!
    $username: String!
    $password: String!
  ) {
    deactivateUserJobRole(
      userId: $userId
      jobRoleId: $jobRoleId
      reasonForChange: $reasonForChange
      username: $username
      password: $password
    ) {
      user {
        id
        jobRoles {
          id
          formattedRoleName
        }
      }
    }
  }
`;
