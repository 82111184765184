import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import Extension from '@material-ui/icons/Extension';
import PayloadFields from './PayloadFields';
import slugs from '../../displaySlugs';

const itemType = 'part';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.description',
    headerName: 'Description',
    type: 'long_text',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.manufacturerReferenceNumber',
    headerName: 'Manufacturer Reference Number',
    type: 'id',
  },
  {
    field: 'owners',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approvers',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query partsQuery {
    parts {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      owners {
        id
      }
      approvers {
        id
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        body
        versionIdentifier
        currentStatusName
        manufacturerReferenceNumber
        description
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Part`,
  categoryLabel: t`Parts`,
  icon: Extension,
  listViewColumns,
  listViewQuery,
  PayloadFields,
};
