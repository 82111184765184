import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'token';
const VALID_TOKEN_BUFFER = 10 * 1000;

function getTokenPayload(token) {
  try {
    return jwtDecode(token);
  } catch (e) {
    return {};
  }
}

function isTokenValid({ exp }) {
  const expMS = exp * 1000;
  return expMS > Date.now() - VALID_TOKEN_BUFFER;
}

function validateToken(token) {
  const tokenPayload = getTokenPayload(token);
  if (isTokenValid(tokenPayload)) {
    return [token, tokenPayload];
  }
  window.memoryDB.removeItem(TOKEN_KEY);
  return [null, {}];
}

function getTokenFromMemory() {
  const token =
    window.memoryDB &&
    window.memoryDB.getItem(TOKEN_KEY) &&
    JSON.parse(window.memoryDB.getItem(TOKEN_KEY));
  return token || null;
}

/**
 * Retrieves a valid auth token from memoryDB. If token appears invalid
 * or has expired it will be cleared and return null.
 *
 * @returns {Array.<string|null, Object>} the non-expired auth token and
 *          payload returned as a "tuple"
 */
function getAuthToken() {
  return validateToken(getTokenFromMemory());
}

export default getAuthToken;
