import React from 'react';

import { Button, LinearProgress, Typography } from '@material-ui/core';
import ReportProblem from '@material-ui/icons/Warning';

import { Spacer } from 'components';
import BaseDialog from 'components/BaseDialog';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  jobRoles: any;
  userFullName: string;
}

function CannotDeactivateUser(props: Props) {
  const classes = useStyles();
  const { isOpen, jobRoles, onClose, userFullName } = props;

  return (
    <BaseDialog
      open={isOpen}
      title="Cannot Deactivate User"
      Icon={ReportProblem}
      size="large"
      onClose={onClose}
      textAlign="left"
      actions={
        <Button onClick={onClose} color="primary" variant="contained" fullWidth>
          Confirm
        </Button>
      }
    >
      <Typography variant="body2">
        <div>
          <span style={{ fontWeight: 600 }}>{userFullName}</span> has the following roles currently assigned to them.
        </div>
        <Spacer factor={2} />
        Please visit the Role Management page to remove these roles before attempting to deactivate this user:
        <Spacer factor={2} />
        {jobRoles.map((role) => {
          if (role.formattedRoleName === 'General') return null;
          return (
            <div style={{ marginLeft: '-10px' }}>
              <div className={classes.itemList}>
                <LinearProgress className={classes.verticalBar} color="primary" variant="determinate" value={100} />
                <span style={{ fontWeight: 600 }}>
                  {role.customIdentifier}: {role.formattedRoleName}
                </span>
              </div>
            </div>
          );
        })}
      </Typography>
    </BaseDialog>
  );
}

export default CannotDeactivateUser;
