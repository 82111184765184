import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    cardWrapper: {
      display: 'flex',
      '& .cardContainer': {
        '&:not(:last-child)': {
          marginRight: theme.spacing(2),
        },
      },
      '& .cardColor': {
        display: 'none',
      },
    },
  }),
);
