import React, { useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Trans } from '@lingui/macro';

import compact from 'lodash/compact';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import snakeCase from 'lodash/snakeCase';
import { useQuery } from 'react-apollo';

import { Blur, Breadcrumbs, ItemTitle, Spacer, WorkflowIcon, NoReleaseModal } from 'components';
import EffectiveViewData from 'compositions/EffectiveViewData';
import { useMetadata } from 'compositions/WithMetadataFromServer';

import styles from './styles';
import query from './query';
import { I18n } from '@lingui/react';
import { PayloadFieldsComponent } from 'itemTypes';
import { Tab } from 'components/Tab';

interface VersionViewProps {
  categorySlug: string;
  classes;
  itemRecord?;
  itemId: string;
  itemType: string;
  PayloadFields: PayloadFieldsComponent;
  VersionsViewOptions?: (props: {
    pdfExportHeading: string;
    pdfExportSubheading: string;
    versionId: string;
  }) => JSX.Element;
}

function VersionsView({
  categorySlug,
  classes,
  itemRecord: itemRecordFromProps,
  itemId,
  itemType,
  PayloadFields,
  VersionsViewOptions,
}: VersionViewProps) {
  const [selectedTab, setSelectedTab] = useState(0);

  const { data: metadata, loading: metadataLoading } = useMetadata(itemType);
  const { data, loading: queryLoading, error } = useQuery(query, {
    fetchPolicy: 'network-only',
    skip: itemRecordFromProps, // if we are passed data, no need to query
    variables: {
      itemId,
      itemType: snakeCase(itemType),
    },
  });
  if (metadataLoading || queryLoading) return null;
  if (error) throw error;
  if (!metadata) throw new Error(`Unable to retrieve metadata for ${itemType}`);

  const { userCreatable } = metadata.itemType;
  const itemRecord = itemRecordFromProps || data.item;

  const versions = get(itemRecord, 'versions', []).filter(
    (r) => r.currentStatusName === 'retired' || r.releasedAt !== null,
  );
  const { ...itemFields } = itemRecord;

  const selectedIx = versions.length - 1 - selectedTab;
  const versionData = versions[selectedIx];
  const itemTitle = get(versionData, 'title', '');

  const isRetired = (version) => version.currentStatusName === 'retired';
  const disableVersionView = versions.length === 0;

  return (
    <div className={classes.container}>
      <Grid container className={classes.spacerTop}>
        {get(versionData, 'currentStatusName') === 'obsolete' && (
          <Typography component="div" className={classes.warningMsg} variant="body2">
            <span className={classes.strong}>Obsolete Version:</span>
            &nbsp;This version is obsolete and for viewing purposes only
          </Typography>
        )}
      </Grid>
      <Grid container className={classes.spacerTop}>
        {get(versionData, 'currentStatusName') === 'retired' && (
          <Typography component="div" className={classes.warningMsg} variant="body2">
            <span className={classes.strong}>Retired Version:</span>
            &nbsp;This version is retired and for viewing purposes only
          </Typography>
        )}
      </Grid>
      <Grid container data-testid="item" spacing={3}>
        <Grid item xs={8} className={classes.grid}>
          <Breadcrumbs categorySlug={categorySlug} itemTitle={`${itemFields.customIdentifier}: ${itemTitle}`} />

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {VersionsViewOptions && (
              <Blur blur={disableVersionView} style={{ width: '100%' }}>
                <VersionsViewOptions
                  pdfExportHeading={compact([itemRecord.customIdentifier, get(versionData, 'title')]).join(': ')}
                  pdfExportSubheading="Versions View"
                  versionId={get(versionData, 'id')}
                />
              </Blur>
            )}
          </div>

          <div className={classes.tabContainer}>
            {versions.map(({ id, versionIdentifier, currentStatusName }, ix) => (
              <Tab
                text={`${versionIdentifier} ${startCase(currentStatusName)}`}
                key={id}
                active={ix === selectedIx}
                onClick={() => setSelectedTab(versions.length - 1 - ix)}
                icon={<WorkflowIcon size="tiny" type={currentStatusName} completed />}
              />
            ))}
          </div>
        </Grid>

        <Grid container>
          <Spacer factor={4} />
        </Grid>
        {!disableVersionView && (
          <Grid container>
            <ItemTitle
              editable={false}
              itemTitle={get(versionData, 'title')}
              titlePrefix={`${get(itemRecord, 'customIdentifier', '')}`}
              versionId={get(versionData, 'id')}
            />
          </Grid>
        )}
        <EffectiveViewData
          asOf={get(versionData, 'releasedAt')}
          blur={disableVersionView}
          itemType={itemType}
          itemId={itemId}
          userCreatable={userCreatable}
          displayHistoryTable
        />

        {PayloadFields && (
          <I18n>
            {({ i18n }) => (
              <Blur blur={disableVersionView} style={{ width: '100%', display: 'flex' }}>
                {versions.map(
                  (rev, ix) =>
                    ix === selectedIx &&
                    (!isRetired(rev) ? (
                      <PayloadFields
                        key={rev.id}
                        displayOnly
                        layoutClasses={classes}
                        versionData={rev || { id: 'BLUR' }}
                        i18n={i18n}
                        versionId={rev.id}
                        itemRecord={itemRecord}
                      />
                    ) : (
                      <>
                        <Spacer factor={2} />
                        <Typography variant="h3">
                          <Trans>This item has been Retired.</Trans>
                        </Typography>
                      </>
                    )),
                )}
              </Blur>
            )}
          </I18n>
        )}

        <NoReleaseModal open={disableVersionView} viewName="Version View" />
      </Grid>
    </div>
  );
}

export default withStyles(styles)(VersionsView);
