import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    riskCodeItems: {
      display: 'flex',
    },
    riskCodeItem: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,

      '&:not(:last-child)': {
        marginRight: theme.spacing(8),
      },
    },
    riskCodeColorItem: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
      borderRadius: 2,
    },
    riskColorTitle: {
      color: theme.palette.blueDark,
    },
    inputLabel: {
      color: theme.palette.blueDark,
    },
    inputLabelDisabled: {
      color: theme.palette.subHeading,
    },
    inputFields: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
);
