import React from 'react';

import { MTableHeader } from 'material-table';

import { Column, ColumnGroups } from './index';

export function addColumnGroups(columns: Column[]) {
  return (props, _emptyObject) => {
    return (
      <>
        <ColumnGroups columns={columns} />
        <MTableHeader {...props} />
      </>
    );
  };
}
