import React, { FC, useState, useRef, useEffect } from 'react';

import LinearScale from '@material-ui/icons/LinearScale';
import { Typography } from '@material-ui/core';

import flowRight from 'lodash/flowRight';

import { AlertDialog, AlertDialogWithUser, ContentSection } from 'components';
import WorkflowMenu, { ArchiveMenuItem } from 'components/WorkflowMenu';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import { GenericRecordWorkflowStatuses, indexById } from 'workflows/statuses';
import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';
import ConnectBar from 'compositions/TimelineGroup/components/ConnectBar';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

import PermissionFailModal from './PermissionFailModal';
import WithCurrentUser from '../../WithCurrentUser';
import withMetadataFromServer from '../../WithMetadataFromServer';
import { StatusHistory } from './StatusHistory';
import { StatusSegment } from './StatusSegment';
import { Unlocked } from './Unlocked';

const { ReadyForReview, OwnerApproval, Released } = GenericRecordWorkflowStatuses;

const StatusById = indexById(GenericRecordWorkflowStatuses);

interface Props {
  currentUser: any;
  currentCompany: any;
  currentCompanyValidatedSystem: any;
  currentCompanyProfessionalLicense: any;
  currentCompanyAllowsTransitioning: any;
  currentVersion: any;
  handleInitTransition: any;
  permissions: any;
  itemType: any;
  remoteItemTypeMetadata: any;
}

interface DeniedModalState {
  open: boolean;
  user: object | any;
  role: string | any;
}

const PATHS = [['draft', 'ready_for_review', 'owner_approval', 'released']];

const REPLACE = [
  {
    match: ({ current, previous }) => current === 'draft' && previous === 'ready_for_review',
    render: () => <Unlocked name="readyForReview" />,
  },
];

const ITEMS_WITH_RO = ['supplierQuestionnaireRecord'];

const GenericRecordWorkflow: FC<Props> = (props) => {
  const {
    permissions: { canOwn, canApprove },
    remoteItemTypeMetadata,
  } = props;

  const [deniedModal, setDeniedModal] = useState({
    open: false,
    heading: '',
    body: '',
  });
  const [deniedModalWithUser, setDeniedModalWithUser] = useState<DeniedModalState>({
    open: false,
    user: null,
    role: null,
  });

  const handleUnresolvedSuggestionsDeniedModal = () => {
    setDeniedModal({
      open: true,
      heading: 'Pending Suggestions in your document',
      body: `There are tracked changes that need to be resolved before this status transition can take place.`,
    });
  };

  const closeModal = () => {
    setDeniedModal((prevState) => ({ ...prevState, open: false }));
  };

  const [modal, setModal] = useState({ open: false, type: '' });

  const scrollRef = useRef(null as any);
  useEffect(() => {
    scrollRef.current && (scrollRef.current as any).scrollBy(999999999, 0);
  }, []);

  const workflowControls = () => {
    const { currentCompanyAllowsTransitioning, currentVersion, handleInitTransition } = props;
    if (!currentCompanyAllowsTransitioning) return;

    const status = StatusById[currentVersion.currentStatusName];

    const canArchive = canOwn && status.isEqualTo(Released);
    const doArchive = () => handleInitTransition('archived');

    return (
      <>
        <WorkflowMenu>
          <ArchiveMenuItem disabled={!canArchive} onClick={doArchive} />
        </WorkflowMenu>
      </>
    );
  };

  const { currentCompanyAllowsTransitioning, currentVersion, handleInitTransition, itemType } = props;
  const {
    approver,
    assignedApproverJobRole,
    assignedOwnerJobRole,
    currentStatusName,
    owner,
    unresolvedSuggestions,
  } = currentVersion;
  const currentWorkflowStatus = StatusById[currentStatusName];

  const currentPath = PATHS.find((x) => !!x.find((name) => name === currentStatusName));
  const currentPathPoint = currentPath && currentPath.indexOf(currentStatusName);
  const showStatus = (name) => {
    if (!currentPath || currentPathPoint == null) return false;
    const itsIndex = currentPath.indexOf(name);
    if (itsIndex === -1) return false;
    return itsIndex > currentPathPoint;
  };

  const hasRO = (item_type) => {
    return ITEMS_WITH_RO.includes(item_type);
  };

  const inactiveUsers = getInactiveUsers(owner, approver);
  const usersWithoutActiveRole = getUsersWithoutActiveRole(
    owner,
    approver,
    assignedOwnerJobRole,
    assignedApproverJobRole,
  );

  const handleInactiveUserDeniedModal = (inactiveUsers) => {
    setDeniedModalWithUser({
      open: true,
      user: inactiveUsers[0].user,
      role: inactiveUsers[0].role,
    });
  };

  const handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    setDeniedModalWithUser({
      open: true,
      user: usersWithoutActiveRole[0].user,
      role: usersWithoutActiveRole[0].role,
    });
  };

  const FutureSegment = ({ name, onClick }) => {
    return (
      <StatusSegment
        statusName={name}
        show={showStatus(name)}
        active={false}
        completed={false}
        disabled={!currentCompanyAllowsTransitioning}
        onClick={onClick}
      />
    );
  };

  return (
    <ContentSection LabelText={'Workflow'} Icon={<LinearScale />} Controls={workflowControls()}>
      <div style={{ overflowX: 'scroll', paddingTop: 30 }} ref={scrollRef}>
        <SmallCircle
          content={'RO'}
          color="created"
          handleClick={() => handleInitTransition('recordOfOrigin')}
          visible={hasRO(itemType)}
        />
        <ConnectBar color="draft" short={true} visible={hasRO(itemType)} />
        <StatusHistory
          workflowType={'GenericRecordWorkflow'}
          statuses={currentVersion.item.statusHistory}
          disabled={!currentCompanyAllowsTransitioning}
          replace={REPLACE}
          isWorkflowSignatureShown={remoteItemTypeMetadata.isWorkflowSignatureShown}
          version={currentVersion}
        />

        <PermissionFailModal
          open={modal.open}
          failType={modal.type}
          closeModal={() => setModal({ ...modal, open: false })}
        />
        <FutureSegment
          name="ready_for_review"
          onClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            if (inactiveUsers.length) return handleInactiveUserDeniedModal(inactiveUsers);
            if (usersWithoutActiveRole.length) return handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);

            if (currentWorkflowStatus.isBefore(ReadyForReview) && currentCompanyAllowsTransitioning)
              return handleInitTransition('readyForReview');
          }}
        />

        <FutureSegment
          name="owner_approval"
          onClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });
            if (unresolvedSuggestions) {
              return handleUnresolvedSuggestionsDeniedModal();
            }

            if (!canOwn) return setModal({ open: true, type: 'permissions' });

            if (currentWorkflowStatus.isBefore(OwnerApproval) && currentCompanyAllowsTransitioning)
              return handleInitTransition('ownerApproval');
          }}
        />

        <FutureSegment
          name="released"
          onClick={() => {
            if (!currentCompanyAllowsTransitioning)
              return setModal({
                open: true,
                type: 'itemTransitioning',
              });

            if (!canApprove) return setModal({ open: true, type: 'permissions' });

            if (currentWorkflowStatus.isBefore(Released) && currentCompanyAllowsTransitioning)
              return handleInitTransition('releaseOrReject');
          }}
        />
      </div>
      <div>
        <AlertDialog open={deniedModal.open} onClose={closeModal} titleText={deniedModal.heading}>
          <Typography variant="body2">{deniedModal.body}</Typography>
        </AlertDialog>
        <AlertDialogWithUser
          open={deniedModalWithUser.open}
          titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
          onClose={() => setDeniedModalWithUser({ open: false, user: null, role: null })}
          subtitle={
            inactiveUsers.length
              ? 'The following user has been deactivated.'
              : "The following user's role has been removed."
          }
          user={deniedModalWithUser.user}
          role={deniedModalWithUser.role === 'owner' ? assignedOwnerJobRole : assignedApproverJobRole}
          body={`Please use the People Menu to reassign the ${
            deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
          } role to a new user.`}
        />
      </div>
    </ContentSection>
  );
};

export default flowRight(
  WithCurrentUser,
  withCurrentCompany,
  withMetadataFromServer(),
)(GenericRecordWorkflow);
