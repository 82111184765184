import React from 'react';
import { useStyles } from './styles';
import ExpandCarrot from 'components/core/ExpandCarrot';

interface Props {
  expanded: boolean;
  setExpanded: (newState: boolean) => void;
}

function Collapse(props: Props) {
  const classes = useStyles();
  return (
    <span className={classes.expand}>
      <ExpandCarrot {...props} size="medium" />
    </span>
  );
}

export default Collapse;
