import { TraceMatrixWorkflowStatuses } from 'workflows/statuses';
import { CancelReason } from 'compositions/TimelineGroup/steps/views';

export default {
  initialStep: 'cancel',
  internalSteps: {
    cancel: {
      component: CancelReason,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: ({ cancel }) => ({
    toStatus: TraceMatrixWorkflowStatuses.Canceled.id,
    ...cancel.signature,
    reasonForTransition: cancel.reason,
  }),
};
