import React, { FC } from 'react';
import flowRight from 'lodash/flowRight';
import { orderBy, flatMap, map, get } from 'lodash';
import { Query } from 'react-apollo';

import { useStyles } from './styles';
import WithCurrentUser from 'compositions/WithCurrentUser';
import NotificationListItem from './NotificationListItem';

import { userNotificationsQuery, userNotificationsQueryVariables } from './__generated__/userNotificationsQuery';
import { USER_NOTIFICATIONS_QUERY } from './gql';
import { currentUser_currentUser } from '../__generated__/currentUser';

interface Props {
  currentUser: currentUser_currentUser;
  closeSelf: () => void;
}

enum Notifications {
  esignatureRequired = 'esignatureRequiredNotifications',
  tasks = 'taskNotifications',
  training = 'trainingNotifications',
  mentions = 'mentionNotifications',
}

const categories = Object.values(Notifications);

const NotificationsMenu: FC<Props> = (props) => {
  const { currentUser, closeSelf } = props;
  const classes = useStyles();

  return (
    <div className={classes.notificationsMenuWrapper}>
      <Query<userNotificationsQuery, userNotificationsQueryVariables>
        query={USER_NOTIFICATIONS_QUERY}
        fetchPolicy="cache-and-network"
        variables={{
          userId: currentUser.id,
        }}
      >
        {({ data, error }) => {
          if (error) throw new Error(`${error}`);

          let notifications = flatMap(categories, (category) => {
            return map(get(data, `user.${category}`, []), (item) => {
              return { ...item, category };
            });
          });

          notifications = orderBy(notifications, (notification) => new Date(notification.createdAt), 'desc');

          return (
            <>
              {notifications.map((notification) => (
                <NotificationListItem
                  key={`${notification.category}-${notification.id}`}
                  currentUser={currentUser}
                  notification={notification}
                  onRedirect={closeSelf}
                />
              ))}
            </>
          );
        }}
      </Query>
    </div>
  );
};

export default flowRight(WithCurrentUser)(NotificationsMenu);
