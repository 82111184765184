import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

interface Props {
  onClick: () => void;
  active: boolean;
  icon?: ReactNode;
  text: string;
  className?: string;
}

export function Tab(props: Props) {
  const classes = useStyles();
  return (
    <Typography
      component="div"
      onClick={props.onClick}
      className={clsx(props.active ? classes.active : classes.inactive, classes.tab, props.className)}
    >
      {props.icon && props.icon}
      <div
        style={{
          width: '6px',
          height: '1px',
          display: 'inline-block',
        }}
      />
      <Typography className={classes.text} variant="overline">
        {props.text}
      </Typography>
    </Typography>
  );
}

export default Tab;
