import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    description: {
      marginBottom: theme.spacing(2),
    },
    selectLabel: {
      display: 'block',
      marginBottom: theme.spacing(1),
    },
    actions: {
      display: 'flex',
      width: '100%',
    },
    button: {
      flex: 1,
    },
  }),
);
