import React, { useEffect, useContext } from 'react';

import { PayloadFieldsProps } from 'itemTypes';
import { PDFData } from 'components/v2';
import { withLockedContext, LockedContext } from 'contexts/lockedContext';

import AssociatedSupplierCard from './AssociatedSupplierCard/index';
import { useVersion } from './gql';
import { InputMethod } from '__generated__/globalTypes';

interface Props {
  inputMethod: InputMethod;
  itemType: string;
}

function PayloadFields(props: Props & PayloadFieldsProps) {
  const { inputMethod } = props;
  const { data, loading, error, refetch } = useVersion(props.versionId || '');
  const { setLocked, locked } = useContext(LockedContext);

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching Quality Agreement version: ${err}`);
    });
  }, [inputMethod, refetch]);

  useEffect(() => {
    if (data) setLocked(data.qualityAgreementVersion.locked);
  }, [data, setLocked]);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Quality Agreement version ${props.versionId}\n${error}`);

  const currentVersion = data.qualityAgreementVersion;

  return <>
    <AssociatedSupplierCard currentVersion={currentVersion} locked={locked}/>
    <PDFData versionData={currentVersion} layoutClasses={props.layoutClasses} refetch={refetch} />
  </>;
}

export default withLockedContext(PayloadFields);
