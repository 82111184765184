import React, { FC } from 'react';
import { Mutation } from 'react-apollo';
import get from 'lodash/get';
import { Link } from '@reach/router';

import HistoryIcon from '@material-ui/icons/History';
import GenericNotificationListItem from '.';
import ItemIdAndTitle from './components/ItemIdAndTitle';
import VerbAndAssignor from './components/VerbAndAssignor';
import NotificationCTA from './components/NotificationCTA';

import {
  updateTrainingCompletedMutationVariables,
  updateTrainingCompletedMutation_updateTrainingCompleted,
} from './__generated__/updateTrainingCompletedMutation';
import { trainingMutation } from './gql';
import { userNotificationsQuery_user_trainingNotifications } from '../../__generated__/userNotificationsQuery';
import linkToItem from 'utils/linkToItem';

interface Props {
  notification: userNotificationsQuery_user_trainingNotifications;
  onRedirect: () => void;
}

const TrainingBody: FC<Props> = ({ notification, onRedirect }) => {
  const payload = {
    variables: {
      input: {
        notificationId: notification.id,
        completed: !notification.completed,
      },
    },
  };

  const ItemTitle = () => (
    <>
      <ItemIdAndTitle
        link={linkToItem(
          get(notification, 'training.item.itemType.displaySlug'),
          get(notification, 'training.item.id'),
        )}
        onRedirect={onRedirect}
        identifier={get(notification, 'training.item.customIdentifier')}
        title={get(notification, 'training.title')}
      />
      <VerbAndAssignor
        beforeVerb="assigned by"
        userId={get(notification, 'assignedBy.id')}
        fullName={get(notification, 'assignedBy.fullName')}
      />
    </>
  );

  return (
    <Mutation<updateTrainingCompletedMutation_updateTrainingCompleted, updateTrainingCompletedMutationVariables>
      mutation={trainingMutation}
      refetchQueries={['userNotificationsQuery', 'currentUser']}
    >
      {(mutate, { error }) => {
        if (error) throw new Error(`${error}`);

        return (
          <GenericNotificationListItem
            headlineIcon={HistoryIcon}
            headlineText={notification.headline}
            itemTitle={<ItemTitle />}
            createdAt={notification.createdAt}
            completed={notification.completed}
            onComplete={() => mutate(payload)}
            callToAction={
              <Link to={`/category/training-records/${notification.training.item.id}`} onClick={onRedirect}>
                <NotificationCTA icon={<HistoryIcon />} text="Go to My Assigned Training" />
              </Link>
            }
          />
        );
      }}
    </Mutation>
  );
};

export default TrainingBody;
