import React from 'react';

import { YesOrNo } from 'compositions/TimelineGroup/steps/views';

export default {
  initialStep: 'newVersion',
  internalSteps: {
    newVersion: {
      component: (props) => (
        <YesOrNo
          disableOnSubmit
          prompt={`Do you want to create a new version of ${props.itemCustomIdentifier}?`}
          closeOnNo
          yesLabel="Yes"
          noLabel="No"
          {...props}
        />
      ),
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: () => ({
    gqlMethod: 'createVersion',
  }),
};
