import React from 'react';

import { Tags } from 'compositions';
import { Popover } from 'components';
import { tagsMutation } from 'utils/gql/tagQueries';
import { useMutation } from 'react-apollo';

function MemberTags({ anchorEl, itemId, tags, onClose, queryToRefetch, open }) {
  const [updateItemTags] = useMutation(tagsMutation, { refetchQueries: queryToRefetch });

  return (
    <Popover data-testid="tags-popover" title="Tags" open={open} anchorEl={anchorEl} onClose={onClose}>
      <Tags
        tags={tags}
        onSelected={(tags) => {
          updateItemTags({
            variables: {
              itemId,
              tags: tags,
            },
          }).catch((err) => {
            throw err;
          });
        }}
      />
    </Popover>
  );
}

export default MemberTags;
