import React from 'react';

import { Grid } from '@material-ui/core';

import { Spacer } from 'components';
import Page from 'components/core/Page';
import ScrollableComments from 'compositions/ScrollableComments';

import SupportWidget from 'components/SupportWidget';
import PrecomplianceDiagram from './PrecomplianceDiagram';
import PrecomplianceTaskList from './PrecomplianceTaskList';

export interface PrecomplianceDashboardProps {
  path: string;
}

const PrecomplianceDashboard: React.FC<PrecomplianceDashboardProps> = () => {
  return (
    <div style={{ marginTop: -35 }}>
      <Page>
        <Grid item xs={8} style={{ minHeight: 165 }}>
          <PrecomplianceTaskList />
          <Spacer factor={2} />
          <PrecomplianceDiagram />
        </Grid>
        <Grid item xs={4}>
          <SupportWidget />
          <Spacer factor={2} />
          <ScrollableComments />
        </Grid>
      </Page>
    </div>
  );
};

export default PrecomplianceDashboard;
