import React, { Component } from 'react';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';
import { Query } from 'react-apollo';

import { withStyles } from '@material-ui/core';

import TimelineMutations from 'compositions/TimelineGroup/components/TimelineMutations';
import styles from 'compositions/TimelineGroup/styles';
import withMetadataFromServer from 'compositions/WithMetadataFromServer';
import WithCurrentUser from 'compositions/WithCurrentUser';
import WorkflowModal from 'components/WorkflowModal';
import { matchPermissionGrantForVersion } from 'compositions/TimelineGroup/util';

import { query as timelineQuery } from 'utils/gql/timelineQueries';

import Wizard from './Wizard';
import RiskNoteTimeline from './RiskNoteTimeline';
import steps from '../../steps/RiskNote';
import format from './format';
import { RetirementStatus } from './RetirementStatus';
import RetirementTimeline from './RetirementTimeline';

interface Props {
  itemType: string;
  itemId: string;
  classes: any;
  itemData?: any;
  loading?: boolean;
  remoteItemTypeMetadata;
  currentUser: object;
  queriesToRefetch: string[];
}

interface State {
  selectedTransition: string | null;
  scrolledOnce: boolean;
}

class RiskNoteWorkflow extends Component<Props, State> {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  state: State = {
    selectedTransition: null,
    scrolledOnce: false,
  };

  constructor(props: Props) {
    super(props);
    this.scrollContainerRef = React.createRef();
  }

  scrollWorkflowToCurrent = () => {
    if (!this.state.scrolledOnce) {
      const workflowScrollContainer = this.scrollContainerRef;
      if (workflowScrollContainer && workflowScrollContainer.current) {
        // set position of scroll container to last item based on container width
        workflowScrollContainer.current.scrollLeft = workflowScrollContainer.current.scrollWidth;
      }

      this.setState({ scrolledOnce: true });
    }
  };

  componentDidUpdate() {
    this.scrollWorkflowToCurrent();
  }

  handleInitTransition = (stepName) => {
    this.setState({ selectedTransition: stepName });
  };

  closeModal = () => {
    this.setState({ selectedTransition: null });
  };

  renderTimeline = (currentRelease, currentVersion, previousVersions) => {
    const { currentUser, itemType } = this.props;

    const permissions = {
      canApprove: matchPermissionGrantForVersion(currentUser, currentVersion, 'approver'),
    };

    if (
      currentVersion.currentStatusName === RetirementStatus.RetirementInitiated ||
      currentVersion.currentStatusName === RetirementStatus.ApprovedRetirement ||
      currentVersion.currentStatusName === RetirementStatus.RetirementRejected
    )
      return (
        <RetirementTimeline
          permissions={permissions}
          previousVersions={previousVersions}
          currentVersion={currentVersion}
          handleInitTransition={this.handleInitTransition}
        />
      );
    else
      return (
        <RiskNoteTimeline
          itemType={itemType}
          currentRelease={currentRelease}
          // FIXME: a hack until creating a new version is supported
          currentVersion={
            currentVersion.releasedAt || currentVersion.currentStatusName === 'canceled'
              ? { currentStatusName: 'not_created' }
              : currentVersion.retiredAt
              ? { currentStatusName: 'retired' }
              : currentVersion
          }
          permissions={permissions}
          previousVersions={previousVersions}
          handleInitTransition={this.handleInitTransition}
        />
      );
  };

  render() {
    const { classes, itemData, itemType, loading, remoteItemTypeMetadata, queriesToRefetch = [] } = this.props;
    if (loading) return null;

    if (!this.props.itemData) throw new Error(`No timeline data returned for ${itemType}:${this.props.itemId}`);

    // FIXME: ideally, the server can return data in the exact format needed
    const { currentRelease, currentVersion, previousVersions } = format(itemData);

    return (
      <div className={classes.timelineBtnContainer} ref={this.scrollContainerRef}>
        {this.renderTimeline(currentRelease, currentVersion, previousVersions)}

        <WorkflowModal open={Boolean(this.state.selectedTransition)} onClose={this.closeModal}>
          <TimelineMutations refetch={queriesToRefetch}>
            {({ error, ...mutationFunctions }) => {
              const handleCompleteTransition = async (nextItemData, versionId, mutationFunctions) => {
                const { createTransition, createVersion, updateTimeline } = mutationFunctions;

                const { itemId } = this.props;

                let mutate;
                let input = { versionId, ...nextItemData };
                if (nextItemData === null) {
                  this.closeModal();
                  return;
                } else if (nextItemData.toStatus) {
                  mutate = createTransition;
                } else if (nextItemData.gqlMethod === 'createVersion') {
                  mutate = createVersion;
                  if (itemId) {
                    input = { itemId };
                  }
                } else {
                  mutate = updateTimeline;
                }

                return mutate({ variables: { input } })
                  .then(() => {
                    if (!error) this.closeModal();
                  })
                  .catch((err) => {
                    throw new Error(`Failed to perform transition: ${err.message}`);
                  });
              };

              return (
                <Wizard
                  error={error}
                  itemTypeMetadata={remoteItemTypeMetadata}
                  onCompleteTransition={async (nextItemData) =>
                    await handleCompleteTransition(nextItemData, currentVersion.id, mutationFunctions)
                  }
                  onAbandonTransition={this.closeModal}
                  steps={this.state.selectedTransition ? steps[this.state.selectedTransition] : null}
                  currentVersion={currentVersion}
                  customIdentifier={itemData.customIdentifier}
                />
              );
            }}
          </TimelineMutations>
        </WorkflowModal>
      </div>
    );
  }
}

function withData(Component) {
  return (props: Props) => {
    return (
      <Query errorPolicy="all" query={timelineQuery} variables={{ itemId: props.itemId }} fetchPolicy="network-only">
        {(result) => {
          const { data, loading, error } = result;
          if (error) throw new Error(`Error in timeline query: ${error.message}`);

          return <Component itemData={get(data, 'item')} loading={loading} {...props} />;
        }}
      </Query>
    );
  };
}

export default flowRight([withStyles(styles), withMetadataFromServer(), WithCurrentUser, withData])(RiskNoteWorkflow);
