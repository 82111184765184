import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

const updateDeviceMasterRecordSubgroup = gql`
  mutation UpdateDeviceMasterRecordSubgroup($id: ID!, $name: String) {
    updateDeviceMasterRecordSubgroup(id: $id, name: $name) {
      subgroup {
        id
      }
    }
  }
`;

export const useUpdateDeviceMasterRecordSubgroup = () =>
  useMutation(updateDeviceMasterRecordSubgroup, { refetchQueries: ['DeviceMasterRecordVersionQuery'] });

const removeSubgroupVersion = gql`
  mutation RemoveSubgroupVersion($id: ID!) {
    removeSubgroupVersion(id: $id) {
      subgroupVersion {
        id
      }
    }
  }
`;

export const useRemoveSubgroupVersion = () =>
  useMutation(removeSubgroupVersion, { refetchQueries: ['DeviceMasterRecordVersionQuery'] });
