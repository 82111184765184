import React from 'react';

import NoRiskSelected from 'compositions/TimelineGroup/components/NoRiskSelected';
import { CertifiedSignature, YesOrNo } from 'compositions/TimelineGroup/steps/views';
import { SupplierWorkflowStatuses } from 'workflows/statuses';

const TO_STATUS = SupplierWorkflowStatuses.PrequalificationComplete.id;

export default {
  initialStep: 'checkRiskLevel',
  internalSteps: {
    checkRiskLevel: {
      component: NoRiskSelected,
      getNextStep: (_) => 'takeOwnership',
    },
    takeOwnership: {
      component: (props) => (
        <YesOrNo
          disableOnSubmit
          prompt={`Do you want to take ownership of version ${props.currentVersion.versionIdentifier}
          of ${props.itemCustomIdentifier}`}
          yesLabel="Take Ownership"
          noLabel="Cancel"
          {...props}
        />
      ),
      getNextStep: (takeOwnership) => (takeOwnership ? 'signature' : 'complete'),
    },
    signature: {
      component: (props) => {
        const heading = 'PreQualification Complete';
        const dialog = `My signature indicates that the Supplier information including category, associated risk, and planned qualification activities have been identified. I verify that any high level risks have been communicated to management.`;

        return <CertifiedSignature heading={heading} dialog={dialog} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ signature }) => {
    return signature
      ? {
          toStatus: TO_STATUS,
          ...signature,
        }
      : null;
  },
};
