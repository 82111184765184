import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './pending_actions.svg';

function PendingActions(props) {
  return (
    <SvgIcon
      style={{ transform: 'scale(1.3)', transformOrigin: 'left top' }}
      {...props}
      children={<path />}
      component={(svgProps) => <Icon {...svgProps} />}
    />
  );
}

export default PendingActions;
