import React, { Fragment, useState } from 'react';

import { Grid, Typography, Tooltip } from '@material-ui/core';
import { Healing, Link, PriorityHigh } from '@material-ui/icons';

import { get, isUndefined } from 'lodash';

import { TriStateToggle } from 'components';
import Input from 'components/core/form/Input';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { useStyles } from './styles';

import ChipSelect from 'pages/RiskAnalysis/Id/PayloadFields/controls/helpers/ChipSelect';
import ChipSelectOptionRender from 'pages/RiskAnalysis/Id/PayloadFields/controls/ChipSelectOptionRender';

import RiskFieldSection from '../RiskFieldSection';
import { RiskVersionQuery_riskVersion } from '../__generated__/RiskVersionQuery';
import { useUpdateRiskControl } from '../gql';
import { useAddNewRiskVersion, useDeleteNewRiskVersion, useChangeNewRiskVersion } from '../gql';

import { onGraphQLError } from '../utils';

interface Props {
  riskVersion: RiskVersionQuery_riskVersion;
  onFieldSave: (field: string) => (value: any) => void;
  dictionaries: any;
}

const IdentifyingNewRisksTab = (props: Props) => {
  const { locked } = props.riskVersion;
  const classes = useStyles();

  const { riskVersion, dictionaries } = props;

  const riskControls = get(riskVersion, 'linkedRiskControlVersions');

  const getLinkedRisks = (riskControlVersion) => {
    const riskVersions = get(riskControlVersion, 'linkedRiskVersions');
    const chosenRiskVersionIds = get(riskControlVersion, 'chosenRiskVersionIds', []);
    return riskVersions.map((m, index) => {
      const currentValue = chosenRiskVersionIds[index] || null;
      return {
        ...m,
        locked: m.locked || true,
        label: m.item.customIdentifier,
        value: currentValue,
        id: m.item.id,
        currentValue: m.id,
        customIdentifier: m.item.customIdentifier,
        currentVersion: {
          title: m.title,
          versionIdentifier: m.versionIdentifier,
          currentStatus: {
            name: m.currentStatus.name,
          },
          item: {
            itemType: {
              displaySlug: m.item.itemType.displaySlug,
            },
          },
        },
      };
    });
  };
  const [doConfirmChange, setDoConfirmChange] = useState(null as any);

  const [addLinkRiskMutation] = useAddNewRiskVersion();
  const [deleteLinkRiskMutation] = useDeleteNewRiskVersion();
  const [changeNewRiskVersionMutation] = useChangeNewRiskVersion();

  const [updateRiskControlMutation] = useUpdateRiskControl();

  const addLinkRisk = (riskControlVersionId: string, riskVersionId: string) => {
    addLinkRiskMutation({
      variables: {
        riskControlVersionId: riskControlVersionId,
        newRiskVersionIds: [riskVersionId],
        trioType: 'MitigationRisk',
      },
    }).catch(onGraphQLError('Error occurred while Add Risk link to the Risk Control'));
  };
  const deleteLinkRisk = (riskControlVersionId: string, riskVersionId: string) => {
    deleteLinkRiskMutation({
      variables: {
        riskControlVersionId: riskControlVersionId,
        newRiskVersionId: riskVersionId,
      },
    }).catch(onGraphQLError('Error occurred while delete Risk link to the Risk Control'));
  };

  function findOldRiskVersionIdByNewRiskVersionId(riskControlVersionId, newVersionId) {
    for (let item of dictionaries.risks) {
      const foundItem = item.versions.find((version) => version.id === newVersionId);
      if (foundItem)
        return getLinkedRisks(riskControls.find((riskControl) => riskControl.id === riskControlVersionId)).find(
          (risk) => risk.item.id === item.id,
        ).value;
    }
    return null;
  }

  const switchRiskControlVersion = (riskControlVersionId: string, newRiskVersionId: string) => {
    changeNewRiskVersionMutation({
      variables: {
        riskControlVersionId: riskControlVersionId,
        newRiskVersionId: newRiskVersionId,
        oldRiskVersionId: findOldRiskVersionIdByNewRiskVersionId(riskControlVersionId, newRiskVersionId),
        trioType: 'MitigationRisk',
      },
    }).catch(onGraphQLError('Error occurred while updating Risk Mitigation'));
  };

  const updateRiskControl = (introducesNewRisks: boolean | null, riskControlId: string) => {
    updateRiskControlMutation({
      variables: {
        id: riskControlId,
        introducesNewRisks,
      },
    }).catch(onGraphQLError('Error occurred while updating Risk Control'));
  };

  const renderLinkedRisks = (riskControl: any) => {
    const introducesNewRisks = riskControl.introducesNewRisks;
    const tooltipForLockedRiskControls = `To add new risks to this risk control, please transition ${
      riskControl.item.customIdentifier
    } to an editable state`;
    return (
      <Fragment key={riskControl.id}>
        <Grid item xs={5}>
          <Input
            value={`${riskControl.item.customIdentifier}: ${get(riskControl, 'title')}`}
            disabled={true}
            rows={1}
          />
        </Grid>
        <Grid item xs={2} className={classes.newRiskToggle}>
          <div className={classes.inlineToggle}>
            <Typography variant={'body2'}>No</Typography>
            <Tooltip title={riskControl.locked ? tooltipForLockedRiskControls : ''}>
              <div>
                <TriStateToggle
                  toggled={introducesNewRisks}
                  onChange={(val) => {
                    const newValue = isUndefined(val) ? null : val!;
                    const id = riskControl.id;
                    if (!newValue && !!introducesNewRisks && get(riskControl, 'linkedRiskVersions.length')) {
                      setDoConfirmChange(() => () => {
                        updateRiskControl(newValue, id);
                      });
                    } else {
                      updateRiskControl(newValue, id);
                    }
                  }}
                  disabled={locked || riskControl.locked}
                />
              </div>
            </Tooltip>
            <Typography variant={'body2'}>Yes</Typography>

            <ConfirmationDialog
              open={!!doConfirmChange}
              title="Confirm Change"
              onClose={() => setDoConfirmChange(null)}
              onCancel={() => {
                setDoConfirmChange(null);
              }}
              onConfirm={() => {
                doConfirmChange && doConfirmChange();
                setDoConfirmChange(null);
              }}
            >
              Changing the selection will remove all added Risks.
            </ConfirmationDialog>
          </div>
        </Grid>
        <Grid item xs={5}>
          <Tooltip title={riskControl.locked ? tooltipForLockedRiskControls : ''}>
            <div>
              <ChipSelect
                disabled={locked || !Boolean(introducesNewRisks) || riskControl.locked}
                renderOption={(option) => {
                  return <ChipSelectOptionRender option={option} />;
                }}
                content={introducesNewRisks ? getLinkedRisks(riskControl) : []}
                options={dictionaries.risks
                  .map((m) => ({
                    ...m,
                    label: m.customIdentifier,
                    value: m.currentVersion.id,
                  }))
                  .filter((v) => {
                    const isCurrent = v.value === riskVersion.item.id;
                    const isSelected = dictionaries.risks.map((m) => m.value).includes(v.value);
                    return !isCurrent && !isSelected;
                  })}
                onAdd={(option) => {
                  addLinkRisk(riskControl.id, get(option, 'value'));
                }}
                onRemove={
                  locked || !Boolean(introducesNewRisks) || riskControl.locked
                    ? undefined
                    : (option) => {
                        deleteLinkRisk(riskControl.id, get(option, 'value'));
                      }
                }
                onToggle={(id) => {
                  switchRiskControlVersion(riskControl.id, id);
                }}
                popupIcon={<Link />}
                placeholder={!Boolean(introducesNewRisks) ? 'No new risks' : ''}
              />
            </div>
          </Tooltip>
        </Grid>
      </Fragment>
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={5}>
        <RiskFieldSection.Heading Icon={Healing}>Risk Controls</RiskFieldSection.Heading>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={'subtitle1'}>Does Risk Control Introduce New Risks?</Typography>
      </Grid>
      <Grid item xs={5}>
        <RiskFieldSection.Heading Icon={PriorityHigh}>New Risks</RiskFieldSection.Heading>
      </Grid>
      {riskControls && riskControls.map(renderLinkedRisks)}
    </Grid>
  );
};

export default IdentifyingNewRisksTab;
