import React from 'react';
import AppWindowControl from './AppWindowControl';

const withCurrentAppWindow = (Component) => (componentProps) => {
  return (
    <AppWindowControl>
      {({ appWindow }) => (
        <Component appWindow={appWindow} {...componentProps} />
      )}
    </AppWindowControl>
  );
};

export default withCurrentAppWindow;
