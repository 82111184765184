import React from 'react';
import get from 'lodash/get';
import { getLayoutComponent } from 'utils/pageUtils';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { Views } from 'workspaces';

import FormRecordItemType from './index';
import BuilderView from './BuilderView';
import EffectiveView from './EffectiveView';
import VersionsView from 'components/VersionsView';
import PayloadFields from './PayloadFields';
import useItemType from 'pages/generic/useItemType';
import { RecordsView } from 'components';

function RecordPage(props) {
  const itemType = useItemType('form');

  switch (props.activeWorkspaceView.id) {
    case Views.Builder.id:
      return <BuilderView itemId={props.itemId} />;
    case Views.Effective.id:
      return <EffectiveView itemId={props.itemId} />;
    case Views.Versions.id:
      return (
        <VersionsView
          categorySlug={'forms'}
          itemType={'form'}
          itemId={props.itemId}
          PayloadFields={PayloadFields}
          VersionsViewOptions={get(itemType, 'EffectiveViewOptions')}
        />
      );
    case Views.Records.id:
      return (
        <RecordsView
          RecordViewOptions={props.RecordViewOptions}
          categorySlug={'forms'}
          itemType={'form'}
          itemId={props.itemId}
        />
      );
    default:
      const LayoutComponent = getLayoutComponent(props.activeWorkspaceView.id);
      return (
        <LayoutComponent
          {...props}
          itemType={FormRecordItemType}
          displayHistoryInEffectiveView
          PayloadFields={PayloadFields}
        />
      );
  }
}

export default withWorkspaceViews()(RecordPage);
