import React from 'react';

import { get } from 'lodash';

import { TextAreaField } from 'components';

import { useDebounce } from 'hooks/useDebounce';
import { useUpdateJustification } from '../hooks';

interface Props {
  justificationItem: any;
  className?: string;
  locked: boolean
}

export default function JustificationForTesting({ justificationItem, className, locked }: Props) {
  const updateField = useUpdateJustification(justificationItem.id);
  const placeholder = `Please add justification`;
  const update = useDebounce(updateField);

  return (
    <div className={className}>
      <TextAreaField rows={4} placeholder={placeholder} locked={locked} initialValue={get(justificationItem, 'text')} onTextChange={update} />
    </div>
  );
}

