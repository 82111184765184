import defaults from 'lodash/defaults';

export default class Graph {
  constructor() {
    this._edges = {};
    this.graph = {};
  }

  get edges() {
    return Object.values(this._edges);
  }

  addEdge(edge) {
    const { upstreamVersion, downstreamVersion } = edge;
    const graph = this.graph;

    graph[upstreamVersion.id] = defaults(graph[upstreamVersion.id], Graph.createVertex(upstreamVersion));
    graph[upstreamVersion.id].downstream.push(Graph.createVertex(downstreamVersion));

    graph[downstreamVersion.id] = defaults(graph[downstreamVersion.id], Graph.createVertex(downstreamVersion));
    graph[downstreamVersion.id].upstream.push(Graph.createVertex(upstreamVersion));

    this._edges[edge.id] = edge;
  }

  findEdge(upstreamId, downstreamId) {
    return this.edges.find(
      (edge) => edge.upstreamVersion.id === upstreamId && edge.downstreamVersion.id === downstreamId,
    );
  }

  hasEdge(id) {
    for (let edge of this.edges) {
      if (edge.upstreamVersion.id === id || edge.downstreamVersion.id === id) {
        return true;
      }
    }

    return false;
  }

  getVertex(id) {
    return this.graph[id];
  }

  hasVertex(id) {
    return id in this.graph;
  }

  static createVertex(object) {
    return {
      id: object.id,
      object,
      upstream: [],
      downstream: [],
    };
  }
}
