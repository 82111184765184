export class WorkflowStatus {
  constructor(public workflowOrder: number, public id: string) {}

  isEqualTo(compare: WorkflowStatus): boolean {
    return compare.id === this.id;
  }

  isAfter(compare: WorkflowStatus): boolean {
    return this.workflowOrder > compare.workflowOrder;
  }

  isBefore(compare: WorkflowStatus): boolean {
    return this.workflowOrder < compare.workflowOrder;
  }
}

const GenericWithMembersWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Draft: new WorkflowStatus(1, 'draft'),
  UnderReview: new WorkflowStatus(2, 'under_review'),
  OwnerApproval: new WorkflowStatus(3, 'owner_approval'),
  ApprovedDraft: new WorkflowStatus(4, 'approved_draft'),
  Released: new WorkflowStatus(5, 'released'),
  RetirementInitiated: new WorkflowStatus(6, 'retirement_initiated'),
  RetirementApproved: new WorkflowStatus(7, 'retirement_approved'),
  RetirementRejected: new WorkflowStatus(8, 'retirement_rejected'),
  Retired: new WorkflowStatus(9, 'retired'),
  RetirementCanceled: new WorkflowStatus(10, 'retirement_canceled'),
  Rejected: new WorkflowStatus(11, 'rejected'),
  Canceled: new WorkflowStatus(12, 'canceled'),
  Void: new WorkflowStatus(13, 'void'),
  Obsolete: new WorkflowStatus(15, 'obsolete'),
};

const CourseWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Draft: new WorkflowStatus(1, 'draft'),
  UnderReview: new WorkflowStatus(2, 'under_review'),
  OwnerApproval: new WorkflowStatus(3, 'owner_approval'),
  ApprovedDraft: new WorkflowStatus(4, 'approved_draft'),
  ReleasedForCoTraining: new WorkflowStatus(5, 'released_for_co_training'),
  Released: new WorkflowStatus(6, 'released'),
  Rejected: new WorkflowStatus(7, 'rejected'),
  Canceled: new WorkflowStatus(8, 'canceled'),
  RetirementInitiated: new WorkflowStatus(9, 'retirement_initiated'),
  RetirementCanceled: new WorkflowStatus(10, 'retirement_canceled'),
  Void: new WorkflowStatus(11, 'void'),
  Retired: new WorkflowStatus(12, 'retired'),
};

const DeviationWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Identify: new WorkflowStatus(1, 'identify'),
  Investigate: new WorkflowStatus(2, 'investigate'),
  CanceledDeviation: new WorkflowStatus(3, 'canceled_deviation'),
  ApproveToProceed: new WorkflowStatus(4, 'approve_to_proceed'),
  Rejected: new WorkflowStatus(5, 'rejected'),
  ClosedDeviation: new WorkflowStatus(6, 'closed_deviation'),
  VoidApproveToProceed: new WorkflowStatus(7, 'void_approve_to_proceed'),
};

const GenericWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Draft: new WorkflowStatus(1, 'draft'),
  UnderReview: new WorkflowStatus(2, 'under_review'),
  OwnerApproval: new WorkflowStatus(3, 'owner_approval'),
  ApprovedDraft: new WorkflowStatus(4, 'approved_draft'),
  Released: new WorkflowStatus(5, 'released'),
  RetirementInitiated: new WorkflowStatus(6, 'retirement_initiated'),
  RetirementApproved: new WorkflowStatus(7, 'retirement_approved'),
  RetirementRejected: new WorkflowStatus(8, 'retirement_rejected'),
  Retired: new WorkflowStatus(9, 'retired'),
  RetirementCanceled: new WorkflowStatus(10, 'retirement_canceled'),
  Rejected: new WorkflowStatus(11, 'rejected'),
  Canceled: new WorkflowStatus(12, 'canceled'),
  VoidOwnerApproval: new WorkflowStatus(13, 'void_owner_approval'),
  VoidApprovedDraft: new WorkflowStatus(14, 'void_approved_draft'),
  Obsolete: new WorkflowStatus(15, 'obsolete'),
};

const SetGenericWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Draft: new WorkflowStatus(1, 'draft'),
  UnderReview: new WorkflowStatus(2, 'under_review'),
  OwnerApproval: new WorkflowStatus(3, 'owner_approval'),
  ApprovedDraft: new WorkflowStatus(4, 'approved_draft'),
  Released: new WorkflowStatus(5, 'released'),
  Rejected: new WorkflowStatus(6, 'rejected'),
  Void: new WorkflowStatus(7, 'void'),
  Obsolete: new WorkflowStatus(8, 'obsolete'),
};

const ChangeOrderWorkflowStatuses = {
  Draft: new WorkflowStatus(0, 'draft'),
  UnderReview: new WorkflowStatus(1, 'under_review'),
  ReadyForClose: new WorkflowStatus(2, 'ready_for_closure'),
  ReadyForTraining: new WorkflowStatus(3, 'ready_for_training'),
  Closed: new WorkflowStatus(4, 'closed'),
  Rejected: new WorkflowStatus(5, 'rejected'),
  Cancel: new WorkflowStatus(6, 'cancel'),
  Void: new WorkflowStatus(7, 'void'),
};

const RecordWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Created: new WorkflowStatus(1, 'created'),
  InProgress: new WorkflowStatus(2, 'in_progress'),
  Checked: new WorkflowStatus(3, 'checked'),
  Cancel: new WorkflowStatus(4, 'cancel'),
  Void: new WorkflowStatus(5, 'void'),
};

const MiscellaneousRecordWorkflowStatuses = {
  Created: new WorkflowStatus(0, 'created'),
  InProgress: new WorkflowStatus(1, 'in_progress'),
  Checked: new WorkflowStatus(2, 'checked'),
  Cancel: new WorkflowStatus(3, 'cancel'),
  Void: new WorkflowStatus(4, 'void'),
};

const SetItemMemberWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Unlocked: new WorkflowStatus(1, 'unlocked'),
  SemiLocked: new WorkflowStatus(2, 'semi_locked'),
  Locked: new WorkflowStatus(3, 'locked'),
  Released: new WorkflowStatus(4, 'released'),
  Obsolete: new WorkflowStatus(5, 'obsolete'),
};

const AttachmentDependentWorkflowStatuses = {
  ToDo: new WorkflowStatus(0, 'to_do'),
  Cancel: new WorkflowStatus(1, 'cancel'),
  Archived: new WorkflowStatus(2, 'archived'),

  Completed: new WorkflowStatus(3, 'completed'),
  Failed: new WorkflowStatus(4, 'failed'),
  Checked: new WorkflowStatus(5, 'checked'),
  NotCreated: new WorkflowStatus(6, 'not_created'),
  Void: new WorkflowStatus(7, 'void'),
};

const TraceMatrixWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Draft: new WorkflowStatus(1, 'draft'),
  UnderReview: new WorkflowStatus(2, 'under_review'),
  OwnerApproval: new WorkflowStatus(3, 'owner_approval'),
  ApprovedDraft: new WorkflowStatus(4, 'approved_draft'),
  Released: new WorkflowStatus(5, 'released'),
  Rejected: new WorkflowStatus(6, 'rejected'),
  Canceled: new WorkflowStatus(7, 'canceled'),
  Void: new WorkflowStatus(8, 'void'),
  Obsolete: new WorkflowStatus(9, 'obsolete'),
};

const TrainingPlanWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Draft: new WorkflowStatus(1, 'draft'),
  UnderReview: new WorkflowStatus(2, 'under_review'),
  Prepared: new WorkflowStatus(3, 'prepared'),
  Released: new WorkflowStatus(4, 'released'),
  Rejected: new WorkflowStatus(5, 'rejected'),
  Inactive: new WorkflowStatus(6, 'inactive'),
  Obsolete: new WorkflowStatus(7, 'obsolete'),
  Void: new WorkflowStatus(8, 'void'),
  Canceled: new WorkflowStatus(9, 'canceled'),
};
const TaskWorkflowStatuses = {
  Backlog: new WorkflowStatus(1, 'backlog'),
  BlockedAfterBacklog: new WorkflowStatus(2, 'blocked_after_backlog'),
  WontDoAfterBacklog: new WorkflowStatus(3, 'wont_do_after_backlog'),
  ToDo: new WorkflowStatus(4, 'to_do'),
  BlockedAfterToDo: new WorkflowStatus(5, 'blocked_after_to_do'),
  WontDoAfterToDo: new WorkflowStatus(6, 'wont_do_after_to_do'),
  InProgress: new WorkflowStatus(7, 'in_progress'),
  BlockedAfterInProgress: new WorkflowStatus(8, 'blocked_after_in_progress'),
  WontDoAfterInProgress: new WorkflowStatus(9, 'wont_do_after_in_progress'),
  Done: new WorkflowStatus(10, 'done'),
  BlockedAfterDone: new WorkflowStatus(11, 'blocked_after_done'),
  WontDoAfterDone: new WorkflowStatus(12, 'wont_do_after_done'),
};

const RiskNoteWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Draft: new WorkflowStatus(1, 'draft'),
  UnderReview: new WorkflowStatus(2, 'under_review'),
  OwnerApproval: new WorkflowStatus(3, 'owner_approval'),
  ApprovedDraft: new WorkflowStatus(4, 'approved_draft'),
  Released: new WorkflowStatus(5, 'released'),
  RetirementInitiated: new WorkflowStatus(6, 'retirement_initiated'),
  RetirementApproved: new WorkflowStatus(7, 'retirement_approved'),
  RetirementRejected: new WorkflowStatus(8, 'retirement_rejected'),
  Retired: new WorkflowStatus(9, 'retired'),
  RetirementCanceled: new WorkflowStatus(10, 'retirement_canceled'),
  Rejected: new WorkflowStatus(11, 'rejected'),
  Canceled: new WorkflowStatus(12, 'canceled'),
  VoidOwnerApproval: new WorkflowStatus(13, 'void_owner_approval'),
  VoidApprovedDraft: new WorkflowStatus(14, 'void_approved_draft'),
  Obsolete: new WorkflowStatus(15, 'obsolete'),
};

export const SupplierWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  SupplierRequested: new WorkflowStatus(1, 'supplier_requested'),
  ReadyForPrequalification: new WorkflowStatus(2, 'ready_for_prequalification'),
  PrequalificationComplete: new WorkflowStatus(3, 'prequalification_complete'),
  ReadyForQualification: new WorkflowStatus(4, 'ready_for_qualification'),
  QualificationComplete: new WorkflowStatus(5, 'qualification_complete'),
  SupplierApproved: new WorkflowStatus(6, 'supplier_approved'),
  QualificationRejected: new WorkflowStatus(7, 'qualification_rejected'),
  QualificationRevoked: new WorkflowStatus(8, 'qualification_revoked'),
  Archived: new WorkflowStatus(9, 'archived'),
};

export const GenericRecordWorkflowStatuses = {
  NotCreated: new WorkflowStatus(0, 'not_created'),
  Draft: new WorkflowStatus(1, 'draft'),
  ReadyForReview: new WorkflowStatus(2, 'ready_for_review'),
  OwnerApproval: new WorkflowStatus(3, 'owner_approval'),
  Released: new WorkflowStatus(4, 'released'),
  Rejected: new WorkflowStatus(5, 'rejected'),
  Archived: new WorkflowStatus(6, 'archived'),
};

type StringToStatus = Record<string, WorkflowStatus>;
type IdToStatus = Record<string, WorkflowStatus>;

function indexById(statusMap: StringToStatus): IdToStatus {
  let byId = {};
  Object.keys(statusMap).forEach((k) => {
    const workflowStatus = statusMap[k];
    byId[workflowStatus.id] = workflowStatus;
  });
  return byId;
}

export {
  GenericWithMembersWorkflowStatuses,
  CourseWorkflowStatuses,
  DeviationWorkflowStatuses,
  GenericWorkflowStatuses,
  SetGenericWorkflowStatuses,
  ChangeOrderWorkflowStatuses,
  RecordWorkflowStatuses,
  MiscellaneousRecordWorkflowStatuses,
  SetItemMemberWorkflowStatuses,
  AttachmentDependentWorkflowStatuses,
  TraceMatrixWorkflowStatuses,
  TrainingPlanWorkflowStatuses,
  TaskWorkflowStatuses,
  RiskNoteWorkflowStatuses,
  indexById,
};
