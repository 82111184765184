import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import slugs from '../../displaySlugs';
import { DocumentToolbar } from '../mixins/Document';

const itemType = 'supplierQuestionnaireRecord';
const versionFields = `
body
descriptionOfChange
pdfDataUpload
virtualAttributes(attrType: "attachmentAttr") {
  attachments {
    filename
  }
}
`;

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Questionnaire Record`,
  categoryLabel: t`Questionnaire Records`,
  icon: PlaylistAddCheck,
  listViewQuery: gql`
    query supplierQuestionnaireRecordsQuery {
      supplierQuestionnaireRecords {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          body
          versionIdentifier
          currentStatusName

          signingOwner {
            id
            firstName
            lastName
          }
          signingApprover {
            id
            firstName
            lastName
          }
        }
      }
    }
  `,
  versionFields,
  EffectiveViewOptions: DocumentToolbar,
  listViewColumns,
};
