import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    actions: {
      display: 'flex',
    },
    attachmentInput: {
      ...theme.typography.body2,
      minHeight: 36,
      margin: 0,
      background: theme.palette.common.input.background,
      border: `2px solid ${theme.palette.common.input.outline}`,
      borderRadius: 4,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);
