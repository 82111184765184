import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { TextField, Paper } from '@material-ui/core';
import { tagsQuery } from 'utils/gql/tagQueries';
import get from 'lodash/get';

import { useStyles } from './styles';
import { withApollo } from 'react-apollo';

function TagAutosuggest({ addTag, client, tags, ...props }) {
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const autoSuggestTheme = {
    container: classes.suggestionsContainer,
    suggestionsContainerOpen: classes.suggestionsContainerOpen,
    suggestionsList: classes.suggestionsList,
    suggestion: classes.suggestion,
    suggestionHighlighted: classes.suggestionHighlighted,
  };

  const autosuggest = (value) => {
    client
      .query({
        query: tagsQuery,
        variables: { search: value },
        fetchPolicy: 'network-only',
      })
      .then((response) => get(response, 'data.tags', []).map((tag) => tag.name))
      .then((suggestions) => {
        // If we don't have any suggestions, show a Create "foo" default suggestion
        if (suggestions.indexOf(value) === -1) {
          // uncomment this if we fix the parsing issue onChange
          // suggestions.push(`Create "${value}"`);
          suggestions.push(value);
        }
        setSuggestions(suggestions);
      });
  };

  const nonMatches = suggestions.filter((suggestion) => !tags.includes(suggestion));

  const handleOnChange = (e, { method }) => {
    if (method === 'enter' || method === 'click') {
      e.preventDefault();
    } else {
      props.onChange(e);
    }
  };

  // new tags come in through suggestions that match a regex
  // extract out the actual value if it matches
  const getSuggestionValue = (suggestion) => {
    const newTagFormat = /^Create "(.*)"$/;
    const newTagMatchData = suggestion.match(newTagFormat);

    if (newTagMatchData) {
      return newTagMatchData[1];
    } else {
      return suggestion;
    }
  };

  return (
    <Autosuggest
      ref={props.ref}
      suggestions={nonMatches}
      shouldRenderSuggestions={(value) => value && value.trim().length > 0}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={(suggestion) => suggestion}
      highlightFirstSuggestion={true}
      // it would be nice to leave this on but causes a known bug
      // https://github.com/moroshko/react-autosuggest/issues/613
      focusInputOnSuggestionClick={false}
      inputProps={{
        ...props,
        onChange: handleOnChange,
        className: classes.input,
      }}
      onSuggestionSelected={(_event, { suggestion }) => addTag(suggestion)}
      onSuggestionsFetchRequested={({ value }) => autosuggest(value)}
      onSuggestionsClearRequested={() => setSuggestions([])}
      theme={autoSuggestTheme}
      renderSuggestionsContainer={(options) => (
        <Paper {...options.containerProps} square>
          {options.children}
        </Paper>
      )}
      renderInputComponent={(inputWrapperProps) => (
        <TextField
          {...inputWrapperProps}
          variant="outlined"
          InputProps={{
            style: { maxHeight: 36 },
          }}
        />
      )}
    />
  );
}

export default withApollo(TagAutosuggest);
