import React, { useState } from 'react';

import { LibraryBooks, Info, Cancel, AddCircle } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

import { ConfirmationDialog, NoContentDisclaimer, Spacer } from 'components';
import SectionLabel from 'components/core/SectionLabel';
import IconButton from 'components/IconButton';

import Subgroup from '../Subgroup';
import TriStateToggle from 'components/TriStateToggle';

import { useRemoveDeviceMasterRecordSubgroups, useAddSection, useUpdateDeviceMasterRecordGroup } from '../gql';

import { useStyles } from './styles';

export default function RenderTab({ group, openSearch, showInfo, toggleInfo, locked }) {
  const classes = useStyles();
  const [updateDeviceMasterRecordGroup] = useUpdateDeviceMasterRecordGroup();
  const [doAddSubgroup] = useAddSection();
  const addSubgroup = () => {
    doAddSubgroup({
      variables: { groupId: group.id },
    }).catch((err) => {
      throw new Error(`Error returned by addSubgroup: ${err.message}`);
    });
  };

  const [selection, setSelection] = useState({});
  const toggleSelection = (key) => {
    setSelection({
      ...selection,
      [key]: !selection[key],
    });
  };

  const disableDeleteSelected = locked || !group.subgroups.find((x) => selection[x.id]);

  const [removeDeviceMasterRecordSubgroups] = useRemoveDeviceMasterRecordSubgroups();

  const [isGoingToRemoveSubgroups, setGoingToRemoveSubgroups] = useState(false);
  const wontRemoveSubgroups = () => setGoingToRemoveSubgroups(false);
  const willRemoveSubgroups = () => setGoingToRemoveSubgroups(true);

  const doRemoveSubgroups = () => {
    const ids = Object.keys(selection).filter((x) => selection[x]);

    setGoingToRemoveSubgroups(false);
    removeDeviceMasterRecordSubgroups({
      variables: { ids },
    })
      .then(() => {
        setSelection({});
      })
      .catch((err) => {
        throw new Error(`Error returned by removeDeviceMasterRecordSubgroups: ${err.message}`);
      });
  };

  const [confirmActionFun, setConfirmActionFun] = useState(null);
  const performAction = () => {
    const action = confirmActionFun as any;
    setConfirmActionFun(null);
    action && action();
  };
  const cancelAction = () => setConfirmActionFun(null);
  const confirmAction = (action) => setConfirmActionFun(() => action);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.buttonsContainer}>
          <IconButton onClick={toggleInfo} Icon={Info} size="large" />
          {disableDeleteSelected ? null : (
            <IconButton onClick={willRemoveSubgroups} disabled={disableDeleteSelected} Icon={Cancel} size="large" />
          )}
        </div>

        <SectionLabel icon={LibraryBooks}
        variant='overline'
        >{group.name}</SectionLabel>
        {showInfo && <Typography variant='body2' className={classes.info}>{group.info}</Typography>}

        {group.optional && (
          <ApplicableControl
            name={group.name}
            applicable={group.applicable}
            disabled={locked}
            setApplicable={(applicable) => {
              if (applicable === undefined) applicable = null
              const id = group.id;
              const action = () => {
                updateDeviceMasterRecordGroup({
                  variables: {
                    id,
                    applicable,
                  },
                })
                  .then(() => true)
                  .catch(() => false);
              };
              if (group.applicable && !applicable) {
                confirmAction(action);
              } else {
                action();
              }
            }}
          />
        )}

        {group.optional && !group.applicable ? null : (
          <>
            {group.subgroups.map((subgroup) => (
              <Subgroup
                key={subgroup.id}
                group={group}
                subgroup={subgroup}
                openSearch={openSearch}
                selection={selection}
                toggleSelection={toggleSelection}
                usedNames={group.subgroups.filter((x) => x.id !== subgroup.id).map((x) => x.name)}
                locked={locked}
              />
            ))}

          {locked ? null : <div
            className={classes.buttonFill}
          ><IconButton onClick={addSubgroup} Icon={AddCircle} size="large" /></div>}

            {group.subgroups.length ? null : (
              <>
                <Spacer factor={10} />
                <NoContentDisclaimer variant={locked ? "locked" : "add"} />
              </>
            )}
          </>
        )}

        <ConfirmationDialog
          onCancel={wontRemoveSubgroups}
          onClose={wontRemoveSubgroups}
          onConfirm={doRemoveSubgroups}
          open={isGoingToRemoveSubgroups}
          title="Confirm Removal"
        >
          Are you sure? Removing these sections will remove all linked content within.
        </ConfirmationDialog>

        <ConfirmationDialog
          onCancel={cancelAction}
          onClose={cancelAction}
          onConfirm={performAction}
          open={!!confirmActionFun}
          title="Confirm Not Applicable"
        >
          Are you sure? Marking this as not applicable will remove all sections and linked content within.
        </ConfirmationDialog>
      </div>
    </>
  );
}

function ApplicableControl({ name, applicable, setApplicable, disabled }) {
  const classes = useStyles()
  return (
    <Typography variant='body2' className={classes.applicableText}>
      Is {name} an applicable part of this Device Master Record?
      <NoYes toggled={applicable} onChange={setApplicable} disabled={disabled} />
    </Typography>
  );
}

function NoYes({ toggled, onChange, disabled }) {
  return (
    <div style={{ display: 'inline-block', padding: 10 }}>
      No <TriStateToggle toggled={toggled} onChange={onChange} disabled={disabled} /> Yes
    </div>
  );
}
