import React from 'react';
import flowRight from 'lodash/flowRight';

import { Grid, Typography } from '@material-ui/core';
import { People } from '@material-ui/icons';

import { Spacer } from 'components';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import { useStyles } from './styles';

interface Props {
  currentCompany: any;
}

function LicenseInformation(props: Props) {
  const classes = useStyles();
  const { currentCompany } = props;

  return (
    <div className={classes.licenseInformation}>
      <Grid container>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <span style={{ display: 'inline-flex' }}>
            <People fontSize="small" />
            &nbsp;
            <Typography variant="subtitle1">LICENSE INFORMATION</Typography>
          </span>
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: 24, paddingTop: 8 }}>
          <span style={{ display: 'inline-flex' }}>
            <Typography variant="subtitle1">General User Seats:&nbsp;</Typography>
            <Typography variant="body2">
              {currentCompany.remainingGeneralUserSeats} of {currentCompany.config.generalUserSeatCountLimit} available
            </Typography>
          </span>
          <Spacer factor={0.5} />
          <span style={{ display: 'inline-flex' }}>
            <Typography variant="subtitle1">Train & View User Seats:&nbsp;</Typography>
            <Typography variant="body2">
              {currentCompany.remainingTrainAndViewUserSeats} of {currentCompany.config.trainAndViewUserSeatCountLimit}{' '}
              available
            </Typography>
          </span>
          <Spacer factor={0.5} />
          <span style={{ display: 'inline-flex' }}>
            <Typography variant="subtitle1">View User Seats:&nbsp;</Typography>
            <Typography variant="body2">
              {currentCompany.remainingViewOnlyUserSeats} of {currentCompany.config.viewOnlyUserSeatCountLimit}{' '}
              available
            </Typography>
          </span>
          <Spacer factor={0.5} />
          <span style={{ display: 'inline-flex' }}>
            <Typography variant="subtitle1">Partner User Seats:&nbsp;</Typography>
            <Typography variant="body2">{currentCompany.partnerWhitelistUserCount}</Typography>
          </span>
        </Grid>
        <Grid item xs={1} />
        <Grid
          item
          xs={6}
          style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'space-between', paddingTop: 8 }}
        >
          <Typography variant="body2">
            To purchase additional license seats email{' '}
            <a href="mailto:support@nemedio.com" style={{ color: '#33BCB4' }}>
              support@nemedio.com
            </a>
          </Typography>
          <Typography variant="body2">Note: Partner user seats to not count towards seat totals.</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default flowRight(withCurrentCompany)(LicenseInformation);
