import gql from 'graphql-tag';

export const PRECOMPLIANCE_TASK_CATEGORIES = gql`
  query PrecomplianceTaskCategories {
    precomplianceTaskCategories {
      name
      tasks {
        id
        title
        task {
          id
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
        content
        additionalTitle
        additionalContent
        position
        completedAt
        reference
        helpUrl
        successHeader
        successMessage
        completedBy {
          username
        }
      }
    }
  }
`;
