import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  chip: {
    background: theme.palette.status.warning,
    height: theme.spacing(3),
    marginTop: theme.spacing(1),
    '&:focus': {
      background: theme.palette.status.warning,
    },
  },
  chipSimple: {
    background: 'transparent',
    margin: 0,
    '& .MuiChip-label': {
      padding: '5px',
    },
    '&:focus': {
      background: 'transparent',
    },
  },
  chipDeleteIconContainer: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: theme.spacing(2),
    justifyContent: 'center',
    width: theme.spacing(2),
    ...theme.typography.body2,
  },
  chipDeleteIcon: {
    color: '#655D47',
  },
  chipDeleteIconSimple: {
    color: theme.palette.status.warning,
  },
  popper: {
    marginLeft: theme.spacing(1),
    zIndex: theme.zIndex.tooltip,
  },
  popperPaper: {
    padding: theme.spacing(0.5),
    width: theme.spacing(32),
  },
}));
