import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 0,
  },
  cardContentContainer: {
    height: '100%',
    width: '100%',
    padding: '8px 12px 8px 12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));
