import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: any) => ({
  noItemsPanel: {
    padding: theme.spacing(1),
    textAlign: "center"
  },
  tableContainer: {
    marginBottom: theme.spacing(1)
  },
  pass: {
    color: theme.palette.common.released.main
  },
  fail: {
    color: theme.palette.common.rejected.main
  },
}));
