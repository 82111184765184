import React, { useContext, useEffect } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography } from '@material-ui/core';
import Flag from '@material-ui/icons/Flag';
import Check from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/styles';

import { useMutation } from 'react-apollo';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';
import { plural } from 'pluralize';

import styles from 'components/BuilderView/styles';
import { Breadcrumbs, ContentSection, ErrorBoundary, ItemTitle, Loading, Spacer } from 'components';
import { ContextDrawer } from 'compositions';
import ItemControls from 'compositions/ItemControls';
import { LockedContext, withLockedContext } from 'contexts/lockedContext';
import Workflow from 'components/BuilderView/Workflow';
import { UPDATE_DOCUMENT_VERSION_INPUT_METHOD } from 'components/BuilderView/gql';
import { deleteAttachment, showPDFExport } from 'components/BuilderView';

import PayloadFields from './PayloadFields';
import { queryNameToRefetch, useBuilderViewQuery } from './gql';

interface ChangeOrderViewProps {
  itemId: string;
  classes;
  categorySlug: string;
}

const itemType = 'changeOrder';

function ChangeOrderView({ itemId, classes, categorySlug }: ChangeOrderViewProps) {
  const matchesPrint = useMediaQuery('print');
  const { data, loading, error } = useBuilderViewQuery(itemId);

  const [updateDocumentVersionInputMethod] = useMutation(UPDATE_DOCUMENT_VERSION_INPUT_METHOD);

  const { setLocked } = useContext(LockedContext);

  useEffect(() => {
    if (data) {
      setLocked(get(data, 'item.currentVersion.locked'));
    }
  }, [data, setLocked]);

  if (error) throw new Error(`Error loading Change Order ${itemId}\n${error}`);
  if (matchesPrint) return null;
  if (loading || !data) return <Loading />;

  const itemRecord = data.changeOrder;

  const { currentVersion } = itemRecord;

  if (!currentVersion) throw new Error(`null currentVersion returned for Change Order ${itemId}`);

  const itemTitle = currentVersion.title;
  const titlePrefix = itemRecord.customIdentifier;
  const titleLocked = get(currentVersion, 'locked');

  return (
    <Grid container data-testid="item" spacing={3} className={classes.container}>
      <Grid item xs={12} className={classes.grid}>
        <Breadcrumbs categorySlug={categorySlug} itemTitle={`${titlePrefix} ${itemTitle}`} />
        <Grid container item xs={8} justify="space-between">
          <ItemTitle
            editable={!titleLocked}
            itemTitle={itemTitle}
            titlePrefix={titlePrefix}
            versionId={get(currentVersion, 'id')}
            refetchQueries={[queryNameToRefetch]}
          />

          <ItemControls
            showPDFExport={showPDFExport(itemRecord.currentVersion, itemType)}
            pdfExportHeader={`${itemRecord.customIdentifier}: ${itemRecord.currentVersion.title}`}
            peopleProps={{
              approver: currentVersion.approver,
              creator: currentVersion.creator,
              createdAt: currentVersion.createdAt,
              owner: currentVersion.owner,
              permissibleApprovers: currentVersion.permissibleApprovers,
              permissibleOwners: currentVersion.permissibleOwners,
              locked: currentVersion.locked,
              ownerLocked: currentVersion.__typename === 'TrainingRecordVersion',
              versionId: currentVersion.id,
              version: currentVersion,
              refetchQueries: [queryNameToRefetch],
            }}
            tagsProps={{
              itemId: itemRecord.id,
              tags: itemRecord.tags,
              refetchQueries: [queryNameToRefetch, `${plural(itemType)}Query`, 'tagsQuery'],
            }}
            ellipsesProps={{
              referenceId: itemRecord.referenceId,
              versionId: itemRecord.currentVersion.id,
              itemId: itemRecord.id,
              itemType: itemType,
              inputMethod: itemRecord.currentVersion.inputMethod,
              attachment: get(itemRecord, 'currentVersion.attachment.filename'),
              locked: currentVersion.locked,
              refetchQueries: [queryNameToRefetch],
              onInputMethodChange: (inputMethod) => {
                updateDocumentVersionInputMethod({
                  variables: {
                    versionId: itemRecord.currentVersion.id,
                    inputMethod: inputMethod,
                  },
                  refetchQueries: [queryNameToRefetch],
                }).catch((err) => {
                  throw new Error(`Error updating input method: ${err.message}`);
                });
              },
              onPDFDelete: () => {
                const attachmentId = get(itemRecord, 'currentVersion.attachment.id');
                if (attachmentId) deleteAttachment(itemRecord.currentVersion.id, attachmentId);
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.grid}>
        <Grid item xs={5} className={classes.gridGutterRight}>
          <ErrorBoundary>
            <Workflow
              status={itemRecord.currentVersion.currentStatusName}
              key={itemRecord.currentVersion.currentStatusName}
              workflow={get(itemRecord, 'itemType.workflow.name')}
              itemId={itemRecord.id}
              refetchQueries={[queryNameToRefetch]}
              itemType={itemType}
              itemData={itemRecord}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={3} className={classes.gridGutterLeft}>
          <ContentSection LabelText={'Item Notes'} Icon={<Flag className={classes.icon} />}>
            <Spacer factor={1} />
            <div className={classes.itemNotes}>
              <Spacer factor={3} />
              <Typography variant="body2" className={classes.iconTextStack}>
                <Check className={classes.icon} />
                No new updates
              </Typography>
            </div>
          </ContentSection>
        </Grid>
      </Grid>

      <Grid item xs={8}>
        <PayloadFields
          layoutClasses={classes}
          itemRecord={itemRecord}
          itemType={itemType}
          versionData={itemRecord.currentVersion}
          displayOnly={get(itemRecord, 'currentVersion.locked')}
        />
      </Grid>

      <ContextDrawer itemId={itemId || itemRecord.id} itemType={itemType} />
    </Grid>
  );
}

export default flowRight([withStyles(styles), withLockedContext])(ChangeOrderView);
