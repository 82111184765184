import React from 'react';

import { Typography } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { CATEGORY_INDEX } from 'categories';

function Breadcrumbs(props) {
  const { categorySlug, itemTitle } = props;
  const activeCategory = CATEGORY_INDEX[categorySlug];
  if (!activeCategory) return null;

  const hierarchy = [activeCategory];
  if (activeCategory.parentSlug) hierarchy.unshift(CATEGORY_INDEX[activeCategory.parentSlug]);

  return (
    <Typography noWrap variant="body2">
      {hierarchy.map((category, i) => (
        <span key={category.slug}>
          {category.name.id}
          {i < hierarchy.length - 1 && <KeyboardArrowRight />}
        </span>
      ))}
      {itemTitle && <KeyboardArrowRight />}
      {itemTitle}
    </Typography>
  );
}

export default Breadcrumbs;
