import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    textAreaContainer: {
      width: '100%',
    },
    textAreaContainerDisplay: {
      border: '2px solid transparent',
      padding: theme.spacing(0.5),
    },
    generalText: {
      display: 'inline-block',
      minHeight: '44px',
      padding: '4px 4px',
      width: '100%',
      fontWeight: 500,
    },
    expanded: {
      resize: 'none',
    },
    fixedHeight: {
      resize: 'none',
    },
    locked: {
      cursor: 'not-allowed',
    },
  }),
);
