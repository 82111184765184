import React, { useEffect, useContext, useState } from 'react';

import { useLazyQuery } from 'react-apollo';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { withLockedContext, LockedContext } from 'contexts/lockedContext';
import { PayloadFieldsProps } from 'itemTypes';

import { VerificationAndValidationReportTabs } from './VerificationAndValidationReportTabs';
import { AssociatedValidationPlanSelect } from './AssociatedValidationPlanSelect';

import { useVersion } from './gql';
import {
  VerificationAndValidationPlanVersionQuery,
  VerificationAndValidationPlanVersionQuery_verificationAndValidationPlanVersion_verificationProtocols as VerificationProtocols,
  VerificationAndValidationPlanVersionQuery_verificationAndValidationPlanVersion_validationProtocols as ValidationProtocols,
} from '../../VerificationAndValidationPlan/Id/__generated__/VerificationAndValidationPlanVersionQuery';
import { GET_VERIFICATION_AND_VALIDATION_PLAN_VERSION } from '../../VerificationAndValidationPlan/Id/gql';

export const defaultAssociatedVVPState = {
  id: '',
  name: '',
  version: '',
  status: '',
};

export interface VVPlan {
  id: string;
  name: string;
  status: string;
  version: string;
  verificationProtocols: VerificationProtocols[];
  validationProtocols: ValidationProtocols[];
}

interface Props {
  activeWorkspaceView: any;
}

function PayloadFields(props: PayloadFieldsProps & Props) {
  const { layoutClasses, activeWorkspaceView } = props;

  const { data, loading, error, refetch } = useVersion(props.versionId || '');

  const { setLocked } = useContext(LockedContext);

  const [associatedVVP, setAssociatedVVP] = useState<any>(defaultAssociatedVVPState);

  const [getVVPData] = useLazyQuery<VerificationAndValidationPlanVersionQuery>(
    GET_VERIFICATION_AND_VALIDATION_PLAN_VERSION,
    {
      // useLazyQuery uses 'Omit' type which is not supported by our TS version. so it's throw error here
      // @ts-ignore
      fetchPolicy: 'network-only',
      onCompleted: (vVPData) => {
        const {
          verificationAndValidationPlanVersion: {
            id,
            currentStatus,
            item: { customIdentifier },
            versionIdentifier,
            title,
            verificationProtocols,
            validationProtocols,
          },
        } = vVPData;

        setAssociatedVVP({
          id,
          name: `${customIdentifier}: ${title}`,
          status: currentStatus.name,
          version: versionIdentifier,
          verificationProtocols,
          validationProtocols,
        });
      },
    },
  );

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching VVR: ${err}`);
    });
  }, [refetch]);

  useEffect(() => {
    if (data) setLocked(data.verificationAndValidationReportVersion.locked);
  }, [data, setLocked]);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Verification And Validation Report version ${props.versionId}\n${error}`);

  const currentVersion = data.verificationAndValidationReportVersion;

  const renderPage = () => {
    return (
      <>
        <AssociatedValidationPlanSelect
          currentVersion={currentVersion}
          getVVPData={getVVPData}
          associatedVVP={associatedVVP}
          setAssociatedVVP={setAssociatedVVP}
          vvReportId={currentVersion.id}
          vvPlan={currentVersion.associatedVvPlan && currentVersion.associatedVvPlan.version.id}
        />
        <VerificationAndValidationReportTabs
          viewType={activeWorkspaceView.id}
          currentVersion={currentVersion}
          layoutClasses={layoutClasses}
          vvPlan={associatedVVP}
          updateVersion={refetch}
        />
      </>
    );
  };
  return renderPage();
}

export default withWorkspaceViews()(withLockedContext(PayloadFields));
