import React from 'react';

import BuilderView from 'components/BuilderView';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { RouteComponentProps } from '@reach/router';
import PayloadFields from './PayloadFields';

interface Props extends RouteComponentProps {
  itemId: string;
  activeWorkspaceView: { id: string };
}

function Page(props: Props) {
  return (
    <BuilderView
      categorySlug="task"
      itemType="task"
      itemId={props.itemId}
      PayloadFields={(payloadProps) => <PayloadFields {...payloadProps} />}
    />
  );
}

export default withWorkspaceViews()(Page);
