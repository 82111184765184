import React, { Component, useState } from 'react';

import get from 'lodash/get';
import includes from 'lodash/includes';
import last from 'lodash/last';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import flowRight from 'lodash/flowRight';

import { Tooltip, Typography, withStyles } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Done from '@material-ui/icons/Done';
import Notes from '@material-ui/icons/Notes';

import withCurrentAppWindow from 'compositions/WithCurrentAppWindow';
// import withProducts from 'compositions/WithProducts';
import { Spacer, VersionSwitch } from 'components';
import NewItem from 'compositions/NewItem';
import {
  itemTypeCategoryHierarchy,
  CATEGORIES,
  FULL_CATEGORIES,
  CATEGORY_INDEX,
  FULL_CATEGORIES_INDEX,
} from 'categories';
import { ItemTypeIndex } from 'itemTypes';
import ExpansionPanelWrapper from './ExpansionPanelWrapper';

const styles = (theme) => ({
  searchResult: {
    paddingLeft: 24,
    display: 'flex',
    alignItems: 'end',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(48, 188, 179, 0.1)',
    },
  },
  versionSection: {
    marginLeft: 'auto',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedRow: {
    backgroundColor: 'rgba(48, 188, 179, 0.1)',
  },
  icon: {
    fontSize: 13,
    color: theme.palette.blueDark,
    marginRight: 4,
    marginBottom: 2,
  },
  iconPrimary: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginRight: 4,
  },
});

const minLengthOfTitleToShowTooltip = 18;

const LeftArrow = () => (
  <KeyboardArrowLeft
    style={{
      fontSize: 16,
      verticalAlign: 'text-bottom',
    }}
  />
);

const RightArrow = () => (
  <KeyboardArrowRight
    style={{
      fontSize: 16,
      verticalAlign: 'text-bottom',
    }}
  />
);

const CategoryItem = ({
  record,
  selectedRecords,
  onRecordClick,
  recordIsSelectedProperty,
  classes,
  style,
  category,
  setItemCurrentVersion,
  enableVersionSupport,
}) => {
  const initialVersionIdValue = enableVersionSupport
    ? (record.currentRelease && record.currentRelease.id) || (record.workingVersion && record.workingVersion.id)
    : null;

  const [versionId, setVersionId] = useState(initialVersionIdValue);
  const isSelected = includes(selectedRecords, record[recordIsSelectedProperty || 'id']);

  const tooltipValue = enableVersionSupport && record.title.length > minLengthOfTitleToShowTooltip ? record.title : '';

  const handleToggle = (val, e) => {
    e.stopPropagation();
    setVersionId(val);
    setItemCurrentVersion({ [record[recordIsSelectedProperty || 'id']]: val });
  };
  return (
    <div
      test-element-type="search-result"
      className={`${classes.searchResult} ${(isSelected && classes.selectedRow) || ''}`}
      style={style}
      onClick={() => {
        if (onRecordClick) onRecordClick(record, category);
      }}
    >
      <Tooltip title={tooltipValue}>
        <Typography
          variant="body2"
          component="div"
          noWrap={true}
          label={record.title}
          style={{
            width: enableVersionSupport ? '60%' : '100%',
          }}
        >
          {isSelected && <Done className={classes.iconPrimary} />}
          {!isSelected && <Notes className={classes.icon} />}
          {`${record.customIdentifier}: ${record.title}`}
        </Typography>
      </Tooltip>
      {enableVersionSupport && (
        <div className={classes.versionSection}>
          <VersionSwitch
            currentRelease={record.currentRelease}
            workingVersion={record.workingVersion}
            onToggle={handleToggle}
            value={versionId}
            withAddButton={false}
          />
        </div>
      )}
    </div>
  );
};

class RecordsAsCategoryTree extends Component {
  CategoryHeading = ({ classes, category: { icon, name, itemType } }) => {
    const Icon = icon;
    if (!Icon) {
      throw new Error(`Invalid icon passed to Icon for item type ${name.id}`);
    }
    return (
      <div
        className={(this.props.onNavigate && classes.clickable) || {}}
        style={{ marginLeft: 12 }}
        onClick={() => {
          if (this.props.onNavigate) this.props.onNavigate(itemType);
        }}
      >
        <Icon className={classes.icon} />{' '}
        <Typography variant="caption" style={{ display: 'inline' }}>
          {name.id}
        </Typography>
      </div>
    );
  };

  CreateNewItem = ({ createItemButtonText, onCreateItem, theme, category: { itemType } }) => {
    const itemTypeMetadata = ItemTypeIndex[itemType];
    if (itemTypeMetadata === undefined) return null;

    return (
      <div>
        <NewItem
          createItemButtonText={createItemButtonText}
          color={theme.palette.blueDark}
          fontSize="16px"
          defaultSelectedItemType={snakeCase(itemTypeMetadata.itemType)}
          styleOverride={{ padding: '0 0 0 16px' }}
          children={
            <Typography style={{ display: 'inline', marginLeft: 4 }} variant="body2">
              Create New {itemTypeMetadata.label.id}
            </Typography>
          }
          onCreate={onCreateItem}
        />
      </div>
    );
  };

  recordsPresentFor = (categories) => {
    if (!categories) return false;

    return categories.some((category) =>
      category.children
        ? category.children.some(
            (child) => get(this.props.categoryRecordsMap, snakeCase(child.itemType), []).length !== 0,
          )
        : get(this.props.categoryRecordsMap, snakeCase(category.itemType), []).length !== 0,
    );
  };

  displayCategoryHeaders = (categories) => this.props.displayAll || this.recordsPresentFor(categories);

  renderRecordsFor = (category) => {
    const {
      classes,
      categoryRecordsMap,
      onRecordClick,
      selectedRecords,
      style = null,
      recordIsSelectedProperty,
      setItemCurrentVersion,
      enableVersionSupport,
      isLoading,
    } = this.props;
    const records = get(categoryRecordsMap, snakeCase(category.itemType), []);
    const hasValidRecords = records.length > 0 && !(records.length === 1 && records[0] === '');
    return (
      <>
        {isLoading ? (
          <Typography variant="body2" style={{ padding: 6 }}>
            loading...
          </Typography>
        ) : hasValidRecords ? (
          records.map((record) => (
            <CategoryItem
              key={record.id}
              category={category}
              record={record}
              selectedRecords={selectedRecords}
              style={style}
              classes={classes}
              onRecordClick={onRecordClick}
              recordIsSelectedProperty={recordIsSelectedProperty}
              setItemCurrentVersion={setItemCurrentVersion}
              enableVersionSupport={enableVersionSupport}
            />
          ))
        ) : (
          <Typography variant="body2" style={{ padding: 6, marginTop: 0 }}>
            No items found
          </Typography>
        )}
      </>
    );
  };
  render() {
    const CreateNewItem = this.CreateNewItem;

    const {
      classes,
      categoryWhitelist,
      selectedItemType,
      theme,
      onCreateItem,
      createItemButtonText,
      showFullCategoryTree,
      onCategoryClick,
    } = this.props;

    const categoryList = showFullCategoryTree ? FULL_CATEGORIES : CATEGORIES;
    const categoryListIndex = showFullCategoryTree ? FULL_CATEGORIES_INDEX : CATEGORY_INDEX;
    if (selectedItemType) {
      const currentCategoryHierarchy = itemTypeCategoryHierarchy(selectedItemType, categoryListIndex);
      const category = last(currentCategoryHierarchy);

      return (
        <>
          <Typography
            variant="body2"
            style={{
              letterSpacing: 'normal',
              display: 'inline',
            }}
          >
            {currentCategoryHierarchy.map((cat, i) => (
              <span key={cat.slug}>
                {cat.name.id}
                {i < currentCategoryHierarchy.length - 1 && <RightArrow />}
              </span>
            ))}
          </Typography>
          <Spacer factor={1} />
          <Typography
            variant="overline"
            className={classes.clickable}
            component="span"
            style={{
              letterSpacing: 0.3,
              marginBottom: 2,
              fontSize: '0.8125rem',
            }}
            onClick={() => this.props.onNavigate(null)}
          >
            <LeftArrow />
            Back
          </Typography>
          <CreateNewItem
            category={category}
            theme={theme}
            createItemButtonText={createItemButtonText}
            onCreateItem={onCreateItem}
          />
          {this.renderRecordsFor(category)}
        </>
      );
    }
    return (
      <>
        <Spacer factor={1} />
        {Object.keys(categoryList).map(
          (categoryTree, topIndex) =>
            this.displayCategoryHeaders(categoryList[categoryTree].categories) && (
              <React.Fragment key={`heading-${categoryTree}`}>
                {categoryList[categoryTree].categories && (
                  <ExpansionPanelWrapper categoryName={startCase(categoryTree)} index={topIndex} defaultExpanded={true}>
                    {categoryList[categoryTree].categories.map((cat, catIndex) => {
                      if (categoryWhitelist && !categoryWhitelist.hasOwnProperty(cat.slug)) {
                        return null;
                      }

                      return (
                        <React.Fragment key={`${cat.name.id}-${catIndex}-expansion-panel-wrapper`}>
                          {cat.children ? (
                            <ExpansionPanelWrapper
                              categoryName={cat.name.id}
                              icon={cat.icon}
                              index={catIndex}
                              defaultExpanded={true}
                            >
                              {get(cat, 'children', []).map((child, index) => {
                                if (categoryWhitelist && !categoryWhitelist.hasOwnProperty(child.slug)) {
                                  return null;
                                }
                                return (
                                  <React.Fragment key={`category-${child.name.id + index}`}>
                                    <div key={child.name.id + index}>
                                      <ExpansionPanelWrapper
                                        categoryName={child.name.id}
                                        icon={child.icon}
                                        index={index}
                                        defaultExpanded={false}
                                        onClick={() => {
                                          onCategoryClick(snakeCase(child.itemType));
                                        }}
                                      >
                                        {' '}
                                        {this.renderRecordsFor(child)}
                                      </ExpansionPanelWrapper>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </ExpansionPanelWrapper>
                          ) : (
                            <React.Fragment key={`category-${cat.name.id}`}>
                              <ExpansionPanelWrapper
                                categoryName={cat.name.id}
                                icon={cat.icon}
                                index={cat.name.id}
                                defaultExpanded={false}
                                onClick={() => {
                                  onCategoryClick(snakeCase(cat.itemType));
                                }}
                              >
                                {this.renderRecordsFor(cat)}
                              </ExpansionPanelWrapper>
                            </React.Fragment>
                          )}
                          <Spacer factor={0.5} />
                        </React.Fragment>
                      );
                    })}
                  </ExpansionPanelWrapper>
                )}
              </React.Fragment>
            ),
        )}
      </>
    );
  }
}

RecordsAsCategoryTree.defaultProps = {
  allowNavigation: false,
  displayAll: false,
  showFullCategoryTree: true,
  onCategoryClick: () => {},
};

export default flowRight([withCurrentAppWindow, withStyles(styles, { withTheme: true })])(RecordsAsCategoryTree);
