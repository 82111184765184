import React from 'react';

import get from 'lodash/get';

import Table from 'components/core/Table';
import { DateCell } from 'components/TableView/Fields/Date';
import { IDCell } from 'components/TableView/Fields/ID';

import { ChangeOrderBuilderViewQuery_changeOrder_currentRelease_impactedEmployees as ImpactedEmployeesType } from '../../../__generated__/ChangeOrderBuilderViewQuery';
import { TrainingCategory } from '__generated__/globalTypes';

interface Props {
  rows: ImpactedEmployeesType[];
}

function ImpactedEmployees(props: Props) {
  const { rows } = props;

  return (
    <Table
      sortable
      data={rows}
      columns={[
        {
          title: 'First Name',
          field: 'user.firstName',
        },
        {
          title: 'Last Name',
          field: 'user.lastName',
        },
        {
          title: 'Training Item',
          field: 'trainingSource.item.customIdentifier',
          render: (row) => (
            <IDCell
              data={
                row.category === TrainingCategory.READ_AND_UNDERSTAND
                  ? row.trainingItem.customIdentifier
                  : row.trainingSource.item.customIdentifier
              }
            />
          ),
        },
        {
          title: 'Training Record',
          field: 'trainingRecordVersion.item.customIdentifier',
          emptyValue: 'DNE',
        },
        {
          title: 'Due Date',
          field: 'trainingRecordVersion.dueDate',
          emptyValue: 'TBD',
          render: (row) => <DateCell data={get(row, 'trainingRecordVersion.dueDate')} />,
        },
        {
          title: 'TR Status',
          field: 'trainingRecordVersion.currentStatus.titleizedName',
          emptyValue: 'DNE',
        },
      ]}
    />
  );
}

export default ImpactedEmployees;
