import React from 'react';

import { Trans, t } from '@lingui/macro';
import { setupI18n } from '@lingui/core';

import Grid from '@material-ui/core/Grid';

import LowPriority from '@material-ui/icons/LowPriority';
import Sort from '@material-ui/icons/Sort';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import { ContentSection, SelectField, Spacer } from 'components';

import { Views } from 'workspaces';

const i18n = setupI18n();

const testClassificationOptions = [
  i18n._(t`Engineering`),
  i18n._(t`Verification`),
  i18n._(t`Validation`),
  i18n._(t`Preference`),
  i18n._(t`Formative`),
  i18n._(t`Summative`),
];

const testTypeOptions = [
  i18n._(t`Bench`),
  i18n._(t`Animal`),
  i18n._(t`Cadaver`),
  i18n._(t`Test Lab`),
  i18n._(t`Human Factors`),
];

const PayloadFields = ({ activeWorkspaceView, layoutClasses, versionData: currentVersion, displayOnly }) => (
  <>
    <Grid item xs={8} className={layoutClasses.gridGutterRight}>
      <RichTextEditorContentSection
        Heading={
          <>
            <Sort className={layoutClasses.icon} />
            <Trans>Test Summary</Trans>
          </>
        }
        locked={currentVersion.locked}
        initialValue={currentVersion.testSummary}
        versionId={currentVersion.id}
        attrName="test_summary"
        refetchQueries={['testProtocolNoteQuery']}
        showComments={activeWorkspaceView.id === Views.Builder.id}
      />
    </Grid>

    <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
      <ContentSection
        LabelText={
          <>
            <LowPriority className={layoutClasses.icon} />
            <Trans>Test Classification</Trans>
          </>
        }
        fillHeight={false}
      >
        <Spacer factor={1} />
        <SelectField
          attrName="test_class"
          options={testClassificationOptions}
          refetchQueries={['testProtocolNoteQuery']}
          versionId={currentVersion.id}
          value={currentVersion.testClass}
          locked={currentVersion.locked || displayOnly}
          placeholder="Select classification"
        />
      </ContentSection>

      <Spacer factor={3} />

      <ContentSection
        LabelText={
          <>
            <LowPriority className={layoutClasses.icon} />
            <Trans>Test Type</Trans>
          </>
        }
        fillHeight={false}
      >
        <Spacer factor={1} />
        <SelectField
          attrName="test_type"
          options={testTypeOptions}
          refetchQueries={['testProtocolNoteQuery']}
          versionId={currentVersion.id}
          value={currentVersion.testType}
          locked={currentVersion.locked || displayOnly}
          placeholder="Select type"
        />
      </ContentSection>
    </Grid>
  </>
);

export default withWorkspaceViews()(PayloadFields);
