import React from 'react';

import get from 'lodash/get';

import { withItemTypeMetadata } from 'utils/withItemTypeMetadata';

import Vertex from './Vertex';

export default withItemTypeMetadata((props) => {
  const vertex = props.vertex,
    item = vertex.object.item;

  let traceableDownstreamTypes, traceableUpstreamTypes;

  if (props.isRiskTrace === true) {
    traceableUpstreamTypes = get(props.allItemTypeMetadata[item.itemType.name], 'traceableRiskUpstreamTypes', []) || [];
    traceableDownstreamTypes =
      get(props.allItemTypeMetadata[item.itemType.name], 'traceableRiskDownstreamTypes', []) || [];
  } else {
    traceableUpstreamTypes =
      get(props.allItemTypeMetadata[item.itemType.name], 'traceableDesignUpstreamTypes', []) || [];
    traceableDownstreamTypes =
      get(props.allItemTypeMetadata[item.itemType.name], 'traceableDesignDownstreamTypes', []) || [];
  }

  const onAddRightEdge = traceableDownstreamTypes.length ? (e) => props.onAddRightEdge(e, vertex.object) : undefined,
    onAddLeftEdge = traceableUpstreamTypes.length ? (e) => props.onAddLeftEdge(e, vertex.object) : undefined;

  // Would be nice to only have to set selected here on Vertex and not un the underlying Card i.e., children
  return (
    <Vertex
      disabled={props.disabled}
      onClick={() => props.onClick(vertex)}
      onAddRightEdge={onAddRightEdge}
      onAddLeftEdge={onAddLeftEdge}
      selected={props.selected}
    >
      {props.children}
    </Vertex>
  );
});
