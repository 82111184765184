import React, { CSSProperties } from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

export function BooleanResultCell({ data }) {
  const classes = useStyles();

  const getValue = (value) => {
    if (value == null) {
      return 'n/a';
    }

    return value ? 'PASS' : 'FAIL';
  };

  return (
    <Typography
      className={clsx(classes.content, data == null ? undefined : data ? classes.pass : classes.fail)}
      variant="body2"
    >
      {getValue(data)}
    </Typography>
  );
}

export const boolResultCellHeaderStyle = { textAlign: 'center' } as CSSProperties;
