import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import More from '@material-ui/icons/More';

import slugs from '../../displaySlugs';
import { ItemTypeDefinition } from 'itemTypes';

import { DocumentToolbar } from '../mixins/Document';
import PayloadFields from './PayloadFields';
import Views from 'workspaces/views';

const itemType = 'miscellaneousRecord';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Miscellaneous Record`,
  categoryLabel: t`Misc. Records`,
  icon: More,
  listViewQuery: gql`
    query miscellaneousRecordsQuery {
      miscellaneousRecords {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        createdAt
        tags
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        currentVersion {
          id
          title
          createdAt
          currentStatusName
          versionIdentifier
          signingOwner {
            id
            firstName
            lastName
          }
          signingApprover {
            id
            firstName
            lastName
          }
        }
      }
    }
  `,
  listViewColumns: [
    {
      field: 'customIdentifier',
      headerName: 'ID',
      type: 'id',
    },
    {
      field: 'currentVersion.title',
      headerName: 'Title',
      type: 'regular_text',
    },
    {
      field: 'currentVersion.currentStatusName',
      headerName: 'Status',
      type: 'status',
    },
    {
      field: 'currentVersion.createdAt',
      headerName: 'Initiation Date',
      type: 'date',
    },
    {
      field: 'owner',
      headerName: 'Owner',
      type: 'user',
    },
    {
      field: 'approver',
      headerName: 'Approver',
      type: 'user',
    },
  ],
  PayloadFields,
  recordWorkspace: [Views.Builder, Views.Effective],
  displayHistoryInEffectiveView: false,
  EffectiveViewOptions: DocumentToolbar,
} as ItemTypeDefinition;
