import React from 'react';

import { Typography } from '@material-ui/core';
import { Link } from '@reach/router';

import Icon from 'assets/icons/Item';

import { useStyles } from './styles';

export interface Props {
  customIdentifier: string;
  itemType: string;
  linkTo: string;
  title?: string;
  isLink?: boolean;
}

export function CardHeader(props: Props) {
  const { isLink = true } = props;
  const classes = useStyles();

  const title = (
    <Typography variant="body2" className={classes.titleText}>
      <Icon fontSize="inherit" type={props.itemType} /> {props.customIdentifier} {props.title && `: ${props.title}`}
    </Typography>
  );

  return (
    <div style={{ display: 'inline', paddingBottom: 4 }}>
      {isLink ? (
        <Link to={props.linkTo} className={classes.cardTitle}>
          {title}
        </Link>
      ) : (
        title
      )}
    </div>
  );
}

export default CardHeader;
