import gql from 'graphql-tag';

export const UPDATE_PASSWORD_MUTATION = gql`
    mutation UpdatePasswordMutation($input: UpdatePasswordInput!) {
        updatePassword(input: $input) {
            clientMutationId
            success
            errors
        }
    }
`;