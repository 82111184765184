import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import EmptyList from './EmptyList';

import TaskSelect from './TaskSelect';
import TaskItemHeader from './TaskItemHeader';

import { useStyles } from './styles';
import { tasksQuery_tasks } from '../__generated__/tasksQuery';
import { Transition } from '../types';

interface Props {
  tasks: tasksQuery_tasks[] | null;
  onChange: (
    currentWorkflowStatus: string,
    versionId: string,
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
  transitions: Transition[];
  onRedirect: () => void;
}

const TaskList: FC<Props> = ({ tasks, onChange, transitions, onRedirect }) => {
  const classes = useStyles();

  if (!tasks) return null;

  return (
    <>
      {tasks.length ? (
        tasks.map((task) => (
          <div key={task.id} className={classes.taskItem}>
            <TaskItemHeader
              id={task.id}
              customIdentifier={task.customIdentifier}
              dueDate={task.currentVersion.dueDate}
              onRedirect={onRedirect}
            />
            <div className={classes.content}>
              <TaskSelect
                currentValue={task.currentVersion.currentStatus.name}
                onChange={(currentWorkflowStatus, event) =>
                  onChange(currentWorkflowStatus, task.currentVersion.id, event)
                }
                transitions={transitions}
              />
              <Typography variant="caption" className={classes.taskTitle}>
                {task.currentVersion.title}
              </Typography>
            </div>
          </div>
        ))
      ) : (
        <EmptyList />
      )}
    </>
  );
};

export default TaskList;
