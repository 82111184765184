import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import Business from '@material-ui/icons/Business';

import { useQuery } from 'react-apollo';

import { riskStringToParams } from 'pages/Supplier/Id/PayloadFields/RenderTab';
import AgGridTable from 'components/AgGridTable';
import { Loading } from 'components';
import ColorBox from 'components/RiskLevelSelect/ColorBox';

import { useStyles } from './styles';
import { suppliersQuery } from './gql';

const columns = [
  {
    headerName: 'ID',
    type: 'id',
    field: 'customIdentifier',
  },
  {
    headerName: 'Company name',
    field: 'currentVersion.title',
    type: 'regular_text',
  },
  {
    headerName: 'Category',
    field: 'currentVersion.categoryText',
    type: 'regular_text',
  },
  { headerName: 'Scope', field: 'currentVersion.suppliedScope', type: 'long_text' },
  {
    headerName: 'Risk Level',
    field: 'currentVersion.riskLevel',
    type: 'custom',
    enableRowGroup: true,
    width: '180px',
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        riskLevel: row.value.currentVersion.riskLevel,
      });
    },
    filterParams: {
      valueFormatter: (row) => {
        return JSON.parse(row.value).riskLevel;
      },
    },
    render(row) {
      if (row.value === undefined) return null;
      let riskLevel;
      const isColumnGroupedBy = Boolean(
        row.columnApi.columnModel.rowGroupColumns.find((column) => column.colId === 'currentVersion.riskLevel'),
      );
      if (isColumnGroupedBy) {
        riskLevel = JSON.parse(row.value).riskLevel;
      } else {
        riskLevel = row.data.currentVersion.riskLevel;
      }
      return (
        <div style={{ display: 'flex' }}>
          {riskLevel && (
            <>
              <ColorBox color={riskStringToParams(riskLevel).color} />
              <Typography variant="body2">{riskStringToParams(riskLevel).label}</Typography>
            </>
          )}
        </div>
      );
    },
  },
  {
    headerName: 'Status',
    field: 'currentVersion.currentStatus.name',
    type: 'status',
  },
  {
    headerName: 'Approval Date',
    field: 'currentVersion.currentStatus.createdAt',
    type: 'date',
  },
  {
    headerName: 'Status Expiration Date',
    field: 'currentVersion.approvalExpiresAt',
    type: 'date',
  },
];

function ApprovedASuppliers(_) {
  const classes = useStyles();

  const { data, loading, error } = useQuery(suppliersQuery);

  if (loading) return <Loading />;
  if (error) throw new Error(`Graphql Error: ${error}`);

  return (
    <Grid container xs={12} className={classes.container}>
      <Grid item xs={12}>
        <AgGridTable
          columns={columns}
          data={data.suppliers}
          TableTitle={() => (
            <div className={classes.titleContainer}>
              <Business viewBox="0 0 24 24" />
              <Typography variant="h2" classes={{ root: classes.typography }}>
                Approved Suppliers List
              </Typography>
            </div>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default ApprovedASuppliers;
