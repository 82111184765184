import React from 'react';

import { Typography } from '@material-ui/core';

import Table from 'components/core/Table';
import Panel from 'components/core/Panel';
import Spacer from 'components/Spacer';
import { CustomColumn } from '../../core/Table/types';

interface Props {
  items: any[];
  versionIdentifier: string;
  columns: CustomColumn<any>[];
}

const getVersionTitle = (versionIdentifier: string): string => `Version ${versionIdentifier.substr(1)}`;

const ItemsTable = (props: Props) => {
  const { items, columns } = props;

  return (
    <Panel>
      <Typography variant={'body2'}>{getVersionTitle(props.versionIdentifier)}</Typography>
      <Spacer factor={1} />
      <Table columns={columns} data={items} useGenericColumns />
    </Panel>
  );
};

export default ItemsTable;
