export default (theme) => ({
  '@global': {
    '@media print': {
      '#sidebar': {
        display: 'none',
      },
    },
  },
  drawer: {
    flexShrink: 0,
    width: theme.mixins.sidebar.expandedWidth,
    height: '100vh',
  },
  drawerOpen: {
    width: theme.mixins.sidebar.expandedWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.mixins.sidebar.collapsedWidth,
    '& .MuiPaper-root': {
      visibility: 'visible !important',
      transform: 'translateX(-293px) !important',
    },
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'visible',
    width: theme.mixins.sidebar.expandedWidth,
  },
  paperClose: {
    overflowX: 'visible',
    transform: 'translateX(293px)',
  },
  listItem: {
    borderRadius: theme.shape.borderRadius,
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    padding: 0,
    width: 'auto',
  },
  listItemDisabled: {
    backgroundColor: theme.palette.background.transparent,
    color: theme.palette.text.disabled,
    padding: theme.spacing(1),
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.background.transparent,
    },
    '&,&:hover,&:focus': {
      backgroundColor: theme.palette.background.transparent,
    },
    div: {
      flex: 'none',
    },
  },
  listItemHighlighted: {
    backgroundColor: theme.palette.action.hover,
  },
  listItemIcon: {
    minWidth: 36,
  },
  listItemSecondaryActionButton: {
    borderRadius: theme.shape.borderRadius,
    color: 'inherit',
    padding: theme.spacing(0.5),
  },
  listItemText: {
    padding: 0,
    marginRight: theme.spacing(2),
    ...theme.typography.body2,
    color: '#3C4858',
  },
  listItemTextActive: {
    flex: 1,
    padding: 0,
    marginRight: theme.spacing(1.5),
    ...theme.typography.body2,
    color: 'inherit',
  },
  listItemTextDisabled: {
    color: 'inherit',
    flex: 'none',
  },
  lockedItemIcon: {
    fontSize: theme.spacing(1.5),
  },
  logo: {
    display: 'block',
    margin: '0',
    padding: `${theme.spacing(3.5)}px 0px ${theme.spacing(2)}px`,
    position: 'relative',
  },
  logoImg: {
    border: '0',
    display: 'block',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    verticalAlign: 'middle',
    width: theme.spacing(25),
  },
  navLink: {
    alignItems: 'center',
    color: theme.palette.text.primary,
    display: 'flex',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    width: '100%',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '&,&:hover,&:focus': {
      color: theme.palette.text.primary,
    },
  },
  navLinkActive: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    width: '100%',
    boxShadow: `0 12px 20px -10px rgba(47,188,178,.28),
                0 4px 20px 0 rgba(0,0,0,.12),
                0 7px 8px -5px rgba(47,188,178,.2)`,
    '&,&:hover,&:focus': {
      color: theme.palette.primary.contrastText,
    },
  },
  navWrapper: {
    position: 'relative',
    /* logo height is 97 */
    height: `calc(100vh - ${theme.spacing(7) + 97}px)`,
    overflowX: 'auto',
    overflowY: 'scroll',
    width: '100%',
    maxWidth: theme.mixins.sidebar.expandedWidth,
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    paddingBottom: theme.spacing(4),
  },
  nested: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1) / -4,
  },
  offset: {
    width: theme.spacing(8),
  },
  sidebarWrapper: {
    width: '100%',
    padding: '0',
  },
});
