import React from 'react';
import { navigate } from '@reach/router';

import { Grid, Typography } from '@material-ui/core';
import Business from '@material-ui/icons/Business';

import { useQuery } from 'react-apollo';

import AgGridTable from 'components/AgGridTable';
import { Breadcrumbs, Loading, MakeLink, VersionAndStatusDot } from 'components';

import { useStyles } from './styles';
import { riskControlImplementationTestsQuery } from './gql';
import { isColumnGroupedBy } from 'utils/agGridHelpers';

const columns = [
  {
    field: 'riskControlImplementationTest.item.customIdentifier',
    headerName: 'ID',
    type: 'custom',
    width: '90px',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.riskControlImplementationTest.item.customIdentifier;
    },
    render: (row) => {
      return (
        <MakeLink newTab={true} item={row.value.riskControlImplementationTest.item}>
          {row.value.riskControlImplementationTest.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    field: 'riskControlImplementationTest.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'riskAnalysisVersion.item.customIdentifier',
    headerName: 'Associated RAN ID',
    width: '90px',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.riskAnalysisVersion.item.customIdentifier;
    },
    render: (row) => {
      return (
        <MakeLink newTab={true} item={row.value.riskAnalysisVersion.item}>
          {row.value.riskAnalysisVersion.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    field: 'riskAnalysisVersion.title',
    headerName: 'Associated RAN Title',
    type: 'regular_text',
  },
  {
    field: 'riskAnalysisVersion',
    headerName: 'Associated RAN Version',
    width: '90px',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        versionIdentifier: row.value.riskAnalysisVersion.versionIdentifier,
        currentStatusName: row.value.riskAnalysisVersion.currentStatus.name,
      });
    },
    render(row) {
      if (row.value === undefined) return null;
      let versionIdentifier;
      let currentStatusName;
      if (isColumnGroupedBy(row, 'riskAnalysisVersion')) {
        versionIdentifier = JSON.parse(row.value).versionIdentifier;
        currentStatusName = JSON.parse(row.value).currentStatusName;
      } else {
        versionIdentifier = row.data.riskAnalysisVersion.versionIdentifier;
        currentStatusName = row.data.riskAnalysisVersion.currentStatus.name;
      }
      return (
        <div style={{ width: 64, display: 'flex', justifyContent: 'center' }}>
          <VersionAndStatusDot version={versionIdentifier} status={currentStatusName} />
        </div>
      );
    },
  },
  {
    field: 'riskControlVersion.item.customIdentifier',
    headerName: 'Associated RCT ID',
    width: '90px',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.riskControlVersion.item.customIdentifier;
    },
    render: (row) => {
      return (
        <MakeLink newTab={true} item={row.value.riskControlVersion.item}>
          {row.value.riskControlVersion.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    field: 'riskControlVersion.title',
    headerName: 'Associated RCT Title',
    type: 'regular_text',
  },
  {
    field: 'riskControlVersion',
    headerName: 'Associated RCT Version',
    width: '90px',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        versionIdentifier: row.value.riskControlVersion.versionIdentifier,
        currentStatusName: row.value.riskControlVersion.currentStatus.name,
      });
    },
    render(row) {
      if (row.value === undefined) return null;
      let versionIdentifier;
      let currentStatusName;
      if (isColumnGroupedBy(row, 'riskControlVersion')) {
        versionIdentifier = JSON.parse(row.value).versionIdentifier;
        currentStatusName = JSON.parse(row.value).currentStatusName;
      } else {
        versionIdentifier = row.data.riskControlVersion.versionIdentifier;
        currentStatusName = row.data.riskControlVersion.currentStatus.name;
      }
      return (
        <div style={{ width: 64, display: 'flex', justifyContent: 'center' }}>
          <VersionAndStatusDot version={versionIdentifier} status={currentStatusName} />
        </div>
      );
    },
  },
  {
    field: 'requirementVersion.item.customIdentifier',
    headerName: 'Associated Requirement Version',
    width: '100px',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.requirementVersion.item.customIdentifier;
    },
    render: (row) => {
      return (
        <MakeLink newTab={true} item={row.value.requirementVersion.item}>
          {row.value.requirementVersion.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    field: 'requirementVersion.title',
    headerName: 'Associated Requirement Version',
    type: 'regular_text',
  },
  {
    field: 'requirementVersion',
    headerName: 'Associated Requirement Version',
    width: '100px',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        versionIdentifier: row.value.requirementVersion.versionIdentifier,
        currentStatusName: row.value.requirementVersion.currentStatus.name,
      });
    },
    render(row) {
      if (row.value === undefined) return null;
      let versionIdentifier;
      let currentStatusName;
      if (isColumnGroupedBy(row, 'requirementVersion')) {
        versionIdentifier = JSON.parse(row.value).versionIdentifier;
        currentStatusName = JSON.parse(row.value).currentStatusName;
      } else {
        versionIdentifier = row.data.requirementVersion.versionIdentifier;
        currentStatusName = row.data.requirementVersion.currentStatus.name;
      }
      return (
        <div style={{ width: 64, display: 'flex', justifyContent: 'center' }}>
          <VersionAndStatusDot version={versionIdentifier} status={currentStatusName} />
        </div>
      );
    },
  },
];

function RiskControlImplementationTests(_) {
  const classes = useStyles();

  const { data, loading, error } = useQuery(riskControlImplementationTestsQuery);

  if (loading) return <Loading />;
  if (error) throw new Error(`Graphql Error: ${error}`);

  return (
    <Grid container xs={12} className={classes.container}>
      <Breadcrumbs categorySlug="risk-trace-matrices" />
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <AgGridTable
          columns={columns}
          data={data.riskControlImplementationTests}
          TableTitle={() => (
            <div className={classes.titleContainer}>
              <Business viewBox="0 0 24 24" />
              <Typography variant="h2" classes={{ root: classes.typography }}>
                Risk Control Implementation Tests
              </Typography>
            </div>
          )}
          onCellClicked={(row) =>
            navigate(
              `/category/${row.data.riskControlImplementationTest.item.itemType.displaySlug}/${
                row.data.riskControlImplementationTest.item.id
              }`,
            )
          }
        />
      </Grid>
    </Grid>
  );
}

export default RiskControlImplementationTests;
