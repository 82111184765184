import React, { useState } from 'react';

import { useStyles as useTimelineStyles } from 'compositions/TimelineGroup/styles';
import WorkflowModal from 'components/WorkflowModal';
import WorkflowModalContainer from 'components/WorkflowModalContainer';
import { transitionMutation as transMut, createVersion as versMut } from 'utils/gql/timelineQueries';

import TrainingPlanTimeline from './TrainingPlanTimeline';
import { useTrainingPlanWorkflowData, useUpdateChangeFields } from './gql';
import Wizard, { TransitionWizard } from './Wizard';
import { TransitionPayload, ServerAction, Status } from './statuses';
import { useMutation } from 'react-apollo';
import useItemType from 'pages/generic/useItemType';

const InitialWizardState = { currentWizard: null, clearError: false };
const useWizard = () => useState<{ currentWizard: TransitionWizard | null; clearError: boolean }>(InitialWizardState);

function TrainingPlanWorkflow(props: { itemId: string }) {
  const trainingRecord = useItemType('training_record');
  const trainingPlan = useItemType('training_plan');
  const [{ currentWizard, clearError }, setWizard] = useWizard();
  const closeWizard = () => setWizard(InitialWizardState);

  const classes = useTimelineStyles();
  const { data, loading, error, refetch } = useTrainingPlanWorkflowData(props.itemId);

  const [createStatusTransition, { error: transErr }] = useMutation(transMut, {
    refetchQueries: ['TrainingPlanBuilderViewQuery'],
  });
  const [createVersion, { error: createErr }] = useMutation(versMut, {
    refetchQueries: ['TrainingPlanBuilderViewQuery'],
  });
  const [updateChangeFields] = useUpdateChangeFields();

  if (error) throw new Error(`Failed to load workflow: ${error.message}`);
  if (loading || !data) return null;

  const { workingVersion, currentVersion, customIdentifier } = data.item;

  const createTransition = async (data: TransitionPayload) => {
    await setWizard({ currentWizard, clearError: false });
    switch (data.action) {
      case ServerAction.UpdateVersion:
        if (!workingVersion) throw new Error(`Cant transition w/o current version (item: ${props.itemId})`);
        await updateChangeFields({
          variables: {
            versionId: workingVersion.id,
            ...data.payload,
          },
        });
        break;
      case ServerAction.StatusTransition:
        if (!workingVersion) throw new Error(`Cant transition w/o current version (item: ${props.itemId})`);

        const variables = {
          input: {
            versionId: workingVersion.id,
            ...data.payload,
          },
        };
        const refetchQueries = trainingRecord && [{ query: trainingRecord.listViewQuery }];
        await createStatusTransition(
          refetchQueries && data.payload.toStatus === Status.Released ? { variables, refetchQueries } : { variables },
        );
        break;
      case ServerAction.CreateVersion:
        await createVersion({
          variables: {
            input: {
              itemId: props.itemId,
            },
          },
        });
        break;
    }

    if (!createErr) {
      closeWizard();
      await refetch();
    }
  };

  const WizardWrapper = () => (
    <Wizard
      error={clearError ? undefined : transErr || createErr}
      itemTypeMetadata={trainingPlan}
      itemCustomIdentifier={customIdentifier}
      versionIdentifier={workingVersion ? workingVersion.versionIdentifier : undefined}
      reasonForChange={workingVersion ? workingVersion.reasonForChange : undefined}
      descriptionOfChange={workingVersion ? workingVersion.descriptionOfChange : undefined}
      createTransition={createTransition}
      currentVersion={workingVersion}
      type={currentWizard}
    />
  );

  // transErr in Wizard is what's returned when status transition fails
  return (
    <div className={classes.timelineBtnContainer}>
      <TrainingPlanTimeline
        currentVersion={
          currentVersion.currentStatus.name === 'released' ||
          currentVersion.currentStatus.name === 'canceled' ||
          currentVersion.currentStatus.name === 'inactive'
            ? currentVersion
            : workingVersion
        }
        startWizard={(t: TransitionWizard) => setWizard({ currentWizard: t, clearError: true })}
        releasedVersions={
          data.item.versions
            ? data.item.versions.filter(
                (r) =>
                  r.currentStatus.name === 'canceled' ||
                  r.releasedAt !== null ||
                  currentVersion.currentStatus.name === 'inactive',
              )
            : []
        }
      />
      {/* We must do this because the "wizard" assumes the content rendered can be wrapped in Modal componenents which is not true */}
      {currentWizard === TransitionWizard.UnderReviewToPrepared && !workingVersion.mayTransitionToPrepared ? (
        <WizardWrapper />
      ) : (
        <WorkflowModal open={currentWizard !== null} onClose={closeWizard}>
          <WorkflowModalContainer onClose={closeWizard} showBackButton={false} onBack={closeWizard}>
            <WizardWrapper />
          </WorkflowModalContainer>
        </WorkflowModal>
      )}
    </div>
  );
}
export default TrainingPlanWorkflow;
