import React from 'react';
import gql from 'graphql-tag';
import { t } from '@lingui/macro';

import PanTool from '@material-ui/icons/PanTool';
import { Typography } from '@material-ui/core';

import slugs from '../../displaySlugs';
import PayloadFields from './PayloadFields';

const itemType = 'riskControl';

const versionFields = `
  description
  strategy
  setMember {
    id
    setItemVersion {
      id
      item {
        id
        customIdentifier
      }
    }
  }
`;

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.strategy',
    headerName: 'Strategy',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.setMember.setItemVersion.item.customIdentifier',
    headerName: 'Associated RAN',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (row.data === undefined) return null;
      return row.data.currentVersion.setMember
        ? row.data.currentVersion.setMember.setItemVersion.item.customIdentifier
        : '-';
    },
    render: (row) => {
      if (row.value === undefined) return null;
      return (
        <Typography variant={'body2'} style={{ width: 100 }}>
          {row.value}
        </Typography>
      );
    },
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query riskControlsQuery {
    riskControls {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        id
        createdAt
        title
        description
        strategy
        currentStatusName
        versionIdentifier
        signingOwner {
          id
          firstName
          lastName
        }
        setMember {
          id
          setItemVersion {
            id
            title
            item {
              id
              customIdentifier
            }
            versionIdentifier
          }
        }
        signingApprover {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Risk Control`,
  categoryLabel: t`Risk Controls`,
  icon: PanTool,
  listViewColumns,
  listViewQuery,
  PayloadFields,
  versionFields,
};
