import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    taskItem: {
      padding: theme.spacing(2, 3),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.blueDark,
      marginBottom: theme.spacing(1),
    },
    titleLink: {
      color: theme.palette.secondary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    dueDate: {
      color: theme.palette.blueDark,
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: 'uppercase',
    },
    content: {
      display: 'flex',
    },
    select: {
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 1px 3px rgba(126, 161, 159, 0.28)',
      padding: theme.spacing(1, 1.5),

      '&.MuiSelect-select': {
        paddingRight: theme.spacing(3.5),
      },

      '& .MuiTypography-root': {
        display: 'none',
      },
    },
    selectItem: {
      display: 'flex',
      alignItems: 'center',
    },
    selectItemText: {
      marginLeft: theme.spacing(1),
    },
    taskTitle: {
      fontSize: 11,
      lineHeight: '18px',
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
    },
    emptyListIcon: {
      color: theme.palette.blueDark,
      fontSize: 16,
    },
  }),
);
