import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import slugs from '../../displaySlugs';

import { listFragment, docMetadata } from '../mixins/Document';
const itemType = 'riskAssessmentDocument';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Risk Assessment Document`,
  categoryLabel: t`Risk Assessment Documents`,
  icon: LibraryBooks,
  listViewQuery: gql`
    query riskAssessmentDocumentsQuery {
      documents(itemType: "risk_assessment_document") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
