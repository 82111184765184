import React, { useState } from 'react';

import { Card, Collapse, Grid, Typography, withStyles } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Sort from '@material-ui/icons/Sort';
import AddCircle from '@material-ui/icons/AddCircle';
import CameraAlt from '@material-ui/icons/CameraAlt';
import AttachFile from '@material-ui/icons/AttachFile';

import { Trans } from '@lingui/macro';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import { DEBOUNCE_MILLISECONDS } from '../../constants';

import AttachmentControl from './AttachmentControl';
import IconButton from '../IconButton';
import PreviewControl from './PreviewControl';

import styles from './styles';

export interface Attachment {
  id: string;
  url: string;
  previewUrl?: string | null;
  description: string | null;
}

function AttachmentsSection(props: {
  classes;
  attachments: Attachment[];
  versionId: string;
  locked: boolean;
  updateAttachment: (
    id: string,
    attachment: {
      url?: string;
      description?: string;
      previewUrl?: string;
    }
  ) => Promise<any>;
  createAttachment: (versionId: string, url: string) => Promise<any>;
  destroyAttachment: (id: string) => Promise<any>;
  enablePreview: boolean;
  title?: string;
  displayHeader: boolean;
  displayNoItemsPanel?: boolean;
}) {
  const [open, setOpen] = useState(true);
  const [blankRow, setBlankRow] = useState<boolean>(false);
  const { displayNoItemsPanel = false } = props;

  const isShowNoLinesPanel = displayNoItemsPanel && !props.attachments.length && !blankRow;

  const handleDescriptionChange = React.useCallback(
    debounce((attachmentId: string, description: string) => {
      props.updateAttachment(attachmentId, { description }).catch(e => {
        throw new Error(
          `Error updating attachment (versionId ${
            props.versionId
          }) ${attachmentId}:\n${e}`
        );
      });
    }, DEBOUNCE_MILLISECONDS),
    []
  );

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Grid
      container
      item
      xs={12}
      className={clsx(props.displayHeader && props.classes.internalPageSection)}
    >
      {props.displayHeader && (
        <div className={props.classes.internalPageSectionTitleWrapper}>
          <div
            className={props.classes.internalPageSectionTitle}
            onClick={handleClick}
          >
            <AttachFile className={props.classes.icon} />
            <Typography
              variant="h2"
              classes={{ root: props.classes.typography }}
            >
              {props.title}
            </Typography>
            {open ? (
              <KeyboardArrowUp className={props.classes.titleExpandIcon} />
            ) : (
              <KeyboardArrowDown className={props.classes.titleExpandIcon} />
            )}
          </div>
        </div>
      )}
      <Grid
        item
        xs={12}
        className={clsx(
          props.displayHeader && props.classes.internalPageSectionContent
        )}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className={props.classes.miscAttachmentTableWrapper}>
            <div className={props.classes.tableTitleRow}>
              <Grid
                item
                className={props.classes.tableTitleWrapper}
                xs={props.enablePreview ? 3 : 4}
              >
                <Typography variant="overline">
                  <AttachFile className={props.classes.icon} />
                  <Trans>File Attachment</Trans>
                </Typography>
              </Grid>

              {props.enablePreview && (
                <Grid item className={props.classes.tableTitleWrapper} xs={3}>
                  <CameraAlt className={props.classes.icon} />
                  <Trans>Image</Trans>
                </Grid>
              )}

              <Grid
                item
                className={props.classes.tableTitleWrapper}
                xs={props.enablePreview ? 5 : 7}
              >
                <Typography variant="overline">
                  <Sort className={props.classes.icon} />
                  <Trans>Description</Trans>
                </Typography>
              </Grid>
            </div>
            {isShowNoLinesPanel && (
              <Card className={props.classes.noItemsPanel} variant={'outlined'}>
                <Typography variant="caption">No files added.</Typography>
              </Card>
            )}
            {props.attachments.map((attachment) => (
              <Grid key={attachment.id} container className={props.classes.miscAttachmentInputRow}>
                <Grid item xs={props.enablePreview ? 3 : 4} className={props.classes.miscAttachmentInputWrapper}>
                  <AttachmentControl
                    versionId={props.versionId}
                    locked={props.locked}
                    attachment={attachment}
                    singleAttachment={true}
                    downloadable={true}
                    classes={props.classes}
                    updateAttachment={(id: string, url: string) =>
                      props.updateAttachment(id, { url })
                    }
                    createAttachment={props.createAttachment}
                    destroyAttachment={() =>
                      props.destroyAttachment(attachment.id)
                    }
                  />
                </Grid>
                {props.enablePreview && (
                  <Grid
                    item
                    xs={3}
                    className={props.classes.miscAttachmentInputWrapper}
                  >
                    <PreviewControl
                      versionId={props.versionId}
                      attachmentId={attachment.id}
                      previewUrl={attachment.previewUrl}
                      classes={props.classes}
                      locked={props.locked}
                      updateAttachment={(id: string, url: string) =>
                        props.updateAttachment(id, { previewUrl: url })
                      }
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={props.enablePreview ? 5 : 7}
                  className={props.classes.miscAttachmentInputWrapper}
                >
                  <input
                    type="text"
                    disabled={props.locked}
                    className={props.classes.attachmentInput}
                    style={{ padding: 7 }}
                    placeholder="File Description"
                    defaultValue={attachment.description || undefined}
                    onChange={e => {
                      e.persist();
                      handleDescriptionChange(attachment.id, e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            ))}
            {blankRow && (
              <Grid container className={props.classes.miscAttachmentInputRow}>
                <Grid
                  item
                  xs={props.enablePreview ? 3 : 4}
                  className={props.classes.miscAttachmentInputWrapper}
                >
                  <AttachmentControl
                    versionId={props.versionId}
                    locked={props.locked}
                    classes={props.classes}
                    updateAttachment={(id: string, url: string) =>
                      props.updateAttachment(id, { url })
                    }
                    createAttachment={props.createAttachment}
                  />
                </Grid>
                {props.enablePreview && (
                  <Grid
                    item
                    xs={3}
                    className={props.classes.miscAttachmentInputWrapper}
                  >
                    <PreviewControl
                      locked={props.locked}
                      versionId={props.versionId}
                      classes={props.classes}
                      updateAttachment={(id: string, url: string) =>
                        props.updateAttachment(id, { previewUrl: url })
                      }
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={props.enablePreview ? 5 : 7}
                  className={props.classes.miscAttachmentInputWrapper}
                >
                  <input
                    type="text"
                    disabled={props.locked}
                    className={props.classes.attachmentInput}
                    style={{ padding: 7 }}
                    placeholder="File Description"
                  />
                </Grid>
              </Grid>
            )}
            {!blankRow && !props.locked && (
              <div className={props.classes.addRowButton}>
                <IconButton
                  Icon={AddCircle}
                  onClick={() => setBlankRow(true)}
                  size="large"
                  tooltip="Add new attachment"
                />
              </div>
            )}
          </div>
        </Collapse>
      </Grid>
    </Grid>
  );
}

AttachmentsSection.defaultProps = {
  displayHeader: true
};

export default withStyles(styles)(AttachmentsSection);
