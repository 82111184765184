import { WorkflowDefinition, WorkflowTransition } from '../types';

import CreateNewVersion from './CreateNewVersion';
import UnderReview from './UnderReview';
import OwnerApproval from './OwnerApproval';
import OwnerApprovalWithoutSig from './OwnerApprovalWithoutSig';
import AssociatedChange from './AssociatedChange';
import ReleaseOrReject from './ReleaseOrReject';
import ApproveOrReject from './ApproveOrReject';
import ResolveRejection from './ResolveRejection';
import InitiateRetirement from './InitiateRetirement';
import Cancel from './Cancel';
import CancelRetirement from './CancelRetirement';
import SummaryOfChange from '../TraceMatrix/SummaryOfChange';
import ReasonForChange from '../shared/ReasonForChange';
import RetireOrRejectWithoutCO from './RetireOrRejectWithoutCO';
import Void from './Void';

const courseWorkflowSteps: WorkflowDefinition = {
  createNewVersion: CreateNewVersion as WorkflowTransition,
  underReview: UnderReview as WorkflowTransition,
  ownerApprovalWithoutSig: OwnerApprovalWithoutSig as WorkflowTransition,
  associatedChange: AssociatedChange as WorkflowTransition,
  ownerApproval: OwnerApproval as WorkflowTransition,
  approveOrReject: ApproveOrReject as WorkflowTransition,
  summaryOfChange: SummaryOfChange as WorkflowTransition,
  reasonForChange: ReasonForChange as WorkflowTransition,
  releaseOrReject: ReleaseOrReject as WorkflowTransition,
  resolveRejection: ResolveRejection as WorkflowTransition,
  canceled: Cancel as WorkflowTransition,
  initiateRetirement: InitiateRetirement as WorkflowTransition,
  cancelRetirement: CancelRetirement as WorkflowTransition,
  retireOrRejectWithoutCO: RetireOrRejectWithoutCO as WorkflowTransition,
  voidOwnerApproval: Void as WorkflowTransition,
};

export default courseWorkflowSteps;
