function getUsersWithoutActiveRole(owner, approver, assignedOwnerJobRole, assignedApproverJobRole) {
  if (approver) {
    const approverHasActiveRole =
      assignedApproverJobRole && assignedApproverJobRole.usersWithRole.some((user) => user.id === approver.id);
    if (!approverHasActiveRole) {
      return [{ user: { id: approver.id, fullName: approver.fullName }, role: 'approver' }];
    }
  }

  if (owner) {
    const ownerHasActiveRole =
      assignedOwnerJobRole && assignedOwnerJobRole.usersWithRole.some((user) => user.id === owner.id);
    if (!ownerHasActiveRole) {
      return [{ user: { id: owner.id, fullName: owner.fullName }, role: 'owner' }];
    }
  }

  return [];
}

export default getUsersWithoutActiveRole;
