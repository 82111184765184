import React from 'react';

import { get } from 'lodash';
import { AuditTrailTransition } from '../../index';

type ParentSetProps = Pick<AuditTrailTransition, 'versionIdentifier' | 'parentSetChange'>;

export default function ParentSetChange(props: ParentSetProps) {
  const { versionIdentifier, parentSetChange } = props;

  const parentCustomIdentifier = get(parentSetChange, 'customIdentifier');
  const parentVersionIdentifier = get(parentSetChange, 'versionIdentifier');
  const action = get(parentSetChange, 'action');

  return (
    <>
      {versionIdentifier} was {action} from {parentCustomIdentifier} {parentVersionIdentifier}
    </>
  );
}
