import React from 'react';
import { Trans } from '@lingui/macro';

import { CertifiedSignature, RejectReason, YesOrNo } from 'compositions/TimelineGroup/steps/views';
import { GenericRecordWorkflowStatuses } from 'workflows/statuses';
import { buildReleaseMessage } from '../shared/workflowTransitionsUtil';

export default {
  initialStep: 'releaseOrReject',
  internalSteps: {
    releaseOrReject: {
      component: (props) => (
        <YesOrNo
          prompt="As Approver, I have reviewed this record and have determined that this Record can be Released."
          yesLabel="Close"
          noLabel="Fail"
          {...props}
        />
      ),
      getNextStep: (release) => (release ? 'releaseSignature' : 'rejectReason'),
    },
    rejectReason: {
      component: (props) => {
        const heading = `Please provide context rejecting ${props.itemCustomIdentifier}: ${props.currentVersion.title}`;
        return <RejectReason {...props} heading={heading} />;
      },
      getNextStep: (_) => 'complete',
    },
    releaseSignature: {
      component: (props) => {
        const heading = <Trans>Release</Trans>;
        const dialog = buildReleaseMessage(props);
        return <CertifiedSignature dialog={dialog} heading={heading} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ releaseOrReject, releaseSignature, rejectReason }) => {
    if (releaseOrReject) {
      return {
        toStatus: GenericRecordWorkflowStatuses.Released.id,
        ...releaseSignature,
      };
    } else {
      return {
        toStatus: GenericRecordWorkflowStatuses.Rejected.id,
        ...rejectReason.signature,
        reasonForRejection: rejectReason.reason,
      };
    }
  },
};
