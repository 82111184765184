import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

function HelperText({ children }) {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.container}>
      {children}
    </Typography>
  );
}

export default HelperText;
