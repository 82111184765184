import { WorkflowDefinition, WorkflowTransition } from '../types';

import ApproveOrReject from './ApproveOrReject';
import AssociatedChange from './AssociatedChange';
import CreateNewVersion from './CreateNewVersion';
import OwnerApproval from './OwnerApproval';
import OwnerApprovalWithoutSig from './OwnerApprovalWithoutSig';
import ReasonForChange from './ReasonForChange';
import ReleaseOrReject from './ReleaseOrReject';
import ResolveRejection from './ResolveRejection';
import SummaryOfChange from './SummaryOfChange';
import UnderReview from './UnderReview';
import VoidSignature from './VoidSignature';

const SetGenericWorkflowSteps: WorkflowDefinition = {
  voidSignature: VoidSignature as WorkflowTransition,
  createNewVersion: CreateNewVersion as WorkflowTransition,
  reasonForChange: ReasonForChange as WorkflowTransition,
  summaryOfChange: SummaryOfChange as WorkflowTransition,
  ownerApprovalWithoutSig: OwnerApprovalWithoutSig as WorkflowTransition,
  associatedChange: AssociatedChange as WorkflowTransition,
  underReview: UnderReview as WorkflowTransition,
  ownerApproval: OwnerApproval as WorkflowTransition,
  approveOrReject: ApproveOrReject as WorkflowTransition,
  releaseOrReject: ReleaseOrReject as WorkflowTransition,
  resolveRejection: ResolveRejection as WorkflowTransition,
};

export default SetGenericWorkflowSteps;
