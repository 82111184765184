import React from 'react';
import { Trans } from '@lingui/macro';

import { RecordWorkflowStatuses } from 'workflows/statuses';
import { RejectReason, YesOrNo } from '../views';

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => {
        let prompt = <Trans>Are you sure you want to cancel? This action cannot be undone.</Trans>;

        if (props.currentVersion.changeOrderVersions.length) {
          const affectedCOs = props.currentVersion.changeOrderVersions.map((co) => co.item.customIdentifier).join(', ');
          prompt = (
            <>
              {prompt}{' '}
              <Trans>
                Cancelling the item shall remove it from the Linked Items section of the following Change Orders:
              </Trans>{' '}
              {affectedCOs};
            </>
          );
        }

        return <YesOrNo heading={'Cancel'} prompt={prompt} closeOnNo yesLabel="Ok" noLabel="No" {...props} />;
      },
      getNextStep: (cancel) => (cancel ? 'cancelReason' : undefined),
    },
    cancelReason: {
      component: (props) => {
        const heading = `As the ${
          props.currentVersion.assignedOwnerJobRole.formattedRoleName
        }, I confirm I want to Cancel ${props.itemCustomIdentifier}.`;
        return <RejectReason heading={heading} title={'Cancel'} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: (props) => {
    return !props.confirmTransition
      ? null
      : {
          ...props.cancelReason.signature,
          reasonForTransition: props.cancelReason.reason,
          toStatus: RecordWorkflowStatuses.Cancel.id,
        };
  },
};
