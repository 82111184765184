import React from 'react';
import startCase from 'lodash/startCase';

import { TableCell, TableBody, TableHead, TableRow, Table } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import MakeLink from 'components/MakeLink';
import Panel from 'components/core/Panel';
import StatusDot from 'components/StatusDot';
import VersionAndStatusDot from 'components/VersionAndStatusDot';
import { BooleanResultCell } from 'components/TableView/Fields/BooleanResult';

import AddRecord from '../AddRecord';
import RemoveRecord from '../RemoveRecord';
import { useStyles } from '../styles';

interface VerificationProtocolsProps {
  associatedVerificationRecords;
  currentVersion;
  verificationProtocols;
}

const VerificationProtocolsTable = (props: VerificationProtocolsProps) => {
  const { associatedVerificationRecords, currentVersion, verificationProtocols } = props;
  const classes = useStyles();

  const compareRecords = function(verificationProtocol) {
    const matchingRecords: any[] = [];
    verificationProtocol.verificationRecords.forEach((recFromProtocol) => {
      associatedVerificationRecords.forEach((associatedRecord) => {
        if (recFromProtocol.id === associatedRecord.id) {
          matchingRecords.push(recFromProtocol);
        }
      });
    });

    return matchingRecords;
  };

  return (
    <Panel>
      <Panel.Heading icon={AccountCircle}>Verification Protocols from V&V Plan</Panel.Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>ID</TableCell>
            <TableCell className={classes.tableHeader}>Current Title</TableCell>
            <TableCell className={classes.tableHeader}>
              Current
              <br />
              Version
            </TableCell>
            <TableCell align="center" className={classes.tableHeader}>
              {''}
            </TableCell>
            <TableCell className={classes.tableHeader}>
              Associated
              <br />
              Record
            </TableCell>
            <TableCell className={classes.tableHeader}>
              Origination
              <br />
              Protocol Version #
            </TableCell>
            <TableCell className={classes.tableHeader}>Record Status</TableCell>
            <TableCell className={classes.tableHeader}>Test Result</TableCell>
            <TableCell className={`${classes.tableHeader} ${classes.noWrap}`}>Deviations</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {verificationProtocols.map((verProtocol) => {
            return (
              <>
                <TableRow key={`${Math.random()}-${verProtocol.id}`} id={verProtocol.id} className={classes.tableRow}>
                  <TableCell scope="row" className={classes.tableCell} rowSpan={compareRecords(verProtocol).length + 1}>
                    <MakeLink item={verProtocol.item} newTab>
                      {verProtocol.item.customIdentifier}
                    </MakeLink>
                  </TableCell>
                  <TableCell
                    scope="row"
                    size="medium"
                    className={classes.tableCell}
                    rowSpan={compareRecords(verProtocol).length + 1}
                  >
                    {verProtocol.item.currentVersion.title}
                  </TableCell>
                  <TableCell
                    scope="row"
                    size="small"
                    className={classes.tableCell}
                    rowSpan={compareRecords(verProtocol).length + 1}
                  >
                    <VersionAndStatusDot
                      style={{ fontSize: 13 }}
                      version={verProtocol.item.currentVersion.versionIdentifier}
                      status={verProtocol.item.currentVersion.currentStatus.name}
                    />
                  </TableCell>
                  <TableCell
                    scope="row"
                    align="center"
                    size="small"
                    className={classes.tableCell}
                    rowSpan={compareRecords(verProtocol).length + 1}
                  >
                    <AddRecord
                      currentVersion={currentVersion}
                      itemType="verification_record"
                      protocol={verProtocol}
                      records={verProtocol.verificationRecords}
                    />
                  </TableCell>
                </TableRow>
                {associatedVerificationRecords.map((verRecord) => {
                  if (verRecord.deviceVerificationVersion.id !== verProtocol.item.currentVersion.id) return null;
                  return (
                    <>
                      <TableRow className={classes.tableRow} key={`${Math.random()}-${verRecord.id}`}>
                        <TableCell className={`${classes.tableCell} ${classes.recordCell}`}>
                          <MakeLink item={verRecord.item} newTab>
                            {verRecord.item.customIdentifier}
                          </MakeLink>
                        </TableCell>
                        <TableCell size="small" className={classes.tableCell}>
                          {verRecord.versionIdentifier}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <span>
                            <StatusDot
                              status={verRecord.currentStatus.name}
                              style={{ marginRight: 10, display: 'inline-block' }}
                            />
                            {startCase(verRecord.currentStatus.name)}
                          </span>
                        </TableCell>
                        <TableCell size="small" className={classes.tableCell}>
                          <BooleanResultCell data={verRecord.isFulfillAllAcceptanceCriteria} />
                        </TableCell>
                        <TableCell size="small" className={classes.tableCell}>
                          {verRecord.deviationVersions.length
                            ? verRecord.deviationVersions.map((deviation) => deviation.item.customIdentifier).join(', ')
                            : 'n/a'}
                        </TableCell>
                        <TableCell size="small" className={classes.tableCell}>
                          <RemoveRecord
                            record={verRecord}
                            itemType="verification_record"
                            currentVersion={currentVersion}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </>
            );
          })}
        </TableBody>
      </Table>
    </Panel>
  );
};

export { VerificationProtocolsTable };
