import React from 'react';
import get from 'lodash/get';

import { Button, Typography } from '@material-ui/core';
import ReportProblem from '@material-ui/icons/Warning';

import { Spacer } from 'components';
import BaseDialog from 'components/BaseDialog';

interface Props {
  open: boolean;
  onClose: () => void;
  jobRole: any;
}

function CannotRemoveRole(props: Props) {
  const { open, jobRole, onClose } = props;

  const userListWithoutSystemUser = () => jobRole.usersWithRole.filter((user) => user.id !== '1');

  return (
    <BaseDialog
      open={open}
      title="Cannot Remove Role From User"
      Icon={ReportProblem}
      size="large"
      onClose={onClose}
      textAlign="left"
      actions={
        <Button onClick={onClose} color="primary" variant="contained" fullWidth>
          Confirm
        </Button>
      }
    >
      <Typography variant="body2">
        <div>
          <span style={{ fontWeight: 600 }}>{get(userListWithoutSystemUser[0], 'fullName')}</span> cannot have their
          role as <span style={{ fontWeight: 600 }}>{jobRole.formattedRoleName}</span> revoked because there are no
          other users with this role who can have signature actions reassigned to them.
        </div>
        <Spacer factor={2} />
        Please add another user to this role before attempting to remove it from this user.
      </Typography>
    </BaseDialog>
  );
}

export default CannotRemoveRole;
