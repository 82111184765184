import gql from 'graphql-tag';

export const UPDATE_PERMISSION_GRANT = gql`
  mutation updatePermissionGrant(
    $userId: ID!
    $versionId: ID!
    $permissionType: String!
    $jobRoleId: ID!
    $reasonForPermissionChange: String!
    $username: String!
    $password: String!
    $userPermissionType: String!
  ) {
    updatePermissionGrant(
      userId: $userId
      versionId: $versionId
      permissionType: $permissionType
      jobRoleId: $jobRoleId
      reasonForPermissionChange: $reasonForPermissionChange
      username: $username
      password: $password
      userPermissionType: $userPermissionType
    ) {
      permissionGrants {
        id
        user {
          id
          fullName
        }
      }
    }
  }
`;
