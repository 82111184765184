import React from 'react';
import { MenuList, Paper, Popper, ClickAwayListener } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import { EllipsesMenuButton } from 'components';

import CancelMenuItem from './CancelMenuItem';
import RetireMenuItem from './RetireMenuItem';
import VoidMenuItem from './VoidMenuItem';
import BlockMenuItem from './BlockMenuItem';
import WontDoMenuItem from './WontDoMenuItem';
import ArchiveMenuItem from './ArchiveMenuItem';

const styles = (theme) => ({
  disabled: {
    color: 'rgba(0, 0, 0, 0.33)',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  ellipsesContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    right: 0,
    top: theme.spacing(1),
  },
  hoverPointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuContainer: {
    padding: 0,
  },
});

class WorkflowMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { popper: null };
  }

  handleToggleMenu = (e) => {
    if (this.state.popper) this.setState({ popper: null });
    else this.setState({ popper: e.currentTarget });
  };

  handleClickAway = (e) => {
    this.setState({ popper: null });
  };

  render() {
    const { classes } = this.props;

    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div className={classes.ellipsesContainer}>
          <EllipsesMenuButton
            className={this.props.disabled ? classes.disabled : classes.hoverPointer}
            onClick={this.handleToggleMenu}
            disabled={this.props.disabled}
          />
          <Popper anchorEl={this.state.popper} open={this.state.popper !== null} placement="bottom-end">
            <Paper>
              <MenuList className={classes.menuContainer}>{this.props.children}</MenuList>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    );
  }
}

export { CancelMenuItem, RetireMenuItem, VoidMenuItem, BlockMenuItem, WontDoMenuItem, ArchiveMenuItem };

export default withStyles(styles)(WorkflowMenu);
