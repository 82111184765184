import React from 'react';

import { Modal, Paper, createStyles, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { YesOrNo } from 'components';

const styles = createStyles((theme) => ({
  closeIcon: {
    float: 'right',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modalContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 322,
    padding: theme.spacing(2),
  },
}));

interface RemoveFromSetModalProps {
  classes: any;
  open: any;
  onClose: any;
  remove: () => void;
  heading: string;
  prompt: string;
}

function RemoveFromSetModal({ classes, open, onClose, remove, heading, prompt }: RemoveFromSetModalProps) {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper classes={{ root: classes.modalContainer }}>
        <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={onClose} color="action" />
        <YesOrNo
          heading={heading}
          prompt={prompt}
          yesLabel="Remove"
          noLabel="Dismiss"
          closeOnNo
          handleComplete={remove}
          handleClose={onClose}
          disableOnSubmit
        />
      </Paper>
    </Modal>
  );
}

export default withStyles(styles)(RemoveFromSetModal);
