import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    categoryTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    categoryTitleContent: {
      display: 'flex',
      alignItems: 'center',
    },
    progress: {
      marginTop: theme.spacing(1.25),
    },
    taskContainer: {
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
    expandContainer: {
      float: 'right',
    },
  }),
);
