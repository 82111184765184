import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    section: {
      padding: theme.spacing(3),
      filter: 'drop-shadow(0px 1px 3px rgba(126, 161, 159, 0.28))',
    },
    sectionTitleArea: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    sectionTitle: {
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    icon: {
      color: theme.palette.secondary.main,
    },
  }),
);
