import gql from 'graphql-tag';

function buildParamsClause(idParam) {
  if (idParam) {
    return { argDef: '($itemId: ID!)', args: '(id: $itemId)' };
  } else {
    return { argDef: '', args: '' };
  }
}

function buildFullItemRecordQuery({ itemType, versionFields, userCreatable = true }) {
  const { argDef } = buildParamsClause(userCreatable);
  return gql`
    query ${itemType}Query${argDef} {
      ${buildRecordQuery({ itemType, versionFields, userCreatable })}
    }
  `;
}

// versionFields should just be a string instead of a structured list
function buildRecordQuery({ itemType, versionFields, userCreatable = true }) {
  const { args } = buildParamsClause(userCreatable);
  const commonFields = `
    id
    locked
    title
    versionIdentifier
    currentStatusName
  `;
  const versionGQL = `${commonFields}\n${versionFields}`;
  return `
    ${itemType}${args} {
      id
      customIdentifier
      createdAt
      itemNumber
      currentUser {
        id
        username
        readOnly
      }
      owners {
        id
        fullName
        title
      }
      approvers {
        id
        fullName
        title
      }
      referenceId
      tags
      workingVersion {
        id
      }
      currentVersion {
        id
        owner {
          id
          fullName
          title
        }
        approver {
          id
          fullName
          title
        }
        createdAt
        creator {
          id
          fullName
          title
        }
        currentStatus {
          id
          name
        }
        permissibleApprovers {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        permissibleOwners {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        signingOwner {
          id
          firstName
          lastName
        }
        signingApprover {
          id
          firstName
          lastName
        }
        assignedOwnerJobRole {
          id
          formattedRoleName
        }
        assignedApproverJobRole {
          id
          formattedRoleName
        }
        item {
          id
          itemType {
            id
            name
            workflow {
              id
              name
            }
          }
        }
        ${versionGQL}
      }
      currentRelease {
        ${versionGQL}
      }
      versions {
        ${versionGQL}
        releasedAt
      }
    }
  `;
}

export { buildParamsClause, buildRecordQuery, buildFullItemRecordQuery };
