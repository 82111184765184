import gql from 'graphql-tag';

export const USER_MULTI_SELECT_QUERY = gql`
  query UserMultiSelect {
    users {
      id
      fullName
      firstName
      lastName
      jobRoles {
        id
        formattedRoleName
      }
      photoUrl
      status
    }
  }
`;
