import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      color: '#1f1f1f',
      fontWeight: 100,
      fontSize: '32px',
      lineHeight: '40px',
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    headerTitle: {
      width: '100%',
      lineHeight: '32px',
      fontWeight: 400,
      fontStyle: 'normal',
      color: theme.palette.secondary.main,
    },
    headerSubTitle: {
      color: theme.palette.secondary.main,
    },
    sectionLabelContainer: {
      color: theme.palette.secondary.main,
      marginBottom: theme.spacing(4),

      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
    icon: {
      color: '#4b49a2',
      marginRight: '20px',
      verticalAlign: 'bottom',
    },
    riskManagementIcon: {
      color: '#FF6A61',
      marginRight: theme.spacing(2),
      verticalAlign: 'bottom',
    },
  }),
);
