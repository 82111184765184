import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    cardTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10
    },
    checklistIcon: {
      position: "relative",
      top: 4,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    toggleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(0.5),
    },
    toggleText: {
      margin: `0 ${theme.spacing(1.5)}px 0`,
    },
    negativeLabel: {
      color: '#F65955',
      textTransform: 'uppercase'
    },
    positiveLabel: {
      color: '#0B915C',
      textTransform: 'uppercase'
    },
  }),
);
