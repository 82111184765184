import gql from 'graphql-tag';
import userFields from 'utils/gql/userFields';

const genericWorkflowBody = `
  reasonForChange
  descriptionOfChange
  linkedChangeId
  linkedChangeCustomIdentifier
  linkedChangeJustificationText
  linkedChangeTitle
`;

const setGenericWorkflowBody = `
  descriptionOfChange
  linkedChangeCustomIdentifier
  linkedChangeId
  linkedChangeJustificationText
  linkedChangeTitle
  mayTransitionToDraft
  mayTransitionToUnderReview
  mayTransitionToOwnerApproval
  mayTransitionToApprovedDraft
  mayTransitionToReleased
  mayTransitionToRejected
  mayTransitionToObsolete
  mayTransitionToVoid
  reasonForChange
`;

const setItemMemberWorkflowBody = `
  reasonForChange
`;

const genericWithMembersWorkflowBody = `
  descriptionOfChange
  linkedChangeCustomIdentifier
  linkedChangeId
  linkedChangeJustificationText
  linkedChangeTitle
  linkedChange {
    id
    itemType {
      id
      name
    }
  }
  mayTransitionToCanceled
  mayTransitionToDraft
  mayTransitionToUnderReview
  mayTransitionToOwnerApproval
  mayTransitionToApprovedDraft
  mayTransitionToReleased
  mayTransitionToRejected
  mayTransitionToRetired
  mayTransitionToRetirementInitiated
  mayTransitionToObsolete
  mayTransitionToVoid
  reasonForChange
`;

const recordWorkflowBody = `
  currentStatusName
  versionIdentifier
  formCustomIdentifier
  formId
  formTitle
  formVersion
  linkedForm {
    id
    item {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
    }
  }
`;

export const query = gql`
  query timelineQuery($itemId: ID!) {
    item(id: $itemId) {
      id
      customIdentifier
      createdAt
      workingVersion {
        id
      }
      currentVersion {
        id
        changeOrderVersions {
          id
          item {
            id
            customIdentifier
          }
        }
        item {
          id
          userCreatable
          versions {
            id
          }
        }
        unresolvedSuggestions
        title
        locked
        approver {
          id
          fullName
          title
          status
        }
        owner {
          id
          fullName
          title
          status
        }
        signingOwner {
          id
          fullName
          jobRoles {
            id
            formattedRoleName
          }
        }
        signingApprover {
          id
          fullName
          jobRoles {
            id
            formattedRoleName
          }
        }
        signingRejector {
          id
          fullName
          title
          jobRoles {
            id
            formattedRoleName
          }
        }
        assignedOwnerJobRole {
          id
          formattedRoleName
          usersWithRole {
            id
            fullName
          }
        }
        assignedApproverJobRole {
          id
          formattedRoleName
          usersWithRole {
            id
            fullName
          }
        }
        releasedAt
        retiredAt
        currentStatusName
        currentStatus {
          id
          name
          createdAt
          user {
            id
            fullName
            title
          }
          previousTransition {
            id
            esignature {
              id
              reasonForRejection
              reasonForSupplierRejection
            }
          }
        }
        statuses {
          id
          name
          createdAt
          user {
            id
            fullName
          }
        }
        versionIdentifier
        createdAt
        creator {
          id
          fullName
        }
        mayTransitionToRetired
        mayTransitionToCanceled
        ... on CybersecurityReportVersion {
          item {
            id
            userCreatable
            versions {
              id
            }
            statusHistory {
              id
              name
            }
          }
        }
        ... on VerificationAndValidationReportVersion {
          mayTransitionToOwnerApproval
        }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
        ... on SupplierAuditReportVersion {
          item {
            id
            userCreatable
            versions {
              id
            }
            statusHistory {
              id
              name
            }
          }
        }
        ... on SupplierAuditPlanVersion {
          item {
            id
            userCreatable
            versions {
              id
            }
            statusHistory {
              id
              name
            }
          }
        }
        ... on SupplierQuestionnaireRecordVersion {
          item {
            id
            userCreatable
            versions {
              id
            }
            statusHistory {
              id
              name
            }
          }
        }
        ... on SupplierQuestionnaireRecordVersion {
          item {
            id
            userCreatable
            versions {
              id
            }
            statusHistory {
              id
              name
            }
          }
        }
        ... on QualityAgreementVersion {
          item {
            id
            userCreatable
            versions {
              id
            }
            statusHistory {
              id
              name
            }
          }
        }
        ... on SupplierVersion {
          approver {
            id
            fullName
          }
          owner {
            id
            fullName
          }
          approvalExpiresAt
          supplierQuestionnaireRecordVersions {
            id
            title
            versionIdentifier
            item {
              id
              customIdentifier
            }
            currentStatus {
              id
              name
            }
          }
          supplierAuditReportVersions {
            id
            title
            versionIdentifier
            item {
              id
              customIdentifier
            }
            currentStatus {
              id
              name
            }
          } 
          riskLevel
          trioReferencedItems {
            id
            customIdentifier
            workingVersion {
              id
              title
              versionIdentifier
              currentStatus {
                id
                name
              }
            }
            currentVersion {
              id
              title
              versionIdentifier
              currentStatus {
                id
                name
              }
            }
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
          trioReferencedVersions {
            id
            versionIdentifier
            title
            currentStatus {
                id
                name
            }
            item {
              currentRelease {
                id
                versionIdentifier
                title
                currentStatus {
                  name
                  id
                }
              }
              workingVersion {
                id
                title
                versionIdentifier
                currentStatus {
                  name
                  id
                }
              }
              id
              customIdentifier
              itemType {
                id
                displaySlug
                userCreatable
              }
            }
          }
          trioReferencedAttachments {
            id
            versionId
            description
            url
          }
          reasonForSupplierRejection
          reasonForRevocation
          item {
            id
            userCreatable
            versions {
              id
            }
            statusHistory {
              id
              name
            }
          }
          revokedReferencedItems {
            id
            customIdentifier
            workingVersion {
              id
              title
              versionIdentifier
              currentStatus {
                id
                name
              }
            }
            currentVersion {
              id
              title
              versionIdentifier
              currentStatus {
                id
                name
              }
            }
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
          revokedReferencedVersions {
            id
            versionIdentifier
            title
            currentStatus {
              id
              name
            }
            item {
              currentRelease {
                id
                versionIdentifier
                title
                currentStatus {
                  name
                  id
                }
              }
              workingVersion {
                id
                title
                versionIdentifier
                currentStatus {
                  name
                  id
                }
              }
              id
              customIdentifier
              itemType {
                id
                displaySlug
                userCreatable
              }
            }
          }
          revokedReferencedAttachments {
            id
            versionId
            description
            url
          }
        }
        ... on SupplierQuestionnaireRecordVersion {
          formCustomIdentifier
          formId
          formTitle
          formVersion
          linkedForm {
            id
            item {
              id
              itemType {
                id
                displaySlug
                userCreatable
              }
            }
          }
        }
        ... on IncomingQualityInspectionVersion {
          descriptionOfChange
          reasonForChange
          tag
        }
        ... on FinalDeviceInspectionVersion {
          descriptionOfChange
          reasonForChange
          tag
        }
        ... on InProcessInspectionVersion {
          descriptionOfChange
          reasonForChange
          tag
        }
        ... on FormVersion {
          descriptionOfChange
          reasonForChange
        }
        ... on CourseVersion {
          descriptionOfChange
          mayTransitionToVoid
          reasonForChange
          mayTransitionToRetirementInitiated
          linkedChangeCustomIdentifier
          linkedChangeId
          linkedChangeJustificationText
          linkedChangeTitle
        }
        ... on TraceMatrixVersion {
          descriptionOfChange
          mayTransitionToOwnerApproval
          mayTransitionToUnderReview
          mayTransitionToApprovedDraft
          mayTransitionToReleased
          linkedChangeId
          linkedChangeCustomIdentifier
          linkedChangeJustificationText
          linkedChangeTitle
          reasonForChange
        }
        ... on TrainingRecordVersion {
            recordOrigin
        }
        ... on ChangeOrderVersion {
          impactedEmployees {
            id
          }
          assignedOwnerJobRole {
            id
            formattedRoleName
          }
          assignedApproverJobRole {
            id
            formattedRoleName
          }
          impactedEmployeesTrainingProgress
          hasImpactedEmployees
          mayTransitionToTraining
          trainingImpactAssessments {
              id
              impactedVersion {
                  id
                  item {
                      id
                      customIdentifier
                      currentVersion {
                          id
                          versionIdentifier
                          currentStatus {
                              id
                              name
                          }
                      }
                  }
                  currentStatus {
                      id
                      name
                      titleizedName
                  }
                  item {
                      id
                      itemType {
                          id
                          name
                          displaySlug
                      }
                  }
              }
              trainingImpactAssociatedCourses {
                id
                courseVersion {
                  id
                  versionIdentifier
                  item {
                      id
                      customIdentifier
                  }
                  currentStatus {
                      id
                      name
                      titleizedName
                  }
                }
              }
              trainingImpactMasterEntries {
                  id
                  category
                  trainingImpactAssociatedCourse {
                    id
                  }
                  trainingItem {
                    id
                  }
              }
          }
          mayNotTransitionToTrainingBecause {
            message
            data {
              key
              value
            }
          }
          supportingItems {
            id
          }
          item {
            id
            ... on ChangeOrder {
              impactedItems {
                id
              }
            }
          }
        }
        ... on GenericWorkflowInterface {
          ${genericWorkflowBody}
        }
        ... on SetGenericWorkflowInterface {
          ${setGenericWorkflowBody}
        }
        ... on SetMemberWorkflowInterface {
          ${setItemMemberWorkflowBody}
        }
        ... on GenericWithMembersWorkflowInterface {
          ${genericWithMembersWorkflowBody}
        }
        ... on TrainingRecordVersion {
          employee {
            id
            user {
              id
            }
          }
          source {
            id
            item {
              id
              customIdentifier
            }
            title
            ... on CourseVersion {
              effectivenessMeasurementRequired
              attachment {
                id
              }
            }
          }
        }
        ... on FormRecordVersion {
          ${recordWorkflowBody}
        }
        ... on IncomingQualityInspectionRecordVersion {
          ${recordWorkflowBody}
        }
        ... on InProcessInspectionRecordVersion {
          ${recordWorkflowBody}
        }
        ... on FinalDeviceInspectionRecordVersion {
          ${recordWorkflowBody}
        }
        ... on VerificationRecordVersion {
          ${recordWorkflowBody}
        }
        ... on ValidationRecordVersion {
          ${recordWorkflowBody}
        }
        ... on UserNeedVersion {
          setMember {
            id
            setItemVersion {
              id
              currentStatusName
              versionIdentifier
              title
              item {
                id
                customIdentifier
                userCreatable
              }
            }
          }
        }
        ... on RequirementVersion {
          setMember {
            id
            setItemVersion {
              id
              currentStatusName
              versionIdentifier
              title
              item {
                id
                customIdentifier
                userCreatable
              }
            }
          }
        }
        ... on RiskVersion {
          setMember {
            id
            setItemVersion {
              id
              currentStatusName
              title
              versionIdentifier
              item {
                id
                customIdentifier
                userCreatable
              }
            }
          }
        }
        ... on RiskControlVersion {
          setMember {
            id
            setItemVersion {
              id
              currentStatusName
              title
              versionIdentifier
              item {
                id
                customIdentifier
                userCreatable
              }
            }
          }
        }
        ... on DeviationVersion {
          formCustomIdentifier
          formId
          formTitle
          formVersion
          linkedForm {
            id
            item {
              id
              itemType {
                id
                displaySlug
                userCreatable
              }
            }
          }
        }
      }
      versions {
        id
        versionIdentifier
        releasedAt
        retiredAt
        currentStatusName
        statuses {
          id
          name
        }
        ... on GenericWorkflowInterface {
          ${genericWorkflowBody}
        }
        ... on SetGenericWorkflowInterface {
          ${setGenericWorkflowBody}
        }
        ... on SetMemberWorkflowInterface {
          ${setItemMemberWorkflowBody}
        }
        ... on GenericWithMembersWorkflowInterface {
          ${genericWithMembersWorkflowBody}
        }
        ... on TrainingRecordVersion {
          currentStatusName
          employee {
            id
            user {
              id
            }
          }
          source {
            id
            title
            ... on CourseVersion {
              attachment {
                id
              }
            }
          }
        }
      }
    }
    currentUser {
      ...userFields
    }
  }
  ${userFields}
`;

export const transitionMutation = gql`
  mutation CreateTransition($input: CreateTransitionInput!) {
    createTransition(input: $input) {
      version {
        id
      }
      error
    }
  }
`;

export const updateMutation = gql`
  mutation UpdateTimelineData($input: UpdateChangeFieldsInput!) {
    updateChangeFields(input: $input) {
      version {
        id
      }
    }
  }
`;

export const createVersion = gql`
  mutation NewRev($input: CreateVersionInput!) {
    createVersion(input: $input) {
      version {
        id
      }
    }
  }
`;
