import { WorkflowDefinition, WorkflowTransition } from '../types';

import RecordOfOrigin from './RecordOfOrigin';
import ReadyForReview from './ReadyForReview';
import OwnerApproval from './OwnerApproval';
import ReleaseOrReject from './ReleaseOrReject';
import Archived from './Archived';

const genericRecordWorkflowSteps: WorkflowDefinition = {
  recordOfOrigin: RecordOfOrigin as WorkflowTransition,
  readyForReview: ReadyForReview as WorkflowTransition,
  ownerApproval: OwnerApproval as WorkflowTransition,
  releaseOrReject: ReleaseOrReject as WorkflowTransition,
  archived: Archived as WorkflowTransition,
};

export default genericRecordWorkflowSteps;
