import React from 'react';

import { Layout } from 'components';

// core components
import { ResetPasswordForm } from 'compositions';

function ResetPassword(props) {
  return (
    <Layout variant="unauthorized">
      <ResetPasswordForm heading={'Reset Your Password'} />
    </Layout>
  );
}

export default ResetPassword;
