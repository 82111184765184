import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      minHeight: theme.spacing(100),

      [theme.breakpoints.up('xl')]: {
        minHeight: theme.spacing(102.5),
      },
    },
    commentsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    timestamp: {
      alignSelf: 'center',
    },
    centerChildren: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
    },
  }),
);
