import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const GET_USER_GROUP_SPECIFICATION_VERSION = gql`
  query UserGroupSpecificationVersionQuery($id: ID!) {
    userGroupSpecificationVersion(id: $id) {
      id
      title
      displayAsApplicationVersion
      item {
        id
        itemNumber
        customIdentifier
      }
      attachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      rawReferenceAttachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      body
      inputMethod
      locked
      displayAsApplicationVersion
      userGroupVersions {
        id
        item {
          id
          customIdentifier
          tags
          itemNumber
          itemType {
            id
            name
          }
          comments {
            id
          }
        }
        locked
        title
        description
        member {
          id
          position
        }
      }
    }
  }
`;

const CREATE_USER_GROUP = gql`
  mutation AddUgpToUserGroupSpecificationVersion($userGroupSpecificationId: ID!, $title: String!) {
    addUgpToUserGroupSpecificationVersion(userGroupSpecificationId: $userGroupSpecificationId, title: $title) {
      item {
        id
      }
    }
  }
`;

const REMOVE_MEMBERS = gql`
  mutation removeUserGroups($ids: [ID!]!) {
    removeMembers(ids: $ids) {
      members {
        id
      }
    }
  }
`;

function useVersion(id: string) {
  return useQuery(GET_USER_GROUP_SPECIFICATION_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

export { useVersion, REMOVE_MEMBERS, CREATE_USER_GROUP, GET_USER_GROUP_SPECIFICATION_VERSION };
