import React, { createContext, FC, ReactNode, useState } from 'react';

import { NAVIGATION_ITEMS } from 'compositions/Sidebar/NavigationConfig';
import { NavigationItemConfig } from 'compositions/Sidebar/NavigationConfig/navigationItems';

const DEFAULT_SELECTED = NAVIGATION_ITEMS[0];

export type NavigationContextType = {
  selectedNavigationItem: NavigationItemConfig;
  setNavigationItem: (disciplineName: string) => void;
};

export const NavigationContext = createContext<NavigationContextType>({
  selectedNavigationItem: DEFAULT_SELECTED,
  setNavigationItem: () => {},
});

export const NavigationProvider: FC<ReactNode> = ({ children }) => {
  const [selected, setSelected] = useState<NavigationItemConfig>(DEFAULT_SELECTED);
  const setSelectedByName = (name: string): void => {
    setSelected(NAVIGATION_ITEMS.find((d) => d.name === name)!);
  };

  return (
    <NavigationContext.Provider value={{ selectedNavigationItem: selected, setNavigationItem: setSelectedByName }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const withNavigation = (Component) => (props) => (
  <NavigationProvider>
    <Component {...props} />
  </NavigationProvider>
);
