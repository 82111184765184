import React from 'react';

import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FlagIcon from '@material-ui/icons/Flag';

import { Spacer } from 'components';

import { VerificationAndValidationReportVersionQuery_verificationAndValidationReportVersion } from '../../__generated__/VerificationAndValidationReportVersionQuery';
import { ValidationProtocolsTable } from './ValidationProtocolsTable';
import { VerificationProtocolsTable } from './VerificationProtocolsTable';
import { VVPlan } from '../../PayloadFields';

interface TestingSummaryTabProps {
  currentVersion: VerificationAndValidationReportVersionQuery_verificationAndValidationReportVersion;
  vvPlan: VVPlan;
}

const TestingSummaryTab = (props: TestingSummaryTabProps) => {
  const { currentVersion, vvPlan } = props;

  return (
    <>
      <Typography variant="h3">
        <AccountCircleIcon />
        Associated Test Overview
      </Typography>
      {!vvPlan.id ? (
        <>
          <Spacer factor={2} />
          <Typography variant={'body2'}>
            <FlagIcon fontSize={'small'} />
            No V&V Plan selected. Select a V&V Plan to view results on this page.
          </Typography>
        </>
      ) : (
        <>
          <Spacer factor={4} />
          <ValidationProtocolsTable
            associatedValidationRecords={currentVersion.validationRecords}
            currentVersion={currentVersion}
            validationProtocols={vvPlan.validationProtocols}
          />
          <Spacer factor={4} />
          <VerificationProtocolsTable
            associatedVerificationRecords={currentVersion.verificationRecords}
            currentVersion={currentVersion}
            verificationProtocols={vvPlan.verificationProtocols}
          />
        </>
      )}
    </>
  );
};

export { TestingSummaryTab };
