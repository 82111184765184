import React from 'react';

import { Link, Paper, Typography } from '@material-ui/core';
import { LiveHelp } from '@material-ui/icons';

import { LabelText } from 'components';
import { useStyles } from './styles';

function SupportWidget() {
  const classes = useStyles();

  const supportUrl = 'https://www.support.nemedio.com/';
  const supportMailUrl = 'mailto:support@nemedio.com';

  return (
    <Paper component="div" elevation={1} className={classes.container}>
      <LabelText labelText="Support" />
      <div style={{ display: 'flex', marginTop: '10px' }}>
        <LiveHelp fontSize="small" />
        <div style={{ marginLeft: '5px' }}>
          <Typography variant="body2" style={{ fontWeight: 700, marginBottom: '2px' }}>
            Have a question?
          </Typography>
          <Typography variant="body2">
            Visit our
            <Link href={supportUrl} color="primary" underline="none" target="_blank" rel="noopener">
              {' '}
              support portal{' '}
            </Link>
            or use the chat feature on the bottom right of the screen.
          </Typography>
          <Typography variant="body2">
            You can also email us at
            <Link href={supportMailUrl} color="primary" underline="none">
              {' '}
              support@nemedio.com
            </Link>
            .
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

export default SupportWidget;
