import React, { FC } from 'react';

import { FormControl, LinearProgress, OutlinedInput, Typography } from '@material-ui/core';

import Spacer from '../Spacer';
import UserListItem from '../UserListItem';
import { useStyles } from './styles';
import { currentUser_currentUser } from 'compositions/__generated__/currentUser';

interface Props {
  currentUser: currentUser_currentUser;
  usernameInputId: string;
  passwordInputId: string;
  onUsernameChange: (e) => void;
  onPasswordChange: (e) => void;
}

const ESignature: FC<Props> = ({
  usernameInputId,
  currentUser,
  passwordInputId,
  onUsernameChange,
  onPasswordChange,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="subtitle1" style={{ textAlign: 'start' }}>
        Electronic Signature
      </Typography>
      <Spacer factor={1} />
      <LinearProgress className={classes.signatureBar} color="primary" variant="determinate" value={100} />
      <div style={{ marginLeft: 16, marginTop: 16, display: 'flex' }}>
        <span className={classes.currentUserSig}>
          <UserListItem userId={currentUser.id} name={currentUser.fullName} />
        </span>
        <span className={classes.esigContainer}>
          <FormControl required fullWidth style={{ marginRight: 8 }}>
            <OutlinedInput
              classes={{ root: classes.esigInput }}
              labelWidth={0}
              fullWidth
              id={usernameInputId}
              type="text"
              onChange={onUsernameChange}
              placeholder="Username"
            />
          </FormControl>
          <FormControl required fullWidth>
            <OutlinedInput
              classes={{ root: classes.esigInput }}
              labelWidth={0}
              fullWidth
              id={passwordInputId}
              type="password"
              onChange={onPasswordChange}
              placeholder="Password"
            />
          </FormControl>
        </span>
      </div>
    </>
  );
};
export default ESignature;
