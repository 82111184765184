import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { navigate } from '@reach/router';

import { getAuthToken, getGraphQLApiUrl } from 'utils';

import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from 'schema.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

/**
 * A custom Apollo link middleware...
 * https://www.apollographql.com/docs/react/advanced/network-layer.html#linkMiddleware
 * @type {ApolloLink}
 */
const authLink = new ApolloLink((operation, forward) => {
  // A valid auth token is required on _all_ GraphQL requests except the createAuthToken mutation.
  operation.setContext(({ headers }) => {
    const [authToken] = getAuthToken();
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${authToken}`,
      },
    };
  });

  return forward(operation);
});

/**
 * The Apollo HTTP link to the backend.
 * @type {ApolloLink}
 */
const httpLink = new HttpLink({
  credentials: 'omit',
  uri: getGraphQLApiUrl(),
});

const logoutLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) navigate('/login'); // eslint-disable-line @typescript-eslint/no-floating-promises
});

/**
 * Build the composed final `ApolloLink` for our client.
 * Note: `.from` is basically a compose function for links.
 * @type {ApolloLink}
 */
const link = ApolloLink.from([authLink, logoutLink, httpLink]);

export default new ApolloClient({
  cache: new InMemoryCache({ fragmentMatcher }),
  connectToDevTools: process.env.REACT_APP_ENABLE_DEV_TOOLS,
  link,
});
