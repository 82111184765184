import React from 'react';

import AttachFile from '@material-ui/icons/AttachFile';

import AttachmentsSection from 'components/AttachmentsSection';
import LabelText from 'components/LabelText';
import Spacer from 'components/Spacer';

export interface AttachmentsProps {
  locked: boolean;
  versionId: string;
  referencedAttachments: any[];
  handlers: {
    update: (
      attachmentId: string,
      attachment: {
        url?: string;
        description?: string;
      },
    ) => Promise<any>;
    destroy: (attachmentId: string) => Promise<any>;
    create: (versionId: string, attachment: string) => Promise<any>;
  };
}

const ReferenceAttachments = (props: AttachmentsProps) => {
  const { handlers, referencedAttachments = [] } = props;
  return (
    <div>
      <LabelText labelText="Reference Attachments" Icon={AttachFile} />
      <Spacer factor={2} />
      <AttachmentsSection
        title="Reference Attachments"
        versionId={props.versionId}
        attachments={referencedAttachments}
        key={referencedAttachments.length}
        locked={props.locked}
        updateAttachment={handlers.update}
        createAttachment={handlers.create}
        destroyAttachment={handlers.destroy}
        enablePreview={false}
        displayHeader={false}
        displayNoItemsPanel={true}
      />
    </div>
  );
};

export default ReferenceAttachments;
