import gql from 'graphql-tag';

export const mutateQuestionnaireRecord = gql`
  mutation createQuestionnaireRecord($input: CreateQuestionnaireRecordInput!) {
    createQuestionnaireRecord(input: $input) {
      item {
        id
        customIdentifier
      }
    }
  }
`;

export const mutateRecord = gql`
  mutation createFormBasedSupplierQuestionnaireRecord(
    $versionId: ID!
    $productId: ID!
    $title: String!
    $host: String!
  ) {
    createFormBasedRecord(versionId: $versionId, productId: $productId, title: $title, host: $host) {
      item {
        id
        customIdentifier
      }
    }
  }
`;

export const effectiveViewQuery = gql`
  query SupplierQuestionnaireEffectiveViewQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      currentUser {
        id
        readOnly
      }
      currentRelease {
        id
        locked
        title
      }
    }
  }
`;
