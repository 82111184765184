import React, { useState, useContext } from 'react';

import { CircularProgress, FormControl, Typography } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import get from 'lodash/get';
import { useMutation } from 'react-apollo';

import {
  CREATE_TRAINING_MATERIAL_ATTACHMENT,
  DESTROY_TRAINING_MATERIAL_ATTACHMENT,
  UPDATE_TRAINING_MATERIAL_ATTACHMENT,
} from './gql';
import AttachmentControl from 'components/AttachmentsSection/AttachmentControl';
import Input from 'components/core/form/Input';
import FileUpload from 'components/FileUpload';
import { LockedContext } from 'contexts/lockedContext';

interface Props {
  attachment?: any;
  courseVersionId: string;
  onAdd?: () => void;
  locked: boolean;
}

function Row(props: Props) {
  const { locked } = useContext(LockedContext);
  const [create] = useMutation(CREATE_TRAINING_MATERIAL_ATTACHMENT, {
    refetchQueries: ['CourseVersionQuery'],
  });

  const [destroy] = useMutation(DESTROY_TRAINING_MATERIAL_ATTACHMENT, {
    refetchQueries: ['CourseVersionQuery'],
  });

  const [update] = useMutation(UPDATE_TRAINING_MATERIAL_ATTACHMENT, {
    refetchQueries: ['CourseVersionQuery'],
  });

  const [loading, setLoading] = useState(false);

  return (
    <tr>
      <td>
        <AttachmentControl
          versionId={props.courseVersionId}
          locked={locked}
          attachment={get(props.attachment, 'file')}
          singleAttachment
          downloadable
          createAttachment={(versionId: string, attachmentURL: string) =>
            create({
              variables: { versionId, attachmentURL },
            }).then(() => {
              if (props.onAdd) {
                props.onAdd();
              }
            })
          }
          destroyAttachment={() =>
            destroy({
              variables: {
                id: props.attachment && props.attachment.id,
              },
            })
          }
        />
      </td>
      <td>
        <FormControl fullWidth>
          <Input
            defaultValue={props.attachment && props.attachment.description}
            debounced
            placeholder="Please add description"
            disabled={locked}
            onChange={(e) =>
              update({
                variables: {
                  id: props.attachment && props.attachment.id,
                  description: e.target.value,
                },
              })
            }
          />
        </FormControl>
      </td>
      <td>
        {get(props.attachment, 'editableDocument') ? (
          <AttachmentControl
            versionId={props.courseVersionId}
            locked={locked}
            attachment={get(props.attachment, 'editableDocument')}
            singleAttachment
            downloadable
            Icon={AddCircle}
            destroyAttachment={() =>
              update({
                variables: {
                  id: props.attachment && props.attachment.id,
                  editableDocument: null,
                },
              })
            }
          />
        ) : (
          <span
            style={{
              width: '25%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              paddingLeft: 15,
            }}
          >
            <Typography variant="body2">{'n/a'}</Typography>
            {!loading && (
              <FileUpload
                disabled={props.locked || !props.attachment}
                Icon={AddCircle}
                onUploadStart={() => setLoading(true)}
                onUploadSuccess={(fileUrl: string) => {
                  const attachmentURL = decodeURIComponent(fileUrl);
                  update({
                    variables: {
                      id: props.attachment && props.attachment.id,
                      editableDocument: attachmentURL,
                    },
                  }).then(
                    () => setLoading(false),
                    (err) => {
                      throw new Error(
                        `Error creating attachment (versionId: ${props.courseVersionId}): ${err.message}`,
                      );
                    },
                  );
                }}
              />
            )}
            {loading && <CircularProgress size={24} />}
          </span>
        )}
      </td>
    </tr>
  );
}

export default Row;
