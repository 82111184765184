import gql from 'graphql-tag';

export const CREATE_DOCUMENT_ATTACHMENT = gql`
  mutation createDocAttachment($attachmentURL: String!, $versionId: ID!) {
    createDocumentAttachment(attachment: $attachmentURL, versionId: $versionId) {
      attachment {
        id
        url
        versionId
        creator {
          id
          fullName
        }
      }
    }
  }
`;

export const DESTROY_DOCUMENT_ATTACHMENT = gql`
  mutation DestroyDocAttachment($id: ID!) {
    destroyDocumentAttachment(id: $id) {
      attachment {
        id
      }
    }
  }
`;

export const CREATE_RAW_REF_ATTACHMENT = gql`
  mutation createRawReferAttachment($attachmentURL: String!, $versionId: ID!) {
    createRawReferenceAttachment(attachment: $attachmentURL, versionId: $versionId) {
      rawReferenceAttachment {
        id
        url
        versionId
        creator {
          id
          fullName
        }
      }
    }
  }
`;

export const DESTROY_RAW_REF_ATTACHMENT = gql`
  mutation destroyRawRefAttachment($id: ID!) {
    destroyRawReferenceAttachment(id: $id) {
      attachment {
        id
      }
    }
  }
`;
