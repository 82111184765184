import React, { ComponentType, useState } from 'react';

import { CircularProgress, SvgIconProps } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';

import FileUpload from 'components/FileUpload';
import fileNameFromURL from 'utils/fileNameFromURL';

import { useStyles } from './styles';

interface Props {
  accept?: Array<string> | undefined;
  versionId: string;
  attachment?: {
    url: string;
    id: string;
  } | null;
  classes;
  locked: boolean;
  singleAttachment: boolean;
  downloadable: boolean;
  Icon: ComponentType<SvgIconProps>;
  updateAttachment?: (id: string, url: string) => Promise<any>;
  createAttachment?: (versionId: string, url: string) => Promise<any>;
  destroyAttachment: () => Promise<any>;
}

function AttachmentControl(props: Props) {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.attachmentInput}>
      <span
        style={{
          alignSelf: 'center',
          color: props.attachment ? 'inherit' : 'rgba(0, 0, 0, 0.2)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.attachment && props.attachment.url ? fileNameFromURL(props.attachment.url) : 'Attach File'}
      </span>
      {!loading && !props.locked && (
        <FileUpload
          accept={props.accept}
          singleAttachment={props.singleAttachment}
          hasAttachment={Boolean(props.attachment)}
          Icon={props.Icon}
          onUploadStart={() => setLoading(true)}
          onUploadSuccess={(fileUrl: string) => {
            const attachmentURL = decodeURIComponent(fileUrl);
            if (props.attachment && !props.singleAttachment && props.updateAttachment) {
              props.updateAttachment(props.attachment.id, attachmentURL).then(
                () => setLoading(false),
                (err) => {
                  setLoading(false);
                  throw new Error(`Error updating attachment ${err.message}`);
                },
              );
            } else {
              props.createAttachment &&
                props.createAttachment(props.versionId, attachmentURL).then(
                  () => setLoading(false),
                  (err) => {
                    throw new Error(`Error creating attachment (versionId: ${props.versionId}): ${err.message}`);
                  },
                );
            }
          }}
        />
      )}
      {loading && <CircularProgress size={24} />}
      {props.attachment && (
        <div className={classes.actions}>
          {(props.locked || props.downloadable) && (
            <VerticalAlignBottom style={{ cursor: 'pointer' }} onClick={() => window.open(props.attachment!.url)} />
          )}
          {props.attachment.url && props.singleAttachment && !props.locked && (
            <Delete style={{ cursor: 'pointer' }} onClick={props.destroyAttachment} />
          )}
        </div>
      )}
    </div>
  );
}

AttachmentControl.defaultProps = {
  singleAttachment: false,
  downloadable: false,
} as Partial<Props>;

export default AttachmentControl;
