import React, { useState } from 'react';

export const SnackContext = React.createContext({
  snackMessage: '',
  snackVisible: false,
  showSnack: (_text) => {},
  hideSnack: () => {},
});

export function SnackContextProvider({ children }) {
  const [message, setMessage] = useState('');
  const [visible, setVisible] = useState(false);

  const showSnack = (text) => {
    setMessage(text);
    setVisible(true);
  };

  const hideSnack = () => {
    setVisible(false);
  };

  const value = {
    snackMessage: message,
    snackVisible: visible,
    showSnack,
    hideSnack,
  };

  return <SnackContext.Provider value={value}>{children}</SnackContext.Provider>;
}

export const withSnackContext = (Component) => (props) => (
  <SnackContextProvider>
    <Component {...props} />
  </SnackContextProvider>
);
