import React, { useState } from 'react';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { compose } from 'recompose';

import { Grid, Typography, withStyles } from '@material-ui/core';

import styles from './styles';
import Spacer from 'components/Spacer';
import { UserAvatar } from 'components';
import { icons } from 'components/WorkflowIcon';
import Button from 'components/Button';

const DraftIcon = icons.draft;

function ResolveRejection({ classes, itemCustomIdentifier, handleComplete, currentVersion, itemTypeMetadata }) {
  const rejectedBy = get(currentVersion, 'signingRejector');
  const rejectedAt = get(currentVersion, 'currentStatus.createdAt');
  const rejectedAtFormatted = `${DateTime.fromISO(rejectedAt).toFormat('MMMM d, yyyy')}.`;
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Spacer factor={1} />
      <div className={classes.heading}>
        <Typography variant="h3">Training Record Failure</Typography>
      </div>

      <Spacer factor={2} />

      <Typography variant="body1" className={classes.boldText}>
        {`${itemCustomIdentifier} version ${get(
          currentVersion,
          'versionIdentifier',
          '',
        )} was failed on ${rejectedAtFormatted}`}
      </Typography>
      <Spacer factor={2} />
      <Typography variant="body2">
        Your completed training record was marked as failed. Please review the Reason for Failure and redo your
        training.
      </Typography>

      <Spacer factor={3} />

      <Typography variant="overline">Reviewed By</Typography>
      <Spacer factor={2} />
      <Grid container>
        <Grid item xs={2} container direction="column" alignItems="flex-end">
          <div className={classes.padRightUnit}>
            <UserAvatar userId={rejectedBy.id} />
          </div>
        </Grid>
        <Grid item xs={10} style={{ marginTop: -4 }}>
          <Typography variant="body2">{rejectedBy.fullName}</Typography>
          <Typography variant="body2">{rejectedBy.title}</Typography>
        </Grid>
      </Grid>

      <Spacer factor={3} />

      <Typography variant="overline">Reason for Failure</Typography>
      <Spacer factor={1} />
      <Typography variant="body2" className={classes.textInput}>
        {get(currentVersion, 'currentStatus.previousTransition.esignature.reasonForRejection', '')}
      </Typography>

      <Spacer factor={4} />
      <Button
        color="primary"
        className={classes.buttonDraft}
        fullWidth
        size="medium"
        type="submit"
        loading={loading}
        variant="contained"
        onClick={() => {
          setLoading(true);
          handleComplete().then(() => setLoading(false));
        }}
      >
        <span className={classes.buttonIconText}>
          <DraftIcon className={classes.buttonIcon} />
          Retake Training
        </span>
        <span style={{ flex: 2 }} />
      </Button>
    </>
  );
}

export default compose(withStyles(styles))(ResolveRejection);
