import React from 'react';
import { LinearProgress, withStyles } from '@material-ui/core';

import { buildConditionalClasses } from 'utils';

const styles = (theme) => ({
  connectBar: {
    top: 27,
    height: 10,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  long: {
    width: 56,
  },
  short: {
    width: 22,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  disabled: {
    opacity: 0.5,
  },
});

function ConnectBar({ classes, color, short = false, disabled = false, theme, visible }) {
  if (!visible) return null;
  return (
    <LinearProgress
      variant="determinate"
      className={buildConditionalClasses([
        [true, classes.connectBar],
        [!short, classes.long],
        [short, classes.short],
        [disabled, classes.disabled],
      ])}
      style={{
        backgroundColor: theme.palette.common[color].main,
      }}
      classes={{ bar: classes.transparent }}
      value={100}
    />
  );
}

export default withStyles(styles, { withTheme: true })(ConnectBar);
