import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import Note from '@material-ui/icons/Note';

import slugs from '../../displaySlugs';

import PayloadFields from './PayloadFields';
import Views from 'workspaces/views';
import { DocumentToolbar } from 'itemTypes/mixins/Document';
import { originationVersion } from '../FormRecord';

const itemType = 'inProcessInspectionRecord';

const versionFields = `
  formCustomIdentifier
  formTitle
  formVersion
  formReleasedAt  
  notes
  unexecutedRecordAttachment {
    id
    url
    filename
    creator {
      id
      fullName
    }
    createdAt
  }
  executedRecordAttachment {
    id
    url
    filename
    creator {
      id
      fullName
    }
    createdAt
  }
  changeOrderVersions {
    id
    item {
      id
      customIdentifier
    }
  }
`;

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    headerName: 'Originates From',
    type: 'regular_text',
    field: 'currentVersion.formCustomIdentifier',
  },
  ...[originationVersion],
  {
    headerName: 'Execution Notes',
    type: 'regular_text',
    field: 'currentVersion.notes',
  },
  {
    field: 'createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
  {
    field: 'currentVersion.formCustomIdentifier',
    headerName: 'Associated Form',
    type: 'regular_text',
    hide: true,
  },
  {
    field: 'currentVersion.formVersion',
    headerName: 'Associated Form Version',
    type: 'version',
    hide: true,
    keyCreator: (row) => {
      return [row.value.currentVersion.formVersion, row.value.currentVersion.linkedForm.currentStatus.name].join('-');
    },
  },
  {
    field: 'currentVersion.releasedAt',
    headerName: 'Release Date',
    type: 'date',
    hide: true,
  },
];

const listViewQuery = gql`
  query inProcessInspectionRecordsQuery {
    inProcessInspectionRecords {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      tags
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        id
        title
        createdAt
        currentStatus {
          id
          name
        }
        formCustomIdentifier
        formVersion
        notes
        versionIdentifier
        signingOwner {
          id
          firstName
          lastName
        }
        signingApprover {
          id
          firstName
          lastName
        }
        formId
        formCustomIdentifier
        formVersion
        linkedForm {
          currentStatus {
            id
            name
          }
        }
      }
    }
  }
`;

const displayHistoryInEffectiveView = false;
export default {
  displaySlug: slugs[itemType],
  displayHistoryInEffectiveView,
  itemType,
  label: t`In Process Inspection Record`,
  categoryLabel: t`In Process Inspection Records`,
  icon: Note,
  PayloadFields,
  versionFields,
  listViewColumns,
  listViewQuery,
  EffectiveViewOptions: DocumentToolbar,
  recordWorkspace: [Views.Builder, Views.Effective],
  listWorkspace: [Views.Table, Views.LinksGraph],
};
