import get from 'lodash/get';

import slugs from '../displaySlugs';
import designHistoryFile from './designHistoryFile';
import qualityManagementSystem from './qualityManagementSystem';
import optionalCategories from './optionalCategories';

function buildCategories(includeOptionalCategories = false) {
  let CATEGORIES = {};
  let CATEGORY_INDEX = {};
  const appWindows = [designHistoryFile, qualityManagementSystem];

  if (includeOptionalCategories) {
    // @ts-ignore
    appWindows.push(optionalCategories);
  }

  appWindows.forEach((appWindow) => {
    CATEGORIES[appWindow.id] = appWindow;
    appWindow.categories.forEach((cat) => {
      if (cat.excludeFromCategoryIndex) return;

      CATEGORY_INDEX[cat.slug] = { ...cat, appWindow: appWindow.id };
      get(cat, 'children', []).forEach((child) => {
        CATEGORY_INDEX[child.slug] = {
          ...child,
          appWindow: appWindow.id,
          parentSlug: cat.slug,
        };
      });
    });
  });

  return { CATEGORIES, CATEGORY_INDEX };
}

function itemTypeCategoryHierarchy(itemTypeName, categoryIndex) {
  const slug = slugs[itemTypeName];
  const baseCategory = categoryIndex[slug];
  let hierarchy = [baseCategory];
  if (baseCategory.parentSlug) hierarchy.push(categoryIndex[baseCategory.parentSlug]);
  hierarchy.reverse();
  return hierarchy;
}

const { CATEGORIES, CATEGORY_INDEX } = buildCategories();
const { CATEGORIES: FULL_CATEGORIES, CATEGORY_INDEX: FULL_CATEGORIES_INDEX } = buildCategories(true);

export { CATEGORIES, CATEGORY_INDEX, FULL_CATEGORIES, FULL_CATEGORIES_INDEX, itemTypeCategoryHierarchy };
