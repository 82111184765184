import React, { useRef, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Grid } from '@material-ui/core';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import omit from 'lodash/omit';
import { Reorder, Description, InsertDriveFile, ThumbUp, Edit, Category } from '@material-ui/icons';

import { Loading, TableView } from 'components';
import OptionsDropdown from 'components/OptionsDropdown';
import DisciplineToggle from './DisciplineToggle';
import Draw from 'assets/icons/Draw';
import PlaylistAddCheckCircle from 'assets/icons/PlaylistAddCheckCircle';

import { ITEM_VERSION_QUERY } from './gql';
import { useStyles } from './styles';
import { ViewTypes, statusesForDraft, statusesForEffective, statusesForInProgress } from './types';

interface ItemVersionIndexProps {
  path: string;
}

const ItemVersionIndex: React.FC<ItemVersionIndexProps> = () => {
  const classes = useStyles();
  const [tableDisplayVariant, setTableDisplayVariant] = useState(ViewTypes.ITEM_VIEW);
  const [disciplineToggle, setDisciplineToggle] = useState<boolean | null>(null);
  const gridRef = useRef<any>();
  const [isCustomGrouping, setIsCustomGrouping] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const { loading, error } = useQuery(ITEM_VERSION_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      groupItems(data);
    },
  });
  if (loading) return <Loading />;
  if (error) throw new Error(`Error loading item version index: ${error.message}`);

  const groupItems = (data: any) => {
    const itemArray: any[] = [];
    data.allItems.forEach((item) => {
      itemArray.push(
        ...item.versions.map((version) => ({
          ...version,
          itemId: version.item.id,
          currentVersion: version,
          versionIdentifier: version.versionIdentifier,
          customIdentifier: version.item.customIdentifier,
          status: version.currentStatus.name,
          itemType: version.item.itemType,
        })),
      );
    });
    setItems(itemArray);
  };

  const itemTypeGroupingLogic =
    tableDisplayVariant === ViewTypes.ITEM_VIEW ||
    tableDisplayVariant === ViewTypes.EFFECTIVE ||
    tableDisplayVariant === ViewTypes.IN_PROGRESS ||
    tableDisplayVariant === ViewTypes.DRAFT;
  const idGroupingLogic =
    tableDisplayVariant === ViewTypes.ITEM_VIEW ||
    tableDisplayVariant === ViewTypes.ITEM_ID ||
    tableDisplayVariant === ViewTypes.EFFECTIVE ||
    tableDisplayVariant === ViewTypes.STATUS;
  const statusGroupingLogic = tableDisplayVariant === ViewTypes.STATUS || tableDisplayVariant === ViewTypes.IN_PROGRESS;

  const listViewColumns = [
    {
      field: 'itemType.name',
      headerName: 'Item Type',
      type: 'regular_text',
      valueGetter: (row) => startCase(get(row.data, 'itemType.name')),
      ...(!isCustomGrouping && {
        rowGroup: itemTypeGroupingLogic,
        hide: itemTypeGroupingLogic,
      }),
    },
    {
      field: 'customIdentifier',
      headerName: 'ID',
      type: 'id',
      enableRowGroup: true,
      ...(!isCustomGrouping && {
        rowGroup: idGroupingLogic,
        hide: idGroupingLogic,
      }),
    },
    {
      field: 'currentVersion',
      headerName: 'Version',
      type: 'version',
      ...(!isCustomGrouping && {
        rowGroup: tableDisplayVariant === ViewTypes.STATUS,
        hide: tableDisplayVariant === ViewTypes.STATUS,
      }),
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'status',
      ...(!isCustomGrouping && {
        rowGroup: statusGroupingLogic,
        hide: statusGroupingLogic,
        rowGroupIndex: disciplineToggle ? 1 : tableDisplayVariant === ViewTypes.STATUS && 0,
      }),
    },
    {
      field: 'owner',
      headerName: 'Owner',
      type: 'user',
    },
    {
      field: 'approver',
      headerName: 'Approver',
      type: 'user',
    },
    {
      field: 'creator',
      headerName: 'Created By',
      type: 'user',
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      type: 'date',
    },
    {
      field: 'releasedAt',
      headerName: 'Effective Date',
      type: 'date',
    },
    {
      field: 'itemType.discipline',
      headerName: 'Discipline',
      type: 'regular_text',
      valueGetter: (row) => startCase(get(row.data, 'itemType.discipline')),
      ...(!isCustomGrouping
        ? {
            rowGroup: disciplineToggle === null ? tableDisplayVariant === ViewTypes.ITEM_VIEW : disciplineToggle,
            hide: disciplineToggle === null ? tableDisplayVariant === ViewTypes.ITEM_VIEW : disciplineToggle,
            rowGroupIndex: (tableDisplayVariant === ViewTypes.ITEM_VIEW || disciplineToggle) && 0,
          }
        : {
            rowGroup: disciplineToggle,
            hide: disciplineToggle,
          }),
      ...(disciplineToggle ? { rowGroupIndex: 0 } : {}),
    },
    {
      field: 'itemType.product',
      headerName: 'Product Category',
      type: 'regular_text',
      valueGetter: (row) => startCase(get(row.data, 'itemType.product')),
      ...(!isCustomGrouping && {
        rowGroup: tableDisplayVariant === ViewTypes.PRODUCT_CATEGORY,
        hide: tableDisplayVariant === ViewTypes.PRODUCT_CATEGORY,
      }),
    },
  ];

  const removeFilters = (filtersToBeDeleted: string[]) => {
    const filters = gridRef.current.api.getFilterModel();
    const updatedFilters = omit(filters, filtersToBeDeleted);
    gridRef.current.api.setFilterModel(updatedFilters);
    gridRef.current.api.onFilterChanged();
  };

  const handleStatusFilter = (values: string[]) => {
    const instance = gridRef.current.api.getFilterInstance('status');
    instance.setModel({ values });
    gridRef.current.api.onFilterChanged();
  };

  const handleColumnRowGroupChanged = () => {
    switch (tableDisplayVariant) {
      case ViewTypes.EFFECTIVE:
        handleStatusFilter(statusesForEffective);
        break;
      case ViewTypes.DRAFT:
        handleStatusFilter(statusesForDraft);
        break;
      case ViewTypes.IN_PROGRESS:
        handleStatusFilter(statusesForInProgress);
        break;
      default:
        removeFilters(['status']);
        break;
    }
  };

  const handleDisciplineToggleChange = (_, newChecked: boolean) => setDisciplineToggle(newChecked);

  return (
    <Grid container className={classes.tableContainer} spacing={1} wrap="nowrap">
      <TableView
        gridRef={gridRef}
        data={items}
        loading={loading}
        categorySlug={'item-version-index'}
        listViewColumns={listViewColumns}
        categoryLabel={'Item Version Index'}
        groupDefaultExpanded={0}
        showNewItemBtn={false}
        leafField="currentVersion"
        DisplayVariantSelect={() => (
          <OptionsDropdown
            value={tableDisplayVariant}
            onChange={(event) => {
              setTableDisplayVariant(event.target.value);
              setIsCustomGrouping(false);
            }}
            optionsTitle="Views"
            options={[
              {
                value: ViewTypes.ALL_ITEMS,
                Icon: Reorder,
                text: 'All Items',
              },
              {
                value: ViewTypes.ITEM_VIEW,
                Icon: Description,
                text: 'Item View',
              },
              {
                value: ViewTypes.ITEM_ID,
                Icon: InsertDriveFile,
                text: 'Item ID',
              },
              {
                value: ViewTypes.STATUS,
                Icon: PlaylistAddCheckCircle,
                text: 'Status',
              },
              {
                value: ViewTypes.EFFECTIVE,
                Icon: ThumbUp,
                text: 'Effective',
              },
              {
                value: ViewTypes.DRAFT,
                Icon: Edit,
                text: 'Draft',
              },
              {
                value: ViewTypes.IN_PROGRESS,
                Icon: Draw,
                text: 'In Progress',
              },
              {
                value: ViewTypes.PRODUCT_CATEGORY,
                Icon: Category,
                text: 'Product Category',
              },
            ]}
          />
        )}
        DisciplineToggle={() => <DisciplineToggle checked={disciplineToggle} onChange={handleDisciplineToggleChange} />}
        handleColumnRowGroupChanged={handleColumnRowGroupChanged}
      />
    </Grid>
  );
};

export default ItemVersionIndex;
