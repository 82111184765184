import { useQuery } from 'react-apollo';

import { linksQuery } from './queries';
import { useContext } from 'react';
import { FilterContext } from 'contexts/LinksGraph/filterContext';

export function useItemSummaries(itemType: string) {
  const { includeDraft, includeRetired } = useContext(FilterContext);

  const variables = { itemType, includeDraft: includeDraft.showing, includeRetired: includeRetired.showing };
  const { data, refetch, ...rest } = useQuery(linksQuery, {
    variables: variables,
    fetchPolicy: 'no-cache',
  });
  return {
    itemSummaries: data ? data.itemSummaries : [],
    refetch: () => {
      refetch(variables).catch((error) => {
        throw new Error(`Failed to retrieve item summaries: ${error.message}`);
      });
    },
    ...rest,
  };
}
