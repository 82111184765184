import React from 'react';

import { Paper } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import MaterialTable, { MTableCell, MTableToolbar } from 'material-table';

import { filterAndSearch } from 'components/TableView/Fields/Date';

import { useStyles } from './styles';

function Table(props) {
  const classes = useStyles();

  const columns = props.columns.map((column) => {
    if (column.type !== 'date') return column;

    return {
      ...column,
      customFilterAndSearch: filterAndSearch(column.field),
    };
  });

  return (
    <div className={classes.otherTableContainer}>
      {/* Only way to get Material Table to work with ANY icons, including those explicitly passed in as props */}
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

      <MaterialTable
        {...props}
        style={{ boxShadow: 'none' }}
        data={props.data}
        components={{
          Cell: (props) => {
            const { columnDef } = props;
            if (!columnDef.cellStyle) columnDef.cellStyle = {};
            if (!columnDef.cellStyle.hasOwnProperty('padding')) columnDef.cellStyle.padding = 8;
            if (!columnDef.cellStyle.hasOwnProperty('margin')) columnDef.cellStyle.margin = 0;

            return <MTableCell {...props} />;
          },
          Container: (props) => <Paper {...props} elevation={0} />,
          Toolbar: (props) => <MTableToolbar {...props} classes={{ root: classes.toolbar }} />,
        }}
        icons={{
          SortArrow: ArrowDropUpIcon,
        }}
        options={{
          paging: false,
          search: true,
          draggable: false,
          showTitle: false,
          headerStyle: {
            padding: '0 2px',
            borderBottom: 'none',
            backgroundColor: '#fafafa',
          },
        }}
        columns={columns}
      />
    </div>
  );
}

export default Table;
