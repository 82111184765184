import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';

import slugs from '../../displaySlugs';
import { DocumentToolbar } from '../mixins/Document';
import PayloadFields from './PayloadFields';

const itemTypeName = 'standardDocument';

const metadata = {
  itemType: itemTypeName,
  PayloadFields,
  versionFields: `
    standardNumber
    standardName
    edition
    doesStandardApply
    doesStandardApplyYesNo
    justificationIfDoesNotApply
  `,
  listViewQuery: gql`
    query standardDocumentsQuery {
      standardDocuments {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        tags
        currentVersion {
          id
          title
          standardNumber
          standardName
          edition
          doesStandardApply
          doesStandardApplyYesNo
          justificationIfDoesNotApply
          currentStatusName
          versionIdentifier
          signingOwner {
            id
            firstName
            lastName
          }
          signingApprover {
            id
            firstName
            lastName
          }
        }
      }
    }
  `,
  listViewColumns: [
    {
      field: 'customIdentifier',
      headerName: 'ID',
      type: 'id',
    },
    {
      field: 'currentVersion.title',
      headerName: 'Title',
      type: 'regular_text',
    },
    {
      field: 'currentVersion.doesStandardApplyYesNo',
      headerName: 'Applicable?',
      type: 'boolean',
    },
    {
      field: 'currentVersion',
      headerName: 'Version',
      type: 'version',
    },
    {
      field: 'currentVersion.currentStatusName',
      headerName: 'Status',
      type: 'status',
    },
    {
      field: 'createdAt',
      headerName: 'Initiation Date',
      type: 'date',
    },
    {
      field: 'owner',
      headerName: 'Owner',
      type: 'user',
    },
    {
      field: 'approver',
      headerName: 'Approver',
      type: 'user',
    },
  ],
  displaySlug: slugs[itemTypeName],
  label: t`Standard Document`,
  categoryLabel: t`Standard Documents`,
  EffectiveViewOptions: DocumentToolbar,
  icon: InsertDriveFile,
};

export default metadata;
