import React from 'react';
import { CertifiedSignature } from '.';
import { Trans } from '@lingui/macro';
import { useCurrentCompany } from 'compositions/WithCurrentCompany';

const CERTIFY_TEXT = 'By electronically signing below, I confirm that I have read and understood the material listed.';

function CompletedNoAssessment(props) {
  const { data, loading } = useCurrentCompany();
  if (loading || !data) return null;

  const heading = <Trans>Completed Confirmation</Trans>;
  const defaultDialog = `As the assignee of ${props.itemCustomIdentifier}, I have completed the following course: ${
    props.currentVersion.source.item.customIdentifier
  } ${props.currentVersion.source.title}`;
  const configuredDialog = data.currentCompany.config.trainingManagement.independentStudyCertificationText;

  return (
    <CertifiedSignature
      heading={heading}
      dialog={configuredDialog || defaultDialog}
      certifyText={CERTIFY_TEXT}
      {...props}
    />
  );
}

export default CompletedNoAssessment;
