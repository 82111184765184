import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { Notes } from '@material-ui/icons';
import { LockedContext } from 'contexts/lockedContext';

import {
  TOGGLE_TEST_RESOURCES,
  CREATE_REFERENCED_DOCUMENT,
  UPDATE_REFERENCED_DOCUMENT,
  DESTROY_REFERENCED_DOCUMENT,
} from './gql';
import { CollapsableTextArea, LabelText, ToggleRichTextEditor, Spacer } from 'components';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import AttachmentsSection, { Attachment } from 'components/AttachmentsSection';

interface Props {
  attachments: Attachment[];
  materialsAndEquipment: string;
  references: string;
  testResourcesSummary: string;
  versionId: string;
  richTextToggled: boolean;
}

function TestResources(props: Props) {
  const { versionId } = props;

  const [toggleTestResources] = useMutation(TOGGLE_TEST_RESOURCES, {
    refetchQueries: ['DeviceVerificationVersionQuery'],
  });
  const [createAttachment] = useMutation(CREATE_REFERENCED_DOCUMENT, {
    refetchQueries: ['DeviceVerificationVersionQuery'],
  });
  const [updateAttachment] = useMutation(UPDATE_REFERENCED_DOCUMENT);
  const [destroyAttachment] = useMutation(DESTROY_REFERENCED_DOCUMENT);
  const { locked } = useContext(LockedContext);

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        {!locked && (
          <ToggleRichTextEditor
            richTextEditorVisible={props.richTextToggled}
            onConfirm={() => {
              toggleTestResources({ variables: { id: versionId, toggle: !props.richTextToggled } }).catch((error) => {
                throw new Error(`Failed to toggle input method: ${error.message}`);
              });
            }}
          />
        )}
      </div>
      <Spacer factor={1} />
      {props.richTextToggled ? (
        <>
          <LabelText labelText="Text Overview" Icon={Notes} />
          <Spacer factor={1} />
          <RichTextEditorContentSection
            locked={locked}
            initialValue={props.testResourcesSummary}
            fixedToolbarOffset={10}
            versionId={versionId}
            attrName="test_resources_summary"
            refetchQueries={[]}
            optionSet="large"
          />
        </>
      ) : (
        <>
          <CollapsableTextArea
            attrName="materials_and_equipment"
            id="device-verification-materials-equipment-text-field"
            initialValue={props.materialsAndEquipment}
            labelText="Materials and Equipment"
            locked={locked}
            refetchQueries={['DeviceVerificationVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="references"
            id="device-verification-references-text-field"
            initialValue={props.references}
            labelText="References"
            locked={locked}
            refetchQueries={['DeviceVerificationVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <AttachmentsSection
            attachments={props.attachments}
            displayHeader={false}
            enablePreview={false}
            key={props.attachments.length}
            locked={locked}
            versionId={versionId}
            createAttachment={(versionId, attachmentURL) =>
              createAttachment({
                variables: { versionId, attachmentURL },
              }).catch((error) => {
                throw new Error(`Adding attachment failed: ${error.message}`);
              })
            }
            updateAttachment={(id, attachment) =>
              updateAttachment({
                variables: {
                  attachmentId: id,
                  attachmentURL: attachment.url,
                  description: attachment.description,
                },
              }).catch((error) => {
                throw new Error(`Updating attachment failed: ${error.message}`);
              })
            }
            destroyAttachment={(id) =>
              destroyAttachment({
                variables: { id },
              }).catch((error) => {
                throw new Error(`Deletion of attachment failed: ${error.message}`);
              })
            }
          />
        </>
      )}
    </>
  );
}

export default TestResources;
