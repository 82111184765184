import React, { useContext } from 'react';
import { LockedContext } from 'contexts/lockedContext';
import { CollapsableTextArea, ContentSection, TriStateToggle } from 'components';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { TOGGLE_PREREQUISITES } from './gql';
import { useMutation } from 'react-apollo';

interface Props {
  prerequisiteApplicable: boolean | null;
  prerequisiteJustification?: string;
  prerequisiteSteps?: string;
  versionId: string;
}

function Prerequisites(props: Props) {
  const classes = useStyles();

  const [togglePrerequisites] = useMutation(TOGGLE_PREREQUISITES, {
    refetchQueries: ['DeviceVerificationVersionQuery'],
  });
  const { locked } = useContext(LockedContext);

  return (
    <>
      <ContentSection>
        <div className={classes.container}>
          <Typography variant="body2">Are prerequisites applicable to this protocol?</Typography>
          <Typography component="div" variant="caption" className={classes.toggleContainer}>
            <span className={classes.toggleText}>No</span>
            <TriStateToggle
              toggled={props.prerequisiteApplicable}
              disabled={locked}
              onChange={(state) => {
                togglePrerequisites({
                  variables: { id: props.versionId, toggle: state === undefined ? null : state },
                }).catch((err) => {
                  throw err;
                });
              }}
            />
            <span className={classes.toggleText}>Yes</span>
          </Typography>
        </div>
      </ContentSection>
      {props.prerequisiteApplicable && (
        <CollapsableTextArea
          attrName="prerequisite_steps"
          id="device-verification-prerequisite-steps-text-field"
          initialValue={props.prerequisiteSteps || ''}
          labelText="Prerequisite Steps"
          locked={locked}
          refetchQueries={['DeviceVerificationVersionQuery']}
          versionId={props.versionId}
          rows={6}
        />
      )}
      {props.prerequisiteApplicable === false && (
        <CollapsableTextArea
          attrName="prerequisite_justification"
          id="device-verification-prerequisite-justification-text-field"
          initialValue={props.prerequisiteJustification || ''}
          labelText="Justification"
          locked={locked}
          refetchQueries={['DeviceVerificationVersionQuery']}
          versionId={props.versionId}
          rows={6}
        />
      )}
    </>
  );
}

export default Prerequisites;
