import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';

import { Loading } from 'components';

import { GET_RISK_ANALYSIS_VERSION } from '../gql';
import { renderVersion } from './util/TraceUtils';
import { SnackContext } from 'contexts/other/snackContext';

function RiskControlTrace(props) {
  const { showSnack } = useContext(SnackContext);

  const { data, error, loading } = useQuery(GET_RISK_ANALYSIS_VERSION, {
    variables: { id: props.id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.set && data.set.memberVersions && data.set.memberVersions.length === 0) {
        showSnack('No risk control traces found.');
      }
    },
  });

  if (error) throw new Error(`Failed to load Risk Analysis ${props.id}: ${error.message}`);
  if (loading) return <Loading />;

  return (
    <>
      {data.set.memberVersions
        .filter((version) => version.__typename === 'RiskControlVersion')
        .map((version) => renderVersion(version, props))}
    </>
  );
}

export default RiskControlTrace;
