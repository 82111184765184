import React from 'react';

import {
  Card,
  DialogContent,
  Dialog as MaterialUIDialog,
  withStyles,
} from '@material-ui/core';

import Close from '@material-ui/icons/Close';

const style = (theme) => ({
  dialogContent: {
    padding: theme.spacing(3),
  },
  // This is set to this just because we include this in the "Print View" menu
  dialogCard: {
    right: '150%',
    position: 'fixed',
    width: 328,
  },
  closeIcon: {
    float: 'right',
    '&:hover': { cursor: 'pointer' },
  },
});

const Dialog = ({ children, classes, onClose, open }) => {
  return (
    <MaterialUIDialog
      onClose={onClose}
      open={open}
      keepMounted
      hideBackdrop
      disableEnforceFocus
      disablePortal
      disableBackdropClick
      closeAfterTransition
    >
      <Card classes={{ root: classes.dialogCard }}>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Close
            className={classes.closeIcon}
            viewBox="-4 4 24 24"
            onClick={onClose}
            color="action"
          />
          {children}
        </DialogContent>
      </Card>
    </MaterialUIDialog>
  );
};

export default withStyles(style)(Dialog);
