import React, { FC } from 'react';
import { Mutation } from 'react-apollo';
import get from 'lodash/get';

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import GenericNotificationListItem from '.';
import ItemIdAndTitle from './components/ItemIdAndTitle';
import VerbAndAssignor from './components/VerbAndAssignor';
import TaskAssociator from '../TaskAssociator';

import {
  updateMentionCompletedMutationVariables,
  updateMentionCompletedMutation_updateMentionCompleted,
} from './__generated__/updateMentionCompletedMutation';
import { mentionMutation } from './gql';
import { userNotificationsQuery_user_mentionNotifications } from '../../__generated__/userNotificationsQuery';
import linkToItem from 'utils/linkToItem';

import { currentUser_currentUser } from '../../../__generated__/currentUser';

interface Props {
  currentUser: currentUser_currentUser;
  notification: userNotificationsQuery_user_mentionNotifications;
  onRedirect: () => void;
}

const MentionBody: FC<Props> = ({ currentUser, notification, onRedirect }) => {
  const payload = {
    variables: {
      input: {
        notificationId: notification.id,
        completed: !notification.completed,
      },
    },
  };

  const ItemTitle = () => (
    <>
      <VerbAndAssignor
        userId={get(notification, 'mentionedBy.id')}
        fullName={get(notification, 'mentionedBy.fullName')}
        afterVerb="mentioned you in"
      />

      <ItemIdAndTitle
        link={linkToItem(get(notification, 'item.itemType.displaySlug'), get(notification, 'item.id'))}
        onRedirect={onRedirect}
        identifier={get(notification, 'item.customIdentifier')}
        title={get(notification, 'item.currentVersion.title')}
      />
    </>
  );

  return (
    <Mutation<updateMentionCompletedMutation_updateMentionCompleted, updateMentionCompletedMutationVariables>
      mutation={mentionMutation}
      refetchQueries={['userNotificationsQuery', 'currentUser']}
    >
      {(mutate, { error }) => {
        if (error) throw new Error(`${error}`);

        return (
          <GenericNotificationListItem
            headlineIcon={AlternateEmailIcon}
            headlineText="👋 Hey you've been mentioned"
            itemTitle={<ItemTitle />}
            createdAt={notification.createdAt}
            completed={notification.completed}
            onComplete={() => mutate(payload)}
            callToAction={
              <TaskAssociator
                currentUser={currentUser}
                versionId={notification.item.currentVersion ? notification.item.currentVersion.id : null}
                taskReferencedVersions={
                  notification.item.currentVersion ? notification.item.currentVersion.taskReferencedVersions : []
                }
              />
            }
          />
        );
      }}
    </Mutation>
  );
};

export default MentionBody;
