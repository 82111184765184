import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { DateTime } from 'luxon';

import BaseDialog from 'components/BaseDialog';

import SmallCircle from '../../SmallCircle';
import { useStyles } from './styles';

interface Props {
  recordOrigin: string;
  createdAt: string;
}

function RecordOrigin(props: Props) {
  const [recordOriginDialog, setRecordOriginDialog] = useState(false);
  const classes = useStyles();
  return (
    <>
      <SmallCircle
        content="RO"
        color="draft"
        id="record-origin-dialog-toggle"
        handleClick={() => setRecordOriginDialog(true)}
      />
      <BaseDialog
        open={recordOriginDialog}
        onClose={() => setRecordOriginDialog(false)}
        title="Record Origin"
        textAlign="left"
      >
        <Typography variant="h4">Created On</Typography>
        <Typography variant="body2" className={classes.createdAt}>
          {DateTime.fromISO(props.createdAt).toLocaleString(DateTime.DATETIME_FULL)}
        </Typography>
        <Typography variant="h4">Origin of Training Record</Typography>
        <Typography variant="body2">{props.recordOrigin}</Typography>
      </BaseDialog>
    </>
  );
}

export default RecordOrigin;
