import React from 'react';
import { SwitchProps, FormControlLabel } from '@material-ui/core';
import { useStyles } from './styles';
import { Toggle, Spacer } from 'components';

interface Props {
  label: string;
}

function LinksGraphToggle(props: Props & SwitchProps) {
  const { label, ...rest } = props;
  const classes = useStyles();
  return (
    <>
      <FormControlLabel
        classes={{
          label: classes.antSwitchLabel,
          root: classes.root,
        }}
        control={<Toggle color="primary" {...rest} />}
        label={label}
      />
      <Spacer factor={1} />
    </>
  );
}

export default LinksGraphToggle;
