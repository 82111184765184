import React from 'react';
import { Grid, Typography, Collapse } from '@material-ui/core';
import { ContentSection } from 'components';
import {
  CollectionsOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  AttachFile,
  Sort,
  VerticalAlignBottom,
} from '@material-ui/icons';
import fileNameFromURL from 'utils/fileNameFromURL';
import ImagePlaceholder from './ImagePlaceholder';

function ImageGallery(props: {
  classes;
  attachments: {
    id: string;
    url: string;
    previewUrl: string | null;
    description: string | null;
  }[];
}) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Grid container item xs={12} className={`${props.classes.grid} ${props.classes.internalPageSection}`}>
      <div className={props.classes.internalPageSectionTitle} onClick={handleClick}>
        <CollectionsOutlined />
        <Typography variant="h2">Image Gallery</Typography>
        {open ? (
          <KeyboardArrowUp className={props.classes.titleExpandIcon} />
        ) : (
          <KeyboardArrowDown className={props.classes.titleExpandIcon} />
        )}
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit className={props.classes.internalPageSectionContent}>
        <Grid container justify="flex-start" style={{ flexGrow: 0, maxWidth: '100%' }}>
          {props.attachments.map((attachment, ix) => (
            <Grid key={ix} item className={props.classes.imageGalleryCard} xs={4}>
              <ContentSection>
                <div className={props.classes.imageContainer}>
                  {/* fullscreen mode is disabled temporarily */}
                  {/* <Fullscreen
                    onClick={handleFullscreenImage}
                    className={`${props.classes.imageControlIcon} ${
                      props.classes.fullscreenImageIcon
                    }`}
                  /> */}
                  <VerticalAlignBottom
                    onClick={() => window.open(attachment.url)}
                    className={`${props.classes.imageControlIcon} ${props.classes.downloadImageIcon}`}
                  />
                  {attachment.previewUrl ? (
                    <img alt="Preview" src={attachment.previewUrl} style={{ width: '100%' }} />
                  ) : (
                    <ImagePlaceholder classes={props.classes} />
                  )}
                </div>
                <div className={props.classes.imageMetaWrapper}>
                  <AttachFile className={props.classes.icon} />
                  <Typography className={props.classes.imageGalleryText}>{fileNameFromURL(attachment.url)}</Typography>
                </div>
                {attachment.description && (
                  <div className={props.classes.imageMetaWrapper}>
                    <Sort className={props.classes.icon} />
                    <Typography className={props.classes.imageGalleryText}>{attachment.description}</Typography>
                  </div>
                )}
              </ContentSection>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Grid>
  );
}

export default ImageGallery;
