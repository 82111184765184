import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import { RiskAnalysisVersionQuery, RiskAnalysisVersionQueryVariables } from './__generated__/RiskAnalysisVersionQuery';
import {
  createItemLinkMutation,
  deleteLinkMutation,
  createRiskControlVersionConnectionMutation,
  deleteRiskControlVersionConnectionMutation,
} from 'pages/Risk/Id/PayloadFields/gql';
import { createVersion } from 'utils/gql/timelineQueries';

// mitigatedHarmAsUnmitigated - unused
export const riskVersionFields = gql`
  fragment RiskVersionFields on RiskVersion {
    id
    versionIdentifier
    currentStatus {
      id
      name
    }
    harm {
      id
      name
    }
    hazard {
      id
      name
    }
    sequenceOfEvents
    hazardousSituation
    justification
    residualRiskJustification
    mitigatedHarm {
      id
      name
    }
    unmitigatedSeverity {
      id
      name
    }
    unmitigatedProbability {
      id
      name
    }
    unmitigatedProbability1 {
      id
      name
    }
    unmitigatedProbability2 {
      id
      name
    }
    unmitigatedRiskPriorityCode {
      id
      name
      fullName
      color
    }
    mitigatedSeverity {
      id
      name
    }
    mitigatedProbability {
      id
      name
    }
    mitigatedProbability1 {
      id
      name
    }
    mitigatedProbability2 {
      id
      name
    }
    mitigatedRiskPriorityCode {
      id
      name
      fullName
      color
    }
    finalRiskAcceptability
    residualRiskAcceptability
    locked
    linkedRiskControlVersions {
      id
      title
      ... on RiskControlVersion {
        id
        introducesNewRisks
        chosenRiskVersionIds
        linkedRiskVersions {
          id
          title
          harm {
            id
            name
          }
          hazard {
            id
            name
          }
          hazardousSituation
          sequenceOfEvents
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
            }
            currentRelease {
              id
              versionIdentifier
              currentStatus {
                id
                name
              }
            }
            workingVersion {
              id
              versionIdentifier
              currentStatus {
                id
                name
              }
            }
          }
        }
      }
      versionIdentifier
      currentStatus {
        id
        name
      }
      item {
        customIdentifier
        currentRelease {
          id
          versionIdentifier
          title
          currentStatus {
            name
            id
          }
        }
        currentVersion {
          title
          id
          versionIdentifier
          ... on RiskControlVersion {
            introducesNewRisks
          }
          item {
            id
            itemType {
              id
              displaySlug
            }
          }
          currentStatus {
            id
            name
          }
        }
        workingVersion {
          id
          title
          versionIdentifier
          currentStatus {
            name
            id
          }
        }
        id
        customIdentifier
        itemType {
          id
          displaySlug
          userCreatable
        }
      }
    }
    item {
      id
      tags
      comments {
        id
      }
      customIdentifier
      itemType {
        id
        displaySlug
        userCreatable
      }
      currentVersion {
        id
        versionIdentifier
        currentStatus {
          id
          name
        }
        locked
      }
      currentRelease {
        id
        versionIdentifier
        currentStatus {
          id
          name
        }
      }
      workingVersion {
        id
        versionIdentifier
        currentStatus {
          id
          name
        }
      }
      riskControlMeasuresOptions(riskAnalysisId: $id) {
        id
        customIdentifier
        currentVersion {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
      }
      riskControlMeasures {
        id
        chosenRiskControlVersionId
        customIdentifier
        introducedNewRisks {
          id
          customIdentifier
          currentVersion {
            title
            id
          }
        }
        currentVersion {
          title
          id
          versionIdentifier
          ... on RiskControlVersion {
            introducesNewRisks
          }
          item {
            id
            itemType {
              id
              displaySlug
            }
            versions {
              id
            }
            currentRelease {
              id
              versionIdentifier
              currentStatus {
                id
                name
              }
            }
            workingVersion {
              id
              versionIdentifier
              currentStatus {
                id
                name
              }
            }
          }
          currentStatus {
            id
            name
          }
        }
      }
    }
  }
`;

const versionQuery = gql`
  query RiskAnalysisVersionQuery($id: ID!) {
    riskAnalysisVersion(id: $id) {
      id
      title
      locked
      item {
        id
        customIdentifier
      }
      memberVersions {
        ...RiskVersionFields
      }
    }
  }
  ${riskVersionFields}
`;

export function useVersion(id: string) {
  return useQuery<RiskAnalysisVersionQuery, RiskAnalysisVersionQueryVariables>(versionQuery, {
    variables: { id },
    fetchPolicy: 'no-cache', // returned risks are updated through cache without refetching
  });
}

export const updateVersion = gql`
  mutation updateRiskVersionInRiskAnalysis(
    $id: ID!
    $unmitigatedSeverityId: ID
    $unmitigatedProbabilityId: ID
    $unmitigatedProbability1Id: ID
    $unmitigatedProbability2Id: ID
    $unmitigatedRiskPriorityCodeId: ID
    $mitigatedSeverityId: ID
    $mitigatedProbabilityId: ID
    $mitigatedProbability1Id: ID
    $mitigatedProbability2Id: ID
    $mitigatedRiskPriorityCodeId: ID
    $finalRiskAcceptability: Boolean
    $residualRiskAcceptability: Boolean
    $harmId: ID
    $mitigatedHarmId: ID
    $hazardId: ID
    $sequenceOfEvents: String
    $hazardousSituation: String
    $justification: String
    $residualRiskJustification: String
    $mitigatedHarmAsUnmitigated: Boolean
    $harmName: String
    $mitigatedHarmName: String
    $hazardName: String
  ) {
    updateRiskVersion(
      id: $id
      unmitigatedSeverityId: $unmitigatedSeverityId
      unmitigatedProbabilityId: $unmitigatedProbabilityId
      unmitigatedProbability1Id: $unmitigatedProbability1Id
      unmitigatedProbability2Id: $unmitigatedProbability2Id
      unmitigatedRiskPriorityCodeId: $unmitigatedRiskPriorityCodeId
      mitigatedSeverityId: $mitigatedSeverityId
      mitigatedProbabilityId: $mitigatedProbabilityId
      mitigatedProbability1Id: $mitigatedProbability1Id
      mitigatedProbability2Id: $mitigatedProbability2Id
      mitigatedRiskPriorityCodeId: $mitigatedRiskPriorityCodeId
      finalRiskAcceptability: $finalRiskAcceptability
      residualRiskAcceptability: $residualRiskAcceptability
      harmId: $harmId
      mitigatedHarmId: $mitigatedHarmId
      hazardId: $hazardId
      sequenceOfEvents: $sequenceOfEvents
      hazardousSituation: $hazardousSituation
      justification: $justification
      residualRiskJustification: $residualRiskJustification
      mitigatedHarmAsUnmitigated: $mitigatedHarmAsUnmitigated
      harmName: $harmName
      mitigatedHarmName: $mitigatedHarmName
      hazardName: $hazardName
    ) {
      riskVersion {
        id
      }
    }
  }
`;

export const linkExistingRisksToRiskAnalysis = gql`
  mutation linkExistingRisksToRiskAnalysis($riskAnalysisVersionId: ID!, $linkedRiskVersionIds: [ID!]!) {
    linkExistingRisksToRiskAnalysis(
      input: { riskAnalysisVersionId: $riskAnalysisVersionId, linkedRiskVersionIds: $linkedRiskVersionIds }
    ) {
      success
    }
  }
`;

export const linkNewRiskToRiskAnalysis = gql`
  mutation linkNewRiskToRiskAnalysis($riskAnalysisVersionId: ID!) {
    linkNewRiskToRiskAnalysis(input: { riskAnalysisVersionId: $riskAnalysisVersionId }) {
      riskVersion {
        id
        title
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export const changeRiskVersionInRiskAnalysis = gql`
  mutation changeRiskVersionInRiskAnalysis(
    $riskAnalysisVersionId: ID!
    $oldRiskVersionId: ID!
    $newRiskVersionId: ID!
  ) {
    changeRiskVersionInRiskAnalysis(
      input: {
        riskAnalysisVersionId: $riskAnalysisVersionId
        oldRiskVersionId: $oldRiskVersionId
        newRiskVersionId: $newRiskVersionId
      }
    ) {
      success
    }
  }
`;

export const unlinkRiskFromRiskAnalysis = gql`
  mutation unlinkRiskFromRiskAnalysis($riskAnalysisVersionId: ID!, $riskVersionId: ID!) {
    unlinkRiskFromRiskAnalysis(riskAnalysisVersionId: $riskAnalysisVersionId, riskVersionId: $riskVersionId) {
      success
    }
  }
`;

export const changeRiskControlVersionMutation = gql`
  mutation changeRiskControlVersion(
    $riskVersionId: ID!
    $newRiskControlVersionId: ID!
    $oldRiskControlVersionId: ID!
    $trioType: String
  ) {
    changeRiskControlVersionInRiskVersion(
      input: {
        riskVersionId: $riskVersionId
        newRiskControlVersionId: $newRiskControlVersionId
        oldRiskControlVersionId: $oldRiskControlVersionId
        trioType: $trioType
      }
    ) {
      version {
        id
        versionIdentifier
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export function useChangeRiskControlVersion() {
  return useMutation(changeRiskControlVersionMutation, {
    refetchQueries: ['RiskAnalysisVersionQuery'],
  });
}

type MutationParams = object;

export function useUpdateVersion(params: MutationParams = {}) {
  return useMutation(updateVersion, params);
}

export function useAddLinkItem(params: MutationParams = {}) {
  return useMutation(createItemLinkMutation, params);
}

export function useAddRiskControlVersion(params: MutationParams = {}) {
  return useMutation(createRiskControlVersionConnectionMutation, params);
}

export function useDeleteItemLink(params: MutationParams = {}) {
  return useMutation(deleteLinkMutation, params);
}

export function useAddRisksToRiskAnalysis(params: MutationParams = {}) {
  return useMutation(linkExistingRisksToRiskAnalysis, params);
}

export function useDeleteRiskControlVersion(params: MutationParams = {}) {
  return useMutation(deleteRiskControlVersionConnectionMutation, params);
}

export function useAddNewRiskToRiskAnalysis(params: MutationParams = {}) {
  return useMutation(linkNewRiskToRiskAnalysis, params);
}

export function useChangeRiskVersion(params: MutationParams = {}) {
  return useMutation(changeRiskVersionInRiskAnalysis, params);
}

export function useCreateNewVersion(params: MutationParams = {}) {
  return useMutation(createVersion, params);
}

export function useUnlinkRiskFromRiskAnalysis(params: MutationParams = {}) {
  return useMutation(unlinkRiskFromRiskAnalysis, params);
}
