import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';

import { Loading } from 'components';

import { GET_USER_NEEDS_SET_VERSION } from '../gql';
import { renderVersion } from './util/TraceUtils';
import { SnackContext } from 'contexts/other/snackContext';

function UserNeedsSetTrace(props) {
  const { showSnack } = useContext(SnackContext);

  const { data, error, loading } = useQuery(GET_USER_NEEDS_SET_VERSION, {
    variables: { id: props.id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.set && data.set.memberVersions && data.set.memberVersions.length === 0) {
        showSnack('No user needs set traces found.');
      }
    },
  });

  if (error) throw new Error(`Failed to load user need set ${props.id}: ${error.message}`);
  if (loading) return <Loading />;

  return <>{data.set.memberVersions.map((version) => renderVersion(version, props))}</>;
}

export default UserNeedsSetTrace;
