import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const searchQuery = gql`
  query Search(
    $q: String
    $itemTypes: [String!]
    $draftOnly: Boolean
    $excludeVoidAndCancel: Boolean
    $supportsChangeOrderOnly: Boolean
    $excludeMembersAlreadyInSet: Boolean
  ) {
    itemSearch(
      query: $q
      itemTypes: $itemTypes
      draftOnly: $draftOnly
      excludeVoidAndCancel: $excludeVoidAndCancel
      supportsChangeOrderOnly: $supportsChangeOrderOnly
      excludeMembersAlreadyInSet: $excludeMembersAlreadyInSet
    ) {
      itemType
      results {
        id
        customIdentifier
        title
        userCreatable
        currentVersionId
        status
        currentRelease {
          id
          versionIdentifier
          currentStatusName
          currentStatus {
            id
            name
          }
        }
        workingVersion {
          id
          versionIdentifier
          currentStatusName
          currentStatus {
            id
            name
          }
        }
      }
    }
  }
`;

export function useItemSearch(
  searchTerm: string | null,
  draftOnly: boolean,
  excludeVoidAndCancel: boolean,
  supportsChangeOrderOnly: boolean,
  excludeMembersAlreadyInSet: boolean,
  itemTypes?: string[],
  onCompleted?: (data: any) => void,
) {
  return useQuery(searchQuery, {
    fetchPolicy: 'network-only',
    skip: searchTerm === null || searchTerm === undefined,
    variables: {
      q: searchTerm,
      draftOnly: draftOnly,
      excludeVoidAndCancel: excludeVoidAndCancel,
      supportsChangeOrderOnly: supportsChangeOrderOnly,
      excludeMembersAlreadyInSet: excludeMembersAlreadyInSet,
      itemTypes: itemTypes,
    },
    onCompleted,
  });
}
