import gql from 'graphql-tag';

const itemTypeFields = gql`
  fragment itemTypeFields on ItemType {
    id
    name
    userCreatable
    discipline
    displaySlug
    workflow {
      id
      name
    }
    fields {
      fieldName
      fieldType
      userEditable
    }
    parentSetItemType {
      id
      name
      displaySlug
    }
    isWorkflowSignatureShown
    permissions {
      jobRole {
        id
        roleName
        customRoleName
      }
      permissionType
      currentUserIsPermitted
    }
  }
`;

const singleItemTypeQuery = gql`
  query ItemTypeMetadata($itemType: String!) {
    itemType(name: $itemType) {
      ...itemTypeFields
    }
  }
  ${itemTypeFields}
`;

/* 
  Just query the essentials if we are making the larger query.
  Can add to this as needed.
*/
const allItemTypesQuery = gql`
  query AllItemTypes {
    itemTypes {
      id
      name
      userCreatable
      discipline
      displaySlug
      suggestedUpstreamItems
      suggestedDownstreamItems
      traceableRiskUpstreamTypes
      traceableRiskDownstreamTypes
      traceableDesignUpstreamTypes
      traceableDesignDownstreamTypes
      parentSetItemType {
        id
        name
      }
      permissions {
        permissionType
        jobRole {
          id
          formattedRoleName
          usersWithRole {
            id
            fullName
          }
        }
      }
      itemsCount
    }
  }
`;

export { itemTypeFields, singleItemTypeQuery, allItemTypesQuery };
