import React from 'react';

import { Typography } from '@material-ui/core';

import ConfirmationDialog from 'components/ConfirmationDialog';

interface VersionTracesConfirmationModalProps {
  isOpen: boolean;
  actionType: 'remove-rcm' | 'toggle-rcm' | null;
  traces: any[];
  riskIdentifier: string;
  currentVersionId: string;
  onCancel: () => void;
  onConfirm: () => void;
}

function VersionTracesConfirmationModal({
  isOpen,
  actionType,
  traces,
  riskIdentifier,
  currentVersionId,
  onCancel,
  onConfirm,
}: VersionTracesConfirmationModalProps) {
  let message = '';
  let title = '';

  const getTraceMatrixDetails = (traces: any[]) => {
    const uniqueTraceMatrixIdentifiers = new Set();
    return traces
      .reduce((acc, trace) => {
        let traceMatrixIdentifier = trace.owner.item.customIdentifier;
        if (!uniqueTraceMatrixIdentifiers.has(traceMatrixIdentifier)) {
          uniqueTraceMatrixIdentifiers.add(traceMatrixIdentifier);
          acc.push(traceMatrixIdentifier);
        }
        return acc;
      }, [])
      .join('\n\n');
  };

  const getTraceDetails = (traces: any[], currentVersionId: string) => {
    return traces
      .map((trace) => {
        if (trace.upstreamVersion.id === currentVersionId) {
          return trace.downstreamVersion.item.customIdentifier;
        } else {
          return trace.upstreamVersion.item.customIdentifier;
        }
      })
      .join('\n\n');
  };

  if (actionType === 'remove-rcm' || actionType === 'toggle-rcm') {
    const traceMatrixDetails = getTraceMatrixDetails(traces);
    const traceDetails = getTraceDetails(traces, currentVersionId);

    message = `${
      actionType === 'remove-rcm' ? 'Removing' : 'Changing'
    } this Risk Control measure from ${riskIdentifier} will also delete the traces that exist on ${traceMatrixDetails} to the following items\n\n${traceDetails}`;
    title = actionType === 'remove-rcm' ? 'Removing Risk Control' : 'Changing Risk Control';
  }

  return (
    <ConfirmationDialog open={isOpen} title={title} onCancel={onCancel} onClose={onCancel} onConfirm={onConfirm}>
      {message}
      <Typography variant="body2">Are you sure you wish to proceed?</Typography>
    </ConfirmationDialog>
  );
}

export default VersionTracesConfirmationModal;
