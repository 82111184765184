import React, { useState } from 'react';
import capitalize from 'lodash/capitalize';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import { t } from '@lingui/macro';

import { Box, Button, Grid, Modal, Tooltip, Typography } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import Archive from '@material-ui/icons/Archive';
import Cancel from '@material-ui/icons/Cancel';

import { BaseSnackbar, TableView } from 'components';
import IconButton from 'components/IconButton';
import { DateCell, dateHeaderStyle } from 'components/TableView/Fields/Date';
import AddUsersToRole from './AddUsersToRole';
import CreateJobRole from './CreateJobRole';
import ArchiveJobRole from './ArchiveJobRole';
import CannotRemoveRole from './CannotRemoveRoleModal';
import RemoveUserFromRole from './RemoveUserFromRole';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import withJobRoles from 'compositions/WithJobRoles';
import { useStyles } from './styles';
import { isColumnGroupedBy, sortIds } from 'utils/agGridHelpers';

interface Props {
  path: string;
  jobRoles: any;
  currentCompany: any;
}

function RoleManagement(props: Props) {
  const classes = useStyles();
  const [addUsersOpen, setAddUsersOpen] = useState(false);
  const [cannotRemoveRoleOpen, setCannotRemoveRoleOpen] = useState(false);
  const [createJobRoleOpen, setCreateJobRoleOpen] = useState(false);
  const [archiveJobRoleOpen, setArchiveJobRoleOpen] = useState(false);
  const [removeUserFromRoleOpen, setRemoveUserFromRoleOpen] = useState(false);
  const [jobRole, setJobRole] = useState({});
  const [usersWithRole, setUsersWithRole] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [usersUpdated, setUsersUpdated] = useState([]);

  const userListWithoutSystemUser = (data) => data.filter((user) => user.id !== '1');

  const handleAddUsersClick = (data) => {
    setAddUsersOpen(true);
    setJobRole(data);
    setUsersWithRole(data.usersWithRole);
  };

  const handleRemoveUsersClick = (data) => {
    if (
      userListWithoutSystemUser(data.usersWithRole).length === 1 &&
      get(userListWithoutSystemUser(data.usersWithRole).first, 'activePermissionGrants', []).length
    ) {
      setCannotRemoveRoleOpen(true);
      setJobRole(data);
    } else {
      setRemoveUserFromRoleOpen(true);
      setJobRole(data);
    }
  };

  const onModalClose = () => {
    setCreateJobRoleOpen(false);
    setArchiveJobRoleOpen(false);
    setCannotRemoveRoleOpen(false);
    setRemoveUserFromRoleOpen(false);
    setJobRole({});
    setUsersWithRole([]);
  };

  const onJobRoleUpdateCompleted = (users) => {
    setUsersUpdated(users);
    setShowSnackbar(true);
  };

  const handleCreateJobRoleClick = () => {
    setCreateJobRoleOpen(true);
  };

  const handleArchiveClick = (data) => {
    setJobRole(data);
    setUsersWithRole(data.usersWithRole);
    setArchiveJobRoleOpen(true);
  };

  const listViewColumns = [
    {
      field: 'customIdentifier',
      headerName: 'Role ID',
      type: 'short_text',
      blockRowLink: true,
      comparator: (value1, value2) => sortIds(value1, value2),
    },
    { field: 'formattedRoleName', headerName: 'Role Name', type: 'regular_text', blockRowLink: true },
    {
      field: 'roleType',
      headerName: 'Role Type',
      type: 'regular_text',
      blockRowLink: true,
      valueGetter: (row) =>
        get(row.data, 'roleType') === 'system_role' ? 'System' : capitalize(get(row.data, 'roleType')),
    },
    {
      field: 'usersWithRole',
      headerName: 'Users With Role',
      type: 'custom',
      blockRowLink: true,
      enableRowGroup: true,
      valueGetter: (row) => {
        if (!row.data) return null;

        const users = userListWithoutSystemUser(get(row.data, 'usersWithRole')).map((role) => role.fullName);
        return users.join(', ');
      },
      render: function(row) {
        if (isColumnGroupedBy(row, 'usersWithRole')) return row.value;
        if (!row.data) return null;

        const users = userListWithoutSystemUser(get(row.data, 'usersWithRole')).map((role) => role.fullName);
        return users.join(', ');
      },
    },
    {
      field: '',
      headerName: 'Manage Role Users',
      type: 'custom',
      width: 100,
      blockRowLink: true,
      textAlign: 'center',
      cellRenderer: function(row) {
        return (
          get(row.data, 'status') === 'active' && (
            <span>
              <IconButton
                Icon={AddCircle}
                disabled={get(row.data, 'roleName') === 'system'}
                onClick={() => handleAddUsersClick(row.data)}
                themeColor
              />
              <IconButton
                Icon={Cancel}
                disabled={
                  get(row.data, 'roleName') === 'system' ||
                  get(row.data, 'roleName') === 'general' ||
                  !row.data.usersWithRole.length
                }
                onClick={() => handleRemoveUsersClick(row.data)}
              />
            </span>
          )
        );
      },
    },
    { field: 'creator.fullName', headerName: 'Created By', type: 'regular_text', blockRowLink: true },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      type: 'date',
      blockRowLink: true,
      headerStyle: dateHeaderStyle,
      render: function(row) {
        return <DateCell data={row.data.createdAt} />;
      },
    },
    {
      field: 'status',
      headerName: 'Archive',
      type: 'custom',
      width: 100,
      blockRowLink: true,
      enableRowGroup: true,
      cellRenderer: function(row) {
        if (row.value === undefined) return null;
        if (!row.data) return row.value;

        return (
          <Tooltip
            title="Upgrade license to archive role"
            disableHoverListener={props.currentCompany.allowCustomPermissions || get(row.data, 'roleName') === 'system'}
          >
            <span>
              {get(row.data, 'status') === 'archived' ? (
                <Typography variant="body2">Archived</Typography>
              ) : (
                <Tooltip
                  title="This role cannot be archived"
                  disableHoverListener={
                    get(row.data, 'roleName') !== 'system' && get(row.data, 'roleName') !== 'general'
                  }
                >
                  <span>
                    <IconButton
                      Icon={Archive}
                      disabled={
                        !props.currentCompany.allowCustomPermissions ||
                        get(row.data, 'roleName') === 'system' ||
                        get(row.data, 'roleName') === 'general'
                      }
                      onClick={() => handleArchiveClick(row.data)}
                    />
                  </span>
                </Tooltip>
              )}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Grid container className={classes.container}>
      <Grid item container className={classes.header}>
        <Box flex={1} />
        <Tooltip
          title="Upgrade license to create new roles"
          disableHoverListener={props.currentCompany.allowCustomPermissions}
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              disabled={!props.currentCompany.allowCustomPermissions}
              onClick={() => handleCreateJobRoleClick()}
            >
              + Create Role
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <Grid item container>
        <>
          <TableView
            data={props.jobRoles}
            loading={false}
            categoryLabel={t`Role Management`}
            categorySlug={'role-management'}
            listViewColumns={listViewColumns}
            showNewItemBtn={false}
            showItemTypeInfo={false}
          />
        </>
      </Grid>
      <Modal open={addUsersOpen}>
        <AddUsersToRole
          open={addUsersOpen}
          onClose={() => setAddUsersOpen(false)}
          jobRole={jobRole}
          usersWithRole={usersWithRole}
          usersUpdated={onJobRoleUpdateCompleted}
        />
      </Modal>
      <BaseSnackbar
        message={`${usersUpdated
          .map((user) => get(user, 'fullName'))
          .join(', ')
          .replace(/,(?=[^,]+$)/, ' and')} ${usersUpdated.length > 1 ? 'were' : 'was'} assigned ${get(
          jobRole,
          'formattedRoleName',
        )} successfully`}
        onClose={() => setShowSnackbar(false)}
        open={showSnackbar}
      />
      <CreateJobRole open={createJobRoleOpen} onClose={onModalClose} />
      <ArchiveJobRole
        open={archiveJobRoleOpen}
        onClose={onModalClose}
        jobRole={jobRole}
        usersWithRole={usersWithRole}
      />
      <CannotRemoveRole open={cannotRemoveRoleOpen} onClose={onModalClose} jobRole={jobRole} />
      <RemoveUserFromRole open={removeUserFromRoleOpen} onClose={onModalClose} jobRole={jobRole} />
    </Grid>
  );
}

export default flowRight([withJobRoles, withCurrentCompany])(RoleManagement);
