import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';

import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';

import { stringToBool } from 'utils';

const styles = (theme) => ({
  formControlLabel: {
    display: 'flex',
    flex: 1,
    maxWidth: 150,
  },
  patch: {
    borderRadius: theme.shape.borderRadius,
    height: 20,
    width: 20,
  },
  radioRoot: {
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  root: {
    width: '100%',
  },
});

const mutation = gql`
  mutation updateCheckboxValue($input: UpdateVirtualAttributeInput!) {
    updateVirtualAttribute(input: $input) {
      virtualAttribute {
        attrName
        booleanValue
      }
    }
  }
`;
class CheckboxField extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      formControlLabel: PropTypes.string.isRequired,
      patch: PropTypes.string.isRequired,
      radioRoot: PropTypes.string.isRequired,
      root: PropTypes.string.isRequired,
    }).isRequired,
    attrName: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    refetchQueries: PropTypes.array,
    versionId: PropTypes.string.isRequired,
    value: PropTypes.bool,
  };

  static defaultProps = {
    refetchQueries: undefined,
    value: false,
    locked: true,
  };

  render() {
    const {
      classes,
      attrName,
      label,
      locked,
      refetchQueries,
      versionId,
      value,
      ...rest
    } = this.props;

    return (
      <Mutation mutation={mutation} ignoreResults>
        {(mutate) => (
          <FormControlLabel
            classes={{ label: classes.formControlLabel }}
            control={
              <Checkbox
                checked={stringToBool(value)}
                onChange={(event) => {
                  mutate({
                    variables: {
                      input: {
                        attrName,
                        booleanValue: event.target.checked,
                        versionId,
                      },
                    },
                    refetchQueries,
                  });
                }}
                value={attrName}
                {...rest}
              />
            }
            disabled={locked}
            label={label}
          />
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(CheckboxField);
