import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './gpp-good.svg';

function GppGood(props) {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <Icon {...svgProps} />} />;
}

export default GppGood;
