import PropTypes from 'prop-types';
import React from 'react';

import { CircularProgress, Grid } from '@material-ui/core';

function Loading({ size, style }) {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{ height: '100vh', width: '100%', ...style }}
    >
      <Grid item>
        <CircularProgress size={size || 120} />
      </Grid>
    </Grid>
  );
}

Loading.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
};

Loading.defaultProps = {
  size: undefined,
  style: undefined,
};

export default Loading;
