import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    close: {
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
    },
    closeContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    dialog: {
      width: '644px',
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    header: {
      display: 'flex',
      marginBottom: 20,
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    status: {
      width: '160px',
      whiteSpace: 'nowrap',

      '& > button ': {
        marginRight: theme.spacing(1),
      },
      '& .MuiTypography-subtitle1': {
        padding: `0 ${theme.spacing(1)}px`,
        display: "inline-block",
        whiteSpace: "normal",
      }
    },
    user: {
      display: 'flex',
      alignItems: "center",
    },
    userInfo: {
      marginLeft: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    date: {
      padding: `0 ${theme.spacing(1)}px`,
      '& .MuiTypography-body2': {
        lineHeight: '32px'
      }
    },
    time: {
      padding: `0 ${theme.spacing(1)}px`,
      '& .MuiTypography-body2': {
        lineHeight: '32px'
      }
    },
    reasonForSignature: {
      marginTop: theme.spacing(2)
    }
  })
);
