import React, { ReactNode } from 'react';

import clsx from 'clsx';

import { useStyles } from './styles';

interface Props {
  children: ReactNode;
  className?: string;
}

function Buttons(props: Props) {
  const classes = useStyles();
  return <div className={clsx(classes.row, props.className)}>{props.children}</div>;
}

export default Buttons;
