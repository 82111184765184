import React from 'react';

import { useMutation } from 'react-apollo';

import { UPDATE_VERSION_TRACE } from '../gql';

export default function withUpdateVersionTrace(Component) {
  return function(props) {
    const [mutation] = useMutation(UPDATE_VERSION_TRACE),
      updateVersionTrace = (variables) => mutation({ variables });

    return <Component {...props} updateVersionTrace={updateVersionTrace} />;
  };
}
