import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import {
  CurriculumBuilderViewQuery,
  CurriculumBuilderViewQueryVariables,
} from './__generated__/CurriculumBuilderViewQuery';
import { CurriculumVersionQuery, CurriculumVersionQueryVariables } from './__generated__/CurriculumVersionQuery';

const builderViewQuery = gql`
  query CurriculumBuilderViewQuery($itemId: ID) {
    item(id: $itemId, itemType: "curriculum") {
      id
      customIdentifier
      createdAt
      currentUser {
        id
        username
        readOnly
      }
      owners {
        id
        fullName
        title
      }
      owner {
        id
        fullName
        title
        status
      }
      approvers {
        id
        fullName
        title
      }
      approver {
        id
        fullName
        title
        status
      }
      referenceId
      tags
      workingVersion {
        id
      }
      currentVersion {
        approver {
          id
          fullName
          title
        }
        createdAt
        creator {
          id
          fullName
          title
        }
        id
        locked
        owner {
          id
          fullName
          title
        }
        assignedOwnerJobRole {
          id
          formattedRoleName
        }
        assignedApproverJobRole {
          id
          formattedRoleName
        }
        permissibleApprovers {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        permissibleOwners {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        title
        versionIdentifier
        currentStatusName
      }
      itemType {
        id
        workflow {
          id
          name
        }
      }
    }
  }
`;

export const useBuilderViewQuery = (itemId: string) =>
  useQuery<CurriculumBuilderViewQuery, CurriculumBuilderViewQueryVariables>(builderViewQuery, {
    variables: {
      itemId,
    },
  });

const versionQuery = gql`
  query CurriculumVersionQuery($id: ID!) {
    curriculumVersion(id: $id) {
      id
      description
      locked
      courseVersions {
        id
        item {
          id
          customIdentifier
        }
        title
        versionIdentifier
        effectivenessMeasurementRequired
        currentStatusName
        attachment {
          id
          filename
        }
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<CurriculumVersionQuery, CurriculumVersionQueryVariables>(versionQuery, { variables: { id } });
}

const updateVersion = gql`
  mutation UpdateCurriculumVersion($id: ID!, $description: String) {
    updateCurriculumVersion(id: $id, description: $description) {
      curriculumVersion {
        id
        description
        courseVersions {
          id
          item {
            id
            customIdentifier
          }
          title
          versionIdentifier
          currentStatusName
          attachment {
            id
          }
        }
      }
    }
  }
`;

export function useUpdateVersion() {
  return useMutation(updateVersion);
}

const addCourse = gql`
  mutation AddCourse($courseVersionId: ID!, $curriculumVersionId: ID!) {
    addCourseToCurriculum(courseVersionId: $courseVersionId, curriculumVersionId: $curriculumVersionId) {
      curriculumVersion {
        id
      }
    }
  }
`;

export function useAddCourse() {
  return useMutation(addCourse);
}

const removeCourse = gql`
  mutation RemoveCourse($courseVersionId: ID!, $curriculumVersionId: ID!) {
    removeCourseFromCurriculum(courseVersionId: $courseVersionId, curriculumVersionId: $curriculumVersionId) {
      curriculumVersion {
        id
      }
    }
  }
`;

export function useRemoveCourse() {
  return useMutation(removeCourse);
}
