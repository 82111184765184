import React from 'react';
import 'ag-grid-enterprise';
import { useQuery } from 'react-apollo';
import { Grid, createStyles, makeStyles } from '@material-ui/core';
import 'components/AgGridTable/Styles/ag-grid.css';
import 'components/AgGridTable/Styles/ag-theme-material.css';

import { Breadcrumbs, ItemTitle, Loading, MakeLink } from 'components';
import AgGridTable from 'components/AgGridTable';
import { LinksGraph, NewItem } from 'compositions';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { LIST_COURSES } from './gql';
import { Views } from 'workspaces';
import useItemType from 'pages/generic/useItemType';

export const useStyles = makeStyles((theme) =>
  createStyles({
    itemTitle: {
      color: theme.palette.secondary.main,
    },
  }),
);

function ListPage(_props) {
  const classes = useStyles();
  const { data, loading, error } = useQuery(LIST_COURSES, { fetchPolicy: 'network-only' });
  const itemType = useItemType(_props.itemType);
  if (!itemType) return null;

  const columns = [
    {
      type: 'id',
      headerName: 'ID',
      // We need this to properly sort but it adds align-right which overrides our styles
      //type: 'numeric',
      field: 'customIdentifier',
    },
    {
      type: 'regular_text',
      headerName: 'Title',
      field: 'currentVersion.title',
    },
    {
      type: 'version',
      headerName: 'Version',
      field: 'currentVersion.versionIdentifier',
    },
    {
      headerName: 'Description',
      field: 'currentVersion.description',
      type: 'regular_text',
    },
    {
      headerName: 'Associated Items',
      field: 'currentVersion.qualityDocumentVersions',
      type: 'custom',
      enableRowGroup: true,
      keyCreator: (row) => {
        return row.value.map((version) => version.item.customIdentifier).join(', ');
      },
      render: (row) => {
        const isColumnGroupedBy = Boolean(
          row.columnApi.columnModel.rowGroupColumns.find(
            (column) => column.colId === 'currentVersion.qualityDocumentVersions',
          ),
        );
        if (isColumnGroupedBy) {
          return row.value;
        }
        return row.data.currentVersion.qualityDocumentVersions.map((version, index) => {
          return (
            <>
              {index > 0 && ', '}
              <MakeLink newTab={true} item={version.item}>
                {version.item.customIdentifier}
              </MakeLink>
            </>
          );
        });
      },
    },
    {
      type: 'date',
      headerName: 'Initiation Date',
      field: 'currentVersion.createdAt',
      format: 'date',
    },
    {
      headerName: 'Learning Objective',
      field: 'currentVersion.learningObjective',
      type: 'regular_text',
    },
    {
      type: 'status',
      headerName: 'Status',
      field: 'currentVersion.currentStatus.name',
    },
    {
      field: 'currentVersion.owner',
      type: 'user',
      headerName: 'Owner',
    },
    {
      field: 'currentVersion.approver',
      type: 'user',
      headerName: 'Approver',
    },
  ];
  if (loading) return <Loading />;
  if (error) throw new Error(`Error loading courses: ${error.message}`);

  switch (_props.activeWorkspaceView.id) {
    case Views.Table.id:
      return (
        <Grid container spacing={5} style={{ width: '100%', margin: 'inherit' }}>
          <Grid item xs={12}>
            <Breadcrumbs categorySlug="courses" />
          </Grid>
          <Grid item xs={12}>
            <AgGridTable
              data={data.courses}
              columns={columns}
              TableTitle={() => (
                <ItemTitle itemTitle="Courses" editable={false} classes={{ typography: classes.itemTitle }} />
              )}
              NewItemButton={() => <NewItem color="#6e7583" fontSize="24px" defaultSelectedItemType="course" />}
            />
          </Grid>
        </Grid>
      );
    case Views.LinksGraph.id:
      return (
        <LinksGraph
          categorySlug={_props.categorySlug}
          itemType={_props.itemType}
          queryVariables={itemType.linksGraphViewQueryVariables}
        />
      );
  }
}

export default withWorkspaceViews()(ListPage);
