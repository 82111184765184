import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { docStylePayloadFields } from '../../../itemTypes/mixins/DocumentBased/gql';

const versionQuery = gql`
  query QualityAgreementVersionQuery($id: ID!) {
    qualityAgreementVersion(id: $id) {
      ${docStylePayloadFields},
        supplierVersion {
          id
          title
          releasedAt
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
          }
        }
    },
  }
`;

export function useVersion(id: string) {
  return useQuery(versionQuery, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

export const SUPPLIER_OPTIONS_QUERY = gql`
  query SupplierOptionsQuery {
    suppliers {
      id
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        versionIdentifier
        currentStatusName
      }
    }
  }
`;

export const SET_SUPPLIER_ID = gql`
  mutation SetSupplierId($id: ID!, $supplierId: ID) {
    updateQualityAgreement(id: $id, supplierVersionId: $supplierId) {
      qualityAgreement {
        id
      }
    }
  }
`;
