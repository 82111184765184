import React from 'react';

import { useMutation } from 'react-apollo';
import { Notes } from '@material-ui/icons';

import { CollapsableTextArea, LabelText, ToggleRichTextEditor, Spacer } from 'components';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import { Views } from 'workspaces';

import { UPDATE_VERIFICATION_AND_VALIDATION_PLAN_VERSION } from '../../gql';

interface Props {
  fields: {
    acceptanceCriteria: string;
    approach: string;
    deviationManagement: string;
    planSummary: string;
    planToggle: boolean;
    purpose: string;
    responsibilities: string;
    scope: string;
  };
  locked: boolean;
  versionId: string;
  viewType: string;
}

export default function PlanTab(props: Props) {
  const { fields } = props;
  const [updatePlan] = useMutation(UPDATE_VERIFICATION_AND_VALIDATION_PLAN_VERSION, {
    refetchQueries: ['VerificationAndValidationPlanVersionQuery'],
  });
  const isEffectiveView = props.viewType === Views.Effective.id;

  return (
    <>
      {!isEffectiveView && (
        <div style={{ textAlign: 'right' }}>
          <ToggleRichTextEditor
            richTextEditorVisible={fields.planToggle}
            onConfirm={() => {
              const variables = { id: props.versionId, planToggle: !fields.planToggle };
              updatePlan({ variables }).catch((error) => {
                throw new Error(`Failed to toggle input method: ${error.message}`);
              });
            }}
          />
        </div>
      )}
      <Spacer factor={1} />
      {fields.planToggle ? (
        <>
          <LabelText labelText="Plan" Icon={Notes} />
          <Spacer factor={1} />
          <RichTextEditorContentSection
            locked={props.locked}
            initialValue={fields.planSummary}
            fixedToolbarOffset={10}
            versionId={props.versionId}
            attrName="plan_summary"
            refetchQueries={[]}
            optionSet="large"
          />
        </>
      ) : (
        <>
          <CollapsableTextArea
            attrName="purpose"
            id="v-and-v-plan-purpose"
            initialValue={fields.purpose}
            labelText="Purpose"
            locked={props.locked}
            refetchQueries={['VerificationAndValidationPlanVersionQuery']}
            versionId={props.versionId}
            useInEffectiveView={isEffectiveView}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="scope"
            id="v-and-v-plan-scope"
            initialValue={fields.scope}
            labelText="Scope"
            locked={props.locked}
            refetchQueries={['VerificationAndValidationPlanVersionQuery']}
            versionId={props.versionId}
            useInEffectiveView={isEffectiveView}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="responsibilities"
            id="v-and-v-plan-responsibilites"
            initialValue={fields.responsibilities}
            labelText="Responsibilities"
            locked={props.locked}
            refetchQueries={['VerificationAndValidationPlanVersionQuery']}
            versionId={props.versionId}
            useInEffectiveView={isEffectiveView}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="approach"
            id="v-and-v-plan-approach"
            initialValue={fields.approach}
            labelText="Approach"
            locked={props.locked}
            refetchQueries={['VerificationAndValidationPlanVersionQuery']}
            versionId={props.versionId}
            useInEffectiveView={isEffectiveView}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="acceptance_criteria"
            id="v-and-v-plan-acceptanceCriteria"
            initialValue={fields.acceptanceCriteria}
            labelText="Acceptance Criteria"
            locked={props.locked}
            refetchQueries={['VerificationAndValidationPlanVersionQuery']}
            versionId={props.versionId}
            useInEffectiveView={isEffectiveView}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="deviation_management"
            id="v-and-v-plan-deviationManagement"
            initialValue={fields.deviationManagement}
            labelText="Deviation Management"
            locked={props.locked}
            refetchQueries={['VerificationAndValidationPlanVersionQuery']}
            versionId={props.versionId}
            useInEffectiveView={isEffectiveView}
            rows={6}
          />
        </>
      )}
    </>
  );
}
