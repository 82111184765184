import gql from 'graphql-tag';

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      errors
      profile {
        id
        title
        phoneNumber
        secondaryPhoneNumber
        photoUrl
        emailAddress
      }
    }
  }
`;
