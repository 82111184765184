import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardContainer: {
      width: 250,
    },
    card: {
      height: 122,
      padding: theme.spacing(0, 2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0px 2px 5px 0px rgba(139, 139, 139, 0.25)',
      backgroundColor: theme.palette.common.white,
    },
    title: {
      color: theme.palette.blueDark,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    count: {
      color: theme.palette.subHeading,
    },
  }),
);
