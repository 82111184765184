import React from 'react';
import flowRight from 'lodash/flowRight';
import { Trans } from '@lingui/macro';
import LinearScale from '@material-ui/icons/LinearScale';

import { Close } from '@material-ui/icons';
import { Modal, Paper, Typography, withStyles } from '@material-ui/core';

import WorkflowIcon from 'components/WorkflowIcon';
import WorkflowMenu, { CancelMenuItem, RetireMenuItem, VoidMenuItem } from 'components/WorkflowMenu';
import { ContentSection } from 'components';
import WithCurrentUser from 'compositions/WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import withMetadataFromServer from 'compositions/WithMetadataFromServer';
import { GenericWorkflowStatuses, indexById } from 'workflows/statuses';

import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';
import { SummaryOfChange } from 'compositions/TimelineGroup/steps/views';
import ConnectBar from 'compositions/TimelineGroup/components/ConnectBar';
import PreviousReleases from 'compositions/TimelineGroup/components/PreviousReleases';
import PermissionFailModal from 'compositions/TimelineGroup/components/PermissionFailModal';
import { AlertDialog, AlertDialogWithUser } from 'components';
import styles from 'compositions/TimelineGroup/components/sharedStyles';

import rfcContent from './rfcContent';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

const { NotCreated, Draft, UnderReview, OwnerApproval, ApprovedDraft, Rejected, Retired } = GenericWorkflowStatuses;

const GenericWorkflowStatusesById = indexById(GenericWorkflowStatuses);

class GenericTimeline extends React.Component {
  state = {
    previousVersionModal: false,
    permissionFailModal: false,
    deniedModal: {
      open: false,
      heading: null,
      body: null,
    },
    deniedModalWithUser: {
      open: false,
      user: null,
      role: null,
    },
    releaseNotPermittedModal: false,
    itemTransitioningLockedModal: false,
    previousVersion: null,
    justificationOfNoChangeOrder: null,
    vvrAssociationsReleasedModal: false,
  };

  constructor(props) {
    super(props);
    this.permissions = props.permissions;
  }

  openModal = (modalName) => {
    this.setState({ [modalName]: true });
  };

  closeModal = () => {
    this.setState({
      permissionFailModal: false,
      deniedModal: {
        open: false,
        heading: null,
        body: null,
      },
      releaseNotPermittedModal: false,
      itemTransitioningLockedModal: false,
      previousVersionModal: false,
    });
  };

  closeModalWithUser = () => {
    this.setState({
      deniedModalWithUser: {
        open: false,
        user: null,
        role: null,
      },
      releaseNotPermittedModal: false,
      itemTransitioningLockedModal: false,
      previousVersionModal: false,
    });
  };

  handleSmallBlueCircleClick = () => {
    const status = this.props.currentVersion.currentStatusName;
    const currentWorkflowStatus = GenericWorkflowStatusesById[status];

    if (currentWorkflowStatus.isEqualTo(NotCreated)) {
      return this.props.handleInitTransition('createNewVersion');
    } else if (
      currentWorkflowStatus.isEqualTo(Draft) ||
      (currentWorkflowStatus.isEqualTo(UnderReview) && this.permissions.canOwn)
    ) {
      return this.props.handleInitTransition('reasonForChange');
    } else return this.props.handleInitTransition('summaryOfChange');
  };

  handleVoidClick = () => {
    const status = this.props.currentVersion.currentStatusName;
    const currentWorkflowStatus = GenericWorkflowStatusesById[status];

    if (currentWorkflowStatus.isEqualTo(OwnerApproval)) {
      this.props.handleInitTransition('voidOwnerApproval');
    } else if (currentWorkflowStatus.isEqualTo(ApprovedDraft)) {
      this.props.handleInitTransition('voidApprovedDraft');
    }
  };

  handleNullifyRenderTransitionDeniedModal = (desiredStateName) => {
    this.setState({
      deniedModal: {
        open: true,
        heading: 'Pending Trace Matrix',
        body: `Remove this version from any traces on the pending Trace Matrix before transitioning to ${desiredStateName}`,
      },
    });
  };

  handleUnresolvedSuggestionsDeniedModal = () => {
    this.setState({
      deniedModal: {
        open: true,
        heading: 'Pending Suggestions in your document',
        body: `There are tracked changes that need to be resolved before this status transition can take place.`,
      },
    });
  };

  handleReleaseRenderTransitionDeniedModal = () => {
    this.setState({
      deniedModal: {
        open: true,
        heading: 'Pending Trace Matrix',
        body:
          'Remove the previous version from any traces on the pending Trace Matrix before releasing this new version',
      },
    });
  };

  handleInactiveUserDeniedModal = (inactiveUsers) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: inactiveUsers[0].user,
        role: inactiveUsers[0].role,
      },
    });
  };

  handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    this.setState({
      deniedModalWithUser: {
        open: true,
        user: usersWithoutActiveRole[0].user,
        role: usersWithoutActiveRole[0].role,
      },
    });
  };

  workflowMenu = () => {
    const { currentCompanyAllowsTransitioning, currentUser, currentVersion } = this.props;
    const currentWorkflowStatus = GenericWorkflowStatusesById[currentVersion.currentStatusName];
    const { canOwn } = this.permissions;

    if (!currentCompanyAllowsTransitioning) return;

    const isCancelable = (status) => canOwn && (status.isEqualTo(Draft) || status.isEqualTo(UnderReview));

    const { signingApprover, signingOwner } = currentVersion;

    const isVoidable = (status) =>
      (signingOwner && signingOwner.id === currentUser.id && currentWorkflowStatus.isEqualTo(OwnerApproval)) ||
      (signingApprover && signingApprover.id === currentUser.id && currentWorkflowStatus.isEqualTo(ApprovedDraft));

    const isRetirable = (status) => canOwn && status.isEqualTo(Draft);

    return (
      <WorkflowMenu>
        <CancelMenuItem
          disabled={!isCancelable(currentWorkflowStatus)}
          onClick={() => {
            if (!currentVersion.mayTransitionToCanceled) {
              return this.handleNullifyRenderTransitionDeniedModal('Canceled');
            }
            this.props.handleInitTransition('cancel');
          }}
        />
        <VoidMenuItem disabled={!isVoidable(currentWorkflowStatus)} onClick={this.handleVoidClick} />
        <RetireMenuItem
          disabled={!isRetirable(currentWorkflowStatus)}
          onClick={() => {
            if (!currentVersion.mayTransitionToRetired) {
              return this.handleNullifyRenderTransitionDeniedModal('Retired');
            }
            this.props.handleInitTransition('initiateRetirement');
          }}
        />
      </WorkflowMenu>
    );
  };

  render() {
    const {
      classes,
      currentCompanyAllowsTransitioning,
      currentRelease,
      currentVersion,
      previousVersions,
      handleInitTransition,
    } = this.props;

    const {
      previousVersionModal,
      permissionFailModal,
      releaseNotPermittedModal,
      itemTransitioningLockedModal,
    } = this.state;

    const {
      currentStatusName: status,
      versionIdentifier,
      linkedChangeCustomIdentifier,
      linkedChangeJustificationText,
      locked,
      unresolvedSuggestions,
      assignedApproverJobRole,
      assignedOwnerJobRole,
    } = currentVersion;

    const currentWorkflowStatus = GenericWorkflowStatusesById[status];
    if (!currentWorkflowStatus) throw new Error(`Invalid status for Generic Workflow: ${status}`);

    const hasChangeOrder = Boolean(linkedChangeCustomIdentifier) && !Boolean(linkedChangeJustificationText);
    const inactiveUsers = getInactiveUsers(currentVersion.owner, currentVersion.approver);
    const usersWithoutActiveRole = getUsersWithoutActiveRole(
      currentVersion.owner,
      currentVersion.approver,
      currentVersion.assignedOwnerJobRole,
      currentVersion.assignedApproverJobRole,
    );

    return (
      <ContentSection scrollX LabelText={<Trans>Workflow</Trans>} Icon={<LinearScale />} Controls={this.workflowMenu()}>
        <div className={classes.timelineContainer}>
          <PreviousReleases
            generateSmallCircleContent={rfcContent}
            releasedVersions={this.props.previousVersions}
            onClick={(v) =>
              this.setState({
                previousVersionModal: true,
                versionForModal: v,
              })
            }
          />

          {!currentWorkflowStatus.isEqualTo(Retired) && (
            <SmallCircle
              content={rfcContent(currentVersion)}
              color="draft"
              handleClick={this.handleSmallBlueCircleClick}
              id={currentWorkflowStatus.isEqualTo(NotCreated) && 'create-new-rev'}
            />
          )}

          {currentWorkflowStatus.isAfter(NotCreated) && !currentWorkflowStatus.isEqualTo(Retired) && (
            <>
              <ConnectBar color="draft" short visible />

              <WorkflowIcon
                type="draft"
                active={currentWorkflowStatus.isEqualTo(Draft)}
                completed={currentWorkflowStatus.isAfter(Draft)}
                version={versionIdentifier}
                label="Draft"
                size="large"
                visible
              />

              <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

              <SmallCircle
                id="co-small"
                content={hasChangeOrder ? 'CO' : linkedChangeJustificationText ? 'JST' : '?'}
                color="underReview"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');

                  if (!hasChangeOrder && !locked && !linkedChangeJustificationText)
                    return handleInitTransition('ownerApprovalWithoutSig');

                  return handleInitTransition('associatedChange');
                }}
                visible
              />

              <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

              <WorkflowIcon
                type="underReview"
                active={currentWorkflowStatus.isEqualTo(UnderReview)}
                completed={currentWorkflowStatus.isAfter(UnderReview)}
                version={versionIdentifier}
                label="Under Review"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');
                  if (inactiveUsers.length) return this.handleInactiveUserDeniedModal(inactiveUsers);
                  if (usersWithoutActiveRole.length)
                    return this.handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);
                  if (currentWorkflowStatus.isBefore(UnderReview) && currentCompanyAllowsTransitioning)
                    return handleInitTransition('underReview');
                }}
                visible
              />

              <ConnectBar
                color="ownerApproval"
                disabled={!currentCompanyAllowsTransitioning || !this.permissions.canOwn}
                incomplete
                visible
              />

              <WorkflowIcon
                type="ownerApproval"
                active={currentWorkflowStatus.isEqualTo(OwnerApproval)}
                completed={currentWorkflowStatus.isAfter(OwnerApproval)}
                version={versionIdentifier}
                label="Owner Approval"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                disabled={!this.permissions.canOwn}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');
                  if (unresolvedSuggestions) {
                    return this.handleUnresolvedSuggestionsDeniedModal();
                  }
                  if (
                    currentVersion.item.__typename === 'VerificationAndValidationReport' &&
                    !currentVersion.mayTransitionToOwnerApproval &&
                    currentWorkflowStatus.isEqualTo(UnderReview)
                  ) {
                    return handleInitTransition('ownerApproval');
                  } else if (currentWorkflowStatus.isEqualTo(UnderReview) && this.permissions.canOwn) {
                    return handleInitTransition('ownerApproval');
                  }

                  if (currentWorkflowStatus.isEqualTo(UnderReview) && !this.permissions.canOwn)
                    return this.openModal('permissionFailModal');

                  return null;
                }}
                visible
              />

              <ConnectBar
                color="rejected"
                disabled={!currentCompanyAllowsTransitioning}
                visible={currentWorkflowStatus.isEqualTo(Rejected)}
              />

              <WorkflowIcon
                type="rejected"
                active
                version={versionIdentifier}
                label="Rejected"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');

                  return handleInitTransition('resolveRejection');
                }}
                visible={currentWorkflowStatus.isEqualTo(Rejected)}
              />

              <ConnectBar
                color="approvedDraft"
                disabled={
                  currentWorkflowStatus.isBefore(OwnerApproval) ||
                  !currentCompanyAllowsTransitioning ||
                  !this.permissions.canApprove
                }
                visible={
                  !currentWorkflowStatus.isEqualTo(Rejected) &&
                  (hasChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval))
                }
              />

              <WorkflowIcon
                type="approvedDraft"
                active={currentWorkflowStatus.isEqualTo(ApprovedDraft)}
                completed={currentWorkflowStatus.isAfter(ApprovedDraft)}
                disabled={currentWorkflowStatus.isBefore(OwnerApproval) || !this.permissions.canApprove}
                version={versionIdentifier}
                label="Approved Draft"
                size="large"
                handleClick={() => {
                  if (!this.permissions.canApprove) return this.openModal('permissionFailModal');

                  if (currentWorkflowStatus.isEqualTo(OwnerApproval)) return handleInitTransition('approveOrReject');
                }}
                visible={
                  !currentWorkflowStatus.isEqualTo(Rejected) &&
                  (hasChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval))
                }
              />

              <ConnectBar
                color="released"
                disabled={!currentCompanyAllowsTransitioning || !this.permissions.canApprove}
                visible={!currentWorkflowStatus.isEqualTo(Rejected)}
              />

              <WorkflowIcon
                type="released"
                version={versionIdentifier}
                label="Release"
                size="large"
                disabledEffect={!currentCompanyAllowsTransitioning}
                handleClick={() => {
                  if (currentWorkflowStatus.isEqualTo(ApprovedDraft)) return this.openModal('releaseNotPermittedModal');

                  if (currentWorkflowStatus.isEqualTo(OwnerApproval) && !hasChangeOrder) {
                    if (!this.permissions.canApprove) {
                      return this.openModal('permissionFailModal');
                    } else if (currentRelease && !currentRelease.mayTransitionToObsolete) {
                      this.handleReleaseRenderTransitionDeniedModal();
                    } else {
                      return handleInitTransition('releaseOrReject');
                    }
                  }

                  if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');
                }}
                disabled={!this.permissions.canApprove}
                visible={!currentWorkflowStatus.isEqualTo(Rejected)}
              />
            </>
          )}

          {previousVersions && (
            <Modal open={previousVersionModal} onClose={this.closeModal} className={classes.infoModal}>
              <Paper className={classes.modalInside}>
                <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={this.closeModal} color="action" />
                <SummaryOfChange currentVersion={this.state.versionForModal} displayChangeInfo={true} />
              </Paper>
            </Modal>
          )}

          <PermissionFailModal
            open={permissionFailModal || releaseNotPermittedModal || itemTransitioningLockedModal}
            failType={permissionFailModal ? 'permissions' : releaseNotPermittedModal ? 'release' : 'itemTransitioning'}
            closeModal={this.closeModal}
          />

          <AlertDialog
            open={this.state.deniedModal.open}
            onClose={() => this.closeModal()}
            titleText={this.state.deniedModal.heading}
          >
            <Typography variant="body2">{this.state.deniedModal.body}</Typography>
          </AlertDialog>
          <AlertDialogWithUser
            open={this.state.deniedModalWithUser.open}
            titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
            onClose={() => this.closeModalWithUser()}
            subtitle={
              inactiveUsers.length
                ? 'The following user has been deactivated.'
                : "The following user's role has been removed."
            }
            user={this.state.deniedModalWithUser.user}
            role={this.state.deniedModalWithUser.role === 'owner' ? assignedOwnerJobRole : assignedApproverJobRole}
            body={`Please use the People Menu to reassign the ${
              this.state.deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
            } role to a new user.`}
          />
        </div>
      </ContentSection>
    );
  }
}

export default flowRight([WithCurrentUser, withStyles(styles), withMetadataFromServer(), withCurrentCompany])(
  GenericTimeline,
);
