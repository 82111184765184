import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';

import { Checkbox, ClickAwayListener, Fab, Input, Typography, withStyles } from '@material-ui/core';

import { DEBOUNCE_MILLISECONDS } from '../../constants';
import styles from './styles';

interface LongCardHeaderProps {
  classes: any;
  onTitleChange?: (e: any) => void;
  onCheckboxClick?: () => void;
  hideCheckbox?: boolean;
  titleEditable?: boolean;
  helpTextEntries?: [
    {
      label: string;
      text: string;
    }
  ];
  handleTitlePrefix?: (e: any) => void;
  primaryBodyText?: {
    attrName: string;
    attrValue: string;
  };
  title: string;
  customIdentifier?: string;
  titleDisplayVariant?: 'titlePrefix' | 'newLine';
  displayAsFolder: boolean;
  Controls?: JSX.Element;
  globalId?: string;
}

class LongCardHeader extends Component<LongCardHeaderProps, any> {
  state = {
    displayHelpText: true,
    showHelpTextOnDefault: false,
    titleEditing: false,
    editedTitle: null,
    showControls: false,
  };

  handleCheckboxClick = (e) => {
    e.stopPropagation();
    this.setState({
      showControls: !this.state.showControls,
    });
    if (this.props.onCheckboxClick) this.props.onCheckboxClick();
  };

  handleHelpTextBtnClick = () => {
    this.setState({
      displayHelpText: !this.state.displayHelpText,
      showHelpTextOnDefault: !this.state.showHelpTextOnDefault,
    });
  };

  render() {
    const { displayHelpText, editedTitle, showHelpTextOnDefault } = this.state;
    const {
      classes,
      helpTextEntries,
      onTitleChange,
      hideCheckbox = false,
      primaryBodyText,
      title,
      globalId,
      customIdentifier,
      titleDisplayVariant = 'titlePrefix',
      titleEditable = true,
      displayAsFolder = false,
    } = this.props;

    const primaryBodyTextAttr = get(primaryBodyText, 'attrName');
    const primaryBodyTextVal = get(primaryBodyText, 'attrValue');

    const titleInitialValue = title ? title : 'Add Title';

    const onTitleChangeDebounced =
      onTitleChange &&
      debounce((val) => {
        this.setState({ editedTitle: val }, () => {
          onTitleChange(val);
        });
      }, DEBOUNCE_MILLISECONDS);

    const primaryBodyClassName = primaryBodyTextVal ? classes.horizontalPanelHeader : '';
    const helpTextClassName = helpTextEntries || displayAsFolder ? classes.verticalPanelHeader : '';
    return (
      <div className={`${primaryBodyClassName} ${helpTextClassName}`}>
        <Typography
          component="div"
          variant="body2"
          className={classes.title}
          style={{
            width: this.state.titleEditing ? '90%' : undefined,
          }}
        >
          {!hideCheckbox ? (
            <Checkbox
              className={classes.checkbox}
              classes={{ root: classes.checkboxRoot }}
              color="primary"
              onClick={(e) => this.handleCheckboxClick(e)}
            />
          ) : (
            <div style={{ paddingLeft: 40 }} />
          )}
          {customIdentifier && titleDisplayVariant === 'titlePrefix' && (
            <span
              className={this.props.handleTitlePrefix ? classes.clickableTitlePrefix : classes.titlePrefix}
              onClick={this.props.handleTitlePrefix}
            >
              {customIdentifier}:
            </span>
          )}

          {this.state.titleEditing ? (
            <ClickAwayListener onClickAway={() => this.setState({ titleEditing: false })}>
              <Input
                draggable
                onDragStart={(e) => e.preventDefault()}
                autoFocus={true}
                style={{
                  width: '80%',
                  color: 'inherit',
                  letterSpacing: 'inherit',
                }}
                disableUnderline={true}
                defaultValue={editedTitle || title}
                onChange={(e) => {
                  e.persist();
                  onTitleChangeDebounced && onTitleChangeDebounced(e.target.value);
                }}
              />
            </ClickAwayListener>
          ) : (
            <span
              onClick={() => {
                onTitleChange && titleEditable && this.setState({ titleEditing: true });
              }}
            >
              {editedTitle ? editedTitle : titleInitialValue}
            </span>
          )}

          {globalId && (
            <Typography className={classes.globalId} variant="caption">
              Global ID: {globalId}
            </Typography>
          )}

          {(helpTextEntries || primaryBodyTextVal) &&
            !(
              primaryBodyTextAttr === 'target' ||
              primaryBodyTextAttr === 'step_text' ||
              primaryBodyTextAttr === 'step_description'
            ) &&
            !this.state.titleEditing && (
              <Fab
                disableRipple
                disableFocusRipple
                classes={{
                  root: `${classes.helpTextBtn}`,
                  label: `${helpTextEntries &&
                    showHelpTextOnDefault &&
                    classes.slashedHelpTextBtn} ${primaryBodyTextVal && displayHelpText && classes.slashedHelpTextBtn}`,
                }}
                onClick={this.handleHelpTextBtnClick}
              >
                ?
              </Fab>
            )}

          {customIdentifier && titleDisplayVariant === 'newLine' && (
            <Typography variant="subtitle2" className={classes.customIdentifier}>
              {customIdentifier}
            </Typography>
          )}
        </Typography>
        {/* TODO: the check on primaryBodyTextAttr is being hardcoded as DSC help
          text and TPP target text share the same prop of 'primaryBodyText'.
          This needs a refactoring in the longrun. */}
        {primaryBodyTextVal &&
          !showHelpTextOnDefault &&
          !(
            primaryBodyTextAttr === 'target' ||
            primaryBodyTextAttr === 'step_text' ||
            primaryBodyTextAttr === 'step_description'
          ) && (
            <>
              <Typography variant="caption" className={classes.helpText}>
                {primaryBodyTextVal}
              </Typography>
            </>
          )}

        {/* {!this.state.titleEditing && this.props.Controls} */}
        {this.state.showControls && this.props.Controls}

        {helpTextEntries && !displayHelpText && (
          <div className={classes.helpTextEntriesWrapper}>
            {helpTextEntries.map((helpTextEntry, i) => {
              return (
                <div key={i} className={classes.helpTextEntry}>
                  <Typography variant="h5" classes={{ root: classes.helpTextLabel }}>
                    {helpTextEntry.label}
                  </Typography>
                  <Typography variant="caption">{helpTextEntry.text}</Typography>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
export default flowRight([withStyles(styles)])(LongCardHeader);
