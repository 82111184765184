import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    ...theme.mixins.gridLayout,
    container: {
      position: 'relative',
      minHeight: 600,
      padding: theme.spacing(2),
    },
    buttonsContainer: {
      position: 'absolute',
      right: 0,
    },
    info: {
      marginBottom: theme.spacing(2),
    },
    applicableText: {
      marginBottom: theme.spacing(2),
    },
    buttonFill: {
      '& .MuiIconButton-root svg': {
        fill: '#3c4858',
      }
    },
  }));
