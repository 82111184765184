import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  currentUserSig: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
  },
  esigContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  esigInput: {
    display: 'flex',
    width: '100%',
    height: 40,
  },
  icon: {
    paddingRight: theme.spacing(0.5),
  },
  signatureBar: {
    position: 'absolute',
    transform: 'rotate(90deg) scaleX(-1)',
    width: '40px',
    marginLeft: theme.spacing(1) * -2,
    marginTop: theme.spacing(4),
    borderRadius: '5px',
  },
  submitBtn: {
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
    height: 40,
  },
  textBoxCaption: {
    display: 'block',
  },
}));
