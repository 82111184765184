import { useMetadata } from 'compositions/WithMetadataFromServer';

function useCurrentUserPermission(itemTypeName: string, permission: string) {
  const { data } = useMetadata(itemTypeName);
  if (!data) return false;

  const perm = data.itemType.permissions.find(
    (val) => val.permissionType === permission,
  );
  if (!perm) return false;
  return perm.currentUserIsPermitted;
}

export default useCurrentUserPermission;
