import {
  ownerApprovalWithoutSigSteps,
  ownerApprovalFinalResult,
} from 'workflows/GenericWorkflow/steps/genericWorkflowTransitionsUtil';

export default {
  initialStep: 'coRequired',
  internalSteps: {
    ...ownerApprovalWithoutSigSteps,
  },
  getFinalResult: ownerApprovalFinalResult,
};
