import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { Mutation } from 'react-apollo';

import { ClickAwayListener, Grid, Typography, withStyles, createStyles } from '@material-ui/core';

import { TextAreaField } from '../../components';
import { DEBOUNCE_MILLISECONDS } from '../../constants';
import { DataProxy } from 'apollo-cache';
import { FetchResult } from 'apollo-link';
import mutation from 'utils/gql/updateTextArea';

const styles = createStyles((theme) => ({
  panelContent: {
    width: '100%',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px 0 ${theme.spacing(5)}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  responseText: {
    minHeight: 40,
    whiteSpace: 'pre-line',
    cursor: 'text',
  },
  textArea: {
    marginTop: theme.spacing(1),
    minHeight: 36,
  },
  textField: {
    paddingBottom: theme.spacing(1.5),
  },
  loadingCursor: {
    cursor: 'wait',
  },
  notAllowedCursor: {
    cursor: 'not-allowed',
  },
}));

interface VerticalBodyProps {
  initialValue: string;
  attrName: string;
  classes: any;
  locked: boolean;
  versionId: string;
  queryToRefetch?: any;
  onMutationUpdate?: (cache: DataProxy, mutationResult: FetchResult) => void;
  Label?: JSX.Element;
}

class VerticalBody extends Component<VerticalBodyProps, any> {
  state = {
    showTextAreaField: false,
    responseTextInput: null,
  };

  showTextField = () => {
    this.setState({
      showTextAreaField: true,
    });
  };

  handleClickAwayTextField = () => {
    this.setState({
      showTextAreaField: false,
    });
  };

  render() {
    const { showTextAreaField } = this.state;

    const { classes, versionId, initialValue, attrName, queryToRefetch, onMutationUpdate, locked, Label } = this.props;

    return (
      <>
        <Grid item className={classes.panelContent}>
          {Label && Label}

          <Mutation
            mutation={mutation}
            update={onMutationUpdate}
            refetchQueries={queryToRefetch ? [queryToRefetch] : undefined}
            awaitRefetchQueries={Boolean(queryToRefetch)}
          >
            {(mutate, { loading, error }) => {
              if (error) throw new error(`Error updating textarea field: ${error}`);

              return (
                <div
                  className={`${classes.responseText} ${loading && classes.loadingCursor} ${locked &&
                    classes.notAllowedCursor}`}
                  onClick={!loading && !locked ? this.showTextField : undefined}
                >
                  {!showTextAreaField ? (
                    <Typography variant="body2">
                      {isNil(this.state.responseTextInput) ? initialValue : this.state.responseTextInput}
                    </Typography>
                  ) : (
                    <ClickAwayListener onClickAway={this.handleClickAwayTextField}>
                      <TextAreaField
                        id="long-card-text-area-field"
                        className={classes.textArea}
                        inputClasses={classes.textField}
                        autoComplete="off"
                        autoFocus={showTextAreaField}
                        multiline
                        versionId={versionId}
                        locked={locked}
                        initialValue={initialValue}
                        attrName={attrName}
                        onTextChange={debounce((textValue) => {
                          this.setState({ responseTextInput: textValue });
                          mutate({
                            variables: {
                              input: {
                                attrName,
                                textValue,
                                versionId,
                              },
                            },
                          });
                        }, DEBOUNCE_MILLISECONDS)}
                      />
                    </ClickAwayListener>
                  )}
                </div>
              );
            }}
          </Mutation>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(VerticalBody);
