import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

import { IconButton, Grid, Tooltip, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { ItemTitle, Loading, NoContentDisclaimer } from 'components';

import DataTable from 'components/DataTable';
import Page from 'components/core/Page';

import AddHarmDialog from './AddHarmDialog';

import { LIST_HARMS } from './gql';
import { useStyles } from './styles';

const dataTableColumns = [
  {
    title: 'Examples of Harms',
    field: 'name',
  },
];

function Harms(props) {
  const [harms, setHarms] = useState([]);
  const [showAddHarm, setShowAddHarm] = useState(false);
  const classes = useStyles();

  const { data, loading, error } = useQuery(LIST_HARMS);

  useEffect(() => {
    if (data) {
      setHarms(data.harms);
    }
  }, [data]);

  if (error) throw new Error(`Error loading harms: ${error.message}`);
  if (loading) return <Loading />;

  return (
    <Page>
      <Grid item xs={11}>
        <Typography variant="body2">Risk Mangement</Typography>
        <ItemTitle itemTitle="Harms Library" editable={false} />
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Add Harm">
          <IconButton color="secondary" size="small" onClick={() => setShowAddHarm(true)}>
            <AddCircleIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        {!harms.length ? (
          <NoContentDisclaimer variant="add" className={classes.noContent}>
            {({ icon }) => <>There's nothing here yet. Click {icon()} to add new Harm.</>}
          </NoContentDisclaimer>
        ) : (
          <DataTable columns={dataTableColumns} data={harms} />
        )}
      </Grid>
      <AddHarmDialog
        onAdd={(harm) => {
          setShowAddHarm(false);
          setHarms([...harms, harm]);
        }}
        onCancel={() => setShowAddHarm(false)}
        open={showAddHarm}
      />
    </Page>
  );
}

export default Harms;
