import React, { useState, useContext, useEffect } from 'react';

import get from 'lodash/get';

import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';

import { Loading, Tab, TabContainer, Spacer, CollapsableTextArea, PDFData } from 'components';

import { LockedContext, withLockedContext } from 'contexts/lockedContext';
import { PayloadFieldsProps } from 'itemTypes';
import { ItemSearch, DocumentBuilder } from 'compositions';
import { useToggleablePopover } from 'hooks/useToggleablePopover';
import RenderTab from './RenderTab';

import { useStyles } from './styles';

import { useAddDeviceMasterRecordSubgroupVersions, useDeviceMasterRecordVersion } from './gql';

let PayloadFields = (props: PayloadFieldsProps) => {
  const { data, loading, error, refetch } = useDeviceMasterRecordVersion(props.versionId!);

  const { setLocked } = useContext(LockedContext);
  const classes = useStyles();

  useEffect(() => {
    setLocked(get(data, 'deviceMasterRecordVersion.locked'));
  }, [data]); // eslint-disable-line

  const [showInfo, setShowInfo] = useState(false);
  const toggleInfo = () => setShowInfo((x) => !x);
  const [activeGroupId, setActiveGroupId] = useState(0);
  const [searchOpen, searchAnchor, toggleSearch] = useToggleablePopover();
  const [addDeviceMasterRecordSubgroupVersions] = useAddDeviceMasterRecordSubgroupVersions();
  const [subgroup, setSubgroup] = useState(null as any);

  const openSearch = (target, subgroup) => {
    setSubgroup(subgroup);
    toggleSearch(target);
  };

  if (loading) return <Loading />;
  if (error) throw new Error(`Error loading DeviceMasterRecordVersion ${props.versionId}: ${error.message}`);

  const { deviceMasterRecordVersion } = data;
  const versionData = deviceMasterRecordVersion;
  const { groups, locked } = versionData;

  const currentTab = groups.find((x) => x.id === activeGroupId) || groups[0];

  if (versionData.inputMethod === 'BODY')
    return (
      <DocumentBuilder
        itemType="deviceMasterRecord"
        refetchQueries={['DeviceMasterRecordVersionQuery']}
        richTextOptionSet="large"
        layoutClasses={props.layoutClasses}
        versionData={versionData}
      />
    );

  if (versionData.inputMethod === 'UPLOAD')
    return <PDFData versionData={versionData} layoutClasses={props.layoutClasses} refetch={refetch} />;

  return (
    <>
      <Grid item xs={8} className={classes.nextRow}>
        <ItemSearch
          draftOnly={false}
          supportsChangeOrder={false}
          defaultSearchTerm={null}
          open={searchOpen}
          closeSelf={() => toggleSearch(null)}
          onSubmit={(versionIds) =>
            addDeviceMasterRecordSubgroupVersions({
              variables: {
                id: subgroup.id,
                versionIds,
              },
            })
              .then(() => true)
              .catch(() => false)
          }
          anchorEl={searchAnchor}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'auto',
            overflow: 'visible',
          }}
        >
          <Paper elevation={0} className={classes.paper}>
            <CollapsableTextArea
              labelText="Description"
              placeholder="Enter description"
              initialValue={versionData.description}
              locked={locked}
              startExpanded={true}
              versionId={versionData.id}
              attrName="description"
              refetchQueries={['deviceMasterRecordsQuery']}
              id="dmr-description"
            />
          </Paper>
        </div>
      </Grid>

      {currentTab && (
        <>
          <Spacer factor={1} />
          <Grid item xs={12} className={classes.nextRow}>
            <TabContainer
              layoutClasses={{ grid: '' }}
              tabs={groups.map((x) => (
                <Tab
                  key={x.id}
                  icon={null}
                  active={currentTab.id === x.id}
                  onClick={() => setActiveGroupId(x.id)}
                  text={x.name}
                  className={classes.tab}
                />
              ))}
            >
              <RenderTab
                group={currentTab}
                openSearch={openSearch}
                showInfo={showInfo}
                toggleInfo={toggleInfo}
                locked={locked}
              />
            </TabContainer>
          </Grid>
        </>
      )}
    </>
  );
};
PayloadFields = withLockedContext(PayloadFields);

export default PayloadFields;
