import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import More from '@material-ui/icons/More';

import { listFragment, docMetadata } from '../mixins/Document';
import slugs from '../../displaySlugs';

const itemType = 'miscellaneousDocument';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Miscellaneous Document`,
  categoryLabel: t`Misc. Documents`,
  icon: More,
  listViewQuery: gql`
    query miscellaneousDocumentsQuery {
      documents(itemType: "miscellaneous_document") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
