import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const query = gql`
  query loadOptionsQuery {
    probabilityLevels {
      id
      color
      name
      level
    }
    severityLevels {
      id
      color
      name
      level
    }
    harms {
      id
      name
    }
    hazards {
      id
      name
    }
  }
`;

export function useOptions() {
  return useQuery(query, {
    fetchPolicy: 'no-cache',
  });
}
