import gql from 'graphql-tag';
import { flowRight, get } from 'lodash';
import React from 'react';
import { Query } from 'react-apollo';

import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import { Loading } from 'components';

const AUDIT_TRAIL_QUERY = gql`
  query GetAuditTrail($itemId: ID!) {
    item(id: $itemId) {
      customIdentifier
      auditTrail {
        userFullName
        versionIdentifier
        timestamp
        recordOrigin
        linkedChangeCustomIdentifier
        statusTransition {
          reasonForChange
          fromStatus
          toStatus
          reasonForSignature
          reasonForRevocation
          reasonForSupplierRejection
          parentSetTransition {
            customIdentifier
            versionIdentifier
            fromStatus
            toStatus
          }
        }
        parentSetChange {
          customIdentifier
          versionIdentifier
          action
        }
      }
    }
  }
`;

const withAuditTrail = (WrappedComponent) => (componentProps) => {
  const { itemId, currentCompanyProfessionalLicense } = componentProps;

  if (currentCompanyProfessionalLicense) {
    return (
      <Query fetchPolicy="no-cache" errorPolicy="all" query={AUDIT_TRAIL_QUERY} variables={{ itemId }}>
        {({ data, loading, error }) => {
          if (error) throw new Error(`Failed to fetch Audit Trail data: ${error.message}`);
          if (loading) return <Loading />;
          const auditTrail = get(data, 'item.auditTrail');
          const customIdentifier = get(data, 'item.customIdentifier');
          return <WrappedComponent {...componentProps} auditTrail={auditTrail} customIdentifier={customIdentifier} />;
        }}
      </Query>
    );
  }

  return <WrappedComponent {...componentProps} auditTrail={[]} />;
};

export default flowRight([withCurrentCompany, withAuditTrail]);
