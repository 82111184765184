export default (theme) => ({
  searchInput: {
    ...theme.typography.h3, // sizes pulled in from theme so they can be applied to input directly
    marginBottom: theme.spacing(3),
    '&::after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  noResults: {
    paddingBottom: theme.spacing(1),
  },
});
