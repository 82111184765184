import React, { useCallback, useState } from 'react';

import { BugReport, CardTravel, Filter1, FormatAlignJustify } from '@material-ui/icons';

import { Tab, TabContainer, TabIcon } from 'components';
import { ReportTab } from './ReportTab';
import { TestingSummaryTab } from './TestingSummaryTab';
import { AdditionalMaterialsTab } from './AdditionalMaterialsTab';
import { DeviationsTab } from './DeviationsTab';
import { VerificationAndValidationReportVersionQuery_verificationAndValidationReportVersion } from '../__generated__/VerificationAndValidationReportVersionQuery';
import { VVPlan } from '../PayloadFields';
import { ApolloQueryResult } from 'apollo-client';

interface VerificationAndValidationReportTabsProps {
  currentVersion: VerificationAndValidationReportVersionQuery_verificationAndValidationReportVersion;
  layoutClasses: { [className: string]: string };
  vvPlan: VVPlan;
  updateVersion: (variables?: Record<string, any>) => Promise<ApolloQueryResult<any>>;
  viewType: string;
}

enum VVReportTabs {
  Report = 'Report',
  TestingSummary = 'Testing Summary',
  Deviations = 'Deviations',
  References = 'References',
}

const VerificationAndValidationReportTabs = (props: VerificationAndValidationReportTabsProps) => {
  const { layoutClasses, vvPlan, currentVersion, updateVersion } = props;

  const vvReportTabsContent = {
    [VVReportTabs.Report]: (
      <ReportTab
        fields={{
          scope: currentVersion.scope,
          purpose: currentVersion.purpose,
          intendedUse: currentVersion.intendedUse,
          systemDescription: currentVersion.systemDescription,
          summaryOfResults: currentVersion.summaryOfResults,
          conclusion: currentVersion.conclusion,
          reportSummary: currentVersion.reportSummary,
        }}
        versionId={currentVersion.id}
        richTextToggled={currentVersion.reportToggle}
      />
    ),
    [VVReportTabs.TestingSummary]: <TestingSummaryTab currentVersion={currentVersion} vvPlan={vvPlan} />,
    [VVReportTabs.Deviations]: <DeviationsTab currentVersion={currentVersion} />,
    [VVReportTabs.References]: <AdditionalMaterialsTab viewType={props.viewType} currentVersion={currentVersion} updateVersion={updateVersion} />,
  };

  const [currentTab, setCurrentTab] = useState(VVReportTabs.Report);

  const handleTabClick = useCallback((currentTabName: VVReportTabs) => () => setCurrentTab(currentTabName), []);

  return (
    <TabContainer
      layoutClasses={layoutClasses}
      tabs={
        <>
          <Tab
            icon={<TabIcon Icon={FormatAlignJustify} />}
            active={VVReportTabs.Report === currentTab}
            onClick={handleTabClick(VVReportTabs.Report)}
            text={VVReportTabs.Report}
          />
          <Tab
            icon={<TabIcon Icon={CardTravel} />}
            active={VVReportTabs.TestingSummary === currentTab}
            onClick={handleTabClick(VVReportTabs.TestingSummary)}
            text={VVReportTabs.TestingSummary}
          />
          <Tab
            icon={<TabIcon Icon={BugReport} />}
            active={VVReportTabs.Deviations === currentTab}
            onClick={handleTabClick(VVReportTabs.Deviations)}
            text={VVReportTabs.Deviations}
          />
          <Tab
            icon={<TabIcon Icon={Filter1} />}
            active={VVReportTabs.References === currentTab}
            onClick={handleTabClick(VVReportTabs.References)}
            text={VVReportTabs.References}
          />
        </>
      }
    >
      {vvReportTabsContent[currentTab]}
    </TabContainer>
  );
};

export { VerificationAndValidationReportTabs };
