import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import posed from 'react-pose';
import { compose, setDisplayName } from 'recompose';
import * as Yup from 'yup';

import { withI18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';

import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Typography,
  withStyles,
} from '@material-ui/core';

import Person from '@material-ui/icons/Person';
import Lock from '@material-ui/icons/Lock';

import { Link, navigate } from '@reach/router';

import LOGO from 'assets/img/nemedio_vert.svg';

import { Logo, Spacer } from 'components';

import { AuthContext } from 'setup/AuthProvider';

import styles from './styles';
import withCreateAuthToken from './withCreateAuthToken';

import { buildConditionalClasses } from 'utils';

const ContainerWithEntry = posed.div({
  hidden: {
    y: -50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      duration: 300,
    },
  },
});

const LoginValidationSchema = Yup.object().shape({
  username: Yup.string().required(<Trans>Username is required</Trans>),
  password: Yup.string().required(<Trans>Password is required</Trans>),
});

class LoginForm extends Component {
  static contextType = AuthContext;

  static propTypes = {
    classes: PropTypes.shape({
      error: PropTypes.string.isRequired,
      formCard: PropTypes.string.isRequired,
      form: PropTypes.string.isRequired,
      inputAdornment: PropTypes.string.isRequired,
      inputField: PropTypes.string.isRequired,
      loginButton: PropTypes.string.isRequired,
    }).isRequired,
    i18n: PropTypes.shape({
      _: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    mounted: false,
    showUnauthorizedError: false,
    showExpiredPasswordScreen: false,
  };

  componentDidMount() {
    // this toggles the pose after mounting, causing the card to be nicely animated into place.
    if (!this.state.mounted) {
      this.setState({ mounted: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.authToken && this.props.authToken !== prevProps.authToken) {
      this.handleSuccessfulAuth();
    }
  }

  handleSuccessfulAuth = () => {
    window.memoryDB.setItem('token', JSON.stringify(this.props.authToken));
    const { startRenewAuthTokenService, requestedPath } = this.context;
    startRenewAuthTokenService && startRenewAuthTokenService();
    this.props.reloadApp();
    navigate(requestedPath || '/'); // eslint-disable-line @typescript-eslint/no-floating-promises
  };

  onSubmit = (values) => {
    this.props.createAuthToken(values);
  };

  render() {
    const { classes, i18n, logo, attemptsSinceLastAuth, passwordExpired, errorMessage } = this.props;
    const { showUnauthorizedError, showExpiredPasswordScreen } = this.state;

    const incorrectPasswordMessage =
      attemptsSinceLastAuth === 1 ? (
        <Trans>Incorrect password. Your account will be locked for 1 hour after 4 more failed attempts.</Trans>
      ) : attemptsSinceLastAuth === 5 ? (
        <Trans>Your account has been locked. Please try again in 1 hour.</Trans>
      ) : attemptsSinceLastAuth > 0 && attemptsSinceLastAuth < 5 ? (
        `Incorrect password. ${5 - attemptsSinceLastAuth} ${attemptsSinceLastAuth === 4 ? `attempt` : `attempts`} left.`
      ) : errorMessage ? (
        <Trans>The username or password you entered is invalid.</Trans>
      ) : null;

    const validateUsername = (value) => {
      if (value.includes('@')) {
        return `It looks like you're entering your email address, not username. Please enter username above.`;
      }
    };

    return (
      <>
        <ContainerWithEntry pose={this.state.mounted ? 'visible' : 'hidden'}>
          <Card className={classes.formCard}>
            <Logo src={logo} classes={classes} />
            <Formik
              initialValues={{ username: '', password: '' }}
              onSubmit={this.onSubmit}
              validationSchema={LoginValidationSchema}
            >
              {({ isValid, values }) => (
                <Form className={classes.form}>
                  {!showExpiredPasswordScreen ? (
                    <CardContent>
                      <Typography variant="h2">Log In</Typography>
                      <Spacer factor={4} />
                      <Field name="username" validate={validateUsername}>
                        {({ field, form: { errors, touched } }) => {
                          const displayError = errors.username && touched.username;
                          return (
                            <FormControl error={displayError} fullWidth>
                              <Input
                                {...field}
                                className={classes.inputField}
                                endAdornment={
                                  <InputAdornment
                                    position="end"
                                    className={buildConditionalClasses([
                                      [true, classes.inputAdornment],
                                      [errors.username, classes.error],
                                      [displayError, classes.error],
                                    ])}
                                  >
                                    <Person />
                                  </InputAdornment>
                                }
                                fullWidth
                                id="username"
                                type="text"
                                placeholder={i18n._(t`Username`)}
                              />
                              <FormHelperText className={classes.error}>{errors.username}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                      <Spacer factor={2} />
                      <Field name="password">
                        {({ field, form: { errors, touched } }) => {
                          const displayError = errors.password && touched.password;
                          return (
                            <FormControl error={displayError} fullWidth>
                              <Input
                                {...field}
                                endAdornment={
                                  <InputAdornment
                                    position="end"
                                    className={buildConditionalClasses([
                                      [true, classes.inputAdornment],
                                      [values.password && errors.password, classes.error],
                                      [displayError, classes.error],
                                      [errorMessage || showUnauthorizedError, classes.error],
                                    ])}
                                  >
                                    <Lock />
                                  </InputAdornment>
                                }
                                fullWidth
                                id="password"
                                type="password"
                                placeholder={i18n._(t`Password`)}
                              />
                              <FormHelperText
                                className={buildConditionalClasses([
                                  [errors.password, classes.error],
                                  [displayError, classes.error],
                                  [attemptsSinceLastAuth || errorMessage || showUnauthorizedError, classes.error],
                                ])}
                              >
                                {attemptsSinceLastAuth || errorMessage || showUnauthorizedError ? (
                                  <span id="unauthorized-message">{incorrectPasswordMessage}</span>
                                ) : (
                                  displayError && errors.password
                                )}
                              </FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </CardContent>
                  ) : (
                    <CardContent>
                      <Typography variant="h2">Expired Password</Typography>
                      <Spacer factor={4} />
                      <Typography paragraph variant="body2">
                        <Trans>
                          The password for this account has expired. Please check the email address associated with this
                          account for a reset link in order to log in.
                        </Trans>
                      </Typography>
                      <Typography paragraph variant="body2">
                        <Trans>You may need to check your spam folder or unblock support@nemedio.com.</Trans>
                      </Typography>
                    </CardContent>
                  )}
                  <Spacer factor={3} />
                  {!showExpiredPasswordScreen ? (
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Button
                            id="login-button"
                            data-testid="login-button"
                            className={classes.loginButton}
                            color="primary"
                            disabled={!isValid || attemptsSinceLastAuth >= 5}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={
                              passwordExpired &&
                              this.setState({
                                showExpiredPasswordScreen: true,
                              })
                            }
                          >
                            <Trans>Log In</Trans>
                          </Button>
                          <Spacer factor={3} />
                        </Grid>
                        <Grid container item justify="center" xs={12}>
                          <Link to="/forgot-password">
                            <Typography variant="body2">
                              <Trans>Forgot password?&nbsp;</Trans>
                            </Typography>
                          </Link>
                          <Link to="/forgot-username">
                            <Typography variant="body2">&nbsp;| Forgot username?</Typography>
                          </Link>
                        </Grid>
                      </Grid>
                    </CardContent>
                  ) : (
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Button
                            id="home-button"
                            data-testid="home-button"
                            className={classes.homeButton}
                            color="primary"
                            fullWidth
                            size="large"
                            type="button"
                            variant="contained"
                            onClick={() => window.location.reload()}
                          >
                            <Trans>Back to login</Trans>
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  )}
                </Form>
              )}
            </Formik>
          </Card>
        </ContainerWithEntry>
      </>
    );
  }
}

LoginForm.defaultProps = {
  logo: LOGO,
};

export default compose(
  setDisplayName('LoginForm'),
  withI18n(),
  withStyles(styles),
  withCreateAuthToken,
)(LoginForm);
