import React from 'react';
import { t } from '@lingui/macro';
import { Typography } from '@material-ui/core';
import Error from '@material-ui/icons/Error';
import slugs from '../../displaySlugs';
import gql from 'graphql-tag';

const itemType = 'risk';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.setMember.setItemVersion.item.customIdentifier',
    headerName: 'Associated RAN',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (row.data === undefined) return null;
      return row.data.currentVersion.setMember
        ? row.data.currentVersion.setMember.setItemVersion.item.customIdentifier
        : '-';
    },
    render: (row) => {
      if (row.value === undefined) return null;
      return (
        <Typography variant={'body2'} style={{ width: 100 }}>
          {row.value}
        </Typography>
      );
    },
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.unmitigatedProbability.name',
    headerName: 'Unmitigated Probability',
    type: 'regular_text',
    defaultValue: ' ',
  },
  {
    field: 'currentVersion.unmitigatedSeverity.name',
    headerName: 'Unmitigated Severity',
    type: 'regular_text',
    defaultValue: ' ',
  },
  {
    field: 'currentVersion.unmitigatedRiskPriorityCode.fullName',
    headerName: 'Unmitigated RPC',
    type: 'regular_text',
    defaultValue: ' ',
  },
  {
    field: 'currentVersion.finalRiskAcceptability',
    headerName: 'Medical Risk Acceptable',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (row.data === undefined) return null;
      if (row.data.currentVersion.finalRiskAcceptability === true) return 'Yes';
      else if (row.data.currentVersion.finalRiskAcceptability === false) return 'No';
      else return '';
    },
    render: (row) => {
      return row.value;
    },
  },
  {
    field: 'currentVersion.mitigatedProbability.name',
    headerName: 'Mitigated Probability',
    type: 'regular_text',
    defaultValue: ' ',
  },
  {
    field: 'currentVersion.mitigatedSeverity.name',
    headerName: 'Mitigated Severity',
    type: 'regular_text',
    defaultValue: ' ',
  },
  {
    field: 'currentVersion.mitigatedRiskPriorityCode.fullName',
    headerName: 'Mitigated RPC',
    type: 'regular_text',
    defaultValue: ' ',
  },
  {
    field: 'currentVersion.residualRiskAcceptability',
    headerName: 'Residual Risk Acceptable',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (row.data === undefined) return null;
      if (row.data.currentVersion.residualRiskAcceptability === true) return 'Yes';
      else if (row.data.currentVersion.residualRiskAcceptability === false) return 'No';
      else return '';
    },
    render: (row) => {
      return row.value;
    },
  },
];

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Risk`,
  categoryLabel: t`Risks`,
  icon: Error,
  listViewQuery: gql`
    query risksQuery {
      risks {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          versionIdentifier
          currentStatusName
          setMember {
            id
            setItemVersion {
              id
              item {
                id
                customIdentifier
              }
            }
          }
          currentStatus {
            id
            name
          }
          unmitigatedProbability {
            id
            name
          }
          unmitigatedSeverity {
            id
            name
          }
          mitigatedSeverity {
            id
            name
          }
          mitigatedProbability {
            id
            name
          }
          finalRiskAcceptability
          residualRiskAcceptability
          unmitigatedRiskPriorityCode {
            id
            name
            fullName
          }
          mitigatedRiskPriorityCode {
            id
            name
            fullName
          }
        }
      }
    }
  `,
  listViewColumns,
};
