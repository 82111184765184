import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import SectionLabel from 'components/core/SectionLabel';
import Update from '@material-ui/icons/Update';

import { useStyles } from './../styles';
import { VersionAndStatusDot } from 'components';
import AgGridTable from 'components/AgGridTable';
import { riskStringToParams } from 'pages/Supplier/Id/PayloadFields/RenderTab';
import ColorBox from 'components/RiskLevelSelect/ColorBox';

const columns = [
  {
    headerName: 'ID',
    type: 'id',
    field: 'customIdentifier',
  },
  {
    headerName: 'Title',
    field: 'currentVersion.title',
    type: 'regular_text',
  },
  {
    headerName: 'Qualification',
    field: 'qualification',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        versionIdentifier: row.value.currentVersion.versionIdentifier,
        currentStatusName: row.value.currentVersion.currentStatus.name,
      });
    },
    render(row) {
      if (row.value === undefined) return null;
      let versionIdentifier;
      let currentStatusName;
      const isColumnGroupedBy = Boolean(
        row.columnApi.columnModel.rowGroupColumns.find((column) => column.colId === 'qualification'),
      );
      if (isColumnGroupedBy) {
        versionIdentifier = JSON.parse(row.value).versionIdentifier;
        currentStatusName = JSON.parse(row.value).currentStatusName;
      } else {
        versionIdentifier = row.data.currentVersion.versionIdentifier.replace(/v/i, 'q');
        currentStatusName = row.data.currentVersion.currentStatus.name;
      }
      return (
        <div style={{ width: 64, display: 'flex', justifyContent: 'center' }}>
          <VersionAndStatusDot version={versionIdentifier} status={currentStatusName} />
        </div>
      );
    },
  },
  {
    headerName: 'Requalification Date',
    field: 'currentVersion.approvalExpiresAt',
    type: 'date',
    width: '160px',
  },
  {
    headerName: 'Risk Level',
    field: 'currentVersion.riskLevel',
    type: 'custom',
    enableRowGroup: true,
    width: '180px',
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        riskLevel: row.value.currentVersion.riskLevel,
      });
    },
    render(row) {
      if (row.value === undefined) return null;
      let riskLevel;
      const isColumnGroupedBy = Boolean(
        row.columnApi.columnModel.rowGroupColumns.find((column) => column.colId === 'currentVersion.riskLevel'),
      );
      if (isColumnGroupedBy) {
        riskLevel = JSON.parse(row.value).riskLevel;
      } else {
        riskLevel = row.data.currentVersion.riskLevel;
      }
      return (
        <div style={{ display: 'flex' }}>
          {riskLevel && (
            <>
              <ColorBox color={riskStringToParams(riskLevel).color} />
              <Typography variant="body2">{riskStringToParams(riskLevel).label}</Typography>
            </>
          )}
        </div>
      );
    },
  },
  {
    headerName: 'Category',
    field: 'currentVersion.categoryText',
    type: 'regular_text',
  },
];

const UpcomingRequalifications = (props) => {
  const classes = useStyles();

  return (
    <Grid xs={12}>
      <div className={classes.supplierActionsLabel}>
        <SectionLabel variant="body1" icon={Update}>
          <Typography
            variant="h3"
            component="span"
            style={{ fontSize: '18px', fontWeight: 400, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
          >
            Upcoming Requalifications
          </Typography>
        </SectionLabel>
      </div>
      <AgGridTable columns={columns} data={props.upcomingRequalifications} suppressViewOptions={true} />
    </Grid>
  );
};

export default UpcomingRequalifications;
