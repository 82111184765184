import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './toolbox.svg';

function Toolbox(props) {
  return (
    <SvgIcon
      {...props}
      style={{ position: 'relative', left: '2px', top: '4px' }}
      children={<path />}
      component={(svgProps) => <Icon {...svgProps} />}
    />
  );
}

export default Toolbox;
