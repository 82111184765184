import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      height: theme.spacing(60.5),
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    cornerLink: {
      position: 'absolute',
      right: 20,
      top: 17,
      color: '#33BCB4',
      fontSize: '13px',
      fontWeight: 600,
    },
  }),
);
