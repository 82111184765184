export default (theme) => ({
  smallBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    border: '1px solid',
    height: 14,
    top: 20,
    margin: -1,
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
    },
    verticalAlign: 'top',
  },
  disabled: {
    opacity: 0.7,
  },
  textDecreased: {
    '& .MuiBadge-badge': {
      fontSize: '0.55rem',
    },
  },
});
