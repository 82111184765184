import React, { useEffect } from 'react';
import { get } from 'lodash';

import { PayloadFieldsProps } from 'itemTypes';

import { PDFData } from 'components';
import AttachmentsSection from 'components/AttachmentsSection';
import DocumentBuilder from 'compositions/DocumentBuilder';

import ImageGallery from './ImageGallery';
import { withStyles, Grid } from '@material-ui/core';
import { ContentSection, Spacer, TextAreaField } from 'components';
import Sort from '@material-ui/icons/Sort';
import { Trans } from '@lingui/macro';

import styles from './styles';
import { useVersion } from './gql';
import { useUpdateAttachment, useDestroyAttachment, useCreateAttachment } from './gql';

function PayloadFields(props: PayloadFieldsProps & { classes }) {
  const { data, loading, error, refetch } = useVersion(props.versionId);
  const inputMethod = get(data, 'labelingVersion.inputMethod');

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching Labeling: ${err}`);
    });
  }, [inputMethod, refetch]);

  const [destroy] = useDestroyAttachment();
  const [create] = useCreateAttachment();
  const [update] = useUpdateAttachment();

  if (error)
    throw new Error(
      `Error querying Labeling version:${props.versionId}\n
      ${error}`,
    );
  if (!data || loading) return null;

  const versionData = data.labelingVersion;
  const { layoutClasses } = props;

  if (inputMethod === 'BODY')
    return (
      <DocumentBuilder
        itemType={props.itemType}
        refetchQueries={['LabelingVersionQuery']}
        richTextOptionSet="large"
        layoutClasses={props.layoutClasses}
        versionData={versionData}
      />
    );

  if (inputMethod === 'UPLOAD')
    return <PDFData versionData={versionData} layoutClasses={props.layoutClasses} refetch={refetch} />;
  if (inputMethod === 'GUIDED')
    return (
      <Grid container item xs={12} className={layoutClasses.grid}>
        <Grid item xs={8} className={layoutClasses.gridGutterRight}>
          <ContentSection
            LabelText={
              <>
                <Sort className={layoutClasses.icon} />
                <Trans>Description</Trans>
              </>
            }
          >
            <Spacer factor={1} />
            <TextAreaField
              refetchQueries={['LabelingVersion']}
              locked={versionData.locked}
              id="do-text-field"
              initialValue={versionData.summary}
              versionId={versionData.id}
              attrName={'summary'}
              rows={8}
              placeholder={'Enter description'}
              className={layoutClasses.textField}
            />
          </ContentSection>
        </Grid>
        <Grid item xs={12} className={layoutClasses.grid}>
          <AttachmentsSection
            title="MSC Attachments"
            versionId={versionData.id}
            classes={props.classes}
            attachments={versionData.labelingAttachments}
            key={versionData.labelingAttachments.length}
            locked={versionData.locked}
            updateAttachment={(
              id: string,
              attachment: {
                url?: string;
                previewUrl?: string;
                description?: string;
              },
            ) =>
              update({
                variables: {
                  attachmentId: id,
                  attachmentURL: attachment.url,
                  description: attachment.description,
                  previewURL: attachment.previewUrl,
                },
              })
            }
            createAttachment={(versionId: string, attachmentURL: string) =>
              create({ variables: { versionId, attachmentURL } })
            }
            destroyAttachment={(id: string) => destroy({ variables: { id } })}
            enablePreview
          />
          <ImageGallery classes={props.classes} attachments={versionData.labelingAttachments} />
        </Grid>
      </Grid>
    );
  return null;
}

export default withStyles(styles)(PayloadFields);
