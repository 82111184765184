import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    overview: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    container: {
      padding: '0 24px 0 32px',
      height: '100%',
    },
    closeIcon: {
      position: 'absolute',
      top: 12,
      right: 12,
      '&:hover': { cursor: 'pointer' },
    },
    details: {
      width: '390px',
      paddingTop: '36px',
      position: 'relative',
    },
    footer: {
      height: '97px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    pdfViewer: {
      paddingTop: '85px',
      width: '100%',
    },
    viewerDocumentPassed: {
      maxHeight: 'calc(75vh - 175px)',
    },
    popup: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      left: '17vh',
      right: '17vh',
      top: '10vh',
      height: '75vh',
    },
    buttons: {
      position: 'absolute',
      bottom: '38px',
      right: '32px',
      left: '0',
    },
    printButton: {
      width: '100%',
    },
    saveButton: {
      width: '100%',
    },
  });
