import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Query, useQuery } from 'react-apollo';

const query = gql`
  query AllProducts {
    products {
      id
      name
    }
  }
`;

const withProducts = (Component) => (componentProps) => (
  <Query query={query}>
    {({ data, error }) => {
      if (error) console.log(error);
      return <Component {...componentProps} products={get(data, 'products')} />;
    }}
  </Query>
);

export function useProducts() {
  return useQuery(query);
}

export default withProducts;
