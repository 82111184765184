import { compact, findIndex, get } from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName } from 'recompose';

import { Trans } from '@lingui/macro';

import { Paper, Tab, Tabs, withStyles } from '@material-ui/core';

import { navigate, Match } from '@reach/router';

import { withRoutes } from '../../setup/WithRoutes';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { withActiveCategory } from 'compositions/WithCategories';

const styles = ({ typography, palette, shape: { borderRadius } }) => ({
  '@global': {
    '@media print': {
      '#workspace-view-menu': {
        display: 'none',
      },
    },
  },
  root: {
    borderRadius: `0 0 ${borderRadius}px ${borderRadius}px`,
  },
  viewTab: {
    ...typography.overline,
    opacity: 1,
    height: 64,
    minWidth: 100,
    minHeight: 'unset',
    paddingTop: 0,
    '& svg': {
      fontSize: 20,
      fill: palette.blueDark,
    },
    '& span': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  categoryTab: {
    ...typography.overline,
    opacity: 1,
    backgroundColor: palette.grey[300],
    color: typography.overline.color,
    minWidth: 80,
    width: 80,
    minHeight: 'unset',
    height: 64,
    paddingTop: 0,
    '& svg': {
      fontSize: 20,
      fill: palette.blueDark,
    },
    '& span': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

const getActiveCategoryTab = memoizeOne(
  ({ activeCategory, classes, getWorkspaceView }) => {
    const activeCategoryRouteName = get(activeCategory, 'parentRouteName');
    if (activeCategoryRouteName) {
      const activeCategoryWorkspaceView = getWorkspaceView({
        routeName: activeCategoryRouteName,
        // protectedRoutesIndex
      });
      return {
        ...activeCategory,
        ...activeCategoryWorkspaceView,
        className: classes.categoryTab,
      };
    }

    return null;
  },
);

function WorkspaceViewMenu({
  activeCategory,
  activeWorkspaceView,
  classes,
  getWorkspaceView,
  setWorkspaceViewForRoute,
  workspaceViews,
}) {
  return (
    <Match path="/category/:itemType/:itemId">
      {(props) => {
        const activeCategoryTab = get(props, 'match.itemId')
          ? getActiveCategoryTab({
              activeCategory,
              classes,
              getWorkspaceView,
            })
          : null;

        const tabElements = compact([activeCategoryTab, ...workspaceViews]);

        if (workspaceViews.length > 0) {
          const workspaceViewIndex = findIndex(
            workspaceViews,
            ({ id }) => id === get(activeWorkspaceView, 'id'),
          );
          const activeWorkspaceViewIndex = activeCategoryTab
            ? workspaceViewIndex + 1
            : workspaceViewIndex;

          return (
            <Paper
              className={classes.root}
              data-testid="workspace-view-menu"
              square
            >
              <Tabs
                indicatorColor="primary"
                onChange={(event, index) => {
                  if (activeCategoryTab && index === 0) {
                    const { slug } = activeCategory;
                    return navigate(`/category/${slug}`);
                  }

                  const { id } = tabElements[index];
                  return setWorkspaceViewForRoute(id);
                }}
                value={activeWorkspaceViewIndex}
              >
                {tabElements.map(({ className, id, label, icon: Icon }) => (
                  <Tab
                    key={id}
                    className={className || classes.viewTab}
                    icon={<Icon />}
                    label={<Trans id={label} />}
                  />
                ))}
              </Tabs>
            </Paper>
          );
        }

        return null;
      }}
    </Match>
  );
}

WorkspaceViewMenu.propTypes = {
  activeWorkspaceView: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    viewTab: PropTypes.string.isRequired,
  }).isRequired,
  setWorkspaceViewForRoute: PropTypes.func.isRequired,
  workspaceViews: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func.isRequired,
      label: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
      id: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

WorkspaceViewMenu.defaultProps = {
  activeWorkspaceView: undefined,
};

export default compose(
  setDisplayName('WorkspaceViewMenu'),
  withActiveCategory,
  withRoutes,
  withWorkspaceViews({ controlRoute: true }),
  withStyles(styles),
)(WorkspaceViewMenu);
