import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import LowPriority from '@material-ui/icons/LowPriority';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { DateTime } from 'luxon';

import { ContentSection, Spacer, WorkflowIcon } from 'components';
import Column from './Column';

function ParentSets({ classes, parentSets }: { classes; parentSets: any[] }) {
  return (
    <Grid item xs={8} className={classes.gridGutterLeft}>
      <ContentSection
        LabelText={
          <>
            <LowPriority className={classes.icon} />
            <Trans>Parent Set</Trans>
          </>
        }
        style={{ maxHeight: 180, overflow: 'scroll' }}
      >
        <Spacer factor={1} />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 'auto' }}>
            <div style={{ height: 18 }} />
            {parentSets.map((set) => (
              <Typography>
                {`${set.item.customIdentifier}: ${set.title || ''}`}
              </Typography>
            ))}
          </div>
          <Column
            heading={i18n._(t`Version`)}
            values={parentSets.map((set) => (
              <Typography>{set.versionIdentifier}</Typography>
            ))}
          />
          <Column
            heading={i18n._(t`Status`)}
            values={parentSets.map((set) => (
              <WorkflowIcon
                size={'tiny'}
                type={set.currentStatusName}
                completed
              />
            ))}
          />
          <Column
            heading={i18n._(t`Effective Date`)}
            values={parentSets.map((set) => (
              <Typography variant="body2">
                {DateTime.fromISO(set.releasedAt).toFormat('MMM. d yyyy')}
              </Typography>
            ))}
          />
        </div>
      </ContentSection>
    </Grid>
  );
}

export default ParentSets;
