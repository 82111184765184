import React, { useContext } from 'react';

import { ListAlt } from '@material-ui/icons';
import { createStyles, makeStyles, Tooltip, IconButton } from '@material-ui/core';
import LinkOff from '@material-ui/icons/LinkOff';

import ActionPopover from 'compositions/LinksGraph/Actions/ActionPopover';
import LinksGraphToggle from 'compositions/LinksGraph/Actions/LinksGraphToggle';
import { TraceMatrixCardContext } from 'contexts/traceMatrixCardContext';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonToolbar: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      backgroundColor: theme.palette.background.default,
    },
  }),
);

function ActionBar(props) {
  const classes = useStyles();

  const { description, otherConnections } = useContext(TraceMatrixCardContext);
  // Tooltip > span is needed to prevent a MaterialUI warning from Tooltip when IconButton is disabled
  return (
    <div className={classes.buttonToolbar}>
      <Tooltip title={props.tooltipText}>
        <span>
          <IconButton
            onClick={props.onDeleteTraceClick}
            disabled={props.isReadOnlyTrace || !Boolean(props.onDeleteTraceClick)}
          >
            <LinkOff />
          </IconButton>
        </span>
      </Tooltip>
      <ActionPopover Icon={ListAlt} cardDescription="Customize Card">
        <LinksGraphToggle checked={description.showing} label="Show Description" onChange={description.toggle} />
        <LinksGraphToggle
          checked={otherConnections.showing}
          label="Show Other Connections"
          onChange={otherConnections.toggle}
        />
      </ActionPopover>
    </div>
  );
}

export default ActionBar;
