import { ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';

import Toolbox from 'assets/icons/Toolbox';

export interface NavigationItemConfig {
  Icon: ComponentType<SvgIconProps>;
  name: string;
  title: string;
  dashboardSlug?: string | undefined;
  childrenList?: string[];
}

export const NAVIGATION_ITEMS: NavigationItemConfig[] = [
  {
    name: 'dashboard',
    title: 'Dashboards',
    Icon: DashboardOutlinedIcon,
    dashboardSlug: '/',
  },
  {
    name: 'quality_management',
    title: 'QMS',
    Icon: AssignmentTurnedInOutlinedIcon,
    childrenList: [
      'management_responsibility',
      'document_control',
      'quality_management',
      'training',
      'supplier_management',
    ],
  },
  {
    name: 'product',
    title: 'Product',
    Icon: CategoryOutlinedIcon,
    childrenList: [
      'project_management',
      'design_inputs',
      'risk_management_file',
      'software_file',
      'cybersecurity',
      'usability_engineering_file',
      'regulatory',
      'testing',
      'design_outputs',
      'device_master_record',
      'device_history_record',
      'drug_master_file',
    ],
  },
  {
    name: 'document_control',
    title: 'Document Repository',
    Icon: DescriptionOutlinedIcon,
    childrenList: [
      'management_responsibility',
      'document_control',
      'quality_management',
      'training',
      'supplier_management',
      'software',
      'design',
      'testing',
      'pharmacological',
      'regulatory',
      'production_&_process_controls',
      'nemedio_validation',
      'archived',
      // 'not_in_tree',
      // 'undefined',
    ],
  },
  {
    name: 'utilities',
    title: 'Utilities',
    Icon: Toolbox,
  },
];
