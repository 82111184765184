import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
    },
    list: {
      margin: 0,
      padding: 0,
    },
    listEntry: {
      listStyleType: 'none',
      display: 'flex',
    },
    trainingPlanText: {
      marginRight: theme.spacing(1),
    },
  }),
);
