import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    ...theme.mixins.gridLayout,
    boldHeaderText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    itemNotes: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    pdfView: {
      width: 500, // fixed width needed here to allow responsiveness
      height: 800, // fixed height needed here to avoid page jumps when changing page
      margin: '0 auto',
      borderRadius: 4,
      paddingTop: theme.spacing(2),
    },
    layout: {
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: theme.spacing(100),
    },
    multiTextAreaFields: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    popper: {
      zIndex: theme.zIndex.tooltip,
    },
    icon: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.5),
      color: theme.palette.blueDark,
    },
    iconTextStack: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    radioGroupField: {
      width: '100%',
    },
    warningMessage: {
      color: theme.palette.error.dark,
      padding: theme.spacing(1),
    },
    attachmentInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    attachmentInput: {
      ...theme.typography.body2,
      minHeight: 36,
      margin: 0,
      background: theme.palette.common.input.background,
      border: `2px solid ${theme.palette.common.input.outline}`,
      borderRadius: 4,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      display: 'flex',
      justifyContent: 'space-between',
    },
  });
