import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    headerNumber: {
      display: 'inline-block',
      padding: '0 17px',
      width: '52px',
    },
  }),
);
