import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'inline-block',
      position: 'relative',
      '& .OtherControls-popup': {
        position: 'absolute',
        left: '0',
        top: '100%',
        display: 'flex',
        '& button': {
          padding: 0,
        },
      },
      marginRight: 5,
    },
    iconButton: {
      padding: theme.spacing(1),
      color: theme.palette.secondary.main,
      '&:hover': {
        color: (theme.palette as any).blueDark,
        backgroundColor: 'transparent',
      },
    },
    smallIcon: {
      width: 20,
      height: 20,
    },
  }),
);
