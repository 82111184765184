import React, { FC } from 'react';
import get from 'lodash/get';
import { useStyles } from './styles';
import { itemLink } from '../../MakeLink';

interface Props {}

const DeviationLinks: FC<Props> = (props) => {
  const classes = useStyles();
  const deviations = get(props, 'deviationVersions', []);
  return (
    <>
      {deviations.map((deviation) => (
        <a
          key={deviation.id}
          className={classes.deviationsLink}
          href={itemLink(deviation.item)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {deviation.item.customIdentifier}
        </a>
      ))}
    </>
  );
};

export default DeviationLinks;
