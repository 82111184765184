import React, { useState } from 'react';

import { LibraryBooks, Portrait } from '@material-ui/icons';

import Panel from 'components/core/Panel';
import SectionLabel from 'components/core/SectionLabel';
import AgGridTable from 'components/AgGridTable';
import { MakeLink } from 'components';
import { isColumnGroupedBy, sortIds } from 'utils/agGridHelpers';

interface Props {
  trainingRecordVersions: any[];
}

const columns = [
  {
    headerName: 'ID',
    type: 'custom',
    blockRowLink: true,
    field: 'customIdentifier',
    comparator: (value1, value2) => sortIds(value1, value2),
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.item.customIdentifier;
    },
    render: (row) => {
      return (
        <MakeLink item={row.value.item} newTab={true}>
          {row.value.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    headerName: 'Title',
    field: 'title',
    type: 'regular_text',
    blockRowLink: true,
  },
  {
    headerName: 'Course',
    field: 'cource',
    type: 'custom',
    blockRowLink: true,
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.source.item.customIdentifier;
    },
    render: (row) => {
      if (isColumnGroupedBy(row, 'cource')) {
        return row.value;
      }
      return (
        <MakeLink item={row.value.source.item} newTab={true}>
          {row.value.source.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    headerName: 'Status',
    field: 'currentStatus.name',
    type: 'status',
    blockRowLink: true,
  },
  {
    headerName: 'Due Date',
    field: 'dueDate',
    type: 'date',
    blockRowLink: true,
  },
];

function Training(props: Props) {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <SectionLabel icon={LibraryBooks}>
        Training
        <SectionLabel.Collapse expanded={expanded} setExpanded={setExpanded} />
      </SectionLabel>
      {expanded && (
        <Panel>
          <Panel.Heading icon={Portrait}>Associated training records</Panel.Heading>
          <AgGridTable data={props.trainingRecordVersions} columns={columns} />
        </Panel>
      )}
    </>
  );
}

export default Training;
