import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    cardTitle: {
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: 'black',
      },
    },
    titleText: {
      fontWeight: 600,
    },
    icon: {
      fontSize: 12,
      paddingTop: 2,
    },
  }),
);
