import React from 'react';
import { Grid } from '@material-ui/core';
import Description from '@material-ui/icons/Description';

import semver from 'semver';

import UploadFile from 'assets/icons/UploadFile';
import { Spacer } from 'components';
import AttachmentControl from 'components/v2/AttachmentsSection/AttachmentControl';
import Panel from 'components/core/Panel';

import {
  CREATE_DOCUMENT_ATTACHMENT,
  CREATE_RAW_REF_ATTACHMENT,
  DESTROY_DOCUMENT_ATTACHMENT,
  DESTROY_RAW_REF_ATTACHMENT,
} from './gql';

import { useMutation } from 'react-apollo';

interface Props {
  versionData: any;
  onUpdate: () => void;
}

export default function DocumentStyleUploader(props: Props) {
  const { versionData } = props;

  const [createDocumentAttachment] = useMutation(CREATE_DOCUMENT_ATTACHMENT);
  const [destroyDocumentAttachment] = useMutation(DESTROY_DOCUMENT_ATTACHMENT);
  const [createRawReferenceAttachment] = useMutation(CREATE_RAW_REF_ATTACHMENT);
  const [destroyRawReferenceAttachment] = useMutation(DESTROY_RAW_REF_ATTACHMENT);
  const handleDocumentAttachmentChange = (versionId, attachmentURL) =>
    createDocumentAttachment({
      variables: {
        versionId,
        attachmentURL,
      },
    }).then(() => props.onUpdate());

  const handleDocumentAttachmentDeletion = () =>
    destroyDocumentAttachment({
      variables: { id: versionData.attachment.id },
    }).then(() => props.onUpdate());

  const handleRawRefAttachmentChange = (versionId, attachmentURL) =>
    createRawReferenceAttachment({
      variables: {
        versionId,
        attachmentURL,
      },
    }).then(() => props.onUpdate());

  const handleRawRefAttachmentDeletion = () =>
    destroyRawReferenceAttachment({
      variables: { id: versionData.rawReferenceAttachment.id },
    }).then(() => props.onUpdate());

  return (
    <Panel>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Panel.Heading icon={Description}>PDF Document</Panel.Heading>
          <Spacer factor={0.5} />
          <AttachmentControl
            accept={['.pdf']}
            versionId={versionData.id}
            locked={versionData.locked}
            attachment={versionData.attachment}
            createAttachment={handleDocumentAttachmentChange}
            destroyAttachment={handleDocumentAttachmentDeletion}
            singleAttachment
            downloadable
          />
        </Grid>
        {semver.gte(versionData.displayAsApplicationVersion, '4.3.0') && (
          <>
            <Spacer factor={4} />
            <Grid item xs={6}>
              <Panel.Heading icon={UploadFile}>Editable Document</Panel.Heading>
              <Spacer factor={0.5} />
              <AttachmentControl
                accept={['*']}
                versionId={versionData.id}
                locked={versionData.locked}
                attachment={versionData.rawReferenceAttachment}
                createAttachment={handleRawRefAttachmentChange}
                destroyAttachment={handleRawRefAttachmentDeletion}
                singleAttachment
                downloadable
              />
            </Grid>
          </>
        )}
      </Grid>
    </Panel>
  );
}
