import React, { useContext, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { AssignmentLate } from '@material-ui/icons';

import clsx from 'clsx';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import { capitalize, flowRight } from 'lodash';

import { LockedContext, withLockedContext } from 'contexts/lockedContext';
import {
  ExpandedTextArea,
  ItemCreatedSnackbar,
  MakeLink,
  NoContentDisclaimer,
  VersionAndStatusDot,
  VersionSwitch,
} from 'components';
import SetsControlBar from 'components/LongCardControls/SetsControlBar';
import AddVersionModal from 'components/AddVersionModal';
import AgGridTable from 'components/AgGridTable';

import {
  RiskMitigations,
  RiskNewRisks,
  RiskProbability,
  RiskSeverity,
  RiskRPC,
  RiskAcceptable,
  RiskHazard,
  RiskHarm,
  OptionsProvider,
} from './controls';

import { useVersion } from './gql';
import { useLinkExistingRisks, useUnlinkRisk, useLinkNewRisk, useCreateVersion, useChangeRisk } from './controls/hooks';
import { useStyles } from './styles';
import { useStyles as useTableStyles } from 'components/core/Table/styles';
import {
  RiskAnalysisVersionQuery_riskAnalysisVersion_memberVersions_RiskControlVersion,
  RiskAnalysisVersionQuery_riskAnalysisVersion_memberVersions_RiskVersion,
} from './__generated__/RiskAnalysisVersionQuery';
import OtherControls from './OtherControls';
import { SetGenericWorkflowStatuses } from 'workflows/statuses';
import { isColumnGroupedBy } from 'utils/agGridHelpers';
import { boolToStr } from 'utils/generalFormatUtils';
import { Views } from 'workspaces';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { workingVersionSelected } from 'components/VersionSwitch';

export type RiskAnalysisMemberVersion = RiskAnalysisVersionQuery_riskAnalysisVersion_memberVersions_RiskControlVersion &
  RiskAnalysisVersionQuery_riskAnalysisVersion_memberVersions_RiskVersion;

const width = {
  id: 117,
  textColumn: 180,
  smallColumn: 100,
  acceptabilityColumn: 210,
  hazardChip: 220,
  manyChips: 200,
  longChip: 260,
  versionColumn: 130,
};

const RiskAnalysisEditableTextField = ({ riskVersion, initialValue, attrName, title, fixedHeight }) => {
  return (
    <ExpandedTextArea
      key={`risk-analysis-${attrName}-${riskVersion.id}`}
      id={`risk-analysis-${attrName}-${riskVersion.id}`}
      initialValue={initialValue}
      versionId={riskVersion.item.currentVersion.id}
      attrName={attrName}
      rows={2}
      maxLength={850}
      locked={riskVersion.locked}
      placeholder={`Enter ${title} text here`}
      refetchQueries={['RiskAnalysisVersionQuery']}
      debounce={2000}
      fixedHeight={fixedHeight}
    />
  );
};

const getColumns = (
  riskAnalysisVersion,
  unlinkRisk,
  changeRiskVersion,
  setAddVersionModal,
  activeWorkspaceView,
  classes,
) => {
  return [
    {
      headerName: '',
      children: [
        {
          field: 'item',
          headerName: 'Risk ID',
          type: 'custom',
          blockRowLink: true,
          render: (row) => {
            return (
              <MakeLink item={row.data.item} newTab={true}>
                {row.data.item.customIdentifier}
              </MakeLink>
            );
          },
        },
        {
          field: 'item',
          headerName: 'Actions',
          type: 'custom',
          blockRowLink: true,
          render(row) {
            const riskVersion: RiskAnalysisMemberVersion = row.data;
            return (
              <OtherControls
                itemId={riskVersion.item.id}
                versionId={riskVersion.id}
                commentCount={riskVersion.item.comments.length}
                itemTags={riskVersion.item.tags}
                riskIdent={riskVersion.item.customIdentifier}
                riskIdentVersion={`${riskVersion.item.customIdentifier} ${riskVersion.versionIdentifier}`}
                unlinkRisk={() => unlinkRisk(riskAnalysisVersion.id, riskVersion.id)}
                locked={riskAnalysisVersion.locked}
              />
            );
          },
        },
        {
          field: 'item.currentVersion',
          headerName: 'Version',
          type: 'custom',
          blockRowLink: true,
          width: width.versionColumn,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            const workingVersionSelected = row.value.id === get(row.value.item, 'workingVersion.id');
            const versionIdentifier = workingVersionSelected
              ? row.value.item.workingVersion.versionIdentifier
              : row.value.item.currentRelease.versionIdentifier;
            const currentStatusName = workingVersionSelected
              ? row.value.item.workingVersion.currentStatus.name
              : row.value.item.currentRelease.currentStatus.name;
            return JSON.stringify({
              versionIdentifier,
              currentStatusName,
            });
          },
          render(row) {
            if (isColumnGroupedBy(row, 'item.currentVersion')) {
              return (
                <VersionAndStatusDot
                  version={JSON.parse(row.value).versionIdentifier}
                  status={JSON.parse(row.value).currentStatusName}
                />
              );
            }
            const isWorkingVersionSelected = workingVersionSelected(
              row.value.item.currentRelease,
              row.value.item.workingVersion,
              row.value.id,
            );

            if (activeWorkspaceView.id === Views.Effective.id || activeWorkspaceView.id === Views.Versions.id) {
              return (
                <VersionAndStatusDot
                  version={
                    isWorkingVersionSelected
                      ? row.value.item.workingVersion.versionIdentifier
                      : row.value.item.currentRelease.versionIdentifier
                  }
                  status={
                    isWorkingVersionSelected
                      ? row.value.item.workingVersion.currentStatus.name
                      : row.value.item.currentRelease.currentStatus.name
                  }
                />
              );
            }

            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return (
              <div style={{ width: 130, display: 'flex', justifyContent: 'center' }}>
                <VersionSwitch
                  disabled={
                    riskAnalysisVersion.locked === true ||
                    (row.value.item.currentVersion.locked &&
                      row.value.item.currentVersion.currentStatus.name !== SetGenericWorkflowStatuses.Released.id)
                  }
                  currentRelease={row.value.item.currentRelease}
                  workingVersion={row.value.item.workingVersion}
                  value={row.value.id}
                  onToggle={(value) => {
                    changeRiskVersion(riskAnalysisVersion.id, riskVersion.id, value);
                  }}
                  onAddClick={() => {
                    setAddVersionModal({
                      open: true,
                      options: {
                        customIdentifier: riskVersion.item.customIdentifier,
                        id: riskVersion.item.id,
                      },
                    });
                  }}
                />
              </div>
            );
          },
        },
        {
          field: 'hazard.name',
          headerName: 'Hazard',
          blockRowLink: true,
          width: width.hazardChip,
          type: 'custom',
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.hazard ? row.value.hazard.name : '';
          },
          render(row) {
            if (isColumnGroupedBy(row, 'hazard.name')) {
              return row.value;
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskHazard riskVersion={riskVersion} className={classes.harmAndHazardCell} />;
          },
        },
        {
          field: 'sequenceOfEvents',
          blockRowLink: true,
          width: width.textColumn,
          type: 'custom',
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.sequenceOfEvents || '';
          },
          render(row) {
            if (isColumnGroupedBy(row, 'sequenceOfEvents')) {
              return row.value;
            }
            const riskVersion = row.value;
            return (
              <RiskAnalysisEditableTextField
                title="Sequence Of Events"
                riskVersion={riskVersion}
                initialValue={riskVersion.sequenceOfEvents}
                attrName="sequence_of_events"
                fixedHeight
              />
            );
          },
        },
        {
          field: 'hazardousSituation',
          blockRowLink: true,
          width: width.textColumn,
          type: 'custom',
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.hazardousSituation || '';
          },
          render(row) {
            if (isColumnGroupedBy(row, 'hazardousSituation')) {
              return row.value;
            }
            const riskVersion = row.value;
            return (
              <RiskAnalysisEditableTextField
                riskVersion={riskVersion}
                title="Hazardous Situation"
                initialValue={riskVersion.hazardousSituation}
                attrName="hazardous_situation"
                fixedHeight
              />
            );
          },
        },
      ],
    },
    {
      headerName: 'UNMITIGATED RISK',
      children: [
        {
          field: 'harm.name',
          headerName: 'Harm',
          type: 'custom',
          width: width.hazardChip,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.harm ? row.value.harm.name : '';
          },
          render(row) {
            if (isColumnGroupedBy(row, 'harm.name')) {
              return row.value;
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskHarm mitigated={false} riskVersion={riskVersion} className={classes.harmAndHazardCell} />;
          },
        },
        {
          field: 'unmitigatedProbability1.name',
          headerName: 'P1',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.unmitigatedProbability1 ? row.value.unmitigatedProbability1.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'unmitigatedProbability1.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return (
              <RiskProbability mitigated={false} which="1" riskVersion={riskVersion} locked={riskVersion.locked} />
            );
          },
        },
        {
          field: 'unmitigatedProbability2.name',
          headerName: 'P2',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.unmitigatedProbability2 ? row.value.unmitigatedProbability2.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'unmitigatedProbability2.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return (
              <RiskProbability mitigated={false} which="2" riskVersion={riskVersion} locked={riskVersion.locked} />
            );
          },
        },
        {
          field: 'unmitigatedProbability.name',
          headerName: 'P',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.unmitigatedProbability ? row.value.unmitigatedProbability.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'unmitigatedProbability.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskProbability mitigated={false} which="" riskVersion={riskVersion} locked={riskVersion.locked} />;
          },
        },
        {
          field: 'unmitigatedSeverity.name',
          headerName: 'S',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.unmitigatedSeverity ? row.value.unmitigatedSeverity.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'unmitigatedSeverity.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskSeverity mitigated={false} riskVersion={riskVersion} locked={riskVersion.locked} />;
          },
        },
        {
          headerName: 'RPC',
          field: 'unmitigatedRiskPriorityCode.name',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.unmitigatedRiskPriorityCode ? row.value.unmitigatedRiskPriorityCode.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'unmitigatedRiskPriorityCode.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskRPC mitigated={false} riskVersion={riskVersion} />;
          },
        },
        {
          headerName: 'Medical Risk Acceptable',
          type: 'custom',
          field: 'finalRiskAcceptability',
          blockRowLink: true,
          width: width.acceptabilityColumn,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return boolToStr(row.value.finalRiskAcceptability);
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'finalRiskAcceptability')) {
              return row.value;
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskAcceptable which="final" riskVersion={riskVersion} locked={riskVersion.locked} />;
          },
        },
        {
          field: 'justification',
          blockRowLink: true,
          width: width.textColumn,
          type: 'custom',
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.justification || '';
          },
          render(row) {
            if (isColumnGroupedBy(row, 'justification')) {
              return row.value;
            }
            const riskVersion = row.value;
            return (
              <RiskAnalysisEditableTextField
                riskVersion={riskVersion}
                title="Justification"
                initialValue={riskVersion.justification}
                attrName="justification"
                fixedHeight
              />
            );
          },
        },
      ],
    },
    {
      headerName: '',
      children: [
        {
          headerName: 'Risk Controls',
          field: 'searchRiskControls',
          type: 'custom',
          width: width.longChip,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.searchRiskControls ? row.value.searchRiskControls : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'searchRiskControls')) {
              return row.value;
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskMitigations riskVersion={riskVersion} className={classes.riskControlCell} />;
          },
        },
      ],
    },
    {
      headerName: 'MITIGATED RISK',
      children: [
        {
          headerName: 'Harm',
          field: 'mitigatedHarm.name',
          width: width.hazardChip,
          type: 'custom',
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.mitigatedHarm ? row.value.mitigatedHarm.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'mitigatedHarm.name')) {
              return row.value;
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskHarm mitigated={true} riskVersion={riskVersion} />;
          },
        },
        {
          headerName: 'P1',
          field: 'mitigatedProbability1.name',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.mitigatedProbability1 ? row.value.mitigatedProbability1.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'mitigatedProbability1.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return (
              <RiskProbability
                mitigated={true}
                which="1"
                riskVersion={riskVersion}
                locked={riskVersion.locked || Boolean(riskVersion.finalRiskAcceptability)}
              />
            );
          },
        },
        {
          headerName: 'P2',
          field: 'mitigatedProbability2.name',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.mitigatedProbability2 ? row.value.mitigatedProbability2.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'mitigatedProbability2.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return (
              <RiskProbability
                mitigated={true}
                which="2"
                riskVersion={riskVersion}
                locked={riskVersion.locked || Boolean(riskVersion.finalRiskAcceptability)}
              />
            );
          },
        },
        {
          headerName: 'P',
          field: 'mitigatedProbability.name',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.mitigatedProbability ? row.value.mitigatedProbability.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'mitigatedProbability.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return (
              <RiskProbability
                mitigated={true}
                which=""
                riskVersion={riskVersion}
                locked={riskVersion.locked || Boolean(riskVersion.finalRiskAcceptability)}
              />
            );
          },
        },
        {
          headerName: 'S',
          field: 'mitigatedSeverity.name',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.mitigatedSeverity ? row.value.mitigatedSeverity.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'mitigatedSeverity.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return (
              <RiskSeverity
                mitigated={true}
                riskVersion={riskVersion}
                locked={riskVersion.locked || Boolean(riskVersion.finalRiskAcceptability)}
              />
            );
          },
        },
        {
          headerName: 'RPC',
          field: 'mitigatedRiskPriorityCode.name',
          type: 'custom',
          width: width.smallColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.mitigatedRiskPriorityCode ? row.value.mitigatedRiskPriorityCode.name : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'mitigatedRiskPriorityCode.name')) {
              return capitalize(row.value);
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskRPC mitigated={true} riskVersion={riskVersion} />;
          },
        },
      ],
    },
    {
      headerName: 'OVERALL RISK EVALUATION',
      children: [
        {
          headerName: 'New Risks',
          field: 'searchNewRisks',
          type: 'custom',
          enableRowGroup: true,
          width: width.manyChips,
          blockRowLink: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.searchNewRisks ? row.value.searchNewRisks : '';
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'searchNewRisks')) {
              return row.value;
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return <RiskNewRisks riskVersion={riskVersion} />;
          },
        },
        {
          headerName: 'Residual Risk Acceptable',
          field: 'residualRiskAcceptability',
          type: 'custom',
          width: width.acceptabilityColumn,
          blockRowLink: true,
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return boolToStr(row.value.residualRiskAcceptability);
          },
          render: (row) => {
            if (isColumnGroupedBy(row, 'residualRiskAcceptability')) {
              return row.value;
            }
            const riskVersion: RiskAnalysisMemberVersion = row.value;
            return (
              <RiskAcceptable
                which="residual"
                riskVersion={riskVersion}
                locked={riskVersion.locked || Boolean(riskVersion.finalRiskAcceptability)}
              />
            );
          },
        },
        {
          field: 'residualRiskJustification',
          blockRowLink: true,
          width: width.textColumn,
          type: 'custom',
          enableRowGroup: true,
          valueGetter: (row) => {
            return row.data;
          },
          keyCreator: (row) => {
            return row.value.residualRiskJustification || '';
          },
          render(row) {
            if (isColumnGroupedBy(row, 'residualRiskJustification')) {
              return row.value;
            }
            const riskVersion = row.value;
            return (
              <RiskAnalysisEditableTextField
                riskVersion={riskVersion}
                title="Justification"
                initialValue={riskVersion.residualRiskJustification}
                attrName="residual_risk_justification"
                fixedHeight
              />
            );
          },
        },
      ],
    },
  ];
};

const formatData = (data) => {
  const memberVersions = [...data];
  memberVersions.forEach((x) => {
    if (x.__typename === 'RiskControlVersion') return;
    if ((x as any).searchRiskControls) return;
    (x as any).searchRiskControls = x.item.riskControlMeasures.map((x) => x.customIdentifier).join('|');
    (x as any).searchNewRisks = flatten(x.item.riskControlMeasures.map((x) => x.introducedNewRisks))
      .map((x: any) => x.customIdentifier)
      .join('|');
  });

  memberVersions.sort((a, b) => ('' + get(a, 'item.customIdentifier')).localeCompare(get(b, 'item.customIdentifier')));

  return memberVersions;
};

function PayloadFields({ versionId, activeWorkspaceView }) {
  const { data, loading, error } = useVersion(versionId);
  const { setLocked } = useContext(LockedContext);
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const [itemId, setItemId] = useState(null);
  const [customIdentifier, setCustomIdentifier] = useState('');
  const [showItemCreatedSnackbar, setShowItemCreatedSnackbar] = useState(false);

  const [addVersionModal, setAddVersionModal] = useState<{
    open: boolean;
    options: any;
  }>({ open: false, options: {} });

  const onCreateMember = (data) => {
    const newRisk = data.linkNewRiskToRiskAnalysis.riskVersion.item;
    setItemId(newRisk.id);
    setCustomIdentifier(newRisk.customIdentifier);
    setShowItemCreatedSnackbar(true);
  };

  const addRisksToRiskAnalysis = useLinkExistingRisks({
    refetchQueries: ['RiskAnalysisVersionQuery'],
  });
  const addNewRiskToRiskAnalysis = useLinkNewRisk({
    refetchQueries: ['RiskAnalysisVersionQuery'],
    update: (_, { data }) => {
      onCreateMember(data);
    },
  });
  const createNewRiskVersion = useCreateVersion({
    refetchQueries: ['RiskAnalysisVersionQuery'],
  });
  const unlinkRisk = useUnlinkRisk({ refetchQueries: ['RiskAnalysisVersionQuery'] });

  const changeRiskVersion = useChangeRisk({
    refetchQueries: ['RiskAnalysisVersionQuery'],
  });

  useEffect(() => {
    if (data && data.riskAnalysisVersion) setLocked(data.riskAnalysisVersion.locked);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data]);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading RiskAnalysis version ${versionId}\n${error}`);

  const version = data.riskAnalysisVersion;
  const noMembers = version.memberVersions.length === 0;

  const columns = getColumns(version, unlinkRisk, changeRiskVersion, setAddVersionModal, activeWorkspaceView, classes);

  return (
    <Grid item xs={12} classes={{ root: classes.container }}>
      <SetsControlBar
        titleAddMember={'Add Risk'}
        titleCreateMember={'Create Risk'}
        versionData={data.riskAnalysisVersion}
        refetchQueries={[]}
        memberItemType={['risk']}
        itemSetsIcon={<AssignmentLate fontSize="inherit" />}
        onCreateMember={() => {
          addNewRiskToRiskAnalysis(data.riskAnalysisVersion.id);
        }}
        onAddMember={(selected) => {
          addRisksToRiskAnalysis(data.riskAnalysisVersion.id, selected);
        }}
      />
      <div>
        {noMembers && (
          <NoContentDisclaimer variant="add">
            {({ icon }) => (
              <>
                There's nothing here yet.{'\n'}
                Click {icon()} to create a new risk.
              </>
            )}
          </NoContentDisclaimer>
        )}
        {noMembers || (
          <OptionsProvider>
            <div className={clsx(classes.tableContainer, tableClasses.table)}>
              <AgGridTable columns={columns} data={formatData(version.memberVersions)} />
            </div>
          </OptionsProvider>
        )}
      </div>
      <AddVersionModal
        open={addVersionModal.open}
        onClose={() => {
          setAddVersionModal({ open: false, options: {} });
        }}
        customIdentifier={addVersionModal.options.customIdentifier as string}
        handleComplete={async () => {
          await createNewRiskVersion(addVersionModal.options.id);
          setAddVersionModal({ open: false, options: {} });
        }}
      />
      <ItemCreatedSnackbar
        open={showItemCreatedSnackbar}
        handleSnackbarClose={() => setShowItemCreatedSnackbar(false)}
        customIdentifier={customIdentifier}
        itemId={itemId}
        selectedItemType={'risk'}
      />
    </Grid>
  );
}

export default flowRight([withWorkspaceViews(), withLockedContext])(PayloadFields);
