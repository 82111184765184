import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    pickerWrapper: {
      background: '#FFFFFF',
      position: 'relative',
      maxWidth: '315px',
    },
    label: {
      marginBottom: theme.spacing(1),
      display: 'inline-block',

      '& > span': {
        marginLeft: theme.spacing(1),
        lineHeight: '22px',
        verticalAlign: 'middle',
      },
    },
    createButton: {
      ...theme.typography.body2,
      height: '30px',
      lineHeight: '30px',
      cursor: 'pointer',
    },
    deleteIcon: {
      '&.MuiChip-deleteIcon': {
        width: '15px',
      },
    },
    chip: {
      color: theme.palette.blueDark,
    },
  }),
);
