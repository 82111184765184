import { WorkflowTransition } from 'compositions/TimelineGroup/steps/types';
import { GenericWithMembersWorkflowStatuses } from 'workflows/statuses';
import { CancelRetirement } from 'compositions/TimelineGroup/steps/views';

export default {
  initialStep: 'cancelRetirement',
  internalSteps: {
    cancelRetirement: {
      component: CancelRetirement,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: (props) => ({
    toStatus: GenericWithMembersWorkflowStatuses.RetirementCanceled.id,
    ...props.cancelRetirement.signature,
  }),
} as WorkflowTransition;
