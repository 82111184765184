import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import capitalize from 'lodash/capitalize';

import { AvatarCell } from 'components/TableView/Fields/Avatar';
import { StatusCell } from 'components/TableView/Fields/Status';
import IconButton from 'components/IconButton';
import Select from 'components/core/form/Select';
import MakeLink from 'components/MakeLink';

import { TaskQuery_taskVersion_taskVersionLinkedTasks as LinkedTasks } from '../../__generated__/TaskQuery';
import { TasksRelationship } from '__generated__/globalTypes';

import { useStyles } from './styles';

const normalize = (str: string): string =>
  str
    .split('_')
    .map((r) => capitalize(r))
    .join(' ');

const RELATIONSHIPS_OPTIONS: { value: any; label: string }[] = Object.values(TasksRelationship).map((rel: string) => ({
  value: rel,
  label: normalize(rel),
}));

interface Props {
  data: LinkedTasks[];
  tasksOptions: { value: any; label: string }[];
  unlinkTask: (id: string) => void;
  linkTask: (linkedTaskId: string, relationship: TasksRelationship) => void;
  onRelationshipChange: (row: LinkedTasks) => (e) => void;
  locked: boolean;
}

export default function LinkedTasksTable(props: Props) {
  const { data, unlinkTask, tasksOptions, linkTask, onRelationshipChange } = props;
  const classes = useStyles();

  const isNewLine = (row: LinkedTasks): boolean => !Boolean(row.id);
  const taskHref = (task) => `/category/task/${task.id}`;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.head}>
              <Typography variant={'subtitle1'}>Relationship</Typography>
            </TableCell>
            <TableCell className={classes.head}>
              <Typography variant={'subtitle1'}>ID</Typography>
            </TableCell>
            <TableCell className={classes.head}>
              <Typography variant={'subtitle1'}>Title</Typography>
            </TableCell>
            <TableCell align="center" className={classes.head}>
              <Typography variant={'subtitle1'}>Assignee</Typography>
            </TableCell>
            <TableCell align="center" className={classes.head}>
              <Typography variant={'subtitle1'}>Status</Typography>
            </TableCell>
            <TableCell className={classes.head} />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell className={classes.cell}>
                {isNewLine(row) ? (
                  <Select
                    options={RELATIONSHIPS_OPTIONS}
                    defaultValue={row.relationship}
                    onChange={onRelationshipChange(row)}
                  />
                ) : (
                  <Typography variant={'body2'}>{normalize(row.relationship as string)}</Typography>
                )}
              </TableCell>
              <TableCell className={classes.cell}>
                {isNewLine(row) ? (
                  <Autocomplete
                    options={tasksOptions}
                    style={{ width: 300 }}
                    renderOption={(option) => <Typography variant="body2">{option.label}</Typography>}
                    getOptionLabel={(option) => option.label}
                    disabled={Boolean(!row.relationship)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        classes={{
                          root: classes.autocompleteInputRoot,
                        }}
                        fullWidth
                        variant="outlined"
                        margin="none"
                        placeholder="Search tasks"
                      />
                    )}
                    value={null}
                    onChange={(_, selectedOption) => {
                      if (selectedOption) {
                        linkTask(selectedOption.value, row.relationship as TasksRelationship);
                      }
                    }}
                  />
                ) : (
                  <Typography variant={'body2'}>
                    <MakeLink newTab={true} href={taskHref(row.linkedTask.item)}>
                      {row.linkedTask.item.customIdentifier}{' '}
                    </MakeLink>
                  </Typography>
                )}
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography variant={'body2'}>{row.linkedTask.title}</Typography>
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                {row.linkedTask.assignedPerson && <AvatarCell data={row.linkedTask.assignedPerson} />}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {row.linkedTask.currentStatus && <StatusCell data={row.linkedTask.currentStatus.name} />}
              </TableCell>
              <TableCell align="right" className={classes.actionCell}>
                {props.locked || (
                  <IconButton
                    Icon={Clear}
                    onClick={() => unlinkTask(row.linkedTask.id)}
                    size="small"
                    includeBackground
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
