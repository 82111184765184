import React from 'react';

import { Typography } from '@material-ui/core';

import capitalize from 'lodash/capitalize';

import { useStyles } from './styles';

export function BooleanCell({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Typography variant={'body2'}>{capitalize(String(data))}</Typography>
    </div>
  );
}

export const booleanHeaderStyle = { textAlign: 'left' };
