import React from 'react';

import { ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  Icon: ComponentType<SvgIconProps>;
}

export default function TabIcon(props: Props) {
  const classes = useStyles();
  const Icon = props.Icon;
  return <Icon className={classes.icon} />;
}
