import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import { withStyles, Paper } from '@material-ui/core';

import { Dialog, PrintViewMenuItem } from 'components';
import UpdateReferenceId from 'compositions/UpdateReferenceId';

import styles from './styles';
import RefIdMenuItem from './ReferenceIdMenuItem';
import MenuContainer from './MenuContainer';

function canHaveGenerateOptionsIdAndTitle(itemType) {
  return ['traceMatrix'].includes(itemType);
}

function canHaveGenerateOptionsGroupedByRequirementType(itemType) {
  return ['requirementsSet'].includes(itemType);
}

function canHaveGenerateOptionsGroupedByStakeholders(itemType) {
  return ['userNeedsSet'].includes(itemType);
}

function canHaveGenerateOptionsGroupedByFolder(itemType) {
  return ['requirementsSet', 'userNeedsSet'].includes(itemType);
}

function shouldHaveLandscapeView(itemType) {
  return ['riskAnalysis'].includes(itemType);
}

class EllipsesMenu extends Component {
  state = {
    modalOpen: false,
    customGenerateOptions: [],
  };

  componentDidMount() {
    if (canHaveGenerateOptionsIdAndTitle(this.props.itemType)) {
      this.setState((prevState) => ({
        customGenerateOptions: [
          ...prevState.customGenerateOptions,
          {
            text: 'Generate PDF with Titles',
            handler: () => {
              this.props.hideMenu();
              this.props.onGeneratePDFClicked();
              this.props.onGeneratePDFWithTitlesClicked();
            },
          },
          {
            text: 'Generate PDF with IDs',
            handler: () => {
              this.props.hideMenu();
              this.props.onGeneratePDFClicked();
              this.props.onGeneratePDFWithIdsClicked();
            },
          },
        ],
      }));
    }

    if (canHaveGenerateOptionsGroupedByRequirementType(this.props.itemType)) {
      this.setState((prevState) => ({
        customGenerateOptions: [
          ...prevState.customGenerateOptions,
          {
            text: 'Generate All Items PDF',
            handler: () => {
              this.props.hideMenu();
              this.props.onGeneratePDFClicked();
            },
          },
          {
            text: 'Generate Grouped By Type PDF',
            handler: () => {
              this.props.hideMenu();
              this.props.onGeneratePDFClicked();
              this.props.onGeneratePDFGroupedByRequirementType();
            },
          },
        ],
      }));
    }

    if (canHaveGenerateOptionsGroupedByStakeholders(this.props.itemType)) {
      this.setState((prevState) => ({
        customGenerateOptions: [
          ...prevState.customGenerateOptions,
          {
            text: 'Generate All Items PDF',
            handler: () => {
              this.props.hideMenu();
              this.props.onGeneratePDFClicked();
            },
          },
          {
            text: 'Generate Grouped By Stakeholders PDF',
            handler: () => {
              this.props.hideMenu();
              this.props.onGeneratePDFClicked();
              this.props.onGeneratePDFGroupedByStakeholders();
            },
          },
        ],
      }));
    }

    if (canHaveGenerateOptionsGroupedByFolder(this.props.itemType)) {
      this.setState((prevState) => ({
        customGenerateOptions: [
          ...prevState.customGenerateOptions,
          {
            text: 'Generate Grouped By Folder PDF',
            handler: () => {
              this.props.hideMenu();
              this.props.onGeneratePDFClicked();
              this.props.onGeneratePDFGroupedByFolder();
            },
          },
        ],
      }));
    }
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false, confirmationModalOpen: false });
  };

  render() {
    const { referenceId, refetchQueries, itemId } = this.props;
    const { modalOpen } = this.state;

    let visibility = this.props.menuHidden ? 'hidden' : 'visible';
    return (
      <>
        <div style={{ visibility, boxShadow: '0px 2px 20px 1px rgba(182, 182, 182, 0.25)' }}>
          <Paper>
            <MenuContainer>
              {this.props.onGeneratePDFClicked && (
                <PrintViewMenuItem
                  customGenerateOptions={this.state.customGenerateOptions}
                  defaultGenerateOption={{
                    text: 'Generate PDF',
                    handler: () => {
                      if (shouldHaveLandscapeView(this.props.itemType)) {
                        this.props.setLandscapeView();
                      }
                      this.props.hideMenu();
                      this.props.onGeneratePDFClicked();
                    },
                  }}
                />
              )}

              <RefIdMenuItem
                referenceId={referenceId}
                onClick={() => {
                  this.props.hideMenu();
                  this.handleModalOpen();
                }}
              />
            </MenuContainer>
          </Paper>
        </div>
        <Dialog open={modalOpen} onClose={this.handleModalClose}>
          <UpdateReferenceId
            itemId={itemId}
            initialValue={referenceId}
            onClose={this.handleModalClose}
            refetchQueries={refetchQueries}
          />
        </Dialog>
      </>
    );
  }
}

EllipsesMenu.propTypes = {
  onGeneratePDFClicked: PropTypes.func,
  hideMenu: PropTypes.func.isRequired,
  menuHidden: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    menuContainer: PropTypes.string.isRequired,
    menuItemText: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }),
};

export default withStyles(styles)(EllipsesMenu);
