import React from 'react';
import { Grid } from '@material-ui/core';
import { Spacer } from 'components';
import { DocumentStyleUploader, PDFViewer } from 'components/v2';

interface Props {
  layoutClasses: any;
  versionData: any;
  refetch?: any;
}

export default function PDFData(props: Props) {
  const { layoutClasses, versionData, refetch } = props;

  return (
    <Grid container justify="flex-end" alignItems="center" direction="row" className={layoutClasses.grid}>
      <Grid item xs={12}>
        <DocumentStyleUploader versionData={versionData} onUpdate={() => refetch()} />
      </Grid>
      {versionData.attachment && (
        <Grid item xs={12} className={`${layoutClasses.grid} ${layoutClasses.pdfView}`}>
          <Spacer factor={2} />
          <PDFViewer url={versionData.attachment.url} />
        </Grid>
      )}
    </Grid>
  );
}
