import React from 'react';
import { TextField, Button, Typography, withStyles } from '@material-ui/core';
import { Spacer } from 'components';

const styles = (theme) => ({
  textReason: {
    width: '100%',
    margin: 0,
  },
  input: {
    fontSize: 'inherit',
    color: 'inherit',
    lineHeight: 'inherit',
  },
});

const TextAreaAndButton = ({
  buttonId,
  buttonText,
  buttonDisabled,
  classes,
  initialValue,
  label,
  onTextChange,
  onButtonClick,
  rows,
}) => (
  <>
    <Typography
      variant="body2"
      component="div" // div cannot be descendant of p
    >
      <TextField
        id="change-justify"
        multiline
        fullWidth
        rows={rows}
        onChange={onTextChange}
        margin="normal"
        variant="outlined"
        className={classes.textReason}
        InputProps={{
          classes: { root: classes.input },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={initialValue}
        label={label}
      />

      <Spacer factor={2} />

      <Button
        disabled={buttonDisabled}
        id={buttonId}
        variant="contained"
        color="primary"
        fullWidth
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
    </Typography>
  </>
);

export default withStyles(styles)(TextAreaAndButton);
