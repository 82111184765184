import gql from 'graphql-tag';

export const RISK_MANAGEMENT_DASHBOARD_QUERY = gql`
  query RiskDashboard {
    riskDashboard {
      acceptable
      alarp
      cannotBeDetermined
      intolerable
      tolerable
      totalRisk
      unacceptable
      unassignedToRiskAnalysis
      undefined
    }
    riskMatrixEntries {
      id
      harmSeverityLevel {
        id
        level
        name
        color
      }
      hazardousSituationProbabilityLevel {
        id
        name
        level
        color
      }
      riskPriorityCode {
        id
        name
        fullName
        color
        updatedAt
        createdAt
      }
    }
  }
`;
