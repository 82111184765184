import gql from 'graphql-tag';
import {
  DesignOutputVersion as VersionQuery,
  DesignOutputVersionVariables as VersionProps,
} from './__generated__/DesignOutputVersion';
import {
  CreateDesignOutputAttachment as Create,
  CreateDesignOutputAttachmentVariables as CreateVars,
} from './__generated__/CreateDesignOutputAttachment';
import {
  UpdateDesignOutputAttachment as Update,
  UpdateDesignOutputAttachmentVariables as UpdateVars,
} from './__generated__/UpdateDesignOutputAttachment';
import {
  DestroyDesignOutputAttachment as Destroy,
  DestroyDesignOutputAttachmentVariables as DestroyVars,
} from './__generated__/DestroyDesignOutputAttachment';
import { useQuery, useMutation } from 'react-apollo';

export const createAttachment = gql`
  mutation CreateDesignOutputAttachment(
    $attachmentURL: String!
    $versionId: ID!
  ) {
    createDesignOutputAttachment(
      attachment: $attachmentURL
      versionId: $versionId
    ) {
      designOutputAttachment {
        id
        url
        versionId
      }
    }
  }
`;

export function useCreateAttachment() {
  return useMutation<Create, CreateVars>(createAttachment, {
    refetchQueries: ['DesignOutputVersion'],
  });
}

export const updateAttachment = gql`
  mutation UpdateDesignOutputAttachment(
    $attachmentId: ID!
    $attachmentURL: String
    $previewURL: String
    $description: String
  ) {
    updateDesignOutputAttachment(
      id: $attachmentId
      attachment: $attachmentURL
      preview: $previewURL
      description: $description
    ) {
      designOutputAttachment {
        id
        url
        previewUrl
        description
        versionId
      }
    }
  }
`;

export function useUpdateAttachment() {
  return useMutation<Update, UpdateVars>(updateAttachment);
}

export const query = gql`
  query DesignOutputVersion($id: ID!) {
    designOutputVersion(id: $id) {
      id
      description
      locked
      attachments {
        id
        url
        previewUrl
        description
      }
    }
  }
`;

export function useVersion(id?: string) {
  const variables = id ? { id } : undefined;
  return useQuery<VersionQuery, VersionProps>(query, {
    skip: !id,
    variables,
  });
}

export const destroyAttachmentMutation = gql`
  mutation DestroyDesignOutputAttachment($id: ID!) {
    destroyDesignOutputAttachment(id: $id) {
      designOutputAttachment {
        id
      }
    }
  }
`;

export function useDestroyAttachment() {
  return useMutation<Destroy, DestroyVars>(destroyAttachmentMutation, {
    refetchQueries: ['DesignOutputVersion'],
  });
}
