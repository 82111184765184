import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import { Folder, People, PersonAdd, Reorder } from '@material-ui/icons';

import { ItemCreatedSnackbar } from 'components';
import DesignServices from 'assets/icons/DesignServices';
import SetsControlBar from 'components/LongCardControls/SetsControlBar';
import withCurrentAppWindow from 'compositions/WithCurrentAppWindow';
import withProducts from 'compositions/WithProducts';
import { PayloadFieldsProps } from 'itemTypes';
import OptionsDropdown from 'components/OptionsDropdown';

import { addFolder, useVersion, addMembers, linkMembers, bulkUpdateMembersMutation } from './gql';
import { filterFolders, findFolderByName } from './util';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import TableContainer from './TableContainer';

function PayloadFields(props: PayloadFieldsProps & { activeWorkspaceView: { id: string } }) {
  const { versionId, itemRecord } = props;
  const { data } = useVersion(versionId!);
  const [itemId, setItemId] = useState(null);
  const [customIdentifier, setCustomIdentifier] = useState('');
  const [showItemCreatedSnackbar, setShowItemCreatedSnackbar] = useState(false);

  const onCreateMember = (data) => {
    const newRequirement = data.linkNewRequirementVersionToRequirementsSet.requirementVersion.item;
    setItemId(newRequirement.id);
    setCustomIdentifier(newRequirement.customIdentifier);
    setShowItemCreatedSnackbar(true);
  };

  const [createRequirement] = useMutation(linkMembers, {
    variables: {
      input: {
        requirementsSetVersionId: versionId,
      },
    },
    update: (_, { data }) => {
      onCreateMember(data);
    },
    refetchQueries: ['RequirementsSetVersionQuery'],
  });
  const [bulkUpdateMembers] = useMutation(bulkUpdateMembersMutation, {
    refetchQueries: ['RequirementsSetVersionQuery'],
  });
  const [createMembers] = useMutation(addMembers);
  const [createFolder] = useMutation(addFolder);
  const [tableDisplayVariant, setTableDisplayVariant] = useState('allItems');
  const [isCustomGrouping, setIsCustomGrouping] = useState(false);

  useEffect(() => {
    const savedDisplayVariant = localStorage.getItem('qms/REQUIREMENTS_SET_TABLE_DISPLAY_VARIANT');
    if (savedDisplayVariant) {
      setTableDisplayVariant(savedDisplayVariant);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('qms/REQUIREMENTS_SET_TABLE_DISPLAY_VARIANT', tableDisplayVariant);
  }, [tableDisplayVariant]);

  const handleUpdateOrderingAndGrouping = (updatedMembers) => {
    const folders = filterFolders(data);
    const formattedMembers = updatedMembers.map((member) => ({
      sortPositionsJson: { ...member.sortPositionsJson },
      id: member.id,
      folderOne: findFolderByName(member.folderOne, folders),
      folderTwo: findFolderByName(member.folderTwo, folders),
      folderThree: findFolderByName(member.folderThree, folders),
      requirementTypes: member.currentVersion.requirementTypes && [...member.currentVersion.requirementTypes],
    }));

    bulkUpdateMembers({ variables: { members: formattedMembers } }).catch((err) => console.error('err', err));
  };

  const handleColumnRowGroupChange = (params) => {
    const foldersColumns = ['folderOneSelect', 'folderTwoSelect', 'folderThreeSelect'];
    const columns = params.columns.map((column) => column.colId);

    if (columns.length === 0) {
      setTableDisplayVariant('allItems');
      setIsCustomGrouping(false);
      return;
    }

    if (columns.includes('currentVersion.requirementTypes') && columns.length === 1) {
      setTableDisplayVariant('requirementType');
      setIsCustomGrouping(false);
      return;
    }

    if (foldersColumns.every((column) => columns.includes(column)) && columns.length === 3) {
      setTableDisplayVariant('folders');
      setIsCustomGrouping(false);
      return;
    }

    if (
      foldersColumns.every((column) => columns.includes(column)) &&
      columns.includes('currentVersion.requirementTypes') &&
      columns.length === 4
    ) {
      setTableDisplayVariant('requirementTypeAndFolders');
      setIsCustomGrouping(false);
      return;
    }

    setIsCustomGrouping(true);
    setTableDisplayVariant('allItems');
  };

  const handleCreateFolder = async (folderName: string, indentationLevel: number) => {
    try {
      await createFolder({
        variables: {
          requirementsSetVersionId: versionId,
          folderName,
          sortPosition: 0,
          indentationLevel,
        },
        refetchQueries: ['RequirementsSetVersionQuery'],
      });
    } catch (error) {
      throw new Error(`Error creating folder ${folderName}\n${error}`);
    }
  };

  if (!data || !itemRecord) return null;

  return (
    <div
      style={{
        width: '100%',
        minHeight: '50vh',
      }}
    >
      {get(data, 'requirementsSetVersion.locked') === false && (
        <Grid container alignItems="center" justify="space-between" spacing={1}>
          <Grid item>
            <SetsControlBar
              shouldShowCreateFolderButton
              versionData={get(data, 'requirementsSetVersion')}
              refetchQueries={['RequirementsSetVersionQuery']}
              memberItemType={['requirement']}
              itemSetsIcon={<DesignServices fontSize="inherit" />}
              onCreateMember={createRequirement}
              onAddMember={(selected) => {
                return createMembers({
                  variables: {
                    requirementsSetVersionId: versionId,
                    requirementVersionIds: selected,
                    sortPosition: 0,
                    indentationLevel: 0,
                  },
                  refetchQueries: ['RequirementsSetVersionQuery'],
                });
              }}
              onCreateFolder={handleCreateFolder}
              titleCreateMember="Create New"
              titleAddMember="Add Existing"
            />
          </Grid>
        </Grid>
      )}
      <div style={{ marginTop: 12 }}>
        {data && (
          <TableContainer
            data={data}
            activeWorkspaceView={props.activeWorkspaceView}
            DisplayVariantSelect={() => (
              <OptionsDropdown
                value={tableDisplayVariant}
                onChange={(event) => {
                  setTableDisplayVariant(event.target.value);
                  setIsCustomGrouping(false);
                }}
                optionsTitle="Views"
                options={[
                  {
                    value: 'allItems',
                    Icon: Reorder,
                    text: 'All Items',
                  },
                  {
                    value: 'requirementType',
                    Icon: People,
                    text: 'Type',
                  },
                  {
                    value: 'requirementTypeAndFolders',
                    Icon: PersonAdd,
                    text: 'Type & Folders',
                  },
                  {
                    value: 'folders',
                    Icon: Folder,
                    text: 'Folders',
                  },
                ]}
              />
            )}
            handleUpdateOrderingAndGrouping={handleUpdateOrderingAndGrouping}
            handleColumnRowGroupChanged={handleColumnRowGroupChange}
            isCustomGrouping={isCustomGrouping}
            isGroupedByType={tableDisplayVariant === 'requirementType'}
            isGroupedByFolders={tableDisplayVariant === 'folders'}
            isGroupedByTypeAndFolders={tableDisplayVariant === 'requirementTypeAndFolders'}
          />
        )}
      </div>
      <ItemCreatedSnackbar
        open={showItemCreatedSnackbar}
        handleSnackbarClose={() => setShowItemCreatedSnackbar(false)}
        customIdentifier={customIdentifier}
        itemId={itemId}
        selectedItemType={'requirement'}
      />
    </div>
  );
}

export default flowRight([withCurrentAppWindow, withProducts, withWorkspaceViews()])(PayloadFields);
