import React, { FC } from 'react';
import { Mutation } from 'react-apollo';
import get from 'lodash/get';

import LibraryAddCheckOutline from 'assets/icons/LibraryAddCheckOutline';
import GenericNotificationListItem from '.';
import ItemIdAndTitle from './components/ItemIdAndTitle';
import VerbAndAssignor from './components/VerbAndAssignor';

import {
  updateTaskCompletedMutationVariables,
  updateTaskCompletedMutation_updateTaskCompleted,
} from './__generated__/updateTaskCompletedMutation';
import { taskMutation } from './gql';
import { userNotificationsQuery_user_taskNotifications } from '../../__generated__/userNotificationsQuery';
import linkToItem from 'utils/linkToItem';

interface Props {
  notification: userNotificationsQuery_user_taskNotifications;
  onRedirect: () => void;
}

const TaskBody: FC<Props> = ({ notification, onRedirect }) => {
  const payload = {
    variables: {
      input: {
        notificationId: notification.id,
        completed: !notification.completed,
      },
    },
  };

  const ItemTitle = () => (
    <>
      <ItemIdAndTitle
        link={linkToItem('task', get(notification, 'task.item.id'))}
        onRedirect={onRedirect}
        identifier={get(notification, 'task.item.customIdentifier')}
        title={get(notification, 'task.title')}
      />
      <VerbAndAssignor
        beforeVerb="assigned by"
        userId={get(notification, 'assignedBy.id')}
        fullName={get(notification, 'assignedBy.fullName')}
      />
    </>
  );

  return (
    <Mutation<updateTaskCompletedMutation_updateTaskCompleted, updateTaskCompletedMutationVariables>
      mutation={taskMutation}
      refetchQueries={['userNotificationsQuery', 'currentUser']}
    >
      {(mutate, { error }) => {
        if (error) throw new Error(`${error}`);

        return (
          <GenericNotificationListItem
            headlineIcon={LibraryAddCheckOutline}
            headlineText={notification.headline}
            itemTitle={<ItemTitle />}
            createdAt={notification.createdAt}
            completed={notification.completed}
            onComplete={() => mutate(payload)}
          />
        );
      }}
    </Mutation>
  );
};

export default TaskBody;
