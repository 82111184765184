import React, { ComponentType, useState } from 'react';

import { Paper, SvgIconProps, withStyles } from '@material-ui/core';

import { Spacer } from 'components';
import LongCardHeader from 'components/LongCardHeader';
import BottomRowControls from 'components/LongCardControls/BottomRowControls';
import TopRowControls from 'components/LongCardControls/TopRowControls';

import LongCardBody from './LongCardBody';
import styles from './styles';

interface BodyTextStruct {
  attrName: string;
  attrValue: string;
}

interface LongCardProps {
  children?: JSX.Element[] | JSX.Element;
  classes: any;
  controlsLoading?: boolean;
  customIdentifier?: string;
  globalId?: string;
  expandCarrotVisible?: boolean;
  handleTitlePrefix?: (e: any) => void;
  helpTextEntries?: JSX.Element[];
  hideCheckbox?: boolean;
  itemRecord?: string;
  locked?: boolean;
  onCheckboxClick?: () => void;
  onClickPlus?: (e: any) => void;
  onRemoveIconClick?: (e: any) => void;
  onTitleChange?: (e: any) => void;
  parentSetItemSlug?: string;
  primaryBodyText?: BodyTextStruct;
  queryToRefetch?: string | any; // gql query or string
  removeIcon: ComponentType<SvgIconProps>;
  rightColumnComponent?: JSX.Element;
  secondaryBodyText?: BodyTextStruct;
  showBody?: boolean;
  showRemoveIcon: boolean;
  title: string;
  titleDisplayVariant?: string;
  variant: 'vertical' | 'horizontal';
  versionId?: string;
}

function LongCard({
  children,
  classes,
  controlsLoading = false,
  customIdentifier,
  expandCarrotVisible = true,
  globalId,
  handleTitlePrefix,
  helpTextEntries,
  hideCheckbox = false,
  itemRecord,
  locked,
  onCheckboxClick,
  onClickPlus,
  onRemoveIconClick,
  onTitleChange,
  parentSetItemSlug,
  primaryBodyText,
  queryToRefetch,
  removeIcon,
  rightColumnComponent,
  secondaryBodyText,
  showBody = true,
  showRemoveIcon = false,
  title,
  titleDisplayVariant,
  variant,
  versionId,
}: LongCardProps) {
  const [showChildren, setShowChildren] = useState(true);

  return (
    <>
      <Paper elevation={0} className={classes.panel}>
        <LongCardHeader
          onTitleChange={onTitleChange}
          onCheckboxClick={onCheckboxClick}
          helpTextEntries={helpTextEntries}
          title={title}
          titleEditable={!locked}
          globalId={globalId}
          customIdentifier={customIdentifier}
          titleDisplayVariant={titleDisplayVariant}
          handleTitlePrefix={handleTitlePrefix}
          hideCheckbox={hideCheckbox}
          primaryBodyText={primaryBodyText}
          locked={locked}
          Controls={
            <TopRowControls
              onClickPlus={onClickPlus}
              loading={controlsLoading}
              expandCarrot={{
                onClick: () => setShowChildren(!showChildren),
                expanded: showChildren,
                visible: variant === 'vertical' && expandCarrotVisible,
              }}
              removeFromSetIcon={{
                visible: showRemoveIcon,
                onClick: onRemoveIconClick,
                icon: removeIcon,
              }}
            />
          }
        />
        <Spacer factor={1} />
        <div className={classes.panelContent}>
          {showBody && (
            <>
              <LongCardBody
                versionId={versionId}
                primaryBodyText={primaryBodyText}
                secondaryBodyText={secondaryBodyText}
                locked={locked}
                variant={variant}
                queryToRefetch={queryToRefetch}
                itemRecord={itemRecord}
                rightColumnComponent={rightColumnComponent}
              />
              <BottomRowControls
                versionId={versionId}
                itemRecord={itemRecord}
                queryToRefetch={queryToRefetch}
                parentSetItemSlug={parentSetItemSlug}
              />
            </>
          )}
        </div>
      </Paper>

      {showChildren && children}
    </>
  );
}

export default withStyles(styles)(LongCard);
