import React from 'react';
import { DateTime } from 'luxon';
import { Paper, Typography } from '@material-ui/core';

import { UserAvatar } from 'components';
import TextWithMentions from 'components/TextWithMentions';

export interface MessageProps {
  classes;
  id: string;
  createdAt: Date;
  body: string;
  creator: {
    id: string;
    fullName: string;
  };
}

function Message({ classes, ...props }: MessageProps) {
  return (
    <div className={classes.chatMessage}>
      <div className={classes.avatarContainer}>
        <UserAvatar diameter={24} userId={props.creator.id} />
      </div>
      <span className={classes.commentDate}>
        {DateTime.fromISO(props.createdAt).toLocaleString(DateTime.DATE_FULL)}
      </span>
      <Paper
        component={'div'}
        elevation={0}
        classes={{ root: classes.chatMessageContent }}
      >
        <div className={classes.timestamp}>
          <Typography variant="caption">
            {DateTime.fromISO(props.createdAt).toLocaleString(
              DateTime.TIME_SIMPLE,
            )}
          </Typography>
        </div>

        <div className={classes.messageBody}>
          <Typography variant="body2" className={classes.from}>
            {props.creator.fullName}
          </Typography>
          <Typography variant="body2">
            <TextWithMentions content={props.body} />
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

export default Message;
