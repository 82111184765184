import PropTypes from 'prop-types';
import React, { Component } from 'react';
import gql from 'graphql-tag';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { Query, Mutation } from 'react-apollo';

import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import AddCircle from '@material-ui/icons/AddCircle';

import { Loading, Spacer, UserListItem } from 'components';
import { ItemTypeIndex } from 'itemTypes';
import { withEnabledCategories, withDisabledCategories } from '../../WithCategories';
import { allItemTypesQuery as ITEM_TYPE_QUERY } from 'utils/gql/metadataQueries';
import withJobRoles from 'compositions/WithJobRoles';
import WithCurrentUser from 'compositions/WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import styles from './styles';

const CREATE_ITEM_MUTATION = gql`
  mutation createItem($input: CreateItemInput!) {
    createItem(input: $input) {
      item {
        id
        customIdentifier
        itemType {
          id
          name
        }
        currentVersion {
          id
        }
      }
    }
  }
`;

class ItemTypeSelector extends Component {
  state = {
    itemId: null,
    itemType: '',
    ownerRole: null,
    ownerUser: null,
    approverRole: null,
    approverUser: null,
    title: '',
    hasError: false,
    mutationError: null,
    itemTypeSelectorOpen: false,
    ownerRoleSelectorOpen: false,
    ownerUserSelectorOpen: false,
    approverRoleSelectorOpen: false,
    approverUserSelectorOpen: false,
    isHoveringDropdown: false,
    username: '',
    password: '',
  };

  renderItemTypeOption = (itemType) => {
    const key = camelCase(itemType);
    const t = ItemTypeIndex[key];
    if (t === undefined) return;
    const { icon: Icon, label } = t;
    return (
      <>
        <Typography variant="body2">
          <Icon />
          <span className={this.props.classes.itemTypeText}>{label.id}</span>
        </Typography>
      </>
    );
  };

  renderJobRoleOption = (jobRole) => {
    return (
      <MenuItem value={jobRole} key={jobRole.id} className={this.props.classes.menuOption}>
        <Typography variant="body2">
          <span className={this.props.classes.itemTypeText}>{jobRole.formattedRoleName}</span>
        </Typography>
      </MenuItem>
    );
  };

  renderUsersWithSelectedJobRole = (user) => {
    return (
      <MenuItem value={user} key={user.id} className={this.props.classes.menuOption}>
        <Typography variant="body2">
          <span className={this.props.classes.itemTypeText}>{user.fullName}</span>
        </Typography>
      </MenuItem>
    );
  };

  handleChange = (name, data = {}) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleClick = () => {
    if (this.state.itemType === '' && this.state.title === '') {
      this.setState({ hasError: true });
    }
  };

  newItemTypeData = (data) => {
    this.setState({
      itemId: data.createItem.item.id,
      customIdentifier: data.createItem.item.customIdentifier,
      itemType: this.state.itemType,
    });
  };

  render() {
    const {
      classes,
      defaultItemType,
      handleModalClose,
      enabledCategories,
      showSnackbar,
      onCreate,
      jobRoles,
      currentCompanyAllowsCustomPermissions,
      currentUser,
    } = this.props;

    const {
      itemType,
      title,
      ownerRole,
      ownerUser,
      approverRole,
      approverUser,
      hasError,
      mutationError,
      username,
      password,
    } = this.state;

    const itemTypeOrDefault = itemType || snakeCase(defaultItemType) || '';

    const unAssignableJobRoles = ['Custom Role', 'System', 'General', 'Admin'];
    const assignableJobRoles =
      jobRoles !== undefined && jobRoles.filter((jobRole) => !unAssignableJobRoles.includes(jobRole.formattedRoleName));

    const itemTypesAssignedDefaultTitle = ['risk'];
    const generateDefaultTitle = itemTypesAssignedDefaultTitle.includes(itemTypeOrDefault);
    const isTaskItem = defaultItemType === 'task' || itemType === 'task';

    /*
      The user_creatable attribute actually signifies item types that are "singletons". Here we actually
      list the item types that cannot be manually created by users.
    */
    const blacklistedItemTypesSet = new Set([
      'deviation',
      'device-verifications',
      'form-records',
      'final-device-inspection-records',
      'incoming-quality-inspection-records',
      'in-process-inspection-records',
      'supplier-questionnaire-record',
      'target-product-profile-characteristics',
      'training-plans',
      'training-records',
      'validation-test-steps',
      'validation-protocols',
      'validation-record',
      'verification-record',
      'vv-reports',
    ]);
    const enabledCategoriesSet = new Set(enabledCategories);
    const itemTypeMeta = ItemTypeIndex[camelCase(itemTypeOrDefault)];
    const otherRefetchQueries = this.props.refetchQueries
      ? Array.isArray(this.props.refetchQueries)
        ? this.props.refetchQueries
        : [this.props.refetchQueries]
      : [];

    return (
      <Card classes={{ root: classes.root }} data-testid="item-type-selector" elevation={5} tabIndex={1}>
        <Query query={ITEM_TYPE_QUERY}>
          {({ data, error }) => {
            if (error) throw new Error(`${error}`);
            const itemTypes = data.itemTypes || [];

            const defaultOwnerRole =
              itemTypeOrDefault &&
              data.itemTypes
                .find((itemType) => itemType.name === itemTypeOrDefault)
                .permissions.find((permission) => permission.permissionType === 'own_record').jobRole;

            const defaultApproverRole =
              itemTypeOrDefault &&
              data.itemTypes
                .find((itemType) => itemType.name === itemTypeOrDefault)
                .permissions.find((permission) => permission.permissionType === 'approve_record').jobRole;

            const userSelectedOwnerRoleOrDefault = ownerRole || defaultOwnerRole;
            const userSelectedApproverRoleOrDefault = approverRole || defaultApproverRole;
            const itemTypeOptions = itemTypes
              .filter(
                (i) =>
                  i.userCreatable &&
                  enabledCategoriesSet.has(i.displaySlug) &&
                  !blacklistedItemTypesSet.has(i.displaySlug),
              )
              .map((i) => i.name);

            const isSetMemberItemType = get(
              data.itemTypes.find((itemType) => itemType.name === itemTypeOrDefault),
              'parentSetItemType',
              null,
            );

            return (
              <CardContent className={classes.content}>
                <Spacer factor={2} />
                <AddCircle color="primary" style={{ margin: '0 auto', fontSize: 43 }} />
                <Spacer factor={1} />
                <Typography variant="h3" align="center">
                  Create New Item
                </Typography>
                <Spacer factor={1} />
                <Typography variant="body2" align="center">
                  Use this form to create a new item in the system
                </Typography>
                <Spacer factor={4} />
                <form className={classes.formContainer}>
                  <FormControl fullWidth required error={hasError}>
                    <Autocomplete
                      onOpen={() => this.setState({ itemTypeSelectorOpen: true })}
                      onClose={() => this.setState({ itemTypeSelectorOpen: false })}
                      open={this.state.itemTypeSelectorOpen}
                      options={itemTypeOptions}
                      defaultValue={defaultItemType}
                      renderOption={(option) => this.renderItemTypeOption(option)}
                      getOptionLabel={(option) => startCase(option)}
                      selectOnFocus
                      disableClearable
                      renderInput={(params) => (
                        <TextField {...params} margin="none" fullWidth placeholder="Item Type" variant="outlined" />
                      )}
                      onChange={(event, newValue) => this.setState({ itemType: newValue })}
                      noOptionsText="No matching item type"
                      classes={{
                        paper: classes.itemTypePaper,
                        option: classes.itemTypeOption,
                        inputRoot: classes.itemTypeInput,
                      }}
                    />
                  </FormControl>
                  <Spacer factor={1} />
                  <OutlinedInput
                    className={classes.textInput}
                    classes={{ input: classes.outlinedInput }}
                    fullWidth
                    id="item-name-input"
                    type="text"
                    placeholder={generateDefaultTitle ? 'Default Title' : 'Item Title'}
                    disabled={generateDefaultTitle}
                    required
                    onChange={this.handleChange('title')}
                    value={!generateDefaultTitle ? title : 'Default Title'}
                  />

                  {hasError && <FormHelperText>This field is required</FormHelperText>}
                  {mutationError && <FormHelperText>{mutationError}</FormHelperText>}
                  <Spacer factor={3} />
                  <Typography align="left" variant="subtitle1">
                    Owner Permissions
                  </Typography>
                  <Spacer factor={1} />
                  {isSetMemberItemType || isTaskItem ? (
                    <Typography variant="body2">N/A {isSetMemberItemType ? '- Managed by set' : null}</Typography>
                  ) : (
                    <>
                      <FormControl fullWidth required error={hasError}>
                        <Tooltip title={currentCompanyAllowsCustomPermissions ? '' : 'Upgrade license to edit'}>
                          <Select
                            className={classes.selectMenu}
                            value={ownerRole}
                            renderValue={() =>
                              (userSelectedOwnerRoleOrDefault && userSelectedOwnerRoleOrDefault.formattedRoleName) ||
                              'Role'
                            }
                            onChange={this.handleChange('ownerRole')}
                            onClose={() => this.setState({ ownerRoleSelectorOpen: false })}
                            onOpen={() => this.setState({ ownerRoleSelectorOpen: true })}
                            open={this.state.ownerRoleSelectorOpen}
                            input={
                              <OutlinedInput
                                labelWidth={0}
                                name="ownerRole"
                                required
                                classes={{ input: classes.outlinedInput }}
                              />
                            }
                            inputProps={{ classes: { icon: classes.dropdownIcon } }}
                            displayEmpty
                            fullWidth
                            required
                            disabled={!currentCompanyAllowsCustomPermissions || !(itemType || defaultItemType)}
                            onMouseEnter={() => this.setState({ isHoveringDropdown: true })}
                            onMouseLeave={() => this.setState({ isHoveringDropdown: false })}
                            MenuProps={{
                              classes: { paper: classes.menuDropdown },
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                            }}
                          >
                            <MenuItem value={null} disabled>
                              <Typography variant="body2">Role</Typography>
                            </MenuItem>
                            {(assignableJobRoles || [])
                              .filter((role) => role.id !== get(userSelectedApproverRoleOrDefault, 'id', ''))
                              .filter((role) => role.status !== 'archived')
                              .map((jobRole) => this.renderJobRoleOption(jobRole))}
                          </Select>
                        </Tooltip>
                      </FormControl>
                      <Spacer factor={1} />
                      <FormControl fullWidth required error={hasError}>
                        <Select
                          className={classes.selectMenu}
                          value={ownerUser}
                          onChange={this.handleChange('ownerUser')}
                          onClose={() => this.setState({ ownerUserSelectorOpen: false })}
                          onOpen={() => this.setState({ ownerUserSelectorOpen: true })}
                          open={this.state.ownerUserSelectorOpen}
                          input={
                            <OutlinedInput
                              labelWidth={0}
                              name="ownerUser"
                              required
                              classes={{ input: classes.outlinedInput }}
                            />
                          }
                          inputProps={{ classes: { icon: classes.dropdownIcon } }}
                          displayEmpty
                          fullWidth
                          required
                          disabled={!userSelectedOwnerRoleOrDefault}
                          MenuProps={{
                            classes: { paper: classes.menuDropdown },
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                        >
                          <MenuItem value={null} disabled>
                            <Typography variant="body2">User</Typography>
                          </MenuItem>
                          {userSelectedOwnerRoleOrDefault &&
                            userSelectedOwnerRoleOrDefault.usersWithRole
                              .filter((user) => user.id !== '1' && user.id !== get(approverUser, 'id', ''))
                              .filter((role) => role.status !== 'archived')
                              .map((user) => this.renderUsersWithSelectedJobRole(user))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  <Spacer factor={3} />
                  <Typography align="left" variant="subtitle1">
                    Approver Permissions
                  </Typography>
                  <Spacer factor={1} />
                  {isSetMemberItemType || isTaskItem ? (
                    <Typography variant="body2">N/A {isSetMemberItemType ? '- Managed by set' : null}</Typography>
                  ) : (
                    <>
                      <FormControl fullWidth required error={hasError}>
                        <Tooltip title={currentCompanyAllowsCustomPermissions ? '' : 'Upgrade license to edit'}>
                          <Select
                            className={classes.selectMenu}
                            value={approverRole}
                            renderValue={() =>
                              (userSelectedApproverRoleOrDefault &&
                                userSelectedApproverRoleOrDefault.formattedRoleName) ||
                              'Role'
                            }
                            onChange={this.handleChange('approverRole')}
                            onClose={() => this.setState({ approverRoleSelectorOpen: false })}
                            onOpen={() => this.setState({ approverRoleSelectorOpen: true, filterText: '' })}
                            open={this.state.approverRoleSelectorOpen}
                            input={
                              <OutlinedInput
                                labelWidth={0}
                                name="approverRole"
                                required
                                classes={{ input: classes.outlinedInput }}
                              />
                            }
                            inputProps={{ classes: { icon: classes.dropdownIcon } }}
                            displayEmpty
                            fullWidth
                            required
                            disabled={!currentCompanyAllowsCustomPermissions || !(itemType || defaultItemType)}
                            onMouseEnter={() => this.setState({ isHoveringDropdown: true })}
                            onMouseLeave={() => this.setState({ isHoveringDropdown: false })}
                            MenuProps={{
                              classes: { paper: classes.menuDropdown },
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                            }}
                          >
                            <MenuItem value={null} disabled>
                              <Typography variant="body2">Role</Typography>
                            </MenuItem>
                            {(assignableJobRoles || [])
                              .filter((role) => role.id !== get(userSelectedOwnerRoleOrDefault, 'id', ''))
                              .map((jobRole) => this.renderJobRoleOption(jobRole))}
                          </Select>
                        </Tooltip>
                      </FormControl>
                      <Spacer factor={1} />
                      <FormControl fullWidth required error={hasError}>
                        <Select
                          className={classes.selectMenu}
                          value={approverUser}
                          onChange={this.handleChange('approverUser')}
                          onClose={() => this.setState({ approverUserSelectorOpen: false })}
                          onOpen={() => this.setState({ approverUserSelectorOpen: true })}
                          open={this.state.approverUserSelectorOpen}
                          input={
                            <OutlinedInput
                              labelWidth={0}
                              name="approverUser"
                              required
                              classes={{ input: classes.outlinedInput }}
                            />
                          }
                          inputProps={{ classes: { icon: classes.dropdownIcon } }}
                          displayEmpty
                          fullWidth
                          required
                          disabled={!userSelectedApproverRoleOrDefault}
                          MenuProps={{
                            classes: { paper: classes.menuDropdown },
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                        >
                          <MenuItem value={null} disabled>
                            <Typography variant="body2">User</Typography>
                          </MenuItem>
                          {userSelectedApproverRoleOrDefault &&
                            userSelectedApproverRoleOrDefault.usersWithRole
                              .filter((user) => user.id !== '1' && user.id !== get(ownerUser, 'id', ''))
                              .map((user) => this.renderUsersWithSelectedJobRole(user))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  <Spacer factor={3} />
                  {isSetMemberItemType || isTaskItem ? null : (
                    <>
                      <Typography align="left" variant="subtitle1">
                        Electronic Signature
                      </Typography>
                      <Spacer factor={1} />
                      <Typography variant="body2">
                        As the Creator of this item, I confirm the Own and Approve permissions granted to the user
                        specified above.
                      </Typography>
                      <Spacer factor={2} />
                      <LinearProgress
                        className={classes.signatureBar}
                        color="primary"
                        variant="determinate"
                        value={100}
                      />
                      <div style={{ marginLeft: 16 }}>
                        <UserListItem userId={currentUser.id} name={currentUser.fullName} />
                        <Spacer factor={1} />
                        <FormControl fullWidth>
                          <OutlinedInput
                            className={classes.textInput}
                            classes={{ input: classes.outlinedInput }}
                            fullWidth
                            id="create-new-item-username-input"
                            type="text"
                            required
                            onChange={this.handleChange('username')}
                            placeholder="Username"
                          />
                        </FormControl>
                        <Spacer factor={1} />
                        <FormControl fullWidth>
                          <OutlinedInput
                            className={classes.textInput}
                            classes={{ input: classes.outlinedInput }}
                            fullWidth
                            id="create-new-item-password-input"
                            type="password"
                            required
                            onChange={this.handleChange('password')}
                            placeholder="Password"
                          />
                        </FormControl>
                      </div>
                      <Spacer factor={4} />
                    </>
                  )}
                  <Mutation
                    mutation={CREATE_ITEM_MUTATION}
                    variables={{
                      input: {
                        itemType: itemTypeOrDefault,
                        title: title || 'Default Title',
                        productId: 1,
                        ownerJobRoleId: userSelectedOwnerRoleOrDefault.id,
                        ownerUserId: get(ownerUser, 'id', ''),
                        approverJobRoleId: userSelectedApproverRoleOrDefault.id,
                        approverUserId: get(approverUser, 'id', ''),
                      },
                    }}
                    awaitRefetchQueries
                    refetchQueries={[
                      itemTypeMeta ? { query: itemTypeMeta.listViewQuery } : undefined,
                      'TraceQuery',
                      'Search',
                      'LinksQuery',
                      'AllJobRoles',
                      ...otherRefetchQueries,
                    ]}
                    update={(_cache, { data }) => {
                      onCreate(data);
                    }}
                  >
                    {(createItem, { loading }) => (
                      <div style={{ display: 'flex' }}>
                        <Button
                          className={classes.closeBtn}
                          onClick={handleModalClose}
                          color="primary"
                          variant="outlined"
                          disabled={loading}
                        >
                          Cancel
                        </Button>
                        <Button
                          className={classes.createBtn}
                          id="item-create-btn"
                          color="primary"
                          fullWidth
                          variant="contained"
                          type="submit"
                          disabled={
                            itemTypeOrDefault === '' ||
                            (!generateDefaultTitle && title === '') ||
                            (isSetMemberItemType || isTaskItem
                              ? false
                              : !ownerUser || !approverUser || !username || !password) ||
                            loading
                          }
                          onClick={
                            (itemTypeOrDefault !== '' && title !== '') ||
                            (itemTypeOrDefault !== '' && generateDefaultTitle)
                              ? (e) => {
                                  e.preventDefault();
                                  createItem()
                                    .then((data) => {
                                      if (
                                        (itemTypeOrDefault !== '' && title !== '') ||
                                        (itemTypeOrDefault !== '' && generateDefaultTitle)
                                      ) {
                                        return showSnackbar();
                                      }
                                    })
                                    .catch((e) => {
                                      this.setState({ mutationError: e.message });
                                      throw e;
                                    });
                                }
                              : this.handleClick
                          }
                        >
                          {!loading ? this.props.createItemButtonText || <Trans>Create</Trans> : <Loading size={25} />}
                        </Button>
                      </div>
                    )}
                  </Mutation>
                  <Spacer factor={2} />
                </form>
              </CardContent>
            );
          }}
        </Query>
      </Card>
    );
  }
}

ItemTypeSelector.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    createBtn: PropTypes.string.isRequired,
    dropdownIcon: PropTypes.string.isRequired,
    formContainer: PropTypes.string.isRequired,
    menuDropdown: PropTypes.string.isRequired,
    menuOption: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    selectMenu: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  refetchQueries: PropTypes.any, // string | array | nothing
  createItemButtonText: PropTypes.string,
};

export default flowRight([
  withI18n(),
  withStyles(styles),
  withDisabledCategories,
  withEnabledCategories,
  withJobRoles,
  withCurrentCompany,
  WithCurrentUser,
])(ItemTypeSelector);
