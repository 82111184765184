import React from 'react';
import { compose } from 'recompose';

import { withCurrentCompany } from 'compositions/WithCurrentCompany';

const withEnabledCategories = (WrappedComponent) => (componentProps) => {
  const { currentCompany } = componentProps;
  return (
    <WrappedComponent
      enabledCategories={currentCompany ? currentCompany.enabledCategories : []}
      {...componentProps}
    />
  );
};

export default compose(
  withCurrentCompany,
  withEnabledCategories,
);
