import gql from 'graphql-tag';

export const builderViewQuery = gql`
  query FormBasedBuilderViewQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      createdAt
      referenceId
      tags
      currentVersion {
        approver {
          id
          fullName
          title
          status
        }
        createdAt
        creator {
          id
          fullName
          title
        }
        id
        locked
        owner {
          id
          fullName
          title
          status
        }
        item {
          id
          itemType {
            id
            name
          }
        }
        assignedOwnerJobRole {
          id
          formattedRoleName
        }
        assignedApproverJobRole {
          id
          formattedRoleName
        }
        permissibleApprovers {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        permissibleOwners {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        title
        versionIdentifier
        currentStatusName
        changeOrderVersions {
          id
          item {
            id
            customIdentifier
          }
        }
        ... on FinalDeviceInspectionVersion {
          body
          inputMethod
          attachment {
            id
            filename
            url
          }
        }
        ... on IncomingQualityInspectionVersion {
          body
          inputMethod
          attachment {
            id
            filename
            url
          }
        }
        ... on InProcessInspectionVersion {
          body
          inputMethod
          attachment {
            id
            filename
            url
          }
        }
      }
    }
  }
`;
