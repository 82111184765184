import React, { useContext } from 'react';

import { Paper, Tab, Tabs, Tooltip } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { NavigationContext } from 'contexts/navigationContext';
import { SidebarWorkspacesContext, WorkspaceType } from 'contexts/workspacesContext';

import { useStyles } from './styles';

const WorkspaceSelector = () => {
  const classes = useStyles();
  const { workspace, setWorkspaceType } = useContext(SidebarWorkspacesContext);
  const { selectedNavigationItem } = useContext(NavigationContext);

  const handleChange = (_event, newValue) => {
    setWorkspaceType(newValue);
  };

  const capitalizeString = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  };

  return selectedNavigationItem.name === 'document_control' ? (
    <Paper square className={classes.root}>
      <Tabs
        value={workspace}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="transparent"
        textColor="primary"
        scrollButtons={'off'}
        aria-label="icon tabs example"
      >
        <Tooltip title={`${capitalizeString(WorkspaceType.DISCIPLINE)} View`}>
          <Tab
            value={WorkspaceType.DISCIPLINE}
            icon={<ViewModuleIcon />}
            aria-label={WorkspaceType.DISCIPLINE}
            className={`${classes.tab} ${workspace === WorkspaceType.DISCIPLINE ? classes.selectedTab : ''}`}
            aria-hidden={false}
          />
        </Tooltip>
        <Tooltip title={`${capitalizeString(WorkspaceType.ALPHABETICAL)} View`}>
          <Tab
            value={WorkspaceType.ALPHABETICAL}
            icon={<SortByAlphaIcon />}
            aria-label={WorkspaceType.ALPHABETICAL}
            className={`${classes.tab} ${workspace === WorkspaceType.ALPHABETICAL ? classes.selectedTab : ''}`}
          />
        </Tooltip>
        {/*<Tooltip title={`${capitalizeString(WorkspaceType.FAVORITES)} View`}>
            <Tab
              value={WorkspaceType.FAVORITES}
              icon={<FavoriteBorderIcon />}
              aria-label={WorkspaceType.FAVORITES}
              className={`${classes.tab} ${workspace === WorkspaceType.FAVORITES ? classes.selectedTab : ''}`}
            />
          </Tooltip>*/}
      </Tabs>
    </Paper>
  ) : null;
};

export default WorkspaceSelector;
