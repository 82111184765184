import React from 'react';

import { t } from '@lingui/macro';
import gql from 'graphql-tag';
import isNull from 'lodash/isNull';
import get from 'lodash/get';

import LibraryBooks from '@material-ui/icons/LibraryBooks';

import slugs from 'displaySlugs';
import { VersionAndStatusDot } from 'components';
import MakeLink from 'components/MakeLink';
import { ShortTextCell } from 'components/TableView/Fields/ShortText';
import Views from 'workspaces/views';

import PayloadFields from './PayloadFields';

const itemType = 'traceMatrix';

//
// currentRelease and currentVersion are only retrieved for Design Output Versions
// Their UI envolves interaction with currentRelease and workingVersion
// We do the same thing in gql.js but here we must use a string due to how the
// query is ran by the parent component and there we just use a GraphQL Fragment.
//
let traceFields = `
  id
  stringified
  currentStatus {
    id
    name
  }
  ...on RiskVersion {
    harm {
      id
      name
    }
    hazard {
      name
      id
    }
    sequenceOfEvents
    hazardousSituation
  }
  item {
    id
    customIdentifier
    itemType {
      id
      name
      displaySlug
    }
    currentRelease {
      id
      stringified
      currentStatus {
        id
        name
      }
      title
      versionIdentifier
      item {
        id
        itemType {
          id
          name
          displaySlug
        }
        customIdentifier
      }
    }
    currentVersion {
      id
      stringified
      currentStatus {
        id
        name
      }
      title
      versionIdentifier
      item {
        id
        itemType {
          id
          name
          displaySlug
        }
        customIdentifier
      }
    }
    workingVersion {
      id
      stringified
      currentStatus {
        id
        name
      }
      title
      versionIdentifier
      item {
        id
        customIdentifier
        itemType {
          id
          name
          displaySlug
        }
      }
    }
  }
  title
  versionIdentifier
`;

// Only need current/working version for Design Output Versions
const versionFields = `
  ... on TraceMatrixVersion {
    traceType
    requirementsSetVersion {
      id
      currentStatus {
        id
        name
      }
      versionIdentifier
      item {
        id
        customIdentifier
        currentRelease {
          id
          versionIdentifier
          title
          currentStatus {
            name
            id
          }
        }
        workingVersion {
          id
          title
          versionIdentifier
          currentStatus {
            name
            id
          }
        }
        itemType {
          id
          name
        }
      }
    }
    traces {
      id
      downstreamVersion {
        ${traceFields}
      }
      upstreamVersion {
        ${traceFields}
      }
    }
    userNeedsSetVersion {
      id
      currentStatus {
        id
        name
      }
      versionIdentifier
      item {
        id
        customIdentifier
        currentRelease {
          id
          versionIdentifier
          title
          currentStatus {
            name
            id
          }
        }
        workingVersion {
          id
          title
          versionIdentifier
          currentStatus {
            name
            id
          }
        }
        itemType {
          id
          name
        }
      }
    }
    riskAnalysisVersion {
      id
      currentStatus {
        id
        name
      }
      versionIdentifier
      item {
        id
        customIdentifier
        currentRelease {
          id
          versionIdentifier
          title
          currentStatus {
            name
            id
          }
        }
        workingVersion {
          id
          title
          versionIdentifier
          currentStatus {
            name
            id
          }
        }
        itemType {
          id
          name
        }
      }
    }
  }
`;
const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.traceType',
    headerName: 'Type',
    type: 'regular_text',
    valueGetter: (row) =>
      `${row.data.currentVersion.traceType === 'design_trace_matrix' ? 'Design' : 'Risk'} Trace Matrix`,
    render: (row) => row.value,
  },
  {
    field: 'currentVersion.userNeedsSetVersion.item.customIdentifier',
    headerName: 'Associated Set #1',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return get(row.value.currentVersion.userNeedsSetVersion, 'item.customIdentifier', '-');
    },
    render: (row) => {
      let associatedSetVersion1 = null;
      if (row.data.currentVersion.traceType === 'design_trace_matrix') {
        associatedSetVersion1 = row.data.currentVersion.userNeedsSetVersion;
      } else if (row.data.currentVersion.traceType === 'risk_trace_matrix') {
        associatedSetVersion1 = row.data.currentVersion.requirementsSetVersion;
      }
      return !isNull(associatedSetVersion1) ? (
        <MakeLink newTab={true} item={associatedSetVersion1.item}>
          <ShortTextCell data={get(associatedSetVersion1, 'item.customIdentifier')} />
        </MakeLink>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'currentVersion.userNeedsSetVersion',
    headerName: 'Associated Set #1 Version',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      if (!row.value.currentVersion.userNeedsSetVersion) return '';
      return JSON.stringify({
        versionIdentifier: row.value.currentVersion.userNeedsSetVersion.versionIdentifier,
        currentStatusName: row.value.currentVersion.userNeedsSetVersion.currentStatus.name,
      });
    },
    render: (row) => {
      let associatedSetVersion1 = null;
      if (row.data.currentVersion.traceType === 'design_trace_matrix') {
        associatedSetVersion1 = row.data.currentVersion.userNeedsSetVersion;
      } else if (row.data.currentVersion.traceType === 'risk_trace_matrix') {
        associatedSetVersion1 = row.data.currentVersion.requirementsSetVersion;
      }
      return !isNull(associatedSetVersion1) ? (
        <VersionAndStatusDot
          version={associatedSetVersion1.versionIdentifier}
          status={associatedSetVersion1.currentStatus.name}
        />
      ) : (
        ''
      );
    },
  },
  {
    field: 'currentVersion.requirementsSetVersion.item.customIdentifier',
    headerName: 'Associated Set #2',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return get(row.value.currentVersion.requirementsSetVersion, 'item.customIdentifier', '-');
    },
    render: (row) => {
      let associatedSetVersion2 = null;
      if (row.data.currentVersion.traceType === 'design_trace_matrix') {
        associatedSetVersion2 = row.data.currentVersion.requirementsSetVersion;
      } else if (row.data.currentVersion.traceType === 'risk_trace_matrix') {
        associatedSetVersion2 = row.data.currentVersion.riskAnalysisVersion;
      }
      return !isNull(associatedSetVersion2) ? (
        <MakeLink newTab={true} item={associatedSetVersion2.item}>
          <ShortTextCell data={get(associatedSetVersion2, 'item.customIdentifier')} />
        </MakeLink>
      ) : (
        ''
      );
    },
  },
  {
    field: 'currentVersion.requirementsSetVersion',
    headerName: 'Associated Set #2 Version',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      if (!row.value.currentVersion.requirementsSetVersion) return '';
      return JSON.stringify({
        versionIdentifier: row.value.currentVersion.requirementsSetVersion.versionIdentifier,
        currentStatusName: row.value.currentVersion.requirementsSetVersion.currentStatus.name,
      });
    },
    render: (row) => {
      let associatedSetVersion2 = null;
      if (row.data.currentVersion.traceType === 'design_trace_matrix') {
        associatedSetVersion2 = row.data.currentVersion.requirementsSetVersion;
      } else if (row.data.currentVersion.traceType === 'risk_trace_matrix') {
        associatedSetVersion2 = row.data.currentVersion.riskAnalysisVersion;
      }
      return !isNull(associatedSetVersion2) ? (
        <VersionAndStatusDot
          version={associatedSetVersion2.versionIdentifier}
          status={associatedSetVersion2.currentStatus.name}
        />
      ) : (
        ''
      );
    },
  },

  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query traceMatricesQuery {
    traceMatrices {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        id
        createdAt
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        traceType
        requirementsSetVersion {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
              name
            }
          }
        }
        userNeedsSetVersion {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            itemType {
              id
              displaySlug
              userCreatable
              name
            }
            id
            customIdentifier
          }
        }
        riskAnalysisVersion {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
              name
            }
          }
        }
      }
    }
  }
`;

export default {
  categoryLabel: t`Trace Matrices`,
  displaySlug: slugs[itemType],
  icon: LibraryBooks,
  itemType,
  label: t`Trace Matrix`,
  PayloadFields,
  listViewQuery,
  listViewColumns,
  recordWorkspace: [Views.Builder, Views.Effective, Views.Versions],
  versionFields,
};
