import React from 'react';

import { useMutation } from 'react-apollo';

import { DELETE_VERSION_TRACE } from '../gql';

export default function withDeleteVersionTrace(Component) {
  return function(props) {
    const [mutation] = useMutation(DELETE_VERSION_TRACE),
      deleteVersionTrace = (variables) => mutation({ variables });

    return <Component {...props} deleteVersionTrace={deleteVersionTrace} />;
  };
}
