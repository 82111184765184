import React, { Component } from 'react';
import get from 'lodash/get';
import { Dialog, ClickAwayListener, Modal, withStyles } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import Controls from 'components/ItemControlButtons';
import { PeopleMenu, TagsMenu, EllipsesMenu } from 'components/ItemControlMenus';
import PDFExportDialog from 'components/PDFExportDialog';
import ChangeOwnerApprover from 'components/ItemControlMenus/PeopleMenu/ChangeOwnerApprover';
import OptionsDropdown from 'components/OptionsDropdown';
import { ConfirmationDialog } from 'components';

import { createMenuClickHandler, createMenuPopper, menuOptions } from './popperUtil';
import { InputMethod } from '__generated__/globalTypes';
import Guidance from 'assets/icons/Guidance';
import Document from 'assets/icons/Document';

const { none, people, tags, more } = menuOptions;
const PeoplePopper = createMenuPopper(people);
const TagsPopper = createMenuPopper(tags);
const EllipsesPopper = createMenuPopper(more);

const styles = (theme) => ({
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  pdfModal: {
    zIndex: theme.zIndex.tooltip + 1,
  },
  controlsRoot: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiInputBase-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.action.active,
      },
    },
  },
});

class ItemControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: none,
      pdfExportDialogVisible: false,
      changeOwnerVisible: false,
      changeApproverVisible: false,
      popperAnchorEl: null,
      menuHidden: false,
      showTitlesInExport: false,
      showIdsInExport: false,
      isGroupedByRequirementType: false,
      isGroupedByFolder: false,
      isGroupedByStakeholders: false,
      landscapeView: false,
      selectedInputMethod: null,
      modalOpen: false,
    };
  }

  canHaveDocumentStyle = (itemType) => !['qualityAgreement'].includes(itemType);
  canHaveGuidedEditor = (itemType) =>
    [
      'verificationAndValidationPlan',
      'deviceMasterRecord',
      'supplierAuditPlan',
      'labeling',
      'supplierAuditReport',
      'deviceVerification',
    ].includes(itemType);

  hideMenu = () => this.setState({ menuHidden: true });
  makeMenuVisible = () => this.setState({ menuHidden: false });

  resetExportOptionsValues = () => {
    this.setState({
      showTitlesInExport: false,
      showIdsInExport: false,
      isGroupedByRequirementType: false,
      isGroupedByFolder: false,
      isGroupedByStakeholders: false,
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleInputMethodChange = (selectedInputMethod) => {
    if (!this.props.ellipsesProps.locked) {
      this.setState({ selectedInputMethod });
      this.setState({ modalOpen: true });
    }
  };

  onConfirmInputMethodChange = () => {
    this.setState({ modalOpen: false });
    this.props.ellipsesProps.onInputMethodChange(this.state.selectedInputMethod);
  };
  render() {
    const { classes, peopleProps, tagsProps, ellipsesProps } = this.props;
    const { popperAnchorEl, activeMenu, menuHidden, changeOwnerVisible, changeApproverVisible, modalOpen } = this.state;
    const propsForPopper = {
      anchorEl: popperAnchorEl,
      activeMenu: activeMenu,
      className: classes.popper,
    };

    const itemTypesToUseDefaultJobRole = ['training_record'];
    const jobRoleSelectLocked = itemTypesToUseDefaultJobRole.includes(
      get(peopleProps, 'version.item.itemType.name', ''),
    )
      ? true
      : false;

    return (
      <ClickAwayListener onClickAway={createMenuClickHandler(none).bind(this)}>
        <div className={classes.controlsRoot}>
          {ellipsesProps.inputMethod && (
            <OptionsDropdown
              value={ellipsesProps.inputMethod}
              onChange={(event) => this.handleInputMethodChange(event.target.value)}
              optionsTitle="Item Format"
              options={[
                ...(this.canHaveGuidedEditor(ellipsesProps.itemType)
                  ? [
                      {
                        value: InputMethod.GUIDED,
                        Icon: Guidance,
                        text: 'Guided Editor',
                      },
                    ]
                  : []),
                ...(this.canHaveDocumentStyle(ellipsesProps.itemType)
                  ? [
                      {
                        value: InputMethod.BODY,
                        Icon: DescriptionIcon,
                        text: 'Document Style',
                      },
                    ]
                  : []),
                {
                  value: InputMethod.UPLOAD,
                  Icon: Document,
                  text: 'Uploaded Content',
                },
              ]}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
          )}

          <Controls
            handlePeopleClick={createMenuClickHandler(people).bind(this)}
            handleTagsClick={createMenuClickHandler(tags).bind(this)}
            handleMoreClick={(e) => {
              if (menuHidden) {
                this.makeMenuVisible();
                if (activeMenu === more) return; // no need to toggle then
              }

              createMenuClickHandler(more).call(this, e);
            }}
            hasInactiveUser={
              get(peopleProps, 'owner.status') === 'inactive' || get(peopleProps, 'approver.status') === 'inactive'
            }
          />
          <PeoplePopper {...propsForPopper}>
            <PeopleMenu
              {...peopleProps}
              onChangeOwnerClicked={(e) => this.setState({ changeOwnerVisible: true })}
              onChangeApproverClicked={(e) => this.setState({ changeApproverVisible: true })}
              changeOwnerApproverVisible={changeOwnerVisible || changeApproverVisible}
            />
          </PeoplePopper>

          <TagsPopper placement="bottom-end" {...propsForPopper}>
            <TagsMenu {...tagsProps} />
          </TagsPopper>

          <EllipsesPopper placement="bottom-end" {...propsForPopper}>
            <EllipsesMenu
              hideMenu={this.hideMenu}
              menuHidden={menuHidden}
              {...ellipsesProps}
              setLandscapeView={() => this.setState({ landscapeView: true })}
              onGeneratePDFClicked={
                this.props.showPDFExport ? (e) => this.setState({ pdfExportDialogVisible: true }) : undefined
              }
              onGeneratePDFWithTitlesClicked={() =>
                this.setState({ pdfExportDialogVisible: true, showTitlesInExport: true })
              }
              onGeneratePDFWithIdsClicked={() => this.setState({ pdfExportDialogVisible: true, showIdsInExport: true })}
              onGeneratePDFGroupedByRequirementType={() =>
                this.setState({ pdfExportDialogVisible: true, isGroupedByRequirementType: true })
              }
              onGeneratePDFGroupedByStakeholders={() =>
                this.setState({ pdfExportDialogVisible: true, isGroupedByStakeholders: true })
              }
              onGeneratePDFGroupedByFolder={() =>
                this.setState({ pdfExportDialogVisible: true, isGroupedByFolder: true })
              }
            />
          </EllipsesPopper>

          <ConfirmationDialog
            open={modalOpen}
            title="Confirm Change"
            onClose={this.handleModalClose}
            onCancel={this.handleModalClose}
            onConfirm={this.onConfirmInputMethodChange}
          >
            By changing editor style, you will lose any added content in this item.
          </ConfirmationDialog>

          {this.props.showPDFExport && (
            <Modal className={classes.pdfModal} open={this.state.pdfExportDialogVisible}>
              <PDFExportDialog
                heading={this.props.pdfExportHeader}
                subheading="Builder View"
                onClose={(e) => this.setState({ pdfExportDialogVisible: false })}
                versionId={ellipsesProps.versionId}
                landscapeView={this.state.landscapeView}
                showIdsInExport={this.state.showIdsInExport}
                showTitlesInExport={this.state.showTitlesInExport}
                isGroupedByRequirementType={this.state.isGroupedByRequirementType}
                isGroupedByStakeholders={this.state.isGroupedByStakeholders}
                isGroupedByFolder={this.state.isGroupedByFolder}
                resetExportOptionsValues={this.resetExportOptionsValues}
              />
            </Modal>
          )}
          {peopleProps.owner && peopleProps.approver && (
            <>
              <Dialog open={changeOwnerVisible} keepMounted maxWidth={false}>
                <ChangeOwnerApprover
                  onClose={() => this.setState({ changeOwnerVisible: false })}
                  permissionType="own_record"
                  permissibleDefaultUsers={peopleProps.permissibleOwners}
                  currentRole={peopleProps.version.assignedOwnerJobRole}
                  currentOwnerRole={peopleProps.version.assignedOwnerJobRole}
                  currentApproverRole={peopleProps.version.assignedApproverJobRole}
                  currentOwner={peopleProps.owner}
                  currentApprover={peopleProps.approver}
                  currentAssignedUser={peopleProps.owner.fullName}
                  versionId={peopleProps.version.id}
                  refetchQueries={peopleProps.refetchQueries}
                  jobRoleSelectLocked={jobRoleSelectLocked}
                />
              </Dialog>
              <Dialog open={changeApproverVisible} keepMounted maxWidth={false}>
                <ChangeOwnerApprover
                  onClose={() => this.setState({ changeApproverVisible: false })}
                  permissionType="approve_record"
                  permissibleDefaultUsers={peopleProps.permissibleApprovers}
                  currentRole={peopleProps.version.assignedApproverJobRole}
                  currentOwnerRole={peopleProps.version.assignedOwnerJobRole}
                  currentApproverRole={peopleProps.version.assignedApproverJobRole}
                  currentOwner={peopleProps.owner}
                  currentApprover={peopleProps.approver}
                  currentAssignedUser={peopleProps.approver.fullName}
                  versionId={peopleProps.version.id}
                  refetchQueries={peopleProps.refetchQueries}
                  jobRoleSelectLocked={jobRoleSelectLocked}
                />
              </Dialog>
            </>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles)(ItemControls);
