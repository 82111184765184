import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import AssignmentInd from '@material-ui/icons/AssignmentInd';

import slugs from '../../displaySlugs';

import PayloadFields from './PayloadFields';

const itemType = 'userRequirementSpecification';

const versionFields = `
  userReqSpecDescription
  riskLevel
  justificationForRiskLevel
`;

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.userReqSpecDescription',
    headerName: 'Description',
    type: 'long_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query userRequirementSpecificationsQuery {
    userRequirementSpecifications {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        id
        createdAt
        userReqSpecDescription
        riskLevel
        justificationForRiskLevel
        title
        currentStatusName
        versionIdentifier
        signingOwner {
          id
          firstName
          lastName
        }
        signingApprover {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`User Requirement Specification`,
  categoryLabel: t`URSs`,
  icon: AssignmentInd,
  versionFields,
  listViewColumns,
  listViewQuery,
  PayloadFields,
};
