import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { iconStyles } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...{
      tooltip: {
        ...theme.typography.body2,
        zIndex: theme.zIndex.tooltip,
      },
      filterContainer: {
        padding: theme.spacing(1),
      },
    },
    ...iconStyles,
  }),
);
