import React from 'react';

import { AssociatedChangeOrder } from 'compositions/TimelineGroup/steps/views';
import { ownerApprovalWithoutSigSteps, ownerApprovalFinalResult } from '../shared/workflowTransitionsUtil';

export default {
  initialStep: 'associatedChange',
  internalSteps: {
    associatedChange: {
      component: ({ currentVersion, ...rest }) => {
        return (
          <AssociatedChangeOrder
            coKey={currentVersion.linkedChangeCustomIdentifier}
            coId={currentVersion.linkedChangeId}
            coTitle={currentVersion.linkedChangeTitle}
            disableEdit={currentVersion.locked}
            coJustificationText={currentVersion.linkedChangeJustificationText}
            {...rest}
          />
        );
      },
      getNextStep: ({ edit }) => {
        if (edit) return 'coRequired';
        return 'complete';
      },
    },
    ...ownerApprovalWithoutSigSteps,
  },
  getFinalResult: ownerApprovalFinalResult,
};
