import React from 'react';

function ImagePreview(props: { classes }) {
  return (
    <svg className={props.classes.imagePlaceholder}>
      <g fill="none" fillRule="evenodd">
        <path fill="#EFEFEF" d="M0 0h256v210H0z" />
        <path fill="#D8D8D8" d="M11.932 187h232.136L174.796 70l-67.688 95.477-40.98-40.694z" />
        <ellipse cx="118.237" cy="78" fill="#D8D8D8" rx="16.271" ry="15" />
      </g>
    </svg>
  );
}

export default ImagePreview;
