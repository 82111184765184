import { t } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';

export const options: MessageDescriptor[] = [
  t`Functional`,
  t`Performance`,
  t`Safety`,
  t`Usability`,
  t`Statutory/Regulatory`,
  t`Interface`,
  t`Previous and Similar Designs`,
  t`Essential Requirements`,
  t`Outputs of Risk Management`,
  t`Customer Requirements`,
  t`Organizational Requirements`,
  t`Other***`,
];

export const placeholder: MessageDescriptor = t`Select type(s)`;
