import gql from 'graphql-tag';

export const CREATE_REFERENCED_DOCUMENT = gql`
  mutation createVerificationRecordAttachment(
    $versionId: ID!
    $attachmentURL: String!
    $isExecutedTestProtocolAttachment: Boolean
  ) {
    addReferencedAttachmentToVerificationRecordVersion(
      versionId: $versionId
      attachment: $attachmentURL
      isExecutedTestProtocolAttachment: $isExecutedTestProtocolAttachment
    ) {
      verificationRecordAttachment {
        id
        url
      }
    }
  }
`;

export const UPDATE_REFERENCED_DOCUMENT = gql`
  mutation updateVerificationRecordAttachment($attachmentId: ID!, $attachmentURL: String, $description: String) {
    updateReferencedAttachmentFromVerificationRecordVersion(
      id: $attachmentId
      attachment: $attachmentURL
      description: $description
    ) {
      verificationRecordAttachment {
        id
        url
        description
      }
    }
  }
`;

export const DESTROY_REFERENCED_DOCUMENT = gql`
  mutation destroyVerificationRecordAttachment($id: ID!) {
    removeReferencedAttachmentFromVerificationRecordVersion(id: $id) {
      verificationRecordAttachment {
        id
      }
    }
  }
`;
