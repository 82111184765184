import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Gesture from '@material-ui/icons/Gesture';
import { Trans } from '@lingui/macro';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import { DateTime } from 'luxon';

import { ContentSection, Spacer, WorkflowIcon } from 'components';

import Cell from './Cell';

interface SignaturesProps {
  classes;
  signatures: {
    id: string;
    name: string;
    createdAt: Date;
  }[];
}
function Signatures({ classes, signatures }: SignaturesProps) {
  return (
    <Grid item xs={6} className={classes.gridGutterLeft}>
      <ContentSection
        LabelText={
          <>
            <Gesture className={classes.icon} />
            <Trans>Version Signature</Trans>
          </>
        }
      >
        <Spacer factor={1} />
        {signatures.map((sig) => (
          <div style={{ display: 'flex', marginBottom: 4 }} key={sig.id}>
            <Cell size={1} style={{ justifyContent: 'center' }}>
              <WorkflowIcon type={camelCase(sig.name)} size="small" completed />
            </Cell>
            <Cell>
              <Typography variant="body2" className={classes.bold}>
                {startCase(sig.name)}
              </Typography>
            </Cell>
            <Cell>
              <Typography variant="body2">
                {/* previousTransition, esignature, and user are all marked as nullable
                    in GQL though in this context they should be not null 100% of the time */}
                {get(sig, 'previousTransition.esignature.user.fullName', '')}
              </Typography>
            </Cell>
            <Cell>
              <Typography variant="body2">{DateTime.fromISO(sig.createdAt).toFormat('MMM. d yyyy')}</Typography>
            </Cell>
            <Cell>
              <Typography variant="body2">
                {`${DateTime.fromISO(sig.createdAt).toFormat('hh:mm:ss a (ZZZZ)')}`}
              </Typography>
            </Cell>
          </div>
        ))}
      </ContentSection>
    </Grid>
  );
}

export default Signatures;
