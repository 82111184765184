import React from 'react';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';

import { Button, LinearProgress, Typography, createStyles, withStyles } from '@material-ui/core';
import ReportProblem from '@material-ui/icons/Warning';

import { Spacer } from 'components';
import BaseDialog from 'components/BaseDialog';
import MakeLink from 'components/MakeLink';

const styles = (theme) =>
  createStyles({
    itemList: {
      display: 'flex',
    },
    verticalBar: {
      transform: 'rotate(90deg) scaleX(-1)',
      width: '5%',
      marginTop: theme.spacing(1),
      borderRadius: '5px',
    },
  });

interface Props {
  open: boolean;
  onClose: () => void;
  selectedUser: any;
  jobRole: any;
  affectedGrants: any;
  type: string;
  classes: any;
}

function ItemsRequireTransitioningModal(props: Props) {
  const { open, onClose, selectedUser, jobRole, type, affectedGrants, classes } = props;

  return (
    <BaseDialog
      open={open}
      title="Cannot Remove Role From User"
      Icon={ReportProblem}
      size="large"
      onClose={onClose}
      textAlign="left"
      actions={
        <Button onClick={onClose} color="primary" variant="contained" fullWidth>
          Confirm
        </Button>
      }
    >
      <Typography component={'div'} variant="body2">
        <div>
          The {get(jobRole, 'formattedRoleName')} role cannot be removed from {get(selectedUser, 'fullName')}'s roles
          list. The following items are in {type} status and have this user set as the Owner or Approver:
          <Spacer factor={2} />
          {affectedGrants.map((grant, index) => {
            return (
              <div key={index} style={{ marginLeft: '-10px' }}>
                <div className={classes.itemList}>
                  <LinearProgress className={classes.verticalBar} color="primary" variant="determinate" value={100} />
                  <span style={{ fontWeight: 600 }}>
                    <MakeLink item={grant.version.item} newTab>
                      {grant.version.item.customIdentifier}: {grant.version.title}
                    </MakeLink>
                  </span>
                </div>
              </div>
            );
          })}
          <Spacer factor={2} />
          {type === 'Owner Approval' ? (
            <>
              These items must be transitioned back to a Draft or Under Review status. To do this, you may either:
              <Spacer factor={1} />
              1) Have the owner void the Owner Approval signature and a new approver assigned before this user can be
              deactivated.
              <Spacer factor={1} />
              2) Have the Approver reject the item and then transition it back to Draft status. Have their Owner
              Approver status voided and a new approver assigned before this user can be deactivated.
            </>
          ) : (
            <>
              These items must be transitioned back to a Draft status. To do this, the Owner may select Cancel
              Retirement.
            </>
          )}
        </div>
        <Spacer factor={2} />
      </Typography>
    </BaseDialog>
  );
}

export default flowRight([withStyles(styles)])(ItemsRequireTransitioningModal);
