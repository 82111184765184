import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';

function BottomRowContainer({ children, classes }) {
  return (
    <div className={classes.bottomIcons}>
      <span className={classes.iconRow}>{children}</span>
    </div>
  );
}

export default withStyles(styles)(BottomRowContainer);
