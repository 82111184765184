import React from 'react';

import { Modal, Paper } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { YesOrNo } from 'components';

interface CreateFormRecordModalProps {
  classes: Record<string, string>;
  open: boolean;
  onClose: () => void;
  handleComplete: () => void;
  formIdentifier: string;
}

const CreateFormRecordModal = (props: CreateFormRecordModalProps) => {
  const { classes, open, onClose, handleComplete, formIdentifier } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <Paper classes={{ root: classes.modalContainer }}>
        <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={onClose} color="action" />
        <YesOrNo
          heading={`Would you like to assign ${formIdentifier} to a new form record and export it for completion?`}
          yesLabel="Create"
          noLabel="Cancel"
          closeOnNo
          handleComplete={handleComplete}
          handleClose={onClose}
          disableOnSubmit
        />
      </Paper>
    </Modal>
  );
};

export { CreateFormRecordModal };
