import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';

import { DEBOUNCE_MILLISECONDS } from '../constants';

import TextArea from './TextArea';
import mutation from 'utils/gql/updateTextArea';

class TextAreaField extends Component {
  static propTypes = {
    attrName: PropTypes.string.isRequired,
    refetchQueries: PropTypes.array,
    versionId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
  };

  static defaultProps = {
    refetchQueries: undefined,
  };

  constructor(props) {
    super(props);
    this.debouncedMutation = debounce(
      (mutate, textValue) => this.handleMutation(mutate, textValue),
      this.props.debounce || DEBOUNCE_MILLISECONDS,
    );
  }

  handleMutation = (mutate, textValue) => {
    mutate({
      variables: {
        input: {
          attrName: this.props.attrName,
          textValue,
          stringValue: textValue,
          versionId: this.props.versionId,
        },
      },
    });
  };

  handleKeyDown = (event, mutate) => {
    if (!event.shiftKey && event.key === 'Enter') {
      if (this.props.initialValue !== event.target.value) {
        this.debouncedMutation.cancel();
        this.handleMutation(mutate, event.target.value);
      }
      this.props.onBlur && this.props.onBlur(event);
    }
  };

  render() {
    const {
      attrName,
      autoComplete,
      autoFocus,
      refetchQueries,
      versionId,
      locked,
      inputClasses,
      multiline,
      onTextChange,
      height,
      useInEffectiveView = false,
      disabled,
      placeholder,
      fixedHeight,
      maxLength,
      onBlur,
      isEditing,
      saveOnBlur = false,
      saveOnEnter = false,
      ...rest
    } = this.props;

    return (
      <Mutation mutation={mutation} refetchQueries={refetchQueries} awaitRefetchQueries>
        {(mutate) => (
          <TextArea
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            disabled={locked || disabled}
            inputClasses={inputClasses}
            multiline={multiline}
            height={height}
            maxLength={maxLength}
            fixedHeight={fixedHeight}
            onKeyDown={(e) => saveOnEnter && this.handleKeyDown(e, mutate)}
            useInEffectiveView={useInEffectiveView}
            placeholder={locked ? '' : placeholder}
            onBlur={(e) => {
              saveOnBlur && this.props.initialValue !== e.target.value && this.handleMutation(mutate, e.target.value);
              onBlur && onBlur(e);
            }}
            isEditing={isEditing}
            onTextChange={onTextChange || (!saveOnBlur && ((textValue) => this.debouncedMutation(mutate, textValue)))}
            {...rest}
          />
        )}
      </Mutation>
    );
  }
}

export default TextAreaField;
