import React, { useContext, useEffect, useState } from 'react';

import semver from 'semver';

import SwapHoriz from '@material-ui/icons/SwapHoriz';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import School from '@material-ui/icons/School';
import AttachFile from '@material-ui/icons/AttachFile';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';

import { Tab, TabContainer } from 'components';
import { LockedContext, withLockedContext } from 'contexts/lockedContext';

import RiskAssessmentTab from './RiskAssessmentTab';
import TrainingImpactTab from './TrainingImpactTab';
import ImpactAnalysisTab from './ImpactAnalysisTab';
import AttachmentTab from './AttachmentTab';
import OverviewTab from './OverviewTab';

import { ChangeOrderBuilderViewQuery_changeOrder_currentVersion as ChangeOrderVersion } from './__generated__/ChangeOrderBuilderViewQuery';

enum ChangeOrderTabType {
  overview = 'OVERVIEW',
  impactAnalysis = 'IMPACT ANALYSIS',
  riskAssessment = 'RISK ASSESSMENT',
  trainingImpact = 'TRAINING IMPACT',
  attachment = 'ATTACHMENTS',
}

export interface TabProps {
  layoutClasses?;
  version: ChangeOrderVersion;
}

function PayloadFields({ versionData, itemRecord, layoutClasses }) {
  if (!itemRecord) {
    throw new Error('invalid input. props.itemRecord is null/undefined');
  }
  const [currentTab, setCurrentTab] = useState<ChangeOrderTabType>(ChangeOrderTabType.overview);

  const { setLocked } = useContext(LockedContext);

  const tabs = {
    [ChangeOrderTabType.overview]: <OverviewTab version={versionData} layoutClasses={layoutClasses} />,
    [ChangeOrderTabType.trainingImpact]: <TrainingImpactTab version={versionData} layoutClasses={layoutClasses} />,
    [ChangeOrderTabType.impactAnalysis]: (
      <ImpactAnalysisTab version={versionData} layoutClasses={layoutClasses} itemRecord={itemRecord} />
    ),
    [ChangeOrderTabType.riskAssessment]: <RiskAssessmentTab version={versionData} layoutClasses={layoutClasses} />,
    [ChangeOrderTabType.attachment]: (
      <AttachmentTab layoutClasses={layoutClasses} itemRecord={itemRecord} version={versionData} />
    ),
  };

  useEffect(() => {
    if (versionData) setLocked(versionData.locked);
  }, [versionData, setLocked]);

  return (
    <TabContainer
      layoutClasses={layoutClasses}
      tabs={
        <>
          <Tab
            icon={<SwapHoriz />}
            active={ChangeOrderTabType.overview === currentTab}
            onClick={() => setCurrentTab(ChangeOrderTabType.overview)}
            text={ChangeOrderTabType.overview}
          />
          <Tab
            icon={<AssessmentOutlinedIcon />}
            active={ChangeOrderTabType.impactAnalysis === currentTab}
            onClick={() => setCurrentTab(ChangeOrderTabType.impactAnalysis)}
            text={ChangeOrderTabType.impactAnalysis}
          />
          <Tab
            icon={<PriorityHigh />}
            active={ChangeOrderTabType.riskAssessment === currentTab}
            onClick={() => setCurrentTab(ChangeOrderTabType.riskAssessment)}
            text={ChangeOrderTabType.riskAssessment}
          />
          {semver.lt('7.3.2', versionData.displayAsApplicationVersion) && (
            <Tab
              icon={<School />}
              active={ChangeOrderTabType.trainingImpact === currentTab}
              onClick={() => setCurrentTab(ChangeOrderTabType.trainingImpact)}
              text={ChangeOrderTabType.trainingImpact}
            />
          )}
          <Tab
            icon={<AttachFile />}
            active={ChangeOrderTabType.attachment === currentTab}
            onClick={() => setCurrentTab(ChangeOrderTabType.attachment)}
            text={ChangeOrderTabType.attachment}
          />
        </>
      }
    >
      {tabs[currentTab]}
    </TabContainer>
  );
}

export default withLockedContext(PayloadFields);
