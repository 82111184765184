import React, { FC } from 'react';

import { MenuItem, Paper, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import { useStyles } from './styles';
import clsx from 'clsx';

interface Props {
  inputText: string;
  selected: string;
  items: string[];
  onChange: (item: string) => void;
}

const SettingsDropdown: FC<Props> = ({ inputText, selected, items, onChange }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.dropdown}>
      <div className={classes.dropdownInput}>
        <Typography variant="body2" className={classes.dropdownInputText}>
          {inputText}
        </Typography>
      </div>
      <div className={classes.list}>
        {items.map((item) => (
          <MenuItem
            key={item}
            value={item}
            onClick={() => onChange(item)}
            className={clsx({ [classes.menuItem]: true, [classes.selectedMenuItem]: selected === item })}
          >
            <Typography variant="body2" className={classes.menuItemText}>
              {item}
            </Typography>
            {selected === item && <Check className={classes.checkmark} />}
          </MenuItem>
        ))}
      </div>
    </Paper>
  );
};

export default SettingsDropdown;
