import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    customSelect: {
      width: '100%',
      textAlign: 'right',
    },
    selectContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    menuPaper: {
      maxHeight: 250,
      overflowY: 'auto',
    },
  }),
);
