import gql from 'graphql-tag';

export const ADD_TRAINING_RECORD_ATTACHMENT = gql`
  mutation AddTrainingRecordAttachment($versionId: ID!, $attachment: String) {
    updateTrainingRecordVersion(id: $versionId, attachment: $attachment) {
      trainingRecordVersion {
        id
      }
    }
  }
`;
