import gql from 'graphql-tag';

export const REMOVE_MEMBERS = gql`
  mutation removeMembers($ids: [ID!]!) {
    removeMembers(ids: $ids) {
      members {
        id
      }
    }
  }
`;
