import { ChangeOrderWorkflowStatuses } from 'workflows/statuses';
import { ResolveRejection } from '../views';

export default {
  initialStep: 'resolve',
  internalSteps: {
    resolve: {
      component: ResolveRejection,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: () => ({
    toStatus: ChangeOrderWorkflowStatuses.Draft.id,
  }),
};
