import React from 'react';
import {
  withStyles,
  MenuItem,
  ListItemText,
  Typography,
  createStyles,
} from '@material-ui/core';

import { UserAvatar } from 'components';

function MentionSuggestion(props: { children; userId: string; classes }) {
  return (
    <MenuItem
      component="span"
      className={props.classes.commentSuggestionWrapper}
    >
      <UserAvatar userId={props.userId} diameter={20} />
      <ListItemText>
        <Typography variant="body2">{props.children}</Typography>
      </ListItemText>
    </MenuItem>
  );
}

export default withStyles(
  createStyles((theme) => ({
    commentSuggestionWrapper: {
      backgroundColor: theme.palette.common.white,
      paddingTop: 8,
      paddingRight: 12,
      paddingBottom: 8,
      paddingLeft: 12,
      '&:hover': {
        backgroundColor: 'rgba(48, 188, 179, 0.1)',
      },
    },
  })),
)(MentionSuggestion);
