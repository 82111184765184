import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    panel: {
      position: 'relative',
      border: `1px solid #F2F2F2`,
      boxShadow: 'none',
      backgroundColor: theme.palette.background.contextDrawerGrey,
    },
    matrixHeading: {
      color: theme.palette.subHeading,
      textTransform: 'uppercase',
    },
    severityHeading: {
      textAlign: 'center',
      marginBottom: theme.spacing(1.5),
    },
    probabilityHeading: {
      position: 'absolute',
      transform: 'rotate(-90deg)',
      top: '50%',
      left: 0,
    },
    matrixSideHeading: {
      textAlign: 'center',
      color: theme.palette.blueDark,
    },
    severityUnit: {},
    severityName: {
      marginBottom: theme.spacing(1.5),
    },
    severityBox: {
      height: 13,
      border: '1px solid #EEE',
    },
    probabilityUnit: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    probabilityName: {
      flex: 1,
      marginRight: theme.spacing(0.5),
    },
    probabilityBox: {
      width: 13,
      height: 80,
      border: '1px solid #EEE',
    },
    matrixCell: {
      display: 'flex',
      alignItems: 'center',
      height: 80,
      background: theme.palette.common.input.background,
      border: '1px solid #EEE',
    },
    matrixCellContent: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    matrixCellColorItem: {
      width: 12,
      height: 12,
      borderRadius: 2,
      marginRight: theme.spacing(1),
    },
    editableMatrixCellSelect: {
      height: '100%',
    },
    editableMatrixCellContent: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
