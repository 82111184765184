import React, { useState } from 'react';

import { useLazyQuery, useQuery } from 'react-apollo';

import differenceBy from 'lodash/differenceBy';

import { Typography } from '@material-ui/core';

import { ConfirmationDialog } from 'components';

import SetSelect from './SetSelect';
import SetDiff from './SetDiff';

const designTraceMatrixSets = [
  'RequirementVersion',
  'UserNeedVersion',
  'DeviceVerificationVersion',
  'DeviceValidationVersion',
];
const RiskTraceMatrixSets = [
  'RequirementVersion',
  'RiskVersion',
  'RiskControlVersion',
  'DeviceVerificationVersion',
  'DeviceValidationVersion',
];

function calculateDiff(oldVersions, newVersions, graph, isRiskAnalysis = false, isRiskTraceType = false) {
  const tracesRemoved = [],
    linksConverted = [],
    versionsRemoved = differenceBy(oldVersions, newVersions, 'id'),
    versionsAdded = differenceBy(newVersions, oldVersions, 'id');
  if (!versionsAdded.length && !versionsRemoved.length) return;
  versionsRemoved.forEach((version) => {
    const vertex = graph.getVertex(version.id);
    if (!vertex) return;

    vertex.upstream.forEach((up) => {
      tracesRemoved.push([up.object, vertex.object]);
    });

    vertex.downstream.forEach((down) => {
      tracesRemoved.push([vertex.object, down.object]);
    });
  });

  const traceMatrixSets = isRiskTraceType ? RiskTraceMatrixSets : designTraceMatrixSets;

  newVersions.forEach((version) => {
    version.item.downstreamItemsKept.forEach((downstreamItem) => {
      if (traceMatrixSets.includes(downstreamItem.currentVersion.__typename)) {
        linksConverted.push([version, downstreamItem]);
      }
    });
    version.item.upstreamItemsKept.forEach((upstreamItem) => {
      if (
        traceMatrixSets.includes(upstreamItem.currentVersion.__typename) &&
        upstreamItem.currentVersion.__typename === ''
      ) {
        linksConverted.push([upstreamItem, version]);
      }
    });
  });

  if (isRiskAnalysis) {
    newVersions.forEach((version) => {
      if (version.__typename === 'RiskVersion') {
        version.linkedRiskControlVersions.forEach((riskControlVersion) => {
          linksConverted.push([version, riskControlVersion]);
        });
      }
    });
  }
  return { versionsAdded, versionsRemoved, tracesRemoved, linksConverted };
}

function onGraphQLError(e) {
  throw new Error(`Failed to fetch set versions: ${e.message}`);
}

function SetDiffSelect(props) {
  // TODO: needs to filter these, see PayloadFields
  const [selectedSetId, setSelectedSetId] = useState(null);
  const [diff, setDiff] = useState(null);

  const { data: currentSetData, loading } = useQuery(props.query, {
    skip: !props.value,
    variables: { id: props.value },
    onError: onGraphQLError,
  });
  const [getSelectedSetVersions] = useLazyQuery(props.query, {
    fetchPolicy: 'network-only',
    onError: onGraphQLError,
    onCompleted: function(selectedSetData) {
      let currentMemberVersions = [];
      var isRiskAnalysis = false;
      if (currentSetData) {
        currentMemberVersions = currentSetData.set.memberVersions;
        isRiskAnalysis = currentSetData.set.__typename === 'RiskAnalysisVersion';
      }
      let selectedMemberVersions = [];
      if (selectedSetData) {
        selectedMemberVersions = selectedSetData.set.memberVersions;
      }
      const isRiskTraceType = props.isRiskTraceType;
      const diff = calculateDiff(
        currentMemberVersions,
        selectedMemberVersions,
        props.graph,
        isRiskAnalysis,
        isRiskTraceType,
      );
      if (!diff) {
        props.onChange(selectedSetId);
        return;
      }

      setDiff(diff);
    },
  });

  if (loading) return null;
  const title =
    !diff || !diff.linksConverted.length
      ? 'Set Version Change Impact on Trace Matrix'
      : 'Set Version Change Impact on Trace Matrix and Links to be Auto-Imported as Traces';
  const showChangeConfirmation = Boolean(diff);
  return (
    <>
      <SetSelect
        disabled={props.disabled}
        value={props.value}
        options={props.options}
        onChange={(e) => {
          setSelectedSetId(e.target.value);
          getSelectedSetVersions({ variables: { id: e.target.value } });
        }}
      />

      {showChangeConfirmation && (
        <ConfirmationDialog
          size="large"
          textAlign="left"
          onCancel={() => setDiff(null)}
          onClose={() => setDiff(null)}
          onConfirm={() => {
            setDiff(null);
            props.onChange(selectedSetId);
          }}
          open={true}
          title={title}
        >
          <SetDiff itemType={props.setItemType} diff={diff} />
          <Typography variant="body2">Are you sure you wish to proceed?</Typography>
        </ConfirmationDialog>
      )}
    </>
  );
}

export default SetDiffSelect;
