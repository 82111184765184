import { createStyles } from '@material-ui/core';

import { styles as effectiveViewStyles } from 'compositions/EffectiveViewData';

export default (theme) =>
  createStyles({
    ...effectiveViewStyles(theme),
    tabContainer: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      marginTop: theme.spacing(1),
    },
    spacerTop: {
      height: theme.spacing(5),
      justifyContent: 'center',
    },
    warningMsg: {
      width: 500,
      backgroundColor: theme.palette.status.warning,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      height: theme.spacing(4),
      border: `1px solid ${theme.palette.error.light}`,
    },
    strong: {
      fontWeight: theme.typography.fontWeightBold,
    },
  });
