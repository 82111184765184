import React, { Component } from 'react';
import { Button, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Trans } from '@lingui/macro';

import styles from './styles';
import { Spacer } from 'components';

class ChangeSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { reasonForChange: null, descriptionOfChange: null };
  }

  getCurrentValue = (key) =>
    this.state[key] || (this.props.unsavedValue && this.props.unsavedValue[key]) || this.props.currentVersion[key];
  getNullableValue = (key) => this.state[key];
  setCurrentValue = (key) => this.props.currentVersion[key];

  render() {
    const { classes, handleComplete, buttonText, smallCircle } = this.props;
    const { reasonForChange, descriptionOfChange } = this.state;

    return (
      <div>
        <div className={classes.heading}>
          <Typography variant="h3">
            <Trans>Summary of Change</Trans>
          </Typography>
        </div>

        <Spacer factor={3} />

        <Typography
          variant="body2"
          component="div" // div cannot be descendant of p
        >
          <TextField
            id="reason-for-change"
            multiline
            fullWidth
            rows={4}
            onChange={(evt) => this.setState({ reasonForChange: evt.target.value })}
            margin="normal"
            variant="outlined"
            className={classes.textReason}
            InputProps={{
              classes: { root: classes.input },
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '1em' },
            }}
            defaultValue={this.setCurrentValue('reasonForChange')}
            label={<div>Reason for Change</div>}
          />

          <Spacer factor={2} />

          <TextField
            id="need-description"
            multiline
            fullWidth
            rows={4}
            onChange={(evt) => this.setState({ descriptionOfChange: evt.target.value })}
            margin="normal"
            variant="outlined"
            className={classes.textReason}
            InputProps={{
              classes: { root: classes.input },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={this.setCurrentValue('descriptionOfChange')}
            label={'Description of Change'}
          />

          <Spacer factor={2} />

          <Button
            disabled={
              smallCircle
                ? false
                : reasonForChange === '' ||
                  descriptionOfChange === '' ||
                  !this.getCurrentValue('reasonForChange') ||
                  !this.getCurrentValue('descriptionOfChange')
            }
            id="change-summary-submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              handleComplete({
                descriptionOfChange: smallCircle
                  ? this.getNullableValue('descriptionOfChange')
                  : this.getCurrentValue('descriptionOfChange'),

                reasonForChange: smallCircle
                  ? this.getNullableValue('reasonForChange')
                  : this.getCurrentValue('reasonForChange'),
              });
            }}
          >
            {buttonText}
          </Button>
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(ChangeSummary);
