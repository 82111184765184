import React from 'react';

import debounce from 'lodash/debounce';

import { InputBaseProps, InputBase } from '@material-ui/core';

import { DEBOUNCE_MILLISECONDS } from '../../../../constants';
import Adornment from './Adornment';
import { useStyles } from './styles';
import clsx from 'clsx';

interface Props {
  debounced?: boolean;
}

function Input(props: Props & InputBaseProps) {
  const { onChange, debounced, ...rest } = props;

  const onChangeWrapped = debounced && onChange ? debounce(onChange, DEBOUNCE_MILLISECONDS) : onChange;

  const classes = useStyles();
  const className = clsx(classes.textReason, !!props.className && props.className);

  return (
    <InputBase
      readOnly={false}
      {...rest}
      classes={{
        input: classes.input,
        ...rest.classes,
      }}
      className={className}
      fullWidth
      onChange={(e) => {
        e.persist();
        if (onChangeWrapped) {
          onChangeWrapped(e);
        }
      }}
    />
  );
}

Input.Adornment = Adornment;

export default Input;
