import React from 'react';
import PrintViewMenu from 'components/PrintViewMenu';
import PayloadFields from './PayloadFields';

export function DocumentToolbar(props) {
  // We can be called from the Effective View without an ID due to blur effect 😿
  if (!props.versionId) return null;

  return (
    <>
      <PrintViewMenu {...props} />
    </>
  );
}

const docMetadata = {
  listViewColumns: [
    {
      field: 'customIdentifier',
      headerName: 'ID',
      type: 'id',
    },
    {
      field: 'currentVersion.title',
      headerName: 'Title',
      type: 'regular_text',
    },
    {
      field: 'currentVersion',
      headerName: 'Version',
      type: 'version',
    },
    {
      field: 'currentVersion.currentStatusName',
      headerName: 'Status',
      type: 'status',
    },
    {
      field: 'currentVersion.createdAt',
      headerName: 'Initiation Date',
      type: 'date',
    },
    {
      field: 'owner',
      headerName: 'Owner',
      type: 'user',
    },
    {
      field: 'approver',
      headerName: 'Approver',
      type: 'user',
    },
  ],
  EffectiveViewOptions: DocumentToolbar,
  PayloadFields,
};

export * from './gql';
export { docMetadata };
