import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
    },
    list: {
      margin: 0,
      padding: 0,
    },
    listEntry: {
      listStyleType: 'none',
      display: 'flex',
    },
    text: {
      marginRight: theme.spacing(1),
    },
    heading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 16,
      textAlign: 'center',
    },
    icon: {
      fontSize: '3rem',
      height: 48,
      marginBottom: 24,
    },
  }),
);
