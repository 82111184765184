import React from 'react';

import Schedule from '@material-ui/icons/Schedule';
import { Typography } from '@material-ui/core';

import { isUndefined, reverse } from 'lodash';

import SectionLabel from 'components/core/SectionLabel';
import PreviousVersions from 'workflows/util/previousVersions';

import PreviousVersionItem from './PreviousVersionItem';
import { useStyles } from './styles';

export type Labels = {
  dateLabel?: string;
  statusLabel?: string;
  versionTypeLabel?: string;
  versionTypeSecondLabel?: string;
};

export type PreviousVersionsListProps = {
  title: string;
  labels: Labels;
  versions: any[];
  Component: any;
};

const PreviousVersionsList = (props: PreviousVersionsListProps) => {
  const { title, Component, versions, labels } = props;
  const classes = useStyles();

  const prevVersions = PreviousVersions(versions);
  if (isUndefined(prevVersions) || prevVersions.length === 0) {
    return null;
  }

  return (
    <div className={classes.container}>
      <section className={classes.panel}>
        <SectionLabel icon={Schedule}>
          <Typography variant={'h3'}>{title}</Typography>
        </SectionLabel>
      </section>
      <div className={classes.versionsContainer}>
        {reverse(prevVersions).map((version: any, index: number) => (
          <PreviousVersionItem
            isInitial={prevVersions.length - 1 === index}
            labels={labels}
            key={version.versionIdentifier}
            version={version}
            Component={Component}
          />
        ))}
      </div>
    </div>
  );
};

export default PreviousVersionsList;
