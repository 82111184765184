import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import React from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Trans } from '@lingui/macro';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Check from '@material-ui/icons/Check';
import Flag from '@material-ui/icons/Flag';

import {
  Breadcrumbs,
  Blur,
  ContentSection,
  ItemTitle,
  Spacer,
  ErrorBoundary,
  Loading,
  NoReleaseModal,
} from 'components';
import { ContextDrawer, ItemControls, TimelineGroup } from 'compositions';
import { GenericWorkflowStatuses } from 'workflows/statuses';

import styles from './styles';
import { builderViewQuery } from './gql';
import { getAuthToken } from 'utils';
import { UPDATE_DOCUMENT_VERSION_INPUT_METHOD } from 'components/BuilderView/gql';
import AttachmentClient from 'utils/AttachmentClient';

const { Released } = GenericWorkflowStatuses;

function noCurrentVersion(itemRecord) {
  const statusName = get(itemRecord, 'currentVersion.currentStatusName');
  return statusName === Released.id;
}

// NOTE: should update to use Ilya's code when that is merged
const SHOW_PDF = ['incomingQualityInspection', 'inProcessInspection', 'finalDeviceInspection', 'supplierQuestionnaire'];

interface FormBasedBuilderViewProps {
  classes;
  itemId: string;
  categorySlug: string;
  itemTypeName: string;
  listViewQueryToRefetch: any;
  PayloadFields: (props: { layoutClasses; versionId: string; itemType: string }) => JSX.Element | null;
}
function FormBasedBuilderView(props: FormBasedBuilderViewProps) {
  const [updateDocumentVersionInputMethod] = useMutation(UPDATE_DOCUMENT_VERSION_INPUT_METHOD);
  const PayloadFields = props.PayloadFields;

  const matchesPrint = useMediaQuery('print');
  const { data, loading, error } = useQuery(builderViewQuery, {
    variables: {
      itemId: props.itemId,
      itemType: snakeCase(props.itemTypeName),
    },
  });
  if (matchesPrint) return null;
  if (loading || !data) return <Loading />;
  if (error) throw new Error(`Error loading Form BuilderView\n${error}`);
  const itemRecord = data.item;

  const currentVersion = itemRecord.currentVersion;
  const itemTitle = get(itemRecord, 'currentVersion.title', '');
  const titlePrefix = `${get(itemRecord, 'customIdentifier', '')}`;

  /*
    version 3 of Material UI has a defect which forces scroll to be disabled if
    any modal is active.  V4 includes a "disableScrollLock" prop for this:
    https://material-ui.com/api/modal/#props
  */
  if (noCurrentVersion(itemRecord)) window.scroll(0, 0);

  const showPDFExport = SHOW_PDF.includes(props.itemTypeName);

  return (
    <Grid container data-testid="item" spacing={3} className={props.classes.container}>
      <Grid item xs={8} className={props.classes.grid}>
        <Breadcrumbs categorySlug={props.categorySlug} itemTitle={`${titlePrefix}: ${itemTitle}`} />
        <Grid container item xs={12} justify="space-between">
          <Blur blur={noCurrentVersion(itemRecord)} container={false}>
            <ItemTitle
              editable={!get(currentVersion, 'locked')}
              itemTitle={itemTitle}
              titlePrefix={titlePrefix}
              versionId={get(currentVersion, 'id')}
              refetchQueries={['FormBasedBuilderViewQuery']}
            />
          </Blur>
          <ItemControls
            showPDFExport={showPDFExport}
            pdfExportHeader={`${itemRecord.customIdentifier}: ${itemRecord.currentVersion.title}`}
            peopleProps={{
              locked: currentVersion.locked,
              approver: currentVersion.approver, // Temp fix until multiple approvers
              creator: currentVersion.creator,
              createdAt: currentVersion.createdAt,
              owner: currentVersion.owner, // Temp fix until multiple owners
              permissibleApprovers: currentVersion.permissibleApprovers,
              permissibleOwners: currentVersion.permissibleOwners,
              versionId: currentVersion.id,
              version: currentVersion,
              refetchQueries: ['FormBasedBuilderViewQuery'],
            }}
            tagsProps={{
              itemId: itemRecord.id,
              tags: itemRecord.tags,
              refetchQueries: [props.listViewQueryToRefetch, 'FormBasedBuilderViewQuery', 'tagsQuery'],
            }}
            ellipsesProps={{
              attachment: get(currentVersion, 'attachment.filename'),
              inputMethod: currentVersion.inputMethod.toUpperCase(),
              referenceId: itemRecord.referenceId,
              key: `elmenu:${itemRecord.currentVersion.pdfDataUpload}`,
              itemId: itemRecord.id,
              versionId: itemRecord.currentVersion.id,
              locked: currentVersion.locked,
              onInputMethodChange: (inputMethod) => {
                updateDocumentVersionInputMethod({
                  variables: {
                    versionId: itemRecord.currentVersion.id,
                    inputMethod: inputMethod,
                  },
                  refetchQueries: [
                    'FormBasedBuilderViewQuery',
                    `${startCase(props.itemTypeName).replace(/\s/g, '')}Version`,
                  ],
                }).catch((err) => {
                  throw new Error(`Error updating input method: ${err.message}`);
                });
              },
              onPDFDelete: () => {
                const attachmentId = get(itemRecord, 'currentVersion.attachment.id');
                if (!attachmentId) return;

                const [token] = getAuthToken();
                new AttachmentClient(token).delete(itemRecord.currentVersion.id, attachmentId).catch((error) => {
                  throw new Error(`Deletion of attachment failed: ${error.message}`);
                });
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container item xs={8} className={props.classes.grid}>
        <Grid item xs={8} className={props.classes.gridGutterRight}>
          <ErrorBoundary>
            <TimelineGroup
              key={itemRecord.currentVersion.currentStatusName}
              itemType={props.itemTypeName}
              itemId={props.itemId}
              queriesToRefetch={['FormBasedBuilderViewQuery']}
              itemData={itemRecord}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={4} className={props.classes.gridGutterLeft}>
          <ContentSection LabelText={<Trans>Item Notes</Trans>} Icon={<Flag className={props.classes.icon} />}>
            <Spacer factor={1} />
            <div className={props.classes.itemNotes}>
              <Spacer factor={3} />
              <Typography variant="body2" className={props.classes.iconTextStack}>
                <Check className={props.classes.icon} />
                <Trans>No new updates</Trans>
              </Typography>
            </div>
          </ContentSection>
        </Grid>
      </Grid>

      <Blur blur={noCurrentVersion(itemRecord)}>
        <PayloadFields
          layoutClasses={props.classes}
          itemType={props.itemTypeName}
          versionId={itemRecord.currentVersion.id}
        />
      </Blur>

      <NoReleaseModal
        open={noCurrentVersion(itemRecord)}
        modalTopPosition="50%"
        viewName="Builder View"
        message={
          <Trans>
            This item has no in-progress version. Select Effective View from the view menu for the current effective
            view of this item.
          </Trans>
        }
        showLink={false}
      />
      <ContextDrawer itemId={props.itemId} itemType={props.itemTypeName} />
    </Grid>
  );
}

export default withStyles(styles)(FormBasedBuilderView);
