import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { folderFragment } from '../setsUtil';
import { RequirementsSetVersionQuery } from './__generated__/RequirementsSetVersionQuery';

export const memberFragment = gql`
  fragment REQMemberFragment on RequirementsSetMember {
    id
    version {
      id
      title
      requirementDescription
      requirementTypes
      locked
      versionIdentifier
      currentStatusName
    }
    indentationLevel
    sortPosition
    folderOneName
    folderTwoName
    folderThreeName
  }
`;

const versionQuery = gql`
  query RequirementsSetVersionQuery($id: ID!) {
    requirementsSetVersion(id: $id) {
      id
      title
      locked
      versionIdentifier
      currentStatusName
      mayTransitionToDraft
      mayTransitionToUnderReview
      mayTransitionToOwnerApproval
      mayTransitionToApprovedDraft
      mayTransitionToReleased
      mayTransitionToRejected
      mayTransitionToObsolete
      mayTransitionToVoid
      setItemChildren {
        ... on RequirementsSetMember {
          id
          version {
            id
            title
            requirementDescription
            requirementTypes
            setMember {
              id
              setItemVersion {
                id
                item {
                  id
                }
              }
            }
            locked
            currentStatusName
            versionIdentifier
            item {
              id
              itemType {
                id
                displaySlug
                userCreatable
              }
              customIdentifier
              tags
              comments {
                id
              }
              currentVersion {
                id
                versionIdentifier
                currentStatusName
              }
              workingVersion {
                id
                versionIdentifier
                currentStatusName
                currentStatus {
                  name
                  titleizedName
                }
              }
              currentRelease {
                id
                versionIdentifier
                currentStatusName
                currentStatus {
                  name
                  titleizedName
                }
              }
            }
          }
          indentationLevel
          sortPosition
          sortPositionsJson
          folderOneName
          folderTwoName
          folderThreeName
        }
        ... on Folder {
          id
          indentationLevel
          sortPosition
          folderName
        }
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<RequirementsSetVersionQuery>(versionQuery, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });
}

export const addFolder = gql`
  mutation createRequirementsSetFolder(
    $requirementsSetVersionId: ID!
    $folderName: String!
    $sortPosition: Int!
    $indentationLevel: Int!
  ) {
    createRequirementsSetFolder(
      requirementsSetVersionId: $requirementsSetVersionId
      folderName: $folderName
      sortPosition: $sortPosition
      indentationLevel: $indentationLevel
    ) {
      folder {
        id
        indentationLevel
        sortPosition
        folderName
      }
    }
  }
`;

export const addMembers = gql`
  mutation createRequirementsSetMembers(
    $requirementsSetVersionId: ID!
    $requirementVersionIds: [ID!]!
    $sortPosition: Int!
    $indentationLevel: Int!
  ) {
    createRequirementsSetMembers(
      requirementsSetVersionId: $requirementsSetVersionId
      requirementVersionIds: $requirementVersionIds
      sortPosition: $sortPosition
      indentationLevel: $indentationLevel
    ) {
      members {
        ... on RequirementsSetMember {
          ...REQMemberFragment
        }
        ... on Folder {
          ...FolderFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const linkMembers = gql`
  mutation linkNewRequirementVersionToRequirementsSet($input: LinkNewRequirementVersionToRequirementsSetInput!) {
    linkNewRequirementVersionToRequirementsSet(input: $input) {
      clientMutationId
      requirementVersion {
        id
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export const bulkUpdateMembersMutation = gql`
  mutation BulkUpdateRequirementsSetMembers($members: [BulkUpdateRequirementsSetMember!]!) {
    bulkUpdateRequirementsSetMembers(members: $members) {
      returnMembers {
        id
      }
    }
  }
`;

export const updateFolder = gql`
  mutation UpdateREQSFolder(
    $requirementsSetFolderId: ID!
    $folderName: String
    $sortPosition: Int
    $indentationLevel: Int
  ) {
    updateRequirementsSetFolder(
      requirementsSetFolderId: $requirementsSetFolderId
      folderName: $folderName
      sortPosition: $sortPosition
      indentationLevel: $indentationLevel
    ) {
      children {
        ... on RequirementsSetMember {
          ...REQMemberFragment
        }
        ... on Folder {
          ...FolderFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const updateMember = gql`
  mutation UpdateREQSMember(
    $setMemberId: ID!
    $sortPosition: Int
    $indentationLevel: Int
    $memberVersionId: ID
    $folderOne: ID
    $folderTwo: ID
    $folderThree: ID
  ) {
    updateRequirementsSetMember(
      requirementsSetMemberId: $setMemberId
      sortPosition: $sortPosition
      indentationLevel: $indentationLevel
      setMemberVersionId: $memberVersionId
      folderOne: $folderOne
      folderTwo: $folderTwo
      folderThree: $folderThree
    ) {
      children {
        ... on RequirementsSetMember {
          ...REQMemberFragment
        }
        ... on Folder {
          ...FolderFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const removeFolder = gql`
  mutation removeRequirementsSetFolder($requirementsSetFolderId: ID!) {
    removeRequirementsSetFolder(requirementsSetFolderId: $requirementsSetFolderId) {
      children {
        ... on Folder {
          ...FolderFragment
        }
        ... on RequirementsSetMember {
          ...REQMemberFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const removeMember = gql`
  mutation removeRequirementsSetMember($requirementsSetMemberId: ID!) {
    removeRequirementsSetMember(requirementsSetMemberId: $requirementsSetMemberId) {
      children {
        ... on RequirementsSetMember {
          ...REQMemberFragment
        }
        ... on Folder {
          ...FolderFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const switchVersion = gql`
  mutation updateVersionOnSetMember($setMemberId: ID!, $memberVersionId: ID) {
    updateRequirementsSetMember(requirementsSetMemberId: $setMemberId, setMemberVersionId: $memberVersionId) {
      member {
        id
        version {
          id
          title
          requirementDescription
          requirementTypes
          locked
          item {
            id
            tags
            currentVersion {
              id
              versionIdentifier
              currentStatusName
            }
            workingVersion {
              id
              versionIdentifier
              currentStatusName
            }
            currentRelease {
              id
              versionIdentifier
              currentStatusName
            }
          }
        }
      }
    }
  }
`;
