import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    textBox: {
      fontSize: '9px !important',
      lineHeight: '12px  !important',
    },
    label: {
      paddingBottom: theme.spacing(0.5),
    },
    multiSection: {
      minHeight: 141,
    },
    dateStatic: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    tableBody: {
      borderRadius: 4,
    },
    tableCell: {
      ...theme.typography.body2,
      padding: theme.spacing(1),
    },
    tableHeader: {
      ...theme.typography.body2,
      whiteSpace: 'nowrap',
      padding: theme.spacing(1),
      fontWeight: 600,
    },
    wrapped: {
      whiteSpace: 'normal',
    },
    tableRow: {
      // @ts-ignore
      border: `2px solid ${theme.palette.common.input.outline}`,
      cursor: 'pointer',
      '&:hover': {
        border: `2px double ${theme.palette.primary.main} !important`,
        borderStyle: 'double',
      },
      '&:hover span': {
        visibility: 'visible',
      },
    },
    textAreaContainer: {
      minHeight: 66,
      whiteSpace: 'pre-line',
      cursor: 'text',
    },
    // textField: {
    //   minHeight: 66,
    // },
    link: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    deleteIcon: {
      visibility: 'hidden',
      '&:hover': {
        pointer: 'cursor',
        color: theme.palette.primary.main,
      },
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      '&.Mui-disabled': {
        background: 'transparent',
        cursor: 'text',
        resize: 'none',
        color: 'rgba(0, 0, 0, .6)',
      },
      '&.Mui-disabled fieldset': {
        border: 'none',
        padding: 0,
      },
    },
    disabled: {
      background: 'transparent',
      border: 'none',
      padding: 0,
      cursor: 'text',
      resize: 'none',
      color: 'rgba(0, 0, 0, .6)',
    },
    input: {
      padding: '8px',
    },
    notchedOutline: {
      // @ts-ignore
      border: `2px solid ${theme.palette.common.input.outline} !important`,
    },
    textField: {
      minHeight: 66,
    },
    dateTextField: {
      width: '100%',
      paddingRight: 0,
    },
    panel: {
      width: '100%',
    },
  }),
);
