import React from 'react';

import { DateTime } from 'luxon';

import { YesOrNo } from 'compositions/TimelineGroup/steps/views';
import SupplierRevocationRejection from 'compositions/TimelineGroup/steps/views/SupplierRevocationRejection';
import { SupplierWorkflowStatuses } from 'workflows/statuses';

const TO_STATUS = SupplierWorkflowStatuses.QualificationRevoked.id;

export default {
  initialStep: 'revokeApproval',
  internalSteps: {
    revokeApproval: {
      component: (props) => (
        <YesOrNo
          heading={'Revoke Approval'}
          disableOnSubmit
          prompt={props.currentVersion.approvalExpiresAt
            ?
            `This supplier approval will expire on ${DateTime.fromISO(
              props.currentVersion.approvalExpiresAt,
            ).toFormat(
              'ccc MM.dd.yyyy',
            )}. After this date the supplier will need to be requalified. Select “Revoke” to expire the approval immediately.`
            :
            `Are you sure you want to revoke this supplier’s approval?`}
          yesLabel="Revoke"
          noLabel="Cancel"
          {...props}
        />
      ),
      getNextStep: (revokeApproval) => (revokeApproval ? 'revoking' : 'complete'),
    },
    revoking: {
      component: (props) => {
        return (
          <SupplierRevocationRejection
            stepProps={props}
            title={'Reason for Revocation'}
            subText={
              'Please provide context for revoking the approval on the supplier and upload any pertinent files, and associated items.'
            }
            signatureDialog="My signature indicates that I have checked this Supplier and all associated qualification activities and have determined this supplier cannot be approved."
            reasonField={{
              fieldName: 'reasonForRevocation',
              fieldLabel: 'Reason For Revoking Approval',
            }}
          />
        );
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ revokeApproval, revoking }) => {
    if (revokeApproval) {
      return {
        toStatus: TO_STATUS,
        ...revoking,
      };
    }
    return {};
  },
};
