import React, { FC } from 'react';
import { Link } from '@reach/router';

import { Box, Typography } from '@material-ui/core';

import { dateFormatter } from 'components/AgGridTable/Fields/date';

import { useStyles } from './styles';

interface Props {
  id: string;
  customIdentifier: string;
  dueDate: string | null;
  onRedirect: () => void;
}

const TaskItemHeader: FC<Props> = ({ id, customIdentifier, dueDate, onRedirect }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <Link to={`/category/task/${id}`} onClick={onRedirect} className={classes.titleLink}>
        <Typography variant="body2" color="inherit">
          {customIdentifier}
        </Typography>
      </Link>
      <Box flex={1} />
      <Typography variant="subtitle2" className={classes.dueDate}>
        Due: {dateFormatter(dueDate)}
      </Typography>
    </div>
  );
};

export default TaskItemHeader;
