import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { currentUser_currentUser } from 'compositions/__generated__/currentUser';

export interface NotificationPreferencesProps {
  currentUser: currentUser_currentUser;
}

export type Preference = {
  [key: string]: {
    headline: {
      icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
      title: string;
    };
    preferenceTitles: {
      [key: string]: string;
    };
  };
};

export type NotificationChannel = {
  in_app: boolean;
  email: boolean;
};

export enum NotificationChannelEnum {
  in_app = 'in_app',
  email = 'email',
}
