import gql from 'graphql-tag';
import { t } from '@lingui/macro';

import slugs from '../../displaySlugs';
import { docMetadata } from '../mixins/Document';
import { docStylePayloadFields } from '../mixins/DocumentBased/gql';

import { QueryBasedPayloadFields } from '../mixins/DocumentBased/QueryBasedPayloadFields';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';

const versionQuery = gql`
  query DeviceUsageDescriptionVersion($id: ID!) {
    deviceUsageDescriptionVersion(id: $id) {
      ${docStylePayloadFields}
    }
  }
`;

const itemType = 'deviceUsageDescription';

const PayloadFields = QueryBasedPayloadFields(versionQuery, itemType);

export default {
  categoryLabel: t`Device Usage Description`,
  displaySlug: slugs[itemType],
  label: t`Device Usage Description`,
  listViewQuery: gql`
    query DeviceUsageDescriptionQuery {
      deviceUsageDescriptions {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          versionIdentifier
          currentStatusName
        }
      }
    }
  `,
  itemType,
  icon: DevicesOtherIcon,
  ...docMetadata,
  PayloadFields,
};
