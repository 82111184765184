import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

import slugs from '../../displaySlugs';
import { listFragment, docMetadata } from '../mixins/Document';

const itemType = 'qualitySystemValidationReport';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Quality System Validation Report`,
  categoryLabel: t`Validation Reports`,
  icon: AssignmentTurnedIn,
  listViewQuery: gql`
    query qualitySystemValidationReportsQuery {
      documents(itemType: "quality_system_validation_report") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
