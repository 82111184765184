import React from 'react';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import { Grid, withStyles } from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { compact } from 'lodash';

import { Blur, Breadcrumbs, ItemTitle } from 'components';
import ContextDrawer from 'compositions/ContextDrawer';
import EffectiveViewData from 'compositions/EffectiveViewData';
import styles from 'compositions/EffectiveViewData/styles';
import NoReleaseModal from 'components/NoReleaseModal';

import CreateFormBasedRecord from './CreateFormBasedRecord';
import { effectiveViewQuery } from './gql';

interface FormBasedEffectiveViewProps {
  PayloadFields: (props: { versionId: string; layoutClasses }) => JSX.Element | null;
  itemId: string;
  itemTypeName: string;
  classes;
  formBasedRecordItemTypeName: string;
  listViewQueryToRefetch;
  categorySlug;
  EffectiveViewOptions?: (props: {
    pdfExportHeading: string;
    pdfExportSubheading: string;
    versionId: string;
  }) => JSX.Element | any;
}
function FormBasedEffectiveView(props: FormBasedEffectiveViewProps) {
  const PayloadFields = props.PayloadFields;
  const EffectiveViewOptions = props.EffectiveViewOptions;

  const { data, loading, error } = useQuery(effectiveViewQuery, {
    fetchPolicy: 'network-only',
    variables: {
      itemId: props.itemId,
      itemType: snakeCase(props.itemTypeName),
    },
  });
  if (loading || !data) return null;
  if (error) throw new Error(`${error}`);
  const itemRecord = data.item;
  const currentUser = itemRecord.currentUser;

  const { currentRelease } = itemRecord;
  const itemTitle = get(currentRelease, 'title', '');
  const enabled = Boolean(currentRelease);

  return (
    <Grid container data-testid="item" spacing={3} className={props.classes.container}>
      <Grid item xs={12} className={props.classes.grid}>
        <Breadcrumbs categorySlug={props.categorySlug} itemTitle={`${itemRecord.customIdentifier}: ${itemTitle}`} />
        <Grid container item xs={8} justify="space-between">
          <ItemTitle
            itemTitle={itemTitle}
            titlePrefix={`${itemRecord.customIdentifier}`}
            versionId={get(currentRelease, 'id', '')}
            editable={false}
          />
          <div>
            {EffectiveViewOptions && (
              <Blur blur={!enabled} style={{ width: '100%' }}>
                <EffectiveViewOptions
                  pdfExportHeading={compact([
                    itemRecord.customIdentifier,
                    get(itemRecord.currentRelease, 'title'),
                  ]).join(': ')}
                  pdfExportSubheading="Effective View"
                  versionId={get(itemRecord.currentRelease, 'id')}
                />
              </Blur>
            )}
          </div>
          {currentRelease && (
            <CreateFormBasedRecord
              formIdentifier={itemRecord.customIdentifier}
              versionId={currentRelease.id}
              refetchQueries={[props.listViewQueryToRefetch]}
              itemTypeName={props.formBasedRecordItemTypeName}
              currentUser={currentUser}
            />
          )}
        </Grid>
      </Grid>

      <EffectiveViewData itemType={props.itemTypeName} itemId={props.itemId} blur={!enabled} displayHistoryTable />

      <Blur blur={!enabled} style={{ width: '100%', display: 'flex' }}>
        <PayloadFields layoutClasses={props.classes} versionId={get(currentRelease, 'id')} />
      </Blur>

      {enabled && <ContextDrawer itemId={props.itemId} itemType={props.itemTypeName} />}

      <NoReleaseModal open={!enabled} />
    </Grid>
  );
}

export default withStyles(styles)(FormBasedEffectiveView);
