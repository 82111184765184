import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { AttachFileOutlined, PlaylistAddCheck, Sort } from '@material-ui/icons';

import get from 'lodash/get';
import { useMutation } from 'react-apollo';
import clsx from 'clsx';

import { CheckList, FileUploadIcon, Version as VersionIcon } from 'assets/icons';
import { Loading, PDFViewer, Spacer, TextAreaField } from 'components';
import SectionLabel from 'components/core/SectionLabel';
import ColorizedTriStateToggle from 'components/TriStateToggle/Colorized';
import Panel from 'components/core/Panel';
import { PayloadFieldsProps } from 'itemTypes';

import { AssociatedTestProtocol } from './AssociatedTestProtocol';
import { UPDATE_VALIDATION_RECORD_VERSION, useVersion } from './gql';
import { DeviationsTable } from './DeviationsTable';
import { AdditionalInformation } from './AdditionalInformation';
import { RecordAttachmentSection } from './RecordAttachmentSection';
import { useStyles } from './styles';

function PayloadFields(props: PayloadFieldsProps) {
  const { versionId, layoutClasses } = props;

  const classes = useStyles();

  const { data, loading, error, refetch } = useVersion(versionId!);
  const [update] = useMutation(UPDATE_VALIDATION_RECORD_VERSION);

  if (error) throw new Error(`Error loading Validation Record version ${versionId}\n${error}`);
  if (loading || !data) return <Loading />;

  const currentVersion = data.validationRecordVersion;

  const onChangeFulfillAllAcceptanceCriteria = (value: boolean | null) => {
    update({
      variables: { id: get(currentVersion, 'id', ''), isFulfillAllAcceptanceCriteria: value },
      refetchQueries: ['ValidationRecordVersionQuery'],
    })
      .then((_) => Promise.resolve())
      .catch((err) => {
        throw new Error(`Updating isFulfillAllAcceptanceCriteria failed. ${err.message}`);
      });
  };

  return (
    <Grid container data-testid="item" spacing={2}>
      <Grid container item xs={12}>
        <Grid item xs={5} className={clsx(layoutClasses.gridGutterRight, classes.protocol)}>
          <Panel className={classes.fullHeight}>
            <SectionLabel icon={PlaylistAddCheck}>Associated Test Protocol</SectionLabel>
            <AssociatedTestProtocol deviceValidationVersion={currentVersion!.deviceValidationVersion} />
            <Spacer factor={1} />
          </Panel>
        </Grid>
        <Grid item xs={7} className={layoutClasses.gridGutterLeft}>
          <Panel>
            <RecordAttachmentSection refetch={refetch} layoutClasses={layoutClasses} currentVersion={currentVersion!} />
            <Spacer factor={1} />
          </Panel>
        </Grid>
      </Grid>

      <Grid container item>
        <div>
          <SectionLabel icon={CheckList}>Summary of Test Results</SectionLabel>
          <ColorizedTriStateToggle
            onChange={onChangeFulfillAllAcceptanceCriteria}
            disabled={get(currentVersion, 'locked', false)}
            toggled={get(currentVersion, 'isFulfillAllAcceptanceCriteria', null)}
          />
        </div>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          <Panel>
            <Typography component="div" variant="button">
              <Sort /> Execution Notes
            </Typography>
            <Spacer factor={1} />
            <TextAreaField
              id="notes-text-area"
              attrName="notes"
              initialValue={currentVersion!.notes || ''}
              labelText="Execution Notes"
              locked={currentVersion!.locked}
              refetchQueries={['ValidationRecordsVersion']}
              versionId={currentVersion!.id}
            />
          </Panel>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          <Panel>
            <Typography component="div" variant="button">
              <VersionIcon fontSize={'small'} /> Deviations
            </Typography>
            <Spacer factor={1} />
            <DeviationsTable currentVersion={currentVersion!} />
          </Panel>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          <Panel>
            <Typography component="div" variant="button">
              <AttachFileOutlined /> Additional Information
            </Typography>
            <AdditionalInformation onUpdate={refetch} currentVersion={currentVersion!} />
            <Spacer factor={1} />
          </Panel>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          <SectionLabel icon={FileUploadIcon}>Executed Test Protocol</SectionLabel>
          <Spacer factor={1} />
          {currentVersion && currentVersion.executedTestProtocolAttachment && (
            <PDFViewer url={currentVersion.executedTestProtocolAttachment.url} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PayloadFields;
