import React, { ReactNode, useContext } from 'react';

import { Card, CardContent, Typography } from '@material-ui/core';

import { Spacer } from 'components';
import { TraceMatrixCardContext } from 'contexts/traceMatrixCardContext';

import { useStyles } from './styles';

import { useAllItemTypes } from 'hooks/useAllItemTypes';
import CardHeader from './CardHeader';

import OtherConnections, { OtherConnection } from '../OtherConnections';

interface Props {
  children: ReactNode;
  item: {
    customIdentifier: string;
    id: number;
    itemType: string;
    title?: string;
  };
  otherConnections?: OtherConnection[];
  selected: boolean;
  summary?: string;
}

function GraphCard(props: Props) {
  const { item } = props;
  const classes = useStyles();

  const { description, otherConnections } = useContext(TraceMatrixCardContext);
  const { loading, error, findTypeByName } = useAllItemTypes();

  function navLink() {
    const slug = findTypeByName(item.itemType).displaySlug;
    return !findTypeByName(item.itemType).userCreatable ? `/category/${slug}?view=Set` : `/category/${slug}/${item.id}`;
  }

  if (loading) return null;
  if (error) throw error;
  return (
    <>
      <Card className={`${(props.selected && classes.selected) || ''} ${classes.cardContentContainer}`}>
        <CardContent classes={{ root: classes.cardContent }}>
          <CardHeader
            isLink={item.itemType !== 'device_safety_characteristic'}
            customIdentifier={item.customIdentifier}
            itemType={item.itemType}
            linkTo={navLink()}
            title={item.itemType === 'risk' ? undefined : item.title}
          />
          <Spacer factor={1} />
          {props.summary && description.showing && <Typography style={{ fontSize: 13 }}>{props.summary}</Typography>}
        </CardContent>
        <Spacer factor={2} />
        {props.children}
        {props.otherConnections && otherConnections.showing && (
          <OtherConnections otherConnections={props.otherConnections} />
        )}
      </Card>
    </>
  );
}

export default GraphCard;
