import { Switch, createStyles, withStyles } from '@material-ui/core';

export default withStyles((theme) =>
  createStyles({
    root: {
      width: 30,
      height: 12,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: `1px  1px`,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(17px)',
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 10,
      height: 10,
      boxShadow: 'none',
      color: theme.palette.common.white,
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#8f8f8f',
      color: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);
