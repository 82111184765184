import React from 'react';
import { Modal, withStyles, createStyles, Paper } from '@material-ui/core';

import { YesOrNo } from 'components';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

const styles = createStyles((theme) => ({
  modalContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 322,
    padding: theme.spacing(2),
  },
}));

function AddVersionModal(props: {
  classes;
  open: boolean;
  onClose: (e) => void;
  customIdentifier: string;
  handleComplete: (value: boolean) => void;
}) {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Paper classes={{ root: props.classes.modalContainer }}>
        <YesOrNo
          handleComplete={props.handleComplete}
          handleClose={props.onClose}
          noLabel={i18n._(t`Dismiss`)}
          yesLabel={i18n._(t`Create`)}
          heading={`Create New Version of ${props.customIdentifier}?`}
          headingWithoutPromptText
          closeOnNo
        />
      </Paper>
    </Modal>
  );
}

export default withStyles(styles)(AddVersionModal);
