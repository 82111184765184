import React from 'react';
import { Trans } from '@lingui/macro';

import { ChangeOrderWorkflowStatuses } from 'workflows/statuses';
import { RejectReason, YesOrNo } from '../views';

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => {
        const prompt = (
          <Trans>
            Are you sure you want to Void your Ready For Closure signature of this Record? This action cannot be undone.
          </Trans>
        );
        return (
          <YesOrNo heading={<Trans>Void</Trans>} prompt={prompt} closeOnNo yesLabel="Yes" noLabel="No" {...props} />
        );
      },
      getNextStep: (cancel) => (cancel ? 'voidReason' : undefined),
    },
    voidReason: {
      component: (props) => {
        const heading = `As the ${
          props.currentVersion.assignedOwnerJobRole.formattedRoleName
        }, I void my signature on ${props.itemCustomIdentifier}.`;
        return <RejectReason {...props} title="Void" heading={heading} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: (props) => {
    return !props.confirmTransition
      ? null
      : {
          ...props.voidReason.signature,
          reasonForTransition: props.voidReason.reason,
          toStatus: ChangeOrderWorkflowStatuses.Void.id,
        };
  },
};
