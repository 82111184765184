import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'recompose';

import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import { AppBar, Tabs, Tab, Typography, withStyles } from '@material-ui/core';

import { Spacer } from 'components';
import withCurrentAppWindow from '../WithCurrentAppWindow';

import styles from './styles';

import ItemSearchTab from './ItemSearchTab';
import TagSearchTab from './TagSearchTab';

const a11yProps = (index) => {
  return {
    id: `search-tab-${index}`,
    'aria-controls': `search-tabpanel-${index}`,
  };
};

const SearchTabWrapper = withStyles({
  indicator: {
    height: '4px',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const SearchTab = withStyles((theme) => ({
  root: {
    '&:hover': {
      opacity: 1,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`search-tabpanel-${index}`}
      aria-labelledby={`search-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function Search({ classes, closeSelf, i18n }) {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (e, newTab) => setActiveTab(newTab);

  return (
    <div className={classes.searchMenuWrapper}>
      <Typography variant="body2" className={classes.searchHeading}>
        <Trans>Search</Trans>
      </Typography>
      <AppBar position="static" className={classes.searchTabsWrapper}>
        <SearchTabWrapper
          value={activeTab}
          onChange={handleTabChange}
          aria-label="search tabs"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <SearchTab classes={{ root: classes.searchTab }} label={i18n._(t`Search by ID or Title`)} {...a11yProps(0)} />
          <SearchTab classes={{ root: classes.searchTab }} label={i18n._(t`Search by Tag`)} {...a11yProps(1)} />
        </SearchTabWrapper>
      </AppBar>
      <TabPanel value={activeTab} index={0} className={classes.searchTabPanel}>
        <Spacer factor={1} />
        <ItemSearchTab closeSelf={closeSelf} />
      </TabPanel>
      <TabPanel value={activeTab} index={1} className={classes.searchTabPanel}>
        <Spacer factor={1} />
        <TagSearchTab closeSelf={closeSelf} />
      </TabPanel>
    </div>
  );
}

Search.propTypes = {
  classes: PropTypes.shape({
    searchHeading: PropTypes.string.isRequired,
    searchMenuWrapper: PropTypes.string.isRequired,
    searchTab: PropTypes.string.isRequired,
    searchTabPanel: PropTypes.string.isRequired,
    searchTabsWrapper: PropTypes.string.isRequired,
  }),
  i18n: PropTypes.shape({
    _: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(
  withI18n(),
  withStyles(styles),
  withCurrentAppWindow,
)(Search);
