import React from 'react';

import { useMutation } from 'react-apollo';

import { ADD_TRACE_MATRIX_VERSION_TRACE } from '../gql';

export default function withAddVersionTrace(Component) {
  return function(props) {
    const [mutation] = useMutation(ADD_TRACE_MATRIX_VERSION_TRACE),
      addVersionTrace = (variables) => mutation({ variables });

    return <Component {...props} addVersionTrace={addVersionTrace} />;
  };
}
