import React, { FC } from 'react';
import { Link } from '@reach/router';
import { Typography } from '@material-ui/core';

import { useStyles } from '../styles';

interface Props {
  link: string;
  identifier: string;
  title: string;
  onRedirect: () => void;
}

const ItemIdAndTitle: FC<Props> = ({ link, identifier, title, onRedirect }) => {
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.itemTitle}>
      <Link to={link} onClick={onRedirect} className={classes.itemIdLink}>
        {identifier}
      </Link>
      {': '}
      {title}
    </Typography>
  );
};

export default ItemIdAndTitle;
