/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum InputMethod {
  BODY = "BODY",
  GUIDED = "GUIDED",
  UPLOAD = "UPLOAD",
}

export enum LicenseTier {
  CORE_QS = "CORE_QS",
  CORE_QS_AND_DESIGN_QS = "CORE_QS_AND_DESIGN_QS",
  DESIGN_QS = "DESIGN_QS",
  NO_QS = "NO_QS",
  PRE_QS = "PRE_QS",
}

export enum TasksRelationship {
  BLOCKS = "BLOCKS",
  CAUSES = "CAUSES",
  EARLIEST_END_IS_START_OF = "EARLIEST_END_IS_START_OF",
  HAS_TO_BE_DONE_AFTER = "HAS_TO_BE_DONE_AFTER",
  HAS_TO_BE_DONE_BEFORE = "HAS_TO_BE_DONE_BEFORE",
  HAS_TO_BE_FINISHED_TOGETHER_WITH = "HAS_TO_BE_FINISHED_TOGETHER_WITH",
  HAS_TO_BE_STARTED_TOGETHER_WITH = "HAS_TO_BE_STARTED_TOGETHER_WITH",
  IS_BLOCKED_BY = "IS_BLOCKED_BY",
  IS_CAUSED_BY = "IS_CAUSED_BY",
  RELATES_TO = "RELATES_TO",
  START_IS_EARLIEST_END_OF = "START_IS_EARLIEST_END_OF",
}

export enum Trace {
  design_trace_matrix = "design_trace_matrix",
  risk_trace_matrix = "risk_trace_matrix",
}

export enum TrainingCategory {
  COURSE = "COURSE",
  READ_AND_UNDERSTAND = "READ_AND_UNDERSTAND",
}

export enum TrainingFormat {
  INDEPENDENT_STUDY = "INDEPENDENT_STUDY",
  INSTRUCTOR_LED = "INSTRUCTOR_LED",
  ON_THE_JOB = "ON_THE_JOB",
}

export enum TrainingType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export enum Type {
  contract = "contract",
  w2 = "w2",
}

export interface BulkUpdateRequirementsSetMember {
  id: string;
  sortPositionsJson: any;
  requirementTypes?: string[] | null;
  folderOne?: string | null;
  folderTwo?: string | null;
  folderThree?: string | null;
}

export interface BulkUpdateUserNeedsSetMember {
  id: string;
  sortPositionsJson: any;
  stakeholders?: string[] | null;
  folderOne?: string | null;
  folderTwo?: string | null;
  folderThree?: string | null;
}

/**
 * Autogenerated input type of CreateAccountCreatedEmail
 */
export interface CreateAccountCreatedEmailInput {
  username: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateComment
 */
export interface CreateCommentInput {
  itemId: string;
  body: string;
  creatorId?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateFormRecord
 */
export interface CreateFormRecordInput {
  formVersionId: string;
  productId: string;
  title: string;
  host: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateItemAndMember
 */
export interface CreateItemAndMemberInput {
  parentCollectionId: string;
  itemType: string;
  productId: number;
  title: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateItem
 */
export interface CreateItemInput {
  itemType: string;
  productId?: number | null;
  title: string;
  ownerJobRoleId?: string | null;
  ownerUserId?: string | null;
  approverJobRoleId?: string | null;
  approverUserId?: string | null;
  username?: string | null;
  password?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateItemLink
 */
export interface CreateItemLinkInput {
  rootItemId: string;
  linkedItemIds: string[];
  direction: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreatePasswordResetEmail
 */
export interface CreatePasswordResetEmailInput {
  username: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateQuestionnaireRecord
 */
export interface CreateQuestionnaireRecordInput {
  questionnaireVersionId: string;
  productId: string;
  title: string;
  host: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateRecoverUsernameEmail
 */
export interface CreateRecoverUsernameEmailInput {
  emailAddress: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateRiskControlLink
 */
export interface CreateRiskControlLinkInput {
  rootItemId: string;
  linkedItemIds: string[];
  direction: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateTransition
 */
export interface CreateTransitionInput {
  versionId: string;
  toStatus: string;
  changeItemId?: string | null;
  justificationText?: string | null;
  username?: string | null;
  password?: string | null;
  reasonForSignature?: string | null;
  reasonForTransition?: string | null;
  reasonForChange?: string | null;
  reasonForRejection?: string | null;
  reasonForSupplierRejection?: string | null;
  reasonForRetire?: string | null;
  reasonForRevocation?: string | null;
  reasonForVoid?: string | null;
  descriptionOfChange?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateUserDeactivatedEmail
 */
export interface CreateUserDeactivatedEmailInput {
  userId: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateVersion
 */
export interface CreateVersionInput {
  itemId: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of DestroyLinkedChange
 */
export interface DestroyLinkedChangeInput {
  versionId: string;
  changeItemId: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of LinkNewRequirementVersionToRequirementsSet
 */
export interface LinkNewRequirementVersionToRequirementsSetInput {
  requirementsSetVersionId: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of LinkNewUserNeedVersionToUserNeedsSet
 */
export interface LinkNewUserNeedVersionToUserNeedsSetInput {
  userNeedsSetVersionId: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateChangeFields
 */
export interface UpdateChangeFieldsInput {
  versionId: string;
  reasonForChange?: string | null;
  descriptionOfChange?: string | null;
  changeItemId?: string | null;
  justificationText?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateClientConfig
 */
export interface UpdateClientConfigInput {
  trainingManagement?: UpdateTrainingManagement | null;
  supplierManagement?: UpdateSupplierManagement | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateCompany
 */
export interface UpdateCompanyInput {
  id: string;
  name: string;
  streetAddress: string;
  city: string;
  stateOrProvince: string;
  zipCode: string;
  phoneNumber: string;
  emailAddress: string;
  timeZone: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateEmployee
 */
export interface UpdateEmployeeInput {
  id: string;
  managerId?: string | null;
  customEmployeeId?: string | null;
  department?: string | null;
  type?: string | null;
  startDate?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateEsignatureRequiredCompleted
 */
export interface UpdateEsignatureRequiredCompletedInput {
  notificationId: string;
  completed: boolean;
  clientMutationId?: string | null;
}

export interface UpdateHarmSeverityLevel {
  id: string;
  name?: string | null;
}

export interface UpdateHazardousSituationProbabilityLevel {
  id: string;
  name?: string | null;
}

/**
 * Autogenerated input type of UpdateMentionCompleted
 */
export interface UpdateMentionCompletedInput {
  notificationId: string;
  completed: boolean;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateNotificationPreference
 */
export interface UpdateNotificationPreferenceInput {
  id: string;
  updatePreferences?: any | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdatePasswordFromToken
 */
export interface UpdatePasswordFromTokenInput {
  token: string;
  password: string;
  passwordConfirmation: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdatePassword
 */
export interface UpdatePasswordInput {
  password: string;
  passwordConfirmation: string;
  currentPassword: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateProfile
 */
export interface UpdateProfileInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  title?: string | null;
  phoneNumber?: string | null;
  secondaryPhoneNumber?: string | null;
  clientMutationId?: string | null;
}

export interface UpdateRiskMatrixEntries {
  id: string;
  riskPriorityCodeId?: string | null;
  harmSeverityLevel?: UpdateHarmSeverityLevel | null;
  hazardousSituationProbabilityLevel?: UpdateHazardousSituationProbabilityLevel | null;
}

/**
 * Autogenerated input type of UpdateRiskMatrixEntry
 */
export interface UpdateRiskMatrixEntryInput {
  riskMatrixEntries?: UpdateRiskMatrixEntries[] | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateRiskPriorityCode
 */
export interface UpdateRiskPriorityCodeInput {
  riskPriorityCodes?: UpdateRiskPriorityCodes[] | null;
  clientMutationId?: string | null;
}

export interface UpdateRiskPriorityCodes {
  id: string;
  name?: string | null;
  fullName?: string | null;
  color?: string | null;
}

export interface UpdateSupplierManagement {
  expirationHighRisk?: number | null;
  expirationMediumRisk?: number | null;
  expirationLowRisk?: number | null;
}

/**
 * Autogenerated input type of UpdateTaskCompleted
 */
export interface UpdateTaskCompletedInput {
  notificationId: string;
  completed: boolean;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateTrainingCompleted
 */
export interface UpdateTrainingCompletedInput {
  notificationId: string;
  completed: boolean;
  clientMutationId?: string | null;
}

export interface UpdateTrainingManagement {
  independentStudyCertificationText?: string | null;
  defaultTrainingRecordVersionDaysDue?: number | null;
  newTrainingPlanVersionDaysDue?: number | null;
  newDocumentVersionDaysDue?: number | null;
  uprevItemVersionDaysDue?: number | null;
  uprevTrainingPlanVersionDaysDue?: number | null;
}

/**
 * Autogenerated input type of UpdateVirtualAttribute
 */
export interface UpdateVirtualAttributeInput {
  versionId: string;
  attrName: string;
  stringValue?: string | null;
  textValue?: string | null;
  integerValue?: number | null;
  booleanValue?: boolean | null;
  datetimeValue?: any | null;
  arrayValue?: string[] | null;
  jsonbValue?: string | null;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
