import React, { useState } from 'react';

import flowRight from 'lodash/flowRight';
import get from 'lodash/get';

import { Close, LinearScale } from '@material-ui/icons';

import { Modal, Paper, Typography } from '@material-ui/core';

import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';

import WithCurrentUser from '../../WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import withMetadataFromServer from '../../WithMetadataFromServer';
import PreviousReleases from './PreviousReleases';

import { AlertDialog, AlertDialogWithUser, ContentSection, WorkflowIcon } from 'components';
import WorkflowMenu, { VoidMenuItem } from 'components/WorkflowMenu';
import ConnectBar from './ConnectBar';
import { SetGenericWorkflowStatuses, indexById } from 'workflows/statuses';
import rfcContent from 'workflows/GenericWorkflow/rfcContent';
import { SummaryOfChange } from 'compositions/TimelineGroup/steps/views';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

import { useStyles } from 'compositions/TimelineGroup/components/sharedStyles';

function SetGenericWorkflow(props) {
  const [alertModal, setAlertModal] = useState({
    body: '',
    heading: '',
    open: false,
  });
  const [deniedModalWithUser, setDeniedModalWithUser] = useState({
    open: false,
    user: null,
    role: null,
  });

  const [previousVersionModal, setPreviousVersionModal] = React.useState(false);
  const [versionForModal, setVersionForModal] = React.useState({});
  const classes = useStyles();

  const { ApprovedDraft, Draft, NotCreated, OwnerApproval, Rejected, UnderReview } = SetGenericWorkflowStatuses;

  const SetGenericWorkflowStatusesById = indexById(SetGenericWorkflowStatuses);

  const { currentCompanyAllowsTransitioning, handleInitTransition } = props;
  const currentVersion = props.currentVersion.releasedAt ? { currentStatusName: 'not_created' } : props.currentVersion;

  const matchPermissionGrant = (grantType) => {
    return get(props.currentUser, 'id', -1) === get(currentVersion, `${grantType}.id`, -2);
  };

  const canOwn = matchPermissionGrant('owner');
  const canApprove = matchPermissionGrant('approver');

  const { linkedChangeJustificationText, versionIdentifier } = currentVersion;

  const hasChangeOrder =
    Boolean(currentVersion.linkedChangeCustomIdentifier) && !Boolean(linkedChangeJustificationText);

  const currentWorkflowStatus = SetGenericWorkflowStatusesById[currentVersion.currentStatusName];

  const closeModal = () => {
    setAlertModal({
      open: false,
      body: '',
      heading: '',
    });
    setPreviousVersionModal(false);
  };

  const renderLicenseTypeModal = () => {
    return setAlertModal({
      open: true,
      heading: 'Item Status Locked',
      body: 'Item status updates require an upgraded license type. Contact your administrator to upgrade your license.',
    });
  };

  const renderTransitionErrorModal = () => {
    return setAlertModal({
      open: true,
      heading: 'Transition Error',
      body:
        'This version cannot be transitioned to Under Review if any members of this set are not in Unlocked status. Please create new versions or remove obsolete members.',
    });
  };

  const workflowControls = () => {
    const isVoidable = (status) =>
      currentVersion.mayTransitionToVoid &&
      ((canApprove && status.isEqualTo(ApprovedDraft)) || (canOwn && status.isEqualTo(OwnerApproval)));

    if (!currentCompanyAllowsTransitioning) return;

    return (
      <>
        <WorkflowMenu>
          <VoidMenuItem
            disabled={!isVoidable(currentWorkflowStatus)}
            onClick={() => handleInitTransition('voidSignature')}
          />
        </WorkflowMenu>
      </>
    );
  };

  const handleSmallBlueCircleClick = (currentVersion) => {
    if (currentWorkflowStatus.isEqualTo(NotCreated)) return handleInitTransition('createNewVersion');
    if (currentWorkflowStatus.isEqualTo(Draft) || (currentWorkflowStatus.isEqualTo(UnderReview) && canOwn))
      return handleInitTransition('reasonForChange');

    return handleInitTransition('summaryOfChange');
  };

  const inactiveUsers = getInactiveUsers(currentVersion.owner, currentVersion.approver);
  const usersWithoutActiveRole = getUsersWithoutActiveRole(
    currentVersion.owner,
    currentVersion.approver,
    currentVersion.assignedOwnerJobRole,
    currentVersion.assignedApproverJobRole,
  );

  const handleInactiveUserDeniedModal = (inactiveUsers) => {
    setDeniedModalWithUser({
      open: true,
      user: inactiveUsers[0].user,
      role: inactiveUsers[0].role,
    });
  };

  const handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    setDeniedModalWithUser({
      open: true,
      user: usersWithoutActiveRole[0].user,
      role: usersWithoutActiveRole[0].role,
    });
  };

  return (
    <ContentSection
      LabelText="Workflow"
      Icon={<LinearScale />}
      Controls={workflowControls()}
      classes={{ label: classes.timelineHeader }}
    >
      <div className={classes.timelineContainer}>
        <PreviousReleases
          generateSmallCircleContent={rfcContent}
          releasedVersions={props.releasedVersions}
          onClick={(v) => {
            setPreviousVersionModal(true);
            setVersionForModal(v);
          }}
        />

        <SmallCircle
          content={rfcContent(currentVersion)}
          color="draft"
          handleClick={() => handleSmallBlueCircleClick(currentVersion)}
        />

        {currentWorkflowStatus.isAfter(NotCreated) && (
          <>
            <ConnectBar color="draft" short visible />

            <WorkflowIcon
              type="draft"
              active={currentWorkflowStatus.isEqualTo(Draft)}
              completed={currentWorkflowStatus.isAfter(Draft)}
              version={versionIdentifier}
              label="Draft"
              visible
            />

            <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

            <SmallCircle
              content={hasChangeOrder ? 'CO' : linkedChangeJustificationText ? 'JST' : '?'}
              color="underReview"
              disabledEffect={!currentCompanyAllowsTransitioning}
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }
                if (!hasChangeOrder && !currentVersion.locked && !linkedChangeJustificationText) {
                  return handleInitTransition('ownerApprovalWithoutSig');
                }

                return handleInitTransition('associatedChange');
              }}
              visible
            />

            <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

            <WorkflowIcon
              type="underReview"
              active={currentWorkflowStatus.isEqualTo(UnderReview)}
              completed={currentWorkflowStatus.isAfter(UnderReview)}
              version={versionIdentifier}
              disabledEffect={!currentCompanyAllowsTransitioning}
              label="Under Review"
              size="large"
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }

                if (inactiveUsers.length) return handleInactiveUserDeniedModal(inactiveUsers);
                if (usersWithoutActiveRole.length)
                  return handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);

                if (currentVersion.mayTransitionToUnderReview) {
                  return handleInitTransition('underReview');
                }

                if (!currentVersion.mayTransitionToUnderReview) {
                  return renderTransitionErrorModal();
                }

                return null;
              }}
              visible
            />

            <ConnectBar color="ownerApproval" disabled={!currentCompanyAllowsTransitioning} visible />

            <WorkflowIcon
              type="ownerApproval"
              active={currentWorkflowStatus.isEqualTo(OwnerApproval)}
              completed={currentWorkflowStatus.isAfter(OwnerApproval)}
              version={versionIdentifier}
              disabled={!canOwn}
              disabledEffect={!currentCompanyAllowsTransitioning}
              label="Owner Approval"
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) return renderLicenseTypeModal();

                if (currentVersion.mayTransitionToOwnerApproval) {
                  return handleInitTransition('ownerApproval');
                }

                return null;
              }}
              visible
            />

            <ConnectBar
              color="rejected"
              disabled={!currentCompanyAllowsTransitioning}
              visible={currentWorkflowStatus.isEqualTo(Rejected)}
            />

            <WorkflowIcon
              type="rejected"
              active
              version={versionIdentifier}
              disabledEffect={!currentCompanyAllowsTransitioning}
              label="Rejected"
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }
                return handleInitTransition('resolveRejection');
              }}
              visible={currentWorkflowStatus.isEqualTo(Rejected)}
            />

            <ConnectBar
              color="approvedDraft"
              disabled={!currentVersion.mayTransitionToApprovedDraft || !canApprove}
              visible={
                !currentWorkflowStatus.isEqualTo(Rejected) &&
                (hasChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval))
              }
            />

            <WorkflowIcon
              type="approvedDraft"
              active={currentWorkflowStatus.isEqualTo(ApprovedDraft)}
              completed={currentWorkflowStatus.isAfter(ApprovedDraft)}
              disabled={!currentVersion.mayTransitionToApprovedDraft || !canApprove}
              version={versionIdentifier}
              label="Approved Draft"
              size="large"
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }

                return handleInitTransition('approveOrReject');
              }}
              visible={
                !currentWorkflowStatus.isEqualTo(Rejected) &&
                (hasChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval))
              }
            />

            <ConnectBar
              color="released"
              disabled={!currentCompanyAllowsTransitioning || !canApprove}
              visible={!currentWorkflowStatus.isEqualTo(Rejected)}
            />

            <WorkflowIcon
              type="released"
              version={versionIdentifier}
              label="Release"
              size="large"
              disabled={!canApprove}
              disabledEffect={!currentCompanyAllowsTransitioning}
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }

                if (hasChangeOrder || currentWorkflowStatus.isEqualTo(ApprovedDraft)) {
                  return setAlertModal({
                    open: true,
                    heading: 'Release not permitted',
                    body: 'This item can only be released upon close of its associated Change Order.',
                  });
                }

                if (currentVersion.mayTransitionToReleased) {
                  return handleInitTransition('releaseOrReject');
                }

                return null;
              }}
              visible={!currentWorkflowStatus.isEqualTo(Rejected)}
              completed={currentWorkflowStatus.isAfter(ApprovedDraft)}
            />

            <AlertDialog open={alertModal.open} onClose={closeModal} titleText={alertModal.heading}>
              <Typography variant="body2">{alertModal.body}</Typography>
            </AlertDialog>

            <AlertDialogWithUser
              open={deniedModalWithUser.open}
              titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
              onClose={() => setDeniedModalWithUser({ open: false, user: null, role: null })}
              subtitle={
                inactiveUsers.length
                  ? 'The following user has been deactivated.'
                  : "The following user's role has been removed."
              }
              user={deniedModalWithUser.user}
              role={
                deniedModalWithUser.role === 'owner'
                  ? currentVersion.assignedOwnerJobRole
                  : currentVersion.assignedApproverJobRole
              }
              body={`Please use the People Menu to reassign the ${
                deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
              } role to a new user.`}
            />

            <Modal open={previousVersionModal} onClose={closeModal} className={classes.infoModal}>
              <Paper className={classes.modalInside}>
                <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={closeModal} color="action" />
                <SummaryOfChange currentVersion={versionForModal} displayChangeInfo={true} />
              </Paper>
            </Modal>
          </>
        )}
      </div>
    </ContentSection>
  );
}

export default flowRight(
  WithCurrentUser,
  withCurrentCompany,
  withMetadataFromServer(),
)(SetGenericWorkflow);
