import { useMutation } from 'react-apollo';
import { BULK_ITEM_TAG_ADD, BULK_ITEM_TAG_REMOVE } from 'utils/gql/tagQueries';

export function useUpdateBulkTags(startingTags: string[], selectedItemIds: number[]) {
  const [addTags] = useMutation(BULK_ITEM_TAG_ADD, { refetchQueries: ['DeviceVerificationVersionQuery'] });
  const [removeTags] = useMutation(BULK_ITEM_TAG_REMOVE, { refetchQueries: ['DeviceVerificationVersionQuery'] });

  return (tags: string[]) => {
    if (startingTags.length > tags.length) {
      return removeTags({
        variables: {
          items: selectedItemIds,
          tags: startingTags.filter((e) => !tags.includes(e)),
        },
      }).then((res) => {
        return res.data.bulkRemoveTags.items;
      });
    } else if (startingTags.length < tags.length) {
      return addTags({
        variables: {
          items: selectedItemIds,
          tags: tags.filter((e) => !startingTags.includes(e)),
        },
      }).then((res) => {
        return res.data.bulkAddTags.items;
      });
    } else {
      return Promise.resolve();
    }
  };
}
