import gql from 'graphql-tag';

export const LIST_VERIFICATION_RECORDS = gql`
  query verificationRecordsQuery {
    verificationRecords {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      tags
      currentVersion {
        id
        title
        protocolVersionRecordNumber
        deviationVersionIds
        createdAt
        currentStatus {
          id
          name
        }
        owner {
          id
          firstName
        }
        approver {
          id
          firstName
        }
        versionIdentifier
        isFulfillAllAcceptanceCriteria
        deviceVerificationVersion {
          id
          item {
            id
            customIdentifier
          }
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
        deviationVersions {
          id
          item {
            itemType {
              id
              displaySlug
              userCreatable
            }
            id
            customIdentifier
          }
        }
      }
    }
  }
`;
