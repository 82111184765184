import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    infoIcon: {
      marginLeft: theme.spacing(1),
    },
    checkbox: {
      padding: theme.spacing(1),
      color: theme.palette.blueDark,
      backgroundColor: 'transparent',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
      },
    },
    checkboxRoot: {
      backgroundColor: 'transparent',
      '&&:hover, &&:active, &&:focus': {
        backgroundColor: 'transparent',
      },
    },
    titlePrefix: {
      marginRight: theme.spacing(0.5),
    },
    clickableTitlePrefix: {
      marginRight: theme.spacing(0.5),
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    customIdentifier: {
      paddingLeft: theme.spacing(5),
      paddingBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1) * -1,
    },
    globalId: {
      paddingLeft: theme.spacing(5),
      marginTop: theme.spacing(1) * -1.5,
      display: 'block',
    },
    helpTextEntriesWrapper: {
      marginLeft: theme.spacing(5),
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    helpTextEntry: {
      whiteSpace: 'pre-line',
      marginBottom: theme.spacing(1),
    },
    helpText: {
      whiteSpace: 'pre-line',
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(0.5)}px ${theme.spacing(5)}px`,
      float: 'left',
    },
    helpTextLabel: {
      textTransform: 'none',
    },
    helpTextPopper: {
      opacity: 1,
    },
    helpTextTooltip: {
      backgroundColor: theme.palette.background.contextDrawerGrey,
      maxWidth: '90%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    horizontalPanelHeader: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    verticalPanelHeader: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
    },
    slashedHelpTextBtn: {
      '&:before': {
        position: 'absolute',
        content: '""',
        left: 0,
        top: '45%',
        right: 0,
        borderTop: '2px solid',
        transform: 'skewY(-45deg)',
      },
    },
    helpTextBtn: {
      width: 16,
      height: 16,
      minHeight: 0,
      marginLeft: theme.spacing(1),
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.blueDark,
      },
    },
    title: {
      fontWeight: 600,
      float: 'left',
      display: 'block',
      alignItems: 'center',
      height: 40,
    },
  });
