import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1.5),
      },
    },
    icon: {
      marginRight: theme.spacing(2),
    },
  }),
);
