import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    addBtn: {
      ...theme.typography.button,
      color: theme.palette.primary.contrastText,
    },
    addBtnContainer: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    addItemContainer: {
      width: 420,
    },
    boldText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    clickable: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    header: {
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
    noResults: {
      paddingBottom: theme.spacing(1),
    },
    productName: {
      paddingBottom: theme.spacing(1),
    },
    results: {
      overflowY: 'scroll',
      height: 'auto',
      maxHeight: 240,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    searchContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    cssOutlinedInput: {
      ...theme.typography.body2,
      height: 36,
      marginBottom: theme.spacing(1),
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    cssFocused: {},
    notchedOutline: {
      border: `2px solid ${theme.palette.common.input.outline} !important`,
    },
    searchInput: {
      ...theme.typography.body2,
      paddingLeft: theme.spacing(1),
    },
    searchIcon: {
      fontSize: 20,
    },
    searchResult: {
      paddingLeft: 24,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    selectedRow: {
      backgroundColor: 'rgba(48, 188, 179, 0.1)',
    },
    splitter: {
      marginTop: 0,
      color: theme.palette.common.input.outline,
      borderStyle: 'solid',
    },
    versionOption: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(-1),
    },
    versionSelectorContainer: {
      padding: theme.spacing(3),
    },
    error: {
      color: theme.palette.common.error.main,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  });
