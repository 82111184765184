import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    margin: '0 auto',
    whiteSpace: 'pre-line',
    textAlign: 'center',
    opacity: 0.6,
  },
  icon: {
    fontSize: 20,
  },
}));
