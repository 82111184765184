import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      color: '#1f1f1f',
      fontWeight: 100,
      fontSize: '32px',
      lineHeight: '40px',
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    headerTitle: {
      width: '100%',
      lineHeight: '32px',
      fontWeight: 400,
      fontStyle: 'normal',
      color: '#6E7583',
    },
    icon: {
      color: '#0B915C',
      marginRight: '20px',
      verticalAlign: 'bottom',
    },
    supplierActionsLabel: {
      marginTop: 40,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    button: {
      height: 40,
      minWidth: 270,
    },
    statusCardContainer: {
      background: 'white',
      height: 138,
      overflow: 'hidden',
      position: 'relative',
    },
    infoIconContainer: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
    tableContainer: {
      maxWidth: 1000,
      width: '100%',
    },
    toolbar: {
      backgroundColor: theme.palette.background.default,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(2),
      minHeight: 'inherit',
      '& .MuiTextField-root': {
        width: 30,
        minWidth: 0,
        transition: ' all .5s',
        '& .MuiInputBase-adornedStart': {
          cursor: 'pointer',
        },
        '& .MuiInputAdornment-positionStart': {
          pointerEvents: 'none',
        },
        '& .MuiInputAdornment-positionEnd': {
          visibility: 'hidden',
          '& .Mui-disabled': {
            visibility: 'hidden',
          },
        },
        '&:focus-within': {
          width: 250,
          '& .MuiInputAdornment-positionEnd': {
            '&::placeholder-shown': {
              visibility: 'hidden',
            },
            visibility: 'visible',
          },
        },
      },
      '& .MuiInput-underline:hover:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
    },
  }),
);
