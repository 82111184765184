import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import { flatMap } from 'lodash';
import ChipSelect from './helpers/ChipSelect';
import ChipSelectOptionRender from './ChipSelectOptionRender';

// read-only
export default function RiskNewRisks({ riskVersion }) {
  const newRisks = flatMap(riskVersion.linkedRiskControlVersions, (x) => x.linkedRiskVersions);

  const formatRisks = (riskVersions) => {
    return riskVersions.map((m) => ({
      ...m,
      label: m.item.customIdentifier,
      value: m.id,
      id: m.item.id,
      currentValue: m.id,
      customIdentifier: m.item.customIdentifier,
      currentVersion: {
        title: m.title,
        versionIdentifier: m.versionIdentifier,
        currentStatus: {
          name: m.currentStatus.name,
        },
        item: {
          itemType: {
            displaySlug: m.item.itemType.displaySlug,
          },
        },
      },
    }));
  };
  return (
    <ChipSelect
      placeholder=""
      disabled={true}
      renderOption={(option) => <ChipSelectOptionRender option={option} />}
      content={formatRisks(newRisks)}
      options={[]}
      onOpen={() => {}}
      popupIcon={
        <Tooltip
          title="Cannot add risks from this view. View the associated risk to create relationships."
          placement="top"
        >
          <div>
            <Link />
          </div>
        </Tooltip>
      }
    />
  );
}
