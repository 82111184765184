import gql from 'graphql-tag';

export const ADD_QUALITY_DOCUMENT = gql`
  mutation AddQualityDocument($courseVersionId: ID!, $qualityDocumentVersionId: ID!) {
    addQualityDocumentVersionToCourseVersion(
      courseVersionId: $courseVersionId
      qualityDocumentVersionId: $qualityDocumentVersionId
    ) {
      courseVersion {
        id
      }
    }
  }
`;

export const REMOVE_QUALITY_DOCUMENT = gql`
  mutation RemoveQualityDocument($courseVersionId: ID!, $qualityDocumentVersionId: ID!) {
    removeQualityDocumentVersionFromCourseVersion(
      courseVersionId: $courseVersionId
      qualityDocumentVersionId: $qualityDocumentVersionId
    ) {
      courseVersion {
        id
      }
    }
  }
`;
