import React from 'react';

import { Tags } from 'compositions';
import { ContentSection, Spacer } from 'components';
import { tagsMutation } from 'utils/gql/tagQueries';
import { useMutation } from 'react-apollo';

interface Props {
  itemId;
  tags;
  refetchQueries;
}

function TagsMenu(props: Props) {
  const [updateItemTags] = useMutation(tagsMutation, { refetchQueries: props.refetchQueries });
  return (
    <ContentSection LabelText="Tags" elevation={1}>
      <Spacer factor={1} />
      <Tags
        tags={props.tags}
        onSelected={(tags) => {
          updateItemTags({
            variables: {
              itemId: props.itemId,
              tags: tags,
            },
          }).catch((err) => {
            throw err;
          });
        }}
      />
    </ContentSection>
  );
}

export default TagsMenu;
