import React, { FC } from 'react';
import { Mutation } from 'react-apollo';
import get from 'lodash/get';

import ItemIdAndTitle from './components/ItemIdAndTitle';
import VerbAndStatus from './components/VerbAndStatus';
import GenericNotificationListItem from '.';
import TaskAssociator from '../TaskAssociator';

import GestureIcon from '@material-ui/icons/Gesture';

import { esignatureRequiredMutation } from './gql';
import { userNotificationsQuery_user_esignatureRequiredNotifications } from '../../__generated__/userNotificationsQuery';
import {
  updateEsignatureRequiredCompletedMutationVariables,
  updateEsignatureRequiredCompletedMutation_updateEsignatureRequiredCompleted,
} from './__generated__/updateEsignatureRequiredCompletedMutation';
import { currentUser_currentUser } from 'compositions/__generated__/currentUser';
import linkToItem from 'utils/linkToItem';

interface Props {
  currentUser: currentUser_currentUser;
  notification: userNotificationsQuery_user_esignatureRequiredNotifications;
  onRedirect: () => void;
}

const EsignatureRequiredBody: FC<Props> = ({ currentUser, notification, onRedirect }) => {
  const payload = {
    variables: {
      input: {
        notificationId: notification.id,
        completed: !notification.completed,
      },
    },
  };

  const ItemTitle = () => (
    <>
      <ItemIdAndTitle
        link={linkToItem(
          get(notification, 'precedingStatus.version.item.itemType.displaySlug'),
          get(notification, 'precedingStatus.version.item.id'),
        )}
        onRedirect={onRedirect}
        identifier={get(notification, 'precedingStatus.version.item.customIdentifier')}
        title={get(notification, 'precedingStatus.version.title')}
      />
      <VerbAndStatus
        beforeVerb="is"
        status={get(notification, 'precedingStatus.name')}
        titleizedName={get(notification, 'precedingStatus.titleizedName')}
        afterVerb="Sign to transition it to the next status."
      />
    </>
  );

  return (
    <Mutation<
      updateEsignatureRequiredCompletedMutation_updateEsignatureRequiredCompleted,
      updateEsignatureRequiredCompletedMutationVariables
    >
      mutation={esignatureRequiredMutation}
      refetchQueries={['userNotificationsQuery', 'currentUser']}
    >
      {(mutate, { error }) => {
        if (error) throw new Error(`${error}`);

        return (
          <GenericNotificationListItem
            headlineIcon={GestureIcon}
            headlineText="Signature Required"
            itemTitle={<ItemTitle />}
            createdAt={notification.createdAt}
            completed={notification.completed}
            onComplete={() => mutate(payload)}
            callToAction={
              <TaskAssociator
                currentUser={currentUser}
                versionId={notification.precedingStatus.version.id}
                taskReferencedVersions={notification.precedingStatus.version.taskReferencedVersions || []}
              />
            }
          />
        );
      }}
    </Mutation>
  );
};

export default EsignatureRequiredBody;
