import React, { useState } from 'react';

import flowRight from 'lodash/flowRight';
import get from 'lodash/get';

import { Close, LinearScale } from '@material-ui/icons';

import { Modal, Paper, Typography } from '@material-ui/core';

import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';

import WithCurrentUser from '../../WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import withMetadataFromServer from '../../WithMetadataFromServer';
import PreviousReleases from './PreviousReleases';

import { AlertDialog, AlertDialogWithUser, ContentSection, WorkflowIcon } from 'components';
import WorkflowMenu, { CancelMenuItem, RetireMenuItem, VoidMenuItem } from 'components/WorkflowMenu';
import ConnectBar from './ConnectBar';
import { GenericWithMembersWorkflowStatuses, indexById } from 'workflows/statuses';
import rfcContent from 'workflows/GenericWorkflow/rfcContent';
import RetirementTimeline from 'workflows/GenericWorkflow/RetirementTimeline';
import { SummaryOfChange } from 'compositions/TimelineGroup/steps/views';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';
import { useStyles } from 'compositions/TimelineGroup/components/sharedStyles';

function GenericWithMembers(props) {
  const [alertModal, setAlertModal] = React.useState({
    body: '',
    heading: '',
    open: false,
  });
  const [previousVersionModal, setPreviousVersionModal] = React.useState(false);
  const [versionForModal, setVersionForModal] = React.useState({});
  const [deniedModalWithUser, setDeniedModalWithUser] = useState({ open: false, user: null, role: null });
  const classes = useStyles();

  const {
    ApprovedDraft,
    Canceled,
    Draft,
    NotCreated,
    OwnerApproval,
    Rejected,
    Retired,
    RetirementInitiated,
    RetirementApproved,
    RetirementRejected,
    UnderReview,
  } = GenericWithMembersWorkflowStatuses;

  const GenericWithMembersWorkflowStatusesById = indexById(GenericWithMembersWorkflowStatuses);

  const { currentCompanyAllowsTransitioning, handleInitTransition } = props;
  const currentVersion =
    props.currentVersion.releasedAt ||
    GenericWithMembersWorkflowStatusesById[props.currentVersion.currentStatusName].isEqualTo(Canceled)
      ? { currentStatusName: 'not_created' }
      : props.currentVersion;

  const currentWorkflowStatus = GenericWithMembersWorkflowStatusesById[currentVersion.currentStatusName];

  const { canApprove, canOwn } = props.permissions;

  const {
    approver,
    assignedApproverJobRole,
    assignedOwnerJobRole,
    owner,
    versionIdentifier,
    unresolvedSuggestions,
  } = currentVersion;

  const linkedChangeType = get(currentVersion, 'linkedChange.itemType.name');
  const linkedChangeOrder = linkedChangeType && linkedChangeType === 'change_order';
  const linkedJustification = linkedChangeType && linkedChangeType === 'justification';

  const previousVersions = currentWorkflowStatus.isEqualTo(Retired)
    ? props.releasedVersions.concat(currentVersion)
    : props.releasedVersions;

  const [deniedModal, setDeniedModal] = useState({
    open: false,
    heading: '',
    body: '',
  });

  const handleUnresolvedSuggestionsDeniedModal = () => {
    setDeniedModal({
      open: true,
      heading: 'Pending Suggestions in your document',
      body: `There are tracked changes that need to be resolved before this status transition can take place.`,
    });
  };

  const closeUnresolvedSuggestionModal = () => {
    setDeniedModal((prevState) => ({ ...prevState, open: false }));
  };

  const closeModal = () => {
    setAlertModal({
      open: false,
      body: '',
      heading: '',
    });
    setPreviousVersionModal(false);
  };

  const renderLicenseTypeModal = () => {
    return setAlertModal({
      open: true,
      heading: 'Item Status Locked',
      body: 'Item status updates require an upgraded license type. Contact your administrator to upgrade your license.',
    });
  };

  const inactiveUsers = getInactiveUsers(owner, approver);
  const usersWithoutActiveRole = getUsersWithoutActiveRole(
    owner,
    approver,
    assignedOwnerJobRole,
    assignedApproverJobRole,
  );

  const handleInactiveUserDeniedModal = (inactiveUsers) => {
    setDeniedModalWithUser({
      open: true,
      user: inactiveUsers[0].user,
      role: inactiveUsers[0].role,
    });
  };

  const handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    setDeniedModalWithUser({
      open: true,
      user: usersWithoutActiveRole[0].user,
      role: usersWithoutActiveRole[0].role,
    });
  };

  const workflowControls = () => {
    const isVoidable = (status) =>
      currentVersion.mayTransitionToVoid &&
      ((canApprove && status.isEqualTo(ApprovedDraft)) || (canOwn && status.isEqualTo(OwnerApproval)));

    const isCancelable = (status) => currentVersion.mayTransitionToCanceled && canOwn;

    const isRetirable = currentVersion.mayTransitionToRetirementInitiated && canOwn;

    if (!currentCompanyAllowsTransitioning) return;

    return (
      <>
        <WorkflowMenu>
          <VoidMenuItem
            disabled={!isVoidable(currentWorkflowStatus)}
            onClick={() => handleInitTransition('voidSignature')}
          />
          <CancelMenuItem
            disabled={!isCancelable(currentWorkflowStatus)}
            onClick={() => handleInitTransition('cancel')}
          />
          {isRetirable && <RetireMenuItem onClick={() => handleInitTransition('initiateRetirement')} />}
        </WorkflowMenu>
      </>
    );
  };

  const handleSmallBlueCircleClick = (currentVersion) => {
    if (currentWorkflowStatus.isEqualTo(NotCreated)) return handleInitTransition('createNewVersion');
    if (currentWorkflowStatus.isEqualTo(Draft) || (currentWorkflowStatus.isEqualTo(UnderReview) && canOwn))
      return handleInitTransition('reasonForChange');

    return handleInitTransition('summaryOfChange');
  };

  if (
    currentWorkflowStatus.isEqualTo(RetirementInitiated) ||
    currentWorkflowStatus.isEqualTo(RetirementApproved) ||
    currentWorkflowStatus.isEqualTo(RetirementRejected)
  ) {
    return (
      <RetirementTimeline
        permissions={{ canOwn, canApprove }}
        previousVersions={previousVersions}
        currentVersion={currentVersion}
        handleInitTransition={handleInitTransition}
      />
    );
  }

  return (
    <ContentSection
      LabelText="Workflow"
      Icon={<LinearScale />}
      Controls={workflowControls()}
      classes={{ label: classes.timelineHeader }}
    >
      <div className={classes.timelineContainer}>
        <PreviousReleases
          generateSmallCircleContent={rfcContent}
          releasedVersions={previousVersions}
          onClick={(v) => {
            setPreviousVersionModal(true);
            setVersionForModal(v);
          }}
        />

        {!currentWorkflowStatus.isEqualTo(Retired) && (
          <SmallCircle
            content={rfcContent(currentVersion)}
            color="draft"
            handleClick={() => handleSmallBlueCircleClick(currentVersion)}
          />
        )}

        {currentWorkflowStatus.isAfter(NotCreated) && !currentWorkflowStatus.isEqualTo(Retired) && (
          <>
            <ConnectBar color="draft" short visible />

            <WorkflowIcon
              type="draft"
              active={currentWorkflowStatus.isEqualTo(Draft)}
              completed={currentWorkflowStatus.isAfter(Draft)}
              version={versionIdentifier}
              label="Draft"
              visible
            />

            <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

            <SmallCircle
              content={linkedChangeOrder ? 'CO' : linkedJustification ? 'JST' : '?'}
              color="underReview"
              disabledEffect={!currentCompanyAllowsTransitioning}
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }
                if (!linkedChangeOrder && !linkedJustification && !currentVersion.locked) {
                  return handleInitTransition('ownerApprovalWithoutSig');
                }

                return handleInitTransition('associatedChange');
              }}
              visible
            />

            <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

            <WorkflowIcon
              type="underReview"
              active={currentWorkflowStatus.isEqualTo(UnderReview)}
              completed={currentWorkflowStatus.isAfter(UnderReview)}
              version={versionIdentifier}
              disabledEffect={!currentCompanyAllowsTransitioning}
              label="Under Review"
              size="large"
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }

                if (inactiveUsers.length) return handleInactiveUserDeniedModal(inactiveUsers);
                if (usersWithoutActiveRole.length)
                  return handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);

                if (currentVersion.mayTransitionToUnderReview) {
                  return handleInitTransition('underReview');
                }

                return null;
              }}
              visible
            />

            <ConnectBar color="ownerApproval" disabled={!currentCompanyAllowsTransitioning} visible />

            <WorkflowIcon
              type="ownerApproval"
              active={currentWorkflowStatus.isEqualTo(OwnerApproval)}
              completed={currentWorkflowStatus.isAfter(OwnerApproval)}
              version={versionIdentifier}
              disabled={!canOwn}
              disabledEffect={!currentCompanyAllowsTransitioning}
              label="Owner Approval"
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) return renderLicenseTypeModal();

                if (unresolvedSuggestions) {
                  return handleUnresolvedSuggestionsDeniedModal();
                }

                if (currentVersion.mayTransitionToOwnerApproval) {
                  return handleInitTransition('ownerApproval');
                }

                return null;
              }}
              visible
            />

            <ConnectBar
              color="rejected"
              disabled={!currentCompanyAllowsTransitioning}
              visible={currentWorkflowStatus.isEqualTo(Rejected)}
            />

            <WorkflowIcon
              type="rejected"
              active
              version={versionIdentifier}
              disabledEffect={!currentCompanyAllowsTransitioning}
              label="Rejected"
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }

                return handleInitTransition('resolveRejection');
              }}
              visible={currentWorkflowStatus.isEqualTo(Rejected)}
            />

            <ConnectBar
              color="approvedDraft"
              disabled={!currentVersion.mayTransitionToApprovedDraft || !canApprove}
              visible={
                !currentWorkflowStatus.isEqualTo(Rejected) &&
                (linkedChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval))
              }
            />

            <WorkflowIcon
              type="approvedDraft"
              active={currentWorkflowStatus.isEqualTo(ApprovedDraft)}
              completed={currentWorkflowStatus.isAfter(ApprovedDraft)}
              disabled={!currentVersion.mayTransitionToApprovedDraft || !canApprove}
              version={versionIdentifier}
              label="Approved Draft"
              size="large"
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }

                return handleInitTransition('approveOrReject');
              }}
              visible={
                !currentWorkflowStatus.isEqualTo(Rejected) &&
                (linkedChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval))
              }
            />

            <ConnectBar
              color="released"
              disabled={!currentCompanyAllowsTransitioning || !canApprove}
              visible={!currentWorkflowStatus.isEqualTo(Rejected)}
            />

            <WorkflowIcon
              type="released"
              version={versionIdentifier}
              label="Release"
              size="large"
              disabled={!canApprove}
              disabledEffect={!currentCompanyAllowsTransitioning}
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) {
                  return renderLicenseTypeModal();
                }

                if (linkedChangeOrder || currentWorkflowStatus.isEqualTo(ApprovedDraft)) {
                  return setAlertModal({
                    open: true,
                    heading: 'Release not permitted',
                    body: 'This item can only be released upon close of its associated Change Order.',
                  });
                }

                if (currentVersion.mayTransitionToReleased) {
                  return handleInitTransition('releaseOrReject');
                }

                return null;
              }}
              visible={!currentWorkflowStatus.isEqualTo(Rejected)}
              completed={currentWorkflowStatus.isAfter(ApprovedDraft)}
            />

            <AlertDialog open={alertModal.open} onClose={closeModal} titleText={alertModal.heading}>
              <Typography variant="body2">{alertModal.body}</Typography>
            </AlertDialog>

            <div>
              <AlertDialog
                open={deniedModal.open}
                onClose={closeUnresolvedSuggestionModal}
                titleText={deniedModal.heading}
              >
                <Typography variant="body2">{deniedModal.body}</Typography>
              </AlertDialog>
            </div>

            <AlertDialogWithUser
              open={deniedModalWithUser.open}
              titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
              onClose={() => setDeniedModalWithUser({ open: false, user: null, role: null })}
              subtitle={
                inactiveUsers.length
                  ? 'The following user has been deactivated.'
                  : "The following user's role has been removed."
              }
              user={deniedModalWithUser.user}
              role={deniedModalWithUser.role === 'owner' ? assignedOwnerJobRole : assignedApproverJobRole}
              body={`Please use the People Menu to reassign the ${
                deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
              } role to a new user.`}
            />
          </>
        )}

        <Modal open={previousVersionModal} onClose={closeModal} className={classes.infoModal}>
          <Paper className={classes.modalInside}>
            <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={closeModal} color="action" />
            <SummaryOfChange currentVersion={versionForModal} displayChangeInfo={true} />
          </Paper>
        </Modal>
      </div>
    </ContentSection>
  );
}

export default flowRight(
  WithCurrentUser,
  withCurrentCompany,
  withMetadataFromServer(),
)(GenericWithMembers);
