import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { LicenseTier } from '__generated__/globalTypes';

const CURRENT_COMPANY_TIER = gql`
  query MeetsTierRequirement($tier: LicenseTier!) {
    hasNecessaryLicense(licenseTier: $tier)
  }
`;

export function useHasNecessaryLicense(requiredLicenseTier: LicenseTier) {
  return useQuery(CURRENT_COMPANY_TIER, { variables: { tier: requiredLicenseTier } });
}
