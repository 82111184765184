import React, { useEffect, FC } from 'react';
import { SupplierWorkflowStatuses } from 'workflows/statuses';

const TO_STATUS = SupplierWorkflowStatuses.QualificationRejected.id;

export default {
  initialStep: 'doIt',
  internalSteps: {
    doIt: {
      component: ((props) => {
        const noModal = true;
        useEffect(() => {
          if (noModal) {
            props.handleComplete(true, { forgetThisStep: true });
          }
        }, []); // eslint-disable-line react-hooks/exhaustive-deps
        return <div />;
      }) as FC<any>,
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ doIt }) => {
    if (doIt) {
      return {
        toStatus: TO_STATUS,
      }
    }
  },
};
