import React, { useState } from 'react';

import { Button, Tooltip, createStyles, withStyles } from '@material-ui/core';

import { useMutation } from 'react-apollo';
import { PureQueryOptions } from 'apollo-client';
import { BaseMutationOptions, RefetchQueriesFunction } from '@apollo/react-common/lib/types/types';

import { ItemCreatedSnackbar } from 'components';
import { getBaseApiUrl } from 'utils';

import { CreateFormRecordModal } from './CreateFormRecordModal';
import { CREATE_FORM_RECORD } from './gql';

const styles = createStyles((theme) => ({
  closeIcon: {
    float: 'right',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modalContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 322,
    padding: theme.spacing(2),
  },
}));

interface CreateFormRecordProps {
  classes: Record<string, string>;
  formIdentifier: string;
  formVersionId: string;
  refetchQueries: Array<string | PureQueryOptions> | RefetchQueriesFunction;
  currentUser: any;
  textForExecutionButton?: any;
  recordItemType?: string;
  createNewRecordMutation?: (options?: BaseMutationOptions<any, CreateFormRecordMutationVariables>) => Promise<any>;
}

export interface CreateFormRecordMutationVariables {
  formVersionId: string;
  productId: number;
  title: string;
  host: URL;
}

function CreateFormRecord(props: CreateFormRecordProps) {
  const {
    classes,
    formIdentifier,
    formVersionId,
    currentUser,
    refetchQueries,
    recordItemType,
    createNewRecordMutation,
  } = props;
  const [showCreateFormRecordModal, setShowCreateFormRecordModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [customIdentifier, setCustomIdentifier] = useState('');
  const [showItemCreatedSnackbar, setShowItemCreatedSnackbar] = useState(false);
  const [createFormRecord] = useMutation(createNewRecordMutation || CREATE_FORM_RECORD);

  const onCreateFormRecord = (data) => {
    if (data) {
      // because "key" field of the data is different depending on mutation
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          if (data[key].item) {
            let newItemId = data[key].item.id;
            let newCustomIdentifier = data[key].item.customIdentifier;
            if (newItemId && newCustomIdentifier) {
              setItemId(newItemId);
              setCustomIdentifier(newCustomIdentifier);
            } else {
              throw new Error(`Can't get item.id or item.customIdentifier from data: ${data}`);
            }
            break;
          }
        }
      }
    }
  };

  const base = new URL('exports.pdf', getBaseApiUrl() || window.location.origin);
  base.searchParams.append('id', formVersionId);
  base.searchParams.append('source', window.location.toString());
  base.searchParams.append('disposition', 'attachment');

  const handleComplete = async () => {
    try {
      const mutationOptions = {
        refetchQueries,
        awaitRefetchQueries: true,
        update: (_cache, updateData) => {
          const { data } = updateData;
          onCreateFormRecord(data);
          setShowItemCreatedSnackbar(true);
        },
      };

      if (createNewRecordMutation) {
        const variables = {
          versionId: formVersionId,
          productId: 1,
          title: 'Default Title',
          host: base,
        };
        await createFormRecord({ ...mutationOptions, variables });
      } else {
        const variables = {
          input: {
            formVersionId,
            productId: 1,
            title: 'Default Title',
            host: base,
          },
        };
        await createFormRecord({ ...mutationOptions, variables });
      }

      setShowCreateFormRecordModal(false);
    } catch (e) {
      throw new Error(`Error ocurred while creating form record item: ${e}`);
    }
  };

  const handleModalClose = () => {
    setShowCreateFormRecordModal(false);
  };

  const handleSnackbarClose = () => {
    setShowItemCreatedSnackbar(false);
    setItemId(null);
    setCustomIdentifier('');
  };

  return (
    <>
      <Tooltip title={currentUser.readOnly ? 'Upgraded license required to use this feature' : ''}>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowCreateFormRecordModal(!showCreateFormRecordModal)}
            disabled={currentUser.readOnly}
          >
            {props.textForExecutionButton || 'Use this form'}
          </Button>
        </div>
      </Tooltip>
      <CreateFormRecordModal
        classes={classes}
        open={showCreateFormRecordModal}
        onClose={handleModalClose}
        handleComplete={handleComplete}
        formIdentifier={formIdentifier}
      />
      <ItemCreatedSnackbar
        open={showItemCreatedSnackbar}
        handleSnackbarClose={handleSnackbarClose}
        customIdentifier={customIdentifier}
        itemId={itemId}
        selectedItemType={recordItemType}
      />
    </>
  );
}

export default withStyles(styles)(CreateFormRecord);
