import React from 'react';
import { Query, useQuery } from 'react-apollo';
import snakeCase from 'lodash/snakeCase';
import get from 'lodash/get';

import { singleItemTypeQuery, allItemTypesQuery } from '../utils/gql/metadataQueries';
import { ItemTypeMetadata, ItemTypeMetadataVariables } from '../utils/gql/__generated__/ItemTypeMetadata';

const withMetadataFromServer = (aheadOfTimeItemType?) => (Component) => (componentProps) => {
  const itemTypeName = aheadOfTimeItemType || get(componentProps, 'itemType');
  return (
    <Query query={singleItemTypeQuery} errorPolicy="all" variables={{ itemType: snakeCase(itemTypeName) }}>
      {(result) => {
        const { data, loading, error } = result;
        if (error) console.log(error);
        if (loading) return null;

        return <Component {...componentProps} remoteItemTypeMetadata={get(data, 'itemType')} />;
      }}
    </Query>
  );
};

const withAllItemTypeMetadata = (Component) => (componentProps) => (
  <Query query={allItemTypesQuery} errorPolicy="all">
    {(result) => {
      const { data, loading, error } = result;
      if (error) console.log(error);

      return <Component {...componentProps} itemTypes={get(data, 'itemTypes')} loading={loading} />;
    }}
  </Query>
);

function useMetadata(itemType: string) {
  return useQuery<ItemTypeMetadata, ItemTypeMetadataVariables>(singleItemTypeQuery, {
    variables: {
      itemType: snakeCase(itemType),
    },
  });
}

export { withAllItemTypeMetadata, useMetadata };

export default withMetadataFromServer;
