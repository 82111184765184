import gql from 'graphql-tag';

export const CREATE_HARM = gql`
  mutation CreateHarm($name: String!) {
    createHarm(name: $name) {
      harm {
        id
        name
      }
    }
  }
`;

export const LIST_HARMS = gql`
  query ListHarms {
    harms {
      id
      name
    }
  }
`;
