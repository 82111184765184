import React, { SyntheticEvent } from 'react';
import { InputRange } from 'components';
import { createStyles, withStyles } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import VersionAndStatusDot from './VersionAndStatusDot';

const styles = (theme) =>
  createStyles({
    switch: {
      backgroundColor: theme.palette.secondary.main,
      margin: '0 4px 0 !important',
    },
    addButton: {
      backgroundColor: theme.palette.common.draft.main,
      borderRadius: '50%',
      color: '#fff',
    },
    disabledDot: {
      opacity: 0.7,
      cursor: 'not-allowed',
    },
  });

interface VersionSwitchProps {
  classes;
  disabled?: boolean;
  currentRelease?: {
    id: string;
    versionIdentifier: string;
    currentStatus: { name: string };
  };
  workingVersion?: {
    id: string;
    versionIdentifier: string;
    currentStatus: { name: string };
  };
  value: string; // either currentRelease.id or workingVersion.id
  onToggle: (newVal: string, e: SyntheticEvent) => void;
  withAddButton?: boolean;
  onAddClick?: (e) => void;
}

export const workingVersionSelected = (currentRelease, workingVersion, value) => {
  if (currentRelease && value === currentRelease.id) {
    return false;
  } else if (workingVersion && value === workingVersion.id) {
    return true;
  } else {
    // this can occur when version become obsolete, or when new versions are released
    // without referencing them in the tableTrio
    // This will be changed to function as a selector for ALL versions
    // console.warn(
    //   `Value: ${props.value} does not match lhs or rhs option:
    //     ${qget(props, 'currentRelease.id')} / ${get(props, 'workingVersion.id')}`,
    // );
    return null;
  }
};

function VersionSwitch(props: VersionSwitchProps) {
  const { currentRelease, workingVersion, disabled = false, withAddButton = true } = props;

  const dotStyle = {
    opacity: disabled ? 0.7 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  // FIXME: 8px padding is needed because we're coupled to RequirementLongCard + UserNeedLongCard's layout: remove and add there
  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '0 8px' }}>
      {currentRelease && (
        <VersionAndStatusDot
          version={currentRelease.versionIdentifier}
          status={currentRelease.currentStatus.name}
          style={{ paddingRight: 4, ...dotStyle }}
          onClick={
            workingVersion &&
            !disabled &&
            function(e) {
              props.onToggle(currentRelease.id, e);
            }
          }
        />
      )}
      {currentRelease && (withAddButton || workingVersion) && (
        <InputRange
          disabled={disabled}
          className={props.classes.switch}
          min="0"
          max="1"
          onChange={(e) => {
            if (currentRelease && workingVersion) {
              const switchVal = e.target.value === '1' ? workingVersion.id : currentRelease.id;
              props.onToggle(switchVal, e);
            } else {
              props.onAddClick && props.onAddClick(e);
            }
          }}
          value={workingVersionSelected(props.currentRelease, props.workingVersion, props.value) ? 1 : 0}
        />
      )}
      {workingVersion && (
        <VersionAndStatusDot
          version={workingVersion.versionIdentifier}
          status={workingVersion.currentStatus.name}
          style={{ paddingLeft: 4, ...dotStyle }}
          onClick={
            currentRelease &&
            !disabled &&
            function(e) {
              props.onToggle(workingVersion.id, e);
            }
          }
        />
      )}{' '}
      {!workingVersion && !disabled && withAddButton && (
        <div style={{ fontSize: 10, display: 'flex', marginLeft: 4 }}>
          <Add
            fontSize="inherit"
            classes={{ root: props.classes.addButton }}
            style={{ cursor: 'pointer' }}
            onClick={props.onAddClick}
          />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(VersionSwitch);
