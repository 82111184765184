import React from 'react';
import TagsInput from 'react-tagsinput';
import Clear from '@material-ui/icons/Clear';
import TagsAutosuggest from './TagAutosuggest';

import 'react-tagsinput/react-tagsinput.css';
import { useStyles } from './styles';

interface Props {
  tags?: string[];
  onSelected: (tags: string[]) => void;
  disabled?: boolean;
}

function Tags(props: Props) {
  const classes = useStyles();

  const renderTag = (props) => {
    const { tag, key, disabled, onRemove, removeIconClassName, getTagDisplayValue, ...other } = props;

    let backgroundColor = '';
    if (tag === 'Risk Control Effectiveness Test') {
      backgroundColor = '#77e873';
    } else if (tag === 'Risk Control Implementation Test') {
      backgroundColor = '#73bbe8';
    }
    const isDeletable = !(tag === 'Risk Control Effectiveness Test' || tag === 'Risk Control Implementation Test');

    return (
      <span key={key} {...other} style={{ backgroundColor }}>
        {getTagDisplayValue(tag)}
        {!disabled && isDeletable && <Clear className={removeIconClassName} onClick={() => onRemove(key)} />}
      </span>
    );
  };

  return (
    <div style={{ height: '92%', width: '240px' }}>
      <TagsInput
        disable
        value={props.tags || []}
        onChange={(newTags) => props.onSelected(newTags)}
        renderInput={(props) => <TagsAutosuggest tags={props.tags || []} {...props} />}
        renderTag={renderTag}
        tagProps={{
          className: classes.tag,
          removeIconClassName: classes.removeIcon,
        }}
        inputProps={{
          placeholder: 'Add Tag',
        }}
        className={''} // clear default styling
        focusedClassName={null}
        renderLayout={(tagComponents, inputComponent) => (
          <>
            {inputComponent}
            {tagComponents}
          </>
        )}
      />
    </div>
  );
}

export default Tags;
