import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    companyUrlContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    companyUrlLabel: {
      marginBottom: theme.spacing(1),
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(0.5),
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
    },
    logoInputContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    input: {
      ...theme.typography.body2,
      padding: theme.spacing(1.5, 2),
    },
    inputIcon: {
      color: theme.palette.secondary.main,
    },
    helperText: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
      color: theme.palette.subHeading,
    },
    helperTextSuccess: {
      marginTop: theme.spacing(1),
      color: '#0B915C',
    },
    logoContainer: {
      marginRight: theme.spacing(2),
    },
    logoImageArea: {
      display: 'flex',
      alignItems: 'center',
    },
    deleteButton: {
      marginLeft: theme.spacing(1),
      '& .MuiButtonBase-root': {
        color: theme.palette.subHeading,
      },
    },
  }),
);
