export enum WorkflowType {
  ChangeOrder = 'change_order',
  Course = 'course',
  Curriculum = 'curriculum',
  Deviation = 'deviation',
  Generic = 'generic',
  GenericWithMembers = 'generic_with_members',
  MiscellaneousRecord = 'miscellaneous_record',
  Record = 'record',
  RiskNote = 'risk_note',
  SetGeneric = 'set_generic',
  SetItemMember = 'set_item_member',
  TraceMatrix = 'trace_matrix',
  TrainingPlan = 'training_plan',
  TrainingRecord = 'attachment_dependent',
  Task = 'task',
  Supplier = 'supplier',
  GenericRecord = 'generic_record',
}
