import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Views } from 'workspaces';
import BuilderView from 'components/BuilderView';

import PayloadFields from './PayloadFields';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import EffectiveView from 'components/EffectiveView';
import VersionsView from 'components/VersionsView';

interface Props extends RouteComponentProps {
  itemId?: string;
  activeWorkspaceView: { id: string };
}

function Page(props: Props) {
  if (!props.itemId) throw new Error('Error loading course page');

  switch (props.activeWorkspaceView.id) {
    case Views.Effective.id:
      return (
        <EffectiveView categorySlug="course" itemType="course" itemId={props.itemId} PayloadFields={PayloadFields} />
      );
    case Views.Versions.id:
      return (
        <VersionsView categorySlug="course" itemType="course" itemId={props.itemId} PayloadFields={PayloadFields} />
      );
    default:
      return (
        <BuilderView categorySlug="course" itemType="course" itemId={props.itemId} PayloadFields={PayloadFields} />
      );
  }
}

export default withWorkspaceViews()(Page);
