import React, { useState, Dispatch } from 'react';
import { Grid, Table, TableBody, Dialog, Paper } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';
import { Trans } from '@lingui/macro';
import LinkOff from '@material-ui/icons/LinkOff';
import AddCircle from '@material-ui/icons/AddCircle';

import { ContentSection, Spacer, YesOrNo } from 'components';
import ItemSearch from 'compositions/ItemSearch';

import useStyles from '../styles';
import useCourses, { Action, ModalState } from './useCourses';
import Heading from './Heading';
import CourseTableRow, { CourseData } from './CourseTableRow';

const ErrorTextAdd = 'An error occurred while adding this Course to the Curriculum.';
const ErrorTextUnlink = 'An error occurred while unlinking this Course from the Curriculum.';

function Courses(props: {
  locked: boolean;
  courses: CourseData[];
  onUnlinkCourses: () => void;
  onAddCourses: (courseIds: string[]) => Promise<boolean>;
  dispatch: Dispatch<Action>;
  modal: ModalState;
  selectedCourses: Set<string>;
  error: boolean;
}) {
  const classes: any = useStyles();
  const [addButtonAnchor, setAddButtonAnchor] = useState();

  return (
    <>
      <Grid item xs={12} className={classes.grid}>
        <ContentSection
          BigHeading={<Trans>Associated Courses</Trans>}
          BigHeadingIcon={<Sort />}
          LabelText={
            !props.locked && (
              <>
                <AddCircle
                  className={classes.listButtonIcon}
                  onClick={(e) => {
                    if (props.locked) return;
                    setAddButtonAnchor(e.currentTarget);
                    props.dispatch({ type: 'SetModal', val: ModalState.Add });
                  }}
                />
                <LinkOff
                  className={classes.listButtonIcon}
                  onClick={() => {
                    if (props.locked) return;
                    if (props.selectedCourses.size > 0)
                      props.dispatch({
                        type: 'SetModal',
                        val: ModalState.Unlink,
                      });
                  }}
                />
              </>
            )
          }
        >
          <Spacer factor={1} />
          <Table>
            <Heading locked={props.locked} classes={classes} />
            <TableBody className={classes.tableBody}>
              {props.courses.map((course) => (
                <CourseTableRow
                  locked={props.locked}
                  selected={props.selectedCourses.has(course.id)}
                  data={course}
                  classes={classes}
                  dispatch={props.dispatch}
                />
              ))}
            </TableBody>
          </Table>
        </ContentSection>
      </Grid>

      <Dialog
        open={props.modal === ModalState.Unlink}
        onClose={() => props.dispatch({ type: 'SetModal', val: ModalState.Closed })}
      >
        <Paper className={classes.modalContainer}>
          <YesOrNo
            disableOnSubmit
            heading="Unlink Confirmation"
            prompt="Are you sure you want to unlink the selected Courses from this Curriculum?"
            yesLabel="Yes, Unlink"
            noLabel="Go Back"
            closeOnNo
            handleComplete={props.onUnlinkCourses}
            handleClose={() => props.dispatch({ type: 'SetModal', val: ModalState.Closed })}
            error={props.error ? ErrorTextUnlink : undefined}
          />
        </Paper>
      </Dialog>

      <ItemSearch
        draftOnly={false}
        supportsChangeOrder={false}
        itemTypes={['course']}
        open={props.modal === ModalState.Add}
        closeSelf={() => props.dispatch({ type: 'SetModal', val: ModalState.Closed })}
        onSubmit={props.onAddCourses}
        anchorEl={addButtonAnchor}
        errorText={props.error ? ErrorTextAdd : undefined}
      />
    </>
  );
}

export { useCourses };
export default Courses;
