import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    textFieldStep: {
      margin: '0',

      '& > div': {
        paddingRight: 0,
      },
    },

    protocol: {
      '& h6.MuiTypography-root': {
        marginBottom: theme.spacing(1),
        marginLeft: 36,
      },
    },
  }),
);
