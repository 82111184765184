import React, { FC } from 'react';

import { Button } from '@material-ui/core';

import { Loading, Spacer } from 'components';

import { useStyles } from './styles';
import { currentUser_currentUser } from 'compositions/__generated__/currentUser';
import ESignature from './ESignature';

interface Props {
  currentUser: currentUser_currentUser;
  usernameInputId: string;
  passwordInputId: string;
  submitLoading?: boolean;
  submitDisabled: boolean;
  onUsernameChange: (e) => void;
  onPasswordChange: (e) => void;
  onSubmitClick: () => void;
}

const ESignatureWithButton: FC<Props> = ({
  usernameInputId,
  currentUser,
  passwordInputId,
  submitLoading,
  submitDisabled,
  onUsernameChange,
  onPasswordChange,
  onSubmitClick,
}) => {
  const classes = useStyles();

  return (
    <>
      <ESignature
        usernameInputId={usernameInputId}
        currentUser={currentUser}
        passwordInputId={passwordInputId}
        onUsernameChange={onUsernameChange}
        onPasswordChange={onPasswordChange}
      />
      <Spacer factor={2} />
      <Button
        color="primary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={submitDisabled}
        className={classes.submitBtn}
        onClick={onSubmitClick}
      >
        {submitLoading ? <Loading size={25} /> : 'Submit'}
      </Button>
    </>
  );
};
export default ESignatureWithButton;
