import React, { FC } from 'react';
import { withStyles } from '@material-ui/core';
import { FormatListNumbered } from '@material-ui/icons';
import OptionsDropdown from 'components/OptionsDropdown';
import Expand from 'assets/icons/Expand';
import Collapse from 'assets/icons/Collapse';

const styles = () => ({
  select: {},
});

interface Props {
  showComments: boolean;
  commentsDisplayVariant: string;
  onToggleComments: any;
  classes: any;
  setCommentsDisplayVariant: any;
  commentsCount?: number;
}

const OptionsToolbar: FC<Props> = (props) => {
  const { commentsDisplayVariant, commentsCount, classes, setCommentsDisplayVariant } = props;

  const handleChangeCommentsDisplayVariant = (event) => {
    setCommentsDisplayVariant(event.target.value);
  };

  return (
    <div className={classes.select}>
      <OptionsDropdown
        value={commentsDisplayVariant}
        onChange={handleChangeCommentsDisplayVariant}
        optionsTitle="Variants"
        options={[
          {
            value: 'inline',
            Icon: FormatListNumbered,
            text: 'Inline',
          },
          {
            value: 'narrowSidebar',
            Icon: Expand,
            text: 'Narrow Panel',
          },
          {
            value: 'wideSidebar',
            Icon: Collapse,
            text: 'Wide Panel',
          },
        ]}
        classes={classes}
        badgeContent={commentsCount}
      />
    </div>
  );
};

export default withStyles(styles as any)(OptionsToolbar);
