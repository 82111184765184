import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './library_add_check_outline.svg';

function LibraryAddCheckOutline(props) {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <Icon {...svgProps} />} />;
}

export default LibraryAddCheckOutline;
