import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { useMutation } from 'react-apollo';

import Buttons from 'components/core/Buttons';
import BaseDialog from 'components/BaseDialog';
import Button from 'components/Button';
import UserMultiSelect from 'components/UserMultiSelect';

import { COPY_USERS_TRAINING_PLAN } from './gql';
import { useStyles } from './styles';

interface Props {
  onClose: () => void;
  open: boolean;
  trainingPlanId: string;
}

function CopyTrainingPlanMenu(props: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [mutation] = useMutation(COPY_USERS_TRAINING_PLAN, { refetchQueries: ['TrainingPlanVersionQuery'] });
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  return (
    <BaseDialog
      open={props.open}
      title="Copy Training Plan"
      onClose={props.onClose}
      textAlign="left"
      actions={
        <Buttons className={classes.actions}>
          <Button onClick={props.onClose} color="primary" variant="outlined" className={classes.button}>
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true);
              mutation({
                variables: {
                  trainingPlanId: props.trainingPlanId,
                  userIds: selectedUsers,
                },
              })
                .then(() => {
                  props.onClose();
                })
                .catch((e) => {
                  throw new Error(`Error copying trainingplan: ${e.message}`);
                });
            }}
            color="primary"
            variant="contained"
            className={classes.button}
          >
            Confirm
          </Button>
        </Buttons>
      }
    >
      <Typography variant="h5" className={classes.description}>
        This will copy all courses from selected employee's most recently released training plan. If no released version
        exists, it will copy the draft data.
      </Typography>
      <Typography variant="overline" className={classes.selectLabel}>
        Select existing employee to copy from
      </Typography>
      <UserMultiSelect onChange={(selections) => setSelectedUsers(selections.map((user) => user.id))} />
    </BaseDialog>
  );
}

export default CopyTrainingPlanMenu;
