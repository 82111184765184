import React, { useState } from 'react';

import { Delete } from '@material-ui/icons';
import { IconButton, createStyles, makeStyles } from '@material-ui/core';
import Modal from 'components/WorkflowModal';
import ModalContainer from 'components/WorkflowModalContainer';
import YesOrNo from 'components/YesOrNo';

interface Props {
  removePrompts: {
    head: string;
    body: string;
  };
  onRemove: () => Promise<any>;
  disabled: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {
      padding: theme.spacing(1),
      color: theme.palette.secondary.main,
      '&:hover': {
        color: (theme.palette as any).blueDark,
        backgroundColor: 'transparent',
      },
    },
    smallIcon: {
      width: 20,
      height: 20,
    },
  }),
);

export default function RemoveControl(props: Props) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const { removePrompts, onRemove, disabled } = props;
  const onClose = () => setShowModal(false);

  return (
    <>
      <Modal
        open={showModal}
        onClose={onClose}
        style={{
          top: '50%',
          transform: 'translate(0, -25%)',
        }}
      >
        <ModalContainer showBackButton={false} onBack={onClose} onClose={onClose}>
          <YesOrNo
            closeOnNo
            disableOnSubmit
            handleComplete={() => onRemove().then(() => setShowModal(false))}
            handleClose={onClose}
            heading={removePrompts.head}
            prompt={removePrompts.body}
            noLabel="dismiss"
            yesLabel="remove"
          />
        </ModalContainer>
      </Modal>
      <IconButton
        data-testid="tags-control"
        aria-label="Open Tags"
        disableRipple
        onClick={() => setShowModal(true)}
        className={classes.iconButton}
        disabled={disabled}
      >
        <Delete classes={{ root: classes.smallIcon }} />
      </IconButton>
    </>
  );
}
