import gql from 'graphql-tag';

export const riskTraceMatricexQuery = gql`
  query riskTraceMatricesQuery {
    traceMatrices(traceType: "risk_trace_matrix") {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        id
        createdAt
        title
        versionIdentifier
        currentStatusName
        traceType
        requirementsSetVersion {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
              name
            }
          }
        }
        userNeedsSetVersion {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            itemType {
              id
              displaySlug
              userCreatable
              name
            }
            id
            customIdentifier
          }
        }
        riskAnalysisVersion {
          id
          versionIdentifier
          currentStatus {
            id
            name
          }
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
              name
            }
          }
        }
      }
    }
  }
`;
