import React, { ReactNode } from 'react';

import Warning from '@material-ui/icons/Warning';

import BaseDialog, { DialogSize, TextAlign } from '../BaseDialog';
import { Button } from '@material-ui/core';

interface Props {
  children: ReactNode;
  error?: string;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  textAlign?: TextAlign;
  title: string;
  size?: DialogSize;
  hideIcon?: boolean;
}

function ConfirmationDialog(props: Props) {
  const dialogProps = {
    actions: (
      <>
        <Button onClick={props.onCancel} color="primary" variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button onClick={props.onConfirm} color="primary" variant="contained" fullWidth>
          Confirm
        </Button>
      </>
    ),
    ...props,
    Icon: Warning,
  };

  if (props.hideIcon) {
    delete dialogProps.Icon;
  }

  return <BaseDialog {...dialogProps} />;
}

export default ConfirmationDialog;
