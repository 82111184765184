import gql from 'graphql-tag';

export const UPDATE_EMPLOYEE_MUTATION = gql`
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      errors
      employee {
        id
        customEmployeeId
        department
        startDate
        type
        manager {
          id
        }
      }
    }
  }
`;
