import React from 'react';
import flowRight from 'lodash/flowRight';

import withMetadataFromServer from '../../../WithMetadataFromServer';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';

import DefaultTimeline from './DefaultTimeline';
import RetirementTimeline from './RetirementTimeline';
import Releases from './Releases';

import previousVersions from 'workflows/util/previousVersions';

import { RetirementStatus } from './RetirementStatus';

function CourseWorkflow(props) {
  const { currentVersion } = props.itemData;

  const retirementInProgress = () => {
    return (
      currentVersion.currentStatusName === RetirementStatus.RetirementInitiated ||
      currentVersion.currentStatusName === RetirementStatus.ApprovedRetirement ||
      currentVersion.currentStatusName === RetirementStatus.RetirementRejected
    );
  };

  if (retirementInProgress()) {
    return (
      <RetirementTimeline
        currentVersion={currentVersion}
        permissions={props.permissions}
        handleInitTransition={props.handleInitTransition}
        currentCompanyAllowsTransitioning={props.currentCompanyAllowsTransitioning}
      />
    );
  } else if (currentVersion.currentStatusName === RetirementStatus.Retired) {
    return <Releases releasedVersions={previousVersions(props.itemData.versions)} />;
  } else {
    return (
      <DefaultTimeline
        permissions={props.permissions}
        currentCompanyAllowsTransitioning={props.currentCompanyAllowsTransitioning}
        releasedVersions={props.releasedVersions}
        handleInitTransition={props.handleInitTransition}
        itemData={props.itemData}
      />
    );
  }
}

export default flowRight(
  withMetadataFromServer(),
  withCurrentCompany,
)(CourseWorkflow);
