import React, { ReactNode } from 'react';
import BaseDialog, { DialogSize, TextAlign } from '../BaseDialog';
import Error from '@material-ui/icons/Error';

interface Props {
  error?: string;
  onClose: () => void;
  open: boolean;
  size?: DialogSize;
  textAlign?: TextAlign;
  titleText?: string;
  subtitle?: string;
  children: ReactNode;
  hideIcon?: boolean;
  icon?: any;
  secondaryIcon?: any;
}

function AlertDialog(props: Props) {
  const { titleText, children, hideIcon, icon, secondaryIcon, ...rest } = props;

  const IconComponent = icon || Error;
  const SecondaryIconComponant = secondaryIcon || Error;
  const dialogOptions: any = {
    title: titleText || 'Not Allowed',
    Icon: hideIcon ? null : IconComponent,
    SecondaryIcon: SecondaryIconComponant,
    ...rest,
  };

  return <BaseDialog {...dialogOptions}>{children}</BaseDialog>;
}

export default AlertDialog;
