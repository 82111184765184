import Graph from 'components/MatrixGraph/Graph';

export function graphFromRoots(roots) {
  const graph = new Graph();

  const forUpstream = (root, item) => {
    if (item.upstreamLinks) {
      item.upstreamLinks.map((upstream) => forUpstream(item, upstream));
    }
    if (!graph.findEdge(item.id, root.id)) {
      graph.addEdge({
        upstreamVersion: item,
        downstreamVersion: root,
        id: `${item.id}-${root.id}`,
      });
    }
  };

  const forDownstream = (root, item) => {
    if (item.downstreamLinks) {
      item.downstreamLinks.map((downstream) => forDownstream(item, downstream));
    }
    if (!graph.findEdge(root.id, item.id)) {
      graph.addEdge({
        upstreamVersion: root,
        downstreamVersion: item,
        id: `${root.id}-${item.id}`,
      });
    }
  };

  roots.forEach((root) => {
    root.upstreamLinks.map((item) => forUpstream(root, item));
    root.downstreamLinks.map((item) => forDownstream(root, item));
  });

  return graph;
}
