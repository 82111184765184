import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    boldHeaderText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    grid: {
      width: '100%',
      paddingLeft: `0 !important`,
      paddingRight: `0 !important`,
    },
    gridGutterRight: {
      paddingLeft: `0 !important`,
      paddingRight: theme.spacing(2),
    },
    gridGutterLeft: {
      paddingRight: `0 !important`,
      paddingLeft: theme.spacing(2),
    },
    pdfView: {
      width: '500px', // fixed width needed here to allow scaling
      height: '800px', // fixed height needed here to avoid page jumps when changing page
      margin: '0 auto',
      borderRadius: 4,
    },
    icon: {
      width: '16px',
      height: '16px',
      marginRight: theme.spacing(0.5),
    },
    fullHeight: {
      height: '100%',
    }
  }),
);
