import { get } from 'lodash';
import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import {
  Button,
  CircularProgress,
  FormControl,
  LinearProgress,
  OutlinedInput,
  withStyles,
  Typography,
} from '@material-ui/core';

import { compose } from 'recompose';

import { Spacer, UserListItem } from 'components';

import withCurrentUser from '../../../WithCurrentUser';

import styles from './styles';
import { GenericError } from 'workflows/releaseErrors';

class Signature extends Component {
  state = {
    submitted: false,
  };

  render() {
    const {
      classes,
      currentUser,
      error,
      errorDisplay, // never display error message returned from API. only custom text or a default
      disabled,
      handleSubmit,
      i18n,
      onUsernameChange,
      onPasswordChange,
    } = this.props;

    return (
      <>
        <div>
          {error && (
            <Typography variant="body2" color="error" component="div" className={classes.sigError}>
              {errorDisplay || GenericError}
            </Typography>
          )}
          <UserListItem
            name={
              <span className={classes.currentUserName}>
                {get(currentUser, 'firstName')} {get(currentUser, 'lastName')}
              </span>
            }
            userId={currentUser.id}
          />
        </div>

        <Spacer factor={2} />

        <FormControl fullWidth>
          <OutlinedInput
            labelWidth={0}
            fullWidth
            id="username"
            type="text"
            onChange={(e) => {
              onUsernameChange(e);
              this.setState({ submitted: false });
            }}
            placeholder={i18n._(t`Username`)}
            variant="outlined"
          />
        </FormControl>

        <Spacer factor={1} />

        <FormControl fullWidth>
          <OutlinedInput
            labelWidth={0}
            fullWidth
            id="password"
            type="password"
            onChange={(e) => {
              onPasswordChange(e);
              this.setState({ submitted: false });
            }}
            placeholder={i18n._(t`Password`)}
            variant="outlined"
          />
        </FormControl>

        <Spacer factor={6} />

        <div style={{ position: 'relative' }}>
          <Button
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={disabled || this.state.submitted}
            onClick={() => this.setState({ submitted: true }, handleSubmit)}
          >
            {this.state.submitted && !error ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : (
              <Trans>Submit</Trans>
            )}
          </Button>
        </div>

        <LinearProgress className={classes.signatureBar} color="primary" variant="determinate" value={100} />
      </>
    );
  }
}

export default compose(withI18n(), withStyles(styles), withCurrentUser)(Signature);
