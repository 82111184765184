import React from 'react';

import PropTypes from 'prop-types';
import { get } from 'lodash';

import SetDiffSelect from './SetDiffSelect';
import { GET_RISK_ANALYSIS_VERSION } from './gql';

function RiskAnalysisSelect(props) {
  const { options, value } = props;

  const calculatedItemId = () => {
    const item = options.find((option) =>
      get(option, 'item.versions')
        .map((version) => version.id)
        .includes(value),
    );
    return get(item, 'id', '');
  };

  const withCalculatedValue = {
    ...props,
    value: calculatedItemId(),
  };

  return <SetDiffSelect {...withCalculatedValue} setItemType="RiskAnalysis" query={GET_RISK_ANALYSIS_VERSION} />;
}

export default RiskAnalysisSelect;

RiskAnalysisSelect.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  graph: PropTypes.any,
  onChange: PropTypes.func,
};
