import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import DeviceHub from '@material-ui/icons/DeviceHub';

import slugs from '../../displaySlugs';

import PayloadFields from './PayloadFields';

const itemTypeName = 'systemArchitecture';

const metadata = {
  itemType: itemTypeName,
  PayloadFields,
  versionFields: `
    architectureDescription,
    virtualAttributes(attrType: "attachmentAttr") {
      attachments {
        id
        filename
      }
    }
  `,
  listViewQuery: gql`
    query systemArchitecturesQuery {
      systemArchitectures {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        tags
        currentVersion {
          id
          title
          architectureDescription
          currentStatusName
          versionIdentifier
          signingOwner {
            id
            firstName
            lastName
          }
          signingApprover {
            id
            firstName
            lastName
          }
        }
      }
    }
  `,
  listViewColumns: [
    {
      field: 'customIdentifier',
      headerName: 'ID',
      type: 'id',
    },
    {
      field: 'currentVersion.title',
      headerName: 'Title',
      type: 'regular_text',
    },
    {
      field: 'currentVersion.architectureDescription',
      headerName: 'Description',
      type: 'long_text',
    },
    {
      field: 'currentVersion',
      headerName: 'Version',
      type: 'version',
    },
    {
      field: 'currentVersion.currentStatusName',
      headerName: 'Status',
      type: 'status',
    },
    {
      field: 'createdAt',
      headerName: 'Initiation Date',
      type: 'date',
    },
    {
      field: 'owner',
      headerName: 'Owner',
      type: 'user',
    },
    {
      field: 'approver',
      headerName: 'Approver',
      type: 'user',
    },
  ],
  displaySlug: slugs[itemTypeName],
  label: t`System Architecture`,
  categoryLabel: t`System Architecture`,
  icon: DeviceHub,
};

export default metadata;
