export default (theme) => ({
  actionBtn: {
    ...theme.typography.button,
    color: theme.palette.primary.main,
  },
  messageIcon: {
    width: '20px',
    height: '20px',
  },
  messageText: {
    ...theme.typography.caption,
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(2),
  },
});
