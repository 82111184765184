import React, { useState } from 'react';
import { compose } from 'recompose';
import { Mutation, useMutation } from 'react-apollo';
import { Grid, withStyles, Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/icons/Link';

import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';

import withActiveCategory from '../WithCategories/withActiveCategory';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import WithCurrentUser from 'compositions/WithCurrentUser';

import { Loading, Breadcrumbs, ItemTitle, Spacer } from 'components';

import styles from './styles';
import NewItem from '../NewItem';
import ItemCard from './ItemCard';
import AdvancedLinkModal from './AdvancedLinkModal';
import { addLinkMutation, deleteLink } from './queries';
import { AlertDialog, BaseSnackbar, ConfirmationDialog, QuickSearchPopover } from 'components';
import Actions from './Actions';
import { CardContextProvider } from 'contexts/LinksGraph/cardContext';
import { FilterContextProvider } from 'contexts/LinksGraph/filterContext';
import { useItemSummaries } from './useItemSummaries';
import { graphFromRoots } from './util';
import useItemType from 'pages/generic/useItemType';

import Row from 'components/MatrixGraph/Row';
import CurrentPosition from 'components/MatrixGraph/CurrentPosition';

import { navigate } from '@reach/router';

function minZeroMaxFive(num) {
  return Math.max(0, Math.min(num, 5));
}

function RowHeader({ title, classes }) {
  return (
    <Typography component="div" className={classes.rowHeader} variant="body2">
      {title}
    </Typography>
  );
}
const RowHeaderStyled = withStyles(styles)(RowHeader);

const descendantRecordItems = [
  'deviation',
  'validation_record',
  'verification_record',
  'supplier_questionnaire_record',
  'supplier_questionnaire',
];

function LinksGraph(props) {
  const { queryVariables } = props;
  const itemType = queryVariables ? queryVariables.itemType : props.itemType;

  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [currentNode, setCurrentNode] = useState();
  const [selectedRowParent, setSelectedRowParent] = useState();
  const [selectedRowRoot, setSelectedRowRoot] = useState();
  const [selectedLinkDirection, setSelectedLinkDirection] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState();
  const [deleteLinkModal, setDeleteLinkModal] = useState(false);
  const [blockRemoveModal, setBlockRemoveModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [initialPopoverAnchorEl, setInitialPopoverAnchorEl] = useState(null);
  const [excludedIds, setExcludedIds] = useState([]);

  const itemTypeMetadata = useItemType(snakeCase(queryVariables ? queryVariables.parentItemType : itemType));

  const { itemSummaries, loading, refetch } = useItemSummaries(snakeCase(itemType));

  const toggleRelationshipSnackbar = (toggle) => {
    setShowSnackbar(toggle);
  };

  const [addLink] = useMutation(addLinkMutation, {
    onCompleted: ({ createItemLink }) => {
      refetch();
      if (Array.isArray(createItemLink.versionTraces) && createItemLink.versionTraces.length > 0) {
        toggleRelationshipSnackbar(true);
      }
    },
  });

  const linkRootToSelectedIds = (selected) => {
    const variables = {
      input: {
        rootItemId: get(currentNode, 'id'),
        linkedItemIds: selected.map((selection) => selection.id),
        direction: selectedLinkDirection,
      },
    };

    addLink({ variables }); // eslint-disable-line @typescript-eslint/no-floating-promises
  };

  const closeLinkModal = () => {
    setDeleteLinkModal(false);
    setBlockRemoveModal(false);
  };

  const setDefaultLinkState = () => {
    setSelectedLinkDirection(null);
  };

  const graph = graphFromRoots(itemSummaries);

  const setSelectedNode = (node, parent, rowRoot, direction = null) => {
    if (parent) {
      setSelectedEdge(
        direction === 'upstream'
          ? graph.findEdge(node.object.id, parent.object.id)
          : graph.findEdge(parent.object.id, node.object.id),
      );
    } else {
      setSelectedEdge(undefined);
    }
    setCurrentNode(node);
    setSelectedRowRoot(rowRoot);
    setSelectedRowParent(parent);
    setSelectedLinkDirection(direction);
    setPopperAnchorEl(null);
    setExcludedIds([...node.downstream.map((e) => e.id), ...node.upstream.map((e) => e.id)]);
  };

  const handleAddLinkButtonClick = ({ anchorEl, direction }) => {
    setPopperAnchorEl(anchorEl);
    setInitialPopoverAnchorEl(anchorEl);
    setSelectedLinkDirection(direction);
  };
  const { categorySlug, classes, theme } = props;

  const selectedRowParentId = get(selectedRowParent, 'id');
  const addLinkPopperOpen = Boolean(popperAnchorEl);
  const trueUpstream = 2 + offset;
  const numUpstream = minZeroMaxFive(trueUpstream);
  const trueDownstream = 2 - offset;
  const numDownstream = minZeroMaxFive(trueDownstream);

  const rootIsOnScreen = numUpstream < 5 && numDownstream < 5;

  const readOnly = props.currentUser.readOnly;

  const currentPosition = new CurrentPosition(offset, {
    trace: selectedEdge,
    object: currentNode,
    root: selectedRowRoot,
  });

  const isCardBelongsToRecordStyle = (itemType) => Boolean(descendantRecordItems.includes(itemType));

  const isRecordStyle = () => {
    return (
      (isCardBelongsToRecordStyle(get(currentNode, 'object.itemType')) &&
        get(selectedEdge, 'downstreamVersion.itemType') === undefined) || //root node
      (isCardBelongsToRecordStyle(get(selectedEdge, 'downstreamVersion.itemType')) &&
        isCardBelongsToRecordStyle(get(selectedEdge, 'upstreamVersion.itemType'))) || // val-r and ver-r with deviations, sqs with sqs-r
      (isCardBelongsToRecordStyle(get(selectedEdge, 'downstreamVersion.itemType')) &&
        (get(selectedEdge, 'upstreamVersion.itemType') === 'device_validation' ||
          get(selectedEdge, 'upstreamVersion.itemType') === 'device_verification'))
    ); // val-r and ver-r with protocols
  };

  return (
    <CardContextProvider>
      <div className={classes.container}>
        <div>
          <div className={classes.fixedHeader}>
            <div className={classes.headerContent}>
              <Spacer factor={3} />
              <Grid item xs={12}>
                <Breadcrumbs categorySlug={categorySlug} />
              </Grid>
              <Grid container item xs={12} justify="space-between">
                <ItemTitle icon={Link} itemTitle="Link Graph" editable={false} />
                <Actions
                  tooltipText={
                    currentNode && isRecordStyle()
                      ? 'Removing a Record Relationship not allowed'
                      : 'Remove Record Relationship'
                  }
                  readOnly={readOnly}
                  isRecordStyle={currentNode && isRecordStyle()}
                  unlinkOnClick={() => {
                    if (currentNode && selectedRowParentId && get(currentNode, 'id') !== selectedRowParentId) {
                      if (!currentNode.object.mayRemoveLinks) {
                        return setBlockRemoveModal(true);
                      }

                      setDeleteLinkModal(true);
                    }
                  }}
                />
              </Grid>
              <Spacer factor={1.5} />
              <div className={classes.columnHeadings}>
                <ChevronLeft className={classes.navigateIcon} onClick={() => setOffset(offset + 1)} />
                {[...Array(numUpstream).keys()].map((i) => (
                  <RowHeaderStyled
                    key={`u-${i}`}
                    title={`Upstream ${numUpstream - i + (trueUpstream - numUpstream)}`}
                  />
                ))}
                {rootIsOnScreen && <RowHeaderStyled title="Root" />}
                {[...Array(numDownstream).keys()].map((i) => (
                  <RowHeaderStyled key={`d-${i}`} title={`Downstream ${i + 1 + (trueDownstream - numDownstream)}`} />
                ))}
                <ChevronRight className={classes.navigateIcon} onClick={() => setOffset(offset - 1)} />
              </div>
            </div>
          </div>
          {!loading &&
            itemSummaries &&
            itemSummaries.map((row, ix) => {
              const drawCard = (vertex, parent, selected, direction) => {
                return (
                  <ItemCard
                    selected={selected}
                    onClick={() => setSelectedNode(vertex, parent, row, direction)}
                    onAddLinkClick={handleAddLinkButtonClick}
                    item={vertex.object}
                    hideConnections
                  />
                );
              };
              return (
                <Row
                  key={`row-root-${row.id}`}
                  root={row}
                  drawCard={drawCard}
                  graph={graph}
                  currentPosition={currentPosition}
                  recordStyleEdge={isRecordStyle()}
                />
              );
            })}
          {loading && <Loading style={{ height: '100px' }} size={40} />}
          <Spacer factor={2} />
          {get(itemTypeMetadata, 'userCreatable') && (
            <div style={{ maxWidth: 1280, margin: '0 auto', display: 'flex' }}>
              <div style={{ height: 64, flex: numUpstream }} />
              <div
                style={{
                  height: 64,
                  flex: 1,
                  borderRadius: 5,
                  background: 'rgba(216, 216, 216, 0.5)',
                  display: offset < 3 && offset > -3 ? 'flex' : 'none',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <NewItem
                  color={theme.palette.secondary.main}
                  defaultSelectedItemType={snakeCase(itemType)}
                  classes={{ newItemButton: classes.newItemButton }}
                />
              </div>
              <div style={{ height: 100, flex: numDownstream }} />
            </div>
          )}
        </div>
        <QuickSearchPopover
          advancedSearch={{
            onClick: () => {
              setAdvancedSearchOpen(true);
              setPopperAnchorEl(null);
            },
          }}
          anchorElement={popperAnchorEl}
          baseRecordId={get(currentNode, 'id')}
          closeSelf={() => {
            setPopperAnchorEl(null);
            setDefaultLinkState();
          }}
          direction={selectedLinkDirection}
          key={`${get(currentNode, 'id')}-${selectedRowParentId}-${selectedLinkDirection}`}
          onSelected={linkRootToSelectedIds}
          open={addLinkPopperOpen}
          baseItemType={get(currentNode, 'object.itemType')}
          showFullItewlist={true}
          excludedIds={excludedIds}
        />
        <AlertDialog open={blockRemoveModal} onClose={closeLinkModal}>
          <Typography variant="body2">{get(currentNode, 'mayNotRemoveLinksBecause', []).join('\n')}</Typography>
        </AlertDialog>
        <BaseSnackbar
          actionText="View Trace"
          actionFunction={() => navigate('trace-matrix')}
          message="Relationship added on Trace Matrix"
          onClose={() => setShowSnackbar(false)}
          open={showSnackbar}
        />
        <Mutation
          awaitRefetchQueries
          mutation={deleteLink}
          refetchQueries={['LinkQuery']}
          onCompleted={(data) => {
            if (!get(data, 'deleteItemLink.errors')) {
              closeLinkModal();
            }
            refetch();
          }}
        >
          {(mutate, { error, data, loading }) => {
            if (error) throw new Error(`Network error occurred removing an ItemLink: ${error.message}`);
            const validationErrors = get(data, 'deleteItemLink.errors');
            const confirmDelete = () => {
              mutate({
                variables: {
                  downstreamId: selectedLinkDirection === 'upstream' ? selectedRowParentId : get(currentNode, 'id'),
                  upstreamId: selectedLinkDirection === 'upstream' ? get(currentNode, 'id') : selectedRowParentId,
                },
              });
            };

            return (
              <ConfirmationDialog
                onCancel={closeLinkModal}
                onClose={closeLinkModal}
                onConfirm={confirmDelete}
                open={deleteLinkModal}
                title="Confirm Removal"
                error={(validationErrors && validationErrors.join(', ')) || (error && 'An error occurred.')}
              >
                Are you sure you want to remove the relationship between {get(currentNode, 'object.customIdentifier')}{' '}
                and {get(selectedRowParent, 'object.customIdentifier')}?
              </ConfirmationDialog>
            );
          }}
        </Mutation>
        <AdvancedLinkModal
          anchorEl={popperAnchorEl}
          baseRecordCustomId={get(currentNode, 'customIdentifier')}
          baseRecordId={get(currentNode, 'id')}
          baseRecordTitle={get(currentNode, 'title')}
          direction={selectedLinkDirection}
          onClose={() => {
            setAdvancedSearchOpen(false);
            setDefaultLinkState();
            refetch();
          }}
          open={advancedSearchOpen}
          suggestedItems={{
            onClick: (anchorEl) => {
              setAdvancedSearchOpen(false);
              setPopperAnchorEl(initialPopoverAnchorEl);
            },
          }}
          excludedIds={excludedIds}
        />
      </div>
    </CardContextProvider>
  );
}

const withFilterContext = (Component) => (props) => (
  <FilterContextProvider>
    <Component {...props} />
  </FilterContextProvider>
);

export default compose(
  withActiveCategory,
  WithCurrentUser,
  withCurrentCompany,
  withStyles(styles, { withTheme: true }),
  withFilterContext,
)(LinksGraph);
