import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import slugs from '../../displaySlugs';

import { listFragment, docMetadata } from '../mixins/Document';
const itemType = 'userNeedsDocument';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`User Needs Document`,
  categoryLabel: t`User Needs Documents`,
  icon: LibraryBooks,
  listViewQuery: gql`
    query userNeedsDocumentsQuery {
      documents(itemType: "user_needs_document") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
