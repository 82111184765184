import gql from 'graphql-tag';

export const currentUsersTaskQuery = gql`
  query TaskListQuery {
    tasks(onlyAssignedToCurrentUser: true, excludeInDoneStatus: true) {
      id
      customIdentifier
      itemType {
        id
        displaySlug
        userCreatable
      }
      currentVersion {
        id
        createdAt
        title
        dueDate
        currentStatus {
          id
          name
        }
      }
    }
  }
`;
