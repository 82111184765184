import React, { FC } from 'react';
import { DateTime } from 'luxon';

import { Box, Typography } from '@material-ui/core';

import { useStyles } from '../styles';

interface Props {
  createdAt: any;
}

const NotificationDate: FC<Props> = ({ createdAt }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body2" className={classes.date}>
        {DateTime.fromISO(createdAt).toRelative()}
      </Typography>
      <Box mb={0.5} />
      <Typography variant="body2" className={classes.date}>
        {DateTime.fromISO(createdAt).toLocaleString(DateTime.TIME_SIMPLE)}
      </Typography>
    </>
  );
};

export default NotificationDate;
