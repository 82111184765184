import React, { FC, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Error } from '@material-ui/icons';
import SettingsEditButton from '../SettingsEditButton';
import SectionTitle from 'components/SettingsSection/SectionTitle';
import RPCCalculation from 'components/RPCCalculation';

import {
  createRmeUpdateData,
  useQueryPriorityCodes,
  useQueryRiskMatrixEntries,
  useUpdateRiskMatrixEntry,
} from './utils';
import { EditProps } from '../../types';

interface Props extends EditProps {}

const RPCConfiguration: FC<Props> = ({ name, editing, onEdit }) => {
  const { data } = useQueryRiskMatrixEntries();
  const { data: rpcData } = useQueryPriorityCodes();
  const [update] = useUpdateRiskMatrixEntry();
  const [updateData, setUpdateData] = useState<{ [id: string]: any }>({});

  if (!data || !rpcData) return null;
  const { riskMatrixEntries } = data;

  const handleSave = async () => {
    try {
      if (!isEmpty(updateData)) {
        await update({ variables: { input: { riskMatrixEntries: createRmeUpdateData(updateData) } } });
      }
      onEdit(null);
      setUpdateData({});
    } catch (error) {}
  };

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;
    setUpdateData((currentUpdateData) => ({ ...currentUpdateData, [name!]: { riskPriorityCodeId: value } }));
  };

  const onInputChange = (
    unit: 'harmSeverityLevel' | 'hazardousSituationProbabilityLevel',
    matrixId: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setUpdateData((currentUpdateData) => ({
      ...currentUpdateData,
      [matrixId]: {
        ...currentUpdateData[matrixId],
        [unit]: {
          id: name,
          name: value,
        },
      },
    }));
  };

  return (
    <>
      <SectionTitle
        icon={<Error />}
        title="Risk Priority Code Table"
        editButton={
          <SettingsEditButton
            isActive={editing}
            onClick={() => onEdit(name)}
            onActionButtonClick={handleSave}
            onCancel={() => onEdit(null)}
          />
        }
      />
      <RPCCalculation
        riskMatrixEntries={riskMatrixEntries}
        editMode={editing}
        riskPriorityCodes={rpcData.riskPriorityCodes}
        onInputChange={onInputChange}
        onMatrixCellChange={handleChange}
      />
    </>
  );
};

export default RPCConfiguration;
