import React from 'react';
import { Typography } from '@material-ui/core';
import get from 'lodash/get';

export default [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.supplierAuditPlanVersion.item.customIdentifier',
    headerName: 'Associated Plan',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (row.data === undefined) return null;
      const supplierAuditPlanVersion = get(row, 'data.currentVersion.supplierAuditPlanVersion', null);
      return supplierAuditPlanVersion === null ? '-' : supplierAuditPlanVersion.item.customIdentifier;
    },
    render(row) {
      if (row.value === undefined) return null;
      return (
        <Typography variant={'body2'} style={{ width: 100 }}>
          {row.value}
        </Typography>
      );
    },
  },
  {
    field: 'currentVersion.supplierAuditPlanVersion.supplierVersion.item.customIdentifier',
    headerName: 'Associated Supplier',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (row.data === undefined) return null;
      const supplierVersion = get(row, 'data.currentVersion.supplierAuditPlanVersion.supplierVersion', null);
      return supplierVersion === null ? '-' : `${supplierVersion.item.customIdentifier}:${supplierVersion.title}`;
    },
    render: (row) => {
      if (row.value === undefined) return null;
      return (
        <Typography variant={'body2'} style={{ width: 100 }}>
          {row.value}
        </Typography>
      );
    },
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];
