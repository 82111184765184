import React from 'react';

import { MenuItem } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

function VoidMenuItem(props) {
  return (
    <MenuItem disabled={props.disabled} onClick={props.onClick}>
      <RemoveCircleIcon /> Void
    </MenuItem>
  );
}

export default VoidMenuItem;
