import React from 'react';
import { useVersion } from './gql';
import { PayloadFieldsProps } from 'itemTypes';
import AttachmentsSection from 'components/AttachmentsSection';
import ImageGallery from './ImageGallery';
import { withStyles, Grid } from '@material-ui/core';
import { ContentSection, Spacer, TextAreaField } from 'components';
import Sort from '@material-ui/icons/Sort';
import { Trans } from '@lingui/macro';

import styles from './styles';
import { useUpdateAttachment, useDestroyAttachment, useCreateAttachment } from './gql';

function PayloadFields(props: PayloadFieldsProps & { classes }) {
  const { data, loading, error } = useVersion(props.versionId);

  const [destroy] = useDestroyAttachment();
  const [create] = useCreateAttachment();
  const [update] = useUpdateAttachment();

  if (error)
    throw new Error(
      `Error querying design output version:${props.versionId}\n
      ${error}`,
    );
  if (!data || loading) return null;

  const versionData = data.designOutputVersion;
  const { layoutClasses } = props;
  return (
    <Grid container item xs={12} className={layoutClasses.grid}>
      <Grid item xs={8} className={layoutClasses.gridGutterRight}>
        <ContentSection
          LabelText={
            <>
              <Sort className={layoutClasses.icon} />
              <Trans>Description</Trans>
            </>
          }
        >
          <Spacer factor={1} />
          <TextAreaField
            refetchQueries={['DesignOutputVersion']}
            locked={versionData.locked}
            id="do-text-field"
            initialValue={versionData.description}
            versionId={versionData.id}
            attrName={'description'}
            rows={8}
            placeholder={'Enter description'}
            className={layoutClasses.textField}
          />
        </ContentSection>
      </Grid>

      <Grid item xs={12} className={layoutClasses.grid}>
        <AttachmentsSection
          title="MSC Attachments"
          versionId={versionData.id}
          classes={props.classes}
          attachments={versionData.attachments}
          key={versionData.attachments.length}
          locked={versionData.locked}
          updateAttachment={(
            id: string,
            attachment: {
              url?: string;
              previewUrl?: string;
              description?: string;
            },
          ) =>
            update({
              variables: {
                attachmentId: id,
                attachmentURL: attachment.url,
                description: attachment.description,
                previewURL: attachment.previewUrl,
              },
            })
          }
          createAttachment={(versionId: string, attachmentURL: string) =>
            create({ variables: { versionId, attachmentURL } })
          }
          destroyAttachment={(id: string) => destroy({ variables: { id } })}
          enablePreview
        />
        <ImageGallery classes={props.classes} attachments={versionData.attachments} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(PayloadFields);
