import React, { Component } from 'react';
import { compose } from 'recompose';

import { capitalize, get, isEmpty, startCase } from 'lodash';

import { Typography, withStyles } from '@material-ui/core';

import { TextAreaField } from 'components';

const styles = (theme) => ({
  body: {
    display: 'flex',
    width: '100%',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
  cell: {
    width: '50%',
    minWidth: '40%',
    paddingRight: theme.spacing(1),
  },
  primaryBodyText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textField: {
    paddingBottom: theme.spacing(1.5),
  },
});

class HorizontalBody extends Component {
  render() {
    const {
      classes,
      versionId,
      primaryBodyText,
      secondaryBodyText,
      queryToRefetch,
      locked,
      rightColumnComponent,
    } = this.props;

    return (
      <div className={classes.body}>
        {!isEmpty(primaryBodyText) && (
          <div className={classes.cell}>
            <Typography variant="caption" className={classes.primaryBodyText}>
              {startCase(capitalize(get(primaryBodyText, 'attrName', '')))}
            </Typography>
            <TextAreaField
              id="body-text-one-text-area-field"
              className={classes.textArea}
              multiline
              inputClasses={classes.textField}
              versionId={versionId}
              locked={locked}
              attrName={get(primaryBodyText, 'attrName', '')}
              initialValue={get(primaryBodyText, 'attrValue', '')}
              refetchQueries={[queryToRefetch]}
              height={'small'}
            />
          </div>
        )}

        {!isEmpty(secondaryBodyText) && (
          <div className={classes.cell}>
            <Typography variant="caption" className={classes.primaryBodyText}>
              {startCase(capitalize(get(secondaryBodyText, 'attrName', '')))}
            </Typography>
            <TextAreaField
              id="body-text-two-text-area-field"
              className={classes.textArea}
              multiline
              inputClasses={classes.textField}
              versionId={versionId}
              locked={locked}
              attrName={get(secondaryBodyText, 'attrName', '')}
              initialValue={get(secondaryBodyText, 'attrValue', '')}
              refetchQueries={[queryToRefetch]}
              height={'small'}
            />
          </div>
        )}

        {rightColumnComponent}
      </div>
    );
  }
}

export default compose(withStyles(styles))(HorizontalBody);
