import React, { PropsWithChildren } from 'react';
import { MenuItem } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

interface WorkflowItemProps {
  disabled: boolean;
  onClick: (event: any) => void;
}

function WontDoMenuItem(props: PropsWithChildren<WorkflowItemProps>) {
  return (
    <MenuItem disabled={props.disabled} onClick={props.onClick}>
      <RemoveCircleIcon />
      {props.children ? props.children : "Won't Do"}
    </MenuItem>
  );
}

export default WontDoMenuItem;
