import React from 'react';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';

import Trace from 'assets/icons/Trace';

const styles = (theme) => ({
  card: {
    width: 0,
    minWidth: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabled: {
    '&:hover': {
      cursor: 'auto',
    },
  },
  traceButton: {
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    height: 24,
    width: 24,
    position: 'absolute',
    top: '40%',
    '&:hover': {
      cursor: 'pointer',
    },
    zIndex: 999,
  },
});

function Vertex(props) {
  const { classes, disabled, selected } = props;

  return (
    <div style={{ flex: 1, display: 'flex', position: 'relative' }}>
      <div
        className={clsx(classes.card, props.disabled && classes.disabled)}
        onClick={selected ? null : (e) => props.onClick(e)}
      >
        {props.children}

        {!disabled && selected && props.onAddLeftEdge && (
          <div onClick={props.onAddLeftEdge} className={classes.traceButton} style={{ left: -12 }}>
            <Trace style={{ borderRadius: '50%' }} />
          </div>
        )}

        {!disabled && selected && props.onAddRightEdge && (
          <div onClick={props.onAddRightEdge} className={classes.traceButton} style={{ right: -12 }}>
            <Trace style={{ borderRadius: '50%' }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(Vertex);
