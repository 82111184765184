import React, { FC } from 'react';

import { Box, Grid, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import NotificationHeadline from './components/NotificationHeadline';
import NotificationDate from './components/NotificationDate';
import NotificationRadio from './components/NotificationRadio';
import { useStyles } from './styles';

interface Props {
  headlineIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  headlineText: string | null;
  itemTitle: React.ReactNode;
  onComplete: any;
  createdAt: any;
  completed: boolean;
  callToAction?: React.ReactNode;
}

const GenericNotificationListItem: FC<Props> = ({
  headlineIcon,
  headlineText,
  itemTitle,
  createdAt,
  completed,
  onComplete,
  callToAction,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.notificationListItem}>
      <Grid container>
        <Grid item xs={10}>
          <div className={classes.notificationListItemMainContent}>
            <NotificationHeadline icon={headlineIcon} headline={headlineText} />
            <Box mb={0.5} />
            <div>{itemTitle}</div>
            <Box flex={1} />
            {callToAction}
          </div>
        </Grid>

        <Grid item xs={2}>
          <Box pr={2} pt={0.5}>
            <NotificationDate createdAt={createdAt} />
          </Box>
          <NotificationRadio checked={completed} onClick={onComplete} />
        </Grid>
      </Grid>
    </div>
  );
};

export default GenericNotificationListItem;
