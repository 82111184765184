import React, { useState } from 'react';

import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';

import { get } from 'lodash';
import { DateTime } from 'luxon';

import { Spacer, UserAvatar } from 'components';
import { WizardComponentProps } from 'compositions/TimelineGroup/steps/types';
import Button from 'components/Button';
import { icons } from 'components/WorkflowIcon';

const DraftIcon = icons.draft;

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    heading: {
      clear: 'both',
      paddingLeft: '1px',
      paddingRight: '1px',
    },
    boldText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    padRightUnit: {
      paddingRight: theme.spacing(1),
    },
    textInput: {
      maxHeight: 80,
      overflow: 'auto',
      background: theme.palette.common.input.background,
      padding: theme.spacing(1),
      borderRadius: '4px',
    },
    buttonDraft: {
      minHeight: 40,
      backgroundColor: theme.palette.common.draft.main,
      '&:hover': {
        backgroundColor: theme.palette.common.draft.main,
      },
      display: 'flex',
    },
    buttonIcon: {
      flex: 2,
      marginRight: theme.spacing(1),
    },
    buttonIconText: {
      flex: 6,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
    },
  }),
);

const CreateSupplierVersion = (props: WizardComponentProps) => {
  const classes = useStyles();
  const { handleComplete, currentVersion, itemCustomIdentifier } = props;
  const revokedBy = get(currentVersion, 'approver');
  const revokedAt = get(currentVersion, 'currentStatus.createdAt');
  const isQualificationRejected = currentVersion.currentStatusName === 'qualification_rejected';

  const revokedAtFormatted = `${DateTime.fromISO(revokedAt).toFormat('MMMM d, yyyy')}.`;
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Spacer factor={1} />
      <div className={classes.heading}>
        <Typography variant="h3">Initiate New Qualification</Typography>
      </div>

      <Spacer factor={2} />

      <Typography variant="h4" className={classes.boldText}>
        {`${itemCustomIdentifier}: ${get(currentVersion, 'title', '')} was revoked on ${revokedAtFormatted}`}
      </Typography>
      <Spacer factor={2} />
      <Typography variant="h5">
        The approval of this Supplier was revoked. Please review the Reason for Revocation and create a new
        Qualification Attempt.
      </Typography>

      <Spacer factor={3} />

      <Typography variant="button">Reviewed By</Typography>
      <Spacer factor={2} />
      {revokedBy && (
        <Grid container>
          <Grid item xs={2} container direction="column" alignItems="flex-end">
            <div className={classes.padRightUnit}>
              <UserAvatar userId={revokedBy.id} />
            </div>
          </Grid>
          <Grid item xs={10} style={{ marginTop: -4 }}>
            <Typography variant="subtitle1">{revokedBy.fullName}</Typography>
            <Typography variant="body2">{revokedBy.title}</Typography>
          </Grid>
        </Grid>
      )}

      <Spacer factor={3} />

      <Typography variant="button">
        {isQualificationRejected ? 'Reason for Rejecting Approval' : 'Reason for Revoking Approval'}
      </Typography>
      <Spacer factor={1} />
      <Typography variant="body2">
        {isQualificationRejected
          ? get(currentVersion, 'currentStatus.previousTransition.esignature.reasonForSupplierRejection')
          : get(currentVersion, 'reasonForRevocation', '')}
      </Typography>

      <Spacer factor={4} />
      <Button
        color="primary"
        className={classes.buttonDraft}
        fullWidth
        size="medium"
        type="submit"
        loading={loading}
        variant="contained"
        onClick={() => {
          setLoading(true);
          handleComplete()
            .then(() => setLoading(false))
            .catch((error) => {
              throw new Error(`Version creation failed: ${error.message}`);
            });
        }}
      >
        <span className={classes.buttonIconText}>
          <DraftIcon className={classes.buttonIcon} />
          new qualification attempt
        </span>
        <span style={{ flex: 2 }} />
      </Button>
    </>
  );
};

export default CreateSupplierVersion;
