import React from 'react';
import { useQuery } from 'react-apollo';

import { DirectUploadQuery } from './__generated__/DirectUploadQuery';
import { uploadQuery } from './directUploadQuery';

export interface WithUploadParamsProps {
  url: string;
  params: { name: string; value: string }[];
}

const WithUploadParams = (Cmp) => (cmpProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, loading, error } = useQuery<DirectUploadQuery>(uploadQuery, {
    fetchPolicy: 'network-only',
  });

  if (error) throw new Error(`${error}`);
  if (!data || loading) return null;
  const directUpload = data.newDirectUpload;

  return <Cmp url={directUpload.url} params={directUpload.params} {...cmpProps} />;
};

export default WithUploadParams;
