import gql from 'graphql-tag';

export const CREATE_TRAINING_MATERIAL_ATTACHMENT = gql`
  mutation createTrainingMaterialAttachment($versionId: ID!, $attachmentURL: String!) {
    createTrainingMaterialAttachment(versionId: $versionId, file: $attachmentURL) {
      trainingMaterialAttachment {
        id
      }
    }
  }
`;

export const DESTROY_TRAINING_MATERIAL_ATTACHMENT = gql`
  mutation DestroyTrainingMaterialAttachment($id: ID!) {
    destroyTrainingMaterialAttachment(id: $id) {
      trainingMaterialAttachment {
        id
      }
    }
  }
`;

export const UPDATE_TRAINING_MATERIAL_ATTACHMENT = gql`
  mutation updateTrainingMaterialAttachment(
    $id: ID!
    $attachmentURL: String
    $description: String
    $editableDocument: String
  ) {
    updateTrainingMaterialAttachment(
      id: $id
      file: $attachmentURL
      description: $description
      editableDocument: $editableDocument
    ) {
      trainingMaterialAttachment {
        id
      }
    }
  }
`;
