import React from 'react';
import { Trans } from '@lingui/macro';
import get from 'lodash/get';

import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Flag from '@material-ui/icons/Flag';
import Check from '@material-ui/icons/Check';

import { Breadcrumbs, ContentSection, ItemTitle, Spacer, ErrorBoundary, Loading } from 'components';
import { ContextDrawer, ItemControls } from 'compositions';
import styles from 'components/BuilderView/styles';
import { useBuilderViewQuery } from './gql';
import CurriculumWorkflow from 'workflows/CurriculumWorkflow';
import PayloadFields from './PayloadFields';
import { FeatureUnavailable } from 'pages';

interface BuilderViewProps {
  classes;
  itemId: string;
  categorySlug: string;
}
function BuilderView({ categorySlug, classes, itemId }: BuilderViewProps) {
  const { data, loading, error } = useBuilderViewQuery(itemId);

  if (loading || !data) return <Loading />;
  if (error) throw new Error(`Error loading curriculum ${itemId}\n${error}`);

  const itemRecord = data.item;
  const readOnly = get(itemRecord, 'currentUser.readOnly', true);
  if (readOnly) return <FeatureUnavailable />;
  const { currentVersion } = itemRecord;
  if (!currentVersion) throw new Error(`null currentVersion returned for curriculum ${itemId}`);

  const itemTitle = get(itemRecord, 'currentVersion.title', '');
  const titlePrefix = `${get(itemRecord, 'customIdentifier', '')}`;

  return (
    <Grid container data-testid="item" spacing={3} className={classes.container}>
      <Grid item xs={8} className={classes.grid}>
        <Breadcrumbs categorySlug={categorySlug} itemTitle={`${titlePrefix} ${itemTitle}`} />
        <Grid container item xs={12} justify="space-between">
          <ItemTitle
            editable={!currentVersion.locked}
            itemTitle={itemTitle}
            titlePrefix={titlePrefix}
            versionId={currentVersion.id}
            refetchQueries={['CurriculumBuilderViewQuery', 'CurriculaQuery']}
          />

          <ItemControls
            tagsProps={{
              itemId: itemRecord.id,
              tags: itemRecord.tags,
              refetchQueries: ['CurriculumBuilderViewQuery', `curriculaQuery`, 'tagsQuery'],
            }}
            peopleProps={{
              locked: currentVersion.locked,
              owner: currentVersion.owner,
              approver: currentVersion.approver,
              creator: currentVersion.creator,
              createdAt: currentVersion.createdAt,
              permissibleApprovers: currentVersion.permissibleApprovers,
              permissibleOwners: currentVersion.permissibleOwners,
              versionId: currentVersion.id,
              version: currentVersion,
              refetchQueries: ['CurriculumBuilderViewQuery'],
            }}
            ellipsesProps={{
              key: `elmenu:${currentVersion.id}`,
              referenceId: itemRecord.referenceId,
              itemId: itemRecord.id,
            }}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.grid}>
        <Grid item xs={8} className={classes.gridGutterRight}>
          <ErrorBoundary>
            <CurriculumWorkflow itemId={itemId} />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={4} className={classes.gridGutterLeft}>
          <ContentSection LabelText={<Trans>Item Notes</Trans>} Icon={<Flag className={classes.icon} />}>
            <Spacer factor={1} />
            <div className={classes.itemNotes}>
              <Spacer factor={3} />
              <Typography variant="body2" className={classes.iconTextStack}>
                <Check className={classes.icon} />
                <Trans>No new updates</Trans>
              </Typography>
            </div>
          </ContentSection>
        </Grid>
      </Grid>

      <PayloadFields versionId={currentVersion.id} />

      <ContextDrawer itemId={itemId} itemType={'curriculum'} />
    </Grid>
  );
}

export default withStyles(styles)(BuilderView);
