import React, { FC } from 'react';
import { SvgIconTypeMap, Typography } from '@material-ui/core';

import { useStyles } from '../styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

interface Props {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  headline: string | null;
}

const NotificationHeadline: FC<Props> = ({ icon: Icon, headline }) => {
  const classes = useStyles();

  return (
    <div className={classes.headlineContainer}>
      <Icon className={classes.headlineIcon} />
      <Typography variant="h6" className={classes.headline}>
        {headline}
      </Typography>
    </div>
  );
};

export default NotificationHeadline;
