import React, { FC, useState } from 'react';
import { MenuItem, Select, TextField, Typography } from '@material-ui/core';

import { SettingsSelectItem } from './types';
import { useStyles } from './styles';

interface Props {
  item: SettingsSelectItem;
  value: number;
  onChange: (name: string, value: number) => void;
  disabled?: boolean;
}

type Unit = 'days' | 'months' | 'years';

const SettingsSelect: FC<Props> = ({ item, value, onChange, disabled }) => {
  const classes = useStyles();
  const [unit, setUnit] = useState<Unit>('days');
  const [valueByUnit, setValueByUnit] = useState<number>(value);
  const { key, label, subLabel } = item;

  const options = [
    { key: 'days', value: 'days', label: 'Days' },
    { key: 'months', value: 'months', label: 'Months' },
    { key: 'years', value: 'years', label: 'Years' },
  ];

  const changeUnit = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const unit = event.target.value as Unit;
    setUnit(unit);
    switch (unit) {
      case 'days':
        setValueByUnit(value);
        break;
      case 'months':
        setValueByUnit(Math.floor(value / 30));
        break;
      case 'years':
        setValueByUnit(Math.floor(value / 365));
        break;
      default:
        break;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let valueAsNumber = Math.floor(Number(event.target.value));
    setValueByUnit(valueAsNumber);
    switch (unit) {
      case 'months':
        valueAsNumber = valueAsNumber * 30; // Converts months to days and add on default value
        break;
      case 'years':
        valueAsNumber = valueAsNumber * 365; // Converts years to days and add on default value
        break;
      default:
        break;
    }

    onChange(event.target.name, valueAsNumber);
  };

  return (
    <div key={key} className={`${classes.selectItem} selectItem`}>
      <div className={classes.selectItemLabelArea}>
        <Typography variant="subtitle1" className={classes.selectItemLabel}>
          {label}
        </Typography>
        {subLabel && (
          <Typography variant="body2" className={classes.selectItemSubLabel}>
            {subLabel}
          </Typography>
        )}
      </div>
      <TextField
        key={`${key}-${valueByUnit}`}
        name={key}
        defaultValue={valueByUnit}
        type="number"
        variant="outlined"
        onChange={handleChange}
        className={classes.input}
        disabled={disabled}
        InputProps={{
          inputProps: { min: 0 },
        }}
      />
      <Select
        name={key}
        variant="outlined"
        value={unit}
        onChange={changeUnit}
        className={classes.select}
        disabled={disabled}
        SelectDisplayProps={{ style: { paddingTop: 12, paddingBottom: 12 } }}
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SettingsSelect;
