import React from 'react';

import { Typography } from '@material-ui/core';
import { get } from 'lodash';

import { VersionAndStatusDot } from 'components';

export const ChipSelectOptionsOptionRender = ({ option }) => {
  return (
    <Typography variant={'body2'} style={{ display: 'flex' }}>
      {get(option, 'customIdentifier', '')}: {get(option, 'currentVersion.title', '')}
      <VersionAndStatusDot
        style={{ marginLeft: '5px' }}
        status={get(option, 'currentVersion.currentStatus.name', '')}
        version={get(option, 'currentVersion.versionIdentifier', '')}
      />
    </Typography>
  );
};

export default ChipSelectOptionsOptionRender;
