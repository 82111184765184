import React from 'react';
import { Mutation } from 'react-apollo';
import get from 'lodash/get';

import {
  createVersion,
  transitionMutation,
  updateMutation,
} from '../../../utils/gql/timelineQueries';

class TimelineMutations extends React.Component {
  render() {
    const { refetch } = this.props;
    return (
      <Mutation
        mutation={updateMutation}
        refetchQueries={['timelineQuery']}
        errorPolicy="all"
      >
        {(updateTimeline, { error: timelineError }) => {
          if (timelineError) console.log(timelineError);

          return (
            <Mutation
              mutation={transitionMutation}
              refetchQueries={refetch}
              errorPolicy="all"
              awaitRefetchQueries
            >
              {(createTransition, { error: transitionError, data }) => {
                const dataError = get(data, 'createTransition.error');
                if (dataError) console.log(dataError);
                if (transitionError) console.log(transitionError);

                return (
                  <Mutation
                    mutation={createVersion}
                    refetchQueries={refetch}
                    errorPolicy="all"
                    awaitRefetchQueries
                  >
                    {(createVersion, { error: createVersionError }) => {
                      if (createVersionError) console.log(createVersionError);

                      return this.props.children({
                        error:
                          transitionError ||
                          dataError ||
                          createVersionError ||
                          timelineError,
                        createVersion,
                        createTransition,
                        updateTimeline,
                      });
                    }}
                  </Mutation>
                );
              }}
            </Mutation>
          );
        }}
      </Mutation>
    );
  }
}

export default TimelineMutations;
