import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { Trans } from '@lingui/macro';

import Page from 'components/core/Page';
import ToolboxGray from 'assets/img/toolbox-gray.svg';

import { useStyles } from './styles';

export interface UtilitiesHomeProps {
  path: string;
}

const UtilitiesHome: React.FC<UtilitiesHomeProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.utilitiesDashboard}>
      <Page>
        <Grid item xs={12}>
          <div className={classes.header}>
            <img src={ToolboxGray} alt="toolbox-gray" style={{ marginRight: '10px' }} />
            <Typography variant="h2" className={classes.headerTitle}>
              <Trans>Utilities Home</Trans>
            </Typography>
          </div>
          <Typography variant="body2">
            This is the Utilities home page. For any questions, please email support@nemedio.com
          </Typography>
        </Grid>
      </Page>
    </div>
  );
};

export default UtilitiesHome;
