import React from 'react';

import { Trans } from '@lingui/macro';

import Grid from '@material-ui/core/Grid';

import Loupe from '@material-ui/icons/Loupe';
import Sort from '@material-ui/icons/Sort';

import { ContentSection, MultiSelectField, Spacer, TextAreaField } from 'components';
import { options, placeholder } from './picklist';

const PayloadFields = ({ displayOnly, layoutClasses, versionData, i18n }) => {
  return (
    <>
      <Grid item xs={8} className={layoutClasses.gridGutterRight}>
        <ContentSection
          LabelText={
            <>
              <Sort className={layoutClasses.icon} />
              <Trans>User Need Description</Trans>
            </>
          }
        >
          <Spacer factor={1} />
          <TextAreaField
            refetchQueries={['userNeedQuery']}
            locked={versionData.locked || versionData.currentStatus.name !== 'unlocked'}
            id="user-need-text-field"
            initialValue={versionData.userNeedText}
            versionId={versionData.id}
            attrName={'user_need_text'}
            rows={8}
            placeholder="Enter description"
            className={layoutClasses.textField}
          />
        </ContentSection>
      </Grid>

      <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
        <ContentSection
          LabelText={
            <>
              <Loupe className={layoutClasses.icon} />
              <Trans>Stakeholders</Trans>
            </>
          }
          fillHeight={false}
        >
          <Spacer factor={1} />
          <MultiSelectField
            displayOnly={displayOnly}
            locked={versionData.locked || versionData.currentStatus.name !== 'unlocked'}
            attrName={'stakeholders'}
            options={options}
            refetchQueries={['userNeedQuery']}
            versionId={versionData.id}
            value={versionData.stakeholders}
            placeholder={placeholder.id}
          />
        </ContentSection>
      </Grid>
    </>
  );
};

export default PayloadFields;
