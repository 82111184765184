import React, { useState } from 'react';

import { LinkOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import Modal from 'components/WorkflowModal';
import ModalContainer from 'components/WorkflowModalContainer';
import YesOrNo from 'components/YesOrNo';

import { useStyles } from './styles';

interface Props {
  riskIdent: string;
  riskIdentVersion: string;
  unlinkRisk: () => Promise<any>;
  disabled: boolean;
}

export default function UnlinkControl(props: Props) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const { riskIdent, riskIdentVersion, unlinkRisk, disabled } = props;

  const head = `Unlink ${riskIdent} from Risk Analysis?`;
  const body = `Making this change will remove any traces attached to ${riskIdentVersion} in the Risk Trace Matrix.`;
  const onClose = () => setShowModal(false);

  return (
    <>
      <Modal
        open={showModal}
        onClose={onClose}
        style={{
          top: '50%',
          transform: 'translate(0, -25%)',
        }}
      >
        <ModalContainer showBackButton={false} onBack={onClose} onClose={onClose}>
          <YesOrNo
            closeOnNo
            disableOnSubmit
            handleComplete={() => unlinkRisk().then(() => setShowModal(false))}
            handleClose={onClose}
            heading={head}
            prompt={body}
            noLabel="dismiss"
            yesLabel="remove"
          />
        </ModalContainer>
      </Modal>
      <IconButton
        data-testid="tags-control"
        aria-label="Open Tags"
        disableRipple
        onClick={() => setShowModal(true)}
        className={classes.iconButton}
        disabled={disabled}
      >
        <LinkOff classes={{ root: classes.smallIcon }} />
      </IconButton>
    </>
  );
}
