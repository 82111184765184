import React from 'react';

import { useStyles } from './styles';

export function MultioptionCell({ data }) {
  const classes = useStyles();
  function truncate(str) {
    if (str.length > 1) {
      let formattedStr =
        str
          .join(', ')
          .replace(/^,/, '')
          .substring(0, 16) + '...';
      return formattedStr;
    } else {
      return str;
    }
  }

  return (
    <div className={classes.content}>
      {data && data.length > 1 ? truncate(data) : data ? data.join(', ').replace(/^,/, '') : null}
    </div>
  );
}

export const multiOptionHeaderStyle = { textAlign: 'left' };
