import React from 'react';
import { SupplierWorkflowStatuses } from 'workflows/statuses';
import { CertifiedSignature, YesOrNo } from 'compositions/TimelineGroup/steps/views';
import SupplierRevocationRejection from 'compositions/TimelineGroup/steps/views/SupplierRevocationRejection';
import SupplierAssociatedDocsReleased from 'compositions/TimelineGroup/components/SupplierAssociatedDocsReleased';

const APPROVED_STATUS = SupplierWorkflowStatuses.SupplierApproved.id;
const REJECTED_STATUS = SupplierWorkflowStatuses.QualificationRejected.id;

export default {
  initialStep: 'approveOrReject',
  internalSteps: {
    approveOrReject: {
      component: (props) => (
        <YesOrNo
          heading={'Supplier Result'}
          disableOnSubmit
          prompt={`Based on the results of the Supplier qualification activities, I have determined that this Supplier can be Approved or Rejected.`}
          yesLabel="Approve"
          noLabel="Reject"
          {...props}
        />
      ),
      getNextStep: (isApprove) => (isApprove ? 'checkAllowedness' : 'rejection'),
    },
    checkAllowedness: {
      component: SupplierAssociatedDocsReleased,
      getNextStep: (_) => 'approval',
    },
    approval: {
      component: (props) => {
        const heading = 'Approve Supplier';
        const dialog =
          'My signature indicates that I have reviewed this Supplier and all associated qualification activities and approve the supplier.';

        return (
          <div style={{ minWidth: '312px' }}>
            <CertifiedSignature heading={heading} dialog={dialog} {...props} />
          </div>
        );
      },
      getNextStep: (_) => 'complete',
    },
    rejection: {
      component: (props) => {
        return (
          <SupplierRevocationRejection
            stepProps={props}
            title={'Reject Supplier'}
            subText={
              'Please provide context for rejecting the supplier and upload any pertinent files, and associated items.'
            }
            reasonField={{
              fieldName: 'reasonForSupplierRejection',
              fieldLabel: 'Reason For Rejection',
            }}
          />
        );
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ approveOrReject, rejection, approval }) => {
    if (approveOrReject) {
      return {
        toStatus: APPROVED_STATUS,
        ...approval,
      };
    } else {
      return {
        toStatus: REJECTED_STATUS,
        ...rejection,
      };
    }
  },
};
