import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    tableContainer: {
      marginBottom: theme.spacing(2),
    },
    info: {
      float: 'right',
    },
  }),
);
