import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { buildConditionalClasses } from 'utils';

import styles from './styles';

import RichTextEditorField from './RichTextEditorField';
import OptionsToolbar from './OptionsToolbar';
import TrackChangesToolbar from './TrackChangesToolbar';

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = { isLayoutReady: false };
    this.commentsContainerRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ isLayoutReady: true });
  }
  render() {
    const { classes } = this.props;
    const commentClasses = {
      wideSidebar: classes.commentsSidebar,
      inline: classes.hiddenComments,
      narrowSidebar: classes.commentsNarrowSidebar,
    };

    return (
      <div className={buildConditionalClasses([[true, classes.container]])}>
        {this.state.isLayoutReady && (
          <div className={classes.textAreaContainer}>
            <RichTextEditorField
              editorRef={this.props.editorRef}
              attrName={this.props.attrName}
              fixedToolbarOffset={this.props.fixedToolbarOffset}
              commentsContainer={this.commentsContainerRef.current}
              showComments={true}
              initialValue={this.props.initialValue}
              locked={this.props.locked}
              optionSet={this.props.optionSet}
              refetchQueries={this.props.refetchQueries}
              versionId={this.props.versionId}
              displayMode={this.props.commentsDisplayVariant}
              setCommentsCount={this.props.setCommentsCount}
              trackChangesMode={this.props.trackChangesToggle}
              trackChangesAction={this.props.trackChangesAction}
              setSuggestionsCount={this.props.setSuggestionsCount}
            />
          </div>
        )}
        <div className={commentClasses[this.props.commentsDisplayVariant]} ref={this.commentsContainerRef} />
      </div>
    );
  }
}

export { OptionsToolbar, TrackChangesToolbar };
export default withStyles(styles)(RichTextEditor);
