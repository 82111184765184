import React from 'react';
import { AttachmentDependentWorkflowStatuses } from 'workflows/statuses';
import { Trans } from '@lingui/macro';
import { CertifiedSignature, UploadAttachment } from '../views';

const CERTIFY_TEXT = 'By electronically signing below, I confirm that I have read and understood the material listed.';

export default {
  initialStep: 'attach_file',
  internalSteps: {
    attach_file: {
      component: (props) => (
        <UploadAttachment
          versionId={props.currentVersion.id}
          dialog={
            <>
              <Trans>Attach the completed assessment associated with </Trans> {props.itemCustomIdentifier}
            </>
          }
          handleComplete={props.handleComplete}
          heading="Completed Confirmation"
        />
      ),
      getNextStep: () => 'confirm',
    },
    confirm: {
      component: (props) => {
        const heading = <Trans>Completed Confirmation</Trans>;
        const dialog = `As the assignee of ${props.itemCustomIdentifier}, I have completed the associated course: ${
          props.currentVersion.source.title
        }`;

        return <CertifiedSignature heading={heading} dialog={dialog} certifyText={CERTIFY_TEXT} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ confirm }) => {
    return {
      toStatus: AttachmentDependentWorkflowStatuses.Completed.id,
      ...confirm,
    };
  },
};
