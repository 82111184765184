import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      '& > *': {
        margin: `0 ${theme.spacing(0.5)}px`,
      },
      '& > *:first-child': {
        marginLeft: `0`,
      },
      '& > *:last-child': {
        marginRight: `0`,
      },
    },
  }),
);
