/**
 * Extracts the tenant subdomain from the client URL for use in requests to the api...
 * @return {string} The Base API URL
 */
function getBaseApiUrl() {
  const hostname = window && window.location && window.location.hostname;
  const hostParts = hostname.split('.');

  if (process.env.REACT_APP_RELEASE_STAGE === 'prod') {
    const subdomains = hostParts.slice(0, hostParts.indexOf('qms'));

    return `https://${subdomains.join('.')}.qms-${process.env.REACT_APP_RELEASE_STAGE}-api.nemedio.com`;
  }

  if (process.env.REACT_APP_RELEASE_STAGE === 'ndf') {
    const subdomains = hostParts.slice(0, hostParts.indexOf('ndf'));

    return `https://${subdomains.join('.')}.ndf-api.nemedio.com`;
  }

  if (process.env.NODE_ENV === 'production') {
    const subdomains = hostParts.slice(0, hostParts.indexOf(`qms-${process.env.REACT_APP_RELEASE_STAGE}`));

    return `https://${subdomains.join('.')}.qms-${process.env.REACT_APP_RELEASE_STAGE}-api.nemedio.com`;
  }

  return '';
}

/**
 * @returns {string} an ENV, release stage, and customer based URL for avatar uploads.
 */
function getProfileAvatarUploadUrl() {
  return `${getBaseApiUrl()}/profiles/photos`;
}

/**
 * @returns {string} an ENV, release stage, and customer based URL for company logo uploads.
 */
function getCompanyLogoUploadUrl() {
  return `${getBaseApiUrl()}/companies/logos`;
}

/**
 * @returns {string} an ENV, release stage, and customer based URL for the graphql endpoint.
 */
function getGraphQLApiUrl() {
  return `${getBaseApiUrl()}/graphql`;
}

export { getBaseApiUrl, getProfileAvatarUploadUrl, getGraphQLApiUrl, getCompanyLogoUploadUrl };
