import { useState } from 'react';

type Anchor = Element | null;

export function useToggleablePopover(): [boolean, Anchor, (a: Anchor) => void] {
  const [toggled, setToggled] = useState(false);
  const [anchor, setAnchor] = useState<Element | null>(null);

  return [
    toggled,
    anchor,
    (anchor: Element | null) => {
      if (anchor) {
        setToggled(true);
        setAnchor(anchor);
      } else {
        setToggled(false);
        setAnchor(null);
      }
    },
  ];
}
