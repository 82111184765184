import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import { RiskVersionQuery, RiskVersionQueryVariables } from './__generated__/RiskVersionQuery';

const versionQuery = gql`
  query RiskVersionQuery($id: ID!) {
    riskVersion(id: $id) {
      item {
        downstreamItemsKept {
          id
          customIdentifier
          downstreamItemsKept {
            id
            customIdentifier
            currentVersion {
              title
              id
            }
          }
          currentVersion {
            title
            id
            ... on RiskControlVersion {
              introducesNewRisks
            }
            item {
              id
              itemType {
                id
                displaySlug
              }
              currentRelease {
                id
                versionIdentifier
                currentStatus {
                  id
                  name
                }
              }
              workingVersion {
                id
                versionIdentifier
                currentStatus {
                  id
                  name
                }
              }
            }
          }
        }
        ... on Risk {
          riskControlMeasures {
            id
            chosenRiskControlVersionId
            customIdentifier
            introducedNewRisks {
              id
              customIdentifier
              currentVersion {
                title
                id
              }
            }
            currentVersion {
              title
              id
              locked
              versionIdentifier
              ... on RiskControlVersion {
                introducesNewRisks
              }
              item {
                id
                itemType {
                  id
                  displaySlug
                }
                versions {
                  id
                }
                currentRelease {
                  id
                  versionIdentifier
                  currentStatus {
                    id
                    name
                  }
                }
                workingVersion {
                  id
                  versionIdentifier
                  currentStatus {
                    id
                    name
                  }
                }
              }
              currentStatus {
                id
                name
              }
            }
          }
        }
      }
      id
      title
      harm {
        id
        name
      }
      hazard {
        id
        name
      }
      sequenceOfEvents
      hazardousSituation
      justification
      residualRiskJustification
      mitigatedHarmAsUnmitigated
      mitigatedHarm {
        id
        name
      }
      item {
        itemType {
          id
          displaySlug
        }
        id
        customIdentifier
      }
      unmitigatedSeverity {
        id
        name
      }
      unmitigatedProbability {
        id
        name
      }
      unmitigatedProbability1 {
        id
        name
      }
      unmitigatedProbability2 {
        id
        name
      }
      unmitigatedRiskPriorityCode {
        id
        name
        fullName
        color
      }
      mitigatedSeverity {
        id
        name
      }
      mitigatedProbability {
        id
        name
      }
      mitigatedProbability1 {
        id
        name
      }
      mitigatedProbability2 {
        id
        name
      }
      mitigatedRiskPriorityCode {
        id
        name
        fullName
        color
      }
      finalRiskAcceptability
      residualRiskAcceptability
      locked
      linkedRiskControlVersions {
        id
        title
        ... on RiskControlVersion {
          id
          introducesNewRisks
          chosenRiskVersionIds
          locked
          linkedRiskVersions {
            id
            title
            locked
            harm {
              id
              name
            }
            hazard {
              id
              name
            }
            hazardousSituation
            sequenceOfEvents
            versionIdentifier
            currentStatus {
              id
              name
            }
            item {
              id
              customIdentifier
              itemType {
                id
                displaySlug
              }
              currentRelease {
                id
                versionIdentifier
                currentStatus {
                  id
                  name
                }
              }
              workingVersion {
                id
                locked
                versionIdentifier
                currentStatus {
                  id
                  name
                }
              }
            }
          }
        }
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          customIdentifier
          currentRelease {
            id
            versionIdentifier
            title
            currentStatus {
              name
              id
            }
          }
          currentVersion {
            title
            id
            versionIdentifier
            ... on RiskControlVersion {
              introducesNewRisks
            }
            item {
              id
              itemType {
                id
                displaySlug
              }
            }
            currentStatus {
              id
              name
            }
          }
          workingVersion {
            id
            title
            versionIdentifier
            currentStatus {
              name
              id
            }
          }
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
    }
    probabilityLevels {
      id
      color
      name
      level
    }
    severityLevels {
      id
      color
      name
      level
    }
    harms {
      id
      name
    }
    hazards {
      id
      name
    }
    riskControls(riskId: $id) {
      customIdentifier
      id
      currentVersion {
        id
        title
        introducesNewRisks
        versionIdentifier
        currentStatus {
          id
          name
          titleizedName
        }
        item {
          id
          versions {
            id
          }
          downstreamItemsKept {
            id
            customIdentifier
            itemType {
              displaySlug
              id
            }
          }
        }
      }
    }
    risks {
      id
      customIdentifier
      versions {
        id
      }
      currentVersion {
        id
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          itemType {
            id
            displaySlug
          }
        }
      }
    }
  }
`;

export const updateVersion = gql`
  mutation updateRiskVersion(
    $id: ID!
    $unmitigatedSeverityId: ID
    $unmitigatedProbabilityId: ID
    $unmitigatedProbability1Id: ID
    $unmitigatedProbability2Id: ID
    $unmitigatedRiskPriorityCodeId: ID
    $mitigatedSeverityId: ID
    $mitigatedProbabilityId: ID
    $mitigatedProbability1Id: ID
    $mitigatedProbability2Id: ID
    $mitigatedRiskPriorityCodeId: ID
    $finalRiskAcceptability: Boolean
    $residualRiskAcceptability: Boolean
    $harmId: ID
    $mitigatedHarmId: ID
    $hazardId: ID
    $sequenceOfEvents: String
    $hazardousSituation: String
    $justification: String
    $residualRiskJustification: String
    $mitigatedHarmAsUnmitigated: Boolean
  ) {
    updateRiskVersion(
      id: $id
      unmitigatedSeverityId: $unmitigatedSeverityId
      unmitigatedProbabilityId: $unmitigatedProbabilityId
      unmitigatedProbability1Id: $unmitigatedProbability1Id
      unmitigatedProbability2Id: $unmitigatedProbability2Id
      unmitigatedRiskPriorityCodeId: $unmitigatedRiskPriorityCodeId
      mitigatedSeverityId: $mitigatedSeverityId
      mitigatedProbabilityId: $mitigatedProbabilityId
      mitigatedProbability1Id: $mitigatedProbability1Id
      mitigatedProbability2Id: $mitigatedProbability2Id
      mitigatedRiskPriorityCodeId: $mitigatedRiskPriorityCodeId
      finalRiskAcceptability: $finalRiskAcceptability
      residualRiskAcceptability: $residualRiskAcceptability
      harmId: $harmId
      mitigatedHarmId: $mitigatedHarmId
      hazardId: $hazardId
      sequenceOfEvents: $sequenceOfEvents
      hazardousSituation: $hazardousSituation
      justification: $justification
      residualRiskJustification: $residualRiskJustification
      mitigatedHarmAsUnmitigated: $mitigatedHarmAsUnmitigated
    ) {
      riskVersion {
        id
        title
        harm {
          id
        }
        hazard {
          id
        }
        unmitigatedSeverity {
          id
          name
        }
        unmitigatedProbability {
          id
          name
        }
        unmitigatedProbability1 {
          id
          name
        }
        unmitigatedProbability2 {
          id
          name
        }
        unmitigatedRiskPriorityCode {
          id
          fullName
          name
        }
        mitigatedSeverity {
          id
          name
        }
        mitigatedProbability {
          id
          name
        }
        mitigatedProbability1 {
          id
          name
        }
        mitigatedProbability2 {
          id
          name
        }
        mitigatedRiskPriorityCode {
          id
          name
          fullName
        }
        finalRiskAcceptability
        hazardousSituation
        sequenceOfEvents
        justification
        residualRiskJustification
      }
    }
  }
`;

export const createItemLinkMutation = gql`
  mutation CreateRiskLink($input: CreateRiskControlLinkInput!) {
    createRiskControlLink(input: $input) {
      itemLinks {
        id
      }
      versionTraces {
        id
      }
    }
  }
`;

export const deleteLinkMutation = gql`
  mutation DeleteRiskLink($upstreamId: ID!, $downstreamId: ID!) {
    deleteRiskControlLink(input: { upstreamItemId: $upstreamId, downstreamItemId: $downstreamId }) {
      errors
      success
      versionTraceRemoved
    }
  }
`;

const updateRiskControlMutation = gql`
  mutation UpdateRiskControl($id: ID!, $introducesNewRisks: Boolean) {
    updateRiskControlVersion(id: $id, introducesNewRisks: $introducesNewRisks) {
      riskControlVersion {
        introducesNewRisks
        id
      }
    }
  }
`;

export const createRiskControlVersionConnectionMutation = gql`
  mutation createRiskControlConnection($riskVersionId: ID!, $riskControlVersionIds: [ID!]!, $trioType: String) {
    linkRiskControlVersionsToRiskVersion(
      input: { riskVersionId: $riskVersionId, riskControlVersionIds: $riskControlVersionIds, trioType: $trioType }
    ) {
      version {
        id
        versionIdentifier
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export const deleteRiskControlVersionConnectionMutation = gql`
  mutation deleteRiskControlConnection($riskVersionId: ID!, $riskControlVersionId: ID!) {
    unlinkRiskControlVersionFromRiskVersion(
      input: { riskVersionId: $riskVersionId, riskControlVersionId: $riskControlVersionId }
    ) {
      version {
        id
        versionIdentifier
        item {
          id
          customIdentifier
        }
      }
      setMemberRemoved
    }
  }
`;

export const changeRiskControlVersionMutation = gql`
  mutation changeRiskCtrlVersion(
    $riskVersionId: ID!
    $newRiskControlVersionId: ID!
    $oldRiskControlVersionId: ID!
    $trioType: String
  ) {
    changeRiskControlVersionInRiskVersion(
      input: {
        riskVersionId: $riskVersionId
        newRiskControlVersionId: $newRiskControlVersionId
        oldRiskControlVersionId: $oldRiskControlVersionId
        trioType: $trioType
      }
    ) {
      version {
        id
        versionIdentifier
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export const createNewRiskVersionLinkMutation = gql`
  mutation createNewRisksConnection($riskControlVersionId: ID!, $newRiskVersionIds: [ID!]!, $trioType: String) {
    linkNewRiskVersionsToRiskControlVersion(
      input: { riskControlVersionId: $riskControlVersionId, newRiskVersionIds: $newRiskVersionIds, trioType: $trioType }
    ) {
      version {
        id
        versionIdentifier
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export const deleteNewRiskVersionConnectionMutation = gql`
  mutation deleteNewRiskConnection($riskControlVersionId: ID!, $newRiskVersionId: ID!) {
    unlinkNewRiskVersionFromRiskControlVersion(
      input: { riskControlVersionId: $riskControlVersionId, newRiskVersionId: $newRiskVersionId }
    ) {
      version {
        id
        versionIdentifier
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export const changeNewRiskVersionMutation = gql`
  mutation changeNewRiskVersion(
    $riskControlVersionId: ID!
    $newRiskVersionId: ID!
    $oldRiskVersionId: ID!
    $trioType: String
  ) {
    changeNewRiskVersionInRiskControlVersion(
      input: {
        riskControlVersionId: $riskControlVersionId
        newRiskVersionId: $newRiskVersionId
        oldRiskVersionId: $oldRiskVersionId
        trioType: $trioType
      }
    ) {
      version {
        id
        versionIdentifier
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<RiskVersionQuery, RiskVersionQueryVariables>(versionQuery, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });
}

export function useUpdateVersion() {
  return useMutation(updateVersion, {
    refetchQueries: ['RiskVersionQuery'],
  });
}

export function useAddLinkItem() {
  return useMutation(createItemLinkMutation, {
    refetchQueries: ['RiskVersionQuery'],
  });
}

export function useDeleteItemLink() {
  return useMutation(deleteLinkMutation, {
    refetchQueries: ['RiskVersionQuery'],
  });
}

export function useUpdateRiskControl() {
  return useMutation(updateRiskControlMutation, {
    refetchQueries: ['RiskVersionQuery'],
  });
}

export function useAddRiskControlConnection() {
  return useMutation(createRiskControlVersionConnectionMutation, {
    refetchQueries: ['RiskVersionQuery'],
  });
}

export function useDeleteRiskControlConnection() {
  return useMutation(deleteRiskControlVersionConnectionMutation, {
    refetchQueries: ['RiskVersionQuery'],
  });
}

export function useChangeRiskControlVersion() {
  return useMutation(changeRiskControlVersionMutation, {
    refetchQueries: ['RiskVersionQuery'],
  });
}

export function useAddNewRiskVersion() {
  return useMutation(createNewRiskVersionLinkMutation, {
    refetchQueries: ['RiskVersionQuery'],
  });
}

export function useDeleteNewRiskVersion() {
  return useMutation(deleteNewRiskVersionConnectionMutation, {
    refetchQueries: ['RiskVersionQuery'],
  });
}

export function useChangeNewRiskVersion() {
  return useMutation(changeNewRiskVersionMutation, {
    refetchQueries: ['RiskVersionQuery'],
  });
}
