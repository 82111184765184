import { WorkflowTransition } from 'compositions/TimelineGroup/steps/types';
import { CourseWorkflowStatuses } from 'workflows/statuses';
import { CancelRetirement } from '../views';

export default {
  initialStep: 'cancelRetirement',
  internalSteps: {
    cancelRetirement: {
      component: CancelRetirement,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: (props) => ({
    toStatus: CourseWorkflowStatuses.RetirementCanceled.id,
    ...props.cancelRetirement.signature,
  }),
} as WorkflowTransition;
