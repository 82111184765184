import gql from 'graphql-tag';

export const CREATE_REFERENCED_ITEM = gql`
  mutation addValidationRecordsReferencedItems($validationRecordVersionId: ID!, $itemIds: [ID!]!) {
    addReferencedItemsToValidationRecordVersion(
      validationRecordVersionId: $validationRecordVersionId
      itemIds: $itemIds
    ) {
      validationRecordVersion {
        id
        deviationVersions {
          id
          item {
            id
            currentVersion {
              id
              currentStatus {
                id
                name
              }
              title
              versionIdentifier
            }
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
    }
  }
`;

export const CREATE_DEVIATION_AND_LINK = gql`
  mutation linkNewDeviationToValidationRecord($validationRecordVersionId: ID!) {
    linkNewDeviationToValidationRecord(validationRecordVersionId: $validationRecordVersionId) {
      validationRecordVersion {
        id
        deviationVersions {
          id
          item {
            id
            currentVersion {
              id
              currentStatus {
                id
                name
              }
              title
              versionIdentifier
            }
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
        }
      }
    }
  }
`;
