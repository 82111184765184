import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import get from 'lodash/get';

import { Notes } from '@material-ui/icons';
import { TextArea } from 'components';
import SettingsEditButton from '../SettingsEditButton';
import SectionTitle from 'components/SettingsSection/SectionTitle';

import { useCurrentCompany } from 'compositions/WithCurrentCompany';
import { UPDATE_CLIENT_CONFIG } from '../../gql';
import { useStyles } from './styles';
import { EditProps } from '../../types';

interface Props extends EditProps {}

const AssesmentConfirmation: FC<Props> = ({ name, editing, onEdit }) => {
  const classes = useStyles();
  const { data } = useCurrentCompany();
  const [updateClientConfig] = useMutation(UPDATE_CLIENT_CONFIG, {
    refetchQueries: ['withCurrentCompany_currentCompanyQuery'],
  });
  const [updateData, setUpdateData] = useState<string | null>(
    get(data, 'currentCompany.config.trainingManagement.independentStudyCertificationText'),
  );

  if (!data) return null;

  const independentStudyCertificationText =
    data.currentCompany.config.trainingManagement.independentStudyCertificationText;

  const onChange = (value: string) => setUpdateData(value);

  const handleSave = async () => {
    if (independentStudyCertificationText === updateData) {
      onEdit(null);
      return;
    }

    await updateClientConfig({
      variables: {
        input: {
          trainingManagement: {
            independentStudyCertificationText: updateData,
          },
        },
      },
    })
      .then(() => {
        onEdit(null);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  return (
    <div>
      <SectionTitle
        icon={<Notes />}
        title="Standard Read & Understand Assessment Confirmation"
        editButton={
          <SettingsEditButton
            isActive={editing}
            onClick={() => onEdit(name)}
            onActionButtonClick={handleSave}
            onCancel={() => onEdit(null)}
          />
        }
      />
      <TextArea
        key={`${independentStudyCertificationText}-${editing}`}
        initialValue={independentStudyCertificationText}
        rows={5}
        onTextChange={onChange}
        disabled={!editing}
        classes={{ textReason: classes.assesmentTextArea, textBoxLook: classes.assesmentTextAreaDisabled }}
      />
    </div>
  );
};

export default AssesmentConfirmation;
