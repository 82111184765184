import React from 'react';
import { Trans } from '@lingui/macro';

import { CertifiedSignature, RejectReason, YesOrNo } from 'compositions/TimelineGroup/steps/views';
import { DeviationWorkflowStatuses } from 'workflows/statuses';
import { buildActionMessage, buildReleaseMessage } from './deviationWorkflowTransitionsUtil';

export default {
  initialStep: 'closeOrReject',
  internalSteps: {
    closeOrReject: {
      component: (props) => (
        <YesOrNo
          prompt={buildActionMessage('close', props.currentVersion, props.itemCustomIdentifier)}
          yesLabel="Close"
          noLabel="Reject"
          {...props}
        />
      ),
      getNextStep: (release) => (release ? 'closeSignature' : 'rejectReason'),
    },
    rejectReason: {
      component: (props) => {
        const heading = `As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I reject ${
          props.itemCustomIdentifier
        }.`;
        return <RejectReason {...props} heading={heading} />;
      },
      getNextStep: (_) => 'complete',
    },
    closeSignature: {
      component: (props) => {
        const heading = <Trans>Close</Trans>;
        const dialog = buildReleaseMessage(props);
        return <CertifiedSignature dialog={dialog} heading={heading} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ closeOrReject, closeSignature, rejectReason }) => {
    if (closeOrReject) {
      return {
        toStatus: DeviationWorkflowStatuses.ClosedDeviation.id,
        ...closeSignature,
      };
    } else {
      return {
        toStatus: DeviationWorkflowStatuses.Rejected.id,
        ...rejectReason.signature,
        reasonForRejection: rejectReason.reason,
      };
    }
  },
};
