import React, { FC } from 'react';

import { Radio } from '@material-ui/core';

import { useStyles } from '../styles';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  checked: boolean;
}

const NotificationRadio: FC<Props> = ({ checked, onClick }) => {
  const classes = useStyles();

  return (
    <Radio
      checked={checked}
      color="primary"
      classes={{
        root: classes.radio,
        checked: classes.radioChecked,
      }}
      onClick={onClick}
    />
  );
};

export default NotificationRadio;
