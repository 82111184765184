import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { docStylePayloadFields } from '../../../itemTypes/mixins/DocumentBased/gql';
import { TRIO_FIELDS } from 'components/TableTrio/gql';

const versionQuery = gql`
  query SupplierAuditPlanVersionQuery($id: ID!) {
    supplierAuditPlanVersion(id: $id) {
      ${docStylePayloadFields},
      ${TRIO_FIELDS}
      supplierVersion {
        id
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
        }
      }
    },
  }
`;

export function useVersion(id: string) {
  return useQuery(versionQuery, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

export const SUPPLIER_OPTIONS_QUERY = gql`
  query SupplierOptsQuery {
    suppliers {
      id
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        plannedQualificationAudit
        id
        createdAt
        title
        versionIdentifier
        currentStatusName
        currentStatus {
          id
          name
        }
      }
    }
  }
`;

export const SET_SUPPLIER_ID = gql`
  mutation setSupplierId($id: ID!, $supplierId: ID) {
    updateSupplierAuditPlan(id: $id, supplierVersionId: $supplierId) {
      supplierAuditPlan {
        id
      }
    }
  }
`;
