import React from 'react';

import { AttachFile, Sort } from '@material-ui/icons';

import Row from './Row';
import { useStyles } from './styles';

interface Props {
  trainingMaterialAttachments: any[];
  courseVersionId: string;
  addingAttachment: boolean;
  onAdd: () => void;
  locked: boolean;
}

function Attachments(props: Props) {
  const classes = useStyles();

  return (props.trainingMaterialAttachments.length || props.addingAttachment) > 0 ? (
    <table className={classes.table}>
      <thead>
        <tr>
          <th align="left" className={classes.header}>
            <AttachFile fontSize="small" />
            Associated PDF
          </th>
          <th align="left" className={classes.header}>
            <Sort fontSize="small" />
            Description
          </th>
          <th align="left" className={classes.header}>
            <AttachFile fontSize="small" />
            Editable Document
          </th>
        </tr>
      </thead>
      <tbody>
        {props.trainingMaterialAttachments.map((attachment) => (
          <Row courseVersionId={props.courseVersionId} attachment={attachment} locked={props.locked} />
        ))}
        {props.addingAttachment && (
          <Row courseVersionId={props.courseVersionId} onAdd={props.onAdd} locked={props.locked} />
        )}
      </tbody>
    </table>
  ) : (
    <></>
  );
}

export default Attachments;
