import gql from 'graphql-tag';

export default gql`
  fragment userFields on User {
    id
    firstName
    lastName
    fullName
    assignedTasksCount
    notificationPreferences {
      id
      preferences
      preferenceType
    }
    incompleteNotificationsCount
    employee {
      id
      manager {
        id
        fullName
      }
      effectiveTrainingPlanVersion {
        id
        item {
          id
        }
      }
    }
    profile {
      id
      title
      firstName
      lastName
      emailAddress
      phoneNumber
      secondaryPhoneNumber
      photoUrl
      user {
        id
      }
    }
    username
    passwordLastUpdatedAt
    jobRoles {
      id
      roleName
      customRoleName
    }
    tier
    readOnly
  }
`;
