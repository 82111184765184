import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { ReactComponent as CheckListIcon } from './check-list.svg';

const CheckList = (props) => {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <CheckListIcon {...svgProps} />} />;
};

export default CheckList;
