import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  input: {
    width: '100%',
  },
  suggestionsContainer: {
    margin: '8px 0 12px 0',
    position: 'relative',
  },
  suggestionsContainerOpen: {
    width: '240px',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    'list-style-type': 'none',
  },
  suggestion: {
    ...theme.typography.body2,
    cursor: 'pointer',
    padding: '8px 14px',
  },
  suggestionHighlighted: {
    'background-color': theme.palette.common.tags.highlight,
  },
}));
