import React from 'react';
import { Trans } from '@lingui/macro';

import { CertifiedSignature } from '../views';
import { MiscellaneousRecordWorkflowStatuses } from 'workflows/statuses';

const ReadyForCompletionSignature = (props) => {
  const heading = <Trans>Record Completion</Trans>;
  const dialog = `As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I certify ${
    props.itemCustomIdentifier
  } is ready for completion.`;
  return <CertifiedSignature dialog={dialog} heading={heading} {...props} />;
};

export default {
  initialStep: 'recordReadyForCompletion',
  internalSteps: {
    recordReadyForCompletion: {
      component: ReadyForCompletionSignature,
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ recordReadyForCompletion }) => {
    return {
      ...recordReadyForCompletion,
      toStatus: MiscellaneousRecordWorkflowStatuses.Checked.id,
    };
  },
};
