import { WorkflowDefinition, WorkflowTransition } from '../types';

// Not clear if this is needed. It's triggered by a CO instead?
import ApproveOrReject from './ApproveOrReject';
import AssociatedChange from './AssociatedChange';
import Cancel from './Cancel';
import CreateNewVersion from './CreateNewVersion';
import OwnerApproval from './OwnerApproval';
import OwnerApprovalWithoutSig from './OwnerApprovalWithoutSig';
import ReasonForChange from './ReasonForChange';
import ReleaseOrReject from './ReleaseOrReject';
import ResolveRejection from './ResolveRejection';
import SummaryOfChange from './SummaryOfChange';
import UnderReview from './UnderReview';
import VoidOwnerApproval from './VoidOwnerApproval';

const TraceMatrixWorkflowSteps: WorkflowDefinition = {
  approveOrReject: ApproveOrReject as WorkflowTransition,
  associatedChange: AssociatedChange as WorkflowTransition,
  underReview: UnderReview as WorkflowTransition,
  cancel: Cancel as WorkflowTransition,
  createNewVersion: CreateNewVersion as WorkflowTransition,
  voidOwnerApproval: VoidOwnerApproval as WorkflowTransition,
  ownerApproval: OwnerApproval as WorkflowTransition,
  ownerApprovalWithoutSig: OwnerApprovalWithoutSig as WorkflowTransition,
  reasonForChange: ReasonForChange as WorkflowTransition,
  releaseOrReject: ReleaseOrReject as WorkflowTransition,
  resolveRejection: ResolveRejection as WorkflowTransition,
  summaryOfChange: SummaryOfChange as WorkflowTransition,
};

export default TraceMatrixWorkflowSteps;
