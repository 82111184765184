import { CustomColumn } from '../../core/Table/types';

export const defaultRecordViewColumns: CustomColumn<any>[] = [
  {
    cellType: 'id',
    title: 'ID',
    field: 'id',
  },
  {
    cellType: 'status',
    title: 'Status',
    align: 'center',
  },
  {
    cellType: 'date',
    title: 'INITIATION DATE',
  },
  {
    cellType: 'user',
    title: 'OWNER',
    field: 'owner',
  },
  {
    cellType: 'user',
    title: 'APPROVER',
    field: 'approver',
  },
];

export const defaultRecordViewColumnsWithNotes: CustomColumn<any>[] = [
  ...defaultRecordViewColumns,
  {
    cellType: 'text',
    title: 'EXECUTION NOTES',
    field: 'notes',
  },
];
