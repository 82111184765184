import React, { ReactNode } from 'react';
import get from 'lodash/get';

import { Select as MuiSelect, MenuItem } from '@material-ui/core';

import Input from '../Input';
import { Views } from 'workspaces';

import { useStyles } from './styles';

export interface SelectProps {
  options: { value: any; label?: any }[];
  defaultValue?: any;
  disabled?: boolean;
  onChange: (value: any) => void;
  renderValue?: (value: any) => ReactNode;
  viewType?: string;
  placeholder?: string;
}

function Select(props: SelectProps) {
  const classes = useStyles();

  const isReadOnly =
    get(props, 'viewType', '') === Views.Effective.id || get(props, 'viewType', '') === Views.Versions.id;

  return (
    <MuiSelect
      disabled={props.disabled}
      displayEmpty
      classes={{
        root: classes.root,
        icon: classes.dropdownIcon,
      }}
      defaultValue={props.defaultValue}
      input={<Input classes={{ root: isReadOnly ? classes.readOnly : undefined }} />}
      MenuProps={{
        classes: { paper: classes.menuDropdown },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      onChange={props.onChange}
      renderValue={props.renderValue}
      SelectDisplayProps={{ style: { height: '100%' } }}
    >
      {props.placeholder && (
        <MenuItem value="" disabled>
          {props.placeholder}
        </MenuItem>
      )}
      {props.options.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value} className={classes.menuOption}>
            {option.label || option.value}
          </MenuItem>
        );
      })}
    </MuiSelect>
  );
}

export default Select;
