import { ItemTypeDefinition } from '../index';
import { t } from '@lingui/macro';
import Launch from '@material-ui/icons/Launch';

import slugs from '../../displaySlugs';
import gql from 'graphql-tag';

import PayloadFields from './PayloadFields';
import { DocumentToolbar } from 'itemTypes/mixins/Document';

const itemType = 'designOutput';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query designOutputsQuery {
    designOutputs {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        description
        versionIdentifier
        currentStatusName
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Design Output`,
  categoryLabel: t`Design Output`,
  icon: Launch,
  listViewQuery,
  listViewColumns,
  PayloadFields,
  EffectiveViewOptions: DocumentToolbar,
} as ItemTypeDefinition;
