import React from 'react';
import flowRight from 'lodash/flowRight';
import { Trans } from '@lingui/macro';

import { Grid, createStyles, withStyles } from '@material-ui/core';
import LocalOffer from '@material-ui/icons/LocalOffer';

import { ContentSection, Spacer, TextAreaField, PDFData } from 'components';
import { DocumentBuilder } from 'compositions';
import { withWorkspaceViews } from './WithWorkspaceViews';

interface FormBasedPayloadFieldsProps {
  associatedRecordCustomIdPrefix: string;
  refetchQueries: [string];
  itemTypeName: string;
  activeWorkspaceView: { id: string };
  classes;
  versionData: {
    id: string;
    locked: boolean;
    inputMethod: string;
    body: string | null;
    tag: string | null;
    displayAsApplicationVersion?: string;
    attachment: {
      id: string;
      filename: string;
      url: string;
      extension: string;
    } | null;
    rawReferenceAttachment?: {
      id: string;
      filename: string;
      url: string;
    } | null;
  };
  onDocumentUploadComplete: () => any;
}

const FormBasedPayloadFields = ({
  classes,
  versionData,
  refetchQueries,
  itemTypeName,
  onDocumentUploadComplete,
  associatedRecordCustomIdPrefix,
  activeWorkspaceView,
}: FormBasedPayloadFieldsProps) => {
  if (versionData.inputMethod === 'body') {
    return (
      <>
        <Grid container xs={8}>
          <Grid item xs={8} className={classes.gridGutterRight}>
            <ContentSection
              fillHeight={false}
              LabelText={
                <>
                  <LocalOffer className={classes.icon} />
                  Tag for Associated {associatedRecordCustomIdPrefix}
                </>
              }
            >
              <Spacer factor={1} />
              <TextAreaField
                locked={versionData.locked}
                initialValue={versionData.tag}
                versionId={versionData.id}
                attrName={'tag'}
                rows={1}
                placeholder="Enter tag"
                refetchQueries={refetchQueries}
              />
            </ContentSection>
            <Spacer factor={1} />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <DocumentBuilder
            activeWorkspaceView={activeWorkspaceView}
            itemType={itemTypeName}
            layoutClasses={classes}
            versionData={versionData}
            richTextOptionSet={'large'}
            refetchQueries={['FormBasedBuilderViewQuery', 'timelineQuery']}
          />
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid container xs={8}>
        <Grid item xs={8} className={classes.gridGutterRight}>
          <ContentSection
            fillHeight={false}
            LabelText={
              <>
                <LocalOffer className={classes.icon} />
                <Trans>Tag for Associated</Trans>
                {` ${associatedRecordCustomIdPrefix}`}
              </>
            }
          >
            <Spacer factor={1} />
            <TextAreaField
              locked={versionData.locked}
              initialValue={versionData.tag}
              versionId={versionData.id}
              attrName={'tag'}
              rows={1}
              placeholder="Enter tag"
            />
          </ContentSection>
          <Spacer factor={1} />
        </Grid>
        <Grid item xs={12}>
          <PDFData versionData={versionData} layoutClasses={classes.grid} refetch={onDocumentUploadComplete} />
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) =>
  createStyles({
    ...theme.mixins.gridLayout,
    icon: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(1),
      color: theme.palette.blueDark,
    },
  });

export default flowRight([withWorkspaceViews(), withStyles(styles)])(FormBasedPayloadFields);
