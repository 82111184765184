import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    content: {
      width: 80,
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);
