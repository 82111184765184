import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import get from 'lodash/get';
import last from 'lodash/last';
import flowRight from 'lodash/flowRight';
import snakeCase from 'lodash/snakeCase';

import { Modal, Paper, Typography, withStyles, LinearProgress } from '@material-ui/core';

import Close from '@material-ui/icons/Close';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import { DateTime } from 'luxon';

import { Spacer, UserAvatar } from 'components';
import { SummaryOfChange } from '../TimelineGroup/steps/views';

import styles from './styles';
import query from './gql';
import Collaborator from './Collaborator';
import StatusSection from './StatusSection';
import Signatures from './Signatures';
import ParentSets from './ParentSets';
import HistoryTable from './HistoryTable';
import {
  effectiveViewQuery,
  effectiveViewQueryVariables,
  /*
    if multiple types implement a gql interface, apollo names
    the generated ts interfaces after the first type that implements it
  */
  // effectiveViewQuery_item_versions_RequirementVersion as SetMemberWorkflow,
} from './__generated__/effectiveViewQuery';
type SetMemberWorkflow = any; // typescript being annoying about it...

interface EffectiveViewDataProps {
  asOf: Date;
  blur: boolean;
  displayHistoryTable: boolean;
  classes;
  itemId: string;
  itemType: string;
  remoteItemTypeMetadata; // ItemTypeType from gql
}
function EffectiveViewData({ asOf, blur, displayHistoryTable, classes, itemType, itemId }: EffectiveViewDataProps) {
  const [{ modalOpen, collabModalOpen }, setModalState] = useState({
    modalOpen: false,
    collabModalOpen: false,
  });
  const closeModal = () => setModalState({ modalOpen: false, collabModalOpen: false });

  const { data, loading, error } = useQuery<effectiveViewQuery, effectiveViewQueryVariables>(query, {
    errorPolicy: 'all',
    variables: { itemId, itemType: snakeCase(itemType) },
    fetchPolicy: 'no-cache',
  });

  if (blur) return null;
  if (loading)
    return (
      <div style={{ width: '100%' }}>
        <LinearProgress
          variant="indeterminate"
          style={{
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
          }}
        />
      </div>
    );
  if (error) throw new Error(`Graphql Error: ${error}`);
  if (!data || !data.item) throw new Error(`Item not found: ${itemType}:${itemId}`);

  const itemData = data.item;
  const inScopeVersions = itemData.versions.filter((r) => {
    if (asOf !== undefined) {
      return r.retiredAt <= asOf || r.releasedAt <= asOf;
    } else {
      return r.releasedAt !== null;
    }
  });

  const currentRelease = last(inScopeVersions);
  if (!currentRelease) throw new Error(`No release found after ${asOf} for ${itemType}:${itemId}`);

  const versionIdentifier = itemType !== 'changeOrder' ? currentRelease.versionIdentifier : '';

  const owner = currentRelease.signingOwner || get(currentRelease, 'owner');
  const approver = currentRelease.signingApprover || get(currentRelease, 'approver');

  return (
    <>
      <StatusSection
        currentStatusName={currentRelease.currentStatusName}
        versionIdentifier={versionIdentifier}
        releasedAt={currentRelease.releasedAt}
        classes={classes}
        itemType={itemType}
        onChangeInfoClick={
          !modalOpen || !collabModalOpen || currentRelease.hasOwnProperty('reasonForChange')
            ? () =>
                setModalState({
                  modalOpen: true,
                  collabModalOpen: false,
                })
            : undefined
        }
        onTeamMembersClick={
          modalOpen || collabModalOpen
            ? undefined
            : () =>
                setModalState({
                  collabModalOpen: true,
                  modalOpen: false,
                })
        }
      />

      {(currentRelease as SetMemberWorkflow).releasedSetVersions ? (
        <ParentSets classes={classes} parentSets={(currentRelease as SetMemberWorkflow).releasedSetVersions} />
      ) : (
        <Signatures classes={classes} signatures={currentRelease.releaseSignatures} />
      )}

      {displayHistoryTable && <HistoryTable classes={classes} versions={inScopeVersions} />}

      <Modal open={collabModalOpen} onClose={closeModal}>
        <Paper className={classes.modalInside}>
          <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={closeModal} color="action" />

          <Spacer factor={1} />
          <Typography variant="h3">
            <Trans>{`Team members associated with ${itemData.customIdentifier}`}</Trans>
          </Typography>
          <Spacer factor={1} />

          <div className={classes.collaboratorRow}>
            <div style={{ flex: 1 }}>
              <UserAvatar userId={currentRelease.creator.id} />
            </div>
            <div style={{ flex: 4 }}>
              <Collaborator fullName={currentRelease.creator.fullName} title={currentRelease.creator.title} />
            </div>
            <div style={{ flex: 4 }} className={classes.versionCreatedInfo}>
              <Typography variant="subtitle2" className={classes.role}>
                <Trans>version creator</Trans>
              </Typography>
              <Typography variant="body2" style={{ fontSize: '0.525rem' }}>
                {DateTime.fromISO(currentRelease.createdAt).toFormat('MMM. d yyyy')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: '0.525rem',
                  float: 'right',
                  clear: 'both',
                }}
              >
                {DateTime.fromISO(currentRelease.createdAt).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}
              </Typography>
            </div>
          </div>

          {owner && (
            <div className={classes.collaboratorRow}>
              <div style={{ flex: 1 }}>
                <UserAvatar userId={owner.id} />
              </div>
              <div style={{ flex: 4 }}>
                <Collaborator fullName={owner.fullName} title={owner.title} />
              </div>
              <div style={{ flex: 4 }}>
                <Typography variant="subtitle2" className={classes.role} style={{ float: 'right' }}>
                  <Trans>owner</Trans>
                </Typography>
              </div>
            </div>
          )}

          {approver && (
            <div className={classes.collaboratorRow}>
              <div style={{ flex: 1 }}>
                <UserAvatar userId={approver.id} />
              </div>
              <div style={{ flex: 4 }}>
                <Collaborator fullName={approver.fullName} title={approver.title} />
              </div>
              <div style={{ flex: 4 }}>
                <Typography variant="subtitle2" className={classes.role} style={{ float: 'right' }}>
                  <Trans>approver</Trans>
                </Typography>
              </div>
            </div>
          )}
        </Paper>
      </Modal>
      <Modal open={modalOpen} onClose={closeModal}>
        <Paper className={classes.modalInside}>
          <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={closeModal} color="action" />

          <Spacer factor={1} />
          <SummaryOfChange currentVersion={currentRelease} displayChangeInfo />
        </Paper>
      </Modal>
    </>
  );
}

export { styles };

export default flowRight([withI18n(), withStyles(styles)])(EffectiveViewData);
