import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    internalPageSection: {
      paddingTop: theme.spacing(3),
    },
    internalPageSectionTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    internalPageSectionTitle: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      '& svg': {
        marginRight: theme.spacing(1.5),
      },
      width: '100%',
    },
    titleExpandIcon: {
      marginLeft: theme.spacing(1),
    },
    titleSecondaryActionButton: {
      color: theme.palette.secondary.main,
      width: 20,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    internalPageSectionContent: {
      paddingTop: theme.spacing(2.5),
      width: '100%',
    },
    miscAttachmentTableWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    miscAttachmentInputRow: {
      alignItems: 'center',
    },
    tableRowCheckbox: {
      padding: '12px 12px 12px 0',
      '& svg': {
        width: 15,
        height: 15,
      },
    },
    tableTitleRow: {
      display: 'flex',
    },
    tableTitleWrapper: {
      ...theme.typography.overline,
      marginRight: 10,
    },
    miscAttachmentInputWrapper: {
      '&:not(:last-child)': {
        marginRight: 4,
      },
    },
    addRowButton: {
      justifyContent: 'flex-start',
      padding: 0,
      paddingLeft: 27,
      paddingTop: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 0.7,
      },
    },
    addRowIcon: {
      width: 25,
    },
    imageGalleryCard: {
      width: 290,
      height: 371,
      padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px 0`,
    },
    imageContainer: {
      height: 210,
      position: 'relative',
      overflowY: 'scroll',
    },
    imageControlIcon: {
      color: theme.palette.blueDark,
      width: 20,
      height: 20,
      right: 12,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    fullscreenImageIcon: {
      position: 'absolute',
      top: 12,
    },
    downloadImageIcon: {
      position: 'absolute',
      bottom: 12,
    },
    imagePlaceholder: {
      width: '100%',
      height: '100%',
      backgroundColor: '#efefef',
    },
    imageMetaWrapper: {
      display: 'flex',
      marginTop: theme.spacing(1.5),
    },
    icon: {
      margin: 4,
    },
    imageGalleryText: {
      margin: 4,
    },
  });
