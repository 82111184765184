import React from 'react';
import { Trans } from '@lingui/macro';

export const ChangeOrderError = (
  <Trans>
    An error occurred. Please verify that you have entered your username and password correctly, that all impacted items
    are in Approved Draft or Approved Retirement state, and that all linked items are in Effective or Retired status.
  </Trans>
);

export const GenericError = (
  <Trans>An error occurred. Please verify you have entered your username and password correctly.</Trans>
);
