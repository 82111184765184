import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    autocompleteField: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.common.input.outline}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.common.input.outline}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    chipsContainer: {
      width: '100%',
      minHeight: 42,
      height: 'auto',
    },
    userChips: {
      color: 'rgba(0, 0, 0, 0.6)',
      height: 20,
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  }),
);
