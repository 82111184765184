import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import FlipToBack from '@material-ui/icons/FlipToBack';

import slugs from '../../displaySlugs';

import PayloadFields from './PayloadFields';

const itemType = 'testProtocolNote';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.testClass',
    headerName: 'Classification',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.testType',
    headerName: 'Type',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query testProtocolNotesQuery {
    testProtocolNotes {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        id
        createdAt
        title
        testClass
        testType
        currentStatusName
        versionIdentifier
        signingOwner {
          id
          firstName
          lastName
        }
        signingApprover {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const versionFields = `
  testClass
  testSummary
  testType
`;

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Test Protocol Note`,
  categoryLabel: t`Test Protocol Notes`,
  icon: FlipToBack,
  PayloadFields,
  versionFields,
  listViewColumns,
  listViewQuery,
};
