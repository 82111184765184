import React from 'react';

import DashboardPanel from '../DashboardPanel';
import { useStyles } from './styles';

interface Data {
  totalRisks: number;
  unassignedToRiskAnalysis: number;
  acceptable: number;
  unacceptable: number;
  undefined: number;
}

const RisksAcceptability: React.FC<{ data: Data }> = (props) => {
  const classes = useStyles();
  const { data } = props;

  const items = [
    { key: 'totalRisks', title: 'Total Risks', count: data.totalRisks },
    {
      key: 'unassignedToRiskAnalysis',
      title: 'Risk Unassigned to Risk Analysis',
      count: data.unassignedToRiskAnalysis,
    },
    { key: 'acceptable', title: 'Acceptable', count: data.acceptable },
    { key: 'unacceptable', title: 'Unacceptable', count: data.unacceptable },
    { key: 'undefined', title: 'Undefined', count: data.undefined },
  ];

  return (
    <div className={classes.cardWrapper}>
      {items.map((item) => (
        <DashboardPanel {...item} />
      ))}
    </div>
  );
};

export default RisksAcceptability;
