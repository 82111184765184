import gql from 'graphql-tag';
import { curry, get, head } from 'lodash';
import React from 'react';
import { Mutation } from 'react-apollo';

const buildMutationChild = curry(
  (
    WrappedComponent,
    componentProps,
    createAuthToken,
    { data, error, loading },
  ) => {
    const authToken = get(data, 'createAuthToken.authToken.token');
    const errorMessage = get(error, 'message', '');
    const attemptsSinceLastAuth = get(
      data,
      'createAuthToken.attemptsSinceLastAuth',
    );
    const passwordExpired = get(data, 'createAuthToken.passwordExpired');
    const unauthorized = !!(
      !authToken && head(errorMessage.match(/unauthorized/gi))
    );

    return (
      <WrappedComponent
        {...componentProps}
        authToken={authToken}
        createAuthToken={(variables) => createAuthToken({ variables })}
        createAuthTokenLoading={loading}
        attemptsSinceLastAuth={attemptsSinceLastAuth}
        passwordExpired={passwordExpired}
        unauthorized={unauthorized}
        errorMessage={errorMessage}
      />
    );
  },
);

// This mutation name must match the operation name excluded from the
// auth requirement in the GraphQL Controller.
export const CREATE_AUTH_TOKEN_MUTATION = gql`
  mutation createAuthToken($username: String!, $password: String!) {
    createAuthToken(input: { username: $username, password: $password }) {
      authToken {
        token
        payload {
          exp
        }
      }
      attemptsSinceLastAuth
      passwordExpired
    }
  }
`;

const withCreateAuthToken = (WrappedComponent) => (componentProps) => (
  <Mutation mutation={CREATE_AUTH_TOKEN_MUTATION}>
    {buildMutationChild(WrappedComponent, componentProps)}
  </Mutation>
);

export default withCreateAuthToken;
