import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';

import { DeviationVersionQuery } from './__generated__/DeviationVersionQuery';

const GET_DEVIATION_VERSION = gql`
  query DeviationVersionQuery($id: ID!) {
    deviationVersion(id: $id) {
      id
      deviationId
      title
      locked
      inputMethod
      associatedTestStep
      body
      deviationSummary
      deviationInfoToggle
      reasonForChange
      descriptionOfChange
      identification
      investigation
      conclusion
      correctiveOrPreventiveActions
      other
      parentRecord {
        id
        createdAt
        currentStatus {
          id
          name
        }
        title
        versionIdentifier
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      parentRecordDeviationsCount
      item {
        id
        customIdentifier
        workingVersion {
          id
          versionIdentifier
          currentStatus {
            id
          }
        }
      }
      associatedItemVersions {
        id
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      associatedItems {
        id
        customIdentifier
        itemType {
          id
          displaySlug
          userCreatable
        }
        workingVersion {
          id
          versionIdentifier
          currentStatus {
            id
          }
        }
      }
      trioReferencedItems {
        id
        customIdentifier
        workingVersion {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
        currentVersion {
          id
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
        itemType {
          id
          displaySlug
          userCreatable
        }
      }
      trioReferencedVersions {
        id
        versionIdentifier
        title
        currentStatus {
          id
          name
        }
        item {
          currentRelease {
            id
            versionIdentifier
            title
            currentStatus {
              name
              id
            }
          }
          workingVersion {
            id
            title
            versionIdentifier
            currentStatus {
              name
              id
            }
          }
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      trioReferencedAttachments {
        id
        versionId
        description
        url
      }
    }
  }
`;

export const UPDATE_DEVIATION_VERSION = gql`
  mutation UpdateDeviationVersion(
    $id: ID!
    $associatedTestStep: String
    $deviationSummary: String
    $deviationInfoToggle: Boolean
    $identification: String
    $investigation: String
    $conclusion: String
    $correctiveOrPreventiveActions: String
    $other: String
  ) {
    updateDeviationVersion(
      id: $id
      associatedTestStep: $associatedTestStep
      deviationSummary: $deviationSummary
      deviationInfoToggle: $deviationInfoToggle
      identification: $identification
      investigation: $investigation
      conclusion: $conclusion
      correctiveOrPreventiveActions: $correctiveOrPreventiveActions
      other: $other
    ) {
      deviationVersion {
        id
      }
    }
  }
`;

export const ADD_ASSOCIATED_TEST_RECORD = gql`
  mutation AddAssociatedTestRecord($id: ID!, $associatedTestRecordId: Float, $associatedTestStep: String) {
    updateDeviationVersion(
      id: $id
      associatedTestRecordId: $associatedTestRecordId
      associatedTestStep: $associatedTestStep
    ) {
      deviationVersion {
        id
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<DeviationVersionQuery>(GET_DEVIATION_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

export function useUpdateDeviationVersion() {
  return useMutation(UPDATE_DEVIATION_VERSION, {
    refetchQueries: ['DeviationVersionQuery'],
  });
}
