import { GenericWorkflowStatuses } from 'workflows/statuses';
import { RetirementStatus } from './RetirementStatus';

export default (itemData) => {
  let currentVersion =
    itemData.workingVersion || itemData.currentVersion.currentStatusName === RetirementStatus.RetirementRejected
      ? itemData.currentVersion
      : itemData.currentVersion.currentStatusName === RetirementStatus.Retired
      ? {
          currentStatusName: GenericWorkflowStatuses.Retired.id,
        }
      : {
          id: itemData.currentVersion.id,
          versionIdentifier: itemData.currentVersion.versionIdentifier,
          currentStatusName: GenericWorkflowStatuses.NotCreated.id,
        };
  const previousVersions = itemData.versions
    ? itemData.versions.filter(
        (r) =>
          r.releasedAt !== null || r.retiredAt !== null || r.currentStatusName === GenericWorkflowStatuses.Canceled.id,
      )
    : [];

  const { currentRelease } = itemData;

  return { currentRelease, currentVersion, previousVersions };
};
