import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: 0,
    },
    antSwitchLabel: {
      marginLeft: '12px',
      ...theme.typography.body2,
    },
  }),
);
