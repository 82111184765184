import { t } from '@lingui/macro';
import Pages from '@material-ui/icons/Pages';
import gql from 'graphql-tag';

import slugs from '../../displaySlugs';
import { docStylePayloadFields } from '../mixins/DocumentBased/gql';
import { QueryBasedPayloadFields } from '../mixins/DocumentBased/QueryBasedPayloadFields';

const itemType = 'qualityByDesignPlan';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const versionQuery = gql`
  query qualityByDesignPlanVersionQuery($id: ID!) {
    qualityByDesignPlanVersion(id: $id) {
      ${docStylePayloadFields}
    }
  }
`;
const PayloadFields = QueryBasedPayloadFields(versionQuery, itemType);

export default {
  displaySlug: slugs[itemType],
  itemType,
  icon: Pages,
  label: t`Quality By Design Plan`,
  categoryLabel: t`QbD Plan`,
  listViewQuery: gql`
    query qualityByDesignPlanListViewQuery {
      qualityByDesignPlans {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          body
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
      }
    }
  `,
  listViewColumns,
  PayloadFields,
};
