import {
  ColDef,
  ColumnRowGroupChangedEvent,
  GridReadyEvent,
  ProcessCellForExportParams,
  ProcessRowGroupForExportParams,
} from 'ag-grid-community';
import get from 'lodash/get';

const getValueByColType = (colValue: Object | string | null, colDef: ColDef) => {
  switch (colDef.type) {
    case 'version':
      if (typeof colValue === 'string') {
        return colValue.split('-')[0];
      } else {
        return get(colValue, 'currentVersion.versionIdentifier', '');
      }
    case 'user':
      if (typeof colValue === 'string') {
        return colValue;
      } else {
        return get(colValue, `${colDef.field}.fullName`, '');
      }
    default:
      return colValue;
  }
};

export const getExportParams = () => ({
  processCellCallback(params: ProcessCellForExportParams) {
    const value = params.value;
    const colDef = params.column.getColDef();
    return getValueByColType(value, colDef);
  },
  processRowGroupCallback(params: ProcessRowGroupForExportParams) {
    const { node } = params;

    if (!node.footer) {
      const rowGroupColumn = params.node.rowGroupColumn;
      if (rowGroupColumn) {
        return `row group: ${getValueByColType(node.key, rowGroupColumn.getColDef())}`;
      } else {
        return `row group: ${node.key}`;
      }
    }
    const isRootLevel = node.level === -1;

    if (isRootLevel) {
      return 'Grand Total';
    }
    return `Sub Total (${node.key})`;
  },
});

export const autoSizeColumnByKey = (event: ColumnRowGroupChangedEvent | GridReadyEvent, key: string) =>
  event.columnApi.autoSizeColumn(key);

export const autoSizeGroupColumn = (event: ColumnRowGroupChangedEvent | GridReadyEvent) =>
  autoSizeColumnByKey(event, 'ag-Grid-AutoColumn');
