import React from 'react';

import { Button, Typography } from '@material-ui/core';
import ReportProblem from '@material-ui/icons/Warning';

import BaseDialog from 'components/BaseDialog';

interface Props {
  open: boolean;
  onClose: () => void;
}

function ExpiredResetPasswordToken(props: Props) {
  const { open, onClose } = props;

  return (
    <BaseDialog
      open={open}
      title="Password Reset Link Has Expired"
      Icon={ReportProblem}
      size="default"
      onClose={onClose}
      textAlign="left"
      actions={
        <>
          <Button onClick={onClose} color="primary" variant="contained" fullWidth>
            Close
          </Button>
        </>
      }
    >
      <Typography variant="body2">
        Your password reset link has expired as it is valid for only 1 hour. Please request a new link using the{' '}
        <a href={'/forgot-password'} style={{ color: '#33BCB4', fontWeight: 600 }}>
          Forgot Password form.
        </a>
      </Typography>
    </BaseDialog>
  );
}

export default ExpiredResetPasswordToken;
