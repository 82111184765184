import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import { Grid, Typography, withStyles } from '@material-ui/core';

import { Spacer } from 'components';

import ComingSoonBackground from '../../assets/img/Computer.svg';
import styles from './styles';

class ComingSoon extends Component {
  static propTypes = {
    i18n: PropTypes.shape({
      _: PropTypes.func.isRequired,
    }).isRequired,
  };

  render() {
    const { classes, i18n } = this.props;

    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <div className={classes.container}>
            <img alt={i18n._(t`Coming Soon Background`)} src={ComingSoonBackground} className={classes.bgImage} />
            <div className={classes.text}>
              <Typography variant="h1">
                <Trans>Feature coming soon</Trans>
              </Typography>
              <Spacer factor={2} />
              <Typography variant="body1">
                <Trans>
                  We're still working on this feature.
                  <br />
                  Impatient? Drop us a line and tell us
                  <br />
                  what you'd like to see at{' '}
                  <a href="mailto:features@nemedio.com" target="_top" className={classes.emailLink}>
                    features@nemedio.com
                  </a>
                  .
                </Trans>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

ComingSoon.propTypes = {
  classes: PropTypes.shape({
    bgImage: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    emailLink: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default compose(
  withI18n(),
  withStyles(styles),
)(ComingSoon);
