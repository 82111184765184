import React from 'react';

import { useQuery } from 'react-apollo';
import TracesListPopover from 'components/TracesListPopover';

import { MEMBER_TRACING_QUERY } from './gql';

function MemberTracing(props) {
  const { itemId, ...rest } = props;
  const { data } = useQuery(MEMBER_TRACING_QUERY, { fetchPolicy: 'no-cache', variables: { itemId } });

  const traces = () => {
    return data
      ? data.item.downstreamItems.concat(data.item.upstreamItems).map((item) => ({
          customIdentifier: item.customIdentifier,
          title: item.currentVersion.virtualAttributes.map((attr) => attr.stringValue),
        }))
      : undefined;
  };

  return <TracesListPopover traces={traces()} {...rest} />;
}

export default MemberTracing;
