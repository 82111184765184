import { SvgIconProps } from '@material-ui/core/SvgIcon';

import { ComponentType } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { View } from 'workspaces';

import BiologicalEvaluationPlan from './BiologicalEvaluationPlan';
import CAPA from './CAPA';
import ChangeOrder from './ChangeOrder';
import CommonWeaknessAssessment from './CommonWeaknessAssessment';
import Complaint from './Complaint';
import CorporateQualityPlan from './CorporateQualityPlan';
import Course from './Course';
import Curriculum from './Curriculum';
import CyberattackAnalysis from './CyberattackAnalysis';
import CybersecurityPlan from './CybersecurityPlan';
import CybersecurityReport from './CybersecurityReport';
import CybersecuritySystemCharacterization from './CybersecuritySystemCharacterization';
import DDPlan from './DDPlan';
import DDReport from './DDReport';
import DeviceMasterRecord from './DeviceMasterRecord';
import Review from './Review';
import DesignRequirementSpecification from './DesignRequirementSpecification';
import DesignOutput from './DesignOutput';
import Deviation from './Deviation';
import DeviceDescription from './DeviceDescription';
import DeviceSafetyCharacteristicsReport from './DeviceSafetyCharacteristicsReport';
import DeviceUsageDescription from './DeviceUsageDescription';
import DeviceValidation from 'pages/DesignValidationProtocol';
import DeviceVerification from './DeviceVerification';
import DesignTraceMatrix from './DesignTraceMatrix';
import NqmsURSDoc from './NqmsURSDoc';
import NqmsSRSSet from './NqmsSRSSet';
import NqmsSRT from './NqmsSRT';
import NqmsDesignRequirementSpec from './NqmsDesignRequirementSpec';
import NqmsTraceMatrixDoc from './NqmsTraceMatrixDoc';
import NqmsValidationReport from './NqmsValidationReport';
import NqmsValidationPlan from './NqmsValidationPlan';
import NqmsValidationProtocol from './NqmsValidationProtocol';
import DVVProtocol from './DVVProtocol';
import FailureModesAndEffectsAnalysis from './FailureModesAndEffectsAnalysis';
import FinalDeviceInspection from './FinalDeviceInspection';
import FinalDeviceInspectionRecord from './FinalDeviceInspectionRecord';
import Form from './Form';
import FormRecord from './FormRecord';
import IncomingQualityInspection from './IncomingQualityInspection';
import IncomingQualityInspectionRecord from './IncomingQualityInspectionRecord';
import IndicationsForUse from './IndicationsForUse';
import InProcessInspection from './InProcessInspection';
import InProcessInspectionRecord from './InProcessInspectionRecord';
import JobDescription from './JobDescription';
import Labeling from './Labeling';
import RiskControl from './RiskControl';
import MiscDocument from './MiscDocument';
import MiscRecord from './MiscRecord';
import OrganizationalChart from './OrganizationalChart';
import Policy from './Policy';
import PrimaryModeOfAction from './PrimaryModeOfAction';
import Part from './Part';
import QualityByDesignPlan from './QualityByDesignPlan';
import QualityAgreement from './QualityAgreement';
import QualityManual from './QualityManual';
import QualityPolicy from './QualityPolicy';
import QualityPlan from './QualityPlan';
import QualitySystemValidationPlan from './QualitySystemValidationPlan';
import QualitySystemValidationProtocol from './QualitySystemValidationProtocol';
import QualitySystemValidationReport from './QualitySystemValidationReport';
import RegulatoryStrategyPlan from './RegulatoryStrategyPlan';
import Requirement from './Requirement';
import RequirementsSet from './RequirementsSet';
import RequirementsDocument from './RequirementsDocument';
import RiskAssessmentDocument from './RiskAssessmentDocument';
import RiskManagementPlan from './RiskManagementPlan';
import RiskManagementReport from './RiskManagementReport';
import RiskNote from './RiskNote';
import Risk from '../pages/Risk/index';
import RiskAnalysis from '../pages/RiskAnalysis/index';
import SoftwareBillOfMaterial from './SoftwareBillOfMaterial';
import SoftwareDevelopmentPlan from './SoftwareDevelopmentPlan';
import SoftwareSafetyClassification from './SoftwareSafetyClassification';
import StandardDocument from './StandardDocument';
import StandardOperatingProcedure from './StandardOperatingProcedure';
import SterilizationPlan from './SterilizationPlan';
import Supplier from './Supplier';
import SupplierAuditPlan from './SupplierAuditPlan';
import SupplierAuditReport from './SupplierAuditReport';
import SupplierQuestionnaire from './SupplierQuestionnaire';
import SupplierQuestionnaireRecord from './SupplierQuestionnaireRecord';
import SystemArchitecture from './SystemArchitecture';
import SystemRequirementSpecification from './SystemRequirementSpecification';
import SystemRequirementTable from './SystemRequirementTable';
import TargetProductProfile from './TargetProductProfile';
import Task from '../pages/Task';
import Template from './Template';
import TestProtocolNote from './TestProtocolNote';
import TrainingRecord from './TrainingRecord';
import TraceMatrix from './TraceMatrix';
import TrainingPlan from './TrainingPlan';
import UseEnvironmentSpecification from './UseEnvironmentSpecification';
import UserGroupSpecification from './UserGroupSpecification';
import PatientPopulationProfile from './PatientPopulationProfile';
import UserNeed from './UserNeed';
import UserNeedsSet from './UserNeedsSet';
import UserNeedsDocument from './UserNeedsDocument';
import UserRequirementSpecification from './UserRequirementSpecification';
import ValidationURSDocument from './ValidationURSDocument';
import ValidationRequirementsDocument from './ValidationRequirementsDocument';
import ValidationDRSDocument from './ValidationDRSDocument';
import ValidationProtocolDocument from './ValidationProtocolDocument';
import ValidationTraceMatrixDoc from './ValidationTraceMatrixDoc';
import ValidationRecord from './ValidationRecord';
import VerificationAndValidationPlan from './VerificationAndValidationPlan';
import VerificationAndValidationReport from 'pages/VerificationAndValidationReport';
import VerificationRecord from './VerificationRecord';
import VVReport from './VVReport';
import WorkInstruction from './WorkInstruction';

import { ItemTypeRoute } from 'setup/AppRouter';
import { BaseMutationOptions, RefetchQueriesFunction } from '@apollo/react-common/lib/types/types';
import { CreateFormRecordMutationVariables } from '../compositions/CreateFormRecord';
import { PureQueryOptions } from 'apollo-client';

export interface PayloadFieldsProps {
  itemType?: string;
  layoutClasses: { [className: string]: string };
  versionData?: any;
  versionId?: string;
  itemRecord?: object;
  i18n?: any;
  displayOnly?: boolean;
}
export interface AttachmentPayLoadFieldsProps extends PayloadFieldsProps {
  attachmentId: string;
  filename: string;
  secondaryFilename?: string;
  secondaryAttachmentId?: string;
  secondaryUrl?: string;
  refetchAttachments: () => void;
  creator;
  createdAt: string;
  url: string;
}

export type PayloadFieldsComponent = (props: PayloadFieldsProps) => JSX.Element | null;

export type ItemTypeDefinition = {
  itemType: string;
  label: MessageDescriptor;
  categoryLabel: MessageDescriptor;
  icon: ComponentType<SvgIconProps>;
  versionFields?: string; // optional interpolation into GQL query
  query?; // manually specify a gql query
  listViewQuery?; // gql for list page
  listViewColumns?;
  recordWorkspace?: View[];
  listWorkspace?: View[];
  displaySlug: string;
  linksGraphViewQueryVariables?: {
    itemType: string;
    parentItemType: string;
    parentItemTypeCollectionName?: string;
  };
  PayloadFields: PayloadFieldsComponent;
  recordItemType?: string;
  EffectiveViewOptions?: (props: {
    pdfExportHeading: string;
    pdfExportSubheading: string;
    versionId: string;
  }) => JSX.Element;
  createNewRecordOptions?: {
    createNewRecordMutation: (options?: BaseMutationOptions<any, CreateFormRecordMutationVariables>) => Promise<any>;
    refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
  };
  displayHistoryInEffectiveView?: boolean;
  titleEditable?: boolean;
  ListPage?: ItemTypeRoute;
  RecordPage?: ItemTypeRoute;
};

const ItemTypes = {
  BiologicalEvaluationPlan,
  CAPA,
  ChangeOrder,
  CorporateQualityPlan,
  CommonWeaknessAssessment,
  Complaint,
  Course,
  Curriculum,
  CyberattackAnalysis,
  CybersecurityPlan,
  CybersecurityReport,
  CybersecuritySystemCharacterization,
  DDPlan,
  DDReport,
  Deviation,
  DeviceMasterRecord,
  DesignRequirementSpecification,
  Review,
  DesignOutput,
  DesignTraceMatrix,
  NqmsURSDoc,
  NqmsSRSSet,
  NqmsSRT,
  NqmsDesignRequirementSpec,
  NqmsTraceMatrixDoc,
  NqmsValidationReport,
  NqmsValidationPlan,
  NqmsValidationProtocol,
  DeviceDescription,
  DeviceSafetyCharacteristicsReport,
  DeviceUsageDescription,
  DeviceValidation,
  DeviceVerification,
  DVVProtocol,
  FailureModesAndEffectsAnalysis,
  FinalDeviceInspection,
  FinalDeviceInspectionRecord,
  Form,
  FormRecord,
  IncomingQualityInspection,
  IncomingQualityInspectionRecord,
  IndicationsForUse,
  InProcessInspection,
  InProcessInspectionRecord,
  JobDescription,
  Labeling,
  RiskControl,
  MiscDocument,
  MiscRecord,
  OrganizationalChart,
  Policy,
  PrimaryModeOfAction,
  Part,
  PatientPopulationProfile,
  QualityAgreement,
  QualityByDesignPlan,
  QualityManual,
  QualityPlan,
  QualityPolicy,
  QualitySystemValidationPlan,
  QualitySystemValidationProtocol,
  QualitySystemValidationReport,
  RegulatoryStrategyPlan,
  Requirement,
  RequirementsSet,
  RequirementsDocument,
  Risk,
  RiskAnalysis,
  RiskAssessmentDocument,
  RiskManagementPlan,
  RiskManagementReport,
  RiskNote,
  SoftwareBillOfMaterial,
  SoftwareDevelopmentPlan,
  SoftwareSafetyClassification,
  StandardDocument,
  StandardOperatingProcedure,
  SterilizationPlan,
  Supplier,
  SupplierAuditPlan,
  SupplierAuditReport,
  SupplierQuestionnaire,
  SupplierQuestionnaireRecord,
  SystemArchitecture,
  SystemRequirementSpecification,
  SystemRequirementTable,
  TargetProductProfile,
  Task,
  Template,
  TestProtocolNote,
  TraceMatrix,
  TrainingPlan,
  TrainingRecord,
  UseEnvironmentSpecification,
  UserGroupSpecification,
  UserNeed,
  UserNeedsSet,
  UserNeedsDocument,
  UserRequirementSpecification,
  ValidationURSDocument,
  ValidationRequirementsDocument,
  ValidationDRSDocument,
  ValidationProtocolDocument,
  ValidationTraceMatrixDoc,
  ValidationRecord,
  VerificationAndValidationPlan,
  VerificationAndValidationReport,
  VerificationRecord,
  VVReport,
  WorkInstruction,
};

let ItemTypeIndex: { [k: string]: ItemTypeDefinition } = {};
for (const name in ItemTypes) {
  const metadata = ItemTypes[name];
  ItemTypeIndex[metadata.itemType] = metadata;
}

export { ItemTypeIndex };
export default ItemTypes;
