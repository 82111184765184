import React, { useState } from 'react';

import { Modal, Paper } from '@material-ui/core';

import LinearScale from '@material-ui/icons/LinearScale';
import { useQuery } from 'react-apollo';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import { AlertDialogWithUser } from 'components';
import Panel from 'components/core/Panel';
import WorkflowIcon from 'components/WorkflowIcon';
import WorkflowMenu, { CancelMenuItem, RetireMenuItem, VoidMenuItem } from 'components/WorkflowMenu';
import { SummaryOfChange } from 'compositions/TimelineGroup/steps/views';

import ConnectBar from '../../ConnectBar';
import { CourseWorkflowStatuses, indexById } from 'workflows/statuses';
import SmallCircle from '../../SmallCircle';
import PermissionFailModal from '../../PermissionFailModal';
import PreviousReleases from 'compositions/TimelineGroup/components/PreviousReleases';

import previousVersions from 'workflows/util/previousVersions';
import { useStyles } from 'compositions/TimelineGroup/components/sharedStyles';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

import ActiveTrainingPlansDialog from './ActiveTrainingPlansDialog';

import { TRAINING_PLANS_FOR_COURSE_QUERY } from './gql';

import rfcContent from 'workflows/GenericWorkflow/rfcContent';
const {
  Draft,
  NotCreated,
  UnderReview,
  OwnerApproval,
  ApprovedDraft,
  ReleasedForCoTraining,
  Rejected,
  Retired,
} = CourseWorkflowStatuses;
const CourseWorkflowStatusesById = indexById(CourseWorkflowStatuses);

function DefaultTimeline(props) {
  const [permissionFailModal, setPermissionFailModal] = useState(false);
  const [releaseNotPermittedModal, setReleaseNotPermittedModal] = useState(false);
  const [itemTransitioningLockedModal, setItemTransitioningLockedModal] = useState(false);
  const [activeTrainingPlanModal, setActiveTrainingPlanModal] = useState(false);

  const [previousVersionModal, setPreviousVersionModal] = useState(false);
  const [versionForModal, setVersionForModal] = useState({});
  const [deniedModalWithUser, setDeniedModalWithUser] = useState({ open: false, user: null, role: null });

  const classes = useStyles();

  const { currentCompanyAllowsTransitioning, handleInitTransition, itemData } = props;

  const { data, loading } = useQuery(TRAINING_PLANS_FOR_COURSE_QUERY, {
    variables: { id: itemData.id },
    fetchPolicy: 'no-cache',
  });

  if (loading) return null;

  const closeModal = () => {
    setPermissionFailModal(false);
    setPreviousVersionModal(false);
    setReleaseNotPermittedModal(false);
    setItemTransitioningLockedModal(false);
  };

  const { canOwn, canApprove } = props.permissions;

  const openModal = (modalName) => {
    switch (modalName) {
      case 'permissionFailModal':
        setPermissionFailModal(true);
        break;
      case 'releaseNotPermittedModal':
        setReleaseNotPermittedModal(true);
        break;
      case 'itemTransitioningLockedModal':
        setItemTransitioningLockedModal(true);
        break;
      default:
        break;
    }
  };

  const {
    approver,
    assignedApproverJobRole,
    assignedOwnerJobRole,
    currentStatus,
    linkedChangeCustomIdentifier,
    linkedChangeJustificationText,
    locked,
    mayTransitionToCanceled,
    mayTransitionToRetirementInitiated,
    mayTransitionToVoid,
    owner,
    releasedAt,
    versionIdentifier,
  } = itemData.currentVersion;

  let currentWorkflowStatus = CourseWorkflowStatusesById[currentStatus.name];
  if (!currentWorkflowStatus) throw new Error(`Invalid status for Course Workflow: ${currentStatus.name}`);

  if (releasedAt || currentStatus.name === 'canceled') currentWorkflowStatus = NotCreated;

  const hasChangeOrder = Boolean(linkedChangeCustomIdentifier) && !Boolean(linkedChangeJustificationText);

  const handleSmallBlueCircleClick = () => {
    if (currentWorkflowStatus.isEqualTo(Draft) || (currentWorkflowStatus.isEqualTo(UnderReview) && canOwn)) {
      return handleInitTransition('reasonForChange');
    } else return handleInitTransition('summaryOfChange');
  };

  const inactiveUsers = getInactiveUsers(owner, approver);
  const usersWithoutActiveRole = getUsersWithoutActiveRole(
    owner,
    approver,
    assignedOwnerJobRole,
    assignedApproverJobRole,
  );

  const handleInactiveUserDeniedModal = (inactiveUsers) => {
    setDeniedModalWithUser({
      open: true,
      user: inactiveUsers[0].user,
      role: inactiveUsers[0].role,
    });
  };

  const handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    setDeniedModalWithUser({
      open: true,
      user: usersWithoutActiveRole[0].user,
      role: usersWithoutActiveRole[0].role,
    });
  };

  return (
    <Panel>
      <Panel.Heading icon={LinearScale}>
        Workflow
        <WorkflowMenu>
          <CancelMenuItem
            disabled={!mayTransitionToCanceled}
            onClick={() => {
              props.handleInitTransition('canceled');
            }}
          />
          <VoidMenuItem
            disabled={!mayTransitionToVoid}
            onClick={() => {
              props.handleInitTransition('voidOwnerApproval');
            }}
          />
          <RetireMenuItem
            disabled={!mayTransitionToRetirementInitiated}
            onClick={() => {
              if (data.course.trainingPlanVersions.length > 0) {
                setActiveTrainingPlanModal(true);
              } else {
                props.handleInitTransition('initiateRetirement');
              }
            }}
          />
        </WorkflowMenu>
      </Panel.Heading>
      <div style={{ overflowX: 'scroll', paddingLeft: 10 }}>
        <PreviousReleases
          generateSmallCircleContent={rfcContent}
          releasedVersions={previousVersions(itemData.versions)}
          onClick={(v) => {
            setPreviousVersionModal(true);
            setVersionForModal(v);
          }}
        />

        {currentWorkflowStatus.isAfter(NotCreated) && !currentWorkflowStatus.isEqualTo(Retired) && (
          <SmallCircle
            content={rfcContent(itemData.currentVersion)}
            color="draft"
            handleClick={handleSmallBlueCircleClick}
          />
        )}

        {!itemData.workingVersion ? (
          <>
            <SmallCircle content={<Add />} color="draft" handleClick={() => handleInitTransition('createNewVersion')} />
          </>
        ) : (
          <>
            <ConnectBar color="draft" short visible />

            <WorkflowIcon
              type="draft"
              active={currentWorkflowStatus.isEqualTo(Draft)}
              completed={currentWorkflowStatus.isAfter(Draft)}
              version={versionIdentifier}
              label="Draft"
              size="large"
              visible
            />

            <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

            <SmallCircle
              id="co-small"
              content={hasChangeOrder ? 'CO' : linkedChangeJustificationText ? 'JST' : '?'}
              color="underReview"
              disabledEffect={!currentCompanyAllowsTransitioning}
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) return this.openModal('itemTransitioningLockedModal');

                if (!hasChangeOrder && !locked && !linkedChangeJustificationText)
                  return handleInitTransition('ownerApprovalWithoutSig');

                return handleInitTransition('associatedChange');
              }}
              visible
            />

            <ConnectBar color="underReview" disabled={!currentCompanyAllowsTransitioning} short visible />

            <WorkflowIcon
              type="underReview"
              active={currentWorkflowStatus.isEqualTo(UnderReview)}
              completed={currentWorkflowStatus.isAfter(UnderReview)}
              version={versionIdentifier}
              label="Under Review"
              size="large"
              disabledEffect={!currentCompanyAllowsTransitioning}
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) return openModal('itemTransitioningLockedModal');

                if (inactiveUsers.length) return handleInactiveUserDeniedModal(inactiveUsers);
                if (usersWithoutActiveRole.length)
                  return handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);

                if (currentWorkflowStatus.isBefore(UnderReview) && currentCompanyAllowsTransitioning)
                  return handleInitTransition('underReview');
              }}
              visible
            />

            <ConnectBar
              color="ownerApproval"
              disabled={!currentCompanyAllowsTransitioning || !canOwn}
              incomplete
              visible
            />

            <WorkflowIcon
              type="ownerApproval"
              active={currentWorkflowStatus.isEqualTo(OwnerApproval)}
              completed={currentWorkflowStatus.isAfter(OwnerApproval)}
              version={versionIdentifier}
              label="Owner Approval"
              size="large"
              disabledEffect={!currentCompanyAllowsTransitioning}
              disabled={!canOwn}
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) return openModal('itemTransitioningLockedModal');

                if (currentWorkflowStatus.isEqualTo(UnderReview) && canOwn)
                  return handleInitTransition('ownerApproval');

                if (currentWorkflowStatus.isEqualTo(UnderReview) && !canOwn) return openModal('permissionFailModal');

                return null;
              }}
              visible
            />

            <ConnectBar
              color="approvedDraft"
              disabled={!currentCompanyAllowsTransitioning || !canApprove}
              incomplete
              visible={
                !currentWorkflowStatus.isEqualTo(Rejected) &&
                (hasChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval))
              }
            />

            <WorkflowIcon
              type="approvedDraft"
              active={currentWorkflowStatus.isEqualTo(ApprovedDraft)}
              completed={currentWorkflowStatus.isAfter(ApprovedDraft)}
              version={versionIdentifier}
              label="Approved Draft"
              size="large"
              disabledEffect={!currentCompanyAllowsTransitioning}
              disabled={!canApprove}
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) return openModal('itemTransitioningLockedModal');
                if (currentWorkflowStatus.isEqualTo(OwnerApproval)) return handleInitTransition('approveOrReject');
              }}
              visible={
                !currentWorkflowStatus.isEqualTo(Rejected) &&
                (hasChangeOrder || currentWorkflowStatus.isBefore(OwnerApproval))
              }
            />

            <ConnectBar
              color="rejected"
              disabled={!currentCompanyAllowsTransitioning}
              visible={currentWorkflowStatus.isEqualTo(Rejected)}
            />

            <WorkflowIcon
              type="rejected"
              active
              version={versionIdentifier}
              label="Rejected"
              size="large"
              disabledEffect={!currentCompanyAllowsTransitioning}
              handleClick={() => {
                if (!currentCompanyAllowsTransitioning) return openModal('itemTransitioningLockedModal');

                return handleInitTransition('resolveRejection');
              }}
              visible={currentWorkflowStatus.isEqualTo(Rejected)}
            />

            <ConnectBar
              color="releasedForCoTraining"
              disabled={!currentCompanyAllowsTransitioning}
              visible={!currentWorkflowStatus.isEqualTo(Rejected) && hasChangeOrder}
            />

            <WorkflowIcon
              type="releasedForCoTraining"
              disabledEffect={!currentCompanyAllowsTransitioning}
              active={currentWorkflowStatus.isEqualTo(ReleasedForCoTraining)}
              completed={currentWorkflowStatus.isAfter(ReleasedForCoTraining)}
              version={versionIdentifier}
              label="Released for CO Training"
              size="large"
              handleClick={() => {
                if (currentWorkflowStatus.isEqualTo(ApprovedDraft)) return openModal('releaseNotPermittedModal');
                if (!currentCompanyAllowsTransitioning) return openModal('itemTransitioningLockedModal');
              }}
              visible={!currentWorkflowStatus.isEqualTo(Rejected) && hasChangeOrder}
            />

            <ConnectBar
              color="released"
              disabled={!currentCompanyAllowsTransitioning || !canApprove}
              visible={!currentWorkflowStatus.isEqualTo(Rejected)}
            />

            <WorkflowIcon
              type="released"
              disabled={!canApprove}
              disabledEffect={!currentCompanyAllowsTransitioning}
              version={versionIdentifier}
              label="Released"
              size="large"
              handleClick={() => {
                if (currentWorkflowStatus.isEqualTo(ReleasedForCoTraining))
                  return openModal('releaseNotPermittedModal');

                if (currentWorkflowStatus.isEqualTo(OwnerApproval)) {
                  return canApprove ? handleInitTransition('releaseOrReject') : openModal('permissionFailModal');
                }

                if (!currentCompanyAllowsTransitioning) return openModal('itemTransitioningLockedModal');
              }}
              visible={!currentWorkflowStatus.isEqualTo(Rejected)}
            />
          </>
        )}
        <PermissionFailModal
          open={permissionFailModal || releaseNotPermittedModal || itemTransitioningLockedModal}
          failType={permissionFailModal ? 'permissions' : releaseNotPermittedModal ? 'release' : 'itemTransitioning'}
          closeModal={closeModal}
        />

        <AlertDialogWithUser
          open={deniedModalWithUser.open}
          titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
          onClose={() => setDeniedModalWithUser({ open: false, user: null, role: null })}
          subtitle={
            inactiveUsers.length
              ? 'The following user has been deactivated.'
              : "The following user's role has been removed."
          }
          user={deniedModalWithUser.user}
          role={deniedModalWithUser.role === 'owner' ? assignedOwnerJobRole : assignedApproverJobRole}
          body={`Please use the People Menu to reassign the ${
            deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
          } role to a new user.`}
        />

        {activeTrainingPlanModal && (
          <ActiveTrainingPlansDialog
            courseId={itemData.customIdentifier}
            onClose={() => setActiveTrainingPlanModal(false)}
            trainingPlans={data.course.trainingPlanVersions}
          />
        )}

        <Modal open={previousVersionModal} onClose={closeModal} className={classes.infoModal}>
          <Paper className={classes.modalInside}>
            <Close className={classes.closeIcon} viewBox="-4 4 24 24" onClick={closeModal} color="action" />
            <SummaryOfChange currentVersion={versionForModal} displayChangeInfo={true} />
          </Paper>
        </Modal>
      </div>
    </Panel>
  );
}

export default DefaultTimeline;
