import gql from 'graphql-tag';

export const CREATE_REFERENCED_DOCUMENT = gql`
  mutation createValidationRecordAttachment(
    $versionId: ID!
    $attachmentURL: String!
    $isExecutedTestProtocolAttachment: Boolean
  ) {
    addReferencedAttachmentToValidationRecordVersion(
      versionId: $versionId
      attachment: $attachmentURL
      isExecutedTestProtocolAttachment: $isExecutedTestProtocolAttachment
    ) {
      validationRecordAttachment {
        id
        url
      }
    }
  }
`;

export const UPDATE_REFERENCED_DOCUMENT = gql`
  mutation updateValidationRecordAttachment($attachmentId: ID!, $attachmentURL: String, $description: String) {
    updateReferencedAttachmentFromValidationRecordVersion(
      id: $attachmentId
      attachment: $attachmentURL
      description: $description
    ) {
      validationRecordAttachment {
        id
        url
        description
      }
    }
  }
`;

export const DESTROY_REFERENCED_DOCUMENT = gql`
  mutation destroyValidationRecordAttachment($id: ID!) {
    removeReferencedAttachmentFromValidationRecordVersion(id: $id) {
      validationRecordAttachment {
        id
      }
    }
  }
`;
