import React, { useEffect, useRef, useState } from 'react';

import AgGridTable from 'components/AgGridTable';
import { MakeLink, VersionAndStatusDot } from 'components';
import VersionToggleField from 'components/VersionToggleField';
import { isColumnGroupedBy } from 'utils/agGridHelpers';
import SelectFolder from 'components/SelectFolder';
import UserNeedLongCard from './UserNeedLongCard';
import { workingVersionSelected } from 'components/VersionSwitch';
import { Views } from 'workspaces';
import { options } from '../UserNeed/picklist';
import { updateMember, switchVersion as switchUserNeedsVersion } from './gql';
import { GenericWorkflowStatuses } from 'workflows/statuses';
import displaySlugs from 'displaySlugs';
import {
  changeSortPositionsBy,
  filterFolders,
  SetChild,
  sortGroupedBy,
  sortSetItems,
  stakeholdersKeyCreator,
  undefinedKey,
  userNeedToSetChild,
} from './util';
import { cloneDeep, get, isEqual } from 'lodash';
import { combineItemGroups } from '../RequirementsSet/util';

interface TableContainerProps {
  data: any;
  DisplayVariantSelect: any;
  activeWorkspaceView: any;
  handleColumnRowGroupChanged: any;
  isCustomGrouping: boolean;
  isGroupedByStakeholder: boolean;
  isGroupedByFolders: boolean;
  isGroupedByStakeholderAndFolders: boolean;
  handleUpdateOrderingAndGrouping: any;
}

const rowDrag = function(params) {
  return !params.node.group;
};

const formatData = (data) => {
  let lastIndexAllItems = 1;
  let lastIndexStakeholder = 1;
  let lastIndexStakeholderAndFolders = 1;
  let lastIndexFolders = 1;

  const filteredData = data
    .filter((child) => child.__typename !== 'Folder')
    .map((member) => userNeedToSetChild(member));

  const finalData = filteredData.map((item) => {
    const newItem = cloneDeep(item);
    if (newItem.sortPositionsJson.allItems) {
      if (newItem.sortPositionsJson.allItems > lastIndexAllItems) {
        lastIndexAllItems = newItem.sortPositionsJson.allItems;
      }
    } else {
      newItem.sortPositionsJson.allItems = lastIndexAllItems;
      lastIndexAllItems++;
    }

    if (newItem.sortPositionsJson.stakeholder) {
      if (newItem.sortPositionsJson.stakeholder > lastIndexStakeholder) {
        lastIndexStakeholder = newItem.sortPositionsJson.stakeholder;
      }
    } else {
      newItem.sortPositionsJson.stakeholder = lastIndexStakeholder;
      lastIndexStakeholder++;
    }

    if (newItem.sortPositionsJson.stakeholderAndFolders) {
      if (newItem.sortPositionsJson.stakeholderAndFolders > lastIndexStakeholderAndFolders) {
        lastIndexStakeholderAndFolders = newItem.sortPositionsJson.stakeholderAndFolders;
      }
    } else {
      newItem.sortPositionsJson.stakeholderAndFolders = lastIndexStakeholderAndFolders;
      lastIndexStakeholderAndFolders++;
    }

    if (newItem.sortPositionsJson.folders) {
      if (newItem.sortPositionsJson.folders > lastIndexFolders) {
        lastIndexFolders = newItem.sortPositionsJson.folders;
      }
    } else {
      newItem.sortPositionsJson.folders = lastIndexFolders;
      lastIndexFolders++;
    }

    return newItem;
  });

  return finalData;
};

const listViewColumns = (
  activeWorkspaceView,
  data,
  isCustomGrouping,
  isGroupedByStakeholder,
  isGroupedByStakeholderAndFolders,
  isGroupedByFolders,
  getFoldersByIndentationLevel,
) => [
  {
    field: 'currentVersion.item.customIdentifier',
    headerName: 'ID',
    type: 'custom',
    blockRowLink: true,
    rowDrag: (params) => rowDrag(params) && !isCustomGrouping,
    render: (row) => {
      return (
        <MakeLink item={row.data.currentVersion.item} newTab={true}>
          {row.data.currentVersion.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    blockRowLink: true,
    type: 'custom',
    render: (row) => {
      if (activeWorkspaceView.id === Views.Effective.id || activeWorkspaceView.id === Views.Versions.id) {
        const isWorkingVersionSelected = workingVersionSelected(
          row.data.currentVersion.item.currentRelease,
          row.data.currentVersion.item.workingVersion,
          row.data.currentVersion.id,
        );

        return (
          <VersionAndStatusDot
            version={
              isWorkingVersionSelected
                ? row.data.currentVersion.item.workingVersion.versionIdentifier
                : row.data.currentVersion.item.currentRelease.versionIdentifier
            }
            status={
              isWorkingVersionSelected
                ? row.data.currentVersion.item.workingVersion.currentStatusName
                : row.data.currentVersion.item.currentRelease.currentStatusName
            }
          />
        );
      }

      return (
        <VersionToggleField
          setItemChild={row.data}
          refetchQueries={['UserNeedsSetVersionQuery']}
          switchVersionMutation={switchUserNeedsVersion}
          disabled={
            data.userNeedsSetVersion.locked === true ||
            (row.data.currentVersion.locked &&
              row.data.currentVersion.currentStatusName !== GenericWorkflowStatuses.Released.id)
          }
        />
      );
    },
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    blockRowLink: true,
    type: 'editable_text',
    editAttr: 'title',
    refetchQuery: 'UserNeedsSetVersionQuery',
    currentVersionPath: 'currentVersion',
  },
  {
    field: 'currentVersion.userNeedText',
    headerName: 'Description',
    blockRowLink: true,
    width: '400px',
    type: 'editable_text',
    editAttr: 'user_need_text',
    refetchQuery: 'UserNeedsSetVersionQuery',
    currentVersionPath: 'currentVersion',
  },
  {
    field: 'currentVersion.stakeholders',
    headerName: 'Stakeholders',
    blockRowLink: true,
    type: 'multi_options',
    editAttr: 'stakeholders',
    refetchQuery: 'UserNeedsSetVersionQuery',
    currentVersionPath: 'currentVersion',
    options: options,
    keyCreator: (params) => {
      return stakeholdersKeyCreator(params.data.currentVersion.stakeholders);
    },
    ...(!isCustomGrouping && {
      rowGroup: isGroupedByStakeholder || isGroupedByStakeholderAndFolders,
      hide: isGroupedByStakeholder || isGroupedByStakeholderAndFolders,
    }),
  },
  {
    field: 'folderOneSelect',
    headerName: 'Folder Level One',
    type: 'custom',
    width: '125px',
    enableRowGroup: true,
    ...(!isCustomGrouping && {
      rowGroup: isGroupedByFolders || isGroupedByStakeholderAndFolders,
      hide: isGroupedByFolders || isGroupedByStakeholderAndFolders,
    }),
    cellStyle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
    },
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.folderOne ? row.value.folderOne : '(undefined)';
    },
    render: (row) => {
      if (isColumnGroupedBy(row, 'folderOneSelect')) {
        return row.value;
      }
      return (
        <SelectFolder
          folders={getFoldersByIndentationLevel(1)}
          selectedFolderName={row.value.folderOne}
          level="folderOne"
          setMemberId={row.value.id}
          updateMemberMutation={updateMember}
          refetchQueries={['UserNeedsSetVersionQuery']}
          locked={get(row.value, 'currentVersion.locked')}
        />
      );
    },
  },
  {
    field: 'folderTwoSelect',
    headerName: 'Folder Level Two',
    type: 'custom',
    width: '125px',
    enableRowGroup: true,
    cellStyle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
    },
    ...(!isCustomGrouping && {
      rowGroup: isGroupedByFolders || isGroupedByStakeholderAndFolders,
      hide: isGroupedByFolders || isGroupedByStakeholderAndFolders,
    }),
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.folderTwo ? row.value.folderTwo : '(undefined)';
    },
    render: (row) => {
      if (isColumnGroupedBy(row, 'folderTwoSelect')) {
        return row.value;
      }
      return (
        <SelectFolder
          folders={getFoldersByIndentationLevel(2)}
          selectedFolderName={row.value.folderTwo}
          level="folderTwo"
          setMemberId={row.value.id}
          updateMemberMutation={updateMember}
          refetchQueries={['UserNeedsSetVersionQuery']}
          locked={get(row.value, 'currentVersion.locked')}
        />
      );
    },
  },
  {
    field: 'folderThreeSelect',
    headerName: 'Folder Level Three',
    type: 'custom',
    width: '125px',
    enableRowGroup: true,
    cellStyle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
    },
    ...(!isCustomGrouping && {
      hide: isGroupedByFolders || isGroupedByStakeholderAndFolders,
      rowGroup: isGroupedByFolders || isGroupedByStakeholderAndFolders,
    }),
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.folderThree ? row.value.folderThree : '(undefined)';
    },
    render: (row) => {
      if (isColumnGroupedBy(row, 'folderThreeSelect')) {
        return row.value;
      }
      return (
        <SelectFolder
          folders={getFoldersByIndentationLevel(3)}
          selectedFolderName={row.value.folderThree}
          level="folderThree"
          setMemberId={row.value.id}
          updateMemberMutation={updateMember}
          refetchQueries={['UserNeedsSetVersionQuery']}
          locked={get(row.value, 'currentVersion.locked')}
        />
      );
    },
  },
  {
    field: 'currentVersion.taskVersionLinkedTasks',
    headerName: 'Actions',
    blockRowLink: true,
    type: 'custom',
    width: '150px',
    valueGetter: (row) => {
      return row.data;
    },
    render: (row) => {
      const userNeedItem = row.value;
      return (
        <UserNeedLongCard
          memberId={userNeedItem.id}
          versionToggleEnabled={false}
          versionId={userNeedItem.currentVersion.id}
          versionIdentifier={userNeedItem.currentVersion.versionIdentifier}
          currentStatusName={userNeedItem.currentVersion.currentStatusName}
          title={userNeedItem.title}
          userNeedText={userNeedItem.currentVersion.userNeedText}
          stakeholders={userNeedItem.currentVersion.stakeholders}
          locked={userNeedItem.currentVersion.locked}
          workingVersion={userNeedItem.currentVersion.item.workingVersion}
          currentRelease={userNeedItem.currentVersion.item.currentRelease}
          itemId={userNeedItem.currentVersion.item.id}
          itemTags={userNeedItem.currentVersion.item.tags}
          parentSetItemSlug={displaySlugs['userNeed']}
          customIdentifier={userNeedItem.currentVersion.item.customIdentifier}
          displayOnly={false}
          commentCount={userNeedItem.currentVersion.item.comments.length}
        />
      );
    },
  },
];

const TableContainer: React.FC<TableContainerProps> = ({
  data,
  handleColumnRowGroupChanged,
  DisplayVariantSelect,
  activeWorkspaceView,
  isCustomGrouping,
  isGroupedByStakeholder,
  isGroupedByFolders,
  isGroupedByStakeholderAndFolders,
  handleUpdateOrderingAndGrouping,
}) => {
  const gridRef = useRef<any>();
  const [isCombinedGroups, setIsCombinedGroups] = useState(true);
  const folders = filterFolders(data);

  const getFoldersByIndentationLevel = (level: number) => {
    return folders
      ? folders
          .filter((folder) => folder.indentationLevel === level)
          .sort((a, b) => a.folderName.localeCompare(b.folderName))
      : null;
  };

  const [setMembers, setSetMembers] = useState<SetChild[]>([]);

  const handleData = () => {
    let sortedMembers: SetChild[] = [];

    if (isCombinedGroups && (isCustomGrouping || isGroupedByStakeholder || isGroupedByStakeholderAndFolders)) {
      sortedMembers = combineItemGroups(data.userNeedsSetVersion.setItemChildren, 'stakeholders');
    } else {
      sortedMembers = sortSetItems(
        formatData(data.userNeedsSetVersion.setItemChildren),
        isCustomGrouping,
        isGroupedByStakeholder,
        isGroupedByFolders,
        isGroupedByStakeholderAndFolders,
      );
    }
    const ids = sortedMembers.map(({ id }) => id);
    const uniqueMembers = sortedMembers.filter(({ id }, index) => !ids.includes(id, index + 1));

    setSetMembers(uniqueMembers);
    if (gridRef.current) {
      gridRef.current.api.setRowData(uniqueMembers);
    }
  };

  const setItemChildren = JSON.stringify(data.userNeedsSetVersion.setItemChildren.map(({ version }) => version));

  useEffect(() => {
    handleData();
  }, [
    data.userNeedsSetVersion.setItemChildren.length,
    setItemChildren,
    isCustomGrouping,
    isGroupedByStakeholder,
    isGroupedByFolders,
    isGroupedByStakeholderAndFolders,
    isCombinedGroups,
  ]);

  const handleGridReady = () => {
    handleData();
  };

  const handleRowDragEnd = () => {
    handleUpdateOrderingAndGrouping(setMembers);
  };

  const handleRowDragMove = (event) => {
    const movingNode = event.node;
    const overNode = event.overNode;

    const movingData = movingNode.data;
    if (isGroupedByStakeholder && !overNode.group) {
      const movingNodeGroup = stakeholdersKeyCreator(movingNode.data.currentVersion.stakeholders);
      const overNodeGroup = stakeholdersKeyCreator(overNode.data.currentVersion.stakeholders);
      if (movingNodeGroup !== overNodeGroup) {
        movingData.currentVersion.stakeholders = overNode.data.currentVersion.stakeholders;
        gridRef.current.api.applyTransaction({
          update: [movingData],
        });
      }
    }

    if (isGroupedByFolders && !overNode.group) {
      const overNodeGroup = `${overNode.data.folderOne || undefinedKey}, ${overNode.data.folderTwo ||
        undefinedKey}, ${overNode.data.folderThree || undefinedKey}`;
      const movingNodeGroup = `${movingNode.data.folderOne || undefinedKey}, ${movingNode.data.folderTwo ||
        undefinedKey}, ${movingNode.data.folderThree || undefinedKey}`;
      if (overNodeGroup !== movingNodeGroup) {
        const movingData = movingNode.data;
        movingData.folderOne = overNode.data.folderOne;
        movingData.folderTwo = overNode.data.folderTwo;
        movingData.folderThree = overNode.data.folderThree;
        gridRef.current.api.applyTransaction({
          update: [movingData],
        });
      }
    }

    if (isGroupedByStakeholderAndFolders && !overNode.group) {
      const overNodeGroup = `${overNode.data.folderOne || undefinedKey}, ${overNode.data.folderTwo ||
        undefinedKey}, ${overNode.data.folderThree || undefinedKey} - ${stakeholdersKeyCreator(
        overNode.data.currentVersion.stakeholders,
      )}`;
      const movingNodeGroup = `${movingNode.data.folderOne || undefinedKey}, ${movingNode.data.folderTwo ||
        undefinedKey}, ${movingNode.data.folderThree || undefinedKey} - ${stakeholdersKeyCreator(
        movingNode.data.currentVersion.stakeholders,
      )}`;
      if (overNodeGroup !== movingNodeGroup) {
        const movingData = movingNode.data;
        movingData.folderOne = overNode.data.folderOne;
        movingData.folderTwo = overNode.data.folderTwo;
        movingData.folderThree = overNode.data.folderThree;
        movingData.currentVersion.stakeholders = overNode.data.currentVersion.stakeholders;
        gridRef.current.api.applyTransaction({
          update: [movingData],
        });
      }
    }

    const rowNeedsToMove = movingNode && overNode && movingNode.data.id !== overNode.data.id && !overNode.group;
    if (rowNeedsToMove) {
      let reorderedSetMembers;
      let key;
      if (isGroupedByStakeholder) {
        key = 'stakeholder';
      }
      if (isGroupedByFolders) {
        key = 'folders';
      }
      if (isGroupedByStakeholderAndFolders) {
        key = 'stakeholderAndFolders';
      }
      if (!isGroupedByStakeholder && !isGroupedByFolders && !isGroupedByStakeholderAndFolders) {
        key = 'allItems';
      }
      reorderedSetMembers = sortGroupedBy(changeSortPositionsBy(setMembers, movingNode, overNode, key), key);
      if (!isEqual(setMembers, reorderedSetMembers)) {
        gridRef.current.api.setRowData(reorderedSetMembers);
        setSetMembers(reorderedSetMembers);
      }
    }
    gridRef.current.api.clearFocusedCell();
  };

  const onViewOptionChange = (checked: boolean) => {
    setIsCombinedGroups(!checked);
  };

  return (
    <AgGridTable
      columns={listViewColumns(
        activeWorkspaceView,
        data,
        isCustomGrouping,
        isGroupedByStakeholder,
        isGroupedByStakeholderAndFolders,
        isGroupedByFolders,
        getFoldersByIndentationLevel,
      )}
      data={setMembers}
      handleColumnRowGroupChanged={handleColumnRowGroupChanged}
      DisplayVariantSelect={DisplayVariantSelect}
      handleRowDragMove={handleRowDragMove}
      gridRef={gridRef}
      handleGridReady={handleGridReady}
      handleRowDragEnd={handleRowDragEnd}
      viewOptionChangeCallback={onViewOptionChange}
    />
  );
};

export default TableContainer;
