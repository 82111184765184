import { WorkflowDefinition, WorkflowTransition } from '../types';
import MiscellaneousRecordInProgress from './MiscellaneousRecordInProgress';
import MiscellaneousRecordChecked from './MiscellaneousRecordChecked';
import RecordCancel from '../Record/RecordCancel';
import RecordVoid from '../Record/RecordVoid';

const MiscellaneousRecordWorkflowSteps: WorkflowDefinition = {
  recordInProgress: MiscellaneousRecordInProgress as WorkflowTransition,
  recordChecked: MiscellaneousRecordChecked as WorkflowTransition,
  recordCanceled: RecordCancel as WorkflowTransition,
  recordVoided: RecordVoid as WorkflowTransition,
};

export default MiscellaneousRecordWorkflowSteps;
