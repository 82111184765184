import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_theme) => ({
  deviationsLink: {
    marginRight: '5px',
    whiteSpace: 'nowrap',
    '&:not(:last-child)': {
      '&:after': {
        content: '","',
        right: 0,
      },
    }
  }
}));
