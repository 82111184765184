import get from 'lodash/get';
import React from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Trans } from '@lingui/macro';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Check from '@material-ui/icons/Check';
import Flag from '@material-ui/icons/Flag';

import {
  Breadcrumbs,
  Blur,
  ContentSection,
  ItemTitle,
  Spacer,
  ErrorBoundary,
  Loading,
  NoReleaseModal,
} from 'components';
import { ContextDrawer, ItemControls, TimelineGroup } from 'compositions';
import { GenericWorkflowStatuses } from 'workflows/statuses';

import styles from './styles';
import { builderViewQuery } from './gql';
import { getAuthToken } from 'utils';
import { UPDATE_DOCUMENT_VERSION_INPUT_METHOD } from 'components/BuilderView/gql';
import AttachmentClient from 'utils/AttachmentClient';

import PayloadFields from './PayloadFields';
import { FeatureUnavailable } from 'pages';

const { Released } = GenericWorkflowStatuses;

function noCurrentVersion(itemRecord) {
  const statusName = get(itemRecord, 'currentVersion.currentStatusName');
  return statusName === Released.id;
}

function BuilderView({ classes, itemId }) {
  const [updateDocumentVersionInputMethod] = useMutation(UPDATE_DOCUMENT_VERSION_INPUT_METHOD);

  const matchesPrint = useMediaQuery('print');
  const { data, loading, error } = useQuery(builderViewQuery, {
    variables: { itemId },
  });

  if (matchesPrint) return null;
  if (loading || !data) return <Loading />;
  if (error) throw new Error(`Error loading Form BuilderView\n${error}`);
  const itemRecord = data.item;

  const readOnly = get(itemRecord, 'currentUser.readOnly', true);
  if (readOnly) return <FeatureUnavailable />;

  const currentVersion = itemRecord.currentVersion;
  const itemTitle = get(itemRecord, 'currentVersion.title', '');
  const titlePrefix = `${get(itemRecord, 'customIdentifier', '')}`;

  /*
    version 3 of Material UI has a defect which forces scroll to be disabled if
    any modal is active.  V4 includes a "disableScrollLock" prop for this:
    https://material-ui.com/api/modal/#props
  */
  if (noCurrentVersion(itemRecord)) window.scroll(0, 0);

  return (
    <Grid container data-testid="item" spacing={3} className={classes.container}>
      <Grid item xs={8} className={classes.grid}>
        <Breadcrumbs categorySlug={'forms'} itemTitle={`${titlePrefix}: ${itemTitle}`} />
        <Grid container item xs={12} justify="space-between">
          <Blur blur={noCurrentVersion(itemRecord)} container={false}>
            <ItemTitle
              editable={!get(currentVersion, 'locked')}
              itemTitle={itemTitle}
              titlePrefix={titlePrefix}
              versionId={get(currentVersion, 'id')}
              refetchQueries={['FormBuilderViewQuery']}
            />
          </Blur>
          <ItemControls
            showPDFExport
            pdfExportHeader={`${itemRecord.customIdentifier}: ${itemRecord.currentVersion.title}`}
            peopleProps={{
              approver: currentVersion.approver,
              createdAt: itemRecord.currentVersion.createdAt,
              creator: itemRecord.currentVersion.creator,
              locked: currentVersion.locked,
              owner: currentVersion.owner,
              permissibleApprovers: currentVersion.permissibleApprovers,
              permissibleOwners: currentVersion.permissibleOwners,
              versionId: currentVersion.id,
              version: currentVersion,
              refetchQueries: ['FormBuilderViewQuery'],
            }}
            tagsProps={{
              itemId: itemRecord.id,
              tags: itemRecord.tags,
              refetchQueries: ['FormBuilderViewQuery', `formsQuery`, 'tagsQuery'],
            }}
            ellipsesProps={{
              attachment: get(itemRecord, 'currentVersion.attachment.filename'),
              inputMethod: (get(itemRecord, 'currentVersion.inputMethod') || '').toUpperCase(),
              referenceId: itemRecord.referenceId,
              itemId: itemRecord.id,
              versionId: itemRecord.currentVersion.id,
              locked: currentVersion.locked,
              onInputMethodChange: (inputMethod) => {
                updateDocumentVersionInputMethod({
                  variables: {
                    versionId: itemRecord.currentVersion.id,
                    inputMethod: inputMethod,
                  },
                  refetchQueries: ['FormBuilderViewQuery', 'FormVersion'],
                }).catch((err) => {
                  throw new Error(`Error updating input method: ${err.message}`);
                });
              },
              onPDFDelete: () => {
                const attachmentId = get(itemRecord, 'currentVersion.attachment.id');
                if (!attachmentId) return;

                const [token] = getAuthToken();
                new AttachmentClient(token).delete(itemRecord.currentVersion.id, attachmentId).catch((error) => {
                  throw new Error(`Deletion of attachment failed: ${error.message}`);
                });
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container item xs={8} className={classes.grid}>
        <Grid item xs={8} className={classes.gridGutterRight}>
          <ErrorBoundary>
            <TimelineGroup
              key={itemRecord.currentVersion.currentStatusName}
              itemType={'form'}
              itemId={itemId}
              queriesToRefetch={['FormBuilderViewQuery']}
              itemData={itemRecord}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={4} className={classes.gridGutterLeft}>
          <ContentSection LabelText={<Trans>Item Notes</Trans>} Icon={<Flag className={classes.icon} />}>
            <Spacer factor={1} />
            <div className={classes.itemNotes}>
              <Spacer factor={3} />
              <Typography variant="body2" className={classes.iconTextStack}>
                <Check className={classes.icon} />
                <Trans>No new updates</Trans>
              </Typography>
            </div>
          </ContentSection>
        </Grid>
      </Grid>

      <Blur blur={noCurrentVersion(itemRecord)}>
        <PayloadFields layoutClasses={classes} itemType={'form'} versionId={itemRecord.currentVersion.id} />
      </Blur>

      <NoReleaseModal
        open={noCurrentVersion(itemRecord)}
        modalTopPosition="50%"
        viewName="Builder View"
        message={
          <Trans>
            This item has no in-progress version. Select Effective View from the view menu for the current effective
            view of this item.
          </Trans>
        }
        showLink={false}
      />
      <ContextDrawer itemId={itemId} itemType={'form'} />
    </Grid>
  );
}

export default withStyles(styles)(BuilderView);
