import { LOCALSTORAGE_WORKSPACE_VIEWS_KEY } from '../constants';

import { workspaceViewsStoreSelector } from './selectors';

function addSubscriber(store, storage = window.memoryDB) {
  function onStateChange() {
    if (storage && storage.setItem) {
      const workspaceViewsState = workspaceViewsStoreSelector(store.getState());
      const workspaceViewsStateAsString = JSON.stringify(workspaceViewsState);
      storage.setItem(
        LOCALSTORAGE_WORKSPACE_VIEWS_KEY,
        workspaceViewsStateAsString,
      );
    }
  }

  return store.subscribe(onStateChange);
}

export default addSubscriber;
