import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  inputRoot: {
    paddingRight: theme.spacing(1),
    '&[class*="MuiOutlinedInput-root"]': {
      paddingRight: '6px !important',
    },
  },
  chipRoot: {
    margin: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(0,0,0,.6)',
    textAlign: 'center',
  },
  chipLabel: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'clip',
  },
}));
