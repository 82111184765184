import React from 'react';
import get from 'lodash/get';

import { useTheme } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Search from '@material-ui/icons/Search';
import MaterialTable, { Column, Action, Components, Localization } from 'material-table';

import WorkflowIcon from 'components/WorkflowIcon';
import { itemLink } from 'components/MakeLink';
import { AvatarCell } from 'components/TableView/Fields/Avatar';
import { BooleanResultCell } from 'components/TableView/Fields/BooleanResult';
import { DateCell } from 'components/TableView/Fields/Date';

import { useStyles } from './styles';
import { CellType } from './types';

interface Props {
  actions?: Action<any>[];
  columns: Column<any>[];
  data: any[];
  onRowClick?: (e: any, data: any) => void;
  sortable?: boolean;
  searchable?: boolean;
  components?: Components;
  localization?: Localization;
  doNotMessWithContainer?: boolean;
  // by default that causes remounts on update...
  // so when this is used styles should be applied from the outside
  // alternatively looking into that ref-passing mechanism may work too
  // (second param when passing own components)
  emptyDataSourceMessage?: string;
  useGenericColumns?: boolean;
}

const getColumns = (columns: Column<any>[]) => {
  function handleColumn(col: Column<any>[] | Column<any>) {
    if (col instanceof Array) {
      return col.map((element) => {
        return handleColumn(element);
      });
    } else if (col instanceof Object) {
      const objectHash: Column<any> = {};
      const objectKeys: string[] = [];
      for (const keyz in col) {
        objectKeys.push(keyz);
      }
      objectKeys.map((key) => {
        objectHash[key] = handleColumn(col[key]);
        if (key === 'cellType' && col[key]) {
          switch (col[key] as CellType) {
            case 'text':
              objectHash['render'] = (row: any) => <span>{get(row, col.field!, '')}</span>;
              break;
            case 'id':
              objectHash['render'] = (row: any) => (
                <a href={itemLink(row.item)} target="_blank" rel="noopener noreferrer">
                  {row.item.customIdentifier}
                </a>
              );
              break;
            case 'status':
              objectHash['render'] = (row: any) => (
                <WorkflowIcon type={row.currentStatus.name} completed size="small" displayTooltip />
              );
              break;
            case 'date':
              objectHash['render'] = (row: any) => <DateCell data={row.createdAt} />;
              break;
            case 'user':
              objectHash['render'] = (row: any) => <AvatarCell data={get(row, col.field!)} />;
              break;
            case 'boolean':
              objectHash['render'] = (row: any) => <BooleanResultCell data={get(row, col.field!)} />;
              break;
            case 'custom':
              objectHash['render'] = (row) => {
                const render = col['editComponent'];
                return render ? render(row) : null;
              };
              break;
          }
        }
        return '';
      });
      return objectHash;
    } else {
      return col;
    }
  }

  return handleColumn(columns);
};

function Table(props: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const overwriteCellStyles = (cell: Column<any>): Column<any> => ({
    ...cell,
    cellStyle: {
      ...cell.cellStyle,
      ...theme.typography.body2,
    },
  });

  const sort: any = (props) => <ArrowDropUpIcon {...props} />;
  const search: any = (props) => <Search {...props} />;

  return (
    <MaterialTable
      actions={props.actions}
      options={{
        paging: false,
        search: props.searchable || false,
        draggable: false,
        showTitle: false,
        sorting: props.sortable || false,
        actionsColumnIndex: -1,
      }}
      onRowClick={props.onRowClick}
      localization={{
        header: {
          actions: '',
        },
        body: {
          emptyDataSourceMessage: props.emptyDataSourceMessage || 'No records to display',
        },
        ...props.localization,
      }}
      icons={{
        Search: search,
        SortArrow: sort,
      }}
      data={props.data}
      columns={(props.useGenericColumns ? getColumns(props.columns) : props.columns).map(overwriteCellStyles)}
      components={{
        Toolbar: () => <></>,
        ...(props.doNotMessWithContainer
          ? {}
          : {
              Container: (props) => <div {...props} className={classes.table} />,
            }),
        ...props.components,
      }}
    />
  );
}

export default Table;
