import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Trans } from '@lingui/macro';

import { Spacer } from 'components';
import styles from './styles';

function ChangeOrderRequired({ classes, handleComplete }) {
  return (
    <>
      <div className={classes.heading}>
        <Typography variant="h3">
          <Trans>Change Order</Trans>
        </Typography>
        <Spacer factor={1} />
        <Typography variant="body2">
          <Trans>Does this change require a Change Order?</Trans>
        </Typography>
      </div>

      <Spacer factor={3} />

      <div style={{ display: 'flex' }}>
        <Button
          id="co-no"
          style={{ flex: 1, marginRight: '5px' }}
          onClick={() => handleComplete(false)}
          color="primary"
          variant="outlined"
        >
          <Trans>No</Trans>
        </Button>
        <Button
          id="co-yes"
          style={{ flex: 1, marginLeft: '5px' }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleComplete(true)}
        >
          <Trans>Yes</Trans>
        </Button>
      </div>
    </>
  );
}
export default withStyles(styles)(ChangeOrderRequired);
