import React, { useState } from 'react';

import { Paper, Chip, Typography, Tooltip, FormControl } from '@material-ui/core';
import { Create, InfoOutlined } from '@material-ui/icons';
import upperFirst from 'lodash/upperFirst';
import { useMutation } from 'react-apollo';

import { Type as EmployeeType, UpdateEmployeeInput } from '__generated__/globalTypes';
import { IconButton, Spacer, SelectUserField } from 'components';
import EditableText from 'components/core/form/EditableText';
import Select from 'components/core/form/Select';

import FormLabel from './FormLabel';
import { UPDATE_EMPLOYEE_MUTATION } from './gql';
import { useStyles } from './styles';

interface Props {
  editable: boolean;
  profile: any;
}

function EmployeeInformation(props: Props) {
  const { profile } = props;
  const { user } = profile;
  const { employee } = user;

  const classes = useStyles();
  const [editing, setInEditingMode] = useState(false);
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE_MUTATION, { refetchQueries: ['ProfileQuery'] });

  const onChange = (updates: UpdateEmployeeInput) => updateEmployee({ variables: { input: updates } });

  return (
    <Paper elevation={1} className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <Typography variant="h3">User Information</Typography>
          {props.editable && <IconButton Icon={Create} onClick={() => setInEditingMode(!editing)} />}
        </div>
        <Tooltip title="Name, Role, License Type and Status are set during onboarding. To change this information, please contact your system administrator.">
          <InfoOutlined fontSize="small" className={classes.infoIcon} />
        </Tooltip>
      </div>
      <Typography variant="caption">Employee ID: {user.employee.id}</Typography>
      <br />
      <Typography variant="caption">Nemedio ID: {user.customIdentifier}</Typography>
      <Spacer factor={4} />
      <FormLabel label="Full Name">
        <Typography variant="body2" className={classes.fullName}>
          <span>{profile.firstName}</span>
          <span>{profile.lastName}</span>
        </Typography>
      </FormLabel>
      <FormLabel label="Active Roles">
        <div className={classes.pills}>
          {user.jobRoles.map((jobRole) => (
            <Chip size="small" key={jobRole.id} label={jobRole.formattedRoleName} />
          ))}
        </div>
      </FormLabel>
      <FormLabel label="License Type">
        <Typography variant="body2">N/A</Typography>
      </FormLabel>
      <FormLabel label="User Status">
        <Typography className={classes.status} variant="body2">
          {user.status}
        </Typography>
      </FormLabel>
      <FormLabel label="Start Date">
        <EditableText
          editing={editing}
          value={employee.startDate}
          onChange={(e) => onChange({ id: employee.id, startDate: e.target.value })}
          type="date"
        />
      </FormLabel>
      <FormLabel label="Manager">
        <SelectUserField
          inEditingMode={editing}
          placeholder="Select manager"
          currentSelectedUser={employee.manager}
          userProfile={profile}
          ignoreInactive
          ignoreSelf
          onSelectedUserInputData={(id) => onChange({ id: employee.id, managerId: id })}
        />
      </FormLabel>
      <FormLabel label="Department">
        <EditableText
          editing={editing}
          value={employee.department}
          onChange={(e) => onChange({ id: employee.id, department: e.target.value })}
        />
      </FormLabel>
      <FormLabel label="User ID">
        <EditableText
          editing={editing}
          value={employee.customEmployeeId}
          onChange={(e) => onChange({ id: employee.id, customEmployeeId: e.target.value })}
        />
      </FormLabel>
      <FormLabel label="User Type">
        {editing ? (
          <FormControl fullWidth>
            <Select
              defaultValue={employee.type || ''}
              options={Object.values(EmployeeType).map((v) => ({ value: v, label: upperFirst(v) }))}
              onChange={(e) => onChange({ id: employee.id, type: e.target.value })}
            />
          </FormControl>
        ) : (
          <Typography variant="body2">{upperFirst(employee.type)}</Typography>
        )}
      </FormLabel>
    </Paper>
  );
}

export default EmployeeInformation;
