import React, { SyntheticEvent } from 'react';

import { Link as RouterLink } from '@reach/router';
import { get } from 'lodash';

import { Chip, Tooltip, Typography } from '@material-ui/core';
import { StatusDot, VersionAndStatusDot, VersionSwitch } from 'components';

import { useStyles } from './styles';
import { Cancel } from '@material-ui/icons';

export enum StatusVersionType {
  none,
  statusAndVersion,
  status,
  versionChange,
}

export enum LinkType {
  none,
  internal,
  internalNewTab,
  external,
}

type ChipItemProps = {
  option: {
    id: string;
    currentVersion: {
      title: string;
      versionIdentifier?: string;
      currentStatus?: {
        name: string;
      };
    };
    itemType: {
      displaySlug: string;
    };
    customIdentifier?: string;
  };
  getTagProps: any;
  linkType?: LinkType;
  customContent?: any;
  href?: string;
  statusVersionDot?: StatusVersionType;
  disable?: boolean;
  deleteIcon?: any;
  onToggle?: (newVal: string, e: SyntheticEvent) => void;
};

const ChipItem = (props: ChipItemProps) => {
  const {
    option,
    getTagProps = {},
    linkType = LinkType.internal,
    statusVersionDot = StatusVersionType.none,
    customContent,
    href,
    disable = true,
    deleteIcon = <Cancel />,
    onToggle = () => {},
  } = props;
  const classes = useStyles();

  const getDataForVersionSwitch = (option: any) => {
    if (option.__typename === 'RiskVersion') {
      return {
        value: get(option, 'currentValue'),
        currentRelease: get(option, 'item.currentRelease'),
        workingVersion: get(option, 'item.workingVersion'),
      };
    } else if (option.__typename === 'RiskControl') {
      return {
        value: get(option, 'chosenRiskControlVersionId'),
        currentRelease: get(option, 'currentVersion.item.currentRelease'),
        workingVersion: get(option, 'currentVersion.item.workingVersion'),
      };
    }
  };

  const getStatusVersionDot = (prop: StatusVersionType) => {
    switch (prop) {
      case StatusVersionType.statusAndVersion:
        return (
          <VersionAndStatusDot
            version={get(option, 'currentVersion.versionIdentifier', '')}
            status={get(option, 'currentVersion.currentStatus.name', '')}
          />
        );
      case StatusVersionType.status:
        return <StatusDot status={get(option, 'currentVersion.currentStatus.name', '')} />;
      case StatusVersionType.versionChange:
        const dataForVersionSwitch = getDataForVersionSwitch(option);
        if (dataForVersionSwitch) {
          return (
            <VersionSwitch
              value={dataForVersionSwitch.value}
              currentRelease={dataForVersionSwitch.currentRelease}
              workingVersion={dataForVersionSwitch.workingVersion}
              onToggle={onToggle}
              withAddButton={false}
            />
          );
        }
        break;
      case StatusVersionType.none:
        return null;
      default:
        return null;
    }
  };

  const content = customContent ? (
    customContent
  ) : (
    <div className={classes.content}>
      <Typography variant="body2">{option.customIdentifier}</Typography>
    </div>
  );

  const CustomLink = ({ children }) => {
    switch (linkType) {
      case LinkType.internal:
        return <RouterLink to={`/category/${option.itemType.displaySlug}/${option.id}`}>{children}</RouterLink>;
      case LinkType.internalNewTab:
        return (
          <a
            href={`${window.location.href.split('/')[0]}/category/${option.itemType.displaySlug}/${option.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
      case LinkType.external:
        return (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      case LinkType.none:
        return children;
      default:
        return <RouterLink to={`/category/${option.itemType.displaySlug}/${option.id}`}>{children}</RouterLink>;
    }
  };

  const getTooltipTitle = (option: any) => {
    if (option.__typename === 'RiskVersion') {
      const hazardName = option.hazard && option.hazard.name ? option.hazard.name : 'N/A';
      const harmName = option.harm && option.harm.name ? option.harm.name : 'N/A';
      const hazardousSituation = option.hazardousSituation ? option.hazardousSituation : 'N/A';
      const sequenceOfEvents = option.sequenceOfEvents ? option.sequenceOfEvents : 'N/A';

      return (
        <div>
          <div>Hazard: {hazardName}</div>
          <div>Hazardous situation: {hazardousSituation}</div>
          <div>Harm: {harmName}</div>
          <div>Sequence of events: {sequenceOfEvents}</div>
        </div>
      );
    }
    return option.currentVersion.title;
  };

  return (
    <Tooltip title={getTooltipTitle(option)} placement="top" style={{ maxWidth: 'none' }}>
      <span>
        <Chip
          {...getTagProps}
          deleteIcon={deleteIcon}
          size={'small'}
          classes={{ root: classes.chipRoot }}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomLink>{content}</CustomLink>
              {statusVersionDot !== StatusVersionType.versionChange && <span>&nbsp;</span>}
              {getStatusVersionDot(statusVersionDot)}
            </div>
          }
          clickable={true}
          disabled={disable}
        />
      </span>
    </Tooltip>
  );
};
export default ChipItem;
