import gql from 'graphql-tag';

import client from 'setup/apolloClient';
import { ApolloQueryResult } from 'apollo-client';

import isEmpty from 'lodash/isEmpty';

const FIND_COMMENT_THREAD_QUERY = gql`
  query FindCommentThread($threadId: ID!) {
    virtualAttributeComments(threadId: $threadId) {
      body
      threadId
      threadCommentId
      createdAt
      updatedAt
      creator {
        id
        firstName
        lastName
        profile {
          photoUrl
        }
      }
    }
  }
`;

const DESTROY_COMMENT_MUTATION = gql`
  mutation DestroyComment($threadId: ID!, $commentId: ID!) {
    destroyVirtualAttributeComment(threadId: $threadId, threadCommentId: $commentId) {
      virtualAttributeComment {
        threadId
      }
    }
  }
`;

const UPDATE_COMMENT_MUTATION = gql`
  mutation UpdateComment($threadId: ID!, $commentId: ID!, $body: String!) {
    updateVirtualAttributeComment(threadId: $threadId, threadCommentId: $commentId, body: $body) {
      virtualAttributeComment {
        threadId
      }
    }
  }
`;

const CREATE_COMMENT_MUTATION = gql`
  mutation CreateRichTextComment($attrName: String!, $versionId: ID!, $threadId: ID!, $commentId: ID!, $body: String!) {
    createVirtualAttributeComment(
      attrName: $attrName
      versionId: $versionId
      threadId: $threadId
      threadCommentId: $commentId
      body: $body
    ) {
      virtualAttributeComment {
        createdAt
      }
    }
  }
`;

const FIND_DOCUMENT_SUGGESTION_QUERY = gql`
  query FindDocumentSuggestion($suggestionId: String!) {
    documentSuggestions(suggestionId: $suggestionId) {
      data
      type
      suggestionId
      originalSuggestionId
      createdAt
      updatedAt
      hasComments
      creator {
        id
        firstName
        lastName
        profile {
          photoUrl
        }
      }
    }
  }
`;

const CREATE_SUGGESTION_MUTATION = gql`
  mutation CreateRichTextSuggestion(
    $attrName: String!
    $versionId: ID!
    $type: String!
    $suggestionId: String!
    $originalSuggestionId: String
    $data: String
  ) {
    createDocumentSuggestion(
      attrName: $attrName
      versionId: $versionId
      type: $type
      suggestionId: $suggestionId
      originalSuggestionId: $originalSuggestionId
      data: $data
    ) {
      documentSuggestion {
        createdAt
      }
    }
  }
`;
const UPDATE_SUGGESTION_MUTATION = gql`
  mutation UpdateSuggestion(
    $suggestionId: String!
    $type: String
    $originalSuggestionId: String
    $data: String
    $state: String
    $hasComments: Boolean
  ) {
    updateDocumentSuggestion(
      suggestionId: $suggestionId
      type: $type
      originalSuggestionId: $originalSuggestionId
      data: $data
      state: $state
      hasComments: $hasComments
    ) {
      documentSuggestion {
        suggestionId
      }
    }
  }
`;

interface Comment {
  authorId: string;
  content: string;
  commentId: string;
  createdAt: Date;
}

interface Thread {
  threadId: string;
  comments: Comment[];
}

interface CommentsConfig {
  attrName: string;
  versionId: string;
  currentUser: {
    id: string;
    name: string;
    avatar?: string;
  };
  allUsers: any;
}
interface Suggestion {
  id: string;
  type: string;
  authorId: string;
  data: any;
  state: any;
  originalSuggestionId: string;
  createdAt: Date;
  hasComments: Boolean;
}

class CommentsAdapter {
  editor: any;
  config: CommentsConfig;

  constructor(editor) {
    this.editor = editor;
    this.config = editor.config.get('comments');
  }

  init() {
    const usersPlugin = this.editor.plugins.get('Users');
    const trackChangesPlugin = this.editor.plugins.get('TrackChanges');
    const commentsRepositoryPlugin = this.editor.plugins.get('CommentsRepository');
    const config = this.config;
    const users = config.allUsers;

    for (const user of users) {
      usersPlugin.addUser({ id: user.id, name: user.fullName, avatar: user.photoUrl });
    }

    usersPlugin.defineMe(config.currentUser.id);

    commentsRepositoryPlugin.adapter = {
      addComment(data) {
        return client
          .mutate({
            mutation: CREATE_COMMENT_MUTATION,
            variables: {
              attrName: config.attrName,
              versionId: config.versionId,
              threadId: data.threadId,
              commentId: data.commentId,
              body: data.content,
            },
          })
          .then((result) => {
            return {
              createdAt: new Date(result.data.createVirtualAttributeComment.virtualAttributeComment.createdAt),
            };
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Error occurred while adding the comment:', err);
          });
      },

      updateComment(data) {
        return client
          .mutate({
            mutation: UPDATE_COMMENT_MUTATION,
            variables: {
              threadId: data.threadId,
              commentId: data.commentId,
              body: data.content,
            },
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Error occurred while updating the comment:', err);
          });
      },

      removeComment(data) {
        return client
          .mutate({
            mutation: DESTROY_COMMENT_MUTATION,
            variables: {
              threadId: data.threadId,
              commentId: data.commentId,
            },
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Error occurred while removing the comment:', err);
          });
      },

      getCommentThread({ threadId }) {
        const resultToThread = (result: ApolloQueryResult<any>): Thread => {
          const thread: Thread = {
            threadId: threadId,
            comments: result.data.virtualAttributeComments.map((comment) => {
              return {
                authorId: comment.creator.id,
                content: comment.body,
                commentId: comment.threadCommentId,
                createdAt: new Date(comment.createdAt),
              };
            }),
          };

          return thread;
        };

        return client
          .query({
            query: FIND_COMMENT_THREAD_QUERY,
            variables: { threadId },
            // Caching results in an error. I assume because we have no ID in this query
            // even though the fallback should work: https://www.apollographql.com/docs/react/advanced/caching/#normalization
            fetchPolicy: 'no-cache',
          })
          .then(resultToThread)
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Error occurred while finding the comment thread:', err);
          });
      },
    };
    trackChangesPlugin.adapter = {
      addSuggestion(data) {
        return client
          .mutate({
            mutation: CREATE_SUGGESTION_MUTATION,
            variables: {
              attrName: config.attrName,
              versionId: config.versionId,
              type: data.type,
              suggestionId: data.id,
              originalSuggestionId: data.originalSuggestionId,
              data: isEmpty(data.data) ? null : JSON.stringify(data.data),
            },
          })
          .then((result) => {
            return {
              createdAt: new Date(result.data.createDocumentSuggestion.documentSuggestion.createdAt),
            };
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Error occurred while adding the suggestion:', err);
          });
      },

      updateSuggestion(id: any, data: any) {
        const variables: any = {
          suggestionId: id,
          ...(data.type !== undefined && data.type !== null ? { type: data.type } : {}),
          ...(data.originalSuggestionId !== undefined && data.originalSuggestionId !== null
            ? { originalSuggestionId: data.originalSuggestionId }
            : {}),
          ...(data.data !== undefined && data.data !== null
            ? { data: isEmpty(data.data) ? null : JSON.stringify(data.data) }
            : {}),
          ...(data.hasComments !== undefined && data.hasComments !== null ? { hasComments: data.hasComments } : {}),
          ...(data.state !== undefined && data.state !== null ? { state: data.state } : {}),
        };
        return client
          .mutate({
            mutation: UPDATE_SUGGESTION_MUTATION,
            variables: variables,
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Error occurred while updating the suggestion:', err);
          });
      },

      getSuggestion(id: any) {
        const resultToThread = (result: ApolloQueryResult<any>): Suggestion => {
          const suggestion: Suggestion = {
            id: result.data.documentSuggestions[0].suggestionId,
            originalSuggestionId: result.data.documentSuggestions[0].originalSuggestionId,
            type: result.data.documentSuggestions[0].type,
            authorId: result.data.documentSuggestions[0].creator.id,
            data: JSON.parse(result.data.documentSuggestions[0].data),
            createdAt: result.data.documentSuggestions[0].createdAt,
            hasComments: result.data.documentSuggestions[0].hasComments,
            state: result.data.documentSuggestions[0].state,
          };
          return suggestion;
        };
        return client
          .query({
            query: FIND_DOCUMENT_SUGGESTION_QUERY,
            variables: { suggestionId: id },
            // Caching results in an error. I assume because we have no ID in this query
            // even though the fallback should work: https://www.apollographql.com/docs/react/advanced/caching/#normalization
            fetchPolicy: 'no-cache',
          })
          .then(resultToThread)
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Error occurred while getting the suggestion:', err);
          });
      },
    };
  }
}

export default CommentsAdapter;
