import React, { useState } from 'react';
import Delete from '@material-ui/icons/Delete';
import { LongCard } from 'compositions';
import { useMutation } from 'react-apollo';
import updateTitleMutation from 'utils/gql/updateTitleMutation';
import { useStyles } from './styles';
import RemoveConfirmation from '../RemoveConfirmation';
import { Step } from '../util';

interface Props {
  step: Step;
  customIdentifier: string;
  locked: boolean;
  canEditTitle: boolean;
  onCheckboxClick: () => void;
  onRemove: (steps: number[]) => void;
}

function StepListItem(props: Props) {
  const { step } = props;
  const classes = useStyles();
  const [mutateTitle] = useMutation(updateTitleMutation);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const handleTitleChange = (versionId) => (newValue) => {
    mutateTitle({
      variables: {
        input: {
          versionId,
          attrName: 'title',
          stringValue: newValue,
        },
      },
    }).catch((e) => {
      throw e;
    });
  };
  return (
    <>
      <LongCard
        customIdentifier={props.customIdentifier}
        onCheckboxClick={props.onCheckboxClick}
        itemRecord={step.raw.item}
        locked={props.locked}
        classes={{ panel: classes.panel }}
        onRemoveIconClick={() => setRemoveConfirmation((prev) => !prev)}
        parentSetItemSlug="device-verifications"
        primaryBodyText={{
          attrName: 'step_description',
          attrValue: step.description,
        }}
        queryToRefetch="DeviceVerificationVersionQuery"
        removeIcon={Delete}
        secondaryBodyText={{
          attrName: 'acceptance_criteria',
          attrValue: step.acceptanceCriteria,
        }}
        showRemoveIcon={!props.locked}
        title={step.title || 'Default Title'}
        titleDisplayVariant="titlePrefix"
        variant="horizontal"
        versionId={step.versionId.toString()}
        onTitleChange={props.canEditTitle ? handleTitleChange(step.versionId) : undefined}
        globalId={step.globalId}
      />
      {removeConfirmation && (
        <RemoveConfirmation
          onCancel={() => setRemoveConfirmation(false)}
          onClose={() => setRemoveConfirmation(false)}
          onRemove={() => props.onRemove([step.memberId])}
          stepsToRemove={[step]}
        />
      )}
    </>
  );
}

export default StepListItem;
