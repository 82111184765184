import React from 'react';

import { Trans, t } from '@lingui/macro';
import { setupI18n } from '@lingui/core';

import Grid from '@material-ui/core/Grid';

import LowPriority from '@material-ui/icons/LowPriority';
import Sort from '@material-ui/icons/Sort';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import { ContentSection, SelectField, Spacer } from 'components';

import { Views } from 'workspaces';

const i18n = setupI18n();

const riskControlTypeOptions = [
  i18n._(t`Inherent Safe Design`),
  i18n._(t`Protective Safety Measure`),
  i18n._(t`Safety Information`),
];

const PayloadFields = ({ activeWorkspaceView, layoutClasses, versionData: currentVersion, displayOnly }) => (
  <>
    <Grid item xs={8} className={layoutClasses.gridGutterRight}>
      <RichTextEditorContentSection
        Heading={
          <>
            <Sort className={layoutClasses.icon} />
            <Trans>Description of Risk Control</Trans>
          </>
        }
        initialValue={currentVersion.description}
        versionId={currentVersion.id}
        attrName="description"
        locked={currentVersion.locked}
        refetchQueries={['riskControlQuery']}
        showComments={activeWorkspaceView.id === Views.Builder.id}
      />
    </Grid>

    <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
      <ContentSection
        LabelText={
          <>
            <LowPriority className={layoutClasses.icon} />
            <Trans>Risk Control Strategy</Trans>
          </>
        }
        fillHeight={false}
      >
        <Spacer factor={1} />
        <SelectField
          locked={currentVersion.locked || displayOnly}
          attrName="strategy"
          options={riskControlTypeOptions}
          placeholder="Select strategy"
          refetchQueries={['riskControlQuery']}
          versionId={currentVersion.id}
          value={currentVersion.strategy}
        />
      </ContentSection>
    </Grid>
  </>
);

export default withWorkspaceViews()(PayloadFields);
