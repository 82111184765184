import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      paddingRight: theme.spacing(2),
    },
    versionsContainer: {
    },
    versionsItemContainer: {
      marginBottom: theme.spacing(2),
    },
    panel: {
      marginTop: '35px',
      '& > div': {
        display: 'flex',
      }
    },
    versionsItemPanel: {
      display: 'flex',
      marginBottom: theme.spacing(2),
      alignItems: 'center',
    },
    versionsItemInfo: {
      width: '100%'
    },
    expandButton: {
      marginLeft: theme.spacing(2),
      borderRadius:'50%',
      minWidth: '36px',
    },
    label: {
      display: 'flex',
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(2),
    }
  }),
);
