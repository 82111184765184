import React from 'react';

import { Grid, TextField } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';
import CalendarToday from '@material-ui/icons/CalendarToday';
import ViewHeadline from '@material-ui/icons/ViewHeadline';

import { DateTime } from 'luxon';

import { TextAreaField } from 'components';
import Panel from 'components/core/Panel';

import { TabProps } from '../PayloadFields';
import { useStyles } from '../styles';
import { queryNameToRefetch } from '../gql';

interface Props extends TabProps {}

const OverviewTab = (props: Props) => {
  const classes = useStyles();
  const { version, layoutClasses } = props;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Panel.Heading icon={CalendarToday}>Initiation Date</Panel.Heading>
          <TextField
            variant="outlined"
            id="init-date"
            type="text"
            className={classes.dateTextField}
            value={DateTime.fromISO(version.createdAt).toFormat('MM-dd-yyyy')}
            InputProps={{
              classes: {
                input: classes.input,
                root: classes.cssOutlinedInput,
              },
              className: classes.dateTextField,
            }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={4}>
          <Panel.Heading icon={CalendarToday}>Effective Date</Panel.Heading>
          <TextField
            variant="outlined"
            id="effective-date"
            type="text"
            className={classes.dateTextField}
            value={version.releasedAt ? DateTime.fromISO(version.releasedAt).toFormat('MM-dd-yyyy') : 'TBD'}
            InputProps={{
              classes: {
                input: classes.input,
                root: classes.cssOutlinedInput,
              },
              className: classes.dateTextField,
            }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} className={layoutClasses.gridGutterRight}>
          <Panel.Heading icon={Sort}>Description of Change Order</Panel.Heading>
          <TextAreaField
            locked={version.locked}
            id="change-order-description"
            initialValue={version.descriptionOfChange}
            versionId={version.id}
            attrName="description_of_change"
            rows={5}
            placeholder="Enter description"
            refetchQueries={[queryNameToRefetch]}
          />
        </Grid>
        <Grid item xs={12} className={layoutClasses.gridGutterRight}>
          <Panel.Heading icon={Sort}>Reason for Change Order</Panel.Heading>
          <TextAreaField
            locked={version.locked}
            id="change-order-rfc"
            initialValue={version.reasonForChange}
            versionId={version.id}
            attrName="reason_for_change"
            rows={5}
            placeholder="Enter reason"
            refetchQueries={[queryNameToRefetch]}
          />
        </Grid>
        <Grid item xs={12} className={layoutClasses.gridGutterRight}>
          <Panel.Heading icon={ViewHeadline}>Implementation Plan</Panel.Heading>
          <TextAreaField
            locked={version.locked}
            initialValue={version.implementationPlan}
            attrName="implementation_plan"
            versionId={version.id}
            placeholder="Enter description"
            multiline
            rows={5}
            refetchQueries={[queryNameToRefetch]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OverviewTab;
