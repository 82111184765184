import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    dropdown: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(0.5),
      boxShadow: '0px 2px 20px 1px rgba(182, 182, 182, 0.25)',
    },
    dropdownInput: {
      padding: theme.spacing(1.5, 2),
      border: `1px solid #DFDFDF`,
      borderRadius: 2,
      marginBottom: theme.spacing(1),
    },
    dropdownInputText: {
      color: theme.palette.blueDark,
    },
    list: {
      height: 316,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#DFDFDF',
        borderRadius: 5,
      },
    },
    menuItem: {
      '&:hover': {
        backgroundColor: '#F2F2F2',
      },
    },
    selectedMenuItem: {
      backgroundColor: '#F2F2F2',
    },
    menuItemText: {
      marginRight: 'auto',
      color: theme.palette.secondary.main,
    },
    checkmark: {
      fontSize: 20,
      color: '#33BCB4',
    },
  }),
);
