import React, { ReactNode } from 'react';

import { Popover as MuiPopover, Typography } from '@material-ui/core';

import ContentSection from 'components/ContentSection';
import Spacer from 'components/Spacer';

import { useStyles } from './styles';
import LabelText from 'components/LabelText';

interface Props {
  title: string;
  children: ReactNode;
  open: boolean;
  anchorEl: Element | null;
  onClose: () => void;
  actionText?: ReactNode | string;
  'data-testid'?: string;
}

function Popover(props: Props) {
  const classes = useStyles();
  return (
    <MuiPopover
      classes={{ paper: classes.tracePopover }}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      data-testid={props['data-testid']}
    >
      <ContentSection
        LabelText={
          <LabelText
            labelText={props.title}
            Adornment={<Typography variant="overline">{props.actionText}</Typography>}
          />
        }
        elevation={1}
      >
        <Spacer factor={1} />
        {props.children}
      </ContentSection>
    </MuiPopover>
  );
}

export default Popover;
