import { handleActions } from 'utils';

import {
  LOCALSTORAGE_WORKSPACE_VIEWS_KEY,
  SET_WORKSPACE_VIEW_FOR_ROUTE,
} from '../constants';

/**
 * Attempt to load the initial app UI state from the localStorage.  Note that this is
 * purposefully using localStorage and not the memoryDB proxy because...
 *  * memoryDB is not available at app bootstrap
 *  * the only reason for this loadInitialState to exist is to re-hydrate the persisted
 *    app UI state; memoryDB is not persisted
 *  * if localStorage is not available (e.g. Safari Private Browsing) then getItem just
 *    returns null, so the defaults will be used instead, and that's fine.
 */
export function loadInitialState(storage = localStorage) {
  const defaultState = {};

  const workspaceViewsState =
    storage &&
    storage.getItem &&
    storage.getItem(LOCALSTORAGE_WORKSPACE_VIEWS_KEY);

  if (workspaceViewsState) {
    return JSON.parse(workspaceViewsState);
  }

  return defaultState;
}

function setWorkspaceViewForRoute(state, { payload }) {
  state[payload.routeName] = payload.workspaceViewName;
  return state;
}

export default handleActions(
  {
    [SET_WORKSPACE_VIEW_FOR_ROUTE]: setWorkspaceViewForRoute,
  },
  loadInitialState(),
);
