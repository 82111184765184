import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { folderFragment } from '../setsUtil';
import { UserNeedsSetVersionQuery } from './__generated__/UserNeedsSetVersionQuery';

export const memberFragment = gql`
  fragment MemberFragment on UserNeedsSetMember {
    id
    version {
      id
      title
      userNeedText
      stakeholders
      locked
      versionIdentifier
      currentStatusName
    }
    indentationLevel
    sortPosition
    folderOneName
    folderTwoName
    folderThreeName
  }
`;

const versionQuery = gql`
  query UserNeedsSetVersionQuery($id: ID!) {
    userNeedsSetVersion(id: $id) {
      id
      title
      locked
      currentStatusName
      mayTransitionToDraft
      mayTransitionToUnderReview
      mayTransitionToOwnerApproval
      mayTransitionToApprovedDraft
      mayTransitionToReleased
      mayTransitionToRejected
      mayTransitionToObsolete
      mayTransitionToVoid
      setItemChildren {
        ... on UserNeedsSetMember {
          id
          version {
            id
            title
            userNeedText
            stakeholders
            setMember {
              id
              setItemVersion {
                id
                item {
                  id
                }
              }
            }
            locked
            currentStatusName
            versionIdentifier
            item {
              id
              itemType {
                id
                displaySlug
                userCreatable
              }
              customIdentifier
              tags
              comments {
                id
              }
              currentVersion {
                id
                versionIdentifier
                currentStatusName
              }
              workingVersion {
                id
                versionIdentifier
                currentStatusName
                currentStatus {
                  name
                  titleizedName
                }
              }
              currentRelease {
                id
                versionIdentifier
                currentStatusName
                currentStatus {
                  name
                  titleizedName
                }
              }
            }
          }
          indentationLevel
          sortPosition
          sortPositionsJson
          folderOneName
          folderTwoName
          folderThreeName
        }
        ... on Folder {
          id
          indentationLevel
          sortPosition
          folderName
        }
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<UserNeedsSetVersionQuery>(versionQuery, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });
}

export const addFolder = gql`
  mutation createUserNeedsSetFolder(
    $userNeedsSetVersionId: ID!
    $folderName: String!
    $sortPosition: Int!
    $indentationLevel: Int!
  ) {
    createUserNeedsSetFolder(
      userNeedsSetVersionId: $userNeedsSetVersionId
      folderName: $folderName
      sortPosition: $sortPosition
      indentationLevel: $indentationLevel
    ) {
      folder {
        id
        indentationLevel
        sortPosition
        folderName
      }
    }
  }
`;

export const addMembers = gql`
  mutation createUserNeedsSetMembers(
    $userNeedsSetVersionId: ID!
    $userNeedVersionIds: [ID!]!
    $sortPosition: Int!
    $indentationLevel: Int!
  ) {
    createUserNeedsSetMembers(
      userNeedsSetVersionId: $userNeedsSetVersionId
      userNeedVersionIds: $userNeedVersionIds
      sortPosition: $sortPosition
      indentationLevel: $indentationLevel
    ) {
      members {
        ... on UserNeedsSetMember {
          ...MemberFragment
        }
        ... on Folder {
          ...FolderFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const linkMembers = gql`
  mutation linkNewUserNeedVersionToUserNeedsSet($input: LinkNewUserNeedVersionToUserNeedsSetInput!) {
    linkNewUserNeedVersionToUserNeedsSet(input: $input) {
      clientMutationId
      userNeedVersion {
        id
        item {
          id
          customIdentifier
        }
      }
    }
  }
`;

export const updateFolder = gql`
  mutation UpdateUNDSFolder(
    $userNeedsSetFolderId: ID!
    $folderName: String
    $sortPosition: Int
    $indentationLevel: Int
  ) {
    updateUserNeedsSetFolder(
      userNeedsSetFolderId: $userNeedsSetFolderId
      folderName: $folderName
      sortPosition: $sortPosition
      indentationLevel: $indentationLevel
    ) {
      children {
        ... on UserNeedsSetMember {
          ...MemberFragment
        }
        ... on Folder {
          ...FolderFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const updateMember = gql`
  mutation UpdateUNDSMember(
    $setMemberId: ID!
    $sortPosition: Int
    $indentationLevel: Int
    $memberVersionId: ID
    $folderOne: ID
    $folderTwo: ID
    $folderThree: ID
  ) {
    updateUserNeedsSetMember(
      userNeedsSetMemberId: $setMemberId
      sortPosition: $sortPosition
      indentationLevel: $indentationLevel
      setMemberVersionId: $memberVersionId
      folderOne: $folderOne
      folderTwo: $folderTwo
      folderThree: $folderThree
    ) {
      children {
        ... on UserNeedsSetMember {
          ...MemberFragment
        }
        ... on Folder {
          ...FolderFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const bulkUpdateMembersMutation = gql`
  mutation BulkUpdateUserNeedsSetMembers($members: [BulkUpdateUserNeedsSetMember!]!) {
    bulkUpdateUserNeedsSetMembers(members: $members) {
      returnMembers {
        id
      }
    }
  }
`;

export const removeFolder = gql`
  mutation removeUserNeedsSetFolder($userNeedsSetFolderId: ID!) {
    removeUserNeedsSetFolder(userNeedsSetFolderId: $userNeedsSetFolderId) {
      children {
        ... on Folder {
          ...FolderFragment
        }
        ... on UserNeedsSetMember {
          ...MemberFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const removeMember = gql`
  mutation removeUserNeedsSetMember($userNeedsSetMemberId: ID!) {
    removeUserNeedsSetMember(userNeedsSetMemberId: $userNeedsSetMemberId) {
      children {
        ... on UserNeedsSetMember {
          ...MemberFragment
        }
        ... on Folder {
          ...FolderFragment
        }
      }
    }
  }
  ${folderFragment}
  ${memberFragment}
`;

export const switchVersion = gql`
  mutation SwitchUNDSMemberRev($setMemberId: ID!, $memberVersionId: ID) {
    updateUserNeedsSetMember(userNeedsSetMemberId: $setMemberId, setMemberVersionId: $memberVersionId) {
      member {
        id
        version {
          id
          title
          userNeedText
          stakeholders
          locked
          item {
            id
            tags
            currentVersion {
              id
              versionIdentifier
              currentStatusName
            }
            workingVersion {
              id
              versionIdentifier
              currentStatusName
            }
            currentRelease {
              id
              versionIdentifier
              currentStatusName
            }
          }
        }
      }
    }
  }
`;
