import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import get from 'lodash/get';

import { FormControl, Typography } from '@material-ui/core';

import RemoveCircle from '@material-ui/icons/RemoveCircle';

import { Spacer } from 'components';
import BaseDialog from 'components/BaseDialog';
import { ReasonForChangeAndEsig } from 'components';
import { DEACTIVATE_USER_JOB_ROLE } from './gql';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedUser: any;
  jobRole: any;
}

function ConfirmUserRoleRemoval(props: Props) {
  const { open, onClose, selectedUser, jobRole } = props;
  const [reasonForChange, setReasonForChange] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const formCanSubmit = () => reasonForChange.length && username.length && password.length;

  const handleSubmit = () => {
    deactivateUserJobRole({
      variables: {
        userId: selectedUser.id,
        jobRoleId: jobRole.id,
        reasonForChange: reasonForChange,
        username: username,
        password: password,
      },
    }).catch((err) => {
      throw new Error(`Error while deactivating users' job role: ${err}`);
    });
  };

  const onUpdateCompleted = () => {
    setReasonForChange('');
    setUsername('');
    setPassword('');
    onClose();
  };

  const [deactivateUserJobRole, { loading, error }] = useMutation(DEACTIVATE_USER_JOB_ROLE, {
    refetchQueries: ['AllJobRoles'],
    onCompleted: onUpdateCompleted,
  });
  if (error) throw new Error(`Error updating user job roles: ${error}`);

  return (
    <BaseDialog
      open={open}
      onClose={onUpdateCompleted}
      title={`Confirm User Role Removal`}
      Icon={RemoveCircle}
      size="xlarge"
    >
      <form>
        <FormControl required fullWidth>
          <Spacer factor={2} />
          <Typography variant="body2" align="left">
            The <span style={{ fontWeight: 600 }}>{get(jobRole, 'formattedRoleName')}</span> role will be removed from{' '}
            <span style={{ fontWeight: 600 }}>{get(selectedUser, 'fullName')}</span>'s list of active roles.
          </Typography>
          <Spacer factor={4} />
          <ReasonForChangeAndEsig
            textboxHeading={`Reason for Role Removal`}
            textboxId={`reason-for-change-input`}
            onTextChange={(e) => setReasonForChange(e.target.value)}
            usernameInputId={`remove-role-from-user-username-input`}
            onUsernameChange={(e) => setUsername(e.target.value)}
            passwordInputId={`remove-role-from-user-password-input`}
            onPasswordChange={(e) => setPassword(e.target.value)}
            submitDisabled={!formCanSubmit() || loading}
            onSubmitClick={handleSubmit}
            submitLoading={loading}
          />
        </FormControl>
      </form>
    </BaseDialog>
  );
}

export default ConfirmUserRoleRemoval;
