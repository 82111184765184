import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    recordConfiguration: {
      '& .selectItem': {
        marginBottom: theme.spacing(4),
      },
    },
    assesmentTextArea: {
      background: '#fff',
      border: '1px solid #DFDFDF',
    },
    assesmentTextAreaDisabled: {
      cursor: 'default',
      color: theme.palette.text.disabled,
      background: theme.palette.common.input.background,
      borderColor: theme.palette.common.input.outline,
    },
  }),
);
