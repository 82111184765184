import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextArea } from 'components';
import { Trans } from '@lingui/macro';
import { compose } from 'recompose';

import styles from './styles';
import { Spacer } from 'components';

import Signature from './Signature';
import withCurrentUser from '../../../WithCurrentUser';

class RejectReason extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
      username: null,
      password: null,
    };
  }
  render() {
    const { classes, error, handleComplete, heading, title } = this.props;
    const { reason, username, password } = this.state;

    return (
      <>
        <div className={classes.heading}>{title ? title : <Trans>Rejection</Trans>}</div>

        <Spacer factor={2} />

        <Typography variant="body2">{heading}</Typography>

        <Spacer factor={2} />

        <TextArea
          required
          label="Reason For Rejection"
          placeholder="Insert reason here*"
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows="2"
          InputLabelProps={{
            shrink: true,
          }}
          onTextChange={(reason) => this.setState({ reason })}
        />
        <Typography variant="caption" className={classes.textBoxCaption}>
          <Trans>*Required</Trans>
        </Typography>

        <Spacer factor={5} />

        <Signature
          itemType={this.props.itemTypeMetadata.name}
          error={error}
          disabled={!this.state.reason || !this.state.username || !this.state.password}
          handleSubmit={
            this.state.reason
              ? () =>
                  handleComplete({
                    reason,
                    signature: {
                      username,
                      password,
                      reasonForSignature: `${heading} ${reason}`,
                    },
                  })
              : undefined
          }
          onUsernameChange={(e) => this.setState({ username: e.target.value })}
          onPasswordChange={(e) => this.setState({ password: e.target.value })}
        />
      </>
    );
  }
}

export default compose(
  withStyles(styles),
  withCurrentUser,
)(RejectReason);
