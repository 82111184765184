import React from 'react';

import { get } from 'lodash';

import RpcInput from 'pages/Risk/Id/PayloadFields/RpcComponent';

interface Props {
  riskVersion: any;
  mitigated: boolean;
  className?: string;
}

export default function RiskRPC({ riskVersion, mitigated, className }: Props) {
  const prefix = mitigated ? '' : 'un';

  const placeholder = 'Not calculated';

  return (
    <div className={className}>
      <RpcInput
        placeholder={placeholder}
        color={get(riskVersion, `${prefix}mitigatedRiskPriorityCode.color`)}
        inputValue={get(riskVersion, `${prefix}mitigatedRiskPriorityCode.name`)}
      />
    </div>
  );
}
