import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 5px',
      '& tr td': {
        borderTop: '1px solid #DFDFDF',
      },
      '& tr td:first-child': {
        borderRadius: '5px 0px 0px 5px',
        borderLeft: '1px solid #DFDFDF',
      },
      '& tr td:last-child': {
        borderRadius: '0px 5px 5px 0px',
        borderRight: '1px solid #DFDFDF',
      },
      '& .MuiTableRow-root:hover .MuiTableCell-root:last-child *': {
        opacity: '1 !important',
      },
    },
    cell: {
      padding: '0 0 0 6px',
      margin: '0 auto',
    },
    actionCell: {
      padding: '12px',
      '& *': {
        opacity: '0',
      },
    },
    head: {
      borderBottom: 'none',
      padding: '0 0 0 6px',
    },
    autocompleteInputRoot: {
      '& .MuiInputBase-root': {
        height: 45,
        position: 'relative',
        bottom: 2.5,
        borderRadius: 4,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        // @ts-ignore
        border: `2px solid ${theme.palette.common.input.outline}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        // @ts-ignore
        border: `2px solid ${theme.palette.common.input.outline}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }),
);
