import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import get from 'lodash/get';

import { Formik, Form, Field } from 'formik';
import { compose } from 'recompose';
import { withI18n } from '@lingui/react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { getAuthToken, getProfileAvatarUploadUrl } from 'utils';

import {
  Avatar,
  Button,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Business from '@material-ui/icons/Business';
import Call from '@material-ui/icons/Call';
import Create from '@material-ui/icons/Create';
import Email from '@material-ui/icons/Email';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import RecentActors from '@material-ui/icons/RecentActors';

import styles from './styles';
import gql from 'graphql-tag';

import WithCurrentUser from 'compositions/WithCurrentUser';
import { withCurrentCompany } from '../WithCurrentCompany';
import { Spacer } from 'components';

const mutation = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      errors
      profile {
        id
        title
        phoneNumber
        secondaryPhoneNumber
        photoUrl
        emailAddress
      }
    }
  }
`;

class ProfileUpdateForm extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    i18n: PropTypes.shape({
      _: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
      profileUpdateWrapper: PropTypes.string.isRequired,
      avatarImageWrapper: PropTypes.string.isRequired,
      avatarImage: PropTypes.string.isRequired,
      updateAvatarOverlay: PropTypes.string.isRequired,
      updateAvatarIcon: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      profileForm: PropTypes.string.isRequired,
      spinner: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    avatarMenuAnchorEl: undefined,
    avatarRemoved: false,
    updatedProfile: undefined,
    successMessage: '',
    errors: [],
    thumbnail: undefined,
    loading: false,
    previewImageUrl: '',
    inEditingMode: false,
  };

  updateAvatar = (file) => {
    if (!file && !this.state.avatarRemoved) {
      return;
    }

    const [authToken] = getAuthToken();
    let method, formData;
    if (this.state.avatarRemoved === true) {
      this.setState({ avatarRemoved: undefined, previewImageUrl: undefined });
      method = 'DELETE';
      formData = undefined;
    } else {
      formData = new FormData();
      method = 'POST';
      formData.append('file', file);
    }

    return fetch(getProfileAvatarUploadUrl(), {
      credentials: 'same-origin',
      headers: {
        Authorization: authToken,
      },
      method: method,
      body: formData,
    });
  };

  avatarImage = (values, profile) => {
    if (values.file) {
      let reader = new FileReader();
      reader.readAsDataURL(values.file);
      return reader.result;
    } else {
      return profile.photoUrl;
    }
  };

  openAvatarMenu = (event) => {
    this.setState({ avatarMenuAnchorEl: event.currentTarget });
  };

  closeAvatarMenu = () => {
    this.setState({ avatarMenuAnchorEl: undefined });
  };

  handleEditingMode = () => {
    this.setState({ inEditingMode: !this.state.inEditingMode });
  };

  phoneInput = (props) => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        placeholder="(555) 555-5555"
      />
    );
  };

  render() {
    const { avatarMenuAnchorEl, avatarRemoved, inEditingMode, loading, previewImageUrl, updatedProfile } = this.state;

    const {
      currentUser,
      classes,
      currentCompany,
      displayCurrentUserProfile = true,
      userProfile = updatedProfile || currentUser.profile,
      isCurrentUser = true,
    } = this.props;

    const firstName = displayCurrentUserProfile ? get(currentUser, 'firstName') : userProfile.firstName;
    const lastName = displayCurrentUserProfile ? get(currentUser, 'lastName') : userProfile.lastName;
    const username = displayCurrentUserProfile ? get(currentUser, 'username') : userProfile.user.username;
    const computedFontSize = `${(175 / 30) * 0.875}rem`;

    function parsePhoneNum(str) {
      return str ? str.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : 'N/A';
    }

    return (
      <>
        <div style={loading ? { opacity: '.5' } : {}} className={classes.profileUpdateWrapper}>
          {isCurrentUser && <Create className={classes.editButton} onClick={this.handleEditingMode} />}
          <Typography className={classes.profileUpdateHeader}>
            {firstName} {lastName}
          </Typography>
          <Typography variant="subtitle2">{`@${username}`}</Typography>
          <CircularProgress style={loading ? {} : { display: 'none' }} className={classes.spinner} />
          <Mutation mutation={mutation} refetchQueries={['profilesQuery']}>
            {(updateProfile, { _loading, error }) => {
              return (
                <Formik
                  initialValues={{
                    title: userProfile.title || '',
                    phoneNumber: userProfile.phoneNumber || '',
                    secondaryPhoneNumber: userProfile.secondaryPhoneNumber || '',
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    this.setState({ loading: true });
                    await this.updateAvatar(values.file);
                    const response = await updateProfile({
                      variables: {
                        input: {
                          id: userProfile.id,
                          title: values.title,
                          phoneNumber: values.phoneNumber,
                          secondaryPhoneNumber: values.secondaryPhoneNumber,
                        },
                      },
                    });
                    this.setState({
                      updatedProfile: response.data.updateProfile.profile,
                    });
                    resetForm();
                    this.setState({ loading: false });
                  }}
                >
                  {({ _values, setFieldValue, dirty }) => {
                    const imageUrl = () => {
                      if (avatarRemoved === true) {
                        return undefined;
                      }

                      return previewImageUrl || userProfile.photoUrl;
                    };

                    return (
                      <Form className={classes.profileForm}>
                        <Field name="title">
                          {({ field, form: { errors, touched } }) => {
                            //const displayError = errors.title && touched.title;
                            return isCurrentUser && inEditingMode ? (
                              <TextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                margin="none"
                                placeholder="Title"
                                disabled={loading}
                                inputProps={{
                                  style: { textAlign: 'center' },
                                }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline,
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                  },
                                }}
                              />
                            ) : (
                              <Typography variant="body2" style={{ textAlign: 'center' }}>
                                {userProfile.title || 'N/A'}
                              </Typography>
                            );
                          }}
                        </Field>
                        <Spacer factor={2.5} />
                        <Avatar className={classes.avatarImageWrapper}>
                          {imageUrl() ? (
                            <img className={classes.avatarImage} src={imageUrl()} alt={'User Avatar'} />
                          ) : (
                            <Typography
                              variant={'body2'}
                              style={{ fontSize: computedFontSize }}
                              className={classes.text}
                            >
                              {`${firstName[0]}${lastName[0]}`}
                            </Typography>
                          )}

                          {!loading && isCurrentUser && inEditingMode && (
                            <div className={classes.updateAvatarOverlay}>
                              <input
                                id="uploader-button"
                                accept=".jpg,.png,.jpeg"
                                type="file"
                                disabled={loading}
                                hidden
                                onChange={(event) => {
                                  const file = event.currentTarget.files[0];
                                  let reader = new FileReader();
                                  reader.onloadend = () => {
                                    this.setState({
                                      previewImageUrl: reader.result,
                                    });
                                  };
                                  reader.readAsDataURL(file);
                                  setFieldValue('file', file);
                                }}
                              />
                              <IconButton
                                className={classes.updateAvatarIcon}
                                aria-label="Change Avatar Menu"
                                aria-controls="change-avatar-menu"
                                aria-haspopup="true"
                                onClick={this.openAvatarMenu}
                              >
                                <PhotoCamera />
                              </IconButton>
                              <Menu
                                anchorEl={avatarMenuAnchorEl}
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'center',
                                }}
                                keepMounted
                                open={!!avatarMenuAnchorEl}
                                onClose={this.closeAvatarMenu}
                              >
                                {imageUrl() && (
                                  <MenuItem
                                    onClick={() => {
                                      this.setState({ avatarRemoved: true });
                                      this.closeAvatarMenu();
                                    }}
                                  >
                                    Remove Photo
                                  </MenuItem>
                                )}
                                <label htmlFor="uploader-button">
                                  <MenuItem onClick={this.closeAvatarMenu}>Change Photo</MenuItem>
                                </label>
                              </Menu>
                            </div>
                          )}
                        </Avatar>
                        <Spacer factor={2.5} />
                        <Typography variant="body2">
                          <RecentActors className={classes.icon} />
                          {currentUser.jobRole
                            .split('_')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')}
                        </Typography>
                        <Typography variant="body2">
                          <Business className={classes.icon} />
                          {currentCompany.name}
                        </Typography>
                        <Typography variant="body2" className={classes.boldText}>
                          <Email className={classes.icon} />
                          {userProfile.emailAddress}
                        </Typography>
                        <Field name="phoneNumber">
                          {({ field, form: { errors, touched } }) => {
                            // const displayError = errors.title && touched.title;
                            return isCurrentUser && inEditingMode ? (
                              <TextField
                                {...field}
                                fullWidth
                                type="tel"
                                variant="outlined"
                                margin="none"
                                disabled={loading}
                                InputProps={{
                                  inputComponent: this.phoneInput,
                                  classes: {
                                    notchedOutline: classes.notchedOutline,
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                  },
                                  startAdornment: (
                                    <InputAdornment style={{ margin: 0 }} position="start">
                                      <Call className={classes.icon} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            ) : (
                              <Typography variant="body2">
                                <Call className={classes.icon} />
                                {parsePhoneNum(userProfile.phoneNumber)}
                              </Typography>
                            );
                          }}
                        </Field>
                        <Field name="secondaryPhoneNumber">
                          {({ field, form: { errors, touched } }) => {
                            // const displayError = errors.title && touched.title;
                            return isCurrentUser && inEditingMode ? (
                              <TextField
                                {...field}
                                fullWidth
                                type="tel"
                                variant="outlined"
                                margin="none"
                                disabled={loading}
                                InputProps={{
                                  inputComponent: this.phoneInput,
                                  classes: {
                                    notchedOutline: classes.notchedOutline,
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                  },
                                  startAdornment: (
                                    <InputAdornment style={{ margin: 0 }} position="start">
                                      <Call className={classes.icon} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            ) : (
                              <Typography variant="body2">
                                <Call className={classes.icon} />
                                {parsePhoneNum(userProfile.secondaryPhoneNumber)}
                              </Typography>
                            );
                          }}
                        </Field>
                        <Spacer factor={2} />
                        {(dirty || avatarRemoved) && (
                          <Button
                            color="primary"
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            className={classes.saveButton}
                            onClick={this.handleEditingMode}
                          >
                            <Trans>Save</Trans>
                          </Button>
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              );
            }}
          </Mutation>
        </div>
      </>
    );
  }
}

export default compose(
  withI18n(),
  withStyles(styles),
  withCurrentCompany,
  WithCurrentUser,
)(ProfileUpdateForm);
