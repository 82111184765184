import React from 'react';
import Link from '@material-ui/icons/Link';

import { useStyles } from './styles';
import { Direction } from 'types';

interface Props {
  direction: Direction;
  classes: any;
  onAddLinkClick: (event: { anchorEl: any; direction: Direction }) => void;
  selected: boolean;
  item: any;
}

export function AddLinkButton(props: Props) {
  const { onAddLinkClick, direction, item } = props;
  const classes = useStyles();
  const linkSpacing = directionBasedStyle(direction, classes);

  return (
    <div
      onClick={(evt) =>
        onAddLinkClick({
          anchorEl: evt.currentTarget,
          direction: direction,
        })
      }
      className={`${classes.addLinkButton} ${linkSpacing}`}
      id={`${direction}-link-button-${item.id}`}
    >
      <Link className={classes.linkIcon} />
    </div>
  );
}

function directionBasedStyle(direction: Direction, classes: any) {
  switch (direction) {
    case 'upstream':
      return classes.upstreamSpacing;
    case 'downstream':
      return classes.downstreamSpacing;
  }
}

export default AddLinkButton;
