import React from 'react';

import { TransitionPayload } from '../statuses';
import InProgressToActive from './InProgressToActive';
import ActiveToPlanning from './ActiveToPlanning';
import ActiveToInactive from './ActiveToInactive';
import InactiveToActive from './InactiveToActive';
import Retire from './Retire';
import { useCurrentUser } from 'compositions/WithCurrentUser';

export enum TransitionWizard {
  PlanningToActive = 'plan2act',
  ActiveToPlanning = 'act2plan',
  ActiveToInactive = 'act2inac',
  InactiveToActive = 'inac2act',
  Retire = 'retire',
}

function Wizard(props: {
  type: TransitionWizard | null;
  createTransition: (data: TransitionPayload) => void;
  itemTypeMetadata: any;
  error?: Error;
  itemCustomIdentifier: string;
  currentVersion: any;
}) {
  const { data, loading, error: currentUserError } = useCurrentUser();
  if (currentUserError) throw currentUserError;
  if (loading || !data || !data.currentUser) return null;

  const releaseDialog = (toStatus) =>
    `As the ${props.currentVersion.assignedOwnerJobRole.formattedRoleName}, I approve transition of ${
      props.itemCustomIdentifier
    } to ${toStatus}.`;

  const { createTransition, error } = props;

  switch (props.type) {
    case TransitionWizard.PlanningToActive:
      return (
        <InProgressToActive createTransition={createTransition} error={error} releaseDialog={releaseDialog('active')} />
      );
    case TransitionWizard.ActiveToPlanning:
      return (
        <ActiveToPlanning createTransition={createTransition} error={error} releaseDialog={releaseDialog('planning')} />
      );
    case TransitionWizard.ActiveToInactive:
      return (
        <ActiveToInactive createTransition={createTransition} error={error} releaseDialog={releaseDialog('inactive')} />
      );
    case TransitionWizard.InactiveToActive:
      return (
        <InactiveToActive
          createTransition={props.createTransition}
          error={error}
          releaseDialog={releaseDialog('active')}
        />
      );
    case TransitionWizard.Retire:
      return (
        <Retire createTransition={props.createTransition} error={error} releaseDialog={releaseDialog('retired')} />
      );
    default:
      throw new Error(`unknown workflow transition: ${props.type}`);
  }
}

export default Wizard;
