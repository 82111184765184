import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme: any) => ({
  panelContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonsSection: {
    marginLeft: theme.spacing(1),
    '& > div': {
      marginRight: theme.spacing(0.5),
      display: 'inline-block',
    },
  },
}));
