import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';

import LinearScale from '@material-ui/icons/LinearScale';
import MoreVert from '@material-ui/icons/MoreVert';
import { Popper, ClickAwayListener } from '@material-ui/core';

import { Status, Order } from './statuses';
import { TransitionWizard } from './Wizard';
import { WorkflowIcon, ContentSection } from 'components';
import ConnectBar from 'compositions/TimelineGroup/components/ConnectBar';
import Ellipses, { ellipsesClickable } from './Ellipses';
import { useCurrentCompany } from 'compositions/WithCurrentCompany';
import WithCurrentUser from 'compositions/WithCurrentUser';
import { matchPermissionGrantForVersion } from 'compositions/TimelineGroup/util';
import useStyles from './styles';

interface VersionData {
  id: string;
  currentStatusName: string;
  releasedAt?;
}

function CurriculumTimeline(props: {
  currentVersion: VersionData;
  currentUser: object;
  startWizard: (t: TransitionWizard) => void;
}) {
  const { data } = useCurrentCompany();
  const classes = useStyles();
  const [popper, setPopper] = useState<SVGSVGElement | null>(null);
  const canTransition = get(data, 'currentCompany.allowTransitioning', false);
  const canOwn = matchPermissionGrantForVersion(props.currentUser, props.currentVersion, 'owner');

  const { id, currentStatusName } = props.currentVersion;

  const currentStatusOrder = Order[currentStatusName];
  if (currentStatusOrder === undefined) throw new Error(`invalid status for version ${id}: ${currentStatusName}`);

  return (
    <ContentSection
      scrollX
      LabelText={<Trans>Workflow</Trans>}
      Icon={<LinearScale />}
      Controls={
        <ClickAwayListener onClickAway={() => setPopper(null)}>
          <span style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -24 }}>
            <MoreVert
              className={ellipsesClickable(currentStatusName) ? classes.hoverPointer : classes.disabled}
              onClick={(e) => {
                e.stopPropagation();
                if (popper) setPopper(null);
                else if (currentStatusName !== Status.Planning && currentStatusName !== Status.Retired)
                  setPopper(e.currentTarget);
              }}
            />
            <Popper id="popper" anchorEl={popper} open={popper !== null} placement="bottom-end">
              <Ellipses status={currentStatusName} startWizard={props.startWizard} />
            </Popper>
          </span>
        </ClickAwayListener>
      }
    >
      <WorkflowIcon
        type="planning"
        active={currentStatusOrder === Order[Status.Planning]}
        completed={currentStatusOrder > Order[Status.Planning]}
        label="Planning"
        size="large"
        visible
      />

      <ConnectBar color="released" disabled={!canTransition || !canOwn} visible />

      <WorkflowIcon
        type="released"
        active={currentStatusOrder === Order[Status.Active]}
        completed={currentStatusOrder > Order[Status.Active]}
        label="Active"
        size="large"
        disabledEffect={!canTransition}
        disabled={!canTransition || !canOwn}
        visible
        handleClick={() => {
          if (currentStatusName === Status.Planning) return props.startWizard(TransitionWizard.PlanningToActive);
        }}
      />

      <ConnectBar color="obsolete" disabled={!canTransition} visible={currentStatusOrder >= Order[Status.Active]} />

      <WorkflowIcon
        type="obsolete"
        active={currentStatusOrder === Order[Status.Inactive]}
        completed={currentStatusOrder > Order[Status.Inactive]}
        label={currentStatusName === Status.Retired ? 'Retired' : 'Inactive'}
        size="large"
        disabledEffect={!canTransition}
        disabled={!canTransition}
        visible={currentStatusOrder >= Order[Status.Active]}
        handleClick={() => {
          if (currentStatusName === Status.Retired) return;

          const wiz =
            currentStatusName === Status.Active ? TransitionWizard.ActiveToInactive : TransitionWizard.InactiveToActive;

          props.startWizard(wiz);
        }}
      />
    </ContentSection>
  );
}

export default flowRight([WithCurrentUser])(CurriculumTimeline);
