import React from 'react';
import last from 'lodash/last';
import capitalize from 'lodash/capitalize';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';

import WorkflowIcon from 'components/WorkflowIcon';
import SmallCircle from './SmallCircle';
import ConnectBar from './ConnectBar';

interface Version {
  statuses: [{ name: string }];
  versionIdentifier: string;
  releasedAt: Date;
  retiredAt: Date;
}

interface PreviousReleasesProps {
  releasedVersions: [Version];
  onClick?: (v: Version) => void;
  generateSmallCircleContent?: any;
}

const PreviousReleases = ({ releasedVersions, onClick, generateSmallCircleContent }: PreviousReleasesProps) => (
  <>
    {(releasedVersions || []).map((v, i) => {
      const currentStatus = get(last(v.statuses), 'name');
      if (!currentStatus) {
        console.error('Unable to compute current status for previous release: ', v);
        return null;
      }
      const camelizedStatus = camelCase(currentStatus);
      let content = generateSmallCircleContent && generateSmallCircleContent(v);
      return (
        <span key={i}>
          {content && (
            <>
              <SmallCircle content={content} color="draft" handleClick={() => onClick && onClick(v)} />
              <ConnectBar color={camelizedStatus} short visible />
            </>
          )}
          <WorkflowIcon
            type={camelizedStatus}
            completed
            version={v.versionIdentifier}
            effectiveDate={v.releasedAt || v.retiredAt}
            label={currentStatus === 'effective' ? 'Released' : capitalize(currentStatus)}
            size="large"
            visible
          />
          {currentStatus !== 'retired' && <ConnectBar color={camelizedStatus} short visible />}
        </span>
      );
    })}
  </>
);

export default PreviousReleases;
