import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './trace.svg';

function Trace(props) {
  return (
    <SvgIcon
      {...props}
      children={<path />}
      component={(svgProps) => <Icon {...svgProps} />}
    />
  );
}

export default Trace;
