import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    noWrap: {
      whiteSpace: 'nowrap',
    },
    recordCell: {
      paddingLeft: 8,
      borderLeft: 'solid 2px #ebeced',
    },
    tableBody: {
      borderRadius: 4,
    },
    tableCell: {
      ...theme.typography.body2,
      padding: theme.spacing(1),
    },
    tableHeader: {
      ...theme.typography.body2,
      padding: theme.spacing(1),
      fontWeight: 600,
    },
    tableRow: {
      border: `2px solid ${theme.palette.common.input.outline}`,
    },
  }),
);
