import React from 'react';
import { camelCase, get } from 'lodash';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';

import { Trans } from '@lingui/macro';

import Grid from '@material-ui/core/Grid';
import Sort from '@material-ui/icons/Sort';
import ViewList from '@material-ui/icons/ViewList';
import AssignmentLate from '@material-ui/icons/AssignmentLate';
import List from '@material-ui/icons/List';

import { LongCard } from 'compositions';
import { ControlBar, ScreenshotRequired } from 'components/LongCardControls';
import { ContentSection, Spacer, TextAreaField } from 'components';
import updateTitleMutation from '../../utils/gql/updateTitleMutation';
import { itemTypeFields } from '../../utils/gql/metadataQueries';

import { GenericWorkflowStatuses, indexById } from 'workflows/statuses';

import mutation from 'utils/gql/createItemAndMember';

const metadataQuery = gql`
  query QSVALandQSTS {
    itemTypes(names: ["quality_system_validation_test_step"]) {
      ...itemTypeFields
    }
  }
  ${itemTypeFields}
`;

function hasEditableTitleField(itemType) {
  if (!itemType) return false;
  let titleIsUserEditable = false;
  get(itemType, 'fields', []).forEach((field) => {
    if (field.fieldName === 'title' && field.userEditable === true)
      titleIsUserEditable = true;
  });
  return titleIsUserEditable;
}

const PayloadFields = ({ versionData, layoutClasses, i18n }) => {
  const { Draft } = GenericWorkflowStatuses;
  const currentVersionStatus = versionData.currentStatusName;
  const GenericWorkflowStatusesById = indexById(GenericWorkflowStatuses);
  const currentWorkflowStatus =
    GenericWorkflowStatusesById[currentVersionStatus];

  return (
    <Query query={metadataQuery}>
      {({ data }) => {
        const itemTypeMetadata = get(data, 'itemTypes', []).reduce(
          (acc, itemType) => {
            if (itemType.name) acc[itemType.name] = itemType;
            return acc;
          },
          {},
        );

        return (
          <Mutation mutation={updateTitleMutation}>
            {(mutateTitle) => (
              <Mutation mutation={mutation}>
                {(createItemMutation) => {
                  const handleTitleChange = (versionId) => (newValue) => {
                    mutateTitle({
                      variables: {
                        input: {
                          versionId,
                          attrName: 'title',
                          stringValue: newValue,
                        },
                      },
                    });
                  };

                  return (
                    <>
                      <Grid item xs={12} className={layoutClasses.grid}>
                        <ContentSection
                          LabelText={
                            <>
                              <Sort className={layoutClasses.icon} />
                              <Trans>Protocol Objective</Trans>
                            </>
                          }
                        >
                          <Spacer factor={1} />
                          <TextAreaField
                            refetchQueries={[
                              'qualitySystemValidationProtocolQuery',
                            ]}
                            locked={versionData.locked}
                            id="validation-protocol-objective-text-field"
                            initialValue={versionData.protocolObjective}
                            versionId={versionData.id}
                            attrName={'protocol_objective'}
                            rows={4}
                            className={layoutClasses.textField}
                          />
                        </ContentSection>
                      </Grid>
                      <Grid item xs={12} className={layoutClasses.grid}>
                        <ContentSection
                          LabelText={
                            <>
                              <AssignmentLate className={layoutClasses.icon} />
                              <Trans>Prerequisite Notes</Trans>
                            </>
                          }
                        >
                          <Spacer factor={1} />
                          <TextAreaField
                            refetchQueries={[
                              'qualitySystemValidationProtocolQuery',
                            ]}
                            locked={versionData.locked}
                            id="validation-protocol-prerequisite-text-field"
                            initialValue={versionData.prerequisiteNotes}
                            versionId={versionData.id}
                            attrName={'prerequisite_notes'}
                            rows={4}
                            className={layoutClasses.textField}
                          />
                        </ContentSection>
                      </Grid>
                      <Grid item xs={12} className={layoutClasses.grid}>
                        <ContentSection
                          LabelText={
                            <>
                              <List className={layoutClasses.icon} />
                              <Trans>Test Setup Notes</Trans>
                            </>
                          }
                        >
                          <Spacer factor={1} />
                          <TextAreaField
                            refetchQueries={[
                              'qualitySystemValidationProtocolQuery',
                            ]}
                            locked={versionData.locked}
                            id="validation-protocol-test-setup-notes-text-field"
                            initialValue={versionData.testSetupNotes}
                            versionId={versionData.id}
                            attrName={'test_setup_notes'}
                            rows={4}
                            className={layoutClasses.textField}
                          />
                        </ContentSection>
                      </Grid>
                      <Grid item xs={12} className={layoutClasses.grid}>
                        <ContentSection
                          BigHeading={<Trans>Test Steps</Trans>}
                          BigHeadingIcon={<ViewList />}
                          padding={false}
                          fillHeight={false}
                        />
                        {get(
                          versionData,
                          'qualitySystemValidationTestSteps',
                          [],
                        ).map((ts) => {
                          return (
                            <>
                              <LongCard
                                key={ts.currentVersion.id}
                                versionId={ts.currentVersion.id}
                                title={
                                  ts.currentVersion.title || 'Default Title'
                                }
                                customIdentifier={ts.customIdentifier}
                                primaryBodyText={{
                                  attrName: 'step_text',
                                  attrValue: ts.currentVersion.stepText,
                                }}
                                secondaryBodyText={{
                                  attrName: 'acceptance_criteria',
                                  attrValue:
                                    ts.currentVersion.acceptanceCriteria,
                                }}
                                onTitleChange={
                                  hasEditableTitleField(
                                    itemTypeMetadata.quality_system_validation_test_step,
                                  )
                                    ? handleTitleChange(ts.currentVersion.id)
                                    : undefined
                                }
                                rightColumnComponent={
                                  <ScreenshotRequired
                                    locked={ts.currentVersion.locked}
                                    screenshotRequiredValue={
                                      ts.currentVersion.screenshotRequired
                                    }
                                    versionId={ts.currentVersion.id}
                                    queryToRefetch="qualitySystemValidationTestStepQuery"
                                  />
                                }
                                locked={versionData.locked}
                                queryToRefetch="qualitySystemValidationProtocolQuery"
                                variant="horizontal"
                                itemType={camelCase(ts.itemType.name)}
                                itemRecord={ts}
                                parentSetItemSlug="validation-protocols"
                              />
                            </>
                          );
                        })}
                        {currentWorkflowStatus &&
                          currentWorkflowStatus.isEqualTo(Draft) && (
                            <ControlBar
                              label="Add Member"
                              onClickPlus={() =>
                                createItemMutation({
                                  variables: {
                                    input: {
                                      productId: 1,
                                      parentCollectionId:
                                        versionData.qualitySystemValidationTestStepsCollectionId,
                                      itemType:
                                        'quality_system_validation_test_step',
                                      title: 'Default Title',
                                    },
                                  },
                                  refetchQueries: [
                                    'qualitySystemValidationProtocolQuery',
                                  ],
                                })
                              }
                            />
                          )}
                      </Grid>
                    </>
                  );
                }}
              </Mutation>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default PayloadFields;
