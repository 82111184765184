import React from 'react';

import { Trans } from '@lingui/macro';

import Grid from '@material-ui/core/Grid';

import ControlCamera from '@material-ui/icons/ControlCamera';
import Sort from '@material-ui/icons/Sort';

import { ContentSection, MultiSelectField, Spacer, TextAreaField } from 'components';

import { options, placeholder } from './picklist';

const PayloadFields = ({ layoutClasses, versionData: currentVersion, displayOnly, ...rest }) => (
  <>
    <Grid item xs={8} className={layoutClasses.gridGutterRight}>
      <ContentSection
        LabelText={
          <>
            <Sort className={layoutClasses.icon} />
            <Trans>Requirement Description</Trans>
          </>
        }
      >
        <Spacer factor={1} />
        <TextAreaField
          locked={currentVersion.locked || currentVersion.currentStatus.name !== 'unlocked'}
          refetchQueries={['requirementQuery']}
          id="requirement-description"
          initialValue={currentVersion.requirementDescription}
          versionId={currentVersion.id}
          attrName={'requirement_description'}
          rows={8}
          placeholder="Enter description"
        />
      </ContentSection>
    </Grid>

    <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
      <ContentSection
        LabelText={
          <>
            <ControlCamera className={layoutClasses.icon} />
            <Trans>Requirement Type</Trans>
          </>
        }
        fillHeight={false}
      >
        <Spacer factor={1} />
        <MultiSelectField
          displayOnly={displayOnly}
          attrName={'requirement_types'}
          options={options}
          refetchQueries={['requirementQuery']}
          versionId={currentVersion.id}
          locked={currentVersion.locked || currentVersion.currentStatus.name !== 'unlocked'}
          value={currentVersion.requirementTypes}
          placeholder={placeholder.id}
        />
      </ContentSection>
    </Grid>
  </>
);

export default PayloadFields;
