import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      color: 'rgba(0,0,0,0.6)',
      textAlign: 'center',
      marginBottom: 24,
      '&.left': {
        textAlign: 'left',
      },
      '&:first-letter': {},
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    message: {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
    heading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 16,
      textAlign: 'center',
    },
    icon: {
      fontSize: '3rem',
      height: 48,
      marginBottom: 24,
    },
    trainingRecordList: {
      listStyle: 'none',
      padding: 0,
    },
  }),
);
