import React from 'react';
import { Trans } from '@lingui/macro';

import { CertifiedSignature, YesOrNo } from 'compositions/TimelineGroup/steps/views';
import { DeviationWorkflowStatuses } from 'workflows/statuses';

export default {
  initialStep: 'takeOwnership',
  internalSteps: {
    takeOwnership: {
      component: (props) => (
        <YesOrNo
          disableOnSubmit
          prompt={`Do you want to take ownership of version ${props.currentVersion.versionIdentifier}
          of ${props.itemCustomIdentifier} or unlock and send to Draft status?`}
          yesLabel="Approve"
          noLabel="Unlock"
          {...props}
        />
      ),
      getNextStep: (takeOwnership) => (takeOwnership ? 'signature' : 'complete'),
    },
    signature: {
      component: (props) => {
        const heading = <Trans>Ownership Declaration</Trans>;
        const dialog = `As the ${props.currentVersion.assignedOwnerJobRole.formattedRoleName}, I take ownership of ${
          props.itemCustomIdentifier
        }.`;

        return <CertifiedSignature heading={heading} dialog={dialog} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ takeOwnership, changeSummary, signature }) => {
    if (takeOwnership) {
      return {
        ...changeSummary,
        toStatus: DeviationWorkflowStatuses.ApproveToProceed.id,
        ...signature,
      };
    } else {
      return {
        toStatus: DeviationWorkflowStatuses.Identify.id,
      };
    }
  },
};
