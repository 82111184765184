import { createStyles, makeStyles } from '@material-ui/core';

const doFill = {
  position: 'absolute !important' as any,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      paddingLeft: '0 !important',
    },
    riskControlCell: {
      width: '100%',
      '& .MuiAutocomplete-popper': {
        border: '1px solid #000',
      },
    },
    harmAndHazardCell: {
      '& .MuiChip-root': {
        height: '100%',
      },
      '& .MuiChip-label': {
        display: 'block',
        whiteSpace: 'normal',
      },
    },
    iconRow: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(1),
    },
    fillCell: {
      ...doFill,
      '& .MuiAutocomplete-root': {
        ...doFill,
      },
      '& .MuiTextField-root': {
        ...doFill,
      },
      '& .MuiInputBase-root': {
        padding: '0 !important',
      },
      '& .MuiInputBase-input': {
        height: '100%',
      },
    },
    tableContainer: {
      '& > .MuiPaper-root': {
        boxShadow: 'none',
      },
      '& table': {
        paddingTop: 42,
      },
      '& td:not(:last-child)': {
        borderRight: '1px solid #dfdfdf',
      },
      '& .MuiTableCell-root': {
        position: 'relative',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    icon: {
      display: 'inline-block !important',
    },
    header: {
      '& > svg': {
        marginRight: 6,
      },
      '& > span': {
        verticalAlign: 'middle',
      },
    },
    rpc: {
      '& *': {
        fontSize: 9,
        cursor: 'arrow',
      },
      '& .MuiInputBase-root': {
        padding: '0 !important',
      },
      '& .MuiInputBase-input': {
        padding: '5px !important',
      },
      '& .MuiChip-root': {
        marginLeft: 14,
      },
    },
    textField: {
      '& .MuiInputBase-root': {
        border: 'none',
        background: 'none',
        fontSize: 9,
      },
    },
    otherControls: {
      display: 'inline-block',
      position: 'relative',
      '& .OtherControls-popup': {
        position: 'absolute',
        left: '0',
        top: '100%',
        display: 'flex',
        '& button': {
          padding: 0,
        },
      },
      marginRight: 5,
    },
    idCell: {
      display: 'flex',
    },
  }),
);
