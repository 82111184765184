import React from "react";

import { Card, Typography } from "@material-ui/core";

import Table from "components/core/Table";
import MakeLink from "components/MakeLink";
import { DateCell, dateHeaderStyle } from "components/TableView/Fields/Date";
import { AvatarCell, avatarHeaderStyle } from "components/TableView/Fields/Avatar";
import { StatusCell, statusHeaderStyle } from "components/TableView/Fields/Status";

import useStyles from "./styles";

interface AssociatedVersionsProps {
  associatedVersions: any[];
}

const AssociatedVersions = (props: AssociatedVersionsProps) => {
  const { associatedVersions } = props;

  const classes = useStyles();
  return (
    <div>
      <div className={classes.tableContainer}>
        {associatedVersions.length > 0 ? (
          <Table
            data={associatedVersions}
            columns={[
              {
                title: "ID",
                field: "id",
                render: version => (
                  <MakeLink item={version.item} newTab>
                    {version.item.customIdentifier}
                  </MakeLink>
                )
              },
              {
                title: "Title",
                field: "title"
              },
              {
                title: "Initiation Date",
                field: "createdAt",
                headerStyle: dateHeaderStyle,
                render: version => (
                  <DateCell data={version.createdAt} />
                )
              },
              {
                title: "Owner",
                field: "owners",
                headerStyle: avatarHeaderStyle as any,
                render: version => (
                  <AvatarCell data={version.owners} />
                )
              },
              {
                title: "Approver",
                field: "approvers",
                headerStyle: avatarHeaderStyle as any,
                render: version => (
                  <AvatarCell data={version.approvers} />
                )
              },
              {
                field: 'currentStatus.name',
                title: 'Status',
                headerStyle: statusHeaderStyle,
                render: version => (
                  <StatusCell data={version.currentStatus.name} />
                )
              },
            ]}
          />
        ) : (
          <Card className={classes.noItemsPanel} variant={"outlined"}>
            <Typography variant="caption">No versions associated.</Typography>
          </Card>
        )}
      </div>
    </div>
  );
};

export default AssociatedVersions;
