import React from 'react';
import { Typography, createStyles, makeStyles } from '@material-ui/core';

import { Toggle } from 'components';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

interface DisciplineToggleProps {
  checked?: boolean | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: theme.palette.secondary.main,
      fontSize: 20,
    },
    toggle: {
      marginLeft: 4,
    },
  }),
);

const DisciplineToggle: React.FC<DisciplineToggleProps> = ({ checked, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ViewModuleIcon className={classes.icon} />
      <Typography variant="body2">Discipline Grouping</Typography>
      <Toggle size="medium" onChange={onChange} checked={checked || false} className={classes.toggle} />
    </div>
  );
};

export default DisciplineToggle;
