import React from 'react';

import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';

import _ from 'lodash';

import MakeLink from 'components/MakeLink';
import Table from 'components/core/Table';
import { TriStateToggle } from 'components';
import UserMultiSelect from 'components/UserMultiSelect';

import { useUpdateImpactAssessmentMutation } from '../gql';
import { useStyles } from '../styles';

interface Props {
  displayOnly: boolean;
  rows: any[];
}

// more like entries now
function NoCourseItems(props: Props) {
  const { displayOnly } = props;
  const classes = useStyles();
  const [updateImpactAssessmentMutation] = useUpdateImpactAssessmentMutation();

  const updateSelectedUsers = (entity, userIds) => {
    updateImpactAssessmentMutation({
      variables: {
        id: entity.id,
        userIds: userIds,
      },
    }).catch((e) => {
      throw new Error(`Table B associated users change failed: ${e.message}`);
    });
  };

  let rows = _.sortBy(props.rows, (x) => x.id); // backend could do
  rows.forEach((row) => {
    row.users = row.trainingImpactMasterEntries.map((x) => x.user.fullName).join('|');
  });

  return (
    <Table
      sortable
      data={rows}
      localization={{
        body: {
          emptyDataSourceMessage: <Typography variant="body2">N/A</Typography>,
        },
      }}
      columns={[
        {
          title: 'ID',
          field: 'impactedVersion.item.customIdentifier',
          width: '85px',
          render: (row) => (
            <Tooltip title={`${row.impactedVersion.item.customIdentifier}:${row.impactedVersion.title}`}>
              <span>
                <MakeLink params={{ displayOnly }} item={row.impactedVersion.item}>
                  {row.impactedVersion.item.customIdentifier}
                </MakeLink>
              </span>
            </Tooltip>
          ),
        },
        {
          title: 'Read and Understand',
          align: 'center',
          width: '150px',
          render: (row) => (
            <>
              <span className={classes.leftSpan}>No</span>
              <TriStateToggle
                disabled={displayOnly}
                toggled={row.requiresReadTraining}
                onChange={(value) => {
                  updateImpactAssessmentMutation({
                    variables: {
                      id: row.id,
                      requiresReadTraining: value === undefined ? null : value,
                    },
                  }).catch((e) => {
                    throw new Error(`Category change failed: ${e.message}`);
                  });
                }}
              />
              <span className={classes.rightSpan}>Yes</span>
            </>
          ),
        },
        {
          title: 'Assigned Users',
          field: 'users',
          cellStyle: {
            paddingLeft: 8,
            borderLeft: 'solid 2px #ebeced',
          },
          render: (row) => (
            <>
              {row.requiresReadTraining || row.requiresReadTraining === null ? (
                <UserMultiSelect
                  valueIds={row.trainingImpactMasterEntries.map((x) => x.user.id)}
                  onChange={(selections) => updateSelectedUsers(row, selections.map((user) => user.id))}
                  disabled={row.requiresReadTraining === null || displayOnly}
                  placeholder={
                    !row.trainingImpactMasterEntries.length
                      ? 'Search by name to select user(s) to assign Read & Understand Training'
                      : ''
                  }
                />
              ) : (
                'n/a'
              )}
            </>
          ),
        },
      ]}
    />
  );
}

export default NoCourseItems;
