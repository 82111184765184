import React from 'react';

import { CertifiedSignature } from 'compositions/TimelineGroup/steps/views';

import formatApolloError from 'utils/formatApolloError';
import { Status, ServerAction, TransitionPayload } from '../statuses';

function Retire(props: { error?: Error; createTransition: (data: TransitionPayload) => void; releaseDialog: any }) {
  return (
    <CertifiedSignature
      itemTypeMetadata={{
        name: 'curriculum',
      }}
      dialog={props.releaseDialog}
      error={props.error}
      errorDisplay={formatApolloError(props.error)}
      heading="Retire Curriculum"
      handleComplete={(data: { username: string; password: string; reasonForSignature: string }) => {
        props.createTransition({
          action: ServerAction.StatusTransition,
          payload: {
            toStatus: Status.Retired,
            ...data,
          },
        });
      }}
    />
  );
}

export default Retire;
