import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';

import { Spacer } from 'components';
import TableTrio from 'components/TableTrio';

import { LockedContext } from 'contexts/lockedContext';

import {
  useAddReferencedAttachment,
  useUpdateReferencedAttachment,
  useRemoveReferencedAttachment,
  useAddReferencedItem,
  useRemoveReferencedItem,
  useAddReferencedVersion,
  useRemoveReferencedVersion,
  useUpdateReferencedVersion
} from 'components/TableTrio/gql';

import { DeviationVersionQuery_deviationVersion } from '../../__generated__/DeviationVersionQuery';
import { Views } from 'workspaces';

interface Props {
  currentVersion: DeviationVersionQuery_deviationVersion;
  viewType: string;
}

export default function DeviationInformationTab(props: Props) {
  const { currentVersion, viewType } = props;

  const createAttachment = useAddReferencedAttachment();
  const updateAttachment = useUpdateReferencedAttachment();
  const destroyAttachment = useRemoveReferencedAttachment();

  const addAssociatedItem = useAddReferencedItem();
  const removeAssociatedItem = useRemoveReferencedItem();

  const addAssociatedItemVersion = useAddReferencedVersion();
  const removeAssociatedItemVersion = useRemoveReferencedVersion();
  const updateAssociatedItemVersion = useUpdateReferencedVersion();

  const { locked } = useContext(LockedContext);

  const noTrioReferencedAttachments = currentVersion.trioReferencedAttachments.length === 0;
  const noTriotrioReferencedItems = currentVersion.trioReferencedItems.length === 0;
  const noTrioReferencedVersions = currentVersion.trioReferencedVersions.length === 0;

  const noTrioData = noTrioReferencedAttachments && noTriotrioReferencedItems && noTrioReferencedVersions;
  const isEffectiveView = viewType === Views.Effective.id;

  return (
    <>
      <>
        {noTrioData && !isEffectiveView && (
          <>
            <Typography variant='h5'>Click the button below to add reference material</Typography>
            <Spacer factor={2} />
          </>
        )}
        <TableTrio
          currentVersion={currentVersion}
          locked={locked}
          attachmentsTableProps={{
            handlers: {
              create: (versionId, attachment) => {
                return createAttachment({
                  refetchQueries: ['DeviationVersionQuery'],
                  variables: { versionId, attachment }
                });
              },
              update: (attachmentId, attachment) => {
                return updateAttachment({
                  refetchQueries: ['DeviationVersionQuery'],
                  variables: {
                    id: attachmentId,
                    attachment: attachment.url,
                    description: attachment.description
                  }
                });
              },
              destroy: attachmentId => {
                return destroyAttachment({
                  refetchQueries: ['DeviationVersionQuery'],
                  variables: { id: attachmentId }
                });
              }
            }
          }}
          itemsTableProps={{
            handlers: {
              remove: (id: string) => {
                return removeAssociatedItem({
                  refetchQueries: ['DeviationVersionQuery'],
                  variables: {
                    versionId: currentVersion.id,
                    referencedItemId: id
                  }
                });
              },
              addAssociatedItem: (id: string) => {
                return addAssociatedItem({
                  refetchQueries: ['DeviationVersionQuery'],
                  variables: {
                    versionId: currentVersion.id,
                    referencedItemIds: id
                  }
                });
              }
            }
          }}
          versionsTableProps={{
            handlers: {
              removeAssociatedItemVersion: (id: string) => {
                return removeAssociatedItemVersion({
                  refetchQueries: ['DeviationVersionQuery'],
                  variables: {
                    versionId: currentVersion.id,
                    referencedVersionId: id
                  }
                });
              },
              updateAssociatedItemVersion: (next, old) => {
                return updateAssociatedItemVersion({
                  refetchQueries: ['DeviationVersionQuery'],
                  variables: {
                    nextVersionId: next,
                    oldVersionId: old,
                    versionId: currentVersion.id,
                  }
                });
              },
              addAssociatedItemVersion: (versionId: string) => {
                return addAssociatedItemVersion({
                  refetchQueries: ['DeviationVersionQuery'],
                  variables: {
                    versionId: currentVersion.id,
                    referencedVersionIds: versionId
                  }
                });
              }
            }
          }}
        />
      </>
    </>
  );
}
