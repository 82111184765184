export default (theme) => ({
  bottomIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  iconButton: {
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.blueDark,
      backgroundColor: 'transparent',
    },
  },
  iconRow: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  },
  smallIcon: {
    width: 20,
    height: 20,
  },
});
