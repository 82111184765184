import React, { FC, useEffect, useState } from 'react';

import { useMutation } from 'react-apollo';
import { Grid, Typography } from '@material-ui/core';

import { useCurrentCompany } from 'compositions/WithCurrentCompany';
import CompanyLogo from './CompanyLogo';

import { useStyles } from './styles';
import { getCompanyLogoUploadUrl } from 'utils/apiUrls';
import { getAuthToken } from 'utils';
import { createUpdateData, getCompanyUrl } from '../../utils';
import { UPDATE_COMPANY_QUERY } from '../../gql';
import TimeZone from './TimeZone';
import { EditProps } from '../../types';

interface Props extends EditProps {
  collapsed: boolean;
}

const BasicInformation: FC<Props> = ({ collapsed, editingComponent, onEdit }) => {
  const MAX_FILE_SIZE = 10485760;
  const classes = useStyles();
  const [updateCompany] = useMutation(UPDATE_COMPANY_QUERY, {
    refetchQueries: ['withCurrentCompany_currentCompanyQuery'],
  });
  const { data, refetch } = useCurrentCompany();

  const [editLogo, setEditLogo] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [logoError, setLogoError] = useState<string | null>(null);
  const [logoSuccess, setLogoSuccess] = useState<string | null>(null);
  const [capturedFileName, setCapturedFileName] = useState('');

  const logoUrl = data && data.currentCompany.logoUrl;
  useEffect(() => {
    if (data) setEditLogo(!data.currentCompany.logoUrl);
  }, [data, logoUrl]);

  useEffect(() => {
    if (!collapsed) {
      onEdit(null);
    }
  }, [collapsed, onEdit]);

  if (!data) return null;
  const { currentCompany } = data;

  const uploadLogo = async (file: File) => {
    try {
      setLogoError(null);
      const [authToken] = getAuthToken();
      const formData = new FormData();
      formData.append('file', file);
      const resp = await fetch(getCompanyLogoUploadUrl(), {
        credentials: 'same-origin',
        headers: {
          Authorization: authToken!,
        },
        method: 'POST',
        body: formData,
      });
      if (!resp.ok) throw new Error(`Upload failed: ${resp.statusText}`);

      await refetch();
      setLogoSuccess('Logo updated');
      setCapturedFileName('');
      setTimeout(() => {
        setLogoSuccess(null);
      }, 2000);
    } catch (error) {
      setLogoError('Encountered an error while uploading logo!');
      throw new Error(`Failed to upload logo: ${error}`);
    }
  };

  const removeLogo = async () => {
    const [authToken] = getAuthToken();
    await fetch(getCompanyLogoUploadUrl(), {
      credentials: 'same-origin',
      headers: {
        Authorization: authToken!,
      },
      method: 'DELETE',
    });

    await refetch();
    setConfirmationDialog(false);
  };

  const handleCapture = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = event.currentTarget;
    if (currentTarget && currentTarget.files && currentTarget.files.length) {
      try {
        setLogoError(null);
        const file = currentTarget.files[0];
        if (file.size > MAX_FILE_SIZE) throw new Error('Error! Max. 10 MB');
        setCapturedFileName(file.name);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        await uploadLogo(file);
      } catch (error) {
        setLogoError((error as Error).message);
      }
    }
  };

  const handleTimeZoneChange = async (timeZone: string) => {
    await updateCompany({ variables: createUpdateData({ ...currentCompany, timeZone }) });
    onEdit(null);
  };

  const handleLogoEdit = () => {
    onEdit(editingComponent === 'company_logo' ? null : 'company_logo');
  };

  const handleFocusBack = () => {
    onEdit(null);
    window.removeEventListener('focus', handleFocusBack);
  };

  const handleLogoUploadClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (editingComponent !== 'company_logo') {
      event.preventDefault();
      onEdit('company_logo');
      window.addEventListener('focus', handleFocusBack);
    }
  };

  const handleTimeZoneEdit = () => {
    onEdit(editingComponent === 'timezone' ? null : 'timezone');
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <div className={classes.companyUrlContainer}>
          <div className={classes.labelContainer}>
            <Typography variant="h5" className={classes.label}>
              Company URL
            </Typography>
          </div>
          <Typography variant="body2">{getCompanyUrl()}</Typography>
        </div>
      </Grid>
      <Grid item xs={4}>
        <CompanyLogo
          editLogo={editingComponent === 'company_logo' || editLogo}
          error={logoError}
          success={logoSuccess}
          dialogOpen={confirmationDialog}
          capturedFileName={capturedFileName}
          setEditLogo={handleLogoEdit}
          onUploadClick={handleLogoUploadClick}
          onCapture={handleCapture}
          setConfirmationDialog={setConfirmationDialog}
          onDialogConfirm={removeLogo}
        />
      </Grid>
      <Grid item xs={4}>
        <TimeZone
          timeZone={currentCompany.timeZone}
          editTimeZone={editingComponent === 'timezone'}
          availableTimeZones={currentCompany.availableTimeZones}
          onChange={handleTimeZoneChange}
          setEditTimeZone={handleTimeZoneEdit}
        />
      </Grid>
    </Grid>
  );
};

export default BasicInformation;
