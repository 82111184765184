import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import slugs from '../../displaySlugs';

import { listFragment, docMetadata } from '../mixins/Document';
const itemType = 'nqmsTraceMatrixDoc';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`nQMS Trace Matrix Document`,
  categoryLabel: t`nQMS Trace Matrix Docs`,
  icon: LibraryBooks,
  listViewQuery: gql`
    query nqmsTraceMatrixDocsQuery {
      documents(itemType: "nqms_trace_matrix_doc") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
