import React, { useState } from 'react';

import { Button, withStyles } from '@material-ui/core';
import { AddCircle, Folder } from '@material-ui/icons';

import AddMember from 'components/LongCardControls/ControlBar/AddMember';
import CreateFolderForm from 'components/CreateFolderForm';

import styles from './styles';

interface Props {
  titleCreateMember: string;
  titleAddMember: string;
  memberItemType: string[];
  itemSetsIcon: any;
  onAddMember: any;
  onCreateMember: any;
  versionData: any;
  refetchQueries: string[];
  shouldShowCreateFolderButton?: boolean;
  onCreateFolder?: any;
  classes: any;
}

function SetsControlBar({
  shouldShowCreateFolderButton = false,
  onCreateFolder = () => {},
  refetchQueries,
  versionData,
  onCreateMember,
  onAddMember,
  itemSetsIcon,
  memberItemType,
  titleAddMember,
  titleCreateMember,
  classes,
}: Props) {
  const [popper, setPopper] = useState({ anchorEl: null });
  const popperOpen = Boolean(popper.anchorEl);
  const memberIds =
    versionData.__typename === 'RiskAnalysisVersion'
      ? versionData.memberVersions.map((child) => child.version && child.version.id)
      : versionData.setItemChildren.map((child) => child.version && child.version.id);

  const [createFolderFormOpen, setCreateFolderFormOpen] = useState<boolean>(false);

  return (
    <>
      {!versionData.locked && (
        <>
          <AddMember
            open={popperOpen}
            anchorEl={popper.anchorEl}
            closeSelf={() => setPopper({ anchorEl: null })}
            onClick={(evt) => setPopper({ anchorEl: evt.target })}
            itemSetsIcon={itemSetsIcon}
            existingMemberIds={memberIds}
            create={onAddMember}
            refetchQueries={refetchQueries}
            memberItemType={memberItemType}
            title={titleAddMember}
          />
          <Button onClick={onCreateMember} variant="outlined" startIcon={<AddCircle />} className={classes.button}>
            {titleCreateMember}
          </Button>
          {shouldShowCreateFolderButton && (
            <>
              <Button
                onClick={() => setCreateFolderFormOpen(true)}
                startIcon={<Folder />}
                variant="outlined"
                className={classes.button}
              >
                Create Folder
              </Button>
              <CreateFolderForm
                open={createFolderFormOpen}
                handleClose={() => setCreateFolderFormOpen(false)}
                onCreateFolder={onCreateFolder}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default withStyles(styles)(SetsControlBar);
