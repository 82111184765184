import React from 'react';
import flowRight from 'lodash/flowRight';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Trans } from '@lingui/macro';

import styles from './styles';
import { Spacer } from 'components';

import Signature from './Signature';
import withCurrentUser from '../../../WithCurrentUser';

class CancelRetirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
    };
  }
  render() {
    const { classes, error, handleComplete } = this.props;
    const { username, password } = this.state;

    return (
      <>
        <div className={classes.heading}>
          <Typography variant="h3">
            <Trans>Cancel Retirement</Trans>
          </Typography>
        </div>

        <Spacer factor={2} />

        <Typography variant="body2">
          Are you sure you want to Cancel Retirement of this item? This will void your signature initiating retirement.
        </Typography>

        <Spacer factor={2} />

        <Spacer factor={5} />

        <Signature
          itemType={this.props.itemTypeMetadata.name}
          error={error}
          disabled={!this.state.username || !this.state.password}
          handleSubmit={() =>
            handleComplete({
              signature: {
                username,
                password,
              },
            })
          }
          onUsernameChange={(e) => this.setState({ username: e.target.value })}
          onPasswordChange={(e) => this.setState({ password: e.target.value })}
        />
      </>
    );
  }
}

export default flowRight([withStyles(styles), withCurrentUser])(CancelRetirement);
