export default (theme) => ({
  bgImage: {
    maxWidth: '50%',
    position: 'fixed',
    bottom: 0,
    right: 0,
  },
  container: {
    position: 'relative',
    textAlign: 'center',
  },
  emailLink: {
    color: theme.palette.primary.main,
    lineHeight: '36px',
    fontWeight: 400,
    '&:hover': {
      opacity: 0.8,
    },
  },
  text: {
    position: 'absolute',
    top: '125px',
    width: '50%',
    textAlign: 'left',
  },
});
