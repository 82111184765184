import React, { Component } from 'react';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';
import { Query } from 'react-apollo';

import { withStyles } from '@material-ui/core';

import TimelineMutations from './components/TimelineMutations';
import WorkflowModal from '../../components/WorkflowModal';
import { TraceMatrixWorkflowStatuses } from 'workflows/statuses';

import { query as timelineQuery } from 'utils/gql/timelineQueries';

import withMetadataFromServer from '../WithMetadataFromServer';
import WithCurrentUser from 'compositions/WithCurrentUser';
import { stepCustomWidth, matchPermissionGrantForVersion } from 'compositions/TimelineGroup/util';

import Wizard from './steps/Wizard';
import WORKFLOWS from './Workflows';
import styles from './styles';

interface TimelineGroupProps {
  itemType: string;
  itemId: string;
  classes: any;
  itemData?: any;
  loading?: boolean;
  remoteItemTypeMetadata;
  currentUser: object;
  queriesToRefetch: string[];
}

interface TimelineGroupState {
  selectedTransition: string | null;
  scrolledOnce: boolean;
}

class TimelineGroup extends Component<TimelineGroupProps, TimelineGroupState> {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  state = {
    selectedTransition: null,
    scrolledOnce: false,
  };

  constructor(props: TimelineGroupProps) {
    super(props);
    this.scrollContainerRef = React.createRef();
  }

  scrollWorkflowToCurrent = () => {
    if (!this.state.scrolledOnce) {
      const workflowScrollContainer = this.scrollContainerRef;
      if (workflowScrollContainer && workflowScrollContainer.current) {
        // set position of scroll container to last item based on container width
        workflowScrollContainer.current.scrollLeft = workflowScrollContainer.current.scrollWidth;
      }

      this.setState({ scrolledOnce: true });
    }
  };

  componentDidUpdate() {
    this.scrollWorkflowToCurrent();
  }

  handleInitTransition = (stepName) => {
    this.setState({ selectedTransition: stepName });
  };

  closeModal = () => {
    this.setState({ selectedTransition: null });
  };

  releasedVersions = (item) => {
    if (!item.versions.length) return [];
    return item.versions.filter(
      (r) => r.releasedAt !== null || r.currentStatusName === TraceMatrixWorkflowStatuses.Canceled.id,
    );
  };

  render() {
    const {
      classes,
      currentUser,
      itemData,
      itemType,
      loading,
      remoteItemTypeMetadata,
      queriesToRefetch = [],
    } = this.props;
    const { selectedTransition } = this.state;
    if (loading) return null;

    const workflowType = get(remoteItemTypeMetadata, 'workflow.name');
    if (!itemData) throw new Error(`No timeline data returned for ${itemType}`);

    // FIXME: ideally, the server can return data in the exact format needed
    const { currentVersion } = itemData;
    const releasedVersions = this.releasedVersions(itemData);

    if (!WORKFLOWS[workflowType]) throw new Error(`Unsupported workflow type: ${workflowType}`);

    const permissions = {
      canApprove: matchPermissionGrantForVersion(currentUser, currentVersion, 'approver'),
      canOwn: matchPermissionGrantForVersion(currentUser, currentVersion, 'owner'),
    };

    const { workflow, WorkflowComponent } = WORKFLOWS[workflowType];
    if (selectedTransition && !workflow[selectedTransition])
      throw new Error(`Unknown workflow step ${selectedTransition} for ${workflowType}`);

    return (
      <div className={classes.timelineBtnContainer} ref={this.scrollContainerRef}>
        <WorkflowComponent
          itemType={itemType}
          currentVersion={currentVersion}
          releasedVersions={releasedVersions}
          handleInitTransition={this.handleInitTransition}
          queriesToRefetch={queriesToRefetch}
          itemId={itemData.id}
          permissions={permissions}
          itemData={itemData}
        />
        <WorkflowModal open={Boolean(selectedTransition)} onClose={this.closeModal}>
          <TimelineMutations refetch={queriesToRefetch}>
            {({ error, ...mutationFunctions }) => {
              const handleCompleteTransition = async (nextItemData, versionId, mutationFunctions) => {
                const { createTransition, createVersion, updateTimeline } = mutationFunctions;

                const { itemId } = this.props;

                let mutate;
                let input = { versionId, ...nextItemData };
                if (nextItemData === null) {
                  this.closeModal();
                  return;
                } else if (nextItemData.toStatus) {
                  mutate = createTransition;
                } else if (nextItemData.gqlMethod === 'createVersion') {
                  mutate = createVersion;
                  if (itemId) {
                    input = { itemId };
                  }
                } else {
                  mutate = updateTimeline;
                }

                return (
                  mutate({ variables: { input } })
                    .then(() => {
                      if (!error) this.closeModal();
                    })
                    // errors are handled by Apollo middlweare
                    .catch((err) => {
                      throw new Error(`Error performing workflow action: ${err}`);
                    })
                );
              };

              return (
                <Wizard
                  width={stepCustomWidth(selectedTransition)}
                  error={error}
                  itemTypeMetadata={remoteItemTypeMetadata}
                  onCompleteTransition={async (nextItemData) =>
                    await handleCompleteTransition(nextItemData, currentVersion.id, mutationFunctions)
                  }
                  onAbandonTransition={this.closeModal}
                  steps={workflow[selectedTransition]}
                  currentVersion={currentVersion}
                  customIdentifier={itemData.customIdentifier}
                />
              );
            }}
          </TimelineMutations>
        </WorkflowModal>
      </div>
    );
  }
}

function withData(Component) {
  return (props: TimelineGroupProps) => {
    return (
      <Query
        errorPolicy="all"
        query={timelineQuery}
        variables={{ itemId: props.itemId }}
        fetchPolicy="no-cache"
        returnPartialData={false}
      >
        {(result) => {
          const { data, loading, error } = result;
          if (error) throw new Error(`Error in timeline query:\n${error}`);

          return <Component {...props} itemData={get(data, 'item')} loading={loading} />;
        }}
      </Query>
    );
  };
}

export default flowRight([withStyles(styles), withMetadataFromServer(), WithCurrentUser, withData])(TimelineGroup);
