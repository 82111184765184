import React, { useContext, useEffect } from 'react';

import { Grid } from '@material-ui/core';

import { DocumentBuilder } from 'compositions';
import Panel from 'components/core/Panel';
import { DocumentStyleUploader, PDFViewer } from 'components';
import { LockedContext, withLockedContext } from 'contexts/lockedContext';
import { PayloadFieldsProps } from 'itemTypes';

import AssociatedCybersecurityPlanCard from './AssociatedCybersecurityPlanCard/index';
import { useVersion } from './gql';
import { InputMethod } from '__generated__/globalTypes';

interface Props {
  inputMethod: InputMethod;
  itemType: string;
}

function PayloadFields(props: Props & PayloadFieldsProps) {
  const { inputMethod, layoutClasses } = props;
  const { data, loading, error, refetch } = useVersion(props.versionId || '');
  const { setLocked } = useContext(LockedContext);

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching CSR: ${err}`);
    });
  }, [inputMethod, refetch]);

  useEffect(() => {
    if (data) setLocked(data.cybersecurityReportVersion.locked);
  }, [data, setLocked]);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Cybersecurity Report version ${props.versionId}\n${error}`);

  const currentVersion = data.cybersecurityReportVersion;

  const renderInputMethod = () => {
    switch (inputMethod) {
      case InputMethod.BODY:
        return (
          <Grid container spacing={2}>
            <AssociatedCybersecurityPlanCard
              currentVersion={currentVersion}
              supplierPassedAudit={currentVersion.supplierPassedAudit}
            />
            <Grid item xs={12}>
              <Panel>
                <DocumentBuilder
                  itemType={props.itemType}
                  refetchQueries={['cybersecurityReportVersionQuery']}
                  richTextOptionSet="large"
                  layoutClasses={props.layoutClasses}
                  versionData={currentVersion}
                />
              </Panel>
            </Grid>
          </Grid>
        );

      case InputMethod.UPLOAD:
        return (
          <>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
              <AssociatedCybersecurityPlanCard
                currentVersion={currentVersion}
                supplierPassedAudit={currentVersion.supplierPassedAudit}
              />
              <Grid item xs={7} className={layoutClasses.gridGutterLeft}>
                <Panel>
                  <DocumentStyleUploader versionData={currentVersion} onUpdate={() => refetch()} displayUploadInfo />
                </Panel>
              </Grid>
              {currentVersion.attachment && (
                <Grid item xs={12}>
                  <PDFViewer url={currentVersion.attachment.url} />
                </Grid>
              )}
            </Grid>
          </>
        );
      default:
        return (
          <Grid container spacing={2}>
            <AssociatedCybersecurityPlanCard
              currentVersion={currentVersion}
              supplierPassedAudit={currentVersion.supplierPassedAudit}
            />
            <Grid item xs={12}>
              <Panel>
                <DocumentBuilder
                  itemType={props.itemType}
                  refetchQueries={['cybersecurityReportVersionQuery']}
                  richTextOptionSet="large"
                  layoutClasses={props.layoutClasses}
                  versionData={currentVersion}
                />
              </Panel>
            </Grid>
          </Grid>
        );
    }
  };

  return <>{renderInputMethod()}</>;
}

export default withLockedContext(PayloadFields);
