import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      textAlign: 'center',
    },
    icon: {
      marginBottom: theme.spacing(1.5),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  }),
);
