import gql from 'graphql-tag';

export const CREATE_SOURCE_FORM_ATTACHMENT = gql`
  mutation createSourceFormAttachment($fileURL: String!, $versionId: ID!) {
    createSourceFormAttachment(file: $fileURL, versionId: $versionId) {
      attachment {
        id
        url
        versionId
        creator {
          id
          fullName
        }
      }
    }
  }
`;

export const DESTROY_SOURCE_FORM_ATTACHMENT = gql`
  mutation DestroySourceFormAttachment($id: ID!) {
    destroySourceFormAttachment(id: $id) {
      attachment {
        id
      }
    }
  }
`;

export const UPDATE_ASSESSMENT_MATERIAL = gql`
  mutation UpdateAssessmentMaterial($id: ID!, $description: String, $editableDocument: String) {
    updateAssessmentMaterial(id: $id, description: $description, editableDocument: $editableDocument) {
      assessmentMaterial {
        id
      }
    }
  }
`;
