import React from 'react';

import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

function FormLabel({ children, label }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.text} variant="subtitle1">
          {label}
        </Typography>
        {children}
      </div>
    </>
  );
}

export default FormLabel;
