import React, { FC } from 'react';

import { Box, Button } from '@material-ui/core';
import { Edit, Save } from '@material-ui/icons';
import Undo from 'assets/icons/Undo';
import { useStyles } from './styles';

interface Props {
  isActive?: boolean;
  onClick: () => void;
  onActionButtonClick?: () => void;
  onCancel?: () => void;
}

const SettingsEditButton: FC<Props> = ({ isActive, onClick, onActionButtonClick, onCancel }) => {
  const classes = useStyles();

  return (
    <div className={classes.editButton}>
      {isActive ? (
        <Box display="flex" alignItems="center">
          <Button variant="outlined" color="primary" startIcon={<Undo />} onClick={onCancel}>
            Cancel
          </Button>
          <Box mr={1} />
          <Button variant="contained" color="primary" startIcon={<Save />} onClick={onActionButtonClick}>
            Save
          </Button>
        </Box>
      ) : (
        <Button variant="outlined" color="primary" startIcon={<Edit />} onClick={onClick}>
          Update
        </Button>
      )}
    </div>
  );
};

export default SettingsEditButton;
