import gql from 'graphql-tag';

export const UPDATE_USER_JOB_ROLE = gql`
  mutation UpdateUserJobRole(
    $userIds: [ID!]!
    $jobRoleId: ID!
    $reasonForRoleChange: String!
    $username: String!
    $password: String!
  ) {
    updateUserJobRole(
      userIds: $userIds
      jobRoleId: $jobRoleId
      reasonForRoleChange: $reasonForRoleChange
      username: $username
      password: $password
    ) {
      users {
        id
        jobRoles {
          id
          formattedRoleName
        }
      }
    }
  }
`;
