import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    leftSpan: {
      verticalAlign: 'top',
      paddingRight: theme.spacing(1.5),
    },
    rightSpan: {
      verticalAlign: 'top',
      paddingLeft: theme.spacing(1.5),
    },
    cellStyle: {
      paddingLeft: 8,
      borderLeft: 'solid 2px #ebeced',
    },
  }),
);
