import React from 'react';
import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import { get } from 'lodash';

import Portrait from '@material-ui/icons/Portrait';

import { MakeLink } from 'components';
import slugs from '../../displaySlugs';
import PayloadFields from './PayloadFields';
import { isColumnGroupedBy } from 'utils/agGridHelpers';

const itemType = 'userNeed';

const versionFields = `
  userNeedText
  stakeholders
  setMember {
    id
    setItemVersion {
      id
      item {
        id
        customIdentifier
      }
    }
  }
`;

const listViewColumns = [
  {
    field: 'currentVersion.setMember.setItemVersion',
    headerName: 'Associated Set',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return get(row.value.currentVersion.setMember, 'setItemVersion.item.customIdentifier', '-');
    },
    render: function(row) {
      if (isColumnGroupedBy(row, 'currentVersion.setMember.setItemVersion')) {
        return row.value;
      }

      let userNeedCurrentVersion = row.data.currentVersion;
      return userNeedCurrentVersion.setMember === null ? (
        '-'
      ) : (
        <MakeLink
          item={{
            id: userNeedCurrentVersion.setMember.setItemVersion.item.id,
            itemType: {
              displaySlug: userNeedCurrentVersion.setMember.setItemVersion.item.itemType.displaySlug,
              userCreatable: userNeedCurrentVersion.setMember.setItemVersion.item.itemType.userCreatable,
            },
          }}
        >
          {userNeedCurrentVersion.setMember.setItemVersion.item.customIdentifier}
        </MakeLink>
      );
    },
  },
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.userNeedText',
    headerName: 'User Need Text',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.stakeholders',
    headerName: 'Stakeholders',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (row.data === undefined) return null;
      const stakeholders = row.data.currentVersion.stakeholders;
      return stakeholders ? stakeholders.join(', ') : '';
    },
    render: (row) => {
      if (row.value === undefined) return null;
      return row.value;
    },
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query userNeedsQuery {
    userNeeds {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        id
        createdAt
        userNeedText
        title
        stakeholders
        currentStatusName
        versionIdentifier
        setMember {
          id
          setItemVersion {
            id
            item {
              id
              customIdentifier
              itemType {
                id
                displaySlug
                userCreatable
              }
            }
          }
        }
        signingOwner {
          id
          firstName
          lastName
        }
        signingApprover {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  displayHistoryInEffectiveView: false,
  itemType,
  label: t`User Need`,
  categoryLabel: t`User Needs`,
  icon: Portrait,
  versionFields,
  listViewColumns,
  listViewQuery,
  PayloadFields,
};
