import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './role-management.svg';

function RoleManagement(props) {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <Icon {...svgProps} />} />;
}

export default RoleManagement;
