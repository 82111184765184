import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    container: {
      padding: 16,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    header: {
      display: 'flex',
      '& button': {
        marginLeft: theme.spacing(1),
      },
    },
    infoIcon: {
      cursor: 'pointer',
    },
    pills: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    status: {
      textTransform: 'capitalize',
    },
    fullName: {
      display: 'flex',
      width: '100%',

      '& span': {
        flex: 1,
      },
    },
  }),
);
