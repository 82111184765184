import React from 'react';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { Views } from 'workspaces';

import BuilderView from './BuilderView';
import { ComingSoon } from 'pages';

function RecordPage(props: {
  itemId: string;
  itemType: string;
  categorySlug: string;
  activeWorkspaceView: { id: string };
}) {
  if (props.itemType !== 'curriculum')
    throw new Error(
      `Attempted to render curriculum RecordPage for item type: ${props.itemType}. Something may be misconfigured.`,
    );

  switch (props.activeWorkspaceView.id) {
    case Views.Builder.id:
      return <BuilderView itemId={props.itemId} categorySlug={props.categorySlug} />;
    default:
      return <ComingSoon />;
  }
}

export default withWorkspaceViews()(RecordPage);
