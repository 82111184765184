import gql from 'graphql-tag';

export const riskControlImplementationTestsQuery = gql`
  query RiskControlImplementationTestsQuery {
    riskControlImplementationTests {
      riskControlImplementationTest {
        id
        title
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      riskAnalysisVersion {
        id
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      riskControlVersion {
        id
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      requirementVersion {
        id
        versionIdentifier
        title
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
    }
  }
`;
