import React, { useEffect, useContext } from 'react';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';
import { Trans } from '@lingui/macro';
// import { I18n } from '@lingui/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography } from '@material-ui/core';
import Flag from '@material-ui/icons/Flag';
import Check from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/styles';

import NoReleaseModal from 'components/NoReleaseModal';
import { FeatureUnavailable } from 'pages';
import styles from 'components/BuilderView/styles';
import { Breadcrumbs, Blur, ContentSection, ItemTitle, Loading, Spacer, ErrorBoundary } from 'components';
import { ContextDrawer } from 'compositions';

import { useBuilderViewQuery } from './gql';
import ItemControls from './ItemControls';
import PayloadFields from './PayloadFields';
import TrainingPlanWorkflow from 'workflows/TrainingPlanWorkflow';
import { withLockedContext, LockedContext } from 'contexts/lockedContext';

interface BuilderViewProps {
  itemId: string;
  classes;
  categorySlug: string;
}
function BuilderView({ itemId, classes, categorySlug }: BuilderViewProps) {
  const matchesPrint = useMediaQuery('print');
  const { data, loading, error } = useBuilderViewQuery(itemId);

  const { setLocked } = useContext(LockedContext);

  useEffect(() => {
    if (data) {
      setLocked(get(data, 'item.currentVersion.locked'));
    }
  }, [data, setLocked]);

  if (error) throw new Error(`Error loading trainingPlan ${itemId}\n${error}`);
  if (matchesPrint) return null;
  if (loading || !data) return <Loading />;

  const itemRecord = data.item;
  const readOnly = get(itemRecord, 'currentUser.readOnly', true);
  if (readOnly) return <FeatureUnavailable />;
  const { currentVersion } = itemRecord;
  if (!currentVersion) throw new Error(`null currentVersion returned for trainingPlan ${itemId}`);
  const itemTitle = currentVersion.title;
  const titlePrefix = itemRecord.customIdentifier;
  const hasWorkingVersion = Boolean(itemRecord.workingVersion);

  return (
    <Grid container data-testid="item" spacing={3} className={classes.container}>
      <Grid item xs={8} className={classes.grid}>
        <Breadcrumbs categorySlug={categorySlug} itemTitle={`${titlePrefix} ${itemTitle}`} />
        <Grid container item xs={12} justify="space-between">
          <Blur blur={!hasWorkingVersion} container={false}>
            <ItemTitle
              editable={currentVersion && currentVersion.locked}
              itemTitle={itemTitle}
              titlePrefix={titlePrefix}
              versionId={get(currentVersion, 'id')}
            />
          </Blur>

          <ItemControls
            locked={currentVersion.locked}
            itemId={itemId}
            referenceId={itemRecord.referenceId}
            approver={currentVersion.approver}
            owner={currentVersion.owner}
            creator={currentVersion.creator}
            createdAt={currentVersion.createdAt}
            tags={itemRecord.tags}
            permissibleApprovers={currentVersion.permissibleApprovers}
            permissibleOwners={currentVersion.permissibleOwners}
            versionId={currentVersion.id}
            version={currentVersion}
            refetchQueries={['TrainingPlanBuilderViewQuery']}
          />
        </Grid>
      </Grid>

      <Grid container item xs={8} className={classes.grid}>
        <Grid item xs={8} className={classes.gridGutterRight}>
          <ErrorBoundary>
            <TrainingPlanWorkflow itemId={itemId} />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={4} className={classes.gridGutterLeft}>
          <ContentSection LabelText={<Trans>Item Notes</Trans>} Icon={<Flag className={classes.icon} />}>
            <Spacer factor={1} />
            <div className={classes.itemNotes}>
              <Spacer factor={3} />
              <Typography variant="body2" className={classes.iconTextStack}>
                <Check className={classes.icon} />
                <Trans>No new updates</Trans>
              </Typography>
            </div>
          </ContentSection>
        </Grid>
      </Grid>

      <Blur blur={!hasWorkingVersion}>
        <PayloadFields layoutClasses={classes} versionId={currentVersion.id} />
      </Blur>

      <NoReleaseModal
        open={!hasWorkingVersion}
        modalTopPosition="50%"
        viewName="Builder View"
        message={
          <Trans>
            This item has no in-progress version. Select Effective View from the view menu for the current effective
            view of this item.
          </Trans>
        }
        showLink={false}
      />
      <ContextDrawer itemId={itemId} itemType={'trainingPlan'} />
    </Grid>
  );
}

export default flowRight([withStyles(styles), withLockedContext])(BuilderView);
