import gql from 'graphql-tag';
import { t } from '@lingui/macro';

import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';

import slugs from '../../displaySlugs';

const itemType = 'verificationAndValidationPlan';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Verification and Validation Plan`,
  categoryLabel: t`V & V Plans`,
  icon: PlaylistAddCheck,
  listViewQuery: gql`
    query verificationAndValidationPlansListViewQuery {
      verificationAndValidationPlans {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          versionIdentifier
          currentStatus {
            id
            name
          }
        }
      }
    }
  `,
  listViewColumns,
};
