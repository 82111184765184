import { Assignment, CheckBox, NotInterested } from '@material-ui/icons';

export const taskOptions = [
  {
    value: 'myIncompleteTasks',
    Icon: Assignment,
    text: 'My Incomplete Tasks',
  },
  {
    value: 'completedTasks',
    Icon: CheckBox,
    text: 'Completed Tasks',
  },
  {
    value: 'blockedTasks',
    Icon: NotInterested,
    text: 'Blocked Tasks',
  },
  {
    value: 'inProgressTasks',
    Icon: Assignment,
    text: 'In-Progress Tasks',
  },
  {
    value: 'allTasks',
    Icon: Assignment,
    text: 'All Tasks',
  },
];
