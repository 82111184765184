import React from 'react';

// eslint-disable-next-line import/named
import { RouteComponentProps } from '@reach/router';
import { ErrorBoundary } from 'components';
import BuilderView from 'components/BuilderView';
import EffectiveView from 'components/EffectiveView';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';

import { Views } from 'workspaces';

import PayloadFields from './PayloadFields';

interface Props extends RouteComponentProps {
  itemId: string;
  activeWorkspaceView: { id: string };
}

export enum TrainingRecordCategoryTitle {
  COURSE = 'Course Training',
  READ_AND_UNDERSTAND = 'Read & Understand',
}

function Page(props: Props) {
  switch (props.activeWorkspaceView.id) {
    case Views.Effective.id:
      return (
        <EffectiveView
          categorySlug="training-records"
          itemType="trainingRecord"
          itemId={props.itemId}
          PayloadFields={PayloadFields}
          displayHistoryInEffectiveView={false}
        />
      );
    default:
      return (
        <ErrorBoundary>
          <BuilderView
            categorySlug="training-records"
            itemType="trainingRecord"
            itemId={props.itemId}
            PayloadFields={PayloadFields}
          />
        </ErrorBoundary>
      );
  }
}

export default withWorkspaceViews()(Page);
