import React from 'react';
import { withStyles } from '@material-ui/core';

function Spacer({ factor, theme }) {
  return (
    <div style={{ height: `${theme.spacing(factor)}px`, clear: 'both' }} />
  );
}

export default withStyles(null, { withTheme: true })(Spacer);
