import gql from 'graphql-tag';

export const builderViewQuery = gql`
  query FormBuilderViewQuery($itemId: ID) {
    item(id: $itemId, itemType: "form") {
      id
      customIdentifier
      currentUser {
        id
        readOnly
      }
      createdAt
      owners {
        id
        fullName
        title
      }
      approvers {
        id
        fullName
        title
      }
      referenceId
      tags
      currentVersion {
        approver {
          id
          fullName
          title
          status
        }
        createdAt
        creator {
          id
          fullName
          title
        }
        item {
          id
          itemType {
            id
            name
          }
        }
        id
        locked
        owner {
          id
          fullName
          title
          status
        }
        permissibleApprovers {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        permissibleOwners {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        assignedOwnerJobRole {
          id
          formattedRoleName
        }
        assignedApproverJobRole {
          id
          formattedRoleName
        }
        ... on FormVersion {
          body
          inputMethod
          attachment {
            id
            filename
            url
          }
        }
        title
        versionIdentifier
        currentStatusName
      }
    }
  }
`;

export const versionQuery = gql`
  query FormVersion($id: ID!) {
    formVersion(id: $id) {
      id
      body
      formRecordTag
      # pdfDataUpload
      reasonForChange
      descriptionOfChange
      locked
      inputMethod
      displayAsApplicationVersion
      createdAt
      attachment {
        id
        filename
        url
      }
      rawReferenceAttachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      creator {
        id
        fullName
        title
      }
      signingOwner {
        id
        firstName
        lastName
      }
      signingApprover {
        id
        firstName
        lastName
      }
    }
  }
`;

export const effectiveViewQuery = gql`
  query FormEffectiveViewQuery($itemId: ID) {
    item(id: $itemId, itemType: "form") {
      id
      customIdentifier
      currentUser {
        id
        readOnly
      }
      currentRelease {
        id
        locked
        title
      }
    }
  }
`;
