import React, { ComponentType, useState } from 'react';

import { CircularProgress, SvgIconProps, Typography } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';

import { DateTime } from 'luxon';
import clsx from 'clsx';

import FileUpload from 'components/FileUpload';
import fileNameFromURL from 'utils/fileNameFromURL';

import { useStyles } from './styles';

interface Props {
  accept?: Array<string> | undefined;
  versionId: string;
  attachment?: {
    url: string;
    id: string;
    createdAt: string;
    creator: {
      fullName: string;
    }
  } | null;
  classes;
  locked: boolean;
  singleAttachment: boolean;
  downloadable: boolean;
  Icon: ComponentType<SvgIconProps>;
  updateAttachment?: (id: string, url: string) => Promise<any>;
  createAttachment?: (versionId: string, url: string) => Promise<any>;
  destroyAttachment: () => Promise<any>;
}

function AttachmentControl(props: Props) {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  return (
    <div>
      <div className={classes.attachmentInput}>
        <span className={clsx(classes.placeholderText, props.attachment && classes.inheritColor)}>
          {props.attachment && props.attachment.url ? fileNameFromURL(props.attachment.url) : 'Attach File'}
        </span>
        {!loading && !props.locked && (
          <FileUpload
            accept={props.accept}
            singleAttachment={props.singleAttachment}
            hasAttachment={Boolean(props.attachment)}
            Icon={props.Icon}
            onUploadStart={() => setLoading(true)}
            onUploadSuccess={(fileUrl: string) => {
              const attachmentURL = decodeURIComponent(fileUrl);
              if (props.attachment && !props.singleAttachment && props.updateAttachment) {
                props.updateAttachment(props.attachment.id, attachmentURL).then(
                  () => setLoading(false),
                  (err) => {
                    setLoading(false);
                    throw new Error(`Error updating attachment ${err.message}`);
                  },
                );
              } else {
                props.createAttachment &&
                  props.createAttachment(props.versionId, attachmentURL).then(
                    () => setLoading(false),
                    (err) => {
                      throw new Error(`Error creating attachment (versionId: ${props.versionId}): ${err.message}`);
                    },
                  );
              }
            }}
          />
        )}
        {loading && <CircularProgress size={24} />}
        {props.attachment && (
          <div className={classes.actions}>
            {props.attachment.url && props.singleAttachment && !props.locked && (
              <Delete style={{ cursor: 'pointer' }} onClick={props.destroyAttachment} />
            )}
            {(props.locked || props.downloadable) && (
              <VerticalAlignBottom style={{ cursor: 'pointer' }} onClick={() => window.open(props.attachment!.url)} />
            )}
          </div>
        )}
      </div>
      {props.attachment && (
        <div style={{ display: 'flex', marginTop: '5px' }}>
          <Typography variant="body2" style={{ textTransform: 'uppercase', fontSize: 12, fontWeight: 600 }}>Upload information:</Typography>
          <Typography variant="body2" style={{ marginLeft: '10px', fontSize: 12 }}>{DateTime.fromISO(props.attachment!.createdAt, { zone: "EST" }).toFormat('MMMM d, yyyy HH:mm a')} by {props.attachment!.creator.fullName}</Typography>
        </div>
      )}
    </div>
  );
}

AttachmentControl.defaultProps = {
  singleAttachment: false,
  downloadable: false,
} as Partial<Props>;

export default AttachmentControl;
