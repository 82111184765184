import React from 'react';
import flowRight from 'lodash/flowRight';

import { Button } from '@material-ui/core';

import { Loading, Spacer } from 'components';
import ESignature from './ESignature';
import ReasonForChangeTextArea from './ReasonForChangeTextArea';

import WithCurrentUser from 'compositions/WithCurrentUser';
import { useStyles } from './styles';

interface Props {
  textboxHeading: string;
  textboxId: string;
  onTextChange: (e) => void;
  usernameInputId: string;
  onUsernameChange: (e) => void;
  passwordInputId: string;
  onPasswordChange: (e) => void;
  submitDisabled: boolean;
  onSubmitClick: () => void;
  submitLoading: boolean;
  currentUser: any;
}

function ReasonForChangeAndEsig(props: Props) {
  const classes = useStyles();

  return (
    <div style={{ position: 'relative' }}>
      <ReasonForChangeTextArea
        textboxHeading={props.textboxHeading}
        textboxId={props.textboxId}
        onTextChange={props.onTextChange}
      />
      <Spacer factor={3} />
      <ESignature
        usernameInputId={props.usernameInputId}
        currentUser={props.currentUser}
        passwordInputId={props.passwordInputId}
        onUsernameChange={props.onUsernameChange}
        onPasswordChange={props.onPasswordChange}
      />
      <Spacer factor={2} />
      <Button
        color="primary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={props.submitDisabled}
        className={classes.submitBtn}
        onClick={props.onSubmitClick}
      >
        {props.submitLoading ? <Loading size={25} /> : 'Submit'}
      </Button>
    </div>
  );
}

export default flowRight([WithCurrentUser])(ReasonForChangeAndEsig);
