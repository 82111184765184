import React from 'react';

import { UserAvatar } from 'components';

import { useStyles } from './styles';

export function AvatarCell({ data }) {
  const classes = useStyles();

  if (!Boolean(data)) return null;

  return (
    <div className={classes.content}>
      {data.length ? (
        data.map((user, i) => <UserAvatar userId={user.id} key={i} displayTooltip />)
      ) : (
        <UserAvatar userId={data.id} key={data.id} displayTooltip />
      )}
    </div>
  );
}

export const avatarHeaderStyle = { textAlign: 'center' };
