import React from 'react';

import { Grid } from '@material-ui/core';
import Business from '@material-ui/icons/Business';

import { useMutation } from 'react-apollo';

import { withLockedContext } from 'contexts/lockedContext';
import { SingleItemSelect } from 'components';
import SectionLabel from 'components/core/SectionLabel';
import Panel from 'components/core/Panel';

import { SET_SUPPLIER_ID, SUPPLIER_OPTIONS_QUERY } from '../gql';

interface Props {
  currentVersion: any;
  locked: boolean;
  supplierPassedAudit: boolean | null;
}

function AssociatedSupplierCard(props: Props) {
  const { currentVersion, locked } = props;
  const [setSupplierId] = useMutation(SET_SUPPLIER_ID, {
    refetchQueries: ['QualityAgreementVersionQuery'],
  });

  const updateQualityAgreement = (supplierVersion) => {
    setSupplierId({
      variables: {
        id: props.currentVersion.id,
        supplierId: supplierVersion ? supplierVersion.currentVersion.id : null,
      },
    }).catch((err) => {
      throw new Error(`Error refetching Associated supplier audit plan version: ${err}`);
    });
  };

  return (
    <Grid item xs={5} style={{ padding: '12px 0' }}>
      <Panel>
        <SectionLabel icon={Business}>Associated Supplier</SectionLabel>
        <div>
          <SingleItemSelect
            value={currentVersion!.supplierVersion}
            disabled={locked}
            onChange={updateQualityAgreement}
            placeholder="Select Associated Supplier"
            options={{
              query: SUPPLIER_OPTIONS_QUERY,
              optionsPath: 'suppliers',
            }}
          />
        </div>
      </Panel>
    </Grid>
  );
}

export default withLockedContext(AssociatedSupplierCard);
