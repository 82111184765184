import React from 'react';

import { Badge, IconButton, withStyles } from '@material-ui/core';

import styles from './styles';

import { buildConditionalClasses } from 'utils';

function SmallCircle(props) {
  const { classes, content, color, disabled, disabledEffect, id, handleClick, theme, style, visible = true } = props;
  if (!visible) return null;
  return (
    <IconButton
      id={id || 'small-circle'}
      className={buildConditionalClasses([[true, classes.smallBtn], [disabledEffect, classes.disabled]])}
      style={{
        color: theme.palette.common[color].main,
        ...style,
      }}
      onClick={handleClick}
    >
      <Badge
        className={buildConditionalClasses([[content.length > 3, classes.textDecreased], [disabled, classes.disabled]])}
        style={{
          color: theme.palette.common[color].main,
        }}
        badgeContent={content}
        children={''}
      />
    </IconButton>
  );
}

export default withStyles(styles, { withTheme: true })(SmallCircle);
