import get from 'lodash/get';
import React from 'react';
import semver from 'semver';

import { Trans } from '@lingui/macro';

import { Grid } from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFile';

import { ContentSection, PDFViewer, Spacer } from 'components';
import AttachmentControl from 'components/AttachmentsSection/AttachmentControl';
import {
  useVersion,
  useCreateMiscellaneousRecordAttachment,
  useDestroyMiscellaneousRecordAttachment,
  useCreateRawReferenceAttachment,
  useDestroyRawReferenceAttachment,
} from './gql';

const PayloadFields = ({ layoutClasses, versionId }) => {
  const { data, loading, error } = useVersion(versionId);

  const [createMiscRecordAttachment] = useCreateMiscellaneousRecordAttachment();
  const [destroyMiscRecordAttachment] = useDestroyMiscellaneousRecordAttachment();
  const [createRawReferenceAttachment] = useCreateRawReferenceAttachment();
  const [destroyRawReferenceAttachment] = useDestroyRawReferenceAttachment();

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Misc Record Version ${versionId}\n${error.message}`);
  const versionData = data.miscellaneousRecordVersion;

  const handleAttachmentChange = async (versionId, attachmentURL) =>
    createMiscRecordAttachment({
      variables: {
        versionId,
        attachmentURL,
      },
      refetchQueries: ['MiscellaneousRecordVersionQuery'],
    });

  const handleAttachmentDeletion = () =>
    destroyMiscRecordAttachment({
      variables: { id: get(versionData, 'attachment.id') },
      refetchQueries: ['MiscellaneousRecordVersionQuery'],
    });

  const handleRawRefAttachmentChange = async (versionId, attachmentURL) =>
    createRawReferenceAttachment({
      variables: {
        versionId,
        attachmentURL,
      },
      refetchQueries: ['MiscellaneousRecordVersionQuery'],
    });

  const handleRawRefAttachmentDeletion = () =>
    destroyRawReferenceAttachment({
      variables: { id: get(versionData, 'rawReferenceAttachment.id') },
      refetchQueries: ['MiscellaneousRecordVersionQuery'],
    });

  return (
    <>
      <Grid container justify="flex-end" alignItems="center" direction="row" className={layoutClasses.grid}>
        <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
          <ContentSection
            LabelText={
              <>
                <AttachFile className={layoutClasses.icon} />
                <Trans>PDF Document</Trans>
              </>
            }
            fillHeight={false}
          >
            <Spacer factor={1} />
            <AttachmentControl
              accept={['.pdf']}
              versionId={versionId}
              locked={versionData.locked}
              attachment={versionData.attachment}
              createAttachment={handleAttachmentChange}
              destroyAttachment={handleAttachmentDeletion}
              singleAttachment
            />
          </ContentSection>
          {semver.gte(versionData.displayAsApplicationVersion, '4.3.0') && (
            <ContentSection
              LabelText={
                <>
                  <AttachFile className={layoutClasses.icon} />
                  <Trans>Editable Document</Trans>
                </>
              }
              fillHeight={false}
            >
              <Spacer factor={1} />
              <AttachmentControl
                versionId={versionId}
                locked={versionData.locked}
                attachment={versionData.rawReferenceAttachment}
                createAttachment={handleRawRefAttachmentChange}
                destroyAttachment={handleRawRefAttachmentDeletion}
                singleAttachment
                downloadable
              />
            </ContentSection>
          )}
        </Grid>
      </Grid>
      <Grid container justify="flex-end" alignItems="center" direction="row" className={layoutClasses.grid}>
        {versionData.attachment && (
          <Grid item xs={12} className={`${layoutClasses.grid} ${layoutClasses.pdfView}`}>
            <Spacer factor={2} />
            <PDFViewer url={versionData.attachment.url} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PayloadFields;
