import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  updateNotificationPreferenceVariables,
  updateNotificationPreference_updateNotificationPreference,
} from './__generated__/updateNotificationPreference';

export const useUpdateNotificationPreference = () => {
  return useMutation<updateNotificationPreference_updateNotificationPreference, updateNotificationPreferenceVariables>(
    updateNotificationPreferenceMutation,
  );
};

export const updateNotificationPreferenceMutation = gql`
  mutation updateNotificationPreference($input: UpdateNotificationPreferenceInput!) {
    updateNotificationPreference(input: $input) {
      errors
      notificationPreference {
        id
        preferences
        preferenceType
      }
    }
  }
`;
