import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      boxShadow: '0px 2px 5px 1px rgba(0, 0, 0, 0.25)',
    },
    title: {
      color: theme.palette.blueDark,
      marginBottom: theme.spacing(2),
    },
    itemContainer: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
    itemTitle: {
      color: theme.palette.blueDark,
      marginBottom: theme.spacing(0.5),
    },
    toggleLabelRoot: {
      marginLeft: 0,
    },
    toggleLabel: {
      marginLeft: theme.spacing(1),
      textTransform: 'capitalize',
    },
  }),
);
