import { Typography, Grid } from '@material-ui/core';
import SectionLabel from 'components/core/SectionLabel';
import React from 'react';
import Equalizer from '@material-ui/icons/Equalizer';

import StatusCard from './StatusCard';
import { useStyles } from './../styles';

interface Props {
  statusesCount: {
    requested: number;
    approved: number;
    needsRequalification: number;
  };
}

const BreakdownByStatus = (props: Props) => {
  const classes = useStyles();

  const { requested, approved, needsRequalification } = props.statusesCount;

  return (
    <>
      <Grid xs={12}>
        <div className={classes.supplierActionsLabel}>
          <SectionLabel variant="body1" icon={Equalizer}>
            <Typography
              variant="h3"
              component="span"
              style={{ fontSize: '18px', fontWeight: 400, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
            >
              Supplier Breakdown by Status
            </Typography>
          </SectionLabel>
        </div>
      </Grid>
      <Grid container spacing={2} justify="space-between">
        <StatusCard
          status="draft"
          labelText="Requested"
          itemsNumber={requested}
          tooltipTitle="This count includes all in-progress qualifications"
        />
        <StatusCard status="supplierApproved" labelText="Approved" itemsNumber={approved} />
        <StatusCard
          status="prequalificationComplete"
          labelText="Needs  Requalification"
          itemsNumber={needsRequalification}
        />
      </Grid>
    </>
  );
};

export default BreakdownByStatus;
