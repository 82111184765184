import React, { SyntheticEvent } from 'react';

import { createStyles, makeStyles, TextField } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { get } from 'lodash';

import ChipItem, { LinkType, StatusVersionType } from 'components/ChipItem';
import clsx from 'clsx';

export const useStyles = makeStyles((_theme: any) =>
  createStyles({
    paper: {
      width: ' max-content',
      maxWidth: '400px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
    },
    root: {
      '& .MuiButtonBase-root.Mui-disabled': {
        pointerEvents: 'all',
      },
    },
    hideDeleteIcon: {
      '& .MuiChip-deleteIcon': {
        display: 'none',
      },
    },
  }),
);

interface ChipSelectProps {
  placeholder?: string;
  disabled?: boolean;
  renderOption?: (option: any) => any;
  content?: any;
  options?: any;
  onAdd?: any;
  onRemove?: any;
  onOpen?: any;
  popupIcon?: any;
  chipDeleteIcon?: any;
  onToggle?: any;
}

export default function ChipSelect({
  placeholder = '',
  disabled = false,
  renderOption = (option: any) => option.id,
  content, // already added options
  options,
  onAdd,
  onRemove,
  onOpen,
  popupIcon = <Link />,
  chipDeleteIcon,
  onToggle,
}: ChipSelectProps) {
  const getLabel = (x) => `${get(x, 'customIdentifier', '')} ${get(x, 'currentVersion.title', '')}`;
  const classes = useStyles();
  const handleToggle = (newVal: string, e: SyntheticEvent) => {
    if (onToggle) {
      onToggle(newVal, e);
    }
  };
  return (
    <Autocomplete
      disabled={disabled}
      multiple
      classes={{ paper: classes.paper, root: clsx(classes.root, !onRemove && classes.hideDeleteIcon) }}
      renderOption={(option, _) => renderOption(option)}
      onOpen={onOpen}
      onChange={(_event, _value, reason, details) => {
        const option = details && details.option;
        switch (reason) {
          case 'select-option':
            option && onAdd(option);
            break;
          case 'remove-option':
            option && onRemove(option);
        }
      }}
      filterSelectedOptions
      disableClearable
      value={content}
      options={options}
      size={'small'}
      getOptionLabel={getLabel}
      popupIcon={popupIcon}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return (
            <ChipItem
              getTagProps={getTagProps({ index })}
              statusVersionDot={disabled ? StatusVersionType.statusAndVersion : StatusVersionType.versionChange}
              linkType={LinkType.internalNewTab}
              deleteIcon={chipDeleteIcon}
              option={{
                ...option,
                itemType: {
                  displaySlug: option.currentVersion.item ? option.currentVersion.item.itemType.displaySlug : '',
                },
              }}
              onToggle={disabled ? undefined : handleToggle}
              disable={disabled}
            />
          );
        })
      }
      renderInput={(params) => {
        return <TextField rows={1} {...params} variant="outlined" placeholder={placeholder} />;
      }}
    />
  );
}
