import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  textField: {
    minWidth: '400px',
    margin: '1rem 0',

    '& > div': {
      paddingRight: 0,
    }
  },

  endAdornment: {
    cursor: 'pointer',
    color: '#e3e3e3',
  },
  
  lockedButton: {
    opacity: 0,
  },
});
