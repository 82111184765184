import React from 'react';

import { Layout } from 'components';

// core components
import { LoginForm } from 'compositions';

function Login(props) {
  return (
    <Layout variant="unauthorized">
      <LoginForm {...props} />
    </Layout>
  );
}

export default Login;
