import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      margin: 'inherit',
    },
    pageTitle: {
      color: theme.palette.secondary.main,
      marginBottom: theme.spacing(3),
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '32px',
    },
    collapseItem: {
      marginBottom: theme.spacing(4),
    },
    section: {
      marginBottom: theme.spacing(2),
    },
  }),
);
