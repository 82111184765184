import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { ReactComponent as SafetyCheckIcon } from './safety-check.svg';

const SafetyCheck = (props) => {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <SafetyCheckIcon {...svgProps} />} />;
};

export default SafetyCheck;
