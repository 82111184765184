import { createStyles } from '@material-ui/core';

const styles = (theme) => {
  return {
    container: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      overflowY: 'scroll',
    },
    dateHeader: {
      textAlign: 'center',
      paddingBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    diffAdded: {
      backgroundColor: '#ddeec9',
      color: '#516c32',
      borderRadius: 3,
      padding: 1,
    },
    diffRemoved: {
      textDecoration: 'line-through',
      backgroundColor: '#e9e9e9',
      borderRadius: 3,
      padding: 1,
    },
    timeRow: {
      textAlign: 'right',
    },
    centeredRow: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(0.25),
      backgroundColor: theme.palette.grey[100],
      borderRadius: 7,
      ...theme.typography.overline2,
    },
    recordEditTitle: {
      fontWeight: 600,
      paddingBottom: theme.spacing(0.5),
    },
  };
};

export default createStyles(styles);
