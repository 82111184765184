import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import startCase from 'lodash/startCase';
import { Trans } from '@lingui/react';

import { FormControl, MenuItem, Input, Select, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  selectRoot: {
    ...theme.typography.body2,
    width: '100%',
    background: theme.palette.common.input.background,
    border: `2px solid ${theme.palette.common.input.outline}`,
    borderRadius: 4,
    '&.Mui-disabled': {
      border: 'none',
      padding: 0,
      background: 'transparent',
      color: theme.palette.subtitleGrey,
    },
  },
  selectIcon: {
    '&.Mui-disabled': {
      visibility: 'hidden',
    },
  },
  selectSelect: {
    paddingLeft: theme.spacing(1),
  },
  menuDropdown: {
    height: 'auto',
    // minHeight: 185,
    maxHeight: 300,
  },
  patch: {
    borderRadius: theme.shape.borderRadius,
    height: 20,
    width: 20,
  },
  menuOption: {
    ...theme.typography.body2,
    borderTop: `2px solid ${theme.palette.common.input.outline}`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      background: theme.palette.common.input.hover,
    },
  },
});

const updateSelectValueMutation = gql`
  mutation updateSelectValue($input: UpdateVirtualAttributeInput!) {
    updateVirtualAttribute(input: $input) {
      virtualAttribute {
        attrName
        stringValue
      }
    }
  }
`;
class SelectField extends Component {
  static defaultProps = {
    refetchQueries: undefined,
    value: '',
    locked: true,
  };

  render() {
    const {
      classes,
      attrName,
      locked,
      options,
      placeholder,
      patches,
      refetchQueries,
      versionId,
      value,
      mutation,
      onChange,
      ...rest
    } = this.props;

    return (
      <Mutation mutation={mutation || updateSelectValueMutation}>
        {(mutate) => {
          const onChangeDefault = (event) => {
            mutate({
              variables: {
                input: {
                  attrName,
                  stringValue: event.target.value,
                  versionId,
                },
              },
              refetchQueries,
            });
          };
          return (
            <FormControl fullWidth disabled={locked}>
              <Select
                classes={{
                  root: classes.selectRoot,
                  select: classes.selectSelect,
                  icon: classes.selectIcon,
                }}
                displayEmpty
                input={<Input disableUnderline={true} name={attrName} id={`${attrName}-selector`} />}
                MenuProps={{
                  classes: { paper: classes.menuDropdown },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                onChange={onChange || onChangeDefault}
                value={value || null}
                {...rest}
              >
                <MenuItem value={null} className={classes.menuOption}>
                  {placeholder}
                </MenuItem>
                {options.map((value, ix) => (
                  <MenuItem key={ix} value={value} className={classes.menuOption}>
                    <Trans id={startCase(value)} />
                    {patches && <div className={classes.patch} style={{ backgroundColor: patches[ix] }} />}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }}
      </Mutation>
    );
  }
}

export default withStyles(styles)(SelectField);
