import React from 'react';
import clsx from 'clsx';
import { AddCircle, Link } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  variant?: 'add' | 'link' | 'locked';
  children?: React.FC<{ icon: () => JSX.Element | string }>;
  className?: string;
}
function NoContentDisclaimer(props: Props) {
  const classes = useStyles();

  const icon = () => {
    switch (props.variant) {
      case 'add':
        return <AddCircle fontSize="small" className={classes.icon} />;
      case 'link':
        return <Link fontSize="small" className={classes.icon} />;
      default:
        return '';
    }
  };

  if (props.variant === 'locked') {
    return (
      <Typography variant="h1" className={classes.container}>
        There's nothing here.
      </Typography>
    );
  }

  const text = props.children ? (
    props.children({ icon })
  ) : (
    <>
      There's nothing here yet.{'\n'}
      Click {icon()} to get started.
    </>
  );

  return (
    <Typography variant="h1" className={clsx(classes.container, props.className)}>
      {text}
    </Typography>
  );
}

export default NoContentDisclaimer;
