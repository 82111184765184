import React, { useState } from 'react';
import { useMutation } from 'react-apollo';

import BaseDialog from 'components/BaseDialog';
import { TextArea, YesOrNo } from 'components';

import { CREATE_JOB_ROLE } from './gql';

interface Props {
  open: boolean;
  onClose: () => void;
}

function CreateJobRole(props: Props) {
  const [roleName, setRoleName] = useState('');

  const [createJobRole, { loading, error }] = useMutation(CREATE_JOB_ROLE, {
    refetchQueries: ['AllJobRoles'],
    onCompleted: props.onClose,
  });
  if (error) throw new Error(`Error creating job role: ${error}`);

  const handleSubmit = () => {
    createJobRole({ variables: { roleName: roleName }})
    .catch((e) => {
      throw new Error(`Error occurred while creating job role: ${e.message}`)
    })
  }

  function handleClose() {
    props.onClose();
    setRoleName('');
  };

  return (
    <BaseDialog
      open={props.open}
      title='Create New Role'
      subtitle='Use this form to create a role type in the list.'
      onClose={handleClose}
    >
      <YesOrNo
        yesLabel="Create"
        noLabel="Cancel"
        closeOnNo
        disableOnSubmit
        handleComplete={handleSubmit}
        handleClose={handleClose}
        loading={loading}
        prompt={
          <TextArea
            id='job-role-title-input'
            placeholder='Role name'
            value={roleName}
            onTextChange={(txt) => setRoleName(txt)}
          />
        }
      />
    </BaseDialog>
  )
}

export default CreateJobRole;