import gql from 'graphql-tag';
import { curry, get } from 'lodash';
import React from 'react';
import { Mutation } from 'react-apollo';

const buildMutationChild = curry(
  (WrappedComponent, componentProps, renewAuthToken, { data, loading }) => {
    const authToken = get(data, 'renewAuthToken.authToken.token');
    return (
      <WrappedComponent
        {...componentProps}
        authToken={authToken}
        renewAuthToken={renewAuthToken}
        renewAuthTokenLoading={loading}
      />
    );
  },
);

const renewAuthTokenMutation = gql`
  mutation AuthProvider_renewAuthToken {
    renewAuthToken(input: {}) {
      authToken {
        token
        payload {
          exp
        }
      }
    }
  }
`;

const withRenewAuthToken = (WrappedComponent) => (componentProps) => (
  <Mutation mutation={renewAuthTokenMutation}>
    {buildMutationChild(WrappedComponent, componentProps)}
  </Mutation>
);

export default withRenewAuthToken;
