import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { setDisplayName } from 'recompose';
import flowRight from 'lodash/flowRight';
import isObject from 'lodash/isObject';
import { withI18n } from '@lingui/react';

import { Grid, withStyles } from '@material-ui/core';

import { TableView } from 'components';
import { sortIds } from 'utils/agGridHelpers';

const query = gql`
  query UsersQuery {
    users {
      id
      customIdentifier
      customEmployeeId
      department
      emailAddress
      firstName
      jobRoles {
        id
        formattedRoleName
      }
      previousJobRoles {
        id
        formattedRoleName
      }
      lastName
      manager {
        id
        firstName
        lastName
      }
      phoneNumber
      secondaryPhoneNumber
      status
      title
      employee {
        id
      }
    }
  }
`;

const styles = (theme) => ({
  tableContainer: {
    display: 'block',
    margin: '0 auto',
  },
  toolbar: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: 0,
    minHeight: 'inherit',
  },
});

const listViewColumns = [
  {
    field: 'customEmployeeId',
    headerName: 'ID',
    type: 'custom',
    render: function(row) {
      return row.data.customIdentifier;
    },
    width: 96,
  },
  {
    field: 'id',
    headerName: 'Nemedio ID',
    type: 'custom',
    comparator: (value1, value2) => sortIds(value1, value2),
    render: function(row) {
      return row.data.employee.id;
    },
    hide: true,
  },
  // No field because the top-level object is the user
  { field: null, headerName: 'Avatar', type: 'user' },
  { field: 'firstName', headerName: 'First Name', type: 'regular_text' },
  { field: 'lastName', headerName: 'Last Name', type: 'regular_text' },
  { field: 'status', headerName: 'Status', type: 'short_text' },
  {
    field: 'department',
    headerName: 'Department',
    type: 'regular_text',
  },
  {
    field: 'jobRoles',
    headerName: 'Active Roles',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (!row.data) return '';
      const roles = row.data.jobRoles.map((role) => role.formattedRoleName);
      return roles.join(', ');
    },
    render: function(row) {
      return row.value;
    },
  },
  {
    field: 'jobRoles',
    headerName: 'Previous Roles',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      if (!row.data) return '';
      const roles = row.data.previousJobRoles.map((role) => role.formattedRoleName);
      return roles.join(', ');
    },
    render: function(row) {
      return row.value;
    },
  },
  { field: 'manager', headerName: 'Manager', type: 'regular_text' },
  { field: 'emailAddress', headerName: 'E-mail', type: 'regular_text' },
  { field: 'phoneNumber', headerName: 'Phone #', type: 'regular_text' },
];

function EmployeeIndexPage(classes, props) {
  const { data, loading, error } = useQuery(query);
  if (loading) return null;
  if (error) throw new Error(`Error loading employees: ${error.message}`);

  // We must do this since TableView does not allow one to concatenate props and add the result to a cell 😿
  // This can be called multiple times, which means manager may have already been massaged.
  // To prevent overwriting, we only do it on the first iteration, where user.manager is an object
  // (or null, which we skip).
  data.users.forEach((user) => {
    if (isObject(user.manager)) user.manager = `${user.manager.firstName} ${user.manager.lastName}`;
  });

  return (
    <Grid container className={classes.tableContainer} spacing={1} wrap="nowrap">
      <TableView
        data={data.users}
        loading={loading}
        categorySlug={'employees'}
        categoryLabel={'Team Directory'}
        listViewColumns={listViewColumns}
        showNewItemBtn={false}
      />
    </Grid>
  );
}

export default flowRight([withI18n(), setDisplayName('Employee'), withStyles(styles)])(EmployeeIndexPage);
