import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    actions: {
      float: 'right',
    },
    icon: {
      height: 16,
      width: 16,
      marginRight: 12,
      color: theme.palette.blueDark,
    },
    imageContainer: {
      textAlign: 'center',
    },
    image: {
      maxWidth: '100%',
      borderRadius: 4,
      height: 430,
      maxHeight: 430,
    },
  }),
);
