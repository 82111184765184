import React, { useState } from 'react';

import { Grid } from '@material-ui/core';

import { Tab, TabContainer } from 'components';

import ReportTab from './ReportTab';
import ReferenceMaterialTab from './ReferenceMaterialTab';

interface Props {
  currentVersion: any;
  layoutClasses: { [className: string]: string };
  locked: boolean;
  onUpdate: any;
  itemType: string;
}

const reportTab = 'Report';
const referenceMaterialTab = 'Reference Material';

export default function GuidedEditorTabs(props: Props) {
  const { currentVersion, layoutClasses, itemType } = props;

  const [currentTab, setCurrentTab] = useState(reportTab);

  const tabs = {};
  tabs[reportTab] = (
    <ReportTab
      itemType={itemType}
      layoutClasses={layoutClasses}
      currentVersion={currentVersion}
      locked={props.locked}
      versionId={currentVersion.id}
    />
  );
  tabs[referenceMaterialTab] = (
    <ReferenceMaterialTab currentVersion={currentVersion} locked={props.locked} layoutClasses={layoutClasses} />
  );

  return (
    <Grid item xs={12}>
      <TabContainer
        layoutClasses={props.layoutClasses}
        tabs={
          <>
            <Tab
              icon={null}
              active={reportTab === currentTab}
              onClick={() => setCurrentTab(reportTab)}
              text={reportTab}
            />
            <Tab
              icon={null}
              active={referenceMaterialTab === currentTab}
              onClick={() => setCurrentTab(referenceMaterialTab)}
              text={referenceMaterialTab}
            />
          </>
        }
      >
        {tabs[currentTab]}
      </TabContainer>
    </Grid>
  );
}
