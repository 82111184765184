import React from 'react';

import { withStyles } from '@material-ui/core';

import get from 'lodash/get';
import camelCase from 'lodash/camelCase';

import { themeColors } from 'components/WorkflowIcon';


function StatusDot({ theme, status, style = {}, diameter = 10 }) {
  const colorForStatus = () => get(theme.palette.common, [camelCase(themeColors[camelCase(status)]), 'main'], theme.palette.grey[200]);
  return (
    <div
      style={{
        height: diameter,
        width: diameter,
        borderRadius: '50%',
        backgroundColor: colorForStatus(),
        ...style,
      }}
    />
  );
}

export default withStyles({}, { withTheme: true })(StatusDot);
