import React, { useState } from 'react';
import { useMutation } from 'react-apollo';

import { LinkOutlined } from '@material-ui/icons';

import IconButton from 'components/IconButton';
import { ItemSearch } from 'compositions';

import { CREATE_ASSOCIATED_VALIDATION_RECORD, CREATE_ASSOCIATED_VERIFICATION_RECORD } from './gql';

interface Props {
  currentVersion;
  itemType: string;
  protocol;
  records;
}

const AddRecord = (props: Props) => {
  const [addAssociatedValRecord] = useMutation(CREATE_ASSOCIATED_VALIDATION_RECORD, {
    refetchQueries: ['VerificationAndValidationReportVersionQuery'],
  });
  const [addAssociatedVerRecord] = useMutation(CREATE_ASSOCIATED_VERIFICATION_RECORD, {
    refetchQueries: ['VerificationAndValidationReportVersionQuery'],
  });

  const [linkAnchor, setLinkAnchor] = useState<Element | null>(null);
  const { currentVersion, itemType, protocol, records } = props;
  const { locked } = currentVersion;
  const addRecordMutation = itemType === 'validation_record' ? addAssociatedValRecord : addAssociatedVerRecord;

  const recordsFormattedForSearch = records.map((record) => ({
    currentRelease: record.currentRelease,
    currentVersionId: record.id,
    customIdentifier: record.item.customIdentifier,
    id: record.item.id,
    status: record.currentStatus.name,
    title: record.title,
    userCreatable: record.item.userCreatable,
    workingVersion: record.item.workingVersion,
  }));

  const recordsSearchResults = [
    Object.assign({
      itemType: itemType,
      results: recordsFormattedForSearch,
    }),
  ];

  return (
    <>
      <IconButton
        Icon={LinkOutlined}
        disabled={locked}
        onClick={(e) => {
          setLinkAnchor(e.currentTarget);
        }}
        includeBackground
      />
      {linkAnchor && (
        <ItemSearch
          anchorEl={linkAnchor}
          closeSelf={() => {
            setLinkAnchor(null);
          }}
          customResults={recordsSearchResults}
          defaultSearchTerm={null}
          usesCurrentVersion={true}
          enableVersionSupport={true}
          onSubmit={(recordVersionIds) =>
            addRecordMutation({
              variables: {
                recordVersionIds: recordVersionIds,
                versionId: currentVersion.id,
                parentVersionId: protocol.item.currentVersion.id,
              },
            })
              .then(() => true)
              .catch((error) => {
                throw new Error(`Error creating associated verification record: ${error}`);
              })
          }
          open
        />
      )}
    </>
  );
};

export default AddRecord;
