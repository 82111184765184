import React, { useContext } from 'react';

import SortIcon from '@material-ui/icons/Sort';

import { LockedContext } from 'contexts/lockedContext';

import Panel from 'components/core/Panel';
import Input from 'components/core/form/Input';

interface Props {
  justification: string;
  onChange: (e) => void;
}

function Justification(props: Props) {
  const { locked } = useContext(LockedContext);

  return (
    <Panel>
      <Panel.Heading icon={SortIcon}>Justification</Panel.Heading>
      <Input
        debounced
        defaultValue={props.justification}
        disabled={locked}
        onChange={props.onChange}
        rows={4}
        multiline
        placeholder="Enter justification"
      />
    </Panel>
  );
}

export default Justification;
