import { QueryFunctionOptions, useMutation, useQuery } from 'react-apollo';
import sortBy from 'lodash/sortBy';
import { TASKS_QUERY } from './gql';
import { tasksQuery, tasksQuery_tasks } from './__generated__/tasksQuery';
import { transitionMutation } from 'utils/gql/timelineQueries';
import { CreateTransitionVariables, CreateTransition_createTransition } from 'utils/gql/__generated__/CreateTransition';

export const useTasksQuery = (options?: QueryFunctionOptions<tasksQuery>) =>
  useQuery<tasksQuery>(TASKS_QUERY, { fetchPolicy: 'no-cache', ...options });

export const useTasksMutation = () =>
  useMutation<CreateTransition_createTransition, CreateTransitionVariables>(transitionMutation, {
    refetchQueries: ['tasksQuery', 'TasksQuery', 'TaskListQuery', 'timelineQuery', 'BuilderViewQuery', 'currentUser'],
  });

export const sortByDueDate = (tasks: tasksQuery_tasks[]) => sortBy(tasks, (item) => item.currentVersion.dueDate || '');
