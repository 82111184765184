import React from 'react';

import Table from 'components/core/Table';

import useStyles from './styles';

function ImpactedCourseTable(props) {
  const classes = useStyles();

  return (
    <div className={classes.noOutline}>
      <Table {...props} />
    </div>
  );
}
export default ImpactedCourseTable;
