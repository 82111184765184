import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import Autosuggest from 'react-autosuggest';
import get from 'lodash/get';
import flowRight from 'lodash/flowRight';

import { Paper, Typography, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { UserAvatar } from 'components';
import WithAllUsers from 'compositions/WithAllUsers';

import SearchInput from './SearchInput';
import { displayUserRoles } from './util';

const styles = (theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: 42,
  },
  rowContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    top: 35,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    height: 200,
    overflow: 'scroll',
  },
  userRoles: {
    marginLeft: theme.spacing(2),
  },
});

const getSuggestions = (value, options, userProfile, ignoreSelf = false, ignoreInactive = false) => {
  return options.filter((user) => {
    const inputValue = value.trim().toLowerCase();
    const filterInactive = user.status === 'active';

    if (ignoreSelf && ignoreInactive) {
      const filterSelf = user.id !== userProfile.user.id; // an employee cannot add themself as their own manager
      return user.fullName.toLowerCase().includes(inputValue) && filterSelf && filterInactive;
    }

    if (ignoreInactive) return user.fullName.toLowerCase().includes(inputValue) && filterInactive;

    return user.fullName.toLowerCase().includes(inputValue);
  });
};

const getSuggestionValue = (suggestion) => suggestion.fullName;

const renderSuggestion = (suggestion, classes) => {
  return (
    <>
      <MenuItem component="div">
        <UserAvatar userId={suggestion.id} diameter={24} />
        <div style={{ marginLeft: 8 }}>
          <Typography variant="body2" style={{ fontWeight: 600 }}>
            {suggestion.fullName}
          </Typography>
          <Typography variant="body2" className={classes.userRoles}>
            {displayUserRoles(suggestion.jobRoles)}
          </Typography>
        </div>
      </MenuItem>
    </>
  );
};

class SelectUserField extends Component {
  state = {
    value: null,
    suggestions: [],
    selectedUserId: null,
  };

  onChange = (_, { newValue }) => {
    this.setState({ value: newValue });
  };

  onSuggestionSelected = (_, { suggestion }) => {
    this.setState(
      {
        selectedUserId: suggestion.id,
      },
      () => this.props.onSelectedUserInputData(this.state.selectedUserId),
    );
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const opts = this.props.options ? this.props.options : this.props.allUsers;

    this.setState({
      suggestions: getSuggestions(
        value,
        opts,
        this.props.userProfile,
        this.props.ignoreInactive,
        this.props.ignoreSelf,
      ),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  renderInputComponent = (props) => {
    return (
      <SearchInput
        {...props}
        disabled={props.disabled}
        fullWidth
        onClear={() => this.setState({ value: '' })}
        size="small"
        variant="outlined"
      />
    );
  };

  render() {
    const { classes, currentSelectedUser, unsavedValue, inEditingMode, placeholder, disabled } = this.props;
    const { suggestions, value } = this.state;

    const selectedUser = currentSelectedUser && currentSelectedUser.fullName;
    let displayValue;
    if (value !== null && selectedUser) {
      displayValue = value;
    } else if (unsavedValue) {
      displayValue = unsavedValue.displayValue;
    } else if (selectedUser) {
      displayValue = selectedUser;
    } else if (disabled) {
      displayValue = 'N/A';
    } else {
      displayValue = '';
    }

    return (
      <>
        {inEditingMode ? (
          <>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={this.onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              renderInputComponent={this.renderInputComponent}
              shouldRenderSuggestions={this.shouldRenderSuggestions}
              inputProps={{
                value: displayValue,
                classes,
                onChange: this.onChange,
                placeholder,
                disabled,
              }}
              theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
              }}
              renderSuggestionsContainer={(options) => (
                <Paper {...options.containerProps} square>
                  {options.children}
                </Paper>
              )}
            />
          </>
        ) : (
          <Typography variant="body2" style={{ width: '100%' }}>
            {get(currentSelectedUser, 'fullName', 'N/A')}
          </Typography>
        )}
      </>
    );
  }
}

export default flowRight([withStyles(styles), withApollo, WithAllUsers])(SelectUserField);
