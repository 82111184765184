import gql from 'graphql-tag';

export const ADD_STEP = gql`
  mutation addStep($text: String, $parentVersionId: ID!, $itemId: ID) {
    addTestSetupStep(text: $text, parentVersionId: $parentVersionId, itemId: $itemId) {
      step {
        id
      }
    }
  }
`;

export const REMOVE_STEP = gql`
  mutation removeStep($id: ID!) {
    removeTestSetupStep(id: $id) {
      success
    }
  }
`;

export const UPDATE_STEP = gql`
  mutation updateStep($id: ID!, $text: String, $position: Int, $itemId: ID) {
    updateTestSetupStep(id: $id, text: $text, position: $position, itemId: $itemId) {
      success
    }
  }
`;
