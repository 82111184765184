import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    chatInput: {
      backgroundColor: theme.palette.common.white,
      color: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      position: 'static',
      overflow: 'scroll',
      width: '100%',
      padding: '0 8px 0 8px',
      resize: 'none',
    },
    chatMessage: {
      display: 'flex',
      marginTop: theme.spacing(4),
      transform: 'rotate(180deg) scaleX(-1)',
    },
    chatMessageContainer: {
      backgroundColor: theme.palette.background.contextDrawerGrey,
      flex: 1,
      overflowX: 'hidden',
      overflowY: 'scroll',
      padding: theme.spacing(1),
      transform: 'rotate(180deg) scaleX(-1)',
    },
    chatMessageContent: {
      flex: 9,
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    timestamp: {
      clear: 'both',
      paddingRight: theme.spacing(1),
      textAlign: 'right',
    },
    messageBody: { paddingRight: theme.spacing(1) },
    from: { fontWeight: theme.typography.fontWeightBold },
    avatarContainer: { alignItems: 'center', display: 'flex', flex: 1 },
    root: {
      backgroundColor: theme.palette.grey[100],
      height: '100%',
      position: 'relative',
    },
    inputContainer: { bottom: 0, position: 'absolute', width: '100%' },
    commentDate: {
      ...theme.typography.overline,
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      position: 'absolute',
      marginTop: theme.spacing(1) * -2,
    },
  });
