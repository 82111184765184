import React, { FC } from 'react';

import { InputAdornment, TextField, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { useCurrentCompany } from 'compositions/WithCurrentCompany';
import { ConfirmationDialog, IconButton } from 'components';
import EditButton from 'components/EditButton';

import { useStyles } from './styles';
import UploadFile from 'assets/icons/UploadFile';

interface Props {
  editLogo: boolean;
  error: string | null;
  success: string | null;
  dialogOpen: boolean;
  capturedFileName: string;
  setEditLogo: () => void;
  onUploadClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  onDialogConfirm: () => void;
  setConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onCapture: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CompanyLogo: FC<Props> = ({
  editLogo,
  error,
  success,
  dialogOpen,
  capturedFileName,
  onUploadClick,
  setEditLogo,
  setConfirmationDialog,
  onDialogConfirm,
  onCapture,
}) => {
  const classes = useStyles();
  const { data } = useCurrentCompany();

  if (!data) return null;
  const { currentCompany } = data;

  return (
    <>
      <div className={classes.logoContainer}>
        <div className={classes.labelContainer}>
          <Typography variant="h5" className={classes.label}>
            Logo
          </Typography>
          {currentCompany.logoUrl && <EditButton onClick={setEditLogo} />}
        </div>
        <div className={classes.logoInputContainer}>
          {editLogo ? (
            <TextField
              variant="outlined"
              placeholder="Add logo"
              value={capturedFileName}
              helperText={error || 'Supported formats: png, jpg. Max. 10 MB'}
              FormHelperTextProps={{ className: classes.helperText }}
              error={!!error}
              InputProps={{
                classes: { input: classes.input },
                readOnly: true,
                endAdornment: (
                  <InputAdornment component="label" position="end">
                    <UploadFile className={classes.inputIcon} />
                    <input type="file" hidden accept=".jpg,.png,.jpeg" onChange={onCapture} onClick={onUploadClick} />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            currentCompany.logoUrl && (
              <div className={classes.logoImageArea}>
                <img src={currentCompany.logoUrl} alt={currentCompany.name} width={150} />
                <div className={classes.deleteButton}>
                  <IconButton Icon={Delete} onClick={() => setConfirmationDialog(true)} />
                </div>
              </div>
            )
          )}
          {success && (
            <Typography variant="caption" className={classes.helperTextSuccess}>
              {success}
            </Typography>
          )}
        </div>
      </div>
      <ConfirmationDialog
        open={dialogOpen}
        title="Confirm Removal"
        onClose={() => setConfirmationDialog(false)}
        onCancel={() => setConfirmationDialog(false)}
        onConfirm={onDialogConfirm}
      >
        Are you sure you want to remove the logo
      </ConfirmationDialog>
    </>
  );
};

export default CompanyLogo;
