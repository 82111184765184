import React from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import flowRight from 'lodash/flowRight';
import LocalOffer from '@material-ui/icons/LocalOffer';

import styles from './styles';
import MemberTags from '../MemberTags';

class TagsControl extends React.Component {
  buttonRef = undefined;

  render() {
    const { classes, onClick, onClose, i18n, showPopup, itemId, tags, queryToRefetch, disabled } = this.props;
    return (
      <>
        <IconButton
          data-testid="tags-control"
          aria-label={i18n._(t`Open Tags`)}
          buttonRef={(n) => (this.buttonRef = n)}
          disableRipple
          className={classes.iconButton}
          onClick={onClick}
          disabled={disabled}
        >
          <LocalOffer classes={{ root: classes.smallIcon }} />{' '}
        </IconButton>

        <MemberTags
          open={showPopup}
          anchorEl={this.buttonRef}
          onClose={onClose}
          itemId={itemId}
          tags={tags}
          queryToRefetch={[queryToRefetch]}
        />
      </>
    );
  }
}

export default flowRight([withStyles(styles), withI18n()])(TagsControl);
