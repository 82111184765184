import React, { useState } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ExpandCarrot from 'components/core/ExpandCarrot';
import Spacer from 'components/Spacer';

import PrecomplianceTaskSection from './PrecomplianceTaskSection';
import { useStyles } from './styles';

interface Props {
  category;
  index: number;
}

function PrecomplianceCategorySection(props: Props) {
  const { category } = props;
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles();

  const completedTasks = () => category.tasks.filter((task) => task.completedAt);
  const progressText = () => `${completedTasks().length}/${category.tasks.length}`;
  const progressValue = () => (completedTasks().length / category.tasks.length) * 100;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <Typography variant="h4">
            {props.index + 1}. {props.category.name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <LinearProgress variant="determinate" value={progressValue()} className={classes.progress} />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">{progressText()}</Typography>
          <span className={classes.expandContainer}>
            <ExpandCarrot expanded={expanded} setExpanded={setExpanded} />
          </span>
        </Grid>
      </Grid>
      <Spacer factor={2} />
      {expanded &&
        props.category.tasks
          .sort((task1, task2) => task1.position - task2.position)
          .map((task, idx) => (
            <div key={`task-${idx}`} className={classes.taskContainer}>
              <PrecomplianceTaskSection key={`task.title-${idx}`} task={task} />
            </div>
          ))}
    </>
  );
}

export default PrecomplianceCategorySection;
