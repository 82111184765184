import React from 'react';

import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

function ProfileSection({ icon, children }) {
  const classes = useStyles();
  const Icon = icon;
  return (
    <div className={classes.container}>
      <Icon fontSize="small" className={classes.icon} />
      <Typography variant="body2">{children}</Typography>
    </div>
  );
}

export default ProfileSection;
