import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import { ItemSearch, ItemSearchVariables, ItemSearch_search } from './__generated__/ItemSearch';
import {
  ItemTraceMatrixSearch,
  ItemTraceMatrixSearchVariables,
  ItemTraceMatrixSearch_traceMatrixSearch,
} from './__generated__/ItemTraceMatrixSearch';

export interface SearchOptions extends ItemSearchVariables, ItemTraceMatrixSearchVariables {
  skipSearch?: boolean;
}

export const ITEM_SEARCH = gql`
  query ItemSearch(
    $query: String
    $itemTypes: [String!]
    $linksGraphRootId: ID
    $excludedStatuses: [String!]
    $excludedCurrentStatuses: [String!]
    $excludedIds: [ID!]
  ) {
    search(
      query: $query
      itemTypes: $itemTypes
      linksGraphRootId: $linksGraphRootId
      excludedStatuses: $excludedStatuses
      excludedCurrentStatuses: $excludedCurrentStatuses
      excludedIds: $excludedIds
    ) {
      itemType
      results {
        id
        itemType {
          id
          displaySlug
        }
        customIdentifier
        title
        status
        userCreatable
        currentVersionId
      }
    }
  }
`;

export const TRACE_MATRIX_SEARCH = gql`
  query ItemTraceMatrixSearch(
    $query: String
    $itemTypes: [String!]
    $traceMatrixId: ID!
    $excludedIds: [ID!]
    $linksGraphRootId: ID
    $excludedStatuses: [String!]
    $excludedCurrentStatuses: [String!]
  ) {
    traceMatrixSearch(
      query: $query
      itemTypes: $itemTypes
      traceMatrixId: $traceMatrixId
      excludedIds: $excludedIds
      linksGraphRootId: $linksGraphRootId
      excludedStatuses: $excludedStatuses
      excludedCurrentStatuses: $excludedCurrentStatuses
    ) {
      itemType
      results {
        id
        itemType {
          id
          displaySlug
        }
        customIdentifier
        title
        status
        userCreatable
        currentVersionId
      }
    }
  }
`;

function removeAttributesWithNoValues(options: ItemSearchVariables & ItemTraceMatrixSearchVariables) {
  Object.keys(options).forEach((k) => {
    if (
      !options[k] ||
      options[k] === null ||
      options[k] === undefined ||
      (Array.isArray(options[k]) && options[k].length === 0)
    )
      delete options[k];
  });
  return options;
}

export function useSearch(options: SearchOptions) {
  const skipQuery = options.skipSearch || isEmpty(removeAttributesWithNoValues(options));
  const { data, loading, error, refetch } = useQuery<ItemSearch & ItemTraceMatrixSearch>(
    options.traceMatrixId ? TRACE_MATRIX_SEARCH : ITEM_SEARCH,
    {
      variables: options,
      skip: skipQuery,
      fetchPolicy: 'cache-and-network',
    },
  );

  const search = data ? (data!.search as ItemSearch_search[]) : [];
  const itemTraceSearch = data ? (data!.traceMatrixSearch as ItemTraceMatrixSearch_traceMatrixSearch[]) : [];

  const items: ItemSearch_search[] | ItemTraceMatrixSearch_traceMatrixSearch[] = search ? search : itemTraceSearch;

  return {
    items,
    loading,
    error,
    refetch,
  };
}

export function useSearchResultMap(options: SearchOptions) {
  const { items, ...rest } = useSearch(options);

  let resultMap = {};
  items.forEach((category) => {
    resultMap[category.itemType] = category.results;
  });

  return {
    resultMap,
    ...rest,
  };
}
