import { t } from '@lingui/macro';
import School from '@material-ui/icons/School';
import { Domain } from '@material-ui/icons';
import itemTypeToCategory from './itemTypeToCategory';
import ItemTypes from 'itemTypes';
import { People, Settings, Description, ThumbUp } from '@material-ui/icons';
import Toolbox from 'assets/icons/Toolbox';
import AdminPanelSettingsIcon from 'assets/icons/AdminPanelSettings';
import SettingsApplicationsIcon from 'assets/icons/SettingsApplications';
import UserManagementIcon from 'assets/icons/UserManagement';
import RoleManagementIcon from 'assets/icons/RoleManagement';

const categories = [
  {
    icon: Domain,
    name: t`Precompliance Dashboard`,
    slug: 'precompliance-dashboard',
    isDashboard: true,
  },
  //from validation first part
  itemTypeToCategory(ItemTypes.ValidationURSDocument),
  itemTypeToCategory(ItemTypes.ValidationRequirementsDocument),
  itemTypeToCategory(ItemTypes.QualitySystemValidationPlan),
  itemTypeToCategory(ItemTypes.ValidationDRSDocument),
  itemTypeToCategory(ItemTypes.ValidationProtocolDocument),
  itemTypeToCategory(ItemTypes.QualitySystemValidationReport),
  itemTypeToCategory(ItemTypes.ValidationTraceMatrixDoc),
  //from validation second part
  itemTypeToCategory(ItemTypes.NqmsURSDoc),
  itemTypeToCategory(ItemTypes.UserRequirementSpecification),
  itemTypeToCategory(ItemTypes.NqmsSRSSet),
  itemTypeToCategory(ItemTypes.SystemRequirementSpecification),
  itemTypeToCategory(ItemTypes.NqmsSRT),
  itemTypeToCategory(ItemTypes.SystemRequirementTable),
  itemTypeToCategory(ItemTypes.NqmsDesignRequirementSpec),
  itemTypeToCategory(ItemTypes.DesignRequirementSpecification),
  itemTypeToCategory(ItemTypes.NqmsValidationPlan),
  itemTypeToCategory(ItemTypes.NqmsValidationProtocol),
  itemTypeToCategory(ItemTypes.NqmsValidationReport),
  itemTypeToCategory(ItemTypes.NqmsTraceMatrixDoc),
  itemTypeToCategory(ItemTypes.PatientPopulationProfile),
  itemTypeToCategory(ItemTypes.CorporateQualityPlan),
  itemTypeToCategory(ItemTypes.QualityPolicy),
  itemTypeToCategory(ItemTypes.TrainingPlan),
  itemTypeToCategory(ItemTypes.TrainingRecord),
  itemTypeToCategory(ItemTypes.UseEnvironmentSpecification),
  itemTypeToCategory(ItemTypes.UserGroupSpecification),

  {
    icon: Toolbox,
    name: t`Utilities Home`,
    slug: 'utilities-home',
  },
  itemTypeToCategory(ItemTypes.Task),
  {
    icon: People,
    name: t`Item Version Index`,
    slug: 'item-version-index',
  },
  {
    icon: People,
    name: t`Team Directory`,
    slug: 'employees',
    excludeFromCategoryIndex: false,
  },
  {
    icon: Description,
    name: t`Training Report`,
    slug: 'training-report',
  },
  {
    icon: ThumbUp,
    name: t`Approved Suppliers`,
    slug: 'approved-supplier',
  },
  {
    icon: Error,
    name: t`Risk Trace Matrices`,
    slug: 'risk-trace-matrices',
  },
  {
    icon: Error,
    name: t`Risk Control Effectiveness Tests`,
    slug: 'risk-control-effectiveness-tests',
  },
  {
    icon: Error,
    name: t`Risk Control Implementation Tests`,
    slug: 'risk-control-implementation-tests',
  },
  {
    icon: Settings,
    name: t`My User Settings`,
    slug: 'user-settings',
  },
  {
    icon: AdminPanelSettingsIcon,
    name: t`Administration`,
    slug: 'administration',
    children: [
      {
        icon: SettingsApplicationsIcon,
        name: t`General Settings`,
        slug: 'general-settings',
      },
      {
        icon: UserManagementIcon,
        name: t`User Management`,
        slug: 'user-management',
      },
      {
        icon: RoleManagementIcon,
        name: t`Role Management`,
        slug: 'role-management',
      },
    ],
  },
];

const optionalCategories = {
  id: 'optionalCategories',
  label: t`Optional Categories`,
  shortLabel: t`optional`,
  icon: School,
  categories,
  colorKey: 'palette.appWindows.trainingManagement',
};

export default optionalCategories;
