import React, { FC } from 'react';

import { FormControl, Typography } from '@material-ui/core';
import { Sort } from '@material-ui/icons';
import { TextArea } from 'components';

import Spacer from '../Spacer';
import { useStyles } from './styles';

interface Props {
  textboxHeading: string;
  textboxId: string;
  onTextChange: (e) => void;
}

const ReasonForChangeTextArea: FC<Props> = ({ textboxHeading, textboxId, onTextChange }) => {
  const classes = useStyles();

  return (
    <>
      <span>
        <Typography variant="subtitle1" style={{ textAlign: 'start' }}>
          <Sort className={classes.icon} />
          {textboxHeading}
        </Typography>
      </span>
      <Spacer factor={0.5} />
      <FormControl required fullWidth>
        <TextArea id={textboxId} initialValue={''} rows={5} placeholder={'Enter description'} onChange={onTextChange} />
      </FormControl>
      <Typography variant="subtitle2" align="right" className={classes.textBoxCaption}>
        *Required
      </Typography>
    </>
  );
};
export default ReasonForChangeTextArea;
