import gql from 'graphql-tag';

export const TOGGLE_TEST_OVERVIEW = gql`
  mutation ToggleTestOverviewSummary($id: ID!, $toggle: Boolean!) {
    updateDeviceVerification(id: $id, toggleTestOverview: $toggle) {
      deviceVerification {
        id
      }
    }
  }
`;
