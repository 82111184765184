import gql from 'graphql-tag';

export const commentsQuery = gql`
  query GetComments($itemId: ID!) {
    item(id: $itemId) {
      id
      comments {
        id
        body
        createdAt
        creator {
          id
          fullName
        }
      }
    }
  }
`;

export const mutation = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      comment {
        id
        body
      }
    }
  }
`;
