import React, { useState } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import { useForm } from 'components/Form';
import { Tab, TabContainer, TabIcon } from 'components';
import styles from 'components/BuilderView/styles';

import RenderTab from '../RenderTab';
import { SUPPLIER_PAGE_TABS } from '..';

interface Props {
  version: any;
  classes: any;
}

function PreviousQualificationLayout(props: Props) {
  const INITIAL_TAB = 'scope';
  const [currentTab, setCurrentTab] = useState(INITIAL_TAB);

  const form = useForm({ data: props.version, update: (param: any) => Promise.resolve(param) });

  return (
    <TabContainer
      layoutClasses={props.classes}
      tabs={
        <>
          <Tab
            icon={<TabIcon Icon={SUPPLIER_PAGE_TABS.scope.icon} />}
            active={SUPPLIER_PAGE_TABS.scope.name === currentTab}
            onClick={() => setCurrentTab(SUPPLIER_PAGE_TABS.scope.name)}
            text={SUPPLIER_PAGE_TABS.scope.title}
          />
          <Tab
            icon={<TabIcon Icon={SUPPLIER_PAGE_TABS.activities.icon} />}
            active={SUPPLIER_PAGE_TABS.activities.name === currentTab}
            onClick={() => setCurrentTab(SUPPLIER_PAGE_TABS.activities.name)}
            text={SUPPLIER_PAGE_TABS.activities.title}
          />
        </>
      }
    >
      <RenderTab name={currentTab} version={props.version} save={null} form={form} />
    </TabContainer>
  );
}

export default withStyles(styles)(PreviousQualificationLayout);
