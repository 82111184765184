import React from 'react';

import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import { Typography } from '@material-ui/core';

import { get, isUndefined, last, nth } from 'lodash';

import { StatusDot } from 'components';

function prevChar(char) {
  return String.fromCharCode(char.charCodeAt(0) - 1);
}

function hasNumber(myString) {
  return /\d/.test(myString);
}

interface VersionSequenceWithStatus {
  versions: any[];
  status: string;
}

function VersionSequenceWithStatus(props: VersionSequenceWithStatus) {
  const { versions, status } = props;
  const currentVersionIdentifier = get(last(versions), 'versionIdentifier');
  const prevVersionStatus = get(nth(versions, -2), 'currentStatus.name');

  const statusDot = (versionNumber?: string) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body2"
          style={{
            marginRight: 2,
          }}
          component="div"
        >
          {isUndefined(versionNumber) ? '_' : `v${versionNumber}`}
        </Typography>
        {versions.length > 1 && <StatusDot status={prevVersionStatus} diameter={10} />}
      </div>
    );
  };

  const Arrow = () => <ArrowRightAlt style={{ fontSize: 24 }} />;

  const cellContent = () => {
    switch (true) {
      case (currentVersionIdentifier === 'v1' || currentVersionIdentifier === 'vA') && status !== 'released':
        return (
          <>
            {statusDot()} {Arrow()} {currentVersionIdentifier}
          </>
        );
      case status === 'released':
        return <>{statusDot(currentVersionIdentifier.substr(1))}</>;
      case (currentVersionIdentifier === 'v1' && status === 'released') ||
        (currentVersionIdentifier !== 'v1' && hasNumber(currentVersionIdentifier)):
        return (
          <>
            {statusDot(String(currentVersionIdentifier.substr(1) - 1))} {Arrow()}
            <span>{currentVersionIdentifier}</span>
          </>
        );
      case currentVersionIdentifier === 'vA' && status === 'released':
        return (
          <>
            {statusDot(prevChar(currentVersionIdentifier.substr(1)))} {Arrow()} <span>{currentVersionIdentifier}</span>
          </>
        );
      case !hasNumber(currentVersionIdentifier):
        return (
          <>
            {statusDot(prevChar(currentVersionIdentifier.substr(1)))} {Arrow()} <span>{currentVersionIdentifier}</span>
          </>
        );
    }
  };

  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{cellContent()}</div>;
}

export default VersionSequenceWithStatus;
