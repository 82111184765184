import { getBaseApiUrl } from 'utils';

export default class AttachmentClient {
  static endpoint = `${getBaseApiUrl()}/attachments`;

  constructor(token) {
    this.headers = new Headers();
    this.headers.append('Authorization', token);
  }

  // TODO
  // upload() {
  //}

  delete(versionId, attachmentId) {
    const formData = new FormData();
    formData.append('version_id', versionId);
    formData.append('attachment_id', attachmentId);

    const handler = (resolve, reject) => {
      fetch(AttachmentClient.endpoint, {
        headers: this.headers,
        method: 'DELETE',
        body: formData,
      })
        .then((response) => {
          if (response.ok) return resolve(response);

          return /^application\/json/i.test(
            response.headers.get('Content-Type'),
          )
            ? response
                .json()
                .then((body) => reject(new Error(body.errors.join(', '))))
            : response.text().then((text) => reject(new Error(text)));
        })
        .catch((error) => reject(error));
    };

    return new Promise(handler);
  }
}
