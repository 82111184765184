import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import Colorize from '@material-ui/icons/Colorize';

import { buildRecordQuery } from 'utils/gql/itemRecordQuery';
import slugs from '../../displaySlugs';
import Views from 'workspaces/views';

import PayloadFields from './PayloadFields';

const itemType = 'targetProductProfile';

const versionFields = `
  targetProductProfileCategories {
    id
    currentVersion {
      id
      title
      targetGuidelines
      annotationGuidelines
      targetProductProfileCharacteristicsCollectionId
      targetProductProfileCharacteristics {
        comments {
          id
        }
        id
        customIdentifier
        currentVersion {
          id
          title
          target
          annotation
        }
      }
    }
  }
`;

const query = gql`
  query targetProductProfileQuery {
    ${buildRecordQuery({ itemType, versionFields, userCreatable: false })}
  }
`;

export default {
  displaySlug: slugs[itemType],
  icon: Colorize,
  itemType,
  label: t`Target Product Profile`,
  categoryLabel: t`Target Product Profile`,
  versionFields,
  PayloadFields,
  query,
  recordPageWorkspaceViews: [Views.Set, Views.Effective, Views.Versions],
};
