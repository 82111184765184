import { useMutation } from 'react-apollo';
import { COMPLETE_PRECOMPLIANCE_TASK, UNCOMPLETE_PRECOMPLIANCE_TASK, INITIATE_PRECOMPLIANCE_TASK } from './gql';

export function useTaskToggle(taskId: number) {
  const [completeMutation] = useMutation(COMPLETE_PRECOMPLIANCE_TASK, {
    refetchQueries: ['PrecomplianceTaskCategories'],
  });
  const [uncompleteMutation] = useMutation(UNCOMPLETE_PRECOMPLIANCE_TASK, {
    refetchQueries: ['PrecomplianceTaskCategories'],
  });
  const [initiateMutation] = useMutation(INITIATE_PRECOMPLIANCE_TASK, {
    refetchQueries: ['PrecomplianceTaskCategories'],
  });

  return {
    complete: () => completeMutation({ variables: { id: taskId } }),
    uncomplete: () => uncompleteMutation({ variables: { id: taskId } }),
    initiate: () => initiateMutation({ variables: { id: taskId } }),
  };
}
