import gql from 'graphql-tag';
import {
  InProcessInspectionVersion,
  InProcessInspectionVersionVariables,
} from './__generated__/InProcessInspectionVersion';
import { useQuery } from 'react-apollo';

const versionQuery = gql`
  query InProcessInspectionVersion($id: ID!) {
    inProcessInspectionVersion(id: $id) {
      id
      tag
      inputMethod
      body
      attachment {
        id
        url
        extension
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      rawReferenceAttachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
        filename
      }
      displayAsApplicationVersion
      locked
      createdAt
      creator {
        id
        fullName
        title
      }
      signingOwner {
        id
        firstName
        lastName
      }
      signingApprover {
        id
        firstName
        lastName
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<InProcessInspectionVersion, InProcessInspectionVersionVariables>(versionQuery, {
    variables: { id },
  });
}
