import React from 'react';

import { Typography } from '@material-ui/core';

import { RiskDashboard_riskMatrixEntries as RPCMatrixEntry } from 'pages/RiskManagementDashboard/__generated__/RiskDashboard';

import { useStyles } from './styles';

interface Props {
  matrixValue: RPCMatrixEntry;
}

const MatrixCell: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { matrixValue } = props;

  return (
    <div className={classes.matrixCell}>
      <div className={classes.matrixCellContent}>
        <div className={classes.matrixCellColorItem} style={{ backgroundColor: matrixValue.riskPriorityCode.color }} />
        <Typography variant="subtitle1">{matrixValue.riskPriorityCode.name}</Typography>
      </div>
    </div>
  );
};

export default MatrixCell;
