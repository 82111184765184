export enum Status {
  Planning = 'planning',
  Active = 'active',
  Inactive = 'inactive',
  Retired = 'retired',
}

export const Order = {
  [Status.Planning]: 0,
  [Status.Active]: 1,
  [Status.Inactive]: 2,
  [Status.Retired]: 3,
};

export enum ServerAction {
  StatusTransition,
}

export type TransitionPayload =
  | {
      action: ServerAction.StatusTransition;
      payload: {
        toStatus: Status;
        username: string;
        password: string;
        reasonForSignature: string;
      };
    };
