import React, { CSSProperties } from 'react';

import { Typography } from '@material-ui/core';

import get from 'lodash/get';
import { DateTime } from 'luxon';
import { Column } from 'material-table';

import { useStyles } from './styles';

export function formatDate(x: string | null) {
  if (!x) return null;
  return DateTime.fromISO(x).toFormat('MMM. d, yyyy');
}

export const filterAndSearch = (field: string) => <T extends object>(
  filter: string,
  data: T,
  _columnDef: Column<T>,
): boolean => {
  const dt = get(data, field);
  if (!dt) return false;
  const date = formatDate(dt).toLowerCase();
  return date.includes(filter.toLowerCase());
};

export function DateCell({ data, defaultValue = 'TBD' }) {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Typography variant="body2">{formatDate(data) || defaultValue}</Typography>
    </div>
  );
}

export const dateHeaderStyle = { textAlign: 'left' } as CSSProperties;
