import React, { useState } from 'react';

import { Button, Tooltip } from '@material-ui/core';
import { useMutation } from 'react-apollo';

import { ItemCreatedSnackbar } from 'components';
import { getBaseApiUrl } from 'utils';

import CreateQuestionnaireRecordModal from './CreateQuestionnaireRecordModal';
import { mutateQuestionnaireRecord } from '../gql';

interface CreateQuestionnaireRecordProps {
  questionnaireIdentifier: string;
  questionnaireVersionId: string;
  refetchQueries: string[];
  currentUser: any;
}

function CreateQuestionnaireRecord(props: CreateQuestionnaireRecordProps) {
  const {questionnaireIdentifier, questionnaireVersionId, refetchQueries, currentUser } = props;
  const [showCreateQuestionnaireRecordModal, setShowCreateQuestionnaireRecordModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [customIdentifier, setCustomIdentifier] = useState('');
  const [showItemCreatedSnackbar, setShowItemCreatedSnackbar] = useState(false);
  const [createQuestionnaireRecord] = useMutation(mutateQuestionnaireRecord);

  const onCreateQuestionnaireRecord = (data) => {
    const newQuestionnaireRecord = data.createQuestionnaireRecord.item;
    setItemId(newQuestionnaireRecord.id);
    setCustomIdentifier(newQuestionnaireRecord.customIdentifier);
    setShowItemCreatedSnackbar(true);
  };

  const base = new URL('exports.pdf', getBaseApiUrl() || window.location.origin);
  base.searchParams.append('id', questionnaireVersionId);
  base.searchParams.append('source', window.location.toString());
  base.searchParams.append('disposition', 'attachment');

  return (
    <>
      <Tooltip title={currentUser.readOnly ? 'Upgraded license required to use this feature' : ''}>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowCreateQuestionnaireRecordModal(!showCreateQuestionnaireRecordModal)}
            disabled={currentUser.readOnly}
          >
            Use This Questionnaire
          </Button>
        </div>
      </Tooltip>
      <CreateQuestionnaireRecordModal
        open={showCreateQuestionnaireRecordModal}
        onClose={() => setShowCreateQuestionnaireRecordModal(false)}
        handleComplete={() => {
          createQuestionnaireRecord({
            variables: {
              input: {
                questionnaireVersionId,
                productId: 1, //FIXME: this needs to accommodate additional products in the future
                title: 'Default Title',
                host: base,
              },
            },
            refetchQueries,
            awaitRefetchQueries: true,
            update: (_cache, { data }) => {
              onCreateQuestionnaireRecord(data);
            },
          }).catch((e) => {
            throw new Error(`Error ocurred while creating questionnaire record item: ${e}`);
          });
          setShowCreateQuestionnaireRecordModal(false);
          setItemId(null);
          setCustomIdentifier('');
        }}
        questionnaireIdentifier={questionnaireIdentifier}
      />
      <ItemCreatedSnackbar
        open={showItemCreatedSnackbar}
        handleSnackbarClose={() => setShowItemCreatedSnackbar(false)}
        customIdentifier={customIdentifier}
        itemId={itemId}
        selectedItemType={'supplierQuestionnaireRecord'}
      />
    </>
  );
}

export default CreateQuestionnaireRecord;