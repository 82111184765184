import React, { useContext, useEffect, useState } from 'react';

import { flowRight, get, isUndefined } from 'lodash';

import { LockedContext, withLockedContext } from 'contexts/lockedContext';
import { Tab, TabContainer } from 'components';
import withCurrentUser from 'compositions/WithCurrentUser';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';

import { useVersion, useUpdateVersion } from './gql';
import RiskAnalysisAndEvaluation from './RiskAnalysisAndEvaluationTab';
import BlurWithPanel from './UnavailablePanel';
import OverallRiskEvaluation from './OverallRiskEvaluationTab';
import RiskControl from './RiskControlTab';
import IdentifyingNewRisksTab from './IdentifyingNewRiskTab';
import { useMutation } from 'react-apollo';
import { CREATE_HARM } from 'pages/Harms/gql';
import { CREATE_HAZARD } from 'pages/Hazards/gql';
import { onGraphQLError } from './utils';

enum RiskTabType {
  analysisAndEvaluation = 'RISK ANALYSIS & EVALUATION',
  control = 'RISK CONTROL',
  newRisks = 'IDENTIFYING NEW RISKS',
  overall = 'OVERALL RISK EVALUATION',
}

function PayloadFields(props: any) {
  const { layoutClasses, versionId } = props;

  const { data, loading, error } = useVersion(props.versionId || '');
  const [createHarm] = useMutation(CREATE_HARM);
  const [createHazard] = useMutation(CREATE_HAZARD);

  const [finalRiskAcceptability, setFinalRiskAcceptability] = useState<boolean | null>(null);
  const [currentTab, setCurrentTab] = useState<RiskTabType>(RiskTabType.analysisAndEvaluation);

  const { setLocked } = useContext(LockedContext);

  const [save] = useUpdateVersion();

  const onFieldSave = (field: string) => (value: any) => {
    save({
      variables: {
        id: versionId,
        [field]: value,
      },
    }).catch(onGraphQLError('Error occurred while updating the Risk'));
  };

  useEffect(() => {
    if (data) {
      // @ts-ignore
      setLocked(data.riskVersion.locked);
      setFinalRiskAcceptability(data.riskVersion.finalRiskAcceptability);
    }
  }, [data, setLocked]);

  const onHazardsChange = (value: string) => {
    const version = get(data, 'riskVersion');
    const hazards = get(data, 'hazards', []);
    if (isUndefined(value)) return onFieldSave('hazardId')(null);
    if (version && version.hazard && value === version.hazard.name) {
      return;
    }
    const existingHazard = hazards.find((h) => h.name === value);
    if (existingHazard) {
      onFieldSave('hazardId')(Number(existingHazard.id));
    } else {
      createHazard({ variables: { name: value } })
        .then((result) => {
          onFieldSave('hazardId')(Number(result.data.createHazard.hazard.id));
        })
        .catch(onGraphQLError('Error occurred while creating Hazard'));
    }
  };

  const onHarmChange = (harmType: 'harm' | 'mitigatedHarm') => (value: string) => {
    const version = get(data, 'riskVersion');
    const harms = get(data, 'harms', []);
    const type = get(data, 'riskVersion.mitigatedAsUnmitigated') ? 'harm' : harmType;

    if (isUndefined(value)) return onFieldSave(`${type}Id`)(null);
    if (version && get(version, type) && value === get(version, `${type}.name`)) {
      return;
    }
    const existingHarm = harms.find((h) => h.name === value);
    if (existingHarm) {
      onFieldSave(`${type}Id`)(Number(existingHarm.id));
    } else {
      createHarm({ variables: { name: value } })
        .then((result) => {
          onFieldSave(`${type}Id`)(Number(result.data.createHarm.harm.id));
        })
        .catch(onGraphQLError(`Error occurred while creating Harm`));
    }
  };

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading risk version ${versionId}\n${error}`);

  const { severityLevels, probabilityLevels, harms, hazards, riskControls, risks } = data;
  const tabs = {
    [RiskTabType.analysisAndEvaluation]: (
      <RiskAnalysisAndEvaluation
        callbacks={{
          onHazardsChange,
          onHarmChange,
        }}
        dictionaries={{ severityLevels, probabilityLevels, harms, hazards }}
        onFieldSave={onFieldSave}
        riskVersion={data.riskVersion}
        viewType={props.activeWorkspaceView.id}
      />
    ),
    [RiskTabType.control]: (
      <BlurWithPanel blur={!!finalRiskAcceptability} container={false}>
        <RiskControl
          callbacks={{
            onHazardsChange,
            onHarmChange,
          }}
          dictionaries={{ severityLevels, probabilityLevels, harms, hazards, riskControls }}
          onFieldSave={onFieldSave}
          riskVersion={data.riskVersion}
          viewType={props.activeWorkspaceView.id}
        />
      </BlurWithPanel>
    ),
    [RiskTabType.newRisks]: (
      <BlurWithPanel blur={!!finalRiskAcceptability} container={false}>
        <IdentifyingNewRisksTab
          dictionaries={{ riskControls, risks }}
          onFieldSave={onFieldSave}
          riskVersion={data.riskVersion}
        />
      </BlurWithPanel>
    ),
    [RiskTabType.overall]: (
      <BlurWithPanel blur={!!finalRiskAcceptability} container={false}>
        <OverallRiskEvaluation onFieldSave={onFieldSave} riskVersion={data.riskVersion} />
      </BlurWithPanel>
    ),
  };

  return (
    <TabContainer
      layoutClasses={layoutClasses}
      tabs={
        <>
          <Tab
            active={RiskTabType.analysisAndEvaluation === currentTab}
            onClick={() => setCurrentTab(RiskTabType.analysisAndEvaluation)}
            text={RiskTabType.analysisAndEvaluation}
          />
          <Tab
            active={RiskTabType.control === currentTab}
            onClick={() => setCurrentTab(RiskTabType.control)}
            text={RiskTabType.control}
          />
          <Tab
            active={RiskTabType.newRisks === currentTab}
            onClick={() => setCurrentTab(RiskTabType.newRisks)}
            text={RiskTabType.newRisks}
          />
          <Tab
            active={RiskTabType.overall === currentTab}
            onClick={() => setCurrentTab(RiskTabType.overall)}
            text={RiskTabType.overall}
          />
        </>
      }
    >
      {tabs[currentTab]}
    </TabContainer>
  );
}

export default flowRight([withCurrentUser, withLockedContext, withWorkspaceViews()])(PayloadFields);
