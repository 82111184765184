import { makeStyles } from '@material-ui/core';

// @ts-ignore
export const useStyles = makeStyles((theme) => ({
  border: {
    height: 2,
    backgroundColor: theme.palette.grey[100],
    width: '100%',
  },
  caption: {
    textTransform: 'uppercase',
    fontSize: '0.5rem',
    opacity: 0.6,
    paddingTop: theme.spacing(0.125),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  formRow: {
    display: 'flex',
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  formRight: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  h7: {
    // @ts-ignore
    ...theme.typography.h7,
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  passwordTextFieldRoot: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  passwordTextFieldInput: {
    padding: theme.spacing(1),
    border: '2px solid #ebeced',
    borderRadius: theme.spacing(0.5),
    // @ts-ignore
    backgroundColor: theme.palette.common.input.background,
  },
  section: {
    clear: 'both',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px 0 0`,
  },
  topMenu: {
    width: '100%',
    marginTop: `${theme.spacing(1.5)}px`,
    flexGrow: 1,
  },
  userMenuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    // @ts-ignore
    width: theme.mixins.sidebar.expandedWidth + theme.spacing(15),
  },
  passwordSettingsContainer: {
    width: '85%',
    margin: '0 auto',
  },
  specifications: {
    fontSize: '10px',
  },
  passwordValidation: {
    paddingTop: theme.spacing(0.5),
    // @ts-ignore
    color: theme.palette.common.error.main,
    whiteSpace: 'pre',
  },
}));
