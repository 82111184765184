import React from 'react';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { DateTime } from 'luxon';

import { Gesture, Update, InsertDriveFile } from '@material-ui/icons';
import { Grid, Paper, Typography } from '@material-ui/core';

import { Breadcrumbs, Loading, ItemTitle, Spacer, WorkflowIcon, UserAvatar } from 'components';
import { formatDate } from 'components/TableView/Fields/Date';
import { useSupplier } from './gql';
import { useStyles } from './styles';
import QualityAgreements from '../PayloadFields/QualityAgreements';

const NA = 'Not available. Supplier not yet approved.';

function MonitoringView({ itemId }) {
  const { data, loading, error } = useSupplier(itemId);
  const classes = useStyles() as any;

  if (loading) return <Loading />;
  if (error) throw error;

  const { supplier } = data;

  const categorySlug = supplier.itemType.displaySlug;
  const titlePrefix = supplier.customIdentifier;
  const itemTitle = supplier.currentVersion.title;
  const riskLevel = supplier.currentVersion.riskLevel;
  const requalification = formatDate(supplier.currentVersion.approvalExpiresAt);
  const status = supplier.currentVersion.currentStatus;
  const reasonForSignature = get(
    supplier,
    'currentVersion.currentStatus.previousTransition.esignature.reasonForSignature',
  );
  const reasonForRevocation = get(
    supplier,
    'currentVersion.currentStatus.previousTransition.esignature.reasonForRevocation',
  );
  const signer = get(supplier, 'currentVersion.currentStatus.previousTransition.esignature.user', null);

  const requalificationNeededOnMessage = () => {
    if (supplier.currentVersion.currentStatus.name !== 'supplier_approved') return NA;
    if (requalification) return requalification;
    return supplier.currentVersion.riskLevel === 'Low' ? 'Not necessarily for low risk Supplier.' : '';
  };

  return (
    <Grid container data-testid="item" spacing={3}>
      <Grid item xs={12} className={classes.grid}>
        <Breadcrumbs categorySlug={categorySlug} itemTitle={`${titlePrefix}: ${itemTitle}`} />
        <Grid container item xs={12} justify="space-between">
          <ItemTitle
            itemTitle="Performance Monitoring"
            editable={false}
            titlePrefix=""
            versionId={0}
            refetchQueries={[]}
          />
        </Grid>
        <Grid container item xs={12} justify="space-between">
          <Paper className={classes.paper}>
            <Typography component="div" variant="button">
              <Gesture className={classes.icon} />
              ITEM STATUS
            </Typography>

            <Spacer factor={3} />
            <div className={classes.statusRow}>
              <WorkflowIcon type={camelCase(status.name)} size="small" completed />
              <Typography variant="subtitle1">{startCase(status.name)}</Typography>

              {signer && (
                <span className={classes.avatarPart}>
                  <UserAvatar diameter={30} userId={signer.id} />

                  <span className={classes.namePart}>
                    <Typography variant="subtitle1" className={classes.userName}>
                      {signer.fullName}
                    </Typography>

                    <Typography variant="body2" className={classes.roles}>
                      {signer && signer.jobRoles.map((job) => job.formattedRoleName).join(', ')}
                    </Typography>
                  </span>
                </span>
              )}

              <Typography variant="body2">{DateTime.fromISO(status.createdAt).toFormat('MMM. dd, yyyy')}</Typography>

              <Typography variant="body2">
                {DateTime.fromISO(status.createdAt).toFormat('hh:mm:ss a (ZZZZ)')}
              </Typography>
            </div>
            <Spacer factor={2} />

            <Typography variant="subtitle1">Reason For {reasonForRevocation ? 'Revocation' : 'Signature'}</Typography>
            <Typography variant="body2">{reasonForSignature || reasonForRevocation || NA}</Typography>

            <hr className={classes.splitter} />

            <Typography component="div" variant="button">
              <Update className={classes.icon} />
              EVALUATION FREQUENCY
            </Typography>

            <Spacer factor={1} />
            <div className={classes.textRow}>
              <Typography variant="subtitle1">Current Risk Level: </Typography>
              <Typography variant="body2">{riskLevel || NA}</Typography>
            </div>
            <Spacer factor={1} />
            <div className={classes.textRow}>
              <Typography variant="subtitle1">Requalification needed on: </Typography>
              <Typography variant="body2">{requalificationNeededOnMessage()}</Typography>
            </div>
          </Paper>

          <hr />
        </Grid>

        <Grid container item xs={12} justify="space-between">
          <ItemTitle itemTitle="Quality Agreements" editable={false} titlePrefix="" versionId={0} refetchQueries={[]} />
        </Grid>

        <Grid container item xs={12} justify="space-between">
          <Paper className={classes.paper}>
            <Typography component="div" variant="button">
              <InsertDriveFile className={classes.icon} />
              QUALITY AGREEMENTS
            </Typography>

            <Spacer factor={1} />
            <QualityAgreements associatedVersions={supplier.currentVersion.qualityAgreementVersions} />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MonitoringView;
