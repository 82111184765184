import React, { FC, useState } from 'react';

import LinearScale from '@material-ui/icons/LinearScale';

import { useMutation } from 'react-apollo';
import flowRight from 'lodash/flowRight';

import { Autorenew, DoneAll, PlaylistAddCheck } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

import { ContentSection, WorkflowIcon } from 'components';
import WorkflowMenu, { WontDoMenuItem, BlockMenuItem } from 'components/WorkflowMenu';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import TaskStatusMenuItem from 'components/WorkflowMenu/TaskStatusMenuItem';
import { TaskWorkflowStatuses, indexById } from 'workflows/statuses';
import { transitionMutation as transMut } from 'utils/gql/timelineQueries';

import ConnectBar from './ConnectBar';
import { AlertDialog } from 'components';

import WithCurrentUser from '../../WithCurrentUser';
import withMetadataFromServer from '../../WithMetadataFromServer';

const {
  Backlog,
  BlockedAfterBacklog,
  WontDoAfterBacklog,
  ToDo,
  BlockedAfterToDo,
  WontDoAfterToDo,
  InProgress,
  BlockedAfterInProgress,
  WontDoAfterInProgress,
  Done,
  BlockedAfterDone,
  WontDoAfterDone,
} = TaskWorkflowStatuses;

const TaskWorkflowStatusesById = indexById(TaskWorkflowStatuses);

interface Props {
  currentUser: any;
  currentCompany: any;
  currentCompanyValidatedSystem: any;
  currentCompanyProfessionalLicense: any;
  currentVersion: any;
  handleInitTransition: any;
  permissions: any;
}

const TaskWorkflow: FC<Props> = (props) => {
  const { currentVersion } = props;
  const { currentStatusName, unresolvedSuggestions } = currentVersion;
  const currentWorkflowStatus = TaskWorkflowStatusesById[currentStatusName];
  const [createStatusTransition] = useMutation(transMut, {
    refetchQueries: ['timelineQuery'],
  });
  const variables = {
    input: {
      versionId: currentVersion.id,
      toStatus: '',
    },
  };
  const refetchQueries = ['timelineQuery', 'BuilderViewQuery'];

  const [deniedModal, setDeniedModal] = useState({
    open: false,
    heading: '',
    body: '',
  });

  const handleUnresolvedSuggestionsDeniedModal = () => {
    setDeniedModal({
      open: true,
      heading: 'Pending Suggestions in your document',
      body: `There are tracked changes that need to be resolved before this status transition can take place.`,
    });
  };

  const closeModal = () => {
    setDeniedModal(prevState => ({ ...prevState, open: false }));
  };

  const isBeforeWontDo = (status): boolean => {
    const ids = [WontDoAfterBacklog, WontDoAfterToDo, WontDoAfterInProgress, WontDoAfterDone].map((s) => s.id);
    if (!ids.includes(currentWorkflowStatus.id)) return true;
    return status.isBefore(currentWorkflowStatus);
  };

  const goToStatus = (status) => {
    variables.input.toStatus = status.id;
    if (!currentWorkflowStatus.isEqualTo(status)) return createStatusTransition({ variables, refetchQueries });
  };

  const workflowControls = () => {
    return (
      <>
        <WorkflowMenu>
          {!isBeforeWontDo(ToDo) && (
            <TaskStatusMenuItem
              title={'To Do'}
              disabled={false}
              onClick={async () => {
                try {
                  await goToStatus(ToDo);
                } catch (error) {
                  throw new Error(`Failed to transition to To Do status: ${error.message}`);
                }
              }}
            >
              <PlaylistAddCheck />
            </TaskStatusMenuItem>
          )}
          {!isBeforeWontDo(InProgress) && (
            <TaskStatusMenuItem
              title={'In Progress'}
              disabled={false}
              onClick={async () => {
                try {
                  await goToStatus(InProgress);
                } catch (error) {
                  throw new Error(`Failed to transition to In Progress status: ${error.message}`);
                }
              }}
            >
              <Autorenew />
            </TaskStatusMenuItem>
          )}
          {!isBeforeWontDo(Done) && (
            <TaskStatusMenuItem
              title={'Done'}
              disabled={false}
              onClick={async () => {
                try {
                  await goToStatus(Done);
                } catch (error) {
                  throw new Error(`Failed to transition to Done status: ${error.message}`);
                }
              }}
            >
              <DoneAll />
            </TaskStatusMenuItem>
          )}
          <WontDoMenuItem
            disabled={false}
            onClick={() => {
              switch (currentWorkflowStatus.id) {
                case 'backlog':
                  variables.input.toStatus = WontDoAfterBacklog.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'blocked_after_backlog':
                  variables.input.toStatus = WontDoAfterBacklog.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'to_do':
                  variables.input.toStatus = WontDoAfterToDo.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'blocked_after_to_do':
                  variables.input.toStatus = WontDoAfterToDo.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'in_progress':
                  variables.input.toStatus = WontDoAfterInProgress.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'blocked_after_in_progress':
                  variables.input.toStatus = WontDoAfterInProgress.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'done':
                  variables.input.toStatus = WontDoAfterDone.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'blocked_after_done':
                  variables.input.toStatus = WontDoAfterDone.id;
                  return createStatusTransition({ variables, refetchQueries });
                default:
                  return null;
              }
            }}
          />

          <BlockMenuItem
            disabled={false}
            onClick={() => {
              switch (currentWorkflowStatus.id) {
                case 'backlog':
                  variables.input.toStatus = BlockedAfterBacklog.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'wont_do_after_backlog':
                  variables.input.toStatus = BlockedAfterBacklog.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'to_do':
                  variables.input.toStatus = BlockedAfterToDo.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'wont_do_after_to_do':
                  variables.input.toStatus = BlockedAfterToDo.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'in_progress':
                  variables.input.toStatus = BlockedAfterInProgress.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'wont_do_after_in_progress':
                  variables.input.toStatus = BlockedAfterInProgress.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'done':
                  variables.input.toStatus = BlockedAfterDone.id;
                  return createStatusTransition({ variables, refetchQueries });
                case 'wont_do_after_done':
                  variables.input.toStatus = BlockedAfterDone.id;
                  return createStatusTransition({ variables, refetchQueries });
                default:
                  return null;
              }
            }}
          />
        </WorkflowMenu>
      </>
    );
  };

  return (
    <ContentSection LabelText={'Workflow'} Icon={<LinearScale />} Controls={workflowControls()}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <WorkflowIcon
          size="large"
          type="taskBacklog"
          active={currentWorkflowStatus.isEqualTo(Backlog)}
          completed={currentWorkflowStatus.isAfter(Backlog)}
          label="Backlog"
          visible
          handleClick={async () => {
            try {
              await goToStatus(Backlog);
            } catch (error) {
              throw new Error(`Failed to transition to Backlog status: ${error.message}`);
            }
          }}
        />

        <ConnectBar
          color="rejected"
          visible={currentWorkflowStatus.isEqualTo(BlockedAfterBacklog) && isBeforeWontDo(Backlog)}
        />
        <WorkflowIcon
          type="TaskBlocked"
          active={currentWorkflowStatus.isEqualTo(BlockedAfterBacklog)}
          label="Blocked"
          size="large"
          visible={currentWorkflowStatus.isEqualTo(BlockedAfterBacklog)}
        />

        <ConnectBar color="taskWontDo" visible={currentWorkflowStatus.isEqualTo(WontDoAfterBacklog)} />
        <WorkflowIcon
          type="TaskWontDo"
          active={currentWorkflowStatus.isEqualTo(WontDoAfterBacklog)}
          label="Wont Do"
          size="large"
          visible={currentWorkflowStatus.isEqualTo(WontDoAfterBacklog)}
        />

        <ConnectBar color="underReview" visible={isBeforeWontDo(ToDo)} />
        <WorkflowIcon
          type="TaskToDo"
          active={currentWorkflowStatus.isEqualTo(ToDo)}
          completed={currentWorkflowStatus.isAfter(ToDo)}
          label="To Do"
          size="large"
          visible={isBeforeWontDo(ToDo)}
          handleClick={async () => {
            try {
              await goToStatus(ToDo);
            } catch (error) {
              throw new Error(`Failed to transition to To Do status: ${error.message}`);
            }
          }}
        />

        <ConnectBar
          color="rejected"
          visible={currentWorkflowStatus.isEqualTo(BlockedAfterToDo) && isBeforeWontDo(ToDo)}
        />
        <WorkflowIcon
          type="TaskBlocked"
          active={currentWorkflowStatus.isEqualTo(BlockedAfterToDo)}
          label="Blocked"
          size="large"
          visible={currentWorkflowStatus.isEqualTo(BlockedAfterToDo)}
        />

        <ConnectBar color="taskWontDo" visible={currentWorkflowStatus.isEqualTo(WontDoAfterToDo)} />
        <WorkflowIcon
          type="TaskWontDo"
          active={currentWorkflowStatus.isEqualTo(WontDoAfterToDo)}
          label="Wont Do"
          size="large"
          visible={currentWorkflowStatus.isEqualTo(WontDoAfterToDo)}
        />

        <ConnectBar color="ownerApproval" visible={isBeforeWontDo(InProgress)} />

        <WorkflowIcon
          size="large"
          type="TaskInProgress"
          active={currentWorkflowStatus.isEqualTo(InProgress)}
          completed={currentWorkflowStatus.isAfter(InProgress)}
          label="In Progress"
          handleClick={async () => {
            try {
              await goToStatus(InProgress);
            } catch (error) {
              throw new Error(`Failed to transition to In Progress status: ${error.message}`);
            }
          }}
          visible={isBeforeWontDo(InProgress)}
        />

        <ConnectBar
          color="rejected"
          visible={currentWorkflowStatus.isEqualTo(BlockedAfterInProgress) && isBeforeWontDo(InProgress)}
        />
        <WorkflowIcon
          type="TaskBlocked"
          active={currentWorkflowStatus.isEqualTo(BlockedAfterInProgress)}
          label="Blocked"
          size="large"
          visible={currentWorkflowStatus.isEqualTo(BlockedAfterInProgress)}
        />

        <ConnectBar color="taskWontDo" visible={currentWorkflowStatus.isEqualTo(WontDoAfterInProgress)} />
        <WorkflowIcon
          type="TaskWontDo"
          active={currentWorkflowStatus.isEqualTo(WontDoAfterInProgress)}
          label="Wont Do"
          size="large"
          visible={currentWorkflowStatus.isEqualTo(WontDoAfterInProgress)}
        />

        <ConnectBar color="released" visible={isBeforeWontDo(Done)} />

        <WorkflowIcon
          size="large"
          type="TaskDone"
          completed={currentWorkflowStatus.isAfter(Done) || currentWorkflowStatus.isEqualTo(Done)}
          label="Done"
          handleClick={async () => {
            try {
              if (unresolvedSuggestions) {
                return handleUnresolvedSuggestionsDeniedModal();
              };
              await goToStatus(Done);
            } catch (error) {
              throw new Error(`Failed to transition to Done: ${error.message}`);
            }
          }}
          visible={isBeforeWontDo(Done)}
        />
        <ConnectBar
          color="rejected"
          visible={currentWorkflowStatus.isEqualTo(BlockedAfterDone) && isBeforeWontDo(Done)}
        />
        <WorkflowIcon
          type="TaskBlocked"
          active={currentWorkflowStatus.isEqualTo(BlockedAfterDone)}
          label="Blocked"
          size="large"
          visible={currentWorkflowStatus.isEqualTo(BlockedAfterDone)}
        />

        <ConnectBar color="taskWontDo" visible={currentWorkflowStatus.isEqualTo(WontDoAfterDone)} />
        <WorkflowIcon
          type="TaskWontDo"
          active={currentWorkflowStatus.isEqualTo(WontDoAfterDone)}
          label="Wont Do"
          size="large"
          visible={currentWorkflowStatus.isEqualTo(WontDoAfterDone)}
        />
      </div>
      <div>
      <AlertDialog
        open={deniedModal.open}
        onClose={closeModal}
        titleText={deniedModal.heading}
      >
        <Typography variant="body2">{deniedModal.body}</Typography>
      </AlertDialog>
      </div>
    </ContentSection>
  );
};

export default flowRight(
  WithCurrentUser,
  withCurrentCompany,
  withMetadataFromServer(),
)(TaskWorkflow);
