import React from 'react';

import { GenericRecordWorkflowStatuses } from 'workflows/statuses';
import { CertifiedSignature, YesOrNo } from 'compositions/TimelineGroup/steps/views';

const TO_STATUS = GenericRecordWorkflowStatuses.Archived.id;

export default {
  initialStep: 'takeOrNot',
  internalSteps: {
    takeOrNot: {
      component: (props) => (
        <YesOrNo
          disableOnSubmit
          prompt={`Do you want to archive version ${props.currentVersion.versionIdentifier}
          of ${props.itemCustomIdentifier}`}
          yesLabel="Archive"
          noLabel="Cancel"
          {...props}
        />
      ),
      getNextStep: (takeOwnership) => (takeOwnership ? 'signature' : 'complete'),
    },
    signature: {
      component: (props) => {
        const heading = 'Archival';
        const dialog = `As the ${props.currentVersion.assignedOwnerJobRole.formattedRoleName}, I archive ${
          props.itemCustomIdentifier
        }.`;

        return <CertifiedSignature heading={heading} dialog={dialog} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
  },
  getFinalResult: ({ takeOrNot, signature }) => {
    if (takeOrNot) {
      return {
        toStatus: TO_STATUS,
        ...signature,
      };
    }
    return {};
  },
};
