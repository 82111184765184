import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tableBody: {
    borderRadius: 4,
  },
  tableCell: {
    ...theme.typography.body2,
    padding: theme.spacing(1),
  },
  tableHeader: {
    ...theme.typography.body2,
    whiteSpace: 'nowrap',
    padding: theme.spacing(1),
    fontWeight: 600,
  },
  tableRow: {
    border: `2px solid ${theme.palette.common.input.outline}`,
    '&:hover': {
      border: `2px double ${theme.palette.primary.main} !important`,
      borderStyle: 'double',
    },
    '&:hover span': {
      visibility: 'visible',
    },
  },
  tableRowCheckbox: {
    padding: '12px 12px 12px 0',
    '& svg': {
      width: 15,
      height: 15,
    },
  },
  listButtonIcon: {
    float: 'right',
    marginRight: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modalContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 322,
    padding: theme.spacing(2),
  },
  ...theme.mixins.gridLayout,
  icon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(0.5),
    color: theme.palette.blueDark,
  },
  textField: {
    border: '5px solid black',
  },
}));
