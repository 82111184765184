import React from 'react';
import { compose } from 'recompose';

import { withCurrentCompany } from 'compositions/WithCurrentCompany';

const withDisabledCategories = (WrappedComponent) => (componentProps) => {
  const { currentCompany } = componentProps;
  return (
    <WrappedComponent
      disabledCategories={
        currentCompany ? currentCompany.disabledCategories : []
      }
      {...componentProps}
    />
  );
};

export default compose(
  withCurrentCompany,
  withDisabledCategories,
);
