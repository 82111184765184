import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './menu_book.svg';

function MenuBook(props) {
  return (
    <SvgIcon
      {...props}
      children={<path />}
      component={(svgProps) => <Icon {...svgProps} />}
    />
  );
}

export default MenuBook;
