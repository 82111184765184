import React from 'react';
import { Modal } from '@material-ui/core';

function WorkflowModal(props: {
  open: boolean;
  onClose: () => void;
  children;
  style?: any;
}) {
  return (
    <Modal open={props.open} onClose={props.onClose} id="transition-modal">
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 0,
          left: 0,
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          outline: 'none',
          ...props.style,
        }}
      >
        {props.children}
      </div>
    </Modal>
  );
}

export default WorkflowModal;
