import React, { FC } from 'react';
import OptionsDropdown from 'components/OptionsDropdown';
import { useGenericOptionsDropdown } from './useGenericOptionsDropdown';

interface Props {
  itemType: string;
  viewOption: string;
  onChange: (event: any) => void;
}

const GenericOptionsDropdown: FC<Props> = ({ itemType, viewOption, onChange }) => {
  const { title, options } = useGenericOptionsDropdown(itemType);

  if (!itemType || !options || !options.length) return null;

  return <OptionsDropdown value={viewOption} onChange={onChange} optionsTitle={title} options={options} />;
};

export default GenericOptionsDropdown;
