function getSentryDsn() {
  if (process.env.REACT_APP_RELEASE_STAGE === 'prod') {
    return 'https://cb93a51e78ac440dad2d3897bc6a4ead@sentry.io/1484085';
  } else if (process.env.REACT_APP_RELEASE_STAGE === 'staging') {
    return 'https://d9d47437c63d45bb8e02823334836e7b@sentry.io/1484083';
  } else if (process.env.REACT_APP_RELEASE_STAGE === 'ndf') {
    return 'https://452037576c06419b9ad56da2b61d3dc0@sentry.io/1484084';
  } else if (process.env.REACT_APP_RELEASE_STAGE === 'internal-validation') {
    return 'https://67b949757a4446f8a73cdaf1f841da7e@sentry.io/2633289';
  }
  return '';
}

export default getSentryDsn;
