import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  headerContainer: {
    // @ts-ignore
    backgroundColor: theme.palette.background.contextDrawerHeader,
    boxShadow: `2px 2px 2px 0 ${theme.palette.secondary.main} !important`,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  headerText: {
    fontWeight: 600,
  },
  container: {
    // @ts-ignore
    backgroundColor: theme.palette.background.contextDrawerGrey,
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2.5),
  },
  messageItem: {
    maxWidth: 200,
    textAlign: 'center',
  },
  infoIcon: { fontSize: 64 },
  messageContainer: { height: '100%' },
  messageMain: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  reasonForSignature: {
    marginTop: theme.spacing(1),
  },
  timestamp: {
    textAlign: 'right',
    paddingBottom: theme.spacing(0.5),
  },
  userText: { fontWeight: 600 },
}));
