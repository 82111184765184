import produce from 'immer';

function handleActions(actionHandlerMap, defaultState) {
  return (state = defaultState, action) =>
    produce(state, (draft) => {
      const actionHandler = actionHandlerMap[action.type];
      actionHandler && actionHandler(draft, action);
    });
}

export default handleActions;
