import gql from 'graphql-tag';

export const CREATE_JOB_ROLE = gql`
  mutation CreateJobRole($roleName: String!) {
    createJobRole(roleName: $roleName) {
      jobRole {
        id
        roleName
      }
    }
  }
`;
