import { WorkflowDefinition, WorkflowTransition } from '../types';
import ChangeOrderUnderReview from './ChangeOrderUnderReview';
import ChangeOrderCancel from './ChangeOrderCancel';
import ChangeOrderClosure from './ChangeOrderClosure';
import ChangeOrderTraining from './ChangeOrderTraining';
import ChangeOrderClosureApproveOrReject from './ChangeOrderClosureApproveOrReject';
import ChangeOrderVoid from './ChangeOrderVoid';
import ResolveRejection from './ResolveRejection';

const ChangeOrderWorkflowSteps: WorkflowDefinition = {
  underReview: ChangeOrderUnderReview as WorkflowTransition,
  changeOrderCanceled: ChangeOrderCancel as WorkflowTransition,
  changeOrderClosure: ChangeOrderClosure as WorkflowTransition,
  changeOrderTraining: ChangeOrderTraining as WorkflowTransition,
  changeOrderClosureApproveOrReject: ChangeOrderClosureApproveOrReject as WorkflowTransition,
  changeOrderVoided: ChangeOrderVoid as WorkflowTransition,
  resolveRejection: ResolveRejection as WorkflowTransition,
};

export default ChangeOrderWorkflowSteps;
