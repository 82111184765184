import React from 'react';

function ColorBox(props) {
  return (
    <div
      style={{
        backgroundColor: props.color,
        borderRadius: 2,
        height: 18,
        marginRight: 6,
        width: 18,
      }}
    />
  );
}

export default ColorBox;
