import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme: any) => ({
  noItemsPanel: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  tableContainer: {
    marginBottom: theme.spacing(1),
  },
  versionCell: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    justifyContent: 'center'
  }
}));
