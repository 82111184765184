import React from 'react';
import { RecordOfOrigin } from '../views';

export default {
  initialStep: 'recordOfOrigin',
  internalSteps: {
    recordOfOrigin: {
      component: (props) => {
        return <RecordOfOrigin {...props} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: ({ recordOfOrigin }) => ({ ...recordOfOrigin }),
};
