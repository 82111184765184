import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    closeIcon: {
      float: 'right',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    modalContainer: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 322,
      padding: theme.spacing(2),
    },
  }),
);
