import React from 'react';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { Views } from 'workspaces';
import EffectiveView from 'components/EffectiveView';
import VersionsView from 'components/VersionsView';
import { ComingSoon } from 'pages';
import { LinksGraph } from 'compositions';

import PayloadFields from './PayloadFields';
import BuilderView from './BuilderView';

function RecordPage(props: {
  itemId: string;
  itemType: string;
  categorySlug: string;
  activeWorkspaceView: { id: string };
}) {
  if (props.itemType !== 'trainingPlan')
    throw new Error(
      `Attempted to render training_plan RecordPage for item type: ${props.itemType}. Something may be misconfigured.`,
    );

  switch (props.activeWorkspaceView.id) {
    case Views.Builder.id:
      return <BuilderView itemId={props.itemId} categorySlug={props.categorySlug} />;
    case Views.Effective.id:
      return (
        <EffectiveView
          itemId={props.itemId}
          itemType={'trainingPlan'}
          PayloadFields={PayloadFields}
          categorySlug={props.categorySlug}
        />
      );
    case Views.Versions.id:
      return (
        <VersionsView
          categorySlug={props.categorySlug}
          itemType={'trainingPlan'}
          itemId={props.itemId}
          PayloadFields={PayloadFields}
        />
      );
    case Views.LinksGraph.id || Views.StepTrace.id:
      return <LinksGraph categorySlug={props.categorySlug} itemType={'trainingPlan'} />;
    default:
      return <ComingSoon />;
  }
}

export default withWorkspaceViews()(RecordPage);
