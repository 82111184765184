import React from 'react';

import { Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { Link } from '@reach/router';

import { Popover } from 'components';

import { useStyles } from './styles';

export interface TraceListItem {
  customIdentifier: string;
  title: string;
}

interface Props {
  anchorEl: Element;
  onClose: () => void;
  open: boolean;
  parentSetItemSlug: string;
  traces?: TraceListItem[];
}

function TracesListPopover(props: Props) {
  const classes = useStyles();
  return (
    <Popover
      actionText={<Link to={`/category/${props.parentSetItemSlug}?view=LinksGraph`}>View Links</Link>}
      open={props.open}
      title="Links"
      anchorEl={props.anchorEl}
      onClose={props.onClose}
    >
      <div>
        {props.traces && props.traces.length > 0 ? (
          props.traces.map((item, idx) => (
            <li key={idx} className={classes.tracedItemsList}>
              <Typography variant="body2" className={classes.traceItemIdentifier}>
                {item.customIdentifier}
              </Typography>
              <Typography variant="body2">{item.title}</Typography>
            </li>
          ))
        ) : (
          <Typography component="div" className={classes.noTraceMsgContainer}>
            <LinkIcon />
            <p className={classes.noTraceMsg}>No links in for this item yet!</p>
          </Typography>
        )}
      </div>
    </Popover>
  );
}

export default TracesListPopover;
