import React, { Component } from 'react';
import { Button, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Trans } from '@lingui/macro';

import styles from './styles';
import { Spacer } from 'components';

class ReasonForChange extends Component {
  constructor(props) {
    super(props);
    this.state = { reasonForChange: null, descriptionOfChange: null };
  }

  getCurrentValue = (key) =>
    this.state[key] || (this.props.unsavedValue && this.props.unsavedValue[key]) || this.props.currentVersion[key];
  getNullableValue = (key) => this.state[key];
  setCurrentValue = (key) => this.props.currentVersion[key];

  render() {
    const { classes, handleComplete, buttonText, smallCircle } = this.props;
    const { reasonForChange } = this.state;

    return (
      <div>
        <div className={classes.heading}>
          <Typography variant="h3">
            <Trans>Summary of Change</Trans>
          </Typography>
        </div>

        <Spacer factor={3} />

        <Typography
          variant="body2"
          component="div" // div cannot be descendant of p
        >
          <TextField
            id="reason-for-change"
            multiline
            fullWidth
            rows={4}
            onChange={(evt) => this.setState({ reasonForChange: evt.target.value })}
            margin="normal"
            variant="outlined"
            className={classes.textReason}
            InputProps={{
              classes: { root: classes.input },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={this.setCurrentValue('reasonForChange')}
            label={'Reason for Change'}
          />

          <Spacer factor={2} />

          <Button
            disabled={smallCircle ? false : reasonForChange === '' || !this.getCurrentValue('reasonForChange')}
            id="change-summary-submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              handleComplete({
                reasonForChange: smallCircle
                  ? this.getNullableValue('reasonForChange')
                  : this.getCurrentValue('reasonForChange'),
              });
            }}
          >
            {buttonText}
          </Button>
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(ReasonForChange);
