import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import { useMutation, useQuery, Mutation } from 'react-apollo';
import { LockedContext } from 'contexts/lockedContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ContentSection, LabelText, NoContentDisclaimer } from 'components';
import { ControlBar } from 'components/LongCardControls';
import { metadataQuery, updateMember, addVerts } from './gql';
import BulkActions from './BulkActions';
import { useToggledSteps } from './hooks';
import { Step } from './util';

import StepListItem from './StepListItem';
import { useStyles } from './styles';

function hasEditableTitleField(itemType) {
  if (!itemType) return false;
  let titleIsUserEditable = false;
  get(itemType, 'fields', []).forEach((field) => {
    if (field.fieldName === 'title' && field.userEditable === true) titleIsUserEditable = true;
  });
  return titleIsUserEditable;
}

interface Props {
  itemNumber: number;
  versionData: any;
}

function ProtocolStepMethods(props: Props) {
  const { itemNumber, versionData } = props;
  const { locked } = useContext(LockedContext);
  const { data, loading } = useQuery(metadataQuery);
  const [memberOrder, setMemberOrder] = useState(versionData.deviceVerificationTestStepVersions);
  const [mutateMember, { loading: memberLoading }] = useMutation(updateMember, {
    refetchQueries: ['DeviceVerificationVersionQuery'],
  });

  const classes = useStyles();

  const [toggledSteps, toggle] = useToggledSteps();

  useEffect(() => {
    setMemberOrder(versionData.deviceVerificationTestStepVersions);
  }, [versionData]);

  if (loading) return <div />;

  const itemTypeMetadata = get(data, 'itemTypes', []).reduce((acc, itemType) => {
    if (itemType.name) acc[itemType.name] = itemType;
    return acc;
  }, {});

  const handleOnDragEnd = (result) => {
    const { destination, draggableId, source } = result;
    const elementNotMoved = destination.droppableId === source.droppableId && destination.index === source.index;
    if (!destination || elementNotMoved) return;

    const testSteps = Array.from(memberOrder);
    const [reorderedTs] = testSteps.splice(source.index, 1);
    testSteps.splice(destination.index, 0, reorderedTs);

    setMemberOrder(testSteps);

    mutateMember({
      variables: {
        memberId: draggableId,
        parentVersionId: versionData.id,
        position: destination.index,
      },
    }).catch((e) => {
      throw new Error(`Error occurred while updating Test Step: ${e}`);
    });
  };

  return (
    <Mutation mutation={addVerts}>
      {(addVertsMutation, { loading: createItemLoading }) => {
        return (
          <>
            <ContentSection
              LabelText={
                <LabelText
                  labelText="Protocol Test Steps"
                  Adornment={
                    !versionData.locked && (
                      <BulkActions
                        selectedSteps={memberOrder
                          .filter((member) => toggledSteps.includes(member.id))
                          .map((member) => new Step(member, itemNumber))}
                        onRemove={toggle}
                      />
                    )
                  }
                />
              }
            >
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="members">
                  {(provided) => (
                    <ul className={classes.testStepsContainer} {...provided.droppableProps} ref={provided.innerRef}>
                      {memberOrder
                        .map((rawStep) => new Step(rawStep, itemNumber))
                        .map((step, idx) => {
                          return (
                            <Draggable
                              key={step.memberId}
                              draggableId={step.memberId}
                              index={idx}
                              isDragDisabled={versionData.locked || memberLoading}
                            >
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <StepListItem
                                    step={step}
                                    customIdentifier={step.stepId}
                                    onCheckboxClick={() => toggle([step.versionId])}
                                    locked={versionData.locked}
                                    onRemove={toggle}
                                    canEditTitle={hasEditableTitleField(itemTypeMetadata.device_verification_test_step)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>

              {!locked && (
                <ControlBar
                  label="Add Member"
                  currentWorkflowStatus={versionData.currentWorkflowStatus}
                  disabled={createItemLoading}
                  onClickPlus={() =>
                    addVertsMutation({
                      variables: {
                        deviceVerificationVersionId: versionData.id,
                        title: 'Default Title',
                      },
                      refetchQueries: ['DeviceVerificationVersionQuery'],
                    })
                  }
                />
              )}
              {memberOrder.length === 0 && <NoContentDisclaimer variant="add" />}
            </ContentSection>
          </>
        );
      }}
    </Mutation>
  );
}

export default ProtocolStepMethods;
