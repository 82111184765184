import React from 'react';
import { Paper, MenuList, MenuItem as MUIMenuItem, Chip } from '@material-ui/core';

import { Status } from './statuses';
import { TransitionWizard } from './Wizard';
import useStyles from './styles';

export const ellipsesClickable = (status: string) => status === Status.Active || status === Status.Inactive;

function Ellipses(props: { status: string; startWizard: (t: TransitionWizard) => void }) {
  const classes = useStyles();
  return (
    <Paper>
      {(() => {
        switch (props.status) {
          case Status.Active:
            return (
              <MenuList className={classes.menuContainer}>
                <>
                  <MenuItem onClick={() => props.startWizard(TransitionWizard.ActiveToPlanning)}>
                    <Chip
                      size="small"
                      label="Planning"
                      classes={{ root: `${classes.planningChip} ${classes.hoverPointer}` }}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => props.startWizard(TransitionWizard.Retire)}>
                    <Chip
                      size="small"
                      label="Retire"
                      classes={{ root: `${classes.retireChip} ${classes.hoverPointer}` }}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => props.startWizard(TransitionWizard.ActiveToInactive)}>
                    <Chip
                      size="small"
                      label="Inactivate"
                      classes={{ root: `${classes.inactivateChip} ${classes.hoverPointer}` }}
                    />
                  </MenuItem>
                </>
              </MenuList>
            );
          case Status.Inactive:
            return (
              <MenuList className={classes.menuContainer}>
                <>
                  <MenuItem onClick={() => props.startWizard(TransitionWizard.InactiveToActive)}>
                    <Chip
                      size="small"
                      label="Reactivate"
                      classes={{ root: `${classes.activateChip} ${classes.hoverPointer}` }}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => props.startWizard(TransitionWizard.Retire)}>
                    <Chip
                      size="small"
                      label="Retire"
                      classes={{ root: `${classes.retireChip} ${classes.hoverPointer}` }}
                    />
                  </MenuItem>
                </>
              </MenuList>
            );
          default:
            return <MenuList className={classes.menuContainer} />;
        }
      })()}
    </Paper>
  );
}

function MenuItem(props) {
  const classes = useStyles();
  return (
    <MUIMenuItem onClick={props.onClick} className={classes.hoverPointer}>
      {props.children}
    </MUIMenuItem>
  );
}

export default Ellipses;
