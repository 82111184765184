import React from 'react';
import { useCurrentUser } from './WithCurrentUser';
import EffectiveView from 'components/EffectiveView';
import TrainingPlanPayloadFields from 'itemTypes/TrainingPlan/PayloadFields';

// reach router requires a prop called path but we do not use it here
function MyTrainingPlan(_props: { path: string }) {
  const { data, loading, error } = useCurrentUser();
  if (error) throw error;
  if (loading || !data || !data.currentUser) return null;

  const tpversion = data.currentUser.employee.effectiveTrainingPlanVersion;
  if (!tpversion) return <>No Training Plan found</>;

  return (
    <EffectiveView
      categorySlug="training-plans"
      itemId={tpversion.item.id}
      itemType="training_plan"
      PayloadFields={TrainingPlanPayloadFields}
    />
  );
}

export default MyTrainingPlan;
