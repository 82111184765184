import React from 'react';
import { Typography } from '@material-ui/core';

function Column({ values, heading }: { values: any[]; heading?: string }) {
  return (
    <div
      style={{
        flex: 1,
        flexBasis: 'auto',
        maxWidth: 130,
      }}
    >
      <Typography
        variant="body2"
        component="div"
        style={{
          height: 18,
          display: 'flex',
          justifyContent: 'center',
          fontWeight: 600,
          marginTop: 4,
        }}
      >
        {heading}
      </Typography>

      {values.map((val) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>{val}</div>
      ))}
    </div>
  );
}

export default Column;
