import React, { FC } from 'react';
import { ClickAwayListener, FormControlLabel, Paper, Popper, Typography } from '@material-ui/core';
import { useStyles } from './style';
import Toggle from 'components/Toggle';

interface State {
  numberOfItemsInGroup: boolean;
  combinedItemsGroups: boolean;
  groupRemoveSingleChildren: boolean;
  showOpenedGroup: boolean;
}

interface Props {
  open: boolean;
  anchorEl: null | HTMLElement;
  state: State;
  handleChange: (event) => void;
  handleClose: () => void;
}

const ViewOptionsMenu: FC<Props> = ({ anchorEl, open, state, handleChange, handleClose }) => {
  const classes = useStyles();

  const viewOptions = [
    {
      key: 'numberOfItemsInGroup',
      title: 'Number of Items in Group',
      toggleLabel: 'Show',
    },
    {
      key: 'combinedItemsGroups',
      title: 'Combined Item Groups',
      toggleLabel: 'On',
    },
    {
      key: 'groupRemoveSingleChildren',
      title: 'Remove Single Children',
      toggleLabel: 'Remove',
    },
    {
      key: 'showOpenedGroup',
      title: 'Show Open Groups',
      toggleLabel: 'Show',
    },
  ];

  return (
    <Popper anchorEl={anchorEl} open={open} placement="bottom-end">
      <ClickAwayListener onClickAway={handleClose}>
        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h4">
            View Options
          </Typography>

          {viewOptions.map(({ key, title, toggleLabel }) => (
            <div key={key} className={classes.itemContainer}>
              <Typography className={classes.itemTitle} variant="h6">
                {title}
              </Typography>
              <FormControlLabel
                control={<Toggle name={key} checked={state[key]} onChange={handleChange} />}
                label={<Typography variant="body2">{toggleLabel}</Typography>}
                classes={{ root: classes.toggleLabelRoot, label: classes.toggleLabel }}
              />
            </div>
          ))}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default ViewOptionsMenu;
