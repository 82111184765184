import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import MenuBook from 'assets/icons/MenuBook';

import { docMetadata, listFragment } from '../mixins/Document';
import slugs from '../../displaySlugs';

const itemType = 'policy';

export default {
  categoryLabel: t`Policy`,
  displaySlug: slugs[itemType],
  label: t`Policy`,
  itemType,
  icon: MenuBook,
  listViewQuery: gql`
    query policiesQuery {
      documents(itemType: "policy") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
