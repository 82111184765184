import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import ImportContacts from '@material-ui/icons/ImportContacts';

import slugs from '../../displaySlugs';
import { listFragment, docMetadata } from '../mixins/Document';

const itemType = 'qualitySystemValidationPlan';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Quality System Validation Plan`,
  categoryLabel: t`Validation Plans`,
  icon: ImportContacts,
  listViewQuery: gql`
    query qualitySystemValidationPlansQuery {
      documents(itemType: "quality_system_validation_plan") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
