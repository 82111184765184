import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    width: 0,
    minWidth: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardContentContainer: {
    height: '100%',
    width: '100%',
    padding: '8px 12px 8px 12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
