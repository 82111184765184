import React from 'react';
import { Paper, CircularProgress } from '@material-ui/core';

export default function LongCardLoading() {
  return (
    <Paper
      elevation={0}
      style={{
        width: '100%',
        height: 120,
      }}
    >
      <CircularProgress
        size={40}
        style={{ marginLeft: '50%', marginTop: 40 }}
      />
    </Paper>
  );
}
