import { useMetadata } from 'compositions/WithMetadataFromServer';
import { ItemTypeIndex } from 'itemTypes';
import camelCase from 'lodash/camelCase';

export default function useItemType(itemType: string): any {
  const { data: server, error, loading } = useMetadata(itemType);
  if (loading) return null;

  if (error)
    throw new Error(`Cannot retrieve "${itemType}": ${error.message}`);

  if(!server || !server.itemType)
    throw new Error(`Item type "${itemType}" not found`);

  const local = ItemTypeIndex[camelCase(itemType)];
  if (!local)
    throw new Error(`No locally configured item type found: "${itemType}"`);

  return {
    ...local,
    ...server.itemType,
  };
}
