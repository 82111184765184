import gql from 'graphql-tag';

export const uploadQuery = gql`
  query DirectUploadQuery {
    newDirectUpload {
      url
      params {
        name
        value
      }
    }
  }
`;
