import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { ReactComponent as LibraryAddCheckOutlinedIcon } from './library-add-check-outlined.svg';

const LibraryAddCheckOutlined = (props) => {
  return (
    <SvgIcon {...props} children={<path />} component={(svgProps) => <LibraryAddCheckOutlinedIcon {...svgProps} />} />
  );
};

export default LibraryAddCheckOutlined;
