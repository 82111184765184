import React from 'react';

import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import LibraryAddCheck from 'assets/icons/LibraryAddCheck';

import { MakeLink } from 'components';
import { TaskWorkflowStatuses } from 'workflows/statuses';
import Views from 'workspaces/views';
import { dateFormatter } from 'components/AgGridTable/Fields/date';

import slugs from '../../displaySlugs';

const itemType = 'task';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Task`,
  categoryLabel: t`Tasks`,
  recordWorkspace: [Views.Task],
  listWorkspace: [Views.Table],
  icon: LibraryAddCheck,
  listViewQuery: gql`
    query TasksQuery {
      tasks {
        id
        customIdentifier
        createdAt
        tags
        itemType {
          id
          displaySlug
        }
        currentVersion {
          id
          createdAt
          title
          versionIdentifier
          currentStatusName
          dueDate
          releasedAt
          currentStatus {
            id
            name
            createdAt
          }
          assignedPerson {
            id
            firstName
            lastName
            fullName
            username
          }
          trioReferencedItems {
            id
            customIdentifier
            itemType {
              id
              displaySlug
              userCreatable
            }
          }
          trioReferencedVersions {
            id
            item {
              id
              customIdentifier
              userCreatable
            }
          }
          taskVersionLinkedTasks {
            id
            linkedTask {
              id
              item {
                id
                customIdentifier
                itemType {
                  id
                  displaySlug
                  userCreatable
                }
              }
            }
          }
        }
        versions {
          id
        }
        workingVersion {
          id
        }
      }
    }
  `,
  listViewColumns: [
    {
      field: 'customIdentifier',
      headerName: 'ID',
      type: 'id',
    },
    {
      field: 'currentVersion.title',
      headerName: 'Title',
      type: 'regular_text',
    },
    {
      field: 'currentVersion.assignedPerson',
      headerName: 'Assignee',
      type: 'user',
    },
    {
      field: 'currentVersion.currentStatusName',
      headerName: 'Status',
      type: 'status',
    },
    {
      field: 'currentVersion.dueDate',
      headerName: 'Due Date',
      type: 'date',
    },
    {
      field: 'tags',
      headerName: 'Tags',
      type: 'custom',
      enableRowGroup: true,
      valueGetter: (row) => {
        return row.data;
      },
      keyCreator: (row) => {
        return row.value.tags.sort().join(', ');
      },
      render: function(row) {
        const isColumnGroupedBy = Boolean(
          row.columnApi.columnModel.rowGroupColumns.find((column) => column.colId === 'tags'),
        );
        if (isColumnGroupedBy) {
          return row.value;
        }
        return row.value.tags.sort().join(', ');
      },
    },
    {
      field: 'currentVersion.releasedAt',
      headerName: 'Completion Date',
      type: 'custom',
      enableRowGroup: true,
      valueGetter: (row) => {
        if (row.data === undefined) return null;
        if (row.data.currentVersion.currentStatus.name === TaskWorkflowStatuses.Done.id) {
          return dateFormatter(row.data.currentVersion.currentStatus.createdAt);
        }
        return 'TBD';
      },
      render: function(row) {
        return row.value;
      },
    },
    {
      field: 'currentVersion.trioReferencedItems',
      headerName: 'Associated Items',
      type: 'custom',
      enableRowGroup: true,
      keyCreator: (row) => {
        return row.value.map((item) => item.customIdentifier).join(', ');
      },
      render: function(row) {
        const isColumnGroupedBy = Boolean(
          row.columnApi.columnModel.rowGroupColumns.find(
            (column) => column.colId === 'currentVersion.trioReferencedItems',
          ),
        );
        if (isColumnGroupedBy) {
          return row.value;
        }
        return row.data.currentVersion.trioReferencedItems.map((item, index) => (
          <>
            {index > 0 && ', '}
            <MakeLink newTab={true} item={item}>
              {item.customIdentifier}
            </MakeLink>
          </>
        ));
      },
    },
    {
      field: 'currentVersion.trioReferencedVersions',
      headerName: 'Associated Item Versions',
      type: 'custom',
      enableRowGroup: true,
      keyCreator: (row) => {
        return row.value.map((version) => version.item.customIdentifier).join(', ');
      },
      render: function(row) {
        const isColumnGroupedBy = Boolean(
          row.columnApi.columnModel.rowGroupColumns.find(
            (column) => column.colId === 'currentVersion.trioReferencedVersions',
          ),
        );
        if (isColumnGroupedBy) {
          return row.value;
        }
        return row.data.currentVersion.trioReferencedVersions
          .map((version) => version.item.customIdentifier)
          .join(', ');
      },
    },
    {
      field: 'currentVersion.taskVersionLinkedTasks',
      headerName: 'Linked Tasks',
      type: 'custom',
      enableRowGroup: true,
      keyCreator: (row) => {
        return row.value.map((versionLinkedTask) => versionLinkedTask.linkedTask.item.customIdentifier).join(', ');
      },
      render: function(row) {
        const isColumnGroupedBy = Boolean(
          row.columnApi.columnModel.rowGroupColumns.find(
            (column) => column.colId === 'currentVersion.taskVersionLinkedTasks',
          ),
        );
        if (isColumnGroupedBy) {
          return row.value;
        }
        return row.data.currentVersion.taskVersionLinkedTasks.map((versionLinkedTask, index) => (
          <>
            {index > 0 && ', '}
            <MakeLink newTab={true} item={versionLinkedTask.linkedTask.item}>
              {versionLinkedTask.linkedTask.item.customIdentifier}
            </MakeLink>
          </>
        ));
      },
    },
  ],
};
