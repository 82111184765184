import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  hoverUnderline: {
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: `${theme.typography.body1.color}`,
    },
  },
  pillText: {
    marginLeft: 5,
  },
}));
