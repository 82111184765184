import React, { useState } from 'react';
import {
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';

interface CreateFolderFormProps {
  open: boolean;
  handleClose: () => void;
  onCreateFolder: (folderName: string, indentationLevel: number) => void;
}

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    '& .MuiDialog-paper': {
      width: '80%',
      maxWidth: 800,
      minWidth: 600,
      padding: theme.spacing(2),
    },
  },
  textField: {
    '& .MuiInputLabel-root': {
      color: 'gray',
    },
    '& .MuiInputBase-input': {
      color: 'gray',
    },
    '& .MuiInputBase-input.MuiInput-input': {
      color: 'black',
    },
  },
  folderNameInput: {
    fontSize: '16px',
  },
  folderLevelInput: {
    '& .MuiInputLabel-root': {
      fontSize: '16px',
    },
  },
}));

const CreateFolderForm: React.FC<CreateFolderFormProps> = ({ open, handleClose, onCreateFolder }) => {
  const styles = useStyles();

  const [folderName, setFolderName] = useState<string>('');
  const [indentationLevel, setIndentationLevel] = useState<number | ''>('');
  const handleSubmit = () => {
    if (typeof indentationLevel === 'number') {
      onCreateFolder(folderName, indentationLevel);
      setFolderName('');
      setIndentationLevel('');
      handleClose();
    }
  };

  const isFormValid = folderName.trim() !== '' && indentationLevel !== '';

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className={styles.modalContainer}>
      <DialogTitle id="form-dialog-title">
        <Typography variant="h2">Create new folder </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="folderName"
          placeholder="Folder Name"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          fullWidth
          className={styles.textField}
          InputProps={{
            className: styles.folderNameInput,
          }}
        />
        <FormControl fullWidth margin="normal" className={`${styles.textField} ${styles.folderLevelInput}`}>
          <InputLabel id="folder-level-label">Folder Level</InputLabel>
          <Select
            labelId="folder-level-label"
            id="folder-level"
            value={indentationLevel}
            onChange={(e) => setIndentationLevel(e.target.value as number)}
          >
            <MenuItem value={1}>Folder Level One</MenuItem>
            <MenuItem value={2}>Folder Level Two</MenuItem>
            <MenuItem value={3}>Folder Level Three</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!isFormValid}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFolderForm;
