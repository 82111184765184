import gql from 'graphql-tag';

const listFragment = gql`
  fragment docListFragment on Document {
    id
    customIdentifier
    itemType {
      id
      displaySlug
      userCreatable
    }
    owner {
      id
      fullName
    }
    approver {
      id
      fullName
    }
    createdAt
    currentVersion {
      id
      createdAt
      title
      body
      versionIdentifier
      currentStatusName
    }
  }
`;

export { listFragment };
