import React from 'react';
import { RouteComponentProps } from '@reach/router';

import BuilderView from 'components/BuilderView';
import EffectiveView from 'components/EffectiveView';
import VersionsView from 'components/VersionsView';
import { Views } from 'workspaces';

import PayloadFields from './PayloadFields';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { DocumentToolbar } from 'itemTypes/mixins/Document';

interface Props extends RouteComponentProps {
  itemId: string;
  activeWorkspaceView: { id: string };
}

function Page(props: Props) {
  switch (props.activeWorkspaceView.id) {
    case Views.Effective.id:
      return (
        <EffectiveView
          categorySlug="validation-record"
          itemType="validationRecord"
          itemId={props.itemId}
          EffectiveViewOptions={DocumentToolbar}
          PayloadFields={(payloadProps) => <PayloadFields {...payloadProps} />}
        />
      );
    case Views.Versions.id:
      return (
        <VersionsView
          categorySlug="validation-record"
          itemType="validationRecord"
          itemId={props.itemId}
          PayloadFields={(payloadProps) => <PayloadFields {...payloadProps} />}
        />
      );
    default:
      return (
        <BuilderView
          categorySlug="validation-record"
          itemType="validationRecord"
          itemId={props.itemId}
          PayloadFields={(payloadProps) => <PayloadFields {...payloadProps} />}
        />
      );
  }
}

export default withWorkspaceViews()(Page);
