import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    disciplinesItem: {
      width: '100%',
    },
    disciplinesItemButton: {
      width: '100%',
    },
    selected: {
      background: 'rgba(255, 255, 255, .2)',
    },
    disciplinesIcon: {
      opacity: 0.5,
      '&:hover': {
        opacity: 1,
      },
    },
    selectedIcon: {
      opacity: 1,
    },
  }),
);
