/* when ts types are auto generated, this can be removed */

type Comment = {
  id: string;
  body: string;
  createdAt: string;
  creator: {
    id: string;
    fullName: string;
  };
};

export type SetChild = {
  id: string;
  indentationLevel: number;
  sortPosition: number;
  sortPositionsJson: JSON;
  folderOne: number;
  folderTwo: number;
  folderThree: number;
  title: string;
  isFolder: boolean;
  customIdentifier?: string;
  itemType?: {
    id: string;
    displaySlug: string;
    userCreatable: boolean;
  };
  currentVersion?: {
    versionIdentifier: string;
    currentStatusName: string;
    item: {
      id: string;
      tags: string[];
      customIdentifier: string;
      comments: Comment[];
      workingVersion?: {
        id: string;
        versionIdentifier: string;
        currentStatusName: string;
      };
      currentRelease?: {
        id: string;
        versionIdentifier: string;
        currentStatusName: string;
      };
    };
    id: string;
    locked: boolean;
    userNeedText: string;
    stakeholders: string[];
  };
};

export function userNeedToSetChild(member): SetChild {
  return {
    id: member.id,
    indentationLevel: member.indentationLevel,
    sortPosition: member.sortPosition,
    sortPositionsJson: member.sortPositionsJson,
    folderOne: member.folderOneName,
    folderTwo: member.folderTwoName,
    folderThree: member.folderThreeName,
    title: member.version.title,
    isFolder: false,
    currentVersion: member.version,
    customIdentifier: member.version.item.customIdentifier,
    itemType: member.version.item.itemType,
  };
}

const sortByCustomIdentifier = (a, b) => {
  const prefixA = a.customIdentifier.split('-')[0];
  const prefixB = b.customIdentifier.split('-')[0];
  const numA = parseInt(a.customIdentifier.split('-')[1], 10);
  const numB = parseInt(b.customIdentifier.split('-')[1], 10);

  if (prefixA === prefixB) {
    return numA - numB;
  } else {
    return prefixA.localeCompare(prefixB);
  }
};

export const sortGroupedBy = (data, arg) => {
  return data.sort((a, b) => {
    return parseInt(a.sortPositionsJson[arg]) - parseInt(b.sortPositionsJson[arg]);
  });
};

export const sortSetItems = (
  data,
  isCustomGrouping,
  isGroupedByStakeholder,
  isGroupedByFolders,
  isGroupedByStakeholderAndFolders,
) => {
  if (isCustomGrouping) {
    return data.sort(sortByCustomIdentifier);
  }
  if (isGroupedByStakeholder) {
    return sortGroupedBy(data, 'stakeholder');
  }
  if (isGroupedByFolders) {
    return sortGroupedBy(data, 'folders');
  }
  if (isGroupedByStakeholderAndFolders) {
    return sortGroupedBy(data, 'stakeholderAndFolders');
  }
  if (!isGroupedByStakeholder && !isGroupedByFolders && !isGroupedByStakeholderAndFolders) {
    return sortGroupedBy(data, 'allItems');
  }

  return data.sort(sortByCustomIdentifier);
};

export const undefinedKey = '(undefined)';

export const stakeholdersKeyCreator = (stakeholders) => {
  return stakeholders ? stakeholders.sort().join(', ') : undefinedKey;
};

export const changeSortPositionsBy = (members, overNode, movingNode, key) => {
  return members.map((member) => {
    if (member.id === movingNode.data.id) {
      const currentSortPositions = member.sortPositionsJson;
      return {
        ...member,
        sortPositionsJson: {
          ...currentSortPositions,
          [key]: overNode.data.sortPositionsJson[key],
        },
      };
    }
    if (member.id === overNode.data.id) {
      const currentSortPositions = member.sortPositionsJson;
      return {
        ...member,
        sortPositionsJson: {
          ...currentSortPositions,
          [key]: movingNode.data.sortPositionsJson[key],
        },
      };
    }
    return member;
  });
};

export const filterFolders = (data) => {
  return data && data.userNeedsSetVersion && data.userNeedsSetVersion.setItemChildren
    ? data.userNeedsSetVersion.setItemChildren.filter((child) => child.__typename === 'Folder')
    : null;
};

export const findFolderByName = (name, folders) => {
  const foundFolder = folders.find((folder) => folder.folderName === name);
  return foundFolder ? foundFolder.id : null;
};
