import React from 'react';

import InputRange from 'components/InputRange';

import { useStyles } from './styles';

export interface TriStateToggleProps {
  toggled: boolean | null;
  disabled?: boolean;
  onChange: (value?: boolean) => void;
}

function TriStateToggle(props: TriStateToggleProps) {
  const { toggled } = props;
  const classes = useStyles();

  const value = () => {
    if (toggled) {
      return 2;
    } else if (toggled === null) {
      return 1;
    } else {
      return 0;
    }
  };

  const toggleStateClasses = [classes.falseStatusSwitcher, classes.nullStatusSwitcher, classes.trueStatusSwitcher];

  return (
    <InputRange
      min="0"
      max="2"
      value={value()}
      disabled={props.disabled}
      className={toggleStateClasses[value()]}
      onChange={(event) => {
        if (event.target.value === '0') {
          props.onChange(false);
        } else if (event.target.value === '1') {
          props.onChange(undefined);
        } else if (event.target.value === '2') {
          props.onChange(true);
        }
      }}
    />
  );
}
export default TriStateToggle;
