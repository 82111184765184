import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      margin: 'inherit',
      padding: `0 ${theme.spacing(5)}px`,
      paddingTop: theme.spacing(5),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      color: theme.palette.secondary.main,
    },
    typography: {
      width: '100%',
      marginLeft: theme.spacing(),
      color: theme.palette.secondary.main,
    },
    tableContainer: {
      maxWidth: 1000,
      width: '100%',
    },
    toolbar: {
      backgroundColor: theme.palette.background.default,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(2),
      minHeight: 'inherit',
      '& .MuiTextField-root': {
        width: 30,
        minWidth: 0,
        transition: ' all .5s',
        '& .MuiInputBase-adornedStart': {
          cursor: 'pointer',
        },
        '& .MuiInputAdornment-positionStart': {
          pointerEvents: 'none',
        },
        '& .MuiInputAdornment-positionEnd': {
          visibility: 'hidden',
          '& .Mui-disabled': {
            visibility: 'hidden',
          },
        },
        '&:focus-within': {
          width: 250,
          '& .MuiInputAdornment-positionEnd': {
            '&::placeholder-shown': {
              visibility: 'hidden',
            },
            visibility: 'visible',
          },
        },
      },
      '& .MuiInput-underline:hover:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
    },
  }),
);
