import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: {
    main: '#4db1ab',
    contrastText: '#fff',
  },
  secondary: {
    main: '#6e7583',
  },
  background: {
    contextDrawerGrey: '#fcfcfc',
    contextDrawerHeader: '#ebeced',
    default: '#fafafa',
    transparent: 'rgba(0, 0, 0, 0)',
  },
  blueDark: '#3c4858',
  subHeading: '#A4A8B1',
  action: {
    active: 'rgba(60, 72, 88)',
    hover: 'rgba(60, 72, 88, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(60, 72, 88, 0.14)',
    disabled: 'rgba(60, 72, 88, 0.26)',
    disabledBackground: 'rgba(60, 72, 88, 0.12)',
  },
  appWindows: {
    designHistoryFile: '#7fc631',
    designMasterFile: '#2676D3',
    trainingManagement: '#4B49A3',
    qms: '#725395',
  },
  titleBlack: 'rgba(0, 0, 0, .87)',
  subtitleGrey: 'rgba(0, 0, 0, .6)',
  common: {
    archived: {
      main: '#808080',
      light: '#808080',
    },
    obsolete: {
      main: '#4c4c4c',
      light: '#4c4c4c',
    },
    closed: {
      main: '#0b915c',
      light: '#8ac8ae',
    },
    retired: {
      main: '#4c4c4c',
      light: '#4c4c4c',
    },
    inactive: {
      main: '#4c4c4c',
      light: '#4c4c4c',
    },
    released: {
      main: '#0b915c',
      light: '#8ac8ae',
    },
    checked: {
      main: '#0b915c',
      light: '#8ac8ae',
    },
    draft: {
      main: '#378eb1',
      light: '#7eb9cf',
    },
    unlocked: {
      main: '#378eb1',
      light: '#7eb9cf',
    },
    created: {
      main: '#378eb1',
      light: '#7eb9cf',
    },
    underReview: {
      main: '#f2bd3c',
      light: '#f8d88a',
    },
    readyForReview: {
      main: '#f2bd3c',
      light: '#f8d88a',
    },
    semiLocked: {
      main: '#f2bd3c',
      light: '#f8d88a',
    },
    inProgress: {
      main: '#f2bd3c',
      light: '#f8d88a',
    },
    ownerApproval: {
      main: '#e27325',
      light: '#f0b075',
    },
    locked: {
      main: '#e27325',
      light: '#f0b075',
    },
    approvedDraft: {
      main: '#725395',
      light: '#b3a2c7',
    },
    canceled: {
      main: '#f65956',
      light: '#f77a77',
    },
    failed: {
      main: '#f65956',
      light: '#f77a77',
    },
    rejected: {
      main: '#f65956',
      light: '#f77a77',
    },
    readyForTraining: {
      main: '#97d96a',
      light: '#97d96a',
    },
    releasedForCoTraining: {
      main: '#97d96a',
      light: '#97d96a',
    },
    input: {
      background: '#fcfcfc',
      outline: '#ebeced',
      hover: 'rgba(48, 188, 179, 0.28)',
    },
    tags: {
      background: '#dfdfdf',
      highlight: 'rgba(48, 188, 179, 0.1)',
    },
    pdf: {
      background: '#9b9b9b',
      navBar: '#808080',
    },
    error: {
      main: '#f65956',
    },
    supplierRequested: {
      main: '#378eb1',
      light: '#7eb9cf',
    },
    readyForPrequalification: {
      main: '#f2bd3c',
      light: '#f8d88a',
    },
    prequalificationComplete: {
      main: '#e27325',
      light: '#f0b075',
    },
    readyForQualification: {
      main: '#f2bd3c',
      light: '#f8d88a',
    },
    qualificationComplete: {
      main: '#e27325',
      light: '#f0b075',
    },
    supplierApproved: {
      main: '#0b915c',
      light: '#8ac8ae',
    },
    qualificationRejected: {
      main: '#f65956',
      light: '#f77a77',
    },
    qualificationRevoked: {
      main: '#b1b6bc',
      light: '#b1b6bc',
    },
    taskWontDo: {
      main: '#636D79',
      light: '#636D79',
    },
  },
  status: {
    warning: '#ffe7ad',
  },
  splitter: {
    background: '#dfdfdf',
  },
};

const theme = createMuiTheme({
  mixins: {
    header: {
      height: 72,
    },
    sidebar: {
      expandedWidth: 350,
      collapsedWidth: 56,
    },
  },
  palette,
  typography: {
    fontFamily: ['Open Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeightBold: 600,
    fontWeightExtraBold: 700,
    // applies the following properties to all typography variants
    allVariants: {
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: palette.subtitleGrey,
    },
    h1: {
      fontSize: 34,
      fontWeight: 300,
      lineHeight: 1.32,
      color: palette.titleBlack,
    },
    h2: {
      fontSize: 24,
      fontWeight: 'normal',
      lineHeight: 1.88,
      letterSpacing: 1.5,
      color: palette.titleBlack,
    },
    h3: {
      fontSize: 20,
      fontWeight: 'normal',
      lineHeight: 1.3,
      color: palette.titleBlack,
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h5: {
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 1.14,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.29,
    },
    subtitle1: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: 1.25,
    },
    subtitle2: {
      fontSize: 9,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    body1: {
      fontSize: 16,
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: 0.51,
    },
    body2: {
      fontSize: 13,
      fontWeight: 'normal',
      lineHeight: 1.38,
    },
    button: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: 1.33,
    },
    overline: {
      fontSize: 11,
      fontWeight: 600,
      lineHeight: 1.27,
    },
  },
  buttonMixins: {
    // TODO: once this get unweildy, its time to superclass the Button elemnt
    justIcon: {
      minWidth: 0,
      padding: 12,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  optionsDrawerWidth: 320,
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: '0.8125rem',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': { backgroundColor: 'none' },
      },
      colorSecondary: {
        '&:hover': { backgroundColor: 'none' },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1em',
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 8,
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
      root: {
        height: 36,
      },
    },
    MuiTableSortLabel: {
      root: {
        '& svg': {
          display: 'none',
        },
      },
      active: {
        '& svg': {
          display: 'inline-block',
        },
      },
    },
    MuiLinearProgress: {
      bar: {
        borderRadius: 6,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#4a4a4a',
        color: '#fff !important',
        padding: '4px 8px 4px 8px',
      },
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle2: 'span',
      },
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiButton: {
      disableFocusRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTooltip: {
      disableFocusListener: true,
    },
  },
});

theme.palette.common.effective = theme.palette.common.released;

theme.mainLayoutGutters = {
  [theme.breakpoints.up('lg')]: {
    maxWidth: 1280,
  },
  [theme.breakpoints.only('lg')]: {
    maxWidth: 1080,
  },
};

theme.mixins.gridLayout = {
  container: {
    padding: `0 ${theme.spacing(5)}px`,
    paddingTop: theme.spacing(5),
  },
  grid: {
    paddingLeft: `0 !important`,
    paddingRight: theme.spacing(2),
  },
  gridGutterLeft: {
    paddingRight: `0 !important`,
    paddingLeft: theme.spacing(2),
  },
};

function ThemeProvider(props) {
  return <MuiThemeProvider {...props} theme={theme} />;
}

export default ThemeProvider;
