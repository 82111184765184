import React, { FC } from 'react';

import { Edit } from '@material-ui/icons';
import { IconButton } from 'components';
import { useStyles } from './styles';

interface Props {
  tooltip?: string;
  onClick: () => void;
}

const EditButton: FC<Props> = ({ tooltip, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.editButton}>
      <IconButton Icon={Edit} onClick={onClick} tooltip={tooltip} />
    </div>
  );
};

export default EditButton;
