import React, { useContext, useEffect, useState } from 'react';
import { BusinessCenter, Contacts, ThumbsUpDown } from '@material-ui/icons';
import { flowRight } from 'lodash';

import { useForm } from 'components/Form';
import { PreviousVersionsList, Tab, TabContainer, TabIcon } from 'components';

import withCurrentUser from 'compositions/WithCurrentUser';
import { LockedContext, withLockedContext } from 'contexts/lockedContext';

import { useUpdateVersion, useVersion } from '../gql';
import RenderTab from './RenderTab';
import PreviousQualificationLayout from './PreviousQualificationLayout';

export interface SupplierPageTab {
  name: string;
  title: string;
  icon: any;
}

export const SUPPLIER_PAGE_TABS = {
  information: {
    name: 'information',
    title: 'Supplier Information',
    icon: Contacts,
  },
  scope: {
    name: 'scope',
    title: 'Scope & Risk',
    icon: BusinessCenter,
  },
  activities: {
    name: 'activities',
    title: 'Qualification Activities',
    icon: ThumbsUpDown,
  },
};

interface Props {
  versionId: string;
  layoutClasses: any;
}

function PayloadFields(props: Props) {
  const { data, loading, error } = useVersion(props.versionId);
  const { setLocked } = useContext(LockedContext);

  const INITIAL_TAB = 'information';
  const [currentTab, setCurrentTab] = useState(INITIAL_TAB);

  useEffect(() => {
    if (data) setLocked(data.supplierVersion.locked);
  }, [data, setLocked]);

  const save = useUpdateVersion();

  const update = (change) => save({ variables: change });
  const form = useForm({ data: data && data.supplierVersion, update });

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading supplier version ${props.versionId}\n${error}`);

  return (
    <>
      <TabContainer
        layoutClasses={props.layoutClasses}
        tabs={
          <>
            <Tab
              icon={<TabIcon Icon={SUPPLIER_PAGE_TABS.information.icon} />}
              active={SUPPLIER_PAGE_TABS.information.name === currentTab}
              onClick={() => setCurrentTab(SUPPLIER_PAGE_TABS.information.name)}
              text={SUPPLIER_PAGE_TABS.information.title}
            />
            <Tab
              icon={<TabIcon Icon={SUPPLIER_PAGE_TABS.scope.icon} />}
              active={SUPPLIER_PAGE_TABS.scope.name === currentTab}
              onClick={() => setCurrentTab(SUPPLIER_PAGE_TABS.scope.name)}
              text={SUPPLIER_PAGE_TABS.scope.title}
            />
            <Tab
              icon={<TabIcon Icon={SUPPLIER_PAGE_TABS.activities.icon} />}
              active={SUPPLIER_PAGE_TABS.activities.name === currentTab}
              onClick={() => setCurrentTab(SUPPLIER_PAGE_TABS.activities.name)}
              text={SUPPLIER_PAGE_TABS.activities.title}
            />
          </>
        }
      >
        <RenderTab name={currentTab} version={data.supplierVersion} save={save} form={form} />
      </TabContainer>
      <PreviousVersionsList
        labels={{
          dateLabel: 'Completion Date',
          statusLabel: 'Qualification Outcome',
          versionTypeLabel: 'Qualification',
          versionTypeSecondLabel: 'Requalification',
        }}
        versions={data.supplierVersion.item.versions.filter((v) => {
          return ['qualification_revoked', 'qualification_rejected'].includes(v.currentStatus.name);
        })}
        title={'Records of Previous Supplier Qualifications'}
        Component={PreviousQualificationLayout}
      />
    </>
  );
}

export default flowRight([withCurrentUser, withLockedContext])(PayloadFields);
