import PropTypes from 'prop-types';
import React from 'react';

import clsx from 'clsx';

import { AppBar, Grid, withStyles } from '@material-ui/core';

import { WorkspaceViewMenu } from 'compositions';
import { TreeDrawerContext } from 'contexts/treeDrawerContext';

const styles = (theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    color: theme.palette.text.secondary,
    padding: `0 ${theme.spacing(2)}px`,
    width: `calc(100% - ${theme.mixins.sidebar.expandedWidth}px)`,
    marginLeft: theme.mixins.sidebar.expandedWidth,
    zIndex: theme.zIndex.appBar,
    height: theme.mixins.header.height,
    transition: 'width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  wide: {
    width: `calc(100% - ${theme.mixins.sidebar.collapsedWidth}px)`,
  },
});

function Header({ classes }) {
  const { expanded } = React.useContext(TreeDrawerContext);
  const headerClass = clsx(classes.appBar, !expanded && classes.wide);
  return (
    <AppBar className={headerClass} position="fixed">
      <Grid container justify="center">
        <Grid item>
          <WorkspaceViewMenu />
        </Grid>
      </Grid>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.shape({
    appBar: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(Header);
