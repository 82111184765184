import React, { FC } from 'react';

import EsignatureRequiredBody from './GenericNotificationListItem/EsignatureRequiredBody';
import MentionBody from './GenericNotificationListItem/MentionBody';
import TaskBody from './GenericNotificationListItem/TaskBody';
import TrainingBody from './GenericNotificationListItem/TrainingBody';

import { currentUser_currentUser } from '../../__generated__/currentUser';

interface Props {
  currentUser: currentUser_currentUser;
  notification: any;
  onRedirect: () => void;
}
const NotificationListItem: FC<Props> = ({ currentUser, notification, onRedirect }) => {
  return (
    notification && (
      <>
        {notification.precedingStatus && (
          <EsignatureRequiredBody currentUser={currentUser} notification={notification} onRedirect={onRedirect} />
        )}
        {notification.comment && (
          <MentionBody currentUser={currentUser} notification={notification} onRedirect={onRedirect} />
        )}
        {notification.task && <TaskBody notification={notification} onRedirect={onRedirect} />}
        {notification.training && <TrainingBody notification={notification} onRedirect={onRedirect} />}
      </>
    )
  );
};

export default NotificationListItem;
