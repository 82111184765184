import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const GET_SUPPLIER_QUESTIONNAIRE = gql`
  query SupplierQuestionnaireVersion($id: ID!) {
    supplierQuestionnaireVersion(id: $id) {
      id
      locked
      inputMethod
      body
      reasonForChange
      descriptionOfChange
      pdfDataUpload
      displayAsApplicationVersion
      item {
        id
        itemNumber
        customIdentifier
      }
      attachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      rawReferenceAttachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
    }
  }
`;

export function useVersion(id: string | undefined) {
  return useQuery(GET_SUPPLIER_QUESTIONNAIRE, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}
