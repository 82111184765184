import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';

import Close from '@material-ui/icons/Close';
import Warning from '@material-ui/icons/Warning';
import {
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { ReasonForChangeAndEsig, Spacer } from 'components';

import WithAllUsers from 'compositions/WithAllUsers';
import withJobRoles from 'compositions/WithJobRoles';
import WithCurrentUser from 'compositions/WithCurrentUser';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import { UPDATE_PERMISSION_GRANT } from '../ChangeOwnerApprover/gql';
import { useStyles } from './styles';

function ChangeOwnerApprover({
  allUsers,
  open,
  onClose,
  currentApprover,
  currentOwnerRole,
  currentApproverRole,
  currentAssignedUser,
  currentOwner,
  currentCompanyAllowsCustomPermissions,
  currentRole,
  currentUser,
  jobRoles,
  permissibleDefaultUsers,
  permissionType,
  versionId,
  refetchQueries,
  jobRoleSelectLocked = false,
}) {
  const classes = useStyles();

  const [newRole, setNewRole] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const [roleSelectorOpen, setRoleSelectorOpen] = useState(false);
  const [userSelectorOpen, setUserSelectorOpen] = useState(false);
  const [reasonForPermissionChange, setReasonForPermissionChange] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const unAssignableJobRoles = ['Custom Role', 'System', 'General', 'Admin'];
  const assignableJobRoles =
    jobRoles !== undefined && jobRoles.filter((jobRole) => !unAssignableJobRoles.includes(jobRole.formattedRoleName));

  const userSelectedRoleOrDefault =
    currentCompanyAllowsCustomPermissions && !jobRoleSelectLocked ? newRole : currentRole;

  const renderJobRoleOption = (jobRole) => {
    return (
      <MenuItem value={jobRole} key={jobRole.id} className={classes.menuOption}>
        <Typography variant="body2">
          <span className={classes.itemTypeText}>{jobRole.formattedRoleName}</span>
        </Typography>
      </MenuItem>
    );
  };

  const renderUsers = (user) => {
    return (
      <MenuItem value={user} key={user.id} className={classes.menuOption}>
        <Typography variant="body2">
          <span className={classes.itemTypeText}>{user.fullName}</span>
        </Typography>
      </MenuItem>
    );
  };

  const formCanSubmit = () => {
    return (
      userSelectedRoleOrDefault && newUser && reasonForPermissionChange.length && username.length && password.length
    );
  };

  const onClickOrClose = () => {
    onClose();
    setNewRole(null);
    setNewUser(null);
    setReasonForPermissionChange('');
    setUsername('');
    setPassword('');
  };

  const [mutate, { loading: submitLoading }] = useMutation(UPDATE_PERMISSION_GRANT, {
    onCompleted: onClickOrClose,
    refetchQueries: ['AllJobRoles'], // TODO: also refetch permission grants-specific query when available
  });
  const updatePermissionGrant = () =>
    mutate({
      variables: {
        userId: newUser.id,
        versionId: versionId,
        permissionType: permissionType,
        jobRoleId: currentCompanyAllowsCustomPermissions && !jobRoleSelectLocked ? newRole.id : currentRole.id,
        reasonForPermissionChange: reasonForPermissionChange,
        username: username,
        password: password,
      },
      refetchQueries: refetchQueries,
    });

  return (
    <CardContent className={classes.content}>
      <Close className={classes.closeIcon} onClick={onClickOrClose} />
      <Spacer factor={4} />
      <Warning color="primary" style={{ margin: '0 auto', fontSize: 43 }} />
      <Spacer factor={1} />
      <Typography variant="h3" align="center">
        {`Change ${permissionType === 'own_record' ? 'Owner' : 'Approver'} Information`}
      </Typography>
      <Spacer factor={4} />
      <form>
        <FormControl required fullWidth>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <div>
                <Typography variant="subtitle1">Current Role Assigned</Typography>
                <Spacer factor={0.5} />
                <TextField
                  className={classes.disabledText}
                  classes={{
                    root: classes.textField,
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldInput,
                    },
                  }}
                  disabled
                  defaultValue={currentRole.formattedRoleName}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={1} style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h4" align="center" className={classes.separator}>
                {'>>'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography variant="subtitle1">New Role</Typography>
                <Spacer factor={0.5} />
                <Tooltip title={currentCompanyAllowsCustomPermissions ? '' : 'Upgrade license to edit'}>
                  <Select
                    classes={{ select: classes.selectMenu }}
                    open={roleSelectorOpen}
                    onClose={() => setRoleSelectorOpen(false)}
                    onOpen={() => setRoleSelectorOpen(true)}
                    onChange={(e) => setNewRole(e.target.value)}
                    disabled={!currentCompanyAllowsCustomPermissions || jobRoleSelectLocked}
                    value={newRole}
                    renderValue={() =>
                      (userSelectedRoleOrDefault && userSelectedRoleOrDefault.formattedRoleName) || 'Role'
                    }
                    variant="outlined"
                    required
                    input={
                      <OutlinedInput
                        labelWidth={0}
                        name="ownerRole"
                        required
                        classes={{ input: classes.outlinedInput }}
                      />
                    }
                    inputProps={{ classes: { icon: classes.dropdownIcon } }}
                    displayEmpty
                    fullWidth
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                  >
                    <MenuItem value={null} disabled>
                      <Typography variant="body2">Role</Typography>
                    </MenuItem>
                    {assignableJobRoles
                      .filter((role) =>
                        role.id !== get(userSelectedRoleOrDefault, 'id', '') && permissionType === 'own_record'
                          ? role.id !== currentApproverRole.id
                          : role.id !== currentOwnerRole.id,
                      )
                      .map((jobRole) => renderJobRoleOption(jobRole))}
                  </Select>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </FormControl>
        <Spacer factor={2} />
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <div>
              <Typography variant="subtitle1">Current User Assigned</Typography>
              <Spacer factor={0.5} />
              <TextField
                className={classes.disabledText}
                classes={{
                  root: classes.textField,
                }}
                InputProps={{
                  classes: {
                    input: classes.textFieldInput,
                  },
                }}
                disabled
                defaultValue={currentAssignedUser}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid item xs={1} style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h4" align="center" className={classes.separator}>
              {'>>'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Typography variant="subtitle1">New Assigned User</Typography>
              <Spacer factor={0.5} />
              <Select
                classes={{ select: classes.selectMenu }}
                open={userSelectorOpen}
                onClose={() => setUserSelectorOpen(false)}
                onOpen={() => setUserSelectorOpen(true)}
                onChange={(e) => setNewUser(e.target.value)}
                disabled={!userSelectedRoleOrDefault}
                value={newUser}
                variant="outlined"
                required
                input={
                  <OutlinedInput labelWidth={0} name="ownerRole" required classes={{ input: classes.outlinedInput }} />
                }
                inputProps={{ classes: { icon: classes.dropdownIcon } }}
                displayEmpty
                fullWidth
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={null} disabled>
                  <Typography variant="body2">User</Typography>
                </MenuItem>
                {!currentCompanyAllowsCustomPermissions
                  ? permissibleDefaultUsers && permissibleDefaultUsers.map((user) => renderUsers(user))
                  : userSelectedRoleOrDefault &&
                    userSelectedRoleOrDefault.usersWithRole
                      .filter(
                        (user) =>
                          user.id !== '1' &&
                          user.id !== get(currentApprover, 'id', '') &&
                          user.id !== get(currentOwner, 'id', ''),
                      )
                      .map((user) => renderUsers(user))}
              </Select>
            </div>
          </Grid>
        </Grid>
        <Spacer factor={4} />
        <ReasonForChangeAndEsig
          textboxHeading={`Reason for ${permissionType === 'own_record' ? 'Owner' : 'Approver'} Change`}
          textboxId={`reason-for-permission-change-text-${permissionType}`}
          onTextChange={(e) => setReasonForPermissionChange(e.target.value)}
          usernameInputId={`username-input-${permissionType}`}
          onUsernameChange={(e) => setUsername(e.target.value)}
          passwordInputId={`password-input-${permissionType}`}
          onPasswordChange={(e) => setPassword(e.target.value)}
          submitDisabled={!formCanSubmit() || submitLoading}
          onSubmitClick={() => updatePermissionGrant()}
          submitLoading={submitLoading}
        />
      </form>
    </CardContent>
  );
}

export default flowRight([WithAllUsers, withJobRoles, withCurrentCompany, WithCurrentUser])(ChangeOwnerApprover);
