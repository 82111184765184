import { get, orderBy } from 'lodash';
import { compose } from 'lodash/fp';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';

import { COLUMNS } from './constants';
import Table from './Table';
import { buildTableColumns } from './utils';

const invertDirection = {
  asc: 'desc',
  desc: 'asc',
};

class CategoryItemsTable extends React.Component {
  static propTypes = {
    activeCategory: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    categoryKeyPathMap: PropTypes.arrayOf(
      PropTypes.arrayOf(
        ([categoryKey, getter], key, componentName, location, propFullName) => {
          if (!Object.keys(COLUMNS).includes(categoryKey)) {
            return new Error(
              `Invalid prop ${propFullName} supplied to ${componentName}. ${categoryKey} is not a valid COLUMN key. Validation failed.`,
            );
          }
          if (typeof getter !== 'string' && typeof getter !== 'function') {
            return new Error(
              `Invalid prop ${propFullName} supplied to ${getter}. ${getter} must be a string or a function. Validation failed.`,
            );
          }
        },
      ).isRequired,
    ).isRequired,
    tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      columnToSort: '',
      sortDirection: 'desc',
    };
  }

  handleSort = (columnName) => {
    this.setState(
      (state) => ({
        columnToSort: columnName,
        sortDirection:
          state.columnToSort === columnName
            ? invertDirection[state.sortDirection]
            : 'asc',
      }),
      () => {
        console.log('TODO: implement API based sort.');
      },
    );
  };

  normalizeData = (categoryKeyPathMap) => (data) =>
    data.map((categoryItem) =>
      categoryKeyPathMap.concat([['id', 'id']]).reduce((acc, [key, getter]) => {
        if (typeof getter === 'function') {
          acc[key] = getter(categoryItem);
        } else {
          acc[key] = get(categoryItem, getter);
        }
        return acc;
      }, {}),
    );

  sortData = (columnToSort, sortDirection) => (data) =>
    orderBy(data, columnToSort, sortDirection);

  formatData = memoizeOne(
    (data, categoryKeyPathMap, columnToSort, sortDirection) =>
      compose(
        this.normalizeData(categoryKeyPathMap),
        this.sortData(columnToSort, sortDirection),
      )(data),
  );

  render() {
    const { categoryKeyPathMap, tableData, ...rest } = this.props;

    const formattedData = this.formatData(
      tableData,
      categoryKeyPathMap,
      this.state.columnToSort,
      this.state.sortDirection,
    );

    return (
      <Table
        {...rest}
        handleSort={this.handleSort}
        columnToSort={this.state.columnToSort}
        sortDirection={this.state.sortDirection}
        tableColumns={buildTableColumns(categoryKeyPathMap)}
        tableData={formattedData}
      />
    );
  }
}

export default CategoryItemsTable;
