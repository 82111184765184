import { ResolveRejection } from 'compositions/TimelineGroup/steps/views';
import { RiskNoteWorkflowStatuses } from 'workflows/statuses';

export default {
  initialStep: 'resolve',
  internalSteps: {
    resolve: {
      component: ResolveRejection,
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: () => ({
    toStatus: RiskNoteWorkflowStatuses.Draft.id,
  }),
};
