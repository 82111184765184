import { createStyles } from '@material-ui/core';

export const styles = (theme) =>
  createStyles({
    autofill: {
      height: '100%',
    },
    header: {
      color: theme.palette.titleBlack,
    },
    icon: {
      color: theme.palette.blueDark,
    },
    detailsList: {
      textTransform: 'none',
      margin: '0',
      marginLeft: theme.spacing(2),
      listStyle: 'none',
      padding: 0,
    },
    row: {
      margin: '0',
      marginBottom: theme.spacing(2),
      '&:first-child': {
        '& span': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      '& span': {
        textTransform: 'none',
      },
    },
  });
