import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import PermDeviceInformation from '@material-ui/icons/PermDeviceInformation';

import slugs from '../../displaySlugs';
import { docMetadata } from '../mixins/Document';
import PayloadFields from './PayloadFields';

const itemType = 'labeling';

export default {
  categoryLabel: t`Labeling`,
  displaySlug: slugs[itemType],
  label: t`Labeling`,
  listViewQuery: gql`
    query labelingListViewQuery {
      labelings {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          body
          inputMethod
          versionIdentifier
          currentStatusName
        }
      }
    }
  `,
  itemType,
  icon: PermDeviceInformation,
  ...docMetadata,
  PayloadFields,
};
