export const styles = (theme) => {
  return {
    '@global': {
      '@media print': {
        main: {
          marginLeft: '20px',
        },
      },
      body: {
        backgroundColor: theme.palette.background.default,
        fontFamily: theme.typography.fontFamily.join(', '),
      },
      a: {
        textDecoration: 'none',
      },
      textarea: {
        border: 0,
        outline: 0,
      },
    },
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${theme.mixins.sidebar.expandedWidth}px)`,
      marginLeft: theme.mixins.sidebar.expandedWidth,
    },
    drawer: {
      width: theme.mixins.sidebar.expandedWidth,
      flexShrink: 0,
    },
    content: {
      flexGrow: 1,
    },
    contentGutters: {
      // ...theme.mainLayoutGutters,
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.action.active,
      fontWeight: 600,
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
    },
    contentContainer: {
      width: '100%',
      padding: `${theme.spacing(11.25)}px ${theme.spacing(5)}px 0px ${theme.spacing(5)}px`,
    },
    fullPageBackground: {
      content: '""',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      height: '100vh',
      position: 'relative',
    },
    unauthorizedRoot: {
      content: '""',
      height: '100%',
      left: 0,
      paddingBottom: '20vh',
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  };
};
