import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import isEmpty from 'lodash/isEmpty';

import ErrorIcon from '@material-ui/icons/Error';
import SettingsSelect from 'components/SettingsSelect';
import Days from 'components/SettingsSelect/Days';
import SettingsEditButton from '../SettingsEditButton';
import SectionTitle from 'components/SettingsSection/SectionTitle';

import { SettingsSelectItem } from 'components/SettingsSelect/types';
import { useCurrentCompany } from 'compositions/WithCurrentCompany';
import { useStyles } from './styles';
import { UPDATE_CLIENT_CONFIG } from '../../gql';
import { EditProps } from '../../types';

interface Props extends EditProps {}

const SupplierManagement: FC<Props> = ({ name, editing, onEdit }) => {
  const classes = useStyles();
  const [updateData, setUpdateData] = useState({});
  const { data } = useCurrentCompany();
  const [updateClientConfig] = useMutation(UPDATE_CLIENT_CONFIG, {
    refetchQueries: ['withCurrentCompany_currentCompanyQuery'],
  });
  const items: SettingsSelectItem[] = [
    { key: 'expirationHighRisk', label: 'High' },
    { key: 'expirationMediumRisk', label: 'Medium' },
    { key: 'expirationLowRisk', label: 'Low' },
  ];

  if (!data) return null;

  const onChange = async (name: string, value: number) =>
    setUpdateData((prevState) => ({ ...prevState, [name]: value }));

  const handleSave = async () => {
    if (isEmpty(updateData)) {
      onEdit(null);
      return;
    }

    await updateClientConfig({
      variables: {
        input: {
          supplierManagement: updateData,
        },
      },
    })
      .then(() => {
        setUpdateData({});
        onEdit(null);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const getSettingValue = (key: string) => data.currentCompany.config.supplierManagement[key];

  return (
    <>
      <SectionTitle
        icon={<ErrorIcon />}
        title="Evaluation Frequency based on the Risk of Supplier’s Score"
        editButton={
          <SettingsEditButton
            isActive={editing}
            onClick={() => onEdit(name)}
            onActionButtonClick={handleSave}
            onCancel={() => onEdit(null)}
          />
        }
      />
      <div className={classes.root}>
        <Days />
        {items.map((item) => (
          <SettingsSelect
            key={`${item.key}-${editing}`}
            item={item}
            value={getSettingValue(item.key)}
            onChange={onChange}
            disabled={!editing}
          />
        ))}
      </div>
    </>
  );
};

export default SupplierManagement;
