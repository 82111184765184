import React, { useContext } from 'react';
import { compose } from 'recompose';

import { withAllItemTypeMetadata } from 'compositions/WithMetadataFromServer';
import { withItemTypeMetadata } from 'utils/withItemTypeMetadata';
import WithCurrentUser from 'compositions/WithCurrentUser';
import AddLinkButton from '../AddLinkButton';
import RiskVersionCard from 'components/CardGraph/Card/RiskCard';
import { GraphCard } from 'components';
import { stripHTML } from 'utils';
import VersionSequenceWithDot from 'utils/VersionSequenceWithDot';

import { useAllItemTypes } from 'hooks/useAllItemTypes';

import { CardContext } from 'contexts/LinksGraph/cardContext';
import { useStyles } from './styles';

function Path() {
  return (
    <div style={{ width: 24, display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1, borderBottom: '1px solid #c7c7c7' }} />
      <div style={{ flex: 1 }} />
    </div>
  );
}

function ItemCard({
  direction,
  item,
  onClick,
  onAddLinkClick,
  selected,
  theme,
  itemTypes,
  allItemTypeMetadata,
  currentUser,
  hideConnections,
  parent,
}) {
  const { loading, error, findTypeByName } = useAllItemTypes();
  const { otherConnections, description } = useContext(CardContext);

  const classes = useStyles();

  if (loading) return null;
  if (error) throw error;

  const includeOnlyTracesFromOppositeDirectionInOtherTraces = () => {
    const mapLink = (link) => {
      return {
        customIdentifier: link.customIdentifier,
        id: link.id,
        itemType: {
          displaySlug: findTypeByName(link.itemType).displaySlug,
          name: findTypeByName(link.itemType).name,
        },
        title: link.title,
      };
    };
    const filterToExcludeParent = (link) => !parent || link.id !== parent.id;
    if (direction === 'right') {
      return item.upstreamLinks.filter(filterToExcludeParent).map(mapLink);
    } else {
      return item.downstreamLinks.filter(filterToExcludeParent).map(mapLink);
    }
  };

  const buildOtherTraces = () => {
    const shouldShowTraces = parent !== undefined && !hideConnections && otherConnections.showing;
    if (shouldShowTraces) {
      return includeOnlyTracesFromOppositeDirectionInOtherTraces();
    } else {
      return undefined;
    }
  };

  const summary = stripHTML(item.stringifiedMostImportantData.join('\n'));

  return (
    <div style={{ flex: 1, display: 'flex', position: 'relative' }} id={`item-card-${item.id}`}>
      {direction === 'right' && <Path />}
      <div className={classes.card} onClick={selected ? null : onClick}>
        <GraphCard
          item={item}
          summary={description.showing ? summary : undefined}
          selected={selected}
          otherConnections={buildOtherTraces()}
        >
          {item.itemType === "risk" && <RiskVersionCard version={item} />}

          <div className={classes.statusContainer}>
            <VersionSequenceWithDot
              to={{ status: item.currentStatus, identifier: item.currentVersion }}
              from={{ status: item.releaseStatus, identifier: item.releaseVersion }}
            />
          </div>
        </GraphCard>
        {!currentUser.readOnly && selected && (
          <>
            {(direction === 'left' || !direction) && (
              <AddLinkButton direction={'upstream'} onAddLinkClick={onAddLinkClick} item={item} />
            )}
            {(direction === 'right' || !direction) && (
              <AddLinkButton direction={'downstream'} onAddLinkClick={onAddLinkClick} item={item} />
            )}
          </>
        )}
      </div>
      {direction === 'left' && <Path />}
    </div>
  );
}

export default compose(
  WithCurrentUser,
  withAllItemTypeMetadata,
  withItemTypeMetadata,
)(ItemCard);
