import React, { useState, useContext } from 'react';

import { useMutation } from 'react-apollo';
import { Paper, Typography } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { LockedContext } from 'contexts/lockedContext';
import TextArea from 'components/TextArea';
import IconButton from 'components/IconButton';
import TagsControl from 'components/LongCardControls/BottomRowControls/TagsControl';
import CommentsControl from 'components/LongCardControls/BottomRowControls/CommentsControl';
import { Spacer } from 'components';
import TracingControl from 'components/LongCardControls/BottomRowControls/TracingControl';
import { useDebounce } from 'hooks/useDebounce';

import { useStyles } from './styles';
import { UPDATE_DEVICE_SAFETY_CHARACTERISTIC } from './gql';

interface Props {
  dsc: any;
}

const DeviceSafetyCharacteristicCard = (props: Props) => {
  const { dsc } = props;

  const { locked } = useContext(LockedContext);

  const [showTags, setShowTags] = useState(false);
  const [showTracing, setShowTracing] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showHelperText, setShowHelperText] = useState(true);

  const [update] = useMutation(UPDATE_DEVICE_SAFETY_CHARACTERISTIC);
  const updateDebounced = useDebounce(update);

  const helperToggle = () => {
    if (dsc.helpText) {
      return showHelperText ? (
        <IconButton tooltip="Hide Helper Text" Icon={Info} onClick={() => setShowHelperText(false)} />
      ) : (
        <IconButton tooltip="Show Helper Text" Icon={InfoOutlined} onClick={() => setShowHelperText(true)} />
      );
    } else {
      return undefined;
    }
  };

  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.card}>
      <Typography component="div" variant="button" className={classes.cardLabel}>
        <span>{`${dsc.item.customIdentifier}: ${dsc.question}`}</span>
        {helperToggle()}
      </Typography>
      <Typography variant={'caption'}>Global ID: {dsc.item.id}</Typography>
      <Spacer factor={1} />
      {showHelperText && (
        <div className={classes.helperText}>
          <Typography variant="caption">{dsc.helpText}</Typography>
        </div>
      )}
      <div className={classes.responseContainer}>
        <TextArea
          initialValue={dsc.response}
          multiline
          disabled={locked}
          placeholder="Enter response here"
          onTextChange={(change) =>
            updateDebounced({
              variables: {
                id: dsc.id,
                response: change,
              },
            })
          }
        />
      </div>
      <div className={classes.controls}>
        <TagsControl
          onClick={() => setShowTags(true)}
          onClose={() => setShowTags(false)}
          itemId={dsc.item.id}
          tags={dsc.item.tags}
          showPopup={showTags}
          queryToRefetch="deviceSafetyCharacteristicsReportQuery"
        />
        <TracingControl
          onClick={() => setShowTracing(!showTracing)}
          onClose={() => setShowTracing(false)}
          itemId={dsc.item.id}
          showPopup={showTracing}
          parentSetItemSlug="dsc-report"
        />
        <CommentsControl
          onClick={() => setShowComments(true)}
          onClose={() => setShowComments(false)}
          itemId={dsc.item.id}
          showPopup={showComments}
          commentCount={dsc.item.comments.length}
        />
      </div>
    </Paper>
  );
};

export default DeviceSafetyCharacteristicCard;
