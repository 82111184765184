import gql from 'graphql-tag';

export const GET_USER_ACTIVE_PERMISSION_GRANTS = gql`
  query activePermissionGrants($userId: ID!) {
    activePermissionGrants(userId: $userId) {
      id
      permissionType
      version {
        id
        title
        currentStatus {
          id
          name
        }
        approver {
          id
        }
        owner {
          id
        }
        assignedOwnerJobRole {
          id
          roleName
        }
        assignedApproverJobRole {
          id
          roleName
        }
        item {
          id
          customIdentifier
          itemType {
            id
            name
            displaySlug
            userCreatable
          }
        }
      }
    }
  }
`;
