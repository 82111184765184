import React from 'react';

import { Typography } from '@material-ui/core';

import VersionAndStatusDot from 'components/VersionAndStatusDot';

import { useStyles } from '../styles';

export type SingleSelectOptionProps = {
  item: any;
  showOptionVersionStatus?: boolean;
};

function SelectOption(props: SingleSelectOptionProps) {
  const classes = useStyles();

  const { item, showOptionVersionStatus } = props;

  return (
    <div className={classes.option}>
      <Typography variant="body2">
        {item.customIdentifier}: {item.currentVersion.title}
      </Typography>
      {showOptionVersionStatus && (
        <VersionAndStatusDot
          version={item.currentVersion.versionIdentifier}
          status={item.currentVersion.currentStatus.name}
        />
      )}
    </div>
  );
}

export default SelectOption;
