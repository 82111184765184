import React from 'react';

import { get } from 'lodash';

// this stuff is useful if these components are being reused outside of Risk Analysis
// import { Report } from '@material-ui/icons';

import { EnhancedAutocomplete } from 'components';

import { useUpdateRiskVersion } from './hooks';
import { useOptionsContext } from './OptionsContext';

interface Props {
  riskVersion: any;
  mitigated: boolean;
  className?: string;
}

export default function RiskHarm({ riskVersion, mitigated, className }: Props) {
  const updateField = useUpdateRiskVersion(riskVersion.id);
  const { harms } = useOptionsContext();

  const { locked } = riskVersion;

  const attrName = mitigated ? 'mitigatedHarm' : 'harm';
  const updateAttrName = `${attrName}Name`;

  const value = get(riskVersion, `${attrName}.name`);
  const options = harms.map((x) => x.name);

  const withNull = (x) => (x == null ? null : x);
  const update = (x) => updateField(updateAttrName)(withNull(x));

  return (
    <EnhancedAutocomplete
      className={className}
      selected={value}
      suggestions={options}
      onChange={update}
      loading={false}
      disabled={locked || (Boolean(riskVersion.finalRiskAcceptability) && mitigated)}
      readOnly={Boolean(value)}
    />
  );
}
