export default (theme) => ({
  container: {
    margin: 'inherit',
  },
  pageTitleInfoContainer: {
    width: '100%',
    position: 'sticky',
    top: 72,
    zIndex: 1099,
    background: theme.palette.background.default,
    transition: 'all 0.1s ease',
  },
  tableContainer: {
    width: '100%',
    margin: 'inherit',
    paddingTop: theme.spacing(5),
  },
  otherTableContainer: {
    maxWidth: 'unset',
  },
  agGridTableContainer: {
    '& .ag-header-cell-wrap-text .ag-header-cell-comp-wrapper': {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  },
  slimRow: {
    padding: '0 !important',
    margin: 0,
  },
  toolbar: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(2),
    minHeight: 'inherit',
    '& .MuiTextField-root': {
      width: 30,
      minWidth: 0,
      transition: ' all .5s',
      '& .MuiInputBase-adornedStart': {
        cursor: 'pointer',
      },
      '& .MuiInputAdornment-positionStart': {
        pointerEvents: 'none',
      },
      '& .MuiInputAdornment-positionEnd': {
        visibility: 'hidden',
        '& .Mui-disabled': {
          visibility: 'hidden',
        },
      },
      '&:focus-within': {
        width: 250,
        '& .MuiInputAdornment-positionEnd': {
          '&::placeholder-shown': {
            visibility: 'hidden',
          },
          visibility: 'visible',
        },
      },
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
  cell: {
    whiteSpace: 'nowrap',
    maxWidth: 150,
  },
  longTextCell: {
    height: 'auto',
    whiteSpace: 'normal',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  textField: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  idField: {
    minHeight: 0,
    overflow: 'visible',
  },
  itemTitle: {
    color: theme.palette.secondary.main,
  },
});
