import gql from 'graphql-tag';

export const BULK_ITEM_TRACES = gql`
  query BulkItemSearch($ids: [ID!]!) {
    search(ids: $ids) {
      itemType
      results {
        customIdentifier
        title
        upstreamItems {
          customIdentifier
          title
        }
        downstreamItems {
          customIdentifier
          title
        }
      }
    }
  }
`;
