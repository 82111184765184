import React, { useContext } from 'react';
import { LockedContext } from 'contexts/lockedContext';

import { CollapsableTextArea, Spacer, ToggleRichTextEditor, LabelText } from 'components';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import { Notes } from '@material-ui/icons';

import { TOGGLE_TEST_OVERVIEW } from './gql';
import { useMutation } from 'react-apollo';

interface Props {
  devicesTested: string;
  purpose: string;
  scope: string;
  testOverviewSummary: string;
  testBackground: string;
  testSubjects: string;
  versionId: string;
  richTextToggled: boolean;
  rolesAndResponsibilities: string;
}

function TestOverview(props: Props) {
  const { versionId } = props;
  const [toggleTestOverview] = useMutation(TOGGLE_TEST_OVERVIEW, {
    refetchQueries: ['DeviceVerificationVersionQuery'],
  });
  const { locked } = useContext(LockedContext);

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        {!locked && (
          <ToggleRichTextEditor
            richTextEditorVisible={props.richTextToggled}
            onConfirm={() => {
              toggleTestOverview({ variables: { id: versionId, toggle: !props.richTextToggled } }).catch((error) => {
                throw new Error(`Failed to toggle rich text editor: ${error.message}`);
              });
            }}
          />
        )}
      </div>
      <Spacer factor={1} />
      {props.richTextToggled ? (
        <>
          <LabelText labelText="Test Overview" Icon={Notes} />
          <Spacer factor={1} />
          <RichTextEditorContentSection
            locked={locked}
            initialValue={props.testOverviewSummary}
            fixedToolbarOffset={10}
            versionId={versionId}
            attrName="test_overview_summary"
            refetchQueries={[]}
            optionSet="large"
          />
        </>
      ) : (
        <>
          <CollapsableTextArea
            attrName="scope"
            id="device-verification-purpose-text-field"
            initialValue={props.scope}
            labelText="Scope"
            locked={locked}
            refetchQueries={['DeviceVerificationVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="purpose"
            id="device-verification-purpose-text-field"
            initialValue={props.purpose}
            labelText="Purpose"
            locked={locked}
            refetchQueries={['DeviceVerificationVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="devices_tested"
            id="device-verification-devices-tested-text-field"
            initialValue={props.devicesTested}
            labelText="Components / Devices Tested"
            locked={locked}
            refetchQueries={['DeviceVerificationVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="test_background"
            id="device-verification-test-background-text-field"
            initialValue={props.testBackground}
            labelText="Test Background"
            locked={locked}
            refetchQueries={['DeviceVerificationVersionQuery']}
            versionId={versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="roles_and_responsibilities"
            id="device-verification-test-subjects-text-field"
            initialValue={props.rolesAndResponsibilities}
            labelText="Roles and Responsibilities"
            locked={locked}
            refetchQueries={['DeviceVerificationVersionQuery']}
            versionId={versionId}
            rows={6}
          />
        </>
      )}
    </>
  );
}

export default TestOverview;
