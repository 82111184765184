import { t } from '@lingui/macro';
import Contacts from '@material-ui/icons/Contacts';
import Domain from '@material-ui/icons/Domain';
import School from '@material-ui/icons/School';
import CorporateFare from 'assets/icons/CorporateFare';
import DocumentScanner from 'assets/icons/DocumentScanner';
import Verified from 'assets/icons/Verified';

import ItemTypes from '../itemTypes';
import itemTypeToCategory from './itemTypeToCategory';

const categories = [
  {
    icon: CorporateFare,
    name: t`Management Responsibility`,
    slug: 'management-responsibility',
    children: [
      itemTypeToCategory(ItemTypes.OrganizationalChart),
      itemTypeToCategory(ItemTypes.Policy),
      itemTypeToCategory(ItemTypes.QualityManual),
      itemTypeToCategory(ItemTypes.QualityPlan),
    ],
  },
  {
    icon: DocumentScanner,
    name: t`Document Control`,
    slug: 'document-control',
    children: [
      itemTypeToCategory(ItemTypes.ChangeOrder),
      itemTypeToCategory(ItemTypes.StandardOperatingProcedure),
      itemTypeToCategory(ItemTypes.QualityPlan),
      itemTypeToCategory(ItemTypes.WorkInstruction),
      itemTypeToCategory(ItemTypes.Template),
      itemTypeToCategory(ItemTypes.Form),
      itemTypeToCategory(ItemTypes.FormRecord),
      { ...itemTypeToCategory(ItemTypes.MiscDocument), excludeFromCategoryIndex: true },
      { ...itemTypeToCategory(ItemTypes.MiscRecord), excludeFromCategoryIndex: true },
    ],
  },
  {
    icon: Verified,
    name: t`Quality Management`,
    slug: 'quality-management',
    children: [
      itemTypeToCategory(ItemTypes.Review),
      itemTypeToCategory(ItemTypes.CAPA),
      itemTypeToCategory(ItemTypes.Complaint),
    ],
  },
  {
    icon: School,
    name: t`Training Management`,
    slug: 'training-dashboard',
    isDashboard: true,
    children: [
      itemTypeToCategory(ItemTypes.JobDescription),
      itemTypeToCategory(ItemTypes.Course),
      itemTypeToCategory(ItemTypes.TrainingPlan),
      itemTypeToCategory(ItemTypes.TrainingRecord),
    ],
  },
  {
    icon: Domain,
    name: t`Supplier Management`,
    slug: 'supplier-management',
    isDashboard: true,
    children: [
      itemTypeToCategory(ItemTypes.Supplier),
      itemTypeToCategory(ItemTypes.SupplierQuestionnaire),
      itemTypeToCategory(ItemTypes.SupplierQuestionnaireRecord),
      itemTypeToCategory(ItemTypes.SupplierAuditPlan),
      itemTypeToCategory(ItemTypes.SupplierAuditReport),
      itemTypeToCategory(ItemTypes.QualityAgreement),
    ],
  },
];

const qualityManagementSystem = {
  id: 'qualityManagementSystem',
  label: t`Quality Management System`,
  shortLabel: t`QMS`,
  icon: Contacts,
  categories,
  colorKey: 'palette.appWindows.qms',
};

export default qualityManagementSystem;
