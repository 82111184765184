import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import FlipToFront from '@material-ui/icons/FlipToFront';

import slugs from '../../displaySlugs';
import { listFragment, docMetadata } from '../mixins/Document';

const itemType = 'template';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Template`,
  categoryLabel: t`Templates`,
  icon: FlipToFront,
  listViewQuery: gql`
    query templatesQuery {
      documents(itemType: "template") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
