import { toRenderProps } from 'utils';

import withDisabledCategories from './withDisabledCategories';
import withEnabledCategories from './withEnabledCategories';
import withActiveCategory from './withActiveCategory';

const WithDisabledCategories = toRenderProps(withDisabledCategories);
const WithEnabledCategories = toRenderProps(withEnabledCategories);

export {
  withActiveCategory,
  withDisabledCategories,
  WithDisabledCategories,
  withEnabledCategories,
  WithEnabledCategories,
};
