import React, { useMemo } from 'react';

import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ThumbUp from '@material-ui/icons/ThumbUp';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

import { useQuery } from 'react-apollo';

import Page from 'components/core/Page';
import SectionLabel from 'components/core/SectionLabel';
import { Spacer } from 'components';

import RisksAcceptability from './RiskAcceptability';
import RisksByMitigatedRPC from './RisksByMitgatedRPC';
import RPCCalculation from 'components/RPCCalculation';

import { useStyles } from './styles';

import { RISK_MANAGEMENT_DASHBOARD_QUERY } from './gql';

import { RiskDashboard } from './__generated__/RiskDashboard';
import { RISK_PRIORITY_CODES } from '../GeneralSettings/Settings/RiskManagement/gql';
import { riskPriorityCodes } from '../GeneralSettings/Settings/RiskManagement/__generated__/riskPriorityCodes';

function RiskManagementDashboard(_props: { path: string }) {
  const classes = useStyles();
  const { data, loading, error } = useQuery<RiskDashboard>(RISK_MANAGEMENT_DASHBOARD_QUERY);
  const { data: riskPriorityCodeData } = useQuery<riskPriorityCodes>(RISK_PRIORITY_CODES);

  const idToName = useMemo(() => {
    const map = {};
    if (
      riskPriorityCodeData &&
      riskPriorityCodeData.riskPriorityCodes &&
      riskPriorityCodeData.riskPriorityCodes.length
    ) {
      riskPriorityCodeData.riskPriorityCodes.forEach((code) => {
        map[code.id] = code.name;
      });
    }
    return map;
  }, [riskPriorityCodeData]);

  if (error) throw new Error(error.message);

  if (loading)
    return (
      <Box style={{ textAlign: 'center', padding: '25px' }}>
        <CircularProgress />
      </Box>
    );

  // @ts-ignore
  const { riskDashboard, riskMatrixEntries } = data;
  const { acceptable, unacceptable, intolerable, tolerable, cannotBeDetermined, alarp, totalRisk } = riskDashboard;

  return data ? (
    <div style={{ marginTop: -35 }}>
      <Page>
        <Grid item xs={12}>
          <div className={classes.header}>
            <ErrorIcon fontSize={'large'} className={classes.riskManagementIcon} />
            <Typography variant="h2" className={classes.headerTitle}>
              Risk Management Dashboard
            </Typography>
          </div>
          <Spacer factor={5} />
          <SectionLabel icon={ThumbUp} classes={{ container: classes.sectionLabelContainer }}>
            <Typography
              variant="h3"
              component="span"
              style={{ fontSize: '18px', fontWeight: 600, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
            >
              Risk Acceptability
            </Typography>
          </SectionLabel>
          <RisksAcceptability
            data={{
              totalRisks: totalRisk,
              unassignedToRiskAnalysis: riskDashboard.unassignedToRiskAnalysis,
              acceptable,
              unacceptable,
              undefined: riskDashboard.undefined,
            }}
          />
          <Spacer factor={5} />
          <SectionLabel icon={PriorityHigh} classes={{ container: classes.sectionLabelContainer }}>
            <Typography
              variant="h3"
              component="span"
              style={{ fontSize: '18px', fontWeight: 600, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
            >
              Risks Grouped by Mitigated Risk Priority Code
            </Typography>
          </SectionLabel>
          <RisksByMitigatedRPC
            data={{
              cannotBeDetermined,
              tolerable,
              tolerableName: idToName[1],
              asLowAsReasonablyPracticable: alarp,
              alarpName: idToName[2],
              intolerable,
              intolerableName: idToName[3],
            }}
          />
          <Spacer factor={5} />
          <SectionLabel icon={YoutubeSearchedForIcon} classes={{ container: classes.sectionLabelContainer }}>
            <Typography
              variant="h3"
              component="span"
              style={{ fontSize: '18px', fontWeight: 600, lineHeight: '25px', fontStyle: 'normal', color: '#6E7583' }}
            >
              Risk Priority Code Calculation Diagram
            </Typography>
          </SectionLabel>
          <RPCCalculation riskMatrixEntries={riskMatrixEntries} />
        </Grid>
      </Page>
    </div>
  ) : null;
}

export default RiskManagementDashboard;
