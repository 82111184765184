import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    restrictedAccess: {
      textAlign: 'center',
      transform: 'translate(-50%, 50%)',
      marginLeft: '50%',
    },
    icon: {
      width: '60px',
      height: '60px',
      color: 'darkslategray',
      marginBottom: '30px',
    },
    heading: {
      textAlign: 'center',
      marginBottom: '80px',
    },
    subHeading: {
      textAlign: 'left',
      marginBottom: '30px',
    },
    text: {
      textAlign: 'left',
    },
  }),
);
