import React, { ReactNode } from 'react';

import { Paper } from '@material-ui/core';

import Adornment from './Adornment';
import Heading from './Heading';
import { useStyles } from './styles';

interface Props {
  children: ReactNode;
  className?: string,
}

function Panel(props: Props) {
  const {
    className = '',
  } = props;
  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.container + ' ' + className}>
      {props.children}
    </Paper>
  );
}

Panel.Adornment = Adornment;
Panel.Heading = Heading;

export default Panel;
