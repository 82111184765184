import React, { ReactNode } from 'react';

import { Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import { LicenseTier } from '__generated__/globalTypes';
import ContactSupport from 'components/ContactSupport';
import Panel from 'components/core/Panel';
import Loading from 'components/Loading';
import { useHasNecessaryLicense } from 'hooks/useCompanyTier';

import { useStyles } from './styles';

interface Props {
  children: ReactNode;
  requiredTier: LicenseTier;
}

function LicenseLockedSection(props: Props) {
  const classes = useStyles();

  const { data, loading, error } = useHasNecessaryLicense(props.requiredTier);
  if (loading) return <Loading />;
  if (error) throw new Error(`Error loading license for company: ${error}`);

  return data.hasNecessaryLicense ? (
    <>{props.children}</>
  ) : (
    <Panel>
      <div className={classes.container}>
        <LockIcon className={classes.icon} />
        <Typography variant="h4" className={classes.title}>
          Feature Unavailable
        </Typography>
        <Typography variant="body1">
          This feature is available with an upgraded license. Please contact <ContactSupport /> to upgrade.
        </Typography>
      </div>
    </Panel>
  );
}

export default LicenseLockedSection;
