import React from 'react';
import { Trans } from '@lingui/macro';
import { useQuery } from 'react-apollo';
import { Grid } from '@material-ui/core';
import LocalOffer from '@material-ui/icons/LocalOffer';

import { ContentSection, Spacer, TextAreaField, PDFData } from 'components';
import { DocumentBuilder } from 'compositions';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';

import { versionQuery } from './gql';

const PayloadFields = ({ activeWorkspaceView, layoutClasses, versionId }) => {
  const { data, loading, error, refetch } = useQuery(versionQuery, {
    variables: { id: versionId },
    fetchPolicy: 'network-only',
  });
  if (!data || loading) return null;
  if (error) throw new Error(`Error loading Form Version ${versionId}\n${error.message}`);
  const versionData = data.formVersion;

  if (versionData.inputMethod === 'body')
    return (
      <>
        <Grid container xs={8}>
          <Grid item xs={8} className={layoutClasses.gridGutterRight}>
            <ContentSection
              fillHeight={false}
              LabelText={
                <>
                  <LocalOffer className={layoutClasses.icon} />
                  <Trans>Tag for Associated FRM-R</Trans>
                </>
              }
            >
              <Spacer factor={1} />
              <TextAreaField
                locked={versionData.locked}
                initialValue={versionData.formRecordTag}
                versionId={versionId}
                attrName={'form_record_tag'}
                rows={1}
                placeholder="Enter tag"
                refetchQueries={['FormVersion']}
              />
            </ContentSection>
            <Spacer factor={1} />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <DocumentBuilder
            activeWorkspaceView={activeWorkspaceView}
            itemType={'form'}
            layoutClasses={layoutClasses}
            versionData={versionData}
            richTextOptionSet={'large'}
            refetchQueries={['FormVersion', 'timelineQuery']}
          />
        </Grid>
      </>
    );

  return (
    <>
      <Grid container xs={8}>
        <Grid item xs={8} className={layoutClasses.gridGutterRight}>
          <ContentSection
            fillHeight={false}
            LabelText={
              <>
                <LocalOffer className={layoutClasses.icon} />
                <Trans>Tag for Associated FRM-R</Trans>
              </>
            }
          >
            <Spacer factor={1} />
            <TextAreaField
              locked={versionData.locked}
              initialValue={versionData.formRecordTag}
              versionId={versionId}
              attrName={'form_record_tag'}
              rows={1}
              placeholder="Enter tag"
              refetchQueries={['FormVersion']}
            />
          </ContentSection>
          <Spacer factor={1} />
        </Grid>
      </Grid>

      <Grid container xs={12} className={layoutClasses.grid}>
        <PDFData versionData={versionData} layoutClasses={layoutClasses} refetch={refetch} />
      </Grid>
    </>
  );
};

export default withWorkspaceViews()(PayloadFields);
