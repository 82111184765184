import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    course: {
      cursor: 'pointer',
    },
    fullHeight: {
      height: '100%',
    },
  }),
);
