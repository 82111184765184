import { createStyles, makeStyles } from '@material-ui/core';

export const useAutocompleteStyles = makeStyles((theme: any) =>
  createStyles({
    tag: {
      ...theme.typography.body2,
      // @ts-ignore
      background: theme.palette.common.tags.background,
      color: theme.palette.blueDark,
      opacity: '1 !important',
    },
    noOptions: {
      ...theme.typography.body2,
      padding: '0',
      fontWeight: 'bold',
    },
    root: {},
    inputRoot: {
      ...theme.typography.body2,
      // @ts-ignore
      borderColor: theme.palette.common.input.outline,
      flexWrap: 'nowrap',
    },
    option: {
      ...theme.typography.body2,
      paddingLeft: 0,
      '&:hover:': {
        background: 'inherit',
      },
      '&[data-focus="true"]': {
        background: 'inherit',
      },
      '&:active': {
        background: 'inherit',
      },
      '&[aria-selected="true"]': {
        background: 'inherit',
        // @ts-ignore
        color: theme.palette.action.disabled,
      },
    },
    popper: {
      borderTop: 'none',
      left: '-1px',
      border: '1px solid #ebeced',
      boxSizing: 'content-box',
      '& .MuiPaper-root': {
        padding: theme.spacing(2),
      },
    },
    listbox: {
      padding: '0',
      maxHeight: '250px',
    },
  }),
);
