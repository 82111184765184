import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { TRIO_FIELDS } from 'components/TableTrio/gql';
import { TaskQuery } from './__generated__/TaskQuery';

const GET_TASK_VERSION = gql`
  query TaskQuery($id: ID!) {
    taskVersion(id: $id) {
      id
      description
      title
      dueDate
      locked
      assignedPerson {
        id
        fullName
      }
      ${TRIO_FIELDS}
      unresolvedSuggestions
      taskVersionLinkedTasks {
        id
        linkedTask {
          id
          title
          item {
            id
            customIdentifier
          }
          assignedPerson {
            id
            fullName
          }
          currentStatus {
            id
            name
          }
        }
        relationship
      }
    }
    tasks {
      id
      customIdentifier
      currentVersion {
        id
        locked
        title
      }
    }
  }
`;

const UPDATE_TASK_VERSION = gql`
  mutation updateTaskVersion($id: ID!, $title: String, $description: String, $dueDate: String, $assignedPersonId: ID) {
    updateTaskVersion(
      id: $id
      title: $title
      description: $description
      dueDate: $dueDate
      assignedPersonId: $assignedPersonId
    ) {
      taskVersion {
        id
        description
        title
        dueDate
        assignedPerson {
          id
          fullName
        }
      }
    }
  }
`;

const LINK_TASK = gql`
  mutation linkTask($taskId: ID!, $linkedTaskId: ID!, $relationship: TasksRelationship!) {
    taskLink(taskId: $taskId, linkedTaskId: $linkedTaskId, relationship: $relationship) {
      taskLink {
        id
        linkedTask {
          id
          description
        }
        relationship
      }
    }
  }
`;

const DESTROY_TASK_LINK = gql`
  mutation destroyTaskLink($taskId: ID!, $linkedTaskId: ID!) {
    destroyTaskLink(taskId: $taskId, linkedTaskId: $linkedTaskId) {
      taskLink {
        id
      }
    }
  }
`;

export const useVersion = (id: string) => {
  return useQuery<TaskQuery>(GET_TASK_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
};

export function useUpdateTask() {
  return useMutation(UPDATE_TASK_VERSION, {
    fetchPolicy: 'no-cache',
    refetchQueries: ['TaskQuery'],
  });
}

export function useLinkTask() {
  return useMutation(LINK_TASK, {
    fetchPolicy: 'no-cache',
    refetchQueries: ['TaskQuery'],
  });
}

export function useDestroyTaskLink() {
  return useMutation(DESTROY_TASK_LINK, {
    fetchPolicy: 'no-cache',
    refetchQueries: ['TaskQuery'],
  });
}
