import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  children: ReactNode;
  layoutClasses: { [className: string]: string };
  tabs: ReactNode;
}

export default function TabContainer(props: Props) {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={props.layoutClasses.grid}>
      <div className={classes.tabs}>{props.tabs}</div>
      <div className={classes.container}>{props.children}</div>
    </Grid>
  );
}
