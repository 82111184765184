import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import Build from '@material-ui/icons/Build';

import { DocumentToolbar } from '../mixins/Document';

import slugs from '../../displaySlugs';

const itemType = 'deviceMasterRecord';

const listViewQuery = gql`
  query deviceMasterRecordsQuery {
    deviceMasterRecords {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      initialReleaseDate
      currentVersion {
        id
        title
        description
        body
        inputMethod
        currentStatus {
          id
          name
        }
      }
    }
  }
`;

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.description',
    headerName: 'Description',
    type: 'long_text',
  },
  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'initialReleaseDate',
    headerName: 'Initial Release Date',
    type: 'date',
  },
];

export default {
  categoryLabel: t`Device Master Record`,
  displaySlug: slugs[itemType],
  label: t`Device Master Record`,
  listViewQuery,
  itemType,
  icon: Build,
  listViewColumns,
  EffectiveViewOptions: DocumentToolbar,
};
