import React from 'react';
import { Trans } from '@lingui/macro';
import { DateTime } from 'luxon';

import { Link, Typography, withStyles } from '@material-ui/core';

import { Spacer, UserAvatar } from 'components';
import { itemLink } from 'components/MakeLink';
import styles from './styles';

const headingByItemType = {
  'form_record': 'Associated FRM',
  'verification_record': 'Associated Protocol',
  'validation_record': 'Associated Protocol',
  'deviation': 'Associated Record',
  'supplier_questionnaire_record': 'Associated Record',
  '...': 'Associated INS',
};

// not doing the "good" way, just

function RecordOfOrigin({ classes, currentVersion, itemTypeMetadata, ...props }) {
  const { formCustomIdentifier, formTitle, formVersion } = currentVersion;

  const heading = headingByItemType[itemTypeMetadata.name] || headingByItemType['...'];
  const href = currentVersion.linkedForm && itemLink(currentVersion.linkedForm.item);

  // can be removed when somebody feels like retesting all relevant cases by simply having .linked_form on all implicated versions
  const parentDisplaySlug = itemTypeMetadata.displaySlug.replace('-record', '');
  const oldHref=`/category/${parentDisplaySlug}/${currentVersion.formId}`;

  return (
    <>
      <div className={classes.heading}>
        <Typography variant="h3" align={'center'}>
          <Trans>Record Origin</Trans>
        </Typography>
      </div>
      <Spacer factor={3} />

      <Typography className={classes.sectionHeading}>
        <Trans>Created On</Trans>
      </Typography>
      <Spacer factor={1} />
      <Typography variant="body2">
        {DateTime.fromISO(currentVersion.createdAt).toLocaleString(DateTime.DATETIME_FULL)}
      </Typography>
      <Spacer factor={3} />

      <Typography className={classes.sectionHeading}>
        <Trans>Creator</Trans>
      </Typography>
      <Spacer factor={1} />
      <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <UserAvatar diameter={24} userId={currentVersion.creator.id} />
        <Typography variant="body2" className={classes.padLeftUnit}>
          {currentVersion.creator.fullName}
        </Typography>
      </span>
      <Spacer factor={3} />

      <Typography className={classes.sectionHeading}>{heading}</Typography>
      <Spacer factor={1} />
      <Typography variant="body2">
        <Link
          href={href || oldHref}
          target="_blank"
          rel="noopener"
          underline="hover"
          variant="body2"
          color="inherit"
        >
          {formCustomIdentifier}: {formTitle} {formVersion}
        </Link>
      </Typography>
    </>
  );
}

export default withStyles(styles)(RecordOfOrigin);
