import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    uploadInfoLabel: {
      marginTop: 5,
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);
