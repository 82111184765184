import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import Info from '@material-ui/icons/Info';

import slugs from '../../displaySlugs';

import PayloadFields from './PayloadFields';

const itemType = 'riskNote';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query riskNotesQuery {
    riskNotes {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      tags
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      currentVersion {
        id
        title
        createdAt
        currentStatusName
        versionIdentifier
        signingOwner {
          id
          firstName
          lastName
        }
        signingApprover {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const versionFields = `notes`;

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Risk Note`,
  categoryLabel: t`Risk Notes`,
  icon: Info,
  PayloadFields,
  versionFields,
  listViewColumns,
  listViewQuery,
};
