import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      border: 'solid 2px #ebeced',
      borderRadius: '3px',
    },
  }),
);
