import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      boxSizing: 'border-box',
      height: theme.spacing(6.5),
      border: 'solid 2px #ebeced',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 3,
      justifyContent: 'space-between',
    },
    index: {
      width: theme.spacing(8),
      padding: `0 ${theme.spacing(2)}px`,
    },
    itemTitle: {
      marginLeft: theme.spacing(1),
    },
    content: {
      flex: 1,
    },
    selectedBorder: {
      borderColor: theme.palette.primary.main,
    },
    deleteIcon: {
      margin: `0 ${theme.spacing(2.25)}px`,
      cursor: 'pointer',
      width: 20,
      height: 20,
    },
    text: {
      cursor: 'text',
    },
    linkIcon: {
      width: 18,
      height: 18,
      marginRight: 11,
    },
  }),
);
