import React, { ReactNode, ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  labelText: string;
  Adornment?: ReactNode;
  Icon?: ComponentType<SvgIconProps>;
}

function LabelText(props: Props) {
  const classes = useStyles();
  const Icon = props.Icon;
  return (
    <Typography component="div" variant="button" className={classes.label}>
      <span>
        {Icon && <Icon className={classes.icon} />}
        {props.labelText}
      </span>
      {props.Adornment && <span>{props.Adornment}</span>}
    </Typography>
  );
}

export default LabelText;
