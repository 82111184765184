import { itemTypeFields } from 'utils/gql/metadataQueries';
import gql from 'graphql-tag';

export const metadataQuery = gql`
  query VERandVERTS {
    itemTypes(names: ["device_verification_test_step"]) {
      ...itemTypeFields
    }
  }
  ${itemTypeFields}
`;

export const updateMember = gql`
  mutation UpdateVERTSMember($memberId: ID!, $parentVersionId: ID!, $position: Int!) {
    updateCollectionMember(memberId: $memberId, parentVersionId: $parentVersionId, position: $position) {
      member {
        id
        position
      }
    }
  }
`;

export const addVerts = gql`
  mutation AddVERTSToDeviceVerificationVersion($deviceVerificationVersionId: ID!, $title: String!) {
    addVertsToDeviceVerificationVersion(deviceVerificationVersionId: $deviceVerificationVersionId, title: $title) {
      item {
        id
        currentVersion {
          id
        }
      }
    }
  }
`;
