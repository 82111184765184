import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    header: {
      ...theme.typography.overline,
    },
  }),
);
