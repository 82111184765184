import React, { useState } from 'react';

import FileUpload from 'components/FileUpload';
import fileNameFromURL from 'utils/fileNameFromURL';
import { CircularProgress } from '@material-ui/core';

function PreviewControl(props: {
  versionId: string;
  previewUrl?: string | null;
  attachmentId?: string;
  classes;
  locked: boolean;
  updateAttachment: (id: string, url: string) => Promise<any>;
}) {
  const [loading, setLoading] = useState(false);

  return (
    <div className={props.classes.attachmentInput}>
      <span
        style={{
          alignSelf: 'center',
          color: props.previewUrl ? 'inherit' : 'rgba(0, 0, 0, 0.2)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.attachmentId && props.previewUrl
          ? fileNameFromURL(props.previewUrl)
          : 'Attach File'}
      </span>
      {!loading && !props.locked && (
        <FileUpload
          disabled={!Boolean(props.attachmentId)}
          onUploadStart={() => setLoading(true)}
          onUploadSuccess={(fileUrl: string) => {
            if (!props.attachmentId) return;
            const attachmentURL = decodeURIComponent(fileUrl);
            props.updateAttachment(props.attachmentId, attachmentURL).then(
              () => setLoading(false),
              (e) => {
                throw new Error(
                  `Error updating design output attachment ${
                    props.attachmentId
                  }\n${e}`,
                );
              },
            );
          }}
        />
      )}
      {loading && <CircularProgress size={24} />}
    </div>
  );
}

export default PreviewControl;
