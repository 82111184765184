import React from 'react';
import { Trans } from '@lingui/macro';

import { RetirementStatus } from './RetirementStatus';
import { buildActionMessage } from './workflowTransitionsUtil';
import { CertifiedSignature, RejectReason, YesOrNo } from 'compositions/TimelineGroup/steps/views';

export default {
  initialStep: 'retireOrRejectWithoutCO',
  internalSteps: {
    retireOrRejectWithoutCO: {
      component: (props) => (
        <YesOrNo
          prompt={buildActionMessage('retire', props.currentVersion, props.itemCustomIdentifier)}
          yesLabel="Retire"
          noLabel="Reject"
          {...props}
        />
      ),
      getNextStep: (retire) => (retire ? 'retireSignature' : 'rejectReason'),
    },
    rejectReason: {
      component: (props) => {
        const heading = `As the ${props.currentVersion.assignedApproverJobRole.formattedRoleName}, I reject ${
          props.itemCustomIdentifier
        }.`;
        return <RejectReason heading={heading} {...props} />;
      },
      getNextStep: (_) => 'complete',
    },
    retireSignature: {
      component: (props) => {
        const heading = <Trans>Retirement</Trans>;
        const dialog = `As the ${
          props.currentVersion.assignedApproverJobRole.formattedRoleName
        }, I approve retirement of ${props.itemCustomIdentifier}.`;

        return <CertifiedSignature heading={heading} dialog={dialog} {...props} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: ({ retireOrRejectWithoutCO, retireSignature, rejectReason }) => {
    if (retireOrRejectWithoutCO) {
      return {
        toStatus: RetirementStatus.Retired,
        ...retireSignature,
      };
    } else {
      return {
        toStatus: RetirementStatus.RetirementRejected,
        ...rejectReason.signature,
        reasonForRejection: rejectReason.reason,
      };
    }
  },
};
