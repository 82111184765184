import React from 'react';
import get from 'lodash/get';
import { Grid, withStyles } from '@material-ui/core';
import { useQuery } from 'react-apollo';

import { Blur, Breadcrumbs, ItemTitle } from 'components';
import ContextDrawer from 'compositions/ContextDrawer';
import EffectiveViewData from 'compositions/EffectiveViewData';
import styles from 'compositions/EffectiveViewData/styles';
import NoReleaseModal from 'components/NoReleaseModal';

import CreateFormRecord from 'compositions/CreateFormRecord';
import { effectiveViewQuery } from './gql';
import PayloadFields from './PayloadFields';
import PrintViewMenu from 'components/PrintViewMenu';

function EffectiveView({ classes, itemId }) {
  const { data, loading, error } = useQuery(effectiveViewQuery, {
    fetchPolicy: 'network-only',
    variables: { itemId, itemType: 'form' },
  });
  if (loading || !data) return null;
  if (error) throw new Error(`${error}`);
  const itemRecord = data.item;
  const currentUser = itemRecord.currentUser;

  const { currentRelease } = itemRecord;
  const itemTitle = get(currentRelease, 'title', '');
  const enabled = Boolean(currentRelease);

  return (
    <Grid container data-testid="item" spacing={3} className={classes.container}>
      <Grid item xs={12} className={classes.grid}>
        <Breadcrumbs categorySlug={'forms'} itemTitle={`${itemRecord.customIdentifier}: ${itemTitle}`} />
        <Grid container item xs={8} justify="space-between">
          <ItemTitle
            itemTitle={itemTitle}
            titlePrefix={`${itemRecord.customIdentifier}`}
            versionId={get(currentRelease, 'id', '')}
            editable={false}
          />
          {currentRelease && (
            <CreateFormRecord
              formIdentifier={itemRecord.customIdentifier}
              formVersionId={currentRelease.id}
              refetchQueries={['formsQuery']}
              currentUser={currentUser}
              recordItemType={'formRecord'}
            />
          )}
          <PrintViewMenu versionId={get(currentRelease, 'id')} />
        </Grid>
      </Grid>
      <EffectiveViewData itemType={'form'} itemId={itemId} blur={!enabled} displayHistoryTable />

      <Blur blur={!enabled} style={{ width: '100%', display: 'flex' }}>
        <PayloadFields
          layoutClasses={classes}
          versionId={get(currentRelease, 'id')}
          displayOnly
          itemRecord={itemRecord}
        />
      </Blur>

      {enabled && <ContextDrawer itemId={itemId} itemType={'form'} />}

      <NoReleaseModal open={!enabled} />
    </Grid>
  );
}

export default withStyles(styles)(EffectiveView);
