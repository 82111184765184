import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './expand.svg';

function Expand(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" children={<path />} component={(svgProps) => <Icon {...svgProps} />} />
  );
}

export default Expand;
