import { makeStyles } from '@material-ui/styles';

const chip = {
  color: '#fff',
  width: '90%',
  borderRadius: 6,
  justifyContent: 'initial',
};
export default makeStyles((theme: any) => ({
  hoverPointer: { '&:hover': { cursor: 'pointer' } },
  disabled: { '&:hover': { cursor: 'not-allowed' }, color: 'rgba(0, 0, 0, 0.33)' },
  menuContainer: {
    width: 125,
    padding: 0,
  },
  retireChip: {
    backgroundColor: theme.palette.common.obsolete.main,
    ...chip,
  },
  inactivateChip: {
    backgroundColor: theme.palette.common.obsolete.main,
    opacity: 0.5,
    ...chip,
  },
  activateChip: {
    backgroundColor: theme.palette.common.effective.main,
    ...chip,
  },
  planningChip: {
    backgroundColor: theme.palette.common.draft.main,
    ...chip,
  },
}));
