import gql from 'graphql-tag';

export function optionsQueryFor(itemTypeName) {
  switch (itemTypeName) {
    case 'requirements_set':
      return {
        query: gql`
          query REQSOptions($status: String) {
            requirementsSets(status: $status) {
              id
              customIdentifier
              currentRelease {
                currentStatusName
              }
              currentVersion {
                id
                title
              }
            }
          }
        `,
        key: 'requirementsSets',
      };
    case 'user_needs_set':
      return {
        query: gql`
          query UNDSOptions($status: String) {
            userNeedsSets(status: $status) {
              id
              customIdentifier
              currentRelease {
                currentStatusName
              }
              currentVersion {
                id
                title
              }
            }
          }
        `,
        key: 'userNeedsSets',
      };
    case 'risk_analysis':
      return {
        query: gql`
          query RiskAnalysisOptions($status: String) {
            riskAnalyses(status: $status) {
              id
              customIdentifier
              currentRelease {
                currentStatus {
                  id
                  name
                }
              }
              currentVersion {
                id
                title
              }
            }
          }
        `,
        key: 'riskAnalyses',
      };
    default:
      throw new Error(`No set options defined found for ${itemTypeName}`);
  }
}

export function moveOrAddToSetMutationFor(itemTypeName) {
  switch (itemTypeName) {
    case 'requirement':
      return gql`
        mutation MoveOrAddREQToSet($setItemVersionId: ID!, $setMemberVersionId: ID!) {
          moveRequirementsSetMember(
            requirementsSetVersionId: $setItemVersionId
            requirementVersionId: $setMemberVersionId
          ) {
            member {
              id
              version {
                id
                title
                setMember {
                  id
                }
              }
              setItemVersion {
                id
                currentStatusName
                versionIdentifier
              }
            }
          }
        }
      `;
    case 'user_need':
      return gql`
        mutation MoveOrAddUNDToSet($setItemVersionId: ID!, $setMemberVersionId: ID!) {
          moveUserNeedsSetMember(userNeedsSetVersionId: $setItemVersionId, userNeedVersionId: $setMemberVersionId) {
            member {
              id
              version {
                id
                title
                setMember {
                  id
                }
              }
              setItemVersion {
                id
                currentStatusName
                versionIdentifier
              }
            }
          }
        }
      `;
    case 'risk':
      return gql`
        mutation LinkExistingRisksToRiskAnalysis($setItemVersionId: ID!, $setMemberVersionId: [ID!]!) {
          linkExistingRisksToRiskAnalysis(
            input: { riskAnalysisVersionId: $setItemVersionId, linkedRiskVersionIds: $setMemberVersionId }
          ) {
            success
          }
        }
      `;
    default:
      throw new Error(`No mutation defined for ${itemTypeName}`);
  }
}
