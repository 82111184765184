import React from 'react';

import { Grid, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { WorkflowIcon } from 'components';

import { useStyles } from './../styles';

interface StatusCardProps {
    status: string;
    labelText: string;
    itemsNumber: number;
    tooltipTitle?: string;
}

const StatusCard = (props: StatusCardProps) => {
    const classes = useStyles();

    const { status, labelText, itemsNumber, tooltipTitle } = props;

    return (
        <Grid xs={4} item>
            <div className={classes.statusCardContainer}>
                {(tooltipTitle && (
                    <div className={classes.infoIconContainer}>
                        <Tooltip
                            title={tooltipTitle}
                        >
                            <InfoIcon />
                        </Tooltip>
                    </div>
                ))}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 16 }}>
                    <WorkflowIcon type={status} completed size="small" />
                    <Typography style={{ marginLeft: 8 }}>{labelText}</Typography>
                </div>
                <Typography style={{ textAlign: 'center', marginTop: 14 }}>{itemsNumber}</Typography>
            </div>
        </Grid>
    );
};

export default StatusCard;
