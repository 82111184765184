import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { combineReducers } from 'redux';

import workspaceViewsSubscriber from '../redux/workspaceViews/subscriber';
import workspaceViewsReducer from '../redux/workspaceViews/reducer';

function ReduxProvider({ children }) {
  const store = createStore(
    combineReducers({
      workspaceViews: workspaceViewsReducer,
    }),
    {},
  );
  workspaceViewsSubscriber(store);

  return <Provider store={store}>{children}</Provider>;
}

export default ReduxProvider;
