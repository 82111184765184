import { ItemTypeDefinition } from '../index';
import { t } from '@lingui/macro';
import Assignment from '@material-ui/icons/Assignment';
import Views from 'workspaces/views';
import slugs from '../../displaySlugs';
import { LIST_COURSES } from './gql';
import ListPage from './ListPage';

export default {
  displaySlug: slugs['course'],
  itemType: 'course',
  label: t`Course`,
  categoryLabel: t`Courses`,
  icon: Assignment,
  // Even though we have our own ListPage we must specify this because we allow for item creation on the list page
  // and, the item creation component depends on having a listViewQuery!
  // https://github.com/neMedIO/qms/blob/51ec98c03977f5819b74eea65ef5b33bdf9b8c44/client/src/compositions/NewItem/ItemTypeSelector/index.js#L229
  // Without it, the new item is not appended to the table
  listViewQuery: LIST_COURSES,
  ListPage,
  listWorkspace: [Views.Table, Views.LinksGraph],
  recordWorkspace: [Views.Builder, Views.Effective, Views.Versions],
} as ItemTypeDefinition;
