import React from 'react';

import { Grid } from '@material-ui/core';

import get from 'lodash/get';

import { Breadcrumbs, ItemTitle, Loading } from 'components';
import Page from 'components/core/Page';
import AgGridTable from 'components/AgGridTable';

import { regularTextHeaderStyle, RegularTextCell } from 'components/TableView/Fields/RegularText';
import { VersionCell } from 'components/VersionTables';
import MakeLink from 'components/MakeLink';
import { isColumnGroupedBy } from 'utils/agGridHelpers';

import { TrainingCategory } from '__generated__/globalTypes';
import { useTrainingRecordSearch } from './gql';
import { useStyles } from './styles';

// warning: reusable stuff lies ahead
//
const userLink = (row) => `/category/employees/${row.id}`;
//
// your are leaving reusable stuff

const identifierSort = (a: string[], b: string[]) => {
  if (a[0] > b[0]) {
    return 1;
  } else if (a[0] < b[0]) {
    return -1;
  } else {
    if (parseInt(a[1]) > parseInt(b[1])) {
      return 1;
    } else if (parseInt(a[1]) < parseInt(b[1])) {
      return -1;
    } else {
      return 0;
    }
  }
};

const qDocsSort = (rowA, rowB, _row, direction) => {
  const aValue =
    rowA.currentVersion.category === TrainingCategory.READ_AND_UNDERSTAND
      ? rowA.qdocNames
      : rowA.qdocs[0] && rowA.qdocs[0].customIdentifier;
  const bValue =
    rowB.currentVersion.category === TrainingCategory.READ_AND_UNDERSTAND
      ? rowB.qdocNames
      : rowB.qdocs[0] && rowB.qdocs[0].customIdentifier;

  if (!aValue) {
    return direction === 'desc' ? -1 : 1;
  } else if (!bValue) {
    return direction === 'desc' ? 1 : -1;
  } else {
    const aSplitted = aValue.split(/([0-9]*)$/);
    const bSplitted = bValue.split(/([0-9]*)$/);
    return identifierSort(aSplitted, bSplitted);
  }
};

const courseSort = (rowA, rowB, _row, direction) => {
  const aSplitted = rowA.courseName && rowA.courseName.split(/([0-9]*)$/);
  const bSplitted = rowB.courseName && rowB.courseName.split(/([0-9]*)$/);
  if (!aSplitted) {
    return direction === 'desc' ? -1 : 1;
  } else if (!bSplitted) {
    return direction === 'desc' ? 1 : -1;
  } else {
    return identifierSort(aSplitted, bSplitted);
  }
};

const columns = [
  {
    field: 'currentVersion.user.firstName',
    headerName: 'First Name',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.currentVersion.user.firstName;
    },
    render: function(row) {
      if (isColumnGroupedBy(row, 'currentVersion.user.firstName')) {
        return <RegularTextCell data={row.value} />;
      }
      return (
        <MakeLink href={userLink(row.data.currentVersion.user)}>
          <RegularTextCell data={get(row.data, 'currentVersion.user.firstName')} />
        </MakeLink>
      );
    },
  },
  {
    field: 'currentVersion.user.lastName',
    headerName: 'Last Name',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.currentVersion.user.lastName;
    },
    render: function(row) {
      if (isColumnGroupedBy(row, 'currentVersion.user.lastName')) {
        return <RegularTextCell data={row.value} />;
      }
      return (
        <MakeLink href={userLink(row.data.currentVersion.user)}>
          <RegularTextCell data={get(row.data, 'currentVersion.user.lastName')} />
        </MakeLink>
      );
    },
  },
  {
    field: 'userJobRoles',
    headerName: 'Role',
    enableRowGroup: true,
    type: 'custom',
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.userJobRoles;
    },
    render: function(row) {
      if (isColumnGroupedBy(row, 'userJobRoles')) {
        return row.value;
      }
      return (
        <MakeLink href={userLink(row.value.currentVersion.user)}>
          <RegularTextCell data={get(row.value, 'userJobRoles')} />
        </MakeLink>
      );
    },
  },
  {
    field: 'customIdentifier',
    headerName: 'Training Record',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.customIdentifier;
    },
    render: function(row) {
      if (isColumnGroupedBy(row, 'customIdentifier')) {
        return row.value;
      }
      return (
        <MakeLink item={row.data}>
          <RegularTextCell data={get(row.value, 'customIdentifier')} />
        </MakeLink>
      );
    },
  },

  {
    field: 'currentVersion.currentStatus.name',
    headerName: 'Training Status',
    type: 'status',
  },

  {
    field: 'qdocNames',
    headerName: 'Quality Document',
    sorting: true,
    type: 'custom',
    comparator: qDocsSort,
    headerStyle: regularTextHeaderStyle,
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.qdocNames;
    },
    render: function(row) {
      if (isColumnGroupedBy(row, 'qdocNames')) {
        return row.value;
      }
      return (
        <RegularTextCell
          data={row.data.qdocColumns.map((x) => (
            <MakeLink item={x}>
              <div>{x.customIdentifier}</div>
            </MakeLink>
          ))}
        />
      );
    },
  },

  {
    field: 'courseVersionColumn.item.customIdentifier',
    headerName: 'Course',
    type: 'custom',
    sorting: true,
    headerStyle: regularTextHeaderStyle,
    comparator: courseSort,
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return row.value.courseVersionColumn.item.customIdentifier;
    },
    render: function(row) {
      if (isColumnGroupedBy(row, 'courseVersionColumn.item.customIdentifier')) {
        return <RegularTextCell data={row.value} />;
      }
      const item = get(row.value, 'courseVersionColumn.item', null);
      if (item === null) {
        return <RegularTextCell data="n/a" />;
      }
      return (
        <MakeLink params={{ view: 'Effective' }} item={item}>
          <RegularTextCell data={get(row.data, 'courseVersionColumn.item.customIdentifier')} />
        </MakeLink>
      );
    },
  },
  {
    field: 'courseVersionColumn',
    headerName: 'Course Version',
    type: 'custom',
    enableRowGroup: true,
    valueGetter: (row) => {
      return row.data;
    },
    keyCreator: (row) => {
      return JSON.stringify({
        versionIdentifier: row.value.courseVersionColumn.versionIdentifier,
        currentStatusName: row.value.courseVersionColumn.currentStatus.name,
      });
    },
    render: function(row) {
      let versionIdentifier;
      let currentStatusName;
      if (isColumnGroupedBy(row, 'courseVersionColumn')) {
        versionIdentifier = JSON.parse(row.value).versionIdentifier;
        currentStatusName = JSON.parse(row.value).currentStatusName;
      } else {
        if (row.value.courseVersionColumn) {
          versionIdentifier = row.value.courseVersionColumn.versionIdentifier;
          currentStatusName = row.value.courseVersionColumn.currentStatus.name;
        } else {
          versionIdentifier = null;
          currentStatusName = null;
        }
      }
      if (versionIdentifier === null || currentStatusName === null) {
        return <RegularTextCell data="n/a" />;
      }
      return <VersionCell version={versionIdentifier} status={currentStatusName} />;
    },
  },

  {
    field: 'currentVersion.dueDate',
    headerName: 'Due Date',
    type: 'date',
  },
  {
    field: 'currentVersion.completionDate',
    headerName: 'Completion Date',
    type: 'date',
  },
];

function FilteredTableView(_p: any) {
  const classes = useStyles();
  const userIds = [];
  const { data, loading, error } = useTrainingRecordSearch({ userIds });

  if (loading) return <Loading />;
  if (error) throw new Error(`Error loading table: ${error.message}`);

  let items = data.trainingRecordSearch;

  items.forEach((x) => {
    if (x.qdocColumns) return;

    x.qdocColumns = get(x, 'currentVersion.source.qualityDocumentVersions', []).map((x) => x.item);
    x.courseVersionColumn = get(x, 'currentVersion.source', null);

    if (x.courseVersionColumn && x.courseVersionColumn.item.itemType.name !== 'course') {
      x.qdocColumns = [x.courseVersionColumn.item];
      x.courseVersionColumn = null;
    }

    x.qdocNames = x.qdocColumns.map((x) => x.customIdentifier).join();
    x.userJobRoles = x.currentVersion.user.jobRoles.map((x) => x.formattedRoleName).join(', ');

    // so this and sorting can be merged in any order without conflicts and delay
    x.qdocs = x.qdocColumns;
    x.courseName = get(x, 'courseVersionColumn.item.customIdentifier', '');
  });

  return (
    <Page>
      <Grid item xs={12}>
        <Breadcrumbs categorySlug="training-reports" />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.table}>
          <AgGridTable
            columns={columns}
            data={items}
            TableTitle={() => (
              <ItemTitle itemTitle="Training Report" editable={false} classes={{ typography: classes.itemTitle }} />
            )}
          />
        </div>
      </Grid>
    </Page>
  );
}

export default FilteredTableView;
