import { Grid, Tooltip } from '@material-ui/core';
import { InfoOutlined, People, AttachFile, Filter3 } from '@material-ui/icons';

import { useMutation } from 'react-apollo';

import { SelectUserField } from 'components';
import AttachmentDownload from 'components/AttachmentDownload';
import Panel from 'components/core/Panel';
import { LockedContext } from 'contexts/lockedContext';
import React, { useContext } from 'react';
import AttachmentControl from 'components/AttachmentsSection/AttachmentControl';
import SectionLabel from 'components/core/SectionLabel';
import { TrainingFormat } from '__generated__/globalTypes';
import { ADD_TRAINING_RECORD_ATTACHMENT } from './gql';

interface Props {
  assessmentGrader;
  assessmentUrl?: string;
  trainingFormat: string | null;
  uploadedRecord?;
  versionId: string;
}

function TrainingAssessment(props: Props) {
  const { locked } = useContext(LockedContext);

  const [updateAttachment] = useMutation(ADD_TRAINING_RECORD_ATTACHMENT, {
    refetchQueries: ['trainingRecordVersionQuery'],
  });

  const handleAttachmentChange = (url: string | null) =>
    updateAttachment({
      variables: {
        versionId: props.versionId,
        attachment: url,
      },
    });

  return (
    <>
      <SectionLabel icon={Filter3}>
        Training Assessments
        <SectionLabel.HelperText>
          {props.trainingFormat === TrainingFormat.INDEPENDENT_STUDY ? (
            <>
              There is no additional assessment required for Read & Understand training. When you have reviewed all
              training materials, you may move this training record to complete.
            </>
          ) : (
            <>
              Please download the associated assesment document, make a copy, and upload your completed assessment to
              complete training. Once you have uploaded your assessment, you may transition this training to completed.
            </>
          )}
        </SectionLabel.HelperText>
      </SectionLabel>

      {props.trainingFormat !== TrainingFormat.INDEPENDENT_STUDY && (
        <Panel>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Panel.Heading icon={AttachFile}>Training Assessment</Panel.Heading>
              {props.assessmentUrl && <AttachmentDownload url={props.assessmentUrl} />}
              <Panel.Heading icon={AttachFile}>Completed Assessment</Panel.Heading>
              <AttachmentControl
                versionId={props.versionId}
                locked={locked}
                attachment={props.uploadedRecord}
                updateAttachment={(_, url) => handleAttachmentChange(url)}
                createAttachment={(_, url) => handleAttachmentChange(url)}
                destroyAttachment={() => handleAttachmentChange(null)}
                singleAttachment
                downloadable
              />
            </Grid>
            <Grid item xs={6}>
              <Panel.Heading icon={People}>
                Assessment Grader
                <Panel.Adornment>
                  <Tooltip title="This is the person who will be responsible for signing off on training records associated with this course.">
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                </Panel.Adornment>
              </Panel.Heading>
              <SelectUserField inEditingMode={false} currentSelectedUser={props.assessmentGrader} />
            </Grid>
          </Grid>
        </Panel>
      )}
    </>
  );
}

export default TrainingAssessment;
