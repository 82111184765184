import React from 'react';

import { Box, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import Panel from 'components/core/Panel';

import { InfoListItem, InfoListProps } from './types';
import { styles } from './styles';

const InfoList: React.FC<InfoListProps> = (props) => {
  const { headerText, data, Icon, children, classes } = props;

  const renderList = () => {
    const items = data.map((item: InfoListItem) => (
      <li className={classes.row}>
        <Typography variant={'button'}>
          {item.title}: <Typography variant={'caption'}>{item.value}</Typography>
        </Typography>
      </li>
    ));

    return <ul className={classes.detailsList}>{items}</ul>;
  };
  return (
    <Panel className={classes.autofill}>
      <Box p={2}>
        <Typography variant={'h3'}>
          {Icon && <Icon color="primary" className={classes.icon} />} {headerText}
        </Typography>
        {data && <Box p={2}>{renderList()}</Box>}
        {children && children}
      </Box>
    </Panel>
  );
};

export default withStyles(styles)(InfoList);
