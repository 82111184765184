import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_theme) =>
  createStyles({
    mitigatedControl: {
      position: 'relative',
      marginLeft: '8px',
    },
    border: {
      width: '5px',
      height: '100%',
      display: 'block',
      position: 'absolute',
      left: '-8px',
      borderRadius: '3px',
      background: '#33BCB4',
    },
  }),
);

const MitigatedField = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.mitigatedControl}>
      <div className={classes.border} />
      {props.children}
    </div>
  );
};

export default MitigatedField;
