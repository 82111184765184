import React from 'react';

import { MenuItem, Select, Typography } from '@material-ui/core';

import { RiskDashboard_riskMatrixEntries as RPCMatrixEntry } from 'pages/RiskManagementDashboard/__generated__/RiskDashboard';
import { riskPriorityCodes_riskPriorityCodes } from '../../pages/GeneralSettings/Settings/RiskManagement/__generated__/riskPriorityCodes';
import { useStyles } from './styles';

interface Props {
  matrixValue: RPCMatrixEntry;
  options?: riskPriorityCodes_riskPriorityCodes[];
  onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

const EditableMatrixCell: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { matrixValue, options, onChange } = props;

  return (
    <div className={classes.matrixCell}>
      <Select
        name={matrixValue.id}
        variant="outlined"
        defaultValue={matrixValue.riskPriorityCode.id}
        className={classes.editableMatrixCellSelect}
        fullWidth
        onChange={onChange}
      >
        {options &&
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <div className={classes.editableMatrixCellContent}>
                <div className={classes.matrixCellColorItem} style={{ backgroundColor: option.color }} />
                <Typography variant="subtitle1">{option.name}</Typography>
              </div>
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export default EditableMatrixCell;
