import React, { useCallback, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import { navigate } from '@reach/router';

import { Typography } from '@material-ui/core';
import { FilterList, TableChart, ListAlt } from '@material-ui/icons';

import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, SuppressKeyboardEventParams } from 'ag-grid-enterprise';
import { GridOptions, RowGroupingDisplayType } from 'ag-grid-community/dist/lib/entities/gridOptions';

import MakeLink from 'components/MakeLink';
import { FieldCell } from 'components/TableView/Fields';
import { isColumnGroupedBy, sortIds, sortVersions } from 'utils/agGridHelpers';
import withAllUsers from 'compositions/WithAllUsers';
import ViewOptionsMenu from 'components/ViewOptionsMenu';
import { ExpandedTextArea } from 'components';
import IconButton from 'components/IconButton';

import { timeFormatter } from './Fields/time';
import { dateFormatter } from './Fields/date';
import { dateTimeFormatter } from './Fields/dateTime';
import { useStyles } from './styles';
import './Styles/ag-grid.css';
import './Styles/ag-theme-material.css';
import { MultiSelectField } from '../index';
import { versionSequenceAsString } from '../../utils';
import { autoSizeGroupColumn, getExportParams } from './utils';

const DEFAULT_COL_DEF = {
  sortable: true,
  filter: true,
  resizable: true,
  enableRowGroup: true,
  autoHeight: true,
  wrapText: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
};

const SIDE_BAR = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: false,
        suppressFilterSearch: false,
        // prevents custom layout changing when columns are reordered in the grid
        suppressSyncLayoutWithGrid: true,
      },
    },
  ],
};

const defaultValuesNonClickableCells = ['Associated Set'];
let nonClickableCells = [...defaultValuesNonClickableCells];

enum ColType {
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  BOOLEAN_RESULT = 'boolean_result',
  CUSTOM = 'custom',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  ID = 'id',
  REGULAR_TEXT = 'regular_text',
  EDITABLE_TEXT = 'editable_text',
  LONG_TEXT = 'long_text',
  MULTI_OPTIONS = 'multi_options',
  RESULT = 'result',
  SHORT_TEXT = 'short_text',
  STATUS = 'status',
  TIME = 'time',
  USER = 'user',
  VERSION = 'version',
}

interface Column extends Pick<GridOptions, 'columnDefs'> {
  type: ColType;
}

interface Props<TData = any> {
  path: string;
  data: TData[];
  TableTitle?: any;
  TableActionButton?: any;
  NewItemButton?: any;
  ExportMenuButton?: any;
  DisplayVariantSelect?: any;
  columns: Column[] | null;
  rowGroupPanelShow?: 'always' | 'onlyWhenGrouping' | 'never';
  groupDisplayType?: RowGroupingDisplayType;
  allUsers: object;
  leafField?: string;
  categorySlug: string;
  suppressSideBar: boolean;
  suppressViewOptions?: boolean;
  onCellClicked: any;
  handleColumnRowGroupChanged: any;
  handleRowDragMove: any;
  handleRowDragEnd: any;
  gridRef: any;
  getRowId: any;
  handleGridReady: any;
  viewOptionChangeCallback?: (checked: boolean) => void;
  groupDefaultExpanded?: number;
  DisciplineToggle?: any;
  noRowsOverlayComponent?: any;
  noRowsOverlayComponentParams?: any;
}

const keys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Meta', 'Enter'];

function handleColumnDefs(colDefs, allUsersList, classes) {
  nonClickableCells = [...defaultValuesNonClickableCells];

  function handleColumn(col) {
    if (col instanceof Array) {
      return col.map((element) => {
        return handleColumn(element);
      });
    } else if (col instanceof Function) {
      return col;
    } else if (col instanceof Object) {
      const objectHash = {};
      const objectKeys: string[] = [];
      for (const keyz in col) {
        objectKeys.push(keyz);
      }
      objectKeys.map((key) => {
        objectHash[key] = handleColumn(col[key]);
        if (col.blockRowLink) {
          nonClickableCells.push(col.headerName);
        }
        if (key == 'type' && col[key]) {
          objectHash['cellStyle'] = {
            padding: '4px',
            color: 'rgba(0,0,0,0.6)',
            fontFamily: ['Open Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            display: 'flex',
            alignItems: 'center',
          };
          switch (col[key]) {
            case ColType.EDITABLE_TEXT:
              objectHash['suppressKeyboardEvent'] = (params: SuppressKeyboardEventParams) => {
                const key = params.event.key;
                const gridShouldDoNothing = keys.includes(key) || params.event.metaKey || params.event.shiftKey;
                return gridShouldDoNothing;
              };
              objectHash['cellRenderer'] = (row) => {
                if (row.value === undefined) return null;
                if (!row.data) return <Typography variant="body2">{row.value}</Typography>;
                const attrName = col.editAttr;
                const currentVersion = get(row.data, col.currentVersionPath, row.data);
                return (
                  <>
                    {attrName ? (
                      <ExpandedTextArea
                        key={`description-of-change-${currentVersion.id}`}
                        id={`description-of-change-${currentVersion.id}`}
                        initialValue={row.value}
                        versionId={currentVersion.id}
                        attrName={attrName}
                        rows={2}
                        locked={currentVersion.locked}
                        placeholder={`Enter ${col.headerName} text here`}
                        collapsedClassName={classes.agGridCell}
                        refetchQueries={[col.refetchQuery]}
                        debounce={2000}
                        saveOnBlur={col.disableSaveOnBlur || true}
                        saveOnEnter={col.disableSaveOnEnter || true}
                      />
                    ) : (
                      row.value
                    )}
                  </>
                );
              };
              break;
            case ColType.ARRAY:
              objectHash['cellRenderer'] = (row) => {
                const rowData = colDefs.reduce((xs) => {
                  const deepObject = get(xs, 'childrenAfterFilter[0]');
                  return xs && deepObject !== null && deepObject !== undefined ? deepObject : xs;
                }, row.node);
                return <FieldCell row={row.data || rowData.data} col={col} />;
              };
              objectHash['keyCreator'] = (params) => {
                return params.value.map((item) => item.formattedRoleName).join('_');
              };
              break;
            case ColType.BOOLEAN_RESULT:
              objectHash['cellRenderer'] = (row) => {
                const rowValue = row.value === 'false' ? false : row.value;
                return <FieldCell row={rowValue} col={col} />;
              };
              break;
            case ColType.MULTI_OPTIONS:
              objectHash['cellRenderer'] = (row) => {
                if (row.value === undefined) return null;
                if (!row.data) return <Typography variant="body2">{row.value}</Typography>;
                const attrName = col.editAttr;
                const currentVersion = get(row.data, col.currentVersionPath);
                const rowValue = typeof row.value === 'string' ? row.value.split(',') : row.value;
                return (
                  <MultiSelectField
                    displayOnly={false}
                    locked={currentVersion.locked}
                    attrName={attrName}
                    options={col.options}
                    refetchQueries={[col.refetchQuery]}
                    versionId={currentVersion.id}
                    value={rowValue}
                    placeholder={`Choose ${col.headerName}`}
                  />
                );
              };
              break;
            case ColType.DATE_TIME:
              objectHash['valueFormatter'] = (row) => dateTimeFormatter(row.value);
              objectHash['keyCreator'] = (row) => {
                return dateTimeFormatter(row.value);
              };
              break;
            case ColType.TIME:
              objectHash['valueFormatter'] = (row) => timeFormatter(row.value);
              objectHash['keyCreator'] = (row) => {
                return timeFormatter(row.value);
              };
              break;
            case ColType.DATE:
              objectHash['width'] = col.width || '105px';
              objectHash['valueFormatter'] = (row) => {
                if (row.value === undefined) return null;
                return dateFormatter(row.value);
              };
              objectHash['keyCreator'] = (row) => {
                return dateFormatter(row.value);
              };
              objectHash['filter'] = 'agMultiColumnFilter';
              objectHash['filterParams'] = {
                filters: [
                  {
                    filter: 'agDateColumnFilter',
                    filterParams: {
                      comparator: (filterDate: Date, cellValue: string) => {
                        if (cellValue === null) return -1;
                        return new Date(cellValue).getDay() - filterDate.getDay();
                      },
                    },
                  },
                ],
              };
              break;
            case ColType.ID:
              objectHash['width'] = col.width || '75px';
              objectHash['enableRowGroup'] = false;
              objectHash['comparator'] = (value1, value2) => sortIds(value1, value2);
              objectHash['cellRenderer'] = (row) => {
                if (isColumnGroupedBy(row, 'customIdentifier')) return row.value || null;

                const recordPath = `/category/${get(row.data, 'itemType.displaySlug')}/${get(row.data, 'itemId') ||
                  get(row.data, 'id')}`;
                if (!row.data) return null;
                return (
                  <MakeLink href={recordPath} newTab={true}>
                    {row.data.customIdentifier}
                  </MakeLink>
                );
              };
              break;
            case ColType.USER:
              objectHash['width'] = col.width || '90px';
              objectHash['valueGetter'] = (row) => {
                return row.data;
              };
              objectHash['keyCreator'] = (row) => {
                return get(row.value, `${col.field}.fullName`, '');
              };
              objectHash['comparator'] = (value1, value2) => {
                if (!value1 || !value2 || !col.field) return null;
                const field1 = get(value1, `${col.field}.fullName`, '');
                const field2 = get(value2, `${col.field}.fullName`, '');
                if (field1 === field2) return 0;
                return field1 > field2 ? 1 : -1;
              };
              objectHash['cellRenderer'] = (row) => {
                if (row.value === undefined) return null;
                let rowData;
                if (row.value instanceof Object) {
                  rowData = get(
                    row.value,
                    col.field,
                    get(row.value, ['currentVersion', col.field].join('.'), row.value),
                  );
                } else if (typeof row.value === 'string') {
                  const currentUser = allUsersList.find((user) => user.fullName === row.value);
                  rowData = { id: currentUser ? currentUser.id : row.value };
                }
                return <FieldCell row={rowData} col={col} />;
              };
              break;
            case ColType.STATUS:
              objectHash['width'] = col.width || '90px';
              objectHash['cellRenderer'] = (row) => {
                return <FieldCell row={row.value} col={col} />;
              };
              objectHash['keyCreator'] = (params) => {
                return params.value;
              };
              break;
            case ColType.VERSION:
              objectHash['width'] = col.width || '90px';
              objectHash['cellRenderer'] = (row) => {
                if (!row.value) return null;
                let rowData;
                if (row.value instanceof Object) {
                  rowData = row.value.currentVersion;
                } else if (typeof row.value === 'string') {
                  const words = row.value.split('-');
                  rowData = {
                    versionIdentifier: words[0],
                    currentStatusName: words[1],
                  };
                }
                return <FieldCell row={rowData} col={col} />;
              };
              objectHash['keyCreator'] = (row) => {
                return [
                  get(row.value, 'currentVersion.versionIdentifier', row.value),
                  row.value.currentVersion.currentStatusName || row.value.currentVersion.currentStatus.name,
                ].join('-');
              };
              objectHash['valueGetter'] = (row) => {
                const readyData = row.data || null;
                return readyData;
              };
              objectHash['comparator'] = (value1, value2) => {
                return sortVersions(value1, value2);
              };
              break;
            case ColType.CUSTOM:
              const render = col['render'];
              objectHash['enableRowGroup'] = col.enableRowGroup || false;
              objectHash['cellRenderer'] = (row) => {
                if (row.value === undefined) return null;
                return render(row);
              };
          }
        }
        return '';
      });
      return objectHash;
    } else {
      return col;
    }
  }

  return handleColumn(colDefs);
}

const defaultNavigate = (row, path, categorySlug) => {
  if (row.data === undefined) return null;
  const recordPath =
    path ||
    `/category/${get(row.data, 'itemType.displaySlug') || categorySlug}/${get(row.data.employee, 'id') ||
      get(row.data, 'id')}`;
  return get(row, 'column.colDef.type') === 'id' ||
    get(row, 'column.colDef.type') === 'custom' ||
    nonClickableCells.includes(get(row, 'column.colDef.headerName'))
    ? null
    : navigate(recordPath);
};

const Table = ({
  rowGroupPanelShow = 'onlyWhenGrouping',
  data,
  columns,
  path,
  categorySlug,
  allUsers,
  leafField = 'customIdentifier',
  groupDisplayType = 'singleColumn',
  suppressSideBar = false,
  suppressViewOptions = false,
  onCellClicked,
  handleColumnRowGroupChanged,
  TableTitle,
  TableActionButton,
  NewItemButton,
  ExportMenuButton,
  DisplayVariantSelect,
  handleRowDragMove,
  gridRef,
  getRowId,
  handleGridReady,
  handleRowDragEnd,
  viewOptionChangeCallback,
  groupDefaultExpanded = -1,
  DisciplineToggle,
  noRowsOverlayComponent,
  noRowsOverlayComponentParams,
}: Props) => {
  const classes = useStyles();
  const newParams = handleColumnDefs(columns, allUsers, classes);
  const gridRefLocal = useRef<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [optionsState, setOptionsState] = useState({
    numberOfItemsInGroup: false,
    combinedItemsGroups: false,
    groupRemoveSingleChildren: false,
    showOpenedGroup: false,
  });
  const refToUse = gridRef ? gridRef : gridRefLocal;
  // @ts-ignore
  nonClickableCells = [...new Set(nonClickableCells)];

  const autoGroupColumnDef: ColDef = useMemo(() => {
    return {
      cellRendererParams: {
        suppressCount: !optionsState.numberOfItemsInGroup,
      },
      headerName: 'Group',
      valueGetter: (params) => {
        return optionsState.showOpenedGroup
          ? undefined
          : leafField === 'currentVersion'
          ? versionSequenceAsString(get(params.data, 'versionIdentifier'), get(params.data, 'currentStatus'))
          : `${get(params.data, leafField, '')}`;
      },
      cellStyle: {
        wordBreak: 'normal',
      },
    };
  }, [optionsState.numberOfItemsInGroup, optionsState.showOpenedGroup, leafField]);

  const gridOptions = useMemo<GridOptions>(() => {
    return {
      groupRemoveSingleChildren: optionsState.groupRemoveSingleChildren,
      showOpenedGroup: optionsState.showOpenedGroup,
      defaultExcelExportParams: getExportParams(),
      defaultCsvExportParams: getExportParams(),
    };
  }, [optionsState.groupRemoveSingleChildren, optionsState.showOpenedGroup]);

  const getOpenedToolPanel = useCallback(() => {
    return refToUse.current.api.getOpenedToolPanel();
  }, []);

  const openToolPanel = useCallback((key) => {
    refToUse.current.api.openToolPanel(key);
  }, []);

  const closeToolPanel = useCallback(() => {
    refToUse.current.api.closeToolPanel();
  }, []);

  const handleChangeToolPanel = (key) => {
    const toolPanelState = getOpenedToolPanel();

    if (toolPanelState === key) {
      closeToolPanel();
    } else {
      openToolPanel(key);
    }
  };

  const handleViewOptionMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);

  const handleViewOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptionsState({ ...optionsState, [event.target.name]: event.target.checked });
    if (event.target.name === 'combinedItemsGroups') {
      viewOptionChangeCallback && viewOptionChangeCallback(event.target.checked);
    } else if (event.target.name === 'showOpenedGroup') {
      toggleColumnVisibilityByLeaf(refToUse.current, event.target.checked);
    }
  };

  const toggleColumnVisibilityByLeaf = (event, visibility?: boolean) => {
    const rowGroupColumns = event.columnApi.getRowGroupColumns();
    const columns = event.columnApi.getColumns();
    if (columns && columns.length) {
      const column = columns.find((column) => column.getColId().includes(leafField));
      if (column) {
        event.columnApi.setColumnVisible(column.getColId(), visibility || !rowGroupColumns.length);
      }
    }
  };

  const onColumnRowGroupChanged = (event) => {
    handleColumnRowGroupChanged && handleColumnRowGroupChanged(event);
    toggleColumnVisibilityByLeaf(event, optionsState.showOpenedGroup);
    autoSizeGroupColumn(event);
  };

  const onGridReady = (event) => {
    handleGridReady && handleGridReady(event);
    toggleColumnVisibilityByLeaf(event, optionsState.showOpenedGroup);
    autoSizeGroupColumn(event);
  };

  return (
    <div className="ag-theme-material" style={{ height: 'calc(100vh - 200px)', position: 'relative' }}>
      <div className={classes.filterColumnsBarContainer}>
        <div className={classes.tableTitle}>
          {TableActionButton && <TableActionButton />}
          {TableTitle && <TableTitle />}
        </div>

        {DisciplineToggle && (
          <div style={{ marginRight: '12px', display: 'flex' }}>
            <DisciplineToggle />
          </div>
        )}

        {DisplayVariantSelect && (
          <div style={{ marginRight: '20px' }}>
            <DisplayVariantSelect />
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!suppressViewOptions && (
            <>
              <IconButton Icon={ListAlt} onClick={handleViewOptionMenuOpen} size="large" tooltip="View Options" />
              <ViewOptionsMenu
                open={Boolean(anchorEl)}
                state={optionsState}
                anchorEl={anchorEl}
                handleChange={handleViewOptionChange}
                handleClose={() => setAnchorEl(null)}
              />
              <div style={{ marginLeft: '20px' }}>
                <IconButton
                  Icon={TableChart}
                  onClick={() => handleChangeToolPanel('columns')}
                  size="large"
                  tooltip="Columns"
                />
              </div>
              <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                <IconButton
                  Icon={FilterList}
                  onClick={() => handleChangeToolPanel('filters')}
                  size="large"
                  tooltip="Filters"
                />
              </div>
              {ExportMenuButton && (
                <div style={{ marginLeft: '12px' }}>
                  <ExportMenuButton />
                </div>
              )}
              {NewItemButton && (
                <div style={{ marginLeft: '12px' }}>
                  <NewItemButton />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <AgGridReact
        ref={refToUse}
        sideBar={suppressSideBar ? false : SIDE_BAR}
        rowGroupPanelShow={rowGroupPanelShow}
        columnHoverHighlight={true}
        suppressRowHoverHighlight={false}
        suppressCellSelection={true}
        autoGroupColumnDef={autoGroupColumnDef}
        rowData={data}
        columnDefs={newParams}
        animateRows={true}
        onColumnRowGroupChanged={onColumnRowGroupChanged}
        onRowDragMove={handleRowDragMove}
        onRowDragEnd={handleRowDragEnd}
        onGridReady={onGridReady}
        defaultColDef={DEFAULT_COL_DEF}
        groupDefaultExpanded={groupDefaultExpanded}
        getRowId={(params) => (getRowId && getRowId(params)) || params.data.id}
        groupDisplayType={groupDisplayType}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        noRowsOverlayComponent={noRowsOverlayComponent}
        onCellClicked={(row) => (onCellClicked ? onCellClicked(row) : defaultNavigate(row, path, categorySlug))}
        {...gridOptions}
      />
    </div>
  );
};

export default withAllUsers(Table, true);
