import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import { TrainingPlanVersionQuery, TrainingPlanVersionQueryVariables } from './__generated__/TrainingPlanVersionQuery';
import {
  TrainingPlanBuilderViewQuery,
  TrainingPlanBuilderViewQueryVariables,
} from './__generated__/TrainingPlanBuilderViewQuery';

export const builderViewQuery = gql`
  query TrainingPlanBuilderViewQuery($itemId: ID) {
    item(id: $itemId, itemType: "training_plan") {
      id
      customIdentifier
      createdAt
      currentUser {
        id
        readOnly
      }
      owners {
        id
        fullName
        title
      }
      approvers {
        id
        fullName
        title
      }
      owner {
        id
        fullName
        title
        status
      }
      approver {
        id
        fullName
        title
        status
      }
      referenceId
      tags
      workingVersion {
        id
      }
      currentVersion {
        id
        createdAt
        owner {
          id
          fullName
          title
        }
        approver {
          id
          fullName
          title
        }
        creator {
          id
          fullName
          title
        }
        assignedOwnerJobRole {
          id
          formattedRoleName
          usersWithRole {
            id
            fullName
          }
        }
        assignedApproverJobRole {
          id
          formattedRoleName
          usersWithRole {
            id
            fullName
          }
        }
        permissibleApprovers {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        permissibleOwners {
          id
          fullName
          title
          jobRoles {
            formattedRoleName
          }
        }
        locked
        title
        versionIdentifier
        currentStatus {
          id
          name
        }
        locked
        title
        versionIdentifier
        ... on TrainingPlanVersion {
          courses {
            id
            customIdentifier
            currentVersion {
              id
              currentStatus {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const useBuilderViewQuery = (itemId: string) =>
  useQuery<TrainingPlanBuilderViewQuery, TrainingPlanBuilderViewQueryVariables>(builderViewQuery, {
    variables: {
      itemId,
    },
  });

export const listViewQuery = gql`
  query trainingPlansQuery {
    trainingPlans {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      currentVersion {
        id
        createdAt
        title
        currentStatus {
          id
          name
        }
        versionIdentifier
        signingOwner {
          id
          firstName
          lastName
        }
        signingApprover {
          id
          firstName
          lastName
        }
      }
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
    }
  }
`;

const versionQuery = gql`
  query TrainingPlanVersionQuery($id: ID!) {
    trainingPlanVersion(id: $id) {
      id
      locked
      displayAsApplicationVersion
      createdAt
      title
      reasonForChange
      descriptionOfChange
      item {
        id
        customIdentifier
      }
      courses {
        id
        currentVersion {
          id
          title
          currentStatus {
            id
            name
          }
          versionIdentifier
        }
        customIdentifier
      }
      curriculumVersions {
        id
        title
        currentStatus {
          id
          name
        }
        item {
          id
          customIdentifier
        }
        courseVersions {
          id
          title
          item {
            id
            customIdentifier
          }
        }
      }
      employee {
        id
        manager {
          id
        }
        user {
          id
          fullName
          userJobRoles {
            jobRole {
              formattedRoleName
            }
          }
        }
      }
      creator {
        id
        fullName
        title
      }
      signingOwner {
        id
        firstName
        lastName
      }
      signingApprover {
        id
        firstName
        lastName
      }
    }
  }
`;

export function useVersion(id: string) {
  return useQuery<TrainingPlanVersionQuery, TrainingPlanVersionQueryVariables>(versionQuery, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

const addCurriculumToTrainingPlan = gql`
  mutation addCurriculumVersionToTrainingPlanVersion($trainingPlanVersionId: ID!, $curriculumVersionId: ID!) {
    addCurriculumVersionToTrainingPlanVersion(
      trainingPlanVersionId: $trainingPlanVersionId
      curriculumVersionId: $curriculumVersionId
    ) {
      trainingPlanVersion {
        id
        curriculumVersions {
          id
        }
      }
    }
  }
`;

export function useAddCurriculumToTrainingPlan() {
  return useMutation(addCurriculumToTrainingPlan);
}

const removeCurriculumFromTrainingPlan = gql`
  mutation removeCurriculumVersionFromTrainingPlanVersion($trainingPlanVersionId: ID!, $curriculumVersionId: ID!) {
    removeCurriculumVersionFromTrainingPlanVersion(
      trainingPlanVersionId: $trainingPlanVersionId
      curriculumVersionId: $curriculumVersionId
    ) {
      trainingPlanVersion {
        id
      }
    }
  }
`;
export function useRemoveCurriculumFromTrainingPlan() {
  return useMutation(removeCurriculumFromTrainingPlan);
}

const addCourseToTrainingPlan = gql`
  mutation addCourseToTrainingPlanVersion($trainingPlanVersionId: ID!, $courseIds: [ID!]!) {
    addCourseToTrainingPlanVersion(trainingPlanVersionId: $trainingPlanVersionId, courseIds: $courseIds) {
      trainingPlanVersion {
        id
        courses {
          id
        }
      }
    }
  }
`;

export function useAddCourseToTrainingPlan() {
  return useMutation(addCourseToTrainingPlan);
}

const removeCourseFromTrainingPlan = gql`
  mutation removeCourseFromTrainingPlanVersion($trainingPlanVersionId: ID!, $courseId: ID!) {
    removeCourseFromTrainingPlanVersion(trainingPlanVersionId: $trainingPlanVersionId, courseId: $courseId) {
      trainingPlanVersion {
        id
      }
    }
  }
`;
export function useRemoveCourseFromTrainingPlan() {
  return useMutation(removeCourseFromTrainingPlan);
}
