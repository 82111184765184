import React from 'react';

import { ChatBubbleOutline } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { Link } from '@reach/router';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { DateTime } from 'luxon';

import TextWithMentions from 'components/TextWithMentions';
import UserAvatar from 'components/UserAvatar';
import { ItemTypeIndex } from 'itemTypes';

import { useStyles } from './styles';

function CommentContainer({ comment }) {
  const classes = useStyles();
  const metadata = ItemTypeIndex[camelCase(comment.item.itemType.name)];
  const Icon = get(metadata, 'icon', ChatBubbleOutline);

  const title = get(comment, 'item.currentVersion.virtualAttributes[0].stringValue', '');

  let itemPath = comment.item.itemType.userCreatable
    ? `/category/${comment.item.itemType.displaySlug}/${comment.item.id}`
    : `/category/${comment.item.itemType.displaySlug}`;

  return (
    <div key={comment.id}>
      <Icon className={classes.icon} />
      <Link to={itemPath}>
        <Typography style={{ display: 'inline' }} variant="body2">
          {comment.item.customIdentifier}: {title}
        </Typography>
      </Link>

      <div className={classes.commentRow}>
        <div className={classes.avatarWrapper}>
          <UserAvatar userId={comment.creator.id} diameter={24} />
        </div>
        <div style={{ width: '100%' }}>
          <Typography className={classes.nameAndTime} variant="body2">
            <span>
              {comment.creator.profile.firstName} {comment.creator.profile.lastName}
            </span>
            <span>{DateTime.fromISO(comment.createdAt).toLocaleString(DateTime.TIME_SIMPLE)}</span>
          </Typography>
          <Typography variant="caption">
            <TextWithMentions content={comment.body} />
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default CommentContainer;
