import React from 'react';
import { Grid } from '@material-ui/core';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import { Views } from 'workspaces';

const DocumentBuilder = ({
  activeWorkspaceView,
  itemType,
  layoutClasses,
  versionData,
  richTextOptionSet,
  refetchQueries,
  Heading = 'Document Body',
}) => {
  return (
    <Grid item xs={12} className={layoutClasses.grid}>
      <RichTextEditorContentSection
        attrName="body"
        Heading={Heading}
        initialValue={versionData.body}
        key={`${versionData.id}-locked:${versionData.locked}`}
        locked={versionData.locked || activeWorkspaceView.id !== Views.Builder.id}
        optionSet={richTextOptionSet}
        refetchQueries={refetchQueries}
        versionId={versionData.id}
        showComments={activeWorkspaceView.id === Views.Builder.id}
      />
    </Grid>
  );
};

export default withWorkspaceViews()(DocumentBuilder);
