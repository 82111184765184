import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';

import { Trans } from '@lingui/macro';
// import { withI18n } from '@lingui/react';

import { List, ListItem, ListItemText, withStyles } from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';

import { navigate } from '@reach/router';

import styles from './styles';

import { ProfileUpdateForm } from 'compositions';

const Divider = ({ classes }) => <div className={classes.border} />;

const DividerWithStyles = withStyles(styles)(Divider);

class UserMenu extends Component {
  signOut = (client) => {
    navigate('/sign-out'); // eslint-disable-line @typescript-eslint/no-floating-promises
  };

  render() {
    const { classes, client } = this.props;

    return (
      <div className={classes.userMenuWrapper}>
        <ProfileUpdateForm />
        <div className={classes.bottomMenu}>
          <DividerWithStyles />
          <List>
            <ListItem id="sign-out" button onClick={() => this.signOut(client)}>
              <ListItemText
                classes={{
                  primary: classes.signOutText,
                }}
              >
                <ExitToApp className={classes.signOutIcon} />
                <Trans>Sign Out</Trans>
              </ListItemText>
            </ListItem>
          </List>
          <DividerWithStyles />
        </div>
      </div>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.shape({
    bottomMenu: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
    userMenuWrapper: PropTypes.string.isRequired,
    signOutText: PropTypes.string.isRequired,
    signOutIcon: PropTypes.string.isRequired,
  }).isRequired,
  client: PropTypes.object,
  // i18n: PropTypes.shape({
  //   _: PropTypes.func.isRequired,
  // }).isRequired,
};

export default compose(
  // withI18n(),
  withStyles(styles),
)(UserMenu);
