import React from 'react';

import { useVersion } from './gql';
import FormBasedPayloadFields from 'compositions/FormBasedPayloadFields';

const PayloadFields = ({ versionId }: { versionId: string }) => {
  const { data, loading, error, refetch } = useVersion(versionId);
  if (!data || loading) return null;
  if (error) throw new Error(`Error loading In Process Inspection Version ${versionId}\n${error}`);

  return (
    <FormBasedPayloadFields
      versionData={data.inProcessInspectionVersion}
      itemTypeName={'inProcessInspection'}
      associatedRecordCustomIdPrefix={'IPI-R'}
      onDocumentUploadComplete={refetch}
      refetchQueries={['InProcessInspectionVersion']}
    />
  );
};

export default PayloadFields;
