import React, { PropsWithChildren } from 'react';

import { MenuItem } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';

interface WorkflowItemProps {
  disabled: boolean;
  onClick: (event: any) => void;
}

function ArchiveMenuItem(props: PropsWithChildren<WorkflowItemProps>) {
  return (
    <MenuItem disabled={props.disabled} onClick={props.onClick}>
      <ArchiveIcon />
      &ensp;
      {props.children ? props.children : 'Archive Item'}
    </MenuItem>
  );
}

export default ArchiveMenuItem;
