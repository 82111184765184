import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import { Drawer, Typography, Button, ClickAwayListener, withStyles } from '@material-ui/core';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import Description from '@material-ui/icons/Description';
import Schedule from '@material-ui/icons/Schedule';

import { Spacer } from 'components';
import Comments from 'compositions/Comments';

import { buildConditionalClasses } from 'utils';

import AuditTrail from './AuditTrail';
import History from './History';
import styles from './styles';

const MESSAGES = 'messages';
const HISTORY = 'history';
const CHANGES = 'changes';

class ContextDrawer extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      active: PropTypes.string.isRequired,
      drawerPaper: PropTypes.string.isRequired,
      headerContainer: PropTypes.string.isRequired,
      inactive: PropTypes.string.isRequired,
      optionsTab: PropTypes.string.isRequired,
      tabContainer: PropTypes.string.isRequired,
    }).isRequired,
    itemId: PropTypes.string.isRequired,
    theme: PropTypes.shape({
      optionsDrawerWidth: PropTypes.number.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      drawer: false,
      buttonPos: 0,
      activeTab: MESSAGES,
    };
    this.drawerWidth = props.theme.optionsDrawerWidth;
  }

  buildButtonComponent = (icon, name) => {
    const { classes } = this.props;
    const { activeTab, drawer } = this.state;

    return (
      <Button
        onClick={() => {
          const newDrawerState = activeTab !== name || !drawer;
          this.toggleDrawer(newDrawerState, name);
        }}
        className={buildConditionalClasses([
          [true, classes.optionsTab],
          [activeTab === name, classes.active],
          [activeTab !== name, classes.inactive],
        ])}
        data-testid={`${name}Button`}
      >
        {icon}
      </Button>
    );
  };

  buildDrawerComponent = () => {
    const { classes, itemId, itemType } = this.props;
    const { activeTab } = this.state;

    switch (activeTab) {
      case MESSAGES:
        return (
          <>
            <div className={classes.headerContainer}>
              <Typography variant="h2">
                <Trans>Comments</Trans>
              </Typography>
            </div>

            <Comments itemId={itemId} commentInputRef={(ref) => (this.focusTarget = ref)} />
          </>
        );
      case HISTORY:
        return (
          <>
            <div className={classes.headerContainer}>
              <Typography variant="h2">
                <Trans>History</Trans>
              </Typography>
            </div>

            <History itemId={itemId} itemType={itemType} />
          </>
        );
      case CHANGES:
        return (
          <>
            <AuditTrail itemId={itemId} />
          </>
        );
      default:
        return null;
    }
  };

  toggleDrawer = (open, activeTab) => {
    const spot = open ? this.drawerWidth : 0;
    this.setState(
      {
        drawer: open,
        buttonPos: spot,
        activeTab: activeTab,
      },
      () => {
        if (open && this.focusTarget) {
          this.focusTarget.focus();
        }
      },
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <ClickAwayListener
        onClickAway={(e) => {
          if (this.contextDrawer && !this.contextDrawer.contains(e.target)) {
            this.toggleDrawer(false);
          }
        }}
      >
        <div ref={(ref) => (this.contextDrawer = ref)}>
          <div
            data-testid="optionsDrawerTabs"
            style={{ right: this.state.buttonPos, transition: 'right .22s' }}
            className={classes.tabContainer}
          >
            {this.buildButtonComponent(<QuestionAnswer />, MESSAGES)}
            <Spacer factor={2} />
            {this.buildButtonComponent(<Schedule />, HISTORY)}
            <Spacer factor={2} />
            {this.buildButtonComponent(<Description />, CHANGES)}
          </div>

          <Drawer
            anchor="right"
            open={this.state.drawer}
            onClose={() => this.setState({ drawer: false })}
            variant="persistent"
            classes={{ paper: classes.drawerPaper }}
            data-testid="optionsDrawer"
          >
            {this.buildDrawerComponent()}
          </Drawer>

          {window.Intercom &&
            (this.state.drawer
              ? window.Intercom('update', { hide_default_launcher: true })
              : window.Intercom('update', { hide_default_launcher: false }))}
        </div>
      </ClickAwayListener>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withI18n(),
)(ContextDrawer);
