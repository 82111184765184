import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    addLinkButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      height: 18,
      width: 18,
      margin: '0 auto',
      position: 'absolute',
      top: '40%',
      '&:hover': {
        cursor: 'pointer',
      },
      zIndex: 999,
    },
    linkIcon: { fontSize: 12, color: 'white', borderRadius: '50%' },
    upstreamSpacing: {
      left: -8,
    },
    downstreamSpacing: {
      right: -8,
    },
    hidden: {
      display: 'none',
    },
  }),
);
