import React from 'react';

function Cell({ children, size, style }: { children?; size?: number; style? }) {
  return (
    <div
      style={{
        flex: size || 3,
        display: 'flex',
        alignItems: 'center',
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export default Cell;
