export default (theme) => ({
  root: {
    left: '50%',
    paddingTop: theme.spacing(1),
    position: 'absolute',
    top: '45%',
    transform: 'translate(-50%, -50%)',
    width: theme.spacing(57),
  },
  button: {
    justifyContent: 'left',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  },
  buttonText: {
    marginLeft: theme.spacing(1),
    textTransform: 'capitalize',
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
  createBtn: {
    width: '50%',
    padding: 0,
  },
  formContainer: {
    '&:after': {
      borderBottom: theme.palette.primary.main,
    },
  },
  textInput: {
    ...theme.typography.body2,
    height: 40,
    '&:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  itemTypeInput: {
    '&[class*="MuiOutlinedInput-root"]': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 40,
    },
  },
  itemTypeOption: {
    height: 40,
    '&[data-focus="true"]': {
      background: theme.palette.common.input.hover,
    },
  },
  itemTypePaper: {
    border: `2px solid ${theme.palette.common.input.outline}`,
  },
  itemTypeText: {
    marginLeft: theme.spacing(0.5),
  },
  menuOption: {
    ...theme.typography.body2,
    borderTop: `2px solid ${theme.palette.common.input.outline}`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      background: theme.palette.common.input.hover,
    },
  },
  selectMenu: {
    ...theme.typography.body2,
    padding: 'none',
    height: 40,
  },
  signatureBar: {
    position: 'absolute',
    transform: 'rotate(90deg) scaleX(-1)',
    width: '140px',
    marginLeft: '-67px',
    marginTop: theme.spacing(7.5),
    borderRadius: '5px',
  },
  menuDropdown: {
    height: '300px',
  },
  closeBtn: {
    width: '50%',
    marginRight: '5px',
    padding: 0,
  },
  dropdownIcon: {
    marginRight: theme.spacing(1.5),
  },
  outlinedInput: {
    padding: '11px 14px',
  },
});
