import React from 'react';

import { ListItemIcon, ListItemText, MenuItem, Typography, withStyles } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';

import { Trans } from '@lingui/macro';

const styles = (theme) => ({
  icon: {
    color: theme.palette.blueDark,
    width: 17,
    marginRight: theme.spacing(-3),
  },
  menuItemHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  menuItemText: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    whiteSpace: 'normal',
    height: 'auto',
    minHeight: 24,
    '&:hover': {
      background: 'rgba(48, 188, 179, 0.1)',
    },
  },
  refIdText: {
    color: theme.palette.titleBlack,
    whiteSpace: 'normal',
    lineHeight: 1.27,
    letterSpacing: 1.1,
    paddingBottom: theme.spacing(0.5),
    paddingtop: theme.spacing(0.5),
  },
});

const GenerateOption = ({ text, handler, classes }) => (
  <MenuItem classes={{ root: classes.menuItemText }} onClick={handler}>
    <ListItemIcon classes={{ root: classes.icon }}>
      <OpenInNew classes={{ root: classes.icon }} />
    </ListItemIcon>
    <ListItemText primaryTypographyProps={{ variant: 'body2' }} classes={{ root: classes.menuItem }}>
      {text}
    </ListItemText>
  </MenuItem>
);

const PrintViewMenuItem = (props) => {
  return (
    <>
      <MenuItem disabled classes={{ root: props.classes.menuItemHeader }}>
        <Typography variant="overline" classes={{ root: props.classes.refIdText }}>
          <Trans>Print View</Trans>
        </Typography>
      </MenuItem>
      {props.customGenerateOptions.length ? (
        props.customGenerateOptions.map((option) => (
          <GenerateOption text={option.text} handler={option.handler} classes={props.classes} />
        ))
      ) : (
        <GenerateOption
          text={props.defaultGenerateOption.text}
          handler={props.defaultGenerateOption.handler}
          classes={props.classes}
        />
      )}
    </>
  );
};

export default withStyles(styles)(PrintViewMenuItem);
