import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    fixedHeightIconContainer: {
      position: 'relative',
      height: 90,
    },
    iconContainer: {
      display: 'inline-block',
      verticalAlign: 'top',
      maxWidth: 64,
    },
    calendarContainer: {
      position: 'absolute',
      top: 11,
      right: 10,
    },
    textOverride: {
      fontSize: 8, // override theme for one-off sizing in timeline
      textAlign: 'center',
      maxWidth: 64,
      whiteSpace: 'normal',
      position: 'relative',
    },
    wideLabel: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%)',
      minWidth: 80
    },
    largeBtn: {
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: 'none',
      zIndex: 2,
      '-webkit-transition': 'width .3s, height .3s',
      transition: 'width .3s, height .3s',
      /* hover functionality still under discussion
    '&:hover': {
      width: '60px',
      height: '60px',
    }, */
      '&:active': {
        boxShadow: 'none',
      },
      margin: '0 -1px 2px -1px',
      verticalAlign: 'top',
    },
    active: {
      boxShadow: 'inset 0px 0px 0px 3px white !important',
      color: `${theme.palette.primary.contrastText} !important`,
      '&:active': {
        boxShadow: 'inset 0px 0px 0px 3px white !important',
      },
    },
    disabled: {
      opacity: 0.7,
      backgroundColor: `${theme.palette.primary.contrastText} !important`,
    },
    empty: {
      backgroundColor: `${theme.palette.primary.contrastText} !important`,
    },
    sizeSmall: {
      minHeight: 0,
      width: '30px !important',
      height: '30px !important',
    },
    sizeTiny: {
      minHeight: 0,
      width: '20px !important',
      height: '20px !important',
    },
    statusIcon: {
      fontSize: 22,
    },
    statusIconTiny: {
      fontSize: 12,
    },
    statusIconSmall: {
      fontSize: 18,
    },
    hidden: {
      display: 'none',
    },
    tooltip: {
      ...theme.typography.body2,
    },
    squareIcon: {
      borderRadius: theme.spacing(1)
    }
  });
