import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    actionBtn: {
      ...theme.typography.button,
      color: theme.palette.primary.main,
      paddingLeft: theme.spacing(3),
    },
    closeIcon: {
      color: theme.palette.primary.contrastText,
    },
    messageText: {
      ...theme.typography.caption,
      color: theme.palette.primary.contrastText,
    },
    snackbar: {
      top: 72,
      marginTop: theme.spacing(1),
      transform: 'none',
    },
  }),
);
