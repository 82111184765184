import gql from 'graphql-tag';

export const USER_NOTIFICATIONS_QUERY = gql`
  query userNotificationsQuery($userId: ID!) {
    user(id: $userId) {
      id
      esignatureRequiredNotifications {
        id
        completed
        createdAt
        updatedAt
        precedingStatus {
          id
          name
          titleizedName
          version {
            id
            title
            item {
              id
              customIdentifier
              itemType {
                id
                displaySlug
              }
            }
            taskReferencedVersions {
              id
              versionIdentifier
              title
              currentStatus {
                id
                name
              }
              locked
              item {
                id
                itemType {
                  id
                  displaySlug
                }
                customIdentifier
                currentVersion {
                  id
                  versionIdentifier
                  title
                  currentStatus {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      mentionNotifications {
        id
        completed
        createdAt
        updatedAt
        mentionedBy {
          id
          fullName
          profile {
            id
            phoneNumber
            photoUrl
          }
        }
        item {
          id
          customIdentifier
          currentVersion {
            id
            title
            taskReferencedVersions {
              id
              versionIdentifier
              title
              currentStatus {
                id
                name
              }
              locked
              item {
                id
                itemType {
                  id
                  displaySlug
                }
                customIdentifier
                currentVersion {
                  id
                  versionIdentifier
                  title
                  currentStatus {
                    id
                    name
                  }
                }
              }
            }
          }
          itemType {
            id
            displaySlug
          }
        }
        comment {
          id
          body
        }
      }
      taskNotifications {
        id
        completed
        createdAt
        updatedAt
        headline
        assignedBy {
          id
          fullName
          profile {
            id
            photoUrl
          }
        }
        task {
          id
          title
          dueDate
          item {
            id
            customIdentifier
          }
        }
      }
      trainingNotifications {
        id
        completed
        createdAt
        updatedAt
        headline
        assignedBy {
          id
          fullName
          profile {
            id
            photoUrl
          }
        }
        training {
          id
          title
          dueDate
          item {
            id
            customIdentifier
            itemType {
              id
              displaySlug
            }
          }
        }
      }
    }
  }
`;
