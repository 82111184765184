import React, { ChangeEvent, ComponentType, FC, useEffect, useRef, useState } from 'react';

import { Paper, TextField, CircularProgress, SvgIconProps, Typography, Tooltip, Chip } from '@material-ui/core';
import Autocomplete, { createFilterOptions, RenderInputParams, RenderOptionState } from '@material-ui/lab/Autocomplete';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import Cancel from '@material-ui/icons/Cancel';

import { isUndefined, remove } from 'lodash';
import clsx from 'clsx';

import { useStyles } from './styles';
import { useAutocompleteStyles } from './autoCompleteStyles';

interface Props {
  options?: {
    label?: string;
    Icon?: ComponentType<SvgIconProps>;
    placeholder?: string;
  };
  loading: boolean;
  suggestions: string[];
  selected?: string | undefined;
  onChange: (value: string) => void;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  chipTooltip?: boolean;
}

export const EnhancedAutocomplete: FC<Props> = (props) => {
  const { onChange, loading, disabled, readOnly } = props;

  const classes = useStyles();
  const autocompleteClasses = useAutocompleteStyles();

  const paperRef = useRef();
  const inputRef = useRef();

  const [searchValue, setSearchValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string[]>([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInputValue(isUndefined(props.selected) ? [] : [props.selected!]);
  }, [props.selected]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    limit: 500,
  });

  const suggestions: string[] = props.suggestions || [];
  const selected: string[] = props.selected && props.selected.length ? [props.selected] : [];
  const { label, Icon, placeholder } = props.options || {
    label: '',
    Icon: DescriptionIcon,
    placeholder: '',
  };

  const create = (value: string) => {
    onChange(value);
    setOpen(false);
  };

  const renderOption = (option, _state: RenderOptionState) => {
    return <Typography variant={'body2'}>{option}</Typography>;
  };

  const onChangeHandler = (_, value: any[]) => {
    const next =
      value && inputValue && value.includes(inputValue[0]) ? remove(value, (el) => el === inputValue[0]) : value;
    setInputValue(next);
    onChange(value[0]);
  };

  const onFocusHandler = (_) => {
    if (!readOnly) setInputValue([]);
  };

  const onBlueHandler = (_) => {
    if (!searchValue.length && selected.length) {
      setInputValue(selected);
    }
    setSearchValue('');
  };

  const onInputChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value);
  };

  const mayHaveTooltip = !props.chipTooltip
    ? {}
    : {
        renderTags: (value, getTagProps) => {
          return value.map((option, index) => (
            <Tooltip title={option} key={index}>
              <Chip
                label={option}
                size="small"
                className={classes.chip}
                deleteIcon={<Cancel className={readOnly || disabled ? classes.deleteIcon : undefined} />}
                {...getTagProps({ index })}
              />
            </Tooltip>
          ));
        },
      };

  return (
    <div className={clsx(classes.pickerWrapper, props.className)}>
      {label && (
        <Typography variant="subtitle1" className={classes.label}>
          {Icon && <Icon fontSize="small" />}
          <span>{label}</span>
        </Typography>
      )}
      <Autocomplete
        open={open}
        onOpen={() => !readOnly && setOpen(true)}
        onClose={() => setOpen(false)}
        disabled={disabled}
        onFocus={onFocusHandler}
        disableClearable={readOnly || disabled}
        classes={autocompleteClasses}
        size={'small'}
        filterOptions={filterOptions}
        options={suggestions}
        value={inputValue}
        loading={loading}
        onBlur={onBlueHandler}
        onChange={onChangeHandler}
        autoHighlight={true}
        multiple
        placeholder={placeholder}
        renderOption={renderOption}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option} deleteIcon={<Cancel className={classes.deleteIcon} />} {...getTagProps({ index })} />
          ))
        }
        PaperComponent={(options) => {
          const { children } = options;
          const isShowButton = !!searchValue.length && !suggestions.includes(searchValue);

          return (
            <Paper ref={paperRef}>
              {children}
              {isShowButton && (
                <div
                  role="button"
                  className={classes.createButton}
                  onMouseDown={(_) => {
                    create(searchValue);
                  }}
                >
                  {searchValue}
                  <Typography variant={'subtitle2'}>*Create new</Typography>
                </div>
              )}
            </Paper>
          );
        }}
        renderInput={(params: RenderInputParams) => {
          return (
            <TextField
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.keyCode === 13) {
                  if (suggestions.includes(searchValue)) onChange(searchValue);
                  else create(searchValue);
                }
              }}
              onChange={onInputChangeHandler}
              {...params}
              variant="outlined"
              rows={1}
              inputRef={inputRef}
              placeholder={selected.length ? '' : placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>{loading ? <CircularProgress color="inherit" size={20} /> : null}</React.Fragment>
                ),
                readOnly,
              }}
            />
          );
        }}
        {...mayHaveTooltip}
      />
    </div>
  );
};

export default EnhancedAutocomplete;
