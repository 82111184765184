export default (theme) => ({
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  profileUpdateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  avatarImageWrapper: {
    width: '175px',
    height: '175px',
    alignSelf: 'center',
  },
  avatarImage: {
    width: '100%',
    height: 'auto',
  },
  updateAvatarOverlay: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, .3)',
    width: '100%',
    height: '50px',
    top: '125px',
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  updateAvatarIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    color: 'rgba(255, 255, 255, 1)',
  },
  editButton: {
    position: 'absolute',
    right: theme.spacing(1),
    cursor: 'pointer',
  },
  icon: {
    color: theme.palette.blueDark,
    width: 18,
    marginRight: '10px',
  },
  profileForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileUpdateHeader: {
    ...theme.typography.h7,
  },
  textInput: {
    textAlign: 'center',
  },
  saveButton: {
    ...theme.typography.button,
    color: theme.palette.primary.contrastText,
  },
  spinner: {
    position: 'absolute',
    top: '160px',
    'z-index': '100',
  },
  text: {
    color: theme.palette.primary.contrastText,
    paddingBottom: '30px',
  },
  cssOutlinedInput: {
    ...theme.typography.body2,
    height: 36,
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  cssFocused: {},
  notchedOutline: {
    border: `2px solid ${theme.palette.common.input.outline} !important`,
  },
});
