import React, { PropsWithChildren } from 'react';
import { MenuItem } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface WorkflowItemProps {
  disabled: boolean;
  onClick: (event: any) => void;
}

function BlockMenuItem(props: PropsWithChildren<WorkflowItemProps>) {
  return (
    <MenuItem disabled={props.disabled} onClick={props.onClick}>
      <DeleteForeverIcon />
      {props.children ? props.children : 'Blocked'}
    </MenuItem>
  );
}

export default BlockMenuItem;
