import React from 'react';

import LinkOff from '@material-ui/icons/LinkOff';
import { IconButton } from '@material-ui/core';

import clsx from 'clsx';

import FilterAction from './FilterAction';
import CustomizeCardAction from './CustomizeCardAction';

import ActionTooltip from '../ActionTooltip';
import { useStyles } from './styles';

interface Props {
  readOnly: boolean;
  isRecordStyle?: boolean;
  tooltipText?: string;
  unlinkOnClick: () => void;
}

function Actions(props: Props) {
  const classes = useStyles();
  return (
    <div>
      <ActionTooltip
        text={props.tooltipText || `Remove Relationship`}
      >
        <span>
          <IconButton disabled={props.isRecordStyle} >
            <LinkOff
              id="destroy-link"
              className={clsx({ [classes.disabledMouse]: props.isRecordStyle, [classes.icon]: !props.isRecordStyle, [classes.disabledIcon]: props.readOnly })}
              onClick={props.unlinkOnClick}
            />
          </IconButton>
        </span>
      </ActionTooltip>
      <FilterAction />
      <CustomizeCardAction />
    </div>
  );
}

export default Actions;
