import React from "react";

import { YesOrNo } from "components";
import { SupplierWorkflowStatuses } from "workflows/statuses";

const TO_STATUS = SupplierWorkflowStatuses.ReadyForQualification.id;

export default {
  initialStep: "confirmTransition",
  internalSteps: {
    confirmTransition: {
      component: props => (
        <YesOrNo
          prompt={`Do you want to disable editing for the Qualification Activities tab of ${
            props.itemCustomIdentifier
          }?`}
          closeOnNo
          yesLabel="Lock"
          noLabel="Cancel"
          {...props}
        />
      ),
      getNextStep: _ => "complete"
    }
  },
  getFinalResult: ({ confirmTransition }) => {
    return confirmTransition ? { toStatus: TO_STATUS } : null;
  }
};
