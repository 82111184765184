import React from 'react';
import { get } from 'lodash';

import { CardActions } from '@material-ui/core';

import { GraphCard, VersionAndStatusDot } from 'components';
import RiskVersionCard from 'components/CardGraph/Card/RiskCard';

function SetVersion({ onClick, selected, version, otherTraces }) {
  const isRiskVersion = get(version, 'item.itemType.name') === 'risk';

  return (
    <GraphCard
      item={{
        customIdentifier: version.item.customIdentifier,
        id: version.item.id,
        itemType: version.item.itemType.name,
        title: version.title,
      }}
      otherConnections={otherTraces}
      selected={selected}
      summary={version.stringified}
    >
      {isRiskVersion && <RiskVersionCard version={version} />}
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <VersionAndStatusDot
          style={{ fontSize: 13 }}
          version={version.versionIdentifier}
          status={version.currentStatus.name}
        />
      </CardActions>
    </GraphCard>
  );
}

export default SetVersion;
