import React, { ReactNode } from 'react';

import { Link } from '@material-ui/core';

import { navigate } from '@reach/router';
import get from 'lodash/get';

import linkToItem from 'utils/linkToItem';
import Views from 'workspaces/views';

type View = keyof typeof Views;

// if `item` is pased here then this example graphql queries data that needs to be avaliable:
// item { id itemType { id displaySlug userCreatable }}
interface Props {
  item?: any;
  href?: string | undefined;
  children: ReactNode;
  params?: {
    displayOnly?: boolean;
    view?: View;
  };
  className?: string;
  newTab?: boolean;
}

export function itemLink(item) {
  const slug = get(item, 'itemType.displaySlug', null);
  const withId = get(item, 'itemType.userCreatable', false);
  const id = get(item, 'id', null);

  if (slug == null) return '';

  return linkToItem(slug, withId && id);
}

const MakeLink = (props: Props): any => {
  const { item, params } = props;

  const viewParam = params && params.view ? `?view=${params.view}` : '';
  const displayOnly = params && !!params.displayOnly;

  let href = props.href ? props.href : itemLink(item);
  if (!href) return null;

  href += viewParam;

  const onClick: any = props.newTab
    ? undefined
    : (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(href).catch((err) => {
          throw err;
        });
      };

  const target = props.newTab ? '_blank' : undefined;

  return displayOnly ? (
    props.children
  ) : (
    <Link
      href={href}
      onClick={onClick}
      color="inherit"
      target={target}
      classes={{
        root: props.className,
      }}
    >
      {props.children}
    </Link>
  );
};

export default MakeLink;
