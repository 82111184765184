import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: any) => ({
  noItemsPanel: {
    padding: theme.spacing(1),
    border: "none",
  },
  tableContainer: {
    marginBottom: theme.spacing(1)
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
