import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Event from '@material-ui/icons/Event';
import { Tooltip, withStyles } from '@material-ui/core';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import styles from './styles';

function EffectiveDate({ classes, timestamps, i18n }) {
  function formattedReleasedDate() {
    if (!timestamps) return 'TBD';

    const months = [
      i18n._(t`January`),
      i18n._(t`February`),
      i18n._(t`March`),
      i18n._(t`April`),
      i18n._(t`May`),
      i18n._(t`June`),
      i18n._(t`July`),
      i18n._(t`August`),
      i18n._(t`September`),
      i18n._(t`October`),
      i18n._(t`November`),
      i18n._(t`December`),
    ];

    let date = new Date(
      `${timestamps.map((timestamp) => timestamp.releasedAt)}`,
    );
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    return months[month] + '-' + day + '-' + year;
  }

  return (
    <div className={classes.calendarContainer}>
      <Tooltip
        classes={{ tooltip: classes.tooltipContainer }}
        className={classes.tooltipContainer}
        title={
          <div className={classes.tooltipText}>
            <Trans>
              Effective Date <br /> {formattedReleasedDate()}
            </Trans>
          </div>
        }
      >
        <Event className={classes.calendarIcon} />
      </Tooltip>
    </div>
  );
}

EffectiveDate.propTypes = {
  classes: PropTypes.shape({
    calendarContainer: PropTypes.string.isRequired,
    calendarIcon: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(
  withI18n(),
  withStyles(styles),
)(EffectiveDate);
