import React, { useState, useContext } from 'react';

import { Typography, Link } from '@material-ui/core';
import { AttachFile, Add, Clear, Notes, School } from '@material-ui/icons';

import Panel from 'components/core/Panel';
import Buttons from 'components/core/Buttons';
import Input from 'components/core/form/Input';
import IconButton from 'components/IconButton';

import Attachments from './Attachments';
import { useStyles } from './styles';
import { updateCourseVersionVariables } from '../__generated__/updateCourseVersion';
import SectionLabel from 'components/core/SectionLabel';
import { LockedContext } from 'contexts/lockedContext';

interface Props {
  trainingMaterialLinks: string[];
  trainingMaterialAttachments: any[];
  courseVersionId: string;
  locked: boolean;
  save: (input: updateCourseVersionVariables) => void;
}

function TrainingMaterials(props: Props) {
  const [choosingAttachment, setChoosingAttachment] = useState(false);
  const [addingAttachment, setAddingAttachment] = useState(false);

  const { locked } = useContext(LockedContext);

  const classes = useStyles();

  const addTraingingLink = () =>
    props.save({
      id: props.courseVersionId,
      trainingMaterialLinks: [...props.trainingMaterialLinks, ''],
    });

  const updateTrainingLinks = (value: string, index: number) => {
    const copy = [...props.trainingMaterialLinks];
    copy.splice(index, 1, value);
    props.save({ id: props.courseVersionId, trainingMaterialLinks: copy });
  };

  const removeTrainingLink = (index: number) => {
    const copy = [...props.trainingMaterialLinks];
    copy.splice(index, 1);
    props.save({ id: props.courseVersionId, trainingMaterialLinks: copy });
  };

  return (
    <>
      <SectionLabel icon={School}>Training Material</SectionLabel>
      <Panel>
        <div>
          <Panel.Heading icon={AttachFile}>Training Material</Panel.Heading>
          {props.trainingMaterialLinks.length === 0 && props.trainingMaterialAttachments.length === 0 && (
            <Typography variant="caption">No files added.</Typography>
          )}
          {props.trainingMaterialLinks.map((link, index) =>
            !locked ? (
              <Input
                key={`${index}-${link}`}
                defaultValue={link}
                disabled={locked}
                placeholder="https://"
                debounced
                endAdornment={
                  !locked && (
                    <Input.Adornment>
                      <IconButton
                        Icon={Clear}
                        onClick={() => removeTrainingLink(index)}
                        size="small"
                        includeBackground
                      />
                    </Input.Adornment>
                  )
                }
                onChange={(e) => updateTrainingLinks(e.target.value, index)}
              />
            ) : (
              <Link href={link} target="_blank" rel="noopener">
                <Typography variant="body2">{link}</Typography>
              </Link>
            ),
          )}
          <Attachments
            addingAttachment={addingAttachment}
            courseVersionId={props.courseVersionId}
            onAdd={() => setAddingAttachment(false)}
            trainingMaterialAttachments={props.trainingMaterialAttachments}
            locked={props.locked}
          />
          {!locked && (
            <Buttons className={classes.actions}>
              <IconButton Icon={Add} onClick={() => setChoosingAttachment((prev) => !prev)} includeBackground />
              {choosingAttachment && (
                <>
                  <IconButton
                    Icon={Notes}
                    onClick={() => {
                      setChoosingAttachment(false);
                      addTraingingLink();
                    }}
                    size="small"
                  />
                  <IconButton
                    Icon={AttachFile}
                    onClick={() => {
                      setChoosingAttachment(false);
                      setAddingAttachment(true);
                    }}
                    size="small"
                  />
                </>
              )}
            </Buttons>
          )}
        </div>
      </Panel>
    </>
  );
}

export default TrainingMaterials;
