import React from 'react';

import { Cancel } from '@material-ui/icons';

import { VersionAndStatusDot } from 'components';

import Table from 'components/core/Table';

import useStyles from './styles';

export function DeleteIcon() {
  return <Cancel className="button" />;
}

export function VersionCell(props) {
  return (
    <div style={{ display: 'inline-block' }}>
      <VersionAndStatusDot version={props.version} status={props.status} />
    </div>
  );
}

export function VersionToItemTable(props) {
  const classes = useStyles();

  return (
    <div className={classes.versionToItemTable}>
      <Table {...props} />
    </div>
  );
}
