import React, { ReactNode } from 'react';

import clsx from 'clsx';

import { useStyles } from './styles';

interface Props {
  children: ReactNode;
  className?: string;
}

function QuotedSection(props: Props) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, props.className)}>
      <div className={classes.line} />
      <div>{props.children}</div>
    </div>
  );
}

export default QuotedSection;
