import React from 'react';
import { useMutation } from 'react-apollo';
import { Notes } from '@material-ui/icons';

import { CollapsableTextArea, LabelText, ToggleRichTextEditor, Spacer } from 'components';
import RichTextEditorContentSection from 'compositions/RichTextEditorContentSection';

import { UPDATE_DEVIATION_VERSION } from '../../gql';

interface Props {
  fields: {
    identification: string;
    investigation: string;
    conclusion: string;
    correctiveOrPreventiveActions: string;
    other: string;
    deviationSummary: string;
    deviationInfoToggle: boolean;
  };
  locked: boolean;
  versionId: string;
}

export default function DeviationInformationTab(props: Props) {
  const { fields } = props;
  const [updateDeviation] = useMutation(UPDATE_DEVIATION_VERSION, {
    refetchQueries: ['DeviationVersionQuery'],
  });

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <ToggleRichTextEditor
          richTextEditorVisible={fields.deviationInfoToggle}
          onConfirm={() => {
            const variables = { id: props.versionId, deviationInfoToggle: !fields.deviationInfoToggle };
            updateDeviation({ variables }).catch((error) => {
              throw new Error(`Failed to toggle input method: ${error.message}`);
            });
          }}
        />
      </div>
      <Spacer factor={1} />

      {fields.deviationInfoToggle ? (
        <>
          <LabelText labelText="Deviation" Icon={Notes} />
          <Spacer factor={1} />
          <RichTextEditorContentSection
            locked={props.locked}
            initialValue={fields.deviationSummary}
            fixedToolbarOffset={10}
            versionId={props.versionId}
            attrName="deviation_summary"
            refetchQueries={[]}
            optionSet="large"
          />
        </>
      ) : (
        <>
          <CollapsableTextArea
            attrName="identification"
            id="deviation-identification"
            initialValue={fields.identification}
            labelText="Identification"
            locked={props.locked}
            refetchQueries={[]}
            versionId={props.versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="investigation"
            id="deviation-investigation"
            initialValue={fields.investigation}
            labelText="Investigation"
            locked={props.locked}
            refetchQueries={[]}
            versionId={props.versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="conclusion"
            id="deviation-conclusion"
            initialValue={fields.conclusion}
            labelText="Conclusion"
            locked={props.locked}
            refetchQueries={[]}
            versionId={props.versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="corrective_or_preventive_actions"
            id="deviation-correctiveOrPreventiveActions"
            initialValue={fields.correctiveOrPreventiveActions}
            labelText="Corrective Or Preventive Actions"
            locked={props.locked}
            refetchQueries={[]}
            versionId={props.versionId}
            rows={6}
          />
          <Spacer factor={3} />
          <CollapsableTextArea
            attrName="other"
            id="deviation-other"
            initialValue={fields.other}
            labelText="Other"
            locked={props.locked}
            refetchQueries={[]}
            versionId={props.versionId}
            rows={6}
          />
        </>
      )}
    </>
  );
}
