import React from 'react';

import reduce from 'lodash/reduce';

import VersionCard from '../Card/Version';
import SetVersionCard from '../Card/SetVersion';

function isSetType(type) {
  return ['RequirementVersion', 'UserNeedVersion', 'RiskVersion', 'RiskControlVersion'].includes(type);
}

export default function Card(props) {
  const { disabled, selected, version, otherTraces } = props;
  const eligibleVersions = [version.item.currentRelease, version.item.workingVersion];

  const tracedVersion = reduce(
    eligibleVersions,
    (chosenVersion, version) => {
      if (!chosenVersion || (version && props.graph.hasVertex(version.id))) {
        return version;
      }

      return chosenVersion;
    },
    version.item.currentRelease || version.item.currentVersion,
  );

  if (isSetType(version.__typename))
    return <SetVersionCard version={version} selected={selected} otherTraces={otherTraces} />;

  // TODO: debounce() onUpdate
  return (
    <VersionCard
      currentRelease={version.item.currentRelease}
      disabled={disabled}
      onUpdate={(newId) => props.onUpdate(tracedVersion.id, newId)}
      selected={selected}
      version={tracedVersion}
      workingVersion={version.item.workingVersion}
      otherTraces={otherTraces}
    />
  );
}
