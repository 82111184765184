import { createStyles, makeStyles } from '@material-ui/core';

const width = (size) => ({ minWidth: size, width: size });

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    actions: {
      paddingBottom: 8,
      display: 'flex',
    },
    body: {
      color: 'rgba(0,0,0,0.6)',
      textAlign: 'center',
      marginBottom: 24,
      '&.left': {
        textAlign: 'left',
      },
    },
    close: {
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
    },
    closeContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '16px',
    },
    content: {
      padding: '16px 24px',
    },
    dialog: {
      position: 'relative',
      ...width(360),
      '&.large': {
        ...width(456),
      },
      '&.xlarge': {
        ...width(640),
      },
    },
    heading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 16,
      textAlign: 'center',
      color: theme.palette.secondary.main,
    },
    icon: {
      fontSize: '3rem',
      height: 48,
      marginBottom: 24,
    },
    label: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    secondaryIcon: {
      fontSize: '2.5rem',
      height: 48,
      margin: theme.spacing(0, 0, 8.75, -1),
    },
    shrunkIcon: {
      fontSize: 32,
      height: 'auto',
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
    shrunkHeader: {
      display: 'flex',
      alignItems: 'center',
      background: theme.palette.background.contextDrawerGrey,
      boxShadow: '0px 1px 3px 0px rgba(126, 161, 159, 0.28)',
      margin: theme.spacing(-2, -2, 2, -2),
      padding: theme.spacing(2),
    },
    shrunkHeadeTitle: {
      flex: 1,
    },
  }),
);
