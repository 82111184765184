import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

import Spacer from 'components/Spacer';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import { Views } from 'workspaces';

import AssociatedItems, { AssociatedItemsProps } from './AssociatedItems';
import AssociatedItemsVersions, { AssociatedVersionsProps } from './AssociatedItemsVersion';
import ReferenceAttachments, { AttachmentsProps } from './ReferenceAttachments';
import TrioControlPanel from './TrioControlPanel';

type ItemsTableProps = Pick<AssociatedItemsProps, 'handlers'>;
type VersionsTableProps = Pick<AssociatedVersionsProps, 'handlers'>;
type AttachmentsTableProps = Pick<AttachmentsProps, 'handlers'>;

interface Props {
  currentVersion: any;
  locked: boolean;
  itemsTableProps: ItemsTableProps;
  versionsTableProps: VersionsTableProps;
  attachmentsTableProps: AttachmentsTableProps;
  activeWorkspaceView: any; // from withWorkspaceViews HOC
  showItemsButton?: boolean;
  showVersionsButton?: boolean;
  showAttachmentButton?: boolean;
}

const TableTrio = (props: Props) => {
  const {
    currentVersion,
    locked,
    showItemsButton = true,
    showVersionsButton = true,
    showAttachmentButton = true,
  } = props;
  const { id, trioReferencedItems = [], trioReferencedVersions = [], trioReferencedAttachments = [] } = currentVersion;

  const [showControls, setShowControls] = useState<boolean>(false);
  const [showAssociatedItems, setShowAssociatedItems] = useState<boolean>(false);
  const [showAssociatedVersions, setShowAssociatedVersions] = useState<boolean>(false);
  const [showReferenceAttachment, setShowReferenceAttachment] = useState<boolean>(false);

  const itemsExits = !!trioReferencedItems.length;
  const versionsExits = !!trioReferencedVersions.length;
  const attachmentsExits = !!trioReferencedAttachments.length;

  const isNoReferences = !(itemsExits || versionsExits || attachmentsExits);
  const isEffectiveView =
    props.activeWorkspaceView.id === Views.Effective.id || props.activeWorkspaceView.id === Views.Versions.id;
  const readOnly = isEffectiveView || locked;

  return (
    <Box>
      {(versionsExits || showAssociatedVersions) && (
        <>
          <AssociatedItemsVersions
            versionId={id}
            associatedVersions={trioReferencedVersions}
            handlers={props.versionsTableProps.handlers}
            locked={locked || isEffectiveView}
          />
          <Spacer factor={4} />
        </>
      )}
      {(itemsExits || showAssociatedItems) && (
        <>
          <AssociatedItems
            locked={locked || isEffectiveView}
            versionId={id}
            associatedItems={trioReferencedItems}
            handlers={props.itemsTableProps.handlers}
          />
          <Spacer factor={4} />
        </>
      )}
      {(attachmentsExits || showReferenceAttachment) && (
        <>
          <ReferenceAttachments
            locked={locked || isEffectiveView}
            versionId={id}
            handlers={props.attachmentsTableProps.handlers}
            referencedAttachments={trioReferencedAttachments}
          />
          <Spacer factor={4} />
        </>
      )}
      {isEffectiveView && isNoReferences && (
        <Typography variant={'body2'}>Not applicable. No reference materials have been included.</Typography>
      )}
      {readOnly && isNoReferences && <Typography variant={'body2'}>No items added.</Typography>}
      {!readOnly && (
        <TrioControlPanel
          opened={showControls}
          showItemsButton={showItemsButton && !itemsExits}
          showVersionsButton={showVersionsButton && !versionsExits}
          showAttachmentButton={showAttachmentButton && !attachmentsExits}
          handleToggle={() => setShowControls(!showControls)}
          toggleItemsTable={() => setShowAssociatedItems(!showAssociatedItems)}
          toggleVersionsTable={() => setShowAssociatedVersions(!showAssociatedVersions)}
          toggleAttachmentsTable={() => setShowReferenceAttachment(!showReferenceAttachment)}
        />
      )}
    </Box>
  );
};

export default withWorkspaceViews()(TableTrio);
