import React, { Component } from 'react';

import { Checkbox, FormControlLabel, Typography, withStyles } from '@material-ui/core';

import { Trans } from '@lingui/macro';

import styles from './styles';
import { Spacer } from 'components';
import Signature from './Signature';

const CERTIFY_TEXT = 'I certify that I have reviewed all relevant comments and edits associated with this item.';

export interface CertifiedSignatureProps {
  classes;
  dialog: string;
  error: any;
  errorDisplay: boolean;
  checkboxDisplay?: boolean;
  heading: string;
  handleComplete: (param: any) => void;
  certifyText?: string;
  itemTypeMetadata: any;
}

export class CertifiedSignature extends Component<CertifiedSignatureProps> {
  state = {
    username: null,
    password: null,
    agreement: null,
  };

  renderHeading() {
    const { classes, heading } = this.props;
    return (
      <div className={classes.heading}>
        <Typography variant="h3">{heading}</Typography>
      </div>
    );
  }

  renderBody() {
    const { dialog } = this.props;
    return <Typography variant="body2">{dialog}</Typography>;
  }

  render() {
    const {
      classes,
      dialog,
      error,
      errorDisplay,
      handleComplete,
      certifyText = CERTIFY_TEXT,
      checkboxDisplay = true,
    } = this.props;

    const { username, password, agreement } = this.state;

    return (
      <>
        <Spacer factor={1} />

        {this.renderHeading()}

        <Spacer factor={2} />

        {this.renderBody()}

        <Spacer factor={3} />

        {checkboxDisplay && (
          <FormControlLabel
            id="signature-checkbox"
            control={<Checkbox color="primary" className={classes.checkbox} />}
            label={
              <Typography variant="caption" component="p">
                <Trans>{certifyText}</Trans>
              </Typography>
            }
            classes={{ root: classes.checkboxContainer }}
            onChange={(e: React.ChangeEvent<any>) => this.setState({ agreement: e.target.checked })}
          />
        )}

        <Spacer factor={5} />

        <Signature
          itemType={this.props.itemTypeMetadata.name}
          error={error}
          errorDisplay={errorDisplay}
          disabled={
            checkboxDisplay
              ? !agreement || !this.state.password || !this.state.username
              : !this.state.password || !this.state.username
          }
          handleSubmit={() => {
            handleComplete({
              username,
              password,
              reasonForSignature: dialog,
            });
          }}
          onUsernameChange={(e) => this.setState({ username: e.target.value })}
          onPasswordChange={(e) => this.setState({ password: e.target.value })}
        />
      </>
    );
  }
}

export default withStyles(styles as any)(CertifiedSignature);
