import React from 'react';

import Close from '@material-ui/icons/Close';
import { withStyles, Button, Paper, Typography } from '@material-ui/core';
import { Spacer } from 'components';

import { getAuthToken, getBaseApiUrl } from 'utils';

import PDFViewer from 'components/PDFViewer';

import styles from './styles';

interface PDFExportDialogProps {
  classes: any;
  heading?: string;
  onClose: () => void;
  versionId: string;
  subheading?: string;
  showIdsInExport?: boolean;
  showTitlesInExport?: boolean;
  isGroupedByFolder?: boolean;
  isGroupedByRequirementType?: boolean;
  isGroupedByStakeholders?: boolean;
  landscapeView: boolean;
}

class PDFExportDialog extends React.Component<PDFExportDialogProps, any> {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  printURL: URL;
  attachmentURL: URL;

  constructor(props) {
    super(props);

    // window.location.origin is needed becasue getBaseApiUrl() returns '' in dev
    const base = new URL(getBaseApiUrl() || window.location.origin);
    base.searchParams.append('id', props.versionId);
    base.searchParams.append('source', window.location.toString());

    this.attachmentURL = new URL(base.toString());
    // Setting the path in the constructor will remove base's searchParams
    this.attachmentURL.pathname = '/exports.pdf';
    this.attachmentURL.searchParams.append('disposition', 'attachment');

    this.attachmentURL.searchParams.append('orientation', props.landscapeView ? 'Landscape' : 'Portrait');

    if (props.showIdsInExport || props.showTitlesInExport) {
      this.attachmentURL.searchParams.append('traseMatrixExportOption', props.showIdsInExport ? 'id' : 'title');
    }
    if (props.isGroupedByRequirementType) {
      this.attachmentURL.searchParams.append('setExportOption', 'requirementType');
    }
    if (props.isGroupedByStakeholders) {
      this.attachmentURL.searchParams.append('setExportOption', 'stakeholders');
    }
    if (props.isGroupedByFolder) {
      this.attachmentURL.searchParams.append('setExportOption', 'folder');
    }
    props.resetExportOptionsValues();

    this.printURL = new URL(base.toString());
    this.printURL.pathname = '/exports/print';

    this.iframeRef = React.createRef<HTMLIFrameElement>();
  }

  handleSave = () => {
    window.open(this.urlWithToken(this.attachmentURL));
  };

  handlePrint = () => {
    const iframe = this.iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage('print', this.urlWithToken(this.printURL));
    }
  };

  // Token is automatically refreshed so we retrieve it every time
  urlWithToken = (url: URL) => {
    url.searchParams.set('token', getAuthToken()[0] || '');
    return url.toString();
  };

  render() {
    const { classes, heading, onClose, subheading } = this.props;

    return (
      <div className={classes.popup}>
        <Paper classes={{ root: classes.container }}>
          <Close className={classes.closeIcon} onClick={onClose} color="action" />

          <div className={classes.overview}>
            <div className={classes.details}>
              <Typography variant="h2">Print or Save as PDF</Typography>

              {heading !== undefined && <Typography variant="body2">{heading}</Typography>}

              {subheading !== undefined && <Typography variant="body2">{subheading}</Typography>}

              <div className={classes.buttons}>
                <Button className={classes.saveButton} color="primary" onClick={this.handleSave} variant="outlined">
                  Save as PDF
                </Button>

                <Spacer factor={1} />

                <Button className={classes.printButton} color="primary" variant="contained" onClick={this.handlePrint}>
                  Print
                </Button>
              </div>
            </div>

            <div className={classes.pdfViewer}>
              <PDFViewer
                disableFullscreen={true}
                url={this.urlWithToken(this.attachmentURL)}
                documentClass={classes.viewerDocumentPassed}
              />
            </div>
          </div>

          <iframe
            title="Print PDF"
            style={{ display: 'none' }}
            src={this.urlWithToken(this.printURL)}
            ref={this.iframeRef}
          />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(PDFExportDialog);
