import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Lock from '@material-ui/icons/Lock';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { buildConditionalClasses } from 'utils';

import NavLink from './NavLink';
import styles from './styles';

function isActiveCategory(props) {
  return get(props, 'activeCategory.slug') === get(props, 'category.slug');
}

function isParentOfActiveCategory(props) {
  const activeCategoryParentSlug = get(props, 'activeCategory.parentSlug');
  const categorySlug = get(props, 'category.slug');
  return activeCategoryParentSlug === categorySlug;
}

function isActiveOrHighlighted(props) {
  if (!props.category.disabled) {
    return isActiveCategory(props) || isParentOfActiveCategory(props);
  }
  return false;
}

class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: isActiveOrHighlighted(props),
    };
  }

  buildListItemClasses = () => {
    const { classes, category, disabledCategoriesSet, nested } = this.props;
    return buildConditionalClasses([
      [disabledCategoriesSet.has(category.slug), classes.listItemDisabled],
      [true, classes.listItem],
      [isParentOfActiveCategory(this.props), classes.listItemHighlighted],
      [nested, classes.nested],
    ]);
  };

  toggleExpanded = (event) => {
    if (this.state.expanded || isActiveOrHighlighted(this.props)) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState(({ expanded }) => ({ expanded: !expanded }));
  };

  renderChildrenExpander = () => {
    const { category, classes, disabledCategoriesSet, i18n } = this.props;
    if (!disabledCategoriesSet.has(category.slug) && !isEmpty(get(category, 'children'))) {
      return (
        <ListItemSecondaryAction style={{ right: '-5px' }}>
          <IconButton
            aria-label={
              this.state.expanded
                ? i18n._(t`Collapse categories under ${category.name}`)
                : i18n._(t`Expand categories under ${category.name}`)
            }
            className={classes.listItemSecondaryActionButton}
            onClick={this.toggleExpanded}
          >
            {this.state.expanded ? (
              <ExpandLess style={{ color: '#6E7585' }} data-testid="parent-category-collapser" />
            ) : (
              <ExpandMore style={{ color: '#6E7585' }} data-testid="parent-category-expander" />
            )}
          </IconButton>
        </ListItemSecondaryAction>
      );
    }
    return null;
  };

  render() {
    const { category, classes, disabledCategoriesSet, enabledCategoriesSet, nested, path } = this.props;

    if (category.traceOnly) return null;
    const Icon = category.icon;
    const visibleCategoriesSet = new Set([...disabledCategoriesSet, ...enabledCategoriesSet]);

    return (
      <>
        <ListItem button className={this.buildListItemClasses()} disableRipple disableTouchRipple>
          {disabledCategoriesSet.has(category.slug) ? (
            <>
              <ListItemIcon className={classes.listItemIcon}>
                <Icon />
              </ListItemIcon>
              <ListItemText
                className={buildConditionalClasses([
                  [isActiveCategory(this.props), classes.listItemTextActive],
                  [!isActiveCategory(this.props), classes.listItemText],
                  [disabledCategoriesSet.has(category.slug), classes.listItemTextDisabled],
                ])}
                primary={<Trans id={category.name} />}
                disableTypography
              />
              <Lock color="inherit" className={classes.lockedItemIcon} />
            </>
          ) : (
            <NavLink
              id={`category-${category.slug}`}
              to={category.children && !category.isDashboard ? '#' : path}
              onClick={() => {
                !this.state.expanded && this.setState({ expanded: true });
              }}
              forceDisplayType={isActiveCategory(this.props) ? 'current' : 'not_current'}
            >
              <ListItemIcon
                className={classes.listItemIcon}
                style={{ color: isActiveCategory(this.props) ? 'inherit' : '#6E7585' }}
              >
                <Icon />
              </ListItemIcon>
              <ListItemText
                className={buildConditionalClasses([
                  [isActiveCategory(this.props), classes.listItemTextActive],
                  [!isActiveCategory(this.props), classes.listItemText],
                ])}
                primary={<Trans id={category.name} />}
                disableTypography
              />
              {this.renderChildrenExpander()}
            </NavLink>
          )}
        </ListItem>

        {category.children && (
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              classes={{
                root: buildConditionalClasses([[nested, classes.nested]]),
              }}
            >
              {Object.values(category.children).map(
                (subcategory, index) =>
                  visibleCategoriesSet.has(subcategory.slug) && (
                    <Category
                      activeCategory={this.props.activeCategory}
                      category={subcategory}
                      disabledCategoriesSet={disabledCategoriesSet}
                      enabledCategoriesSet={enabledCategoriesSet}
                      classes={classes}
                      key={subcategory.slug + index}
                      nested
                      path={`/category/${subcategory.slug}`}
                    />
                  ),
              )}
            </List>
          </Collapse>
        )}
      </>
    );
  }
}

Category.propTypes = {
  classes: PropTypes.shape({
    listItem: PropTypes.string.isRequired,
    listItemDisabled: PropTypes.string.isRequired,
    listItemHighlighted: PropTypes.string.isRequired,
    listItemIcon: PropTypes.string.isRequired,
    listItemText: PropTypes.string.isRequired,
    listItemTextDisabled: PropTypes.string.isRequired,
    lockedItemIcon: PropTypes.string.isRequired,
    nested: PropTypes.string.isRequired,
  }),
  path: PropTypes.string.isRequired,
  nested: PropTypes.bool,
};

Category.defaultProps = {
  activeCategory: undefined,
  nested: false,
};

export default compose(
  setDisplayName('Category'),
  withI18n(),
  withStyles(styles),
)(Category);
