import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { WorkflowIcon } from 'components';
import Panel from 'components/core/Panel';
import { useStyles } from './styles';
import { dashboard_trainingDashboard as DashboardCounts } from '../__generated__/dashboard';

const StatusCounts: React.FC<{ data: DashboardCounts }> = (props) => {
  const { data } = props;
  const classes = useStyles();

  const statusPanel = (title: string, status: keyof DashboardCounts) => {
    return (
      <Grid item xs={3} key={status}>
        <Panel>
          <div className={classes.statusTitle}>
            <WorkflowIcon type={status} completed size="small" />
            <Typography variant="h5" style={{ whiteSpace: 'nowrap' }}>
              {title}
            </Typography>
          </div>
          <div className={classes.statusCount}>
            <Typography variant="h5">{data[status]}</Typography>
          </div>
        </Panel>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      {statusPanel('To Do', 'toDo')}
      {statusPanel('Completed', 'completed')}
      {statusPanel('Checked', 'checked')}
      {statusPanel('Failed', 'failed')}
    </Grid>
  );
};

export default StatusCounts;
