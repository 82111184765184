import gql from 'graphql-tag';
import React from 'react';
import { get, head } from 'lodash';
import { Query, useQuery } from 'react-apollo';
import userFields from 'utils/gql/userFields';
import { currentUser } from './__generated__/currentUser';

const query = gql`
  query currentUser {
    currentUser {
      ...userFields
    }
  }
  ${userFields}
`;

export function currentUserJobRole(user) {
  if (user && user.jobRoles) return get(head(user.jobRoles), 'roleName', '');
  return [];
}

export const useCurrentUser = () => useQuery<currentUser>(query);

export default (Component) => {
  return (props) => (
    <Query query={query}>
      {({ data, loading, error, refetch }) => {
        if (loading) return <></>;
        if (error) console.log(error);

        const user = get(data, 'currentUser');
        const jobRole = currentUserJobRole(user);
        return (
          <Component
            currentUserRefetch={refetch}
            currentUser={{
              ...user,
              jobRole,
            }}
            {...props}
          />
        );
      }}
    </Query>
  );
};
