import { useReducer } from 'react';

export enum ModalState {
  Unlink,
  Add,
  Closed,
}

interface State {
  modal: ModalState;
  selectedCourses: Set<string>;
  error: boolean;
}

export type Action =
  | { type: 'SelectCourse' | 'DeselectCourse'; val: string }
  | { type: 'SetModal'; val: ModalState }
  | { type: 'SetError' }
  | { type: 'Clear' };

const init = () => ({
  modal: ModalState.Closed,
  selectedCourses: new Set<string>(),
  error: false,
});

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SelectCourse':
      var selectedCourses = new Set(state.selectedCourses).add(action.val);
      return { ...state, selectedCourses };
    case 'DeselectCourse':
      // eslint-disable-next-line no-redeclare
      var selectedCourses = new Set(state.selectedCourses);
      selectedCourses.delete(action.val);
      return { ...state, selectedCourses };
    case 'SetModal':
      return action.val === ModalState.Closed
        ? { ...state, modal: ModalState.Closed, error: false }
        : { ...state, modal: action.val };
    case 'SetError':
      return { ...state, error: true };
    case 'Clear':
      return init();
    default:
      return state;
  }
};

export default () => useReducer(reducer, init());
