import React, { ComponentType, useState } from 'react';
import { createStyles, Typography, withStyles, SvgIconProps } from '@material-ui/core';

import Button from 'components/Button';

import { Spacer } from 'components';

const styles = createStyles((theme) => ({
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: theme.spacing(-1.5),
    marginLeft: theme.spacing(-1.5),
  },
  icon: {
    minWidth: 312,
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '3rem',
    marginBottom: 12,
  },
  heading: {
    clear: 'both',
    paddingLeft: '1px',
    paddingRight: '1px',
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    minWidth: '312px',
  },
  errorText: {
    marginBottom: theme.spacing(3),
  },
}));

interface YesOrNoProps {
  classes;
  closeOnNo?: boolean;
  disableOnSubmit?: boolean;
  disableNo?: boolean;
  disableYes?: boolean;
  error?: string;
  handleComplete?: any;
  handleClose?: any;
  headingWithoutPromptText?: any;
  heading?: string;
  yesLabel?: string;
  noLabel?: string;
  prompt?: string | JSX.Element;
  Icon?: ComponentType<SvgIconProps>;
  loading?: boolean;
}

const YesOrNo = ({
  classes,
  closeOnNo = false,
  disableOnSubmit,
  disableNo = false,
  disableYes = false,
  error,
  handleComplete,
  handleClose,
  headingWithoutPromptText = false,
  heading,
  prompt,
  noLabel,
  yesLabel,
  Icon,
  loading,
}: YesOrNoProps) => {
  const [disabledYes, setDisabledYes] = useState(disableYes);
  const [disabledNo, setDisabledNo] = useState(disableNo);
  const [loadingNo, setLoadingNo] = useState(false);

  return (
    <>
      {Icon && <Icon className={classes.icon} />}
      <div className={classes.heading}>
        {heading && <Typography variant="h3">{heading}</Typography>}
        <Spacer factor={1} />
        <Typography variant="body2">{prompt}</Typography>
      </div>

      {headingWithoutPromptText ? <Spacer factor={6} /> : <Spacer factor={2} />}

      {error && (
        <Typography variant="body2" color={'error'} component="div" className={classes.errorText}>
          {error}
        </Typography>
      )}

      <div style={{ display: 'flex' }}>
        <Button
          data-testid="btn-no"
          id="btn-no"
          style={{ width: '50%', marginRight: '5px', padding: 0 }}
          onClick={() => {
            setLoadingNo(true);
            setDisabledNo(true);
            if (closeOnNo || !handleComplete) {
              handleClose();
              setLoadingNo(false);
              setDisabledNo(false);
            } else {
              handleComplete && handleComplete(false);
              setLoadingNo(false);
              setDisabledNo(false);
            }
          }}
          color="primary"
          variant="outlined"
          disabled={disabledNo}
          loading={loadingNo}
        >
          {noLabel || 'No'}
        </Button>
        <Button
          data-testid="btn-yes"
          id="btn-yes"
          style={{ width: '50%', marginLeft: '5px', padding: 0 }}
          variant="contained"
          color="primary"
          fullWidth
          disabled={disabledYes}
          onClick={() => {
            handleComplete && handleComplete(true);
            if (disableOnSubmit) setDisabledYes(!disabledYes);
          }}
          loading={loading}
        >
          {yesLabel || 'Yes'}
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(YesOrNo);
