import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { compose, setDisplayName } from 'recompose';

import { t, Trans } from '@lingui/macro';
import { camelCase, get, isUndefined, startCase, values, snakeCase } from 'lodash';
import clsx from 'clsx';

import { Drawer, List, ListItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core';
import { People, Error, Domain, School, Settings, Description, ThumbUp } from '@material-ui/icons';
import AdminPanelSettingsIcon from 'assets/icons/AdminPanelSettings';
import SettingsApplicationsIcon from 'assets/icons/SettingsApplications';
import UserManagementIcon from 'assets/icons/UserManagement';
import RoleManagementIcon from 'assets/icons/RoleManagement';
import ReportIcon from '@material-ui/icons/Report';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import LOGO from 'assets/img/nav-logo.svg';
import Toolbox from 'assets/icons/Toolbox';
import LibraryAddCheck from 'assets/icons/LibraryAddCheck';

import { Intercom, Logo } from 'components';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import itemTypeToCategory from 'categories/itemTypeToCategory';
import { TreeDrawerContext } from 'contexts/treeDrawerContext';
import WorkspaceSelector from 'components/WorkspaceSelector';
import { NavigationContext } from 'contexts/navigationContext';
import { SidebarWorkspacesContext } from 'contexts/workspacesContext';
import CollapseButton from 'compositions/Sidebar/CollapseButton';
import { withActiveCategory, withDisabledCategories, withEnabledCategories } from 'compositions/WithCategories';
import ItemTypes from 'itemTypes';
import { buildConditionalClasses } from 'utils';
// import { SORTED_ITEM_TYPES_BY_PRODUCTS } from 'disciplines/sortedItemTypesByProducts';

import Category from './Category';
import NavLink from './NavLink';
import SystemValidated from './SystemValidated';

import styles from './styles';
import groupBy from 'lodash/groupBy';
import {
  getDisciplineIcon,
  getProductIcon,
  SORTED_ITEM_TYPES_BY_PRODUCTS,
  SORTED_ITEM_TYPES_BY_DISCIPLINES,
} from './NavigationConfig';
import pagesListToInsert from './pages';

function Sidebar({
  activeCategory,
  // appWindowShortLabel,
  // AppWindowIcon,
  currentCompanyValidatedSystem,
  categoryTree,
  classes,
  disabledCategories,
  enabledCategories,
  logo,
  allItemTypeMetadata,
}) {
  const categories = categoryTree || [];
  const disabledCategoriesSet = new Set(disabledCategories);
  const enabledCategoriesSet = new Set(enabledCategories);
  // @ts-ignore
  const visibleCategoriesSet = new Set([...disabledCategoriesSet, ...enabledCategoriesSet]);

  const { expanded, setExpanded } = useContext(TreeDrawerContext);
  const { selectedNavigationItem } = useContext(NavigationContext);
  const { workspace } = useContext(SidebarWorkspacesContext);

  const selectedDisciplinesList = (discipline) => {
    if (discipline && discipline.childrenList) {
      return discipline.childrenList;
    } else if (discipline && discipline.name) {
      return [discipline.name];
    } else {
      return [];
    }
  };

  const disciplinesList = selectedDisciplinesList(selectedNavigationItem);
  const selectedDisciplineItemTypes = () => {
    return values(allItemTypeMetadata).filter((itemType) => disciplinesList.includes(itemType.discipline));
  };

  const names = selectedDisciplineItemTypes().map((dit) => dit.name);

  const itemTypes = values(ItemTypes);
  const preCategories = itemTypes.filter((it) => {
    return names.map((n) => camelCase(n)).includes(it.itemType);
  });

  const allCategories: any[] = itemTypes
    .sort((a, b) => a.displaySlug.toString().localeCompare(b.displaySlug.toString()))
    .map((cat) => itemTypeToCategory(cat));

  const disciplineCategories =
    selectedNavigationItem.name === 'utilities'
      ? [
          {
            icon: Toolbox,
            name: t`Utilities Home`,
            slug: 'utilities-home',
          },
          itemTypeToCategory(ItemTypes.Task),
          {
            icon: People,
            name: t`Item Version Index`,
            slug: 'item-version-index',
          },
          {
            icon: People,
            name: t`Team Directory`,
            slug: 'employees',
            excludeFromCategoryIndex: false,
          },
          {
            icon: Description,
            name: t`Training Report`,
            slug: 'training-report',
          },
          {
            icon: ThumbUp,
            name: t`Approved Suppliers`,
            slug: 'approved-supplier',
          },
          {
            icon: ReportIcon,
            name: 'Harms Library',
            slug: 'harms',
          },
          {
            icon: ReportProblemIcon,
            name: t`Hazards Library`,
            slug: 'hazards',
          },
          {
            icon: Settings,
            name: t`My User Settings`,
            slug: 'user-settings',
          },
          {
            icon: AdminPanelSettingsIcon,
            name: t`Administration`,
            slug: 'administration',
            children: [
              {
                icon: SettingsApplicationsIcon,
                name: t`General Settings`,
                slug: 'general-settings',
              },
              {
                icon: UserManagementIcon,
                name: t`User Management`,
                slug: 'user-management',
              },
              {
                icon: RoleManagementIcon,
                name: t`Role Management`,
                slug: 'role-management',
              },
            ],
          },
        ]
      : selectedNavigationItem.name === 'dashboard'
      ? [
          {
            icon: LibraryAddCheck,
            name: t`Precompliance Dashboard`,
            slug: 'precompliance-dashboard',
          },
          {
            icon: School,
            name: t`Training Dashboard`,
            slug: 'training-dashboard',
          },
          {
            icon: Domain,
            name: t`Supplier Dashboard`,
            slug: 'supplier-management',
          },
          {
            icon: Error,
            name: t`Risk Dashboard`,
            slug: 'risk-management',
          },
        ]
      : preCategories.map((cat) => itemTypeToCategory(cat));
  const isDefault = !Boolean(activeCategory);
  const moduleDashboard = categories.find((c) => c.isModuleDashboard);
  const isActive =
    !(get(activeCategory, 'slug') === 'training-dashboard') &&
    (isDefault || get(moduleDashboard, 'slug') === get(activeCategory, 'slug'));
  // @ts-ignore
  // const moduleRootLink = moduleDashboard ? `/category/${moduleDashboard.slug}` : '/';
  const isDashboardShow = !isUndefined(selectedNavigationItem.dashboardSlug) && workspace !== 'alphabetical';

  const getCategoriesList = (workspace, disciplineName: string) => {
    if (disciplineName === 'document_control' || disciplineName === 'quality_management') {
      if (workspace === 'alphabetical') return allCategories;
      const groupedByDisciplines = groupBy(values(allItemTypeMetadata), 'discipline');
      const filteredGroupedByDiscipline = Object.keys(groupedByDisciplines)
        .filter((key) => disciplinesList.includes(key))
        .map((key) => {
          const array = groupedByDisciplines[key];
          const sortedItemTypesObject = SORTED_ITEM_TYPES_BY_DISCIPLINES.find((obj) => obj.disciplineName === key);
          if (sortedItemTypesObject) {
            array.sort((a, b) => {
              const order = sortedItemTypesObject.itemTypes;
              return order.indexOf(a.name) - order.indexOf(b.name);
            });
          }
          return { key, array };
        });

      const categoriesByDisciplines: any[] = [];
      filteredGroupedByDiscipline.forEach(({ key, array }) => {
        const catNames = array.map((dit) => dit.name);
        let dCategories = itemTypes.filter((it) => {
          return catNames.map((n) => camelCase(n)).includes(it.itemType);
        });
        const sortedItemTypesObject = SORTED_ITEM_TYPES_BY_DISCIPLINES.find((obj) => obj.disciplineName === key);
        if (sortedItemTypesObject) {
          dCategories = dCategories.sort((a, b) => {
            const order = sortedItemTypesObject.itemTypes;
            return order.indexOf(snakeCase(a.itemType)) - order.indexOf(snakeCase(b.itemType));
          });
        }
        // @ts-ignore
        dCategories = dCategories.map((cat) => itemTypeToCategory(cat));
        categoriesByDisciplines.push({
          icon: getDisciplineIcon(key),
          name: startCase(key),
          slug: `${key}-dashboard`,
          children: dCategories,
        });
      });

      const sortedCategoriesByDisciplines = categoriesByDisciplines.sort((a, b) => {
        const aIndex = disciplinesList.indexOf(a.slug.split('-')[0]);
        const bIndex = disciplinesList.indexOf(b.slug.split('-')[0]);
        return aIndex - bIndex;
      });

      return sortedCategoriesByDisciplines;
    }
    return disciplineCategories;
  };

  const getCategoriesListByProducts = () => {
    const groupedByProducts = groupBy(values(allItemTypeMetadata), 'product');
    const filteredGroupedByProduct = Object.keys(groupedByProducts)
      .filter((key) => disciplinesList.includes(key))
      .map((key) => {
        const array = groupedByProducts[key];
        const sortedByProductsObject = SORTED_ITEM_TYPES_BY_PRODUCTS.find((obj) => obj.productName === key);
        if (sortedByProductsObject) {
          array.sort((a, b) => {
            const order = sortedByProductsObject.itemTypes;
            return order.indexOf(a.name) - order.indexOf(b.name);
          });
        }
        return { key, array };
      });
    const categoriesByProducts: any[] = [];
    filteredGroupedByProduct.forEach(({ key, array }) => {
      const catNames = array.map((dit) => dit.name);
      let dCategories = itemTypes.filter((it) => {
        return catNames.map((n) => camelCase(n)).includes(it.itemType);
      });

      // @ts-ignore
      dCategories.push(...pagesListToInsert(key));

      const sortedByProductsObject = SORTED_ITEM_TYPES_BY_PRODUCTS.find((obj) => obj.productName === key);
      if (sortedByProductsObject) {
        dCategories = dCategories.sort((a, b) => {
          const order = sortedByProductsObject.itemTypes;
          return order.indexOf(snakeCase(a.itemType)) - order.indexOf(snakeCase(b.itemType));
        });
      }
      // @ts-ignore
      dCategories = dCategories.map((cat) => itemTypeToCategory(cat));
      categoriesByProducts.push({
        icon: getProductIcon(key),
        name: startCase(key),
        slug: `${key}-dashboard`,
        children: dCategories,
      });
    });
    const sortedCategoriesByProducts = categoriesByProducts.sort((a, b) => {
      const aIndex = disciplinesList.indexOf(a.slug.split('-')[0]);
      const bIndex = disciplinesList.indexOf(b.slug.split('-')[0]);
      return aIndex - bIndex;
    });

    return sortedCategoriesByProducts;
  };

  const Icon = selectedNavigationItem.Icon;
  return (
    <Drawer
      anchor={'left'}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: expanded,
        [classes.drawerClose]: !expanded,
      })}
      classes={{ paper: classes.drawerPaper }}
      variant="persistent"
      open={expanded}
    >
      <Intercom />
      <span className={classes.offset} />
      <div className={classes.sidebarWrapper}>
        <Logo src={logo} classes={classes}>
          <SystemValidated currentCompanyValidatedSystem={currentCompanyValidatedSystem} />
        </Logo>
        <WorkspaceSelector />
        <div className={classes.navWrapper}>
          <List component="nav">
            <ListItem button className={classes.listItem} disableRipple disableTouchRipple>
              {isDashboardShow && (
                <NavLink
                  to={selectedNavigationItem.dashboardSlug}
                  forceDisplayType={isActive ? 'current' : 'not_current'}
                >
                  <ListItemIcon className={classes.listItemIcon} style={{ color: isActive ? 'inherit' : '#6E7585' }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    className={buildConditionalClasses([
                      [isActive, classes.listItemTextActive],
                      [!isActive, classes.listItemText],
                    ])}
                    disableTypography
                    primary={
                      <>
                        {selectedNavigationItem.title === 'Dashboard' ? (
                          <Trans>Main Dashboard</Trans>
                        ) : (
                          <>
                            {selectedNavigationItem.title} <Trans>Home</Trans>
                          </>
                        )}
                      </>
                    }
                  />
                </NavLink>
              )}
            </ListItem>
            {selectedNavigationItem.name === 'document_control' || selectedNavigationItem.name === 'quality_management'
              ? getCategoriesList(workspace, selectedNavigationItem.name).map((category) => {
                  const isShowCategory = (cat) => {
                    if (!!cat.children) {
                      for (let i = 0; i < cat.children.length; i++) {
                        if (visibleCategoriesSet.has(cat.children[i].slug)) {
                          return true;
                        }
                      }
                      return false;
                    }
                    return visibleCategoriesSet.has(cat.slug);
                  };

                  return (
                    isShowCategory(category) && (
                      <Category
                        activeCategory={activeCategory}
                        disabledCategoriesSet={disabledCategoriesSet}
                        enabledCategoriesSet={enabledCategoriesSet}
                        category={category}
                        key={category.slug}
                        path={`/category/${category.slug}`}
                      />
                    )
                  );
                })
              : selectedNavigationItem.name === 'product'
              ? getCategoriesListByProducts().map((category) => {
                  const isShowCategory = (cat) => {
                    if (!!cat.children) {
                      for (let i = 0; i < cat.children.length; i++) {
                        if (visibleCategoriesSet.has(cat.children[i].slug)) {
                          return true;
                        }
                      }
                      return false;
                    }
                    return visibleCategoriesSet.has(cat.slug);
                  };

                  return (
                    isShowCategory(category) && (
                      <Category
                        activeCategory={activeCategory}
                        disabledCategoriesSet={disabledCategoriesSet}
                        enabledCategoriesSet={enabledCategoriesSet}
                        category={category}
                        key={category.slug}
                        path={`/category/${category.slug}`}
                      />
                    )
                  );
                })
              : getCategoriesList(workspace, selectedNavigationItem.name).map((category) => {
                  const isShowCategory = (cat) => {
                    if (!!cat.children) {
                      for (let i = 0; i < cat.children.length; i++) {
                        if (visibleCategoriesSet.has(cat.children[i].slug)) {
                          return true;
                        }
                      }
                      return false;
                    }
                    return visibleCategoriesSet.has(cat.slug);
                  };

                  return (
                    isShowCategory(category) && (
                      <Category
                        activeCategory={activeCategory}
                        disabledCategoriesSet={disabledCategoriesSet}
                        enabledCategoriesSet={enabledCategoriesSet}
                        category={category}
                        key={category.slug}
                        path={`/category/${category.slug}`}
                      />
                    )
                  );
                })}
          </List>
        </div>
        <CollapseButton
          open={expanded}
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
      </div>
    </Drawer>
  );
}

Sidebar.propTypes = {
  classes: PropTypes.shape({
    drawer: PropTypes.string.isRequired,
    drawerPaper: PropTypes.string.isRequired,
    listItem: PropTypes.string.isRequired,
    listItemIcon: PropTypes.string.isRequired,
    listItemText: PropTypes.string.isRequired,
    navWrapper: PropTypes.string.isRequired,
  }).isRequired,
  currentCompanyValidatedSystem: PropTypes.bool.isRequired,
  disabledCategories: PropTypes.array.isRequired,
  enabledCategories: PropTypes.array.isRequired,
  logo: PropTypes.string,
};

Sidebar.defaultProps = {
  activeCategory: undefined,
  categoryTree: {},
  logo: LOGO,
};

export default compose(
  setDisplayName('Sidebar'),
  withActiveCategory,
  withCurrentCompany,
  withDisabledCategories,
  withEnabledCategories,
  // @ts-ignore
  withStyles(styles),
)(Sidebar);
