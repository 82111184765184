import React, { useState } from 'react';
import FormatAlignJustify from '@material-ui/icons/FormatAlignJustify';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import { Tab, TabIcon, TabContainer } from 'components';
import PlanTab from './PlanTab';
import ReferencesTab from './ReferencesTab';

interface Props {
  currentVersion: any;
  layoutClasses: { [className: string]: string };
  locked: boolean;
  onUpdate: any;
  itemType: string;
}

const planTab = 'Plan';
const referencesTab = 'References';

export default function GuidedEditorTabs(props: Props) {
  const { currentVersion, layoutClasses, itemType } = props;

  const [currentTab, setCurrentTab] = useState(planTab);

  const tabs = {};
  tabs[planTab] = (
    <PlanTab
      itemType={itemType}
      layoutClasses={layoutClasses}
      currentVersion={currentVersion}
      locked={props.locked}
      versionId={currentVersion.id}
    />
  );
  tabs[referencesTab] = (
    <ReferencesTab
      currentVersion={currentVersion}
      locked={props.locked}
      layoutClasses={layoutClasses}
    />
  );

  return (
    <TabContainer
      layoutClasses={props.layoutClasses}
      tabs={
        <>
          <Tab
            icon={<TabIcon Icon={FormatAlignJustify} />}
            active={planTab === currentTab}
            onClick={() => setCurrentTab(planTab)}
            text={planTab}
          />
          <Tab
            icon={<TabIcon Icon={PlaylistAddCheck} />}
            active={referencesTab === currentTab}
            onClick={() => setCurrentTab(referencesTab)}
            text={referencesTab}
          />
        </>
      }
    >
      {tabs[currentTab]}
    </TabContainer>
  );
}
