import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import posed from 'react-pose';
import { compose, setDisplayName } from 'recompose';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { withI18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';

import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Person from '@material-ui/icons/Person';

import { Link } from '@reach/router';

import LOGO from 'assets/img/nemedio_vert.svg';

import { Logo, Spacer } from 'components';

import styles from './styles';

const ContainerWithEntry = posed.div({
  hidden: {
    y: -50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      duration: 300,
    },
  },
});

const mutation = gql`
  mutation createPasswordResetEmail($input: CreatePasswordResetEmailInput!) {
    createPasswordResetEmail(input: $input) {
      success
    }
  }
`;
class ForgotPasswordForm extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      formCard: PropTypes.string.isRequired,
      form: PropTypes.string.isRequired,
      inputAdornment: PropTypes.string.isRequired,
      inputField: PropTypes.string.isRequired,
      forgotPasswordButton: PropTypes.string.isRequired,
    }).isRequired,
    i18n: PropTypes.shape({
      _: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = { mounted: false, isButtonDisabled: false, successMessage: '' };

  componentDidMount() {
    // this toggles the pose after mounting, causing the card to be nicely animated into place.
    if (!this.state.mounted) {
      this.setState({ mounted: true });
    }
  }

  onSubmit = () => {
    this.setState({
      successMessage: <Trans>Please check your email for a password reset link</Trans>,
      isButtonDisabled: true,
    });
  };

  render() {
    const { classes, i18n, logo } = this.props;
    const { mounted, isButtonDisabled, successMessage } = this.state;

    const validateUsername = (value) => {
      if (value.includes('@')) {
        return `It looks like you're entering your email address, not username. Please enter username above.`;
      }
    }

    return (
      <ContainerWithEntry pose={mounted ? 'visible' : 'hidden'}>
        <Card className={classes.formCard}>
          <Logo src={logo} classes={classes} />
          <Grid container item xs={10}>
            <Tooltip title={i18n._(t`Back to login`)} classes={{ tooltip: classes.tooltip }} placement="right">
              <Link to="/login" className={classes.backArrow}>
                <ArrowBack />
              </Link>
            </Tooltip>
          </Grid>

          <Formik initialValues={{ username: '' }} onSubmit={this.onSubmit}>
            {({ isValid, values }) => (
              <Mutation
                mutation={mutation}
                variables={{
                  input: {
                    username: values.username,
                  },
                }}
              >
                {(createPasswordResetEmail, { loading, error }) => (
                  <Form className={classes.form}>
                    <CardContent>
                      <Typography variant="h2">
                        <Trans>Forgot Password?</Trans>
                      </Typography>
                      <Spacer factor={2} />

                      <Typography paragraph variant="body2">
                        <Trans>
                          Enter your username, and we'll send you a link to the email address associated with your
                          account to reset your password.
                        </Trans>
                      </Typography>
                      <Typography paragraph variant="body2">
                        <Trans>You may need to check your spam folder or unblock support@nemedio.com.</Trans>
                      </Typography>
                      <Spacer factor={2} />
                      <Field name="username" validate={validateUsername}>
                        {({ field, form: { errors, touched } }) => (
                          <FormControl fullWidth>
                            <Input
                              {...field}
                              className={classes.inputField}
                              endAdornment={
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                  <Person />
                                </InputAdornment>
                              }
                              fullWidth
                              id="username"
                              type="text"
                              placeholder="Username"
                            />
                            <FormHelperText className={classes.success}>
                              {loading ? 'Submitting...' : successMessage}
                            </FormHelperText>
                            <FormHelperText className={classes.error}>{errors.username}</FormHelperText>
                          </FormControl>
                        )}
                      </Field>
                    </CardContent>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Button
                            className={classes.forgotPasswordButton}
                            color="primary"
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            disabled={!isValid || isButtonDisabled}
                            onClick={createPasswordResetEmail}
                          >
                            <Trans>Send Link</Trans>
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Form>
                )}
              </Mutation>
            )}
          </Formik>
        </Card>
      </ContainerWithEntry>
    );
  }
}

ForgotPasswordForm.defaultProps = {
  logo: LOGO,
};

export default compose(
  setDisplayName('ForgotPasswordForm'),
  withI18n(),
  withStyles(styles),
)(ForgotPasswordForm);
