import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { VerificationRecordVersionQuery } from './__generated__/VerificationRecordVersionQuery';

const GET_VERIFICATION_RECORD_VERSION = gql`
  query VerificationRecordVersionQuery($id: ID!) {
    verificationRecordVersion(id: $id) {
      id
      title
      notes
      locked
      isFulfillAllAcceptanceCriteria
      linkedForm {
        id
        title
        versionIdentifier
        releasedAt
        item {
          id
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      executedTestProtocolAttachment {
        id
        url
        creator {
          id
          fullName
        }
        createdAt
      }
      unexecutedTestProtocolAttachment {
        id
        url
        creator {
          id
          fullName
        }
      }
      referencedAttachments {
        id
        url
        description
      }
      deviationVersions {
        id
        deviationId
        item {
          id
          currentVersion {
            id
            createdAt
            currentStatus {
              id
              name
            }
            title
            versionIdentifier
          }
          customIdentifier
          itemType {
            id
            displaySlug
            userCreatable
          }
        }
      }
      item {
        id
        customIdentifier
      }
    }
  }
`;

const UPDATE_VERIFICATION_RECORD_VERSION = gql`
  mutation updateVerificationRecordVersion(
    $id: ID!
    $title: String
    $notes: String
    $isFulfillAllAcceptanceCriteria: Boolean
  ) {
    updateVerificationRecordVersion(
      id: $id
      title: $title
      notes: $notes
      isFulfillAllAcceptanceCriteria: $isFulfillAllAcceptanceCriteria
    ) {
      verificationRecordVersion {
        id
      }
    }
  }
`;

const useVersion = (id: string) => {
  return useQuery<VerificationRecordVersionQuery>(GET_VERIFICATION_RECORD_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
};

export { useVersion, UPDATE_VERIFICATION_RECORD_VERSION };
