import { DateTime } from 'luxon';

export const dateFormatter = (dateStr): string => {
  if (!dateStr || dateStr === 'TBD') return 'TBD';

  const parsedDate = DateTime.fromFormat(dateStr, 'd-LLL-yyyy', { zone: 'utc' });

  if (parsedDate.isValid) {
    return dateStr;
  } else {
    const date = DateTime.fromISO(dateStr, { zone: 'utc' });
    return date.toFormat('d-LLL-yyyy').toUpperCase();
  }
};
