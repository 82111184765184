import React from 'react';
import { Spacer } from 'components';
import { Typography, withStyles, createStyles, Button } from '@material-ui/core';

import styles from './styles';
import { Trans } from '@lingui/macro';
import AttachmentControl from 'components/AttachmentsSection/AttachmentControl';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';

const mutation = gql`
  mutation UpdateADWorkflow($versionId: ID!, $attachment: String!) {
    updateTrainingRecordVersion(id: $versionId, attachment: $attachment) {
      trainingRecordVersion {
        id
        uploadedRecord {
          id
          url
          filename
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const query = gql`
  query TrainingRecordVersionAttachment($versionId: ID!) {
    trainingRecordVersion(id: $versionId) {
      id
      uploadedRecord {
        id
        url
        filename
      }
    }
  }
`;

function UploadAttachment(props: {
  classes;
  heading: JSX.Element | string;
  dialog: JSX.Element | string;
  versionId: string;
  handleComplete: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) {
  const { data, error } = useQuery(query, {
    variables: { versionId: props.versionId },
  });
  const [mutate] = useMutation(mutation);

  if (error) throw new Error(`error loading attachment for version ${props.versionId}\n${error}`);

  const handleAttachmentChange = async (_id, url) =>
    mutate({
      variables: {
        versionId: props.versionId,
        attachment: url,
      },
    });

  const attachment = data && data.trainingRecordVersion && data.trainingRecordVersion.uploadedRecord;
  return (
    <>
      <Spacer factor={1} />

      <div className={props.classes.heading}>
        <Typography variant="h3">{props.heading}</Typography>
      </div>

      <Spacer factor={2} />
      <Typography>{props.dialog}</Typography>
      <Spacer factor={2} />

      <AttachmentControl
        versionId={props.versionId}
        attachment={attachment}
        classes={{
          attachmentInput: props.classes.attachmentInput,
        }}
        locked={false}
        updateAttachment={handleAttachmentChange}
        createAttachment={handleAttachmentChange}
      />

      <Spacer factor={2} />

      <Button
        color="primary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={attachment === null || attachment === undefined}
        onClick={props.handleComplete}
      >
        <Trans>Next</Trans>
      </Button>
    </>
  );
}

export default withStyles(createStyles(styles))(UploadAttachment);
