import { t } from '@lingui/macro';
import { withStyles, Typography } from '@material-ui/core';
import flowRight from 'lodash/flowRight';
import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { withI18n } from '@lingui/react';

import MentionSuggestion from 'components/MentionSuggestion';

interface InputProps {
  theme;
  i18n;
  value: string;
  inputRef: (el) => any;
  onChange: (e) => void;
  onKeyDown: (e) => void;
  users: { id: string; display: string }[];
}

function Input({
  theme,
  onChange,
  onKeyDown,
  inputRef,
  i18n,
  value,
  users,
}: InputProps) {
  return (
    <div>
      <Typography variant="body2" component="span">
        <MentionsInput
          allowSuggestionsAboveCursor
          inputRef={inputRef}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={i18n._(t`Type your comment`)}
          style={{
            input: {
              overflow: 'auto',
              height: 70,
              font: 'inherit',
              color: 'inherit',
              padding: '12px 8px',
            },
            highlight: {
              display: 'none',
            },
            suggestions: {
              boxShadow: theme.shadows[1],
              list: {
                maxHeight: 200,
                overflow: 'auto',
              },
            },
          }}
          value={value}
        >
          <Mention
            markup="@[__display__:__id__]"
            trigger="@"
            placeholder={i18n._(t`Type your comment`)}
            data={users}
            renderSuggestion={(suggestion, _search, highlightedDisplay) => (
              <MentionSuggestion userId={suggestion.id}>
                {highlightedDisplay}
              </MentionSuggestion>
            )}
          />
        </MentionsInput>
      </Typography>
    </div>
  );
}

export default flowRight([withI18n(), withStyles({}, { withTheme: true })])(
  Input,
);
