const styles = (theme) => ({
  modalContentContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    left: theme.mixins.sidebar.expandedWidth,
    right: 0,
    top: '25%',
    bottom: 0,
    outline: 'none',
  },
  cssOutlinedInput: {
    ...theme.typography.body2,
    height: 36,
    marginBottom: theme.spacing(4.25),
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  cssFocused: {},
  notchedOutline: {
    border: `2px solid ${theme.palette.common.input.outline} !important`,
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
    float: 'right',
  },
  container: {
    padding: theme.spacing(2),
  },
  linkTextField: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: 0,
    marginTop: 0,
    zIndex: 100,
  },
  results: {
    padding: theme.spacing(2),
    paddingTop: 0,
    marginBottom: theme.spacing(2),
    overflowY: 'scroll',
    maxHeight: theme.spacing(40),
  },
  splitter: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3.25),
    marginTop: 0,
    color: theme.palette.splitter.background,
    borderStyle: 'solid',
    borderWidth: 0.5,
  },
  fullItemList: {
    color: '#33BCB4',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    // cursor: "pointer",
    padding: '4px 8px',
    fontWeight: 600,
    backgroundColor: 'rgba(48, 188, 179, 0.2)',
  },
  suggestedItems: {
    color: '#3C4858',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'capitalize',
    fontWeight: 600,
    marginLeft: '16px',
  },
  suggested: {
    color: '#33BCB4',
    fontSize: '12px',
    letterSpacing: '0.05em',
    lineHeight: '14px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    padding: '4px 8px',
    fontWeight: 600,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
  },
  rightHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginRight: '16px',
  },
  selectedRow: {
    backgroundColor: 'rgba(48, 188, 179, 0.1)',
  },
});

export default styles;
