import React, { FC, useState } from 'react';
import { navigate } from '@reach/router';

import { Box, Typography } from '@material-ui/core';
import { Launch } from '@material-ui/icons';

import TaskList from './TaskList';
import { IconButton } from 'components';

import { sortByDueDate, useTasksMutation, useTasksQuery } from './utils';
import { useStyles } from './styles';
import { TaskWorkflowStatuses } from '../../workflows/statuses';
import { Transition } from './types';
import { tasksQuery_tasks } from './__generated__/tasksQuery';

interface Props {
  onClose: () => void;
}

const TaskMenu: FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const [tasks, setTasks] = useState<tasksQuery_tasks[]>([]);
  useTasksQuery({
    onCompleted: (data) => {
      if (data.tasks) {
        setTasks(sortByDueDate(data.tasks));
      }
    },
  });
  const [createStatusTransition] = useTasksMutation();
  const {
    Backlog,
    BlockedAfterBacklog,
    ToDo,
    BlockedAfterToDo,
    InProgress,
    BlockedAfterInProgress,
    Done,
    BlockedAfterDone,
    WontDoAfterBacklog,
    WontDoAfterToDo,
    WontDoAfterInProgress,
    WontDoAfterDone,
  } = TaskWorkflowStatuses;

  const handleNavigation = async () => {
    await navigate('/category/task');
    onClose();
  };

  const transitions: Transition[] = [
    { key: 'backlog', icon: 'taskBacklog', label: 'Backlog', value: [Backlog.id] },
    { key: 'to_do', icon: 'taskToDo', label: 'To Do', value: [ToDo.id] },
    { key: 'in_progress', icon: 'taskInProgress', label: 'In Progress', value: [InProgress.id] },
    { key: 'done', icon: 'taskDone', label: 'Done', value: [Done.id] },
    {
      key: 'blocked',
      icon: 'taskBlocked',
      label: 'Blocked',
      value: [BlockedAfterBacklog.id, BlockedAfterToDo.id, BlockedAfterInProgress.id, BlockedAfterDone.id],
    },
    {
      key: 'wont_do',
      icon: 'taskWontDo',
      label: "Won't do",
      value: [WontDoAfterBacklog.id, WontDoAfterToDo.id, WontDoAfterInProgress.id, WontDoAfterDone.id],
    },
  ];

  const blockedConditions = {
    backlog: BlockedAfterBacklog.id,
    to_do: BlockedAfterToDo.id,
    in_progress: BlockedAfterInProgress.id,
    done: BlockedAfterDone.id,
  };

  const wontDoConditions = {
    backlog: WontDoAfterBacklog.id,
    to_do: WontDoAfterToDo.id,
    in_progress: WontDoAfterInProgress.id,
    done: WontDoAfterDone.id,
  };

  const handleStatusChange = async (
    currentWorkflowStatus: string,
    versionId: string,
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const value = event.target.value as string;
    let toStatus = value;
    if (value.includes('blocked')) {
      toStatus = blockedConditions[currentWorkflowStatus];
    }

    if (value.includes('wont_do')) {
      toStatus = wontDoConditions[currentWorkflowStatus];
    }

    try {
      await createStatusTransition({
        variables: {
          input: {
            versionId,
            toStatus,
          },
        },
      });
    } catch (error) {}
  };

  return (
    <div className={classes.taskMenuWrapper}>
      <div className={classes.headerContainer}>
        <Typography variant="body1" className={classes.menuHeader}>
          My Tasks
        </Typography>
        <Box ml={1.5} />
        <IconButton Icon={Launch} onClick={handleNavigation} tooltip="View all tasks" size="small" />
      </div>
      <Box pt={7}>
        <TaskList tasks={tasks} onChange={handleStatusChange} transitions={transitions} onRedirect={onClose} />
      </Box>
    </div>
  );
};

export default TaskMenu;
