import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { Spacer } from 'components';
import Page from 'components/core/Page';
import WithCurrentUser from 'compositions/WithCurrentUser';
import ScrollableComments from 'compositions/ScrollableComments';

import SupportWidget from 'components/SupportWidget';
import CurrentUsersTrainingRecordsList from './CurrentUsersTrainingRecordsList';
import CurrentUsersTaskList from './CurrentUsersTaskList';
import { CurrentUserAvatar } from 'components/UserAvatar';

function Dashboard({ currentUser }) {
  return (
    <div style={{ marginTop: -35 }}>
      <Page>
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>
              <CurrentUserAvatar diameter={56} aria-label={'Open User Profile'} id="profile-button" />
            </div>
            <Typography
              variant="h2"
              style={{ fontWeight: 400, lineHeight: '32px', fontStyle: 'normal', color: '#6E7583' }}
            >
              Hi {currentUser.firstName}, welcome back to Nemedio.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={8} style={{ minHeight: 165 }}>
          <CurrentUsersTaskList />
          <Spacer factor={2} />
          <CurrentUsersTrainingRecordsList />
        </Grid>
        <Grid item xs={4}>
          <SupportWidget />
          <Spacer factor={2} />
          <ScrollableComments />
        </Grid>
      </Page>
    </div>
  );
}

export default WithCurrentUser(Dashboard);
