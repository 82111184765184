import React from 'react';
import { TableHead, TableRow, TableCell } from '@material-ui/core';

export default function Heading(props: { locked: boolean; classes }) {
  return (
    <TableHead>
      <TableRow>
        {/* checkbox column */}
        {!props.locked && <TableCell align="left" className={props.classes.tableHeader} />}

        <TableCell align="left" className={props.classes.tableHeader}>
          ID
        </TableCell>
        <TableCell align="left" className={props.classes.tableHeader}>
          Title
        </TableCell>
        <TableCell align="center" className={props.classes.tableHeader}>
          Version
        </TableCell>
        <TableCell align="center" className={props.classes.tableHeader}>
          Status
        </TableCell>
        <TableCell align="left" className={props.classes.tableHeader}>
          Is Effectiveness Measurement Required?
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
