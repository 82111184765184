import React from 'react';

import { Grid, Typography } from '@material-ui/core';

interface GroupProps {
  children: any;
  label?: string;
  border?: {
    color?: string;
    width?: string;
    style?: string;
  };
}

const RiskDiagramRow = (props: GroupProps) => {
  const { children } = props;
  const border = () =>
    props.border ? `${props.border.width} ${props.border.style} ${props.border.color}` : '1px solid transparent';
  return (
    <Grid
      container
      spacing={2}
      style={{ position: 'relative', marginTop: '60px', alignItems: 'center', border: border() }}
    >
      {props.label && (
        <Typography
          style={{
            position: 'absolute',
            left: '5px',
            top: '5px',
          }}
        >
          {props.label}
        </Typography>
      )}
      {children}
    </Grid>
  );
};

export default RiskDiagramRow;
