import React, { useState } from 'react';

function squashEvent(e) {
  e.preventDefault();
  e.stopPropagation();
}

function DropArea({ classes, onDrop }) {
  const [active, setActive] = useState(false);
  return (
    <div
      onDragOver={(e) => {
        setActive(true);
      }}
      onDragLeave={(e) => {
        squashEvent(e);
        setActive(false);
      }}
      onDrop={(e) => {
        squashEvent(e);
        onDrop && onDrop(e);
        setActive(false);
      }}
      className={active ? classes.active : classes.inactive}
    />
  );
}

export default DropArea;
