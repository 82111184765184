import { WorkflowDefinition, WorkflowTransition } from '../types';

import ApproveOrReject from './ApproveOrReject';
import AssociatedChange from '../shared/AssociatedChange';
import Cancel from './Cancel';
import CreateNewVersion from '../shared/CreateNewVersion';
import OwnerApproval from './OwnerApproval';
import OwnerApprovalWithoutSig from '../shared/OwnerApprovalWithoutSig';
import ReasonForChange from '../shared/ReasonForChange';
import ReleaseOrReject from './ReleaseOrReject';
import ResolveRejection from './ResolveRejection';
import SummaryOfChange from '../shared/SummaryOfChange';
import UnderReview from './UnderReview';
import VoidSignature from './VoidSignature';
import InitiateRetirement from './InitiateRetirement';
import CancelRetirement from './CancelRetirement';
import RetireOrRejectWithoutCO from '../shared/RetireOrRejectWithoutCO';
import RetireOrReject from './RetireOrReject';

const GenericWithMembersWorkflowSteps: WorkflowDefinition = {
  initiateRetirement: InitiateRetirement as WorkflowTransition,
  cancelRetirement: CancelRetirement as WorkflowTransition,
  voidSignature: VoidSignature as WorkflowTransition,
  cancel: Cancel as WorkflowTransition,
  createNewVersion: CreateNewVersion as WorkflowTransition,
  reasonForChange: ReasonForChange as WorkflowTransition,
  summaryOfChange: SummaryOfChange as WorkflowTransition,
  ownerApprovalWithoutSig: OwnerApprovalWithoutSig as WorkflowTransition,
  associatedChange: AssociatedChange as WorkflowTransition,
  underReview: UnderReview as WorkflowTransition,
  ownerApproval: OwnerApproval as WorkflowTransition,
  approveOrReject: ApproveOrReject as WorkflowTransition,
  releaseOrReject: ReleaseOrReject as WorkflowTransition,
  resolveRejection: ResolveRejection as WorkflowTransition,
  retireOrRejectWithoutCO: RetireOrRejectWithoutCO as WorkflowTransition,
  retireOrReject: RetireOrReject as WorkflowTransition,
};

export default GenericWithMembersWorkflowSteps;
