import React, { FC } from 'react';

import { MenuItem, Select, Typography } from '@material-ui/core';

import { WorkflowIcon } from 'components';

import { useStyles } from './styles';
import { Transition } from '../types';

interface Props {
  currentValue: string;
  onChange: (
    currentWorkflowStatus: string,
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
  transitions: Transition[];
}

const TaskSelect: FC<Props> = ({ currentValue, onChange, transitions }) => {
  const classes = useStyles();

  return (
    <Select
      value={currentValue}
      variant="standard"
      classes={{ select: classes.select }}
      onChange={(event) => onChange(currentValue, event)}
      disableUnderline
    >
      {transitions.map(({ key, label, icon, value }) => {
        const transitionValue = value.find((item) => item === currentValue) || value[0];
        return (
          <MenuItem key={key} value={transitionValue}>
            <div className={classes.selectItem}>
              <WorkflowIcon type={icon} workflowType="tasks" completed size="tiny" displayTooltip />
              <Typography variant="body2" className={classes.selectItemText}>
                {label}
              </Typography>
            </div>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default TaskSelect;
