import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 500,

      '& .MuiTab-root': {
        minWidth: 'unset',
        borderRadius: 5,
        height: 32,
        minHeight: 'unset',
        color: 'rgba(110, 117, 131, 0.6)',
      },
      '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
        padding: 2,
        borderRadius: 4,
        background: 'white',
      },
      '& .MuiTab-textColorPrimary.Mui-selected': {
        background: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1)',
        color: theme.palette.blueDark,
      },
    },
    tab: {
      minWidth: 'unset',
      borderRadius: 5,
      height: 32,
      minHeight: 'unset',
      color: 'rgba(110, 117, 131, 0.6)',
      flex: '0 1 33%',
      background:
        'linear-gradient(180deg, rgb(231 233 235 / 30%) 0%, rgba(242, 244, 247, 0.529303) 47.07%, rgb(230 233 239 / 30%) 100%, rgb(228 234 246) 100%)',
    },
    selectedTab: {
      background: 'white',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1)',
      color: theme.palette.blueDark,
    },
  }),
);
