import React, { ReactNode, useState } from 'react';
import { Showable, defaultShowable } from './showable';

interface State {
  includeDraft: Showable;
  includeRetired: Showable;
}

export const FilterContext = React.createContext<State>({
  includeDraft: defaultShowable,
  includeRetired: defaultShowable,
});

export function FilterContextProvider(props: { children: ReactNode }) {
  const [showIncludeDraft, setShowIncludeDraft] = useState(true);
  const [showIncludeRetired, setShowRetired] = useState(true);

  return (
    <FilterContext.Provider
      value={{
        includeDraft: {
          showing: showIncludeDraft,
          toggle: () => setShowIncludeDraft((prev) => !prev),
        },
        includeRetired: {
          showing: showIncludeRetired,
          toggle: () => setShowRetired((prev) => !prev),
        },
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
}
