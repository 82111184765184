import gql from 'graphql-tag';

export const REMOVE_VALIDATION_RECORD = gql`
  mutation removeValidationRecord($vvrVersionId: ID!, $valRecordVersionId: ID!) {
    removeValidationRecordFromVerificationAndValidationReportVersion(
      vvrVersionId: $vvrVersionId
      valRecordVersionId: $valRecordVersionId
    ) {
      verificationAndValidationReportVersion {
        id
      }
    }
  }
`;

export const REMOVE_VERIFICATION_RECORD = gql`
  mutation removeVerificationRecord($vvrVersionId: ID!, $verRecordVersionId: ID!) {
    removeVerificationRecordFromVerificationAndValidationReportVersion(
      vvrVersionId: $vvrVersionId
      verRecordVersionId: $verRecordVersionId
    ) {
      verificationAndValidationReportVersion {
        id
      }
    }
  }
`;
