import React from 'react';
import camelCase from 'lodash/camelCase';
import flowRight from 'lodash/flowRight';

import {
  withDisabledCategories,
  withEnabledCategories,
} from 'compositions/WithCategories';

import { withAllItemTypeMetadata } from 'compositions/WithMetadataFromServer';
import { toRenderProps } from 'utils';
import { ItemTypeIndex } from 'itemTypes';

import { singletonRoute, listRoute, recordRoute } from 'setup/routes';

const withRouteComponents = (WrappedComponent) => (componentProps) => {
  const {
    disabledCategories = [],
    enabledCategories = [],
    itemTypes = [],
  } = componentProps;

  var routes = [];

  const disabledSet = new Set(disabledCategories);
  const enabledSet = new Set(enabledCategories);
  itemTypes.forEach((itemType) => {
    const camelizedName = camelCase(itemType.name);
    const localItemTypeMetadata = ItemTypeIndex[camelizedName];
    const isDisabled = disabledSet.has(itemType.displaySlug);
    const isEnabled = enabledSet.has(itemType.displaySlug);

    if (localItemTypeMetadata && !isDisabled && isEnabled) {
      const metadata = {
        ...localItemTypeMetadata,
        ...itemType,
      };

      if (itemType.userCreatable) {
        routes.push(listRoute(metadata));
        routes.push(recordRoute(metadata));
      } else {
        routes.push(singletonRoute(metadata));
      }
    }
  });

  return <WrappedComponent {...componentProps} protectedRoutes={routes} />;
};

const withRoutes = flowRight([
  withAllItemTypeMetadata,
  withDisabledCategories,
  withEnabledCategories,
  withRouteComponents,
]);

export { withRoutes, withRouteComponents };
export default toRenderProps(withRoutes);
