import React, { useState } from 'react';

import { Paper, Typography, Avatar } from '@material-ui/core';
import { Business, Call, Email, RecentActors, Edit } from '@material-ui/icons';
import flowRight from 'lodash/flowRight';
import { useMutation } from 'react-apollo';

import { UpdateProfileInput } from '__generated__/globalTypes';
import { Spacer, IconButton } from 'components';
import Input from 'components/core/form/Input';

import { useStyles } from './styles';
import ProfileSection from './ProfileSection';
import { withCurrentCompany } from 'compositions/WithCurrentCompany';
import { UPDATE_PROFILE_MUTATION } from './gql';

interface Props {
  profile: any;
  currentCompany: any;
  editable: boolean;
}

function EmployeeProfile(props: Props) {
  const { profile } = props;
  const [editing, setEditing] = useState(false);
  const [updateProfile, { error }] = useMutation(UPDATE_PROFILE_MUTATION);
  const classes = useStyles();

  const onChange = (changes: UpdateProfileInput) =>
    updateProfile({ variables: { input: changes } }).catch(() => {
      // swallow the error
    });

  return (
    <Paper className={classes.container} elevation={1}>
      <div className={classes.titleContainer}>
        <Typography variant="body1" align="center">
          {profile.firstName} {profile.lastName}
        </Typography>
        {props.editable && (
          <div className={classes.editContainer}>
            <IconButton Icon={Edit} onClick={() => setEditing((prev) => !prev)} />
          </div>
        )}
      </div>
      <Typography variant="subtitle2" align="center" component="p">
        @{profile.user.username}
      </Typography>
      <Typography variant="body2" align="center">
        {profile.title}
      </Typography>
      <Spacer factor={3} />
      <Avatar className={classes.avatarImageWrapper}>
        {profile.photoUrl ? (
          <img className={classes.avatarImage} src={profile.photoUrl} alt="User Avatar" />
        ) : (
          <Typography variant="body2">{`${profile.firstName[0]}${profile.lastName[0]}`}</Typography>
        )}
      </Avatar>
      <Spacer factor={3} />
      <ProfileSection icon={RecentActors}>
        {profile.user.jobRoles.map((job) => job.formattedRoleName).join(', ')}
      </ProfileSection>
      <ProfileSection icon={Business}>{props.currentCompany.name}</ProfileSection>
      <ProfileSection icon={Email}>{profile.emailAddress}</ProfileSection>
      <ProfileSection icon={Call}>
        {editing ? (
          <Input
            debounced
            defaultValue={profile.phoneNumber}
            onChange={(e) => onChange({ id: profile.id, phoneNumber: e.target.value })}
          />
        ) : (
          <>{profile.phoneNumber}</>
        )}
      </ProfileSection>
      <ProfileSection icon={Call}>
        {editing ? (
          <Input
            debounced
            defaultValue={profile.secondaryPhoneNumber}
            onChange={(e) => onChange({ id: profile.id, secondaryPhoneNumber: e.target.value })}
          />
        ) : (
          <>{profile.secondaryPhoneNumber}</>
        )}
      </ProfileSection>
      {error && (
        <Typography variant="caption" color="error">
          Save failed for the following reason: {error.message}
        </Typography>
      )}
    </Paper>
  );
}

export default flowRight([withCurrentCompany])(EmployeeProfile);
