import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((_theme) =>
  createStyles({
    textLook: {
      borderColor: 'rgba(0, 0, 0, 0) !important',
      backgroundColor: 'white',
      '& textarea': {
        color: 'rgba(0, 0, 0, 0.6)',
        cursor: 'default !important',
      },
    },
  }),
);
