import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';

import { Input, Typography, withStyles } from '@material-ui/core';
import { navigate } from '@reach/router';

import { DEBOUNCE_MILLISECONDS } from '../../../constants';
import { CATEGORY_INDEX } from 'categories';
import { ItemTypeIndex } from 'itemTypes';

import RecordsAsCategoryTree from 'components/RecordsAsCategoryTree';
import { useItemSearch } from 'utils/gql/itemSearch';
import styles from './styles';

function ItemSearchTab({ classes, closeSelf, i18n }) {
  const [searchTerm, setSearchTerm] = useState(null);

  const { data, error } = useItemSearch(searchTerm, false, false, false, false, []);
  if (error) throw new Error(`Item search failed: ${error.message}`);

  const handleChange = debounce((e) => {
    const term = e.target.value;
    if (term && term.length >= 3) setSearchTerm(term.trim());
  }, DEBOUNCE_MILLISECONDS);

  const noResults = get(data, 'itemSearch') && get(data, 'itemSearch', []).length === 0 && (
    <Typography variant="body2" className={classes.noResults}>
      <Trans>No results found.</Trans>
    </Typography>
  );

  const resultMap = {};
  get(data, 'itemSearch', []).forEach((r) => {
    resultMap[r.itemType] = get(r, 'results', []);
  });

  const whitelist = {};
  Object.keys(resultMap).forEach((t) => {
    const displaySlug = get(ItemTypeIndex, [camelCase(t), 'displaySlug']);

    whitelist[displaySlug] = true;

    const parentSlug = get(CATEGORY_INDEX, [displaySlug, 'parentSlug']);
    if (parentSlug) whitelist[parentSlug] = true;
  });

  return (
    <>
      <Input
        className={classes.searchInput}
        id="item-search-input"
        autoComplete="off"
        fullWidth
        inputProps={{
          'aria-label': i18n._(t`Search by item ID or title`),
          onChange: (e) => {
            e.persist();
            handleChange(e);
          },
        }}
        placeholder={i18n._(t`Search by item ID or title`)}
        type="search"
      />
      <div className={classes.results}>
        {noResults}
        <RecordsAsCategoryTree
          allowNavigation={false}
          categoryRecordsMap={resultMap}
          categoryWhitelist={whitelist}
          style={{ paddingLeft: 40 }}
          onRecordClick={(record, cat) => {
            const itemLink = `/category/${cat.slug}${record.userCreatable ? `/${record.id}` : ''}/?view=CardBuilder`;

            navigate(itemLink) && closeSelf();
          }}
        />
      </div>
    </>
  );
}
export default flowRight([withI18n(), withStyles(styles)])(ItemSearchTab);
