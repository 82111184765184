import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';

import { Checkbox, FormControl, MenuItem, InputBase, Select, withStyles, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = (theme) => ({
  selectRoot: {
    ...theme.typography.body2,
    width: '100%',
    background: theme.palette.common.input.background,
    border: `2px solid ${theme.palette.common.input.outline}`,
    borderRadius: 4,
  },
  menuDropdown: {
    height: 'auto',
    minHeight: 185,
    maxHeight: 300,
  },
  menuOption: {
    ...theme.typography.body2,
    borderTop: `2px solid ${theme.palette.common.input.outline}`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      background: theme.palette.common.input.hover,
    },
  },
  picklistValue: {
    paddingLeft: 8,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const mutation = gql`
  mutation updateStringArray($input: UpdateVirtualAttributeInput!) {
    updateVirtualAttribute(input: $input) {
      virtualAttribute {
        attrName
        arrayValue
      }
    }
  }
`;

class MultiSelectField extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      selectRoot: PropTypes.string.isRequired,
      menuDropdown: PropTypes.string.isRequired,
      menuOption: PropTypes.string.isRequired,
    }).isRequired,
    attrName: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired).isRequired,
    refetchQueries: PropTypes.array,
    versionId: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    displayOnly: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    refetchQueries: undefined,
    value: [],
    locked: true,
    displayOnly: false,
    IconComponent: ArrowDropDownIcon,
  };

  state = {
    optimisticallyUpdatedValue: undefined,
    readMode: true,
    timeout: null,
  };

  componentDidMount() {
    if (!this.props.value || this.props.value.length === 0) {
      this.setState({ readMode: false });
    }
  }

  shouldComponentUpdate(nextProps, { optimisticallyUpdatedValue, locked }) {
    return this.state.optimisticallyUpdatedValue !== optimisticallyUpdatedValue || locked !== true;
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }

  onReadModeTextClick = (event) => {
    clearTimeout(this.state.timeout);
    if (event.detail === 2) {
      this.setState({ readMode: false });
    }
    const timeout = setTimeout(() => {
      this.setState({ readMode: true });
    }, 2000);
    this.setState({ timeout });
  };

  renderPicklistValue = (selected) => {
    if (selected.length === 0) {
      return <div className={this.props.classes.picklistValue}>{this.props.placeholder}</div>;
    }

    return (
      <>
        {selected.map((m, ix) => (
          <div
            key={ix}
            className={this.props.classes.picklistValue}
            style={{
              paddingBottom: 4,
            }}
          >
            {m}
          </div>
        ))}
      </>
    );
  };

  render() {
    const {
      classes,
      attrName,
      locked,
      options,
      placeholder,
      refetchQueries,
      renderValue,
      versionId,
      value,
      displayOnly,
      ...rest
    } = this.props;

    const selectedOptions = this.state.optimisticallyUpdatedValue || value || [];

    if (locked) {
      return (
        <Typography variant="body2">
          {selectedOptions.map((v, ix) => {
            if (v === undefined) return '';
            if (ix !== selectedOptions.length - 1) return `${v}, `;
            return v;
          })}
        </Typography>
      );
    }
    if (this.state.readMode) {
      return (
        <Typography onClick={this.onReadModeTextClick} variant="body2" style={{ cursor: 'pointer' }}>
          {selectedOptions.join(', ')}
        </Typography>
      );
    }
    return (
      <Mutation mutation={mutation}>
        {(mutate) => (
          <FormControl fullWidth disabled={locked}>
            <Select
              multiple
              disabled={locked}
              classes={{ root: classes.selectRoot }}
              input={<InputBase id="item-name" placeholder={placeholder} />}
              IconComponent={locked ? null : ArrowDropDownIcon}
              onClose={() => {
                if (this.state.optimisticallyUpdatedValue) {
                  mutate({
                    variables: {
                      input: {
                        attrName: this.props.attrName,
                        arrayValue: this.state.optimisticallyUpdatedValue,
                        versionId: this.props.versionId,
                      },
                    },
                    refetchQueries: this.props.refetchQueries,
                  });
                  if (this.state.optimisticallyUpdatedValue.length !== 0) {
                    this.setState({ readMode: true });
                  }
                }
              }}
              onOpen={() => {
                clearTimeout(this.state.timeout);
              }}
              MenuProps={{
                classes: { paper: classes.menuDropdown },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              inputProps={{
                name: attrName,
                id: `${attrName}-selector`,
              }}
              onChange={(event) => {
                const val = event.target.value;
                this.setState({ optimisticallyUpdatedValue: val });
              }}
              displayEmpty
              value={selectedOptions}
              renderValue={this.renderPicklistValue}
              {...rest}
            >
              <MenuItem value="" disabled className={classes.menuOption}>
                {placeholder}
              </MenuItem>
              {options.map((opt) => (
                <MenuItem key={opt.id} value={opt.id} className={classes.menuOption}>
                  <Checkbox checked={selectedOptions.includes(opt.id)} />
                  {opt.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(MultiSelectField);
