import React from 'react';

import { createStyles, Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import { Blur } from 'components';

interface UnavailablePanelProps {
  children;
  blur: boolean;
  container: boolean;
  [key: string]: any;
}
export const useStyles = makeStyles((theme) =>
  createStyles({
    panel: {
      width: '430px',
      position: 'absolute',
      zIndex: 99999,
      transform: 'translate(50%, 0)',
      top: '30%',
      padding: theme.spacing(2),
    },
    container: {
      position: 'relative',
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const BlurWithPanel = (props: UnavailablePanelProps) => {
  const { children, blur, container } = props;
  const classes = useStyles();

  const renderPanel = () => {
    return (
      <Paper elevation={2} className={classes.panel}>
        <Typography className={classes.title} variant={'body1'}>
          Operations Unavailable
        </Typography>
        <Typography variant={'body2'}>
          These operations are not available due to a selection made during Risk Analysis and Evaluation.{' '}
        </Typography>
      </Paper>
    );
  };
  return (
    <Grid className={classes.container}>
      {blur && renderPanel()}
      <Blur blur={blur} container={container}>
        {children}
      </Blur>
    </Grid>
  );
};

export default BlurWithPanel;
