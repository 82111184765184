import React, { Component } from 'react';
import get from 'lodash/get';
import { Typography, Button, Paper, TextField, InputAdornment, MenuItem, Link } from '@material-ui/core';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import Search from '@material-ui/icons/Search';
import { navigate } from '@reach/router';
import Autosuggest from 'react-autosuggest';
import { withStyles } from '@material-ui/core/styles';
import { Trans } from '@lingui/macro';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { compose } from 'recompose';

import styles from './styles';
import { Spacer } from 'components';

const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();

  return options.filter((co) => co.name.toLowerCase().includes(inputValue));
};

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion) => <MenuItem component="div">{suggestion.name}</MenuItem>;

const renderInputComponent = ({ classes, inputRef = () => {}, ref, ...other }) => (
  <TextField
    id="select-co-input"
    fullWidth
    variant="outlined"
    margin="dense"
    InputProps={{
      inputRef: (node) => {
        ref(node);
        inputRef(node);
      },
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    }}
    {...other}
  />
);

class SelectChangeOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      suggestions: [],
      selectedChangeOrderId: null,
    };
  }

  onChange = (_, { newValue }) => {
    this.setState({ value: newValue, hasTypedSinceLastSelection: true });
  };

  onSuggestionSelected = (_, { suggestion }) => {
    this.setState({
      selectedChangeOrderId: suggestion.id,
      hasTypedSinceLastSelection: false,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const opts = this.props.options;
    this.setState({
      suggestions: getSuggestions(value, opts),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  render() {
    const { classes, handleComplete, currentVersion, unsavedValue } = this.props;
    const { suggestions, value } = this.state;
    const { linkedChangeCustomIdentifier, linkedChangeTitle, linkedChangeId } = currentVersion;

    /*
      The API does not explicitly distinguish between a linked change
      and a justification for no change as the justification is itself
      a linked change.  To know if the linkedChangeId is pointing to a
      CO (rather than a JST) we use linkedChangeTitle since JST records
      do not have a title.
    */
    const currentlyLinkedCO = linkedChangeTitle && `${linkedChangeCustomIdentifier}: ${linkedChangeTitle}`;
    let displayValue;
    if (value !== null) {
      displayValue = value;
    } else if (unsavedValue) {
      displayValue = unsavedValue.displayValue;
    } else if (currentlyLinkedCO) {
      displayValue = currentlyLinkedCO;
    } else {
      displayValue = '';
    }

    const changeOrderId = this.state.selectedChangeOrderId || get(unsavedValue, 'changeOrderId', null);

    return (
      <div>
        <div className={classes.heading}>
          <Typography variant="h3">
            <Trans>Trace Change Order</Trans>
          </Typography>

          <Spacer factor={1} />
          <Typography variant="body2">
            <Trans>Which Change Order do you want to trace?</Trans>
          </Typography>
        </div>

        <Spacer factor={2} />

        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          inputProps={{
            value: displayValue,
            classes,
            onChange: this.onChange,
            placeholder: 'Search change orders',
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
        <div className={classes.spacer} />
        <div className={classes.spacer} />
        <Button
          id="select-co-button"
          fullWidth
          variant="contained"
          color="primary"
          disabled={this.state.hasTypedSinceLastSelection || (linkedChangeId === null && changeOrderId === null)}
          onClick={() => {
            handleComplete({
              changeOrderId,
              displayValue,
            });
          }}
        >
          <Trans>Next</Trans>
        </Button>
      </div>
    );
  }
}

// TODO: the change orders api probably needs filtering capability
const CHANGE_ORDERS_QUERY = gql`
  query AllDraftChangeOrders($status: String!) {
    changeOrders(status: $status) {
      id
      customIdentifier
      currentVersion {
        id
        title
      }
    }
  }
`;

const NoChangeOrders = ({ classes }) => {
  return (
    <>
      <Typography variant="h3">No Change Orders available to trace</Typography>
      <Spacer factor={1} />
      <Typography>
        <Trans>To trace a Change Order, create a version or create a new Change Order.</Trans>
      </Typography>
      <Spacer factor={1} />
      <Link className={classes.link} style={{ float: 'right' }} onClick={() => navigate('/category/change-order')}>
        <SwapHoriz style={{ marginRight: 4 }} />
        <Trans>Change Orders</Trans>
      </Link>
    </>
  );
};

const NoChangeOrdersStyled = withStyles(styles)(NoChangeOrders);

function WithChangeOrders(Component) {
  return (props) => (
    <Query errorPolicy="all" query={CHANGE_ORDERS_QUERY} fetchPolicy={'network-only'} variables={{ status: 'draft' }}>
      {({ data, loading }) => {
        if (data === undefined || loading) return <div> ... </div>;

        if (!data.changeOrders || data.changeOrders.length === 0) return <NoChangeOrdersStyled />;

        const formattedChangeOrders =
          data.changeOrders &&
          data.changeOrders.map((co) => ({
            id: co.id,
            name: `${co.customIdentifier}: ${co.currentVersion.title}`,
          }));

        return <Component {...props} options={formattedChangeOrders} />;
      }}
    </Query>
  );
}

export default compose(
  withStyles(styles),
  WithChangeOrders,
)(SelectChangeOrder);
