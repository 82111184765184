import React, { PropsWithChildren } from 'react';
import { MenuItem } from '@material-ui/core';

interface WorkflowItemProps {
  title: string;
  disabled: boolean;
  onClick: (event: any) => void;
}

function TaskStatusMenuItem(props: PropsWithChildren<WorkflowItemProps>) {
  return (
    <MenuItem disabled={props.disabled} onClick={props.onClick}>
      {props.children} {props.title}
    </MenuItem>
  );
}

export default TaskStatusMenuItem;
