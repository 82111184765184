import React, { useContext, useEffect } from 'react';

import { get } from 'lodash';

import { DocumentBuilder } from 'compositions';
import { PDFData } from 'components';
import { LockedContext, withLockedContext } from 'contexts/lockedContext';

import { useVersion } from './gql';
import { PayloadFieldsProps } from '../index';


const PayloadFields = ({ versionId, ...props }: PayloadFieldsProps) => {
  const { data, loading, error, refetch } = useVersion(versionId);
  const { setLocked } = useContext(LockedContext);

  const inputMethod = get(data, 'supplierQuestionnaireVersion.inputMethod');

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching Supplier Questionnaire: ${err}`);
    });
  }, [inputMethod, refetch]);

  useEffect(() => {
    if (data) setLocked(data.supplierQuestionnaireVersion.locked);
  }, [data, setLocked]);

  if (!data || loading) return null;
  if (error)
    throw new Error(
      `Error loading Supplier Questionnaire Version ${versionId}\n${error}`,
    );

  const version = data.supplierQuestionnaireVersion
  if (inputMethod === 'BODY')
    return (
      <DocumentBuilder
      itemType="supplierQuestionnaire"
      refetchQueries={['SupplierQuestionnaireVersionQuery']}
      richTextOptionSet="large"
      layoutClasses={props.layoutClasses}
      versionData={version}
    />
  );

  if (inputMethod === 'UPLOAD')
    return (
      <PDFData versionData={version} layoutClasses={props.layoutClasses} refetch={refetch} />
  );
};

export default withLockedContext(PayloadFields);
