export default (theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  textAreaContainer: {
    // Need else it may shrink when there's no toolbar and no/minimal text
    minWidth: '55%',
    width: '100%',
    '& .ck-sticky-panel__content_sticky': {
      position: 'fixed',
    },
  },
  commentsSidebar: {
    width: '45%',
  },
  commentsNarrowSidebar: {
    width: '5%',
  },
  hiddenComments: {
    '& .ck-comment-marker': {
      backgroundColor: 'inherit',
    },
    '& $commentsSidebar': {
      display: 'none',
    },
  },
});
