import gql from 'graphql-tag';

import { useMutation, useQuery } from 'react-apollo';

import { TRIO_FIELDS } from 'components/TableTrio/gql';

export const queryNameToRefetch = 'ChangeOrderBuilderViewQuery';

const builderViewQuery = gql`
    query ChangeOrderBuilderViewQuery($itemId: ID) {
        changeOrder(id: $itemId) {
            id
            customIdentifier
            referenceId
            tags
            owners {
                id
                fullName
                title
            }
            approvers {
                id
                fullName
                title
            }
            currentUser {
                id
                readOnly
            }
            impactedItems {
                id
                currentVersionId
                customIdentifier
                currentStatusName
                title
                descriptionOfChange
                reasonForChange
                itemType
                versionIdentifier
                userCreatable
                locked
                version {
                  id
                  locked
                    item {
                      id
                        versions {
                            id
                            versionIdentifier
                            currentStatus {
                                id
                                name
                            }
                        }
                    }
                }
                taskReferencedVersions {
                    id
                    item {
                        id
                        itemType {
                            id
                            displaySlug
                        }
                        customIdentifier
                        currentVersion {
                          id
                            versionIdentifier
                            title
                            currentStatus {
                              id
                                name
                            }
                        }
                    }
                }
            }
            supportingItems {
                id
                currentVersion {
                    id
                    currentStatusName
                    currentStatus {
                        id
                        name
                    }
                    title
                    versionIdentifier
                }
                customIdentifier
                itemType {
                    id
                    name
                    userCreatable
                }
            }
            attachments {
                id
                url
                description
            }
            workingVersion {
                id
            }
            currentVersion {
                id
                releasedAt
                currentStatusName
                currentStatus {
                    id
                    name
                }
                assignedOwnerJobRole {
                  id
                  formattedRoleName
                  usersWithRole {
                    id
                    fullName
                  }
                }
                assignedApproverJobRole {
                  id
                  formattedRoleName
                  usersWithRole {
                    id
                    fullName
                  }
                }
                descriptionOfChange
                displayAsApplicationVersion
                impactAssessment
                implementationPlan
                justificationForRiskLevel
                locked
                reasonForChange
                trainingSummary
                riskLevel
                title
                creator {
                    id
                    fullName
                    title
                }
                owner {
                    id
                    fullName
                    title
                    status
                }
                approver {
                    id
                    fullName
                    title
                    status
                }
                permissibleOwners {
                    id
                    fullName
                    title
                    jobRoles {
                      id
                        formattedRoleName
                    }
                }
                permissibleApprovers {
                    id
                    fullName
                    title
                    jobRoles {
                      id
                        formattedRoleName
                    }
                }
                signingOwner {
                    id
                    firstName
                    lastName
                }
                signingApprover {
                    id
                    firstName
                    lastName
                }
                supportingItems {
                    id
                }
                impactedEmployees {
                    id
                    category
                    user {
                        id
                        firstName
                        lastName
                    }
                    trainingItem {
                        id
                        customIdentifier
                        itemType {
                            id
                            userCreatable
                        }
                    }
                    trainingSource {
                        id
                        item {
                            id
                            customIdentifier
                        }
                    }
                    trainingRecordVersion {
                        id
                        dueDate
                        currentStatus {
                            id
                            name
                            titleizedName
                        }
                        item {
                            id
                            customIdentifier
                            itemType {
                                id
                                userCreatable
                            }
                        }
                    }
                }
                impactedEmployeesTrainingProgress
                trainingImpactAssessments {
                    id
                    requiresReadTraining
                    impactedVersion {
                        id
                        title
                        item {
                            id
                            customIdentifier
                            itemType {
                              id
                                displaySlug
                                userCreatable
                            }
                        }
                    }
                    trainingImpactAssociatedCourses {
                        id
                        directlyAddedCourse
                        requiresCourseTraining
                        justificationForNoTraining
                        courseVersion {
                          id
                            title
                            versionIdentifier
                            currentStatus {
                                id
                                name
                            }
                            item {
                                id
                                customIdentifier
                                itemType {
                                  id
                                    displaySlug
                                    userCreatable
                                }
                            }
                        }
                        trainingImpactMasterEntries {
                            id
                            category
                            user {
                                id
                                fullName
                            }
                            trainingRecordVersion {
                                id
                                item {
                                    id
                                    customIdentifier
                                    itemType {
                                      id
                                        displaySlug
                                        userCreatable
                                    }
                                }
                            }
                        }
                    }
                    trainingImpactMasterEntries {
                        id
                        category
                        user {
                            id
                            fullName
                        }
                        trainingRecordVersion {
                            id
                            item {
                                id
                                customIdentifier
                                itemType {
                                  id
                                    displaySlug
                                    userCreatable
                                }
                            }
                        }
                    }
                }
                item {
                    id
                    ... on ChangeOrder {
                        impactedItems {
                            id
                        }
                    }
                }
                createdAt
                ${TRIO_FIELDS}
            }
            currentRelease {
                id
                releasedAt
                currentStatusName
                currentStatus {
                    id
                    name
                }
                descriptionOfChange
                displayAsApplicationVersion
                impactAssessment
                implementationPlan
                justificationForRiskLevel
                locked
                reasonForChange
                riskLevel
                title
                createdAt
                impactedEmployees {
                    id
                    category
                    user {
                        id
                        firstName
                        lastName
                    }
                    trainingItem {
                        id
                        customIdentifier
                        itemType {
                            id
                            userCreatable
                        }
                    }
                    trainingSource {
                        id
                        item {
                            id
                            customIdentifier
                        }
                    }
                    trainingRecordVersion {
                        id
                        currentStatus {
                            id
                            name
                            titleizedName
                        }
                        item {
                            id
                            customIdentifier
                            itemType {
                                id
                                userCreatable
                            }
                        }
                        dueDate
                    }
                }
                impactedEmployeesTrainingProgress
                trainingImpactAssessments {
                    id
                    impactedVersion {
                        id
                        title
                        item {
                            id
                            customIdentifier
                            itemType {
                                id
                                userCreatable
                                displaySlug
                            }
                        }
                    }
                    requiresReadTraining
                    trainingImpactAssociatedCourses {
                        id
                        courseVersion {
                          id
                            title
                            versionIdentifier
                            currentStatus {
                                id
                                name
                            }
                            item {
                                id
                                customIdentifier
                                itemType {
                                    id
                                    displaySlug
                                    userCreatable
                                }
                            }
                        }
                        directlyAddedCourse
                        requiresCourseTraining
                        justificationForNoTraining
                        trainingImpactMasterEntries {
                            id
                            user {
                                id
                                fullName
                            }
                            category
                            trainingRecordVersion {
                                id
                                item {
                                    id
                                    customIdentifier
                                    itemType {
                                        id
                                        userCreatable
                                    }
                                }
                            }
                        }
                    }
                    trainingImpactMasterEntries {
                        id
                        user {
                            id
                            fullName
                        }
                        category
                        trainingRecordVersion {
                            id
                            item {
                                id
                                customIdentifier
                                itemType {
                                    id
                                    userCreatable
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useBuilderViewQuery = (itemId: string) =>
  useQuery(builderViewQuery, {
    variables: {
      itemId,
    },
  });

const createAttachment = gql`
  mutation CreateChangeOrderAttachment($attachmentURL: String!, $versionId: ID!) {
    createChangeOrderAttachment(attachment: $attachmentURL, versionId: $versionId) {
      attachment {
        id
        url
        description
        changeOrderVersionId
        changeOrderVersion {
          id
          item {
            ... on ChangeOrder {
              id
              attachments {
                id
              }
            }
          }
          attachments {
            id
          }
        }
      }
    }
  }
`;

export function useCreateAttachment() {
  return useMutation(createAttachment);
}

export const updateAttachment = gql`
  mutation UpdateChangeOrderAttachment($attachmentId: ID!, $attachmentURL: String, $description: String) {
    updateChangeOrderAttachment(id: $attachmentId, attachment: $attachmentURL, description: $description) {
      attachment {
        id
        url
        description
        changeOrderVersionId
      }
    }
  }
`;

export function useUpdateAttachment() {
  return useMutation(updateAttachment);
}

export const destroyAttachmentMutation = gql`
  mutation DestroyChangeOrderAttachment($id: ID!) {
    destroyChangeOrderAttachment(id: $id) {
      attachment {
        id
        changeOrderVersion {
          id
          item {
            ... on ChangeOrder {
              id
              attachments {
                id
              }
            }
          }
          attachments {
            id
          }
        }
      }
    }
  }
`;

export function useDestroyAttachment() {
  return useMutation(destroyAttachmentMutation);
}

const addImpactedItemMutation = gql`
  mutation AddImpactedItem($changeOrderId: ID!, $versionIds: [ID!]!) {
    addChangeOrderImpactedItems(changeOrderId: $changeOrderId, versionIds: $versionIds) {
      changeOrder {
        id
        impactedItems {
          id
          currentVersionId
          customIdentifier
          currentStatusName
          title
          locked
          descriptionOfChange
          reasonForChange
          itemType
          versionIdentifier
          userCreatable
        }
      }
    }
  }
`;

export function useAddImpactedItems() {
  return useMutation(addImpactedItemMutation, {
    refetchQueries: [queryNameToRefetch, 'timelineQuery'],
  });
}

const addTaskToImpactedItemMutation = gql`
  mutation AddTaskToImpactedItem($versionId: ID!, $referencedTaskIds: [ID!]!) {
    addTaskReferencedVersion(versionId: $versionId, referencedTaskIds: $referencedTaskIds) {
      version {
        id
      }
    }
  }
`;

export function useAddTaskToImpactedItem() {
  return useMutation(addTaskToImpactedItemMutation, {
    refetchQueries: [queryNameToRefetch],
  });
}

const removeTaskToImpactedItemMutation = gql`
  mutation RemoveTaskFromImpactedItem($versionId: ID!, $taskId: ID!) {
    removeTaskReferencedVersion(versionId: $versionId, taskId: $taskId) {
      version {
        id
      }
    }
  }
`;

export function useRemoveTaskToImpactedItem() {
  return useMutation(removeTaskToImpactedItemMutation, {
    refetchQueries: [queryNameToRefetch],
  });
}

export const destroyLinkedChange = gql`
  mutation DestroyLinkedChange($input: DestroyLinkedChangeInput!) {
    destroyLinkedChange(input: $input) {
      errors
      version {
        id
      }
    }
  }
`;
