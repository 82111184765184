import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useMutation } from 'react-apollo';

import { LockedContext, withLockedContext } from 'contexts/lockedContext';
import { CheckList } from 'assets/icons';
import { Toggle } from 'components';
import Panel from 'components/core/Panel';
import SectionLabel from 'components/core/SectionLabel';

import { useStyles } from './styles';
import { TOGGLE_SUPPLIER_PASSED_AUDIT } from './gql';

interface Props {
  versionId: string;
  supplierPassedAudit: boolean | null;
}

function PayloadFields(props: Props) {
  const classes = useStyles();

  const [toggleSupplierPassedAudit] = useMutation(TOGGLE_SUPPLIER_PASSED_AUDIT, {
    refetchQueries: ['SupplierAuditReportVersionQuery'],
  });
  const { locked } = useContext(LockedContext);

  return (
    <Grid item xs={12}>
      <Panel>
        <SectionLabel icon={CheckList}>Audit Outcome</SectionLabel>
        <div className={classes.container}>
          <Typography variant="body2">Did this supplier pass the audit?</Typography>
          <Typography component="div" variant="caption" className={classes.toggleContainer}>
            <span className={`${classes.negativeLabel} ${classes.toggleText}`}>Fail</span>
            <Toggle
              size={'medium'}
              disabled={locked}
              onChange={(_, checked: boolean) => {
                toggleSupplierPassedAudit({
                  variables: { id: props.versionId, toggle: checked === undefined ? null : checked },
                }).catch((err) => {
                  throw err;
                });
              }}
              checked={null === props.supplierPassedAudit ? undefined : props.supplierPassedAudit}
            />
            <span className={`${classes.positiveLabel} ${classes.toggleText}`}>Pass</span>
          </Typography>
        </div>
      </Panel>
    </Grid>
  );
}

export default withLockedContext(PayloadFields);
