import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import Input from '@material-ui/icons/Input';

import PayloadFields from './PayloadFields';

import slugs from '../../displaySlugs';

import { docMetadata } from 'itemTypes/mixins/Document';
import { CREATE_VERIFICATION_RECORD } from './gql';
import Views from '../../workspaces/views';
import { recordViewColumns } from 'pages/DesignValidationProtocol';

const itemType = 'deviceVerification';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion',
    headerName: 'Version',
    type: 'version',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const recordsQuery = gql`
  query ListQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      currentVersion {
        id
        title
        id
        currentStatus {
          id
          name
        }
      }
      versions {
        ... on DeviceVerificationVersion {
          verificationRecordVersions {
            createdAt
            deviationVersions {
              id
              title
              item {
                id
                customIdentifier
                itemType {
                  displaySlug
                  id
                  name
                  userCreatable
                }
              }
            }
            isFulfillAllAcceptanceCriteria
            item {
              itemType {
                id
                userCreatable
                displaySlug
              }
              id
              customIdentifier
            }
            owner {
              id
              fullName
            }
            approver {
              id
              fullName
            }
            id
            title
            currentStatus {
              name
              id
            }
          }
        }
        id
        releasedAt
        locked
        title
        approver {
          id
          firstName
        }
        owner {
          id
          firstName
        }
        currentStatus {
          name
          id
        }
        versionIdentifier
      }
      currentRelease {
        id
        title
      }
      currentUser {
        id
        readOnly
      }
    }
  }
`;

const listViewQuery = gql`
  query deviceVerificationsQuery {
    deviceVerifications {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      createdAt
      currentVersion {
        id
        createdAt
        title
        versionIdentifier
        currentStatusName
      }
    }
  }
`;

export default {
  displaySlug: slugs[itemType],
  icon: Input,
  itemType,
  recordItemType: 'verificationRecord',
  createNewRecordOptions: {
    createNewRecordMutation: CREATE_VERIFICATION_RECORD,
    refetchQueries: [],
  },
  label: t`Verification Protocol`,
  categoryLabel: t`Verification Protocols`,
  EffectiveViewOptions: docMetadata.EffectiveViewOptions,
  RecordViewOptions: {
    query: recordsQuery,
    recordsProperty: 'verificationRecordVersions',
    recordViewColumns,
  },
  PayloadFields,
  listViewQuery,
  listViewColumns,
  listWorkspace: [Views.Table, Views.LinksGraph],
  recordWorkspace: [Views.Builder, Views.Effective, Views.Versions, Views.Records],
};
