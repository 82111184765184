import gql from 'graphql-tag';

export const CREATE_REFERENCED_DOCUMENT = gql`
  mutation createFormRecordAttachment($versionId: ID!, $attachmentURL: String!, $isExecutedAttachment: Boolean) {
    addAttachmentToRecordVersion(
      versionId: $versionId
      attachment: $attachmentURL
      isExecutedAttachment: $isExecutedAttachment
    ) {
      recordAttachment {
        id
        url
      }
    }
  }
`;

export const DESTROY_REFERENCED_DOCUMENT = gql`
  mutation destroyFormRecordAttachment($id: ID!) {
    removeAttachmentFromRecordVersion(id: $id) {
      recordAttachment {
        id
      }
    }
  }
`;
