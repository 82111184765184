import React from 'react';

import { Grid } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';

import semver from 'semver';

import Panel from 'components/core/Panel';
import { Spacer, TextAreaField } from 'components';

import TrainingImpact from './TrainingImpact';

import { TabProps } from '../PayloadFields';
import { queryNameToRefetch } from '../gql';

interface Props extends TabProps {}

function showTrainingImpactTable(version) {
  if (version.currentStatus.name === 'draft') return true;
  return !semver.lt(version.displayAsApplicationVersion, '7.6.0');
}

const TrainingImpactTab = (props: Props) => {
  const { layoutClasses, version } = props;
  return (
    <>
      {semver.lt('9.1.0', version.displayAsApplicationVersion) && (
        <Grid item xs={12} className={layoutClasses.gridGutterRight}>
          <Panel.Heading icon={Sort}>Training Summary</Panel.Heading>
          <TextAreaField
            locked={version.locked}
            id="training-summary"
            initialValue={version.trainingSummary}
            versionId={version.id}
            attrName="training_summary"
            rows={5}
            placeholder="Enter summary"
            refetchQueries={[queryNameToRefetch]}
          />
          <Spacer factor={2} />
        </Grid>
      )}
      {showTrainingImpactTable(version) && (
        <Grid item xs={12} className={layoutClasses.grid}>
          <TrainingImpact
            displayOnly={version.locked}
            assessments={version.trainingImpactAssessments || []}
            impactedEmployees={version.impactedEmployees}
            impactedEmployeesTrainingProgress={
              ['ready_for_training', 'closed'].includes(version.currentStatus.name)
                ? version.impactedEmployeesTrainingProgress
                : null
            }
          />
        </Grid>
      )}
    </>
  );
};

export default TrainingImpactTab;
