import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    avatarWrapper: {
      margin: '0 16px',
    },
    commentRow: {
      alignItems: 'center',
      display: 'flex',
      margin: '16px 0',
    },
    icon: {
      height: 16,
      width: 16,
      marginRight: 12,
    },
    nameAndTime: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);
