import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { AddProtocolJustification } from './__generated__/AddProtocolJustification';

const ADD_PROTOCOL_JUSTIFICATION = gql`
  mutation AddProtocolJustification($itemId: ID!, $parentVersionId: ID!) {
    addProtocolJustification(linkedItemId: $itemId, parentVersionId: $parentVersionId) {
      protocolJustification {
        id
      }
    }
  }
`;

const REMOVE_PROTOCOL_JUSTIFICATION = gql`
  mutation RemoveProtocolJustification($id: ID!) {
    removeProtocolJustification(id: $id) {
      success
    }
  }
`;

const UPDATE_PROTOCOL_JUSTIFICATION = gql`
  mutation UpdateProtocolJustification($id: ID!, $text: String!) {
    updateProtocolJustification(id: $id, text: $text) {
      protocolJustification {
        id
      }
    }
  }
`;

export function useAddJustification(versionId: number) {
  const [mutation] = useMutation<AddProtocolJustification>(ADD_PROTOCOL_JUSTIFICATION, {
    refetchQueries: ['VerificationAndValidationPlanVersionQuery'],
  });
  return (itemId: string): Promise<string | null> => {
    return mutation({ variables: { itemId, parentVersionId: versionId } }).then((res) => {
      if (res.data && res.data.addProtocolJustification) {
        return res.data.addProtocolJustification.protocolJustification.id;
      } else {
        return null;
      }
    });
  };
}

export function useRemoveJustification() {
  const [mutation] = useMutation(REMOVE_PROTOCOL_JUSTIFICATION, {
    refetchQueries: ['VerificationAndValidationPlanVersionQuery'],
  });
  return (justificationId) => mutation({ variables: { id: justificationId } });
}

export function useUpdateJustification(justificationId: string) {
  const [mutation] = useMutation(UPDATE_PROTOCOL_JUSTIFICATION, {
    refetchQueries: ['VerificationAndValidationPlanVersionQuery'],
  });
  return (text) => mutation({ variables: { id: justificationId, text } });
}
