import React, { useState, FC } from 'react';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import { Box, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import SettingsEditButton from '../SettingsEditButton';
import SectionTitle from 'components/SettingsSection/SectionTitle';
import Input from 'components/core/form/Input';

import { useStyles } from './styles';
import { createRpcUpdateData, useQueryPriorityCodes, useUpdatePriorityCode } from './utils';
import { EditProps } from '../../types';

const riskCodeTitles = {
  1: 'Acceptable Risk',
  2: 'Conditionally Acceptable Risk',
  3: 'Intolerable Risk',
};

interface Props extends EditProps {}

const RiskCodeConfiguration: FC<Props> = ({ name, editing, onEdit }) => {
  const classes = useStyles();
  const { data } = useQueryPriorityCodes();
  const [update] = useUpdatePriorityCode();
  const [updateData, setUpdateData] = useState<{ [id: string]: any }>({});

  if (!data) return null;
  const { riskPriorityCodes } = data;

  const handleSave = async () => {
    try {
      if (!isEmpty(updateData)) {
        await update({ variables: { input: { riskPriorityCodes: createRpcUpdateData(updateData) } } });
      }
      onEdit(null);
      setUpdateData({});
    } catch (error) {}
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, riskCodeId: string) => {
    const { name, value } = event.target;
    setUpdateData((currentUpdateData) => ({
      ...currentUpdateData,
      [riskCodeId]: { ...currentUpdateData[riskCodeId], [name]: value },
    }));
  };

  const InputLabel = ({ label }: { label: string }) => (
    <Box p={0.5}>
      <Typography variant="subtitle1" className={clsx(classes.inputLabel, { [classes.inputLabelDisabled]: !editing })}>
        {label}
      </Typography>
    </Box>
  );

  const inputFields = [
    { key: 'title', label: 'Title', valueKey: 'fullName' },
    { key: 'abbreviation', label: 'Abbreviation', valueKey: 'name' },
  ];

  return (
    <>
      <SectionTitle
        icon={<Error />}
        title="Risk Code Configuration"
        editButton={
          <SettingsEditButton
            isActive={editing}
            onClick={() => onEdit(name)}
            onActionButtonClick={handleSave}
            onCancel={() => onEdit(null)}
          />
        }
      />
      <div className={classes.riskCodeItems}>
        {riskPriorityCodes.map((item) => (
          <React.Fragment key={item.id}>
            <Box width={4} borderRadius={2} style={{ backgroundColor: item.color }} mr={1} />
            <div className={classes.riskCodeItem}>
              <Box display="flex" mb={1}>
                <div className={classes.riskCodeColorItem} style={{ backgroundColor: item.color }} />
                <Typography variant="h6" className={classes.riskColorTitle}>
                  {riskCodeTitles[item.id]}
                </Typography>
              </Box>
              {inputFields.map((input) => (
                <Box key={input.key} className={classes.inputFields}>
                  <InputLabel label={input.label} />
                  <Input
                    key={`${item.id}-${editing}`}
                    name={input.valueKey}
                    defaultValue={item[input.valueKey]}
                    disabled={!editing}
                    onChange={(event) => handleChange(event, item.id)}
                  />
                </Box>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default RiskCodeConfiguration;
