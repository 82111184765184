import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    notificationsMenuWrapper: {
      backgroundColor: theme.palette.common.white,
      width: '100%',
    },
  }),
);
