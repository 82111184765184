import gql from 'graphql-tag';

export const currentUsersTrainingRecordsQuery = gql`
  query FilteredTrainingRecordsSearch($userIds: [ID!]!) {
    trainingRecordSearch(userIds: $userIds, statusNames: ["to_do", "failed"]) {
      id
      customIdentifier
      itemType {
        id
        displaySlug
        userCreatable
      }
      currentVersion {
        id
        dueDate
        title
        currentStatus {
          id
          name
        }
      }
    }
  }
`;
