import React from 'react';
import { Trans } from '@lingui/macro';

import { AttachmentDependentWorkflowStatuses } from 'workflows/statuses';
import { RejectReason, YesOrNo } from '../views';

export default {
  initialStep: 'confirmTransition',
  internalSteps: {
    confirmTransition: {
      component: (props) => {
        const prompt =
          'Are you sure you want to void your Completed signature of this Item? This action can not be undone.';
        return (
          <YesOrNo heading={<Trans>Void</Trans>} prompt={prompt} closeOnNo yesLabel="Yes" noLabel="No" {...props} />
        );
      },
      getNextStep: (cancel) => (cancel ? 'voidReason' : undefined),
    },
    voidReason: {
      component: (props) => {
        const heading = `As the Owner of ${props.itemCustomIdentifier}, I Void my signature.`;
        return <RejectReason {...props} title="Void" heading={heading} />;
      },
      getNextStep: () => 'complete',
    },
  },
  getFinalResult: (props) => {
    return !props.confirmTransition
      ? null
      : {
          ...props.voidReason.signature,
          reasonForSignature: props.voidReason.reason,
          toStatus: AttachmentDependentWorkflowStatuses.Void.id,
        };
  },
};
