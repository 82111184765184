import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import Business from '@material-ui/icons/Business';
import Flag from '@material-ui/icons/Flag';

import { useMutation } from 'react-apollo';

import { MakeLink, SingleItemSelect, VersionAndStatusDot } from 'components';
import Panel from 'components/core/Panel';
import SectionLabel from 'components/core/SectionLabel';
import { withLockedContext } from 'contexts/lockedContext';

import { SET_SUPPLIER_AUDIT_PLAN_ID, SUPPLIER_AUDIT_PLAN_OPTIONS_QUERY } from '../gql';
import { useStyles } from './styles';

interface Props {
  currentVersion: any;
  supplierPassedAudit: boolean | null;
}

function AssociatedSupplierAuditPlanCard(props: Props) {
  const classes = useStyles();

  const [setSupplierAuditPlanId] = useMutation(SET_SUPPLIER_AUDIT_PLAN_ID, {
    refetchQueries: ['SupplierAuditReportVersionQuery'],
  });

  const updateSupplierAuditReport = (supplierAuditPlan) => {
    setSupplierAuditPlanId({
      variables: {
        id: props.currentVersion.id,
        supplierAuditPlanId: supplierAuditPlan ? supplierAuditPlan.currentVersion.id : null,
      },
    }).catch((err) => {
      throw new Error(`Error refetching Associated supplier audit report version: ${err}`);
    });
  };

  return (
    <Grid item xs={5}>
      <Panel>
        <SectionLabel icon={Business}>Associated Supplier Audit Plan</SectionLabel>
        <SingleItemSelect
          value={props.currentVersion.supplierAuditPlanVersion}
          disabled={false}
          onChange={updateSupplierAuditReport}
          placeholder="Select Supplier Audit Plan"
          options={{
            query: SUPPLIER_AUDIT_PLAN_OPTIONS_QUERY,
            optionsPath: 'supplierAuditPlans',
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 28 }}>
          <Business style={{ fontSize: 15 }} />
          <Typography variant="h4" style={{ marginLeft: 6, fontSize: 11 }}>
            ASSOCIATED SUPPLIER
          </Typography>
        </div>
        <div style={{ marginTop: 10 }}>
          {props.currentVersion.supplierAuditPlanVersion &&
          props.currentVersion.supplierAuditPlanVersion.supplierVersion ? (
            <div>
              <MakeLink item={props.currentVersion.supplierAuditPlanVersion.supplierVersion.item} newTab>
                <Typography variant="h5" className={classes.supplierLink}>
                  {props.currentVersion.supplierAuditPlanVersion.supplierVersion.item.customIdentifier}:{' '}
                  {props.currentVersion.supplierAuditPlanVersion.supplierVersion.title}
                </Typography>
              </MakeLink>
              <div style={{ marginTop: 6 }}>
                <VersionAndStatusDot
                  version={props.currentVersion.supplierAuditPlanVersion.supplierVersion.versionIdentifier}
                  status={props.currentVersion.supplierAuditPlanVersion.supplierVersion.currentStatus.name}
                />
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <Flag style={{ fontSize: 20, position: 'relative', left: -3 }} />
              <Typography variant="h5" style={{ marginLeft: 6, fontSize: 13 }}>
                No supplier associated. Select an audit plan to display associated supplier.
              </Typography>
            </div>
          )}
        </div>
      </Panel>
    </Grid>
  );
}

export default withLockedContext(AssociatedSupplierAuditPlanCard);
