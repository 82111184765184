import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    diagram: {
      textAlign: 'center',
    },
    group: {
      display: 'flex',
      width: 'max-content',
      alignItems: 'center',
      margin: '0 auto 60px',
    },
    item: {
      marginRight: '30px',
      marginLeft: '30px',
      padding: '20px',
      border: '1px solid #ccc',
      height: 'fit-content',

      '&:last-child': {
        marginRight: '0',
      },
      '&:first-child': {
        marginRight: '0',
      },
    },
    switchViewPanel: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    switchButton: {
      margin: 0,
      padding: 0,
    },
    switchViewPanelText: {
      ...theme.typography.subtitle1,
      width: '100%',
      marginRight: '16px',
    },
    inlineToggle: {
      display: 'inline',
      '& > span': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    divider: {
      marginBottom: theme.spacing(3),
    },
  }),
);
