import React, { useState, useMemo } from 'react';

import { Popover, Typography, Button, TextField, InputAdornment } from '@material-ui/core';
import { KeyboardArrowRight, Done, Notes, Search } from '@material-ui/icons';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';
import camelCase from 'lodash/camelCase';
import flowRight from 'lodash/flowRight';

import { useStyles } from './styles';
import { Direction } from 'types';

import { Loading, Spacer } from 'components';
import { useSearchResultMap } from 'hooks/items/useSearch';
import { useAllItemTypes } from 'hooks/useAllItemTypes';
import { ItemTypeIndex } from 'itemTypes';
import ItemTypes from 'itemTypes';

interface Props {
  advancedSearch?: {
    onClick: () => void;
  };
  anchorElement: any;
  selectedItemId?: number;
  baseRecordId?: string;
  categoryWhitelist: any;
  closeSelf: () => void;
  direction?: Direction;
  i18n?: any;
  onSelected: (selected: any[]) => {};
  open: boolean;
  traceMatrixId?: string;
  excludedCurrentStatuses?: string[];
  excludedIds?: string[];
  itemTypes?: string[];
  baseItemType?: string;
  showFullItewlist?: boolean;
  isRiskTrace?: boolean;
}

function QuickSearchPopover(props: Props) {
  const { baseItemType, baseRecordId, direction, traceMatrixId, i18n, isRiskTrace, excludedIds } = props;
  const classes = useStyles();
  const [skipSearch, setSkipSearch] = useState(true);

  const [searchTerm, setSearchTerm] = useState(null);

  const { findTypeByName } = useAllItemTypes();

  const itemTypeName = findTypeByName(baseItemType || 'requirement');
  let suggestedDownstreamItemTypes: string[] | null;
  if (traceMatrixId) {
    if (isRiskTrace) {
      suggestedDownstreamItemTypes = itemTypeName.traceableRiskDownstreamTypes;
    } else {
      suggestedDownstreamItemTypes = itemTypeName.traceableDesignDownstreamTypes;
    }
  } else {
    suggestedDownstreamItemTypes = itemTypeName.suggestedDownstreamItems;
  }

  let suggestedUpstreamItemTypes: string[] | null;
  if (traceMatrixId) {
    if (isRiskTrace) {
      suggestedUpstreamItemTypes = itemTypeName.traceableRiskUpstreamTypes;
    } else {
      suggestedUpstreamItemTypes = itemTypeName.traceableDesignUpstreamTypes;
    }
  } else {
    suggestedUpstreamItemTypes = itemTypeName.suggestedUpstreamItems;
  }

  const suggestedItemTypes = useMemo(() => {
    return direction === 'upstream'
      ? suggestedUpstreamItemTypes
      : direction === 'downstream'
      ? suggestedDownstreamItemTypes
      : [];
  }, [suggestedUpstreamItemTypes, suggestedDownstreamItemTypes, direction]);

  const allSuggestedItemTypes = useMemo(() => {
    const upstream = suggestedUpstreamItemTypes || [];
    const downstream = suggestedDownstreamItemTypes || [];

    const combinedSet = new Set([...upstream, ...downstream]);
    setSkipSearch(false);
    return Array.from(combinedSet);
  }, [suggestedUpstreamItemTypes, suggestedDownstreamItemTypes]);

  const [selected, setSelected] = useState<any[]>([]);
  const [chosenItemType, setChosenItemType] = useState<string | ''>('');
  const [chosenItemData, setChosenItemData] = useState<{ icon: any; label: string } | null>(null);
  const { resultMap, loading } = useSearchResultMap({
    query: searchTerm,
    itemTypes: allSuggestedItemTypes,
    linksGraphRootId: baseRecordId,
    traceMatrixId: traceMatrixId || '',
    excludedCurrentStatuses: props.excludedCurrentStatuses,
    excludedIds: excludedIds,
    skipSearch: skipSearch,
  });

  const filteredResultMap = suggestedItemTypes
    ? suggestedItemTypes.reduce((filteredObj, key) => {
        if (resultMap.hasOwnProperty(key)) {
          filteredObj[key] = resultMap[key];
        }
        return filteredObj;
      }, {})
    : {};
  const additionalItemTypes = {
    deviceSafetyCharacteristic: {
      icon: ItemTypes.DeviceSafetyCharacteristicsReport.icon,
      label: t`Device Safety Characteristics`,
    },
  };

  const mergedItemTypeIndex = { ...ItemTypeIndex, ...additionalItemTypes };
  const renderSuggestedItemType = (name) => {
    const itemTypeMeta = mergedItemTypeIndex[camelCase(name)];

    if (itemTypeMeta === undefined) return;
    const { icon: Icon, label } = itemTypeMeta;
    return (
      <div
        className={classes.containerSuggestedItem}
        onClick={() => {
          setChosenItemType(name);
          setChosenItemData({ icon: Icon, label: label.id });
        }}
      >
        <div>
          <Icon className={classes.icon} />
          <Typography variant="body2" component="span" className={classes.typography}>
            {label.id}
          </Typography>
        </div>
        <KeyboardArrowRightIcon className={classes.arrowIcon} />
      </div>
    );
  };

  const resetDefaults = () => {
    setSearchTerm(null);
    setSelected([]);
    setChosenItemType('');
    setChosenItemData(null);
  };

  const onKeyPress = (e) => {
    const term = e.target.value;
    if (term && e.key === 'Enter') {
      setSearchTerm(term.trim());
    }
  };
  const displayResults = Object.keys(resultMap).length > 0;
  const isSelected = (itemId) => {
    return selected.some((item) => item.id === itemId);
  };

  const getTitleText = () => {
    return props.traceMatrixId ? 'Allowed Traces' : 'Suggested items';
  };

  const RightArrow = () => (
    <KeyboardArrowRight
      style={{
        fontSize: 16,
        verticalAlign: 'text-bottom',
      }}
    />
  );

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: direction === 'upstream' ? 'right' : 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: direction === 'upstream' ? 'right' : 'left',
      }}
      onClose={() => {
        resetDefaults();
        props.closeSelf();
      }}
      classes={{ paper: classes.linkModalRoot }}
    >
      <Spacer factor={1} />

      <>
        {displayResults ? (
          <>
            <div className={classes.headerContainer}>
              <Typography variant="body2" component="div" className={classes.suggestedItems}>
                {getTitleText()}
              </Typography>
              {props.showFullItewlist && (
                <div className={classes.rightHeader}>
                  <Typography
                    variant="body2"
                    component="div"
                    className={classes.fullItemList}
                    onClick={props.advancedSearch && props.advancedSearch.onClick}
                  >
                    Full Item List
                  </Typography>
                  <Typography variant="body2" component="div" className={classes.suggested} onClick={() => null}>
                    Suggested
                  </Typography>
                </div>
              )}
            </div>
            {chosenItemType && chosenItemType.length > 0 && chosenItemData && (
              <>
                <TextField
                  autoComplete="off"
                  id="link-input"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder={i18n._(t`Search suggested items`)}
                  className={classes.padding}
                  onKeyPress={onKeyPress}
                  InputProps={{
                    'aria-label': i18n._(t`Link Search`),
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                    },
                    startAdornment: (
                      <InputAdornment style={{ paddingTop: 2 }} position="start">
                        <Search className={classes.search} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    letterSpacing: 'normal',
                    display: 'inline',
                    marginLeft: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    resetDefaults();
                  }}
                >
                  Suggested item{''}
                  <span key={chosenItemType} style={{ lineHeight: '18px', letterSpacing: '0.003em', fontWeight: 600 }}>
                    <RightArrow />
                    <chosenItemData.icon className={classes.icon} />
                    {chosenItemData.label}
                  </span>
                </Typography>
                <Spacer factor={1} />
                <div>
                  {filteredResultMap[chosenItemType] &&
                    filteredResultMap[chosenItemType].map((item) => {
                      return (
                        <div
                          key={item.id}
                          onClick={() => {
                            if (isSelected(item.id)) {
                              setSelected(selected.filter((selectedItem) => selectedItem.id !== item.id));
                            } else {
                              setSelected([...selected, item]);
                            }
                          }}
                          className={`${(isSelected(item.id) && classes.selectedRow) || ''}`}
                        >
                          <div
                            style={{
                              letterSpacing: 'normal',
                              display: 'inline',
                              marginLeft: '16px',
                            }}
                          >
                            {isSelected(item.id) && <Done className={classes.icon} />}
                            {!isSelected(item.id) && <Notes className={classes.icon} />}

                            <Typography variant="body2" component="span">
                              {item.customIdentifier + ': ' + item.title}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
            <div className={classes.results} style={{ display: !chosenItemType ? 'block' : 'hidden' }}>
              {!chosenItemType && suggestedItemTypes && suggestedItemTypes.length > 0
                ? //@ts-ignore
                  suggestedItemTypes.map((itemType) => {
                    return <div>{renderSuggestedItemType(itemType)}</div>;
                  })
                : !chosenItemType &&
                  props.showFullItewlist && (
                    <>
                      <Typography
                        variant="body2"
                        style={{
                          paddingLeft: 10,
                          fontSize: '11px',
                          lineHeight: '16px',
                          fontWeight: 400,
                          letterSpacing: '0.003em',
                        }}
                      >
                        There are no suggested item types for linking.
                        <br />
                        View{' '}
                        <button
                          style={{
                            color: '#6BAFAA',
                            background: 'none',
                            border: 'none',
                            padding: 0,
                            font: 'inherit',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (props.advancedSearch && props.advancedSearch.onClick) {
                              props.advancedSearch.onClick();
                            }
                          }}
                        >
                          full item list.
                        </button>
                      </Typography>
                    </>
                  )}
            </div>

            <hr className={`${classes.splitter} ${classes.margin}`} />
            <div className={classes.buttonSection}>
              <Button
                disabled={selected.length === 0}
                variant="contained"
                color="primary"
                onClick={() => {
                  props.onSelected(selected);
                  resetDefaults();
                  props.closeSelf();
                }}
                fullWidth
              >
                Submit {selected.length > 0 && `(${selected.length})`}
              </Button>
            </div>
            <Spacer factor={2} />
          </>
        ) : (
          <Typography variant="body2" className={classes.noResults}>
            {loading ? <Loading size={30} style={{ height: 70 }} /> : 'No results found.'}
          </Typography>
        )}
      </>
    </Popover>
  );
}

export default flowRight([withI18n()])(QuickSearchPopover);
