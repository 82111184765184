// import { Ballot, DescriptionOutlined, FolderShared, InputOutlined, PermDataSetting, School, AccountTree} from '@mui/icons-material';
import get from 'lodash/get';
import {
  AssignmentLate,
  Ballot,
  DescriptionOutlined,
  FolderShared,
  InputOutlined,
  PermDataSetting,
  School,
} from '@material-ui/icons';

import AccountTree from 'assets/icons/AccountTree';
import CorporateFare from 'assets/icons/CorporateFare';
import DocumentScanner from 'assets/icons/DocumentScanner';
import Verified from 'assets/icons/Verified';
import Factory from 'assets/icons/Factory';
import Medication from 'assets/icons/Medication';
import AssuredWorkload from 'assets/icons/AssuredWorkload';
import Schema from 'assets/icons/Schema';
import GppGood from 'assets/icons/GppGood';
import Inventory from 'assets/icons/Inventory';
import Warehouse from 'assets/icons/Warehouse';

const DISCIPLINES_CONFIG = [
  { name: 'management_responsibility', Icon: CorporateFare },
  { name: 'document_control', Icon: DocumentScanner },
  { name: 'quality_management', Icon: Verified },
  { name: 'training', Icon: School },
  { name: 'supplier_management', Icon: Factory },
  { name: 'software', Icon: AssignmentLate },
  { name: 'design', Icon: PermDataSetting },
  { name: 'testing', Icon: Ballot },
  { name: 'pharmacological', Icon: Medication },
  { name: 'regulatory', Icon: AssuredWorkload },
  { name: 'production_&_process_controls', Icon: Schema },
  { name: 'nemedio_validation', Icon: GppGood },
];

const PRODUCTS_CONFIG = [
  { name: 'project_management', Icon: AccountTree },
  { name: 'design_inputs', Icon: InputOutlined },
  { name: 'risk_management_file', Icon: AssignmentLate },
  { name: 'software_file', Icon: AssignmentLate },
  { name: 'cybersecurity', Icon: AssignmentLate },
  { name: 'usability_engineering_file', Icon: FolderShared },
  { name: 'regulatory', Icon: AssuredWorkload },
  { name: 'testing', Icon: Ballot },
  { name: 'design_outputs', Icon: Inventory },
  { name: 'device_master_record', Icon: Factory },
  { name: 'device_history_record', Icon: Warehouse },
  { name: 'drug_master_file', Icon: Medication },
];

export const getDisciplineIcon = (name: string) => {
  return DISCIPLINES_CONFIG.map((c) => c.name).includes(name)
    ? get(DISCIPLINES_CONFIG.find((c) => c.name === name), 'Icon', DescriptionOutlined)
    : DescriptionOutlined;
};

export const getProductIcon = (name: string) => {
  return PRODUCTS_CONFIG.map((c) => c.name).includes(name)
    ? get(PRODUCTS_CONFIG.find((c) => c.name === name), 'Icon', DescriptionOutlined)
    : DescriptionOutlined;
};
