import React from 'react';
import flowRight from 'lodash/flowRight';
import snakeCase from 'lodash/snakeCase';

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  withStyles,
} from '@material-ui/core';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const styles = (theme) => ({
  icon: {
    fontSize: 13,
    color: theme.palette.blueDark,
    marginRight: 4,
    marginBottom: 2,
  },
  expansionPanelRoot: {
    '&:before': {
      backgroundColor: 'transparent',
    },
    '&$expanded': {
      margin: '0 0 0 4px',
    },
    margin: '0 0 0 4px',
  },
  expansionPanelDetailsRoot: {
    display: 'block',
    padding: 0,
  },
  expansionPanelRecordDetailsRoot: {
    display: 'block',
    padding: '0 0 0 12px',
  },
  expansionPanelSummaryRoot: {
    minHeight: 0,
    padding: '0 0 0 12px',
  },
  expansionPanelSummaryExpandIcon: {
    padding: 4,
  },
  expansionPanelSummaryContent: {
    margin: '4px 0',
    '&$expanded': {
      margin: '4px 0',
    },
  },
  // Material-UI expects a class name matching the local rule:
  // https://material-ui.com/customization/components/#use-rulename-to-reference-a-local-rule-within-the-same-style-sheet
  expanded: {
    // Material-UI makes this difficult to override. Utilizing `!important` as a hack fix.
    'min-height': '0 !important',
  },
});

function ExpansionPanelWrapper({ categoryName, classes, icon, index, children, defaultExpanded = false, onClick }) {
  const Icon = icon;
  return (
    <ExpansionPanel
      key={`${snakeCase(categoryName)}-${index}`}
      classes={{
        root: classes.expansionPanelRoot,
        expanded: classes.expanded,
      }}
      elevation={0}
      defaultExpanded={defaultExpanded}
    >
      <ExpansionPanelSummary
        aria-controls={`${snakeCase(categoryName)}-${index}-summary`}
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent,
          expanded: classes.expanded,
          expandIcon: classes.expansionPanelSummaryExpandIcon,
        }}
        expandIcon={<ArrowDropDown />}
        id={`${snakeCase(categoryName)}-${index}-summary`}
        onClick={onClick}
      >
        {icon && <Icon className={classes.icon} />}
        <Typography
          variant="caption"
          style={{
            fontWeight: 600,
            opacity: 0.6,
          }}
        >
          {categoryName}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: classes.expansionPanelRecordDetailsRoot,
        }}
      >
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default flowRight([withStyles(styles, { withTheme: true })])(ExpansionPanelWrapper);
