import { ItemTypeDefinition } from 'itemTypes';
import slugs from '../../displaySlugs';
import { t } from '@lingui/macro';
import Brush from '@material-ui/icons/Brush';
import gql from 'graphql-tag';
import RecordPage from './RecordPage';
import PayloadFields from './PayloadFields';
import Views from 'workspaces/views';

const listViewColumns = [
  {
    field: 'customIdentifier',
    headerName: 'ID',
    type: 'id',
  },
  {
    field: 'currentVersion.title',
    headerName: 'Title',
    type: 'regular_text',
  },
  {
    field: 'currentVersion.description',
    headerName: 'Description',
    type: 'long_text',
  },
  {
    field: 'currentVersion.numberOfCourses',
    headerName: '# of Courses',
    type: 'short_text',
  },
  {
    field: 'currentVersion.currentStatusName',
    headerName: 'Status',
    type: 'status',
  },
  {
    field: 'currentVersion.createdAt',
    headerName: 'Initiation Date',
    type: 'date',
  },
  {
    field: 'owner',
    headerName: 'Owner',
    type: 'user',
  },
  {
    field: 'approver',
    headerName: 'Approver',
    type: 'user',
  },
];

const listViewQuery = gql`
  query CurriculaQuery {
    curricula {
      id
      itemType {
        id
        displaySlug
        userCreatable
      }
      customIdentifier
      createdAt
      currentVersion {
        id
        createdAt
        versionIdentifier
        title
        description
        numberOfCourses
        currentStatusName
      }
      owner {
        id
        fullName
      }
      approver {
        id
        fullName
      }
    }
  }
`;

export default {
  itemType: 'curriculum',
  displaySlug: slugs['curriculum'],
  label: t`Curriculum`,
  categoryLabel: t`Curricula`,
  icon: Brush,
  listViewQuery,
  listViewColumns,
  RecordPage,
  PayloadFields,
  listWorkspace: [Views.Table, Views.LinksGraph],
  recordWorkspace: [Views.Builder],
} as ItemTypeDefinition;
