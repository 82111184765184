import React from 'react';
import {
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import Edit from '@material-ui/icons/Edit';

import { useStyles } from './styles';

function ReferenceIdMenuItem(props: {
  referenceId: string | null;
  onClick: React.MouseEventHandler;
}) {
  const classes = useStyles();

  return (
    <>
      <MenuItem disabled classes={{ root: classes.menuItemHeader }}>
        <Typography variant="overline" classes={{ root: classes.refIdText }}>
          <Trans>Reference Id:</Trans> {props.referenceId}
        </Typography>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItemText }}
        onClick={props.onClick}
      >
        <ListItemIcon classes={{ root: classes.icon }}>
          <Edit classes={{ root: classes.icon }} />
        </ListItemIcon>
        <ListItemText
          id="add-reference-id"
          primaryTypographyProps={{ variant: 'body2' }}
          classes={{ root: classes.menuItem }}
        >
          {props.referenceId ? (
            <Trans>Edit Reference ID</Trans>
          ) : (
            <Trans>Add Reference ID</Trans>
          )}
        </ListItemText>
      </MenuItem>
    </>
  );
}

export default ReferenceIdMenuItem;
