import { withApollo } from 'react-apollo';
import { navigate } from '@reach/router';

function SignOut(props) {
  window.memoryDB.removeItem('token');
  window.Intercom && window.Intercom('shutdown'); // clears out user data that is passed through API
  props.client.cache.reset();
  props.reloadApp();
  navigate('/login'); // eslint-disable-line @typescript-eslint/no-floating-promises
  return null;
}

export default withApollo(SignOut);
