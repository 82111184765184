import React from 'react';

import versionSequence from 'utils/versionSequence';

import { useStyles } from './styles';

export function VersionCell({ data }) {
  const classes = useStyles();
  // currentStatusName is deprecated but due to the global list view configured by itemTypes/NAME/index.[tj]s
  // we're stuck with it for some time. The alternative is currentStatus.name, which is used by new code
  // that also, unfortunately, depends on the global list view
  return <div className={classes.content}>{versionSequence(data.versionIdentifier, data.currentStatusName || data.currentStatus.name)}</div>;
}

export const versionHeaderStyle = { textAlign: 'center' };
