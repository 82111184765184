import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  noOutline: {
    '& table tbody > tr:hover': {
      '& td': {
        borderColor: '#ebeced',
      },
    },
    '& table tbody > tr:hover:not(:first-child)': {
      '& td': {
        borderTopColor: 'transparent',
      },
    },
  },
}));
