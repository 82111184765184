import React, { useEffect, useContext } from 'react';

import { PayloadFieldsProps } from 'itemTypes';
import GuidedEditorTabs from './GuidedEditorTabs';
import { useVersion } from './gql';
import { withLockedContext, LockedContext } from 'contexts/lockedContext';
import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';

interface Props {
  activeWorkspaceView: any;
}

function PayloadFields(props: Props & PayloadFieldsProps) {
  const { data, loading, error, refetch } = useVersion(props.versionId || '');
  const { setLocked } = useContext(LockedContext);

  useEffect(() => {
    refetch().catch((err) => {
      throw new Error(`Error refetching VVP: ${err}`);
    });
  }, [refetch]);

  useEffect(() => {
    if (data) setLocked(data.verificationAndValidationPlanVersion.locked);
  }, [data, setLocked]);

  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Verification And Validation Plan version ${props.versionId}\n${error}`);

  const currentVersion = data.verificationAndValidationPlanVersion;

  const renderPage = () => {
    return (
      <GuidedEditorTabs
        viewType={props.activeWorkspaceView.id}
        currentVersion={currentVersion}
        layoutClasses={props.layoutClasses}
        locked={currentVersion.locked}
        onUpdate={refetch}
      />
    );
  };
  return renderPage();
}

export default withWorkspaceViews()(withLockedContext(PayloadFields));
