import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import ContactMail from '@material-ui/icons/ContactMail';

import slugs from '../../displaySlugs';
import { docMetadata } from '../mixins/Document';

const itemType = 'qualityAgreement';

export default {
  categoryLabel: t`Quality Agreement`,
  displaySlug: slugs[itemType],
  label: t`Quality Agreement`,
  listViewQuery: gql`
    query qualityAgreementListViewQuery {
      qualityAgreements {
        id
        itemType {
          id
          displaySlug
          userCreatable
        }
        customIdentifier
        owner {
          id
          fullName
        }
        approver {
          id
          fullName
        }
        createdAt
        currentVersion {
          id
          createdAt
          title
          body
          versionIdentifier
          currentStatusName
        }
      }
    }
  `,
  itemType,
  icon: ContactMail,
  ...docMetadata,
};
