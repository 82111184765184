import React from 'react';

import MateralUIButton, { ButtonProps } from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

interface Props {
  loading?: boolean;
}

// In Material-UI 5.x we should get access to LoadingButton:
// (https://github.com/mui-org/material-ui/blob/next/packages/material-ui-lab/src/LoadingButton/LoadingButton.js)
const Button = React.forwardRef<HTMLButtonElement, Props & ButtonProps>((props, ref) => {
  const { children, loading, ...buttonProps } = props;

  return (
    <MateralUIButton {...buttonProps} ref={ref}>
      {loading ? <CircularProgress size={24} color="inherit" /> : children}
    </MateralUIButton>
  );
});

export default Button;
