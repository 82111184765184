import React, { ComponentType } from 'react';

import { SvgIconProps } from '@material-ui/core/SvgIcon';
import AttachFile from '@material-ui/icons/AttachFile';

import { useQuery } from 'react-apollo';

import { IconButton } from 'components';
import { DirectUploadQuery } from 'components/WithUploadParams/__generated__/DirectUploadQuery';
import { uploadQuery } from 'components/WithUploadParams/directUploadQuery';

interface FileUploadProps {
  accept?: Array<string> | undefined;
  params: {
    name: string;
    value: string;
  }[];
  url: string;
  onUploadSuccess: (fileUrl: string) => void;
  onUploadStart: () => void;
  disabled?: boolean;
  singleAttachment: boolean;
  hasAttachment?: boolean;
  Icon: ComponentType<SvgIconProps>;
}
class FileUpload extends React.Component<FileUploadProps, {}> {
  static defaultProps = {
    Icon: AttachFile,
  };

  formRef = React.createRef<HTMLFormElement>();
  inputRef = React.createRef<HTMLInputElement>();

  handleFileUpload = async () => {
    if (!this.formRef.current) return;
    const formData = new FormData(this.formRef.current);
    // aws apears to not handle spaces in filenames correctly
    const file = formData.get('file') as File;
    const sanitizedFilename = file.name.replace(/\s/g, '_');
    formData.set('file', new File([file], sanitizedFilename, { type: file.type }));
    const resp = await fetch(this.props.url, {
      method: 'POST',
      body: formData,
    });

    if (!resp.ok) throw new Error(`Upload failed: ${resp.statusText}`);

    if (!resp.headers.get('Location')) throw new Error('Upload failed: response contained no Location header');

    return resp.headers.get('Location');
  };

  renderAttachFile = () => {
    if (this.props.singleAttachment && !this.props.hasAttachment) {
      return (
        <IconButton
          disabled={this.props.disabled}
          Icon={this.props.Icon}
          size="large"
          onClick={() => {
            if (this.inputRef.current && !this.props.disabled) this.inputRef.current.click();
          }}
        />
      );
    } else if (!this.props.singleAttachment) {
      return (
        <IconButton
          disabled={this.props.disabled}
          Icon={this.props.Icon}
          size="large"
          onClick={() => {
            if (this.inputRef.current && !this.props.disabled) this.inputRef.current.click();
          }}
        />
      );
    }
  };

  render() {
    let accept;

    if (this.props.accept) accept = this.props.accept.join(',');

    return (
      <form action={this.props.url} method="post" ref={this.formRef}>
        {this.props.params.map(({ name, value }) => (
          <input type="hidden" name={name} value={value || ''} key={name} />
        ))}
        <input
          ref={this.inputRef}
          type="file"
          name="file"
          accept={accept}
          style={{ display: 'none' }}
          onChange={async (e) => {
            e.preventDefault();
            this.props.onUploadStart();
            const file = await this.handleFileUpload();
            if (file) this.props.onUploadSuccess(file);

            if (this.inputRef.current) this.inputRef.current.value = '';
          }}
        />
        {this.renderAttachFile()}
        <br />
      </form>
    );
  }
}

const withUploadParams = (Cmp) => (cmpProps) => {
  const { data, loading, error } = useQuery<DirectUploadQuery>(uploadQuery, {
    fetchPolicy: 'network-only',
  });

  if (error) throw new Error(`${error}`);
  if (!data || loading) return null;
  const directUpload = data.newDirectUpload;

  return <Cmp url={directUpload.url} params={directUpload.params} {...cmpProps} />;
};

export default withUploadParams(FileUpload);
