import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  addCircleIcon: {
    color: theme.palette.secondary.main,
    fontSize: 20,
  },
  tag: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.tags.background,
    borderRadius: 16,
    alignItems: 'center',
    display: 'inline-flex',
    marginBottom: 6,
    marginRight: 6,
    padding: '1px 6px',
  },
  removeIcon: {
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    color: theme.palette.common.tags.background,
    cursor: 'pointer',
    fontSize: 14,
    marginLeft: 8,
  },
}));
