import React from 'react';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Icon } from './guidance.svg';

function Guidance(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" children={<path />} component={(svgProps) => <Icon {...svgProps} />} />
  );
}

export default Guidance;
