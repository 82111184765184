import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    firstTab: {
      position: 'relative',
      paddingTop: 2,
      '& > .MuiGrid-root': {
        margin: '0 !important',
      },
      '& .MuiGrid-root:nth-child(2)': {
        paddingRight: 0,
      },
    },
    cornerButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    riskLevelSelect: {
      width: '346px !important',
    },
    checkList: {
      paddingLeft: theme.spacing(2.5),
      paddingBottom: theme.spacing(2),
      '& .MuiButtonBase-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiFormGroup-root': {
        paddingTop: theme.spacing(1),
      },
    },
    floatingTextContainer: {
      position: 'relative',
      '& .MuiInputBase-root': {
        position: 'absolute',
        top: 0,
        left: 25,
        width: 'calc(100% - 25px)',
      }
    },
  }),
);
