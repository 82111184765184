import capitalize from 'lodash/capitalize';
import { isUndefined } from 'lodash';

interface LevelOption {
  color: string;
  label: string;
  value: null | string;
}

export const levelsDictToOptions = (levels: any[], placeholder: string | undefined = undefined): LevelOption[] => {
  const initial = !isUndefined(placeholder)
    ? [
        {
          color: '',
          label: placeholder!,
          value: null,
        },
      ]
    : [];
  return initial.concat(
    [
      {
        color: '',
        label: '',
        value: null,
      },
    ],
    levels ? levels.map((item) => ({ color: item.color, label: capitalize(item.name), value: item.level })) : [],
  );
};

export const onGraphQLError = (message: string) => (e: any) => {
  throw new Error(`${message}${e.message}`);
};
