import get from 'lodash/get';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import semver from 'semver';

import { Trans } from '@lingui/macro';

import { Grid } from '@material-ui/core';

import AttachFile from '@material-ui/icons/AttachFile';

import DocumentBuilder from 'compositions/DocumentBuilder';

import { ContentSection, DocumentUploader, PDFViewer, Spacer } from 'components';
import gql from 'graphql-tag';

const query = gql`
  query documentVersion($id: ID!) {
    documentVersion(id: $id) {
      id
      body
      pdfDataUpload
      locked
      displayAsApplicationVersion
      attachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      rawReferenceAttachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      unresolvedSuggestions
    }
  }
`;

const PayloadFields = ({ layoutClasses, versionId, ...props }) => {
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const { data, loading, error, refetch } = useQuery(query, {
    skip: !versionId,
    variables: { id: versionId },
    fetchPolicy: 'network-only',
  });
  if (loading || !data) return null;
  if (error) throw new Error(`Error loading Form Version ${versionId}\n${error.message}`);
  const versionData = data.documentVersion;

  if (!versionData.pdfDataUpload)
    return (
      <DocumentBuilder
        itemType={props.itemType}
        refetchQueries={['documentVersion', 'BuilderViewQuery']}
        richTextOptionSet="large"
        layoutClasses={layoutClasses}
        versionData={versionData}
      />
    );

  return (
    <>
      <Grid container xs={8} justify="flex-end" alignItems="center" direction="row" className={layoutClasses.grid}>
        <Grid item xs={4} className={layoutClasses.gridGutterLeft}>
          <ContentSection
            LabelText={
              <>
                <AttachFile className={layoutClasses.icon} />
                <Trans>PDF Document</Trans>
              </>
            }
            fillHeight={false}
          >
            <Spacer factor={1} />
            <DocumentUploader
              attrName={'attachment'}
              attachmentId={get(versionData, 'attachment.id')}
              filename={get(versionData, 'attachment.filename')}
              locked={versionData.locked}
              onCompleted={refetch}
              beforeUpload={() => setLoadingAttachment(true)}
              beforeDelete={() => setLoadingAttachment(false)}
              versionId={versionData.id}
            />
          </ContentSection>
          {semver.gte(versionData.displayAsApplicationVersion, '4.3.0') && (
            <ContentSection
              LabelText={
                <>
                  <AttachFile className={layoutClasses.icon} />
                  <Trans>Editable Document</Trans>
                </>
              }
              fillHeight={false}
            >
              <Spacer factor={1} />
              <DocumentUploader
                acceptedFileTypes={'*'}
                attrName={'raw_reference_attachment'}
                attachmentId={get(versionData, 'rawReferenceAttachment.id')}
                filename={get(versionData, 'rawReferenceAttachment.filename')}
                url={get(versionData, 'rawReferenceAttachment.url')}
                locked={versionData.locked}
                versionId={versionData.id}
                onCompleted={refetch}
              />
            </ContentSection>
          )}
        </Grid>
      </Grid>

      <Grid container justify="flex-end" alignItems="center" direction="row" className={layoutClasses.grid}>
        {versionData.attachment && (
          <Grid item xs={12} className={`${layoutClasses.grid} ${layoutClasses.pdfView}`}>
            <PDFViewer loading={loadingAttachment} url={get(versionData, 'attachment.url')} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PayloadFields;
