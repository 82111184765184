import React from 'react';
import { DateTime } from 'luxon';
import { Paper, Typography } from '@material-ui/core';
import { UserAvatar, TextWithMentions } from 'components';

const Comment = ({
  classes,
  createdAt,
  body,
  creator,
}: {
  classes;
  createdAt: Date;
  body: string;
  creator: {
    id: string;
    fullName: string;
  };
}) => (
  <div className={classes.chatMessage}>
    <Typography variant="subtitle2" className={classes.chatTimestamp}>
      {DateTime.fromISO(createdAt).toLocaleString(DateTime.TIME_SIMPLE)}
    </Typography>
    <div className={classes.chatMessageContentWrapper}>
      <div className={classes.avatarContainer}>
        <UserAvatar diameter={24} userId={creator.id} />
      </div>
      <Paper elevation={0} classes={{ root: classes.chatMessageContent }}>
        <div className={classes.messageBody}>
          <Typography variant="body2" className={classes.chatMessageUser}>
            {creator.fullName}
          </Typography>
          <Typography variant="caption">
            <TextWithMentions content={body} />
          </Typography>
        </div>
      </Paper>
    </div>
  </div>
);

export default Comment;
