import gql from 'graphql-tag';

export default gql`
  query effectiveViewQuery($itemId: ID, $itemType: String) {
    item(id: $itemId, itemType: $itemType) {
      id
      customIdentifier
      versions {
        id
        ... on ChangeOrderVersion {
          impactedEmployeesTrainingProgress
          impactedEmployees {
            id
            category
            user {
              id
              firstName
              lastName
            }
            trainingItem {
              id
              customIdentifier
              itemType {
                id
                userCreatable
              }
            }
            trainingRecordVersion {
              id
              currentStatus {
                id
                name
                titleizedName
              }
              item {
                id
                customIdentifier
                itemType {
                  id
                  userCreatable
                }
              }
              dueDate
            }
          }
          trainingImpactAssessments {
            id
            impactedVersion {
              id
              title
              item {
                id
                customIdentifier
                itemType {
                  id
                  userCreatable
                }
              }
            }
            requiresReadTraining
            trainingImpactAssociatedCourses {
              id
              courseVersion {
                id
                versionIdentifier
                currentStatus {
                  id
                  name
                }
                item {
                  id
                  customIdentifier
                  itemType {
                    id
                    userCreatable
                  }
                }
              }
              directlyAddedCourse
              requiresCourseTraining
              justificationForNoTraining
              trainingImpactMasterEntries {
                user {
                  id
                  fullName
                }
                category
                trainingRecordVersion {
                  id
                  item {
                    id
                    customIdentifier
                    itemType {
                      id
                      userCreatable
                    }
                  }
                }
              }
            }
            trainingImpactMasterEntries {
              id
              user {
                id
                fullName
              }
              category
              trainingRecordVersion {
                id
                item {
                  id
                  customIdentifier
                  itemType {
                    id
                    userCreatable
                  }
                }
              }
            }
          }
        }
        ... on CourseVersion {
          reasonForChange
          descriptionOfChange
          linkedChangeId
          linkedChangeJustificationText
          linkedChangeCustomIdentifier
        }
        owner {
          id
          fullName
          title
        }
        approver {
          id
          fullName
          title
        }
        ... on GenericWorkflowInterface {
          linkedChangeId
          reasonForChange
          descriptionOfChange
          linkedChangeJustificationText
          linkedChangeCustomIdentifier
          lastOwnerApproval {
            id
            createdAt
            user {
              id
              firstName
              lastName
            }
          }
        }
        ... on GenericWithMembersWorkflowInterface {
          linkedChangeId
          reasonForChange
          descriptionOfChange
          linkedChangeJustificationText
          linkedChangeCustomIdentifier
        }
        ... on SetGenericWorkflowInterface {
          linkedChangeId
          reasonForChange
          descriptionOfChange
          linkedChangeJustificationText
          linkedChangeCustomIdentifier
        }
        ... on SetMemberWorkflowInterface {
          reasonForChange
          releasedSetVersions {
            id
            versionIdentifier
            currentStatusName
            releasedAt
            title
            item {
              id
              customIdentifier
            }
          }
        }
        ... on TraceMatrixVersion {
          linkedChangeId
          reasonForChange
          descriptionOfChange
          linkedChangeJustificationText
          linkedChangeCustomIdentifier
        }
        ... on TrainingPlanVersion {
          reasonForChange
          descriptionOfChange
        }
        approval {
          id
          createdAt
          user {
            id
            firstName
            lastName
            fullName
          }
        }
        retirement {
          id
          createdAt
          user {
            id
            fullName
          }
        }
        versionIdentifier
        currentStatusName
        createdAt
        creator {
          id
          fullName
          title
        }
        signingOwner {
          id
          fullName
          title
        }
        signingApprover {
          id
          fullName
          title
        }
        releasedAt
        retiredAt
        releaseSignatures {
          id
          createdAt
          previousTransition {
            id
            esignature {
              id
              user {
                id
                fullName
                title
              }
            }
          }
          name
        }
      }
    }
  }
`;
