import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { Cancel, Link } from '@material-ui/icons';
import { useLazyQuery } from 'react-apollo';
import { get } from 'lodash';

import { useAddRiskCtrlVer, useDeleteRiskCtrlVer } from './hooks';
import { useChangeRiskControlVersion } from '../gql';
import { RiskAnalysisMemberVersion } from '../index';
import ChipSelect from './helpers/ChipSelect';
import ChipSelectOptionRender from './ChipSelectOptionRender';
import VersionTracesConfirmationModal from './modals/VersionTracesConfirmationModal';
import { VERSION_TRACE_QUERY } from './modals/gql';

interface RiskMitigationsProps {
  riskVersion: RiskAnalysisMemberVersion;
  className?: any;
}

export default function RiskMitigations({ riskVersion, className = '' }: RiskMitigationsProps) {
  const { locked } = riskVersion;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const addRiskCtrlVer = useAddRiskCtrlVer({ refetchQueries: ['RiskAnalysisVersionQuery'] });
  const deleteRiskCtrlVer = useDeleteRiskCtrlVer({ refetchQueries: ['RiskAnalysisVersionQuery'] });
  const linkedRiskControls = get(riskVersion, 'item.riskControlMeasures');

  const [changeRiskControlVersion] = useChangeRiskControlVersion();

  function findRiskControlByVersionId(data, versionId) {
    for (let item of data) {
      const foundVersion = item.currentVersion.item.versions.find((version) => version.id === versionId);
      if (foundVersion) return item.chosenRiskControlVersionId;
    }
    return null;
  }

  const onError = (e) => (message: string) => {
    throw new Error(`${message}${e.message}`);
  };

  const changeRiskControl = (id: string) => {
    changeRiskControlVersion({
      variables: {
        riskVersionId: riskVersion.id,
        newRiskControlVersionId: id,
        oldRiskControlVersionId: findRiskControlByVersionId(linkedRiskControls, id),
        trioType: 'RiskMitigation',
      },
    }).catch(onError);
  };
  const content = riskVersion.item.riskControlMeasures;
  const options = riskVersion.item.riskControlMeasuresOptions;
  const contentIds = content.map((item) => item.id);
  const filteredOptions = options.filter((option) => !contentIds.includes(option.id));
  const placeholder = content.length ? '' : 'Select Risk Controls';
  const [selectedRiskControl, setSelectedRiskControl] = useState(null);
  const [selectedToggledId, setSelectedToggledId] = useState(null);
  const [versionTraces, setVersionTraces] = useState([]);
  const [actionType, setActionType] = useState<'remove-rcm' | 'toggle-rcm' | null>(null);

  function onGraphQLError(e) {
    throw new Error(`Failed to fetch set versions: ${e.message}`);
  }

  const [getVersionTrace] = useLazyQuery(VERSION_TRACE_QUERY, {
    //@ts-ignore
    fetchPolicy: 'network-only',
    onError: onGraphQLError,
    onCompleted: function(data) {
      if (data && data.versionTrace && data.versionTrace.length > 0) {
        setShowConfirmationModal(true);
        //@ts-ignore
        setVersionTraces(data.versionTrace);
      } else if (!data || data.versionTrace.length === 0) {
        if (actionType === 'remove-rcm') {
          //@ts-ignore
          deleteRiskCtrlVer(riskVersion.id, selectedRiskControl.chosenRiskControlVersionId);
          setSelectedRiskControl(null);
          setActionType(null);
        } else if (actionType === 'toggle-rcm') {
          //@ts-ignore
          changeRiskControl(selectedToggledId);
          setSelectedToggledId(null);
          setActionType(null);
        }
      }
    },
  });

  const handleOnRemove = async (riskControl) => {
    setSelectedRiskControl(riskControl);
    setActionType('remove-rcm');
    getVersionTrace({ variables: { traceVersionId: riskControl.chosenRiskControlVersionId } });
  };

  const handleOnToggle = async (id) => {
    setSelectedToggledId(id);
    setActionType('toggle-rcm');
    getVersionTrace({ variables: { traceVersionId: findRiskControlByVersionId(linkedRiskControls, id) } });
  };

  return (
    <div className={className}>
      <ChipSelect
        placeholder={placeholder}
        disabled={locked || Boolean(riskVersion.finalRiskAcceptability)}
        renderOption={(option) => {
          return <ChipSelectOptionRender option={option} />;
        }}
        content={content}
        options={filteredOptions}
        onAdd={
          locked || Boolean(riskVersion.finalRiskAcceptability)
            ? undefined
            : (riskControl) => {
                addRiskCtrlVer(riskVersion.id, riskControl.currentVersion.id);
              }
        }
        onRemove={locked || Boolean(riskVersion.finalRiskAcceptability) ? undefined : handleOnRemove}
        onToggle={locked || Boolean(riskVersion.finalRiskAcceptability) ? undefined : handleOnToggle}
        onOpen={() => {
          // FIXME: refreshing options on open may make sense
        }}
        chipDeleteIcon={
          <Tooltip title="Remove Risk Control" placement="bottom-start">
            <Cancel />
          </Tooltip>
        }
        popupIcon={
          <Tooltip title="Add Risk Control" placement="bottom-start">
            <div>
              <Link />
            </div>
          </Tooltip>
        }
      />
      {showConfirmationModal && (
        <VersionTracesConfirmationModal
          isOpen={showConfirmationModal}
          actionType={actionType}
          traces={versionTraces}
          riskIdentifier={riskVersion.item.customIdentifier}
          //@ts-ignore
          currentVersionId={selectedRiskControl ? selectedRiskControl.chosenRiskControlVersionId : selectedToggledId}
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={() => {
            setShowConfirmationModal(false);
            if (actionType === 'remove-rcm') {
              //@ts-ignore
              deleteRiskCtrlVer(riskVersion.id, selectedRiskControl.chosenRiskControlVersionId);
              setSelectedRiskControl(null);
              setVersionTraces([]);
              setActionType(null);
            } else if (actionType === 'toggle-rcm') {
              //@ts-ignore
              changeRiskControl(selectedToggledId);
              setSelectedToggledId(null);
              setVersionTraces([]);
              setActionType(null);
            }
          }}
        />
      )}
    </div>
  );
}
