import gql from 'graphql-tag';
import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { buildConditionalClasses } from 'utils';
import { Mutation } from 'react-apollo';
import { DateTime } from 'luxon';

const styles = (theme) => ({
  input: {
    width: '100%',
    padding: theme.spacing(0.5),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
    margin: `${theme.spacing(0.5)}px 0`,
  },
});

const mutation = gql`
  mutation updateDatetime($input: UpdateVirtualAttributeInput!) {
    updateVirtualAttribute(input: $input) {
      virtualAttribute {
        attrName
        datetimeValue
      }
    }
  }
`;

function DateField({
  classes,
  attrName,
  datetimeValue,
  versionId,
  refetchQueries,
  InputClasses,
  TypographyProps,
  ...rest
}) {
  return (
    <Mutation mutation={mutation}>
      {(mutate) => (
        <Typography variant="body2" {...TypographyProps}>
          <input
            type="date"
            className={buildConditionalClasses([
              [true, InputClasses],
              [true, classes.input],
            ])}
            onChange={(e) => {
              mutate({
                variables: {
                  input: {
                    versionId,
                    attrName,
                    datetimeValue: DateTime.fromISO(e.target.value),
                  },
                },
                refetchQueries,
              });
            }}
            {...rest}
          />
        </Typography>
      )}
    </Mutation>
  );
}

export default withStyles(styles)(DateField);
