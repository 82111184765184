import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  list: {
    margin: 0,
    padding: 0,
    '& li': {
      paddingBottom: theme.spacing(1),
    },
    '& li:nth-of-type(1)': {
      paddingTop: theme.spacing(0.25),
    },
    '& li:nth-last-of-type(1)': {
      paddingBottom: theme.spacing(0.25),
    },
  },
  listItem: {
    listStyleType: 'none',
    display: 'flex',
    '& a:hover': {
      textDecoration: 'underline',
      color: theme.palette.subtitleGrey,
    },
  },
  itemId: {
    marginRight: theme.spacing(1.75),
  },
}));
