import React, { useMemo } from 'react';

import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';

import Panel from 'components/core/Panel';

import ProbabilityUnit from './ProbabilityUnit';
import SeverityUnit from './SeverityUnit';
import MatrixCell from './MatrixCell';
import EditableMatrixCell from './EditableMatrixCell';

import { useStyles } from './styles';
import {
  RiskDashboard_riskMatrixEntries as RPCMatrixEntry,
  RiskDashboard_riskMatrixEntries_hazardousSituationProbabilityLevel as Probability,
} from 'pages/RiskManagementDashboard/__generated__/RiskDashboard';
import { riskPriorityCodes_riskPriorityCodes } from '../../pages/GeneralSettings/Settings/RiskManagement/__generated__/riskPriorityCodes';

interface Props {
  riskMatrixEntries: RPCMatrixEntry[];
  editMode?: boolean;
  riskPriorityCodes?: riskPriorityCodes_riskPriorityCodes[];
  onInputChange?: (
    unit: 'harmSeverityLevel' | 'hazardousSituationProbabilityLevel',
    matrixId: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onMatrixCellChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

const getUniques = <T extends unknown>(arr: T[], key: string): T[] => {
  // @ts-ignore
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

const RPCCalculation: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { riskMatrixEntries, editMode, riskPriorityCodes, onInputChange, onMatrixCellChange } = props;

  const severityLevels = useMemo(() => {
    return getUniques(riskMatrixEntries.map((entry) => ({ ...entry.harmSeverityLevel, matrixId: entry.id })), 'name');
  }, [riskMatrixEntries]);

  const probabilityLevels = useMemo(() => {
    return getUniques(
      riskMatrixEntries.map((entry) => ({ ...entry.hazardousSituationProbabilityLevel, matrixId: entry.id })),
      'name',
    );
  }, [riskMatrixEntries]);

  const renderMatrixRow = (probability: Probability & { matrixId: string }, matrixValues: RPCMatrixEntry[]) => {
    return (
      <React.Fragment key={probability.level}>
        <Grid item xs={2}>
          <ProbabilityUnit
            probability={probability}
            editMode={editMode}
            onChange={(event) =>
              onInputChange && onInputChange('hazardousSituationProbabilityLevel', probability.matrixId, event)
            }
          />
        </Grid>
        {matrixValues.map((matrixValue) => (
          <Grid key={matrixValue.id} item xs={2}>
            {editMode ? (
              <EditableMatrixCell matrixValue={matrixValue} options={riskPriorityCodes} onChange={onMatrixCellChange} />
            ) : (
              <MatrixCell matrixValue={matrixValue} />
            )}
          </Grid>
        ))}
      </React.Fragment>
    );
  };

  return (
    <Panel className={classes.panel}>
      <Grid container spacing={0}>
        <Grid item xs={2} />
        <Grid item xs={10}>
          <Typography variant="h6" className={clsx(classes.matrixHeading, classes.severityHeading)}>
            Severity
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" className={clsx(classes.matrixHeading, classes.probabilityHeading)}>
            Probability
          </Typography>
        </Grid>
        {severityLevels.map((severity) => (
          <Grid key={severity.level} item xs={2}>
            <SeverityUnit
              severity={severity}
              editMode={editMode}
              onChange={(event) => onInputChange && onInputChange('harmSeverityLevel', severity.matrixId, event)}
            />
          </Grid>
        ))}
        {probabilityLevels.map((prob) =>
          renderMatrixRow(
            prob,
            riskMatrixEntries.filter((entry) => entry.hazardousSituationProbabilityLevel.name === prob.name),
          ),
        )}
      </Grid>
    </Panel>
  );
};

export default RPCCalculation;
