import React from 'react';
import get from 'lodash/get';
import { Trans } from '@lingui/macro';

import { ChangeOrderRequired, SelectChangeOrder, Justify } from 'compositions/TimelineGroup/steps/views';

export const ownerApprovalWithoutSigSteps = {
  coRequired: {
    component: ChangeOrderRequired,
    getNextStep: (coRequired) => (coRequired ? 'selectCO' : 'justify'),
  },
  selectCO: {
    component: (props) => <SelectChangeOrder {...props} allowNull />,
    getNextStep: (_) => 'complete',
  },
  justify: {
    component: (props) => <Justify {...props} />,
    getNextStep: (_) => 'complete',
  },
};

export function ownerApprovalFinalResult({ selectCO, justify }) {
  return {
    changeItemId: get(selectCO, 'changeOrderId'),
    justificationText: justify,
  };
}

export function buildReleaseMessage(props) {
  return `My signature indicates that I have reviewed the ${props.itemCustomIdentifier}: ${props.currentVersion.title}`;
}

export function buildActionMessage(action, currentVersion, itemCustomIdentifier) {
  return (
    <Trans>
      Do you want to {action} version {get(currentVersion, 'versionIdentifier')} of {itemCustomIdentifier}?
    </Trans>
  );
}
