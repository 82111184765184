import React, { useState } from 'react';
import flowRight from 'lodash/flowRight';

import { Add } from '@material-ui/icons';

import { AlertDialogWithUser, WorkflowIcon } from 'components';
import SmallCircle from 'compositions/TimelineGroup/components/SmallCircle';
import ConnectBar from 'compositions/TimelineGroup/components/ConnectBar';
import WithCurrentUser from 'compositions/WithCurrentUser';
import { matchPermissionGrantForVersion } from 'compositions/TimelineGroup/util';

import { Order, Status } from '../../statuses';
import { TrainingPlanWorkflowStatuses, indexById } from 'workflows/statuses';
import { TransitionWizard } from '../../Wizard';
import { getInactiveUsers, getUsersWithoutActiveRole } from 'utils';

interface Props {
  currentCompanyAllowsTransitioning: boolean;
  currentVersion: any;
  startWizard: (t: TransitionWizard) => void;
  currentUser: any;
}

interface DeniedModalState {
  open: boolean;
  user: object | any;
  role: string | any;
}

function CurrentWorkflow(props: Props) {
  const [deniedModalWithUser, setDeniedModalWithUser] = useState<DeniedModalState>({
    open: false,
    user: null,
    role: null,
  });

  const currentVersion = props.currentVersion;
  const {
    approver,
    assignedApproverJobRole,
    assignedOwnerJobRole,
    currentStatus,
    owner,
    versionIdentifier,
  } = currentVersion;

  const TrainingPlanWorkflowStatusesById = indexById(TrainingPlanWorkflowStatuses);
  const currentWorkflowStatus = TrainingPlanWorkflowStatusesById[currentStatus.name];
  const { Draft, UnderReview, Prepared, Rejected } = TrainingPlanWorkflowStatuses;

  const canOwn = matchPermissionGrantForVersion(props.currentUser, currentVersion, 'owner');
  const canApprove = matchPermissionGrantForVersion(props.currentUser, currentVersion, 'approver');
  const currentStatusOrder = Order[currentStatus.name];

  const rfcContent = ({ reasonForChange, descriptionOfChange }) =>
    reasonForChange || descriptionOfChange ? 'RFC' : <Add />;

  const inactiveUsers = getInactiveUsers(owner, approver);
  const usersWithoutActiveRole = getUsersWithoutActiveRole(
    owner,
    approver,
    assignedOwnerJobRole,
    assignedApproverJobRole,
  );

  const handleInactiveUserDeniedModal = (inactiveUsers) => {
    setDeniedModalWithUser({
      open: true,
      user: inactiveUsers[0].user,
      role: inactiveUsers[0].role,
    });
  };

  const handleUserWithoutActiveRoleDeniedModal = (usersWithoutActiveRole) => {
    setDeniedModalWithUser({
      open: true,
      user: usersWithoutActiveRole[0].user,
      role: usersWithoutActiveRole[0].role,
    });
  };

  return (
    <>
      <SmallCircle
        content={rfcContent(currentVersion)}
        color="draft"
        handleClick={() => {
          (currentWorkflowStatus.isEqualTo(Draft) || currentWorkflowStatus.isEqualTo(UnderReview)) && canOwn
            ? props.startWizard(TransitionWizard.RFCDOC)
            : props.startWizard(TransitionWizard.SummaryOfChange);
        }}
      />

      <ConnectBar color="draft" visible short />

      <WorkflowIcon
        type="draft"
        active={currentStatusOrder === Order[Status.Draft]}
        completed={currentStatusOrder > Order[Status.Draft]}
        version={versionIdentifier}
        label="Draft"
        size="large"
        visible
      />

      <ConnectBar color="underReview" visible disabled={!currentVersion.mayTransitionToUnderReview} />

      <WorkflowIcon
        type="underReview"
        active={currentStatusOrder === Order[Status.UnderReview]}
        completed={currentStatusOrder > Order[Status.UnderReview]}
        version={versionIdentifier}
        label="Under Review"
        size="large"
        visible
        disabled={!currentVersion.mayTransitionToUnderReview}
        handleClick={() => {
          if (inactiveUsers.length) return handleInactiveUserDeniedModal(inactiveUsers);
          if (usersWithoutActiveRole.length) return handleUserWithoutActiveRoleDeniedModal(usersWithoutActiveRole);
          if (currentWorkflowStatus.isEqualTo(Draft)) props.startWizard(TransitionWizard.DraftToUnderReview);
        }}
      />

      <ConnectBar color="ownerApproval" visible disabled={!canOwn} />

      <WorkflowIcon
        type="ownerApproval"
        active={currentStatusOrder === Order[Status.Prepared]}
        completed={currentStatusOrder > Order[Status.Prepared]}
        version={versionIdentifier}
        label="Prepared"
        size="large"
        visible
        disabled={!canOwn}
        handleClick={() => {
          if (currentWorkflowStatus.isEqualTo(UnderReview)) props.startWizard(TransitionWizard.UnderReviewToPrepared);
        }}
      />

      <ConnectBar color="rejected" visible={currentStatusOrder === Order[Status.Rejected]} />

      <WorkflowIcon
        type="rejected"
        active={currentStatusOrder === Order[Status.Rejected]}
        visible={currentStatusOrder === Order[Status.Rejected]}
        size="large"
        version={versionIdentifier}
        label="Rejected"
        handleClick={() => {
          if (currentWorkflowStatus.isEqualTo(Rejected)) props.startWizard(TransitionWizard.ResolveRejection);
        }}
      />

      <ConnectBar color="released" visible={currentStatusOrder !== Order[Status.Rejected]} disabled={!canApprove} />

      <WorkflowIcon
        type="released"
        active={currentStatusOrder === Order[Status.Released]}
        visible={currentStatusOrder !== Order[Status.Rejected]}
        size="large"
        version={versionIdentifier}
        label="Released"
        disabled={!canApprove}
        handleClick={() => {
          if (currentWorkflowStatus.isEqualTo(Prepared)) props.startWizard(TransitionWizard.ReleaseOrReject);
        }}
      />

      <AlertDialogWithUser
        open={deniedModalWithUser.open}
        titleText={inactiveUsers.length ? 'Deactivated User' : 'Deactivated Role'}
        onClose={() => setDeniedModalWithUser({ open: false, user: null, role: null })}
        subtitle={
          inactiveUsers.length
            ? 'The following user has been deactivated.'
            : "The following user's role has been removed."
        }
        user={deniedModalWithUser.user}
        role={deniedModalWithUser.role === 'owner' ? assignedOwnerJobRole : assignedApproverJobRole}
        body={`Please use the People Menu to reassign the ${
          deniedModalWithUser.role === 'owner' ? 'Owner' : 'Approver'
        } role to a new user.`}
      />
    </>
  );
}

export default flowRight([WithCurrentUser])(CurrentWorkflow);
