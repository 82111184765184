import React from 'react';

import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import SettingsInput from '../SettingsInput';

import { RiskDashboard_riskMatrixEntries_harmSeverityLevel as Severity } from 'pages/RiskManagementDashboard/__generated__/RiskDashboard';

import { useStyles } from './styles';

interface Props {
  severity: Severity;
  editMode?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SeverityUnit: React.FC<Props> = ({ severity, editMode, onChange }) => {
  const classes = useStyles();
  const { id, name, color } = severity;

  return (
    <div className={classes.severityUnit}>
      {editMode ? (
        <SettingsInput name={id} defaultValue={name} fullWidth onChange={onChange} />
      ) : (
        <Typography variant="h6" className={clsx(classes.matrixSideHeading, classes.severityName)}>
          {name}
        </Typography>
      )}
      <div className={classes.severityBox} style={{ backgroundColor: color }} />
    </div>
  );
};

export default SeverityUnit;
