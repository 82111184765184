import React, { ComponentType, ReactNode } from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import Collapse from './Collapse';
import HelperText from './HelperText';
import { useStyles } from './styles';

interface Props {
  icon: ComponentType<SvgIconProps>;
  children?: ReactNode;
  variant?: string;
  classes?: any;
}

function SectionLabel(props: Props) {
  const classes = useStyles();
  const Icon = props.icon;
  return (
    <Typography
      component="div"
      variant={(props.variant || 'h3') as any}
      className={clsx(classes.container, props.classes && props.classes.container)}
    >
      <Icon className={clsx(classes.icon, props.classes && props.classes.icon)} />
      {props.children}
    </Typography>
  );
}

SectionLabel.Collapse = Collapse;
SectionLabel.HelperText = HelperText;

export default SectionLabel;
