import React, { ReactNode, useState } from 'react';

interface State {
  locked: boolean;
  setLocked: (state: boolean) => void;
}

export const LockedContext = React.createContext<State>({
  locked: false,
  setLocked: (_) => {},
});

export function LockedContextProvider(props: { children: ReactNode }) {
  const [locked, setLocked] = useState(false);

  return (
    <LockedContext.Provider
      value={{
        locked,
        setLocked,
      }}
    >
      {props.children}
    </LockedContext.Provider>
  );
}

export const withLockedContext = (Component) => (props) => (
  <LockedContextProvider>
    <Component {...props} />
  </LockedContextProvider>
);
