import React, { CSSProperties } from 'react';

import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

export function RegularTextCell({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Typography variant="body2">{data}</Typography>
    </div>
  );
}

export const regularTextHeaderStyle = { textAlign: 'left' } as CSSProperties;
