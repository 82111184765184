import React, { FC } from 'react';

import { Box, Typography } from '@material-ui/core';
import { LibraryAddCheckOutlined } from 'assets/icons';
import watermarkTop from 'assets/img/watermark_left_menu_top.png';
import watermarkBottom from 'assets/img/watermark_left_menu_bottom.png';

import { useStyles } from './styles';

const EmptyList: FC = () => {
  const classes = useStyles();

  return (
    <Box ml={3}>
      <Box display="flex" alignItems="center" mb={0.25}>
        <LibraryAddCheckOutlined className={classes.emptyListIcon} />
        <Box mr={1} />

        <Typography variant="button">Relax</Typography>
      </Box>
      <Typography variant="body2">All assigned tasks are complete </Typography>
      <Box>
        <Box mt={4} />
        <img src={watermarkTop} style={{ position: 'absolute', right: 0 }} height={412} alt="watermark-left-menu-top" />
        <img
          src={watermarkBottom}
          style={{ position: 'absolute', left: 56, bottom: 0 }}
          height={356}
          alt="watermark-left-menu-bottom"
        />
      </Box>
    </Box>
  );
};

export default EmptyList;
