import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const GET_USE_ENVIRONMENT_SPECIFICATION_VERSION = gql`
  query UseEnvironmentSpecificationVersionQuery($id: ID!) {
    useEnvironmentSpecificationVersion(id: $id) {
      id
      title
      displayAsApplicationVersion
      item {
        id
        itemNumber
        customIdentifier
      }
      attachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      rawReferenceAttachment {
        id
        url
        filename
        createdAt
        creator {
          id
          fullName
        }
      }
      body
      inputMethod
      locked
      displayAsApplicationVersion
      useEnvironmentVersions {
        id
        item {
          id
          customIdentifier
          tags
          itemNumber
          itemType {
            id
            name
          }
          comments {
            id
          }
        }
        locked
        title
        description
        member {
          id
          position
        }
      }
    }
  }
`;

const CREATE_USE_ENVIRONMENT = gql`
  mutation AddUevToUseEnvironmentSpecificationVersion($useEnvironmentSpecificationId: ID!, $title: String!) {
    addUevToUseEnvironmentSpecificationVersion(
      useEnvironmentSpecificationId: $useEnvironmentSpecificationId
      title: $title
    ) {
      item {
        id
      }
    }
  }
`;

const REMOVE_MEMBERS = gql`
  mutation removeUseEnvironments($ids: [ID!]!) {
    removeMembers(ids: $ids) {
      members {
        id
      }
    }
  }
`;

function useVersion(id: string) {
  return useQuery(GET_USE_ENVIRONMENT_SPECIFICATION_VERSION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
}

export { useVersion, REMOVE_MEMBERS, CREATE_USE_ENVIRONMENT, GET_USE_ENVIRONMENT_SPECIFICATION_VERSION };
