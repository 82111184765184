import React, { useRef } from 'react';

import { AttachFile, Link as LinkIcon, MoreHoriz } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import VersionIcon from 'assets/icons/Version';
import IconButton from 'components/IconButton';
import useOnClickOutside from 'hooks/useOutSideClick';

import useStyles from './styles';

type TrioPanelProps = {
  handleToggle: () => void;
  toggleItemsTable: () => void;
  toggleVersionsTable: () => void;
  toggleAttachmentsTable: () => void;
  opened: boolean;
  showItemsButton: boolean;
  showVersionsButton: boolean;
  showAttachmentButton: boolean;
};

const TrioControlPanel = (props: TrioPanelProps) => {
  const { handleToggle, toggleVersionsTable, toggleItemsTable, toggleAttachmentsTable, opened } = props;
  const classes = useStyles();

  const ref = useRef(null);

  useOnClickOutside(ref, () => handleToggle());

  return (
    <div className={classes.panelContainer}>
      <IconButton size={'large'} onClick={() => handleToggle()} Icon={MoreHoriz} includeBackground />
      {opened && (
        <section ref={ref} className={classes.buttonsSection}>
          {props.showItemsButton && (
            <Tooltip title={'Link Item'}>
              <div>
                <IconButton onClick={() => toggleItemsTable()} Icon={LinkIcon} includeBackground />
              </div>
            </Tooltip>
          )}
          {props.showVersionsButton && (
            <Tooltip title={'Link Item Version'}>
              <div>
                <IconButton onClick={() => toggleVersionsTable()} Icon={VersionIcon} includeBackground />
              </div>
            </Tooltip>
          )}

          {props.showAttachmentButton && (
            <Tooltip title={'Attach File'}>
              <div>
                <IconButton onClick={() => toggleAttachmentsTable()} Icon={AttachFile} includeBackground />
              </div>
            </Tooltip>
          )}
        </section>
      )}
    </div>
  );
};

export default TrioControlPanel;
