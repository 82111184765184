import React from 'react';
import { navigate } from '@reach/router';

import { BaseSnackbar } from 'components';

interface Props {
  open: boolean;
  handleSnackbarClose: () => void;
  newUser: any;
}

function UserCreatedSnackbar(props: Props) {
  const { open, handleSnackbarClose, newUser } = props;
  return (
    <BaseSnackbar
      actionText="View User"
      actionFunction={() => {
        const employeeUrl = `/category/employees/${newUser.employee.id}`;

        navigate(employeeUrl).catch((err) => {
          throw err;
        });
      }}
      message={`An account was created for ${newUser.fullName}.`}
      onClose={handleSnackbarClose}
      open={open}
    />
  );
}

export default UserCreatedSnackbar;
