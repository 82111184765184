import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    table: {
      marginTop: theme.spacing(2),
      width: '100%',
      borderCollapse: 'separate',
    },
    header: {
      ...theme.typography.overline,
    },
    fullHeight: {
      height: '100%',
    },
  }),
);
