import gql from 'graphql-tag';
import { t } from '@lingui/macro';
import List from '@material-ui/icons/List';
import slugs from '../../displaySlugs';

import { listFragment, docMetadata } from '../mixins/Document';
const itemType = 'workInstruction';

export default {
  displaySlug: slugs[itemType],
  itemType,
  label: t`Work Instruction`,
  categoryLabel: t`Work Instructions`,
  icon: List,
  listViewQuery: gql`
    query workInstructionsQuery {
      documents(itemType: "work_instruction") {
        ...docListFragment
      }
    }
    ${listFragment}
  `,
  ...docMetadata,
};
