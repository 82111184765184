import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  formLeft: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const FormLabel = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.formLeft}>
      <Typography variant="caption" className={classes.bold} component="div">
        {text}
      </Typography>
    </div>
  );
};
export default FormLabel;
