import React from 'react';

import Close from '@material-ui/icons/Close';

import { Button, IconButton, Snackbar } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  message: string;
  onClose: () => void;
  open: boolean;

  // to add custom action
  actionText?: string;
  actionFunction?: () => void;
}

function BaseSnackbar(props: Props) {
  const classes = useStyles();

  const hasAction = props.actionText && props.actionFunction;

  const CustomAction = () => (
    <Button className={classes.actionBtn} key="btn" size="small" type="button" onClick={props.actionFunction}>
      {props.actionText}
    </Button>
  );

  const CloseAction = () => (
    <IconButton className={classes.closeIcon} key="close" color="primary" onClick={props.onClose}>
      <Close />
    </IconButton>
  );

  const actions = hasAction ? [<CustomAction />, <CloseAction />] : [<CloseAction />];

  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={props.open}
      onClose={props.onClose}
      message={props.message}
      action={actions}
    />
  );
}

export default BaseSnackbar;
