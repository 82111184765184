import React from 'react';

import { Grid, withStyles } from '@material-ui/core';

import { ComingSoon } from 'components';

const styles = (theme) => ({
  container: {
    padding: `0 ${theme.spacing(2)}px`,
    width: '100%',
  },
});

function ComingSoonPage(props) {
  return (
    <Grid
      container
      classes={{ container: props.classes.container }}
      item
      xs={12}
    >
      <ComingSoon />
    </Grid>
  );
}

export default withStyles(styles)(ComingSoonPage);
