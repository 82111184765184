import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    ...theme.mixins.gridLayout,
    paper: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),
      width: '100%',
    },
    icon: {
      width: 16,
      height: 16,
      marginRight: 4,
    },
    splitter: {
      color: theme.palette.splitter.background,
      borderStyle: 'solid',
      borderWidth: 0.5,
    },
    textRow: {
      display: 'flex',
    },
    statusRow: {
      display: 'flex',
      alignItems: 'center',
      '& > .MuiTypography-root': {
        marginLeft: theme.spacing(1.5),
        whiteSpace: 'pre',
      },
    },
    avatarPart: {
      marginLeft: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    namePart: {
      display: 'flex',
      flexDirection: 'column',
    },
    userName: {
      marginLeft: theme.spacing(0.5),
      whiteSpace: 'pre',
    },
    roles: {
      whiteSpace: 'pre',
      marginLeft: theme.spacing(0.5),
      fontSize: '60%',
      maxWidth: 200,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
)
