import React, { useRef } from 'react';
import get from 'lodash/get';
import { Query } from 'react-apollo';
import { Typography } from '@material-ui/core';

import { withWorkspaceViews } from 'compositions/WithWorkspaceViews';
import useItemType from './useItemType';
import { Views } from 'workspaces';
import { TableView, ComingSoon, Loading } from 'components';
import { LinksGraph } from 'compositions';
import GenericOptionsDropdown from 'compositions/GenericOptionsDropdown';
import { useViewOption } from './useViewOption';

function ListPage(props: { itemType: string; activeWorkspaceView: { id: string }; categorySlug: string }) {
  const itemType = useItemType(props.itemType);
  const [onChange, { viewOption }] = useViewOption(props.itemType);
  const gridRef = useRef<any>();
  if (!itemType) return null;
  if (!itemType.listViewQuery)
    throw new Error(
      `Cannot render generic ListPage for ItemType ${
        props.itemType
      }.  Either define a listViewQuery or override ListPage`,
    );

  return (
    <Query errorPolicy="all" query={itemType.listViewQuery} fetchPolicy={'network-only'}>
      {(result) => {
        const { data, error, loading } = result;
        if (loading) return <Loading />;
        if (error) throw error;

        const records = get(data, Object.keys(data)[0], []);

        if (!records.length && !itemType.disableNoRecordsPanel)
          return (
            <Typography variant="h2" align="center" style={{ paddingTop: '20%' }}>
              There are currently no records for this item type yet.
            </Typography>
          );
        // FIXME: This is temporary and needs styling

        switch (props.activeWorkspaceView.id) {
          case Views.Table.id:
            return (
              <TableView
                gridRef={gridRef}
                categoryLabel={itemType.categoryLabel}
                categorySlug={props.categorySlug}
                itemType={props.itemType}
                listViewColumns={itemType.listViewColumns}
                loading={loading}
                data={records}
                DisplayVariantSelect={() => (
                  <GenericOptionsDropdown
                    itemType={props.itemType}
                    viewOption={viewOption}
                    onChange={(event) => onChange(event, gridRef.current)}
                  />
                )}
              />
            );
          case Views.LinksGraph.id || Views.StepTrace.id:
            return (
              <LinksGraph
                categorySlug={props.categorySlug}
                itemType={props.itemType}
                queryVariables={itemType.linksGraphViewQueryVariables}
              />
            );
          default:
            return <ComingSoon />;
        }
      }}
    </Query>
  );
}

export default withWorkspaceViews()(ListPage);
