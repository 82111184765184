import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

import { StatusDot } from 'components';

interface Props {
  version: string;
  status: string;
  style?: object;
  theme;
  onClick?;
}

function VersionAndStatusDot(props: Props) {
  let style = {
    display: 'flex',
    alignItems: 'center',
    ...props.style,
  };

  if (typeof props.onClick === 'function') {
    Object.assign(style, { cursor: 'pointer' });
  }

  return (
    <div style={style} onClick={props.onClick}>
      <Typography
        variant="body2"
        style={{
          ...style,
          textTransform: 'uppercase',
          marginRight: 2,
        }}
        component="div"
        noWrap
      >
        {props.version}
      </Typography>
      <StatusDot status={props.status} diameter={10} />
    </div>
  );
}

export default withStyles({}, { withTheme: true })(VersionAndStatusDot);
