import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { ReactComponent as FactCheck } from './fact-check.svg';

const FactCheckIcon = (props) => {
  return <SvgIcon {...props} children={<path />} component={(svgProps) => <FactCheck {...svgProps} />} />;
};

export default FactCheckIcon;
