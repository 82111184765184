import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

import { IconButton, Grid, Tooltip, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { ItemTitle, Loading, NoContentDisclaimer } from 'components';

import AddHazardDialog from './AddHazardDialog';
import DataTable from 'components/DataTable';
import Page from 'components/core/Page';

import { LIST_HAZARDS } from './gql';
import { useStyles } from './styles';

const dataTableColumns = [
  {
    title: 'Examples of Hazards',
    field: 'name',
  },
];

function Hazards(props) {
  const [hazards, setHazards] = useState([]);
  const [showAddHazard, setShowAddHazard] = useState(false);
  const classes = useStyles();

  const { data, loading, error } = useQuery(LIST_HAZARDS);

  useEffect(() => {
    if (data) {
      setHazards(data.hazards);
    }
  }, [data]);

  if (error) throw new Error(`Error loading hazards: ${error.message}`);
  if (loading) return <Loading />;

  return (
    <Page>
      <Grid item xs={11}>
        <Typography variant="body2">Risk Management</Typography>
        <ItemTitle itemTitle="Hazards Library" editable={false} />
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Add Hazard">
          <IconButton color="secondary" size="small" onClick={() => setShowAddHazard(true)}>
            <AddCircleIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        {!hazards.length ? (
          <NoContentDisclaimer variant="add" className={classes.noContent}>
            {({ icon }) => <>There's nothing here yet. Click {icon()} to add new Hazard.</>}
          </NoContentDisclaimer>
        ) : (
          <DataTable columns={dataTableColumns} data={hazards} />
        )}
      </Grid>

      <AddHazardDialog
        onAdd={(hazard) => {
          setShowAddHazard(false);
          setHazards([...hazards, hazard]);
        }}
        onCancel={() => setShowAddHazard(false)}
        open={showAddHazard}
      />
    </Page>
  );
}

export default Hazards;
