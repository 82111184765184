import React from 'react';
import { Grid } from '@material-ui/core';
import { DocumentStyleUploader, PDFViewer, Spacer } from 'components';

interface Props {
  layoutClasses: any;
  versionData: any;
  refetch?: any;
}

export default function PDFData(props: Props) {
  const { layoutClasses, versionData, refetch } = props;
  return (
    <>
      <Grid container xs={8} className={layoutClasses.grid}>
        <Grid item xs={4} style={{ marginLeft: 'auto' }} className={layoutClasses.gridGutterLeft}>
          <DocumentStyleUploader versionData={versionData} onUpdate={() => refetch()} />
        </Grid>
      </Grid>
      <Grid container justify="flex-end" alignItems="center" direction="row" className={layoutClasses.grid}>
        {versionData.attachment && (
          <Grid item xs={12} className={`${layoutClasses.grid} ${layoutClasses.pdfView}`}>
            <Spacer factor={2} />
            <PDFViewer url={versionData.attachment.url} />
          </Grid>
        )}
      </Grid>
    </>
  );
}
